import { ComponentsThemeProvider, getCssVariable, Icon, themeAutomator, } from '@fourthwall/components';
import { CloseIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
export const Bar = ({ title }) => {
    const colorPrimary = getCssVariable('--color-primary');
    const colorOnPrimary = getCssVariable('--color-on-primary');
    const colorBackground = getCssVariable('--color-background');
    const colorOnBackground = getCssVariable('--color-on-background');
    return (React.createElement(ComponentsThemeProvider, { automatedTheme: themeAutomator({
            colorBackground,
            colorOnPrimary,
            colorPrimary,
            colorOnBackground,
        }) },
        React.createElement(S.Container, null,
            React.createElement(S.Close, null,
                React.createElement(Icon, { component: CloseIcon, height: 12 })),
            React.createElement(S.Icon, { src: "/platform/favicon" }),
            React.createElement(S.Details, null,
                React.createElement(S.Title, null, title),
                React.createElement(S.Subtitle, null, "App available now!")),
            React.createElement(S.Button, null, "Install"))));
};
