import React, { useLayoutEffect } from 'react';
import { Button, Icon, InputUI, List, Loader, Subsection } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import * as S from './styled';
import { SelectedItem } from './components/SelectedItem';
export const SelectedItems = ({ header, disabled, isLoading, itemsCount, data, error, maxHeight, addItemsButtonLabel = 'Add', renderItem, onAddItems, onRemoveItem, }) => {
    const containerRef = React.useRef(null);
    const [currentHeight, setCurrentHeight] = React.useState(0);
    useLayoutEffect(() => {
        if (!isLoading && containerRef.current) {
            setCurrentHeight(containerRef.current.clientHeight);
        }
    }, [isLoading, itemsCount]);
    return (React.createElement(S.Container, { disabled: disabled },
        React.createElement(Subsection, null,
            React.createElement(S.Header, null,
                React.createElement(S.HeaderContentWrapper, null,
                    React.createElement(S.HeaderContent, null, header)),
                !disabled && (React.createElement(Button, { label: addItemsButtonLabel, size: "small", leftIcon: React.createElement(Icon, { component: PlusIcon, height: 12 }), onClick: () => onAddItems() }))),
            React.createElement("div", { ref: containerRef },
                React.createElement(S.SelectedItemsList, { maxHeight: maxHeight }, !!itemsCount &&
                    (isLoading || !data ? (React.createElement(S.LoaderContainer, { height: currentHeight || undefined },
                        React.createElement(Loader, null))) : (React.createElement(List, { data: data, topBorder: false, renderItem: (item, index) => (React.createElement(SelectedItem, { withMaxHeight: !!maxHeight, disabled: disabled, onRemove: () => onRemoveItem(item) }, renderItem(item, index))) })))))),
        !!error?.length && React.createElement(InputUI.Error, null, error)));
};
