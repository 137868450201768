import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { useDashboardFeatureFlags } from '../../../../../../components/utils/DashboardFeatureFlags';
import { useTagsQuery } from '../../../hooks/useTagsQuery';
import { usePostsQuery } from '../../Posts/hooks/usePostsQuery';
import { getAudioPostPayload, getImagePostPayload, getMultipleImagePostPayload, getTextPostPayload, getVideoEmbedPostPayload, getVideoPostPayload, } from '../utils/forms';
import { usePinPostMutation } from './usePinPostMutation';
import { usePost } from './usePost';
import { usePostSeriesQuery } from './usePostSeriesQuery';
import { useUnpinPostMutation } from './useUnpinPostMutation';
import { useUpdateAudioPost } from './useUpdateAudioPost';
import { useUpdateImagePost } from './useUpdateImagePost';
import { useUpdateMultipleImagePostMutation } from './useUpdateMultipleImagePostMutation';
import { useUpdatePollPostMutation } from './useUpdatePollPostMutation';
import { useUpdateTextPostMutation } from './useUpdateTextPostMutation';
import { useUpdateVideoEmbedPost } from './useUpdateVideoEmbedPost';
import { useUpdateVideoPost } from './useUpdateVideoPost';
export const usePostUpdate = (postId) => {
    const modal = useModalContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { updateTextPostMutation } = useUpdateTextPostMutation();
    const { updatePollPostMutation } = useUpdatePollPostMutation();
    const { updateMultipleImagePostMutation } = useUpdateMultipleImagePostMutation();
    const { updateImagePost, isLoading: isImageUpdateLoading } = useUpdateImagePost();
    const { pinPostMutation } = usePinPostMutation();
    const { unpinPostMutation } = useUnpinPostMutation();
    const { postsQuery } = usePostsQuery([
        {
            state: 'Published',
            page: 1,
            limit: 3,
        },
    ], {
        enabled: false,
    });
    const { updateVideoPost, isLoading: isVideoUpdateLoading, mediaUploadProgress: videoUploadProgress, } = useUpdateVideoPost();
    const { updateVideoEmbedPost, isLoading: isVideoEmbedUpdateLoading } = useUpdateVideoEmbedPost();
    const { updateAudioPost, isLoading: isAudioUpdateLoading, mediaUploadProgress: audioUploadProgress, } = useUpdateAudioPost();
    const { postQuery } = usePost(postId);
    const { tagsQuery } = useTagsQuery([{ limit: 9999 }]);
    const { postSeriesQuery } = usePostSeriesQuery(Number(postId));
    const handleSuccess = () => {
        postQuery.refetch();
        tagsQuery.refetch();
        postSeriesQuery.refetch();
        Toasts.notify('Post updated.', { type: 'info' });
    };
    const updatePost = async (values) => {
        if (values.postType === 'Text') {
            updateTextPostMutation.mutate({ id: Number(postId), ...getTextPostPayload(values) }, {
                onSuccess: handleSuccess,
            });
        }
        if (values.postType === 'Poll') {
            updatePollPostMutation.mutate({ id: Number(postId), ...getTextPostPayload(values) }, {
                onSuccess: handleSuccess,
            });
        }
        if (values.postType === 'Image') {
            if (dashboardFeatureFlags.membershipsMultipleImages.enabled) {
                updateMultipleImagePostMutation.mutate([{ id: Number(postId) }, { ...getMultipleImagePostPayload(values) }], {
                    onSuccess: handleSuccess,
                });
            }
            else {
                const payload = await getImagePostPayload(values);
                if (values.image) {
                    await updateImagePost(postId, values.image, payload, handleSuccess);
                }
            }
        }
        if (values.postType === 'Video') {
            const payload = await getVideoPostPayload(values);
            if (values.video) {
                await updateVideoPost(postId, values.video, values.videoThumbnail, values.videoSubtitles, payload, handleSuccess);
            }
        }
        if (values.postType === 'VideoEmbed') {
            const payload = await getVideoEmbedPostPayload(values);
            await updateVideoEmbedPost(postId, values.videoThumbnail, payload, handleSuccess);
        }
        if (values.postType === 'Audio') {
            const payload = await getAudioPostPayload(values);
            if (values.audio) {
                await updateAudioPost(postId, values.audio, values.audioThumbnail, payload, handleSuccess);
            }
        }
    };
    const pinPost = () => {
        postsQuery.refetch().then((data) => {
            if (data.data?.posts.every((post) => post.pinnedAt)) {
                return modal.open(ConfirmModal, {
                    title: 'Pin limit reached',
                    text: 'You can pin up to 3 posts. Pinning this post will replace your oldest pinned post.',
                    onConfirm: () => {
                        pinPostMutation.mutate([{ post_id: Number(postId) }], {
                            onSuccess: () => {
                                postQuery.refetch();
                                Toasts.notify('Post pinned.', { type: 'info' });
                            },
                        });
                    },
                });
            }
            pinPostMutation.mutate([{ post_id: Number(postId) }], {
                onSuccess: () => {
                    postQuery.refetch();
                    Toasts.notify('Post pinned.', { type: 'info' });
                },
            });
        });
    };
    const unpinPost = () => {
        unpinPostMutation.mutate([{ post_id: Number(postId) }], {
            onSuccess: () => {
                postQuery.refetch();
                Toasts.notify('Post unpinned.', { type: 'info' });
            },
        });
    };
    return {
        updatePost,
        mediaUploadProgress: videoUploadProgress || audioUploadProgress,
        pinPost,
        unpinPost,
        isLoading: updateTextPostMutation.isLoading ||
            updatePollPostMutation.isLoading ||
            updateMultipleImagePostMutation.isLoading ||
            isImageUpdateLoading ||
            isVideoUpdateLoading ||
            isVideoEmbedUpdateLoading ||
            isAudioUpdateLoading ||
            pinPostMutation.isLoading ||
            unpinPostMutation.isLoading ||
            postsQuery.isFetching,
    };
};
