import { getHasuraApiUrl } from '@fourthwall/env';
import { ApiBase } from '../ApiBase';
class HasuraApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getBillingReport = (shopId) => {
            return this.get(`/rest/reports/billing/${shopId}`);
        };
        this.getMailingListReport = (shopId) => {
            return this.get(`/rest/reports/mailing-list/${shopId}`);
        };
        this.getOrdersList = (shopId) => {
            return this.get(`/rest/reports/orders/${shopId}`);
        };
        this.getContributionsSubscriptions = (params) => this.post('/rest/admin/dashboard/contributions/subscriptions', params);
        this.getContributionsSubscription = (billingId) => this.get(`/rest/admin/dashboard/contributions/subscriptions/${billingId}`);
    }
}
export const HasuraApiClient = new HasuraApiClientModel(getHasuraApiUrl('api'));
