export const getPromotionTitle = (promotionData) => {
    if (promotionData) {
        return isPromotionSingle(promotionData) || isPromotionMembershipsSingle(promotionData)
            ? promotionData.code
            : promotionData.title;
    }
    return '';
};
export const isShopPromotion = (promotion) => promotion.type === 'SINGLE' || promotion.type === 'MULTI' || promotion.type === 'AUTO_APPLYING';
export const isPromotionSingle = (promotion) => promotion.type === 'SINGLE';
export const isPromotionMembershipsSingle = (promotion) => promotion.type === 'MEMBERSHIPS_SINGLE';
export const isMembershipsPromotion = (promotion) => promotion.type === 'MEMBERSHIPS_SINGLE' || promotion.type === 'MEMBERSHIPS_MULTI';
export const isPromotionMultiple = (promotion) => promotion.type === 'MULTI';
export const isPromotionAutoApplying = (promotion) => promotion.type === 'AUTO_APPLYING';
