import { ShopRendererApiClient } from '@fourthwall/services/lib/ShopRendererApiClient';
import { useMutation } from '@tanstack/react-query';
export const useUpdateTiktokIntegrationMutation = (options) => {
    const updateTiktokIntegrationMutation = useMutation({
        mutationFn: async (params) => {
            const { data } = await ShopRendererApiClient.updateTiktokIntegration(...params);
            return data;
        },
        ...options,
    });
    return {
        updateTiktokIntegrationMutation,
    };
};
