import React from 'react';
import { Button } from '../../common/Button';
import { ModalBox } from '../../partials/ModalBox';
export const ConfirmModal = ({ title, text, disabled, additionalContent, confirmLabel = 'Confirm', cancelLabel = 'Cancel', confirmAppearance = 'primary', size = 'small', maxWidth, confirmButtonAnalytics, close, onClose, onCancel, onConfirm, }) => {
    const handleCancelClick = () => {
        // NOTE: in order to allow opening different modals in onCancel we have to call close before it
        close();
        if (onCancel) {
            onCancel();
        }
    };
    const handleConfirmClick = () => {
        // NOTE: in order to allow opening different modals in onConfirm we have to call close before it
        close();
        if (onConfirm) {
            onConfirm();
        }
    };
    return (React.createElement(ModalBox, { title: title, size: size, maxWidth: maxWidth, buttons: [
            React.createElement(Button, { label: cancelLabel, appearance: "secondary", onClick: handleCancelClick }),
            React.createElement(Button, { label: confirmLabel, appearance: confirmAppearance, analytics: confirmButtonAnalytics, disabled: disabled, onClick: handleConfirmClick }),
        ], onClose: onClose || handleCancelClick },
        text,
        additionalContent));
};
