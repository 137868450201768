import flatten from 'lodash-es/flatten';
export const UPDATE_QUERY_PARAM = 'update';
export const isThemeUpdateAvailable = (currentVersion, availableVersion) => currentVersion !== availableVersion;
export const mapTheme = (theme, status, selectedThemeId) => ({
    id: theme.id || '',
    preset: theme.preset,
    handle: theme.themeLibrary?.handle || '',
    version: theme.themeLibrary?.version || '',
    name: theme.name || '',
    status,
    role: theme.role,
    tags: theme.themeLibrary?.version ? [`version ${theme.themeLibrary.version}`] : undefined,
    image: theme.screenshots?.desktopUrl,
    previewUrl: theme.previewUrl,
    updatedAt: theme.settingsUpdatedAt || theme.updatedAt,
    isMain: theme.role === 'main',
    isActive: theme.id === selectedThemeId,
    isProcessing: theme.processing || theme.status === 'Duplicating',
    isCustomized: theme.customisation === 'Fully',
});
export const getThemeList = (currentTheme, availableThemes, selectedThemeId) => {
    const getStatus = (theme) => {
        const status = theme.processing || theme.status === 'Duplicating' ? theme.status : undefined;
        if (!status)
            return undefined;
        return `${status}...`;
    };
    return [
        currentTheme ? mapTheme(currentTheme, undefined, selectedThemeId) : undefined,
        ...(availableThemes?.map((theme) => mapTheme(theme, getStatus(theme), selectedThemeId)) || []),
    ].filter(Boolean);
};
export const getPresetImages = (libraryThemes) => libraryThemes.reduce((imagesList, theme) => {
    const presetImages = theme.versions[0].presets
        ? theme.versions[0].presets.map((preset) => {
            return [preset.screenshots?.desktopUrl, preset.screenshots?.mobileUrl].filter((item) => !!item);
        })
        : [];
    return [...imagesList, ...flatten(presetImages)];
}, []);
