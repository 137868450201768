import { ApiBase } from './ApiBase';
class UserSecurityApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getUserSecurity = () => {
            return this.get('/user/security');
        };
        // getUserSecurityRedirect = (queryParams: GetUserSecurityRedirect['QueryParams']) => {
        //   return this.get<GetUserSecurityRedirect['Response']>('/user/security/redirect', queryParams)
        // }
        this.getUserSecurityRedirectUrl = (queryParams) => {
            // NOTE: Don't forget "/api" part in the URL.
            const url = new URL('/api/user/security/redirect', this.getUrl());
            url.searchParams.set('action', queryParams.action);
            if (queryParams.id)
                url.searchParams.set('id', queryParams.id);
            return url.toString();
        };
    }
}
export const UserSecurityApiClient = new UserSecurityApiClientModel();
