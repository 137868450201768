import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const MenuItem = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const MenuItemContent = styled.div `
  display: flex;
  align-items: center;
  flex: 1;
`;
export const MenuItemAvatarContainer = styled.div `
  margin-right: 8px;
`;
export const AvatarWrapper = styled.div `
  position: relative;
`;
export const MenuItemName = styled.div `
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${mediaQueries.laptop} {
    max-width: 120px;
  }
`;
export const AvatarLabel = styled.div `
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 4px;

  font-size: 8px;
  font-weight: 700;
  line-height: 150%; /* 12px */
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${Colors.GS0};

  background: ${Colors.GS1000};
  border: 3px solid ${({ borderColor }) => borderColor || Colors.GS200};
  border-radius: 100px;

  pointer-events: none;

  ${({ size }) => size === 'small' &&
    css `
      font-size: 6px;
      border-width: 2px;
      top: -7px;
    `}
`;
