import React, { useState } from 'react';
import { useDashboardPermissions } from '../../../../components/utils/DashboardPermissions';
import { createUseOrdersQueryParams, useOrdersQuery } from '../../../../hooks/useOrdersQuery';
import { SIZE } from './consts';
import { ProductOrdersView } from './ProductOrdersView';
export const ProductOrdersContainer = ({ offerId }) => {
    const { hasPermission } = useDashboardPermissions();
    const [currentPage, setCurrentPage] = useState(1);
    const { orders, ordersQuery } = useOrdersQuery(createUseOrdersQueryParams({ currentPage, size: SIZE, offerId }), { keepPreviousData: true, enabled: hasPermission('contributions.orders.view') });
    return (React.createElement(ProductOrdersView, { orders: orders?.orders, isLoading: ordersQuery.isFetching, totalPages: orders?.page.totalPages, currentPage: currentPage, onCurrentPageChange: setCurrentPage }));
};
