export const STATUSES = {
    // Order Statuses
    CONFIRMED: {
        label: 'Order placed',
        appearance: 'neutral',
    },
    COMPLETED: {
        label: 'Completed',
        appearance: 'success',
    },
    PARTIALLY_IN_PRODUCTION: {
        label: 'Partially in production',
        appearance: 'neutral',
    },
    IN_PRODUCTION: {
        label: 'In production',
        appearance: 'neutral',
    },
    PARTIALLY_SHIPPED: {
        label: 'Partially shipped',
        appearance: 'neutral',
    },
    SHIPPED: {
        label: 'Shipped',
        appearance: 'neutral',
    },
    PARTIALLY_DELIVERED: {
        label: 'Partially delivered',
        appearance: 'neutral',
    },
    DELIVERED: {
        label: 'Delivered',
        appearance: 'success',
    },
    CANCELLED: {
        label: 'Canceled',
        appearance: 'caution',
    },
    SCHEDULE: {
        label: 'Schedule',
        appearance: 'caution',
    },
    // ORDER - existing order statuses, but should never appear on frontend
    OPEN: {
        label: 'Awaiting payment',
        appearance: 'neutral',
    },
    ABANDONED: {
        label: 'Payment failed',
        appearance: 'neutral',
    },
    FAILED: {
        label: 'Failed',
        appearance: 'neutral',
    },
    // Product
    PUBLIC: {
        label: 'Public',
        appearance: 'success',
    },
    HIDDEN: {
        label: 'Hidden',
        appearance: 'caution',
    },
    ARCHIVED: {
        label: 'Archived',
        appearance: 'neutral',
    },
    // kudos
    WAITING: {
        label: 'Waiting',
        appearance: 'caution',
    },
    SENT: {
        label: 'Sent',
        appearance: 'success',
    },
};
