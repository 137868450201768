import { Avatar, ButtonClean, Dropdown, Icon } from '@fourthwall/components';
import { DotsHorizontalIcon, DragIcon } from '@fourthwall/icons';
import isUndefined from 'lodash-es/isUndefined';
import React, { cloneElement, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { QuantityBadge } from '@components/common/QuantityBadge';
import { HELPER_CLASS } from './consts';
import * as S from './styled';
const SelectedListElement = SortableElement(({ id, photoUrl, quantity, name, link, rightContent, showAvatar, isDragged, isDisabled, onPositionChange, onRemove, }) => {
    const [dropdownKey, setDropdownKey] = useState(0);
    const handleMouseLeave = () => setDropdownKey((key) => key + 1);
    const renderLeftContent = () => {
        if (showAvatar) {
            return (React.createElement(S.ImageContainer, null,
                React.createElement(Avatar, { id: id, name: name })));
        }
        return (React.createElement(S.ImageContainer, null,
            React.createElement(PhotoThumbnail, { url: photoUrl, size: "medium" }),
            quantity && React.createElement(QuantityBadge, { quantity: quantity })));
    };
    const renderRightContent = () => {
        if (rightContent) {
            return React.createElement(S.RightContent, null, rightContent);
        }
    };
    return (React.createElement(S.Element, { isDragged: isDragged, onMouseLeave: handleMouseLeave, "data-testid": "SelectedListElement" },
        !isDisabled && (React.createElement(S.DragHandle, { title: "Drag" },
            React.createElement(Icon, { component: DragIcon, height: 16 }))),
        renderLeftContent(),
        React.createElement(S.Name, null, link ? React.createElement(ButtonClean, { to: link, label: name, withUnderline: false }) : name),
        renderRightContent(),
        !isDisabled && (React.createElement(Dropdown, { key: dropdownKey, content: React.createElement(React.Fragment, null,
                React.createElement(Dropdown.Item, { label: "Change position", onClick: onPositionChange }),
                React.createElement(Dropdown.Item, { label: "Remove from collection", onClick: onRemove })), position: "bottom-end" },
            React.createElement(S.Handle, { title: "Options", "data-testid": "SelectedList.Options" },
                React.createElement(Icon, { component: DotsHorizontalIcon, height: 16 }))))));
});
const SelectedListItem = ({ index, id, photoUrl, quantity, showAvatar, name, link, rightContent, isDragged, disabled, onPositionChange, onRemove, }) => {
    if (isUndefined(index) || isUndefined(isDragged)) {
        return null;
    }
    const handleRemove = () => {
        if (onRemove) {
            onRemove(index);
        }
    };
    const handlePositionChange = () => {
        if (onPositionChange) {
            onPositionChange(index);
        }
    };
    return (React.createElement(S.Item, null,
        React.createElement(S.Placeholder, null),
        React.createElement(SelectedListElement, { index: index, disabled: disabled, id: id, photoUrl: photoUrl, quantity: quantity, showAvatar: showAvatar, name: name, link: link, rightContent: rightContent, isDragged: isDragged, isDisabled: disabled, onPositionChange: handlePositionChange, onRemove: handleRemove })));
};
const SelectedListContainer = SortableContainer(({ isDragged, disabled, children, onPositionChange, onRemove }) => {
    return (React.createElement(S.Container, null, React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
            return cloneElement(child, {
                index,
                isDragged,
                disabled,
                onPositionChange,
                onRemove,
            });
        }
    })));
});
export const SelectedList = ({ children, disabled, onSortChange, onPositionChange, onRemove, }) => {
    const [isDragged, setIsDragged] = useState(false);
    const handleSortStart = () => {
        setIsDragged(true);
    };
    const handleSortEnd = ({ oldIndex, newIndex }) => {
        setIsDragged(false);
        if (onSortChange) {
            onSortChange(oldIndex, newIndex);
        }
    };
    const cancelSortingOnLinkClick = (e) => {
        if ('tagName' in e.target) {
            return e.target.tagName.toLowerCase() === 'a';
        }
        return false;
    };
    return (React.createElement(SelectedListContainer, { isDragged: isDragged, disabled: disabled, helperClass: HELPER_CLASS, distance: 1, axis: "y", shouldCancelStart: cancelSortingOnLinkClick, onSortStart: handleSortStart, onSortEnd: handleSortEnd, onPositionChange: onPositionChange, onRemove: onRemove }, children));
};
SelectedList.Item = SelectedListItem;
