import { Button, HeadContent, List, Table, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import { NavTabs } from '@components/common/NavTabs';
import * as S from './styled';
import { TeamMemberListItem } from './components/TeamMemberListItem';
import { RoleListItem } from './components/RoleListItem';
export const TeamView = ({ tabsProps, teamTab, rolesTab }) => {
    const media = useMediaQueryContext();
    const renderButton = () => {
        if (tabsProps.activeTab === 'team' && teamTab.buttonProps) {
            return React.createElement(Button, { size: "small", ...teamTab.buttonProps });
        }
        return null;
    };
    const renderContent = () => {
        if (tabsProps.activeTab === 'team') {
            if (!media.tablet) {
                return (React.createElement(List, { renderItem: (data) => (React.createElement(TeamMemberListItem, { data: data, dropdownProps: teamTab.dropdownProps })), ...teamTab.listProps }));
            }
            return React.createElement(Table, { minWidth: 0, ...teamTab.tableProps });
        }
        if (tabsProps.activeTab === 'roles') {
            if (!media.tablet) {
                return (React.createElement(List, { renderItem: (role) => React.createElement(RoleListItem, { role: role }), ...rolesTab.listProps }));
            }
            return React.createElement(Table, { minWidth: 0, ...rolesTab.tableProps });
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Team \u2013 Settings" }),
        React.createElement(S.TabsContainer, null,
            React.createElement(NavTabs, { ...tabsProps }),
            renderButton()),
        renderContent()));
};
