import { getShopRendererApiUrl } from '@fourthwall/env';
import { ApiBase } from '../ApiBase';
class ShopRendererOfferApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getOffers = (pathParams, queryParams) => {
            return this.get(`/shops/${pathParams.shop_id}/offers`, queryParams);
        };
        this.getOffer = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/offers/${pathParams.offer_id}`);
        };
    }
}
export const ShopRendererOfferApiClient = new ShopRendererOfferApiClientModel(getShopRendererApiUrl('api'));
