import styled from 'styled-components';
export const RadioWrapper = styled.div `
  margin-top: 8px;
`;
export const Wrapper = styled.div `
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;
