import { getImageWelcomePostPayload } from './getImageWelcomePostPayload';
import { getTextWelcomePostPayload } from './getTextWelcomePostPayload';
import { getVideoWelcomePostPayload } from './getVideoWelcomePostPayload';
export const getWelcomePostPayload = async (welcomePost) => {
    switch (welcomePost.postType) {
        case 'Text':
            return getTextWelcomePostPayload(welcomePost);
        case 'Image':
            return await getImageWelcomePostPayload(welcomePost);
        case 'Video':
            return await getVideoWelcomePostPayload(welcomePost);
    }
};
