import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Text = styled.div `
  color: ${Colors.GS600};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
`;
