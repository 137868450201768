const getCartAbandonedType = (values) => {
    if (values.abandonedCheckoutEmailFrequency === 'light') {
        return 'LIGHT';
    }
    if (values.abandonedCheckoutEmailFrequency === 'recommended') {
        return 'RECOMMENDED';
    }
    return 'NONE';
};
export const getPayload = (values) => {
    return {
        mailPreferences: {
            cartAbandoned: {
                type: getCartAbandonedType(values),
            },
        },
    };
};
