export var FormFields;
(function (FormFields) {
    FormFields["annualDiscount"] = "annualDiscount";
    FormFields["title"] = "title";
    FormFields["description"] = "description";
    FormFields["price"] = "price";
    FormFields["discount"] = "discount";
    FormFields["monthDiscount"] = "monthDiscount";
    FormFields["yearDiscount"] = "yearDiscount";
    FormFields["timeLimit"] = "timeLimit";
    FormFields["startDate"] = "startDate";
    FormFields["endDate"] = "endDate";
    FormFields["image"] = "image";
    FormFields["imageFile"] = "imageFile";
    FormFields["removeImage"] = "removeImage";
})(FormFields || (FormFields = {}));
