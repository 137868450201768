var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import upperFirst from 'lodash/upperFirst';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
export const createQueryHook = (method, settings) => {
    return (params, options) => {
        const queryClient = useQueryClient();
        const queryKey = [...settings.queryKey, params];
        const queryName = queryKey[0];
        const queryOptions = Object.assign(Object.assign(Object.assign({}, settings), options), { queryKey });
        const queryFn = () => __awaiter(void 0, void 0, void 0, function* () {
            const { data } = yield method(...(params || []));
            return data;
        });
        const query = useQuery(Object.assign({ queryFn }, queryOptions));
        const invalidateQuery = () => __awaiter(void 0, void 0, void 0, function* () {
            yield queryClient.invalidateQueries({ queryKey });
        });
        const setQueryData = (data) => {
            queryClient.setQueryData(queryKey, data);
        };
        return {
            [`${queryName}Query`]: query,
            [`set${upperFirst(queryName)}Data`]: setQueryData,
            [`invalidate${upperFirst(queryName)}Query`]: invalidateQuery,
        };
    };
};
export const createMutationHook = (method, settings) => {
    return (options) => {
        const mutationFn = (params) => __awaiter(void 0, void 0, void 0, function* () {
            const { data } = yield method(...params);
            return data;
        });
        const mutationOptions = Object.assign(Object.assign({}, settings), options);
        const mutationName = mutationOptions.mutationKey[0];
        const mutation = useMutation(Object.assign({ mutationFn }, mutationOptions));
        return {
            [`${mutationName}Mutation`]: mutation,
        };
    };
};
