import { Checkbox } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { FilterDropdown } from '@components/common/FilterDropdown';
import * as S from './styled';
export const PostsFilterView = ({ types, initialValues, filterActive, onSubmit, onReset, }) => {
    const formik = useFormik({
        initialValues,
        onSubmit,
        enableReinitialize: true,
    });
    const handleReset = () => {
        formik.resetForm();
        onReset();
    };
    const handleCheckboxGroupChange = (checked, value, name) => {
        const fieldValue = checked
            ? [...formik.values[name], value]
            : formik.values[name].filter((v) => v !== value);
        formik.setFieldValue(name, fieldValue);
    };
    const renderTypes = () => !!types?.length && (React.createElement(S.FiltersGroup, null,
        React.createElement(FilterDropdown.GroupTitle, { title: "Post type" }),
        React.createElement(S.CheckboxGroup, { role: "group" }, types.map((option) => (React.createElement(Checkbox, { native: true, key: option.value, label: option.label, value: option.value, checked: formik.values.types.includes(option.value), onValueChange: (checked) => handleCheckboxGroupChange(checked, option.value, 'types') }))))));
    return (React.createElement(FilterDropdown, { content: React.createElement("form", null, renderTypes()), activityIndicator: filterActive, onSubmit: formik.handleSubmit, onReset: handleReset }));
};
