import React from 'react';
import { BaseContent, ToolbarButton, Dropdown } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import * as S from './styled';
export const CredentialList = ({ items, onDelete }) => (React.createElement(S.Container, null,
    React.createElement("ul", null, items.map((item) => (React.createElement(S.Item, { key: item.id },
        React.createElement("div", null,
            React.createElement(BaseContent, null, item.name),
            React.createElement(BaseContent, { appearance: "secondary", size: "small" }, item.description)),
        onDelete && (React.createElement("div", null,
            React.createElement(Dropdown, { content: React.createElement(Dropdown.Item, { label: "Delete", appearance: "destructive", onClick: () => onDelete(item.id) }) },
                React.createElement(ToolbarButton, { icon: DotsHorizontalIcon, size: "small" }))))))))));
