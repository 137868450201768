import React from 'react';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { EntityTableCell, useMediaQueryContext } from '@fourthwall/components';
import * as S from './styled';
export const GiveawayPackageListItem = ({ giveawayPackage }) => {
    const media = useMediaQueryContext();
    return (React.createElement(S.Container, null,
        React.createElement(EntityTableCell, { top: giveawayPackage.items[0]?.name, image: React.createElement(PhotoThumbnail, { url: giveawayPackage.items[0]?.image, size: media.tablet ? 'large' : 'extraLarge' }), bottom: React.createElement("div", null,
                giveawayPackage.stats.usage.redeemed,
                "/",
                giveawayPackage.stats.usage.all,
                " used") })));
};
