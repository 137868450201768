import { PriceString, Timeline } from '@fourthwall/components';
import isEmpty from 'lodash-es/isEmpty';
import map from 'lodash-es/map';
import React from 'react';
import { getVariants } from '../../../utils/variants';
import { ProductsList, ProductsListSize } from '../ProductsList';
import * as S from './OrderPopover.styled';
import { Padding } from './types';
const renderProduct = ({ quantity, offer: { name }, variant }) => (React.createElement(ProductsList.Item, { key: variant.id, image: variant.images[0]?.url, name: name, variants: getVariants(variant), quantity: quantity }));
const OrderPopover = ({ title, products, donation, events, padding = Padding.Medium, }) => {
    const renderDonation = () => {
        if (donation) {
            return (React.createElement(ProductsList.Item, { name: React.createElement(React.Fragment, null,
                    React.createElement(PriceString, { value: donation }),
                    " Donation"), donation: true }));
        }
        return null;
    };
    const renderProducts = () => {
        if (products) {
            return (React.createElement(ProductsList, { size: ProductsListSize.Small },
                map(products, renderProduct),
                renderDonation()));
        }
        return null;
    };
    const renderTimeline = () => {
        if (!events) {
            return null;
        }
        if (isEmpty(events)) {
            return React.createElement(S.EmptyNotice, null, "There are no events yet to display for this order.");
        }
        return (React.createElement(S.TimelineContainer, null,
            React.createElement(Timeline, { events: events })));
    };
    return (React.createElement(S.Container, { padding: padding },
        title && React.createElement(S.Title, null, title),
        renderProducts(),
        renderTimeline()));
};
const EnhancedOrderPopover = React.memo(OrderPopover);
export { EnhancedOrderPopover as OrderPopover };
