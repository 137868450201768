import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const PerksContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const Placeholder = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.GS200};
`;
export const PerkContainer = styled.div `
  position: relative;
  cursor: grab;
`;
