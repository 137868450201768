import { Button } from '@fourthwall/components';
import capitalize from 'lodash-es/capitalize';
import sortBy from 'lodash-es/sortBy';
import React from 'react';
import { typeOrder } from '../ProductVariantType/consts';
import * as S from './AddButtons.styled';
export const AddButtons = ({ allValues, setValues }) => {
    if (allValues.length >= 3)
        return null;
    const getSortedTypes = (variantTypes) => {
        return sortBy(variantTypes, (variantType) => typeOrder.indexOf(variantType.type));
    };
    const handleButtonClick = (type) => {
        const newVariantType = {
            type,
            title: capitalize(type),
            options: [
                {
                    name: '',
                    images: [],
                    colorSwatch: type === 'COLOR' ? '#000000' : undefined,
                    weight: undefined,
                },
            ],
            variesBy: { price: false, imagery: false, weight: false },
        };
        setValues({ types: getSortedTypes([...allValues, newVariantType]) });
    };
    return (React.createElement(S.Container, null,
        !allValues.some((variantType) => variantType.type === 'COLOR') && (React.createElement(Button, { label: "Add different colors", appearance: "secondary", onClick: () => handleButtonClick('COLOR') })),
        !allValues.some((variantType) => variantType.type === 'SIZE') && (React.createElement(Button, { label: "Add different sizes", appearance: "secondary", onClick: () => handleButtonClick('SIZE') })),
        !allValues.some((variantType) => variantType.type === 'CUSTOM') && (React.createElement(Button, { label: "Add custom variants", appearance: "secondary", onClick: () => handleButtonClick('CUSTOM') }))));
};
