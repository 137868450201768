export const CATEGORIES = [
    'social_commerce',
    'alerts',
    'memberships',
    'social_feeds',
    'email_marketing',
    'shipping',
];
export const CATEGORY_LABELS = {
    social_commerce: 'Social commerce',
    alerts: 'Alerts',
    memberships: 'Memberships',
    social_feeds: 'Social feeds',
    email_marketing: 'Email marketing',
    shipping: 'Shipping',
};
