import { Colors } from '@fourthwall/components';
import styled, { css, keyframes } from 'styled-components';
export const DropdownButton = styled.button `
  width: 32px;
  height: 32px;

  display: none;
  align-items: center;
  justify-content: center;

  ${({ isOpen }) => isOpen &&
    css `
      display: flex;
    `};

  color: ${Colors.GS1000};
  background: ${Colors.GS0};

  border: 1px solid ${Colors.GS400};
  border-radius: 2px;

  &:hover,
  &:focus {
    background: ${Colors.GS200};
  }
`;
export const Card = styled.div `
  padding: 10px;
  cursor: pointer;
`;
export const DetailsContainer = styled.div `
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
`;
export const Container = styled.div `
  position: relative;
  background-color: ${Colors.GS200};
  border-radius: 4px;
  border: 2px solid ${Colors.GS200};

  &:hover {
    background-color: ${Colors.GS300};
  }

  ${({ isActive }) => isActive &&
    css `
      background-color: ${Colors.B100};
      border: 2px solid ${Colors.B500};

      &:hover {
        background-color: ${Colors.B100};
      }
    `};

  &:hover ${DropdownButton} {
    display: flex;
  }
`;
export const LastModified = styled.div `
  color: ${Colors.GS600};
  font-size: 12px;
`;
export const ThemeName = styled.div `
  margin-top: 6px;
  font-weight: 600;
  color: ${Colors.GS1000};
`;
const smoothOpacity = keyframes `
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.7;
  }
`;
export const ImageContainer = styled.button `
  position: relative;

  ${({ isTransparent }) => isTransparent &&
    css `
      animation: ${smoothOpacity} 3s ease infinite;
    `};

  canvas,
  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 2px;
  }
`;
export const TagsContainer = styled.div `
  position: absolute;
  bottom: 16px;
  right: 16px;
`;
export const OptionsContainer = styled.div `
  position: absolute;
  top: 20px;
  right: 20px;
`;
export const Status = styled.div `
  position: absolute;

  top: 80px;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 100px;

  padding: 8px 20px;

  background-color: ${Colors.GS1000};
  font-weight: 600;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: ${Colors.GS100};
`;
export const DropdownItemLabel = styled.div `
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const BadgesContainer = styled.div `
  display: grid;
  gap: 2px;
`;
