import { QueryClient, QueryCache } from '@tanstack/react-query';
import { retry, createOnError, createQueryCacheOnError } from './utils';
export const createQueryClient = ({ notify }) => {
    return new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry,
            },
            mutations: {
                onError: createOnError(notify),
            },
        },
        queryCache: new QueryCache({
            onError: createQueryCacheOnError(notify),
        }),
    });
};
