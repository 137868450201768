import styled from 'styled-components';
export const CopyContainer = styled.div `
  margin-top: 4px;
  margin-bottom: 16px;
`;
export const WeightInputContainer = styled.div `
  display: flex;
  width: 232px;
`;
export const DayRangeContainer = styled.div ``;
export const DateRangeContainer = styled.div `
  position: relative;
  z-index: 2;
`;
export const InnerField = styled.div `
  margin-left: 36px;
`;
export const ShippingSubtitle = styled.div `
  & > * + * {
    margin-left: 8px;
  }
`;
