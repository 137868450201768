import { useQueryClient } from '@tanstack/react-query';
import reduce from 'lodash-es/reduce';
import union from 'lodash-es/union';
import { FREE_TIER_ID } from '../../consts';
import { useCreateCustomPerkMutation } from '../../hooks/useCreateCustomPerkMutation';
import { useCreateDiscordPerkMutation } from '../../hooks/useCreateDiscordPerkMutation';
import { useCreateDiscountPerkMutation } from '../../hooks/useCreateDiscountPerkMutation';
import { useCreateProductPerkMutation } from '../../hooks/useCreateProductPerkMutation';
import { useCreateRssPerkMutation } from '../../hooks/useCreateRssPerkMutation';
import { useDeleteCustomPerkMutation } from '../../hooks/useDeleteCustomPerkMutation';
import { useDeleteDiscordPerkMutation } from '../../hooks/useDeleteDiscordPerkMutation';
import { useDeleteDiscountPerkMutation } from '../../hooks/useDeleteDiscountPerkMutation';
import { useDeleteProductPerkMutation } from '../../hooks/useDeleteProductPerkMutation';
import { useDeleteRssPerkMutation } from '../../hooks/useDeleteRssPerkMutation';
import { useTiers } from '../../hooks/useTiers';
import { useUpdateCustomPerkMutation } from '../../hooks/useUpdateCustomPerkMutation';
import { useUpdateDiscordPerkMutation } from '../../hooks/useUpdateDiscordPerkMutation';
import { useUpdateDiscountPerkMutation } from '../../hooks/useUpdateDiscountPerkMutation';
import { useUpdateProductPerkMutation } from '../../hooks/useUpdateProductPerkMutation';
import { useUpdateRssPerkMutation } from '../../hooks/useUpdateRssPerkMutation';
import { validationSchema } from '../consts';
import { RssType } from '../types';
import { getCommonPerkPayload, getCreateDiscordPerkPayload, getCreateRssPerkPayload, getCustomPerkPayload, getUpdateDiscordPerkPayload, getUpdateRssPerkPayload, } from '../utils';
export const usePerkModal = ({ perk, selectedType, selectedTiers, close }) => {
    const { tiers } = useTiers();
    const queryClient = useQueryClient();
    const { createCustomPerkMutation } = useCreateCustomPerkMutation();
    const { updateCustomPerkMutation } = useUpdateCustomPerkMutation();
    const { deleteCustomPerkMutation } = useDeleteCustomPerkMutation();
    const { createDiscordPerkMutation } = useCreateDiscordPerkMutation();
    const { updateDiscordPerkMutation } = useUpdateDiscordPerkMutation();
    const { deleteDiscordPerkMutation } = useDeleteDiscordPerkMutation();
    const { createRssPerkMutation } = useCreateRssPerkMutation();
    const { updateRssPerkMutation } = useUpdateRssPerkMutation();
    const { deleteRssPerkMutation } = useDeleteRssPerkMutation();
    const { createProductPerkMutation } = useCreateProductPerkMutation();
    const { updateProductPerkMutation } = useUpdateProductPerkMutation();
    const { deleteProductPerkMutation } = useDeleteProductPerkMutation();
    const { createDiscountPerkMutation } = useCreateDiscountPerkMutation();
    const { updateDiscountPerkMutation } = useUpdateDiscountPerkMutation();
    const { deleteDiscountPerkMutation } = useDeleteDiscountPerkMutation();
    const isLoading = createCustomPerkMutation.isLoading ||
        updateCustomPerkMutation.isLoading ||
        deleteCustomPerkMutation.isLoading ||
        createDiscordPerkMutation.isLoading ||
        updateDiscordPerkMutation.isLoading ||
        deleteDiscordPerkMutation.isLoading ||
        createRssPerkMutation.isLoading ||
        updateRssPerkMutation.isLoading ||
        deleteRssPerkMutation.isLoading ||
        createDiscountPerkMutation.isLoading ||
        updateDiscountPerkMutation.isLoading ||
        deleteDiscountPerkMutation.isLoading ||
        createProductPerkMutation.isLoading ||
        updateProductPerkMutation.isLoading ||
        deleteProductPerkMutation.isLoading;
    const getSelectedTiers = () => {
        if (selectedTiers)
            return selectedTiers;
        if (perk) {
            const tiers = {};
            perk.tiers.forEach((selectedTier) => {
                if (selectedTier.type === 'Custom' && selectedTier.tierType === 'free') {
                    tiers[FREE_TIER_ID] = true;
                }
                else {
                    tiers[selectedTier.id] = true;
                }
            });
            return tiers;
        }
        return tiers?.reduce((selectedTiers, tier) => {
            if (selectedTiers) {
                selectedTiers[tier.id] = false;
            }
            return selectedTiers;
        }, {});
    };
    const getSelectedRoles = () => {
        if (perk?.tiers) {
            const selectedRoles = {};
            perk.tiers.forEach((tier) => {
                selectedRoles[tier.id] = tier.roles?.map((role) => role.id) || [];
            });
            tiers?.forEach((tier) => {
                if (!selectedRoles[tier.id])
                    selectedRoles[tier.id] = [];
            });
            return selectedRoles;
        }
        return (tiers?.reduce((selectedTiers, tier) => {
            selectedTiers[tier.id] = [];
            return selectedTiers;
        }, {}) || {});
    };
    const getRssTags = () => {
        if (perk && 'tags' in perk && perk.tags) {
            return perk.tags;
        }
        return [];
    };
    const initialValues = {
        mode: perk ? 'edit' : 'create',
        type: selectedType || perk?.type || 'Custom',
        title: perk?.title || '',
        description: perk && 'description' in perk ? perk.description : '',
        image: undefined,
        rssType: perk && 'tags' in perk ? RssType.selected : RssType.all,
        rssTags: getRssTags(),
        hidden: perk && 'hiddenForTiers' in perk ? perk.hiddenForTiers : false,
        selectedTiers: getSelectedTiers(),
        selectedRoles: getSelectedRoles(),
        removeImage: false,
    };
    const handleSubmit = (values) => {
        if (!perk) {
            if (values.type === 'Custom') {
                return createCustomPerkMutation.mutate([getCustomPerkPayload(values)], {
                    onSuccess: () => handleSuccess(values),
                });
            }
            if (values.type === 'Discord') {
                return createDiscordPerkMutation.mutate([getCreateDiscordPerkPayload(values)], {
                    onSuccess: () => handleSuccess(values),
                });
            }
            if (values.type === 'Rss') {
                return createRssPerkMutation.mutate([getCreateRssPerkPayload(values)], {
                    onSuccess: () => handleSuccess(values),
                });
            }
            if (values.type === 'Discount') {
                return createDiscountPerkMutation.mutate([getCommonPerkPayload(values)], {
                    onSuccess: () => handleSuccess(values),
                });
            }
            if (values.type === 'Product') {
                return createProductPerkMutation.mutate([getCommonPerkPayload(values)], {
                    onSuccess: () => handleSuccess(values),
                });
            }
        }
        if (perk) {
            if (values.type === 'Custom') {
                return updateCustomPerkMutation.mutate([perk.id, { ...getCustomPerkPayload(values) }], {
                    onSuccess: () => handleSuccess(values),
                });
            }
            if (values.type === 'Discord') {
                return updateDiscordPerkMutation.mutate([perk.id, { ...getUpdateDiscordPerkPayload(values) }], {
                    onSuccess: () => handleSuccess(values),
                });
            }
            if (values.type === 'Rss') {
                return updateRssPerkMutation.mutate([perk.id, { ...getUpdateRssPerkPayload(values) }], {
                    onSuccess: () => handleSuccess(values),
                });
            }
            if (values.type === 'Discount') {
                return updateDiscountPerkMutation.mutate([perk.id, { ...getCommonPerkPayload(values) }], {
                    onSuccess: () => handleSuccess(values),
                });
            }
            if (values.type === 'Product') {
                return updateProductPerkMutation.mutate([perk.id, { ...getCommonPerkPayload(values) }], {
                    onSuccess: () => handleSuccess(values),
                });
            }
        }
    };
    const handleDeleteClick = (values) => {
        if (!perk)
            return;
        if (values.type === 'Custom') {
            deleteCustomPerkMutation.mutate([perk.id], {
                onSuccess: () => handleSuccess(values),
            });
        }
        if (values.type === 'Rss') {
            deleteRssPerkMutation.mutate([perk.id], {
                onSuccess: () => handleSuccess(values),
            });
        }
        if (values.type === 'Discount') {
            deleteDiscountPerkMutation.mutate([perk.id], {
                onSuccess: () => handleSuccess(values),
            });
        }
        if (values.type === 'Discord') {
            deleteDiscordPerkMutation.mutate([perk.id], {
                onSuccess: () => handleSuccess(values),
            });
        }
        if (values.type === 'Product') {
            deleteProductPerkMutation.mutate([perk.id], {
                onSuccess: () => handleSuccess(values),
            });
        }
    };
    const handleSuccess = (values) => {
        const perksIds = [];
        const tierIds = reduce(values.selectedTiers, (sum, enabled, id) => {
            if (enabled)
                sum.push(parseInt(id));
            return sum;
        }, []);
        if (perk) {
            perk.tiers.forEach((tier) => {
                perksIds.push(tier.id);
            });
        }
        union(tierIds, perksIds).forEach((id) => {
            queryClient.refetchQueries([
                'tierPerks',
                [{ tier: { id, type: id === FREE_TIER_ID ? 'free' : 'paid' } }],
            ]);
        });
        close();
    };
    return {
        initialValues,
        validationSchema,
        isLoading,
        handleDeleteClick,
        handleSubmit,
    };
};
