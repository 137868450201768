import { getPrice } from '@fourthwall/utils/lib';
import React from 'react';
import { Accordion } from '../../../../../../common/Accordion';
import { PriceString } from '../../../../../../functional/PriceString';
import { Input } from '../../../../../../inputs/Input';
import { InputUI } from '../../../../../../ui';
import { AccordionPanel } from '../AccordionPanel';
import * as S from './styled';
export const AccordionCustomItem = ({ autoAdjustedSellingPrice, data, expanded, includeFeesValue, soldValue, soldValueError, onChange, onSoldValueChange, ...props }) => {
    const isSoldValueValid = !!soldValue.length && !soldValueError;
    return (React.createElement(S.AccordionCustomItem, { paddingBottom: soldValueError ? 28 : 0 },
        React.createElement(Accordion.Item, { disabled: !isSoldValueValid, expanded: isSoldValueValid && expanded, onChange: onChange, heading: React.createElement(S.Heading, null,
                React.createElement(S.CustomSoldContainer, null,
                    React.createElement(S.QuantityInputContainer, null,
                        React.createElement(Input, { type: "number", size: "small", placeholder: "Custom", step: 1, min: 0, max: 99999999, hideSpinner: true, value: soldValue, error: soldValueError, displayError: false, onChange: (event) => onSoldValueChange(event.target.value) }),
                        React.createElement(S.QuantityInputError, null, soldValueError && React.createElement(InputUI.Error, null, soldValueError))),
                    React.createElement("span", null, "sold")),
                !expanded && isSoldValueValid && !!data.cost && (React.createElement("div", null,
                    React.createElement(PriceString, { value: data.unitProfit }),
                    " profit"))), footer: isSoldValueValid &&
                !!data.cost && (React.createElement(S.TotalProfitContainer, null,
                React.createElement(S.TotalProfitCopy, { top: expanded ? 0 : -8 }, `${getPrice(data.totalProfit)} for ${data.numberOfUnits} sold`))), ...props },
            React.createElement(AccordionPanel, { price: data.price, cost: data.cost, profit: data.unitProfit, feesValue: data.feesValue, includeFeesValue: includeFeesValue, autoAdjustedSellingPrice: autoAdjustedSellingPrice }))));
};
