import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '@fourthwall/components';
import { DragIcon } from '@fourthwall/icons';
import React from 'react';
import { usePerksContext } from '../../hooks/usePerksContext';
import * as S from './styled';
export const Perk = ({ perk }) => {
    const { editPerk } = usePerksContext();
    const { isDragging, setNodeRef, transform, attributes, listeners } = useSortable({
        id: perk.id,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? 999 : undefined,
    };
    return (React.createElement(S.Container, { ref: setNodeRef, style: style, onClick: () => editPerk(perk) },
        React.createElement(S.Title, { "data-testid": "Perks.title" }, perk.title),
        React.createElement(S.DragHandle, { ...listeners, ...attributes },
            React.createElement(Icon, { component: DragIcon, height: 16 }))));
};
