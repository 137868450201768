import { AgencyApiClient } from '@fourthwall/services/lib/AgencyApiClient';
import { AnalyticsApiClient } from '@fourthwall/services/lib/AnalyticsApiClient';
import { BespokeProductsApiClient } from '@fourthwall/services/lib/BespokeProductsApiClient';
import { BillingApiClient } from '@fourthwall/services/lib/BillingApiClient';
import { CollectionsApiClient } from '@fourthwall/services/lib/CollectionsApiClient';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { CreatorIntegrationsV2ApiClient } from '@fourthwall/services/lib/CreatorIntegrationsV2ApiClient';
import { CreatorMessagesApiClient } from '@fourthwall/services/lib/CreatorMessagesApiClient';
import { CustomizationApiClient } from '@fourthwall/services/lib/CustomizationApiClient';
import { CustomizationV4ApiClient } from '@fourthwall/services/lib/CustomizationV4ApiClient';
import { DashboardApiClient } from '@fourthwall/services/lib/DashboardApiClient';
import { DashboardV2ApiClient } from '@fourthwall/services/lib/DashboardV2ApiClient';
import { DnsApiClient } from '@fourthwall/services/lib/DnsApiClient';
import { DonationApiClient } from '@fourthwall/services/lib/DonationApiClient';
import { DropsApiClient } from '@fourthwall/services/lib/DropsApiClient';
import { FulfillmentsApiClient } from '@fourthwall/services/lib/FulfillmentsApiClient';
import { GiftsApiClient } from '@fourthwall/services/lib/GiftsApiClient';
import { GiveawayConfigApiClient } from '@fourthwall/services/lib/GiveawayConfigApiClient';
import { GiveawayContributionApiClient } from '@fourthwall/services/lib/GiveawayContributionApiClient';
import { GoogleAnalyticsConfigurationApiClient } from '@fourthwall/services/lib/GoogleAnalyticsConfigurationApiClient';
import { HarmonizedCodeApiClient } from '@fourthwall/services/lib/HarmonizedCodeApiClient';
import { HasuraApiClient } from '@fourthwall/services/lib/HasuraApiClient';
import { InternalApiClient } from '@fourthwall/services/lib/InternalApiClient';
import { KudosApiClient } from '@fourthwall/services/lib/KudosApiClient';
import { MembershipsApiClient } from '@fourthwall/services/lib/MembershipsApiClient';
import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { MembershipsCreatorV2ApiClient } from '@fourthwall/services/lib/MembershipsCreatorV2ApiClient';
import { MembershipsDirectMessagesApiClient } from '@fourthwall/services/lib/MembershipsDirectMessagesApiClient';
import { MetaPartnerApiClient } from '@fourthwall/services/lib/MetaPartnerApiClient';
import { MetaPartnerProductsApiClient } from '@fourthwall/services/lib/MetaPartnerProductsApiClient';
import { NotificationPreferencesApiClient } from '@fourthwall/services/lib/NotificationPreferencesApiClient';
import { NotificationShopPreferencesApiClient } from '@fourthwall/services/lib/NotificationShopPreferencesApiClient';
import { OffersApiClient } from '@fourthwall/services/lib/OffersApiClient';
import { OffersV2ApiClient } from '@fourthwall/services/lib/OffersV2ApiClient';
import { OnboardingApiClient } from '@fourthwall/services/lib/OnboardingApiClient';
import { OpenAPIApiClient } from '@fourthwall/services/lib/OpenAPIApiClient';
import { OrderAnalyticsApiClient } from '@fourthwall/services/lib/OrderAnalyticsApiClient';
import { OrderApiClient } from '@fourthwall/services/lib/OrderApiClient';
import { PaymentApiClient } from '@fourthwall/services/lib/PaymentApiClient';
import { PaymentShopBlockedTransactionsApiClient } from '@fourthwall/services/lib/PaymentShopBlockedTransactionsApiClient';
import { PayoutApiClient } from '@fourthwall/services/lib/PayoutApiClient';
import { ProductCatalogApiClient } from '@fourthwall/services/lib/ProductCatalogApiClient';
import { PromotionsApiClient } from '@fourthwall/services/lib/PromotionsApiClient';
import { SamplesBudgetApiClient } from '@fourthwall/services/lib/SamplesBudgetApiClient';
import { ShippingApiClient } from '@fourthwall/services/lib/ShippingApiClient';
import { ShippingProfilesApiClient } from '@fourthwall/services/lib/ShippingProfilesApiClient';
import { ShipstationIntegrationApiClient } from '@fourthwall/services/lib/ShipstationIntegrationApiClient';
import { ShopRendererApiClient } from '@fourthwall/services/lib/ShopRendererApiClient';
import { ShopRendererBlocksApiClient } from '@fourthwall/services/lib/ShopRendererBlocksApiClient';
import { ShopRendererMembershipsApiClient } from '@fourthwall/services/lib/ShopRendererMembershipsApiClient';
import { ShopRendererOfferApiClient } from '@fourthwall/services/lib/ShopRendererOfferApiClient';
import { ShopRendererThemeSettingsApiClient } from '@fourthwall/services/lib/ShopRendererThemeSettingsApiClient';
import { ShopRendererThemesApiClient } from '@fourthwall/services/lib/ShopRendererThemesApiClient';
import { ShopUsersApiClient } from '@fourthwall/services/lib/ShopUsersApiClient';
import { ShopsApiClient } from '@fourthwall/services/lib/ShopsApiClient';
import { StockApiClient } from '@fourthwall/services/lib/StockApiClient';
import { ThemeEditorApiClient } from '@fourthwall/services/lib/ThemeEditorApiClient';
import { ThirdPartyApiClient } from '@fourthwall/services/lib/ThirdPartyApiClient';
import { ThirdPartyLogisticsApiClient } from '@fourthwall/services/lib/ThirdPartyLogisticsApiClient';
import { UserApiClient } from '@fourthwall/services/lib/UserApiClient';
import { UserSecurityApiClient } from '@fourthwall/services/lib/UserSecurityApiClient';
import { WebhooksApiClient } from '@fourthwall/services/lib/WebhooksApiClient';
import { YouTubeMerchantCenterApiClient } from '@fourthwall/services/lib/YouTubeMerchantCenterApiClient';
export const API_CLIENTS = [
    AgencyApiClient,
    AnalyticsApiClient,
    BespokeProductsApiClient,
    BillingApiClient,
    CollectionsApiClient,
    CreatorIntegrationsApiClient,
    CreatorIntegrationsV2ApiClient,
    CreatorMessagesApiClient,
    CustomizationApiClient,
    CustomizationV4ApiClient,
    DashboardApiClient,
    DashboardV2ApiClient,
    DnsApiClient,
    DonationApiClient,
    DropsApiClient,
    FulfillmentsApiClient,
    GiftsApiClient,
    GiveawayConfigApiClient,
    GiveawayContributionApiClient,
    GoogleAnalyticsConfigurationApiClient,
    HarmonizedCodeApiClient,
    HasuraApiClient,
    InternalApiClient,
    KudosApiClient,
    MembershipsApiClient,
    MembershipsCreatorApiClient,
    MembershipsCreatorV2ApiClient,
    MembershipsDirectMessagesApiClient,
    MetaPartnerApiClient,
    MetaPartnerProductsApiClient,
    NotificationPreferencesApiClient,
    NotificationShopPreferencesApiClient,
    OffersApiClient,
    OffersV2ApiClient,
    OnboardingApiClient,
    OpenAPIApiClient,
    OrderAnalyticsApiClient,
    OrderApiClient,
    PaymentApiClient,
    PaymentShopBlockedTransactionsApiClient,
    PayoutApiClient,
    ProductCatalogApiClient,
    PromotionsApiClient,
    SamplesBudgetApiClient,
    ShippingApiClient,
    ShippingProfilesApiClient,
    ShipstationIntegrationApiClient,
    ShopRendererApiClient,
    ShopRendererBlocksApiClient,
    ShopRendererMembershipsApiClient,
    ShopRendererOfferApiClient,
    ShopRendererThemeSettingsApiClient,
    ShopRendererThemesApiClient,
    ShopUsersApiClient,
    ShopsApiClient,
    StockApiClient,
    ThemeEditorApiClient,
    ThirdPartyApiClient,
    ThirdPartyLogisticsApiClient,
    UserApiClient,
    UserSecurityApiClient,
    WebhooksApiClient,
    YouTubeMerchantCenterApiClient,
];
