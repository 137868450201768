import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { useQuery } from '@tanstack/react-query';
export const useMembersDataQuery = () => {
    const membersDataQuery = useQuery({
        queryKey: ['useMembersDataQuery'],
        queryFn: async () => {
            const { data } = await MembershipsCreatorApiClient.getMembersData();
            return data;
        },
        enabled: false,
    });
    return {
        membersDataQuery,
    };
};
