import isEmpty from 'lodash-es/isEmpty';
import map from 'lodash-es/map';
import React, { Fragment } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Loading } from '../../Loading';
import { Loader } from '../../Loader';
import { DRAG_HELPER_CLASS } from '../consts';
import { TableCell } from '../TableCell/TableCell';
import { TableRow } from '../TableRow/TableRow';
import * as S from './styled';
import { TableBodyWrapper } from './TableBodyWrapper';
const Row = SortableElement(({ row, rowIndex, headers, actions, sortable, highlighted, compact, rowComponent, href, target, renderFooter, getRowLink, onClick, }) => {
    const renderCells = () => {
        return map(headers, (header) => (React.createElement(TableCell, { key: header.id, width: header.width, minWidth: header.minWidth, height: header.height, minHeight: header.minHeight, alignment: header.alignment, alignItems: header.alignItems, compact: compact }, header.render(row, rowIndex))));
    };
    return (React.createElement(Fragment, { key: row.id },
        React.createElement(TableRow, { component: rowComponent, row: row, to: getRowLink && getRowLink(row), href: href, target: target, withDragHandle: sortable, actions: actions, highlighted: highlighted, renderFooter: renderFooter, onClick: onClick }, renderCells())));
});
const RowGroup = SortableContainer(({ data, headers, actions, sortable, compact, rowComponent, rowTarget, renderFooter, getRowLink, getRowHref, getRowHighlighted, onRowClick, }) => (React.createElement(S.RowGroup, { role: "rowgroup" }, map(data, (row, index) => (React.createElement(Fragment, { key: row.id },
    React.createElement(S.Placeholder, null),
    React.createElement(Row, { row: row, rowIndex: index, index: index, headers: headers, actions: actions, sortable: sortable, compact: compact, rowComponent: rowComponent, href: getRowHref ? getRowHref(row) : undefined, target: rowTarget, disabled: !sortable, highlighted: getRowHighlighted ? getRowHighlighted(row) : false, renderFooter: renderFooter, getRowLink: getRowLink, onClick: onRowClick ? () => onRowClick(row) : undefined })))))));
export const TableBody = ({ data, headers, actions, sortable, compact, maxHeight, rowComponent, rowTarget, isLoading, emptyMessage, emptyComponent, renderFooter, getRowLink, getRowHref, getRowHighlighted, onRowClick, onPositionChange, }) => {
    const handleSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex && onPositionChange) {
            onPositionChange(oldIndex, newIndex);
        }
    };
    if (isEmpty(data)) {
        if (isLoading) {
            return (React.createElement(S.EmptyComponent, null,
                React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                    React.createElement(Loader, { size: "large" }))));
        }
        if (emptyComponent) {
            return React.createElement(S.EmptyComponent, null, emptyComponent);
        }
        if (emptyMessage) {
            return React.createElement(S.EmptyMessage, null, emptyMessage);
        }
        return null;
    }
    return (React.createElement(TableBodyWrapper, { maxHeight: maxHeight },
        React.createElement(Loading, { isLoading: isLoading },
            React.createElement(RowGroup, { data: data, headers: headers, actions: actions, sortable: sortable, compact: compact, rowComponent: rowComponent, rowTarget: rowTarget, getRowLink: getRowLink, getRowHref: getRowHref, getRowHighlighted: getRowHighlighted, helperClass: DRAG_HELPER_CLASS, axis: "y", useDragHandle: true, renderFooter: renderFooter, onRowClick: onRowClick, onSortEnd: handleSortEnd }))));
};
