import { Colors } from '@fourthwall/components';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';
import { formatExpiresAt } from '../../utils';
import { PAYPAL_SCRIPT_ID } from './consts';
export const getCardStyle = () => ({
    base: {
        fontSize: '16px',
        color: Colors.GS1000,
        lineHeight: '24px',
        fontSmoothing: 'antialiased',
        fontFamily: '"Suisse Int\'l", sans-serif',
        backgroundColor: 'transparent',
    },
    invalid: {
        color: Colors.GS1000,
    },
});
export const filterOutExistingPaymentMethods = (userPaymentMethods, shopPaymentMethods) => userPaymentMethods?.filter((userPaymentMethod) => !shopPaymentMethods?.result.some((shopPaymentMethod) => shopPaymentMethod.customerPaymentMethodId === userPaymentMethod.id));
export const mapUserPaymentMethods = (paymentMethods) => paymentMethods.map((paymentMethod, index) => {
    if (paymentMethod.details.type === 'CARD') {
        return {
            id: paymentMethod.id,
            type: 'CARD',
            brand: paymentMethod.details.brand,
            number: paymentMethod.details.last4Digits,
            expires: formatExpiresAt(paymentMethod.details.expiresAt),
            selected: index === 0,
        };
    }
    // PAYPAL
    return {
        id: paymentMethod.id,
        type: 'PAYPAL',
        email: paymentMethod.details.email,
        selected: index === 0,
    };
});
export const hasChargeLimitValuesChanged = (values, initialValues) => {
    const chargeLimitValues = ['setChargeLimit', 'chargeLimit'];
    return !isEqual(pick(values, chargeLimitValues), pick(initialValues, chargeLimitValues));
};
export const getSetCurrentShopConfigChargeLimitPayload = (values) => {
    if (!values.setChargeLimit) {
        return {
            chargePolicy: {
                type: 'NO_LIMIT',
            },
        };
    }
    return {
        chargePolicy: {
            type: 'LIMITED',
            amount: {
                currency: 'USD',
                value: parseFloat(values.chargeLimit),
            },
        },
    };
};
export const loadPayPalScript = (clientId, userIdToken) => new Promise((resolve, reject) => {
    const disableFundingSources = 'card,credit,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo';
    const src = `https://www.paypal.com/sdk/js?client-id=${clientId}&disable-funding=${disableFundingSources}&locale=en_US&intent=capture&currency=USD`;
    const currentScriptTag = document.getElementById(PAYPAL_SCRIPT_ID);
    if (currentScriptTag) {
        currentScriptTag.src = src;
        currentScriptTag.setAttribute('data-user-id-token', userIdToken);
        resolve();
        return;
    }
    const scriptElement = document.createElement('script');
    scriptElement.src = src;
    scriptElement.id = PAYPAL_SCRIPT_ID;
    scriptElement.setAttribute('data-user-id-token', userIdToken);
    scriptElement.onload = () => resolve();
    scriptElement.onerror = (error) => reject(error);
    document.head.appendChild(scriptElement);
});
