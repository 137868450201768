import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 48px;
`;
export const Title = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;

  ${mediaQueries.tablet} {
    margin-top: 0;
  }
`;
export const CopyLinkWrapper = styled.div `
  display: block;
  overflow: hidden;
  min-width: 150px;
`;
