import { ApiBase } from '../ApiBase';
class SamplesBudgetApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getBalance = () => {
            return this.get('/samples-budget');
        };
        this.createCheckout = (payload) => {
            return this.post('/samples-budget/checkout', payload);
        };
        this.getReferralConfiguration = () => {
            return this.get('/samples-budget/referral-configuration/last-active');
        };
    }
}
export const SamplesBudgetApiClient = new SamplesBudgetApiClientModel();
