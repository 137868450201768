import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
import { ScrollContent } from '@components/common/ScrollContent';
export const Container = styled.div `
  padding: 24px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: absolute;
`;
export const Content = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const SectionHeader = styled.div `
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;
export const Section = styled.div `
  margin-bottom: 32px;
`;
export const ScrollableContentContainer = styled.div `
  flex: 1;
`;
export const ScrollableContent = styled(ScrollContent) `
  padding-right: 30px;
  margin-right: -30px;
`;
export const Header = styled.div `
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Message = styled.div `
  font-size: 16px;
  line-height: 24px;
`;
export const DetailsButtonContainer = styled.div `
  font-weight: normal;
  margin-left: 12px;
  font-size: 14px;
  line-height: 21px;
`;
export const TagsContainer = styled.div `
  margin-bottom: 12px;
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const Footer = styled.div `
  margin-top: 32px;
  color: ${Colors.GS600};
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const DownloadAppQrcodeIcon = styled.img `
  width: 48px;
  height: 48px;
  opacity: 0.5;
`;
export const PlatformLink = styled.a `
  text-decoration: underline;
  display: inline-flex;
  vertical-align: top;
  align-items: center;

  &:hover {
    color: ${Colors.GS1000};
  }
`;
export const PlatformIcon = styled.img `
  display: inline-block;
  vertical-align: baseline;
  margin-right: 2px;
`;
export const FooterContent = styled.div ``;
