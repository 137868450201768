import { ApiBase } from '../ApiBase';
class ThirdPartyLogisticsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getOffersData = ({ query }) => {
            return this.get('/3pl/inventory_shipments/offers', query);
        };
        this.getShippingPackagePricingOptions = () => {
            return this.get('/3pl/pricing-options');
        };
        this.getInventoryShipment = ({ id }) => {
            return this.get(`/3pl/inventory_shipments/${id}`);
        };
        this.getInventoryShipments = (query) => {
            return this.get(`/3pl/inventory_shipments`, query);
        };
        this.createInventoryShipment = (requestBody) => {
            return this.post('/3pl/inventory_shipments', requestBody);
        };
        this.updateInventoryShipment = ({ id }, requestBody) => {
            return this.post(`/3pl/inventory_shipments/${id}`, requestBody);
        };
        this.cancelInventoryShipment = ({ id }) => {
            return this.post(`/3pl/inventory_shipments/${id}/cancel`);
        };
        this.getInventoryStockSnapshot = ({ snapshotId, }) => {
            return this.get(`/3pl/inventory/stocks/snapshots/${snapshotId}`);
        };
    }
}
export const ThirdPartyLogisticsApiClient = new ThirdPartyLogisticsApiClientModel();
