import { Button, ToolbarButton, useMediaQueryContext } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import React from 'react';
import { Dashboard } from '../../../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../../../components/common/PageLayout';
import { routing } from '../../../../../../../utils/routing';
export const CreatorMessagesLayout = ({ children, showSendNewMessage, }) => {
    const media = useMediaQueryContext();
    const renderToolbarContent = () => {
        if (!showSendNewMessage) {
            return null;
        }
        if (!media.tablet) {
            return (React.createElement(ToolbarButton, { label: "Send a new message", icon: PlusIcon, to: routing.contributions.creatorMessages.send }));
        }
        return (React.createElement(Button, { label: "Send a new message", appearance: "primary", to: routing.contributions.creatorMessages.send }));
    };
    return (React.createElement(PageLayout, { size: "large" },
        React.createElement(Dashboard.Section, { name: "Messages", toolbarContent: renderToolbarContent() }, children)));
};
