import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
`;
export const LogoContainer = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 21px;

  display: block;
`;
export const Main = styled.div `
  max-width: 498px;

  text-align: center;
`;
export const LoaderContainer = styled.div `
  margin-bottom: 18px;

  display: flex;
  justify-content: center;
`;
export const Description = styled.div `
  margin-top: 16px;

  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS1000};
`;
export const ActionsContainer = styled.div `
  margin-top: 24px;

  display: flex;
  justify-content: center;
`;
