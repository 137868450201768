import { useRoles } from '@fourthwall/auth';
import { useAvailableRoles } from '../../../../../../../../../hooks/useAvailableRoles';
import { getSelectableRoles } from './utils';
export const useTeamMembersRoles = () => {
    const { group: userRole, invalidateRolesQuery, isLoadingRoles } = useRoles();
    const { availableRoles, isLoading, invalidateAvailableRoles } = useAvailableRoles();
    const topRoleId = availableRoles && availableRoles[0].id;
    const canUserManageTopRoleTeamMembers = userRole === topRoleId;
    const selectableRoles = (availableRoles &&
        getSelectableRoles(canUserManageTopRoleTeamMembers ? availableRoles : availableRoles.slice(1))) ||
        [];
    const getRoleName = (roleId) => (availableRoles && availableRoles.find(({ id }) => id === roleId)?.name) || roleId;
    return {
        topRoleId,
        selectableRoles,
        canUserManageTopRoleTeamMembers,
        availableRoles: {
            roles: availableRoles,
            loading: isLoadingRoles || isLoading,
            invalidate: () => {
                invalidateAvailableRoles();
                invalidateRolesQuery();
            },
        },
        getRoleName,
    };
};
