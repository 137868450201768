import forEach from 'lodash-es/forEach';
export const getNumberOfProducts = (items, donation) => {
    let itemsCount = 0;
    forEach(items, ({ quantity }) => {
        itemsCount += quantity;
    });
    if (donation) {
        itemsCount += 1;
    }
    return itemsCount;
};
export const getWeightOptions = () => {
    const labels = {
        lb: 'lb',
        oz: 'oz',
        g: 'g',
        kg: 'kg',
    };
    return Object.entries(labels).map(([value, label]) => ({ label, value }));
};
export const getDefaultProductImage = (product) => {
    const firstVariantImageId = product.variants[0]?.images[0];
    if (firstVariantImageId) {
        return product.images.find((image) => image.id === firstVariantImageId)?.url;
    }
    return product.images[0]?.url;
};
