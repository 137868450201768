import map from 'lodash-es/map';
import React from 'react';
import { DateString } from '../../functional/DateString';
import * as S from './Timeline.styled';
import { getSections } from './utils';
export const Timeline = ({ events, size = 'small' }) => {
    const renderTime = (time) => {
        if (!time || size === 'small') {
            return null;
        }
        return (React.createElement(S.Time, null,
            React.createElement(DateString, { date: time, format: "h:mma" })));
    };
    const renderEvent = ({ happenedAt, description }, index) => (React.createElement(S.Item, { key: happenedAt || index, completed: happenedAt, size: size },
        React.createElement(S.IconContainer, { completed: happenedAt }),
        React.createElement(S.Description, null,
            React.createElement(S.Title, { size: size }, description),
            renderTime(happenedAt))));
    const renderSection = (section) => {
        const firstEvent = section[0];
        return (React.createElement(React.Fragment, { key: firstEvent.id },
            firstEvent.happenedAt && (React.createElement(S.DateSection, { size: size },
                React.createElement(DateString, { date: firstEvent.happenedAt, format: "MMM D" }))),
            map(section, renderEvent)));
    };
    const renderTimeline = (sections) => map(sections, renderSection);
    return React.createElement(S.Container, { size: size }, renderTimeline(getSections(events)));
};
