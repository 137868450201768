import { useFetchShipStationStoresQuery } from './useFetchShipStationStoresQuery';
import { useShipStationStoresQuery } from './useShipStationStoresQuery';
export const useShipStationStores = (status, values) => {
    const { fetchShipStationStoresQuery, invalidateFetchShipStationStoresQuery, setFetchShipStationStoresData, } = useFetchShipStationStoresQuery([values], {
        enabled: status !== 'CONNECTED' && !!values.apiKey.length && !!values.apiSecret.length,
        retry: false,
        keepPreviousData: true,
    });
    const { shipStationStoresQuery, invalidateShipStationStoresQuery } = useShipStationStoresQuery(undefined, {
        enabled: status === 'CONNECTED',
    });
    if (status !== 'CONNECTED') {
        return {
            stores: !values.apiKey.length || !values.apiSecret.length
                ? undefined
                : fetchShipStationStoresQuery.data?.stores,
            isLoadingStores: fetchShipStationStoresQuery.isInitialLoading,
            invalidateStores: invalidateFetchShipStationStoresQuery,
            setStoresData: setFetchShipStationStoresData,
        };
    }
    return {
        stores: shipStationStoresQuery.data?.stores || fetchShipStationStoresQuery.data?.stores,
        isLoadingStores: shipStationStoresQuery.isInitialLoading,
        invalidateStores: invalidateShipStationStoresQuery,
    };
};
