import { ApiBase } from './ApiBase';
class OrderAnalyticsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getOffersAnalytics = (params) => {
            return this.get('/orders/analytics/offers', params);
        };
        this.getOfferOrdersByVariantsAnalytics = (pathParams) => {
            return this.get(`/orders/analytics/offers/${pathParams.offerId}/variants`);
        };
        this.getVariantsAnalytics = (params) => {
            return this.get('/orders/analytics/variants', params);
        };
        this.getOfferPendingOrders = (pathParams) => {
            return this.get(`/orders/analytics/offers/${pathParams.offerId}/pending`);
        };
    }
}
export const OrderAnalyticsApiClient = new OrderAnalyticsApiClientModel();
