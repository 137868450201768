import { Banner, ButtonClean, Checkbox, DatePicker, DayRange, Fieldset, Input, Select, } from '@fourthwall/components';
import { FeatureFlagDecorator } from '@components/utils/FeatureFlagDecorator';
import { Field } from 'formik';
import * as S from '@modules/Dashboard/Product/modules/shipping/ProductShippingByCreator/styled';
import React from 'react';
import { isShipmentStartDateEditable } from '@modules/Dashboard/Product/store/product/utils';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { routing } from '@utils/routing';
import { useOfferPendingOrdersQuery } from '@modules/Dashboard/Product/hooks/useOfferPendingOrdersQuery';
import isEqual from 'lodash-es/isEqual';
import { useParams } from 'react-router-dom';
const SHIPMENT_START_DATE_OPTIONS = [
    { value: 'UNKNOWN', label: 'No set shipping time' },
    { value: 'DAYS_RANGE', label: 'Number of days after the order is received' },
    { value: 'DATE_RANGE', label: 'On a specific date' },
];
const RangeSuggestion = () => (React.createElement(React.Fragment, null,
    "We use this to show your supporters when their order will ship.",
    ' ',
    React.createElement(ButtonClean, { label: "Learn more", appearance: "secondary", href: routing.external.helpCenter.selfSipping, target: "_blank" })));
export const ShippingStartDate = ({ initialValues }) => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const { productId = '' } = useParams();
    const { values, errors, setFieldValue } = useFormikContext();
    const isFormEditable = hasPermission('products.edit.details');
    const disabled = !isFormEditable;
    const isStartDateEditable = isShipmentStartDateEditable(values.manufacturingService);
    const { offerPendingOrdersQuery } = useOfferPendingOrdersQuery([{ offerId: productId }], {
        enabled: !!productId,
    });
    const pendingOrdersCount = offerPendingOrdersQuery?.data?.count;
    const hasShipmentChanged = !isEqual(initialValues.shipment, values.shipment);
    const handleDayRangeChange = async (value) => {
        await setFieldValue('shipment.days.daysMin', value[0]);
        await setFieldValue('shipment.days.daysMax', value[1]);
    };
    const handleDateRageChange = async (newDates) => {
        await setFieldValue('shipment.dates.from', newDates.startDate);
        await setFieldValue('shipment.dates.to', newDates.endDate);
    };
    if (!isStartDateEditable) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        dashboardFeatureFlags.productAdvancedOptions.enabled && (React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement("div", { style: { margin: '8px 0' } },
                    React.createElement(FeatureFlagDecorator, { enabled: true, description: dashboardFeatureFlags.productAdvancedOptions.description },
                        React.createElement(Banner, { title: "Please confirm shipping times with production ops", appearance: "warning" })))))),
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { name: "shipment.type", component: Select, options: SHIPMENT_START_DATE_OPTIONS, label: "When will this product be shipped?", disabled: disabled }))),
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                values.shipment.type === 'DAYS_RANGE' && (React.createElement(S.DayRangeContainer, null,
                    React.createElement(DayRange, { name: ['shipment.days.daysMin', 'shipment.days.daysMax'], value: [values.shipment.days.daysMin, values.shipment.days.daysMax], suggestion: React.createElement(RangeSuggestion, null), error: errors?.shipment?.days?.daysMin || errors?.shipment?.days?.daysMax, width: 232, disabled: disabled, onChange: handleDayRangeChange }))),
                values.shipment.type === 'DATE_RANGE' && (React.createElement(S.DateRangeContainer, null,
                    React.createElement(DatePicker, { type: "range", name: ['shipment.dates.from', 'shipment.dates.to'], startDate: values.shipment.dates.from, endDate: values.shipment.dates.to, suggestion: React.createElement(RangeSuggestion, null), error: errors?.shipment?.dates?.from || errors?.shipment?.dates?.to, disabled: disabled, onDatesChange: handleDateRageChange }))))),
        !!pendingOrdersCount && hasShipmentChanged && (React.createElement(React.Fragment, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: "shipmentDateUpdatePendingOrders", component: Checkbox, label: `Apply this change to all orders (${pendingOrdersCount}) that haven’t shipped yet`, disabled: disabled }))),
            values.shipment.type !== 'UNKNOWN' && values.shipmentDateUpdatePendingOrders && (React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(S.InnerField, null,
                        React.createElement(Field, { name: "shipmentDateNotifyPendingSupporters", component: Checkbox, label: "Notify supporters about the new estimated delivery date", disabled: disabled }))))),
            values.shipment.type !== 'UNKNOWN' &&
                values.shipmentDateUpdatePendingOrders &&
                values.shipmentDateNotifyPendingSupporters && (React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(S.InnerField, null,
                        React.createElement(S.InnerField, null,
                            React.createElement(Field, { name: "shipmentDateUpdateReason", component: Input, label: "Reason (optional)", suggestion: "Reason will appear on a notification email sent to supporters.", disabled: disabled }))))))))));
};
