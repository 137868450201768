import { ButtonClean, Tag } from '@fourthwall/components';
import { reverse } from 'named-urls';
import React from 'react';
import { routing } from '../../../../../../utils/routing';
import * as S from './CatalogProductDetails.styled';
export const CatalogProductDetails = ({ product }) => {
    if (!product) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        product.status === 'DISCONTINUED' && (React.createElement(S.Status, null,
            React.createElement(Tag, { display: "inline-flex", label: "Discontinued", appearance: "neutral" }))),
        React.createElement("p", null,
            product.name,
            " by ",
            product.brandName),
        React.createElement(ButtonClean, { label: "View details", href: reverse(routing.productCatalog.product, { slug: product.slug }), target: "_blank" })));
};
