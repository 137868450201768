import React from 'react';
import { Option } from '../../common/Option';
import { withFormikSupport } from '../../hocs/withFormikSupport/withFormikSupport';
const RadioButtonComponent = ({ value, name, tooltip, checkedValue, label, suggestion, disabled, onChange, onBlur, onValueChange, }) => {
    const handleChange = (e) => {
        if (onChange)
            onChange(e);
        if (onValueChange)
            onValueChange(e.target.value);
    };
    return (React.createElement(Option, { label: label, name: name, tooltip: tooltip, checked: checkedValue === value, value: checkedValue, suggestion: suggestion, disabled: disabled, onChange: handleChange, onBlur: onBlur }));
};
export const RadioButton = withFormikSupport(RadioButtonComponent);
