import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
const getContainerWidth = (size) => {
    const widths = {
        tiny: 33,
        small: 42,
        medium: 48,
        large: 60,
        extraLarge: 66,
    };
    return widths[size];
};
export const Container = styled.div `
  width: ${({ size }) => `${getContainerWidth(size)}px`};

  flex-shrink: 0;
`;
export const Image = styled.img `
  width: 100%;
  height: 100%;
  background-color: ${Colors.GS200};
  object-fit: contain;
`;
export const Placeholder = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.GS200};
  color: ${Colors.GS400};
`;
