import { useMemo } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
export const useActiveTab = ({ tabValues }) => {
    const [queryParams, setQueryParams] = useQueryParams({
        tab: StringParam,
    });
    const setTabParam = (tab) => setQueryParams({ tab });
    const activeTab = useMemo(() => {
        return tabValues.includes(queryParams.tab ?? '') ? queryParams.tab : tabValues[0];
    }, [queryParams, tabValues]);
    return {
        activeTab,
        setTabParam,
    };
};
