import React from 'react';
import { usePagination } from '@fourthwall/components';
import { useFilterContributions } from '../../hooks/useFilterContributions';
import { SIZE } from './consts';
import { useOrdersQuery, createUseOrdersQueryParams } from '../../../../../hooks/useOrdersQuery';
import { OrdersView } from './OrdersView';
import { useSearchQuery } from '../../../hooks/useSearchQuery';
export const OrdersContainer = () => {
    const [currentPage, setCurrentPage] = usePagination();
    const { searchQuery } = useSearchQuery();
    const { filtersParams } = useFilterContributions();
    const { orders, ordersQuery } = useOrdersQuery(createUseOrdersQueryParams({
        currentPage,
        size: SIZE,
        query: searchQuery,
        status: filtersParams?.status?.split(','),
        filterOutGiveawayGifts: !searchQuery,
    }), { keepPreviousData: true });
    return (React.createElement(OrdersView, { orders: orders?.orders, totalPages: orders?.page.totalPages, currentPage: currentPage, isFetching: ordersQuery.isFetching, onPageChange: setCurrentPage }));
};
