import styled from 'styled-components';
export const MediaPlayer = styled.div `
  max-width: 132px;
`;
export const Section = styled.section `
  margin-top: 16px;
  max-width: 445px;
  display: flex;
`;
export const FileDescription = styled.div `
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  min-width: 100%;
  margin-left: 24px;
`;
export const ButtonWrapper = styled.div `
  margin-top: 4px;
`;
