import { HeadContent, Pagination, Table, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dashboard } from '../../../../../../../components/common/Dashboard';
import { useFilterPosts } from '../../hooks/useFilterPosts';
import { usePosts } from '../../hooks/usePosts';
export const PublishedPosts = () => {
    const { posts } = usePosts('Published');
    const { hasFilterParams } = useFilterPosts();
    const media = useMediaQueryContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Published Posts" }),
        React.createElement(Dashboard.Section.Item, null,
            React.createElement(Dashboard.Section.ScrollableContent, null,
                React.createElement(Table, { ...posts.table, rowComponent: Link, isLoading: posts.isLoading, emptyMessage: hasFilterParams ? 'No posts found. Adjust your filters.' : 'You have no posts yet', minWidth: media.laptop ? undefined : 0 }))),
        posts.pagination.pageCount !== 1 && (React.createElement(Dashboard.Section.Item, null,
            React.createElement(Pagination, { ...posts.pagination })))));
};
