import { numberFormatter } from '../../numberFormatter/numberFormatter';
export const getRecipient = (recipient) => {
    // should never hit this condition
    if (!recipient) {
        return ``;
    }
    // todo: remove when real data will be received from backend
    // HACK to display nicely formatted data on front
    let recipientNumber = Math.abs(recipient);
    if (recipientNumber > 1000000000) {
        recipientNumber = Number(recipientNumber.toString().slice(0, 5));
    }
    return numberFormatter(recipientNumber);
};
