import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { ButtonClean, Checkbox, Fieldset, HeadContent, Input, InputUI, Loading, RadioButton, Skeleton, Subsection, Tag, } from '@fourthwall/components';
import { mapStatusToTagProps } from '@modules/Dashboard/Apps/utils';
import { ConfirmBarContainer } from '@modules/Dashboard/ConfirmBar/ConfirmBarContainer';
import { routing } from '@utils/routing';
import { Field, Formik } from 'formik';
import React from 'react';
import { ConnectBar } from '../../components/ConnectBar';
import { StatusTag } from './components/StatusTag/StatusTag';
import { useTwitchDiscounts } from './hooks/useTwitchDiscounts';
import * as S from './styled';
import { FormFields } from './types';
export const AppsTwitchDiscounts = () => {
    const { confirmBarProps, connectBarProps, twitchStatus, form, memberships, shop, isLoading } = useTwitchDiscounts();
    const renderStatusTag = () => {
        const props = mapStatusToTagProps(twitchStatus);
        if (!props)
            return null;
        return (React.createElement("div", { style: { marginBottom: 20 } },
            React.createElement(Tag, { ...props, size: "large", display: "inline-flex" })));
    };
    const disabledSuggestion = (React.createElement(Fieldset.Row, { inner: true },
        React.createElement(S.SuggestionContainer, null,
            React.createElement(InputUI.Suggestion, null, "Discount amount cannot be changed with members actively using the promotion"))));
    const renderShopForm = () => {
        if (!shop.shouldRenderForm)
            return null;
        return (React.createElement(Subsection, { title: "Shop discounts" },
            React.createElement(S.SectionDescription, null,
                React.createElement("p", null,
                    "To create shop discounts for Twitch subs:",
                    React.createElement("ol", null,
                        React.createElement("li", null,
                            React.createElement(ButtonClean, { label: "Create a new auto-applied promotion", target: "_blank", to: `${routing.promotions.promotionCodes.create.self}?mode=twitch` }),
                            ' ',
                            "in Promotions tab"),
                        React.createElement("li", null, "Select \u201CMake this promotion available only for your Twitch subs\u201D option in the Requirements section."))),
                React.createElement("p", null,
                    "Supporters with eligible products in their cart will see promotion banner at the top of the checkout where they can connect to Twitch to verify their subscription.",
                    ' ',
                    React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.twitchShopDiscounts, target: "_blank" })))));
    };
    const renderMembershipsForm = (values, errors, touched) => {
        if (!memberships.shouldRenderForm)
            return null;
        const renderTiers = () => {
            if (values.accessType == 'AllTiers')
                return null;
            return Object.keys(values.tiers).map((key) => {
                const tierId = parseInt(key);
                const tier = values.tiers[tierId];
                return (React.createElement(React.Fragment, null,
                    React.createElement(Fieldset.Row, { inner: true },
                        React.createElement(Field, { component: Checkbox, name: `${FormFields.tiers}.${key}.enabled`, label: tier.name, hideSpinner: true, disabled: !tier.editable })),
                    values.tiers?.[tierId].enabled && (React.createElement(React.Fragment, null,
                        React.createElement(Fieldset.Row, { inner: true },
                            React.createElement(S.PercentageInputContainer, null,
                                React.createElement(Field, { component: Input, name: `${FormFields.tiers}.${key}.percentageOff`, label: "Percentage off", type: "number", append: "%", hideSpinner: true, disabled: !tier.editable }))),
                        React.createElement(S.PercentageInputContainer, null, !tier.editable && disabledSuggestion)))));
            });
        };
        return (React.createElement(Subsection, { title: "Membership discounts" },
            memberships.statusTagProps && React.createElement(StatusTag, { ...memberships.statusTagProps }),
            React.createElement(S.SectionDescription, null,
                "Supporters will see promotion banner at the top of the membership checkout. Once members connect their accounts, the discount will apply as long as they remained subscribed on Twitch.",
                ' ',
                React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.twitchMembershipsIntegrations, target: "_blank" })),
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { name: FormFields.accessType, component: RadioButton, label: "Offer the same discount for all tiers", checkedValue: "AllTiers", disabled: memberships.hasActiveSubscriptions })),
                values.accessType === 'AllTiers' && (React.createElement(React.Fragment, null,
                    React.createElement(Fieldset.Row, { inner: true },
                        React.createElement(Field, { component: Input, name: FormFields.percentageOff, label: "Percentage off", type: "number", append: "%", hideSpinner: true, disabled: !values.editable })),
                    !values.editable && disabledSuggestion)),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { name: FormFields.accessType, component: RadioButton, label: "Offer different discounts for different tiers", checkedValue: "SelectedTiers", disabled: memberships.hasActiveSubscriptions })),
                renderTiers(),
                errors.tiers && touched.tiers && (React.createElement(Fieldset.Row, { inner: true },
                    React.createElement(InputUI.Error, null, errors.tiers))))));
    };
    const renderForm = () => {
        return (React.createElement(Formik, { ...form, enableReinitialize: true }, ({ values, errors, touched, dirty, submitForm, resetForm }) => {
            return (React.createElement(React.Fragment, null,
                React.createElement(ConfirmBarContainer, { ...confirmBarProps, isOpen: dirty, onConfirm: submitForm, onCancel: resetForm }),
                renderShopForm(),
                renderMembershipsForm(values, errors, touched)));
        }));
    };
    return (React.createElement(Loading, { isLoading: isLoading },
        React.createElement(HeadContent, { title: "Discounts for Twitch subs" }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: "Discounts for Twitch subs", topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }) },
                React.createElement(S.HeaderContainer, null,
                    renderStatusTag(),
                    React.createElement(S.DescriptionWrapper, null,
                        React.createElement("p", null, "Offer your Twitch subscribers discounts on shop orders or membership subscriptions.")),
                    React.createElement("div", null, connectBarProps ? (React.createElement(ConnectBar, { ...connectBarProps })) : (React.createElement(Skeleton, null,
                        React.createElement(Skeleton.Shape, { height: 64 }))))),
                renderForm()))));
};
