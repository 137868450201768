import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { mediaQueries } from '../../providers/MediaQueryProvider';
export const Container = styled.div `
  display: block;
`;
export const Dropdown = styled.div `
  position: relative;
  z-index: 10;
  background: ${Colors.GS0};
  padding: 8px 0;
  color: ${Colors.GS1000};
  width: 100%;

  &::after {
    display: block;
    content: '';
    position: absolute;
    height: 50px;
    width: 100%;
    top: 100%;
    background: ${Colors.GS0};
  }

  ${mediaQueries.tablet} {
    border: 1px solid ${Colors.GS500};
    padding: 8px 0;

    &::after {
      display: none;
    }

    ${(props) => props.fluid
    ? css `
            width: 100%;
          `
    : css `
            max-width: ${props.maxWidth}px;
          `}
  }
`;
export const Options = styled.div(({ maxHeight }) => css `
    ${maxHeight &&
    css `
      max-height: ${maxHeight}px;
      overflow-y: scroll;
    `};
  `);
