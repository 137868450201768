import React from 'react';
import { ButtonClean } from '@fourthwall/components';
import * as S from './styled';
export const SelfShippingContent = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Heading, null, "I\u2019m shipping orders myself"),
        React.createElement(S.Content, null,
            "You\u2019ll pack and ship orders, upload tracking numbers, and handle customer support yourself.",
            ' ',
            React.createElement(ButtonClean, { label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/13331284092187-Fourthwall-fulfillment-service-guide", target: "_blank" }))));
};
