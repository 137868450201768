import styled from 'styled-components';
export const Container = styled.div `
  margin-bottom: 56px;
`;
export const DataContainer = styled.div `
  margin-bottom: 16px;
`;
export const SingleVariantContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;
export const SingleVariantItem = styled.div `
  display: flex;
`;
export const SingleVariantLabel = styled.div `
  font-size: 16px;
  margin-right: 3px;
  line-height: 24px;
`;
export const PriceWrapper = styled.div `
  padding-right: 20px;
`;
export const WeightContainer = styled.div `
  max-width: 156px;
  display: flex;
`;
export const Footer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;

  & > * + * {
    margin-top: 16px;
  }
`;
export const FooterInputContainer = styled.div `
  margin-top: 12px;
`;
