import React from 'react';
import { Icon, Tooltip, useMediaQueryContext } from '@fourthwall/components';
import { CloseIcon } from '@fourthwall/icons';
import * as S from './styled';
export const SelectedItem = ({ children, withMaxHeight, disabled, onRemove, }) => {
    const media = useMediaQueryContext();
    const RemoveButton = disabled ? null : (React.createElement(S.RemoveButton, { onClick: onRemove },
        React.createElement(Icon, { component: CloseIcon, height: media.laptop ? 16 : 14 })));
    return (React.createElement(S.Container, null,
        React.createElement(S.Content, null, children),
        React.createElement(S.RemoveButtonWrapper, { inside: withMaxHeight }, media.laptop ? (React.createElement(Tooltip, { reference: RemoveButton, placement: withMaxHeight ? 'left' : 'bottom' }, "Remove")) : (RemoveButton))));
};
