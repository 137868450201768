import { ButtonClean, CurrencyInput, InputUI, ProfitValue, useMediaQueryContext, } from '@fourthwall/components';
import { Field } from 'formik';
import isNil from 'lodash-es/isNil';
import React from 'react';
import * as S from './styled';
export const ImplicitSellingPrice = ({ allowExplicitType, sizePriceSuggestion, profit, fieldName, error, disabled = false, onBlur, onValueChange, onCustomizeClick, onLearnMoreClick, }) => {
    const media = useMediaQueryContext();
    const renderSwitchTypeButton = () => {
        if (!allowExplicitType || disabled)
            return null;
        const label = sizePriceSuggestion ? 'Customize price' : 'Set different price per size';
        return React.createElement(ButtonClean, { appearance: "secondary", onClick: onCustomizeClick, label: label });
    };
    const renderProfitDescription = () => {
        const label = media.tablet ? 'Learn more' : 'How we calculate profits';
        return (React.createElement(S.ProfitDescription, null,
            !isNil(profit) && (React.createElement(S.ProfitValueWrapper, null,
                React.createElement(ProfitValue, { profit: profit }))),
            !disabled && React.createElement(ButtonClean, { label: label, onClick: onLearnMoreClick })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(S.ImplicitContent, null,
            React.createElement(S.ImplicitInput, null,
                React.createElement(Field, { component: CurrencyInput, name: fieldName, label: !media.tablet ? 'Selling price' : undefined, fullWidth: !media.tablet, displayError: false, disabled: disabled, onValueChange: onValueChange, onBlur: onBlur })),
            media.tablet && renderProfitDescription()),
        error && React.createElement(InputUI.Error, null, error),
        React.createElement(InputUI.Suggestion, null,
            sizePriceSuggestion || null,
            " ",
            renderSwitchTypeButton()),
        !media.tablet && renderProfitDescription()));
};
