import { ButtonClean, ModalBox, useModalContext } from '@fourthwall/components';
import React from 'react';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { reverse, routing } from '@utils/routing';
import * as S from './styled';
export const ProductsSyncStatusModal = ({ title, products }) => {
    const { close } = useModalContext();
    return (React.createElement(ModalBox, { title: title, onClose: close },
        React.createElement(S.Container, null, products.map((product) => (React.createElement(S.ProductContainer, null,
            React.createElement(S.ProductImageContainer, null,
                React.createElement(PhotoThumbnail, { url: product.imageSrc, size: "tiny" })),
            React.createElement(S.ProductContentContainer, null,
                React.createElement(S.ProductName, { href: reverse(routing.products.all.product.self, { productId: product.id }), target: "_blank", rel: "noopener noreferrer" }, product.name),
                product.issues.length > 0 && (React.createElement(S.ProductDescription, null, product.issues.map((issue) => (React.createElement(S.ProductDescriptionItem, null,
                    issue.description,
                    ' ',
                    issue.url && (React.createElement(React.Fragment, null,
                        "(",
                        React.createElement(ButtonClean, { label: "Learn more", href: issue.url, target: "_blank" }),
                        ")"))))))))))))));
};
