import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  padding: 16px 20px;
  background: ${Colors.GS200};
`;
export const Main = styled.div `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
export const LogoContainer = styled.div `
  width: 28px;
  margin-right: 12px;

  img {
    width: 100%;
    height: auto;
  }

  ${({ logoStyle }) => logoStyle === 'circle' &&
    css `
      border-radius: 50%;
      overflow: hidden;
    `}

  ${mediaQueries.tablet} {
    margin-right: 16px;
  }
`;
export const Content = styled.div `
  flex: 1;

  display: flex;
  justify-content: center;
  margin-right: 12px;
  flex-direction: column;

  min-width: 0;
`;
export const DescriptionAndTagContainer = styled.div `
  flex: 1;

  display: flex;
  align-items: center;
  margin-right: 12px;
`;
export const InfoContainer = styled.div `
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.GS600};
`;
export const Description = styled.p `
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: ${Colors.GS1000};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const TagWrapper = styled.div `
  margin-left: 12px;
`;
export const Buttons = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  ${mediaQueries.tablet} {
    flex-direction: row;
  }
`;
export const Footer = styled.div `
  margin-top: 16px;
`;
