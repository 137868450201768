import React from 'react';
import { Dropdown, HeadContent, ToolbarButton } from '@fourthwall/components';
import { isWebView } from '@fourthwall/utils';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import { AnalyticsCard, AnalyticsChart, AnalyticsLayout, AnalyticsRows, } from '@fourthwall/module-analytics';
import { Dashboard, PageLayout } from '@components/common';
import { AnalyticsToolbar } from './components/AnalyticsToolbar/AnalyticsToolbar';
export const AnalyticsView = ({ title, toolbarItems, rangeSelect, rangePicker, precisionSelect, barContent, profit, membershipsTotalMembers, membershipsTotalFreeMembers, membershipsMembersChange, membershipsFreeMembersChange, contributions, contributionValue, topProducts, salesByCountry, membershipsTopTiers, }) => (React.createElement(React.Fragment, null,
    React.createElement(HeadContent, { title: title, titleTemplate: isWebView() ? title : undefined }),
    React.createElement(PageLayout, { size: "full" },
        React.createElement(Dashboard.Section, { name: isWebView() ? undefined : title, toolbarContent: !isWebView() && toolbarItems.length ? (React.createElement(Dropdown, { content: toolbarItems.map((toolbarDropdownItem, index) => (React.createElement(Dropdown.Item, { key: index, ...toolbarDropdownItem }))), position: "bottom-end" },
                React.createElement(ToolbarButton, { label: "Settings", icon: DotsHorizontalIcon }))) : undefined },
            React.createElement(AnalyticsToolbar, { rangeSelect: rangeSelect, rangePicker: rangePicker, precisionSelect: precisionSelect }),
            barContent ? React.createElement("div", { style: { marginBlock: 20 } }, barContent) : null,
            React.createElement(AnalyticsLayout, null,
                profit && (React.createElement(AnalyticsLayout.Item, { featured: true },
                    React.createElement(AnalyticsCard, { title: profit.title, reportTo: profit.reportTo, value: profit.value, statistics: profit.series ? (React.createElement(AnalyticsChart, { label: profit.title, description: profit.range, series: profit.series, formatDate: profit.formatDate, formatValue: profit.formatValue })) : undefined, isLoading: profit.isLoading, isError: profit.isError }))),
                membershipsTotalMembers && (React.createElement(AnalyticsLayout.Item, null,
                    React.createElement(AnalyticsCard, { title: membershipsTotalMembers.title, reportTo: membershipsTotalMembers.reportTo, value: membershipsTotalMembers.value, statistics: membershipsTotalMembers.series ? (React.createElement(AnalyticsChart, { label: membershipsTotalMembers.title, description: membershipsTotalMembers.range, series: membershipsTotalMembers.series, formatDate: membershipsTotalMembers.formatDate, formatValue: membershipsTotalMembers.formatValue })) : undefined, isLoading: membershipsTotalMembers.isLoading, isError: membershipsTotalMembers.isError }))),
                membershipsMembersChange && (React.createElement(AnalyticsLayout.Item, null,
                    React.createElement(AnalyticsCard, { title: membershipsMembersChange.title, reportTo: membershipsMembersChange.reportTo, value: membershipsMembersChange.value, statistics: membershipsMembersChange.series ? (React.createElement(AnalyticsChart, { label: membershipsMembersChange.title, description: membershipsMembersChange.range, series: membershipsMembersChange.series, formatDate: membershipsMembersChange.formatDate, formatValue: membershipsMembersChange.formatValue })) : undefined, isLoading: membershipsMembersChange.isLoading, isError: membershipsMembersChange.isError }))),
                membershipsTotalFreeMembers && (React.createElement(AnalyticsLayout.Item, null,
                    React.createElement(AnalyticsCard, { title: membershipsTotalFreeMembers.title, tooltip: membershipsTotalFreeMembers.tooltip, reportTo: membershipsTotalFreeMembers.reportTo, value: membershipsTotalFreeMembers.value, statistics: membershipsTotalFreeMembers.series ? (React.createElement(AnalyticsChart, { label: membershipsTotalFreeMembers.title, description: membershipsTotalFreeMembers.range, series: membershipsTotalFreeMembers.series, formatDate: membershipsTotalFreeMembers.formatDate, formatValue: membershipsTotalFreeMembers.formatValue })) : undefined, isLoading: membershipsTotalFreeMembers.isLoading, isError: membershipsTotalFreeMembers.isError }))),
                membershipsFreeMembersChange && (React.createElement(AnalyticsLayout.Item, null,
                    React.createElement(AnalyticsCard, { title: membershipsFreeMembersChange.title, reportTo: membershipsFreeMembersChange.reportTo, value: membershipsFreeMembersChange.value, statistics: membershipsFreeMembersChange.series ? (React.createElement(AnalyticsChart, { label: membershipsFreeMembersChange.title, description: membershipsFreeMembersChange.range, series: membershipsFreeMembersChange.series, formatDate: membershipsFreeMembersChange.formatDate, formatValue: membershipsFreeMembersChange.formatValue })) : undefined, isLoading: membershipsFreeMembersChange.isLoading, isError: membershipsFreeMembersChange.isError }))),
                contributions && (React.createElement(AnalyticsLayout.Item, null,
                    React.createElement(AnalyticsCard, { title: contributions.title, reportTo: contributions.reportTo, value: contributions.value, statistics: contributions.series ? (React.createElement(AnalyticsChart, { label: contributions.title, description: contributions.range, series: contributions.series, formatDate: contributions.formatDate, formatValue: contributions.formatValue })) : undefined, isLoading: contributions.isLoading, isError: contributions.isError }))),
                contributionValue && (React.createElement(AnalyticsLayout.Item, null,
                    React.createElement(AnalyticsCard, { title: contributionValue.title, reportTo: contributionValue.reportTo, value: contributionValue.value, statistics: contributionValue.series ? (React.createElement(AnalyticsChart, { label: contributionValue.title, description: contributionValue.range, series: contributionValue.series, formatDate: contributionValue.formatDate, formatValue: contributionValue.formatValue })) : undefined, isLoading: contributionValue.isLoading, isError: contributionValue.isError }))),
                topProducts && (React.createElement(AnalyticsLayout.Item, null,
                    React.createElement(AnalyticsCard, { title: topProducts.title, reportTo: topProducts.reportTo, description: topProducts.range, statistics: React.createElement(AnalyticsRows, { rows: topProducts.rows, emptyMessage: "No contributions found in this date range." }), isLoading: topProducts.isLoading, isError: topProducts.isError }))),
                salesByCountry && (React.createElement(AnalyticsLayout.Item, null,
                    React.createElement(AnalyticsCard, { title: salesByCountry.title, reportTo: salesByCountry.reportTo, description: salesByCountry.range, statistics: React.createElement(AnalyticsRows, { rows: salesByCountry.rows, emptyMessage: "No contributions found in this date range." }), isLoading: salesByCountry.isLoading, isError: salesByCountry.isError }))),
                membershipsTopTiers && (React.createElement(AnalyticsLayout.Item, null,
                    React.createElement(AnalyticsCard, { title: membershipsTopTiers.title, reportTo: membershipsTopTiers.reportTo, description: membershipsTopTiers.range, statistics: React.createElement(AnalyticsRows, { rows: membershipsTopTiers.rows, emptyMessage: "No data found in this date range." }), isLoading: membershipsTopTiers.isLoading, isError: membershipsTopTiers.isError }))))))));
