import { Toasts } from '@fourthwall/components';
import { HasuraApiClient } from '@fourthwall/services/lib/HasuraApiClient';
import { useMutation } from '@tanstack/react-query';
import { downloadCsvFile } from '@fourthwall/utils/lib/downloadCsvFile';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { formatMoney, getOrderType, sortOrderRowsByDate } from './utils';
export const useOrdersCsvExport = () => {
    const { currentShopQuery } = useCurrentShopQuery();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (shopId) => {
            const { data } = await HasuraApiClient.getOrdersList(shopId);
            return data;
        },
    });
    const download = () => {
        if (!currentShopQuery.data)
            return;
        mutate(currentShopQuery.data.id, {
            onSuccess: (data) => {
                const orders = data.orders.map((order) => ({
                    TYPE: getOrderType(order),
                    ID: order.id,
                    'FRIENDLY ID': order.friendly_id,
                    'DATE (UTC)': order.created_at,
                    STATUS: order.status,
                    'SUPPORTER EMAIL': order.email,
                    'EMAIL MARKETING OPT-IN': order.email_marketing_opt_in,
                    MERCHANDISE: formatMoney(order.sales_price, order.sales_price_currency),
                    DONATION: formatMoney(order.donation, order.sales_price_currency),
                    SHIPPING: formatMoney(order.shipping_price, order.sales_price_currency),
                    TAXES: formatMoney(order.tax_price, order.sales_price_currency),
                    DISCOUNT: formatMoney(order.discount, order.sales_price_currency),
                    REFUNDED: formatMoney(order.refunds_aggregate.aggregate.sum.amount, order.sales_price_currency),
                    'SHIPPING NAME': [order.shipping_first_name, order.shipping_lastName]
                        .join(' ')
                        .trim(),
                    'SHIPPING ADDRESS': [order.shipping_address1, order.shipping_address2]
                        .join(' ')
                        .trim(),
                    'SHIPPING CITY': order.shipping_city,
                    'SHIPPING STATE': order.shipping_state,
                    'SHIPPING ZIP': order.shipping_zip,
                    'SHIPPING COUNTRY': order.shipping_country,
                    'SHIPPING PHONE NUMBER': order.shipping_phone_number,
                    'BILLING FIRST NAME': order.billing_first_name,
                    'BILLING LAST NAME': order.billing_lastName,
                    'BILLING ADDRESS': [order.billing_address1, order.billing_address2].join(' ').trim(),
                    'BILLING CITY': order.billing_city,
                    'BILLING STATE': order.billing_state,
                    'BILLING ZIP': order.billing_zip,
                    'BILLING COUNTRY': order.billing_country,
                    USERNAME: order.additional_fields.additionalFields?.find((field) => field.first == 'username')
                        ?.second || '',
                    MESSAGE: order.message.replace(/\s/g, ' '),
                    'PROMOTION CODE': order?.promotion?.code || '',
                    'REFUND REASONS': order.refunds?.map((refund) => refund.reason).join(', '),
                    'TAX ID': order.tax_id || '',
                }));
                const donations = data.donations.map((donation) => ({
                    TYPE: 'Donation',
                    ID: donation.id,
                    'DATE (UTC)': donation.created_at,
                    STATUS: donation.status,
                    'SUPPORTER EMAIL': donation.email,
                    'EMAIL MARKETING OPT-IN': false,
                    MERCHANDISE: '',
                    DONATION: formatMoney(donation.donation, donation.donation_currency),
                    USERNAME: donation?.username || '',
                    MESSAGE: donation?.message?.replace(/\s/g, ' ') || '',
                }));
                const giveaways = data.giveaways.map((giveaway) => ({
                    TYPE: 'Twitch gift purchase',
                    ID: giveaway.id,
                    'FRIENDLY ID': giveaway.friendly_id,
                    'DATE (UTC)': giveaway.created_at,
                    STATUS: 'COMPLETED',
                    'SUPPORTER EMAIL': giveaway.email,
                    'EMAIL MARKETING OPT-IN': false,
                    MERCHANDISE: formatMoney(giveaway.total_price, giveaway.currency),
                    DONATION: formatMoney(giveaway.donation, giveaway.currency),
                    DISCOUNT: formatMoney(giveaway.discount, giveaway.currency),
                    USERNAME: giveaway?.username || '',
                    MESSAGE: giveaway?.message?.replace(/\s/g, ' ') || '',
                }));
                const sortedData = sortOrderRowsByDate([...orders, ...donations, ...giveaways]);
                if (!sortedData.length) {
                    Toasts.notify("You haven't received any orders to export yet", { type: 'error' });
                    return;
                }
                downloadCsvFile(sortedData, `${currentShopQuery.data.name}-orders`);
            },
        });
    };
    return {
        ordersList: {
            download,
            isLoading,
        },
    };
};
