import { ButtonClean, Checkbox, Subsection } from '@fourthwall/components';
import { routing } from '@utils/routing';
import { Field } from 'formik';
import React from 'react';
import { SectionDescription } from '../SectionDescription';
export const CookieSettings = () => {
    return (React.createElement(Subsection, { title: "Cookie settings" },
        React.createElement(SectionDescription, null,
            "Cookie policy ensures legal compliance with stringent data protection regulations like the GDPR and to empower users with control over their online privacy. Some locations require visitors to give permission before their data can be used. When enabled, a cookie banner will appear on your site for these visitors. Learn more about",
            ' ',
            React.createElement(ButtonClean, { label: "cookie policy", href: routing.external.helpCenter.cookiePolicy, target: "_blank" }),
            "."),
        React.createElement(Field, { component: Checkbox, name: "cookiePolicy", label: "Enable cookie policy" })));
};
