import { ConfirmModal, useModalContext } from '@fourthwall/components';
import { useDashboardFeatureFlags } from '../../../../../../../../components/utils/DashboardFeatureFlags';
import { checklistDataLayer } from '../../../../../../../../utils/gtm/checklistDataLayer';
import { getSteps, mergeStep } from '../utils';
import { useHideMembershipsOnboardingMutation } from './useHideMembershipsOnboardingMutation';
import { useMembershipsOnboardingData } from './useMembershipsOnboardingData';
import { useSkipMembershipsOnboardingStepMutation } from './useSkipMembershipsOnboardingStepMutation';
export const useMembershipsOnboarding = ({ page, title }) => {
    const modal = useModalContext();
    const { hideMembershipsOnboardingMutation } = useHideMembershipsOnboardingMutation();
    const { skipMembershipsOnboardingStepMutation } = useSkipMembershipsOnboardingStepMutation();
    const { membershipsOnboardingQuery, isOnboardingCompleted, isOnboardingHidden, onboardingSteps } = useMembershipsOnboardingData();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const steps = getSteps(page);
    const getItems = () => {
        if (!onboardingSteps) {
            return [];
        }
        const mergedSteps = steps
            .map((step) => {
            const onboardingStep = onboardingSteps.find((onboardingStep) => onboardingStep.type === step.type);
            if (!onboardingStep) {
                return;
            }
            const onSkipStepConfirm = async () => {
                await skipMembershipsOnboardingStepMutation.mutateAsync([onboardingStep.id], {
                    onSuccess: membershipsOnboardingQuery.refetch,
                });
                checklistDataLayer('skipChecklistStepConfirm', page, 'Memberships', step.title);
            };
            return mergeStep(step, onboardingStep, async () => {
                modal.open(ConfirmModal, {
                    title: `Are you sure you want to skip “${step.title}” step?`,
                    text: 'This action cannot be reversed.',
                    confirmLabel: 'Skip',
                    confirmButtonAnalytics: 'skipChecklistStepConfirm',
                    onConfirm: onSkipStepConfirm,
                });
                checklistDataLayer('skipChecklistStep', page, 'Memberships', step.title);
            });
        })
            .filter(Boolean);
        return mergedSteps;
    };
    const getProgress = () => {
        const items = getItems();
        if (!items.length) {
            return { title: '', percentage: 0 };
        }
        const completedStepsCount = items?.filter((step) => step.completed).length;
        const percentage = Math.floor((completedStepsCount * 100) / items.length);
        return {
            title: title ? `${title} (${percentage}% complete)` : `${percentage}% complete`,
            percentage,
        };
    };
    const getActive = () => membershipsOnboardingQuery.isSuccess && !(isOnboardingCompleted || isOnboardingHidden);
    const onSkipConfirm = () => {
        hideMembershipsOnboardingMutation.mutate([], {
            onSuccess: () => {
                membershipsOnboardingQuery.refetch();
            },
        });
        checklistDataLayer('skipChecklistConfirm', 'Home', 'Memberships');
    };
    const onSkip = () => {
        modal.open(ConfirmModal, {
            title: 'Are you sure you want to skip memberships checklist?',
            text: 'This action cannot be reversed.',
            confirmLabel: 'Skip',
            confirmButtonAnalytics: 'skipChecklistConfirm',
            onConfirm: onSkipConfirm,
        });
        checklistDataLayer('skipChecklist', 'Home', 'Memberships');
    };
    return {
        membershipsOnboardingQuery,
        hideMembershipsOnboardingMutation,
        progress: getProgress(),
        items: getItems(),
        active: getActive(),
        hidden: membershipsOnboardingQuery.isSuccess ? isOnboardingHidden : true,
        isLoading: dashboardFeatureFlags.membershipsOnboarding.enabled
            ? membershipsOnboardingQuery.isLoading
            : false,
        skipLabel: 'Skip memberships checklist',
        onSkipConfirm,
        onSkip,
    };
};
