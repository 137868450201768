import { Button, ButtonClean, ButtonGroup, HeadContent, Skeleton, Subsection, useMediaQueryContext, } from '@fourthwall/components';
import React from 'react';
import { routing } from '@utils/routing';
import { SamplesCreditBalance } from '../../components/SamplesCreditBalance';
export const SamplesCreditView = ({ credit, products }) => {
    const media = useMediaQueryContext();
    const renderProducts = () => {
        if (products.status === 'HIDDEN') {
            return null;
        }
        if (products.status === 'LOADING') {
            return (React.createElement("div", { style: { marginTop: 16 } },
                React.createElement(Skeleton, null,
                    React.createElement(Skeleton.Shape, { width: media.tablet ? 180 : '100%', height: 48 }))));
        }
        if (products.status === 'NO_PRODUCTS') {
            return (React.createElement("div", { style: { marginTop: 16 } },
                React.createElement("div", { style: { marginBottom: 16 } }, "You don't have any products that you can order samples for (only products without minimums are currently able to be ordered as samples)"),
                React.createElement(Button, { appearance: "primary", label: "Manage your products", onClick: products.onManage })));
        }
        if (products.status === 'AVAILABLE') {
            return (React.createElement("div", { style: { marginTop: 16 } },
                React.createElement(ButtonGroup, { fullWidth: !media.tablet },
                    React.createElement(Button, { label: "Create a new order", to: {
                            pathname: routing.settings.samples.order,
                        }, state: { showModal: true } }))));
        }
    };
    return (React.createElement(Subsection, { title: "Samples" },
        React.createElement(HeadContent, { title: "Samples \u2013 Settings" }),
        React.createElement("p", null,
            "Order pre-production samples of your products for quality checks and promotion. You can use sample credit (if available) or a credit card to pay for your samples.",
            ' ',
            React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.orderingSamples, target: "_blank" })),
        React.createElement("p", { style: { marginTop: 12, fontWeight: 600 } }, credit ? (React.createElement(SamplesCreditBalance, { label: "Your current samples credit", balance: credit })) : (React.createElement(Skeleton, null,
            React.createElement(Skeleton.Shape, { width: 200 })))),
        renderProducts()));
};
