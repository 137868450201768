import { Toasts } from '@fourthwall/components';
import { DnsApiClient } from '@fourthwall/services/lib/DnsApiClient';
import { useMutation } from '@tanstack/react-query';
export const useSendEntriesViaEmailMutation = () => {
    const mutationKey = 'useSendEntriesViaEmailMutation';
    const sendEntriesViaEmailMutation = useMutation({
        mutationKey: [mutationKey],
        mutationFn: (emailAddress) => {
            return DnsApiClient.sendEntriesViaEmail({ emailAddress });
        },
        onError: () => {
            Toasts.notify('Failed to send DNS records via email', {
                toastId: mutationKey,
                type: 'error',
            });
        },
    });
    return { sendEntriesViaEmailMutation };
};
