import { ApiBase } from './ApiBase';
class OnboardingApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.setChecklistVisibility = (payload) => {
            return this.put(`/onboarding/checklist-visibility`, payload);
        };
        this.setChecklistActionVisibility = (params, payload) => {
            return this.put(`/onboarding/checklist/${params.actionType}/visibility`, payload);
        };
        this.setRecommendationsActionVisibility = (params) => {
            return this.put(`/onboarding/recommendations/${params.actionType}/visibility`);
        };
        this.getSummary = () => {
            return this.get(`/onboarding/summary`);
        };
    }
}
export const OnboardingApiClient = new OnboardingApiClientModel();
