import Yup from '@fourthwall/utils/lib/yup';
import { usePlatformFeatureFlags } from '@components/utils/DashboardFeatureFlags/usePlatformFeatureFlags';
import { getShippingViewModel } from '@modules/Dashboard/Product/modules/shipping/viewModel/getShippingViewModel';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
const ShipmentSchema = Yup.object().shape({
    type: Yup.string(),
    days: Yup.object().when('type', {
        is: (type) => type === 'DAYS_RANGE',
        then: Yup.object().shape({
            daysMin: Yup.number().required().positive().integer().required().max(356),
            daysMax: Yup.number()
                .required()
                .positive()
                .integer()
                .max(356)
                .moreThan(Yup.ref('daysMin'), 'To must be greater than From.'),
        }),
    }),
    dates: Yup.object().when('type', {
        is: (type) => type === 'DATE_RANGE',
        then: Yup.object().shape({
            from: Yup.string().required(),
            to: Yup.string().required(),
        }),
    }),
});
export const useShippingValidator = () => {
    const { hasFeatureFlag } = usePlatformFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const adminMode = useAdminModeContext();
    const featureEnabled = (hasFeatureFlag('assisted_product_shipping') &&
        hasPermission('products.thirdPartyLogistics.edit')) ||
        Boolean(adminMode?.isActive);
    return (values) => {
        const vm = getShippingViewModel({
            values,
            featureEnabled,
        });
        if (vm.view === 'SHIPPING_3PL') {
            return {
                customsInformation: Yup.lazy(() => Yup.object().shape({
                    shortHsCode: Yup.string().nullable().required('Product code is required'),
                    countryOfOrigin: Yup.string()
                        .nullable()
                        .required('Country product was manufactured in is required'),
                })),
                shippingPackageSize: Yup.string().nullable().required('Shipping package size is required'),
            };
        }
        if (vm.view === 'WEIGHT_BASED_SELF_SHIPPING' ||
            vm.view === 'FLAT_SELF_SHIPPING' ||
            vm.view === 'SHIPPING_3PL_LEGACY' ||
            vm.view === 'BESPOKE_SHIPPING') {
            return {
                customsInformation: Yup.lazy(() => Yup.object().shape({
                    shortHsCode: Yup.string().nullable(),
                    countryOfOrigin: Yup.string().nullable(),
                })),
                shipment: ShipmentSchema,
            };
        }
        return {};
    };
};
