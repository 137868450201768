import { ConfirmModal, useModalContext } from '@fourthwall/components';
import React from 'react';
import { useCreateOpenAPIConfigurationMutation } from './hooks/useCreateOpenAPIConfigurationMutation';
import { useDeleteOpenAPIConfigurationMutation } from './hooks/useDeleteOpenAPIConfigurationMutation';
import { useOpenAPIConfigurationQuery } from './hooks/useOpenAPIConfigurationQuery';
import { OpenAPIView } from './OpenAPIView';
export const OpenAPIContainer = () => {
    const { open } = useModalContext();
    const { openAPIConfigurationQuery, invalidateOpenAPIConfigurationQuery } = useOpenAPIConfigurationQuery();
    const { createOpenAPIConfigurationMutation } = useCreateOpenAPIConfigurationMutation();
    const { deleteOpenAPIConfigurationMutation } = useDeleteOpenAPIConfigurationMutation();
    const user = openAPIConfigurationQuery.isSuccess
        ? openAPIConfigurationQuery.data.configuration
        : undefined;
    const handleUserCreate = () => {
        createOpenAPIConfigurationMutation.mutate([], {
            onSuccess: () => {
                invalidateOpenAPIConfigurationQuery();
            },
        });
    };
    const handleUserRemove = () => {
        open(ConfirmModal, {
            title: `Are you sure that you want to remove API User?`,
            confirmLabel: 'Remove',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                deleteOpenAPIConfigurationMutation.mutate([], {
                    onSuccess: () => {
                        invalidateOpenAPIConfigurationQuery();
                    },
                });
            },
        });
    };
    return (React.createElement(OpenAPIView, { user: user, isLoading: createOpenAPIConfigurationMutation.isLoading || deleteOpenAPIConfigurationMutation.isLoading, onUserCreate: !openAPIConfigurationQuery.isLoading ? handleUserCreate : undefined, onUserRemove: handleUserRemove }));
};
