import React from 'react';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { QuantityBadge } from '@components/common/QuantityBadge';
import * as S from './styled';
export const CollectionTableCell = ({ name, quantity, photoUrl }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.Photo, null,
            React.createElement(PhotoThumbnail, { url: photoUrl, size: "large" }),
            quantity > 0 && React.createElement(QuantityBadge, { quantity: quantity })),
        React.createElement(S.Name, null, name)));
};
