import Yup from '@fourthwall/utils/lib/yup';
import { isProvidingMinimumProfitOrZero, isValidVariantPriceValue, htmlTextContentMax, } from '@fourthwall/utils/lib/validation';
import { getPrice } from '@fourthwall/utils';
export const getVariantPriceSchema = ({ variants, variantCost, minimumProfit, }) => Yup.object().shape({
    value: Yup.number()
        .required()
        .test('isValidVariantPriceValue', 'Products must have a minimum price of 0.99 or be free.', (value) => isValidVariantPriceValue(value))
        .test('providesMinimumProfit', `The price of the product must be higher.`, (value, { options, createError, path }) => {
        const cost = variantCost || (variants && variants[options.index]?.cost?.value) || 0;
        if (isProvidingMinimumProfitOrZero({ value, cost, minimumProfit })) {
            return true;
        }
        return createError({
            path,
            message: `Must be ${getPrice(cost + minimumProfit)} or higher.`,
        });
    }),
});
export const variantWeightSchema = Yup.object().shape({
    value: Yup.number().positive().required(),
    unit: Yup.string().required(),
});
export const digitalItemVariantWeightSchema = Yup.object({
    value: Yup.number().required(),
    unit: Yup.string().required(),
});
export const variantSkuSchema = Yup.string().min(4).max(40);
export const productValidatorSchema = ({ manufacturingService, variants, minimumProfit, shouldValidateSellingPriceValue, }) => {
    return {
        name: Yup.string().max(200).required(),
        description: Yup.string().test('htmlTextContentMax', 'Must have less than 4000 characters.', (value) => (value ? htmlTextContentMax(4000)(value) : true)),
        sellingPriceValue: Yup.number()
            .test('isValidVariantPriceValue', 'Products must have a minimum price of 0.99 or be free.', (value) => isValidVariantPriceValue(value))
            .test('providesMinimumProfit', `Your selling price must be ${getPrice((variants[0]?.cost?.value || 0) + (minimumProfit || 0))} or higher.`, (value) => {
            if (!shouldValidateSellingPriceValue)
                return true;
            const cost = variants[0]?.cost?.value || 0;
            if (!cost || !minimumProfit) {
                return true;
            }
            return (value || 0) - cost >= minimumProfit;
        }),
        variants: Yup.array().of(Yup.object().shape({
            price: getVariantPriceSchema({ variants, minimumProfit }),
            weight: manufacturingService === 'DIGITAL_ITEM'
                ? digitalItemVariantWeightSchema
                : variantWeightSchema,
            sku: variantSkuSchema.test('conditionalRequired', 'This field is required.', function (value) {
                if (this.options.context?.values.visualHints?.customSkus) {
                    return !!value;
                }
                return true;
            }),
        })),
        visualHints: Yup.object().shape({
            customSkus: Yup.bool(),
        }),
        withMembersOnly: Yup.boolean(),
        membersOnlyType: Yup.string().oneOf(['ALL_TIERS', 'SELECTED_TIERS']),
        membersOnlyTierIds: Yup.object().when('membersOnlyType', {
            is: (membersOnlyType) => membersOnlyType === 'SELECTED_TIERS',
            then: Yup.object().test('isEmpty', 'At least one selected tier is required', (value) => Object.values(value).filter(Boolean).length > 0),
        }),
        manufacturingService: Yup.string(),
        bespokeProductId: Yup.string().nullable().optional(),
    };
};
