import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const DragHandle = styled.div `
  opacity: 0;
  color: ${Colors.GS600};
  cursor: grab;
`;
export const Container = styled.div `
  display: flex;

  position: relative;
  z-index: 1;

  align-items: center;
  justify-content: space-between;

  padding: 12px 24px;

  background-color: ${Colors.GS0};
  cursor: pointer;

  line-height: 1.5;

  &:hover {
    background-color: ${Colors.GS200};

    ${DragHandle} {
      opacity: 1;
    }
  }
`;
export const Title = styled.div `
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
