import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  overflow: hidden;
`;
export const MainContent = styled.div `
  flex: 1;

  ${({ appearance }) => appearance === 'DARK' &&
    css `
      background-color: ${Colors.GS1000};
      color: ${Colors.GS100};
    `}

  ${({ appearance }) => appearance === 'LIGHT' &&
    css `
      background-color: ${Colors.GS0};
      color: ${Colors.GS1000};
    `}
`;
export const MainHeader = styled.div `
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Content = styled.div `
  max-height: 50vh;
  height: 100%;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
`;
export const MainFooter = styled.div `
  padding: 0 40px;
  height: 115px;
  display: grid;
  grid-template-rows: 34px 1fr 34px;
  grid-template-columns: 150px 1fr 150px;
`;
export const MainFooterLeft = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-row-start: 1;
  grid-row-end: 4;
`;
export const MainFooterTop = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 1;
  grid-column-start: 2;
`;
export const MainFooterContent = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 2;
  grid-column-start: 2;
`;
export const MainFooterRight = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-start: 3;
  grid-row-start: 1;
  grid-row-end: 4;
`;
export const SidebarContent = styled.div `
  width: 400px;

  & > div {
    width: 400px;
  }
`;
