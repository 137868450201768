import React from 'react';
import { GiveawayCostsBreakdownView } from './GiveawayCostsBreakdownView';
import { useGiveawayContributionCostsBreakdownQuery } from './hooks/useGiveawayContributionCostsBreakdownQuery';
export const GiveawayCostsBreakdownContainer = ({ giveawayId, }) => {
    const { giveawayContributionCostsBreakdownQuery } = useGiveawayContributionCostsBreakdownQuery([
        { giveawayId },
    ]);
    const costsBreakdown = giveawayContributionCostsBreakdownQuery.data
        ? { costsBreakdown: giveawayContributionCostsBreakdownQuery.data }
        : undefined;
    return React.createElement(GiveawayCostsBreakdownView, { costsBreakdown: costsBreakdown });
};
