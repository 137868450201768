import React from 'react';
import { Button, Dropdown, Icon, Tag, useMediaQueryContext } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import * as S from './styled';
export const Suggestion = ({ title, description, badge, content, buttonLabel, buttonHref, buttonTo, buttonTarget, buttonLoading, buttonDisabled, imageUrl, size = 'medium', analytics, onButtonClick, onDismiss, }) => {
    const media = useMediaQueryContext();
    const renderDropdown = () => {
        if (!onDismiss)
            return null;
        return (React.createElement(S.DropdownButtonContainer, null,
            React.createElement(Dropdown, { content: onDismiss && React.createElement(Dropdown.Item, { label: "Hide", onClick: onDismiss }), position: "bottom-end" },
                React.createElement(S.DropdownButton, null,
                    React.createElement(Icon, { component: DotsHorizontalIcon, height: 16 })))));
    };
    const renderContent = () => (content ? React.createElement(S.Content, null, content) : null);
    const renderButton = () => buttonLabel ? (React.createElement(S.ButtonContainer, null,
        React.createElement(Button, { label: buttonLabel, to: buttonTo, href: buttonHref, target: buttonTarget, appearance: size === 'large' ? 'primary' : 'secondary', size: "medium", loading: buttonLoading, disabled: buttonDisabled, analytics: analytics, onClick: onButtonClick, fullWidth: true }))) : null;
    const renderBadge = () => badge ? (React.createElement(S.Badge, null,
        React.createElement(Tag, { ...badge, display: "inline-flex" }))) : null;
    const renderImage = () => (React.createElement(S.ImageContainer, null,
        React.createElement(S.Image, { src: imageUrl })));
    return (React.createElement(S.Container, { size: size, "data-testid": "Suggestion" },
        React.createElement(S.Main, null,
            React.createElement(S.TextualContent, null,
                React.createElement(S.Title, null,
                    title,
                    renderBadge()),
                React.createElement(S.Description, null, description)),
            !media.tablet && renderImage(),
            media.tablet && (React.createElement(React.Fragment, null,
                renderContent(),
                renderButton()))),
        media.tablet && renderImage(),
        !media.tablet && (React.createElement(React.Fragment, null,
            renderContent(),
            renderButton())),
        renderDropdown()));
};
