import React from 'react';
import { ProductShippingByCreator } from '@modules/Dashboard/Product/modules/shipping/ProductShippingByCreator';
import { ProductShippingNotConfigurable } from '@modules/Dashboard/Product/modules/shipping/ProductShippingNotConfigurable';
import { getShippingViewModel } from '@modules/Dashboard/Product/modules/shipping/viewModel/getShippingViewModel';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { usePlatformFeatureFlags } from '@components/utils/DashboardFeatureFlags/usePlatformFeatureFlags';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import { ProductShippingBespoke } from '@modules/Dashboard/Product/modules/shipping/ProductShippingBespoke/ProductShippingBespoke';
import { ProductShipping3PL } from './ProductShipping3PL/ProductShipping3PL';
export const Shipping = ({ initialValues }) => {
    const { values } = useFormikContext();
    const { hasFeatureFlag } = usePlatformFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const adminMode = useAdminModeContext();
    const featureEnabled = (hasFeatureFlag('assisted_product_shipping') &&
        hasPermission('products.thirdPartyLogistics.edit')) ||
        Boolean(adminMode?.isActive);
    const vm = getShippingViewModel({
        values,
        featureEnabled,
    });
    if (vm.view === 'SHIPPING_3PL') {
        return React.createElement(ProductShipping3PL, { initialValues: initialValues });
    }
    if (vm.view === 'NO_SHIPPING') {
        return null;
    }
    if (vm.view === 'WEIGHT_BASED_SELF_SHIPPING' || vm.view === 'FLAT_SELF_SHIPPING') {
        return React.createElement(ProductShippingByCreator, { initialValues: initialValues });
    }
    if (vm.view === 'BESPOKE_SHIPPING') {
        return React.createElement(ProductShippingBespoke, { initialValues: initialValues });
    }
    // TODO: LEGACY 3PL goes here as well (refactor after 3PL starts using new shipping form)
    // remove all fieldsets from this component after that as well
    return React.createElement(ProductShippingNotConfigurable, { initialValues: initialValues });
};
