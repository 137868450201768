import React from 'react';
import { Button } from '@fourthwall/components';
import { PhoneIcon } from '@fourthwall/icons';
import { useDashboardStatisticsQuery } from '@queries';
import { routing } from '../../../utils/routing';
// import { useCurrentShop } from '../../../modules/Dashboard/hooks/useCurrentShop'
import { useOrdersQuery } from '../../../hooks/useOrdersQuery';
import { useDashboardPermissions } from '../../utils/DashboardPermissions';
const staleTime = 60_000; // 1 minute
/**
 * SELF_SERVICE && ordersTotal <= 3
 */
export const BookACall = () => {
    const { hasPermission } = useDashboardPermissions();
    // const {
    //   currentShopQuery: { data },
    // } = useCurrentShop()
    const { ordersQuery } = useOrdersQuery({ types: ['COMMON'], limit: 1 }, { staleTime, enabled: hasPermission('contributions.orders.view') });
    const { dashboardStatisticsQuery } = useDashboardStatisticsQuery();
    // if (!data || data.leadTier != 'SELF_SERVICE') return null
    if (dashboardStatisticsQuery?.data?.status != 'DASHBOARD_STATISTICS')
        return null;
    if (!dashboardStatisticsQuery.data.bookACall)
        return null;
    const ordersTotal = ordersQuery.data?.page?.elementsTotal;
    if (ordersTotal === undefined || ordersTotal > 2)
        return null;
    return (React.createElement(Button, { label: "Book a call with us", href: routing.external.hubspot.bookAcall, target: "_blank", size: "small", leftIcon: React.createElement(PhoneIcon, null) }));
};
