import styled, { createGlobalStyle, css } from 'styled-components';
import { Colors } from '../../colors';
import { mediaQueries } from '../../providers/MediaQueryProvider';
export const StyleOverride = createGlobalStyle `
  .CalendarMonth_caption {
    padding-bottom: 44px !important;
  }

  .CalendarMonth_caption strong {
    font-size: 16px;
    color: ${Colors.GS1000};
    font-weight: 500;
  }

  .DayPickerNavigation_button__default {
    display: flex;
    align-items: center;
    top: 15px !important;
    right: 17px;
    width: 40px;
    height: 40px;
    padding: 12px !important;

    outline: none !important;
    border: none !important;
    border-radius: 0 !important;
    background: ${Colors.GS200} !important;

    content: url("data:image/svg+xml,%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 30l14-14L16 2m14 14H-.06' stroke='%2310110D' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");

    & svg {
      display: none;
    }

    &:hover {
      background: ${Colors.GS500} !important;
    }

    &:first-of-type {
      left: 17px;

      content: url("data:image/svg+xml,%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 30L2 16 16 2M2 16h30.06' stroke='%2310110D' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
  }

  .DayPicker_weekHeader_ul {
    margin: 7px 0;

    color: ${Colors.GS600};
  }

  .CalendarMonth_table {
    margin-top: 8px;
  }

  .CalendarDay {
    font-size: 16px !important;
  }

  .CalendarDay__default {
    border: 1px solid ${Colors.GS300} !important;
  }

  .CalendarDay__default.CalendarDay__selected,
  .CalendarDay__default.CalendarDay__selected:hover {
    color: ${Colors.GS0} !important;
    background: ${Colors.B600} !important;
    border: 1px double #1b63ef !important;
  }

  .CalendarDay__selected_span {
    background: ${Colors.B500} !important;
    border: 1px double #1b63ef !important;
  }

  .CalendarDay__hovered_span {
    background: ${Colors.B500} !important;
    color: ${Colors.GS0} !important;
    border: 1px double #1b63ef !important;
  }

  .CalendarDay__today {
    font-weight: 500;
  }
`;
export const Container = styled.div `
  display: inline-block;

  width: 100%;

  ${({ scrollableContent }) => scrollableContent &&
    css `
      max-height: 45vh;
      overflow: scroll;
    `}

  ${mediaQueries.tablet} {
    width: auto;
    border: 1px solid ${Colors.GS500};
  }
`;
export const InputsContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid ${Colors.GS400};
  padding: 20px;

  background: ${Colors.GS0};
`;
export const InputContainer = styled.div `
  & + & {
    margin-left: 16px;
  }
`;
export const ClearButtonContainer = styled.div `
  margin-top: -4px;
  padding-bottom: 20px;
  text-align: center;
  background: ${Colors.GS0};
`;
export const CalendarContainer = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
