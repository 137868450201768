import React from 'react';
import { FileUploader } from './components/FileUploader/FileUploader';
import { UploadedMediaDisplayer } from './components/UploadedMediaDisplayer/UploadedMediaDisplayer';
import { useUpload } from './hooks/useUpload';
const DropzoneSubsectionComp = () => {
    const { uploadFile, removeFile, onFileRejection, vm: { file, fileType, viewStatus }, } = useUpload();
    if (viewStatus === 'SUCCESS') {
        return React.createElement(UploadedMediaDisplayer, { vm: { file, fileType }, onRemove: removeFile });
    }
    return (React.createElement(FileUploader, { isLoading: viewStatus === 'LOADING', handleFileDrop: uploadFile, handleDropRejection: onFileRejection }));
};
export const DropzoneSubsection = React.memo(DropzoneSubsectionComp);
