import React, { Children } from 'react';
import * as S from './ButtonGroup.styled';
export const ButtonGroup = ({ space = 'small', justify = 'left', align, direction = 'row', fullWidth = false, children, }) => {
    const renderButton = (child, index) => {
        if (!child)
            return null;
        return (React.createElement(S.ButtonWrapper, { key: index, space: space, direction: direction, align: align, fullWidth: fullWidth }, child));
    };
    return (React.createElement(S.Container, { justify: justify, align: align, direction: direction, fullWidth: fullWidth }, Children.map(children, renderButton)));
};
