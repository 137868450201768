import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const AvatarContainer = styled.div `
  flex-shrink: 0;
`;
export const Name = styled.div `
  line-height: 1.5;
`;
export const Info = styled.div `
  font-size: 12px;
  line-height: 1.3;
  color: ${Colors.GS600};
`;
