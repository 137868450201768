import { CreatorMessagesApiClient } from '@fourthwall/services/lib/CreatorMessagesApiClient';
import { useQuery } from '@tanstack/react-query';
export const useGetCreatorMessageCollectionsQuery = (page) => {
    const useCollectionsQuery = useQuery({
        queryKey: ['useGetCreatorMessageCollectionsQuery', page],
        queryFn: async () => {
            const { data } = await CreatorMessagesApiClient.getCreatorMessageCollections(page);
            return data;
        },
        keepPreviousData: true,
        staleTime: 60_000,
    });
    return {
        useCollectionsQuery,
        collectionsData: useCollectionsQuery.data,
    };
};
