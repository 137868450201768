import React from 'react';
import { PromotionCodeTableCell } from '@modules/Dashboard/Promotions/modules/PromotionCodes/components/PromotionCodeTableCell';
import { PromotionStatusTag } from '@components/common/PromotionStatusTag';
import { PromotionUsageTableCell } from '@modules/Dashboard/Promotions/modules/PromotionCodes/components/PromotionUsageTableCell';
import * as S from './styled';
export const PromotionCodeListItem = ({ promotion }) => {
    return (React.createElement(S.Container, null,
        React.createElement(PromotionCodeTableCell, { promotion: promotion }),
        React.createElement(PromotionStatusTag, { promotion: promotion }),
        React.createElement(S.UsageWrapper, null,
            React.createElement(PromotionUsageTableCell, { promotion: promotion }),
            " used")));
};
