import { STATE_OPTIONS } from '@fourthwall/utils/lib/inputs';
import * as yup from 'yup';
import Yup from '@fourthwall/utils/lib/yup';
export const validationSchema = Yup.object().shape({
    address1: Yup.string().required(),
    address2: Yup.string(),
    city: Yup.string().required(),
    country: Yup.string().required(),
    state: Yup.string().when('country', {
        is: (country) => !!STATE_OPTIONS[country],
        then: yup.string().required(),
    }),
    zip: Yup.string().required(),
});
