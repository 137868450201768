import { ApiBase } from '../ApiBase';
class PromotionsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getPromotions = (params) => {
            return this.get('/promotions', params);
        };
        this.getPromotion = (params) => {
            return this.get(`/promotions/${params.promotionId}`);
        };
        this.createPromotion = (payload) => {
            return this.post('/promotions', payload);
        };
        this.enablePromotion = (params) => {
            return this.put(`/promotions/${params.promotionId}/state/live`);
        };
        this.disablePromotion = (params) => {
            return this.put(`/promotions/${params.promotionId}/state/ended`);
        };
        this.deletePromotion = (params) => {
            return this.delete(`/promotions/${params.promotionId}`);
        };
        this.updatePromotion = (params, payload) => {
            return this.put(`/promotions/${params.promotionId}`, payload);
        };
    }
}
export const PromotionsApiClient = new PromotionsApiClientModel();
