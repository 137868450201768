import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  margin: -4px 0;
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const Arrow = styled.div `
  color: ${Colors.GS600};
`;
export const Details = styled.div `
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${Colors.GS600};
`;
export const CompareAtPrice = styled.div `
  text-decoration: line-through;
`;
export const Separator = styled.div `
  width: 1px;
  height: 12px;
  background-color: ${Colors.GS500};
`;
