import styled, { css } from 'styled-components';
import { Colors } from '../../../../../../colors';
export const AccordionCustomItemStyles = (paddingBottom) => {
    return {
        paddingTop: 8,
        paddingBottom,
    };
};
export const TotalProfitContainer = styled.div `
  width: 100%;
  padding: 0 20px;
  font-size: 14px;
  color: ${Colors.GS600};
  display: flex;
  justify-content: flex-end;
`;
export const TotalProfitCopy = styled.p `
  max-width: 65%;
  text-align: right;

  ${({ top }) => top &&
    css `
      position: relative;
      top: ${top}px;
    `}
`;
export const Heading = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
`;
export const CustomSoldContainer = styled.div `
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
`;
export const QuantityInputContainer = styled.div `
  font-weight: normal;
  width: 125px;
  position: relative;
  overflow: visible;
`;
export const QuantityInputError = styled.div `
  position: absolute;
  top: 100%;
  white-space: nowrap;
`;
export const AccordionCustomItem = styled.div `
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `${paddingBottom}px` : '0')};
`;
