export const mapAccessTierNames = (access, tiers) => {
    if (!tiers)
        return;
    if (access.type === 'Public')
        return 'Everyone';
    if (!access || tiers?.length === access.tiers?.length)
        return 'All members';
    const selectedTiers = access.tiers?.map(({ id }) => tiers?.find((tier) => tier.id === id));
    return selectedTiers?.map((tier) => tier?.name).join(', ');
};
