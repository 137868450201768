import { DateString, List, PriceString, ProfitString, TableBreakdown, Tag, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { getGiveawayBreakdownLink } from './utils/getGiveawayBreakdownLink';
import { GiveawayBreakdownListItem } from '../GiveawayBreakdownListItem';
import { getGiveawayBreakdownTagProps } from '../../utils/getGiveawayBreakdownTagProps';
import { GIVEAWAYS_CONSTANTS } from '../../constants';
import { getGiveawayBreakdownTypeLabel } from '../../utils/getGiveawayBreakdownTypeLabel';
import { isFromLocationStateState } from '../../utils/isFromLocationStateState';
import { createHeadlineDataId } from '../../utils/createHeadlineDataId';
export const GiveawaysItemBreakdownData = ({ giveaway, hovered, }) => {
    const media = useMediaQueryContext();
    const { hasPermission } = useDashboardPermissions();
    const { close } = useModalContext();
    const location = useLocation();
    const { giveawayId } = giveaway;
    const data = giveaway.giveawayBreakdown;
    const locationState = {
        from: { label: GIVEAWAYS_CONSTANTS.title, location, state: { giveawayId } },
    };
    if (!media.tablet) {
        return (React.createElement(List, { data: data, renderItem: (giveawayBreakdown) => (React.createElement(GiveawayBreakdownListItem, { giveawayBreakdown: giveawayBreakdown })), getItemLink: getGiveawayBreakdownLink, locationState: locationState, onClick: () => close() }));
    }
    return (React.createElement(TableBreakdown, { headline: "Twitch Gift Breakdown", headlineDataId: createHeadlineDataId(giveaway), columns: [
            {
                render: (giveawayBreakdown) => (React.createElement(DateString, { date: giveawayBreakdown.type === 'GIFT_REDEEM'
                        ? giveawayBreakdown.redeemedAt
                        : giveawayBreakdown.createdAt, format: "MMM D" })),
                flex: 0.8,
            },
            {
                render: (giveawayBreakdown) => getGiveawayBreakdownTypeLabel(giveawayBreakdown.type),
                flex: 1.2,
            },
            {
                render: (giveawayBreakdown) => (React.createElement("span", { title: giveawayBreakdown.supporter.email }, giveawayBreakdown.supporter.username || giveawayBreakdown.supporter.email)),
                flex: 1.8,
            },
            {
                render: (giveawayBreakdown) => (React.createElement(Tag, { ...getGiveawayBreakdownTagProps(giveawayBreakdown.status), display: "inline-flex" })),
                flex: 2,
            },
            {
                render: (giveawayBreakdown) => (React.createElement(PriceString, { ...giveawayBreakdown.total })),
            },
            {
                hidden: !hasPermission('contributions.profit'),
                render: (giveawayBreakdown) => (React.createElement(ProfitString, { ...giveawayBreakdown.profit })),
                flex: 1.2,
            },
        ], data: data, footer: React.createElement(TableBreakdown.Footer, { text: `${giveaway.giftsLeftToRedeem} ${pluralize(giveaway.giftsLeftToRedeem, 'gift')} left to redeem` }), hovered: hovered, initialOpen: isFromLocationStateState(location.state)
            ? giveaway.giveawayId === location.state.giveawayId
            : undefined, rowComponent: Link, getRowComponentProps: (giveaway) => ({
            to: getGiveawayBreakdownLink(giveaway),
            state: locationState,
        }) }));
};
