import { Toasts } from '@fourthwall/components';
import { HasuraApiClient } from '@fourthwall/services/lib/HasuraApiClient';
import { useMutation } from '@tanstack/react-query';
import { downloadCsvFile } from '@fourthwall/utils/lib/downloadCsvFile';
import { useCurrentShopQuery } from '../../../../hooks/useCurrentShopQuery';
export const useMailingListReport = () => {
    const { currentShopQuery } = useCurrentShopQuery();
    const { mutate } = useMutation({
        mutationFn: async (shopId) => {
            const { data } = await HasuraApiClient.getMailingListReport(shopId);
            return data;
        },
    });
    const handleSuccess = (data) => {
        if (!data.length) {
            return Toasts.notify('There is no data to export.', { type: 'info' });
        }
        downloadCsvFile(data, `${currentShopQuery.data?.name}-mailing-list-report`);
    };
    const download = () => {
        if (!currentShopQuery.data)
            return;
        mutate(currentShopQuery.data.id, {
            onSuccess: ({ admin_mailing_lists_report: data }) => {
                handleSuccess(data);
            },
        });
    };
    return {
        mailingListReport: {
            download,
        },
    };
};
