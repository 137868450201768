import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { useMutation } from '@tanstack/react-query';
export const useCreateDiscordIntegrationMutation = () => {
    const createDiscordIntegrationMutation = useMutation({
        mutationFn: async () => {
            const { data } = await MembershipsCreatorApiClient.createDiscordIntegration();
            return data;
        },
    });
    return { createDiscordIntegrationMutation };
};
