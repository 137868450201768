import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
export const Container = styled.label `
  display: flex;
  cursor: pointer;
`;
const getColor = ({ theme }) => {
    if (theme.automated?.styles?.paleTextColor) {
        return theme.automated?.styles.paleTextColor;
    }
    return Colors.GS600;
};
export const LabelContainer = styled.div `
  width: 100%;
  margin-left: 12px;
`;
export const Label = styled.div `
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: ${getAutomatedStyleProp('colorOnBackground', Colors.GS1000)};

  user-select: none;

  ${({ disabled }) => disabled &&
    css `
      color: ${getColor};
      cursor: not-allowed;
    `}
`;
export const TooltipContainer = styled.div `
  position: relative;
  z-index: 10;
  margin-left: 6px;
`;
