import { InputUI, Loader, Subsection, Toasts, Upload, useMediaQueryContext, } from '@fourthwall/components';
import { UploadIcon } from '@fourthwall/icons';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import get from 'lodash-es/get';
import React from 'react';
import { getRejectionErrors } from '@utils/dropzone';
import { RemovableUpload } from '../../../../components/RemovableUpload/RemovableUpload';
import { UploadedImage } from '../../../../components/UploadedImage/UploadedImage';
import * as S from './ImageFields.styled';
export const ImageFields = ({ imageFieldName, image, subsectionTitle, isUploading }) => {
    const media = useMediaQueryContext();
    const { errors, setFieldValue } = useFormikContext();
    const handleImageUpload = (files) => {
        if (files) {
            setFieldValue(imageFieldName, files[0]);
        }
    };
    const handleImageRemove = () => {
        setFieldValue(imageFieldName, undefined);
    };
    const handleDropRejection = (rejectedFiles) => {
        getRejectionErrors(rejectedFiles).forEach((rejection) => {
            Toasts.notify(rejection);
        });
    };
    const renderOverlay = () => {
        if (isUploading) {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.Overlay, null),
                React.createElement(S.MessageContainer, null,
                    React.createElement(Loader, { size: "large", appearance: "primary" }),
                    React.createElement(S.Message, null, "Uploading"))));
        }
    };
    const renderImages = () => {
        if (!image) {
            return null;
        }
        return (React.createElement(RemovableUpload, { onRemove: handleImageRemove },
            React.createElement(S.Container, null,
                React.createElement(UploadedImage, { image: image, objectFit: "contain" }),
                renderOverlay())));
    };
    const renderUpload = () => {
        if (image) {
            return null;
        }
        return (React.createElement("div", { style: { height: 120 } },
            React.createElement(Upload, { accept: {
                    'image/*': [],
                }, title: media.tablet ? (React.createElement("span", null,
                    "Drop image or ",
                    React.createElement("u", null, "browse"))) : ('Browse'), invalid: !!get(errors, imageFieldName), icon: UploadIcon, iconSize: 20, maxSize: 10485760, orientation: "horizontal", onFileDrop: handleImageUpload, onFileDropRejected: handleDropRejection })));
    };
    return (React.createElement(Subsection, { title: subsectionTitle },
        renderImages(),
        renderUpload(),
        get(errors, imageFieldName) && React.createElement(InputUI.Error, null, get(errors, imageFieldName))));
};
