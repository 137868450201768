import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { SECTION_NAMES } from '@constants/sectionNames';
import { routing } from '@utils/routing';
import React from 'react';
import { AnalyticsView } from './AnalyticsView';
import { useAnalytics } from './hooks/useAnalytics/useAnalytics';
import { useAnalyticsParams } from './hooks/useAnalyticsParams';
import { GoogleAnalyticsBarContainer } from './modules/GoogleAnalyticsBar';
export const AnalyticsContainer = () => {
    const { params, rangeSelect, rangePicker, precisionSelect } = useAnalyticsParams();
    const { analytics } = useAnalytics(params);
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const toolbarItems = [
        dashboardFeatureFlags.analyticsTrackingPixels.enabled
            ? {
                label: 'Tracking pixels',
                to: routing.analytics.trackingPixels,
            }
            : undefined,
    ].filter(Boolean);
    return (React.createElement(AnalyticsView, { title: SECTION_NAMES.analytics, toolbarItems: toolbarItems, rangeSelect: rangeSelect, rangePicker: rangePicker, precisionSelect: precisionSelect, barContent: React.createElement(GoogleAnalyticsBarContainer, null), profit: analytics.profit, membershipsTotalMembers: analytics.membershipsTotalMembers, membershipsTotalFreeMembers: analytics.membershipsTotalFreeMembers, membershipsMembersChange: analytics.membershipsMembersChange, membershipsFreeMembersChange: analytics.membershipsFreeMembersChange, contributions: analytics.contributions, contributionValue: analytics.contributionValue, topProducts: analytics.topProducts, salesByCountry: analytics.salesByCountry, membershipsTopTiers: analytics.membershipsTopTiers }));
};
