import React from 'react';
import { BaseContent, Button, ButtonGroup, Subsection, useModalContext, } from '@fourthwall/components';
import { CreateProductModal } from '@modules/Dashboard/Products';
import { routing } from '@utils/routing';
import { useOffersTotal } from '@hooks';
export const EmptyProductsApprovalStatus = ({ appName }) => {
    const offersTotal = useOffersTotal();
    const { open } = useModalContext();
    // loading or has public offers
    if (!offersTotal || !!offersTotal.public)
        return null;
    const status = offersTotal.all === 0 ? 'NO_OFFERS' : 'NO_PUBLIC_OFFERS';
    const props = {
        NO_OFFERS: {
            description: 'You haven’t created any products yet.',
            button: {
                label: 'Create your first product',
                onClick: () => {
                    open(CreateProductModal);
                },
            },
        },
        NO_PUBLIC_OFFERS: {
            description: `You don’t have any live products to show on ${appName}.`,
            button: {
                label: 'Manage your products',
                to: routing.products.all.self,
            },
        },
    };
    return (React.createElement(Subsection, { title: "Product approval status" },
        React.createElement(BaseContent, null, props[status].description),
        React.createElement("div", { style: { marginTop: 16 } },
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { appearance: "primary", ...props[status].button })))));
};
