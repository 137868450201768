import { Button, ButtonClean, Icon, useMediaQueryContext } from '@fourthwall/components';
import { ArrowShortDownIcon, TickIcon } from '@fourthwall/icons';
import kebabCase from 'lodash-es/kebabCase';
import React, { useEffect, useState } from 'react';
import * as S from './styled';
export const OnboardingList = ({ items }) => {
    const { tablet } = useMediaQueryContext();
    const [openedIndex, setOpenedIndex] = useState(items.findIndex((item) => !item.completed) || 0);
    useEffect(() => {
        if (items[openedIndex]?.completed) {
            setOpenedIndex(items.findIndex((item) => !item.completed) || 0);
        }
    }, [items, openedIndex]);
    return (React.createElement("ol", null, items.map((item, index) => {
        const isOpen = openedIndex === index && !item.completed;
        const getAppearance = () => {
            if (item.completed)
                return 'success';
            if (isOpen)
                return 'active';
            return 'normal';
        };
        const handleClick = () => {
            if (!isOpen && !item.completed) {
                setOpenedIndex(index);
            }
        };
        return (React.createElement(S.ItemContainer, { key: kebabCase(item.title), completed: !!item.completed, open: isOpen, isNextOpen: openedIndex === index + 1 && !items[index + 1]?.completed, last: index === items.length - 1, onClick: handleClick },
            React.createElement(S.ItemHoverBackground, null),
            React.createElement(S.ItemNumberContainer, null,
                React.createElement(S.ItemNumber, { appearance: getAppearance() }, item.completed ? React.createElement(Icon, { component: TickIcon, height: 12 }) : index + 1)),
            React.createElement(S.ItemRow, null,
                React.createElement(S.ItemMain, null,
                    React.createElement(S.ItemHeader, { float: !!item.node },
                        React.createElement("div", null,
                            React.createElement(S.ItemTitleWrapper, null,
                                React.createElement(S.ItemTitle, { active: isOpen }, item.title),
                                React.createElement(S.ItemArrow, null,
                                    React.createElement(Icon, { component: ArrowShortDownIcon, height: 12 })),
                                item.completed && item.completedButton && (React.createElement(S.ItemCompletedButtonContainer, null,
                                    React.createElement(ButtonClean, { ...item.completedButton, appearance: "secondary" })))),
                            isOpen && React.createElement(S.ItemDescription, null, item.description)),
                        isOpen && (React.createElement(S.ItemFooter, { float: !!item.node },
                            React.createElement(Button, { appearance: "primary", ...item.primaryButton, analytics: "checklistStep" }),
                            item.onSkip && (React.createElement(S.ItemSkipButton, { onClick: item.onSkip, "data-analytics": "skipChecklistStep" }, tablet ? 'Skip this step' : 'Skip'))))),
                    isOpen && item.node && React.createElement("div", { style: { marginTop: 20 } }, item.node)),
                isOpen && tablet && item.imageUrl && React.createElement(S.ItemImage, { src: item.imageUrl }))));
    })));
};
