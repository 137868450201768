import React from 'react';
import { Accordion } from '../../../../../common/Accordion';
import { AccordionCustomItem, AccordionItem } from './AccordionItem';
export const QuantityAccordion = ({ accordionState, autoAdjustedSellingPrice, data, includeFeesValue, soldValue, soldValueError, onChange, onSoldValueChange, }) => {
    return (React.createElement(Accordion, null, accordionState.map((state, index) => {
        const dataItem = data.quantityPricing?.length ? data.quantityPricing[index] : undefined;
        if (dataItem) {
            return index === accordionState.length - 1 ? (React.createElement(AccordionCustomItem, { autoAdjustedSellingPrice: autoAdjustedSellingPrice, data: dataItem, expanded: state, includeFeesValue: includeFeesValue, key: `${dataItem.numberOfUnits}-${index}`, soldValue: soldValue, soldValueError: soldValueError, onChange: () => onChange(index), onSoldValueChange: onSoldValueChange })) : (React.createElement(AccordionItem, { autoAdjustedSellingPrice: autoAdjustedSellingPrice, data: dataItem, expanded: state, includeFeesValue: includeFeesValue, isMinimumQuantity: index === 0, key: `${dataItem.numberOfUnits}-${index}`, onChange: () => onChange(index) }));
        }
    })));
};
