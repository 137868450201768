import { Button, Fieldset, Input, ModalBox, Select } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import { getCountriesOptions, NON_SHIPPABLE_COUNTRIES, STATE_OPTIONS, } from '@fourthwall/utils/lib/inputs';
import React from 'react';
import { validationSchema } from './consts';
import * as S from './styled';
const countriesOptions = getCountriesOptions({
    exclude: NON_SHIPPABLE_COUNTRIES,
});
export const ShippingLocationModal = ({ shippingLocation, title, onSubmit, close }) => {
    const formik = useFormik({
        initialValues: {
            address1: shippingLocation?.address1 || '',
            address2: shippingLocation?.address2 || '',
            state: shippingLocation?.state || '',
            zip: shippingLocation?.zip || '',
            city: shippingLocation?.city || '',
            country: shippingLocation?.country || '',
        },
        validationSchema,
        onSubmit: (values) => {
            close();
            onSubmit(values);
        },
    });
    const stateOptions = STATE_OPTIONS[formik.values.country];
    const renderState = () => {
        const shouldDisplayStateDropdown = !!stateOptions;
        if (shouldDisplayStateDropdown) {
            return React.createElement(Select, { label: "State", options: stateOptions, ...formik.getFieldProps('state') });
        }
        return React.createElement(Input, { label: "State", ...formik.getFieldProps('state') });
    };
    const handleCountryChange = () => {
        formik.setFieldValue('state', '');
        formik.setFieldTouched('state', false);
    };
    return (React.createElement(ModalBox, { size: "small", onClose: close, title: title, buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close }),
            React.createElement(Button, { label: "Save", appearance: "primary", onClick: formik.submitForm }),
        ] },
        React.createElement(S.Subtitle, null, "This address is used to calculate shipping costs and taxes. Your supporters do not see it."),
        React.createElement("form", { onSubmit: formik.handleSubmit },
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Input, { label: "Street address", ...formik.getFieldProps('address1') }))),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Input, { label: "Apartament, suite, etc. (optional)", ...formik.getFieldProps('address2') }))),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Input, { label: "City", ...formik.getFieldProps('city') }))),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Select, { options: countriesOptions, label: "Country", onValueChange: handleCountryChange, ...formik.getFieldProps('country') }))),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, { flex: 2 }, renderState()),
                React.createElement(Fieldset.Item, null,
                    React.createElement(Input, { label: "Zip code", ...formik.getFieldProps('zip') }))))));
};
