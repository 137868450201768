import React from 'react';
import { insertIf } from '../../../../../utils/insertIf';
import { ProductDiscontinuedBanner } from '../../modules/ProductDiscontinuedBanner/ProductDiscontinuedBanner';
export const useOfferDiscontinuedBanners = (offer, product) => {
    if (!product || !offer) {
        return [];
    }
    return [
        ...insertIf(product?.status === 'DISCONTINUED', React.createElement(ProductDiscontinuedBanner, { offer: offer, product: product })),
    ];
};
