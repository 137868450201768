import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.GS1000};
`;
export const Summary = styled.div `
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${Colors.GS400};
  font-weight: bold;
`;
