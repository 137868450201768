import { ApiBase } from './ApiBase';
class ShipstationIntegrationApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getShipstationConfiguration = () => {
            return this.get('/creator-integrations/shipstation/configuration');
        };
        this.updateShipstationConfiguration = (payload) => {
            return this.post('/creator-integrations/shipstation/configuration', payload);
        };
        this.deleteShipstationConfiguration = () => {
            return this.delete('/creator-integrations/shipstation/configuration');
        };
        this.getShipstationStoresFromKey = (payload) => {
            return this.post('/creator-integrations/shipstation/get-stores', payload);
        };
        this.getShipstationStores = () => {
            return this.get('/creator-integrations/shipstation/stores');
        };
    }
}
export const ShipstationIntegrationApiClient = new ShipstationIntegrationApiClientModel();
