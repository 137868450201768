import { useField } from 'formik';
import { useState } from 'react';
import { useSelectableList } from '../../../../../../../../../../SelectableList';
import { getAllCollections } from './helpers/getAllCollections';
import { useGetCreatorMessageCollectionsQuery } from './useGetCreatorMessageCollectionsQuery';
export const useCreatorMessageCollections = ({ onItemSelectionChange }) => {
    const [collectionsPage, setCollectionsPage] = useState(0);
    const { collectionsData, useCollectionsQuery } = useGetCreatorMessageCollectionsQuery(collectionsPage);
    const [, , helpers] = useField('sendTo');
    const allItems = getAllCollections(collectionsData?.collections);
    const handleOnItemSelectionChange = (selectedItemsId, selectedItems) => {
        helpers.setValue({
            type: 'COLLECTIONS',
            ids: selectedItemsId,
        });
        onItemSelectionChange(selectedItems);
    };
    const handleOnLoadMoreItems = () => {
        setCollectionsPage((prevState) => prevState + 1);
    };
    const { selectedListProps, selectedItemsIds, setSelectedItemsIds, pageProps } = useSelectableList({
        positionChangeModalTitle: 'Change collection position',
        allItems,
        onItemSelectionChange: handleOnItemSelectionChange,
        maxItemsPerPage: 5,
    });
    const selectableCollectionsProps = {
        items: allItems,
        isFetching: useCollectionsQuery.isFetching,
        hasNextPage: !!collectionsData?.page.hasNextPage,
        onLoadMoreItems: handleOnLoadMoreItems,
        selectedItemsIds,
        onSave: (selectedItemsIds) => {
            setSelectedItemsIds(selectedItemsIds);
        },
        itemName: 'collection',
        tableEmptyStateMsg: 'You haven’t created any collections yet.',
        searchPlaceholder: 'Search collections',
        positionChangeModalTitle: 'Change collection position',
        tableTitle: 'Select collections',
    };
    return {
        pageProps,
        selectedItemsListProps: selectedListProps,
        selectableListProps: selectableCollectionsProps,
    };
};
