import { HeadContent } from '@fourthwall/components';
import normalizeUrl from 'normalize-url';
import React from 'react';
import { CopyLink } from '@components/common/CopyLink';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { useDashboardFeatureFlags } from '../../../../../components/utils/DashboardFeatureFlags';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { Analytics } from './modules/Analytics/Analytics';
import { Articles } from './modules/Articles/Articles';
import { Events } from './modules/Events/Events';
import { Onboarding } from './modules/Onboarding/Onboarding';
import { Suggestions } from './modules/Suggestions/Suggestions';
import * as S from './styled';
export const Overview = () => {
    const { currentShopQuery } = useCurrentShopQuery();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const getTitle = () => {
        if (!currentShopQuery.isSuccess)
            return null;
        return (React.createElement(S.Title, null,
            "Your members-only area is available at",
            ' ',
            React.createElement(S.CopyLinkWrapper, null,
                React.createElement(CopyLink, { label: normalizeUrl(`${currentShopQuery.data.primaryDomain}/supporters`, {
                        stripProtocol: true,
                        stripWWW: true,
                    }), href: `${currentShopQuery.data.baseUri}/supporters` }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Memberships overview" }),
        React.createElement(PageLayout, null,
            React.createElement(Dashboard.Section, { name: "Memberships overview", title: getTitle(), bottomMargin: "large" }),
            React.createElement(S.Container, null,
                dashboardFeatureFlags.membershipsOnboarding.enabled && React.createElement(Onboarding, null),
                React.createElement(Analytics, null),
                React.createElement(Events, null),
                React.createElement(Suggestions, null),
                React.createElement(Articles, null)))));
};
