import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  padding: 24px 32px 0 32px;
`;
export const TitleContainer = styled.div `
  margin-bottom: 20px;
`;
export const IdleContainer = styled.div `
  width: 100%;
  height: 110px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ListContainer = styled.div `
  flex: 1;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;

  /* NOTE: Hack for making checkbox focus outline visible */
  padding-left: 20px;
  margin-left: -20px;

  ${mediaQueries.tablet} {
    max-height: 457px;
    padding-right: 16px;
  }
`;
export const LoaderContainer = styled.div `
  display: flex;
  justify-content: center;
  padding: 32px;
`;
export const Bar = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 80px;
  padding: 0 32px;

  border-top: 1px solid ${Colors.GS400};
  background-color: ${Colors.GS100};
`;
export const Message = styled.div `
  margin-right: auto;

  color: ${Colors.GS600};
`;
