import React, { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Offer } from '@fourthwall/services/lib/models/offer';
import { useMediaQueryContext } from '@fourthwall/components';
import { useOffersQuery } from '@queries/useOffersQuery';
import { PhotoTableCellContent } from '@components/common/PhotoTableCellContent';
import { getInitialSelectedItems, getItemsFromOffers } from './utils';
import { useSamplesInfiniteOffers } from '../../../../../../hooks/useSamplesInfiniteOffers';
export const useSelectItems = ({ initialValues: providedInitialValues, }) => {
    const media = useMediaQueryContext();
    const [searchValue, setSearchValue] = useState('');
    const [searchValueDebounced] = useDebounce(searchValue, 300);
    const { samplesOffers } = useSamplesInfiniteOffers(searchValueDebounced);
    const items = useMemo(() => {
        return getItemsFromOffers(samplesOffers.offers);
    }, [samplesOffers.offers]);
    const [selectedItems, setSelectedItems] = useState(getInitialSelectedItems(providedInitialValues, items));
    const { offersQuery: notAvailableOffersQuery } = useOffersQuery([
        {
            size: 1000,
            status: Offer.OFFER_STATUSES_WITH_SAMPLES,
            fulfillmentService: Offer.FULFILLING_SERVICES.filter((item) => !Offer.FULFILLING_SERVICES_WITH_SAMPLES.includes(item)),
        },
    ]);
    const resetSearchValue = () => setSearchValue('');
    const getBanner = () => {
        if (!notAvailableOffersQuery.data)
            return undefined;
        if (!notAvailableOffersQuery.data.offers.length)
            return undefined;
        return {
            size: 'small',
            title: `${notAvailableOffersQuery.data.offers.length} of your products are not available to order as samples.`,
            subtitle: 'Only products without minimums can be ordered as samples.',
            panel: (React.createElement("div", { style: { display: 'grid', gap: 4 } }, notAvailableOffersQuery.data.offers.map((offer) => (React.createElement("div", null,
                React.createElement(PhotoTableCellContent, { name: offer.name, photo: offer.images.length ? offer.images[0].url : undefined, photoSize: media.tablet ? 'medium' : 'tiny' })))))),
        };
    };
    const hasErrors = selectedItems.some(({ error }) => error);
    return {
        banner: getBanner(),
        selection: {
            items,
            selectedItems,
            isLoading: samplesOffers.isLoading,
            offersCount: samplesOffers.offers.length,
            hasNextPage: samplesOffers.hasNextPage,
            hasErrors,
            setSelectedItems,
            loadMoreItems: samplesOffers.fetchNextPage,
        },
        search: {
            value: searchValue,
            debouncedValue: searchValueDebounced,
            resetValue: resetSearchValue,
            setValue: setSearchValue,
        },
    };
};
