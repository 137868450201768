import { Icon } from '@fourthwall/components';
import { PhotoIcon } from '@fourthwall/icons';
import React from 'react';
import { QuantityBadge } from '../QuantityBadge';
import * as S from './styled';
export const ImagePreview = ({ image, imagesCount }) => {
    const renderContent = () => {
        if (!image) {
            return (React.createElement(S.PreviewIcon, { "data-testid": "ImagePreview" },
                React.createElement(Icon, { component: PhotoIcon, height: 24 })));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(S.Image, { src: image }),
            imagesCount && imagesCount > 1 && React.createElement(QuantityBadge, { quantity: imagesCount })));
    };
    return React.createElement(S.Container, null, renderContent());
};
