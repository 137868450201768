import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Description = styled.div `
  margin-bottom: 16px;
`;
export const ButtonContainer = styled.div `
  margin-bottom: 20px;
`;
export const ClientSecretContainer = styled.div `
  margin-top: 20px;
`;
export const ClientSecretContainerValue = styled.div `
  display: inline-block;
  padding: 2px 8px;
  margin-top: 10px;
  background: ${Colors.GS300};
  color: ${Colors.GS1000};
  font-family: 'PT Mono', monospace;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`;
