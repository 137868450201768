import { Fieldset, Input, InputUI, Subsection } from '@fourthwall/components';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { MessageRichEditor } from './MessageRichEditor';
export const MessageBodySubsection = ({ vm: { disabled } }) => {
    const { errors: { message }, getFieldMeta, } = useFormikContext();
    const renderInputErrorMessage = () => {
        return getFieldMeta('message').touched && message && React.createElement(InputUI.Error, null, message);
    };
    return (React.createElement(Subsection, { title: "Message" },
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { component: Input, name: "subject", disabled: disabled, placeholder: "Subject", fullWidth: true }))),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { component: MessageRichEditor, name: "message", placeholder: "Message", displayError: false }),
                    renderInputErrorMessage())))));
};
