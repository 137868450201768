import { getWelcomePostPayload } from './getWelcomePostPayload';
export const getCreateWelcomePostPayload = async (welcomePost) => {
    const post = await getWelcomePostPayload(welcomePost);
    return {
        welcomePost: {
            type: 'Single',
            post,
        },
    };
};
