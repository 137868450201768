import { ConfirmModal, Dropdown, ToolbarButton, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { reverse, routing } from '@utils/routing';
import { SlugChangeModal } from '@components/modals/SlugChangeModal';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useCurrentShop } from '../../hooks/useCurrentShop';
import { PRODUCT_SETTINGS_VIEW_TITLE } from '../ProductSettings';
import { useOffersDuplicateMutation } from '../../hooks/useOffersDuplicateMutation';
import { useOfferQuery } from '../queries/useOfferQuery';
export const ProductToolbar = ({ disabledArchive = false, onChangeUrl, onOrderSamples, onArchive, }) => {
    const { open } = useModalContext();
    const media = useMediaQueryContext();
    const { hasPermission, hasRole } = useDashboardPermissions();
    const { duplicateOfferMutation } = useOffersDuplicateMutation();
    const { productId = '' } = useParams();
    const { offerQuery } = useOfferQuery([productId]);
    const { currentShop } = useCurrentShop();
    const productName = offerQuery.data?.name;
    const slug = offerQuery.data?.slug;
    const id = offerQuery.data?.id;
    const customizationId = offerQuery.data?.customizationId;
    const status = offerQuery.data?.state.status;
    const handleChangeUrlClick = () => {
        open(SlugChangeModal, {
            title: 'Edit product URL',
            slug: slug || '',
            shopBaseUri: currentShop?.baseUri || '',
            prepend: 'products',
            onSubmit: (newSlug) => {
                onChangeUrl(newSlug);
            },
        });
    };
    const handleArchiveClick = () => {
        open(ConfirmModal, {
            title: `Are you sure that you want to archive ${productName}`,
            confirmLabel: 'Archive',
            confirmAppearance: 'destructive',
            onConfirm: onArchive,
        });
    };
    const handleUnarchiveClick = () => {
        open(ConfirmModal, {
            title: 'Are you sure you want to unarchive this product?',
            text: 'To unarchive this product you’ll need to review it in the product designer to ensure the design looks good.',
            confirmLabel: 'Unarchive and review',
            onConfirm: async () => {
                window.location.assign(reverse(routing.productDesignerV3.customizationEdit, {
                    customizationId: String(customizationId),
                    productId: String(id),
                }));
            },
        });
    };
    const handleDuplicateOffer = () => {
        open(ConfirmModal, {
            title: 'Duplicate product',
            confirmLabel: 'Duplicate',
            cancelLabel: 'Cancel',
            text: (React.createElement("p", null,
                "Are you sure you want to duplicate ",
                React.createElement("strong", null, productName))),
            onConfirm: () => {
                if (!id)
                    return;
                duplicateOfferMutation.mutate([{ path: { offerId: id } }], {
                    onSuccess: (duplicatedOffer) => {
                        window.location.href = reverse(routing.products.all.product.self, {
                            productId: duplicatedOffer.id,
                        });
                    },
                });
            },
        });
    };
    const getDropdownItems = () => {
        const dropdownItems = [];
        if (onOrderSamples && hasPermission('products.orderSamples')) {
            dropdownItems.push(React.createElement(Dropdown.Item, { label: "Order samples", onClick: onOrderSamples }));
        }
        if (hasPermission('products.edit.url')) {
            dropdownItems.push(React.createElement(Dropdown.Item, { label: "Change URL", onClick: handleChangeUrlClick }));
        }
        if (hasPermission('products.duplicate')) {
            dropdownItems.push(React.createElement(Dropdown.Item, { label: "Duplicate", onClick: handleDuplicateOffer }));
        }
        if (hasPermission('products.advancedSettings')) {
            dropdownItems.push(React.createElement(Dropdown.Item, { label: PRODUCT_SETTINGS_VIEW_TITLE, to: reverse(routing.products.all.product.settings, { productId }) }));
        }
        if (hasRole('ROLE_COSTS_WRITE')) {
            dropdownItems.push(React.createElement(Dropdown.Item, { label: "Costs", to: reverse(routing.products.all.product.costs, { productId }) }));
        }
        if (hasPermission('products.archive')) {
            if (status === 'ARCHIVED') {
                dropdownItems.push(React.createElement(Dropdown.Item, { label: "Unarchive", appearance: "destructive", onClick: handleUnarchiveClick, disabled: disabledArchive }));
            }
            else {
                dropdownItems.push(React.createElement(Dropdown.Item, { label: "Archive", appearance: "destructive", onClick: handleArchiveClick, disabled: disabledArchive }));
            }
        }
        return dropdownItems;
    };
    const dropdownItems = getDropdownItems();
    if (!dropdownItems.length) {
        return null;
    }
    return (React.createElement(Dropdown, { content: dropdownItems.map((dropdownItem) => dropdownItem), position: "bottom-end" },
        React.createElement(ToolbarButton, { label: "Settings", icon: DotsHorizontalIcon, size: media.tablet ? 'medium' : 'small' })));
};
