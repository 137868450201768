import { Icon } from '@fourthwall/components';
import { ArrowLongRightIcon, BoxWithPlusIcon } from '@fourthwall/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styled';
export const Hint = ({ title, to, children }) => {
    return (React.createElement(S.Container, { as: to ? Link : undefined, to: to, hoverable: !!to },
        React.createElement(S.Icon, null,
            React.createElement(Icon, { component: BoxWithPlusIcon, height: 24 })),
        React.createElement(S.Title, null, title),
        React.createElement(S.ContentContainer, null,
            React.createElement(S.Content, null, children),
            React.createElement(S.LinkIcon, null, to && React.createElement(Icon, { component: ArrowLongRightIcon, height: 16 })))));
};
