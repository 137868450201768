import styled from 'styled-components';
export const FiltersGroup = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const CheckboxGroup = styled.div `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
`;
