import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  background: ${Colors.GS200};
  flex-direction: column;

  ${mediaQueries.tablet} {
    flex-direction: row;
  }
`;
export const RateContainer = styled.div `
  flex: 1;
  padding: 20px;
  text-align: center;

  & + & {
    border-left: 1px solid ${Colors.GS400};
  }
`;
export const RateTitle = styled.div `
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;
export const RateSubtitle = styled.div `
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.GS600};
  margin-bottom: 16px;
`;
export const RateInput = styled.div `
  text-align: left;
  padding: 0 16px;

  & + & {
    margin-top: 16px;
  }
`;
