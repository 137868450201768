import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
const BORDER_WIDTH = 1;
export const Container = styled.div `
  border: ${BORDER_WIDTH}px solid ${Colors.Y300};

  position: relative;
  display: ${({ display }) => display};

  ${({ spacing }) => spacing === 'small' &&
    css `
      margin: -${8 + BORDER_WIDTH}px;
      padding: 8px;
    `};

  ${({ spacing }) => spacing === 'regular' &&
    css `
      margin: -${16 + BORDER_WIDTH}px;
      padding: 16px;
    `};
`;
export const Description = styled.div `
  position: absolute;
  right: -${BORDER_WIDTH}px;

  padding: 4px 8px;
  background: ${Colors.Y100};
  border: 1px solid ${Colors.Y300};

  color: ${Colors.Y400};
  text-transform: uppercase;
  font-size: 10px;
  line-height: 150%;
  font-weight: 600;

  user-select: none;

  ${({ position }) => position === 'inside' &&
    css `
      top: -${BORDER_WIDTH}px;
    `};

  ${({ position }) => position === 'outside' &&
    css `
      bottom: 100%;
    `};
`;
