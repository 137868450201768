import React from 'react';
import { HeadContent, Skeleton, ButtonClean } from '@fourthwall/components';
import { SECTION_NAMES } from '@constants/sectionNames';
import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { routing } from '@utils/routing';
import { ConnectBar } from '../../components/ConnectBar';
export const AppsStreamelementsView = ({ connectBar }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: SECTION_NAMES.appStreamElements }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: SECTION_NAMES.appStreamElements, title: React.createElement(React.Fragment, null,
                    "Show stream alerts for product purchases and gifts via StreamElements.",
                    ' ',
                    React.createElement(ButtonClean, { label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/13331283282331-Using-Streamlabs-StreamElements-to-power-purchase-alerts", target: "_blank" })), topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }) },
                React.createElement("div", { style: { marginTop: 16 - 24, marginBottom: 40 } }, connectBar ? (React.createElement(ConnectBar, { ...connectBar })) : (React.createElement(Skeleton, null,
                    React.createElement(Skeleton.Shape, { height: 64 }))))))));
};
