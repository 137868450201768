import { formatCurrency } from '@fourthwall/utils/lib/currency';
import { DIGITAL_COST_PERCENTAGE } from '@fourthwall/utils/lib/constants';
export const calculateProfit = (variants, offerType) => {
    const cost = variants[0].cost?.value;
    const price = variants[0].price.value;
    if (!cost && offerType === 'DIGITAL') {
        return Number(formatCurrency(((100 - DIGITAL_COST_PERCENTAGE) / 100) * price));
    }
    return price - (cost || 0);
};
