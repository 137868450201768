export const getIdsOfAdjacentElements = (products, oldIndex, newIndex) => {
    const before = newIndex ? undefined : products[newIndex].id;
    const after = newIndex ? products[newIndex - (oldIndex > newIndex ? 1 : 0)].id : undefined;
    return { before, after };
};
export const getCurrentProductPositionIndex = (offerId, products, currentPage, pageSize) => {
    const index = products.findIndex((product) => product.id === offerId) + 1;
    return pageSize * (currentPage - 1) + index;
};
export const getUpdatedProductPositionIndex = (type, updatedIndex, totalProductsCount) => ({
    TOP: 0,
    BOTTOM: totalProductsCount - 1,
    EXACT: updatedIndex - 1,
})[type];
