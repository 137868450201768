import { createQueryHook } from '@fourthwall/utils/lib/queries';
import { OffersApiClient } from '@fourthwall/services/lib/OffersApiClient';
import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
export const useOfferQuery = createQueryHook(OffersApiClient.getOffer, {
    queryKey: ['offer'],
    select: (data) => ({
        ...data,
        name: decodeHtmlEntities(data.name),
    }),
});
