import styled from 'styled-components';
export const Suggestions = styled.div `
  margin-bottom: 20px;

  & > :not(:last-of-type) {
    margin-bottom: 12px;
  }
`;
export const SellingPriceInputWrapper = styled.div `
  margin-bottom: 8px;
`;
