import React, { useState } from 'react';
import { ArrowShortDownIcon, ArrowShortUpIcon, CloseIcon, ExclamationMarkIcon, } from '@fourthwall/icons';
import { Icon } from '../Icon';
import * as S from './styled';
export const Banner = ({ title, subtitle, panel, appearance = 'info', icon = ExclamationMarkIcon, size = 'normal', justify = 'left', children, onClose, }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(S.Container, { appearance: appearance, size: size, role: "alert", "data-testid": "Banner", withRightContent: !!onClose },
        React.createElement(S.Main, { justify: justify },
            React.createElement(S.IconContainer, null,
                React.createElement(Icon, { component: icon, height: size === 'normal' ? 20 : 15 })),
            React.createElement("div", null,
                title && React.createElement(S.Title, null, title),
                subtitle && React.createElement(S.Subtitle, null, subtitle),
                isOpen && panel && React.createElement("div", { style: { marginTop: 8 } }, panel),
                children && React.createElement(S.ChildrenContainer, null, children))),
        panel && (React.createElement("div", null,
            React.createElement("button", { type: "button", onClick: () => setIsOpen((currentIsOpen) => !currentIsOpen) },
                React.createElement(Icon, { component: isOpen ? ArrowShortUpIcon : ArrowShortDownIcon, height: 16 })))),
        onClose && (React.createElement(S.CloseButtonContainer, { size: size, onClick: onClose },
            React.createElement(Icon, { component: CloseIcon, height: 16 })))));
};
