import styled, { css } from 'styled-components';
import { Colors } from '../../../colors';
export const IconContainer = styled.div `
  margin-left: 8px;

  display: flex;
  align-items: center;

  position: relative;
  top: -3px;

  opacity: ${({ direction }) => (direction ? '1' : '0')};
  transform: ${({ direction }) => (direction === 'asc' ? 'rotate(180deg)' : 'none')};
`;
export const Container = styled.span `
  display: flex;
  align-items: flex-end;
  justify-content: ${({ alignment }) => (alignment === 'right' ? 'flex-end' : 'flex-start')};
  flex: ${({ $width }) => $width};
  padding-bottom: 17px;
  padding-right: 20px;
  border-bottom: 1px solid ${Colors.GS400};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};

  text-align: left;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${Colors.GS600};
  line-height: 150%;

  user-select: none;

  &:last-child {
    padding-right: 0;
  }

  ${({ forOption }) => forOption &&
    css `
      flex: none;
      min-width: 0;
    `};

  ${({ compact }) => compact &&
    css `
      padding-bottom: 8px;
    `};

  ${({ onClick }) => onClick &&
    css `
      cursor: pointer;

      &:hover > ${IconContainer} {
        opacity: 1;
      }
    `};
`;
