import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;

  text-align: center;
  line-height: 24px;
`;
export const HeadingContainer = styled.div `
  margin-bottom: 12px;
`;
export const SubheadingContainer = styled.div `
  max-width: 480px;
`;
export const ButtonsContainer = styled.div `
  width: 100%;
  margin-bottom: 40px;

  ${mediaQueries.tablet} {
    width: auto;
  }
`;
export const VideoContainer = styled.div `
  margin: 32px 0;
  width: 100%;
  max-width: 720px;
  aspect-ratio: 16 / 9;
`;
export const Features = styled.div `
  display: flex;

  flex-wrap: wrap;
  flex-direction: column;
  justify-content: start;
  gap: 12px;

  max-width: 680px;

  text-align: left;

  ${mediaQueries.tablet} {
    flex-direction: row;
    justify-content: center;
    gap: 0px;
  }
`;
export const Feature = styled.div `
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;

  ${mediaQueries.tablet} {
    &:nth-child(2n + 1) {
      flex-shrink: 0;
      margin-right: 32px;
    }
  }

  ${mediaQueries.tablet} {
    line-height: 36px;
    align-items: initial;
  }
`;
export const FeatureIcon = styled.div `
  display: flex;
  align-items: center;
  margin-right: 12px;
  font-size: 22px;
`;
