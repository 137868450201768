import React from 'react';
import { DateString, PriceWithCurrency, Tag } from '@fourthwall/components';
import * as S from './styled';
import { tagsAppearances } from '../../consts';
const getPaidToLabel = (paidTo) => {
    if (['Bank account', 'Card'].includes(paidTo)) {
        return paidTo.toLowerCase();
    }
    return paidTo;
};
export const PayoutsHistoryListItem = ({ payoutHistoryItem }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.LeftSide, null,
            React.createElement(S.Date, null,
                React.createElement(DateString, { date: payoutHistoryItem.date, format: "MMM DD, YYYY" })),
            React.createElement("p", null,
                "Paid to ",
                getPaidToLabel(payoutHistoryItem.paidTo)),
            React.createElement(S.TagWrapper, null,
                React.createElement(Tag, { label: payoutHistoryItem.status, appearance: tagsAppearances[payoutHistoryItem.status] }))),
        React.createElement(S.RightSide, null,
            React.createElement(S.PriceWrapper, null,
                React.createElement(PriceWithCurrency, { ...payoutHistoryItem.transferAmount })),
            payoutHistoryItem.payoutAmount &&
                payoutHistoryItem.payoutAmount.currency !== payoutHistoryItem.transferAmount.currency ? (React.createElement(S.SecondaryPriceWrapper, null,
                React.createElement(PriceWithCurrency, { ...payoutHistoryItem.payoutAmount }))) : null)));
};
