import React from 'react';
import { useDashboardFeatureFlags } from '../../../../../../../components/utils/DashboardFeatureFlags';
import { useTiers } from '../../../Perks/hooks/useTiers';
import { NewMembers } from '../NewMembers/NewMembers';
import { useMembershipsOnboardingData } from '../Onboarding/hooks/useMembershipsOnboardingData';
import { TopComments } from '../TopComments/TopComments';
import * as S from './styled';
export const Events = () => {
    const { hasAnyTier } = useTiers();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { isOnboardingCompleted, isOnboardingHidden } = useMembershipsOnboardingData();
    if (!hasAnyTier) {
        return null;
    }
    if (dashboardFeatureFlags.membershipsOnboarding.enabled &&
        !(isOnboardingCompleted || isOnboardingHidden)) {
        return null;
    }
    return (React.createElement(S.Events, null,
        React.createElement(TopComments, null),
        React.createElement(NewMembers, null)));
};
