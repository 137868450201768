import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  margin-top: 16px;
`;
export const RadioGroupLabel = styled.div `
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${Colors.GS1000};
`;
export const InputWrapper = styled.div `
  width: 260px;
`;
