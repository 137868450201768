import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  margin-left: -24px;
`;
export const InnerContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const TabsContainer = styled.div `
  display: flex;
`;
export const Tab = styled.button `
  position: relative;
  margin-left: 24px;
  padding-bottom: 2px;

  font-weight: 600;
  font-size: 20px;
  color: ${Colors.GS600};
  line-height: 30px;
  cursor: pointer;

  &:hover {
    color: ${Colors.GS1000};
  }

  &:after {
    content: '';

    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;

    background: ${Colors.GS1000};
    opacity: 0;
  }

  &.active {
    color: ${Colors.GS1000};

    &:after {
      opacity: 1;
    }
  }

  ${({ active }) => active &&
    css `
      color: ${Colors.GS1000};

      &:after {
        opacity: 1;
      }
    `};
`;
