import map from 'lodash-es/map';
import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import { Dropdown } from '../Dropdown';
import { Icon } from '../Icon';
import { Loader } from '../Loader';
import { HELPER_CLASS } from './consts';
import * as S from './SortableGallery.styled';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
import { useModalContext } from '../../providers/ModalProvider';
import { PositionChangeModal } from '../../modals/PositionChangeModal';
const SortableGalleryElement = SortableElement(({ value, size, isDisabled, onEdit, onView, onRemove, onChangePosition, }) => {
    const [dropdownKey, setDropdownKey] = useState(0);
    const handleMouseLeave = () => setDropdownKey((key) => key + 1);
    if (value.isUploading) {
        return (React.createElement(S.Item, null,
            React.createElement(S.LoaderContainer, { size: size },
                React.createElement("div", null, "Uploading..."),
                React.createElement(Loader, null))));
    }
    return (React.createElement(S.Item, { onMouseLeave: handleMouseLeave, disabled: isDisabled },
        React.createElement(S.ImageContainer, { size: size, disabled: isDisabled },
            React.createElement(S.Image, { src: value.url }),
            React.createElement(S.Options, null,
                React.createElement(Dropdown, { key: dropdownKey, content: React.createElement(React.Fragment, null,
                        onChangePosition && !isDisabled && (React.createElement(Dropdown.Item, { label: "Change image position", onClick: onChangePosition })),
                        onEdit && !isDisabled && (React.createElement(Dropdown.Item, { label: "Edit image", onClick: () => onEdit(value.id) })),
                        onView && (React.createElement(Dropdown.Item, { label: "View full image", onClick: () => onView(value) })),
                        onRemove && !isDisabled && (React.createElement(Dropdown.Item, { label: "Delete image", onClick: () => onRemove(value) }))), optionsVerticalOffset: 6, optionsHorizontalOffset: 8 },
                    React.createElement(S.OptionsIconContainer, { "data-testid": "SortableGallery.Options" },
                        React.createElement(Icon, { component: DotsHorizontalIcon, height: 16 })))))));
});
const SortableGalleryContainer = SortableContainer(({ data, additionalItem, size, disabled, onEdit, onView, onRemove, onChangePosition, }) => {
    const media = useMediaQueryContext();
    return (React.createElement(S.Container, { size: size },
        data &&
            map(data, (value, index) => {
                return (React.createElement(S.ItemWrapper, { key: value.id },
                    React.createElement(S.Placeholder, null),
                    React.createElement(SortableGalleryElement, { index: index, value: value, size: size, disabled: disabled || !media.tablet, isDisabled: disabled, onEdit: onEdit, onView: onView, onRemove: onRemove, onChangePosition: onChangePosition && (() => onChangePosition(index)) })));
            }),
        additionalItem && React.createElement(S.AdditionalItem, null, additionalItem)));
});
export const SortableGallery = ({ data, additionalItem, size = 'medium', disabled = false, onEdit, onView, onRemove, onSortChange, }) => {
    const media = useMediaQueryContext();
    const { open } = useModalContext();
    const [isDragged, setIsDragged] = useState(false);
    const handleSortStart = () => {
        setIsDragged(true);
    };
    const handleSortEnd = ({ oldIndex, newIndex }) => {
        setIsDragged(false);
        if (onSortChange) {
            onSortChange(oldIndex, newIndex);
        }
    };
    const handleChangePosition = (index) => {
        if (!onSortChange) {
            return;
        }
        open(PositionChangeModal, {
            title: 'Change image position',
            currentPosition: index + 1,
            maxPosition: data.length,
            topPositionLabel: 'Move to start',
            bottomPositionLabel: 'Move to end',
            onSubmit: (values) => {
                const { type = 'EXACT' } = values;
                const newIndex = {
                    TOP: 0,
                    BOTTOM: data.length - 1,
                    EXACT: values.exactPosition - 1,
                }[type];
                onSortChange(index, newIndex);
            },
        });
    };
    return (React.createElement(SortableGalleryContainer, { data: data, additionalItem: additionalItem, size: size, disabled: disabled, isDragged: isDragged, helperClass: HELPER_CLASS, distance: 1, axis: "xy", onEdit: onEdit, onView: onView, onRemove: onRemove, onChangePosition: media.tablet ? undefined : handleChangePosition, onSortStart: handleSortStart, onSortEnd: handleSortEnd }));
};
