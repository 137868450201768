import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const ItemHoverBackground = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  opacity: 0.1;
  z-index: -1;
  background: linear-gradient(
    270deg,
    rgba(193, 193, 193, 0) 0%,
    #c1c1c1 23.97%,
    #c1c1c1 77.09%,
    rgba(193, 193, 193, 0) 100%
  );
`;
export const ItemArrow = styled.div `
  margin-left: 10px;
  display: none;

  color: ${Colors.GS600};
`;
export const ItemCompletedButtonContainer = styled.div `
  margin-left: 16px;
  font-size: 16px;
  line-height: 150%;
  display: none;
`;
export const ItemContainer = styled.li `
  list-style: none;
  width: 100%;
  padding: 18px 0;

  display: flex;
  align-items: center;
  position: relative;

  ${({ completed, open }) => !completed &&
    !open &&
    css `
      &:hover {
        cursor: pointer;

        ${ItemHoverBackground} {
          display: block;
        }

        ${ItemArrow} {
          display: block;
        }
      }
    `}

  ${({ completed }) => completed &&
    css `
      &:hover {
        ${ItemHoverBackground} {
          display: block;
        }

        ${ItemCompletedButtonContainer} {
          display: block;
        }
      }
    `}

  ${({ open }) => open &&
    css `
      padding: 24px 0;

      ${ItemHoverBackground} {
        display: block;
      }
    `}

  ${({ last, isNextOpen }) => !last &&
    css `
      &::after {
        content: '';
        position: absolute;
        top: 46px;
        left: 14px;
        width: 0;
        border-left: 1px dashed ${Colors.GS600};
        height: calc(100% - ${isNextOpen ? 22 : 28}px);
        z-index: -1;
      }
    `}
`;
export const ItemNumberContainer = styled.div `
  margin-right: 24px;
  align-self: flex-start;
`;
export const ItemNumber = styled.div `
  width: 28px;
  height: 28px;
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;

  color: ${Colors.GS600};

  ${({ appearance }) => appearance === 'success' &&
    css `
      background: ${Colors.G300};
      color: ${Colors.GS0};
      border: 1px solid ${Colors.G300};
    `};

  ${({ appearance }) => appearance === 'active' &&
    css `
      background: ${Colors.GS1000};
      color: ${Colors.GS0};
      border: 1px solid ${Colors.GS1000};
      font-weight: 600;
    `};

  ${({ appearance }) => appearance === 'normal' &&
    css `
      background: transparent;
      color: ${Colors.GS600};
      border: 1px dashed ${Colors.GS600};
      font-weight: 400;
    `};
`;
export const ItemMain = styled.div `
  flex: 1;
  min-width: 0;

  ${mediaQueries.tablet} {
    padding-right: 24px;
  }
`;
export const ItemRow = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;

  ${mediaQueries.tablet} {
    flex-direction: row;
  }
`;
export const ItemHeader = styled.div `
  display: grid;
  gap: 16px;

  ${mediaQueries.tablet} {
    gap: 20px;

    ${({ float }) => float &&
    css `
        display: flex;
        align-items: flex-start;

        > :first-child {
          flex: 1;
        }
      `};
  }
`;
export const ItemTitleWrapper = styled.div `
  flex: 1;

  display: flex;
  align-items: center;
`;
export const ItemTitle = styled.div `
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
  color: ${Colors.GS600};

  ${({ active }) => active &&
    css `
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: ${Colors.GS1000};
    `};

  ${mediaQueries.tablet} {
    font-size: 16px;

    ${({ active }) => active &&
    css `
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
      `};
  }
`;
export const ItemDescription = styled.div `
  max-width: 468px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
  color: ${Colors.GS600};

  ${mediaQueries.tablet} {
    max-width: 468px;
    margin-top: 8px;
    font-size: 16px;
  }
`;
export const ItemFooter = styled.div `
  display: flex;
  align-items: center;
  gap: 24px;

  ${mediaQueries.tablet} {
    ${({ float }) => float &&
    css `
        display: flex;
        flex-direction: row-reverse;
      `};
  }
`;
export const ItemSkipButton = styled.button `
  cursor: pointer;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS600};
  text-decoration: underline;

  &:hover {
    color: ${Colors.GS1000};
  }
`;
export const ItemImage = styled.img `
  width: 188px;
  height: auto;
  align-self: center;
`;
