import { ApiBase } from './ApiBase';
class AgencyImagesApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.uploadImage = (params, payload) => {
            return this.post(`/agency/images/${params.tag}/upload`, payload);
        };
        this.deleteImage = (params) => {
            return this.delete(`/agency/images/${params.tag}`);
        };
    }
}
export const AgencyImagesApiClient = new AgencyImagesApiClientModel();
