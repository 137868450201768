import { Button, ConfirmLeaveModal, ConfirmModal, Fieldset, ModalBox } from '@fourthwall/components';
import { captureMessage, withScope } from '@fourthwall/sentry';
import { Formik } from 'formik';
import isEmpty from 'lodash-es/isEmpty';
import React, { useState } from 'react';
import { useAllPerks } from './hooks/useAllPerks';
import { usePerkModal } from './hooks/usePerkModal';
import { CommonFields } from './modules/CommonFields';
import { DiscordIntegrationFields } from './modules/DiscordIntegrationFields';
import { DiscountInfoFields } from './modules/DiscountInfoFields';
import { ImageFields } from './modules/ImageFields';
import { ProductInfoFields } from './modules/ProductInfoFields';
import { RssTypeFields } from './modules/RssTypeFields';
import { TiersFields } from './modules/TiersFields';
export const PerkModal = ({ perk, selectedType, selectedTiers, onExistingPerkAdd, close, }) => {
    const [leavingGuardVisible, setLeavingGuardVisible] = useState(false);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const { getPerk } = useAllPerks();
    const { initialValues, validationSchema, isLoading, handleSubmit, handleDeleteClick } = usePerkModal({
        perk,
        selectedType,
        selectedTiers,
        close,
    });
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: handleSubmit }, ({ values, errors, dirty, submitForm }) => {
        const discordPerk = getPerk('Discord');
        if (values.type === 'Discord' && !!discordPerk) {
            onExistingPerkAdd?.(discordPerk, values.selectedTiers);
        }
        const handleClose = () => {
            if (dirty) {
                return setLeavingGuardVisible(true);
            }
            close();
        };
        const renderLeavingGuard = () => {
            if (!leavingGuardVisible)
                return;
            return (React.createElement(ConfirmLeaveModal, { title: "Would you like to save your changes?", text: "If you close this modal, all unsaved changes will be discarded.", close: () => {
                    setLeavingGuardVisible(false);
                }, onDiscard: () => {
                    setLeavingGuardVisible(false);
                    close();
                }, onSave: () => {
                    setLeavingGuardVisible(false);
                    submitForm();
                } }));
        };
        const handleSubmitButtonClick = () => {
            if (!isEmpty(errors)) {
                withScope((scope) => {
                    scope.setExtras({
                        formValues: values,
                        formErrors: errors,
                    });
                    captureMessage('Perk validation error');
                });
            }
            submitForm();
        };
        const getButtons = () => {
            const buttons = [
                React.createElement(Button, { label: "Cancel", onClick: handleClose }),
                React.createElement(Button, { label: perk ? 'Update' : 'Add perk', type: "submit", appearance: "primary", loading: isLoading, onClick: handleSubmitButtonClick }),
            ];
            if (perk) {
                buttons.unshift(React.createElement(Button, { label: "Delete perk", appearance: "destructive", disabled: isLoading, onClick: () => setDeleteConfirmVisible(true) }));
            }
            return buttons;
        };
        const renderDeleteConfirmModal = () => {
            if (!deleteConfirmVisible)
                return;
            return (React.createElement(ConfirmModal, { title: `Are you sure that you want to delete "${values.title}" perk?`, confirmLabel: "Delete", confirmAppearance: "destructive", close: () => {
                    setDeleteConfirmVisible(false);
                }, onConfirm: () => {
                    setDeleteConfirmVisible(false);
                    handleDeleteClick(values);
                }, onCancel: () => {
                    setDeleteConfirmVisible(false);
                } }));
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(ModalBox, { title: perk ? 'Edit perk' : 'Create a new perk', buttons: getButtons(), onClose: handleClose },
                React.createElement(Fieldset, null,
                    React.createElement(CommonFields, null),
                    values.type === 'Discord' && React.createElement(DiscordIntegrationFields, null),
                    values.type === 'Discount' && React.createElement(DiscountInfoFields, null),
                    values.type === 'Rss' && (React.createElement(ImageFields, { imageSrc: perk && 'image' in perk ? perk.image?.url : undefined })),
                    values.type === 'Product' && React.createElement(ProductInfoFields, null),
                    values.type === 'Rss' && React.createElement(RssTypeFields, null),
                    React.createElement(TiersFields, null))),
            renderLeavingGuard(),
            renderDeleteConfirmModal()));
    }));
};
