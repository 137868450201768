import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
`;
export const Label = styled.label `
  flex: 1;
`;
export const InputContainer = styled.div `
  max-width: 125px;
`;
