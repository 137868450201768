import { AudioIcon, PhotoIcon, PollIcon, 
// StreamIcon,
TypographyIcon, VideoIcon, } from '@fourthwall/icons';
export const TYPE_BUTTONS = [
    {
        type: 'Text',
        label: 'Text',
        icon: TypographyIcon,
    },
    {
        type: 'Image',
        label: 'Images',
        icon: PhotoIcon,
    },
    {
        type: 'Video',
        label: 'Video',
        icon: VideoIcon,
    },
    {
        type: 'Audio',
        label: 'Audio',
        icon: AudioIcon,
    },
    {
        type: 'Poll',
        label: 'Poll',
        icon: PollIcon,
    },
    // {
    //   type: 'LiveStream',
    //   label: 'Stream',
    //   icon: StreamIcon,
    // },
];
