import styled, { css } from 'styled-components';
import { mediaQueries } from '../../../providers/MediaQueryProvider';
export const Container = styled.div `
  ${mediaQueries.tablet} {
    display: flex;
  }

  ${({ inner }) => inner &&
    css `
      margin-top: 12px !important;
      margin-left: 36px;
    `};

  ${({ variant, noMargin }) => variant === 'small' &&
    !noMargin &&
    css `
      margin-top: 8px;
    `};

  ${({ variant, noMargin }) => variant === 'medium' &&
    !noMargin &&
    css `
      margin-top: 16px;
    `};

  ${({ variant, noMargin }) => variant === 'large' &&
    !noMargin &&
    css `
      margin-top: 32px;
    `};

  &:first-of-type {
    margin-top: 0;
  }
`;
