import { useAvailableRoles } from '@hooks/useAvailableRoles';
import { getRolesTableHeaders } from './utils';
export const useRolesTab = () => {
    const { availableRoles, isLoading } = useAvailableRoles();
    const data = availableRoles || [];
    const emptyMessage = 'No roles found';
    return {
        rolesTab: {
            listProps: {
                data,
                isLoading,
                emptyComponent: emptyMessage,
            },
            tableProps: {
                headers: getRolesTableHeaders(),
                data,
                isLoading,
                emptyMessage,
            },
        },
    };
};
