import { Loader, ModalBox, useModalContext } from '@fourthwall/components';
import React from 'react';
import { CostsBreakdown } from '../../../../../../../components/common/CostsBreakdown';
export const GiveawayCostsBreakdownView = ({ costsBreakdown }) => {
    const { close } = useModalContext();
    const renderCostsBreakdown = () => {
        if (!costsBreakdown) {
            return (React.createElement("div", { style: { height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                React.createElement(Loader, { size: "large" })));
        }
        return React.createElement(CostsBreakdown, { ...costsBreakdown });
    };
    return (React.createElement(ModalBox, { title: "Costs breakdown", onClose: close }, renderCostsBreakdown()));
};
