import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Subtitle = styled.div `
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 24px;

  p + p {
    margin-top: 12px;
  }
`;
export const LoaderWrapper = styled.div `
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PaymentMethodsContainer = styled.div `
  margin-bottom: 16px;
`;
export const ErrorBannerContainer = styled.div `
  margin-bottom: 16px;
`;
export const Footer = styled.div `
  z-index: 2;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0 -24px;

  ${mediaQueries.tablet} {
    margin: 0;
    justify-content: flex-start;
    position: absolute;
    left: 32px;
    bottom: 24px;
  }
`;
