import { getPrice } from '@fourthwall/utils/lib/common';
import React from 'react';
export const PriceString = ({ value, negative, currency, minimumFractionDigits, }) => {
    if (value === undefined) {
        return null;
    }
    const settings = JSON.parse(JSON.stringify({ negative, currency, minimumFractionDigits }));
    if (typeof value === 'string') {
        return React.createElement(React.Fragment, null, getPrice(parseFloat(value), settings));
    }
    return React.createElement(React.Fragment, null, getPrice(value, settings));
};
