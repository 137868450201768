import { automatedThemeDefaults, Button, ButtonGroup, Colors, ComponentsThemeProvider, DashboardLogo, getInternalTheme, useMediaQueryContext, } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const ConfirmBar = ({ dashboardLogo, isOpen = false, isLoading = false, confirmLabel = 'Save', message = 'Unsaved changes', onConfirm, onCancel, additionalElement, }) => {
    const { tablet } = useMediaQueryContext();
    return (React.createElement(ComponentsThemeProvider, { automatedTheme: getInternalTheme(automatedThemeDefaults.dark) },
        React.createElement(S.Container, { isOpen: isOpen, "data-testid": "ConfirmBar" },
            React.createElement(S.LogoContainer, null,
                React.createElement(DashboardLogo, { name: dashboardLogo.name, to: dashboardLogo.to, color: Colors.GS100 })),
            React.createElement(S.Content, null,
                tablet && React.createElement(S.Message, null, message),
                React.createElement(ButtonGroup, { space: tablet ? 'large' : 'medium' },
                    onCancel && (React.createElement(Button, { label: "Discard", size: "small", disabled: isLoading, onClick: onCancel })),
                    React.createElement(ButtonGroup, { space: "small" },
                        React.createElement(Button, { label: confirmLabel, appearance: "primary", size: "small", loading: isLoading, disabled: isLoading, onClick: onConfirm }),
                        additionalElement))))));
};
