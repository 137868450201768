import React from 'react';
import { Heading, BaseContent, Button, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { CreateProductModal } from '@modules/Dashboard/Products';
import * as S from './styled';
import { HomeProductsSliderContainer } from './modules/HomeProductsSliderContainer';
export const HomeProductsView = ({ title, subtitle }) => {
    const mediaQuery = useMediaQueryContext();
    const { open } = useModalContext();
    return (React.createElement(S.Container, null,
        React.createElement(S.Header, null,
            React.createElement("div", null,
                mediaQuery.tablet ? (React.createElement(Heading, { type: "h7", weight: "semibold" }, title)) : (React.createElement(BaseContent, null,
                    React.createElement("strong", null, title))),
                React.createElement("div", { style: { marginTop: 4 } },
                    React.createElement(BaseContent, { size: mediaQuery.laptop ? 'base' : 'small' },
                        React.createElement("p", null, subtitle)))),
            React.createElement("div", null,
                React.createElement(Button, { label: "Create a new product", appearance: "primary", fullWidth: true, onClick: () => open(CreateProductModal) }))),
        React.createElement("div", null,
            React.createElement(HomeProductsSliderContainer, null))));
};
