import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { mediaQueries } from '../../providers/MediaQueryProvider';
export const Drawer = styled.div `
  position: relative;
  z-index: 10;
`;
export const Body = styled(m.div) `
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: 100%;
  background-color: ${Colors.GS0};

  ${({ position }) => position === 'left' &&
    css `
      left: 0;
    `}

  ${({ position }) => position === 'right' &&
    css `
      right: 0;
    `}
`;
export const Overlay = styled(m.div) `
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: ${Colors.GS700};
  cursor: pointer;
`;
export const Header = styled.div `
  flex: 0 1 auto;

  border-bottom: 1px solid ${Colors.GS400};

  ${({ size }) => size === 'medium' &&
    css `
      padding: 14px 16px;
    `}

  ${({ size }) => size === 'large' &&
    css `
      padding: 23px 20px;
    `}
`;
export const Navigation = styled.div `
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;
export const Content = styled.div `
  flex: 1;
  overflow-y: auto;
`;
export const Section = styled.div `
  padding: 20px;

  ${mediaQueries.tablet} {
    padding: 16px;
  }
`;
export const Footer = styled.div `
  flex: 0 1 auto;
  padding: 16px;
`;
