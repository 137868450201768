import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
const getColumns = ({ count }) => {
    if (count === 1) {
        return 1;
    }
    if (count < 3) {
        return 2;
    }
    if (count < 4) {
        return 3;
    }
    if (count < 13) {
        return 4;
    }
    return 5;
};
const getSize = ({ count }) => {
    if (count === 1) {
        return '100%';
    }
    if (count < 3) {
        return '272px';
    }
    if (count < 4) {
        return '176px';
    }
    if (count < 13) {
        return '128px';
    }
    return '98px';
};
export const ImagesContainer = styled.div `
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, ${getSize});

  ${mediaQueries.tablet} {
    grid-template-columns: repeat(${getColumns}, 1fr);
  }
`;
export const ImageContainer = styled.div `
  width: ${getSize};
  height: ${getSize};
  background-color: ${Colors.GS300};
  /* https://docs.dndkit.com/api-documentation/sensors/pointer#touch-action */
  touch-action: none;
`;
