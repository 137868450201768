import React, { useState } from 'react';
import { DoubleArrowDownIcon, DoubleArrowUpIcon } from '@fourthwall/icons';
import { Icon } from '../Icon';
import * as S from './styled';
import { TableBreakdownFooter } from './TableBreakdownFooter';
export const TableBreakdown = ({ headline, headlineDataId, columns, data, footer, hovered = false, initialOpen = false, rowComponent = 'div', getRowComponentProps = () => ({}), }) => {
    const [open, setOpen] = useState(initialOpen);
    const handleClick = () => {
        setOpen((state) => !state);
    };
    const renderContent = () => {
        if (!open)
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null, data.map((row) => (React.createElement(S.Row, { key: row.id, as: rowComponent, ...getRowComponentProps(row) }, columns
                .filter((column) => !column.hidden)
                .map(({ render, flex = 1 }, index) => (React.createElement(S.Cell, { key: `${row.id}_${index}`, flex: flex }, render(row)))))))),
            footer && React.createElement(S.Footer, null, footer)));
    };
    return (React.createElement("div", null,
        React.createElement(S.Headline, { hovered: hovered, "data-id": headlineDataId, onClick: handleClick },
            headline,
            React.createElement(S.HeadlineIcon, null,
                React.createElement(Icon, { component: open ? DoubleArrowUpIcon : DoubleArrowDownIcon, height: 10 }))),
        renderContent()));
};
TableBreakdown.Footer = TableBreakdownFooter;
