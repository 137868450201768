import styled from 'styled-components';
import { Colors } from '../../colors';
const getColor = ({ appearance, isOpen, theme }) => {
    if (appearance === 'primary') {
        return isOpen ? Colors.GS1000 : theme.automated?.styles?.colorOnBackground || Colors.GS1000;
    }
    if (appearance === 'secondary') {
        return Colors.GS600;
    }
};
export const TooltipContainer = styled.div `
  color: ${getColor};
  cursor: pointer;
`;
