import { Heading, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import { DashboardSectionItem } from './DashboardSectionItem';
import { DashboardSectionScrollableContent } from './DashboardSectionScrollableContent';
import * as S from './styled';
export const DashboardSection = ({ name, title, titleSpacing = 'small', toolbarContent, topContent, bottomContent, bottomMargin = 'medium', children, }) => {
    const media = useMediaQueryContext();
    const renderNameContent = () => {
        if (!name && !toolbarContent) {
            return null;
        }
        return (React.createElement(S.NameContainer, null,
            React.createElement(S.NameContent, null,
                name && (React.createElement(S.HeadingContainer, null,
                    React.createElement(Heading, { type: media.laptop ? 'h5' : 'h6', asTag: "h1", weight: "semibold" }, name))),
                toolbarContent && React.createElement(S.ToolbarContainer, null, toolbarContent))));
    };
    return (React.createElement(S.Container, null,
        (topContent || name || title || bottomContent || toolbarContent) && (React.createElement(S.HeaderContainer, { bottomMargin: bottomMargin },
            topContent && (React.createElement(S.TopContainer, { "data-testid": "DashboardSection.TopContainer" }, topContent)),
            renderNameContent(),
            title && (React.createElement(S.TitleContainer, { titleSpacing: titleSpacing },
                React.createElement(S.Title, null, title))),
            bottomContent && React.createElement(S.BottomContainer, null, bottomContent))),
        children));
};
DashboardSection.Item = DashboardSectionItem;
DashboardSection.ScrollableContent = DashboardSectionScrollableContent;
