import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import { useAuth } from '@fourthwall/auth';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { routing } from '@utils/routing';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
const getAppSettingsReturnUrl = (app, baseUri) => {
    const paths = {
        streamelements: routing.apps.streamelements,
    };
    if (!app || !(app in paths)) {
        throw new Error(`Unknown integration provided in URL path: ${app}`);
    }
    const url = new URL(paths[app], baseUri);
    url.searchParams.append('flow', 'register');
    return url.toString();
};
const connect = (app, token, returnUrlParam, baseUri) => {
    const returnUrl = returnUrlParam || getAppSettingsReturnUrl(app, baseUri);
    const url = CreatorIntegrationsApiClient.appAuthorizationUrl({ app, token, returnUrl });
    window.location.assign(url);
};
export const ConnectIntegration = () => {
    const { integration } = useParams();
    const { keycloak } = useAuth();
    const [returnUrlParam] = useQueryParam('return_url', StringParam);
    const { currentShop } = useCurrentShop();
    useEffect(() => {
        if (!integration || !keycloak?.token || !currentShop?.baseUri)
            return;
        connect(integration, keycloak?.token, returnUrlParam || undefined, currentShop.baseUri);
    }, [integration, keycloak?.token, returnUrlParam, currentShop?.baseUri]);
    return null;
};
