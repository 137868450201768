export const ENTRI_CDN_URL = 'https://cdn.goentri.com/entri.js';
export const defaultContextValue = {
    isEntriLoaded: false,
    enableEntri: () => {
        throw new Error('Not implemented');
    },
    checkDomain: () => {
        throw new Error('Not implemented');
    },
    showEntriModal: () => {
        throw new Error('Not implemented');
    },
    addEntriCloseEvent: () => {
        throw new Error('Not implemented');
    },
    removeEntriCloseEvent: () => {
        throw new Error('Not implemented');
    },
};
