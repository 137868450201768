import Yup from '@fourthwall/utils/lib/yup';
export const validationSchema = Yup.object().shape({
    pricingMethod: Yup.string(),
    domesticFirst: Yup.number().when('pricingMethod', {
        is: (pricingMethod) => pricingMethod === 'Fixed',
        then: Yup.number().required().min(0, 'Negative values are not supported.'),
    }),
    domesticAdditional: Yup.number().when('pricingMethod', {
        is: (pricingMethod) => pricingMethod === 'Fixed',
        then: Yup.number().required().min(0, 'Negative values are not supported.'),
    }),
    internationalFirst: Yup.number().when('pricingMethod', {
        is: (pricingMethod) => pricingMethod === 'Fixed',
        then: Yup.number().required().min(0, 'Negative values are not supported.'),
    }),
    internationalAdditional: Yup.number().when('pricingMethod', {
        is: (pricingMethod) => pricingMethod === 'Fixed',
        then: Yup.number().required().min(0, 'Negative values are not supported.'),
    }),
    useAdditionalShippingFee: Yup.boolean().when('pricingMethod', {
        is: (pricingMethod) => pricingMethod === 'Weighted',
        then: Yup.boolean(),
    }),
    additionalShippingFee: Yup.string().when(['pricingMethod', 'useAdditionalShippingFee'], {
        is: (pricingMethod, useAdditionalShippingFee) => pricingMethod === 'Weighted' && useAdditionalShippingFee,
        then: Yup.string().required(),
    }),
});
