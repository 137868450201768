import { Icon } from '@fourthwall/components';
import { ResetIcon, SearchIcon } from '@fourthwall/icons';
import { CHECKOUT_NON_SHIPPABLE_COUNTRIES, getCountriesOptions } from '@fourthwall/utils/lib/inputs';
import React, { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { SelectCountryModalView } from './SelectCountryModalView';
export const SelectCountryModalContainer = ({ title, initialCountries = [], excludedCountries = CHECKOUT_NON_SHIPPABLE_COUNTRIES, close, onConfirm, }) => {
    const [search, setSearch] = useState('');
    const [selectedCountries, setSelectedCountries] = useState(initialCountries);
    const [searchDebounced] = useDebounce(search, 300);
    const countriesOptions = useMemo(() => getCountriesOptions({
        exclude: excludedCountries,
        withMostPopular: true,
        mostPopularWithDivider: false,
    }), []);
    const searchedCountries = useMemo(() => countriesOptions.filter((country) => country.label.toLowerCase().includes(searchDebounced.toLowerCase())), [searchDebounced, countriesOptions]);
    const countries = useMemo(() => searchedCountries.map((country) => ({
        ...country,
        selected: selectedCountries.includes(country.value),
    })), [selectedCountries, searchedCountries]);
    const handleCountrySelect = (country) => {
        if (selectedCountries.includes(country)) {
            setSelectedCountries((currentSelectedCountries) => currentSelectedCountries.filter((selectedCountry) => selectedCountry !== country));
            return;
        }
        setSelectedCountries((currentSelectedCountries) => [...currentSelectedCountries, country]);
    };
    const handleConfirm = () => {
        close?.();
        onConfirm(selectedCountries);
    };
    return (React.createElement(SelectCountryModalView, { title: title, countries: countries, message: `${selectedCountries.length} selected`, searchInputProps: {
            value: search,
            placeholder: 'Search',
            leftIcon: SearchIcon,
            actionIcon: search.length ? React.createElement(Icon, { component: ResetIcon, height: 18 }) : undefined,
            onActionIconClick: () => setSearch(''),
            onValueChange: setSearch,
        }, selectAllCheckboxProps: {
            label: selectedCountries.length ? `${selectedCountries.length} selected` : 'Select all',
            value: !!selectedCountries.length,
            onValueChange: () => {
                if (selectedCountries.length) {
                    setSelectedCountries([]);
                    return;
                }
                setSelectedCountries(countries.map((country) => country.value));
            },
        }, onCountrySelect: handleCountrySelect, onConfirm: handleConfirm, close: close }));
};
