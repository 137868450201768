import { Checkbox, Fieldset } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React from 'react';
import { FormFields } from '../../types';
export const Discount = () => {
    const formik = useFormikContext();
    if (formik.values.price && parseFloat(formik.values.price) === 0)
        return null;
    return (React.createElement(Fieldset.Row, null,
        React.createElement(Fieldset.Item, null,
            React.createElement(Field, { component: Checkbox, name: FormFields.discount, label: "Offer one-time discount for new members" }))));
};
