import { InputUI, Subsection, Toasts, Upload, useMediaQueryContext } from '@fourthwall/components';
import { ExclamationMarkIcon, PhotoIcon, UploadIcon } from '@fourthwall/icons';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { getRejectionErrors } from '../../../../../../../utils/dropzone';
import AudioPlayer from '../../../../components/AudioPlayer';
import { RemovableUpload } from '../../../../components/RemovableUpload/RemovableUpload';
import { UploadedImage } from '../../../../components/UploadedImage/UploadedImage';
import { ALLOWED_AUDIO_EXTENSIONS } from '../../../../consts';
import { Overlay } from '../../components/Overlay';
import { FormFields } from '../../types';
import * as S from './AudioFields.styled';
export const AudioFields = ({ isUploading, uploadProgress, status }) => {
    const media = useMediaQueryContext();
    const { values, errors, setFieldValue } = useFormikContext();
    const audioThumbnailError = errors[FormFields.audioThumbnail];
    const audioError = errors[FormFields.audio];
    const handleAudioThumbnailUpload = (files) => {
        if (files?.[0]) {
            setFieldValue(FormFields.audioThumbnail, files[0]);
        }
    };
    const handleAudioUpload = (files) => {
        if (files?.[0]) {
            setFieldValue(FormFields.audio, files[0]);
        }
    };
    const renderThumbnail = () => {
        if (values.audioThumbnail) {
            return (React.createElement(S.ThumbnailContainer, null,
                React.createElement(RemovableUpload, { onRemove: () => setFieldValue(FormFields.audioThumbnail, undefined) },
                    React.createElement(UploadedImage, { image: values.audioThumbnail })),
                isUploading && React.createElement(Overlay, null)));
        }
        return (React.createElement(S.ThumbnailContainer, null,
            React.createElement(Upload, { accept: {
                    'image/*': [],
                }, title: "", icon: PhotoIcon, iconSize: 24, maxSize: 10485760, orientation: "horizontal", invalid: !!errors[FormFields.audioThumbnail], onFileDrop: handleAudioThumbnailUpload, onFileDropRejected: handleFileReject })));
    };
    const renderOverlay = () => {
        if (status === 'Errored') {
            return (React.createElement(Overlay, { type: "error", showLoader: false, title: "An error occured during audio processing. Please re-upload your audio.", icon: ExclamationMarkIcon }));
        }
        if (status === 'Processing') {
            return (React.createElement(Overlay, { title: "Processing", subTitle: "This process can take up to an hour, depending on the audio file size.", showLoader: false }));
        }
    };
    const renderUploadingOverlay = () => {
        const getMessage = () => {
            if (uploadProgress === 0) {
                return 'Uploading';
            }
            return `Uploading (${uploadProgress}%)`;
        };
        return React.createElement(Overlay, { title: getMessage() });
    };
    const handleFileReject = (rejectedFiles) => {
        getRejectionErrors(rejectedFiles).forEach((rejection) => {
            Toasts.notify(rejection);
        });
    };
    const renderAudio = () => {
        const audio = values.audio;
        if (status === 'Processing' || status === 'Errored') {
            return (React.createElement(S.PlayerContainer, null,
                React.createElement(S.OverlayContainer, null, renderOverlay())));
        }
        if (audio) {
            const getAudio = () => {
                if (typeof audio === 'string') {
                    return audio;
                }
                return URL.createObjectURL(audio);
            };
            return (React.createElement(S.PlayerContainer, null,
                React.createElement(RemovableUpload, { onRemove: () => setFieldValue(FormFields.audio, undefined) },
                    React.createElement(AudioPlayer, { audioSrc: getAudio(), size: "small", hls: typeof audio === 'string' })),
                isUploading && renderUploadingOverlay()));
        }
        return (React.createElement(S.PlayerContainer, null,
            React.createElement(Upload, { accept: ALLOWED_AUDIO_EXTENSIONS, title: media.tablet ? (React.createElement("span", null,
                    "Drop audio or ",
                    React.createElement("u", null, "browse"))) : ('Browse'), maxSize: 53687091200, icon: UploadIcon, iconSize: 20, orientation: "horizontal", invalid: !!errors[FormFields.audio], onFileDrop: handleAudioUpload, onFileDropRejected: handleFileReject })));
    };
    return (React.createElement(Subsection, { title: "Audio" },
        React.createElement(S.Container, null,
            renderThumbnail(),
            renderAudio()),
        React.createElement("div", { style: { display: 'grid' } },
            audioThumbnailError && React.createElement(InputUI.Error, null, audioThumbnailError),
            audioError && React.createElement(InputUI.Error, null, audioError))));
};
