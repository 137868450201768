import { Table } from '@fourthwall/components';
import filter from 'lodash-es/filter';
import React, { useState } from 'react';
import { SelectModal } from '../../../../../../../components/modals/SelectModal/SelectModal';
import { useSeries } from '../../hooks/useSeries';
import * as S from './styled';
import { getTableHeaders } from './utils';
export const SelectSeries = ({ selectedSeries, onConfirm, close }) => {
    const [innerSelectedSeries, setInnerSelectedSeries] = useState(selectedSeries || []);
    const { seriesQuery, series } = useSeries();
    const handleCheckboxChange = (singleSeries) => (value) => {
        if (value) {
            return setInnerSelectedSeries([
                {
                    id: singleSeries.id,
                },
                ...innerSelectedSeries,
            ]);
        }
        return setInnerSelectedSeries(filter(innerSelectedSeries, (selectedSingleSeries) => selectedSingleSeries.id !== singleSeries.id));
    };
    const renderProducts = () => {
        return (React.createElement(Table, { data: series || [], headers: getTableHeaders(innerSelectedSeries, handleCheckboxChange), isLoading: seriesQuery.isLoading, minWidth: 0 }));
    };
    const handleSubmitClick = () => {
        onConfirm(innerSelectedSeries);
        close();
    };
    return (React.createElement(SelectModal, { title: "Add this video to selected series", content: React.createElement(S.TableContainer, null, renderProducts()), message: `${innerSelectedSeries.length} series selected`, submitButtonLabel: "Confirm", onSubmitClick: handleSubmitClick, close: close }));
};
