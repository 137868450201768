import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div ``;
export const Files = styled.div ``;
export const FileWrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const File = styled.div `
  line-height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;
export const FileWarning = styled.div `
  padding-left: 40px;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.24px;
  color: ${Colors.Y400};
  display: flex;
  align-items: center;
  gap: 6px;
`;
export const FileIcon = styled.div `
  margin-right: 16px;
`;
export const FileName = styled.div `
  margin-right: 32px;
  position: relative;
  height: 24px;
  flex: 1;
`;
export const FileNameWrapper = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const FileSize = styled.div `
  margin-right: ${({ withoutMargin }) => (withoutMargin ? '0' : '32px')};
  text-align: right;
  min-width: 100px;
`;
export const FileDelete = styled.button `
  color: ${Colors.GS600};
`;
