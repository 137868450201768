import { BackButton, Button, Checkbox, ConfirmModal, DatePicker, Dropdown, Fieldset, HeadContent, Icon, Input, LeavingGuard, Loading, PositionChangeModal, SaveShortcutListener, Subsection, ToolbarButton, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { DotsHorizontalIcon, PlusIcon } from '@fourthwall/icons';
import { arrayMove } from '@fourthwall/utils/lib/array';
import { pluralize } from '@fourthwall/utils/lib/string';
import { routing } from '@utils/routing';
import { Field, Form, Formik } from 'formik';
import difference from 'lodash-es/difference';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import unescape from 'lodash-es/unescape';
import React, { useRef, useState } from 'react';
import { Dashboard, PageLayout } from '@components/common';
import { SideSummary } from '@components/common/SideSummary';
import { UnavailableResource } from '@components/common/UnavailableResource';
import { Status } from '@components/composed/Status';
import { SlugChangeModal } from '@components/modals/SlugChangeModal';
import { DashboardPermissionGuard } from '@components/utils/DashboardPermissions';
import { addProtocol, addSourceParamToUrl } from '@utils/url';
import { ConfirmBarContainer } from '../ConfirmBar';
import { SelectProducts } from '../SelectProducts';
import { SelectedProducts } from './components/SelectedProducts';
import { StatusConfirmationModal } from './components/StatusConfirmationModal';
import { validationSchema } from './consts';
import * as S from './styled';
import { getCollectionTimeFrame, getShouldDisplayStatusConfirmation } from './utils';
export const CollectionView = ({ initialValues, collection, offers, shopBaseUri, isFetching, isCreating, isSubmitting, isError, isEditable, hasDropProducts, totalSold, setSelectedOfferIds, onSubmit, onStatusSubmit, onSlugChange, onCollectionDelete, }) => {
    const { open } = useModalContext();
    const media = useMediaQueryContext();
    const [isLeavingGuardActive, setLeavingGuardActive] = useState(true);
    const formikRef = useRef(null);
    const isFormLoading = isSubmitting || (!isCreating && isFetching);
    const collectionUrl = `${shopBaseUri}/collections/${collection?.slug}`;
    const getPageTitle = () => {
        return isCreating ? 'Create new collection' : unescape(collection?.name);
    };
    const handleFormSubmit = (values) => {
        setLeavingGuardActive(false);
        const getNewProductsStatus = () => {
            if (values.isSoldOut && collection?.state.status === 'HIDDEN') {
                return 'HIDDEN';
            }
            if (values.isTimeLimited) {
                return 'PUBLIC';
            }
            return collection?.state.status;
        };
        const productsAreEmpty = isEmpty(formikRef.current?.values.selectedProducts);
        const newTimeFrame = getCollectionTimeFrame(values.isTimeLimited, values.startDate, values.endDate);
        if (!isCreating && collection && !productsAreEmpty) {
            const newStatus = getNewProductsStatus();
            const productsHasChanged = difference(values.selectedProducts, collection?.offerIds).length;
            const timeFrameHasChanged = !isEqual(newTimeFrame, collection?.timeFrame);
            if (productsHasChanged || timeFrameHasChanged) {
                return open(StatusConfirmationModal, {
                    newStatus,
                    startDate: newStatus === 'PUBLIC' ? values.startDate : undefined,
                    endDate: newStatus === 'PUBLIC' ? values.endDate : undefined,
                    onConfirm: (withStatusUpdate) => onSubmit(values, withStatusUpdate),
                });
            }
        }
        onSubmit(values, false);
    };
    const handleCollectionDelete = () => {
        open(ConfirmModal, {
            title: `Are you sure that you want to delete "${collection?.name}" collection?`,
            confirmLabel: 'Delete',
            confirmAppearance: 'destructive',
            onConfirm: onCollectionDelete,
        });
    };
    const handleSlugChange = () => {
        open(SlugChangeModal, {
            title: 'Edit collection URL',
            slug: collection?.slug || '',
            shopBaseUri: shopBaseUri || '',
            prepend: 'collections',
            onSubmit: onSlugChange,
        });
    };
    const handleStatusTooltipSubmit = (values) => {
        if (!formikRef.current)
            return;
        const { selectedProducts } = formikRef.current.values;
        const selectedOffers = offers
            ? selectedProducts.map((id) => offers[id])
            : [];
        const shouldDisplayStatusConfirmation = getShouldDisplayStatusConfirmation(values.status, selectedOffers);
        if (!shouldDisplayStatusConfirmation) {
            return onStatusSubmit(values, true);
        }
        if (values.status === 'SCHEDULE') {
            return open(StatusConfirmationModal, {
                startDate: values.startDate,
                endDate: values.setEndDate ? values.endDate : undefined,
                onConfirm: (withStatusUpdate) => onStatusSubmit(values, withStatusUpdate),
            });
        }
        return open(StatusConfirmationModal, {
            newStatus: values.status,
            onConfirm: (withStatusUpdate) => onStatusSubmit(values, withStatusUpdate),
        });
    };
    const renderStatus = () => {
        if (isCreating || !collection)
            return null;
        return (React.createElement(Status, { status: collection.state.status, timeFrame: collection.timeFrame, isSoldOut: !collection.available, disabled: !isEditable, onSubmit: handleStatusTooltipSubmit }));
    };
    const renderToolbarContent = () => {
        if (isCreating || !collection || !isEditable)
            return null;
        return (React.createElement(Dropdown, { content: React.createElement(React.Fragment, null,
                React.createElement(Dropdown.Item, { label: "Change URL", onClick: handleSlugChange }),
                React.createElement(Dropdown.Item, { label: "Delete collection", onClick: handleCollectionDelete })), position: "bottom-end" },
            React.createElement(ToolbarButton, { label: "Settings", icon: DotsHorizontalIcon, size: media.tablet ? 'medium' : 'small' })));
    };
    const handleAddProducts = () => {
        if (!formikRef.current)
            return;
        open(SelectProducts, {
            selectedProductsIds: formikRef.current.values.selectedProducts,
            onSave: (selectedProductsIds) => {
                if (!formikRef.current)
                    return;
                formikRef.current.setFieldValue('selectedProducts', selectedProductsIds);
                setSelectedOfferIds(selectedProductsIds);
            },
        });
    };
    const handleItemRemove = (index) => {
        if (!formikRef.current)
            return;
        formikRef.current.setFieldValue('selectedProducts', formikRef.current.values.selectedProducts.filter((item, itemIndex) => itemIndex !== index));
    };
    const handleSortChange = (newIndex, oldIndex) => {
        if (!formikRef.current)
            return;
        formikRef.current.setFieldValue('selectedProducts', arrayMove(formikRef.current.values.selectedProducts, newIndex, oldIndex));
    };
    const handlePositionChange = (index) => {
        if (!formikRef.current)
            return;
        open(PositionChangeModal, {
            title: 'Change product position',
            maxPosition: formikRef.current.values.selectedProducts.length,
            currentPosition: index + 1,
            onSubmit: (values) => {
                if (!formikRef.current)
                    return;
                const { type = 'EXACT' } = values;
                const toIndex = {
                    TOP: 0,
                    BOTTOM: formikRef.current.values.selectedProducts.length - 1,
                    EXACT: values.exactPosition - 1,
                }[type];
                return formikRef.current.setFieldValue('selectedProducts', arrayMove(formikRef.current.values.selectedProducts, index, toIndex));
            },
        });
    };
    const renderAnalyticsSummary = () => {
        if (isCreating || !totalSold) {
            return 'No sales yet';
        }
        return `${totalSold} ${pluralize(totalSold, 'product')} sold`;
    };
    if (isError) {
        return React.createElement(UnavailableResource, { resource: "collection" });
    }
    const getTimeLimitedCheckboxProps = () => {
        if (isCreating) {
            return {
                disabled: true,
                tooltip: 'You need to save your collection before you can make it time-limited.',
            };
        }
        if (hasDropProducts) {
            return {
                disabled: true,
                tooltip: 'You cannot set up time-limited collection that includes pre-sales offers.',
            };
        }
        return { disabled: !isEditable };
    };
    const getSoldOutCheckboxProps = () => {
        if (isCreating) {
            return {
                disabled: true,
                tooltip: 'You need to save your collection before you can mark it as sold out.',
            };
        }
        return { disabled: !isEditable };
    };
    return (React.createElement(Formik, { innerRef: formikRef, initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: handleFormSubmit }, (formik) => (React.createElement(Form, null,
        React.createElement(HeadContent, { title: getPageTitle() }),
        React.createElement(Loading, { isLoading: isFormLoading },
            React.createElement(PageLayout, { rightContent: React.createElement(DashboardPermissionGuard, { permission: "contributions.profit" },
                    React.createElement(SideSummary, null,
                        React.createElement(SideSummary.Item, { title: "Analytics" }, renderAnalyticsSummary()))), leftContent: React.createElement(React.Fragment, null,
                    React.createElement(Subsection, { title: "Collection details" },
                        React.createElement(Fieldset, null,
                            React.createElement(Fieldset.Row, null,
                                React.createElement(Fieldset.Item, null,
                                    React.createElement(Field, { component: Input, name: "name", label: "Collection name", required: true, disabled: !isEditable }))),
                            React.createElement(Fieldset.Row, null,
                                React.createElement(Field, { component: Checkbox, name: "isTimeLimited", label: "Make this a limited-time only collection", description: "Set a start time for when the products should be listed as public, and an end time for when they should be marked as sold out.", ...getTimeLimitedCheckboxProps() })),
                            formik.values.isTimeLimited && (React.createElement(Fieldset.Row, { inner: true },
                                React.createElement(DatePicker, { startDate: formik.values.startDate, endDate: formik.values.endDate, disabled: !isEditable, showTimeInputs: true, onDatesChange: ({ startDate, endDate }) => {
                                        formik.setFieldValue('startDate', startDate);
                                        formik.setFieldValue('endDate', endDate);
                                    } }))),
                            React.createElement(Fieldset.Row, null,
                                React.createElement(Field, { component: Checkbox, name: "isSoldOut", label: "Mark products as sold out", description: "This will override any time-limits set on products.", ...getSoldOutCheckboxProps() })))),
                    React.createElement(Subsection, { title: "Products" },
                        React.createElement(S.ProductsHeader, null,
                            React.createElement(S.CountWrapper, null,
                                React.createElement(S.Count, null,
                                    React.createElement(React.Fragment, null,
                                        formik.values.selectedProducts.length || 'No',
                                        ' ',
                                        pluralize(formik.values.selectedProducts.length, 'product'),
                                        " added"))),
                            React.createElement(Button, { label: "Add products", size: "small", disabled: !isEditable, leftIcon: React.createElement(Icon, { component: PlusIcon, height: 12 }), onClick: handleAddProducts })),
                        React.createElement(S.SelectedProductsContainer, { isEditable: isEditable },
                            React.createElement(SelectedProducts, { collectionName: collection?.name || 'Collection', offers: offers, selectedProductsIds: formik.values.selectedProducts, disabled: !isEditable, onRemove: handleItemRemove, onSortChange: handleSortChange, onPositionChange: handlePositionChange })))) },
                React.createElement("div", null,
                    React.createElement(Dashboard.ItemViewHeader, { name: getPageTitle(), link: !isCreating
                            ? {
                                label: addProtocol(collectionUrl),
                                url: addSourceParamToUrl(collectionUrl, 'dashboard'),
                            }
                            : undefined, backButton: React.createElement(BackButton, { label: "Collections", to: routing.products.collections.self }), status: renderStatus(), toolbar: renderToolbarContent() })))),
        React.createElement(SaveShortcutListener, { disabled: !formik.dirty, onSave: formik.submitForm }),
        React.createElement(ConfirmBarContainer, { isOpen: formik.dirty, isLoading: isFormLoading, onConfirm: formik.submitForm, onCancel: formik.resetForm }),
        React.createElement(LeavingGuard, { when: formik.dirty && isLeavingGuardActive })))));
};
