import { List, Loader } from '@fourthwall/components';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SelectModal } from '@components/modals/SelectModal';
import { SelectProductsListItem } from './components/SelectProductsListItem';
import * as S from './styled';
const scrollableTarget = 'scrollableDiv';
export const SelectProductsView = ({ title, message, products, productsStates, innerProductsIds, multiple, mode, hasNextPage, inputProps, emptyMessage, isLoading, submitButtonLabel, cancelButtonLabel, close, onSubmitClick, onLoadMoreProducts, onSelectProduct, }) => {
    const getValue = (product) => {
        if (multiple)
            return !!innerProductsIds.find((productId) => product.id === productId);
        return innerProductsIds[0]?.toString();
    };
    const getDisabled = (product) => {
        if (mode === 'INVENTORY_SHIPMENT') {
            return !product.customsInformation.shortHsCode;
        }
        return productsStates[product.id]?.disabled.value;
    };
    const getDisabledMessage = (product) => {
        if (mode === 'INVENTORY_SHIPMENT')
            return;
        return productsStates[product.id]?.disabled.message;
    };
    const renderProducts = () => {
        if (isLoading) {
            return (React.createElement(S.IdleContainer, null,
                React.createElement(Loader, null)));
        }
        if (emptyMessage) {
            return React.createElement(S.IdleContainer, null, emptyMessage);
        }
        return (React.createElement(InfiniteScroll, { dataLength: products?.length || 0, next: onLoadMoreProducts, hasMore: hasNextPage, loader: React.createElement(S.LoaderContainer, null,
                React.createElement(Loader, null)), scrollableTarget: scrollableTarget, style: { overflow: 'none' } },
            React.createElement("div", null,
                React.createElement(List, { data: products, renderItem: (product) => (React.createElement(SelectProductsListItem, { product: product, mode: mode, multiple: multiple, value: getValue(product), disabled: getDisabled(product), disabledMessage: getDisabledMessage(product), onSelect: onSelectProduct })) }))));
    };
    return (React.createElement(SelectModal, { title: title, inputProps: inputProps, message: message, content: React.createElement(S.ListContainer, { id: scrollableTarget }, renderProducts()), submitButtonLabel: submitButtonLabel, cancelButtonLabel: cancelButtonLabel, close: close, onSubmitClick: onSubmitClick }));
};
