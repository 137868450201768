import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { useQuery } from '@tanstack/react-query';
export const usePostSeriesQuery = (id, enabled) => {
    const postSeriesQuery = useQuery({
        queryKey: ['usePostSeriesQuery', id],
        queryFn: async () => {
            const { data } = await MembershipsCreatorApiClient.getPostSeries(id, {
                page: 1,
                limit: 9999,
            });
            return data;
        },
        enabled,
    });
    return {
        postSeriesQuery,
    };
};
