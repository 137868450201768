import { Checkbox, Fieldset, Input, LeavingGuard, SaveShortcutListener, Subsection, Switch, } from '@fourthwall/components';
import { ConfirmBarContainer } from '@modules/Dashboard/ConfirmBar';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { SyncProducts } from '../SyncProducts';
import { settingsFormValidationSchema } from './consts';
export const SettingsForm = ({ initialValues, errorBanners, isLoading, isDisabled, syncProducts, onSubmit, }) => {
    return (React.createElement(Formik, { enableReinitialize: true, initialValues: initialValues, validationSchema: settingsFormValidationSchema, onSubmit: onSubmit }, ({ values, dirty, isSubmitting, resetForm, submitForm }) => (React.createElement(Form, null,
        React.createElement(Subsection, null,
            errorBanners,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { component: Switch, name: "salesEnabled", label: "Enable TikTok Shop sales", description: "Turning this on will allow fans to purchase your Fourthwall products directly from your TikTok Shop.", disabled: isDisabled || isLoading })))),
        values.salesEnabled && React.createElement(SyncProducts, { ...syncProducts }),
        values.salesEnabled && (React.createElement(Subsection, { title: "Product pricing on TikTok Shop" },
            React.createElement("div", null, "If you want, you can adjust your product prices to cover TikTok\u2019s per transaction fees. They currently charge 2% $.30 per transaction, but are increasing this to 6% + $.30 in April, and then 8% + $.30 in July 2024."),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { component: Checkbox, name: "priceIncreaseEnabled", label: "Increase price of my products on TikTok Shop", disabled: isDisabled || isLoading }))),
            values.priceIncreaseEnabled && (React.createElement("div", { style: { marginTop: 16, width: 170 } },
                React.createElement(Fieldset.Row, { inner: true },
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { component: Input, type: "number", name: "priceIncrease", label: "Price increase", append: "%", hideSpinner: true, disabled: isDisabled || isLoading }))))))),
        React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
        React.createElement(ConfirmBarContainer, { isOpen: dirty || isSubmitting, isLoading: isLoading || isSubmitting, onConfirm: submitForm, onCancel: resetForm }),
        React.createElement(LeavingGuard, { when: dirty })))));
};
