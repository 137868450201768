import styled from 'styled-components';
export const Description = styled.div `
  p + p {
    margin-top: 20px;
  }
`;
export const LoadingProductsContainer = styled.div `
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
