import { ApiBase } from './ApiBase';
class InternalApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.enrollCampaign = (body) => {
            return this.post('/internal/campaign/enroll', body);
        };
    }
}
export const InternalApiClient = new InternalApiClientModel();
