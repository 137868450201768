import React from 'react';
import { useActionsBars } from '@modules/Dashboard/Home/hooks/useActionsBars';
import { RecommendationButton, RecommendationButtonGroup } from '@fourthwall/components';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useCurrentShopQuery } from '../hooks/useCurrentShopQuery';
import { HomeView } from './HomeView';
import { useBlogPosts } from './hooks/useBlogPosts';
import { useHome } from './hooks/useHome';
import { useRecommendationBars } from './hooks/useRecommendationBars';
import { useRecommendations } from './hooks/useRecommendations';
import { HomeAnalyticsContainer } from './modules/HomeAnalytics';
export const HomeContainer = () => {
    const { home } = useHome();
    const { onboarding } = home; // TODO: Temporarily use onboarding type in this container here.
    const { hasPermission } = useDashboardPermissions();
    const { currentShopQuery } = useCurrentShopQuery();
    const { recommendations } = useRecommendations();
    const { recommendationBars } = useRecommendationBars();
    const { actionsBars } = useActionsBars();
    const { blogPosts } = useBlogPosts();
    return (React.createElement(HomeView, { name: home.name, title: home.title, schedulerProps: onboarding.schedulerProps, onboardingsProps: onboarding.onboardingProps, actionBarsContent: ['ONBOARDING_MEMBERSHIPS', 'NONE'].includes(onboarding.onboardingType) &&
            !!actionsBars.length && (React.createElement(RecommendationButtonGroup, null, actionsBars.map((actionBar, index) => (React.createElement(RecommendationButton, { key: index, ...actionBar }))))), 
        // NOTE: display statistics when shop onboarding is not shown or if shop was ever live
        content: (['ONBOARDING_MEMBERSHIPS', 'NONE'].includes(onboarding.onboardingType) ||
            !!currentShopQuery.data?.lastLiveAt) &&
            hasPermission('home.analytics') && (React.createElement("div", { style: { marginTop: 48, marginBottom: 48 } },
            React.createElement(HomeAnalyticsContainer, null))), recommendationBarsProps: hasPermission('home.recommendations') ? recommendationBars : [], suggestionsProps: hasPermission('home.recommendations') ? recommendations : [], articleListSection: blogPosts }));
};
