import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
`;
export const UsageWrapper = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
`;
