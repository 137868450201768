import { getBaseApiUrl } from '@fourthwall/env';
import { ApiBase } from '../ApiBase';
class CreatorIntegrationsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getCreatorIntegrations = () => {
            return this.get('/api/creator-integrations');
        };
        this.deleteCreatorIntegration = (params) => {
            return this.delete(`/api/creator-integrations/${params.app}`);
        };
        this.getTwitchAuthorization = (params) => {
            return this.get('/api/creator-integrations/twitch/authorization', params, undefined, { withCredentials: true });
        };
        this.getTwitchToken = (params) => {
            return this.get('/api/creator-integrations/twitch/token', params, undefined, { withCredentials: true });
        };
        this.getBeehiivConfiguration = () => {
            return this.get('/api/creator-integrations/beehiiv/configuration');
        };
        this.updateBeehiivConfiguration = (requestBody) => {
            return this.post('/api/creator-integrations/beehiiv/configuration', requestBody);
        };
        this.deleteBeehiivConfiguration = () => {
            return this.delete('/api/creator-integrations/beehiiv/configuration');
        };
        this.getLayloConfiguration = () => {
            return this.get('/api/creator-integrations/laylo/configuration');
        };
        this.updateLayloConfiguration = (requestBody) => {
            return this.post('/api/creator-integrations/laylo/configuration', requestBody);
        };
        this.deleteLayloConfiguration = () => {
            return this.delete('/api/creator-integrations/laylo/configuration');
        };
        this.getTiktokPartnerConfiguration = () => {
            return this.get('/api/creator-integrations/tiktok-partner/configuration');
        };
        this.updateTiktokPartnerConfiguration = (requestBody) => {
            return this.put('/api/creator-integrations/tiktok-partner/configuration', requestBody);
        };
        this.updateTiktokPartnerConfigurationStatus = (requestBody) => {
            return this.put('/api/creator-integrations/tiktok-partner/configuration/status', requestBody);
        };
        this.deleteTiktokPartnerConfiguration = () => {
            return this.delete('/api/creator-integrations/tiktok-partner/configuration');
        };
        this.getTiktokPartnerWarehouses = () => {
            return this.get('/api/creator-integrations/tiktok-partner/warehouses');
        };
        this.getTiktokPartnerShops = () => {
            return this.get('/api/creator-integrations/tiktok-partner/get_authorized_shop');
        };
        this.getTiktokPartnerProducts = () => {
            return this.get('/api/creator-integrations/tiktok-partner/products');
        };
        this.reloadTiktokPartnerProducts = () => {
            return this.post('/api/creator-integrations/tiktok-partner/products/reload');
        };
        this.appAuthorizationUrl = ({ app, token, returnUrl: returnUrlParam = window.location.toString(), }) => {
            const url = new URL(`${this.getUrl()}/oauth2/authorization/${app}`);
            token && url.searchParams.append('access_token', token);
            const returnUrl = new URL(returnUrlParam);
            returnUrl.searchParams.append('connected', '');
            url.searchParams.append('return_url', returnUrl.toString());
            return url;
        };
    }
}
export const CreatorIntegrationsApiClient = new CreatorIntegrationsApiClientModel(getBaseApiUrl());
