import React from 'react';
import { TeamMember } from '../../components/TeamMember';
import { TeamMemberDropdown } from '../../components/TeamMemberDropdown';
export const getTeamTableHeaders = ({ disabledHeaders, dropdownProps, }) => {
    const table = [
        {
            id: 'name',
            label: 'Name',
            render: (data) => React.createElement(TeamMember, { data: data }),
            width: 2,
        },
        {
            id: 'role',
            label: 'Role',
            render: ({ roleName }) => roleName,
        },
        {
            id: 'select',
            label: ' ',
            render: (data) => React.createElement(TeamMemberDropdown, { data: data, ...dropdownProps }),
            width: 0,
            minWidth: 40,
            alignment: 'right',
        },
    ];
    return !disabledHeaders?.length ? table : table.filter(({ id }) => !disabledHeaders.includes(id));
};
export const sortTeamUsers = (userEmail, users, availableRoles) => {
    if (!users || !availableRoles)
        return [];
    return users.sort((userA, userB) => {
        const roleA = availableRoles.find(({ id }) => id === userA.group);
        const roleB = availableRoles.find(({ id }) => id === userB.group);
        if (!roleA || !roleB || roleA === roleB) {
            if (userA.invitationStatus === 'FULFILLED' && userB.invitationStatus !== 'FULFILLED') {
                return -1;
            }
            if (userEmail && userA.email === userEmail) {
                return -1;
            }
            return 0;
        }
        return roleB.permissions.length - roleA.permissions.length;
    });
};
