import { Icon, Loader } from '@fourthwall/components';
import { AudioIcon, ExclamationMarkIcon, PhotoIcon, PinIcon, PollIcon, StreamIcon, TypographyIcon, VideoIcon, } from '@fourthwall/icons';
import React from 'react';
import * as S from './PostTypeThumbnail.styled';
const postIcon = {
    Text: TypographyIcon,
    Poll: PollIcon,
    Audio: AudioIcon,
    Video: VideoIcon,
    VideoEmbed: VideoIcon,
    Image: PhotoIcon,
    LiveStream: StreamIcon,
};
export const PostTypeThumbnail = ({ type, thumbnailUrl, resources, pinned }) => {
    const renderThumbnail = () => {
        if (thumbnailUrl) {
            return React.createElement(S.Thumbnail, { src: thumbnailUrl });
        }
        return React.createElement(Icon, { component: postIcon[type], height: 24 });
    };
    const renderOverlay = () => {
        if (resources === 'Errored') {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.ErrorOverlay, null),
                React.createElement(S.ErrorMessage, null,
                    React.createElement(Icon, { component: ExclamationMarkIcon, height: 13 }),
                    "Error")));
        }
        if (resources === 'Processing') {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.ProcessingOverlay, null),
                React.createElement(S.ProcessingMessage, null,
                    React.createElement(Loader, { size: "small", appearance: "primary" }),
                    React.createElement("div", null, "Processing"))));
        }
    };
    return (React.createElement(S.Container, { "aria-label": "Thumbnail" },
        pinned && (React.createElement(S.PinnedBadge, null,
            React.createElement(Icon, { component: PinIcon, height: 12 }))),
        renderThumbnail(),
        renderOverlay()));
};
