import { Checkbox, CountryFlag } from '@fourthwall/components';
import React from 'react';
import { SelectModal } from '../SelectModal';
import * as S from './styled';
export const SelectCountryModalView = ({ title = 'Select countries', countries, searchInputProps, selectAllCheckboxProps, message, onCountrySelect, onConfirm, close, }) => {
    const renderCountries = () => {
        if (countries.length) {
            return countries.map((country) => (React.createElement(S.CountryContainer, { key: country.key },
                React.createElement(Checkbox, { value: country.selected, onValueChange: () => onCountrySelect(country.value), label: React.createElement(S.CountryCheckboxLabel, null,
                        React.createElement(CountryFlag, { countryCode: country.value, height: 27 })) }))));
        }
        return React.createElement(S.EmptyMessageContainer, null, "No countries found");
    };
    return (React.createElement(SelectModal, { title: title, message: message, content: React.createElement(S.CountriesContainer, null, renderCountries()), inputProps: searchInputProps, selectAllCheckboxProps: selectAllCheckboxProps, submitButtonLabel: "Confirm", onSubmitClick: onConfirm, close: close }));
};
