import { HeadContent } from '@fourthwall/components';
import React from 'react';
import { useCurrentShop } from '../../../hooks/useCurrentShop';
import { GeneralView } from './GeneralView';
import { validationSchema } from './consts';
import { useGeneralShopSettings } from './hooks/useGeneralShopSettings';
import { useMailingListReport } from './hooks/useMailingListReport';
import { useShopRendererDataQuery } from './hooks/useShopRendererDataQuery';
export const GeneralContainer = () => {
    const { currentShop, isLoading } = useCurrentShop();
    const { mailingListReport } = useMailingListReport();
    const { shopRendererDataQuery } = useShopRendererDataQuery([{ shop_id: currentShop?.id }], {
        enabled: !!currentShop?.id,
    });
    const { isSubmitting, handleUpdateShop } = useGeneralShopSettings();
    const initialValues = {
        name: currentShop?.name || '',
        customerSupportEmail: currentShop?.contactInfo?.customerSupportEmail || '',
        creatorName: currentShop?.creatorName || '',
        description: currentShop?.description || '',
        themeAutoUpdateHour: shopRendererDataQuery.data?.shop.theme_update_hour?.toString() || '',
        socialImage: shopRendererDataQuery.data?.shop.ogImageUrl || '',
        socialImageType: 'Keep',
        cookiePolicy: shopRendererDataQuery.data?.shop.cookie_policy === 'ShowInEu' || false,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "General \u2013 Settings" }),
        React.createElement(GeneralView, { initialValues: initialValues, validationSchema: validationSchema, isLoading: !currentShop || !shopRendererDataQuery.data || isLoading, isSubmitting: isSubmitting, onMarketingReportDownload: mailingListReport.download, onSubmit: handleUpdateShop })));
};
