import { Checkbox } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { FilterDropdown } from '../../../../../../../components/common/FilterDropdown';
import * as S from './styled';
export const MembersFilterView = ({ types, tiers, billingCycles, states, initialValues, filterActive, onSubmit, onReset, }) => {
    const formik = useFormik({
        initialValues,
        onSubmit: () => {
            if (isDisabled()) {
                onSubmit({ ...formik.values, tier: [], billingCycle: [] });
                return;
            }
            onSubmit(formik.values);
        },
        enableReinitialize: true,
    });
    const handleReset = () => {
        formik.resetForm();
        onReset();
    };
    const handleCheckboxGroupChange = (checked, value, name) => {
        const fieldValue = checked
            ? [...formik.values[name], value]
            : formik.values[name].filter((v) => v !== value);
        formik.setFieldValue(name, fieldValue);
    };
    const isDisabled = () => {
        const type = formik.values.type;
        return type.length === 1 && type[0] === 'free';
    };
    const renderTiers = () => !!tiers?.length && (React.createElement(S.FiltersGroup, null,
        React.createElement(FilterDropdown.GroupTitle, { title: "Membership tier" }),
        React.createElement(S.CheckboxGroup, { role: "group" }, tiers.map((option) => (React.createElement(Checkbox, { native: true, key: option.value, label: option.label, value: option.value, checked: formik.values.tier.includes(option.value), disabled: isDisabled(), onValueChange: (checked) => handleCheckboxGroupChange(checked, option.value, 'tier') }))))));
    const renderBillingCycles = () => !!billingCycles?.length && (React.createElement(S.FiltersGroup, null,
        React.createElement(FilterDropdown.GroupTitle, { title: "Membership billing cycle" }),
        React.createElement(S.CheckboxGroup, { role: "group" }, billingCycles.map((option) => (React.createElement(Checkbox, { native: true, key: option.value, label: option.label, value: option.value, checked: formik.values.billingCycle.includes(option.value), disabled: isDisabled(), onValueChange: (checked) => handleCheckboxGroupChange(checked, option.value, 'billingCycle') }))))));
    const renderStates = () => !!states?.length && (React.createElement(S.FiltersGroup, null,
        React.createElement(FilterDropdown.GroupTitle, { title: "Membership state" }),
        React.createElement(S.CheckboxGroup, { role: "group" }, states.map((option) => (React.createElement(Checkbox, { native: true, key: option.value, label: option.label, value: option.value, checked: formik.values.state.includes(option.value), disabled: isDisabled(), onValueChange: (checked) => handleCheckboxGroupChange(checked, option.value, 'state') }))))));
    const renderTypes = () => !!types?.length && (React.createElement(S.FiltersGroup, null,
        React.createElement(FilterDropdown.GroupTitle, { title: "Type" }),
        React.createElement(S.CheckboxGroup, { role: "group" }, types.map((option) => (React.createElement(Checkbox, { native: true, key: option.value, label: option.label, value: option.value, checked: formik.values.type.includes(option.value), onValueChange: (checked) => handleCheckboxGroupChange(checked, option.value, 'type') }))))));
    return (React.createElement(FilterDropdown, { content: React.createElement(S.Form, null,
            React.createElement("div", null,
                renderTypes(),
                renderStates()),
            React.createElement("div", null,
                renderBillingCycles(),
                renderTiers())), activityIndicator: filterActive, onSubmit: formik.handleSubmit, onReset: handleReset }));
};
