import React from 'react';
import { GiveawayPackagesView } from './GiveawayPackagesView';
import { useGiveawayPackagesQuery } from './hooks/useGiveawayPackagesQuery';
export const GiveawayPackagesContainer = () => {
    const { giveawayPackagesQuery } = useGiveawayPackagesQuery(undefined, {
        keepPreviousData: true,
    });
    return (React.createElement(GiveawayPackagesView, { tableProps: {
            data: giveawayPackagesQuery.data?.packages || [],
            isLoading: giveawayPackagesQuery.isLoading,
        } }));
};
