import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ItemContainer = styled.div `
  display: flex;

  margin-left: 8px;

  ${({ native }) => native &&
    css `
      margin-left: 6px;
    `}
  &:first-child {
    margin-left: 0;
  }
`;
