import { automatedThemeDefaults, ButtonGroup, ComponentsThemeProvider, DashboardLogo, getInternalTheme, Heading, } from '@fourthwall/components';
import React from 'react';
import { routing } from '../../../../utils/routing';
import { OfferStatusChip } from '../OfferStatusChip';
import * as S from './styled';
export const IframePreview = ({ headline, status, buttons, iframeSrc }) => (React.createElement(S.Container, null,
    React.createElement(ComponentsThemeProvider, { automatedTheme: getInternalTheme(automatedThemeDefaults.dark) },
        React.createElement(S.Header, null,
            React.createElement(S.HeaderLogo, null,
                React.createElement(DashboardLogo, { to: routing.home.toString(), appearance: "dark" })),
            React.createElement(S.Headline, null,
                React.createElement(Heading, { type: "h7", asTag: "h6", weight: "semibold" }, headline),
                React.createElement(OfferStatusChip, { status: status })),
            React.createElement(S.HeaderButtons, null,
                React.createElement(ButtonGroup, { space: "large" }, buttons)))),
    React.createElement("div", null,
        React.createElement(S.PreviewIframe, { src: iframeSrc }))));
