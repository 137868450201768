import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const UploadContainer = styled.div `
  border: 1px dashed ${Colors.GS700};
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 40px;
  color: ${Colors.GS600};
`;
export const UploadContent = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Divider = styled.div `
  width: 100%;
  margin-bottom: 26px;
  text-align: center;
  position: relative;

  :before {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    width: calc(50% - 24px);
    top: 50%;
    background: ${Colors.GS500};
  }

  :after {
    content: '';
    position: absolute;
    height: 1px;
    right: 0;
    width: calc(50% - 24px);
    top: 50%;
    background: ${Colors.GS500};
  }
`;
