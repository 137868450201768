import { useModalContext } from '@fourthwall/components';
import { useNavigate } from 'react-router-dom';
import { useDashboardFeatureFlags } from '../../../../../../../../../components/utils/DashboardFeatureFlags';
import { reverse, routing } from '../../../../../../../../../utils/routing';
import { useCurrentShopContext } from '../../../../../../../hooks/useCurrentShopContext';
import { usePages } from '../../../../../../../hooks/usePages';
import { useThemeEditorContext } from '../../../../../../../hooks/useThemeEditorContext';
import { useActiveThemeContext } from '../../../../../../../providers/ActiveThemeProvider';
import { usePageLayoutContext } from '../../../../../../../providers/PageLayoutProvider';
import { useThemeLivePreviewContext } from '../../../../../../LivePreview/providers/ThemeLivePreviewProvider';
import { useCreateSectionMutation } from '../../../../../hooks/useCreateSectionMutation';
export const useSection = () => {
    const { currentShop } = useCurrentShopContext();
    const { activeTheme } = useActiveThemeContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const navigate = useNavigate();
    const { setIsPreviewLoading, resetPreviewTimestamp, currentPageId } = useThemeEditorContext();
    const { createSectionMutation } = useCreateSectionMutation();
    const { pageLayoutQuery } = usePageLayoutContext();
    const { currentPage } = usePages();
    const { close } = useModalContext();
    const { channel } = useThemeLivePreviewContext();
    const createLayoutSection = (sectionType, blockId, position) => {
        !dashboardFeatureFlags.themeEditorDynamicPreview.enabled && setIsPreviewLoading(true);
        createSectionMutation.mutate([
            {
                shop_id: currentShop.id,
                theme_id: activeTheme.id,
                page_id: currentPageId,
                block_id: blockId,
            },
            {
                sectionType,
                position,
            },
        ], {
            onSuccess: async ({ sectionKey }) => {
                if (dashboardFeatureFlags.themeEditorDynamicPreview.enabled) {
                    const response = await fetch(currentPage?.previewUrl);
                    const htmlText = await response.text();
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(htmlText, 'text/html');
                    const bodyContent = doc.body.outerHTML;
                    channel.emit({
                        action: 'ThemePreview:UpdateDOM',
                        payload: {
                            content: bodyContent,
                        },
                    });
                }
                pageLayoutQuery.refetch();
                !dashboardFeatureFlags.themeEditorDynamicPreview.enabled && resetPreviewTimestamp();
                close();
                navigate(reverse(routing.themeEditor.layout.page.sections, {
                    pageId: currentPageId,
                    sectionKey,
                }));
            },
        });
    };
    return {
        isLoading: createSectionMutation.isLoading,
        createLayoutSection,
    };
};
