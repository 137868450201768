import { getFormatedDate } from './helpers/getFormatedDate';
import { getRecipient } from './helpers/getRecipient';
import { getThumbnail } from './helpers/getThumbnail';
export const getCreatorMessagesViewModel = (response, query, emptyState) => {
    const totalPages = response?.page?.totalPages || 0;
    return {
        page: {
            totalPages,
            showPagination: totalPages > 0,
        },
        creatorMessages: response && response.creatorMessages?.length > 0
            ? response.creatorMessages.map((message) => {
                return {
                    id: message.id,
                    date: getFormatedDate(message.createdAt),
                    messageSubject: message.content.subject,
                    messageBody: message.content.body,
                    status: message.status,
                    recipient: getRecipient(message.potentialNumberOfRecipient),
                    thumbnail: getThumbnail(message.content.attachments),
                };
            })
            : [],
        isFetching: query.isFetching,
        emptyStateViewModel: emptyState,
    };
};
