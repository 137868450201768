import { DelimitedArrayParam, StringParam, useQueryParams } from 'use-query-params';
import React, { useEffect } from 'react';
import { useProducts } from './useProducts/useProducts';
export const useAdditionalProducts = ({ onCreated }) => {
    const { products, isLoading } = useProducts();
    const [query, setQuery] = useQueryParams({
        originOfferId: StringParam,
        additionalOffersIds: DelimitedArrayParam,
    });
    useEffect(() => {
        if (isLoading) {
            return;
        }
        const originOfferId = query.originOfferId;
        const originProduct = products.find((product) => product.id === originOfferId);
        const additionalOffersLength = query.additionalOffersIds?.length;
        if (!originProduct || !additionalOffersLength) {
            return;
        }
        const pluralizedProductText = additionalOffersLength > 1
            ? `additional products created successfully`
            : 'additional product created successfully';
        const message = (React.createElement("p", null,
            React.createElement("strong", null,
                "\"",
                originProduct.name,
                "\""),
            " and ",
            React.createElement("strong", null, additionalOffersLength),
            ' ',
            pluralizedProductText));
        onCreated({ message });
        setQuery({ originOfferId: undefined, additionalOffersIds: undefined });
    }, [isLoading, onCreated, products, query, setQuery]);
};
