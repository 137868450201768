import { mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  ${mediaQueries.tablet} {
    width: auto;
  }
`;
export const ImageContainer = styled.div `
  margin: -16px 0 -17px;
  padding: 0 14px 0 0;
  height: 100%;
  width: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  ${mediaQueries.tablet} {
    height: 89px;
    margin: -12px 0 -13px;
  }

  ${({ isLast }) => isLast &&
    css `
      margin: -16px 0 -16px;

      ${mediaQueries.tablet} {
        margin: -12px 0 -12px;
        height: 88px;
      }
    `}
`;
export const FirstImageLine = styled.div `
  width: 1px;
  background-color: #d8d8d8;
  height: 50%;
  position: absolute;
  left: 28px;
  bottom: 0;

  ${mediaQueries.tablet} {
    height: 8px;
  }
`;
export const ConsecutiveImageLine = styled.div `
  width: 1px;
  background-color: #d8d8d8;
  position: absolute;
  left: 28px;
  bottom: 0;
  top: 0;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: #d8d8d8;
  }
`;
export const LastImageLine = styled.div `
  width: 1px;
  background-color: #d8d8d8;
  position: absolute;
  height: 50%;
  left: 28px;
  top: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(-50%);
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: #d8d8d8;
  }
`;
