import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;
export const ImageContainer = styled.div `
  margin-right: 16px;
`;
export const NameContainer = styled.div `
  flex: 1;
`;
export const Name = styled.a `
  &:hover {
    text-decoration: underline;
  }
`;
export const Description = styled.div `
  margin-left: 16px;

  color: ${Colors.GS600};
`;
