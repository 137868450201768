import { Input as InputField, withFormikSupport } from '@fourthwall/components';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Info } from '../../components/Info/Info';
export const InputComponent = ({ data, value, name, icon, invalid, disabled, error, append, onChange, onBlur, onSettingUpdate, }) => {
    const { id, label, value: initialValue } = data;
    const [lastValue, setLastValue] = useState(initialValue);
    const formik = useFormikContext();
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
        const targetValue = e.target.value === '' ? undefined : e.target.value;
        if (lastValue === targetValue) {
            return;
        }
        setLastValue(targetValue);
        if (!(name && formik.errors[name])) {
            onSettingUpdate(id, targetValue);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InputField, { label: label, name: name, placeholder: label, invalid: invalid, disabled: disabled, error: error, append: append, leftIcon: icon, value: value, onChange: onChange, onBlur: handleBlur }),
        React.createElement(Info, null, data.info)));
};
export const Input = withFormikSupport(InputComponent);
