export const loadStripeScript = () => {
    const scriptElementId = 'stripe-v3-script';
    return new Promise((resolve, reject) => {
        if (document.getElementById(scriptElementId)) {
            resolve();
            return;
        }
        const scriptElement = document.createElement('script');
        scriptElement.id = scriptElementId;
        scriptElement.src = 'https://js.stripe.com/v3/';
        scriptElement.onload = () => resolve();
        scriptElement.onerror = () => reject(new Error('Failed to load Stripe SDK'));
        document.head.appendChild(scriptElement);
    });
};
