import { Toasts } from '@fourthwall/components';
import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import { useMutation } from '@tanstack/react-query';
export const useUpdateImagePostMutation = () => {
    const updateImagePostMutation = useMutation({
        mutationFn: async ({ id, ...payload }) => {
            const { data } = await MembershipsCreatorApiClient.updateImagePost(id, payload);
            return data;
        },
        onError: (error) => {
            const errorMessage = errorMessageSelector(error.response?.data);
            Toasts.notify(errorMessage, { type: 'error' });
        },
    });
    return { updateImagePostMutation };
};
