import { getShopRendererApiUrl } from '@fourthwall/env';
import { ApiBase } from '../ApiBase';
class ShopRendererBlocksApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getBlockSchemas = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/block_schemas/sections/${pathParams.id}`);
        };
        this.getBlocks = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/blocks/sections/${pathParams.id}`);
        };
        this.createBlock = (pathParams, requestBody) => {
            return this.post(`/shops/${pathParams.shop_id}/blocks`, requestBody);
        };
        this.updateBlock = (pathParams, requestBody) => {
            return this.put(`/shops/${pathParams.shop_id}/blocks/${pathParams.block_id}`, requestBody);
        };
        this.reorderBlock = (pathParams, requestBody) => {
            return this.post(`/shops/${pathParams.shop_id}/blocks/${pathParams.block_id}/reorder`, requestBody);
        };
        this.removeBlock = (pathParams) => {
            return this.delete(`/shops/${pathParams.shop_id}/blocks/${pathParams.block_id}`);
        };
    }
}
export const ShopRendererBlocksApiClient = new ShopRendererBlocksApiClientModel(getShopRendererApiUrl('api'));
