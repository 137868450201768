import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;

  height: 100%;

  color: ${Colors.GS550};

  ${mediaQueries.laptop} {
    padding-bottom: 24px;
  }
`;
