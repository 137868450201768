import { CopyLink } from '@components/common/CopyLink';
import { ButtonGroup, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import { DashboardSection } from '../DashboardSection';
import * as S from './styled';
export const DashboardItemViewHeader = ({ name, title, link, backButton, toolbar, status, }) => {
    const media = useMediaQueryContext();
    const getTitle = () => {
        if (!title && !link)
            return null;
        return (React.createElement(React.Fragment, null,
            link && (React.createElement(S.TitleLink, null,
                React.createElement(CopyLink, { appearance: media.laptop ? 'primary' : 'secondary', href: link.url, label: link.label }))),
            title && title));
    };
    const renderStatus = () => {
        if (!status)
            return null;
        return React.createElement(S.StatusWrapper, null, status);
    };
    const renderTopContent = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement(S.BackButtonWrapper, null, backButton),
            !media.tablet && toolbar));
    };
    const renderToolbarContent = () => {
        if (!media.tablet)
            return null;
        return (React.createElement(ButtonGroup, null,
            renderStatus(),
            toolbar));
    };
    return (React.createElement(DashboardSection, { name: name, title: getTitle(), bottomContent: !media.tablet && renderStatus(), topContent: renderTopContent(), toolbarContent: renderToolbarContent(), bottomMargin: "large" }));
};
