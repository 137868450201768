import isNil from 'lodash-es/isNil';
import sumBy from 'lodash-es/sumBy';
import React, { useState } from 'react';
import { OrderVariantsModalView } from './OrderVariantsModalView';
export const OrderVariantsModalContainer = ({ offer, replacements, close, onConfirm, }) => {
    const variantTypes = offer.offer.variantTypes;
    const [selectedVariantId, setSelectedVariantId] = useState(offer.variant.id);
    const viewVariants = offer.offer.variants
        .filter((variant) => variant.id === selectedVariantId || variant.status !== 'ARCHIVED')
        .map((variant) => {
        const isReplacingFrom = (replacement) => replacement.from === variant.id;
        const isReplacingTo = (replacement) => replacement.to === variant.id;
        const additionalQuantity = sumBy(replacements.filter(isReplacingFrom), 'quantity');
        const selectedQuantity = sumBy(replacements.filter(isReplacingTo), 'quantity');
        const currentQuantity = !isNil(variant.quantity)
            ? variant.quantity + additionalQuantity - selectedQuantity
            : variant.quantity;
        return {
            id: variant.id,
            name: variant.name,
            description: variant.attributesDescription,
            quantity: variant.quantity,
            disabled: !isNil(currentQuantity) && currentQuantity < offer.quantity,
            selected: selectedVariantId === variant.id,
        };
    });
    const selectedVariant = offer.offer.variants?.find((variant) => variant.id === selectedVariantId);
    const currentVariant = {
        id: offer.variant.id,
        name: offer.variant.name,
        description: offer.variant.attributesDescription,
        image: offer.variant.images[0]?.url,
    };
    const handleConfirm = () => {
        close();
        if (!selectedVariant) {
            return;
        }
        onConfirm({
            ...offer,
            price: selectedVariant.price,
            variantId: selectedVariant.id,
            variant: {
                ...selectedVariant,
                images: selectedVariant.images.map((imageId) => offer.offer.images.find((offerImage) => offerImage.id === imageId)),
            },
        });
    };
    return (React.createElement(OrderVariantsModalView, { currentVariant: currentVariant, variants: viewVariants, hasVariants: !!variantTypes?.length, disabled: !variantTypes?.length || !selectedVariant, onSelectedVariantChange: (variant) => setSelectedVariantId(variant.id), onConfirm: handleConfirm, close: close }));
};
