import { mapQueryToPaginationProps, usePagination } from '@fourthwall/components';
import { usePayoutTransactionsQuery } from '../usePayoutTransactionsQuery';
import { getPaidTo } from './utils';
export const PAYOUT_STATUS = {
    PENDING: 'PROCESSING',
    IN_PROGRESS: 'PROCESSING',
    PAID: 'PAID',
    FAILED: 'FAILED',
};
export const usePayoutsHistory = ({ enabled = false, }) => {
    const [currentPage, setCurrentPage] = usePagination();
    const { payoutTransactionsQuery } = usePayoutTransactionsQuery([
        {
            page: currentPage - 1,
            size: 10,
        },
    ], {
        enabled,
        keepPreviousData: true,
    });
    const data = payoutTransactionsQuery.data?.transactions.map(({ createdAt, transferAmount, payoutAmount, paidTo, status }) => ({
        date: createdAt,
        transferAmount,
        payoutAmount,
        paidTo: getPaidTo(paidTo),
        status: PAYOUT_STATUS[status],
    })) || [];
    const pagination = mapQueryToPaginationProps(payoutTransactionsQuery, currentPage, setCurrentPage);
    return {
        data,
        isLoading: payoutTransactionsQuery.isLoading,
        pagination,
    };
};
