import { Colors, getAutomatedStyleProp } from '@fourthwall/components';
import styled, { css } from 'styled-components';
const PAYPAL_BUTTON_Z_INDEX = 200;
export const Subtitle = styled.div `
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 24px;

  p + p {
    margin-top: 12px;
  }
`;
export const RadioWrapper = styled.div `
  & + & {
    margin-top: 16px;
  }
`;
export const RadioContent = styled.div `
  padding-left: 36px;
  padding-top: 12px;

  ${({ isHidden }) => isHidden &&
    css `
      display: none;
    `}
`;
export const PaymentMethodTypeItem = styled.div `
  border: 1px solid ${Colors.GS500};
  border-bottom-width: 0;
  padding: 16px;

  & + & {
    border-bottom-width: 1px;
  }
`;
export const StripeFormWrapper = styled.div `
  margin-top: 16px;
`;
export const PayPalIcon = styled.img `
  height: 16px;
  margin-top: 5px;
`;
export const CheckboxWrapper = styled.div `
  margin-top: 16px;
`;
export const LoaderWrapper = styled.div `
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PayPalButtonContainer = styled.div `
  position: relative;

  .paypal-buttons {
    vertical-align: top;
    margin: 1px 0;
  }

  #paypal-button-element {
    position: relative;
    z-index: 1;
  }
`;
export const PayPalButtonOverlay = styled.div `
  position: absolute;
  z-index: ${PAYPAL_BUTTON_Z_INDEX + 1};
  width: 100%;
  height: 100%;
  background-color: ${getAutomatedStyleProp('colorBackground', Colors.GS100)};
  opacity: 0.8;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
`;
