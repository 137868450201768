import { Banner, Button, Division, Input, ModalBox, Select } from '@fourthwall/components';
import { isRequired } from '@fourthwall/utils/lib/validation';
import { Field, Formik } from 'formik';
import React from 'react';
import * as S from './styled';
export const OrderCancelConfirmModalView = ({ content, error, initialValues, reasonOptions, isLoading, onSubmit, onClose, }) => {
    const renderInput = () => {
        if (reasonOptions) {
            return (React.createElement(Field, { component: Select, name: "reason", label: "Refund reason", options: reasonOptions, validate: isRequired('Please select a refund reason. This reason will appear in TikTok Seller Center.'), suggestion: "This reason will appear in TikTok Seller Center." }));
        }
        return (React.createElement(Field, { component: Input, name: "reason", label: "Refund reason (optional)", suggestion: "Only you and other managers can see this reason." }));
    };
    return (React.createElement(Formik, { initialValues: initialValues, enableReinitialize: true, onSubmit: onSubmit }, ({ submitForm }) => (React.createElement(ModalBox, { title: "Are you sure you want to cancel these items?", buttons: [
            React.createElement(Button, { label: "Back", appearance: "secondary", onClick: onClose }),
            React.createElement(Button, { label: "Yes, cancel these items", appearance: "destructive", loading: isLoading, onClick: submitForm }),
        ], onClose: onClose },
        error && (React.createElement(S.ErrorBannerContainer, null,
            React.createElement(Banner, { appearance: "danger" }, error))),
        content,
        React.createElement(Division, { size: "small" }, "Once the items are canceled, this cannot be undone. The cost of refunding these items will be taken from your account. Refunds will be sent directly to the supporter's payment method (it may take up to 48 hours to process) and the items will not be delivered to them."),
        React.createElement(S.InputContainer, null, renderInput())))));
};
