export const IDENTITY_PROVIDERS = {
    'twitter-v2': 'X',
    apple: 'Apple',
    facebook: 'Facebook',
    google: 'Google',
    twitch: 'Twitch',
    youtube: 'YouTube',
};
export const isIdentityProvider = (value) => {
    return value ? Object.keys(IDENTITY_PROVIDERS).includes(value) : false;
};
