import { Formik } from 'formik';
import React, { useRef } from 'react';
import { useShippingProfilesQuery } from '@modules/Dashboard/hooks/useShippingProfilesQuery';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { useShopShippingFlatRatesQuery } from '../../../hooks/useShopShippingFlatRatesQuery';
import { validationSchema } from './consts';
import { useSaveFlatRatesMutation } from './hooks/useSaveFlatRatesMutation';
import { useUpdateShopOriginLocationMutation } from './hooks/useUpdateShopOriginLocationMutation';
import { ShippingView } from './ShippingView';
import { getInitialValues, getSaveFlatRatesPayload } from './utils';
import { useSetShippingProfilesStateMutation } from './hooks/useSetShippingProfilesStateMutation';
export const ShippingContainer = () => {
    const { currentShopQuery } = useCurrentShopQuery();
    const { shopShippingFlatRatesQuery } = useShopShippingFlatRatesQuery();
    const { saveFlatRatesMutation } = useSaveFlatRatesMutation();
    const { updateShopOriginLocationMutation } = useUpdateShopOriginLocationMutation(currentShopQuery.data?.id);
    const { setShippingProfilesStateMutation } = useSetShippingProfilesStateMutation();
    const flatRates = shopShippingFlatRatesQuery.data;
    const { shippingProfilesQuery, invalidateShippingProfilesQuery } = useShippingProfilesQuery();
    const formRef = useRef(null);
    const initialValues = getInitialValues(flatRates, currentShopQuery.data, shippingProfilesQuery.data);
    const shippingPricingMethodOptions = [
        { label: 'Fixed shipping rates', value: 'Fixed' },
        { label: 'Estimate based on order weight', value: 'Weighted' },
    ];
    const handleSubmit = (values) => {
        saveFlatRatesMutation.mutate(getSaveFlatRatesPayload(values));
        if (values.shippingLocation) {
            updateShopOriginLocationMutation.mutate(values.shippingLocation);
        }
        if (values.withShippingGroupsYouShip !== initialValues.withShippingGroupsYouShip) {
            if (values.withShippingGroupsYouShip &&
                !shippingProfilesQuery.data?.shippingProfiles.length) {
                formRef.current?.setFieldValue('withShippingGroupsYouShip', false);
            }
            else {
                setShippingProfilesStateMutation.mutate([
                    {
                        enabled: values.withShippingGroupsYouShip,
                    },
                ], {
                    onSuccess: invalidateShippingProfilesQuery,
                });
            }
        }
    };
    return (React.createElement(Formik, { innerRef: formRef, initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: handleSubmit },
        React.createElement(ShippingView, { shippingPricingMethodOptions: shippingPricingMethodOptions, isLoading: shopShippingFlatRatesQuery.isFetching || shippingProfilesQuery.isFetching, isSubmitting: saveFlatRatesMutation.isLoading ||
                updateShopOriginLocationMutation.isLoading ||
                setShippingProfilesStateMutation.isLoading })));
};
