export const getIntervals = (intervalsParam) => {
    if (intervalsParam === 'Month' || intervalsParam === 'Year') {
        return [intervalsParam];
    }
};
export const getIntervalsSelect = (reportType, intervalsParam, setIntervalsParam) => {
    if (reportType !== 'memberships-members-change' && reportType !== 'memberships-total-members') {
        return;
    }
    return {
        options: [
            { label: 'All', value: 'ALL' },
            { label: 'Month', value: 'Month' },
            { label: 'Annual', value: 'Year' },
        ],
        value: intervalsParam || 'ALL',
        onValueChange: (val) => {
            setIntervalsParam(val);
        },
    };
};
