import { useAuth } from '@fourthwall/auth';
import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { isExcludedProductSelection, isSelectedProductSelection, mapDataToInitialValues, mapValuesToMutationPayload, } from '@modules/Dashboard/Apps/modules/AppsTwitch/utils';
import React from 'react';
import logoSrc from '../../../assets/signets/twitch.svg';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
import { useCreatorIntegrationsQuery } from '../../../queries/useCreatorIntegrationsQuery';
import { useStreamelements } from '../../AppsStreamelements';
import { useGiveawayConfigOAuthConnectionsQuery } from '../queries';
import { useDeleteTwitchMutation } from './useDeleteTwitchMutation';
import { useGiftableProducts } from './useGiftableProducts';
import { useGiveawayConfigMutation } from './useGiveawayConfigMutation';
import { useGiveawayConfigQuery } from './useGiveawayConfigQuery';
import { useTwitchAuthorizationQuery } from './useTwitchAuthorizationQuery';
import { useTwitchToken } from './useTwitchToken';
const connectBarBase = {
    logoSrc,
    logoAlt: 'Twitch',
    description: 'Connect to your Twitch account',
};
export const useTwitch = () => {
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const includedProducts = useGiftableProducts();
    const excludedProducts = useGiftableProducts({
        modalTitle: 'Select products to exclude from gifting',
    });
    const { giveawayConfigQuery } = useGiveawayConfigQuery(undefined, {
        onSuccess: (data) => {
            includedProducts.setProductsIds(isSelectedProductSelection(data.productSelection) ? data.productSelection.offerIds : []);
            excludedProducts.setProductsIds(isExcludedProductSelection(data.productSelection) ? data.productSelection.offerIds : []);
        },
    });
    const { twitchAuthorizationQuery } = useTwitchAuthorizationQuery([{ referencePath: window.location.pathname }], {
        enabled: creatorIntegrationsQuery.data?.twitch.status === 'NOT_CONNECTED' ||
            !!creatorIntegrationsQuery.data?.twitch?.missingScopes?.length,
    });
    const { giveawayConfigMutation } = useGiveawayConfigMutation({
        onSuccess: () => {
            Toasts.notify('Gifting settings saved successfully.', { type: 'success' });
        },
    });
    const { deleteTwitchMutation } = useDeleteTwitchMutation();
    const { streamelements } = useStreamelements();
    const { open } = useModalContext();
    const twitchConnectEvent = useAppConnectEvent('twitch_gifting');
    const streamelementsConnectEvent = useAppConnectEvent('stream_elements_alerts');
    const { giveawayConfigOAuthConnectionsQuery } = useGiveawayConfigOAuthConnectionsQuery();
    const { keycloak } = useAuth();
    useTwitchToken();
    const getStatus = () => {
        if (!giveawayConfigQuery.data)
            return undefined;
        if (giveawayConfigQuery.data.platform === 'OPEN_API')
            return streamelements.status;
        return creatorIntegrationsQuery.data?.twitch.status;
    };
    const getTwitchConnectBar = () => {
        // unknown
        if (!creatorIntegrationsQuery.data)
            return undefined;
        const status = creatorIntegrationsQuery.data.twitch.status;
        const onConnect = () => {
            if (twitchAuthorizationQuery.data?.redirectUrl) {
                twitchConnectEvent.trigger();
                window.location.assign(twitchAuthorizationQuery.data.redirectUrl);
            }
        };
        const onDisconnect = () => {
            open(ConfirmModal, {
                title: 'Are you sure you want to disconnect from Twitch?',
                confirmLabel: 'Yes, disconnect',
                confirmAppearance: 'destructive',
                onConfirm: () => {
                    const toastId = 'deleteTwitchMutation';
                    Toasts.notify('Disconnecting from Twitch...', {
                        toastId,
                        type: 'info',
                        autoClose: false,
                    });
                    deleteTwitchMutation.mutate(undefined, {
                        onSuccess: () => {
                            creatorIntegrationsQuery.refetch();
                            Toasts.notify('Disconnected from Twitch successfully.', { type: 'success' });
                            Toasts.dismiss(toastId);
                        },
                    });
                },
            });
        };
        // not connected
        if (status === 'NOT_CONNECTED') {
            return {
                ...connectBarBase,
                disabled: twitchAuthorizationQuery.isLoading,
                onConnect,
            };
        }
        if (status === 'CONNECTED' && !!creatorIntegrationsQuery.data?.twitch?.missingScopes?.length) {
            return {
                ...connectBarBase,
                description: creatorIntegrationsQuery.data.twitch.channelName,
                connectButtonLabel: 'Update permissions',
                disabled: twitchAuthorizationQuery.isLoading,
                onConnect,
                onDisconnect,
            };
        }
        // connected
        if (status === 'CONNECTED') {
            return {
                ...connectBarBase,
                description: creatorIntegrationsQuery.data.twitch.channelName,
                disabled: deleteTwitchMutation.isLoading,
                onDisconnect,
            };
        }
    };
    const getStreamelementsConnectBanner = () => {
        if (!giveawayConfigOAuthConnectionsQuery.isSuccess)
            return;
        const onConnect = () => {
            streamelementsConnectEvent.trigger();
            const url = CreatorIntegrationsApiClient.appAuthorizationUrl({
                app: 'streamelements',
                token: keycloak?.token,
            });
            window.location.assign(url);
        };
        const { data } = giveawayConfigOAuthConnectionsQuery;
        if (data.streamelements.type === 'CONNECTED' &&
            data.streamelements.missingScopes &&
            data.streamelements.missingScopes.length) {
            return {
                children: (React.createElement(React.Fragment, null,
                    "You have to ",
                    React.createElement("button", { onClick: onConnect }, "reconnect"),
                    " in order for StreamElements chatbot to start working.")),
                appearance: 'warning',
            };
        }
    };
    const getFormProps = () => {
        if (!giveawayConfigQuery.data)
            return undefined;
        return {
            initialValues: mapDataToInitialValues(giveawayConfigQuery.data),
            validate: (values) => {
                if (values.platform === 'TWITCH' &&
                    creatorIntegrationsQuery.data &&
                    creatorIntegrationsQuery.data?.twitch.status !== 'CONNECTED') {
                    return { platform: 'You must connect to Twitch before selecting this chatbot.' };
                }
                if (values.platform === 'OPEN_API' &&
                    streamelements.status &&
                    streamelements.status !== 'CONNECTED') {
                    return { platform: 'You must connect to StreamElements before selecting this chatbot.' };
                }
                return {};
            },
            onSubmit: (values) => {
                giveawayConfigMutation.mutate(mapValuesToMutationPayload(values), {
                    onSuccess: () => giveawayConfigQuery.refetch(),
                });
            },
        };
    };
    const getConfirmBarProps = () => ({
        isLoading: giveawayConfigMutation.isLoading,
        onCancel: () => {
            if (giveawayConfigQuery.data) {
                const initialValues = mapDataToInitialValues(giveawayConfigQuery.data);
                includedProducts.setProductsIds(initialValues.includedProductsIds);
                excludedProducts.setProductsIds(initialValues.excludedProductsIds);
            }
        },
    });
    return {
        status: getStatus(),
        twitch: {
            status: creatorIntegrationsQuery.data?.twitch.status,
            twitchConnect: {
                connectBar: getTwitchConnectBar(),
            },
        },
        streamelements: {
            status: streamelements.status,
            streamelementsConnect: {
                connectBar: streamelements.connectBar,
                banner: getStreamelementsConnectBanner(),
            },
        },
        formProps: getFormProps(),
        confirmBarProps: getConfirmBarProps(),
        giftableProducts: {
            includedProducts,
            excludedProducts,
        },
    };
};
