import { getPrice } from '@fourthwall/utils';
const getConfigState = ({ onSignUp: { value: recipientSignupValue }, onMinimumOrderAchieved: { value: recipientOrdersValue }, }, { onSignUp: { value: referrerSignupValue }, onMinimumOrderAchieved: { value: referrerOrdersValue }, }) => ({
    recipient: {
        signup: {
            value: recipientSignupValue,
            price: getPrice(recipientSignupValue),
        },
        orders: {
            value: recipientOrdersValue,
            price: getPrice(recipientOrdersValue),
        },
    },
    referrer: {
        signup: {
            value: referrerSignupValue,
            price: getPrice(referrerSignupValue),
        },
        orders: {
            value: referrerOrdersValue,
            price: getPrice(referrerOrdersValue),
        },
    },
});
const getRecipientBenefitsText = ({ signup }) => signup.value > 0 ? `They get ${signup.price} sample credit on sign up. ` : '';
const getReferralBenefitsText = ({ recipient, referrer }) => {
    let referralBenefitsText = '';
    if (recipient.orders.value === referrer.orders.value && recipient.orders.value > 0) {
        referralBenefitsText = `You each get ${referrer.orders.price}`;
    }
    else if (referrer.signup.value > 0) {
        referralBenefitsText = `You get ${referrer.signup.price} when they sign up`;
        if (referrer.orders.value > 0) {
            referralBenefitsText += ` plus another ${referrer.orders.price}`;
        }
    }
    else if (referrer.orders.value > 0) {
        referralBenefitsText = `You get ${referrer.orders.price}`;
    }
    return referralBenefitsText;
};
const getConditionText = (signupBonusOauthRequired, minimumOrdersToBonus) => {
    if (signupBonusOauthRequired) {
        return ' if they sign up with their primary social';
    }
    if (minimumOrdersToBonus > 0) {
        return ` once they've made ${minimumOrdersToBonus} or more sales`;
    }
    return '';
};
export const getReferralDescription = ({ recipientBonus, referrerBonus, minimumOrdersToBonus, signupBonusOauthRequired, }) => {
    const configState = getConfigState(recipientBonus, referrerBonus);
    const recipientBenefitsText = getRecipientBenefitsText(configState.recipient);
    const referralBenefitsText = getReferralBenefitsText(configState);
    const conditionText = getConditionText(signupBonusOauthRequired, minimumOrdersToBonus);
    return `${recipientBenefitsText}${referralBenefitsText}${conditionText}.`;
};
