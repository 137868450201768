import { useModalContext, usePagination } from '@fourthwall/components';
import React from 'react';
import { CreatePromotionModal } from '../../components/CreatePromotionModal';
import { PromotionCodesView } from './PromotionCodesView';
import { PER_PAGE } from './consts';
import { usePromotionsQuery } from './hooks/usePromotionsQuery';
import { useFilterPromotionCodes } from './modules/PromotionCodesFilter';
export const PromotionCodesContainer = () => {
    const { open } = useModalContext();
    const [currentPage, setCurrentPage] = usePagination();
    const { filtersParams, hasFilterParams } = useFilterPromotionCodes();
    const { promotionsQuery } = usePromotionsQuery([
        {
            page: currentPage - 1,
            size: PER_PAGE,
            creationReasons: filtersParams.creationReason || ['MANUAL'],
        },
    ], {
        keepPreviousData: true,
    });
    const getEmptyStateProps = () => {
        if (hasFilterParams) {
            return {
                title: 'No promotions found',
                subtitle: 'Adjust your filters.',
            };
        }
        return {
            title: "You haven't created any promo codes yet.",
            label: 'Create promo code',
            onClick: () => open(CreatePromotionModal),
        };
    };
    return (React.createElement(PromotionCodesView, { promotions: promotionsQuery.data?.promotions, totalPages: promotionsQuery.data?.page.totalPages || 0, currentPage: currentPage, isFetching: promotionsQuery.isFetching, emptyStateProps: getEmptyStateProps(), onPageChange: setCurrentPage }));
};
