import isUndefined from 'lodash-es/isUndefined';
import React, { cloneElement, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { CloseIcon, DragIcon } from '@fourthwall/icons';
import { Icon } from '../Icon';
import { HELPER_CLASS } from './consts';
import * as S from './styled';
const DragHandle = SortableHandle(() => (React.createElement(S.DragHandle, { title: "Drag" },
    React.createElement(Icon, { component: DragIcon, height: 14 }))));
export const Toolbar = ({ onRemove }) => {
    return (React.createElement(S.ToolbarContainer, null,
        React.createElement(DragHandle, null),
        React.createElement(S.Handle, { title: "Remove", onClick: onRemove, "data-testid": "SortableBlocks.RemoveHandle" },
            React.createElement(Icon, { component: CloseIcon, height: 14 }))));
};
const SortableBlocksElement = SortableElement(({ isDragged, children, onRemove }) => (React.createElement(S.Element, { isDragged: isDragged },
    children,
    React.createElement(Toolbar, { onRemove: onRemove }))));
const SortableBlockItem = ({ index, isDragged, children, onRemove }) => {
    if (isUndefined(index) || isUndefined(isDragged))
        return null;
    const handleRemove = () => {
        if (onRemove) {
            onRemove(index);
        }
    };
    return (React.createElement(S.Item, { "data-testid": "SortableBlocks.Item" },
        React.createElement(S.Placeholder, null),
        React.createElement(SortableBlocksElement, { index: index, isDragged: isDragged, onRemove: handleRemove }, children)));
};
const SortableBlocksContainer = SortableContainer(({ isDragged, children, onRemove }) => {
    return (React.createElement(S.Container, null, React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
            return cloneElement(child, { index, isDragged, onRemove });
        }
    })));
});
export const SortableBlocks = ({ children, onSortChange, onRemove }) => {
    const [isDragged, setIsDragged] = useState(false);
    const handleSortStart = () => {
        setIsDragged(true);
    };
    const handleSortEnd = ({ oldIndex, newIndex }) => {
        setIsDragged(false);
        if (onSortChange) {
            onSortChange(oldIndex, newIndex);
        }
    };
    return (React.createElement(SortableBlocksContainer, { isDragged: isDragged, helperClass: HELPER_CLASS, useDragHandle: true, distance: 1, axis: "y", onSortStart: handleSortStart, onSortEnd: handleSortEnd, onRemove: onRemove }, children));
};
SortableBlocks.Item = SortableBlockItem;
