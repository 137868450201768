import { useOffersQuery } from '@queries/useOffersQuery';
// minimum valid size
const size = 5;
export const useOffersTotal = () => {
    const { offersQuery: allQuery } = useOffersQuery([{ size }]);
    const { offersQuery: publicQuery } = useOffersQuery([{ size, status: ['PUBLIC'] }]);
    if (!allQuery.isSuccess || !publicQuery.isSuccess)
        return null;
    return {
        all: allQuery.data.page.elementsTotal,
        public: publicQuery.data.page.elementsTotal,
    };
};
