import Hls from 'hls.js/dist/hls.light.min';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import React, { useEffect, useRef } from 'react';
import * as S from './AudioPlayer.styled';
import iconSprite from './assets/icon-sprite.svg';
export const AudioPlayer = ({ audioSrc, thumbnailSrc, size = 'medium', hls }) => {
    const audioElement = useRef(null);
    const controls = [
        'rewind',
        'play',
        'fast-forward',
        'progress',
        'current-time',
        'duration',
        'mute',
        'volume',
        'airplay',
    ];
    useEffect(() => {
        if (!hls && audioElement.current) {
            audioElement.current.src = audioSrc;
            new Plyr(audioElement.current, {
                controls,
                iconUrl: iconSprite,
                seekTime: 15,
                tooltips: { controls: false, seek: false },
            });
        }
        if (hls && audioElement.current) {
            const hls = new Hls();
            if (!Hls.isSupported()) {
                audioElement.current.src = audioSrc;
                new Plyr(audioElement.current, {
                    controls,
                    iconUrl: iconSprite,
                    seekTime: 15,
                    tooltips: { controls: false, seek: false },
                });
            }
            else {
                hls.loadSource(audioSrc);
                hls.attachMedia(audioElement.current);
                new Plyr(audioElement.current, {
                    controls,
                    iconUrl: iconSprite,
                    seekTime: 15,
                    tooltips: { controls: false, seek: false },
                });
            }
        }
    }, [audioSrc, thumbnailSrc, hls]);
    return (React.createElement(S.Container, { size: size, "data-testid": "AudioPlayer" },
        thumbnailSrc && React.createElement(S.Thumbnail, { src: thumbnailSrc }),
        React.createElement(S.Controls, null,
            React.createElement(S.MediaPlayer, { size: size },
                React.createElement("audio", { controls: true, preload: "none", ref: audioElement })))));
};
