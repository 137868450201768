import styled from 'styled-components';
import { Colors } from '../../../colors';
const getContainerPadding = (spacing) => {
    return { tiny: 8, small: 12, regular: 16 }[spacing];
};
export const Container = styled.div `
  padding: ${({ $spacing }) => getContainerPadding($spacing)}px 0;
  border-bottom: 1px solid ${Colors.GS400};

  &:last-child {
    border-bottom: none;
  }
`;
export const HeadingContainer = styled.div `
  display: flex;
  align-items: center;
  /* font-weight: 600; */ // TODO
  position: relative;
`;
export const Heading = styled.div `
  flex: 1;
`;
export const ExpandButton = styled.button `
  margin-left: 8px;
  margin-right: -8px;
  padding: 8px;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;
