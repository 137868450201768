import React from 'react';
import { ButtonClean, DateString, PriceString, Tag } from '@fourthwall/components';
import { PaymentMethod } from '../PaymentMethod';
import { tagsAppearances } from '../../consts';
import * as S from './styled';
export const ChargesHistoryListItem = ({ chargesHistoryItem, locationState, }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.LeftSide, null,
            React.createElement(S.Date, null,
                React.createElement(DateString, { date: chargesHistoryItem.date, format: "MMM DD, YYYY" })),
            React.createElement(ButtonClean, { to: chargesHistoryItem.type.url, label: chargesHistoryItem.type.label, state: locationState }),
            React.createElement(S.TagWrapper, null,
                React.createElement(Tag, { label: chargesHistoryItem.status, appearance: tagsAppearances[chargesHistoryItem.status] }))),
        React.createElement(S.RightSide, null,
            React.createElement(S.PriceWrapper, null,
                React.createElement(PriceString, { value: chargesHistoryItem.amount.value, currency: chargesHistoryItem.amount.currency })),
            React.createElement(PaymentMethod, { method: chargesHistoryItem.method }))));
};
