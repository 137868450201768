import { MembershipsApiClient } from '@fourthwall/services/lib/MembershipsApiClient';
import { useQuery } from '@tanstack/react-query';
export const useMembershipAccountQuery = (options) => {
    const membershipAccountQuery = useQuery({
        queryKey: ['membershipAccount'],
        queryFn: async () => {
            const { data } = await MembershipsApiClient.getAccount();
            return data;
        },
        ...(options || {}),
        retry: false,
        onError: () => {
            // NOTE: we have to ignore default error behavior since 404 simply means that memberships are not configured,
            // and we don't need error message then
        },
    });
    return {
        membershipAccountQuery,
    };
};
