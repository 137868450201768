import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { reverse, routing } from '@utils/routing';
import { useGiveawayContributionQuery } from '../../Giveaway';
import { useGiftQuery } from './useGiftQuery';
export const useOrder = (order) => {
    const { giftQuery } = useGiftQuery([order?.giftId], { enabled: !!order?.giftId });
    const { giveawayContributionQuery } = useGiveawayContributionQuery([{ giveawayId: giftQuery.data?.giveawayId }], { enabled: !!giftQuery.data?.giveawayId });
    const location = useLocation();
    const locationState = { from: { label: `Order ${order?.friendlyId}`, location } };
    const getGiveawayBanner = () => {
        if (!giftQuery.data)
            return;
        if (!giftQuery.data.giveawayId) {
            return { children: 'This is a giveaway order.', appearance: 'warning' };
        }
        if (!giveawayContributionQuery.data)
            return;
        const { supporter, giveawayId, friendlyId } = giveawayContributionQuery.data;
        const by = supporter.username || supporter.email;
        return {
            children: (React.createElement(React.Fragment, null,
                "This order is a redeemed gift funded by ",
                by,
                " (",
                React.createElement(Link, { to: reverse(routing.contributions.orders.giveaways.giveaway, { giveawayId }), state: locationState },
                    "#",
                    friendlyId),
                ")")),
            appearance: 'warning',
        };
    };
    const getGiftAsPromotionRedeemBanner = () => {
        if (order?.promotion?.creationReason?.type !== 'FROM_GIFT')
            return;
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { giveawayId } = order?.promotion?.creationReason;
        if (!giveawayId)
            return;
        return {
            children: (React.createElement(React.Fragment, null,
                "This order used a promo code that came from a",
                ' ',
                React.createElement(Link, { to: reverse(routing.contributions.orders.giveaways.giveaway, { giveawayId }), state: locationState }, "purchased gift"),
                ".",
                ' ',
                React.createElement("a", { href: routing.external.helpCenter.twitchGifting, target: "_blank", rel: "noopener noreferrer" }, "Learn more"))),
            appearance: 'warning',
        };
    };
    const getSalesChannelBanner = () => {
        const type = order?.salesChannel?.type;
        if (!type)
            return;
        if (type === 'TikTok') {
            return {
                children: (React.createElement(React.Fragment, null,
                    "This order was placed through TikTok Shop.",
                    ' ',
                    React.createElement("a", { href: routing.external.helpCenter.tiktokShop, target: "_blank", rel: "noopener noreferrer" }, "Learn more"),
                    React.createElement("br", null),
                    React.createElement("a", { href: `${routing.external.tiktok.orderDetails}?order_no=${order?.salesChannel?.id}&shop_region=US`, target: "_blank", rel: "noopener noreferrer" }, "View order details in TikTok Seller Center"))),
                appearance: 'warning',
            };
        }
        if (type === 'Meta') {
            return {
                children: (React.createElement(React.Fragment, null,
                    "This order was placed through Meta Shop.",
                    ' ',
                    React.createElement("a", { href: routing.external.helpCenter.meta, target: "_blank", rel: "noreferrer" }, "Learn more"),
                    React.createElement("br", null),
                    React.createElement("a", { href: `https://business.facebook.com/commerce/${order?.salesChannel?.cmsId}/orders/${order?.salesChannel?.id}`, target: "_blank", rel: "noreferrer" }, "View order details in Meta Commerce Manager"))),
                appearance: 'warning',
            };
        }
    };
    const banners = [
        getGiveawayBanner(),
        getGiftAsPromotionRedeemBanner(),
        getSalesChannelBanner(),
    ].filter(Boolean); // OrderViewProps['banners']
    const shippingMethodTooltip = (() => {
        if (order && order.type === 'GIFT' && giftQuery.data && giftQuery.data.giveawayId) {
            return 'Average shipping costs for purchased gifts are included in the product price.';
        }
    })();
    return {
        order: {
            banners,
            shippingMethodTooltip,
        },
    };
};
