import { addUtmSourceParamToUrl } from '@utils/url';
import { useShopStage } from '@hooks';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { assets } from './assets';
export const useBlogPosts = () => {
    const { shopStage } = useShopStage();
    const { currentShop } = useCurrentShop();
    const { hasPermission } = useDashboardPermissions();
    const getBlogPosts = () => {
        if (!hasPermission('home.guidesAndTips'))
            return undefined;
        const allItems = [
            // stage1
            {
                when: shopStage.currentStage === '1' &&
                    currentShop?.metafields?.onboarding?.['registration-type'] === 'STREAMELEMENTS',
                title: 'Getting Started on Fourthwall: StreamElements',
                description: 'Get your site and products up and running in just a few steps.',
                imageSrc: assets['getting-started-on-fourthwall'],
                url: 'https://fourthwall.com/blog/getting-started-on-fourthwall-streamelements',
                timeToRead: '5 min read',
            },
            {
                when: shopStage.currentStage === '1' &&
                    currentShop?.metafields?.onboarding?.['registration-type'] !== 'STREAMELEMENTS',
                title: 'Getting Started on Fourthwall',
                description: 'Build and launch your shop in a few quick steps.',
                imageSrc: assets['getting-started-on-fourthwall'],
                url: 'https://fourthwall.com/blog/getting-started-on-fourthwall',
                timeToRead: '4 min read',
            },
            {
                when: shopStage.currentStage === '1',
                title: 'How to Get Your First Sales: Growing Income as a Creator',
                description: "We've rounded up the best advice from top-selling creators and the Fourthwall team to help you effectively promote your next launch.",
                imageSrc: assets['how-to-get-your-first-sales'],
                url: 'https://fourthwall.com/blog/how-to-get-your-first-sales',
                timeToRead: '5 min read',
            },
            // stage1 & stage2
            {
                title: '10 Tips to 10x Your Sales',
                description: 'Master Your Sales: 10 Proven Tips to Boost Your Shop Revenue',
                imageSrc: assets['10-tips-to-10x-your-sales'],
                url: 'https://fourthwall.com/blog/10-tips-to-10x-your-sales',
                timeToRead: '5 min read',
            },
            // stage2
            {
                when: shopStage.stage2,
                title: '8 E-Commerce Strategies Creators Can Use to Build Their Brand',
                description: "Your community loves your content. Here's how to give them more of what they love (without a ton of extra work).",
                imageSrc: assets['ten-e-commerce-strategies-creators-can-use-to-build-their-brand'],
                url: 'https://fourthwall.com/blog/ten-e-commerce-strategies-creators-can-use-to-build-their-brand',
                timeToRead: '7 min read',
            },
        ];
        return {
            title: 'Guides & tips',
            articleList: {
                items: allItems
                    .filter(({ when = true }) => when)
                    .map((item) => ({
                    ...item,
                    url: addUtmSourceParamToUrl(item.url, 'onboarding'),
                })),
            },
        };
    };
    return { blogPosts: getBlogPosts() };
};
