import * as yup from 'yup';
import { FormFields } from './types';
export const validationSchema = yup.object().shape({
    [FormFields.enabled]: yup.boolean().required(),
    [FormFields.tier]: yup
        .string()
        .when(FormFields.enabled, (enabled) => enabled ? yup.string().required() : yup.string()),
    [FormFields.value]: yup
        .number()
        .when(FormFields.enabled, (enabled) => enabled ? yup.number().required().min(10).max(100000) : yup.number()),
});
