import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { List, Table, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import { ThankYouListItem } from './components/ThankYouListItem';
import { getTableHeaders } from './utils/getTableHeaders';
export const ThankYousData = ({ data, isLoading, activeTab, emptyComponent, onRowClick, }) => {
    const { hasPermission } = useDashboardPermissions();
    const media = useMediaQueryContext();
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, emptyComponent: emptyComponent, renderItem: (thankYou) => React.createElement(ThankYouListItem, { thankYou: thankYou }), onClick: onRowClick }));
    }
    return (React.createElement(Table, { data: data, headers: getTableHeaders(activeTab, {
            hideProfitColumn: !hasPermission('contributions.profit'),
        }), isLoading: isLoading, emptyComponent: emptyComponent, onRowClick: onRowClick ? (item) => onRowClick(item) : undefined }));
};
