import { ButtonClean, Icon, Skeleton } from '@fourthwall/components';
import { ArrowShortRightIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
export const Chart = ({ total, label, to, isLoading, children }) => {
    if (isLoading) {
        return (React.createElement(S.Container, null,
            React.createElement(Skeleton.Shape, { height: 172, width: "100%" })));
    }
    const renderButton = () => {
        if (!to) {
            return React.createElement(S.LinkContainer, null, label);
        }
        return (React.createElement(ButtonClean, { label: React.createElement(S.LinkContainer, null,
                label,
                React.createElement(Icon, { component: ArrowShortRightIcon, height: 10 })), to: to, withUnderline: false }));
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.Total, null, total),
        renderButton(),
        children));
};
