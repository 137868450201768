import React from 'react';
import { SelectedItems } from '@components/common/SelectedItems';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { getDefaultProductImage } from '@utils/products';
import { useMediaQueryContext } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import { StatusTag } from '@components/common/StatusTag';
import * as S from './styled';
export const SelectedProducts = ({ productsCount, onAddProducts, onRemoveProduct, ...props }) => {
    const media = useMediaQueryContext();
    return (React.createElement(SelectedItems, { header: React.createElement(React.Fragment, null,
            productsCount || 'No',
            " ",
            pluralize(productsCount, 'product'),
            " added"), itemsCount: productsCount, addItemsButtonLabel: "Add products", renderItem: (product) => (React.createElement(React.Fragment, null,
            React.createElement(PhotoThumbnail, { url: getDefaultProductImage(product), size: media.laptop ? 'large' : 'medium' }),
            React.createElement(S.ProductDetails, null,
                React.createElement("div", null, product.name),
                React.createElement(StatusTag, { status: product.state.status, isSoldOut: !product.state.available })))), onAddItems: onAddProducts, onRemoveItem: (product) => onRemoveProduct(product.id), ...props }));
};
