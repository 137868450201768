import { countries } from 'countries-list';
import filter from 'lodash-es/filter';
import map from 'lodash-es/map';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';
import sortBy from 'lodash-es/sortBy';
import { AUSTRALIA_STATES, BRAZIL_STATES, CA_STATES, JAPAN_STATES, MILITARY_US_STATES, US_STATES, } from './states';
const mapStateToOption = ({ name, abbreviation }) => ({
    label: name,
    value: abbreviation,
});
export const STATE_OPTIONS = {
    US: sortBy([...US_STATES, ...MILITARY_US_STATES].map(mapStateToOption), ['label']),
    CA: sortBy(CA_STATES.map(mapStateToOption), ['label']),
    JP: sortBy(JAPAN_STATES.map(mapStateToOption), ['label']),
    AU: sortBy(AUSTRALIA_STATES.map(mapStateToOption), ['label']),
    BR: sortBy(BRAZIL_STATES.map(mapStateToOption), ['label']),
};
// countries not supported for both shipping and digital products
export const BLOCKED_COUNTRIES = [
    'RU', // Russia
];
// non-shippable countries for both shipping origin and shipping destination
export const NON_SHIPPABLE_COUNTRIES = [
    ...BLOCKED_COUNTRIES,
    // Sanctioned by US
    'CU', // Cuba
    'IR', // Iran
    'KP', // North Korea
    'SD', // Sudan
    'SY', // Syria
    // US territories
    'AS', // American Samoa
    'GU', // Guam
    'MP', // Northern Mariana Islands
    'PR', // Puerto Rico
    'UM', // United States Minor Outlying Islands
    'VI', // U.S. Virgin Islands
    // Unsupported
    'AF', // Afghanistan
    'AX', // Åland Islands
    'AQ', // Antarctica
    'BT', // Bhutan
    'BY', // Belarus
    'EC', // Ecuador
    'LA', // Laos
    'LY', // Libya
    'MN', // Mongolia
    'PG', // Papua New Guinea
    'RU', // Russia
    'SB', // Solomon Islands
    'SS', // South Sudan
    'TD', // Chad
    'TL', // Timor-Leste
    'TM', // Turkmenistan
    'WS', // Samoa
    'YE', // Yemen
];
// non-shippable countries for shipping destination
export const CHECKOUT_NON_SHIPPABLE_COUNTRIES = [
    'UA', // Ukraine
    ...NON_SHIPPABLE_COUNTRIES,
];
export const mostPopularCountriesTreshold = 10;
export const MOST_POPULAR_COUNTRIES = [
    'US', // United States
    'GB', // United Kingdom
    'CA', // Canada
];
const getCountries = (params = {}) => {
    const { include, exclude } = params;
    let allCountries = countries;
    if (include) {
        allCountries = pick(countries, include);
    }
    if (exclude) {
        allCountries = omit(allCountries, exclude);
    }
    return allCountries;
};
const formatCountryOptions = (unformattedCountries) => {
    return unformattedCountries.map(({ label, value, disabled }, index) => ({
        label,
        value,
        disabled,
        key: index.toString(),
    }));
};
const dividerOption = {
    label: '---',
    value: '---',
    disabled: true,
};
export const getCountriesOptions = (params) => {
    const { withMostPopular = false, mostPopularWithDivider = true } = params || {};
    const filteredCountries = getCountries(params);
    const options = map(filteredCountries, (country, key) => ({ label: country === null || country === void 0 ? void 0 : country.name, value: key }));
    const filteredOptions = filter(options, ({ label }) => !!label);
    const orderedCountries = filteredOptions.sort((item1, item2) => item1.label.localeCompare(item2.label));
    if (withMostPopular && orderedCountries.length > mostPopularCountriesTreshold) {
        const mostPopularCountries = MOST_POPULAR_COUNTRIES.map((countryCode) => orderedCountries.find(({ value }) => value === countryCode)).filter(Boolean);
        if (mostPopularCountries.length > 0) {
            return formatCountryOptions([
                ...mostPopularCountries,
                dividerOption,
                ...orderedCountries.filter(({ value }) => !MOST_POPULAR_COUNTRIES.includes(value)),
            ].filter(({ value }) => mostPopularWithDivider || value !== dividerOption.value));
        }
        return formatCountryOptions(orderedCountries);
    }
    return formatCountryOptions(orderedCountries);
};
export const getCountryByCode = (countryCode, params) => {
    return getCountries(params)[countryCode] || null;
};
export const getCountryNameByCode = (countryCode) => { var _a; return ((_a = getCountryByCode(countryCode)) === null || _a === void 0 ? void 0 : _a.name) || null; };
export const getStateNameByCode = (countryCode, state) => {
    var _a, _b;
    if (STATE_OPTIONS[countryCode]) {
        return ((_b = (_a = STATE_OPTIONS[countryCode]) === null || _a === void 0 ? void 0 : _a.find(({ value }) => value === state)) === null || _b === void 0 ? void 0 : _b.label) || state;
    }
    return state;
};
