import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  border-radius: 16px;
  border: 1px solid ${Colors.GS500};
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 4px 5px 4px 12px;
`;
