import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
`;
export const Content = styled.div `
  flex: 1;
  margin-right: 16px;
`;
export const Title = styled.div `
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
export const Url = styled.div `
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  word-break: break-word;
  margin-bottom: 8px;
`;
