import { ApiBase } from '../ApiBase';
class DropsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getPresales = (body) => {
            return this.get(`/presales`, body);
        };
        this.createPresalesCampaign = (body) => {
            return this.post('/presales', body);
        };
        this.updatePresalesCampaignTimeFrame = (dropId, body) => {
            return this.put(`/presales/${dropId}`, body);
        };
        this.getPresalesCampaignByOfferId = (offerId) => {
            return this.get(`/presales/offer/${offerId}`);
        };
        this.updatePresalesOptionalMoq = ({ path, query, }) => {
            return this.put(`/presales/${path.dropId}/optional-moq`, {}, Object.assign({}, query));
        };
    }
}
export const DropsApiClient = new DropsApiClientModel();
