const getAbandonedCheckoutEmailFrequency = (type) => {
    if (type === 'LIGHT') {
        return 'light';
    }
    if (type === 'RECOMMENDED') {
        return 'recommended';
    }
    return 'off';
};
export const getInitialValues = (data) => {
    return {
        abandonedCheckoutEmailFrequency: getAbandonedCheckoutEmailFrequency(data?.mailPreferences?.cartAbandoned?.type),
    };
};
