import React from 'react';
import { BaseContent, ModalBox, Code, useModalContext } from '@fourthwall/components';
export const CustomerSupportModal = ({ settingsUrl, contactEmail, website, messaging, }) => {
    const { close } = useModalContext();
    return (React.createElement(ModalBox, { title: "Set up customer support", onClose: close },
        React.createElement(BaseContent, null,
            React.createElement("ol", null,
                React.createElement("li", null,
                    "Open",
                    ' ',
                    React.createElement("a", { href: settingsUrl, target: "_blank", rel: "noreferrer" }, "Meta Commerce Manager Settings")),
                React.createElement("li", null,
                    "Fill in the following fields:",
                    React.createElement("dl", { style: { marginTop: 8 } },
                        React.createElement("div", null,
                            React.createElement("dt", null, "Shop contact email:"),
                            React.createElement("dd", null,
                                React.createElement(Code, null, contactEmail))),
                        React.createElement("div", null,
                            React.createElement("dt", null, "Website:"),
                            React.createElement("dd", null,
                                React.createElement(Code, null, website))),
                        React.createElement("div", null,
                            React.createElement("dt", null, "Messaging:"),
                            React.createElement("dd", null,
                                React.createElement(Code, null, messaging ? 'On' : 'Off'))))),
                React.createElement("li", null, "From now on, all customer support emails will be redirected to Fourthwall")))));
};
