import { useModalContext } from '@fourthwall/components';
import { useEffect, useMemo, useState } from 'react';
import { useOffersByIdsQuery } from '@hooks/useOffersByIdsQuery';
import { SelectProducts } from '@modules/Dashboard/SelectProducts';
import { mapOffersDataToSelectedProducts } from '../utils';
export const useGiftableProducts = ({ onProductsIdsChange, modalTitle = 'Select products', } = {}) => {
    const { open } = useModalContext();
    const [productsIds, setProductsIds] = useState([]);
    const { offersByIdsQuery } = useOffersByIdsQuery([productsIds], {
        enabled: !!productsIds.length,
    });
    const products = useMemo(() => mapOffersDataToSelectedProducts(offersByIdsQuery.data), [offersByIdsQuery.data]);
    useEffect(() => {
        onProductsIdsChange && onProductsIdsChange(productsIds);
    }, [productsIds]);
    const removeProductId = (id) => {
        const ids = productsIds.filter((productId) => productId !== id);
        setProductsIds(ids);
    };
    const openSelectProductsModal = () => open(SelectProducts, {
        title: modalTitle,
        selectedProductsIds: productsIds,
        submitButtonLabel: 'Confirm',
        onSave: (offersIds) => setProductsIds(offersIds),
    });
    return {
        products,
        productsIds,
        isLoading: offersByIdsQuery.isLoading,
        data: Object.values(offersByIdsQuery.data || []),
        setProductsIds,
        onAddProduct: openSelectProductsModal,
        onRemoveProduct: removeProductId,
    };
};
