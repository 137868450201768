import { Button, Input, ModalBox, Toasts } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { useCurrentShopContext } from '../../hooks/useCurrentShopContext';
import { useActiveThemeContext } from '../../providers/ActiveThemeProvider';
import { useUpdateSectionMutation } from '../LayoutSection/hooks/useUpdateSectionMutation';
import { validationSchema } from './consts';
export const UpdateSectionTitleModal = ({ id, title, onSuccess, close }) => {
    const { updateSectionMutation } = useUpdateSectionMutation();
    const { currentShop } = useCurrentShopContext();
    const { activeTheme } = useActiveThemeContext();
    const formik = useFormik({
        initialValues: {
            title,
        },
        validationSchema,
        onSubmit: (values) => {
            updateSectionMutation.mutate([
                {
                    shop_id: currentShop.id,
                    theme_id: activeTheme.id,
                    section_id: id,
                },
                { name: values.title },
            ], {
                onSuccess: () => {
                    Toasts.notify('Section title updated', { type: 'info' });
                    onSuccess();
                    close();
                },
            });
        },
    });
    const handleClose = () => {
        if (!updateSectionMutation.isLoading) {
            close();
        }
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(ModalBox, { size: "small", title: "Update section title", buttons: [
                React.createElement(Button, { label: "Cancel", type: "button", appearance: "secondary", disabled: updateSectionMutation.isLoading, onClick: handleClose }),
                React.createElement(Button, { label: "Update", appearance: "primary", loading: updateSectionMutation.isLoading, disabled: !formik.dirty, type: "submit" }),
            ], onClose: handleClose },
            React.createElement(Input, { label: "Title", ...formik.getFieldProps('title') }))));
};
