import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
export const DefaultContentContainerBackground = styled.div `
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.GS600};
  opacity: 0.1;
`;
export const DefaultContentContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px dashed ${({ invalid }) => (invalid ? Colors.R300 : Colors.GS600)};
  text-align: center;
  cursor: pointer;
  position: relative;

  ${({ size }) => size === 'medium' &&
    css `
      padding: 10px 15px;
    `};

  ${({ size }) => size === 'large' &&
    css `
      padding: 15px 30px;
    `};

  ${({ disabled }) => !disabled &&
    css `
      &:hover {
        ${DefaultContentContainerBackground} {
          display: block;
        }
      }
    `}

  ${({ accepted }) => accepted &&
    css `
      background-color: ${Colors.B100};
      border-color: ${Colors.B500};
    `};

  ${({ rejected }) => rejected &&
    css `
      background-color: ${Colors.R100};
      border-color: ${Colors.R300};
    `};

  ${({ disabled }) => disabled &&
    css `
      cursor: not-allowed;
      border-color: ${Colors.GS600};
      background-color: ${Colors.GS200};
    `};
`;
export const ContentContainer = styled.div `
  width: 100%;
  height: 100%;
  outline: none;

  &:focus {
    ${DefaultContentContainer} {
      border-color: ${Colors.GS600};
    }
  }
`;
export const LoaderContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const IconContainer = styled.div `
  margin: 0 16px;

  ${({ orientation }) => orientation === 'vertical' &&
    css `
      margin-bottom: 8px;
    `};
`;
export const Headline = styled.div `
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ size }) => size === 'medium' &&
    css `
      font-size: 14px;
      line-height: 16px;
    `};

  ${({ size }) => size === 'large' &&
    css `
      line-height: 24px;
    `};

  ${({ orientation }) => orientation === 'vertical' &&
    css `
      flex-direction: column;
    `};

  ${({ orientation }) => orientation === 'horizontal' &&
    css `
      flex-direction: row;
    `};
`;
