import styled, { css } from 'styled-components';
export const Container = styled.div `
  line-height: 24px;

  & + & {
    margin-top: 40px;
  }
`;
export const Title = styled.div `
  &::after {
    content: '';
    display: block;
    height: 1px;
    margin-top: 8px;
    margin-bottom: 16px;
    background: #e3e2e1;

    ${({ noMargin }) => noMargin &&
    css `
        margin-bottom: 0;
      `};
  }

  ${({ borderless }) => borderless &&
    css `
      margin-bottom: 17px;

      &::after {
        content: none;
      }
    `};
`;
export const HeadingContainer = styled.div `
  display: flex;
  align-items: center;
`;
export const Heading = styled.div `
  flex: 1;
`;
export const RightContent = styled.div `
  margin-left: 16px;
`;
