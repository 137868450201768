export const getUpdateSingleSeriesPayload = (values) => {
    const getCoverImage = () => {
        if (!values.coverImage) {
            return {
                type: 'Remove',
            };
        }
        if (values.coverImage.startsWith('http')) {
            return {
                type: 'Keep',
            };
        }
        return {
            type: 'New',
            data: values.coverImage,
        };
    };
    return {
        title: values.title,
        description: values.description,
        coverImage: getCoverImage(),
    };
};
