import { formatMoneyValue, formatNumberValue, formatTopProductsByUnitsSoldCsv, getDateFormatter, } from '@fourthwall/module-analytics';
import camelCase from 'lodash-es/camelCase';
import mapKeys from 'lodash-es/mapKeys';
export const title = 'Analytics';
const renameCsvColumns = (tableData) => {
    const newKeys = {
        averageProfit: 'Average Profit',
        averageSales: 'Average Sales Value',
        cancellations: 'Cancels',
        contribution: 'Total',
        contributionDonations: 'Donations',
        contributionGiftsOnCreatorCost: 'Giveaway Links',
        contributionGiveaway: 'Twitch Gifts',
        contributionOrders: 'Shop Orders',
        contributionSubscriptions: 'Memberships',
        cost: 'Costs',
    };
    return tableData.map((item) => {
        // @ts-ignore
        return mapKeys(item, (value, key) => (key in newKeys ? camelCase(newKeys[key]) : key));
    });
};
export const REPORT_TYPES = {
    profit: {
        slug: 'profit',
        title: 'Profit',
        precision: true,
        analyticsChartQuery: 'profit',
        chartValueQuery: undefined,
        endpoint: 'chart',
        getDateFormatter,
        formatValue: formatMoneyValue,
        formatCsv: undefined,
    },
    'memberships-total-members': {
        slug: 'memberships-total-members',
        title: 'Total members',
        precision: true,
        analyticsChartQuery: 'totalMembers',
        chartValueQuery: undefined,
        endpoint: 'membership-chart',
        getDateFormatter,
        formatValue: formatNumberValue,
        formatCsv: undefined,
    },
    'memberships-total-free-members': {
        slug: 'memberships-total-free-members',
        title: 'Free accounts',
        tooltip: 'Includes free account signups and former members',
        precision: true,
        analyticsChartQuery: 'totalFreeAccounts',
        chartValueQuery: undefined,
        endpoint: 'membership-chart',
        getDateFormatter,
        formatValue: formatNumberValue,
        formatCsv: undefined,
    },
    'memberships-members-change': {
        slug: 'memberships-members-change',
        title: 'Members change',
        precision: true,
        analyticsChartQuery: 'membersChange',
        chartValueQuery: undefined,
        endpoint: 'membership-chart',
        getDateFormatter,
        formatValue: formatNumberValue,
        formatCsv: undefined,
    },
    'memberships-free-members-change': {
        slug: 'memberships-free-members-change',
        title: 'Free accounts change',
        precision: true,
        analyticsChartQuery: 'freeAccountsChange',
        chartValueQuery: undefined,
        endpoint: 'membership-chart',
        getDateFormatter,
        formatValue: formatNumberValue,
        formatCsv: undefined,
    },
    orders: {
        slug: 'orders',
        title: 'Orders',
        precision: true,
        analyticsChartQuery: 'contribution',
        chartValueQuery: undefined,
        endpoint: 'chart',
        getDateFormatter,
        formatValue: formatNumberValue,
        formatCsv: renameCsvColumns,
    },
    'average-order-value': {
        slug: 'average-order-value',
        title: 'Average order value',
        precision: true,
        analyticsChartQuery: 'contributionAvg',
        chartValueQuery: 'averageGrossSalesValue',
        endpoint: 'chart',
        getDateFormatter,
        formatValue: formatMoneyValue,
        formatCsv: renameCsvColumns,
    },
    'top-products-by-units-sold': {
        slug: 'top-products-by-units-sold',
        title: 'Top products by units sold',
        precision: false,
        analyticsChartQuery: undefined,
        chartValueQuery: undefined,
        endpoint: 'top-offer',
        getDateFormatter,
        formatValue: undefined,
        formatCsv: formatTopProductsByUnitsSoldCsv,
    },
    'sales-by-country': {
        slug: 'sales-by-country',
        title: 'Sales by country',
        precision: false,
        analyticsChartQuery: undefined,
        chartValueQuery: undefined,
        endpoint: 'top-sale-by-country',
        getDateFormatter,
        formatValue: undefined,
        formatCsv: undefined,
    },
    'membership-top-tiers': {
        slug: 'membership-top-tiers',
        title: 'Top tiers by members',
        precision: false,
        analyticsChartQuery: undefined,
        chartValueQuery: undefined,
        endpoint: 'membership-top-tiers',
        getDateFormatter,
        formatValue: undefined,
        formatCsv: undefined,
    },
};
