import styled, { css } from 'styled-components';
import { mediaQueries } from '../../../providers/MediaQueryProvider';
export const Container = styled.div(({ flex, alignRight, alignCenter }) => css `
    flex-grow: 1;
    flex: ${flex};

    & + & {
      margin-top: 16px;

      ${mediaQueries.tablet} {
        margin-top: 0;
        width: auto;
        margin-left: 16px;
      }
    }

    ${alignRight &&
    css `
      display: flex;
      justify-content: flex-end;
    `};

    ${alignCenter &&
    css `
      display: flex;
      align-items: center;
    `};
  `);
export const Footer = styled.div `
  margin-top: 2px;
`;
