import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Container as DashboardMenuContainer } from '../styled';
export const Container = styled.li `
  & > ${DashboardMenuContainer} {
    margin-top: 12px;
    margin-left: 20px;
  }
`;
export const Wrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  & > :not(:first-child) {
    margin-left: 8px;
  }
`;
export const Link = styled(NavLink) `
  color: ${Colors.GS1000};

  ${({ active }) => !active &&
    css `
      &:hover {
        color: ${Colors.B500};
      }
    `};

  ${({ active }) => active &&
    css `
      ${Label}::before {
        opacity: 1;
      }

      color: ${Colors.GS1000};
    `};
`;
export const Label = styled.div `
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0; // This is required for "ellipsis" to work properly in a "flex" element
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;

  ${mediaQueries.tablet} {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: ${Colors.GS1000};
    opacity: 0;
  }
`;
