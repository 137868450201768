import beehiivThumbnail from './beehiiv.svg';
import discordThumbnail from './discord.svg';
import fallbackThumbnail from './fallback.svg';
import instagramThumbnail from './instagram.svg';
import klaviyoThumbnail from './klaviyo.svg';
import layloThumbnail from './laylo.svg';
import metaThumbnail from './meta.svg';
import shipstationThumbnail from './shipstation.svg';
import streamelementsThumbnail from './streamelements.svg';
import streamlabsThumbnail from './streamlabs.svg';
import tiktokThumbnail from './tiktok.svg';
import twitchGiftingThumbnail from './twitchGifting.svg';
import twitchMembershipsThumbnail from './twitchMemberships.svg';
import twitterShopsThumbnail from './twitter.svg';
import youtubeThumbnail from './youtube.svg';
export const thumbnails = {
    beehiivThumbnail,
    discordThumbnail,
    instagramThumbnail,
    klaviyoThumbnail,
    layloThumbnail,
    metaThumbnail,
    shipstationThumbnail,
    streamelementsThumbnail,
    streamlabsThumbnail,
    tiktokThumbnail,
    twitchGiftingThumbnail,
    twitchMembershipsThumbnail,
    twitterShopsThumbnail,
    youtubeThumbnail,
    fallbackThumbnail,
};
