import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const CropperContainer = styled.div `
  position: relative;
  height: 408px;
  margin-bottom: 16px;
  border: 1px solid ${Colors.GS500};
`;
export const Footer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ButtonContainer = styled.div `
  margin-left: auto;
`;
