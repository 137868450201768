import { TextBlockEllipsis } from '@fourthwall/components';
import React from 'react';
import { inserterOptions } from '../../../../consts/inserterOptions';
import * as S from './Message.styled';
import { extractContent } from './utils/extractContent';
export const Message = ({ subject, body }) => {
    if (!subject || !body) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Subject, null,
            React.createElement(TextBlockEllipsis, { maxLine: 1, text: subject })),
        React.createElement(S.Body, null,
            React.createElement(TextBlockEllipsis, { maxLine: 1, text: extractContent(body, inserterOptions) }))));
};
