import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { mediaQueries } from '../../providers/MediaQueryProvider';
export const Container = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${Colors.GS0};
  width: 100%;

  &:after {
    display: none;
    content: '';
    position: absolute;
    border-width: 7px;
    border-style: solid;
    left: 11px;

    ${({ pickerPosition }) => pickerPosition === 'bottom-start' &&
    css `
        top: -14px;
        border-color: transparent transparent ${Colors.GS0};
      `}

    ${({ pickerPosition }) => pickerPosition === 'top-start' &&
    css `
        bottom: -14px;
        border-color: ${Colors.GS0} transparent transparent;
      `}
  }

  &:before {
    display: none;
    content: '';
    position: absolute;
    border-width: 8px;
    border-style: solid;
    left: 10px;

    ${({ pickerPosition }) => pickerPosition === 'bottom-start' &&
    css `
        top: -16px;
        border-color: transparent transparent ${Colors.GS500};
      `}

    ${({ pickerPosition }) => pickerPosition === 'top-start' &&
    css `
        bottom: -16px;
        border-color: ${Colors.GS500} transparent transparent;
      `}
  }

  ${mediaQueries.tablet} {
    width: 318px;
    border: 1px solid ${Colors.GS500};

    &:after,
    &:before {
      display: inline;
    }
  }
`;
export const ColorsContainer = styled.div `
  display: flex;
  margin-bottom: 16px;
`;
export const Saturation = styled.div `
  position: relative;
  overflow: hidden;
  flex: 1 0 auto;
  margin-right: 16px;
`;
export const Hue = styled.div `
  position: relative;
  width: 24px;
  height: 130px;
`;
export const Alpha = styled.div `
  position: relative;
  width: 24px;
  height: 130px;
  margin-left: 16px;
`;
export const Modifiers = styled.div `
  display: flex;
`;
export const Selected = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ rgb }) => `rgba(${rgb?.r},${rgb?.g},${rgb?.b},${rgb?.a})`};
`;
export const SelectedContainer = styled.div `
  position: relative;
  height: 24px;
  margin-bottom: 16px;
`;
export const CustomPointer = styled.div `
  position: relative;
  left: ${({ alpha }) => (alpha ? -7 : -4)}px;
  top: -4px;
  width: 30px;
  height: 8px;
  border: 1px solid ${Colors.GS500};
  background: ${Colors.GS0};
`;
export const CustomCircle = styled.div `
  position: relative;
  top: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  border: 2px solid ${Colors.GS0};
  border-radius: 50%;

  cursor: pointer;
`;
export const ButtonContainer = styled.div `
  width: 100%;

  &:first-child {
    margin-right: 16px;
  }

  &:last-child {
    margin-right: 0;
  }
`;
export const Buttons = styled.div `
  display: flex;
`;
export const Input = styled.div `
  width: 50%;

  &:first-child {
    margin-right: 16px;
  }
`;
export const Label = styled.div `
  position: absolute;
  top: 10px;
  left: 16px;
  z-index: 1;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.GS600};
  line-height: 16px;
`;
export const Inputs = styled.div `
  display: flex;

  ${({ withMargin }) => withMargin &&
    css `
      margin-top: 16px;
    `};
`;
