import React from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from './const';
export const ComponentsThemeProvider = ({ theme, automatedTheme, mode = 'opacity', children, }) => {
    return (React.createElement(ThemeProvider, { theme: {
            mode,
            ...{ ...lightTheme, ...theme },
            automated: automatedTheme,
        } }, children));
};
