import styled from 'styled-components';
export const PaymentMethodsSectionText = styled.div `
  margin-bottom: 16px;

  a {
    text-decoration: underline;
    white-space: nowrap;
  }
`;
export const PaymentMethod = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const SpendingLimitContainer = styled.div `
  margin-top: 16px;
`;
export const TabsContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TableWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const BannerContainer = styled.div `
  margin-bottom: 12px;
`;
