import reduce from 'lodash-es/reduce';
export const getTierIds = (formTiers) => {
    return reduce(formTiers, (sum, enabled, id) => {
        if (enabled) {
            sum.push({
                id: parseInt(id),
            });
        }
        return sum;
    }, []);
};
