import { Select } from '@fourthwall/components';
import { Field } from 'formik';
import React from 'react';
import { useBespokeProductsQuery } from '../hooks/useBespokeProductsQuery';
export const ProductBespokeProductId = ({ bespokeProductId }) => {
    const { bespokeProducts } = useBespokeProductsQuery();
    const options = bespokeProducts?.map((product) => ({
        label: `${product.name} (${product.id})`,
        value: product.id,
    }));
    return (React.createElement("div", { style: { marginTop: 8 } }, bespokeProducts && (React.createElement(Field, { component: Select, name: "bespokeProductId", label: "Bespoke Product", options: options, value: bespokeProductId }))));
};
