import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const ProductsContainer = styled.div `
  border-top: 1px solid ${Colors.GS400};
`;
export const VariantsContainer = styled.div `
  margin-top: 8px;
  margin-left: 49px;
  margin-bottom: -4px;
  border-top: 1px solid ${Colors.GS400};
`;
export const VariantName = styled.div `
  font-size: 14px;
  line-height: 150%;
`;
