import { Buffer } from 'buffer';
import { generateFileChecksum } from './generateFileChecksum';
export const getImageDirectUploadData = async (image) => {
    const generateChecksum = async () => {
        const test = await generateFileChecksum(image);
        return Buffer.from(test, 'hex').toString('base64');
    };
    return {
        byteSize: image.size,
        checksum: await generateChecksum(),
    };
};
