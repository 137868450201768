import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useCurrentShopContext } from '../hooks/useCurrentShopContext';
import { usePageLayoutQuery } from '../hooks/usePageLayoutQuery';
import { usePages } from '../hooks/usePages';
import { useActiveThemeContext } from './ActiveThemeProvider';
export const PageLayoutContext = createContext(undefined);
export const PageLayoutProvider = ({ children }) => {
    const { currentShop } = useCurrentShopContext();
    const { activeTheme } = useActiveThemeContext();
    const { currentPage } = usePages();
    const isQueryEnabled = !!currentPage?.id &&
        currentPage.id !== 'checkout' &&
        currentPage.id !== 'memberships_perks' &&
        currentPage.id !== 'memberships_feed' &&
        currentPage.id !== 'memberships_video_catalog' &&
        currentPage.id !== 'checkout';
    const { pageLayoutQuery } = usePageLayoutQuery([currentShop.id, activeTheme.id, currentPage?.id], {
        enabled: isQueryEnabled,
    });
    const getSectionBySectionKey = useCallback((key) => {
        return pageLayoutQuery.data?.sections.blocks
            .flatMap((block) => block.sections)
            .find(({ section_key }) => section_key === key);
    }, [pageLayoutQuery.data?.sections.blocks]);
    const value = useMemo(() => ({
        pageLayoutQuery,
        getSectionBySectionKey,
    }), [getSectionBySectionKey, pageLayoutQuery]);
    return React.createElement(PageLayoutContext.Provider, { value: value }, children);
};
export const usePageLayoutContext = () => {
    const pageLayoutContext = useContext(PageLayoutContext);
    if (pageLayoutContext === undefined) {
        throw new Error('pageLayoutContext must be used within a PageLayoutProvider');
    }
    return pageLayoutContext;
};
