import React from 'react';
import { StatusTag } from '../../../../../../components/common/StatusTag';
import { Tooltip } from '../../../../../../components/common/Tooltip';
import { StatusTagTimeline } from '../modules/StatusTagTimeline';
export const TableStatusTag = ({ orderId, status }) => {
    if (!orderId) {
        return React.createElement(StatusTag, { status: status });
    }
    return (React.createElement(Tooltip, { placement: "bottom-start", size: "medium", content: React.createElement(StatusTagTimeline, { orderId: orderId }) },
        React.createElement(StatusTag, { status: status, hoverable: true })));
};
