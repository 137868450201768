import { getFulfillmentType } from '@modules/Dashboard/Product/store/product/utils';
export const getShippingViewModel = ({ values, featureEnabled, }) => {
    const fulfillmentType = getFulfillmentType(values.fulfillmentService);
    const manufacturingService = values.manufacturingService;
    let view = 'NOT_CONFIGURABLE';
    if (fulfillmentType === 'DIGITAL') {
        view = 'NO_SHIPPING';
    }
    if (fulfillmentType === 'SHIP_MONK' && manufacturingService === 'MANUFACTURED_BY_CREATOR') {
        view = featureEnabled ? 'SHIPPING_3PL' : 'SHIPPING_3PL_LEGACY';
    }
    if (fulfillmentType === 'FULFILLED_BY_CREATOR' &&
        manufacturingService === 'MANUFACTURED_BY_CREATOR') {
        // NOTE: for now, we only support weight-based self shipping we will support FLAT_SELF_SHIPPING in the future
        view = 'WEIGHT_BASED_SELF_SHIPPING';
    }
    if (manufacturingService === 'FOURTHWALL_BESPOKE') {
        view = 'BESPOKE_SHIPPING';
    }
    return { view };
};
