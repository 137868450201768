import { isProduction } from '@fourthwall/utils/lib/dev';
import React from 'react';
import * as S from './InProgress.styled';
export const InProgress = ({ inline, disabled, children }) => {
    if (isProduction() && !disabled) {
        return null;
    }
    return (React.createElement(S.Container, { inline: inline },
        children,
        React.createElement(S.Note, null, "in progress")));
};
