import { Toasts } from '@fourthwall/components';
import { useState } from 'react';
import { useCheckMediaCreatedMutation } from '../../../../../hooks/useCheckMediaCreatedMutation';
import { useImageConfirmMutation } from '../../../../../hooks/useImageConfirmMutation';
import { useUploadImageMutation } from '../../../../../hooks/useUploadImageMutation';
import { uploadMedia } from '../../../../../utils/uploadMedia';
import { getCreateWelcomePostPayload } from '../utils/getCreateWelcomePostPayload';
import { useCreateWelcomePostsMutation } from './useCreateWelcomePostsMutation';
import { useUpdateWelcomePostsMutation } from './useUpdateWelcomePostsMutation';
export const useSingleWelcomePostsCreate = () => {
    const [isLoading, setLoading] = useState(false);
    const [mediaUploadProgress, setMediaUploadProgress] = useState({ single: 0 });
    const { createWelcomePostsMutation } = useCreateWelcomePostsMutation();
    const { updateWelcomePostsMutation } = useUpdateWelcomePostsMutation();
    const { uploadImageMutation } = useUploadImageMutation();
    const { imageConfirmMutation } = useImageConfirmMutation();
    const { checkMediaCreatedMutation } = useCheckMediaCreatedMutation();
    const createSingleWelcomePosts = async (values, update, handleSuccess) => {
        setLoading(true);
        const mutation = update ? updateWelcomePostsMutation : createWelcomePostsMutation;
        const payload = await getCreateWelcomePostPayload(values.welcomePost);
        const { uploadData } = await mutation.mutateAsync(payload, {
            onSuccess: ({ uploadData }) => {
                // Update only text fields
                if (!('imageDirectUploadData' in uploadData[0]) &&
                    !('videoDirectUploadData' in uploadData[0])) {
                    handleSuccess();
                    setLoading(false);
                }
            },
            onError: () => setLoading(false),
        });
        // Update image field
        if ('imageDirectUploadData' in uploadData[0] && uploadData[0].imageDirectUploadData) {
            await uploadImageMutation.mutateAsync({
                file: values.welcomePost.postType === 'Image'
                    ? values.welcomePost.image
                    : values.welcomePost.videoThumbnail,
                ...uploadData[0].imageDirectUploadData,
            }, {
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: single welcome post upload image error');
                },
            });
            await imageConfirmMutation.mutateAsync({ id: uploadData[0].postId, signedId: uploadData[0].imageDirectUploadData.signedId }, {
                onSuccess: () => {
                    // Update only image field
                    if (!('videoDirectUploadData' in uploadData[0])) {
                        handleSuccess();
                        setLoading(false);
                    }
                },
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: single welcome post confirm image error');
                },
            });
        }
        // Upload video field
        if ('videoDirectUploadData' in uploadData[0] && uploadData[0].videoDirectUploadData) {
            await uploadMedia(uploadData[0].videoDirectUploadData.uploadUrl, values.welcomePost.video, (progress) => {
                setMediaUploadProgress((prev) => ({ ...prev, single: Number(progress.toFixed()) }));
            }).catch(() => {
                setLoading(false);
                Toasts.notify('Media upload error. Please try again.', { type: 'error' });
            });
            await checkMediaCreatedMutation.mutate(uploadData[0].postId, {
                onSuccess: () => {
                    setMediaUploadProgress((prev) => ({ ...prev, single: 0 }));
                    setLoading(false);
                    handleSuccess();
                },
                onError: () => setLoading(false),
            });
        }
    };
    return {
        createSingleWelcomePosts,
        mediaUploadProgress,
        isLoading,
    };
};
