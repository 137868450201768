import { ApiBase } from './ApiBase';
class DashboardApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getCurrentShopDashboard = () => {
            return this.get('/shops/current/dashboard');
        };
    }
}
export const DashboardApiClient = new DashboardApiClientModel();
