import styled, { css } from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const RemoveButton = styled.button.attrs({ type: 'button' }) `
  padding: 4px;

  ${mediaQueries.laptop} {
    visibility: hidden;
  }
`;
export const Container = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 12px;

  ${mediaQueries.laptop} {
    &:hover {
      ${RemoveButton} {
        visibility: visible;
      }
    }
  }
`;
export const Content = styled.div `
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;

  ${mediaQueries.tablet} {
    gap: 16px;
  }
`;
export const RemoveButtonWrapper = styled.div `
  ${mediaQueries.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ inside }) => !inside &&
    css `
        position: absolute;
        top: 0;
        height: 100%;
        left: 100%;
      `}

    &:hover {
      ${RemoveButton} {
        visibility: visible;
      }
    }
  }
`;
