import { Modal } from '@fourthwall/components';
import { captureException, initSentry } from '@fourthwall/sentry';
import { AnalyticsApiClient } from '@fourthwall/services/lib/AnalyticsApiClient';
import { BespokeProductsApiClient } from '@fourthwall/services/lib/BespokeProductsApiClient';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { DashboardApiClient } from '@fourthwall/services/lib/DashboardApiClient';
import { DashboardV2ApiClient } from '@fourthwall/services/lib/DashboardV2ApiClient';
import { DropsApiClient } from '@fourthwall/services/lib/DropsApiClient';
import { GiftsApiClient } from '@fourthwall/services/lib/GiftsApiClient';
import { GiveawayConfigApiClient } from '@fourthwall/services/lib/GiveawayConfigApiClient';
import { GiveawayContributionApiClient } from '@fourthwall/services/lib/GiveawayContributionApiClient';
import { HasuraApiClient } from '@fourthwall/services/lib/HasuraApiClient';
import { KudosApiClient } from '@fourthwall/services/lib/KudosApiClient';
import { MembershipsApiClient } from '@fourthwall/services/lib/MembershipsApiClient';
import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { MembershipsCreatorV2ApiClient } from '@fourthwall/services/lib/MembershipsCreatorV2ApiClient';
import { NotificationShopPreferencesApiClient } from '@fourthwall/services/lib/NotificationShopPreferencesApiClient';
import { SamplesBudgetApiClient } from '@fourthwall/services/lib/SamplesBudgetApiClient';
import { ShippingApiClient } from '@fourthwall/services/lib/ShippingApiClient';
import { ShopUsersApiClient } from '@fourthwall/services/lib/ShopUsersApiClient';
import { ShopsApiClient } from '@fourthwall/services/lib/ShopsApiClient';
import { errorLoggerInterceptor, isDeployed } from '@fourthwall/utils/lib/dev';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Root } from './Root';
initSentry({ appName: 'creator-dashboard' });
if (isDeployed()) {
    const interceptor = errorLoggerInterceptor(captureException);
    AnalyticsApiClient.addErrorResponseInterceptor(interceptor);
    BespokeProductsApiClient.addErrorResponseInterceptor(interceptor);
    CreatorIntegrationsApiClient.addErrorResponseInterceptor(interceptor);
    DashboardApiClient.addErrorResponseInterceptor(interceptor);
    DashboardV2ApiClient.addErrorResponseInterceptor(interceptor);
    DropsApiClient.addErrorResponseInterceptor(interceptor);
    GiftsApiClient.addErrorResponseInterceptor(interceptor);
    GiveawayConfigApiClient.addErrorResponseInterceptor(interceptor);
    GiveawayContributionApiClient.addErrorResponseInterceptor(interceptor);
    HasuraApiClient.addErrorResponseInterceptor(interceptor);
    KudosApiClient.addErrorResponseInterceptor(interceptor);
    MembershipsApiClient.addErrorResponseInterceptor(interceptor);
    MembershipsCreatorApiClient.addErrorResponseInterceptor(interceptor);
    MembershipsCreatorV2ApiClient.addErrorResponseInterceptor(interceptor);
    NotificationShopPreferencesApiClient.addErrorResponseInterceptor(interceptor);
    SamplesBudgetApiClient.addErrorResponseInterceptor(interceptor);
    ShippingApiClient.addErrorResponseInterceptor(interceptor);
    ShopUsersApiClient.addErrorResponseInterceptor(interceptor);
    ShopsApiClient.addErrorResponseInterceptor(interceptor);
}
const rootElement = document.getElementById('root');
if (rootElement) {
    Modal.setAppElement(rootElement);
}
const root = createRoot(document.getElementById('root'));
root.render(React.createElement(Root, null));
