import { Icon } from '@fourthwall/components';
import { TickFilledIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './PostTypeButtons.styled';
export const PostTypeButtons = ({ buttons, activeButton, disabled, onClick }) => {
    const handleClick = (button) => () => {
        if (!button.disabled && button.type !== activeButton) {
            onClick(button.type);
        }
    };
    return (React.createElement(S.Container, null, buttons.map((button, index) => (React.createElement(S.Button, { key: index, active: activeButton === button.type, disabled: button.disabled || disabled, onClick: handleClick(button) },
        activeButton === button.type && (React.createElement(S.IconContainer, null,
            React.createElement(Icon, { component: TickFilledIcon, height: 16 }))),
        React.createElement(Icon, { component: button.icon, height: 20 }),
        button.label)))));
};
