import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  padding: 20px;
  background-color: ${Colors.GS200};
`;
export const OptionsContainer = styled.div `
  display: grid;
  gap: 16px;
`;
export const Divider = styled.div `
  margin: 20px 0;
  height: 1px;
  background-color: ${Colors.GS400};
`;
export const LengthInputsContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;

  ${mediaQueries.laptop} {
    flex-direction: row;
  }
`;
export const BoostingContainer = styled.div `
  display: flex;
  gap: 6px;
  align-items: baseline;
`;
