import { useAuth } from '@fourthwall/auth';
import { Button, ButtonGroup, ErrorPage, SuspenseFallback } from '@fourthwall/components';
import { isProduction } from '@fourthwall/utils/lib/dev';
import React from 'react';
import { MY_SHOP_PRODUCTION_URL, MY_SHOP_STAGING_URL } from '../../../../consts/myShop';
import { useCurrentShop } from '../../../Dashboard/hooks/useCurrentShop';
export const ForbiddenShopGuard = ({ children }) => {
    const { logout } = useAuth();
    const { currentShop, isUnauthorized } = useCurrentShop();
    const getUrl = () => {
        return isProduction() ? MY_SHOP_PRODUCTION_URL : MY_SHOP_STAGING_URL;
    };
    if (isUnauthorized) {
        return (React.createElement(ErrorPage, { heading: "You're currently logged in on another Fourthwall site", description: "Log out to access the admin for this site.", actions: React.createElement(ButtonGroup, { space: "medium" },
                React.createElement(Button, { label: "Log out", onClick: () => logout() }),
                React.createElement(Button, { label: "Return to current site", href: getUrl() })) }));
    }
    if (!currentShop) {
        return React.createElement(SuspenseFallback, null);
    }
    return children;
};
