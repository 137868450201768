import { Colors, getAutomatedStyleProp } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  margin-top: 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.GS400};
  border-radius: 4px;
  padding: 12px;
  margin: 12px 0;
  width: 100%;
  height: 80px;
  background-color: ${getAutomatedStyleProp('colorBackground')};
  font-family: ${getAutomatedStyleProp('fontFamilyBase')};
`;
export const Close = styled.div `
  margin-right: 12px;
  color: ${getAutomatedStyleProp('colorOnBackground')};
`;
export const Icon = styled.img `
  border: 1px solid ${getAutomatedStyleProp('paleBorder')};
  border-radius: 8px;
  margin-right: 12px;
  width: 56px;
  height: 56px;
`;
export const Details = styled.div `
  flex: 1;
  margin-right: 12px;
  overflow: hidden;
`;
export const Title = styled.div `
  margin-bottom: 2px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 700;
  color: ${getAutomatedStyleProp('colorOnBackground')};
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const Subtitle = styled.div `
  font-size: 12px;
  line-height: 1.2;
  color: ${getAutomatedStyleProp('paleTextColor')};
`;
export const Button = styled.div `
  padding: 8px 12px;
  color: ${getAutomatedStyleProp('colorOnPrimary')};
  background-color: ${getAutomatedStyleProp('colorPrimary')};
  font-weight: 700;
  border-radius: ${getAutomatedStyleProp('buttonCornerRadius')};
  font-size: 14px;
  line-height: 1.5;
`;
