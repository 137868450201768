import { Dropdown } from '@fourthwall/components';
import React from 'react';
import { routing } from '@utils/routing';
import { CreateNewSiteDropdownItem } from './components/CreateNewSiteDropdownItem';
import { ShopItem } from './components/ShopItem';
import * as S from './ShopSwitch.styled';
export const ShopSwitch = ({ connectedShops }) => (React.createElement(React.Fragment, null,
    React.createElement(S.Container, null,
        connectedShops?.map(({ id, internalBaseUri, baseUri, logoUri, name, primaryDomain }) => (React.createElement(ShopItem, { key: id, adminLink: `${internalBaseUri}${routing.home}`, shopLink: baseUri, label: name, labelDescription: primaryDomain, logoUrl: logoUri }))),
        React.createElement(CreateNewSiteDropdownItem, null)),
    React.createElement(Dropdown.Divider, null)));
