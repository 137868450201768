import { Colors, mediaQueries } from '@fourthwall/components';
import { AppDownloadAppStoreIcon, AppDownloadGooglePlayIcon } from '@fourthwall/icons';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  position: relative;
  display: flex;
  overflow: hidden;

  ${mediaQueries.tablet} {
    height: 480px;
  }
`;
export const Left = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 20px;

  ${mediaQueries.tablet} {
    align-items: center;
    padding: 24px 32px;
  }

  ${mediaQueries.laptop} {
    align-items: flex-start;
  }
`;
export const Right = styled.div `
  display: none;
  position: relative;
  width: 60%;
  padding: 24px 32px 0 23px;

  ${mediaQueries.laptop} {
    display: block;
  }
`;
export const Title = styled.div `
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;

  ${mediaQueries.tablet} {
    margin-top: 24px;
  }

  ${mediaQueries.laptop} {
    margin-top: 0;
  }
`;
export const Content = styled.div `
  margin-top: 16px;
  margin-bottom: 12px;
  line-height: 24px;
  text-align: left;
  max-width: 430px;

  ${mediaQueries.tablet} {
    margin: 32px 0;
    text-align: center;
    max-width: 70%;
  }

  ${mediaQueries.laptop} {
    text-align: left;
    max-width: none;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;
export const Footer = styled.div `
  margin-top: 64px;
  color: ${Colors.GS600};
  align-self: flex-start;
  font-size: 14px;

  ${mediaQueries.tablet} {
    margin-top: auto;
    align-self: inherit;
    text-align: center;
  }

  ${mediaQueries.laptop} {
    align-self: flex-start;
    text-align: left;
    font-size: inherit;
  }
`;
export const AppStoreButton = styled.img `
  width: 140px;
`;
export const PhoneImage = styled.img `
  width: 262px;
`;
export const DownloadButtons = styled.div `
  display: flex;
  flex-flow: row wrap;

  ${mediaQueries.laptop} {
    flex-flow: column nowrap;
  }
`;
export const DownloadLink = styled.a `
  margin-right: 16px;
  margin-top: 12px;

  &:last-of-type {
    margin-right: 0;
  }

  ${mediaQueries.laptop} {
    margin-top: 0;
  }

  ${mediaQueries.laptop} {
    margin-right: 0;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
const iconsStyles = css `
  height: 48px;
`;
export const AppDownloadAppStore = styled(AppDownloadAppStoreIcon) `
  ${iconsStyles};
  width: 143.5px;
`;
export const AppDownloadGooglePlay = styled(AppDownloadGooglePlayIcon) `
  ${iconsStyles};
  width: 163px;
`;
