import { Button, ModalBox, Option, ProductsList } from '@fourthwall/components';
import isNil from 'lodash-es/isNil';
import React from 'react';
import * as S from './styled';
export const OrderVariantsModalView = ({ currentVariant, variants, hasVariants, disabled, onSelectedVariantChange, onConfirm, close, }) => {
    const renderVariants = () => {
        if (!hasVariants) {
            return (React.createElement(S.EmptyVariantsNotice, null, "This offer doesn't have set up variants that can use as a replacement."));
        }
        return (React.createElement(S.VariantsContainer, null, variants?.map((variant) => (React.createElement(S.VariantContainer, { key: variant.id },
            React.createElement(Option, { checked: variant.selected, disabled: variant.disabled, label: React.createElement(S.OptionLabel, { disabled: Boolean(variant.disabled) },
                    React.createElement("div", null, variant.description),
                    React.createElement("div", null, isNil(variant.quantity) ? 'Unlimited' : `${variant.quantity} in stock`)), onChange: () => onSelectedVariantChange(variant) }))))));
    };
    return (React.createElement(ModalBox, { title: "Edit variant", buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close }),
            React.createElement(Button, { label: "Update", appearance: "primary", disabled: disabled, onClick: onConfirm }),
        ], onClose: close },
        React.createElement(S.ProductSummaryContainer, null,
            React.createElement(ProductsList, null,
                React.createElement(ProductsList.Item, { name: currentVariant.name, description: currentVariant.description, image: currentVariant.image }))),
        renderVariants()));
};
