import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { useMutation } from '@tanstack/react-query';
export const useTwitchTokenMutation = () => {
    const twitchTokenMutation = useMutation({
        mutationFn: async (params) => {
            const { data } = await CreatorIntegrationsApiClient.getTwitchToken(params);
            return data;
        },
        onError: undefined,
    });
    return { twitchTokenMutation };
};
