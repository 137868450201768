import React from 'react';
import { Field, useFormikContext } from 'formik';
import { ButtonClean, Checkbox, Fieldset } from '@fourthwall/components';
import { InnerField } from '@components/common/InnerField';
import { getShippingGroupLink } from './utils/getShippingGroupLink';
import * as S from './styled';
import { ShippingGroupEmptyState } from '../../components/ShippingGroupEmptyState';
import { ShippingGroupTileContainer } from '../../components/ShippingGroupTile';
export const ShippingGroupsYouShipView = ({ shippingGroups, onEdit, onDelete, }) => {
    const { values } = useFormikContext();
    const renderShippingGroups = () => {
        if (!shippingGroups.length) {
            return React.createElement(ShippingGroupEmptyState, { to: getShippingGroupLink() });
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(S.ScrollContainer, { maxHeight: 600 },
                React.createElement(S.ShippingGroups, null, shippingGroups.map((shippingGroup, index) => {
                    const name = `Shipping group ${index + 1}`;
                    return (React.createElement(ShippingGroupTileContainer, { key: shippingGroup.id || name, name: name, offerIds: shippingGroup.offerIds, countries: shippingGroup.countries, onEdit: () => onEdit(shippingGroup.id), onDelete: () => onDelete(shippingGroup.id, name) }));
                }))),
            React.createElement(ButtonClean, { label: "Create a shipping group", to: getShippingGroupLink() })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Fieldset.Row, null,
            React.createElement("div", null,
                React.createElement(Field, { component: Checkbox, name: "withShippingGroupsYouShip", label: "Restrict the countries where I ship my products" }))),
        values.withShippingGroupsYouShip && (React.createElement(InnerField, { fluid: true },
            React.createElement(S.ShippingGroups, null, renderShippingGroups())))));
};
