import { Loading, Upload, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import * as S from './FileUploader.styled';
import { Incentive } from './Incentive/Incentive';
const FileUploaderComp = ({ isLoading, handleFileDrop, handleDropRejection, }) => {
    const media = useMediaQueryContext();
    return (React.createElement(Loading, { isLoading: isLoading },
        React.createElement(S.Section, null,
            React.createElement(S.UploadWrapper, null,
                React.createElement(Upload, { accept: {
                        'audio/*': [],
                        'video/*': [],
                    }, title: media.tablet ? 'Drop file or browse' : 'Browse', onFileDrop: handleFileDrop, onFileDropRejected: handleDropRejection })),
            React.createElement(S.IncentiveWrapper, null,
                React.createElement(Incentive, null)))));
};
export const FileUploader = React.memo(FileUploaderComp);
