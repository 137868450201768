import { ShopRendererApiClient } from '@fourthwall/services/lib/ShopRendererApiClient';
import { useMutation } from '@tanstack/react-query';
export const useCreateInstagramIntegrationMutation = (options) => {
    const createInstagramIntegrationMutation = useMutation({
        mutationFn: async (params) => {
            const { data } = await ShopRendererApiClient.createInstagramIntegration(...params);
            return data;
        },
        ...options,
    });
    return {
        createInstagramIntegrationMutation,
    };
};
