import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { SECTION_NAMES } from '@constants/sectionNames';
import { BaseContent, HeadContent, Subsection, Tag } from '@fourthwall/components';
import { mapStatusToTagProps } from '@modules/Dashboard/Apps/utils';
import { routing } from '@utils/routing';
import React from 'react';
import * as S from './styled';
export const AppsTwitterShopsView = ({ status, productsLink }) => {
    const renderStatusTag = () => {
        const props = mapStatusToTagProps(status);
        if (!props)
            return null;
        return (React.createElement("div", { style: { marginBottom: 20 } },
            React.createElement(Tag, { ...props, size: "large", display: "inline-flex" })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: SECTION_NAMES.appTwitterShops }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: SECTION_NAMES.appTwitterShops, topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }) },
                renderStatusTag(),
                React.createElement("div", { style: { marginBottom: 48 } },
                    React.createElement(BaseContent, null, "Fourthwall creators can now show off their products on their X (formerly Twitter) profiles! It takes just a few minutes to set up.")),
                React.createElement(Subsection, { title: "Set up instructions" },
                    React.createElement(BaseContent, null,
                        React.createElement(S.List, null,
                            React.createElement("li", null,
                                "Go to",
                                ' ',
                                React.createElement("a", { target: "_blank", href: "https://ads.twitter.com/shopping_manager", rel: "noreferrer" }, "https://ads.twitter.com/shopping_manager")),
                            React.createElement("li", null, "Enter \u201CFourthwall Products\u201D for your catalog name and press \u201CSave\u201D"),
                            React.createElement("li", null, "Click \u201CAdd products\u201D"),
                            React.createElement("li", null, "Click \u201CScheduled feed"),
                            React.createElement("li", null, "Select \u201CXML\u201D as the Format type"),
                            React.createElement("li", null,
                                "For URL, enter: ",
                                React.createElement("br", null),
                                React.createElement("code", null, productsLink)),
                            React.createElement("li", null, "Leave username and password blank"),
                            React.createElement("li", null, "Set the frequency to \u201CHourly\u201D and repeat to \u201C4 hours\u201D. Twitter will now update your products once every four hours (if you edit the title/price/photos)."),
                            React.createElement("li", null, "Press \u201CCreate\u201D and you\u2019ve now imported all your products!"),
                            React.createElement("li", null, "Final step: Go to \u201CAdd products\u201D and choose the products you\u2019d like to show up on your Twitter profile"))))))));
};
