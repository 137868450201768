import { ShopsApiClient } from '@fourthwall/services/lib/ShopsApiClient';
import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
import { createQueryHook } from '@fourthwall/utils';
export const useConnectedShopsQuery = createQueryHook(ShopsApiClient.getConnectedShops, {
    queryKey: ['connectedShops'],
    select: (data) => {
        data.shops = data.shops.map((shop) => ({
            ...shop,
            name: decodeHtmlEntities(shop.name),
            creatorName: decodeHtmlEntities(shop.creatorName),
            description: decodeHtmlEntities(shop.description),
        }));
        return data;
    },
});
