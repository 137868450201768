import { CurrencyInput } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { FixedRatesTable } from '../../components/FixedRatesTable';
import * as S from './styled';
export const FixedShippingView = ({ domesticRatesSubtitle }) => {
    const formik = useFormikContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Description, null, "Charge a fixed price for shipping based on the number of items in the cart."),
        React.createElement(FixedRatesTable, null,
            React.createElement(FixedRatesTable.Column, { title: "Domestic rates", subtitle: domesticRatesSubtitle, inputs: [
                    React.createElement(CurrencyInput, { label: "First item", ...formik.getFieldProps('domesticFirst') }),
                    React.createElement(CurrencyInput, { label: "Each additional item", ...formik.getFieldProps('domesticAdditional') }),
                ] }),
            React.createElement(FixedRatesTable.Column, { title: "International rates", subtitle: "Rest of the world", inputs: [
                    React.createElement(CurrencyInput, { label: "First item", ...formik.getFieldProps('internationalFirst') }),
                    React.createElement(CurrencyInput, { label: "Each additional item", ...formik.getFieldProps('internationalAdditional') }),
                ] }))));
};
