import { Usage } from '../../types';
export const getUsage = (welcomePostsQuery) => {
    switch (welcomePostsQuery.data?.type) {
        case 'Single':
            return Usage.same;
        case 'Different':
            return Usage.different;
        case 'Archived':
        default:
            return Usage.none;
    }
};
