import { ModalBox, useMediaQueryContext } from '@fourthwall/components';
import React, { useEffect, useRef, useState } from 'react';
import * as S from './styled';
export const GalleryModal = ({ images, selectedImageId, close }) => {
    const [selectedImage, setSelectedImage] = useState(selectedImageId);
    const media = useMediaQueryContext();
    const initialSelectedImageIndex = images.findIndex(({ id }) => id === selectedImageId);
    const showSlider = !media.tablet;
    const sliderRef = useRef(null);
    useEffect(() => {
        if (showSlider)
            if (sliderRef.current) {
                const scrollAmount = initialSelectedImageIndex * sliderRef.current.clientWidth;
                sliderRef.current.scrollTo({
                    left: scrollAmount,
                    behavior: 'instant',
                });
            }
    }, [initialSelectedImageIndex, showSlider]);
    if (showSlider) {
        return (React.createElement(ModalBox, { size: "fullScreen", withoutPadding: true, onClose: close, disableScrolling: true },
            React.createElement(S.SliderContainer, null,
                React.createElement(S.Slider, { ref: sliderRef }, images.map((image) => (React.createElement(S.Slide, { key: image.id },
                    React.createElement(S.ImagesListItemImage, { src: image.url, "data-testid": "GalleryModal.ImagesListItem" }))))))));
    }
    return (React.createElement(ModalBox, { size: "fullScreen", disableScrolling: true, onClose: close },
        React.createElement(S.Container, null,
            React.createElement(S.SelectedImageContainer, null,
                React.createElement(S.SelectedImage, { src: images.find(({ id }) => id === selectedImage)?.url, "data-testid": "GalleryModal.SelectedImage" })),
            React.createElement(S.ImagesList, null, images.map((image) => (React.createElement(S.ImagesListItem, { key: image.id, active: image.id === selectedImage, onClick: () => setSelectedImage(image.id), "data-testid": "GalleryModal.ImagesListItem" },
                React.createElement(S.ImagesListItemImage, { src: image.url }))))))));
};
