const currencyLanguageMap = {
    USD: 'en-US',
    EUR: 'en-US',
    CAD: 'en-CA',
    GBP: 'en-GB',
    AUD: 'en-AU',
    NZD: 'en-NZ',
    SEK: 'sv-SE',
    NOK: 'nb-NO',
    DKK: 'da-DK',
    PLN: 'pl-PL',
    INR: 'hi-IN',
    JPY: 'ja-JP',
};
export const getPrice = (value, options) => {
    let displayedValue = value;
    if ((options === null || options === void 0 ? void 0 : options.negative) && value > 0) {
        displayedValue *= -1;
    }
    const settings = Object.assign({ style: 'currency', currency: 'USD', minimumFractionDigits: Number.isInteger(displayedValue) ? 0 : 2 }, options);
    // NOTE: Make sure settings.currency exists.
    return displayedValue.toLocaleString(currencyLanguageMap[settings.currency] || 'en-US', settings);
};
export const getCurrencySymbol = (currency = 'USD') => {
    const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };
    return (0)
        .toLocaleString(currencyLanguageMap[currency] || 'en-US', options)
        .replace(/\d/g, '')
        .trim();
};
export const getCurrencyPrefix = (currency) => {
    const currencySymbol = getCurrencySymbol(currency);
    if (['$', '£', 'kr', 'kr.', '¥'].includes(currencySymbol)) {
        return currency.toUpperCase();
    }
    return undefined;
};
export const getPriceRange = (fromValue, toValue) => {
    return `${getPrice(fromValue)} – ${getPrice(toValue)}`;
};
export const loadStyleUrl = (href) => {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.href = href;
        link.rel = 'stylesheet';
        link.onload = resolve;
        link.onerror = reject;
        document.head.append(link);
    });
};
export const loadScriptUrl = (url) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        document.head.append(script);
    });
};
