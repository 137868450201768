import { validateYupSchema, yupToFormErrors } from 'formik';
import Yup from '@fourthwall/utils/lib/yup';
export const validate = (values) => (schemas) => {
    const combinedSchema = Object.assign({}, ...Object.values(schemas));
    // NOTE: https://github.com/jaredpalmer/formik/issues/506
    try {
        validateYupSchema(values, Yup.object().shape(combinedSchema), true, {
            values,
        });
    }
    catch (err) {
        return yupToFormErrors(err);
    }
    return {};
};
