import { useShopGroupStatsQuery } from '../useShopGroupStatsQuery';
export const useAvailableRoles = () => {
    const { shopGroupStatsQuery, invalidateShopGroupStatsQuery } = useShopGroupStatsQuery();
    const availableRoles = shopGroupStatsQuery.data
        ?.map(({ group: { id, name, roles: permissions, description }, userCount }) => ({
        id,
        membersCount: userCount,
        name,
        description,
        permissions,
    }))
        .sort((roleA, roleB) => roleB.permissions.length - roleA.permissions.length);
    return {
        availableRoles,
        isLoading: shopGroupStatsQuery.isLoading,
        invalidateAvailableRoles: invalidateShopGroupStatsQuery,
    };
};
