import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const TitleContainer = styled.div `
  margin-bottom: 12px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
export const Title = styled.div `
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS1000};
`;
export const SkipButton = styled.button `
  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GS600};

  &:hover {
    color: ${Colors.GS1000};
  }
`;
export const Progress = styled.div `
  height: 10px;
  background: ${Colors.GS300};
  border-radius: 100px;
  overflow: hidden;
`;
export const ProgressFill = styled.div `
  height: 100%;
  background: ${Colors.G300};
`;
