import { Button, ButtonGroup, Colorway, Skeleton, Tag, useMediaQueryContext, useOutsideClick, } from '@fourthwall/components';
import React, { useRef, useState } from 'react';
import * as S from './styled';
export const ThemeCard = ({ theme, primaryButtonProps, secondaryButtonProps, colorwayProps, }) => {
    const media = useMediaQueryContext();
    const [showActionButtons, setShowActionButton] = useState(false);
    const imagesContainerRef = useRef(null);
    const selectedPreset = theme.presets.find((preset) => preset.isSelected);
    useOutsideClick(imagesContainerRef, () => {
        !media.laptop && setShowActionButton(false);
    });
    const handleImagesContainerClick = () => {
        setShowActionButton(true);
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.ImagesContainer, { ref: imagesContainerRef, role: !media.laptop ? 'button' : undefined, onClick: handleImagesContainerClick },
            React.createElement(S.DesktopImageWrapper, null,
                React.createElement(S.Loader, null,
                    React.createElement(Skeleton.Shape, { height: "100%" })),
                React.createElement(S.Image, { src: selectedPreset?.desktopImage })),
            React.createElement(S.MobileImageWrapper, null,
                React.createElement(S.Loader, null,
                    React.createElement(Skeleton.Shape, { height: "100%" })),
                React.createElement(S.Image, { src: selectedPreset?.mobileImage })),
            (primaryButtonProps || secondaryButtonProps) && (React.createElement(S.SelectContainer, { display: showActionButtons },
                React.createElement(ButtonGroup, { direction: "column", space: "medium" },
                    primaryButtonProps && (React.createElement(Button, { size: media.laptop ? 'medium' : 'small', appearance: "primary", fullWidth: true, ...primaryButtonProps })),
                    secondaryButtonProps && (React.createElement(Button, { size: media.laptop ? 'medium' : 'small', fullWidth: true, ...secondaryButtonProps })))))),
        React.createElement(S.Footer, null,
            React.createElement(S.Details, null,
                React.createElement(S.Name, null, theme.name),
                colorwayProps && React.createElement(Colorway, { ...colorwayProps })),
            React.createElement(S.Tags, null, theme.isCurrent && React.createElement(Tag, { label: "Current theme", appearance: "dark", size: "small" })))));
};
