import React, { useEffect, useState } from 'react';
import { ArrowShortLeftIcon, ArrowShortRightIcon } from '@fourthwall/icons';
import { Input } from '../../inputs/Input';
import { Icon } from '../Icon';
import * as S from './Pagination.styled';
import { validatePageNumber } from './utils';
export const Pagination = ({ pageCount, currentPage, onChange }) => {
    const [inputValue, setInputValue] = useState(`${currentPage}`);
    const parsedInputValue = parseInt(inputValue);
    const handleSetInputValue = (value) => {
        if (value && /^[0-9]*$/.test(value)) {
            return setInputValue(value);
        }
        return setInputValue('');
    };
    const handleInputChange = (e) => {
        handleSetInputValue(e.target.value);
    };
    useEffect(() => {
        handleSetInputValue(`${currentPage}`);
    }, [currentPage]);
    const submitInputChange = () => {
        if (parsedInputValue !== currentPage && validatePageNumber(parsedInputValue, pageCount)) {
            if (onChange) {
                onChange(parsedInputValue);
            }
        }
        else {
            setInputValue(`${currentPage}`);
        }
    };
    const handleInputBlur = () => {
        submitInputChange();
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        submitInputChange();
    };
    const handleIconClick = (nextPage) => () => {
        if (validatePageNumber(nextPage, pageCount)) {
            if (onChange) {
                onChange(nextPage);
            }
        }
    };
    return (React.createElement(S.Pagination, { "data-testid": "Pagination" },
        React.createElement(S.IconContainer, { left: true, disabled: currentPage === 1, onClick: handleIconClick(currentPage - 1), "data-testid": "Pagination.LeftIcon" },
            React.createElement(Icon, { component: ArrowShortLeftIcon, height: 16 })),
        React.createElement(S.InputForm, { onSubmit: handleFormSubmit },
            React.createElement(Input, { value: `${inputValue}`, size: "small", centered: true, onChange: handleInputChange, onBlur: handleInputBlur, "data-testid": "current.page" })),
        React.createElement("span", { "data-testid": "Pagination.totalPages" }, `of ${pageCount}`),
        React.createElement(S.IconContainer, { right: true, disabled: currentPage === pageCount, onClick: handleIconClick(currentPage + 1), "data-testid": "Pagination.RightIcon" },
            React.createElement(Icon, { component: ArrowShortRightIcon, height: 16 }))));
};
