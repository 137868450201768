import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
  height: 56px;
  display: flex;
  align-items: center;
`;
export const Content = styled.div `
  display: flex;
  padding: 24px 16px 0;
  font-size: 16px;
  line-height: 150%;
`;
