import { Checkbox, Fieldset, Input, RadioButton, Subsection } from '@fourthwall/components';
import { Field } from 'formik';
import React from 'react';
import * as S from './styled';
export const SoundScan = ({ isSoundScanProduct, disabled = false }) => {
    const renderSoundScanInputs = () => {
        if (!isSoundScanProduct)
            return null;
        return (React.createElement(S.Container, null,
            React.createElement(Fieldset.Row, { inner: true },
                React.createElement(S.RadioGroupLabel, null, "Product format")),
            React.createElement(Fieldset.Row, { inner: true },
                React.createElement(Field, { component: RadioButton, name: "format", label: "Single", checkedValue: "SINGLE", disabled: disabled })),
            React.createElement(Fieldset.Row, { inner: true },
                React.createElement(Field, { component: RadioButton, name: "format", label: "Album", checkedValue: "ALBUM", disabled: disabled })),
            React.createElement(Fieldset.Row, { inner: true },
                React.createElement(S.InputWrapper, null,
                    React.createElement(Field, { component: Input, name: "soundScanCode", label: "SoundScan code (EAN/UPC)", disabled: disabled })))));
    };
    return (React.createElement(Subsection, { title: "SoundScan" },
        React.createElement(Fieldset, null,
            React.createElement(Field, { component: Checkbox, name: "isSoundScanProduct", label: "This is a SoundScan product", disabled: disabled }),
            renderSoundScanInputs())));
};
