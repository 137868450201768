import * as yup from 'yup';
import { FormFields } from './types';
export const validationSchema = yup.object().shape({
    [FormFields.annualEnabled]: yup.boolean(),
    [FormFields.anualDiscount]: yup
        .number()
        .when([FormFields.annualEnabled], (annualEnabled) => {
        if (annualEnabled) {
            return yup.number().max(25).required();
        }
        return yup.number();
    }),
    [FormFields.contentVisibility]: yup.string().required(),
    [FormFields.membersCountEnabled]: yup.boolean().required(),
    [FormFields.adultContent]: yup.boolean().required(),
});
