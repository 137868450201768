import isNil from 'lodash-es/isNil';
import { usePagination } from '@fourthwall/components';
import { useOffersQuery } from '@queries/useOffersQuery';
import { useDashboardPermissions } from '../../../../../components/utils/DashboardPermissions';
import { useSearchQuery } from '../../../hooks/useSearchQuery';
import { useFilterProducts } from '../useFilterProducts';
import { useOrdersAnalyticsQuery } from '../useOrdersAnalyticsQuery';
import { getIdsFromOffers, getProductsStatus, getProductsWithAnalytics } from './utils';
import { PER_PAGE } from '../../const';
export const useProducts = () => {
    const { hasPermission } = useDashboardPermissions();
    const [currentPage] = usePagination();
    const { filtersParams } = useFilterProducts();
    const { searchQuery } = useSearchQuery();
    const { offersQuery } = useOffersQuery([
        {
            q: searchQuery,
            page: currentPage - 1,
            size: PER_PAGE,
            status: getProductsStatus(filtersParams.status, !!filtersParams.archived),
            available: isNil(filtersParams?.availability) ? undefined : !!filtersParams?.availability,
        },
    ], {
        keepPreviousData: true,
    });
    const offers = offersQuery.data?.offers || [];
    const offersIds = offers.length ? getIdsFromOffers(offers) : [];
    const analyticsQueryEnabled = hasPermission('contributions.profit') && !!offers.length;
    const { offersAnalyticsQuery } = useOrdersAnalyticsQuery([{ ids: offersIds }], {
        enabled: analyticsQueryEnabled,
    });
    const analyticsItems = offersAnalyticsQuery.data?.items || {};
    const products = getProductsWithAnalytics(offers, analyticsItems);
    return {
        products,
        page: offersQuery.data?.page,
        isLoading: offersQuery.isFetching || (analyticsQueryEnabled && offersAnalyticsQuery.isLoading),
        refetchProducts: offersQuery.refetch,
    };
};
