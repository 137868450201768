import { Avatar, Dropdown, Icon } from '@fourthwall/components';
import { ArrowShortRightIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
export const DashboardProfileDropdown = ({ variant = 'avatar', label, name, children, avatarSize = 'medium', testid, isOpen, setIsOpen, backgroundColor, }) => {
    const component = {
        'menu-item': (React.createElement(S.MenuItem, { onClick: () => setIsOpen((state) => !state) },
            React.createElement(S.MenuItemContent, null,
                React.createElement(S.MenuItemAvatarContainer, null,
                    React.createElement(S.AvatarWrapper, null,
                        React.createElement(Avatar, { name: name, size: avatarSize }),
                        label && (React.createElement(S.AvatarLabel, { borderColor: backgroundColor, size: avatarSize }, label)))),
                React.createElement(S.MenuItemName, null, name)),
            React.createElement(Icon, { component: ArrowShortRightIcon, height: 12 }))),
        avatar: (React.createElement(S.AvatarWrapper, null,
            React.createElement(Avatar, { name: name, size: avatarSize, hoverable: true, active: isOpen, onClick: () => setIsOpen((state) => !state) }),
            label && (React.createElement(S.AvatarLabel, { borderColor: backgroundColor, size: avatarSize }, label)))),
    }[variant];
    const dropdownProps = {
        avatar: {
            position: 'bottom-end',
        },
        'menu-item': {
            position: 'right-end',
        },
    }[variant];
    return (React.createElement(Dropdown, { content: children, maxWidth: 300, testid: testid, isOpen: isOpen, onClose: () => setIsOpen(false), ...dropdownProps }, component));
};
