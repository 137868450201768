import { Toasts } from '@fourthwall/components';
import axios from 'axios';
export const handleCalculateRefundsError = (error) => {
    if (axios.isAxiosError(error) &&
        error.response?.data.reason?.code === 'TOTAL_AMOUNT_TO_HIGH_AFTER_CANCELLATION') {
        return Toasts.notify('These items cannot be canceled as it would make a promotion used in placing the order invalid', { type: 'error' });
    }
    if (axios.isAxiosError(error) && error.response?.data.title) {
        return Toasts.notify(error.response?.data.title, { type: 'error' });
    }
    return Toasts.notify('Something went wrong', { type: 'error' });
};
