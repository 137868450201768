import { MY_SHOP_PRODUCTION_HOST, MY_SHOP_STAGING_HOST } from '../../../consts/myShop';
import { useFeatureFlagsQuery } from './useFeatureFlagsQuery';
export const usePlatformFeatureFlags = () => {
    const { featureFlagsQuery } = useFeatureFlagsQuery(undefined, {
        enabled: ![MY_SHOP_PRODUCTION_HOST, MY_SHOP_STAGING_HOST].includes(window.location.host),
    });
    const hasFeatureFlag = (flag) => {
        return !!featureFlagsQuery.data?.features.includes(flag);
    };
    return {
        featureFlagsQuery,
        hasFeatureFlag,
    };
};
