import { closestCenter, DndContext, DragOverlay, PointerSensor, useSensor, useSensors, } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import React, { useState } from 'react';
export const SortableGrid = ({ items, onSort, draggedElement, children }) => {
    const [activeItem, setActiveItem] = useState(null);
    const sensors = useSensors(useSensor(PointerSensor, {
        activationConstraint: {
            distance: 5,
        },
    }));
    const handleDragStart = ({ active }) => {
        const item = items.find((itemId) => itemId == active.id);
        if (item) {
            setActiveItem(item);
        }
    };
    const handleDragEnd = ({ active, over }) => {
        setActiveItem(null);
        const newIndex = items.findIndex((itemId) => itemId === active.id);
        const oldIndex = items.findIndex((itemId) => itemId === over?.id);
        if (newIndex === oldIndex)
            return;
        onSort(newIndex, oldIndex);
    };
    const handleDragCancel = () => {
        setActiveItem(null);
    };
    return (React.createElement(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragStart: handleDragStart, onDragEnd: handleDragEnd, onDragCancel: handleDragCancel },
        React.createElement(SortableContext, { items: items, strategy: rectSortingStrategy }, children),
        activeItem && React.createElement(DragOverlay, null, draggedElement(activeItem))));
};
