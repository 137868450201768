export const PRODUCTION_METHODS = {
    ALL_OVER_PRINT: {
        label: 'All over printed',
        title: 'All over printed',
        tooltip: 'Uses sublimation technique to print vibrant, full-color artwork on the whole area of a product. Can be more expensive as each item is individually cut and sewn to order. Requires a polyester blend fabric.',
    },
    DTG: {
        label: 'DTG',
        title: 'Direct to garment',
        tooltip: 'Produces soft prints that feel like part of the fabric, but can struggle with printing ultra bright colors on dark fabric colors. Prints can also fade slightly over time.',
        advantages: [
            'Prints meld with fabric and can’t be felt but are a little less vibrant than DTFx and fade slightly over time',
            'Prints don’t impact the fabrics breathability – works well with large solid prints ',
        ],
    },
    SCREEN_PRINTING: {
        label: 'Screen printed',
        title: 'Screen printed',
        tooltip: 'The classic apparel printing technique. Produces bright, vibrant, long-lasting prints but requires a minimum of 75+ units to be ordered and can only print 9 colors per side (no photos or gradients).',
    },
    EMBROIDERY: {
        label: 'Embroidered (stitched)',
        title: 'Embroidered (stitched)',
        tooltip: 'Technique for stitching artwork. Supports a maximum of five different thread colors.',
    },
    DTFX: {
        label: 'DTFx',
        title: 'Direct to film',
        tooltip: 'Produces bright, vibrant, long-lasting prints (similar to screen printing, but without minimums or color limitations). The downside is that prints are not breathable, so avoid large solid graphics on thin t-shirts.',
        advantages: [
            'Longest-lasting prints with ultra vibrant colors',
            'Print sits on top of the fabric – works best with heavy fabrics',
            'Prints are not breathable and have a plastic-y feel – works best with art that isn’t a big solid block',
        ],
    },
    PRINTED: {
        label: 'Digital printed',
        title: 'Digital printed',
        tooltip: 'High-quality printing (300dpi) used for art prints and other printed paper products. Same quality as you’d find in a magazine or art book.',
    },
    GARMENT_PRINTED: {
        label: 'Printed',
        title: 'Printed on demand',
        tooltip: 'We’ll automatically choose the best production method for your artwork.',
    },
    SUBLIMATION: {
        label: 'Sublimation',
        title: 'Sublimation',
        tooltip: "Vibrant, full-color printing technique that allows you to print on various surfaces of a product that other methods don't allow.",
    },
    STICKER: {
        label: 'Sticker',
        title: 'Sticker',
    },
    UV: {
        label: 'UV printed',
        title: 'UV printed',
    },
    KNITTING: {
        label: 'Knitted',
        title: 'Knitted',
    },
    DRINKWARE: {
        label: 'Drinkware',
        title: 'Drinkware',
    },
    OTHER: {
        label: 'Other',
        title: 'Other',
    },
};
