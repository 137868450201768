import { Toasts, useModalContext } from '@fourthwall/components';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DomainConfigurationView } from './DomainConfigurationView';
import { useSendEntriesViaEmailMutation } from './hooks/useSendEntriesViaEmailMutation';
import { useVerifyDomainMutation } from '../../hooks/useVerifyDomainMutation';
import { useDnsConfiguration } from './hooks/useDnsConfiguration';
import { routing } from '../../../../../utils/routing';
export const DomainConfigurationContainer = () => {
    const { domain, provider, mode, domainDnsRecords, warning, isLoading, isAutomaticSetupInProgress, invalidateDnsQuery, verifyProviderDetails, setUpAutomatically, } = useDnsConfiguration();
    const { sendEntriesViaEmailMutation } = useSendEntriesViaEmailMutation();
    const { verifyDomainMutation } = useVerifyDomainMutation();
    const { close } = useModalContext();
    const navigate = useNavigate();
    const onFocus = useCallback(() => {
        verifyDomainMutation.mutate([]);
        verifyProviderDetails?.();
    }, [verifyDomainMutation, verifyProviderDetails]);
    useEffect(() => {
        if (!isLoading) {
            window.addEventListener('focus', onFocus);
            return () => {
                window.removeEventListener('focus', onFocus);
            };
        }
    }, [isLoading, onFocus]);
    const handleSend = ({ email }) => {
        sendEntriesViaEmailMutation.mutate(email, {
            onSuccess: close,
        });
    };
    const handleDomainVerification = () => {
        verifyDomainMutation.mutate([], {
            onSuccess: (data) => {
                invalidateDnsQuery();
                if (data.dnsList.areAllEntriesComplete) {
                    navigate(routing.settings.domain.self);
                    Toasts.notify(`${data.dnsList.domain} was added and is currently syncing.`, {
                        type: 'info',
                    });
                }
            },
            onError: () => {
                Toasts.notify('Failed to verify domain', { type: 'error' });
            },
        });
    };
    return (React.createElement(DomainConfigurationView, { domain: domain, provider: provider, mode: mode, domainDnsRecords: domainDnsRecords, isLoading: isLoading, isVerificationInProgress: verifyDomainMutation.isLoading, isAutomaticSetupInProgress: isAutomaticSetupInProgress, warning: warning, onSend: handleSend, onDomainVerification: handleDomainVerification, onAutomaticSetup: setUpAutomatically }));
};
