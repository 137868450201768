import { ButtonClean, Input, InputUI, Select, Subsection } from '@fourthwall/components';
import { CloseIcon } from '@fourthwall/icons';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import times from 'lodash-es/times';
import React from 'react';
import { MAX_POLL_OPTIONS, MIN_POLL_OPTIONS } from '../../../../consts';
import { FormFields } from '../../types';
import * as S from './PollFields.styled';
export const PollFields = ({ disabled }) => {
    const { values, errors, setFieldValue } = useFormikContext();
    const pollOptions = values.pollOptions || [];
    const handlePollOptionAdd = () => {
        if (pollOptions.length >= MAX_POLL_OPTIONS) {
            return null;
        }
        setFieldValue(FormFields.pollOptions, [...pollOptions, '']);
    };
    const handlePollOptionRemove = (optionIndex) => {
        const newOptions = [...pollOptions];
        newOptions.splice(optionIndex, 1);
        setFieldValue(FormFields.pollOptions, [...newOptions]);
    };
    const renderAdditionalOptions = () => {
        return times(pollOptions.length - MIN_POLL_OPTIONS, (index) => {
            return (React.createElement(Field, { key: index, name: `pollOptions.${index + MIN_POLL_OPTIONS}`, component: Input, placeholder: `Option ${index + MIN_POLL_OPTIONS + 1}`, actionIcon: !disabled ? CloseIcon : undefined, onActionIconClick: () => handlePollOptionRemove(index + MIN_POLL_OPTIONS), disabled: disabled }));
        });
    };
    const renderOptions = () => {
        return times(MIN_POLL_OPTIONS, (index) => {
            return (React.createElement(Field, { key: index, name: `pollOptions.${index}`, component: Input, placeholder: `Option ${index + 1}`, disabled: disabled }));
        });
    };
    const renderAddButton = () => {
        if (pollOptions.length >= MAX_POLL_OPTIONS || disabled) {
            return null;
        }
        return React.createElement(ButtonClean, { label: "+ Add a poll option", withUnderline: true, onClick: handlePollOptionAdd });
    };
    return (React.createElement(Subsection, { title: "Poll options" },
        React.createElement(S.Container, null,
            React.createElement(S.OptionsContainer, null,
                renderOptions(),
                renderAdditionalOptions(),
                renderAddButton()),
            React.createElement(S.Divider, null),
            !disabled && (React.createElement(React.Fragment, null,
                React.createElement("p", null, "Poll length"),
                React.createElement(S.LengthInputsContainer, null,
                    React.createElement(Field, { component: Select, name: "pollLength.days", label: "Days", options: times(8, (index) => ({
                            label: `${index}`,
                            value: `${index}`,
                        })) }),
                    React.createElement(Field, { component: Select, name: "pollLength.hours", label: "Hours", options: times(24, (index) => ({
                            label: `${index}`,
                            value: `${index}`,
                        })) }),
                    React.createElement(Field, { component: Select, name: "pollLength.minutes", label: "Minutes", options: times(60, (index) => ({
                            label: `${index}`,
                            value: `${index}`,
                        })) })),
                errors.pollLength && React.createElement(InputUI.Error, null, errors.pollLength))))));
};
