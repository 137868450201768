import { Accordion, BaseContent, ModalBox, useAccordionState, useModalContext, } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import React from 'react';
import { reverse, routing } from '@utils/routing';
import { ProductsStatusModalAccordionHeading, } from './components/ProductsStatusModalAccordionHeading';
import * as S from './styled';
export const ProductsStatusModal = ({ title, products }) => {
    const { close } = useModalContext();
    const { activeState, toggle } = useAccordionState(products.map(() => false), { allowZeroActive: true });
    return (React.createElement(ModalBox, { title: title, onClose: close },
        React.createElement(S.ProductsContainer, null,
            React.createElement(Accordion, null, products.map((product, index) => {
                const hasVariants = !!product.variants.length;
                const getDescription = () => {
                    if (typeof product.description === 'string') {
                        return product.description;
                    }
                    if (hasVariants) {
                        return `${product.variants.length} ${pluralize(product.variants.length, 'variant')}`;
                    }
                };
                return (React.createElement(Accordion.Item, { key: product.id, heading: React.createElement(ProductsStatusModalAccordionHeading, { imageSrc: product.imageSrc, name: product.name, tag: product.tag, description: getDescription(), url: reverse(routing.products.all.product.self, { productId: product.id }), onClick: hasVariants ? () => toggle(index) : undefined }), spacing: "tiny", expanded: activeState[index], onChange: hasVariants ? () => toggle(index) : undefined }, hasVariants && (React.createElement(S.VariantsContainer, null,
                    React.createElement(Accordion, null, product.variants.map((variant) => (React.createElement(Accordion.Item, { heading: variant.name && React.createElement(S.VariantName, null, variant.name), spacing: "small", expanded: true }, variant.description && (React.createElement(BaseContent, { appearance: "secondary", size: "xsmall" }, variant.description))))))))));
            })))));
};
