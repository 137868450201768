import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  position: relative;
`;
export const PaymentMethodContainer = styled.div `
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 16px;
  border: 1px solid ${Colors.GS500};

  & + & {
    margin-top: -1px;
    border-top-color: transparent;
  }

  ${({ selectable }) => selectable &&
    css `
      cursor: pointer;
    `}

  ${({ invalid }) => invalid &&
    css `
      border: 1px solid ${Colors.R300};
      border-top-color: ${Colors.R300} !important;
    `}

  ${({ selected }) => selected &&
    css `
      padding: 11px 15px;
      border: 2px solid ${Colors.GS1000};
      border-top-color: ${Colors.GS1000} !important;
    `}
`;
export const SelectedMark = styled.div `
  position: absolute;
  top: 3px;
  left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${Colors.GS1000};
  color: ${Colors.GS0};
`;
export const IconContainer = styled.div `
  margin-right: 16px;
`;
export const TitleContainer = styled.div `
  flex: 1;
  position: relative;
  min-height: 24px;
  margin-right: 16px;
`;
export const Title = styled.div `
  font-size: 16px;
  line-height: 150%;

  ${({ withEllipsis }) => withEllipsis &&
    css `
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
export const Subtitle = styled.div `
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: ${Colors.GS600};
`;
export const TagsContainer = styled.div `
  display: flex;
`;
export const TagWrapper = styled.div `
  & + & {
    margin-left: 12px;
  }
`;
export const DropdownWrapper = styled.div `
  margin-left: 20px;
`;
