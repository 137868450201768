import React from 'react';
import { AddSectionModalView } from './AddSectionModalView';
import { useAvailableSections } from './hooks/useAvailableSections';
import { useSection } from './hooks/useSection';
import { useSectionConfigurations } from './hooks/useSectionConfigurations';
export const AddSectionModalContainer = ({ blockId, type, position }) => {
    const { sectionConfigurations } = useSectionConfigurations(type);
    const { sectionGroups } = useAvailableSections({
        sections: sectionConfigurations,
    });
    const { createLayoutSection } = useSection();
    const handleCreateLayoutSection = (sectionType, blockId) => {
        const defaultPosition = type === 'header' ? 1 : undefined;
        createLayoutSection(sectionType, blockId, position || defaultPosition);
    };
    return (React.createElement(AddSectionModalView, { blockId: blockId, sections: sectionGroups, onCreateLayoutSection: handleCreateLayoutSection }));
};
