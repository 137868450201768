import * as Yup from 'yup';
export const DURATION_MIN = 20;
export const DURATION_MAX = 180;
export const CHARGE_MIN = 0.01;
export const SettingsValidationSchema = Yup.object().shape({
    enabled: Yup.boolean(),
    duration: Yup.number().min(DURATION_MIN).max(DURATION_MAX),
    includedProductsIds: Yup.array().when('productSelectionType', (type, schema) => type === 'SELECTED_PRODUCTS'
        ? schema.min(1, 'At least one selected product is required.')
        : schema.optional()),
    shippingCharge: Yup.number().when('shippingChargeType', (type, schema) => type === 'MAX_CREATOR' ? schema.min(CHARGE_MIN).required() : schema.optional()),
});
