export const getThumbnail = (attachments) => {
    if (!attachments || attachments.length === 0) {
        return {
            type: 'MESSAGE',
        };
    }
    if (attachments[0].type === 'AUDIO') {
        return {
            type: 'AUDIO',
        };
    }
    if (attachments[0].type === 'VIDEO') {
        return {
            type: 'VIDEO',
            thumbnail: attachments[0].thumbnail ?? '',
        };
    }
    return {
        type: 'MESSAGE',
    };
};
