import asset1 from './getting-started-on-fourthwall.webp';
import asset2 from './how-to-get-your-first-sales.webp';
import asset3 from './10-tips-to-10x-your-sales.webp';
import asset4 from './ten-e-commerce-strategies-creators-can-use-to-build-their-brand.webp';
export const assets = {
    'getting-started-on-fourthwall': asset1,
    'how-to-get-your-first-sales': asset2,
    '10-tips-to-10x-your-sales': asset3,
    'ten-e-commerce-strategies-creators-can-use-to-build-their-brand': asset4,
};
