import { useAuth } from '@fourthwall/auth';
import { BaseContent, ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { getDefaultErrorMessage } from '@fourthwall/utils/lib/queryClient';
import { pluralize } from '@fourthwall/utils/lib/string';
import { useOffersTotal } from '@hooks';
import { useIsPaymentMethodModalRequiredMutation } from '@hooks/useIsPaymentMethodModalRequiredMutation';
import { useShopPaymentMethods } from '@modules/Dashboard/Apps/hooks/useShopPaymentMethods';
import { PaymentMethodRequiredModal } from '@modules/Dashboard/PaymentMethods';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { mapStatusToTagProps } from '../../utils';
import { DisconnectErrorModal } from './components/DisconnectErrorModal';
import { useDeleteMetaConfigurationMutation, useMetaCommerceMutation, useMetaStatusMutation, } from './mutations';
import { useMetaCommercesQuery, useMetaConfigurationQuery, useMetaProductsQuery } from './queries';
import { createPreviewProps, findPageByCmsId, getPermissionsDescription } from './utils';
export const useAppsMeta = () => {
    const { keycloak } = useAuth();
    const { open } = useModalContext();
    const offersTotal = useOffersTotal();
    const [cmsIdParam] = useQueryParam('cms_id', StringParam);
    const [connectedParam] = useQueryParam('connected', StringParam);
    const [connecting, setConnecting] = useState(false);
    const [merchantPageId, setMerchantPageId] = useState('');
    const { metaConfigurationQuery, invalidateMetaConfigurationQuery } = useMetaConfigurationQuery(undefined, {
        refetchInterval: (data) => {
            if (!data)
                return false;
            const { type } = data;
            // returned from meta but needs one more refetch to get the correct status
            // also, !!connectedParam does not work because it's an empty string when having `?connected=#_=_` in URL
            if (type === 'NOT_CONNECTED' && typeof connectedParam === 'string')
                return 1_000;
            // refetching while connecting or disconnecting to get the correct status
            if (type === 'CONNECTING_IN_PROGRESS' || type === 'DISCONNECTING_IN_PROGRESS')
                return 3_000;
            return false;
        },
        onSuccess: (data) => {
            if (data.type === 'NOT_CONFIGURED' || data.type === 'CONFIGURED') {
                metaCommercesQuery.refetch();
            }
        },
    });
    const { metaCommercesQuery } = useMetaCommercesQuery(undefined, {
        enabled: false, // see metaConfigurationQuery onSuccess callback
    });
    const { metaProductsQuery, invalidateMetaProductsQuery } = useMetaProductsQuery(undefined, {
        enabled: metaConfigurationQuery.isSuccess &&
            metaConfigurationQuery.data.type === 'CONFIGURED' &&
            metaConfigurationQuery.data.status === 'ACTIVE',
    });
    const invalidateQueries = () => {
        invalidateMetaConfigurationQuery();
        invalidateMetaProductsQuery();
    };
    const onSuccess = invalidateQueries;
    const { metaCommerceMutationMutation } = useMetaCommerceMutation({ onSuccess });
    const { metaStatusMutationMutation } = useMetaStatusMutation({ onSuccess });
    const { deleteMetaConfigurationMutation } = useDeleteMetaConfigurationMutation({
        onSuccess,
        onError: undefined,
    });
    const { isPaymentMethodModalRequiredMutation } = useIsPaymentMethodModalRequiredMutation();
    const { hasActivePaymentMethod } = useShopPaymentMethods();
    const initialMerchantPageId = (() => {
        if (!cmsIdParam)
            return '';
        const page = findPageByCmsId(cmsIdParam, metaCommercesQuery.data);
        return page ? page.id : '';
    })();
    useEffect(() => {
        if (!cmsIdParam)
            return;
        const page = findPageByCmsId(cmsIdParam, metaCommercesQuery.data);
        return page ? setMerchantPageId(page.id) : undefined;
    }, [cmsIdParam, metaCommercesQuery.data]);
    const statusTagProps = (() => {
        if (!metaConfigurationQuery.isSuccess)
            return;
        return mapStatusToTagProps(metaConfigurationQuery.data.type);
    })();
    const connectBarProps = (() => {
        if (!metaConfigurationQuery.isSuccess)
            return;
        const getOnConnect = (target) => () => {
            setConnecting(true);
            const url = CreatorIntegrationsApiClient.appAuthorizationUrl({
                app: 'instagram-partner',
                token: keycloak?.token,
            });
            window.open(url, target);
        };
        const onDisconnect = () => {
            open(ConfirmModal, {
                title: 'Are you sure you want to disconnect from Instagram & Facebook Shop?',
                confirmLabel: 'Yes, disconnect',
                confirmAppearance: 'destructive',
                onConfirm: async () => {
                    await deleteMetaConfigurationMutation.mutateAsync([], {
                        onError: (error) => {
                            if (axios.isAxiosError(error) &&
                                error.response?.data.code === 'META_PARTNER_ORDERS_NOT_COMPLETED') {
                                open(DisconnectErrorModal, {
                                    isEnabled: metaConfigurationQuery.data?.type === 'CONFIGURED' &&
                                        metaConfigurationQuery.data?.status === 'ACTIVE',
                                    onDisable: () => {
                                        setConfigurationStatus('INACTIVE');
                                    },
                                });
                                return;
                            }
                            Toasts.notify(getDefaultErrorMessage(error), {
                                type: 'error',
                            });
                        },
                    });
                },
            });
        };
        const { type } = metaConfigurationQuery.data;
        const props = {
            NOT_CONNECTED: {
                description: 'Connect your Meta account',
                isConnecting: connecting,
                onConnect: getOnConnect(),
            },
            CONNECTING_IN_PROGRESS: {
                description: 'Connecting your Meta account...',
                isConnecting: true,
                info: 'This process can take up to a minute',
                onConnect: getOnConnect(),
            },
            NOT_CONFIGURED: {
                description: 'Meta account',
                footer: (React.createElement(BaseContent, { appearance: "secondary", size: "xsmall" }, getPermissionsDescription(metaConfigurationQuery.data, metaCommercesQuery.data, getOnConnect()))),
                onDisconnect,
                onReconnect: getOnConnect(),
            },
            CONFIGURED: {
                description: 'Meta account',
                footer: (React.createElement(BaseContent, { appearance: "secondary", size: "xsmall" }, getPermissionsDescription(metaConfigurationQuery.data, metaCommercesQuery.data, getOnConnect('_blank')))),
                onDisconnect,
                onReconnect: getOnConnect(),
            },
            DISCONNECTING_IN_PROGRESS: {
                description: 'Disconnecting your Meta account...',
                info: 'This process can take up to a minute',
            },
        };
        return props[type];
    })();
    const setConfigurationStatus = async (status) => {
        return await metaStatusMutationMutation.mutateAsync([{ status }]);
    };
    const openPaymentMethodRequiredModal = () => {
        const subtitle = (React.createElement(React.Fragment, null, "For Facebook or Instagram Shop orders, Meta collects the money from the sale and sends you the profit directly. Fourthwall will deduct any costs of Meta orders (such as manufacturing or shipping we\u2019re handling) from your account balance, and if your account balance does not cover the costs we will charge the amount to your payment method."));
        open(PaymentMethodRequiredModal, {
            title: 'Payment method required',
            subtitle,
            selectPaymentMethodSubtitle: subtitle,
            onSuccess: () => setConfigurationStatus('ACTIVE'),
        });
    };
    const activateSales = async () => {
        return await isPaymentMethodModalRequiredMutation.mutateAsync([], {
            onSuccess: (data) => {
                if (data.isPaymentMethodModalRequired) {
                    openPaymentMethodRequiredModal();
                }
                else {
                    setConfigurationStatus('ACTIVE');
                }
            },
        });
    };
    const configure = (() => {
        if (!metaConfigurationQuery.isSuccess || !metaCommercesQuery.isSuccess)
            return;
        const { type } = metaConfigurationQuery.data;
        if (type === 'NOT_CONNECTED') {
            return { type: 'CONNECT_FIRST' };
        }
        if (type !== 'NOT_CONFIGURED')
            return;
        const { pages, businesses } = metaCommercesQuery.data;
        const options = pages
            .filter((page) => {
            return businesses.some((business) => {
                return business.cms.some((cms) => cms.merchantPageId === page.id);
            });
        })
            .map((page) => ({
            label: page.name,
            value: page.id,
        }));
        if (!options.length) {
            return {
                type: 'CREATE_SHOP',
                url: metaCommercesQuery.data.commerceManagerOnboardingUri,
            };
        }
        return {
            type: 'SELECT_SHOP',
            url: metaCommercesQuery.data.commerceManagerOnboardingUri,
            initialValues: { merchantPageId: initialMerchantPageId },
            selectProps: { options, onValueChange: setMerchantPageId },
            previewProps: createPreviewProps(merchantPageId, metaCommercesQuery.data),
            onSubmit: async (values) => {
                const { businesses } = metaCommercesQuery.data;
                const { merchantPageId } = values;
                const business = businesses.find((business) => business.cms.find((cms) => cms.merchantPageId === merchantPageId));
                if (!business)
                    return;
                const cms = business.cms.find((cms) => cms.merchantPageId === merchantPageId);
                if (!cms)
                    return;
                return await metaCommerceMutationMutation.mutateAsync([{ metaCMSId: cms.id }], {
                    onSuccess: () => {
                        activateSales();
                    },
                });
            },
        };
    })();
    const banners = (() => {
        if (!metaConfigurationQuery.isSuccess || metaConfigurationQuery.data.type !== 'CONFIGURED') {
            return [];
        }
        return [
            ...metaConfigurationQuery.data.reasons.map((reason) => ({
                title: reason.text,
                appearance: 'danger',
                active: reason.type !== 'DISABLED_BY_USER' && reason.type !== 'NO_VALID_PAYMENT_METHOD',
            })),
            {
                title: (React.createElement(React.Fragment, null,
                    "Meta shop app requires active payment method to cover costs of orders (such as manufacturing or shipping we\u2019re handling).",
                    ' ',
                    React.createElement("button", { onClick: () => openPaymentMethodRequiredModal() }, "Set it up now"),
                    ".")),
                appearance: 'danger',
                active: metaConfigurationQuery.data.status === 'INACTIVE' &&
                    metaConfigurationQuery.data.reasons.some((reason) => reason.type === 'NO_VALID_PAYMENT_METHOD') &&
                    !hasActivePaymentMethod,
            },
            {
                title: (React.createElement(React.Fragment, null,
                    "We were unable to charge your payment method to cover costs of your Meta Shop orders. In order to enable Meta Shop sales please",
                    ' ',
                    React.createElement("button", { onClick: () => openPaymentMethodRequiredModal() }, "update your payment method"),
                    ".")),
                appearance: 'danger',
                active: metaConfigurationQuery.data.status === 'INACTIVE' &&
                    metaConfigurationQuery.data.reasons.some((reason) => reason.type === 'NO_VALID_PAYMENT_METHOD') &&
                    hasActivePaymentMethod,
            },
        ].filter((banner) => banner.active);
    })();
    const settings = (() => {
        if (!metaConfigurationQuery.isSuccess)
            return;
        const { type } = metaConfigurationQuery.data;
        if (type !== 'CONFIGURED')
            return;
        const { status, reasons } = metaConfigurationQuery.data;
        return {
            banners,
            initialValues: {
                status: status === 'ACTIVE',
            },
            disabled: status === 'INACTIVE' &&
                reasons.some((reason) => [
                    'NO_VALID_PAYMENT_METHOD',
                    'WRONG_COMMERCE_CHECKOUT_TYPE',
                    'META_NO_FACEBOOK_OR_INSTAGRAM_CHANNEL',
                    'META_NO_FACEBOOK_OR_INSTAGRAM_CHANNEL',
                ].includes(reason.type)),
            onSubmit: async (values) => {
                if (values.status) {
                    await activateSales();
                    return;
                }
                return setConfigurationStatus('INACTIVE');
            },
        };
    })();
    const customerSupport = (() => {
        if (!metaConfigurationQuery.isSuccess)
            return;
        if (metaConfigurationQuery.data.type !== 'CONFIGURED')
            return;
        const { customerSupport } = metaConfigurationQuery.data;
        return {
            settingsUrl: customerSupport.redirectURI,
            contactEmail: customerSupport.email,
            website: customerSupport.website,
            messaging: customerSupport.messaging,
        };
    })();
    const products = (() => {
        if (!offersTotal || !metaConfigurationQuery.isSuccess)
            return;
        // It's not perfect to check it here. It just waits for the EmptyProductsApprovalStatus component to load.
        if (offersTotal.public === 0) {
            return { type: 'EMPTY' };
        }
        const shouldDisplayProducts = metaConfigurationQuery.data.type === 'CONFIGURED' &&
            metaConfigurationQuery.data.status === 'ACTIVE';
        if (!shouldDisplayProducts)
            return;
        if (!metaProductsQuery.isSuccess) {
            return { type: 'LOADING' };
        }
        const { active, pending, denied } = metaProductsQuery.data;
        const mapProduct = (product) => ({
            id: product.offerId,
            imageSrc: product.imageUrl,
            name: product.name,
            description: '', // overrides the default description
            variants: product.issues.map((issue) => ({
                description: (React.createElement(React.Fragment, null,
                    issue.detail,
                    ".",
                    issue.documentation && (React.createElement(React.Fragment, null,
                        ' ',
                        React.createElement("a", { href: issue.documentation, target: "_blank", rel: "noreferrer" }, "Learn more"))))),
            })),
        });
        const isNonNullable = (item) => {
            return Boolean(item);
        };
        const errorSamples = (() => {
            if (!metaConfigurationQuery.isSuccess || !metaProductsQuery.isSuccess)
                return;
            if (metaConfigurationQuery.data.type !== 'CONFIGURED')
                return;
            const { errorSamples } = metaProductsQuery.data;
            if (!errorSamples || !errorSamples.length)
                return;
            const { metaProductCatalogId, metaBusinessId } = metaConfigurationQuery.data;
            if (!metaProductCatalogId)
                return;
            return {
                type: 'pending',
                label: `${errorSamples.length} ${pluralize(errorSamples.length, 'product')} with issues to resolve`,
                details: {
                    type: 'LINK',
                    url: `https://business.facebook.com/commerce/catalogs/${metaProductCatalogId}/products/?business_id=${metaBusinessId}`,
                },
            };
        })();
        return {
            type: 'PRODUCTS',
            groups: [
                active &&
                    !!active.length && {
                    type: 'approved',
                    label: `${active.length} ${pluralize(active.length, 'product')} approved`,
                    details: { type: 'ITEMS', items: active.map(mapProduct) },
                },
                pending &&
                    !!pending.length && {
                    type: 'pending',
                    label: `${pending.length} ${pluralize(pending.length, 'product')} pending`,
                    details: { type: 'ITEMS', items: pending.map(mapProduct) },
                },
                errorSamples,
                denied &&
                    !!denied.length && {
                    type: 'rejected',
                    label: `${denied.length} ${pluralize(denied.length, 'product')} rejected`,
                    details: { type: 'ITEMS', items: denied.map(mapProduct) },
                },
            ].filter(isNonNullable),
        };
    })();
    return { statusTagProps, connectBarProps, configure, settings, customerSupport, products };
};
