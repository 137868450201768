import { ButtonGroup, Loading, ToolbarButton, useMediaQueryContext } from '@fourthwall/components';
import { DownloadIcon } from '@fourthwall/icons';
import { lazyWithRetry } from '@fourthwall/utils/lib/lazyWithRetry';
import React from 'react';
import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { Dashboard } from '../../../components/common/Dashboard';
import { NavTabs } from '../../../components/common/NavTabs';
import { PageLayout } from '../../../components/common/PageLayout';
import { SearchBar } from '../../../components/common/SearchBar';
import { PermissionGuardedRoute, useDashboardPermissions, } from '../../../components/utils/DashboardPermissions';
import { routing } from '../../../utils/routing';
import { useSearchQuery } from '../hooks/useSearchQuery';
import { ThankYousBarContainer } from '../ThankYous';
import { useOrdersCsvExport } from './hooks/useExportOrdersCsv';
import { DonationsContainer } from './modules/Donations/DonationsContainer';
import { GIVEAWAYS_CONSTANTS, useGiveawayContributionsQuery } from './modules/Giveaways';
import Orders from './modules/Orders';
import { OrdersFilterContainer } from './modules/Orders/modules/OrdersFilter';
import * as S from './styled';
const GiveawaysContainer = lazyWithRetry(() => import('./modules/Giveaways/GiveawaysContainer'));
export const Contributions = () => {
    const { hasPermission } = useDashboardPermissions();
    const media = useMediaQueryContext();
    const { resetSearchValue, searchValue, setSearchValue, searchOptions } = useSearchQuery();
    const { ordersList } = useOrdersCsvExport();
    const { pathname } = useLocation();
    const { giveawayContributionsQuery } = useGiveawayContributionsQuery();
    const isOrdersTab = pathname === routing.contributions.orders.all;
    const renderSearchBar = () => {
        if (!isOrdersTab)
            return null;
        return (React.createElement(SearchBar, { name: "search", placeholder: "Search orders", value: searchValue, maxLength: searchOptions.maxLength, onValueChange: (value) => setSearchValue(value), onActionIconClick: resetSearchValue }));
    };
    const getToolbarContent = () => (React.createElement(ButtonGroup, { space: "medium" },
        React.createElement(Loading, { isLoading: ordersList.isLoading },
            React.createElement(ToolbarButton, { label: "Export orders", icon: DownloadIcon, onClick: ordersList.download })),
        isOrdersTab && React.createElement(OrdersFilterContainer, null),
        media.tablet && renderSearchBar()));
    const tabs = [
        {
            label: 'Orders',
            to: routing.contributions.orders.all,
            element: React.createElement(Orders, null),
        },
        {
            label: 'Donations',
            to: routing.contributions.orders.donations.all,
            hidden: !hasPermission('contributions.donations'),
            element: (React.createElement(PermissionGuardedRoute, { permission: "contributions.donations", element: React.createElement(DonationsContainer, null) })),
        },
        {
            label: GIVEAWAYS_CONSTANTS.title,
            to: routing.contributions.orders.giveaways.all,
            element: React.createElement(GiveawaysContainer, null),
            hidden: !giveawayContributionsQuery?.data?.result.length,
        },
    ];
    // This is a temporary solution that should be fixed once whole dashboard router is migrated to react-router 6.
    const getElement = (pathname) => {
        return tabs[tabs.findIndex(({ to }) => to === pathname)]?.element || React.Fragment;
    };
    return (React.createElement(PageLayout, { size: "large" },
        React.createElement(Dashboard.Section, { name: "Orders", bottomContent: React.createElement(S.BottomContent, null,
                !media.tablet && renderSearchBar(),
                React.createElement(ThankYousBarContainer, { type: "REDIRECT" })), bottomMargin: "medium", toolbarContent: getToolbarContent() },
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(NavTabs, { tabs: tabs.filter((tab) => !tab.hidden), tabComponent: NavLink })),
            React.createElement(Routes, null,
                React.createElement(Route, { index: true, element: getElement(pathname) })))));
};
