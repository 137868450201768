import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  color: ${Colors.GS600};
`;
export const Item = styled.div `
  display: flex;

  &:before {
    content: '\\2022';
    margin: 0 6px;
  }
`;
