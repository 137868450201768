import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: 16px;

  ${mediaQueries.tablet} {
    gap: 20px;
  }
`;
export const Details = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;
