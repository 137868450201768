import { Colors, Icon, Input } from '@fourthwall/components';
import { ResetIcon, SearchIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
import { useMembersSearch } from './useMembersSearch';
export const MembersSearch = () => {
    const { value, onInputChange, onInputClear } = useMembersSearch();
    return (React.createElement(S.Container, null,
        React.createElement(Input, { value: value, onChange: (e) => onInputChange(e.target.value), size: "small", leftIcon: SearchIcon, placeholder: "Search members", actionIcon: value ? React.createElement(Icon, { component: ResetIcon, height: 18 }) : undefined, actionIconColor: Colors.GS1000, onActionIconClick: onInputClear })));
};
