import React from 'react';
import Dropzone from 'react-dropzone';
import { AddPhotoIcon } from '@fourthwall/icons';
import { UploadAreaUI } from '../../ui/UploadArea';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
export const Upload = ({ title, accept, maxSize = 104857600, // 100 MB
multiple = false, isUploading, disabled = false, invalid = false, contentAccepted, contentRejected, content, orientation = 'vertical', size = 'large', icon = AddPhotoIcon, iconSize = 32, onFileDrop, onFileDialogCancel, onFileDropRejected, openFileDialogProgrammatically = false, }) => {
    const media = useMediaQueryContext();
    const defaultTitle = media.tablet ? 'Drop file or browse to upload' : 'Browse to upload';
    const renderDefaultContent = () => {
        if (isUploading) {
            return React.createElement(UploadAreaUI.Loader, { size: size, orientation: orientation });
        }
        return (React.createElement(UploadAreaUI.Hero, { size: size, orientation: orientation },
            React.createElement(UploadAreaUI.HeroIcon, { icon: icon, iconSize: iconSize, orientation: orientation }),
            title ?? defaultTitle));
    };
    /**
     *
     * @param accepted
     * @param rejected
     * @param active
     * @param open - opening file dialog, works only when openFileDialogProgrammatically is true
     */
    const renderContent = (accepted, rejected, active, open) => {
        if (content) {
            return content({
                accepted,
                rejected,
                active,
                ...(openFileDialogProgrammatically && { open }),
            });
        }
        return (React.createElement(UploadAreaUI.ContentWrapper, { accepted: accepted || !!contentAccepted, rejected: rejected || !!contentRejected, invalid: invalid, disabled: disabled, size: size }, renderDefaultContent()));
    };
    return (React.createElement(Dropzone, { accept: accept, maxSize: maxSize, disabled: isUploading || disabled, multiple: multiple, onFileDialogCancel: onFileDialogCancel, onDropRejected: onFileDropRejected, onDropAccepted: onFileDrop, noClick: openFileDialogProgrammatically, noKeyboard: openFileDialogProgrammatically }, ({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive, open }) => (React.createElement(UploadAreaUI.Root, { ...getRootProps(), "data-testid": "Upload.ContentContainer" },
        React.createElement("input", { ...getInputProps() }),
        renderContent(isDragAccept, isDragReject, isDragActive, open)))));
};
