import { getDefaultPaymentMethod } from '../../utils';
export const getErrorBanner = (paymentMethods) => {
    const defaultPaymentMethod = getDefaultPaymentMethod(paymentMethods);
    if (defaultPaymentMethod?.expired) {
        return 'Your default payment method is expired. Update your payment method to prevent blocked transactions.';
    }
    if (defaultPaymentMethod?.invalid) {
        return 'We were unable to charge your payment method. Update your payment method to prevent blocked transactions.';
    }
    return undefined;
};
