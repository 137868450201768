import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  position: relative;
`;
export const ColorContainer = styled.div `
  position: relative;
  overflow: hidden;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 15px;
`;
export const ColorItemContainer = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
`;
export const InputContainer = styled.div `
  cursor: pointer;
`;
export const ChevronContainer = styled.div `
  display: flex;
  position: absolute;
  color: ${Colors.GS600};
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;
export const PickerContainer = styled.div ``;
