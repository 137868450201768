import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  background: ${Colors.GS200};
  padding: 24px 28px;
`;
export const Subtitle = styled.h4 `
  color: ${Colors.GS1000};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;
