import React from 'react';
import { Button, ButtonGroup, CurrencyInput, Input, ModalBox, PriceString, ProfitString, useModalContext, } from '@fourthwall/components';
import { StockTooltip } from '@components/common/StockTooltip';
import { Field, Form, Formik } from 'formik';
import { WeightInput } from '@components/common/WeightInput';
import { useMinimumProfit } from '@modules/Dashboard/Product/hooks/useMinimumProfit';
import { productVariantValidationSchema } from './validationSchema';
import * as S from './styled';
export const ProductVariantModal = ({ productVariant, sold, isPriceEditable, isCompareAtPriceEditable, isWeightEditable, isSKUEditable, shipMonkAvailableStock, whiplashAvailableStock, onSubmit, onStockTooltipChange, }) => {
    const { close } = useModalContext();
    const { minimumProfit } = useMinimumProfit();
    const compareAtPrice = productVariant.compareAtPrice?.value;
    const cost = productVariant.cost?.value;
    const showButtons = isPriceEditable || isCompareAtPriceEditable || isWeightEditable || isSKUEditable;
    const handleOnSubmit = (values) => {
        onSubmit(values);
        close();
    };
    return (React.createElement(ModalBox, { title: productVariant.attributesDescription, onClose: close },
        React.createElement(Formik, { initialValues: {
                quantity: productVariant.quantity,
                price: productVariant.price,
                compareAtPrice: productVariant.compareAtPrice,
                weight: productVariant.weight,
                sku: productVariant.sku,
            }, validationSchema: productVariantValidationSchema({ minimumProfit, variantCost: cost }), onSubmit: handleOnSubmit }, ({ values, setFieldValue }) => (React.createElement(Form, null,
            React.createElement(S.Container, null,
                React.createElement(S.Content, null,
                    React.createElement(S.Row, null,
                        React.createElement(S.Column, null,
                            React.createElement(S.Label, null, "In stock"),
                            React.createElement(S.StockTooltipWrapper, null,
                                React.createElement(StockTooltip, { quantity: values.quantity, onChange: (oldQuantity, newQuantity) => {
                                        setFieldValue('quantity', newQuantity);
                                        onStockTooltipChange(oldQuantity, newQuantity);
                                    } }))),
                        React.createElement(S.Column, null,
                            React.createElement(S.Label, null, "Sold"),
                            React.createElement("div", null, sold))),
                    shipMonkAvailableStock && (React.createElement(S.Row, null,
                        React.createElement(S.Column, null,
                            React.createElement(S.Label, null, "In ShipMonk"),
                            React.createElement("div", null, shipMonkAvailableStock)))),
                    whiplashAvailableStock && (React.createElement(S.Row, null,
                        React.createElement(S.Column, null,
                            React.createElement(S.Label, null, "In Whiplash"),
                            React.createElement("div", null, whiplashAvailableStock)))),
                    React.createElement(S.Row, null,
                        React.createElement(S.Column, null,
                            React.createElement(S.Label, null, "Selling price"),
                            isPriceEditable ? (React.createElement(Field, { component: CurrencyInput, name: "price.value", type: "number", size: "medium", hideSpinner: true })) : (React.createElement(PriceString, { value: productVariant.price.value }))),
                        (compareAtPrice || isCompareAtPriceEditable) && (React.createElement(S.Column, null,
                            React.createElement(S.Label, null, "Compare-at price"),
                            isCompareAtPriceEditable ? (React.createElement(Field, { name: "compareAtPrice.value", component: CurrencyInput, type: "number", size: "medium", strikethrough: true })) : (React.createElement(S.CompareAtPrice, null,
                                React.createElement(PriceString, { value: compareAtPrice })))))),
                    cost && (React.createElement(S.Row, null,
                        React.createElement(S.Column, null,
                            React.createElement(S.Label, null, "Profit per sale"),
                            React.createElement(ProfitString, { value: values.price.value - cost })))),
                    React.createElement(S.Row, null,
                        React.createElement(S.Column, null,
                            React.createElement(S.Label, null, "Weight"),
                            isWeightEditable ? (React.createElement(S.WeightInputContainer, null,
                                React.createElement(WeightInput, { weightName: "weight.value", unitName: "weight.unit", size: "medium", weightWidth: "100%", unitWidth: "100%", displayError: true }))) : (React.createElement("div", null,
                                productVariant.weight.value,
                                productVariant.weight.unit)))),
                    isSKUEditable && (React.createElement(S.Row, null,
                        React.createElement(S.Column, null,
                            React.createElement(S.Label, null, "SKU or UPC code"),
                            React.createElement(Field, { name: "sku", component: Input, size: "medium" }))))),
                showButtons && (React.createElement(ButtonGroup, { direction: "column", fullWidth: true, space: "medium" },
                    React.createElement(Button, { type: "submit", appearance: "primary", label: "Save", fullWidth: true }),
                    React.createElement(Button, { appearance: "secondary", label: "Cancel", fullWidth: true, onClick: close })))))))));
};
