import React, { forwardRef } from 'react';
import { Icon } from '../../common/Icon';
import * as S from './styled';
const Root = S.Root;
const Container = S.Container;
const Box = S.Box;
const Input = S.Input;
const SpinnerContainer = S.SpinnerContainer;
const SpinnerItem = forwardRef(({ icon, iconSize, ...props }, ref) => {
    return (React.createElement(S.SpinnerItem, { ref: ref, type: "button", ...props },
        React.createElement(Icon, { component: icon, height: iconSize })));
});
const Prepend = S.Prepend;
const Append = S.Append;
const IconContainer = S.IconContainer;
const ActionIconContainer = S.ActionIconContainer;
const Label = S.Label;
const Suggestion = S.Suggestion;
const Info = S.Info;
const Error = forwardRef(({ errorTimestampKey, children, ...props }, ref) => {
    return (React.createElement(S.Error, { ref: ref, "aria-live": "polite", "aria-atomic": "true", role: "alert", key: errorTimestampKey, ...props }, children));
});
export { ActionIconContainer, Append, Box, Container, Error, IconContainer, Info, Input, Label, Prepend, Root, SpinnerContainer, SpinnerItem, Suggestion, };
