import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  height: 200px;

  ${({ size }) => size === 'small' &&
    css `
      height: 120px;
    `};
`;
export const Thumbnail = styled.img `
  height: 100%;
  max-width: 200px;
  object-fit: cover;
`;
export const Controls = styled.div `
  flex: 1;
  position: relative;
`;
export const MediaPlayer = styled.div `
  .plyr {
    input[type='range'] {
      cursor: pointer;

      &::-webkit-slider-runnable-track {
        background-color: var(--plyr-audio-progress-buffered-background);
      }

      &::-webkit-slider-thumb {
        background: ${Colors.GS1000};
        box-shadow: none;
      }
    }
  }

  .plyr {
    --plyr-color-main: ${Colors.GS1000};
    --plyr-control-spacing: 0;
    --plyr-audio-controls-background: none;
    --plyr-audio-control-color: ${Colors.GS1000};
    --plyr-audio-control-color-hover: ${Colors.B500};
    --plyr-audio-control-background-hover: none;
    --plyr-range-track-height: 4px;
    --plyr-range-thumb-height: 16px;
    --plyr-audio-progress-buffered-background: ${Colors.GS400};

    position: static;

    [data-plyr='volume'] {
      height: 0;
      overflow: hidden;
    }

    .plyr__control {
      &[data-plyr='mute'] {
        transform: rotate(90deg);
        opacity: 0.5;
        padding: 8px;

        & + input[type='range'] {
          &:hover {
            padding: 14px 8px;
            height: initial;
            overflow: visible;
          }
        }

        &:hover {
          opacity: 1;

          & + input[type='range'] {
            padding: 14px 8px;
            height: initial;
            overflow: visible;
          }
        }
      }

      &[data-plyr='rewind'],
      &[data-plyr='play'],
      &[data-plyr='fast-forward'] {
        --plyr-control-icon-size: 32px;

        position: absolute;
        top: 76px;
        left: 50%;
        transform: translateX(calc(-50%));

        ${({ size }) => size === 'small' &&
    css `
            top: 35px;
          `};
      }

      &[data-plyr='rewind'] {
        transform: translateX(calc(-50% - 100% - 40px));
      }

      &[data-plyr='fast-forward'] {
        transform: translateX(calc(-50% + 100% + 40px));
      }
    }

    .plyr__volume {
      --plyr-audio-control-color-hover: ${Colors.GS1000};

      display: flex;
      top: 124px;
      right: -30px;
      transform: rotate(-90deg);
      width: auto;
      max-width: 128px;

      ${({ size }) => size === 'small' &&
    css `
          top: 48px;
          right: -54px;
        `};
    }

    .plyr__time {
      position: absolute;
      bottom: 5px;
      opacity: 0.5;
      font-size: 14px;
      line-height: 120%;
      color: ${Colors.GS1000};

      &:before {
        display: none;
      }

      &--current {
        left: 12px;
        width: 65px;

        ${({ size }) => size === 'small' &&
    css `
            left: 0;
            bottom: 0;
            width: auto;
          `};
      }

      &--duration {
        display: block;
        right: 42px;
        width: 87px;

        ${({ size }) => size === 'small' &&
    css `
            bottom: 0;
            width: auto;
          `};
      }
    }

    .plyr__progress__container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 4px 118px 4px 77px !important;

      input[type='range'] {
        height: 16px;
      }

      ${({ size }) => size === 'small' &&
    css `
          padding: 0px 100px 0px 60px !important;
        `};
    }
  }
`;
