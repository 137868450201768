import { EmptyState, PriceString, ProfitString, Tag, useMediaQueryContext, useModalContext, usePagination, } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import React, { useEffect, useMemo } from 'react';
import { TableCell } from '@components/common/TableCell';
import { TableProductsList } from '@components/common/TableProductsList';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { TableItemDate } from '@components/utils/TableItemDate';
import { useQueryParamsToTableSorting } from '@hooks/useQueryParamsToTableSorting';
import { useLocation } from 'react-router-dom';
import { GiveawaysItemBreakdownModal } from '../../components/GiveawaysItemBreakdownModal';
import { useGiveawayContributionsQuery } from '../useGiveawayContributionsQuery';
import { filterMembershipsGiftsRedeems, getQueryParams } from './utils';
import { GiveawayListItem } from '../../components/GiveawayListItem';
import { createHeadlineDataId } from '../../utils/createHeadlineDataId';
import { GiveawaysItemBreakdownData } from '../../components/GiveawaysItemBreakdownData';
import { isFromLocationStateState } from '../../utils/isFromLocationStateState';
const dateHeaderId = 'date';
const initialSorting = { headerId: dateHeaderId, direction: 'desc' };
export const useGiveaways = () => {
    const media = useMediaQueryContext();
    const { hasPermission } = useDashboardPermissions();
    const [currentPage, setCurrentPage] = usePagination();
    const { sorting, onSortingChange } = useQueryParamsToTableSorting({ initialSorting });
    const { open } = useModalContext();
    const location = useLocation();
    const { giveawayContributionsQuery } = useGiveawayContributionsQuery([getQueryParams(currentPage, dateHeaderId, sorting)], { keepPreviousData: true });
    const data = useMemo(() => giveawayContributionsQuery.data?.result || [], [giveawayContributionsQuery]);
    const emptyComponentTitle = 'There are no purchased gifts yet';
    useEffect(() => {
        if (!media.tablet && isFromLocationStateState(location.state)) {
            const giveaway = data.find(({ giveawayId }) => giveawayId === location.state.giveawayId);
            giveaway && open(GiveawaysItemBreakdownModal, { giveaway });
        }
    }, [data, location.state, media.tablet, open]);
    const openGiveawaysItemBreakdownModal = (giveaway) => {
        open(GiveawaysItemBreakdownModal, { giveaway });
    };
    const headers = [
        {
            id: dateHeaderId,
            label: 'Date',
            render: ({ createdAt }) => (React.createElement("div", null,
                React.createElement(TableItemDate, { date: createdAt }))),
            sortable: true,
            width: 0.8,
        },
        {
            id: 'product',
            label: ' ',
            render: ({ productImageUrl, numberOfGifts }) => (React.createElement(TableProductsList, { image: productImageUrl, offers: [], offersCancelled: [], quantity: numberOfGifts })),
        },
        {
            id: 'purchasedBy',
            label: ' ',
            render: ({ supporter }) => (React.createElement(TableCell, { mainContent: supporter.username || supporter.email, ellipsis: true })),
            width: 2,
        },
        {
            id: 'status',
            label: 'Status',
            render: ({ claimedGifts, numberOfGifts }) => (React.createElement(Tag, { label: `Gift (Redeemed ${claimedGifts}/${numberOfGifts})`, appearance: "success" })),
            width: 2,
        },
        {
            id: 'total',
            label: 'Total',
            render: ({ total }) => React.createElement(PriceString, { value: total.value }),
        },
        {
            id: 'profit',
            hidden: !hasPermission('contributions.profit'),
            label: 'Profit',
            render: ({ profit, giftsLeftToRedeem }) => (React.createElement(TableCell, { mainContent: React.createElement(ProfitString, { value: profit.value }), additionalContent: giftsLeftToRedeem
                    ? `${giftsLeftToRedeem} ${pluralize(giftsLeftToRedeem, 'redeem')} left`
                    : undefined })),
            width: 1.2,
        },
    ];
    const list = {
        data,
        isLoading: giveawayContributionsQuery.isFetching,
        emptyComponent: React.createElement(EmptyState, { variant: "secondary", title: emptyComponentTitle }),
        renderItem: (giveaway) => React.createElement(GiveawayListItem, { giveaway: giveaway }),
        onClick: openGiveawaysItemBreakdownModal,
    };
    const table = {
        data,
        headers: headers.filter((column) => !column.hidden),
        sorting,
        emptyComponent: React.createElement(EmptyState, { title: emptyComponentTitle }),
        isLoading: giveawayContributionsQuery.isFetching,
        // @ts-ignore
        renderFooter: (giveaway, { isHover }) => {
            giveaway.giveawayBreakdown = giveaway.giveawayBreakdown.filter(filterMembershipsGiftsRedeems);
            return React.createElement(GiveawaysItemBreakdownData, { giveaway: giveaway, hovered: isHover });
        },
        onRowClick: (row) => {
            const headlineDataId = createHeadlineDataId(row);
            const element = document.querySelector(`[data-id="${headlineDataId}"]`);
            if (element) {
                element.click();
            }
        },
        onSortingChange,
    };
    const getPagination = () => {
        if (!giveawayContributionsQuery.data?.page.totalPages)
            return;
        return {
            pageCount: giveawayContributionsQuery.data?.page.totalPages || 1,
            currentPage,
            onChange: setCurrentPage,
        };
    };
    return {
        giveaways: {
            table,
            list,
            pagination: getPagination(),
        },
    };
};
