import React from 'react';
import * as S from './styled';
export const UnavailableResource = ({ resource, description = 'Check the URL and try again.', }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.Title, null,
            "There's no ",
            resource,
            " at this address."),
        React.createElement(S.Description, null, description)));
};
