import { Toasts } from '@fourthwall/components';
import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import { useMutation } from '@tanstack/react-query';
export const useCreateSingleSeriesMutation = () => {
    const createSingleSeriesMutation = useMutation({
        mutationFn: async (payload) => {
            const { data } = await MembershipsCreatorApiClient.createSingleSeries(payload);
            return data;
        },
        onError: (error) => {
            const errorMessage = errorMessageSelector(error.response?.data);
            Toasts.notify(errorMessage, { type: 'error' });
        },
    });
    return { createSingleSeriesMutation };
};
