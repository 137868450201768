import React, { useState } from 'react';
import { useAuth } from '@fourthwall/auth';
import { Banner, ButtonClean, ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { QuestionMarkIcon } from '@fourthwall/icons';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { isDevelopment } from '@fourthwall/utils/lib/dev';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import { useCreatorIntegrationsQuery } from '@modules/Dashboard/Apps';
import { useProductApprovalStatus } from '@modules/Dashboard/Apps/hooks/useProductApprovalStatus';
import { useCurrentShopQuery } from '@modules/Dashboard/hooks/useCurrentShopQuery';
import { formatDate } from '@utils/date';
import { routing } from '@utils/routing';
import { useDeleteCreatorIntegrationMutation } from '@mutations';
import logoSrc from '../../../assets/signets/youtube.svg';
import { useAppConnectedEvent } from '../../../hooks/useAppConnectedEvent';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
import { MissingTermsBanner } from '../components/MissingTermsBanner';
import { useYouTubeMerchantCenterIntegrationsQuery } from './useYouTubeMerchantCenterIntegrationsQuery';
import { useYouTubeMerchantCenterProductsQuery } from './useYouTubeMerchantCenterProductsQuery';
import { useYouTubeMerchantCenterReconnectMutation } from './useYouTubeMerchantCenterReconnectMutation';
const createReturnUrl = (domain) => {
    const url = new URL(window.location.toString());
    url.hostname = domain;
    url.port = '';
    return isDevelopment() ? url.toString() : undefined;
};
// NOTE: Those are supported keys for /oauth2/authorization/:provider URL.
const PROVIDERS = [
    'youtube-merchshelf-1',
    'youtube-merchshelf-2',
    'youtube-merchshelf-3',
    'youtube-merchshelf-4',
    'youtube-merchshelf-5',
];
const openProviderWindow = (provider, token, domain) => {
    const returnUrl = createReturnUrl(domain);
    const url = CreatorIntegrationsApiClient.appAuthorizationUrl({ app: provider, token, returnUrl });
    const target = isDevelopment() ? '_blank' : '_self';
    window.open(url, target);
};
const getReasonTooltip = (reasonType) => {
    const tooltips = {
        INVALID_SCOPES_OR_CHANNEL: 'Unable to retrieve channel details due to insufficient permissions or non-existent channel.',
        NOT_ENOUGH_SUBSCRIBERS: 'Your channel has less than 500 subscribers.',
        CHANNEL_NOT_ELIGIBLE: 'Your channel is not eligible for YouTube Product Shelf. Ensure you have accepted terms and conditions in YouTube Studio to access the monetization platform. If the issue persists, reach out to our support team for assistance.',
        UNKNOWN_ERROR: "There's an unknown issue with your connection. Our team is working on a fix.",
    };
    return tooltips[reasonType];
};
const getIntegrationTag = (status) => {
    const { type } = status;
    const isActionRequired = status.type === 'DENIED' && status.reason.type === 'TERMS_OF_SERVICE_NOT_SIGNED';
    const reasonTooltip = status.type === 'DENIED' ? getReasonTooltip(status.reason.type) : undefined;
    const tags = {
        ACTIVE: { label: 'connected', appearance: 'success' },
        PENDING: { label: 'pending', appearance: 'caution' },
        DENIED: {
            label: isActionRequired ? 'action required' : 'rejected',
            appearance: isActionRequired ? 'caution' : 'alert',
            icon: reasonTooltip ? QuestionMarkIcon : undefined,
            tooltip: reasonTooltip,
        },
    };
    return tags[type];
};
const mapProductToVariant = (product) => {
    const getName = () => {
        if (!product.color && !product.size)
            return product.name;
        return [product.color, product.size].filter(Boolean).join(', ');
    };
    return {
        name: getName(),
        description: (() => {
            if (!product.issues.length)
                return;
            return product.issues.map(({ detail, documentation }) => {
                const link = documentation ? (React.createElement(React.Fragment, null,
                    ' ',
                    React.createElement(ButtonClean, { label: "(Learn more)", href: documentation, target: "_blank" }))) : ('');
                return (React.createElement("p", null,
                    detail,
                    link,
                    ".",
                    ' '));
            });
        })(),
    };
};
export const reduceProducts = (previous, current) => {
    const offer = previous.find((item) => item.id === current.offerId);
    if (!offer) {
        previous.push({
            id: current.offerId,
            imageSrc: current.imageUrl,
            name: current.name,
            tag: current.disclosureDate
                ? {
                    label: `Goes public ${formatDate(current.disclosureDate, 'MMM D')}`,
                    appearance: 'caution',
                }
                : {
                    label: 'Public',
                    appearance: 'success',
                },
            variants: [mapProductToVariant(current)],
        });
        return previous;
    }
    offer.variants.push(mapProductToVariant(current));
    return previous;
};
export const useSettingsAppsYouTubeProductShelf = () => {
    const initialRefetchInterval = 1_000;
    const [refetchInterval, setRefetchInterval] = useState(initialRefetchInterval);
    const { creatorIntegrationsQuery, invalidateCreatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const { youTubeMerchantCenterIntegrationsQuery, invalidateYouTubeMerchantCenterIntegrationsQuery, } = useYouTubeMerchantCenterIntegrationsQuery(undefined, {
        refetchInterval,
        onSettled: () => {
            setRefetchInterval((value) => value + 500);
        },
    });
    const { youTubeMerchantCenterReconnectMutation } = useYouTubeMerchantCenterReconnectMutation();
    const { deleteCreatorIntegrationMutation } = useDeleteCreatorIntegrationMutation();
    const isAnyIntegrationPending = youTubeMerchantCenterIntegrationsQuery.data?.integrations?.some((integration) => integration.status.type === 'PENDING');
    const { youTubeMerchantCenterProductsQuery, invalidateYouTubeMerchantCenterProductsQuery } = useYouTubeMerchantCenterProductsQuery(undefined, {
        refetchInterval,
        enabled: !isAnyIntegrationPending,
    });
    const { currentShopQuery } = useCurrentShopQuery(undefined, { staleTime: Infinity });
    const { keycloak } = useAuth();
    const { open } = useModalContext();
    const adminMode = useAdminModeContext();
    const { productApprovalStatus } = useProductApprovalStatus();
    const appConnectEvent = useAppConnectEvent('youtube_product_shelf');
    const [isReconnecting, setIsReconnecting] = useState({});
    useAppConnectedEvent('youtube_product_shelf');
    const getOnConnect = (where) => {
        if (!youTubeMerchantCenterIntegrationsQuery.isSuccess)
            return;
        const { integrations } = youTubeMerchantCenterIntegrationsQuery.data;
        if (where === 'footer' && !integrations.length)
            return;
        const usedProviders = integrations.map(({ oAuth2Provider }) => oAuth2Provider);
        const availableProvider = PROVIDERS.find((item) => !usedProviders.includes(item));
        if (!availableProvider)
            return;
        return () => {
            appConnectEvent.trigger();
            if (keycloak && keycloak.token && currentShopQuery.isSuccess) {
                const domain = currentShopQuery.data.domains[0];
                openProviderWindow(availableProvider, keycloak.token, domain);
            }
        };
    };
    const onReconnect = (provider) => {
        setIsReconnecting((prev) => ({ ...prev, [provider]: true }));
        youTubeMerchantCenterReconnectMutation.mutate([{ provider }], {
            onSuccess: () => {
                invalidateCreatorIntegrationsQuery();
                invalidateYouTubeMerchantCenterIntegrationsQuery();
                invalidateYouTubeMerchantCenterProductsQuery();
            },
            onSettled: () => {
                setIsReconnecting((prev) => ({ ...prev, [provider]: false }));
            },
        });
    };
    const onDisconnect = async (integration) => {
        open(ConfirmModal, {
            title: 'Are you sure you want to disconnect from YouTube?',
            confirmLabel: 'Yes, disconnect',
            confirmAppearance: 'destructive',
            onConfirm: async () => {
                Toasts.notify('Disconnecting from YouTube...', { type: 'info' });
                await deleteCreatorIntegrationMutation.mutateAsync(integration.oAuth2Provider);
                Toasts.notify('Disconnected from YouTube successfully.', { type: 'success' });
                setRefetchInterval(initialRefetchInterval);
                invalidateCreatorIntegrationsQuery();
                invalidateYouTubeMerchantCenterIntegrationsQuery();
                invalidateYouTubeMerchantCenterProductsQuery();
            },
        });
    };
    const getBars = () => {
        if (!youTubeMerchantCenterIntegrationsQuery.isSuccess)
            return [];
        const { integrations } = youTubeMerchantCenterIntegrationsQuery.data;
        if (!integrations.length) {
            return [
                {
                    logoSrc,
                    description: 'Connect your YouTube account',
                    disabled: deleteCreatorIntegrationMutation.isLoading,
                    onConnect: getOnConnect('bar'),
                },
            ];
        }
        const getIntegrationFooter = (integration) => {
            const { status } = integration;
            if (status.type === 'DENIED' && status.reason.type === 'TERMS_OF_SERVICE_NOT_SIGNED') {
                return (React.createElement(MissingTermsBanner, { isReconnecting: isReconnecting[integration.oAuth2Provider], onReconnect: () => onReconnect(integration.oAuth2Provider) }));
            }
            if (status.type === 'DENIED' && status.reason.type === 'MISREPRESENTATION') {
                return (React.createElement(Banner, { appearance: "warning", size: "small" },
                    "There was an issue with your connection to YouTube. Please",
                    ' ',
                    React.createElement("button", { onClick: () => onDisconnect(integration) }, "disconnect this app"),
                    " and then connect again to ensure the Product Shelf works properly."));
            }
            return null;
        };
        const mapIntegration = (integration) => ({
            logoSrc: integration.thumbnailUri || logoSrc,
            logoStyle: integration.thumbnailUri ? 'circle' : 'normal',
            description: integration.channelName,
            tag: getIntegrationTag(integration.status),
            disabled: deleteCreatorIntegrationMutation.isLoading,
            settingsUri: integration.settingsUri ?? routing.external.youtube.shoppingSettings,
            settingsLabel: 'YouTube Studio',
            footer: getIntegrationFooter(integration),
            onDisconnect: () => onDisconnect(integration),
        });
        return integrations.map(mapIntegration);
    };
    const getProducts = () => {
        if (productApprovalStatus !== 'APPROVED')
            return;
        if (!youTubeMerchantCenterIntegrationsQuery.isSuccess)
            return;
        const { integrations } = youTubeMerchantCenterIntegrationsQuery.data;
        // no active integration
        if (!integrations.some(({ status }) => status.type === 'ACTIVE'))
            return;
        if (!youTubeMerchantCenterProductsQuery.isSuccess)
            return;
        return {
            approved: youTubeMerchantCenterProductsQuery.data.active.reduce(reduceProducts, []),
            pending: youTubeMerchantCenterProductsQuery.data.pending.reduce(reduceProducts, []),
            rejected: youTubeMerchantCenterProductsQuery.data.denied.reduce(reduceProducts, []),
        };
    };
    const hasNoProducts = () => {
        if (!youTubeMerchantCenterProductsQuery.isSuccess)
            return false;
        return Object.values(youTubeMerchantCenterProductsQuery.data).every((item) => !item.length);
    };
    const description = (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "Show your products below your YouTube videos. YouTube requires at least 500 subscribers to use this feature",
            ' ',
            React.createElement(ButtonClean, { label: "(view all requirements)", href: routing.external.helpCenter.youtubeProductShelf, target: "_blank" }),
            "."),
        creatorIntegrationsQuery.data?.youtubeMerchshelf.status !== 'CONNECTED' &&
            (currentShopQuery.data?.status !== 'LIVE' || hasNoProducts()) && (React.createElement("p", null,
            React.createElement(Banner, { appearance: "warning" }, "Your shop and products must be live to be eligible for YouTube Product Shelf. Please make sure your products are publicly accessible so YouTube\u2019s team is able to review them.")))));
    const productsIsLoading = (() => {
        if (productApprovalStatus !== 'APPROVED')
            return false;
        if (!youTubeMerchantCenterIntegrationsQuery.isSuccess)
            return false;
        const { integrations } = youTubeMerchantCenterIntegrationsQuery.data;
        // some integrations are pending
        if (integrations.some(({ status }) => status.type === 'PENDING'))
            return true;
        // no active integration
        if (!integrations.some(({ status }) => status.type === 'ACTIVE'))
            return false;
        return youTubeMerchantCenterProductsQuery.isLoading;
    })();
    const adminContent = (() => {
        if (!adminMode?.isActive)
            return;
        if (!currentShopQuery.isSuccess)
            return;
        const token = keycloak?.token;
        if (!token)
            return;
        const domain = currentShopQuery.data.domains[0];
        return (React.createElement("div", null,
            React.createElement(ButtonClean, { label: "Connect (youtube-merchshelf-admin)", onClick: () => {
                    openProviderWindow('youtube-merchshelf-admin', token, domain);
                } })));
    })();
    return {
        settingsAppsYouTubeProductShelf: {
            status: creatorIntegrationsQuery.data?.youtubeMerchshelf.status,
            connectBar: { bars: getBars(), onConnect: getOnConnect('footer') },
            products: getProducts(),
            description,
            productsIsLoading,
            adminContent,
        },
    };
};
