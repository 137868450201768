import React from 'react';
import { Button, HeadContent } from '@fourthwall/components';
import { routing } from '@utils/routing';
import { useCopyToClipboard } from '@components/hooks/useCopyToClipboard';
import { IframePreview } from './components/IframePreview';
export const OfferPreviewView = ({ title, headline, status, productUrl, editTo, dashboardTo, iframeSrc, }) => {
    const { copyToClipboard } = useCopyToClipboard({
        toastMessage: 'Product URL copied',
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: title }),
        React.createElement(IframePreview, { headline: headline, status: status, buttons: [
                React.createElement(Button, { label: "Copy URL", size: "small", onClick: () => copyToClipboard(productUrl) }),
                React.createElement(Button, { label: "Edit", to: editTo, size: "small" }),
                React.createElement(Button, { label: "Create another product", href: routing.productCatalog.self, size: "small" }),
                React.createElement(Button, { label: "Go to dashboard", to: dashboardTo, appearance: "primary", size: "small" }),
            ], iframeSrc: iframeSrc })));
};
