import React from 'react';
import { Toasts } from '@fourthwall/components';
import { useCurrentShopQuery } from '@modules/Dashboard/hooks/useCurrentShopQuery';
import { useShippingProfilesQuery } from '@modules/Dashboard/hooks/useShippingProfilesQuery';
import { useNavigate, useParams } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routing } from '@utils/routing';
import { BooleanParam, useQueryParams } from 'use-query-params';
import { INITIAL_SHIPPING_COUNTRIES } from './consts';
import { useSaveShippingProfileMutation } from './hooks/useSaveShippingProfileMutation';
import { ShippingGroupView } from './ShippingGroupView';
export const ShippingGroupContainer = () => {
    const { currentShopQuery } = useCurrentShopQuery();
    const navigate = useNavigate();
    const { shippingGroupId = '' } = useParams();
    const [queryParams] = useQueryParams({
        selfFulfilled: BooleanParam,
    });
    // TODO: get shipping group by id once endpoint is ready
    const { shippingProfilesQuery, invalidateShippingProfilesQuery } = useShippingProfilesQuery();
    const { saveShippingProfileMutation } = useSaveShippingProfileMutation();
    const shippingGroup = shippingProfilesQuery.data?.shippingProfiles.find((profile) => profile.id === shippingGroupId);
    const isEdit = Boolean(shippingGroupId);
    const navigateToEditRoute = (id) => navigate({
        pathname: reverse(routing.settings.shippingGroup.self, { shippingGroupId: id }),
        search: location.search,
    });
    const handleSubmit = async (values) => {
        await saveShippingProfileMutation.mutateAsync([
            {
                id: shippingGroupId || undefined,
                offerIds: values.selectedProductsIds,
                countries: values.selectedCountries,
            },
        ], {
            onSuccess: ({ id }) => {
                invalidateShippingProfilesQuery();
                Toasts.notify(isEdit ? 'Shipping group has been updated.' : 'Shipping group has been created.', { type: 'success' });
                if (!isEdit && id) {
                    navigateToEditRoute(id);
                }
            },
        });
    };
    return (React.createElement(ShippingGroupView, { isSelfFulfilled: !!queryParams.selfFulfilled, initialValues: {
            selectedProductsIds: isEdit && shippingGroup ? shippingGroup.offerIds : [],
            selectedCountries: isEdit && shippingGroup ? shippingGroup.countries : INITIAL_SHIPPING_COUNTRIES,
        }, shippingLocation: currentShopQuery.data?.originAddress, onSubmit: handleSubmit }));
};
