import { ApiBase } from './ApiBase';
class CreatorIntegrationsV2ApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getCreatorIntegrationsV2 = () => {
            return this.get('/v2/creator-integrations');
        };
    }
}
export const CreatorIntegrationsV2ApiClient = new CreatorIntegrationsV2ApiClientModel();
