import React from 'react';
import { useMediaQueryContext } from '@fourthwall/components';
import { useThemeEditorContext } from '../../../../hooks/useThemeEditorContext';
import * as S from './styled';
import { Toolbar } from '../Toolbar';
export const ThemePreviewContent = ({ isThemeMain, shopUrl }) => {
    const media = useMediaQueryContext();
    const { previewMode, previewTimestamp, isPreviewLoading, currentSectionKey, setPreviewMode } = useThemeEditorContext();
    if (!shopUrl) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        media.laptop && (React.createElement(Toolbar, { previewMode: previewMode, isThemeMain: isThemeMain, onPreviewModeChange: (previewMode) => {
                setPreviewMode(previewMode);
            } })),
        React.createElement(S.ThemePreview, { shopUrl: shopUrl, previewMode: previewMode, iframeTimestamp: previewTimestamp, isLoading: isPreviewLoading, previewSectionKey: currentSectionKey })));
};
