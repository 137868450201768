import { Toasts } from '@fourthwall/components';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { createMutationHook } from '@fourthwall/utils/lib/queries';
import { getDefaultErrorMessage } from '@fourthwall/utils/lib/queryClient';
import axios from 'axios';
import React from 'react';
import { routing } from '@utils/routing';
export const useUpdateTiktokShopConfigurationStatusMutation = createMutationHook(CreatorIntegrationsApiClient.updateTiktokPartnerConfigurationStatus, {
    mutationKey: ['updateTiktokShopConfigurationStatus'],
    onError: (error) => {
        if (axios.isAxiosError(error) &&
            error.response?.data?.code === 'TIKTOK_SHIPPING_TEMPLATES_NOT_CONFIGURED') {
            Toasts.notify(React.createElement(React.Fragment, null,
                "You must set up a shipping template in your TikTok Seller Center before you sync your products to TikTok.",
                ' ',
                React.createElement("a", { href: routing.external.helpCenter.tiktokShop, target: "_blank", rel: "noopener noreferrer" }, "Shipping template setup guide")), {
                type: 'error',
                autoClose: false,
            });
            return;
        }
        if (axios.isAxiosError(error) &&
            error.response?.data?.code === 'TIKTOK_RETURN_WAREHOUSE_NOT_CONFIGURED') {
            Toasts.notify(React.createElement(React.Fragment, null,
                "You must set up a warehouse and return warehouse in your TikTok Seller Center before you sync your products to TikTok.",
                ' ',
                React.createElement("a", { href: routing.external.helpCenter.tiktokShop, target: "_blank", rel: "noopener noreferrer" }, "Warehouse setup guide")), {
                type: 'error',
                autoClose: false,
            });
            return;
        }
        Toasts.notify(getDefaultErrorMessage(error), {
            type: 'error',
        });
    },
});
