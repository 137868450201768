import React from 'react';
import { useOffersByIdsQuery } from '@hooks/useOffersByIdsQuery';
import { getCountryNameByCode } from '@fourthwall/utils/lib/inputs';
import { getContent } from './utils/getContent';
import { ShippingGroupTileView } from './ShippingGroupTileView';
export const ShippingGroupTileContainer = ({ offerIds, countries, ...props }) => {
    const { offersByIdsQuery } = useOffersByIdsQuery([offerIds]);
    const getProductsContent = () => {
        const offerNames = Object.values(offersByIdsQuery.data || {}).map((offer) => offer.name);
        return getContent(offerNames);
    };
    const getCountriesContent = () => {
        const countryNames = countries.map((country) => getCountryNameByCode(country));
        return getContent(countryNames);
    };
    return (React.createElement(ShippingGroupTileView, { productsContent: getProductsContent(), productsContentLoading: offersByIdsQuery.isLoading, countriesContent: getCountriesContent(), ...props }));
};
