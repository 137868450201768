import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '@fourthwall/components';
import { DragIcon, PencilIcon, TrashIcon } from '@fourthwall/icons';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { AutoComplete } from '../../../../fields/AutoComplete';
import { SocialLink } from '../../../../fields/SocialLink/SocialLink';
import { getSocialIcon } from '../../../../utils/socials';
import { SearchOption } from '../SearchOption/SearchOption';
import * as S from './styled';
export const SocialLinkInput = ({ id, link, search, onSocialLinkEdit, onSocialLinkRemove, onSocialLinkSearch, onValueChange, }) => {
    const { setFieldValue, getFieldProps } = useFormikContext();
    const { attributes, listeners, setNodeRef, isDragging, transform } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
    };
    const getSearchOptions = (socialName) => {
        if (socialName !== search.socialName)
            return;
        return search.options?.map(({ id, name, title, description, image }) => ({
            id,
            value: name,
            content: React.createElement(SearchOption, { title: title, description: description, image: image }),
        }));
    };
    const handleAutocompleteSettingUpdate = (id, socialName, newValue) => {
        const selectedOption = search.options?.find((option) => option.id === newValue);
        setFieldValue(id, selectedOption?.name || '');
        onValueChange(socialName, selectedOption || '');
    };
    const renderLinkAppend = (link) => (React.createElement(S.SocialLinkHoverContent, null,
        !link.dragDisabled && (React.createElement(S.DragHandle, { ...attributes, ...listeners },
            React.createElement(Icon, { component: DragIcon, height: 12 }))),
        link.editUrl && (React.createElement(S.SocialLinkIcon, { onClick: () => onSocialLinkEdit(link?.editUrl) },
            React.createElement(Icon, { component: PencilIcon, height: 12 }))),
        !link.disabled && (React.createElement(S.SocialLinkIcon, { onClick: () => onSocialLinkRemove(link.name) },
            React.createElement(Icon, { component: TrashIcon, height: 12 })))));
    if (link.searchable) {
        return (React.createElement(S.SocialLinkWrapper, { ref: link.dragDisabled ? undefined : setNodeRef, style: style, key: link.name },
            React.createElement(AutoComplete, { data: link.data, disabled: link.disabled, icon: getSocialIcon(link.data.id), options: getSearchOptions(link.name) || [], isLoading: search.isLoading, append: renderLinkAppend(link), ...getFieldProps(link.data.id), onSearch: (search) => onSocialLinkSearch(link.name, search), onSettingUpdate: (id, newValue) => handleAutocompleteSettingUpdate(id, link.name, newValue) })));
    }
    return (React.createElement(S.SocialLinkWrapper, { ref: link.dragDisabled ? undefined : setNodeRef, style: style, key: link.name },
        React.createElement(SocialLink, { data: link.data, disabled: link.disabled, append: renderLinkAppend(link), ...getFieldProps(link.data.id), onSettingUpdate: (key, newValue) => onValueChange(link.name, newValue) })));
};
