import React from 'react';
import { CopyLinkInput } from '@components/common/CopyLinkInput';
import referAFriend from '../../assets/refer-a-friend.png';
import { useReferralConfigurationQuery } from './queries/useReferralConfigurationQuery';
import { getReferralDescription } from './utils/getReferralDescription';
import { ANALYTICS, MAX_REDEMPTIONS_TITLE_THRESHOLD } from './consts';
export const useReferralRecommendation = () => {
    const { referralConfigurationQuery } = useReferralConfigurationQuery(undefined, {
        onError: undefined,
    });
    const link = referralConfigurationQuery.data?.shortLink;
    const maxRedemptions = referralConfigurationQuery.data?.referralConfiguration.maxRedemptions || 0;
    const redeemedTimes = referralConfigurationQuery.data?.redeemedSummary.redeemedTimes || 0;
    const isMaxRedemptionsReached = maxRedemptions ? redeemedTimes >= maxRedemptions : false;
    const getTitle = () => {
        if (maxRedemptions && maxRedemptions <= MAX_REDEMPTIONS_TITLE_THRESHOLD) {
            return `Refer up to ${maxRedemptions} of your friends to Fourthwall`;
        }
        return 'Refer your friends to Fourthwall';
    };
    const getDescription = () => {
        if (!referralConfigurationQuery.data?.referralConfiguration)
            return '';
        return getReferralDescription(referralConfigurationQuery.data?.referralConfiguration);
    };
    return {
        referralConfigurationQuery,
        referralRecommendation: {
            enabled: !!link && !isMaxRedemptionsReached,
            suggestionProps: {
                type: 'props', // TODO: infer
                props: {
                    title: getTitle(),
                    description: getDescription(),
                    imageUrl: referAFriend,
                    analytics: ANALYTICS,
                    content: React.createElement(CopyLinkInput, { href: link, analytics: ANALYTICS }),
                },
            },
        },
    };
};
