import Yup from '@fourthwall/utils/lib/yup';
import { isPlatformSearchable, validation } from '@fourthwall/module-create-site';
export const getValidationSchema = (manualURLType) => Yup.object().shape({
    siteName: validation.siteName,
    creatorName: Yup.string().when('siteForSomeoneElse', {
        is: true,
        then: validation.creatorName,
    }),
    location: Yup.string().required(),
    platform: Yup.string().when('siteForSomeoneElse', {
        is: true,
        then: validation.platform,
    }),
    channelUrl: Yup.string().when(['siteForSomeoneElse', 'platform'], {
        is: (siteForSomeoneElse, platform) => siteForSomeoneElse && isPlatformSearchable(platform) && !manualURLType,
        then: Yup.string(),
        otherwise: validation.channelUrl(),
    }),
    searchedChannel: Yup.lazy((value) => {
        if (!value || typeof value === 'string') {
            return Yup.string().when(['siteForSomeoneElse', 'platform'], {
                is: (siteForSomeoneElse, platform) => siteForSomeoneElse && isPlatformSearchable(platform) && !manualURLType,
                then: Yup.string()
                    .required()
                    .test('searchedChannel', 'Please select on of the search results.', () => false),
                otherwise: Yup.string().nullable(),
            });
        }
        return Yup.object().shape({
            handle: Yup.string().required(),
            name: Yup.string().required(),
            imageUrl: Yup.string().required(),
            channelUrl: Yup.string().required(),
        });
    }),
});
