import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const ItemContainer = styled.div `
  padding: 16px 0;
  border-bottom: 1px solid ${Colors.GS400};
  display: flex;
`;
export const Content = styled.div `
  flex: 1;
`;
export const Title = styled.div `
  font-size: 16px;
  line-height: 150%;
`;
export const Subtitle = styled.div `
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${Colors.GS600};
`;
export const TagContainer = styled.div `
  display: flex;
  align-items: center;
`;
