import { PhotoTableCellContent } from '@components/common/PhotoTableCellContent';
import { StatusTag } from '@components/common/StatusTag';
import { formatCurrency } from '@fourthwall/utils/lib/currency';
import React from 'react';
export const isSelectedProductSelection = (productSelection) => productSelection.type === 'SELECTED_PRODUCTS';
export const isExcludedProductSelection = (productSelection) => productSelection.type === 'EXCLUDED_PRODUCTS';
export const mapDataToInitialValues = (data) => ({
    enabled: data.enabled,
    platform: data.platform || 'NOT_SELECTED',
    duration: data.durationSeconds || 60,
    productSelectionType: data.productSelection.type === 'EXCLUDED_PRODUCTS'
        ? 'ALL_PRODUCTS'
        : data.productSelection.type,
    includedProductsIds: isSelectedProductSelection(data.productSelection)
        ? data.productSelection.offerIds
        : [],
    excludedProductsIds: isExcludedProductSelection(data.productSelection)
        ? data.productSelection.offerIds
        : [],
    shippingChargeType: data.chargePolicy.shipping.type,
    shippingCharge: formatCurrency((data.chargePolicy.shipping.type === 'MAX_CREATOR' && data.chargePolicy.shipping.max) || 3),
});
export const mapOffersDataToSelectedProducts = (data) => {
    if (!data) {
        return [];
    }
    return Object.values(data).map((offer) => ({
        id: offer.id,
        name: offer.name,
        image: offer.images[0] ? offer.images[0].url : undefined,
        status: offer.state.status,
    }));
};
export const getProductSelection = (type, excludedOfferIds, includedOfferIds) => {
    if (type === 'SELECTED_PRODUCTS') {
        return {
            type,
            offerIds: includedOfferIds,
        };
    }
    if (type === 'ALL_PRODUCTS' && !!excludedOfferIds.length) {
        return {
            type: 'EXCLUDED_PRODUCTS',
            offerIds: excludedOfferIds,
        };
    }
    return { type };
};
export const getChargePolicy = (type, max) => {
    return { shipping: type === 'MAX_CREATOR' ? { type, max: Number(max) } : { type } };
};
export const mapValuesToMutationPayload = (values) => ({
    enabled: values.enabled,
    durationSeconds: values.duration,
    productSelection: getProductSelection(values.productSelectionType, values.excludedProductsIds, values.includedProductsIds),
    chargePolicy: getChargePolicy(values.shippingChargeType, values.shippingCharge),
    platform: values.platform,
});
export const headers = [
    {
        id: 'name',
        label: undefined,
        render: (item) => (React.createElement(PhotoTableCellContent, { name: item.name, photo: item.image, photoSize: "large" })),
        width: 4,
    },
    {
        id: 'status',
        label: undefined,
        alignment: 'right',
        render: ({ status }) => React.createElement(StatusTag, { status: status }),
        width: 2,
    },
];
export const getError = (error) => {
    if (!error || !error.length)
        return;
    return typeof error === 'string' ? error : error[0];
};
