import { ApiBase } from '../ApiBase';
class OrderApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getCart = (cartId) => {
            return this.getUnsafe(`/cart/${cartId}`);
        };
        this.addToCart = (cartId, payload) => {
            return this.post(`/cart/${cartId}`, payload);
        };
        this.updateCart = (cartId, payload) => {
            return this.put(`/cart/${cartId}`, payload);
        };
        this.removeFromCart = (cartId, payload) => {
            return this.delete(`/cart/${cartId}`, payload);
        };
        this.getTaxEstimate = (payload) => {
            return this.post('/checkout/tax/estimate', payload);
        };
        this.createOrder = (payload) => {
            return this.post('/orders', payload);
        };
        this.getOrder = (orderId) => {
            return this.get(`/orders/${orderId}`);
        };
        this.getOrders = (params) => {
            return this.get('/orders', params);
        };
        this.buyOrder = (orderId) => {
            return this.put(`/orders/${orderId}/buy`);
        };
        this.getOrderCancellation = (orderId) => {
            return this.get(`/orders/${orderId}/cancellation`);
        };
        this.cancelOrder = (orderId, payload) => {
            return this.post(`/orders/${orderId}/cancel`, payload);
        };
        this.calculateCancelRefunds = (orderId, payload) => {
            return this.post(`/orders/${orderId}/pre-cancel/calculate-refunds`, payload);
        };
        this.replaceOrderItems = (orderId, payload) => {
            return this.post(`/orders/${orderId}/replace-items`, payload);
        };
        this.calculateItemsReplacements = (orderId, payload) => {
            return this.post(`/orders/${orderId}/replace-items/calculate-replacements`, payload);
        };
        this.getOrderRefund = (orderId) => {
            return this.get(`/orders/${orderId}/refund`);
        };
        this.getRefundEstimatedCosts = (orderId, params) => {
            return this.get(`/orders/${orderId}/refund/estimate-costs`, params);
        };
        this.refundOrder = (orderId, payload) => {
            return this.post(`/orders/${orderId}/refund`, payload);
        };
        this.updateShippingAddress = (orderId, payload) => {
            return this.put(`/orders/${orderId}/address`, payload);
        };
        this.updateEmail = ({ orderId, email }) => {
            return this.put(`/orders/${orderId}/email`, { email });
        };
        this.getTimeline = (orderId) => {
            return this.get(`/timelines/${orderId}`);
        };
        this.getFulfillmentsCSV = () => {
            return this.getBlob('/orders/fulfillments/csv');
        };
        this.setCheckoutEmail = (cartId, email) => {
            return this.post(`/checkout/${cartId}`, { email });
        };
        this.getDownloadOrdersLink = () => {
            return this.getLink('/orders/csv');
        };
        this.updateOrderSupporterAddress = (pathParams, queryParams, payload) => {
            return this.put(`/orders/${pathParams.orderId}/supporter/address`, payload, queryParams);
        };
    }
}
export const OrderApiClient = new OrderApiClientModel();
