import React from 'react';
import { TickIcon } from '@fourthwall/icons';
import { Colors } from '../../colors';
import * as S from './Swatch.styled';
import isColorBright from './utils/isColorBright';
export const Swatch = ({ variant, fill, color, size, active, disabled = false, onClick, onMouseEnter, }) => {
    const isBright = isColorBright(fill);
    const detectedColor = isBright ? Colors.GS1000 : Colors.GS100;
    return (React.createElement(S.Swatch, { variant: variant, size: size, active: active, disabled: disabled, style: {
            color: color ?? detectedColor,
            backgroundColor: fill,
            borderColor: isBright ? Colors.GS500 : fill,
        }, onClick: onClick, onMouseEnter: onMouseEnter },
        disabled && React.createElement(S.CrossOut, null),
        variant === 'checkable' && active && React.createElement(TickIcon, { width: 12 })));
};
