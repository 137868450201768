import React from 'react';
import { BaseContent, Tag } from '@fourthwall/components';
import * as S from './styled';
export const ConnectionsGrid = ({ items }) => (React.createElement(S.Container, null, items.map((item) => (React.createElement(S.ItemContainer, { to: item.to },
    React.createElement(S.ItemThumbnail, { src: item.thumbnail, alt: `${item.title} logo` }),
    React.createElement(S.ItemTitle, null, item.title),
    React.createElement(S.ItemDescription, null,
        React.createElement(BaseContent, { size: "small", appearance: "secondary" }, item.description)),
    item.tag && (React.createElement(S.ItemTagContainer, null,
        React.createElement(Tag, { ...item.tag }))))))));
