import { useTiersQuery } from './useTiersQuery';
export const useTiers = () => {
    const { tiersQuery } = useTiersQuery();
    const getTiers = () => {
        if (!tiersQuery.data?.tiers)
            return null;
        return tiersQuery.data?.tiers
            .map((tier) => {
            return {
                id: tier.id,
                title: tier.name,
                description: tier.description,
                position: tier.position,
                status: tier.status,
                image: tier.image?.url,
                thumbnail: tier.image?.thumbnailUrl,
                membersCount: tier.statistics.membersCount,
                allowPublishToggle: !tier.mobileWlaEnabled,
                price: tier.variants.monthly.amount / 100,
                discount: !!(tier.discount?.percentOff.monthly || tier.discount?.percentOff.annual),
                monthDiscount: tier.discount?.percentOff.monthly,
                yearDiscount: tier.discount?.percentOff.annual,
                timeLimit: !!(tier.discount?.timeLimited?.startDate || tier.discount?.timeLimited?.endDate),
                startDate: tier.discount?.timeLimited?.startDate,
                endDate: tier.discount?.timeLimited?.endDate,
            };
        })
            .sort((a, b) => a.position - b.position);
    };
    return {
        tiersQuery,
        hasAnyTier: tiersQuery.data ? tiersQuery.data.tiers.length > 0 : false,
        tiers: getTiers(),
    };
};
