import { useCurrentShopQuery } from '@modules/Dashboard/hooks/useCurrentShopQuery';
import { useTagManager } from '@hooks';
import logoSrc from '../../../assets/signets/instagram.svg';
import { useOAuthDialog } from '../../../hooks/useOAuthDialog';
import { useCreateInstagramIntegrationMutation } from './useCreateInstagramIntegrationMutation';
import { useInstagramIntegrationQuery } from './useInstagramIntegrationQuery';
import { useRemoveInstagramIntegrationMutation } from './useRemoveInstagramIntegrationMutation';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
export const useInstagram = () => {
    const { currentShopQuery } = useCurrentShopQuery();
    const { createInstagramIntegrationMutation } = useCreateInstagramIntegrationMutation();
    const { removeInstagramIntegrationMutation } = useRemoveInstagramIntegrationMutation();
    const { instagramIntegrationQuery } = useInstagramIntegrationQuery([currentShopQuery.data?.id], {
        enabled: !!currentShopQuery.data?.id,
        onError: undefined,
    });
    const tagManager = useTagManager();
    const appConnectEvent = useAppConnectEvent('instagram_feed');
    const { openOAuthDialog } = useOAuthDialog({
        onComplete: () => {
            instagramIntegrationQuery.refetch();
            tagManager.trigger('app_connected', { app_name: 'instagram_feed' });
        },
    });
    const getStatus = () => {
        if (instagramIntegrationQuery.isError) {
            return 'NOT_CONNECTED';
        }
        if (instagramIntegrationQuery.data?.consumerToken) {
            return 'CONNECTED';
        }
    };
    const status = getStatus();
    const getConnectBar = () => {
        const connectBarBase = {
            logoSrc,
            description: 'Connect to your Instagram account',
            disabled: true,
        };
        if (status === 'CONNECTED') {
            const onDisconnect = async () => {
                await removeInstagramIntegrationMutation.mutateAsync([currentShopQuery.data?.id]);
                await instagramIntegrationQuery.refetch();
            };
            return {
                ...connectBarBase,
                disabled: false,
                onDisconnect,
            };
        }
        if (status === 'NOT_CONNECTED') {
            const onConnect = async () => {
                appConnectEvent.trigger();
                const integration = await createInstagramIntegrationMutation.mutateAsync([
                    currentShopQuery.data?.id,
                    { flow: 'postMessage' },
                ]);
                openOAuthDialog(integration.redirectTo);
            };
            return {
                ...connectBarBase,
                disabled: false,
                onConnect,
            };
        }
        return connectBarBase;
    };
    return {
        instagram: {
            connectBar: getConnectBar(),
            status,
        },
    };
};
