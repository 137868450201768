import { ButtonClean } from '@fourthwall/components';
import React, { Children, cloneElement, isValidElement, useState } from 'react';
import * as S from './styled';
export const ConcealedText = ({ hideLabel, showLabel, defaultHidden = true, hiddenColor = '#D9D9D9', children, }) => {
    const [isHidden, setIsHidden] = useState(defaultHidden);
    const wrapNode = (node) => {
        if (!node) {
            return node;
        }
        return React.createElement(S.Wrapper, null, node);
    };
    const mapChildren = (nodes) => {
        return Children.map(nodes, (node) => {
            if (!isValidElement(node)) {
                return wrapNode(node);
            }
            if (node.props.children) {
                return cloneElement(node, {
                    // @ts-ignore
                    children: mapChildren(node.props.children),
                });
            }
            return node;
        });
    };
    const toggleHidden = () => setIsHidden(!isHidden);
    const label = isHidden ? showLabel : hideLabel;
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Content, { isHidden: isHidden, hiddenColor: hiddenColor }, mapChildren(children)),
        React.createElement(ButtonClean, { key: label, onClick: toggleHidden, label: label })));
};
