import { Button, ButtonClean, ButtonGroup, DatePicker, Fieldset, isPastDate, Tag, useOutsideClick, } from '@fourthwall/components';
import { ArrowShortDownIcon } from '@fourthwall/icons';
import { useFormik } from '@fourthwall/utils/lib/formik';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import * as S from './ScheduleTag.styled';
export const ScheduleTag = ({ currentDate, onDateUpdate }) => {
    const [isOpen, setOpen] = useState(false);
    const containerRef = useRef(null);
    useOutsideClick(containerRef, () => {
        setOpen(false);
    });
    const toggleOpen = () => {
        setOpen((prevIsOpen) => !prevIsOpen);
    };
    const formik = useFormik({
        initialValues: {
            date: currentDate,
        },
        onSubmit: (values) => {
            const liveAt = currentDate === values.date ? dayjs(Date.now()).toISOString() : values.date;
            onDateUpdate(liveAt);
            setOpen(false);
        },
        enableReinitialize: true,
    });
    const getStartDate = (date) => dayjs(date).format('MMM D');
    const renderReference = ({ ref }) => {
        return (React.createElement("button", { ref: ref, type: "button", onClick: toggleOpen, "data-testid": "ScheduleTag.button" },
            React.createElement(Tag, { label: `Goes public ${getStartDate(currentDate)}`, appearance: "caution", size: "large", icon: ArrowShortDownIcon })));
    };
    const renderTooltip = ({ ref, style }) => (React.createElement("div", { ref: ref, style: { ...style, paddingTop: 8, zIndex: 5 } },
        React.createElement(S.FormContainer, null,
            React.createElement("form", { onSubmit: formik.handleSubmit, "data-testid": "ScheduleTag.form" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(DatePicker, { type: "single", date: formik.values.date, dateFormat: "MMM D, YYYY, h:mm A", startLabel: "Publish date & time", disableDates: isPastDate, onDateChange: (newEndDate) => {
                                formik.setFieldValue('date', newEndDate);
                            }, showTimeInputs: true }))),
                React.createElement(S.ButtonsContainer, null,
                    React.createElement(ButtonGroup, { justify: "right", align: "center", space: "medium" },
                        React.createElement(ButtonClean, { label: "Publish now", onClick: formik.submitForm }),
                        React.createElement(Button, { label: "Cancel", size: "small", onClick: () => {
                                formik.resetForm();
                                setOpen(false);
                            } }),
                        React.createElement(Button, { label: "Save", appearance: "primary", disabled: !formik.dirty || !formik.isValid, size: "small", onClick: formik.submitForm })))))));
    return (React.createElement(Manager, null,
        React.createElement("div", { ref: containerRef },
            React.createElement(Reference, null, renderReference),
            isOpen && React.createElement(Popper, { placement: "bottom-end" }, renderTooltip))));
};
