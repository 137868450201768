import React, { cloneElement, useState } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { HELPER_CLASS } from './consts';
import * as S from './SortableList.styled';
import { FooterListItem, SortableListItem } from './SortableListItem';
const SortableListContainer = SortableContainer(({ children, isDragged, listHasFooter, onRemove }) => (React.createElement(S.ItemWrapper, null, children.map((child, index) => cloneElement(child, { index, isDragged, listHasFooter, onRemove })))));
export const SortableList = ({ children, footer, onSortChange, onRemove, }) => {
    const [isDragged, setIsDragged] = useState(false);
    const handleSortStart = () => {
        setIsDragged(true);
    };
    const handleSortEnd = ({ oldIndex, newIndex }) => {
        setIsDragged(false);
        if (onSortChange) {
            onSortChange(oldIndex, newIndex);
        }
    };
    return (React.createElement(S.Container, null,
        React.createElement(SortableListContainer, { helperClass: HELPER_CLASS, isDragged: isDragged, useDragHandle: true, listHasFooter: !!footer, onSortStart: handleSortStart, onSortEnd: handleSortEnd, onRemove: onRemove }, children),
        footer));
};
SortableList.FooterItem = FooterListItem;
SortableList.Item = SortableListItem;
