import some from 'lodash-es/some';
import * as yup from 'yup';
import { MIN_POLL_OPTIONS, STREAMABLE_REGEX, VIMEO_LIVESTREAM_REGEX, VIMEO_REGEX, YOUTUBE_REGEX, } from '../../consts';
import { FormFields } from './types';
export const getValidationSchema = (validation) => {
    const pollLength = (shouldValidate) => {
        if (!shouldValidate)
            return;
        return {
            [FormFields.pollLength]: yup.object().when([FormFields.postType], (postType) => {
                if (postType === 'Poll') {
                    return yup
                        .object()
                        .required()
                        .test('pollLength', 'Poll must be at least one minute long.', (value) => {
                        return value && some(value, (property) => property !== '0' && property !== undefined);
                    });
                }
                return yup.mixed();
            }),
        };
    };
    const image = (shouldValidate) => {
        if (!shouldValidate)
            return;
        return {
            [FormFields.image]: yup.mixed().when([FormFields.postType], (postType) => {
                if (postType === 'Image') {
                    return yup.mixed().required();
                }
                return yup.mixed();
            }),
        };
    };
    const images = (shouldValidate) => {
        if (!shouldValidate)
            return;
        return {
            [FormFields.images]: yup.array().when([FormFields.postType], (postType) => {
                if (postType === 'Image') {
                    return yup.array().min(1, 'This field is required.').required();
                }
                return yup.array();
            }),
        };
    };
    return yup.object().shape({
        [FormFields.liveAt]: yup.date().when([FormFields.mode], (mode) => {
            if (mode === 'update' && validation?.scheduleStatus === 'Done') {
                return yup.date().max(new Date(), 'Please select date from the past');
            }
            return yup.date();
        }),
        [FormFields.access]: yup.string().required(),
        [FormFields.postType]: yup.string(),
        [FormFields.title]: yup.string().required().min(3),
        [FormFields.description]: yup
            .string()
            .max(100000)
            // Validate if the description has any text content
            .test('This field is required.', 'This field is required.', (value) => {
            if (!value)
                return true;
            const tmp = document.createElement('div');
            tmp.innerHTML = value || '';
            if (!tmp.textContent)
                return false;
            if (!tmp.textContent.trim())
                return false;
            return true;
        }),
        // [FormFields.images]: yup.array().when([FormFields.postType], (postType: PostType) => {
        //   if (postType === 'Image') {
        //     return yup.array().min(1, 'This field is required.').required()
        //   }
        //   return yup.array()
        // }),
        ...image(!validation?.multipleImages),
        ...images(validation?.multipleImages),
        [FormFields.videoType]: yup.string().required(),
        [FormFields.video]: yup
            .mixed()
            .when([FormFields.postType, FormFields.videoType], (postType, videoType) => {
            if (postType === 'Video' && videoType === 'upload') {
                return yup
                    .mixed()
                    .required()
                    .test('video', '', function () {
                    const access = this.parent[FormFields.access];
                    const postType = this.parent[FormFields.postType];
                    const videoType = this.parent[FormFields.videoType];
                    if (validation?.publicVideo) {
                        return true;
                    }
                    if (access === 'Public' && postType === 'Video' && videoType === 'upload') {
                        return false;
                    }
                    return true;
                });
            }
            return yup.mixed();
        }),
        [FormFields.videoThumbnail]: yup.mixed(),
        [FormFields.videoUrl]: yup
            .string()
            .when([FormFields.postType, FormFields.videoType], (postType, videoType) => {
            if ((postType === 'Video' || postType === 'VideoEmbed') && videoType === 'embed') {
                return yup
                    .string()
                    .required()
                    .test('videoUrl', 'This is not a valid YouTube, Vimeo or Streamable URL.', (value) => {
                    return !!(value?.match(YOUTUBE_REGEX) ||
                        value?.match(VIMEO_REGEX) ||
                        value?.match(VIMEO_LIVESTREAM_REGEX) ||
                        value?.match(STREAMABLE_REGEX));
                });
            }
            return yup.string();
        }),
        [FormFields.videoSeries]: yup.array().of(yup.object().shape({
            id: yup.number(),
        })),
        [FormFields.audio]: yup.mixed().when([FormFields.postType], (postType) => {
            if (postType === 'Audio') {
                return yup.mixed().required('Audio file is required.');
            }
            return yup.mixed();
        }),
        [FormFields.audioThumbnail]: yup.mixed().when([FormFields.postType], (postType) => {
            if (postType === 'Audio') {
                return yup.mixed().required('Audio thumbnail is required.');
            }
            return yup.mixed();
        }),
        [FormFields.pollOptions]: yup.array().when([FormFields.postType], (postType) => {
            if (postType === 'Poll') {
                return yup
                    .array()
                    .min(MIN_POLL_OPTIONS)
                    .required()
                    .of(yup.string().required().min(1).max(80));
            }
            return yup.array();
        }),
        [FormFields.livestreamUrl]: yup
            .string()
            .when([FormFields.postType, FormFields.livestreamType], (postType, livestreamType) => {
            if (postType === 'LiveStream' && livestreamType === 'YouTube Live') {
                return yup
                    .string()
                    .required()
                    .test('livestreamUrl', 'YouTube livestream URL is not in valid format.', (value) => {
                    if (value?.match(YOUTUBE_REGEX)) {
                        return true;
                    }
                    return false;
                });
            }
            if (postType === 'LiveStream' && livestreamType === 'Vimeo') {
                return yup
                    .string()
                    .required()
                    .test('livestreamUrl', 'Vimeo livestream URL is not in valid format.', (value) => {
                    if (value?.match(VIMEO_LIVESTREAM_REGEX)) {
                        return true;
                    }
                    return false;
                });
            }
            return yup.string();
        }),
        [FormFields.tierIds]: yup
            .object()
            .when([FormFields.access], (access) => {
            if (access === 'Plans') {
                return yup
                    .object()
                    .test('isEmpty', 'At least one selected tier is required.', (value) => Object.values(value).filter(Boolean).length > 0);
            }
            return yup.object();
        }),
        ...pollLength(validation?.pollLenghValidation),
    });
};
