import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Size = styled.div `
  text-align: left;
  max-width: 250px;

  p {
    color: ${Colors.GS600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.14px;

    padding-left: 36px;
  }

  & > * + * {
    margin-top: 4px;
  }
`;
export const MaxSize = styled.div `
  text-align: left;

  div {
    color: ${Colors.GS600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.14px;

    color: ${Colors.GS600};
  }

  & > * + * {
    margin-top: 4px;
  }
`;
export const Storage = styled.div `
  text-align: left;
`;
