import styled, { css } from 'styled-components';
import { Colors } from '../../../../../colors';
export const Container = styled.div `
  ${({ withSeparator }) => withSeparator &&
    css `
      padding-top: 8px;
      margin-top: 16px;
      border-top: 1px solid ${Colors.GS400};
    `}
`;
