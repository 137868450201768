import { ConfirmModal, useModalContext } from '@fourthwall/components';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../../../../utils/routing';
import { useDeleteSingleSeriesMutation } from './useDeleteSingleSeriesMutation';
export const useDeleteSingleSeries = () => {
    const modal = useModalContext();
    const navigate = useNavigate();
    const { deleteSingleSeriesMutation } = useDeleteSingleSeriesMutation();
    const deleteSingleSeries = (id) => modal.open(ConfirmModal, {
        title: 'Are you sure you want to delete this video series?',
        text: 'Deleting this video series will permanently remove it, so members can no longer view it. This action will not delete any of your videos. You cannot undo this.',
        confirmLabel: 'Delete series',
        confirmAppearance: 'destructive',
        onConfirm: () => {
            deleteSingleSeriesMutation.mutate({ id }, {
                onSuccess: () => {
                    navigate(routing.memberships.series.self);
                },
            });
        },
    });
    return {
        deleteSingleSeries,
        isLoading: deleteSingleSeriesMutation.isLoading,
    };
};
