import { useAuth } from '@fourthwall/auth';
import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import logoSrc from '../../../assets/signets/klaviyo.svg';
import { useAppConnectedEvent } from '../../../hooks/useAppConnectedEvent';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
import { useCreatorIntegrationsQuery } from '../../../queries/useCreatorIntegrationsQuery';
import { useDeleteKlaviyoMutation } from './useDeleteKlaviyoMutation';
const connectBarBase = {
    logoSrc,
    logoAlt: 'Klaviyo',
    description: 'Connect to your Klaviyo account',
    disabled: false,
};
export const useKlaviyo = () => {
    const { keycloak } = useAuth();
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const { open } = useModalContext();
    const { deleteKlaviyoMutation } = useDeleteKlaviyoMutation();
    const appConnectEvent = useAppConnectEvent('klaviyo');
    useAppConnectedEvent('klaviyo');
    const status = creatorIntegrationsQuery.data?.klaviyo.status;
    const getConnectBar = () => {
        // unknown
        if (!creatorIntegrationsQuery.data)
            return undefined;
        // not connected
        switch (status) {
            case 'NOT_CONNECTED': {
                const onConnect = () => {
                    appConnectEvent.trigger();
                    const url = CreatorIntegrationsApiClient.appAuthorizationUrl({
                        app: 'klaviyo',
                        token: keycloak?.token,
                    });
                    window.location.assign(url);
                };
                return {
                    ...connectBarBase,
                    onConnect,
                };
            }
            case 'CONNECTED': {
                const onDisconnect = () => {
                    open(ConfirmModal, {
                        title: 'Are you sure you want to disconnect from Klaviyo?',
                        confirmLabel: 'Yes, disconnect',
                        confirmAppearance: 'destructive',
                        onConfirm: () => {
                            const toastId = 'deleteKlaviyoMutation';
                            Toasts.notify('Disconnecting from Klaviyo...', {
                                toastId,
                                type: 'info',
                                autoClose: false,
                            });
                            deleteKlaviyoMutation.mutate(undefined, {
                                onSuccess: () => {
                                    creatorIntegrationsQuery.refetch();
                                    Toasts.notify('Disconnected from Klaviyo successfully.', {
                                        type: 'success',
                                    });
                                    Toasts.dismiss(toastId);
                                },
                            });
                        },
                    });
                };
                return {
                    ...connectBarBase,
                    description: '',
                    onDisconnect,
                };
            }
        }
    };
    return {
        klaviyo: {
            status,
            connectBar: getConnectBar(),
        },
    };
};
