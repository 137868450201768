import range from 'lodash-es/range';
export const flattenOffers = (offers) => {
    if (!offers) {
        return [];
    }
    return offers.reduce((accumulator, currentOffer) => {
        return [
            ...accumulator,
            ...range(currentOffer.quantity).map(() => ({ ...currentOffer, quantity: 1 })),
        ];
    }, []);
};
const addExistingItemQuantity = (items, existingItem) => items.map((item) => {
    const adjustment = item === existingItem ? 1 : 0;
    return {
        ...item,
        quantity: item.quantity + adjustment,
    };
});
export const aggregateOffers = (offers) => {
    if (!offers) {
        return [];
    }
    return offers.reduce((accumulator, offer) => {
        const existingOffer = accumulator.find((orderOffer) => orderOffer.variantId === offer.variantId);
        if (existingOffer) {
            return addExistingItemQuantity(accumulator, existingOffer);
        }
        return [...accumulator, offer];
    }, []);
};
export const getReplacementsPayload = (editableOffers) => {
    if (!editableOffers) {
        return [];
    }
    return editableOffers
        .filter((editableOffer) => editableOffer.original.variantId !== editableOffer.current.variantId)
        .map((editableOffer) => ({
        from: editableOffer.original.variantId,
        to: editableOffer.current.variantId,
        quantity: editableOffer.current.quantity,
    }))
        .reduce((accumulator, currentReplacement) => {
        const existingReplacement = accumulator.find((replacement) => replacement.from === currentReplacement.from && replacement.to === currentReplacement.to);
        if (existingReplacement) {
            return addExistingItemQuantity(accumulator, existingReplacement);
        }
        return [...accumulator, currentReplacement];
    }, []);
};
