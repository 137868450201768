import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  font-size: 16px;
  line-height: 150%;
  text-align: right;

  color: ${Colors.GS1000};
`;
export const OldPriceContainer = styled.div `
  color: ${Colors.GS600};

  text-decoration: line-through;
`;
