import { useMemo, useState, useEffect, useCallback } from 'react';
const mapTimeToValues = (countdownTime) => {
    if (countdownTime <= 0)
        return;
    // NOTE: https://blog.greenroots.info/how-to-create-a-countdown-timer-using-react-hooks
    const days = Math.floor(countdownTime / (1_000 * 60 * 60 * 24));
    const hours = Math.floor((countdownTime % (1_000 * 60 * 60 * 24)) / (1_000 * 60 * 60));
    const minutes = Math.floor((countdownTime % (1_000 * 60 * 60)) / (1_000 * 60));
    const seconds = Math.floor((countdownTime % (1_000 * 60)) / 1_000);
    return { days, hours, minutes, seconds };
};
export const useDateCountdown = (targetDate) => {
    const targetTime = useMemo(() => targetDate.getTime(), [targetDate]);
    const getTimeDiff = useCallback(() => targetTime - new Date().getTime(), [targetTime]);
    const [countdownTime, setCountdownTime] = useState(getTimeDiff());
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdownTime(getTimeDiff());
        }, 1_000);
        return () => clearInterval(interval);
    }, [targetTime, setCountdownTime, getTimeDiff]);
    return mapTimeToValues(countdownTime);
};
