import { CreatorMessagesApiClient } from '@fourthwall/services/lib/CreatorMessagesApiClient';
import { useQuery, useQueryClient } from '@tanstack/react-query';
export const useCreatorMessagesQuery = (page = 1) => {
    const queryKey = ['useCreatorMessagesQuery', page];
    const creatorMessagesQuery = useQuery({
        queryKey,
        queryFn: async () => {
            const unifiedPageNumber = page - 1;
            const { data } = await CreatorMessagesApiClient.getCreatorMessages({
                page: unifiedPageNumber,
            });
            return data;
        },
        keepPreviousData: true,
        staleTime: 60_000,
    });
    const queryClient = useQueryClient();
    return {
        creatorMessagesData: creatorMessagesQuery.data,
        creatorMessagesQuery,
        invalidateCreatorMessagesQuery: () => queryClient.invalidateQueries(queryKey),
    };
};
