import React from 'react';
import { List, useMediaQueryContext } from '@fourthwall/components';
import { getDefaultProductImage } from '@utils/products';
import { StatusTag } from '@components/common/StatusTag';
import { useLocation } from 'react-router-dom';
import { getProductLink } from '@modules/Dashboard/Collection/utils';
import { SelectedList } from '@components/common/SelectedList';
import { SelectedProductsListItem } from '../SelectedProductsListItem';
export const SelectedProducts = ({ offers, collectionName, selectedProductsIds, disabled, onRemove, onSortChange, onPositionChange, }) => {
    const media = useMediaQueryContext();
    const location = useLocation();
    const locationState = {
        from: { label: collectionName, location },
    };
    const getProduct = (productId) => (offers ? offers[productId] : null);
    if (!media.tablet) {
        return (React.createElement(List, { data: selectedProductsIds, topBorder: false, getItemLink: (productId) => getProductLink(productId), locationState: locationState, renderItem: (productId, index) => {
                const product = getProduct(productId);
                if (!product)
                    return null;
                return (React.createElement(SelectedProductsListItem, { product: product, disabled: disabled, onRemove: () => onRemove(index), onPositionChange: () => onPositionChange(index) }));
            } }));
    }
    const renderSelectedProducts = () => {
        return selectedProductsIds.map((productId) => {
            const product = getProduct(productId);
            if (!product)
                return null;
            return (React.createElement(SelectedList.Item, { id: product.id, name: product.name, link: getProductLink(product.id), photoUrl: getDefaultProductImage(product), rightContent: React.createElement("div", { style: { display: 'flex' } },
                    React.createElement(StatusTag, { status: product.state.status, isSoldOut: !product.state.available })) }));
        });
    };
    return (React.createElement(SelectedList, { disabled: disabled, onRemove: onRemove, onSortChange: onSortChange, onPositionChange: onPositionChange }, renderSelectedProducts()));
};
