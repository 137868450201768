import { getSupportersAmountLabel } from '../../../../utils/getSupportersAmountLabel';
export const getAllCollections = (collectionsData) => {
    if (!collectionsData) {
        return [];
    }
    return collectionsData.map((collection) => {
        return {
            id: collection.id,
            name: collection.name,
            imageUrl: collection.imageUrl,
            supportersTotal: collection.supportersTotal,
            supportersTotalLabel: getSupportersAmountLabel(collection.supportersTotal, 'collection'),
            productsTotal: collection.productsTotal,
        };
    });
};
