import React from 'react';
import { useLocation } from 'react-router-dom';
import kebabCase from 'lodash-es/kebabCase';
import { Arrows, BaseContent, Button, Tag, useSlider } from '@fourthwall/components';
import { SECTION_NAMES } from '@constants/sectionNames';
import * as S from './styled';
export const AppsSlider = ({ heading, apps }) => {
    const location = useLocation();
    const slider = useSlider();
    const locationState = { from: { label: SECTION_NAMES.home, location } };
    return (React.createElement(S.Container, { ...slider.getContainerProps() },
        React.createElement(S.Header, null,
            React.createElement(S.Heading, null, heading),
            React.createElement("div", null,
                React.createElement(Arrows, { size: "small" },
                    React.createElement(Arrows.Button, { direction: "prev", ...slider.getPrevButtonProps() }),
                    React.createElement(Arrows.Button, { direction: "next", ...slider.getNextButtonProps() })))),
        apps && (React.createElement(S.Slider, { ...slider.getSliderProps() }, apps.map((app) => (React.createElement(S.Slide, { key: kebabCase(app.name), ...slider.getSlideProps() },
            React.createElement(S.Thumbnail, { src: app.thumbnail, alt: `${app.name} thumbnail` }),
            React.createElement("div", null,
                React.createElement(S.Name, null, app.name),
                app.description && (React.createElement(BaseContent, { size: "small", appearance: "secondary" },
                    React.createElement(S.Description, null,
                        app.description,
                        app.docs ? (React.createElement(React.Fragment, null,
                            ' ',
                            React.createElement("a", { href: app.docs, target: "_blank", rel: "noreferrer" }, "Learn more"))) : null))),
                React.createElement(S.Buttons, null, app.to ? (React.createElement(Button, { label: app.connected ? 'Manage' : 'Connect', to: app.to, state: locationState, fullWidth: true, leftIcon: !app.connected && app.signet ? React.createElement(S.Signet, { src: app.signet }) : undefined })) : (React.createElement(Tag, { label: "coming soon" })))))))))));
};
