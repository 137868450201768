import { useQueryPage } from '@fourthwall/utils';
import isNil from 'lodash-es/isNil';
import { StringParam, BooleanParam, useQueryParams } from 'use-query-params';
export const useFilterProducts = () => {
    const { setPage } = useQueryPage();
    const [queryParams, setQueryParams] = useQueryParams({
        status: StringParam,
        availability: BooleanParam,
        archived: BooleanParam,
    });
    const resetFiltersParams = () => {
        setPage(undefined);
        setQueryParams({
            status: undefined,
            availability: undefined,
            archived: undefined,
        });
    };
    const setFiltersParams = (params) => {
        setPage(undefined);
        setQueryParams(params);
    };
    const hasFilterParams = Object.values(queryParams).some((param) => !isNil(param));
    return { filtersParams: queryParams, setFiltersParams, resetFiltersParams, hasFilterParams };
};
