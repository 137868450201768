import React from 'react';
import { toast } from 'react-toastify';
import { Alert } from '../../common/Alert';
const notify = (message, options) => {
    return toast(({ closeToast }) => (React.createElement(Alert, { message: message, type: options?.type || 'error', onClose: closeToast })), options);
};
const update = (toastId, options) => {
    toast.update(toastId, options);
};
const dismiss = (toastId) => {
    toast.dismiss(toastId);
};
const dismissAll = () => {
    toast.dismiss();
};
export { dismiss, dismissAll, notify, toast, update };
