import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 54px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${Colors.GS200};
  box-shadow: inset 0px 0px 0px 1px ${Colors.GS500};
`;
export const Image = styled.img `
  width: 100%;
  height: auto;
`;
export const PreviewIcon = styled.div `
  color: ${Colors.GS600};
  margin-left: 4px;
`;
