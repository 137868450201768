import { Accordion, Colors, Subsection, useModalContext } from '@fourthwall/components';
import { EmptyProductsApprovalStatus } from '@modules/Dashboard/Apps/components/EmptyProductsApprovalStatus';
import React from 'react';
import { productSyncStatuses } from '../../consts';
import { getSyncedProductsHeading } from '../../utils';
import { ErrorBanner } from '../ErrorBanner';
import { ProductsSyncStatusHeading } from '../ProductsSyncStatusHeading';
import { ProductsSyncStatusModal } from '../ProductsSyncStatusModal';
export const SyncProducts = ({ products, warnings }) => {
    const { open } = useModalContext();
    const handleSeeDetails = (productSyncStatus) => {
        if (!products)
            return;
        open(ProductsSyncStatusModal, {
            title: getSyncedProductsHeading(productSyncStatus, products[productSyncStatus].length),
            products: products[productSyncStatus],
        });
    };
    const renderSyncedProductsList = () => {
        if (!products)
            return null;
        if (productSyncStatuses.every((productSyncStatus) => !products[productSyncStatus].length)) {
            return React.createElement("div", { style: { marginTop: 16, color: Colors.GS600 } }, "No products connected yet");
        }
        return (React.createElement("div", { style: { marginTop: 16 } },
            React.createElement(Accordion, null, productSyncStatuses.map((productSyncStatus) => (React.createElement(Accordion.Item, { heading: React.createElement(ProductsSyncStatusHeading, { status: productSyncStatus, label: getSyncedProductsHeading(productSyncStatus, products[productSyncStatus].length), onSeeDetails: products[productSyncStatus].length
                        ? () => handleSeeDetails(productSyncStatus)
                        : undefined }) }))))));
    };
    if (!products) {
        return React.createElement(EmptyProductsApprovalStatus, { appName: "TikTok" });
    }
    return (React.createElement(Subsection, { title: "Product sync status" },
        React.createElement(ErrorBanner, { enabled: warnings.synchronizationLimitReached, appearance: "warning" }, "You have reached maximum number of product to be synced for today, we will resume the update automatically tomorrow."),
        React.createElement("div", null, "Products that meet TikTok\u2019s requirements are automatically synced to your TikTok Shop."),
        renderSyncedProductsList()));
};
