import styled, { css } from 'styled-components';
import { mediaQueries, styledUtils } from '@fourthwall/components';
import { DASHBOARD_STYLED_CONSTS } from '../../consts';
export const Content = styled.div `
  overflow-x: auto;
  flex-shrink: 0;
  scroll-snap-type: x mandatory;

  ${({ fullWidth }) => fullWidth &&
    css `
      width: 100vw;
      position: relative;
      left: -${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL}px;
      padding: 0 ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL}px;
    `}

  ${({ hideScrollbar }) => hideScrollbar &&
    css `
      ${styledUtils.hideScrollbar};
    `}

  ${mediaQueries.tablet} {
    overflow-x: unset;
    width: auto;
    left: 0;
    padding: 0;
  }
`;
