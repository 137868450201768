import styled, { css } from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
`;
export const ContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
export const MainContent = styled.div `
  width: 100%;

  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS1000};

  ${({ bold }) => bold &&
    css `
      font-weight: 700;
    `};

  ${({ ellipsis }) => ellipsis &&
    css `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;
export const AdditionalContent = styled.div `
  width: 100%;

  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GS600};

  ${({ ellipsis }) => ellipsis &&
    css `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;
