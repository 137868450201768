import { Heading, Loader, ModalBox, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import React from 'react';
import { SectionItem } from '../SectionItem';
import * as S from './styled';
export const AddSectionModalView = ({ blockId, sections, onCreateLayoutSection }) => {
    const media = useMediaQueryContext();
    const { close } = useModalContext();
    const renderSectionItems = (sections) => (React.createElement(React.Fragment, null, sections.map((item) => (React.createElement(SectionItem, { key: item.sectionType, section: item, onButtonClick: () => onCreateLayoutSection(item.sectionType, blockId) })))));
    return (React.createElement(ModalBox, { maxWidth: sections?.length === 1 ? 600 : 896, disableScrolling: true, withoutPadding: true, onClose: close }, !sections ? (React.createElement(S.LoaderWrapper, null,
        React.createElement(Loader, { size: "large" }))) : (React.createElement(S.Container, null,
        React.createElement(S.TitleContainer, null,
            React.createElement(Heading, { type: media.tablet ? 'h6' : 'h7', weight: "semibold" }, "Add section")),
        React.createElement(S.Content, null,
            sections.length === 1 && (React.createElement(React.Fragment, null, sections.map(({ group, sections }) => (React.createElement("div", { key: group }, renderSectionItems(sections)))))),
            sections.length > 1 && (React.createElement(React.Fragment, null, sections.map(({ group, sections }) => (React.createElement("div", { key: group },
                React.createElement(S.SectionHeader, null, group),
                React.createElement(S.Wrapper, null, renderSectionItems(sections))))))))))));
};
