import { BespokeProductsApiClient } from '@fourthwall/services/lib/BespokeProductsApiClient';
import { useQuery } from '@tanstack/react-query';
const queryFn = async () => {
    const { data } = await BespokeProductsApiClient.getAll();
    return data;
};
export const useBespokeProductsQuery = (options) => {
    const bespokeProductsQuery = useQuery({ queryKey: ['useBespokeProductsQuery'], queryFn: () => queryFn(), ...options });
    return {
        bespokeProducts: bespokeProductsQuery.data,
        bespokeProductsQuery,
    };
};
