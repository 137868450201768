import compact from 'lodash-es/compact';
import React from 'react';
import { Button } from '../../common/Button';
import { ModalBox } from '../../partials/ModalBox';
export const ConfirmLeaveModal = ({ title, text, isLoading, cancelLabel = 'Cancel', discardLabel = 'Discard changes', saveLabel = 'Save changes', close, onDiscard, onSave, }) => {
    const handleCancelClick = () => {
        close();
    };
    const handleDiscardClick = () => {
        // NOTE: in order to allow opening different modals in onDiscard we have to call close before it
        close();
        if (onDiscard) {
            onDiscard();
        }
    };
    const handleSaveClick = () => {
        // NOTE: in order to allow opening different modals in onSave we have to call close before it
        close();
        if (onSave) {
            onSave();
        }
    };
    return (React.createElement(ModalBox, { title: title, buttons: compact([
            React.createElement(Button, { label: cancelLabel, appearance: "secondary", onClick: handleCancelClick }),
            onDiscard && (React.createElement(Button, { label: discardLabel, appearance: "destructive", disabled: isLoading, onClick: handleDiscardClick })),
            onSave && (React.createElement(Button, { label: saveLabel, appearance: "primary", loading: isLoading, onClick: handleSaveClick })),
        ]), onClose: close }, text));
};
