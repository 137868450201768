import React, { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { routing } from '@utils/routing';
import * as S from './styled';
export const DashboardMenuItem = ({ label, icon, to, children, onClick, }) => {
    const location = useLocation();
    const matched = useMemo(() => {
        return !!matchPath({ path: String(to), end: to === routing.home }, location.pathname);
    }, [location, to]);
    return (React.createElement(S.Container, { onClick: onClick },
        to ? (React.createElement(S.Link, { to: to, active: matched && !children },
            React.createElement(S.Wrapper, null,
                React.createElement(S.Label, null, label),
                icon))) : (React.createElement(S.Wrapper, null,
            React.createElement(S.Label, null, label),
            icon)),
        children));
};
