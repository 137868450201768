import React from 'react';
import { InputUI, useModalContext, withFormikSupport } from '@fourthwall/components';
import { ArrowShortDownIcon } from '@fourthwall/icons';
import { useThirdPartyLogisticsOptionsQuery } from '@modules/Dashboard/hooks/useThirdPartyLogisticsOptionsQuery';
import { getPrice } from '@fourthwall/utils';
import { PackageSizeModal } from './PackageSizeModal';
import { EXAMPLES_BY_SIZES, LABELS } from './labels';
import * as S from './PackageSizeInput.styled';
const getPackageSizes = (sizes) => {
    return sizes.map((size) => {
        return {
            size: size.size,
            examples: EXAMPLES_BY_SIZES[size.size],
            maxSize: {
                dimensions: size.maxDimensions,
                volume: `Up to ${size.maxVolume} cubic inches`,
            },
            storage: {
                feePerMonth: size.storageFee.value,
            },
            // 'Small ($0.05/month per item for storage)'
            inputLabel: `${LABELS[size.size]} (${getPrice(size.storageFee.value, { currency: size.storageFee.currency, minimumFractionDigits: 2 })}/month per item for storage)`,
        };
    });
};
const PackageSizeInput = React.memo(({ error, value, shippingConfig, onChange }) => {
    const { open, close } = useModalContext();
    const { thirdPartyLogisticsOptionsQuery } = useThirdPartyLogisticsOptionsQuery();
    if (!thirdPartyLogisticsOptionsQuery.data) {
        return null;
    }
    const { sizes } = thirdPartyLogisticsOptionsQuery.data;
    const openModalWithOptions = async () => {
        if (!shippingConfig?.shippingPackageSizeEditable) {
            return;
        }
        open(PackageSizeModal, {
            currentValue: value,
            sizesData: getPackageSizes(sizes),
            onClose: () => {
                close();
            },
            onConfirm: async (value) => {
                onChange(value);
            },
        });
    };
    const getInputValue = () => {
        if (shippingConfig.shippingPackageSize === 'CUSTOM' &&
            shippingConfig.shippingPackageStorageFee) {
            return `Custom (${getPrice(shippingConfig.shippingPackageStorageFee.value, {
                currency: shippingConfig.shippingPackageStorageFee.currency,
                minimumFractionDigits: 2,
            })}/month per item for storage)`;
        }
        return value ? (getPackageSizes(sizes).find((s) => s.size === value)?.inputLabel ?? '') : '';
    };
    return (React.createElement(S.PackageSizeInput, null,
        React.createElement(InputUI.Root, { fullWidth: true },
            React.createElement(InputUI.Box, { invalid: Boolean(error), disabled: !shippingConfig?.shippingPackageSizeEditable },
                React.createElement(InputUI.Container, { onClick: openModalWithOptions, style: {
                        cursor: !shippingConfig?.shippingPackageSizeEditable ? 'not-allowed' : 'pointer',
                    } },
                    React.createElement(InputUI.IconContainer, { style: { right: 15, top: 18 } },
                        React.createElement(ArrowShortDownIcon, null)),
                    React.createElement(InputUI.Input, { name: "shippingPackageSize", disabled: !shippingConfig?.shippingPackageSizeEditable, inputSize: "large", id: "shippingPackageSize__input", type: "text", value: getInputValue(), hasLabel: Boolean(getInputValue()), "aria-labelledby": "shippingPackageSize__label", readOnly: true }),
                    React.createElement(InputUI.Label, { htmlFor: "shippingPackageSize__input", size: "large", id: "shippingPackageSize__label", hasValue: Boolean(getInputValue()) }, "Product size")))),
        error ? React.createElement(InputUI.Error, null, error) : null));
});
export const PackageSizeInputFormik = withFormikSupport(PackageSizeInput);
