import { CalculateProfitModal, CalculateProfitPricing, useAccordionState, } from '@fourthwall/components';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useCustomizationQuery } from '../../../hooks/useCustomizationQuery';
import { DEFAULT_SOLD_VALUES, PRODUCT_TYPE_TITLE_COPY, SOLD_VALUE_DEBOUNCE_VALUE } from './consts';
import { useDraftPricingCalculationQuery } from './hooks/useDraftPricingCalculationQuery';
import { getDigitalCost, getEstimatedPaymentProcessingFees, getMOQPricingData, getOnDemandPricingData, getSoldValueError, getSuggestions, getUnitProfit, isSoldValueValid, } from './utils';
import { useOfferQuery } from '../../../queries/useOfferQuery';
import { getCosts, getOfferTypeUtil, getCostsPerSize, getSizesPerCost, } from '../../../store/product/utils';
export const CalculateProfit = ({ productId, // NOTE: Router useParams hook returns undefined in a modal.
initialPriceValue = 0, pricingType, sizePriceSuggestion, }) => {
    const { offerQuery } = useOfferQuery([productId]);
    const [includeFeesValue, setIncludeFeesValue] = useState(false);
    const [priceValue, setPriceValue] = useState(initialPriceValue.toString());
    const [soldValue, setSoldValue] = useState('');
    const [isSoldValueTouched, setIsSoldValueTouched] = useState(false);
    const [soldValueError, setSoldValueError] = useState(undefined);
    const [soldValueDebounced] = useDebounce(soldValue, SOLD_VALUE_DEBOUNCE_VALUE);
    const product = offerQuery.data;
    const productCosts = getCosts(offerQuery.data);
    const manufacturingService = offerQuery.data?.manufacturingService || 'FOURTHWALL_BESPOKE';
    const offerType = getOfferTypeUtil(manufacturingService);
    const costsPerSize = getCostsPerSize(offerQuery.data);
    const sizesPerCost = getSizesPerCost(productCosts, costsPerSize) || [];
    const { customization } = useCustomizationQuery(product?.customizationId);
    const minimumOrderQuantity = customization?.product.minimumOrdersNumber || 1;
    const numberOfUnits = [minimumOrderQuantity, ...DEFAULT_SOLD_VALUES, Number(soldValueDebounced)];
    const feesValue = getEstimatedPaymentProcessingFees(Number(priceValue));
    const queryEnabled = offerType === 'MOQ' &&
        !soldValueError &&
        (!isSoldValueTouched || isSoldValueValid(Number(soldValueDebounced), minimumOrderQuantity));
    const { draftPricingCalculation, draftPricingCalculationQuery: { isFetching }, } = useDraftPricingCalculationQuery(product?.customizationId, { numberOfUnits }, {
        keepPreviousData: true,
        enabled: queryEnabled,
    });
    const { activeState, setActiveState, toggle } = useAccordionState([], {
        allowZeroActive: true,
        allowMultipleActive: false,
    });
    useEffect(() => {
        if (!activeState.length && draftPricingCalculation?.pricing.length) {
            setActiveState(Array(draftPricingCalculation?.pricing.length).fill(false));
        }
    }, [draftPricingCalculation]);
    useEffect(() => {
        setSoldValueError(getSoldValueError(soldValueDebounced, minimumOrderQuantity));
    }, [soldValueDebounced]);
    const handleAccordionChange = (id) => toggle(id);
    const handleSoldValueChange = (value) => {
        if (!isSoldValueTouched) {
            setIsSoldValueTouched(true);
        }
        setSoldValue(value);
    };
    const handleSellingPriceBlur = () => {
        if (!priceValue.length) {
            setPriceValue('0');
        }
    };
    const handleToggleIncludeFeesValue = () => setIncludeFeesValue((prevState) => !prevState);
    const getOnDemandContent = () => (React.createElement(CalculateProfitPricing.OnDemandPricing, { includeFeesValue: includeFeesValue, data: getOnDemandPricingData(pricingType === 'EXPLICIT', includeFeesValue, Number(priceValue), sizesPerCost) }));
    const getMOQContent = () => (React.createElement(CalculateProfitPricing.MOQPricing, { isLoading: isFetching, accordionState: activeState, includeFeesValue: includeFeesValue, data: getMOQPricingData(pricingType === 'EXPLICIT', includeFeesValue, Number(priceValue), sizesPerCost, draftPricingCalculation?.pricing), soldValue: soldValue, soldValueError: soldValueError, onAccordionChange: handleAccordionChange, onSoldValueChange: handleSoldValueChange }));
    const getManualContent = () => {
        const cost = manufacturingService === 'FOURTHWALL_BESPOKE' && productCosts && productCosts[0] !== null
            ? productCosts[0]
            : undefined;
        return (React.createElement(CalculateProfitPricing.ManualPricing, { includeFeesValue: includeFeesValue, feesValue: feesValue, cost: cost, unitProfit: getUnitProfit(Number(priceValue), cost || 0, includeFeesValue) }));
    };
    const getDigitalContent = () => {
        const cost = getDigitalCost(Number(priceValue));
        return (React.createElement(CalculateProfitPricing.DigitalPricing, { includeFeesValue: includeFeesValue, feesValue: feesValue, cost: cost, unitProfit: getUnitProfit(Number(priceValue), cost, includeFeesValue) }));
    };
    const pricingContent = {
        ON_DEMAND: getOnDemandContent,
        MOQ: getMOQContent,
        MANUAL: getManualContent,
        DIGITAL: getDigitalContent,
    };
    return (React.createElement(CalculateProfitModal.Root, { title: `Profit for ${PRODUCT_TYPE_TITLE_COPY[offerType]} products`, content: React.createElement(React.Fragment, null,
            React.createElement(CalculateProfitModal.PriceInput, { priceValue: priceValue, onPriceChange: setPriceValue, onSellingPriceBlur: handleSellingPriceBlur }),
            pricingContent[offerType]()), includeFeesValue: includeFeesValue, suggestions: getSuggestions(offerType, sizePriceSuggestion), onToggleIncludeFeesValue: handleToggleIncludeFeesValue }));
};
