import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const Container = styled.ul `
  display: grid;
  gap: 16px;
  flex-direction: column;

  & + & {
    margin-top: 48px;
  }

  ${mediaQueries.tablet} {
    gap: 12px;
  }
`;
