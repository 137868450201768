import React from 'react';
import { Toasts, useModalContext } from '@fourthwall/components';
import { GiveawayTwitchRedoView } from './GiveawayTwitchRedoView';
import { useRedoGiveawayMutation } from './hooks/useRedoGiveawayMutation';
import { useTableItems } from './hooks/useTableItems';
import { useGiveawayConfigStreamQuery } from './queries';
export const GiveawayTwitchRedoContainer = ({ giveawayId }) => {
    const { giveawayConfigStreamQuery } = useGiveawayConfigStreamQuery();
    const { redoGiveawayMutation } = useRedoGiveawayMutation();
    const { close } = useModalContext();
    const { tableItems, selectedTableItemsIds, stats } = useTableItems(giveawayId);
    const isStreamLive = giveawayConfigStreamQuery.isSuccess && giveawayConfigStreamQuery.data.type === 'STREAM_LIVE';
    const hasTableItems = !!tableItems.length;
    const noTableItemSelected = hasTableItems && !selectedTableItemsIds.length;
    const getSubmitButtonLabel = () => {
        if (stats?.gifts.noWinner && stats?.gifts.noWinner > 0) {
            return `Redo ${stats?.gifts.noWinner} ${stats?.gifts.noWinner > 1 ? 'giveaways' : 'giveaway'}`;
        }
        return 'Redo giveaway';
    };
    const getSubmitButtonTooltip = () => {
        if (stats?.gifts.available === 0 && stats?.gifts.noWinner === 0) {
            return 'You cannot redo giveaways that have been already redeemed';
        }
        if (!isStreamLive) {
            return 'You must be live to redo a giveaway';
        }
        if (noTableItemSelected) {
            return 'You must select at least one gift';
        }
        return undefined;
    };
    const handleSubmitSuccess = (data) => {
        close();
        Toasts.notify(`Giveaway will start shortly at ${data.twitch.twitchChannel}. Wait for the results of a new draw.`, { type: 'info' });
    };
    const handleSubmitButtonClick = () => {
        if (!hasTableItems) {
            redoGiveawayMutation.mutate({
                params: { giveawayId },
                payload: { gifts: undefined },
            }, {
                onSuccess: handleSubmitSuccess,
            });
        }
        else {
            redoGiveawayMutation.mutate({
                params: { giveawayId },
                payload: { gifts: selectedTableItemsIds },
            }, {
                onSuccess: handleSubmitSuccess,
            });
        }
    };
    return (React.createElement(GiveawayTwitchRedoView, { tableItems: tableItems, submitButton: {
            label: getSubmitButtonLabel(),
            disabled: !isStreamLive || noTableItemSelected,
            loading: redoGiveawayMutation.isLoading,
            tooltip: getSubmitButtonTooltip(),
            onClick: handleSubmitButtonClick,
        }, close: close }));
};
