export const getInitialValues = (flatRates, currentShop, shippingProfiles) => {
    const shippingLocation = currentShop?.originAddress;
    const baseValues = {
        pricingMethod: 'Weighted',
        domesticFirst: '',
        domesticAdditional: '',
        internationalFirst: '',
        internationalAdditional: '',
        useAdditionalShippingFee: false,
        additionalShippingFee: '',
        shippingLocation,
        withShippingGroupsYouShip: shippingProfiles?.configState === 'ENABLED' || false,
    };
    if (!flatRates) {
        return baseValues;
    }
    if (flatRates.enabled) {
        return {
            ...baseValues,
            pricingMethod: 'Fixed',
            domesticFirst: flatRates.domesticShippingBasePrice.value.toString() || '',
            domesticAdditional: flatRates.domesticShippingEachAdditionalItemFee.value.toString() || '',
            internationalFirst: flatRates.internationalShippingBasePrice.value.toString() || '',
            internationalAdditional: flatRates.internationalShippingEachAdditionalItemFee.value.toString() || '',
        };
    }
    return baseValues;
};
export const getSaveFlatRatesPayload = (values) => {
    return {
        enabled: values.pricingMethod === 'Fixed',
        domesticShippingBasePrice: { value: parseFloat(values.domesticFirst) || 0, currency: 'USD' },
        domesticShippingEachAdditionalItemFee: {
            value: parseFloat(values.domesticAdditional) || 0,
            currency: 'USD',
        },
        internationalShippingBasePrice: {
            value: parseFloat(values.internationalFirst) || 0,
            currency: 'USD',
        },
        internationalShippingEachAdditionalItemFee: {
            value: parseFloat(values.internationalAdditional) || 0,
            currency: 'USD',
        },
    };
};
