import { HeadContent, Loader } from '@fourthwall/components';
import React from 'react';
import { DomainSuggestion } from './components/DomainSuggestion';
import { ExistingDomainTile } from './components/ExistingDomainTile';
import * as S from './Domain.styled';
export const DomainView = ({ dnsList, shopInternalDomain, shopPrimaryDomain, isLoading, isDomainRemovalRequested, isVerificationInProgress, isRetryConnectionInProgress, onDomainDelete, onRetryConnection, }) => {
    const renderContent = () => {
        if (isLoading) {
            return (React.createElement(S.LoaderContainer, null,
                React.createElement(Loader, { size: "large" })));
        }
        if (dnsList) {
            return (React.createElement(ExistingDomainTile, { domainName: dnsList.domain, status: dnsList.status, currentShopDomain: dnsList.areAllEntriesComplete ? dnsList.domain : shopPrimaryDomain, shopInternalDomain: shopInternalDomain, isDomainRemovalRequested: isDomainRemovalRequested, isVerificationInProgress: isVerificationInProgress, isRetryConnectionInProgress: isRetryConnectionInProgress, onDomainDelete: onDomainDelete, onRetryConnection: onRetryConnection }));
        }
        return React.createElement(DomainSuggestion, { size: "large" });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Domain \u2013 Settings" }),
        renderContent()));
};
