import React from 'react';
import { Link } from 'react-router-dom';
import { routing } from '../../../../../utils/routing';
export const MARKETING_EMAILS_FIELDS = [
    { name: 'creatorDigest' },
    { name: 'productUpdates' },
    { name: 'oneOffCampaigns' },
];
export const ACCOUNT_NOTIFICATIONS_FIELDS = [
    { name: 'offerVariantStatusUpdates' },
    { name: 'paymentMethodCharges' },
    { name: 'monthlyChargeLimitReached' },
    { name: 'blockedTransactions' },
    { name: 'twitchGiftingSummaries' },
];
export const FIELD_PROPS = {
    blockedTransactions: {
        label: 'Blocked transactions',
        description: (React.createElement(React.Fragment, null,
            "Notify me when a",
            ' ',
            React.createElement(Link, { to: routing.blockedTransactions, target: "_blank" }, "transaction is blocked"))),
    },
    creatorDigest: {
        label: 'Creator Digest',
        description: 'Our monthly newsletter with the latest updates, tips, and creator spotlights',
    },
    monthlyChargeLimitReached: {
        label: 'Monthly charge limit reached',
        description: (React.createElement(React.Fragment, null,
            "Notify me when my",
            ' ',
            React.createElement(Link, { to: routing.settings.billing.self, target: "_blank" }, "monthly charge limit"),
            ' ',
            "is reached")),
    },
    offerVariantStatusUpdates: {
        label: 'Fourthwall-manufactured products going out of stock',
        description: 'Notify me when a Fourthwall-manufactured product goes out of stock',
    },
    oneOffCampaigns: {
        label: 'One-off campaigns',
        description: 'Get notified about promotional offers and community updates',
    },
    paymentMethodCharges: {
        label: 'Payment method charges',
        description: (React.createElement(React.Fragment, null,
            "Notify me when my",
            ' ',
            React.createElement(Link, { to: routing.settings.billing.self, target: "_blank" }, "payment method"),
            ' ',
            "is charged")),
    },
    productUpdates: {
        label: 'Product updates',
        description: 'Learn about latest updates to the Fourthwall platform',
    },
    twitchGiftingSummaries: {
        label: 'Twitch gifting summaries',
        description: (React.createElement(React.Fragment, null,
            "Send me a summary of recent",
            ' ',
            React.createElement(Link, { to: routing.contributions.orders.giveaways.all, target: "_blank" }, "Twitch gifts"),
            ' ',
            "(including a clip of the giveaway)")),
    },
    newMembers: {
        label: 'New members',
        description: 'Notify me when new members join',
    },
    newPostComments: {
        label: 'New post comments',
        description: 'Notify me about new comments under my posts',
    },
    newDirectMessage: {
        label: 'New messages from members',
        description: 'Notify me when a member sends me a private message',
    },
    newDirectMessageWithTip: {
        label: 'Only notify me if the message includes a tip',
        description: '',
    },
    newPostCommentsReply: { label: 'Only replies to my comments', description: '' },
    messageUnlocks: {
        label: 'Message unlocks',
        description: 'Notify me when a message is unlocked',
    },
};
