import React from 'react';
import { BaseContent, Skeleton, ToolbarButton } from '@fourthwall/components';
import { CloseIcon, PencilIcon } from '@fourthwall/icons';
import * as S from './styled';
export const ShippingGroupTileView = ({ name, productsContent, productsContentLoading, countriesContent, onEdit, onDelete, }) => {
    const renderToolbarButton = (props) => React.createElement(ToolbarButton, { placement: "top", size: "extraSmall", iconSize: 12, ...props });
    return (React.createElement(S.Container, null,
        React.createElement("div", null,
            React.createElement(S.Label, null, name),
            React.createElement(BaseContent, null, productsContentLoading ? (React.createElement(Skeleton, null,
                React.createElement(Skeleton.Shape, { width: "50%", height: 24 }))) : (productsContent))),
        React.createElement("div", null,
            React.createElement(S.Label, null, "Limit shipping to"),
            React.createElement(BaseContent, null, countriesContent)),
        React.createElement(S.Actions, null,
            renderToolbarButton({ label: 'Edit', icon: PencilIcon, onClick: onEdit }),
            renderToolbarButton({ label: 'Remove', icon: CloseIcon, onClick: onDelete }))));
};
