import { useUploadImageMutation } from '../../../hooks/useUploadImageMutation';
import { getImageDirectUploadData } from '../../../utils/getImageDirectUploadData';
import { useConfirmSubtitlesResourceMutation } from './useConfirmSubtitlesResourceMutation';
import { useCreateSubtitlesResourceMutation } from './useCreateSubtitlesResourceMutation';
export const useCreateSubtitlesResource = () => {
    const { createSubtitlesResourceMutation } = useCreateSubtitlesResourceMutation();
    const { uploadImageMutation } = useUploadImageMutation();
    const { confirmSubtitlesResourceMutation } = useConfirmSubtitlesResourceMutation();
    const createSubtitlesResource = async (file, onError) => {
        let subtitlesResourceId = 0;
        try {
            const payload = await getImageDirectUploadData(file);
            const { uploadData, resourceId } = await createSubtitlesResourceMutation.mutateAsync([
                { ...payload },
            ]);
            await uploadImageMutation.mutateAsync({
                file,
                headers: uploadData.headers,
                signedId: uploadData.signedId,
                uploadUrl: uploadData.url,
            });
            await confirmSubtitlesResourceMutation.mutateAsync([
                resourceId,
                { signedId: uploadData.signedId },
            ]);
            subtitlesResourceId = resourceId;
        }
        catch {
            onError();
        }
        return subtitlesResourceId;
    };
    return {
        createSubtitlesResource,
    };
};
