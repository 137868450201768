import React from 'react';
import { EmptyState, List, Table, useMediaQueryContext } from '@fourthwall/components';
import { routing } from '@utils/routing';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { CollectionListItem } from '@modules/Dashboard/Collections/components/CollectionListItem';
import { TABLE_HEADERS } from './consts';
const getRowLink = (collection) => ({
    pathname: reverse(routing.products.collections.collection.self, {
        collectionId: collection.id,
    }),
    search: location.search,
});
export const CollectionsData = ({ data, isLoading }) => {
    const media = useMediaQueryContext();
    const emptyComponent = (React.createElement(EmptyState, { title: "You haven\u2019t created any collections yet", subtitle: "Group your products or create categories.", label: "Create new collection", variant: media.tablet ? 'primary' : 'secondary', to: routing.products.collections.create.self }));
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, emptyComponent: emptyComponent, renderItem: ({ name, offerIds, thumbnail, state, timeFrame }) => (React.createElement(CollectionListItem, { name: name, quantity: offerIds.length, photoUrl: thumbnail, status: state.status, timeFrame: timeFrame })), getItemLink: getRowLink }));
    }
    return (React.createElement(Table, { headers: TABLE_HEADERS, data: data, emptyComponent: emptyComponent, isLoading: isLoading, rowComponent: Link, getRowLink: getRowLink }));
};
