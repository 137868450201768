import { DigitalPricing } from './DigitalPricing';
import { ManualPricing } from './ManualPricing';
import { MOQPricing } from './MOQPricing';
import { OnDemandPricing } from './OnDemandPricing';
import { PerSizePricing } from './PerSizePricing';
export const CalculateProfitPricing = {
    OnDemandPricing,
    PerSizePricing,
    DigitalPricing,
    ManualPricing,
    MOQPricing,
};
