import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
export const Pagination = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: ${Colors.GS600};
`;
export const InputForm = styled.form `
  width: 50px;
  margin-right: 12px;
`;
export const IconContainer = styled.div(({ left, right, disabled }) => css `
    display: flex;
    padding: 10px;

    cursor: pointer;

    ${left &&
    css `
      margin-left: -10px;
      margin-right: 14px;
    `}

    ${right &&
    css `
      margin-left: 14px;
      margin-right: -10px;
    `}

  ${disabled &&
    css `
      color: ${Colors.GS400};
      cursor: default;
    `}
  `);
