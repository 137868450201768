import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const SummaryItem = styled.div `
  margin-bottom: 8px;

  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 1.5;

  > div:first-child {
    flex: 1;
  }
`;
export const SummaryItemTooltipLabelContainer = styled.div `
  display: flex;
  align-items: center;
`;
export const SummaryItemTooltipLabelTooltipContainer = styled.div `
  margin-left: 4px;
`;
export const SummaryDivider = styled.hr `
  margin: 12px 0;
  border-top: 1px solid ${Colors.GS500};
`;
export const SummaryFooter = styled.div `
  margin-top: ${8 - 4}px;

  font-size: 12px;
  line-height: 130%;
  text-align: right;
  color: ${Colors.GS600};
`;
