import { getImageDirectUploadData } from '../../../../utils/getImageDirectUploadData';
import { getCommonFieldsPayload } from './getCommonFieldsPayload';
export const getImagePostPayload = async (values) => {
    if (typeof values.image === 'object') {
        return {
            ...getCommonFieldsPayload(values),
            imageDirectUploadData: await getImageDirectUploadData(values.image),
        };
    }
    return {
        ...getCommonFieldsPayload(values),
    };
};
