import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;

  ${mediaQueries.tablet} {
    flex-direction: row;
  }
`;
export const ButtonWrapper = styled.div `
  ${mediaQueries.tablet} {
    width: 110px;
  }
`;
export const InputWrapper = styled.div `
  flex: 1;
`;
