import { ApiBase } from './ApiBase';
class FulfillmentsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.uploadCsv = (csv) => {
            return this.post('/fulfillments/csv', csv);
        };
        this.getUnfulfilledCount = () => {
            return this.get('/fulfillments/self-serve/unfulfilled/count');
        };
        this.pushToThirdParty = () => {
            return this.post('/fulfillments/self-serve/unfulfilled/push-to-third-party');
        };
    }
}
export const FulfillmentsApiClient = new FulfillmentsApiClientModel();
FulfillmentsApiClient.setMaxRequestsCount();
