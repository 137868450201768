import { Tag } from '@fourthwall/components';
import { getDaysLeft, getHoursLeft, isBeforeNow } from '@utils/date';
import { isTimeFrameFrom, isTimeFrameNone, isTimeFrameRange, isTimeFrameTo } from '@utils/timeframe';
import dayjs from 'dayjs';
import React from 'react';
import { STATUSES } from './consts';
export const StatusTag = ({ status, timeFrame, size = 'small', icon, hoverable = false, isSoldOut = false, }) => {
    const getStartDate = (date) => dayjs(date).format('MMM D');
    // eslint-disable-next-line react/no-unstable-nested-components
    const StartingTag = ({ startDate }) => {
        return React.createElement(Tag, { label: `Going public ${startDate}`, appearance: "caution", icon: icon, size: size });
    };
    // eslint-disable-next-line react/no-unstable-nested-components
    const EndingTag = ({ endDate }) => {
        const daysLeft = getDaysLeft(endDate);
        const hoursLeft = getHoursLeft(endDate);
        const hoursLabel = hoursLeft > 0 ? `${hoursLeft} hours left` : 'less than an hour left';
        const label = daysLeft > 0 ? `${daysLeft} days left` : hoursLabel;
        return React.createElement(Tag, { label: `Public - ${label}`, appearance: "success", icon: icon, size: size });
    };
    // eslint-disable-next-line react/no-unstable-nested-components
    const EndedTag = () => {
        return React.createElement(Tag, { label: "Ended", appearance: "neutral", icon: icon, size: size });
    };
    // eslint-disable-next-line react/no-unstable-nested-components
    const PublicTag = () => {
        return React.createElement(Tag, { label: "Public", appearance: "success", icon: icon, size: size });
    };
    // eslint-disable-next-line react/no-unstable-nested-components
    const HiddenTag = () => {
        return React.createElement(Tag, { label: "Hidden", appearance: "caution", icon: icon, size: size });
    };
    if (timeFrame) {
        if (isTimeFrameNone(timeFrame)) {
            if (status === 'HIDDEN') {
                return React.createElement(HiddenTag, null);
            }
            return React.createElement(PublicTag, null);
        }
        if (isTimeFrameTo(timeFrame)) {
            if (isBeforeNow(timeFrame.to)) {
                return React.createElement(EndedTag, null);
            }
            return React.createElement(EndingTag, { endDate: timeFrame.to });
        }
        if (isTimeFrameFrom(timeFrame)) {
            if (isBeforeNow(timeFrame.from)) {
                return React.createElement(PublicTag, null);
            }
            return React.createElement(StartingTag, { startDate: getStartDate(timeFrame.from) });
        }
        if (isTimeFrameRange(timeFrame)) {
            if (isBeforeNow(timeFrame.to)) {
                return React.createElement(EndedTag, null);
            }
            if (!isBeforeNow(timeFrame.from)) {
                return React.createElement(StartingTag, { startDate: getStartDate(timeFrame.from) });
            }
            return React.createElement(EndingTag, { endDate: timeFrame.to });
        }
    }
    return (React.createElement(Tag, { label: `${STATUSES[status]?.label || status}${isSoldOut ? ' - sold out' : ''}`, appearance: STATUSES[status]?.appearance, size: size, icon: icon, hoverable: hoverable }));
};
