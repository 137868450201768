import { HeadContent, LeavingGuard, SaveShortcutListener } from '@fourthwall/components';
import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDashboardFeatureFlags } from '../../../../../components/utils/DashboardFeatureFlags';
import { ConfirmBarContainer } from '../../../ConfirmBar/ConfirmBarContainer';
import { useCreateImageResources } from '../../hooks/useCreateImageResources';
import { useTagsQuery } from '../../hooks/useTagsQuery';
import { useTiers } from '../Perks/hooks/useTiers';
import { PostLayout } from './components/PostLayout';
import { getValidationSchema } from './consts';
import { useImageResources } from './hooks/useImageResources';
import { usePost } from './hooks/usePost';
import { usePostSeriesQuery } from './hooks/usePostSeriesQuery';
import { usePostUpdate } from './hooks/usePostUpdate';
import { AudioFields } from './modules/AudioFields';
import { ImageFields } from './modules/ImageFields';
import { CommonFields } from './modules/CommonFields';
import { ImagesFields } from './modules/ImagesFields';
import { LivestreamFields } from './modules/LivestreamFields';
import { ReadOnlyPollFields } from './modules/ReadOnlyPollFields';
import { SeriesFields } from './modules/SeriesFields';
import { VideoFields } from './modules/VideoFields';
import { PostViewSkeleton } from './PostSkeleton';
import { FormFields } from './types';
import { getSelectedTierIds } from './utils/getSelectedTierIds';
export const Post = () => {
    const { postId = '' } = useParams();
    const { post, postQuery } = usePost(postId);
    const { images, isLoading: imageResourcesIsLoading } = useImageResources(postId);
    const { tiers } = useTiers();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { tagsQuery } = useTagsQuery([{ limit: 9999 }]);
    const { postSeriesQuery } = usePostSeriesQuery(Number(postId), post?.type === 'Video' || post?.type === 'VideoEmbed');
    const { updatePost, isLoading, mediaUploadProgress } = usePostUpdate(postId);
    const { createImageResources, isLoading: createImageResourcesIsLoading } = useCreateImageResources();
    const isErrored = post?.statuses.resources === 'Errored';
    const disabled = isLoading || imageResourcesIsLoading || isErrored;
    if (!post || !tiers || !tagsQuery.data) {
        return React.createElement(PostViewSkeleton, null);
    }
    if (post.type === 'Video' && !postSeriesQuery.data) {
        return React.createElement(PostViewSkeleton, null);
    }
    if (post.type === 'Image' && !images) {
        return React.createElement(PostViewSkeleton, null);
    }
    const tiersIds = post.access.tiers?.map(({ id }) => id);
    const getTiers = getSelectedTierIds(tiersIds || [], tiers);
    const getTags = () => post.tags.map((postTag) => tagsQuery.data.tags.find((tag) => tag.id === postTag.id)?.name || '');
    const getPollOptions = () => {
        if ('options' in post) {
            return post.options?.map((option) => option.title || '');
        }
        return [];
    };
    const getImageUrl = () => {
        if ('image' in post && post.image && 'url' in post.image) {
            return post.image.url;
        }
    };
    const initialValues = {
        mode: 'update',
        postType: post.type,
        access: post.access.type,
        tierIds: getTiers,
        title: post.title,
        description: post.description,
        notifyMembers: post.notifications.type === 'Enabled',
        tags: getTags(),
        image: getImageUrl(),
        images: images || [],
        audio: post.type === 'Audio' ? post.mediaUrl : undefined,
        audioThumbnail: getImageUrl(),
        video: post.type === 'Video' ? post.mediaUrl : undefined,
        videoType: post.type === 'VideoEmbed' ? 'embed' : 'upload',
        videoThumbnail: getImageUrl(),
        videoSubtitles: post.type === 'Video' ? post.customSubtitlesResource || undefined : undefined,
        videoUrl: post.type === 'VideoEmbed' ? post.embed.url : undefined,
        videoSeries: post.type === 'Video' || post.type === 'VideoEmbed' ? postSeriesQuery.data?.series : [],
        pollOptions: getPollOptions(),
        livestreamType: 'YouTube Live',
        liveAt: post.liveAt,
    };
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: getValidationSchema({
            pollLenghValidation: false,
            multipleImages: dashboardFeatureFlags.membershipsMultipleImages.enabled,
            publicVideo: dashboardFeatureFlags.membershipsPublicVideo.enabled,
            scheduleStatus: post.statuses.schedule,
        }), enableReinitialize: true, onSubmit: updatePost }, ({ values, dirty, setFieldValue, submitForm, resetForm }) => {
        const handleImagesUpload = (files) => {
            createImageResources({
                files,
                onSuccess: (images) => setFieldValue(FormFields.images, [
                    ...values.images,
                    ...images.map((image) => ({ image: image.resizedUrl, id: image.id })),
                ]),
            });
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(HeadContent, { title: post.title }),
            post.type === 'Text' && (React.createElement(PostLayout, { disabled: disabled },
                React.createElement(CommonFields, { disabled: disabled }))),
            post.type === 'Image' && (React.createElement(PostLayout, { disabled: disabled },
                dashboardFeatureFlags.membershipsMultipleImages.enabled ? (React.createElement(ImagesFields, { imagesFieldName: FormFields.images, subsectionTitle: "Images", isUploading: createImageResourcesIsLoading, isLoading: isLoading, onImagesUpload: handleImagesUpload })) : (React.createElement(ImageFields, { imageFieldName: FormFields.image, image: values.image, subsectionTitle: "Image", isUploading: disabled })),
                React.createElement(CommonFields, { disabled: disabled }))),
            (post.type === 'Video' || post.type === 'VideoEmbed') && (React.createElement(PostLayout, { disabled: disabled },
                React.createElement(VideoFields, { type: values.videoType, typeFieldName: FormFields.videoType, video: values.video, videoFieldName: FormFields.video, thumbnail: values.videoThumbnail, thumbnailFieldName: FormFields.videoThumbnail, subtitles: values.videoSubtitles, subtitlesFieldName: FormFields.videoSubtitles, isThumbnailCustom: post.image?.type === 'Custom', url: values.videoUrl, urlFieldName: FormFields.videoUrl, subsectionTitle: "Video", uploadProgress: mediaUploadProgress, disabled: isErrored, isUploading: isLoading &&
                        (typeof values.video === 'object' ||
                            typeof values.videoThumbnail === 'object' ||
                            typeof values.videoSubtitles === 'object'), status: post.statuses.resources }),
                React.createElement(CommonFields, { disabled: disabled },
                    React.createElement(SeriesFields, { disabled: disabled })))),
            post.type === 'Audio' && (React.createElement(PostLayout, { disabled: disabled },
                React.createElement(AudioFields, { uploadProgress: mediaUploadProgress, isUploading: isLoading &&
                        (typeof values.audio === 'object' || typeof values.audioThumbnail === 'object'), status: post.statuses.resources }),
                React.createElement(CommonFields, { disabled: disabled }))),
            post.type === 'Poll' && (React.createElement(PostLayout, { disabled: disabled },
                React.createElement(CommonFields, { disabled: disabled }),
                React.createElement(ReadOnlyPollFields, { pollFinishDate: post.finishedAt, pollOptions: post.options, votesAmount: post.votesAmount }))),
            post.type === 'LiveStream' && (React.createElement(PostLayout, { disabled: isLoading },
                React.createElement(LivestreamFields, { disabled: isLoading }),
                React.createElement(CommonFields, { disabled: isLoading }))),
            React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
            React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isLoading || createImageResourcesIsLoading || postQuery.isFetching, onConfirm: submitForm, onCancel: resetForm }),
            React.createElement(LeavingGuard, { when: dirty || isLoading })));
    }));
};
