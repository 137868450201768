import { object, string } from 'yup';
import { Dashboard, PageLayout } from '@components/common';
import { DashboardBackButton } from '@components/utils';
import { SECTION_NAMES } from '@constants/sectionNames';
import { Accordion, Avatar, Banner, BaseContent, Button, Checkbox, EntityTableCell, Fieldset, HeadContent, List, Loader, Select, Subsection, Tag, useModalContext, } from '@fourthwall/components';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { routing } from '@utils/routing';
import { ConfirmBarContainer } from '@modules/Dashboard/ConfirmBar';
import { EmptyProductsApprovalStatus } from '@modules/Dashboard/Apps/components/EmptyProductsApprovalStatus';
import { signets } from '../../assets/signets';
import { ConnectBar } from '../../components/ConnectBar';
import { YouTubeVideoEntry } from '../../components/YouTubeVideoEntry';
import { AccordionHeading, ProductsStatusModal } from '../AppsYouTubeProductShelf';
import { CustomerSupportModal } from './components/CustomerSupportModal';
const CONFIGURE_FIELDS = {
    merchantPageId: 'merchantPageId',
};
const ConfigureSchema = object({
    merchantPageId: string().required(),
});
const SETTINGS_FIELDS = {
    status: 'status',
};
export const AppsMetaView = ({ statusTagProps, connectBarProps, configure, settings, customerSupport, products, }) => {
    const { open } = useModalContext();
    const renderConfigureContent = () => {
        if (!configure)
            return null;
        const { type } = configure;
        if (type === 'CONNECT_FIRST') {
            return React.createElement(BaseContent, null, "Connect your Meta account first.");
        }
        if (type === 'CREATE_SHOP') {
            return (React.createElement(React.Fragment, null,
                React.createElement(BaseContent, null, "Your Meta account doesn\u2019t have any shops created. Refresh this page after adding a Meta Shop to your account."),
                React.createElement("div", { style: { marginTop: 16, display: 'flex' } },
                    React.createElement(Button, { appearance: "primary", label: "Create a new Meta Shop", href: configure.url, target: "_blank" }))));
        }
        if (type === 'SELECT_SHOP') {
            return (React.createElement(Formik, { initialValues: configure.initialValues, validationSchema: ConfigureSchema, validateOnMount: true, enableReinitialize: true, onSubmit: configure.onSubmit }, (formik) => (React.createElement(Form, null,
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Field, { component: Select, name: CONFIGURE_FIELDS.merchantPageId, label: "Select your Merchant Page", suggestion: React.createElement(React.Fragment, null,
                                    "Select your Merchant Page or",
                                    ' ',
                                    React.createElement("a", { href: configure.url, target: "_blank", rel: "noreferrer" }, "create a new one"),
                                    "."), ...configure.selectProps }))),
                    configure.previewProps && (React.createElement("div", { style: { marginBlock: 20 } },
                        React.createElement(List, { data: [configure.previewProps], renderItem: (item) => (React.createElement("div", { style: { display: 'flex', gap: 20, alignItems: 'center' } },
                                React.createElement("div", null,
                                    React.createElement(EntityTableCell, { image: React.createElement(Avatar, { name: item.pageTitle, imageUrl: item.image }), top: item.pageTitle, bottom: React.createElement(React.Fragment, null,
                                            React.createElement("div", null, item.pageDescription),
                                            React.createElement(BaseContent, { size: "xsmall", appearance: "secondary" }, item.pageFooter)) })),
                                React.createElement("div", null,
                                    React.createElement(EntityTableCell, { top: item.commerceName, bottom: React.createElement(React.Fragment, null,
                                            React.createElement("div", null, item.commerceDescription),
                                            React.createElement(BaseContent, { size: "xsmall", appearance: "secondary" }, item.commerceFooter)) })))) }))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Button, { type: "submit", label: "Confirm selection", appearance: "primary", disabled: !formik.isValid, loading: formik.isSubmitting }))))))));
        }
    };
    const renderSettings = () => {
        if (!settings)
            return null;
        return (React.createElement(Formik, { initialValues: settings.initialValues, enableReinitialize: true, onSubmit: settings.onSubmit }, (formik) => (React.createElement(Subsection, { title: "Settings" },
            settings.banners.length > 0 && (React.createElement("div", { style: { display: 'grid', gap: 16, marginBlock: 16 } }, settings.banners.map((banner, index) => (React.createElement(Banner, { key: index, ...banner }))))),
            React.createElement(Form, null,
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Field, { component: Checkbox, name: SETTINGS_FIELDS.status, disabled: settings.disabled, label: "Enable sales on Instagram/Facebook shop", description: "Turning this on will allow fans to purchase your Fourthwall products directly from your Instagram or Facebook shop." }))))),
            React.createElement(ConfirmBarContainer, { isOpen: formik.dirty, isLoading: formik.isSubmitting, onConfirm: formik.submitForm, onCancel: formik.resetForm })))));
    };
    const renderCustomerSupport = () => {
        if (!customerSupport)
            return null;
        return (React.createElement(Subsection, { title: "Customer support" },
            React.createElement(BaseContent, null,
                React.createElement("p", null,
                    "In order to use Fourthwall customer support for Meta sales, you have to set it up inside Meta Commerce Manager.",
                    ' ',
                    React.createElement("a", { href: "https://help.fourthwall.com/hc/en-us/articles/25096229829147-Facebook-and-Instagram-Shopping-Set-Up-Guide", target: "_blank", rel: "noreferrer" }, "Learn more"))),
            React.createElement("div", { style: { marginTop: 16 } },
                React.createElement(Button, { label: "Set up customer support", onClick: () => open(CustomerSupportModal, customerSupport) }))));
    };
    const renderProducts = () => {
        if (!products)
            return null;
        if (products.type === 'LOADING') {
            return (React.createElement(Subsection, { title: "Product sync status" },
                React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                    React.createElement(Loader, { size: "large" }))));
        }
        if (products.type === 'EMPTY') {
            return React.createElement(EmptyProductsApprovalStatus, { appName: "Meta" });
        }
        const handleSeeDetails = (title, products) => {
            open(ProductsStatusModal, { title, products });
        };
        return (React.createElement(Subsection, { title: "Product sync status" },
            React.createElement(BaseContent, null,
                React.createElement("p", null, "Products that meet Meta's requirements are synced to your Instagram or Facebook shop. This process can take up to an hour.")),
            React.createElement("div", { style: { marginTop: 16 } },
                React.createElement(Accordion, null, products.groups.map((group) => (React.createElement(Accordion.Item, { key: group.type, heading: React.createElement(AccordionHeading, { status: group.type, label: group.label, onSeeDetails: () => {
                            if (group.details.type === 'ITEMS') {
                                return handleSeeDetails(group.label, group.details.items);
                            }
                            if (group.details.type === 'LINK') {
                                return window.open(group.details.url, '_blank');
                            }
                        } }) })))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: SECTION_NAMES.appMeta }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: SECTION_NAMES.appMeta, topContent: React.createElement(DashboardBackButton, { label: SECTION_NAMES.apps, to: routing.apps.self }) },
                React.createElement(Subsection, null,
                    React.createElement("div", { style: { display: 'grid', gap: 20 } },
                        statusTagProps && (React.createElement("div", null,
                            React.createElement(Tag, { ...statusTagProps, size: "large", display: "inline-flex" }))),
                        React.createElement("div", null,
                            React.createElement(BaseContent, null,
                                "Showcase and sell products directly to your fans on Instagram or Facebook.",
                                ' ',
                                React.createElement("a", { href: routing.external.helpCenter.meta, target: "_blank", rel: "noreferrer" }, "Learn more"))),
                        React.createElement("div", null,
                            React.createElement(Banner, { appearance: "warning" },
                                "You get paid directly on Meta. Fourthwall will charge your account for any costs related to the order.",
                                ' ',
                                React.createElement("a", { href: routing.external.helpCenter.meta, target: "_blank", rel: "noreferrer" }, "Learn more"))),
                        React.createElement(YouTubeVideoEntry, { title: "How to Set up Meta Shopping", description: "Learn how to enable shopping on your Instagram and Facebook profiles, and easily display your products for followers to purchase.", size: statusTagProps?.appearance === 'success' ? 'compact' : 'regular', videoId: "i-Ly3kDwsss" }),
                        connectBarProps && (React.createElement("div", null,
                            React.createElement(ConnectBar, { ...connectBarProps, logoSrc: signets.metaSignet }))))),
                configure && (React.createElement(Subsection, { title: "Configure your Instagram or Facebook Shop" }, renderConfigureContent())),
                renderSettings(),
                renderCustomerSupport(),
                renderProducts()))));
};
