var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { ApiBase } from '../ApiBase';
class CustomizationApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getDraft = (draftId) => {
            return this.get(`/customization/${draftId}`);
        };
        this.getDraftDetails = (draftId) => {
            return this.get(`/customization/${draftId}/details`);
        };
        this.updateDraftDetails = (payload) => {
            return this.post(`/customization/${payload.customizationId}/details`, payload);
        };
        this.getDraftPricingCalculation = (draftId, params) => {
            return this.get(`/customization/${draftId}/calculate-pricing`, params);
        };
        this.updateCustomizationMetafields = (draftId, payload) => {
            return this.put(`/customization/${draftId}/metafields`, payload);
        };
        this.createDraft = (productId, requestBody, shopId) => {
            return this.post(`/customization/product/${productId}`, requestBody, undefined, {
                headers: shopId ? { 'X-ShopId': shopId } : undefined,
            });
        };
        this.createOffer = (draftId, payload) => {
            return this.post(`/customization/${draftId}/create_offer`, payload);
        };
        this.updateOffer = (draftId, offerId, payload) => {
            return this.post(`/customization/${draftId}/update_offer/${offerId}`, payload);
        };
        this.deleteImages = (draftId, type) => {
            return this.delete(`/customization/${draftId}/images`, undefined, { type });
        };
        this.uploadManufacturerImage = (requestBody) => {
            return this.post(`/customization/save-manufacturer-generated-image`, requestBody);
        };
        // TODO: lacks type from auto generated schema
        this.deleteMediaImage = (imageId) => {
            return this.delete(`/customization/images/${imageId}`);
        };
        this.uploadMediaImage = (payload) => {
            return this.post('/customization/images', payload);
        };
        this.getMediaImages = () => {
            return this.get('/customization/images');
        };
        this.uploadOfferImage = (requestBody) => {
            return this.post(`/customization/save-offer-generated-image`, requestBody);
        };
        this.createRequestCustomization = (requestBody) => {
            return this.post(`/customization/request-customization`, requestBody);
        };
        this.createRequestCustomizationImages = (payload) => {
            return this.post(`/customization/request-customization/images/uploaded`, payload);
        };
        this.getRequestCustomizationCount = () => {
            return this.get('/customization/request-customization/count');
        };
        this.requestAssetUploadLink = (body) => {
            return this.post(`/customization/request-asset-upload-link`, body);
        };
        this.initiateDirectAssetUpload = (_a, _b) => __awaiter(this, [_a, _b], void 0, function* ({ customizationId, }, { file, type, }) {
            const contentType = file.type || 'application/octet-stream';
            const response = yield this.requestAssetUploadLink({
                type,
                contentType,
                customizationId,
                fileName: file.name,
                size: file.size,
            });
            const { uploadUrl } = response.data;
            yield axios.put(uploadUrl, file, {
                headers: {
                    'Content-Type': contentType,
                    'x-goog-content-length-range': `0,${file.size}`,
                },
            });
            return {
                uploadedFileUrl: response.data.fileUrl,
            };
        });
    }
}
export const CustomizationApiClient = new CustomizationApiClientModel();
CustomizationApiClient.setMaxRequestsCount();
