import React from 'react';
import { DateString, PriceString, ProfitString } from '@fourthwall/components';
import { transactionTypeTexts } from '../../constants';
import { TransactionIdLink } from './components/TransactionIdLink';
export const headers = [
    {
        id: 'date',
        label: 'Date',
        render: ({ transactionAt }) => (React.createElement("div", { style: { lineHeight: '24px' } },
            React.createElement(DateString, { date: transactionAt, format: "MM/DD/YYYY" }),
            React.createElement("br", null),
            React.createElement(DateString, { date: transactionAt, format: "h:mm A" }))),
    },
    {
        id: 'id',
        label: 'ID',
        render: (row) => React.createElement(TransactionIdLink, { transaction: row }),
    },
    {
        id: 'type',
        label: 'Transaction type',
        render: ({ type }) => transactionTypeTexts[type],
    },
    {
        id: 'profit',
        label: 'Profit/Cost',
        render: ({ value, type }) => {
            if (type === 'CREATOR_CHARGE') {
                return React.createElement(PriceString, { value: value.value });
            }
            return React.createElement(ProfitString, { value: value.value });
        },
    },
    {
        id: 'balance',
        label: (React.createElement(React.Fragment, null,
            "Balance after",
            React.createElement("br", null),
            "transaction")),
        render: ({ balance }) => React.createElement(PriceString, { value: balance.value }),
    },
];
