import { automatedThemeDefaults, Button, Calendar, Checkbox, ComponentsThemeProvider, getInternalTheme, Icon, useOutsideClick, } from '@fourthwall/components';
import { CalendarIcon, CalendarScheduledIcon } from '@fourthwall/icons';
import React, { useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import * as S from './ScheduleInput.styled';
export const ScheduleInput = ({ date, onDateChange }) => {
    const [isOpen, setOpen] = useState(false);
    const [isCalendarVisible, setIsCalendarVisible] = useState(!!date);
    const containerRef = useRef(null);
    useOutsideClick(containerRef, () => {
        setOpen(false);
    });
    const toggleOpen = () => {
        setOpen((prevIsOpen) => !prevIsOpen);
    };
    const renderReference = ({ ref }) => {
        if (date) {
            return (React.createElement("div", { ref: ref },
                React.createElement(Button, { size: "small", appearance: "primary", icon: React.createElement(Icon, { component: CalendarScheduledIcon, height: 19 }), onClick: toggleOpen })));
        }
        return (React.createElement("div", { ref: ref, "data-testid": "ScheduleInput.CalendarIcon" },
            React.createElement(Button, { size: "small", appearance: "primary", icon: React.createElement(Icon, { component: CalendarIcon, height: 19 }), onClick: toggleOpen })));
    };
    const renderCalendar = ({ ref, style }) => (React.createElement(ComponentsThemeProvider, { automatedTheme: getInternalTheme(automatedThemeDefaults.light), mode: "solid" },
        React.createElement("div", { ref: ref, style: { ...style, paddingTop: 8, zIndex: 1 } },
            React.createElement(S.CheckboxContainer, { "data-testid": "ScheduleInput.Checkbox" },
                React.createElement(Checkbox, { value: isCalendarVisible, label: "Set release date", description: "Publish post at specific time.", onValueChange: (value) => {
                        setIsCalendarVisible(value);
                        if (!value) {
                            setTimeout(() => {
                                onDateChange(undefined);
                            }, 0);
                        }
                    } })),
            isCalendarVisible && (React.createElement(Calendar, { type: "single", date: date, onDateChange: onDateChange, startTimeLabel: "Publish time" })))));
    return (React.createElement(Manager, null,
        React.createElement("div", { ref: containerRef },
            React.createElement(Reference, null, renderReference),
            isOpen && React.createElement(Popper, { placement: "bottom-end" }, renderCalendar))));
};
