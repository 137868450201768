import styled from 'styled-components';
export const Description = styled.div `
  margin-bottom: 16px;
`;
export const InputContainer = styled.div `
  margin-bottom: 16px;
`;
export const AppendContainer = styled.div `
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: -9px;
`;
export const AppendIcon = styled.div `
  margin-right: 20px;
  cursor: pointer;
`;
export const ButtonContainer = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const LoaderContainer = styled.div `
  margin-left: 10px;
`;
