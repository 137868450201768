import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAnimation, useDragControls } from 'framer-motion';
import { THEME_EDITOR_STYLED_CONSTS } from '../ThemeEditorLayout/consts';
import { useBottomSheetContext } from './contexts/BottomSheetContext';
import * as S from './styled';
export const BottomSheet = ({ children }) => {
    const controls = useDragControls();
    const animationControls = useAnimation();
    const bottomSheetRef = useRef(null);
    const bottomSheetOffset = useRef(0);
    const { position: currentPosition, setPosition: setCurrentPosition, shouldBounce, } = useBottomSheetContext();
    const bounceAnimation = useCallback((options) => {
        const bounceYValue = {
            top: -1,
            center: bottomSheetOffset.current / 2,
            bottom: bottomSheetOffset.current,
        }[currentPosition];
        animationControls.start({
            y: [bounceYValue, bounceYValue - 20, bounceYValue, bounceYValue - 20, bounceYValue],
            transition: { times: [0, 0.25, 0.5, 0.75, 1], duration: 0.75, delay: 0.5, ...options },
        });
    }, [animationControls, currentPosition]);
    const variants = useMemo(() => ({
        top: { y: -1 },
        center: {
            y: bottomSheetOffset.current / 2,
        },
        bottom: {
            y: bottomSheetOffset.current,
        },
    }), []);
    const setPosition = useCallback((position) => {
        animationControls.start(position);
        setCurrentPosition(position);
    }, [animationControls, setCurrentPosition]);
    const updateOffsetAndVariants = useCallback(() => {
        bottomSheetOffset.current =
            window.innerHeight -
                THEME_EDITOR_STYLED_CONSTS.NAVBAR_HEIGHT -
                THEME_EDITOR_STYLED_CONSTS.BOTTOMSHEET_BOTTOM_OFFSET;
        variants.top = { y: -1 };
        variants.center = { y: bottomSheetOffset.current / 2 };
        variants.bottom = { y: bottomSheetOffset.current };
    }, [variants]);
    useEffect(() => {
        updateOffsetAndVariants();
        const handleResize = () => {
            updateOffsetAndVariants();
            setPosition('bottom');
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setPosition, updateOffsetAndVariants]);
    useEffect(() => {
        if (bottomSheetRef.current) {
            animationControls.set(currentPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bottomSheetRef.current]);
    useEffect(() => {
        if (shouldBounce) {
            bounceAnimation(typeof shouldBounce === 'boolean' ? undefined : shouldBounce.options);
        }
    }, [shouldBounce, bounceAnimation]);
    const handleOnDragEnd = (_, info) => {
        const shouldClose = info.velocity.y > 100 || (info.velocity.y >= 0 && info.point.y > 100);
        setPosition(shouldClose ? 'bottom' : 'top');
    };
    const handleDraggableClick = () => {
        if (currentPosition === 'center') {
            setPosition('top');
            return;
        }
        setPosition('center');
    };
    return (React.createElement(S.Container, { ref: bottomSheetRef, key: bottomSheetRef.current?.clientHeight, drag: "y", initial: "bottom", onDragEnd: handleOnDragEnd, animate: animationControls, dragControls: controls, variants: variants, transition: {
            type: 'spring',
            damping: 40,
            stiffness: 400,
        }, whileTap: { boxShadow: '0px 0px 15px rgba(0,0,0,0.2)' }, dragListener: false, dragConstraints: {
            top: THEME_EDITOR_STYLED_CONSTS.NAVBAR_HEIGHT,
            bottom: bottomSheetOffset.current,
        } },
        React.createElement(S.Draggable, { extendedHandle: currentPosition === 'bottom', onPointerDown: (event) => controls.start(event), onClick: handleDraggableClick },
            React.createElement(S.Handle, null)),
        React.createElement(S.Content, { centered: currentPosition === 'center', bottomSheetHeight: bottomSheetOffset.current / 2, onPointerDownCapture: (e) => e.stopPropagation() }, children)));
};
