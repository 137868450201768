import { getDefaultPaymentMethod } from '../../utils';
export const getErrorBanner = (paymentMethods) => {
    const defaultPaymentMethod = getDefaultPaymentMethod(paymentMethods);
    if (!defaultPaymentMethod) {
        return 'There is no default payment method selected. Please choose a new default payment method.';
    }
    if (defaultPaymentMethod.expired) {
        return 'Your default payment method is expired. Please choose a new default payment method. ';
    }
    if (defaultPaymentMethod.invalid) {
        return 'Your default payment method is invalid. Please choose a new default payment method.';
    }
    return undefined;
};
