import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
const getRadioBorderActiveColor = () => getAutomatedStyleProp('paleTextColor', Colors.GS600);
const getOutlineColor = ({ theme }) => {
    if (theme.automated?.styles?.colorPrimary) {
        return tinycolor(theme.automated?.styles.colorPrimary).setAlpha(0.4).toString();
    }
    return tinycolor(Colors.B500).setAlpha(0.4).toString();
};
export const Container = styled.div `
  display: block;
  width: 24px;
  height: 24px;

  position: relative;
`;
export const Input = styled.input `
  clip: rect(1px, 1px, 1px, 1px);

  position: absolute;
`;
export const Radio = styled.div `
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${getAutomatedStyleProp('paleBackground', Colors.GS500)};
  border-radius: 50%;

  background-color: ${getAutomatedStyleProp('paleBackground', Colors.GS0)};

  position: relative;

  &:after {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    visibility: hidden;
  }

  label:hover & {
    border-color: ${getRadioBorderActiveColor};
  }

  input:focus-visible + & {
    outline: 2px solid ${getOutlineColor};
    outline-offset: 1px;
    transition: 'outline-offset 0s, outline 0s';
  }

  input:active + & {
    border-color: ${getRadioBorderActiveColor};

    &:after {
      background: ${getRadioBorderActiveColor};
      visibility: visible;
    }
  }

  input:checked + & {
    border-color: ${getAutomatedStyleProp('colorPrimary', Colors.B500)};
    background-color: ${getAutomatedStyleProp('colorPrimary', Colors.B500)};

    &:after {
      background: ${getAutomatedStyleProp('colorOnPrimary', Colors.GS0)};

      visibility: visible;
    }
  }

  input:disabled + & {
    cursor: not-allowed;
    background-color: ${Colors.GS400};
    border-color: ${Colors.GS500};
  }

  input:checked:disabled + & {
    background-color: ${Colors.GS400};
    border-color: ${Colors.GS500};

    &:after {
      background: ${Colors.GS500};
    }
  }
`;
