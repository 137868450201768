import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
export const EVENTS = {
    onLocationChange: 'webview:on-location-change',
    onAuthSuccess: 'webview:on-auth-success',
};
export const WebviewEventsBusContext = React.createContext(undefined);
export const WebviewEventsBusProvider = ({ children }) => {
    const location = useLocation();
    const dispatchEvent = (event, payload) => {
        document.dispatchEvent(new CustomEvent(EVENTS[event], { detail: payload }));
    };
    useEffect(() => {
        dispatchEvent('onLocationChange', location);
    }, [location]);
    const value = useMemo(() => ({
        events: EVENTS,
        dispatchEvent,
    }), []);
    return (React.createElement(WebviewEventsBusContext.Provider, { value: value }, children));
};
export function useWebviewEventsBus() {
    const context = useContext(WebviewEventsBusContext);
    if (context === undefined) {
        throw new Error('useWebviewEventsBus must be used within a WebviewEventsBusProvider');
    }
    return context;
}
