import styled from 'styled-components';
import { Colors } from '../../../colors';
export const Container = styled.div `
  margin-top: 8px;
`;
export const TabsListContainer = styled.div `
  width: 100%;
  overflow-x: auto;
`;
export const Separator = styled.div `
  width: 100%;
  height: 1px;
  background-color: ${Colors.GS400};
`;
