import { ConfirmModal, useModalContext } from '@fourthwall/components';
import { useTagManager } from '@hooks';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
import { useCreatorIntegrationsQuery } from '../../../queries/useCreatorIntegrationsQuery';
import { useDeleteLayloConfigurationMutation } from './useDeleteLayloConfigurationMutation';
import { useUpdateLayloConfigurationMutation } from './useUpdateLayloConfigurationMutation';
export const useLaylo = () => {
    const { open } = useModalContext();
    const { creatorIntegrationsQuery, invalidateCreatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const { updateLayloConfigurationMutation } = useUpdateLayloConfigurationMutation();
    const { deleteLayloConfigurationMutation } = useDeleteLayloConfigurationMutation();
    const tagManager = useTagManager();
    const appConnectEvent = useAppConnectEvent('laylo');
    const handleSettingsSubmit = async (values) => {
        appConnectEvent.trigger();
        return updateLayloConfigurationMutation.mutateAsync([values], {
            onSuccess: () => {
                invalidateCreatorIntegrationsQuery();
                tagManager.trigger('app_connected', { app_name: 'laylo' });
            },
        });
    };
    const handleDisconnect = () => {
        open(ConfirmModal, {
            title: 'Are you sure you want to disconnect your Laylo account?',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                deleteLayloConfigurationMutation.mutate([], {
                    onSuccess: () => {
                        invalidateCreatorIntegrationsQuery();
                    },
                });
            },
        });
    };
    return {
        laylo: {
            connection: {
                initialValues: {
                    apiKey: '',
                },
                onSubmit: handleSettingsSubmit,
            },
            status: creatorIntegrationsQuery.data?.laylo?.status,
            isLoading: creatorIntegrationsQuery.isLoading,
            onDisconnect: handleDisconnect,
        },
    };
};
