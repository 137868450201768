import isUndefined from 'lodash-es/isUndefined';
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { SortableListItemToolbar } from '../SortableListItemToolbar';
import * as S from './SortableListItem.styled';
const Element = SortableElement(({ children, isDragged, onRemove }) => (React.createElement(S.Wrapper, { isDragged: isDragged },
    children,
    React.createElement(S.ToolbarWrapper, null,
        React.createElement(SortableListItemToolbar, { onRemove: onRemove })))));
export const FooterListItem = ({ children }) => (React.createElement(S.Container, { isLastSection: true, "data-testid": "SortableListItem.Footer" },
    React.createElement(S.Wrapper, null, children)));
export const SortableListItem = ({ index, isDragged, listHasFooter, children, onRemove, }) => {
    if (isUndefined(index) || isUndefined(isDragged))
        return null;
    const handleRemove = () => {
        if (onRemove) {
            onRemove(index);
        }
    };
    return (React.createElement(S.Container, { isLastSection: !listHasFooter, "data-testid": "SortableListItem" },
        React.createElement(S.Placeholder, null),
        React.createElement(Element, { index: index, isDragged: isDragged, onRemove: handleRemove }, children)));
};
