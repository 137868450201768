import React from 'react';
import { ConfirmModal, PositionChangeModal, Toasts, useModalContext, usePagination, } from '@fourthwall/components';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { reverse } from 'named-urls';
import { useQueryClient } from '@tanstack/react-query';
import { pluralize } from '@fourthwall/utils/lib/string';
import { useDeleteOfferMutation } from '@mutations';
import { useProducts } from './hooks/useProducts/useProducts';
import { useUpdateCollectionOrderMutation } from './hooks/useUpdateCollectionOrderMutation';
import { PER_PAGE } from './const';
import { getCurrentProductPositionIndex, getIdsOfAdjacentElements, getUpdatedProductPositionIndex, } from './utils';
import { useOffersDuplicateMutation } from '../hooks/useOffersDuplicateMutation';
import { routing } from '../../../utils/routing';
import { useAdditionalProducts } from './hooks/useAdditionalProducts';
import { ProductsView } from './ProductsView';
import { useStatusMutation } from '../Product/hooks/useStatusMutation';
import { ToggleStatusModal } from './components/ToggleStatusModal/ToggleStatusModal';
import { ChangeToAnotherProductModal } from '../MigrateOffer/modules/ChangeToAnotherProductModal/ChangeToAnotherProductModal';
export const ProductsContainer = () => {
    const queryClient = useQueryClient();
    const { open, close } = useModalContext();
    const [currentPage, setCurrentPage] = usePagination();
    const { products, page, isLoading, refetchProducts } = useProducts();
    const { updateCollectionOrderMutation } = useUpdateCollectionOrderMutation();
    const { duplicateOfferMutation } = useOffersDuplicateMutation();
    const { deleteOfferMutation } = useDeleteOfferMutation();
    const { statusMutation } = useStatusMutation();
    const navigate = useNavigate();
    useAdditionalProducts({
        onCreated: ({ message }) => {
            Toasts.notify(message, { type: 'info' });
        },
    });
    const handlePageChange = (newPage) => setCurrentPage(newPage);
    const handleProductPositionChange = (oldIndex, newIndex) => {
        TagManager.dataLayer({ dataLayer: { event: 'product_drag_and_drop' } });
        const offerId = products[oldIndex].id;
        const adjacentElementsIds = getIdsOfAdjacentElements(products, oldIndex, newIndex);
        updateCollectionOrderMutation.mutate([{ slug: 'all' }, { offerId, ...adjacentElementsIds }], {
            onSuccess: () => refetchProducts(),
        });
    };
    const handleDuplicateOffer = (rowData) => {
        open(ConfirmModal, {
            title: 'Duplicate product',
            confirmLabel: 'Duplicate',
            cancelLabel: 'Cancel',
            text: (React.createElement("p", null,
                "Are you sure you want to duplicate ",
                React.createElement("strong", null, rowData.name))),
            onConfirm: async () => {
                try {
                    // NOTE: QueryClient by default was set to do toast notify when error occurs
                    // so we need to suggest another products when duplication fails we need to remove default `onError` handler
                    queryClient.setMutationDefaults(['duplicateOffer'], { onError: undefined });
                    const duplicatedOffer = await duplicateOfferMutation.mutateAsync([
                        { path: { offerId: rowData.id } },
                    ]);
                    refetchProducts();
                    navigate(reverse(routing.products.all.product.self, { productId: duplicatedOffer.id }));
                }
                catch (error) {
                    open(ChangeToAnotherProductModal, {
                        offerId: rowData.id,
                        async onConfirm(duplicatedOfferId) {
                            navigate(reverse(routing.products.all.product.self, { productId: duplicatedOfferId }));
                        },
                        onClose() {
                            close();
                        },
                    });
                }
            },
        });
    };
    const handleArchiveClick = (rowData) => {
        open(ConfirmModal, {
            title: `Are you sure that you want to archive ${rowData.name}?`,
            confirmLabel: 'Archive',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                deleteOfferMutation.mutate([{ offerId: rowData.id }], {
                    onSuccess: () => {
                        refetchProducts();
                    },
                });
            },
        });
    };
    const handleArchiveManyClick = (offerIds, cb) => {
        open(ConfirmModal, {
            title: `Are you sure that you want to archive ${offerIds.length} ${pluralize(offerIds.length, 'product')}?`,
            confirmLabel: 'Archive',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                const deletePromises = offerIds.map(async (offerId) => deleteOfferMutation.mutateAsync([{ offerId }]));
                Promise.all(deletePromises).then(() => {
                    refetchProducts().then(() => {
                        cb?.();
                        Toasts.notify(`${offerIds.length} ${pluralize(offerIds.length, 'product')} archived`, {
                            type: 'info',
                        });
                    });
                });
            },
        });
    };
    const handleProductPositionSet = (rowData) => {
        page &&
            open(PositionChangeModal, {
                title: 'Change product position',
                maxPosition: page.elementsTotal,
                currentPosition: getCurrentProductPositionIndex(rowData.id, products, currentPage, PER_PAGE),
                onSubmit: (formValues) => {
                    const { type = 'EXACT', exactPosition } = formValues;
                    const position = getUpdatedProductPositionIndex(type, exactPosition, page.elementsTotal);
                    updateCollectionOrderMutation.mutate([{ slug: 'all' }, { offerId: rowData.id, position }], {
                        onSuccess: () => refetchProducts(),
                    });
                },
            });
    };
    const handleToggleMultipleOfferStatus = (offerIds, cb) => {
        open(ToggleStatusModal, {
            getCount: () => offerIds?.length,
            onSubmit: (status) => {
                const updatePromises = offerIds.map(async (offerId) => statusMutation.mutateAsync({ offerId, params: { status } }));
                return Promise.all(updatePromises).then(() => {
                    return refetchProducts().then(() => {
                        cb?.();
                        Toasts.notify(`${offerIds.length} products status updated`, {
                            type: 'info',
                        });
                    });
                });
            },
        });
    };
    return (React.createElement(ProductsView, { products: products, isLoading: isLoading || updateCollectionOrderMutation.isLoading || statusMutation.isLoading, currentPage: currentPage, totalPages: page?.totalPages, onPageChange: handlePageChange, onProductPositionChange: handleProductPositionChange, onProductPositionSet: handleProductPositionSet, onDuplicateOffer: handleDuplicateOffer, onArchiveOffer: handleArchiveClick, onArchiveManyOffers: handleArchiveManyClick, onToggleMultipleOfferStatus: handleToggleMultipleOfferStatus }));
};
