/* NOTE: This one uses label as identifier since it's unique while value is not. Keep in mind we can
have two red colors with exact same value, but different labels due to different material used. In
the future, we should extend color model to include an unique key. */
export const getSelected = (color, selected) => {
    // selected is null
    if (!selected)
        return false;
    // selected is an array of strings or Colors
    if (Array.isArray(selected)) {
        return selected.some((item) => {
            return typeof item === 'string' ? color === item : color === item.label;
        });
    }
    // selected is a string
    if (typeof selected === 'string') {
        return color === selected;
    }
    // selected is a Color
    return color === selected.label;
};
