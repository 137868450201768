export const getPaidTo = (data) => {
    const paidTo = {
        BANK_ACCOUNT: 'Bank account',
        CARD: 'Card',
        PARTNER: 'name' in data ? data.name : '-',
        UNKNOWN: '-',
        MANUAL: 'Manual transfer',
    };
    return paidTo[data.type];
};
