import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
const TYPE_ATTRIBUTES = {
    extraSmall: { colorSize: '16px' },
    small: { colorSize: '20px' },
    medium: { colorSize: '24px' },
};
const getAppearanceStyle = ({ appearance, hoverable, selected }) => {
    if (appearance === 'single-select') {
        return css `
      position: relative;

      &:before {
        position: absolute;

        width: calc(100% + 6px);
        height: calc(100% + 6px);
        content: '';
        display: block;

        border-radius: 50%;
        border: 2px solid transparent;
      }

      ${hoverable &&
            selected &&
            css `
        &:before {
          border-color: #000;
        }
      `}

      ${hoverable &&
            !selected &&
            css `
        &:hover::before {
          border-color: #000;
        }
      `}
    `;
    }
    return '';
};
export const Container = styled.div `
  display: flex;
  align-items: center;
`;
export const Color = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ size }) => TYPE_ATTRIBUTES[size].colorSize};
  height: ${({ size }) => TYPE_ATTRIBUTES[size].colorSize};
  border-radius: 50%;

  ${({ light }) => light &&
    css `
      box-shadow: inset 0 0 0 1px #c1c1c1;
    `};
`;
export const ColorContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${(props) => (props.hoverable ? 'pointer' : 'initial')};

  ${(props) => getAppearanceStyle(props)}
`;
export const CheckMark = styled.span `
  color: ${({ light }) => (light ? Colors.GS700 : Colors.GS0)};
`;
