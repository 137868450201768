import { Emoji, Icon } from '@fourthwall/components';
import { DiscordIcon } from '@fourthwall/icons';
import React from 'react';
export const FEATURES = [
    {
        icon: React.createElement(Emoji, { shortcode: "camera" }),
        label: 'Post exclusive videos, images, polls and audio',
    },
    {
        icon: React.createElement(Emoji, { shortcode: "mobilePhone" }),
        label: 'Custom apps',
    },
    {
        icon: React.createElement(Emoji, { shortcode: "tshirt" }),
        label: 'Members-only products & discounts',
    },
    {
        icon: React.createElement(Emoji, { shortcode: "speachBalloon" }),
        label: 'Direct messages and tips',
    },
    {
        icon: React.createElement(Emoji, { shortcode: "chartIncreasing" }),
        label: 'Upsell shop buyers into members',
    },
    {
        icon: (React.createElement("div", { style: { display: 'flex', color: '#5865F2' } },
            React.createElement(Icon, { component: DiscordIcon, height: 24 }))),
        label: 'Members-only Discord',
    },
];
