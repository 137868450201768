import React from 'react';
import { ArticleList } from '../../../../../../../components/common/ArticleList';
import { SectionTitle } from '../../../../../Home/components/SectionTitle';
import { launchingMembershipsArticle, membershipsStrategiesArticle, perksGuideArticle, quickStartGuideArticle, } from '../../../../consts';
import { useMembersQuery } from '../../../Members/hooks/useMembersQuery';
import { useTiers } from '../../../Perks/hooks/useTiers';
export const Articles = () => {
    const { tiersQuery, hasAnyTier } = useTiers();
    const { membersQuery } = useMembersQuery([{ page: 1, limit: 10 }]);
    if (!tiersQuery.isSuccess || !membersQuery.isSuccess) {
        return null;
    }
    const getLaunchingMembershipsArticle = () => {
        if (hasAnyTier && membersQuery.data.members.length < 10) {
            return [launchingMembershipsArticle];
        }
        return [];
    };
    const getQuickStartGuideArticle = () => {
        if (membersQuery.data.members.length < 3) {
            return [quickStartGuideArticle];
        }
        return [];
    };
    const getArticleListProps = () => {
        return [
            ...getLaunchingMembershipsArticle(),
            ...getQuickStartGuideArticle(),
            membershipsStrategiesArticle,
            perksGuideArticle,
        ];
    };
    return (React.createElement("div", null,
        React.createElement(SectionTitle, { title: "Guides & tips" }),
        React.createElement(ArticleList, { items: getArticleListProps() })));
};
