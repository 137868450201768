import { Button, ButtonGroup, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import * as S from './AddButtons.styled';
const titleMap = {
    MORE_DETAILS: 'More details',
    SIZE_AND_FIT: 'Size & fit',
    GUARANTEE_AND_RETURNS: 'Quality guarantee & returns',
};
export const AddButtons = ({ allValues, setValues, isDigitalProduct }) => {
    const media = useMediaQueryContext();
    if (allValues.additionalSections.length >= 3)
        return null;
    const hasSectionOfType = (type) => {
        return allValues.additionalSections.some((section) => section.type === type);
    };
    const shouldRender = {
        moreDetailsButton: !hasSectionOfType('MORE_DETAILS'),
        sizeAndFitButton: isDigitalProduct ? false : !hasSectionOfType('SIZE_AND_FIT'),
        guaranteeAndReturnsButton: !hasSectionOfType('GUARANTEE_AND_RETURNS'),
    };
    const handleSectionCreate = (type) => {
        const newSection = { type, title: titleMap[type], bodyHtml: '' };
        setValues({ additionalSections: [...allValues.additionalSections, newSection] });
    };
    return (React.createElement(S.Container, null,
        React.createElement(ButtonGroup, { space: "large", direction: media.tablet ? 'row' : 'column', align: media.tablet ? undefined : 'stretch' },
            shouldRender.moreDetailsButton && (React.createElement(Button, { fullWidth: !media.tablet, label: "Add more details section", onClick: () => handleSectionCreate('MORE_DETAILS') })),
            shouldRender.sizeAndFitButton && (React.createElement(Button, { fullWidth: !media.tablet, label: "Add size & fit section", onClick: () => handleSectionCreate('SIZE_AND_FIT') })),
            shouldRender.guaranteeAndReturnsButton && (React.createElement(Button, { fullWidth: !media.tablet, label: "Add quality guarantee & returns section", onClick: () => handleSectionCreate('GUARANTEE_AND_RETURNS') })))));
};
