import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
import { Size } from '../types';
export const IMAGE_SIZES = {
    regular: 60,
    small: 48,
};
export const ImageContainer = styled.td `
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: ${IMAGE_SIZES.regular}px;
  box-sizing: content-box;
`;
export const Image = styled.img `
  width: 100%;
  height: auto;
  object-fit: contain;
  background-color: ${Colors.GS200};
`;
export const Quantity = styled.td `
  text-align: center;
  padding: 0 40px;
`;
export const Price = styled.td `
  text-align: right;
`;
export const Tag = styled.td `
  text-align: right;
`;
export const TagWrapper = styled.div `
  display: inline-flex;
`;
export const ItemContainer = styled.tr `
  ${({ size }) => size === Size.Small &&
    css `
      font-size: 14px;

      & + & {
        margin-top: 8px;
      }

      ${ImageContainer} {
        padding-right: 12px;
        padding-top: 4px;
        padding-bottom: 4px;
        width: ${IMAGE_SIZES.small}px;
      }

      ${Quantity} {
        padding-left: 24px;
        padding-right: 22px;
      }
    `}
`;
export const Name = styled.td `
  text-align: left;
`;
export const NameLink = styled.a `
  &:hover {
    text-decoration: underline;
  }
`;
export const Details = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
`;
export const ProductThumbnailWrapper = styled.div `
  width: 66px;
  height: 88px;
`;
