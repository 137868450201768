import { isDateRangeValue, isPrecisionValue, mapDateRangeValueToPrecisionOptions, useDateRange, usePrecision, } from '@fourthwall/module-analytics';
import dayjs from 'dayjs';
import last from 'lodash-es/last';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useDashboardFeatureFlags } from '../../../../../components/utils/DashboardFeatureFlags';
import { useMembershipAccountQuery } from '../../../hooks/useMembershipAccountQuery';
import { REPORT_TYPES } from '../../consts';
import { useReportType } from '../useReportType';
import { analyticsQueryParams } from './types';
import { getChartEntitySelect } from './utils/chartEntity';
import { getDevices, getDevicesSelect } from './utils/devices';
import { getIntervals, getIntervalsSelect } from './utils/intervals';
import { getTiers, getTiersSelect } from './utils/tiers';
export const useAnalyticsParams = () => {
    const { reportType } = useReportType();
    const { membershipAccountQuery } = useMembershipAccountQuery();
    const { dateRange } = useDateRange();
    const { precision } = usePrecision(dateRange.value);
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const [devicesParam, setDevicesParam] = useQueryParam(analyticsQueryParams.devices);
    const [intervalsParam, setIntervalsParam] = useQueryParam(analyticsQueryParams.intervals);
    const [tiersIdsParam, setTiersIdsParam] = useQueryParam('tiersIds');
    const [fromParam, setFromParam] = useQueryParam(analyticsQueryParams.from, withDefault(StringParam, ''));
    const [toParam, setToParam] = useQueryParam(analyticsQueryParams.to, withDefault(StringParam, ''));
    const [chartEntityParam, setChartEntityParam] = useQueryParam(analyticsQueryParams.chartEntity);
    const params = {
        from: dateRange.value === 'custom' ? fromParam : dateRange.params?.from || '',
        to: dateRange.value === 'custom' ? toParam : dateRange.params?.to || '',
        precision: precision.params.precision,
        chartEntityGroup: chartEntityParam,
        devices: getDevices(devicesParam),
        intervals: getIntervals(intervalsParam),
        tiersIds: getTiers(tiersIdsParam),
    };
    const rangeSelect = {
        options: dateRange.select.options,
        value: dateRange.value,
        onValueChange: (value) => {
            dateRange.select.onValueChange(value);
            setFromParam(value === 'custom' ? dateRange.params?.from || '' : undefined);
            setToParam(value === 'custom' ? dateRange.params?.to || '' : undefined);
            if (isDateRangeValue(value)) {
                const newPrecisionValue = last(mapDateRangeValueToPrecisionOptions(value))?.value;
                if (newPrecisionValue && isPrecisionValue(newPrecisionValue)) {
                    precision.setValue(newPrecisionValue);
                }
            }
        },
    };
    // NOTE: Don't ask.
    const rangePicker = {
        value: (() => {
            const FORMAT = 'YYYY-MM-DD';
            const from = fromParam
                ? dayjs(dayjs.utc(fromParam).format(FORMAT)).startOf('day').add(12, 'hour').toISOString()
                : '';
            const to = fromParam
                ? dayjs(dayjs.utc(toParam).format(FORMAT)).startOf('day').add(12, 'hour').toISOString()
                : '';
            return [from, to];
        })(),
        onValueChange: (value) => {
            const FORMAT = 'YYYY-MM-DD';
            const fromFormatted = value[0] ? dayjs(value[0]).format(FORMAT) : '';
            const from = fromFormatted ? dayjs.utc(fromFormatted).toISOString() : '';
            const toFormatted = value[1] ? dayjs(value[1]).format(FORMAT) : '';
            const to = toFormatted ? dayjs.utc(toFormatted).endOf('day').toISOString() : '';
            setFromParam(from);
            setToParam(to);
        },
    };
    const precisionSelect = (() => {
        if (!REPORT_TYPES[reportType].precision)
            return;
        return {
            ...precision.select,
            disabled: precision.select.options.length === 1,
        };
    })();
    const getOptions = () => {
        const options = [
            { label: 'All', value: 'ALL' },
            { label: 'Orders', value: 'ORDERS' },
            { label: 'Donations', value: 'DONATIONS' },
            { label: 'Twitch Gifts', value: 'GIVEAWAY' },
            { label: 'Giveaway links', value: 'GIFTS_ON_CREATOR_COST' },
            // { label: 'Purchased gifts', value: 'PURCHASED_GIFTS' },
            // { label: 'Redeemed gifts', value: 'REDEEMED_GIFTS' },
        ];
        if (dashboardFeatureFlags.memberships.enabled) {
            options.push({ label: 'Memberships', value: 'SUBSCRIPTIONS' }, { label: 'Memberships tip', value: 'MEMBERSHIP_TIP' });
        }
        if (dashboardFeatureFlags.membershipsMessagesPayPerView.enabled) {
            options.push({ label: 'Memberships locked message', value: 'MEMBERSHIP_LOCKED_MESSAGE' });
        }
        return options;
    };
    const chartEntityGroupSelect = getChartEntitySelect(getOptions(), reportType, chartEntityParam, setChartEntityParam);
    const devicesSelect = getDevicesSelect(reportType, devicesParam, setDevicesParam);
    const intervalSelect = getIntervalsSelect(reportType, intervalsParam, setIntervalsParam);
    const tiersSelect = getTiersSelect(reportType, tiersIdsParam, membershipAccountQuery, setTiersIdsParam);
    return {
        params,
        rangeSelect,
        rangePicker,
        precisionSelect,
        tiersSelect,
        intervalSelect,
        devicesSelect,
        chartEntityGroupSelect,
    };
};
