import { Avatar, ButtonClean, Dropdown, Icon, Skeleton } from '@fourthwall/components';
import { ArrowShortDownIcon } from '@fourthwall/icons';
import React, { useEffect, useState } from 'react';
import { useCurrentShopQuery } from '../../../../../hooks/useCurrentShopQuery';
import { useTiersQuery } from '../../../Perks/hooks/useTiersQuery';
import { EventsSection } from '../../components/EventsSection/EventsSection';
import { useMembershipsOverview } from '../../hooks/useMembershipsOverview';
import { getJoinedDate } from '../../utils';
import { useTopCommentsQuery } from './hooks/useTopCommentsQuery';
import * as S from './styled';
const rangeLabels = {
    '24_hours': 'Last 24 hours',
    '7_days': 'Last 7 days',
    '30_days': 'Last 30 days',
    year: 'Last year',
    all: 'All time',
};
export const TopComments = () => {
    const [range, setRange] = useState('7_days');
    const [isDefaultRange, setIsDefaultRange] = useState(true);
    const { currentShopQuery } = useCurrentShopQuery();
    const { tiersQuery } = useTiersQuery();
    const { topComments } = useMembershipsOverview();
    const { topCommentsQuery } = useTopCommentsQuery([{ range }], { enabled: !isDefaultRange });
    useEffect(() => {
        if (topComments?.range) {
            setRange(topComments.range);
        }
    }, [topComments]);
    if (!topComments || !tiersQuery.data || !currentShopQuery.data) {
        return (React.createElement(Skeleton, null,
            React.createElement(Skeleton.Shape, { width: "100%", height: 256 })));
    }
    const comments = topCommentsQuery.data?.comments || topComments.comments;
    const renderDropdown = () => {
        return (React.createElement(Dropdown, { content: Object.keys(rangeLabels).map((label) => {
                return (React.createElement(Dropdown.Item, { label: rangeLabels[label], onClick: () => {
                        setRange(label);
                        setIsDefaultRange(false);
                    } }));
            }) },
            React.createElement(S.ButtonContainer, null,
                React.createElement(ButtonClean, { label: rangeLabels[range], appearance: "secondary", withUnderline: false }),
                React.createElement(Icon, { component: ArrowShortDownIcon, height: 10 }))));
    };
    return (React.createElement(EventsSection, { title: "Top comments", button: renderDropdown(), emptyMessage: !comments.length ? "There's no comments yet." : undefined },
        React.createElement(S.Container, null, comments.map(({ author, postedAt, post, path, body }) => {
            return (React.createElement(S.Comment, { href: `${currentShopQuery.data.baseUri}${path}`, target: "_blank" },
                React.createElement(S.Top, null,
                    React.createElement(S.User, null,
                        React.createElement(Avatar, { name: author.name, imageUrl: author.avatar || '', size: "small" }),
                        author.name),
                    React.createElement(S.Date, null, getJoinedDate(postedAt))),
                React.createElement(S.Title, null,
                    "Re: ",
                    post.title),
                React.createElement(S.Text, null, body)));
        }))));
};
