import { PriceString, ProfitString } from '@fourthwall/components';
import React from 'react';
import { BreakdownSection } from './components/BreakdownSection';
import * as S from './styled';
const renderSection = (title, costsBreakdownItem, negative = false, displayprofit = false) => {
    if (!costsBreakdownItem) {
        return null;
    }
    return (React.createElement(BreakdownSection, { title: title, cost: costsBreakdownItem.cost &&
            (displayprofit ? (React.createElement(ProfitString, { value: costsBreakdownItem.cost.value })) : (React.createElement(PriceString, { value: costsBreakdownItem.cost.value, negative: negative }))), overriddenCost: costsBreakdownItem.overriddenCost && (React.createElement(PriceString, { value: costsBreakdownItem.overriddenCost.value, negative: negative })), tooltipContent: costsBreakdownItem.explanation, entries: costsBreakdownItem.entries }));
};
export const CostsBreakdown = ({ costsBreakdown }) => {
    const incomeTitle = costsBreakdown.total.refund
        ? 'Initial payment by supporter'
        : 'Paid by supporter';
    const refundTitle = {
        Cancel: 'Refunded',
        RefundByCreator: 'Refunded by you',
        RefundByFourthwall: 'Refunded by Fourthwall',
    }[costsBreakdown.total.refundCause || 'Cancel'];
    return (React.createElement(S.Container, null,
        renderSection('Fulfillment fees', costsBreakdown.fulfillmentFee, true),
        renderSection('Product manufacturing costs', costsBreakdown.offers, true),
        renderSection('Digital products fees', costsBreakdown.digitals, true),
        renderSection('Payment processing fees', costsBreakdown.paymentFee, true),
        renderSection('Shipping', costsBreakdown.shipping, true),
        renderSection('Taxes', costsBreakdown.tax, true),
        React.createElement(S.Summary, null,
            renderSection('Total costs', costsBreakdown.total.cost, true),
            renderSection(incomeTitle, costsBreakdown.total.income),
            renderSection(refundTitle, costsBreakdown.total.refund, true),
            renderSection('Net payment by supporter', costsBreakdown.total.supporterNetPayment)),
        React.createElement(S.Summary, null,
            renderSection('Your profit', costsBreakdown.total.profit, undefined, true),
            renderSection('Prepayment balance', costsBreakdown.prepaymentBalance, true))));
};
