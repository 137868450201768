import { Button, Input } from '@fourthwall/components';
import { SearchIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
export const Search = ({ placeholder, onInputChange, onBrowseClick, disabled = true, value = '', }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.InputContainer, null,
            React.createElement(Input, { size: "medium", placeholder: placeholder, fullWidth: true, disabled: disabled, leftIcon: SearchIcon, onChange: onInputChange, value: value })),
        React.createElement(Button, { label: "Browse", onClick: onBrowseClick })));
};
