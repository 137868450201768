import React from 'react';
import { List, Table, useMediaQueryContext } from '@fourthwall/components';
import { headers } from './consts';
import { TransactionsListItem } from './components/TransactionsListItem';
export const TransactionsData = ({ data, isLoading }) => {
    const media = useMediaQueryContext();
    const emptyMessage = 'You have no earnings history yet. As you earn money through contributions, your earnings will show up here.';
    const getHighlightedRow = ({ type }) => type === 'MANUAL_PAYOUT';
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, emptyComponent: emptyMessage, renderItem: (transactionItem) => (React.createElement(TransactionsListItem, { transactionItem: transactionItem })), getItemHighlighted: getHighlightedRow }));
    }
    return (React.createElement(Table, { headers: headers, data: data, emptyMessage: emptyMessage, isLoading: isLoading, getRowHighlighted: getHighlightedRow }));
};
