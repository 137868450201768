import { Field } from 'formik';
import React from 'react';
import { Fieldset, Input, Subsection, useModalContext } from '@fourthwall/components';
import { isWebView } from '@fourthwall/utils';
import { TinyMCE } from '@components/adapters/inputs/TinyMCE';
import { ImageUploadModal } from '../../components/ImageUploadModal';
import { FormFields } from '../../types';
export const CommonFields = ({ disabled, children }) => {
    const modal = useModalContext();
    return (React.createElement(Subsection, { title: "Post" },
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: FormFields.title, component: Input, label: "Post title", required: true, disabled: disabled, fullWidth: true }))),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: FormFields.description, component: TinyMCE, resize: !isWebView(), buttons: { customImage: true }, statusbar: !isWebView(), height: isWebView() ? undefined : 185, placeholder: "Post description", disabled: disabled, onSetup: (editor) => {
                            editor.ui.registry.addButton('customImage', {
                                icon: 'image',
                                onAction: () => {
                                    const selectedNode = editor.selection.getNode();
                                    if (selectedNode.nodeName === 'IMG') {
                                        return modal.open(ImageUploadModal, {
                                            src: selectedNode.getAttribute('src') || '',
                                            width: selectedNode.getAttribute('width')
                                                ? Number(selectedNode.getAttribute('width'))
                                                : undefined,
                                            height: selectedNode.getAttribute('height')
                                                ? Number(selectedNode.getAttribute('height'))
                                                : undefined,
                                            alt: selectedNode.getAttribute('alt') || '',
                                            onConfirm: (src, width, height, alt) => editor.selection.setContent(`<img src="${src}" width="${width}" height="${height}" alt="${alt}"/>`),
                                        });
                                    }
                                    modal.open(ImageUploadModal, {
                                        onConfirm: (src, width, height, alt) => editor.insertContent(`<p><img src="${src}" width="${width}" height="${height}" alt="${alt}" /></p>`),
                                    });
                                },
                            });
                        } }))),
            children && React.createElement(Fieldset.Row, null, children))));
};
