import styled from 'styled-components';
import { CONTENT_GAP, RIGHT_CONTENT_WIDTH } from '@components/common/PageLayout/styled';
import { mediaQueries } from '@fourthwall/components';
export const TitleLink = styled.div `
  ${mediaQueries.laptop} {
    max-width: calc(100% - ${RIGHT_CONTENT_WIDTH + CONTENT_GAP}px);
  }
`;
export const StatusWrapper = styled.div `
  display: inline-block;
`;
export const BackButtonWrapper = styled.div `
  flex: 1;
`;
