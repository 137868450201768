import { Icon } from '@fourthwall/components';
import { ResetIcon, SearchIcon } from '@fourthwall/icons';
import filter from 'lodash-es/filter';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { SEARCH_MIN_LENGTH } from './consts';
import { useProductsInfiniteQuery } from './hooks/useProductsInfiniteQuery';
import { SelectProductsView } from './SelectProductsView';
import { getProductsStates } from './utils';
export const SelectProductsContainer = ({ multiple = true, title = 'Select products', params, mode = 'COMMON', selectedProductsIds = [], productsStateResolver, initialInputValue = '', submitButtonLabel = 'Save', cancelButtonLabel = 'Cancel', onSave, close, }) => {
    const [inputSuggestion, setInputSuggestion] = useState(undefined);
    const [inputValue, setInputValue] = useState(initialInputValue);
    const [inputValueDebounced] = useDebounce(inputValue, 300);
    const [innerProductsIds, setInnerProductsIds] = useState(selectedProductsIds);
    const { products, hasNextPage, fetchNextPage, isLoading } = useProductsInfiniteQuery(inputValueDebounced, undefined, params);
    const productsStates = getProductsStates(productsStateResolver, products);
    const resetInputValue = () => setInputValue('');
    useEffect(() => {
        setInputSuggestion(undefined);
    }, [inputValue]);
    const handleKeyDown = (event) => {
        if (inputValue.length && inputValue.length < SEARCH_MIN_LENGTH && event.key === 'Enter') {
            setInputSuggestion(`Enter at least ${SEARCH_MIN_LENGTH} characters to start the search.`);
        }
    };
    const handleLoadMoreProducts = () => {
        if (hasNextPage) {
            fetchNextPage();
        }
    };
    const handleCheckboxChange = (product) => (value) => {
        if (!multiple) {
            return setInnerProductsIds([product.id]);
        }
        if (value) {
            return setInnerProductsIds([product.id, ...innerProductsIds]);
        }
        return setInnerProductsIds(filter(innerProductsIds, (o) => o !== product.id));
    };
    const handleSubmitClick = () => {
        if (onSave) {
            onSave(innerProductsIds);
        }
        close();
    };
    const getMessage = () => {
        if (!multiple)
            return;
        const productsCount = innerProductsIds.length;
        const title = productsCount === 1 ? 'product' : 'products';
        return `${productsCount} ${title} selected`;
    };
    const getEmptyMessage = () => {
        if (!products?.length) {
            return !inputValueDebounced.length
                ? 'You haven’t created any products yet.'
                : 'No products found';
        }
    };
    return (React.createElement(SelectProductsView, { title: title, products: products || [], productsStates: productsStates, innerProductsIds: innerProductsIds, multiple: multiple, mode: mode, message: getMessage(), emptyMessage: getEmptyMessage(), isLoading: isLoading, hasNextPage: !!hasNextPage, submitButtonLabel: submitButtonLabel, cancelButtonLabel: cancelButtonLabel, inputProps: {
            value: inputValue,
            placeholder: 'Search products',
            leftIcon: SearchIcon,
            disabled: false,
            actionIcon: inputValue.length ? React.createElement(Icon, { component: ResetIcon, height: 18 }) : undefined,
            suggestion: inputSuggestion,
            onKeyDown: handleKeyDown,
            onValueChange: setInputValue,
            onActionIconClick: resetInputValue,
        }, close: close, onLoadMoreProducts: handleLoadMoreProducts, onSelectProduct: handleCheckboxChange, onSubmitClick: handleSubmitClick }));
};
