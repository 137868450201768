import React, { useEffect } from 'react';
import { ModalBox, TableBreakdown, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import { GiveawaysItemBreakdownData } from '../GiveawaysItemBreakdownData';
export const GiveawaysItemBreakdownModal = ({ giveaway }) => {
    const { close } = useModalContext();
    const media = useMediaQueryContext();
    useEffect(() => {
        if (media.tablet) {
            close();
        }
    }, [close, media]);
    return (React.createElement(ModalBox, { title: "Twitch gift breakdown", onClose: close },
        React.createElement(GiveawaysItemBreakdownData, { giveaway: giveaway }),
        React.createElement("div", { style: { marginTop: 16 } },
            React.createElement(TableBreakdown.Footer, { text: `${giveaway.giftsLeftToRedeem} ${pluralize(giveaway.giftsLeftToRedeem, 'gift')} left to redeem` }))));
};
