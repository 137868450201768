import { Toasts, useModalContext } from '@fourthwall/components';
import { getDefaultErrorMessage } from '@fourthwall/utils/lib/queryClient';
import { routing } from '@utils/routing';
import axios from 'axios';
import { reverse } from 'named-urls';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrderIdParam } from '../hooks';
import { useCancellationOffers } from '../hooks/useCancellationOffers';
import { useOrderQuery } from '../queries';
import { useCalculateCancelRefundsQuery } from './hooks/useCalculateCancelRefundsQuery';
import { useCancelOrderMutation } from './hooks/useCancelOrderMutation';
import { OrderCancelConfirmModalContainer } from './modules/OrderCancelConfirmModal/OrderCancelConfirmModalContainer';
import { OrderCancelView } from './OrderCancelView';
import { handleCalculateRefundsError } from './utils';
export const OrderCancelContainer = () => {
    const navigate = useNavigate();
    const { open } = useModalContext();
    const { orderId } = useOrderIdParam();
    const { orderQuery } = useOrderQuery([orderId]);
    const [selectableOffers, setSelectableOffers] = useState();
    const [shouldRestock, setShouldRestock] = useState(true);
    const [itemsToCancel, setItemsToCancel] = useState([]);
    const { cancellableOffers, nonCancellableOffers, partiallyCancellable, orderCancellationQuery, invalidateOrderCancellationQuery, } = useCancellationOffers(orderId, orderQuery.data?.offers);
    const { calculateCancelRefundsQuery } = useCalculateCancelRefundsQuery([orderId, { itemsToCancel }], { retry: false, keepPreviousData: true, onError: handleCalculateRefundsError });
    const { cancelOrderMutation } = useCancelOrderMutation({ onError: undefined });
    const calculateCancelRefunds = calculateCancelRefundsQuery.data;
    useEffect(() => {
        if (cancellableOffers) {
            setSelectableOffers(cancellableOffers?.map((offer) => ({
                ...offer,
                selected: false,
                selectedQuantity: offer.quantity,
            })));
        }
    }, [cancellableOffers]);
    useEffect(() => {
        if (!selectableOffers) {
            setItemsToCancel([]);
            return;
        }
        const itemsToCancel = selectableOffers
            .filter((selectableOffer) => selectableOffer.selected)
            .map(({ variantId, selectedQuantity }) => ({ variantId, quantity: selectedQuantity }));
        setItemsToCancel(itemsToCancel);
    }, [selectableOffers]);
    const getOrderCancellationReasons = () => {
        if (orderQuery.data?.salesChannel.type === 'TikTok') {
            return [
                { value: 'seller_cancel_reason_out_of_stock', label: 'Out of stock' },
                { value: 'seller_cancel_reason_wrong_price', label: 'Pricing error' },
                {
                    value: 'seller_cancel_paid_reason_address_not_deliver',
                    label: 'Unable to deliver to buyer address',
                },
                {
                    value: 'seller_cancel_paid_reason_buyer_requested_cancellation',
                    label: 'Buyer requested cancellation',
                },
            ];
        }
    };
    const cancelOrder = (productsContent, expectedCostSplit, reason) => {
        cancelOrderMutation.mutate([orderId, { itemsToCancel, restockCancelledItems: shouldRestock, reason, expectedCostSplit }], {
            onSuccess: () => {
                invalidateOrderCancellationQuery();
                navigate(reverse(routing.contributions.orders.order.self, { orderId }));
                Toasts.notify('Order has been canceled successfully.', { type: 'info' });
            },
            onError: (error) => {
                if (axios.isAxiosError(error) &&
                    error.response?.data.code === 'ORDER_CANCEL_EXPECTED_CHARGE_AMOUNT_CHANGED') {
                    open(OrderCancelConfirmModalContainer, {
                        orderId,
                        itemsToCancel,
                        content: productsContent,
                        reasonOptions: getOrderCancellationReasons(),
                        initialReason: reason,
                        error: 'Your balance has changed and cannot cover refund amount. Please try again.',
                        onConfirm: (expectedCostSplit, reason) => {
                            cancelOrder(productsContent, expectedCostSplit, reason);
                        },
                    });
                    return;
                }
                Toasts.notify(getDefaultErrorMessage(error), { type: 'error' });
            },
        });
    };
    const handleCancelOrder = (productsContent) => {
        open(OrderCancelConfirmModalContainer, {
            orderId,
            itemsToCancel,
            content: productsContent,
            reasonOptions: getOrderCancellationReasons(),
            onConfirm: (expectedCostSplit, reason) => cancelOrder(productsContent, expectedCostSplit, reason),
        });
    };
    const handleOfferSelect = (selectableOffer, selected) => {
        setSelectableOffers((currentSelectableOffers) => currentSelectableOffers?.map((offer) => {
            if (selectableOffer.variantId !== offer.variantId) {
                return offer;
            }
            return { ...offer, selected };
        }));
    };
    const handleOfferSelectedQuantityChange = (selectableOffer, selectedQuantity) => {
        setSelectableOffers((currentSelectableOffers) => currentSelectableOffers?.map((offer) => {
            if (selectableOffer.variantId !== offer.variantId) {
                return offer;
            }
            return { ...offer, selectedQuantity };
        }));
    };
    const handleSelectAll = (selected) => {
        setSelectableOffers((currentSelectableOffers) => currentSelectableOffers?.map((offer) => {
            return { ...offer, selected };
        }));
    };
    return (React.createElement(OrderCancelView, { orderId: orderId, friendlyId: orderQuery.data?.friendlyId, cancelableOffers: selectableOffers, nonCancellableOffers: nonCancellableOffers, donation: orderQuery.data?.donation || undefined, promotion: orderQuery.data?.promotion, subtotal: calculateCancelRefunds?.cancellationAmounts.items, shipping: calculateCancelRefunds?.cancellationAmounts.shipping, taxes: calculateCancelRefunds?.cancellationAmounts.tax, discount: calculateCancelRefunds?.cancellationAmounts.discount, refundTotal: calculateCancelRefunds?.cancellationAmounts.total, shouldRestock: shouldRestock, isCancelButtonDisabled: !partiallyCancellable ||
            !selectableOffers?.filter((offer) => offer.selected).length ||
            calculateCancelRefundsQuery.isFetching ||
            calculateCancelRefundsQuery.isError ||
            orderCancellationQuery.isFetching ||
            orderCancellationQuery.isError, isLoading: orderQuery.isLoading ||
            calculateCancelRefundsQuery.isLoading ||
            calculateCancelRefundsQuery.isLoading ||
            cancelOrderMutation.isLoading, onOrderCancel: handleCancelOrder, onOfferSelect: handleOfferSelect, onRestockChange: setShouldRestock, onOfferSelectedQuantityChange: handleOfferSelectedQuantityChange, onSelectAll: handleSelectAll }));
};
