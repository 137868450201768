import { List, Table, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import { PayoutsHistoryListItem } from './components/PayoutsHistoryListItem';
import { headers } from './consts';
export const PayoutsHistoryData = ({ data, isLoading }) => {
    const media = useMediaQueryContext();
    const emptyMessage = 'There are no payouts yet.';
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, emptyComponent: emptyMessage, renderItem: (payoutHistoryItem) => (React.createElement(PayoutsHistoryListItem, { payoutHistoryItem: payoutHistoryItem })) }));
    }
    return (React.createElement(Table, { headers: headers, data: data, emptyMessage: emptyMessage, isLoading: isLoading, minWidth: 0 }));
};
