import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  margin-top: 16px;

  > * + * {
    margin-top: 16px;
  }
`;
export const Description = styled.p `
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS1000};

  > a {
    text-decoration: underline;
  }
`;
export const HelperText = styled.div `
  margin-top: 4px;

  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${Colors.GS600};

  > a {
    text-decoration: underline;
  }
`;
