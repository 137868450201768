import { Icon, PriceString, ProfitString, Tag, Tooltip } from '@fourthwall/components';
import { QuestionMarkIcon } from '@fourthwall/icons';
import get from 'lodash-es/get';
import React from 'react';
import { TableItemDate } from '@components/utils/TableItemDate';
import { getNumberOfProducts } from '@utils/products';
import { TableProductsList } from '@components/common/TableProductsList';
import { TableCell } from '@components/common/TableCell';
import { getBackerName } from './utils';
import { TableStatusTag } from '../../../../TableStatusTag';
export const getOrdersTableHeaders = ({ hideProfitColumn, }) => {
    const headers = [
        {
            id: 'createdAt',
            label: 'Date',
            render: ({ createdAt }) => (React.createElement("div", null,
                React.createElement(TableItemDate, { date: createdAt }))),
        },
        {
            id: 'products',
            label: ' ',
            render: ({ offers, donation, offersCancelled }) => (React.createElement(TableProductsList, { offers: offers, offersCancelled: offersCancelled, donation: donation, quantity: getNumberOfProducts(offers, donation) })),
        },
        {
            id: 'shipping.address.firstName',
            label: 'Supporter',
            render: (order) => React.createElement(TableCell, { mainContent: getBackerName(order), ellipsis: true }),
            width: 0,
            minWidth: 220,
        },
        {
            id: 'status',
            label: 'Status',
            render: ({ id, status, salesChannel }) => (React.createElement("div", null,
                React.createElement(TableStatusTag, { orderId: id, status: status }),
                salesChannel.type === 'Meta' && (React.createElement("div", { style: { marginTop: 8 } },
                    React.createElement(Tag, { label: "From Meta" }))),
                salesChannel.type === 'TikTok' && (React.createElement("div", { style: { marginTop: 8 } },
                    React.createElement(Tag, { label: "From TikTok" }))))),
            width: 2,
        },
        {
            id: 'totalPrice.value',
            label: 'Total',
            render: ({ totalPrice }) => (React.createElement(TableCell, { mainContent: React.createElement(PriceString, { value: get(totalPrice, 'value') }) })),
        },
        {
            id: 'profit',
            hidden: hideProfitColumn,
            label: (React.createElement(Tooltip, { reference: React.createElement("span", { style: {
                        display: 'grid',
                        gridAutoFlow: 'column',
                        alignItems: 'middle',
                        gap: 3,
                    } },
                    "Profit ",
                    React.createElement(Icon, { component: QuestionMarkIcon, height: 12 })) },
                React.createElement("span", { style: { textTransform: 'none' } }, "If the order contains MOQ screen printed products, profit values are estimated. The final profit values will be available within earnings history."))),
            render: ({ profit }) => (React.createElement(TableCell, { mainContent: profit ? React.createElement(ProfitString, { value: profit.value }) : React.createElement(React.Fragment, null, "\u2013") })),
        },
    ];
    return headers.filter((column) => !column.hidden);
};
