import React from 'react';
import { ContributionSummarySection } from './ContributionSummarySection/ContributionSummarySection';
import * as S from './styled';
export const ContributionSummary = ({ title, subtitle, status, children }) => (React.createElement(S.Container, null,
    React.createElement(S.Header, null,
        title && React.createElement("div", null, title),
        subtitle && React.createElement(S.Subtitle, null, subtitle),
        status && React.createElement("div", null, status)),
    children));
ContributionSummary.Section = ContributionSummarySection;
