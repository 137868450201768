import { ApiBase } from '../ApiBase';
class MembershipsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getAccount = () => {
            return this.get('/memberships/accounts/current');
        };
    }
}
export const MembershipsApiClient = new MembershipsApiClientModel();
