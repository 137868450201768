import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  gap: 16px;
`;
export const LeftSide = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Date = styled.div `
  font-weight: 600;
`;
export const TagWrapper = styled.div `
  margin-top: 8px;
`;
export const RightSide = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
`;
export const PriceWrapper = styled.div `
  align-self: flex-end;
`;
export const SecondaryPriceWrapper = styled(PriceWrapper) `
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.24px;
  color: ${Colors.GS600};
`;
