import { useOffersInfiniteQuery } from '../../../../hooks/useOffersInfiniteQuery';
import { getSamplesOffersQueryParams } from './utils';
import { useAdminModeContext } from '../../../../../App/providers/AdminModeProvider';
const size = 5;
export const useSamplesInfiniteOffersQuery = (query, options) => {
    const adminMode = useAdminModeContext();
    const { offersInfiniteQuery } = useOffersInfiniteQuery(getSamplesOffersQueryParams({
        size,
        query,
        includeFulfillmentService: !adminMode?.isActive,
    }), {
        keepPreviousData: true,
        ...options,
    });
    return {
        offersInfiniteQuery,
    };
};
