import styled from 'styled-components';
import { Colors } from '../../colors';
import { mediaQueries } from '../../providers/MediaQueryProvider';
const getContainerBackground = (type) => {
    return {
        success: Colors.GS1000, // using black color for both success and info types
        info: Colors.GS1000,
        error: Colors.R300,
    }[type];
};
export const Container = styled.div `
  padding: 12px 20px;
  background: ${({ $type }) => getContainerBackground($type)};

  display: flex;
  align-items: center;
  gap: 16px;

  font-size: 16px;
  line-height: 150%;

  color: ${Colors.GS100};

  ol {
    padding-left: 20px;

    list-style-type: decimal;
  }

  a {
    text-decoration: underline;
  }

  ${mediaQueries.tablet} {
    padding: 20px 16px;
    max-width: 870px;
    width: 80vw;
  }
`;
export const Message = styled.div `
  flex: 1;
`;
export const Close = styled.button `
  padding: 8px;
  margin: -8px;

  color: inherit;
`;
