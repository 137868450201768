import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const ThumbnailWrapper = styled.div `
  width: 66px;
  height: 88px;
  background-color: ${Colors.GS200};
  display: flex;
  justify-content: center;
`;
export const Icon = styled.div `
  align-self: center;
`;
export const VideoPreview = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
