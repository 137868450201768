import { Icon } from '@fourthwall/components';
import { ArrowShortRightIcon } from '@fourthwall/icons';
import { pluralize } from '@fourthwall/utils/lib/string';
import React from 'react';
import { Link } from 'react-router-dom';
import { useDateCountdown } from './hooks/useDateCountdown';
import * as S from './styled';
export const RecommendationBar = ({ title, description, href, to, state, appearance, countdownDate, }) => {
    const countdown = countdownDate && useDateCountdown(countdownDate);
    return (React.createElement(S.Container, { as: (() => {
            if (to)
                return Link;
            if (href)
                return 'a';
            return 'div';
        })(), to: to, state: state, href: href, target: href ? '_blank' : undefined, appearance: appearance, "data-testid": "RecommendationBar" },
        React.createElement(S.Main, null,
            React.createElement(S.Title, null, title),
            countdown && (React.createElement(React.Fragment, null,
                countdown.days > 0 && (React.createElement(S.Description, null,
                    countdown.days,
                    " ",
                    pluralize(countdown.days, 'day'))),
                React.createElement(S.Description, null,
                    countdown.hours,
                    " ",
                    pluralize(countdown.hours, 'hour')),
                React.createElement(S.Description, null,
                    countdown.minutes,
                    " ",
                    pluralize(countdown.minutes, 'minute')),
                countdown.days === 0 && (React.createElement(S.Description, null,
                    countdown.seconds,
                    " ",
                    pluralize(countdown.seconds, 'second'))))),
            React.createElement(S.Description, null, description)),
        React.createElement(S.IconContainer, null,
            React.createElement(Icon, { component: ArrowShortRightIcon, height: 16 }))));
};
