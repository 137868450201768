import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  height: 50%;
  width: 100%;
  padding: 32px;

  ${mediaQueries.laptop} {
    flex: 1;
    padding: 0;
    height: 100%;
    margin: 0 32px;
  }
`;
export const Title = styled.div `
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: ${Colors.GS0};
  margin-top: 12px;
`;
export const Underline = styled.span `
  text-decoration: underline;
`;
export const Subtitle = styled.div `
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.GS550};
  margin-top: 16px;
`;
