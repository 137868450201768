import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
import { Size } from './types';
const getContainerFontSize = ({ size }) => {
    if (size === Size.Small) {
        return 20;
    }
    if (size === Size.Medium) {
        return 24;
    }
    if (size === Size.Large) {
        return 28;
    }
};
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${Colors.GS200};
  font-size: ${getContainerFontSize}px;
  color: ${Colors.GS1000};
  user-select: none;
  aspect-ratio: 3 / 4;
`;
