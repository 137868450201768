import { useField } from 'formik';
import { useState } from 'react';
import { useSelectableList } from '../../../../../../../../../../SelectableList';
import { getAllProducts } from './helpers/getAllProducts';
import { useGetCreatorMessageProductsQuery } from './useGetCreatorMessageProductsQuery';
export const useCreatorMessageProducts = ({ onItemSelectionChange }) => {
    const [productsPage, setProductsPage] = useState(0);
    const { productsData, useProductsQuery } = useGetCreatorMessageProductsQuery(productsPage);
    const [, , helpers] = useField('sendTo');
    const allItems = getAllProducts(productsData?.products);
    const handleOnItemSelectionChange = (selectedItemsId, selectedItems) => {
        helpers.setValue({
            type: 'PRODUCTS',
            ids: selectedItemsId,
        });
        onItemSelectionChange(selectedItems);
    };
    const handleOnLoadMoreItems = () => {
        setProductsPage((prevState) => prevState + 1);
    };
    const { selectedListProps, selectedItemsIds, setSelectedItemsIds, pageProps } = useSelectableList({
        allItems,
        positionChangeModalTitle: 'Change position position',
        maxItemsPerPage: 5,
        onItemSelectionChange: handleOnItemSelectionChange,
    });
    const selectableProductsProps = {
        items: allItems,
        isFetching: useProductsQuery.isFetching,
        hasNextPage: !!productsData?.page.hasNextPage,
        onLoadMoreItems: handleOnLoadMoreItems,
        selectedItemsIds,
        onSave: (selectedItemsIds) => {
            setSelectedItemsIds(selectedItemsIds);
        },
        itemName: 'product',
        tableEmptyStateMsg: 'You haven’t created any products yet.',
        searchPlaceholder: 'Search products',
        positionChangeModalTitle: 'Change product position',
        tableTitle: 'Select products',
    };
    return {
        pageProps,
        selectedItemsListProps: selectedListProps,
        selectableListProps: selectableProductsProps,
    };
};
