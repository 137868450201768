import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { ArrowShortDownIcon } from '@fourthwall/icons';
import { useTiers } from '@modules/Dashboard/Memberships/modules/Perks/hooks/useTiers';
import { useTwitch } from '../../AppsTwitch/hooks/useTwitch';
import { useTwitchToken } from '../../AppsTwitch/hooks/useTwitchToken';
import { validationSchema } from '../consts';
import { useCreateTwitchMembershipsDiscountMutation } from './useCreateTwitchMembershipsDiscountMutation';
import { useSetTwitchMembershipsDiscountStatusMutation } from './useSetTwitchMembershipsDiscountStatusMutation';
import { useTwitchMembershipsDiscountQuery } from './useTwitchMembershipsDiscountQuery';
import { useUpdateTwitchMembershipsDiscountMutation } from './useUpdateTwitchMembershipsDiscountMutation';
export const useTwitchDiscounts = () => {
    const { tiers } = useTiers();
    const { twitch } = useTwitch();
    const { twitchMembershipsDiscountQuery } = useTwitchMembershipsDiscountQuery(undefined, {
        enabled: twitch.status === 'CONNECTED',
        // Error is expected, it means that there is no discount app set up
        onError: () => { },
    });
    const { createTwitchMembershipsDiscountMutation } = useCreateTwitchMembershipsDiscountMutation();
    const { updateTwitchMembershipsDiscountMutation } = useUpdateTwitchMembershipsDiscountMutation();
    const { setTwitchMembershipsDiscountStatsMutation } = useSetTwitchMembershipsDiscountStatusMutation();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    useTwitchToken();
    const getMembershipsStatusTagProps = () => {
        if (twitchMembershipsDiscountQuery.isLoading) {
            return undefined;
        }
        if (twitch.status === 'CONNECTED' && !twitchMembershipsDiscountQuery.data) {
            return {
                tagProps: {
                    appearance: 'caution',
                    size: 'large',
                    label: 'Requires setup',
                },
            };
        }
        if (twitchMembershipsDiscountQuery.isSuccess &&
            twitchMembershipsDiscountQuery.data.status === 'Active') {
            return {
                tagProps: {
                    appearance: 'success',
                    size: 'large',
                    label: 'Active',
                    icon: ArrowShortDownIcon,
                },
                initialValues: {
                    status: twitchMembershipsDiscountQuery.data.status,
                },
                onSubmit: handleStatusFormSubmit,
            };
        }
        if (twitchMembershipsDiscountQuery.isSuccess &&
            twitchMembershipsDiscountQuery.data.status === 'Inactive') {
            return {
                tagProps: {
                    appearance: 'caution',
                    size: 'large',
                    label: 'Inactive',
                    icon: ArrowShortDownIcon,
                },
                initialValues: {
                    status: twitchMembershipsDiscountQuery.data.status,
                },
                onSubmit: handleStatusFormSubmit,
            };
        }
        return undefined;
    };
    const handleFormSubmit = (values) => {
        const tiers = [];
        Object.keys(values.tiers).forEach((key) => {
            const tierId = parseInt(key);
            if (values.tiers[tierId].enabled) {
                tiers.push({
                    id: tierId,
                    // @ts-ignore
                    percentageOff: parseInt(values.tiers[tierId].percentageOff),
                });
            }
        });
        const payload = {
            accessType: values.accessType,
            percentageOff: 
            // @ts-ignore
            values.accessType === 'AllTiers' ? parseInt(values.percentageOff) : undefined,
            tiers: values.accessType === 'AllTiers' ? undefined : tiers,
        };
        if (twitchMembershipsDiscountQuery.data) {
            updateTwitchMembershipsDiscountMutation.mutate([payload], {
                onSuccess: () => twitchMembershipsDiscountQuery.refetch(),
            });
        }
        else {
            createTwitchMembershipsDiscountMutation.mutate([payload], {
                onSuccess: () => twitchMembershipsDiscountQuery.refetch(),
            });
        }
    };
    const handleStatusFormSubmit = (values) => {
        setTwitchMembershipsDiscountStatsMutation.mutate([{ status: values.status }], {
            onSuccess: () => twitchMembershipsDiscountQuery.refetch(),
        });
    };
    const getTiersValue = () => {
        if (!tiers || twitchMembershipsDiscountQuery.isLoading)
            return [];
        const tiersValue = {};
        const tiersFromQuery = twitchMembershipsDiscountQuery.isSuccess && 'tiers' in twitchMembershipsDiscountQuery.data
            ? twitchMembershipsDiscountQuery.data.tiers
            : null;
        tiers.forEach((tier) => {
            const tierFromQuery = tiersFromQuery
                ? tiersFromQuery.find((tierFromQuery) => tierFromQuery.id === tier.id)
                : null;
            tiersValue[tier.id] = {
                name: tier.title,
                percentageOff: tierFromQuery ? tierFromQuery.percentageOff : undefined,
                enabled: !!tierFromQuery,
                editable: tierFromQuery ? tierFromQuery.editable : true,
            };
        });
        return tiersValue;
    };
    const getHasActiveMembershipsSubscriptions = () => {
        if (!twitchMembershipsDiscountQuery.isSuccess) {
            return false;
        }
        if (twitchMembershipsDiscountQuery.data.accessType === 'AllTiers' &&
            twitchMembershipsDiscountQuery.data.editable === false) {
            return true;
        }
        if (twitchMembershipsDiscountQuery.data.accessType === 'SelectedTiers' &&
            twitchMembershipsDiscountQuery.data.tiers.some((tier) => tier.editable === false)) {
            return true;
        }
        return false;
    };
    return {
        connectBarProps: twitch.twitchConnect.connectBar,
        twitchStatus: twitch.status,
        confirmBarProps: {
            isLoading: createTwitchMembershipsDiscountMutation.isLoading ||
                updateTwitchMembershipsDiscountMutation.isLoading,
        },
        shop: {
            shouldRenderForm: twitch.status === 'CONNECTED',
        },
        memberships: {
            statusTagProps: getMembershipsStatusTagProps(),
            shouldRenderForm: tiers &&
                dashboardFeatureFlags.membershipsTwitchDiscounts.enabled &&
                twitch.status === 'CONNECTED',
            hasActiveSubscriptions: getHasActiveMembershipsSubscriptions(),
        },
        form: {
            initialValues: {
                percentageOff: twitchMembershipsDiscountQuery.isSuccess &&
                    twitchMembershipsDiscountQuery.data.accessType === 'AllTiers'
                    ? twitchMembershipsDiscountQuery.data.percentageOff
                    : undefined,
                accessType: (twitchMembershipsDiscountQuery.isSuccess &&
                    twitchMembershipsDiscountQuery.data.accessType) ||
                    'AllTiers',
                editable: twitchMembershipsDiscountQuery.isSuccess &&
                    twitchMembershipsDiscountQuery.data.accessType === 'AllTiers'
                    ? twitchMembershipsDiscountQuery.data.editable
                    : true,
                tiers: getTiersValue(),
            },
            validationSchema,
            onSubmit: handleFormSubmit,
        },
        isLoading: twitchMembershipsDiscountQuery.isInitialLoading ||
            createTwitchMembershipsDiscountMutation.isLoading ||
            updateTwitchMembershipsDiscountMutation.isLoading ||
            setTwitchMembershipsDiscountStatsMutation.isLoading,
    };
};
