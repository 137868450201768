import React, { useState } from 'react';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { Dashboard, TabPills } from '@components/common';
import { StatGroup } from '@fourthwall/module-analytics';
import { useTiers } from '../../../Perks/hooks/useTiers';
import { useMembershipsOnboarding } from '../Onboarding/hooks/useMembershipsOnboarding';
import { MembersChangeChart } from './components/MembersChangeChart/MembersChangeChart';
import { MembersTotalChart } from './components/MembersTotalChart/MembersTotalChart';
import { ProfitChart } from './components/ProfitChart/ProfitChart';
import { TABS } from './consts';
import * as S from './styled';
export const Analytics = () => {
    const [range, setRange] = useState(30);
    const { hasAnyTier } = useTiers();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const membershipsOnboarding = useMembershipsOnboarding({ page: 'MembershipsOverview' });
    if (!hasAnyTier) {
        return null;
    }
    if (dashboardFeatureFlags.membershipsOnboarding.enabled &&
        (membershipsOnboarding.isLoading || membershipsOnboarding.active)) {
        return null;
    }
    return (React.createElement("div", null,
        React.createElement("div", { style: { marginBottom: 24 } },
            React.createElement(TabPills, { options: TABS.map((tab) => ({ ...tab, value: `${tab.value}` })), value: `${range}`, onChange: (value) => setRange(Number(value)) })),
        React.createElement(Dashboard.Section.ScrollableContent, { hideScrollbar: true, fullWidth: true },
            React.createElement(StatGroup, null,
                React.createElement(S.Stat, null,
                    React.createElement(MembersTotalChart, { range: range })),
                React.createElement(S.Stat, null,
                    React.createElement(MembersChangeChart, { range: range })),
                React.createElement(S.Stat, null,
                    React.createElement(ProfitChart, { label: TABS.find((tab) => tab.value === range)?.profitChartLabel || 'Profit', range: range }))))));
};
