import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { FeatureFlagDecorator } from '@components/utils/FeatureFlagDecorator';
import { SECTION_NAMES } from '@constants/sectionNames';
import { Accordion, BaseContent, ButtonClean, Colors, HeadContent, Loader, Subsection, Tag, useModalContext, } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import { EmptyProductsApprovalStatus } from '@modules/Dashboard/Apps/components/EmptyProductsApprovalStatus';
import { routing } from '@utils/routing';
import startCase from 'lodash-es/startCase';
import React from 'react';
import { YouTubeVideoEntry } from '../../components/YouTubeVideoEntry';
import { mapStatusToTagProps } from '../../utils';
import { AccordionHeading } from './components/AccordionHeading';
import { MultipleConnectBar } from './components/MultipleConnectBar';
import { ProductsStatusModal } from './components/ProductsStatusModal';
import * as S from './styled';
const keys = ['approved', 'pending', 'rejected'];
export const AppsYouTubeProductShelfView = ({ status, connectBar, products, description, productsIsLoading, adminContent, }) => {
    const { open } = useModalContext();
    const renderStatusTag = () => {
        const props = mapStatusToTagProps(status);
        if (!props)
            return null;
        return (React.createElement("div", { style: { marginBottom: 20 } },
            React.createElement(Tag, { ...props, size: "large", display: "inline-flex" })));
    };
    const renderMultipleConnectBar = () => {
        if (!connectBar)
            return null;
        return (React.createElement("div", { style: { marginTop: 20 } },
            React.createElement(MultipleConnectBar, { ...connectBar })));
    };
    const renderAdminContent = () => {
        if (!adminContent)
            return null;
        return (React.createElement(Subsection, null,
            React.createElement(FeatureFlagDecorator, { enabled: true, highlight: true, description: "Fourthwall Developer \uD83D\uDD12" }, adminContent)));
    };
    const handleSeeDetails = (key) => {
        if (!products)
            return;
        open(ProductsStatusModal, {
            title: `${startCase(key)} ${pluralize(products[key].length, 'product')}`,
            products: products[key],
        });
    };
    const renderProductsList = () => {
        if (productsIsLoading) {
            return (React.createElement(S.LoadingProductsContainer, null,
                React.createElement(Loader, { size: "medium" }),
                " Synchronizing products..."));
        }
        if (!products)
            return null;
        if (keys.every((key) => !products[key].length)) {
            return React.createElement("div", { style: { marginTop: 16, color: Colors.GS600 } }, "No products connected yet");
        }
        return (React.createElement("div", { style: { marginTop: 16 } },
            React.createElement(Accordion, null, keys.map((key) => (React.createElement(Accordion.Item, { heading: React.createElement(AccordionHeading, { status: key, label: `${products[key].length} ${pluralize(products[key].length, 'product')} ${key}`, onSeeDetails: products[key].length ? () => handleSeeDetails(key) : undefined }) }))))));
    };
    const renderProducts = () => {
        if (!products && !productsIsLoading) {
            return React.createElement(EmptyProductsApprovalStatus, { appName: "YouTube" });
        }
        return (React.createElement(Subsection, { title: "Product approval status" },
            React.createElement("div", null,
                React.createElement("p", null,
                    "It can take up to 24 hours for YouTube to review new products. To choose which approved products show up under your videos, visit the Shopping tab under channel's Monetization Settings on YouTube.",
                    ' ',
                    React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.youtubeProductShelf, target: "_blank" }))),
            renderProductsList()));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: SECTION_NAMES.appYoutubeProductShelf }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: SECTION_NAMES.appYoutubeProductShelf, topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }) },
                React.createElement(Subsection, null,
                    renderStatusTag(),
                    description && React.createElement(S.Description, null, description),
                    status && (React.createElement("div", { style: { marginBlock: 20 } },
                        React.createElement(YouTubeVideoEntry, { title: "See How YouTube Product Shelf Works", description: "Watch YouTube Product Shelf app in action and learn how to quickly set it up.", size: status === 'NOT_CONNECTED' ? 'regular' : 'compact', videoId: "W7piktQJGt0" }))),
                    renderMultipleConnectBar(),
                    React.createElement("div", { style: { marginTop: 20 } },
                        React.createElement(BaseContent, { size: "xsmall", appearance: "secondary" },
                            "This app uses",
                            ' ',
                            React.createElement("a", { href: "https://developers.google.com/youtube", target: "_blank", rel: "noreferrer" }, "YouTube's API"),
                            ". By using this app you agree to also be bound by the",
                            ' ',
                            React.createElement("a", { href: "https://www.youtube.com/t/terms", target: "_blank", rel: "noreferrer" }, "YouTube Terms of Service"),
                            ". Fourthwall adheres to",
                            ' ',
                            React.createElement("a", { href: "https://developers.google.com/terms/api-services-user-data-policy", target: "_blank", rel: "noreferrer" }, "Google API Services User Data Policy"),
                            ", including the Limited Use requirements. You can revoke our access to the data you share through this app through your",
                            ' ',
                            React.createElement("a", { href: "https://myaccount.google.com/security", target: "_blank", rel: "noreferrer" }, "Google's Security Settings"),
                            ".",
                            ' ',
                            React.createElement("a", { href: "https://help.fourthwall.com/hc/en-us/articles/13331252530203-How-do-I-set-up-YouTube-Merch-Shelf-and-feature-products-on-end-screen#:~:text=Managing%20Your%20YouTube%20Data", target: "_blank", rel: "noreferrer" }, "Learn more")))),
                renderAdminContent(),
                renderProducts()))));
};
