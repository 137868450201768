import { ApiBase } from './ApiBase';
class ShippingProfilesApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getShippingProfiles = () => this.get('/shipping/shipping-profiles');
        this.saveShippingProfile = (payload) => this.post('/shipping/shipping-profiles', payload);
        this.setShippingProfilesState = (payload) => this.post('/shipping/shipping-profiles/state', payload);
        this.deleteShippingProfile = (id) => {
            return this.delete(`/shipping/shipping-profiles/${id}`);
        };
    }
}
export const ShippingProfilesApiClient = new ShippingProfilesApiClientModel();
