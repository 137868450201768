import { Checkbox, Fieldset, HeadContent, Skeleton, Subsection } from '@fourthwall/components';
import { Field, Form, Formik } from 'formik';
import times from 'lodash-es/times';
import React from 'react';
import { ConfirmBarContainer } from '../../../ConfirmBar';
import { FIELD_PROPS } from './consts';
export const EmailNotificationsView = ({ initialValues, sections, onSubmit, }) => {
    const renderFields = (fields, values) => fields.map(({ name, innerField }) => (React.createElement(React.Fragment, null,
        React.createElement(Fieldset.Row, { key: name },
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { component: Checkbox, name: name, label: FIELD_PROPS[name].label, description: FIELD_PROPS[name].description }))),
        innerField && values[name] && (React.createElement(Fieldset.Row, { key: innerField.name, inner: true },
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { component: Checkbox, name: innerField.name, label: FIELD_PROPS[innerField.name].label, description: FIELD_PROPS[innerField.name].description })))))));
    const renderForm = () => {
        if (!initialValues) {
            return sections.map(({ title, fields }) => (React.createElement(Subsection, { title: title },
                React.createElement(Skeleton, { gap: 16 }, times(fields.length, () => (React.createElement(Skeleton.Shape, { height: 45, width: "80%" })))))));
        }
        return (React.createElement(Subsection, null,
            React.createElement(Formik, { initialValues: initialValues, enableReinitialize: true, onSubmit: onSubmit }, ({ values, dirty, isSubmitting, submitForm, resetForm }) => (React.createElement(Form, null,
                sections.map(({ title, subtitle, fields }) => {
                    return (React.createElement(Subsection, { title: title },
                        subtitle && (React.createElement("div", { style: { marginBottom: 16 } },
                            React.createElement("p", null, subtitle))),
                        React.createElement(Fieldset, null, renderFields(fields, values))));
                }),
                React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isSubmitting, onConfirm: submitForm, onCancel: resetForm }))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Email notifications \u2013 Account settings" }),
        React.createElement("div", null, renderForm())));
};
