import { Fieldset, Input, Subsection, Textarea } from '@fourthwall/components';
import { Field } from 'formik';
import React from 'react';
import { FormFields } from '../../types';
import { SectionDescription } from '../SectionDescription';
import { SocialImage } from '../SocialImage/SocialImage';
export const SiteDetails = () => (React.createElement(Subsection, { title: "Site details" },
    React.createElement(SectionDescription, null, "Title and description appear on the browser window and search engines."),
    React.createElement(Fieldset, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { name: FormFields.name, component: Input, label: "Site title", required: true, maxLength: 70, showCharacterCount: true, fullWidth: true })),
        React.createElement(Fieldset.Row, { variant: "large" },
            React.createElement(Field, { name: FormFields.description, component: Textarea, label: "Site description", placeholder: "Site description", maxLength: 320, required: true, fullWidth: true })),
        React.createElement(Fieldset.Row, null,
            React.createElement(SocialImage, null)))));
