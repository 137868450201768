import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: block;
  padding: 20px;
  color: ${Colors.GS1000};
  background: ${Colors.GS200};

  ${({ hoverable }) => hoverable &&
    css `
      &:hover {
        background: ${Colors.GS300};
      }
    `}
`;
export const Icon = styled.div `
  margin-bottom: 10px;
`;
export const Title = styled.div `
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 4px;
`;
export const ContentContainer = styled.div `
  display: flex;
  align-items: flex-end;
`;
export const Content = styled.div `
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
`;
export const LinkIcon = styled.div `
  margin-left: 12px;
`;
