import { Colors } from '../../../colors';
export const defaultColors = {
    white: '#fff',
    inputError: '#e12020',
};
const defaultLightTheme = {
    colorPrimary: Colors.B500,
    colorOnPrimary: Colors.GS0,
    colorBackground: Colors.GS0,
    colorOnBackground: Colors.GS1000,
    inputBackground: Colors.GS0,
    inputBorder: Colors.GS500,
    inputErrorText: Colors.R300,
};
const defaultDarkTheme = {
    colorPrimary: Colors.B500,
    colorOnPrimary: Colors.GS0,
    colorBackground: '#000000',
    colorOnBackground: Colors.GS0,
    buttonBackground: Colors.GS800,
    buttonBackgroundHover: Colors.GS700,
    inputBackground: Colors.GS800,
    inputErrorText: Colors.R200,
};
const highContrastLightTheme = {
    colorPrimary: '#000000',
    colorOnPrimary: Colors.GS0,
    colorBackground: Colors.GS0,
    colorOnBackground: '#000000',
};
const highContrastDarkTheme = {
    colorPrimary: Colors.GS0,
    colorOnPrimary: '#000000',
    colorBackground: '#000000',
    colorOnBackground: Colors.GS0,
};
export const automatedThemeDefaults = {
    light: defaultLightTheme,
    dark: defaultDarkTheme,
    highContrastLight: highContrastLightTheme,
    highContrastDark: highContrastDarkTheme,
};
