import { PriceString } from '@fourthwall/components';
import { useAnalyticsChartQuery, AnalyticsChart, mapChartToSeries, getDateFormat, } from '@fourthwall/module-analytics';
import { getPrice } from '@fourthwall/utils/lib/common';
import dayjs from 'dayjs';
import React from 'react';
import { useDashboardPermissions } from '../../../../../../../../../components/utils/DashboardPermissions';
import { reverse, routing } from '../../../../../../../../../utils/routing';
import { analyticsQueryParams } from '../../../../../../../Analytics/hooks/useAnalyticsParams/types';
import { getFrom, getPrecision, getRangeQueryParam, to } from '../../utils';
import { useCurrentShop } from '../../../../../../../hooks/useCurrentShop';
import { Chart } from '../Chart';
export const ProfitChart = ({ label, range }) => {
    const from = getFrom(range);
    const { hasPermission } = useDashboardPermissions();
    const { currentShop } = useCurrentShop({ staleTime: Infinity });
    const { analyticsChartQuery: profitQuery } = useAnalyticsChartQuery([
        {
            from,
            to,
            precision: getPrecision(range),
            chartTypes: ['PROFIT'],
            chartEntityGroup: 'SUBSCRIPTIONS',
            sid: [currentShop?.id],
        },
    ]);
    const series = mapChartToSeries(profitQuery.data?.current.profit?.data, {
        from,
        to,
        precision: getPrecision(range),
    });
    const chartTo = hasPermission('analytics.profit')
        ? `${reverse(routing.analytics.report, {
            reportType: 'profit',
        })}?${analyticsQueryParams.chartEntity}=SUBSCRIPTIONS&${analyticsQueryParams.range}=${getRangeQueryParam(range)}`
        : undefined;
    return (React.createElement(Chart, { total: React.createElement(PriceString, { value: profitQuery.data?.current.profit?.aggregatedValue || 0 }), label: label, to: chartTo, isLoading: !profitQuery.data || profitQuery.isFetching },
        React.createElement(AnalyticsChart, { label: "Profit", series: series, height: 90, showAxes: false, formatDate: (date) => dayjs(date)
                .utc()
                .format(getDateFormat(getPrecision(range))), formatValue: (value) => getPrice(value) })));
};
