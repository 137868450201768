import { CurrencyInput } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { ConfirmBarContainer } from '../../../ConfirmBar/ConfirmBarContainer';
import * as S from './styled';
export const CostsForm = ({ variants, initialValues, isSubmitting, onSubmit }) => {
    const formik = useFormik({ initialValues, enableReinitialize: true, onSubmit });
    if (!Object.keys(initialValues).length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: formik.handleSubmit }, variants.map((variant) => (React.createElement(S.Variant, { key: variant.id },
            React.createElement(S.Label, null,
                React.createElement("div", null,
                    variant.attributesDescription,
                    status === 'UNAVAILABLE' && ` (${status.toLowerCase()})`),
                React.createElement("div", null,
                    React.createElement("small", { style: { color: '#6f6f69' } }, variant.id))),
            React.createElement("div", null,
                React.createElement(CurrencyInput, { label: "Variant Cost", ...formik.getFieldProps(variant.id) })))))),
        React.createElement(ConfirmBarContainer, { isOpen: formik.dirty || isSubmitting, isLoading: isSubmitting, onConfirm: formik.submitForm })));
};
