import isNumber from 'lodash-es/isNumber';
export const isEmail = (message) => (value) => {
    if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        return message;
    }
    return undefined;
};
export const isUntrimmedEmail = (message) => (value) => {
    if (value && !/^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/.test(value)) {
        return message;
    }
    return undefined;
};
export const isRequired = (message) => (value) => {
    if (!value && !isNumber(value)) {
        return message;
    }
    return undefined;
};
export const hasMaxLength = (maxLength, message) => (value) => {
    if (value && value.length > maxLength) {
        return message;
    }
    return undefined;
};
export const isUsZip = (message) => (value) => {
    if (value && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
        return message;
    }
    return undefined;
};
export const isValidVariantPriceValue = (value) => {
    if (typeof value === 'undefined')
        return true;
    return value === 0 || (value >= 0.99 && value <= 1000000);
};
export const isProvidingMinimumProfitOrZero = ({ value, cost, minimumProfit, }) => {
    if (!cost || !minimumProfit || value === undefined) {
        return true;
    }
    return parseFloat(((value || 0) - cost).toFixed(2)) >= minimumProfit;
};
export const max = (maxValue, message) => (value) => {
    if (!!value && parseFloat(value) > maxValue)
        return message;
};
export const POBoxLiterals = [
    'po box',
    'post office box',
    'p.o. box',
    'p.o box',
    'postal box',
    'p.o.b',
    'p.o.box',
];
export const isPOBox = (value) => !!POBoxLiterals.find((item) => value.toLowerCase().includes(item));
export const latinCharactersClasses = /\p{Script=Latin}\p{White_Space}\p{Punctuation}\p{Number}\n/gu;
export const japaneseCharactersClasses = /\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}\p{White_Space}\p{Punctuation}\p{Number}\u30A0-\u30FF\n/gu;
export const isLatin = (value) => {
    return new RegExp(`^[${latinCharactersClasses}]*$`, 'gu').test(value);
};
export const isJapanese = (value) => {
    return new RegExp(`^[${japaneseCharactersClasses}]*$`, 'gu').test(value);
};
export const isInDialect = (value, dialects) => {
    const dialectValidatorsMap = {
        latin: latinCharactersClasses,
        japanese: japaneseCharactersClasses,
    };
    const dialectsCharactersClasses = dialects
        .map((dialect) => dialectValidatorsMap[dialect].source)
        .join('');
    return new RegExp(`^[${dialectsCharactersClasses}]*$`, 'gu').test(value);
};
export const isAustralia = (value) => value === 'AU';
export const includesHTMLTags = (value) => /<\/?\s*[a-zA-Z][^>]*>/g.test(value);
export const htmlTextContentMax = (max) => (value) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = value;
    const textContent = tempElement.innerText || tempElement.textContent || '';
    return textContent.length <= max;
};
