import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';
export const Container = styled.div `
  position: relative;
`;
const cellphoneWidthStyles = css `
  width: 62.4%;

  ${mediaQueries.tablet} {
    width: 54.6%;
  }

  ${mediaQueries.laptop} {
    width: 78.5%;
  }
`;
export const CellphoneWrapper = styled.div `
  position: relative;
  width: ${({ phoneWidth }) => (phoneWidth ? `${phoneWidth}px` : cellphoneWidthStyles)};

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    width: calc(100% - 20px);
    height: 78%;
    box-shadow: 15px 15px 50px 10px ${tinycolor(Colors.GS1000).setAlpha(0.25).toString()};
  }
`;
export const Image = styled.img `
  width: 100%;
`;
export const VideoContainer = styled.div `
  position: absolute;
  left: 7%;
  right: 7%;
  top: 8.9%;
  height: 0;
`;
export const MuteWrapper = styled.div `
  position: absolute;
  z-index: 2;
  right: -45px;
  bottom: 0;
  color: ${Colors.GS600};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries.desktop} {
    right: -65px;
  }

  &:hover {
    color: ${Colors.GS200};
  }

  > svg {
    width: 24px;
    height: 24px;
  }
`;
export const Video = styled.div `
  position: relative;
  height: 0;
  padding-bottom: 177.87%;
  border-radius: 5px;
  overflow: hidden;

  video {
    width: 100%;
  }
`;
export const TwitterWidget = styled.div `
  position: absolute;
  z-index: 1;
  top: 16.27%;
  right: 0;
  width: 65.6%;
  box-shadow: 0 90px 80px -80px ${tinycolor(Colors.GS1000).setAlpha(0.5).toString()};
  min-width: 246px;

  ${mediaQueries.tablet} {
    top: 21.75%;
    width: 50%;
  }

  ${mediaQueries.laptop} {
    top: 30%;
    width: 61%;
  }

  ${mediaQueries.desktop} {
    top: 31%;
    width: 50%;
  }
`;
export const TwitterMessage = styled.p `
  font-size: 14px;
  line-height: 150%;
`;
export const TwitterVideo = styled.div `
  > * {
    margin-top: 16px;
  }
`;
