import { downloadTextFile } from '@fourthwall/utils/lib/downloadCsvFile';
import { useMembersDataQuery } from './useMembersDataQuery';
export const useMembersDataExport = () => {
    const { membersDataQuery } = useMembersDataQuery();
    const exportMembersData = async () => {
        const membersData = await membersDataQuery.refetch();
        if (membersData.data) {
            downloadTextFile(membersData.data, 'members.csv');
        }
    };
    return {
        exportMembersData,
        isMembersDataLoading: membersDataQuery.isFetching,
    };
};
