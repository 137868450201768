import { Button, Input, ModalBox, useModalContext } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { initialValues, validationSchema } from './consts';
import * as S from './EmailSendModal.styled';
export const EmailSendModal = ({ onSubmit }) => {
    const { close } = useModalContext();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(ModalBox, { title: "Send DNS records list via email", size: "small", buttons: [
                React.createElement(Button, { label: "Send", appearance: "primary", type: "submit", disabled: !formik.isValid || !formik.dirty }),
                React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close }),
            ], onClose: close },
            React.createElement(S.Description, null, "Enter your email and we\u2019ll send you a copy of the DNS records."),
            React.createElement(Input, { label: "your@email.com", required: true, ...formik.getFieldProps('email') }))));
};
