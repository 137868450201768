import { ApiBase } from '../ApiBase';
class BespokeProductsModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getAll = () => {
            return this.get(`/bespoke-products`);
        };
    }
}
export const BespokeProductsApiClient = new BespokeProductsModel();
