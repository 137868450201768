import { AnimatePresence, domAnimation, LazyMotion } from 'framer-motion';
import React from 'react';
import * as S from './Drawer.styled';
const animationByPosition = {
    left: {
        initial: { x: '-100%' },
        animate: { x: 0 },
        exit: { x: '-100%' },
    },
    right: {
        initial: { x: '100%' },
        animate: { x: 0 },
        exit: { x: '100%' },
    },
};
export const Drawer = ({ children }) => {
    return (React.createElement(LazyMotion, { features: domAnimation },
        React.createElement(AnimatePresence, { mode: "wait" }, children)));
};
const DrawerBody = ({ position, width = 320, overlay, children }) => {
    return (React.createElement(S.Drawer, null,
        React.createElement(S.Body, { key: "Drawer.Body", position: position, width: width, transition: { ease: [0.65, 0, 0.35, 1] }, ...animationByPosition[position] }, children),
        overlay));
};
const DrawerOverlay = ({ onClick }) => {
    return (React.createElement(S.Overlay, { initial: { opacity: 0 }, animate: { opacity: 0.4 }, exit: { opacity: 0 }, onClick: onClick, key: "Drawer.Overlay", "data-testid": "Drawer.Overlay" }));
};
const DrawerHeader = ({ size = 'medium', children }) => {
    return React.createElement(S.Header, { size: size }, children);
};
Drawer.Body = DrawerBody;
Drawer.Overlay = DrawerOverlay;
Drawer.Header = DrawerHeader;
Drawer.Navigation = S.Navigation;
Drawer.Content = S.Content;
Drawer.Section = S.Section;
Drawer.Footer = S.Footer;
