import unescape from 'lodash-es/unescape';
import React from 'react';
import { Link } from 'react-router-dom';
import { FwOriginalIcon } from '@fourthwall/icons';
import { Icon } from '../Icon';
import * as S from './styled';
export const DashboardLogo = ({ name, src, to, href, appearance = 'default', hoverable = false, color, }) => {
    const getComponent = () => {
        if (to) {
            return Link;
        }
        if (href) {
            return 'a';
        }
        return 'div';
    };
    return (React.createElement(S.Container, { as: getComponent(), to: to, href: href, "$appearance": appearance, "$hoverable": hoverable, "$color": color, "$align": src ? 'center' : 'flex-start', "data-testid": "DashboardLogo" },
        React.createElement(S.LogoContainer, null,
            React.createElement(Icon, { component: FwOriginalIcon, width: 28, height: 17 })),
        !src && name && (React.createElement(S.NameContainer, null,
            React.createElement(S.Name, null, name && unescape(name)))),
        src && (React.createElement(S.ImageContainer, null,
            React.createElement(S.Image, { src: src, alt: name && unescape(name) })))));
};
