import * as yup from 'yup';
import { FormFields } from './types';
export const validationSchema = (validation) => yup.object().shape({
    [FormFields.name]: yup
        .string()
        .required()
        .test('name', 'There’s already a theme installed with this name.', (value) => {
        if (value &&
            validation.excludedNames &&
            validation.excludedNames.indexOf(value?.trim()) !== -1) {
            return false;
        }
        return true;
    }),
});
