import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const FileListWrapper = styled.div `
  width: 100%;
`;
export const SeparatorLine = styled.hr `
  height: 1px;
  background: ${Colors.GS400};
  margin-bottom: 16px;
`;
