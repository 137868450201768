export const getViewState = (uploadFileMutation, file) => {
    if (!file) {
        return 'IDLE';
    }
    if (uploadFileMutation.isLoading) {
        return 'LOADING';
    }
    if (uploadFileMutation.isError) {
        return 'ERROR';
    }
    if (uploadFileMutation.isSuccess) {
        return 'SUCCESS';
    }
    return 'IDLE';
};
