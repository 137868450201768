import { Field } from 'formik';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonClean, Fieldset, Input, Subsection } from '@fourthwall/components';
import { TinyMCE } from '@components/adapters/inputs/TinyMCE';
import { DashboardPermissionGuard } from '@components/utils/DashboardPermissions';
import { reverse, routing } from '@utils/routing';
import * as S from './styled';
export const ProductDetails = ({ productId, isFormEditable }) => {
    const location = useLocation();
    return (React.createElement(Subsection, { title: "Product details" },
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: "name", component: Input, label: "Product name", disabled: !isFormEditable, fluid: true }))),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: "description", component: TinyMCE, placeholder: "Description (optional)", buttons: { code: true }, disabled: !isFormEditable, resize: true }))),
            React.createElement(DashboardPermissionGuard, { permission: "products.edit.additionalSections" },
                React.createElement(S.Buttons, null,
                    React.createElement(S.ButtonContainer, null,
                        React.createElement(ButtonClean, { label: "Edit additional sections", to: {
                                pathname: reverse(routing.products.all.product.sections, { productId }),
                                search: location.search,
                            } })))))));
};
