import Yup from '@fourthwall/utils/lib/yup';
import { FormFields } from './types';
export const validationSchema = Yup.object().shape({
    [FormFields.name]: Yup.string().required().max(70).noHTML(),
    [FormFields.customerSupportEmail]: Yup.string().email(),
    [FormFields.description]: Yup.string().required().max(320).noHTML(),
    [FormFields.creatorName]: Yup.string().required().max(100).noHTML(),
    [FormFields.themeAutoUpdateHour]: Yup.string().required(),
    [FormFields.socialImage]: Yup.string(),
    [FormFields.socialImageType]: Yup.string().oneOf(['Keep', 'Remove', 'New']),
});
