import { APPS_MAP } from './constants';
export const filterSalesApp = (app) => {
    return app.recommendations ? app.recommendations.some(({ type }) => type === 'SALES') : false;
};
export const sortApp = (a, b) => {
    const aRecommendation = a.recommendations.find(({ type }) => type === 'SALES');
    const bRecommendation = b.recommendations.find(({ type }) => type === 'SALES');
    if (!aRecommendation || !bRecommendation)
        return 0;
    return aRecommendation.order - bRecommendation.order;
};
export const mapApp = (app) => ({
    flag: APPS_MAP[app.app].flag,
    connected: app.status !== 'NOT_CONNECTED',
    thumbnail: APPS_MAP[app.app].thumbnail,
    signet: APPS_MAP[app.app].signet,
    name: APPS_MAP[app.app].name || app.app,
    description: APPS_MAP[app.app].description,
    to: APPS_MAP[app.app].to,
    docs: APPS_MAP[app.app].docs,
});
