import { reverse } from 'named-urls';
import React from 'react';
import { routing } from '../../../../utils/routing';
import { EditorPanel } from '../../components/EditorPanel';
import { Tabs } from '../../components/Tabs/Tabs';
import { usePages } from '../../hooks/usePages';
import * as S from './styled';
export const NavigationTabs = () => {
    const { currentPage } = usePages();
    return (React.createElement(S.Container, null,
        React.createElement(EditorPanel.Header, { title: React.createElement(Tabs, null,
                React.createElement(Tabs.Item, { label: "Layout", to: reverse(routing.themeEditor.layout.page.self, {
                        pageId: currentPage?.id || 'index',
                    }) }),
                React.createElement(Tabs.Item, { label: "Style", to: routing.themeEditor.general.self }),
                React.createElement(Tabs.Item, { label: "Pages", to: routing.themeEditor.navigations.self }),
                React.createElement(Tabs.Item, { label: "Theme", to: routing.themeEditor.themes.self })) })));
};
