import { getNumberString, NumberString } from '@fourthwall/components';
import { AnalyticsChart, useMembershipsChartQuery, mapChartToSeries, getDateFormat, } from '@fourthwall/module-analytics';
import dayjs from 'dayjs';
import React from 'react';
import { useDashboardPermissions } from '../../../../../../../../../components/utils/DashboardPermissions';
import { reverse, routing } from '../../../../../../../../../utils/routing';
import { analyticsQueryParams } from '../../../../../../../Analytics/hooks/useAnalyticsParams/types';
import { getFrom, getPrecision, getRangeQueryParam, to } from '../../utils';
import { Chart } from '../Chart';
import { useCurrentShop } from '../../../../../../../hooks/useCurrentShop';
export const MembersTotalChart = ({ range }) => {
    const from = getFrom(range);
    const { hasPermission } = useDashboardPermissions();
    const { currentShop } = useCurrentShop({ staleTime: Infinity });
    const { membershipsChartQuery: membersTotalQuery } = useMembershipsChartQuery([
        {
            from,
            to,
            precision: getPrecision(range),
            chartTypes: ['MEMBERS_TOTAL'],
            sid: [currentShop?.id],
        },
    ]);
    const series = mapChartToSeries(membersTotalQuery.data?.current.totalMembers?.data, {
        from,
        to,
        precision: getPrecision(range),
    });
    const chartTo = hasPermission('analytics.totalMembers')
        ? `${reverse(routing.analytics.report, {
            reportType: 'memberships-total-members',
        })}?${analyticsQueryParams.range}=${getRangeQueryParam(range)}`
        : undefined;
    return (React.createElement(Chart, { total: React.createElement(NumberString, { value: membersTotalQuery.data?.current.totalMembers?.aggregatedValue || 0 }), label: "Total members", to: chartTo, isLoading: !membersTotalQuery.data || membersTotalQuery.isFetching },
        React.createElement(AnalyticsChart, { label: "Total members", series: series, height: 90, showAxes: false, formatDate: (date) => dayjs(date)
                .utc()
                .format(getDateFormat(getPrecision(range))), formatValue: getNumberString })));
};
