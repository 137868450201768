import React from 'react';
import { DropzoneSubsection } from '../DropzoneSubsection/DropzoneSubsection';
import { MessageBodySubsection } from '../MessageBodySubsection/MessageBodySubsection';
import { SendToSubsection } from '../SendToSubsection/SendToSubsection';
import { useDashboardFeatureFlags } from '../../../../../../../../../components/utils/DashboardFeatureFlags';
export const CreatorMessagesSendFormContent = ({ vm, setFinalSupportersCount, }) => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    return (React.createElement(React.Fragment, null,
        dashboardFeatureFlags.messagesSendTo.enabled && (React.createElement(SendToSubsection, { vm: vm, setFinalSupportersCount: setFinalSupportersCount })),
        React.createElement(MessageBodySubsection, { vm: { disabled: vm.disabled } }),
        React.createElement(DropzoneSubsection, null)));
};
