import { PhotoIcon, TypographyIcon, VideoIcon } from '@fourthwall/icons';
export const TYPE_BUTTONS = [
    {
        type: 'Text',
        label: 'Text',
        icon: TypographyIcon,
    },
    {
        type: 'Image',
        label: 'Image',
        icon: PhotoIcon,
    },
    {
        type: 'Video',
        label: 'Video',
        icon: VideoIcon,
    },
];
