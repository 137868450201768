import { Dropdown } from '@fourthwall/components';
import React from 'react';
import { QuickActionsDropdownItem } from './components/QuickActionsDropdownItem';
import { useQuickActions } from './hooks/useQuickActions';
export const QuickActionsDropdown = ({ children }) => {
    const { dropdownItems } = useQuickActions();
    if (!dropdownItems.length)
        return null;
    return (React.createElement(Dropdown, { content: dropdownItems.map(({ ...props }, index) => (React.createElement(QuickActionsDropdownItem, { key: index, ...props }))), position: "bottom-end", testid: "QuickActionsDropdown" }, children));
};
