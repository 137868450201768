import styled, { css } from 'styled-components';
import { Colors } from '@fourthwall/components';
import { ScrollContent } from '@components/common/ScrollContent';
export const Container = styled.div `
  ${({ disabled }) => disabled &&
    css `
      opacity: 0.3;
    `}
`;
export const LoaderContainer = styled.div `
  display: flex;
  justify-content: center;
  min-height: 100px;

  ${({ height }) => height &&
    css `
      height: ${height}px;
    `}
`;
export const Header = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  margin-top: 12px;
  border-top: 1px solid ${Colors.GS400};
  border-bottom: 1px solid ${Colors.GS400};
`;
export const HeaderContentWrapper = styled.div `
  display: flex;
  align-items: center;
`;
export const HeaderContent = styled.p `
  line-height: 1.5;
`;
export const SelectedItemsList = styled(ScrollContent) `
  ${({ maxHeight }) => maxHeight &&
    css `
      position: relative;
      padding-right: 15px;
      margin-right: -30px;
    `}
`;
