import React from 'react';
import { CostItem, EstimatedFees, EstimatedProfit, SellingPrice, } from '../../../../components/CostItem';
import * as S from './styled';
export const AccordionPanel = ({ cost, price, profit, feesValue, includeFeesValue, autoAdjustedSellingPrice, }) => {
    return (React.createElement(S.Container, null,
        React.createElement(SellingPrice, { value: price || 0, autoAdjusted: autoAdjustedSellingPrice }),
        React.createElement(CostItem, { name: "Manufacturing costs per item", value: cost || 0, negative: true }),
        includeFeesValue && React.createElement(EstimatedFees, { value: feesValue }),
        React.createElement(EstimatedProfit, { value: profit, withSeparator: false })));
};
