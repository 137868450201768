import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Title = styled.div `
  width: 100%;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid ${Colors.GS400};
  margin-bottom: 8px;

  ${mediaQueries.tablet} {
    width: auto;
    padding: 0;
    font-weight: 400;
    border-bottom: none;
  }
`;
