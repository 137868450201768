import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const ScrollableContainer = styled.div `
  flex: 1;
  width: 100%;
  overflow-y: auto;
`;
export const ThemesGrid = styled.div `
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 49px;
  row-gap: 42px;

  ${mediaQueries.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }

  ${mediaQueries.laptop} {
    grid-template-columns: repeat(auto-fit, minmax(414px, 1fr));
    padding: 32px;
  }

  ${mediaQueries.desktop} {
    grid-template-columns: repeat(auto-fit, minmax(496px, 1fr));
  }
`;
export const PreviewContainer = styled.div `
  flex: 1;
  height: 100%;
  width: 100%;
`;
export const PreviewToolsContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 36px;
`;
export const PreviewHeaderLeftSide = styled.div `
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  gap: 24px;
`;
export const CloseButtonWrapper = styled.div `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;

  ${mediaQueries.laptop} {
    margin-left: 32px;
  }
`;
