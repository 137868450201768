import { Checkbox, Fieldset, InputUI, RadioButton } from '@fourthwall/components';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { InnerField } from '../../../../../components/common/InnerField';
import * as S from './styled';
export const ProductMembershipSettings = ({ membershipTiers, disabled = false }) => {
    const { values, errors, touched } = useFormikContext();
    const renderMembersOnlyTiers = () => {
        if (values.membersOnlyType !== 'SELECTED_TIERS') {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            membershipTiers?.map((tier) => (React.createElement(InnerField, { key: tier.id },
                React.createElement(InnerField, { fluid: true },
                    React.createElement(Field, { component: Checkbox, name: `membersOnlyTierIds[${tier.id}]`, label: tier.name, disabled: disabled }))))),
            React.createElement(InnerField, { fluid: true }, errors.membersOnlyTierIds && touched.membersOnlyTierIds && (
            // @ts-ignore
            React.createElement(InputUI.Error, null, errors.membersOnlyTierIds)))));
    };
    const renderMembersOnlyType = () => {
        if (!values.withMembersOnly) {
            return null;
        }
        return (React.createElement(S.RadioWrapper, null,
            React.createElement(InnerField, { fluid: true },
                React.createElement(Field, { component: RadioButton, name: "membersOnlyType", checkedValue: "ALL_TIERS", label: "All tiers", disabled: disabled })),
            React.createElement(InnerField, { fluid: true },
                React.createElement(Field, { component: RadioButton, name: "membersOnlyType", checkedValue: "SELECTED_TIERS", label: "Selected tiers", disabled: disabled })),
            renderMembersOnlyTiers()));
    };
    if (!membershipTiers?.length) {
        return null;
    }
    return (React.createElement(S.Wrapper, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { component: Checkbox, name: "withMembersOnly", label: "Make this a members-only product", disabled: disabled })),
        renderMembersOnlyType()));
};
