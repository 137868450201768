import React from 'react';
import { useParams } from 'react-router-dom';
import { ProductSectionsView } from './ProductSectionsView';
import { useUpdateOfferSectionsMutation } from './mutations/useUpdateOfferSectionsMutation';
import { useOfferQuery } from '../queries/useOfferQuery';
import { isDigitalFulfillment } from '../store/product/utils';
export const ProductSectionsContainer = () => {
    const { productId = '' } = useParams();
    const { offerQuery, setOfferData } = useOfferQuery([productId]);
    const productIsFetching = offerQuery.isLoading;
    const sections = offerQuery.data?.additionalSections || [];
    const productName = offerQuery.data?.name;
    const fulfillmentService = offerQuery.data?.fulfillingService || 'FULFILLED_BY_CREATOR';
    const isDigital = isDigitalFulfillment(fulfillmentService);
    const { updateOfferSectionsMutation } = useUpdateOfferSectionsMutation();
    const handleSubmit = (payload) => {
        updateOfferSectionsMutation.mutate([{ offerId: productId }, payload], {
            onSuccess: (data) => {
                setOfferData(data);
            },
        });
    };
    return (React.createElement(ProductSectionsView, { sections: sections, productName: productName, productId: productId, isDigital: isDigital, productIsFetching: productIsFetching, onSubmit: handleSubmit }));
};
