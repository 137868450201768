import { CreatorMessagesApiClient } from '@fourthwall/services/lib/CreatorMessagesApiClient';
import { useQuery } from '@tanstack/react-query';
export const useGetCreatorMessageProductsQuery = (page = 0) => {
    const useProductsQuery = useQuery({
        queryKey: ['useGetCreatorMessageProductsQuery', page],
        queryFn: async () => {
            const { data } = await CreatorMessagesApiClient.getCreatorMessageProducts(page);
            return data;
        },
        keepPreviousData: true,
        staleTime: 60_000,
    });
    return {
        useProductsQuery,
        productsData: useProductsQuery.data,
    };
};
