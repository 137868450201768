import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 54px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${Colors.GS200};
  box-shadow: inset 0px 0px 0px 1px ${Colors.GS500};
`;
export const Image = styled.img `
  width: 100%;
  height: auto;
`;
export const ShipmentContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const ShipmentName = styled.div `
  display: flex;
  align-items: center;
`;
export const ShipmentItemsQuantity = styled.div `
  color: ${Colors.GS600};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.14px;
`;
export const Info = styled.div `
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const ShipmentDetails = styled.div `
  display: flex;
  flex-direction: column;
`;
