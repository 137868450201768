import React from 'react';
import { Colors } from '../../colors';
import { PriceString } from '../../functional/PriceString';
const getAppearance = (value) => {
    if (value > 0)
        return 'positive';
    if (value < 0)
        return 'negative';
    return 'neutral';
};
export const ProfitString = ({ value }) => {
    const appearance = getAppearance(value);
    const symbol = {
        positive: '+',
        negative: '',
        neutral: '',
    }[appearance];
    const color = {
        positive: Colors.G300,
        negative: Colors.R300,
        neutral: 'inherit',
    }[appearance];
    return (React.createElement("span", { style: { color } },
        symbol,
        React.createElement(PriceString, { value: value })));
};
