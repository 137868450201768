import { Avatar } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const MobileCell = ({ name, avatarUrl, shadowbanned, children }) => {
    const getAvatar = () => {
        if (!avatarUrl)
            return;
        return avatarUrl;
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.AvatarContainer, null,
            React.createElement(Avatar, { name: name, imageUrl: getAvatar() })),
        React.createElement(S.DetailsContainer, null,
            name,
            shadowbanned && React.createElement(S.Info, null, "Shadowbanned"),
            children)));
};
