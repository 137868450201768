import { Manager, Popper as ReactPopper, Reference } from 'react-popper';
import React, { useRef } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { createPortal } from 'react-dom';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import * as S from './Popper.styled';
export const Popper = ({ isOpen, children, referenceComponent, popperProps, style: childrenStyle, onClose, }) => {
    const media = useMediaQueryContext();
    const containerRef = useRef(null);
    const displayAsBottomSheet = !media.tablet;
    const zIndex = childrenStyle?.zIndex || 10;
    useOutsideClick(containerRef, () => {
        !displayAsBottomSheet && onClose();
    });
    const renderChildren = ({ ref, style, placement: dataPlacement }) => {
        const styles = displayAsBottomSheet
            ? {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex,
                ...childrenStyle,
            }
            : { ...style, zIndex, ...childrenStyle };
        if (!displayAsBottomSheet) {
            return (React.createElement("div", { ref: ref, "data-placement": dataPlacement, style: styles }, children));
        }
        return createPortal(React.createElement("div", { id: "bottomsheet" },
            React.createElement(S.Backdrop, { onClick: (e) => {
                    e.preventDefault();
                    onClose();
                }, style: { zIndex, margin: 0 } }),
            React.createElement(S.BottomInset, { style: { zIndex } }),
            React.createElement("div", { ref: ref, "data-placement": dataPlacement, style: styles },
                React.createElement(RemoveScroll, null, children))), document.body);
    };
    const renderContent = () => {
        if (!isOpen)
            return null;
        return React.createElement(ReactPopper, { ...popperProps }, renderChildren);
    };
    return (React.createElement(Manager, null,
        React.createElement("div", { ref: containerRef },
            React.createElement(Reference, null, ({ ref }) => React.createElement("div", { ref: ref }, referenceComponent)),
            renderContent())));
};
