import React from 'react';
import { TeamMember } from '../TeamMember';
import * as S from './styled';
import { TeamMemberDropdown } from '../TeamMemberDropdown';
export const TeamMemberListItem = ({ data, dropdownProps }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.TeamMemberWrapper, null,
            React.createElement(TeamMember, { data: data })),
        React.createElement(TeamMemberDropdown, { data: data, ...dropdownProps })));
};
