import { BillingApiClient } from '@fourthwall/services/lib/BillingApiClient';
import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
import { createQueryHook } from '@fourthwall/utils';
export const useCostsBreakdownQuery = createQueryHook(BillingApiClient.getDonationCostsBreakdown, {
    queryKey: ['donationCostsBreakdown'],
    select: (data) => {
        if (data.offers?.entries) {
            data.offers.entries = data.offers.entries.map((entry) => ({
                ...entry,
                title: decodeHtmlEntities(entry.title),
            }));
        }
        if (data.digitals?.entries) {
            data.digitals.entries = data.digitals.entries.map((entry) => ({
                ...entry,
                title: decodeHtmlEntities(entry.title),
            }));
        }
        return data;
    },
});
