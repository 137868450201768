import { Icon, Loader } from '@fourthwall/components';
import { ArrowLongLeftIcon } from '@fourthwall/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';
import * as S from './EditorPanelHeader.styled';
export const EditorPanelHeader = ({ title, to, actionContent, isLoading, onClick }) => {
    const renderIcon = () => {
        if (isLoading) {
            return (React.createElement(S.LoaderContainer, null,
                React.createElement(Loader, null)));
        }
        if (onClick) {
            return (React.createElement(S.BackButton, { onClick: onClick, "data-testid": "EditorPanelHeader.ArrowIcon" },
                React.createElement(Icon, { component: ArrowLongLeftIcon, height: 16 })));
        }
        if (to) {
            return (React.createElement(S.BackButton, { as: NavLink, to: to, "data-testid": "EditorPanelHeader.ArrowIcon" },
                React.createElement(Icon, { component: ArrowLongLeftIcon, height: 16 })));
        }
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.Wrapper, null,
            renderIcon(),
            React.createElement(S.Title, null, title),
            actionContent && React.createElement("div", null, actionContent))));
};
