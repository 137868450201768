import { Button, ButtonClean, ButtonGroup, Dropdown, Input } from '@fourthwall/components';
import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as S from './styled';
import { getValidationSchema } from './validationSchema';
export const QuantityDropdown = ({ title, description, disabled, quantity, maxValue, onSubmit, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const initialValues = {
        quantity: parseInt(quantity),
    };
    const handleClose = () => setIsOpen(false);
    const handleSubmit = (values) => {
        onSubmit(values.quantity.toString());
        handleClose();
    };
    return (React.createElement(Dropdown, { isOpen: isOpen, content: React.createElement(Formik, { initialValues: initialValues, validationSchema: getValidationSchema(maxValue), onSubmit: handleSubmit }, ({ submitForm }) => (React.createElement(Form, null,
            React.createElement(S.Container, null,
                React.createElement(S.Heading, null,
                    React.createElement("div", null, title),
                    description && React.createElement(S.Description, null, description)),
                React.createElement(Field, { name: "quantity", component: Input, type: "number", size: "large", fullWidth: true, label: "Quantity", min: 1, max: maxValue, disabled: disabled }),
                React.createElement(ButtonGroup, { direction: "column", fullWidth: true, space: "medium" },
                    React.createElement(Button, { type: "submit", appearance: "primary", label: "Save", fullWidth: true, onClick: submitForm }),
                    React.createElement(Button, { appearance: "secondary", label: "Cancel", fullWidth: true, onClick: handleClose })))))), onClose: handleClose },
        React.createElement(ButtonClean, { label: quantity, onClick: () => setIsOpen(true) })));
};
