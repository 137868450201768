import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  --background: ${Colors.GS0};
  --border-color: ${Colors.GS400};
  --hover-background: ${Colors.GS200};
  --hover-border-color: ${Colors.GS500};

  padding: ${12 - 1}px ${20 - 1}px;
  background: var(--background);
  border: 1px solid var(--border-color);
  border-radius: 2px;

  display: flex;
  align-items: center;
  gap: 16px;

  // to make ellipsis work in flexbox
  min-width: 0;

  &:hover {
    background: var(--hover-background);
    border-color: var(--hover-border-color);
  }

  ${({ appearance }) => appearance === 'warning' &&
    css `
      --background: rgba(250, 229, 167, 0.3);
      --border-color: ${Colors.Y200};
      --hover-background: ${Colors.Y100};
      --hover-border-color: ${Colors.Y300};
    `};
`;
export const Label = styled.div `
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
