import React from 'react';
import { useParams } from 'react-router-dom';
import { DateString, useModalContext } from '@fourthwall/components';
import { getPrice } from '@fourthwall/utils';
import { DonationCostsBreakdownModal } from '@modules/Dashboard/Contributions/modules/Donation/DonationCostsBreakdownModal';
import { DonationView } from './DonationView';
import { useDonationQuery } from './queries/useDonationQuery';
export const DonationContainer = () => {
    const { open } = useModalContext();
    const { donationId = '' } = useParams();
    const { donationQuery } = useDonationQuery([{ donationId }]);
    const title = donationQuery.data?.username
        ? `${donationQuery.data.username}'s donation`
        : 'Donation';
    const subtitle = donationQuery.data ? (React.createElement(DateString, { date: donationQuery.data.createdAt, format: "[Placed] MMM D, YYYY [at] h:mm A" })) : ('');
    const timelineEvents = donationQuery.data
        ? [
            {
                id: donationQuery.data.id,
                happenedAt: donationQuery.data.createdAt,
                description: `${getPrice(donationQuery.data.donation.value)} donation made by ${donationQuery.data.username || donationQuery.data.email}`,
            },
        ]
        : undefined;
    const handleShowBreakdownClick = () => {
        open(DonationCostsBreakdownModal, { donationId });
    };
    return (React.createElement(DonationView, { title: title, subtitle: subtitle, value: donationQuery.data?.donation, profit: donationQuery.data?.profit, username: donationQuery.data?.username || '', email: donationQuery.data?.email || '', message: donationQuery.data?.message ? `"${donationQuery.data?.message}"` : '', timelineEvents: timelineEvents, isFetching: donationQuery.isInitialLoading, onBreakdownClick: handleShowBreakdownClick }));
};
