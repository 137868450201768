// NOTE: https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { lazy } from 'react';
export const lazyWithRetry = (componentImport) => {
    return lazy(() => __awaiter(void 0, void 0, void 0, function* () {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem('page-has-been-force-refreshed') || 'false');
        try {
            const component = yield componentImport();
            window.localStorage.setItem('page-has-been-force-refreshed', 'false');
            return component;
        }
        catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem('page-has-been-force-refreshed', 'true');
                return window.location.reload();
            }
            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    }));
};
