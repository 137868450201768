const countDecimals = (number) => {
    const decimals = number.split('.')[1];
    return (decimals && decimals.length) || 0;
};
export const validateValue = (value, step, maxValue) => {
    if (parseFloat(value) > maxValue) {
        return false;
    }
    if (countDecimals(value) > countDecimals(step.toString())) {
        return false;
    }
    return true;
};
