import { withFormikSupport } from '@fourthwall/components';
import React from 'react';
import { getSocialHandler, getSocialIcon } from '../../utils/socials';
import { InputComponent } from '../Input/Input';
export const SocialLinkComponent = (props) => {
    const { data, onSettingUpdate } = props;
    const handleSettingUpdate = (id, newValue) => {
        onSettingUpdate(id, getSocialHandler(id)?.(newValue) || newValue);
    };
    return (React.createElement(InputComponent, { ...props, icon: getSocialIcon(data.id), onSettingUpdate: handleSettingUpdate }));
};
export const SocialLink = withFormikSupport(SocialLinkComponent);
