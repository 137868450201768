import styled from 'styled-components';
import { Colors } from '../../colors';
export const Headline = styled.button `
  width: 100%;
  padding-top: 6px;
  padding-bottom: 5px;
  background: ${({ hovered }) => (hovered ? Colors.GS400 : Colors.GS200)};

  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${Colors.GS600};

  position: relative;
`;
export const HeadlineIcon = styled.div `
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;
export const Row = styled.div `
  border: 1px solid ${Colors.GS400};

  display: flex;

  & + & {
    margin-top: -1px;
  }

  &:hover {
    background: ${Colors.GS200};
  }
`;
export const Cell = styled.div `
  padding: 20px 16px;

  flex: ${({ flex }) => flex};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${Colors.GS1000};
`;
export const Footer = styled.footer `
  margin-top: -1px;
  padding: 18px 16px;
  border: 1px solid ${Colors.GS400};
  border-bottom: 0;
`;
