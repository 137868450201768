import { Button, Fieldset, Input, ModalBox, RadioButton } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React, { useEffect } from 'react';
import { validationSchema } from './consts';
import * as S from './styled';
import { FormFields } from './types';
import { useDashboardFeatureFlags } from '../../../../../../../components/utils/DashboardFeatureFlags';
import { FeatureFlagDecorator } from '../../../../../../../components/utils/FeatureFlagDecorator';
import { TeamMember } from '../TeamMember';
export const RoleModal = ({ type = 'invitation', name, initialValues, selectableRoles, close, onSubmit, }) => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const inputRef = React.useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);
    const formik = useFormik({
        initialValues: {
            email: initialValues?.email,
            role: initialValues?.role || selectableRoles[0].checkedValue,
        },
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
            close();
        },
    });
    const getTitle = () => {
        if (type === 'invitation') {
            return 'Invite new team member';
        }
        return 'Change team member role';
    };
    const getSubmitLabel = () => {
        if (type === 'invitation') {
            return 'Invite';
        }
        return 'Change';
    };
    const renderInput = () => {
        if (type === 'invitation') {
            return (React.createElement(Input, { inputRef: inputRef, type: "email", label: "Email address", suggestion: dashboardFeatureFlags.roles.enabled
                    ? undefined
                    : 'New team members have full admin privileges', fullWidth: true, ...formik.getFieldProps(FormFields.email) }));
        }
        return (formik.values.email && (React.createElement(TeamMember, { data: { name: name || '', email: formik.values.email, isYou: false } })));
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(ModalBox, { title: getTitle(), buttons: [
                React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close }),
                React.createElement(Button, { label: getSubmitLabel(), appearance: "primary", type: "submit", disabled: !formik.isValid || !formik.dirty }),
            ], size: "small", onClose: close },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(S.InputContainer, null, renderInput())),
                React.createElement(FeatureFlagDecorator, { spacing: "small", ...dashboardFeatureFlags.roles },
                    React.createElement(S.RolesContainer, null,
                        React.createElement(S.RolesTitle, null, "Select a role"),
                        selectableRoles.map((selectableRole) => (React.createElement(Fieldset.Row, { key: selectableRole.checkedValue },
                            React.createElement(RadioButton, { ...selectableRole, ...formik.getFieldProps(FormFields.role) }))))))))));
};
