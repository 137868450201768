import { Button, ButtonAppearance, ButtonClean, ButtonGroup, Checkbox, Input, NumberString, Popper, Tabs, useMediaQueryContext, } from '@fourthwall/components';
import { isIntegerString, isPositiveIntegerString } from '@fourthwall/utils/lib/string';
import isNil from 'lodash-es/isNil';
import React, { useEffect, useRef, useState } from 'react';
import * as S from './styled';
const UNLIMITED_VALUE = 'Unlimited';
export const StockTooltip = ({ quantity, disabled = false, onChange }) => {
    const media = useMediaQueryContext();
    const inputRef = useRef(null);
    const initialQuantityValue = !isNil(quantity) ? quantity.toString() : UNLIMITED_VALUE;
    const [newQuantity, setNewQuantity] = useState(initialQuantityValue);
    const [adjustBy, setAdjustBy] = useState('0');
    const [isOpen, setOpen] = useState(false);
    const isUnlimited = newQuantity === UNLIMITED_VALUE;
    const isNewQuantityInvalid = !isUnlimited && !isPositiveIntegerString(newQuantity);
    const isAdjustByInvalid = !isUnlimited && (!isIntegerString(adjustBy) || parseInt(newQuantity) < 0);
    const isDisabled = isNewQuantityInvalid || isAdjustByInvalid;
    const setAdjustedQuantity = (adjustValue) => {
        const adjustParsed = isIntegerString(adjustValue) ? parseInt(adjustValue) : 0;
        const initialQuantity = quantity || 0;
        setNewQuantity((initialQuantity + adjustParsed).toString());
    };
    const setInputFocus = () => {
        if (!media.tablet)
            return;
        setTimeout(() => {
            inputRef.current?.focus();
            inputRef.current?.select();
        });
    };
    useEffect(() => {
        setNewQuantity(initialQuantityValue);
    }, [quantity]);
    useEffect(() => {
        if (!isOpen && !isUnlimited) {
            setAdjustBy('0');
            setAdjustedQuantity('0');
        }
        else if (!isUnlimited) {
            setInputFocus();
        }
    }, [isOpen]);
    const handleAdjustByChange = (adjustByValue) => {
        setAdjustBy(adjustByValue);
        setAdjustedQuantity(adjustByValue);
    };
    const handleUnlimitedCheckboxChange = () => {
        if (!isUnlimited) {
            setAdjustBy('0');
            setNewQuantity(UNLIMITED_VALUE);
        }
        else {
            setAdjustedQuantity(adjustBy);
        }
    };
    const handleTabSelect = () => {
        if (!isUnlimited) {
            setAdjustedQuantity(adjustBy);
            setInputFocus();
        }
    };
    const renderQuantityValue = (value) => {
        return isIntegerString(value) ? React.createElement(NumberString, { value: parseInt(value) }) : value;
    };
    const handleSubmit = () => {
        if (!isDisabled) {
            const resultQuantity = isUnlimited ? undefined : parseInt(newQuantity);
            onChange(quantity, resultQuantity);
        }
        setOpen(false);
    };
    const renderTooltipContent = () => (React.createElement(S.TooltipContainer, null,
        React.createElement(Tabs, { defaultIndex: !quantity ? 1 : 0, onSelect: handleTabSelect },
            React.createElement(S.TabsList, null,
                React.createElement(Tabs.Item, { name: "Adjust quantity", style: { paddingTop: 16, paddingBottom: 16 }, variant: "dimmed" }),
                React.createElement(Tabs.Item, { name: "Set new quantity", style: { paddingTop: 16, paddingBottom: 16 }, variant: "dimmed" })),
            React.createElement(S.TabsPanelContainer, null,
                React.createElement(Tabs.Panel, { style: { padding: 16 } },
                    React.createElement(S.PanelContainer, null,
                        React.createElement(S.PanelColumn, null,
                            React.createElement(Input, { label: "Adjust by", value: adjustBy, type: "number", invalid: isAdjustByInvalid, disabled: isUnlimited, placeholder: "0", inputRef: inputRef, onValueChange: handleAdjustByChange })),
                        React.createElement(S.PanelColumn, null,
                            React.createElement(S.ColumnLabel, null, "New quantity"),
                            React.createElement(S.ColumnValue, null, renderQuantityValue(newQuantity))))),
                React.createElement(Tabs.Panel, { style: { padding: 16 } },
                    React.createElement(S.PanelContainer, null,
                        React.createElement(S.PanelColumn, null,
                            React.createElement(S.ColumnLabel, null, "Current quantity"),
                            React.createElement(S.ColumnValue, null, renderQuantityValue(initialQuantityValue))),
                        React.createElement(S.PanelColumn, null,
                            React.createElement(Input, { label: "New quantity", value: newQuantity, disabled: isUnlimited, invalid: isNewQuantityInvalid, inputRef: inputRef, onValueChange: setNewQuantity })))))),
        React.createElement(S.UnlimitedCheckboxContainer, null,
            React.createElement(Checkbox, { label: "Make unlimited", value: isUnlimited, onValueChange: handleUnlimitedCheckboxChange })),
        React.createElement(S.TooltipFooter, null,
            React.createElement(ButtonGroup, { space: "medium", fullWidth: true, direction: media.tablet ? 'row' : 'column-reverse' },
                React.createElement(Button, { label: "Cancel", onClick: () => setOpen(false), fullWidth: true }),
                React.createElement(Button, { label: "Save", appearance: ButtonAppearance.Primary, disabled: isDisabled, onClick: handleSubmit, fullWidth: true })))));
    return (React.createElement(Popper, { isOpen: isOpen, referenceComponent: React.createElement(ButtonClean, { label: renderQuantityValue(initialQuantityValue), disabled: disabled, onClick: () => setOpen(true) }), popperProps: {
            placement: 'bottom-start',
        }, style: { paddingTop: media.tablet ? 8 : 0 }, onClose: () => setOpen(false) }, renderTooltipContent()));
};
