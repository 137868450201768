export var RssType;
(function (RssType) {
    RssType["all"] = "all";
    RssType["selected"] = "selected";
})(RssType || (RssType = {}));
export var FormFields;
(function (FormFields) {
    FormFields["mode"] = "mode";
    FormFields["type"] = "type";
    FormFields["title"] = "title";
    FormFields["description"] = "description";
    FormFields["image"] = "image";
    FormFields["rssType"] = "rssType";
    FormFields["rssTags"] = "rssTags";
    FormFields["hidden"] = "hidden";
    FormFields["selectedTiers"] = "selectedTiers";
    FormFields["selectedRoles"] = "selectedRoles";
    FormFields["removeImage"] = "removeImage";
})(FormFields || (FormFields = {}));
