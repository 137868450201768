import React, { useEffect, useState } from 'react';
import { Input } from '../../../inputs/Input';
export const ColorPickerInput = ({ value, label, onChange }) => {
    const [innerValue, setInnerValue] = useState(value);
    const [isFocused, setFocus] = useState(false);
    useEffect(() => {
        if (!isFocused) {
            setInnerValue(value);
        }
    }, [value]);
    const handleInputChange = (e) => {
        setInnerValue(e.target.value);
        onChange(e.target.value);
    };
    const handleBlur = () => {
        setInnerValue(value);
        setFocus(false);
    };
    const handleFocus = () => {
        setFocus(true);
    };
    return (React.createElement(Input, { value: innerValue, invalid: false, label: label, fullWidth: true, onChange: handleInputChange, onBlur: handleBlur, onFocus: handleFocus }));
};
