import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Section = styled.div `
  & + & {
    margin-top: 48px;
  }
`;
export const SectionHeader = styled.div `
  padding-bottom: 12px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${Colors.GS400};
`;
export const SummaryHeader = styled.div `
  margin-bottom: 8px;
  font-weight: 600;
`;
export const SummarySubsection = styled.div `
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0 0;
  color: ${Colors.GS600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const SummaryDivider = styled.div `
  height: 1px;
  margin: 12px 0;
  background: ${Colors.GS400};
`;
export const SummaryButton = styled.div `
  margin-top: 24px;
`;
export const SummaryCheckbox = styled.div `
  margin-top: 16px;
`;
export const ProductItemContainer = styled.div `
  display: flex;
`;
export const ProductCheckoutContainer = styled.div `
  flex: 0 0 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const ProductDetailsContainer = styled.div `
  flex: 1;
`;
export const ProductQuantityContainer = styled.div `
  flex: 0 0 80px;
  display: flex;
  align-items: center;
`;
export const ProductQuantityText = styled.span `
  padding-left: 16px;
`;
export const ProductPriceContainer = styled.div `
  flex: 0 0 68px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
