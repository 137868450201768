import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { getValidationSchema } from './utils';
import { FormFields } from './types';
import * as S from './styled';
import { ModalBox } from '../../partials/ModalBox';
import { Button } from '../../common/Button';
import { Fieldset } from '../../common/Fieldset/Fieldset';
import { RadioButton } from '../../inputs/RadioButton/RadioButton';
import { Input } from '../../inputs/Input';
import { InputUI } from '../../ui/Input';
export const PositionChangeModal = ({ title, currentPosition, maxPosition, topPositionLabel = 'Move to top', bottomPositionLabel = 'Move to bottom', onSubmit, close, }) => {
    const formik = useFormik({
        initialValues: {
            type: undefined,
            exactPosition: currentPosition,
        },
        validationSchema: getValidationSchema(maxPosition, currentPosition),
        onSubmit: (values) => {
            onSubmit(values);
            close();
        },
    });
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(ModalBox, { title: title, size: "small", buttons: [
                React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close }),
                React.createElement(Button, { label: "Update", appearance: "primary", type: "submit", disabled: !formik.isValid || !formik.dirty }),
            ], onClose: close },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(RadioButton, { checkedValue: "TOP", label: topPositionLabel, ...formik.getFieldProps(FormFields.type) })),
                React.createElement(Fieldset.Row, null,
                    React.createElement(RadioButton, { checkedValue: "BOTTOM", label: bottomPositionLabel, ...formik.getFieldProps(FormFields.type) })),
                React.createElement(Fieldset.Row, null,
                    React.createElement(RadioButton, { checkedValue: "EXACT", label: "Set exact position", ...formik.getFieldProps(FormFields.type) })),
                formik.values.type === 'EXACT' && (React.createElement(React.Fragment, null,
                    React.createElement(Fieldset.Row, { inner: true },
                        React.createElement(S.ExactPositionContainer, null,
                            React.createElement(S.InputContainer, null,
                                React.createElement(Input, { type: "number", hideSpinner: true, displayError: false, ...formik.getFieldProps(FormFields.exactPosition) })),
                            "out of ",
                            maxPosition)),
                    formik.errors.exactPosition && (React.createElement(S.InputErrorContainer, null,
                        React.createElement(InputUI.Error, null, formik.errors.exactPosition)))))))));
};
