import { Toasts, useModalContext } from '@fourthwall/components';
import { useTagManager } from '@hooks';
import { useState } from 'react';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
import { useCreatorIntegrationsQuery } from '../../../queries/useCreatorIntegrationsQuery';
import { ConfirmStoreDisconnectModal } from '../components/ConfirmStoreDisconnectModal';
import { SyncFulfillmentsModal } from '../components/SyncFulfillmentsModal';
import { useDeleteShipStationConfigurationMutation } from './useDeleteShipStationConfigurationMutation';
import { usePushToThirdPartyMutation } from './usePushToThirdPartyMutation';
import { useShipStationConfigurationQuery } from './useShipStationConfigurationQuery';
import { useShipStationStores } from './useShipStationStores';
import { useUnfulfilledCountQuery } from './useUnfulfilledCountQuery';
import { useUpdateShipStationConfigurationMutation } from './useUpdateShipStationConfigurationMutation';
export const useShipStation = () => {
    const { open } = useModalContext();
    const { creatorIntegrationsQuery, invalidateCreatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const { shipStationConfigurationQuery, invalidateShipStationConfigurationQuery } = useShipStationConfigurationQuery(undefined, {
        enabled: creatorIntegrationsQuery.data?.shipstation?.status === 'CONNECTED',
    });
    const [connectFormValues, setConnectFormValues] = useState({
        apiKey: '',
        apiSecret: '',
    });
    const { stores, isLoadingStores, invalidateStores } = useShipStationStores(creatorIntegrationsQuery.data?.shipstation?.status, connectFormValues);
    const { unfulfilledCountQuery } = useUnfulfilledCountQuery(undefined, {
        enabled: creatorIntegrationsQuery.data?.shipstation?.status === 'CONNECTED',
    });
    const { updateShipStationConfigurationMutation } = useUpdateShipStationConfigurationMutation();
    const { deleteShipStationConfigurationMutation } = useDeleteShipStationConfigurationMutation();
    const { pushToThirdPartyMutation } = usePushToThirdPartyMutation();
    const tagManager = useTagManager();
    const appConnectEvent = useAppConnectEvent('ship_station');
    const getStatus = () => {
        if (creatorIntegrationsQuery.data?.shipstation?.status === 'NOT_CONNECTED' && stores?.length)
            return 'NOT_CONFIGURED';
        return creatorIntegrationsQuery.data?.shipstation?.status;
    };
    const handleConnectionSubmit = (values) => {
        appConnectEvent.trigger();
        const { apiKey, apiSecret } = values;
        values.apiKey = apiKey.trim();
        values.apiSecret = apiSecret.trim();
        if (values.apiKey === connectFormValues.apiKey &&
            values.apiSecret === connectFormValues.apiSecret) {
            invalidateStores();
        }
        else {
            setConnectFormValues(values);
        }
    };
    const displaySettingsSubmitSuccessToast = () => {
        Toasts.notify('ShipStation settings saved successfully.', { type: 'success' });
    };
    const getInitialStoreId = () => {
        const storeId = shipStationConfigurationQuery?.data?.storeId;
        return storeId && stores?.some((store) => store.storeId === storeId) ? storeId.toString() : '';
    };
    const handleSyncFulfillments = () => {
        pushToThirdPartyMutation.mutate([], {
            onSuccess: () => {
                displaySettingsSubmitSuccessToast();
            },
        });
    };
    const handleSettingsSubmitSuccess = () => {
        if (!shipStationConfigurationQuery?.data?.storeId) {
            open(SyncFulfillmentsModal, {
                onConfirm: (syncType) => {
                    if (syncType === 'SYNC_ALL') {
                        handleSyncFulfillments();
                    }
                    else {
                        displaySettingsSubmitSuccessToast();
                    }
                },
            });
        }
        else {
            displaySettingsSubmitSuccessToast();
        }
        setConnectFormValues({ apiKey: '', apiSecret: '' });
        invalidateCreatorIntegrationsQuery();
        invalidateShipStationConfigurationQuery();
        tagManager.trigger('app_connected', { app_name: 'ship_station' });
    };
    const updateSettings = (values) => {
        updateShipStationConfigurationMutation.mutate([
            {
                apiKey: shipStationConfigurationQuery.data?.apiKey ? undefined : connectFormValues.apiKey,
                apiSecret: shipStationConfigurationQuery.data?.apiSecret
                    ? undefined
                    : connectFormValues.apiSecret,
                storeId: Number(values.storeId),
            },
        ], {
            onSuccess: handleSettingsSubmitSuccess,
        });
    };
    const handleSettingsSubmit = (values) => {
        if (shipStationConfigurationQuery?.data?.storeId &&
            values.storeId !== shipStationConfigurationQuery?.data?.storeId.toString() &&
            unfulfilledCountQuery.data?.count) {
            open(ConfirmStoreDisconnectModal, {
                type: 'CHANGE_SHOP',
                pendingFulfillmentsCount: unfulfilledCountQuery.data?.count,
                onConfirm: () => {
                    updateSettings(values);
                },
            });
            return;
        }
        updateSettings(values);
    };
    const handleDisconnect = () => {
        open(ConfirmStoreDisconnectModal, {
            type: 'DISCONNECT',
            pendingFulfillmentsCount: unfulfilledCountQuery.data?.count,
            onConfirm: () => {
                deleteShipStationConfigurationMutation.mutate([], {
                    onSuccess: () => {
                        invalidateCreatorIntegrationsQuery();
                        invalidateShipStationConfigurationQuery();
                    },
                });
            },
        });
    };
    return {
        shipStation: {
            settings: {
                isLoading: isLoadingStores,
                isSubmitting: updateShipStationConfigurationMutation.isLoading,
                initialValues: {
                    storeId: getInitialStoreId(),
                },
                stores: stores?.map((store) => ({
                    value: store.storeId.toString(),
                    label: store.storeName,
                })) || [],
                onSubmit: handleSettingsSubmit,
            },
            connection: {
                isSubmitting: isLoadingStores,
                initialValues: {
                    apiKey: '',
                    apiSecret: '',
                },
                onSubmit: handleConnectionSubmit,
            },
            status: getStatus(),
            isLoading: creatorIntegrationsQuery.isLoading,
            onDisconnect: handleDisconnect,
        },
    };
};
