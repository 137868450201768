import maxBy from 'lodash-es/maxBy';
export const getOrderImageUrl = (offers, offersCancelled) => {
    const mostExpensiveOffer = maxBy(offers, 'price.value');
    const mostExpensiveCanceledOffer = maxBy(offersCancelled, 'price.value');
    if (mostExpensiveOffer) {
        return mostExpensiveOffer?.variant?.images[0]?.url;
    }
    if (mostExpensiveCanceledOffer) {
        return mostExpensiveCanceledOffer?.variant?.images[0]?.url;
    }
};
