import kebabCase from 'lodash-es/kebabCase';
import times from 'lodash-es/times';
import React from 'react';
import { SectionTitle } from '@modules/Dashboard/Home/components/SectionTitle';
import { Dashboard } from '../../../components/common/Dashboard';
import { Onboarding } from '../../../components/common/Onboarding';
import { PageLayout } from '../../../components/common/PageLayout';
import { Suggestion, SuggestionSkeleton } from '../../../components/common/Suggestion';
import { ArticleListSection } from './components/ArticleListSection';
import { RecommendationBar } from './components/RecommendationBar';
import { Scheduler } from './components/Scheduler';
import * as S from './styled';
export const HomeView = ({ name, title, content, schedulerProps, onboardingsProps, actionBarsContent, recommendationBarsProps, suggestionsProps, articleListSection, }) => {
    const renderRecommendationBars = () => {
        if (!recommendationBarsProps?.length)
            return null;
        return (React.createElement(S.RecommendationBars, null, recommendationBarsProps.map((props, index) => (React.createElement(RecommendationBar, { key: index, ...props })))));
    };
    const renderRecommendationSuggestions = () => {
        if (suggestionsProps === undefined) {
            return (React.createElement(React.Fragment, null, times(5, (index) => (React.createElement(S.SuggestionWrapper, { key: index },
                React.createElement(SuggestionSkeleton, null))))));
        }
        if (!suggestionsProps.length)
            return null;
        return (React.createElement(React.Fragment, null, suggestionsProps.map((props) => {
            if (props.type === 'node') {
                return React.createElement(S.SuggestionWrapper, null, props.node);
            }
            return (React.createElement(S.SuggestionWrapper, { key: kebabCase(props.props.title) },
                React.createElement(Suggestion, { ...props.props })));
        })));
    };
    const renderRecommendations = () => {
        if (recommendationBarsProps &&
            !recommendationBarsProps.length &&
            suggestionsProps &&
            !suggestionsProps.length) {
            return null;
        }
        return (React.createElement(S.Recommendations, null,
            renderRecommendationBars(),
            renderRecommendationSuggestions()));
    };
    return (React.createElement(PageLayout, null,
        React.createElement(Dashboard.Section, { name: name, title: title },
            content,
            schedulerProps && React.createElement(Scheduler, { ...schedulerProps }),
            onboardingsProps && (React.createElement("div", { style: { marginTop: 48 } },
                React.createElement(Onboarding, { ...onboardingsProps }))),
            React.createElement("div", { style: { marginTop: 48 } },
                React.createElement(SectionTitle, { title: "Next steps" }),
                actionBarsContent && React.createElement(S.ActionBarsContainer, null, actionBarsContent),
                renderRecommendations()),
            articleListSection && React.createElement(ArticleListSection, { ...articleListSection }))));
};
