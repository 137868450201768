import { useMembershipsOverviewQuery } from './useMembershipsOverviewQuery';
export const useMembershipsOverview = () => {
    const { membershipsOverviewQuery } = useMembershipsOverviewQuery();
    return {
        membershipsOverviewQuery,
        discordPerk: membershipsOverviewQuery.data?.perks.discord.enabled,
        newMembers: membershipsOverviewQuery.data?.members.newest,
        topComments: membershipsOverviewQuery.data?.topComments,
        welcomePost: membershipsOverviewQuery.data?.welcomePost?.id,
    };
};
