import React from 'react';
import { AlertModal, ConfirmModal, useModalContext } from '@fourthwall/components';
export const DisconnectErrorModal = ({ isEnabled, onDisable }) => {
    const { close } = useModalContext();
    const title = 'You cannot disconnect Meta while orders are still processing';
    if (isEnabled) {
        return (React.createElement(ConfirmModal, { title: title, text: React.createElement(React.Fragment, null, "Because there are Meta orders that have not yet been delivered, Meta does not allow you to disconnect the integration. You can disable sales though, so that no new orders can be placed."), confirmLabel: "Disable Meta shop sales", onConfirm: onDisable, close: close }));
    }
    return (React.createElement(AlertModal, { title: title, size: "small", text: React.createElement(React.Fragment, null, "You have disabled Meta Shop, so you will no longer receive additional orders, but you cannot disconnect the app until all existing orders are delivered."), close: close }));
};
