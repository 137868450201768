import { ApiBase } from './ApiBase';
class UserApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getUserConnections = () => {
            return this.get('/user/connections');
        };
        this.updateUserAccountSettings = (payload) => {
            return this.put('/user/account-settings', payload);
        };
    }
}
export const UserApiClient = new UserApiClientModel();
