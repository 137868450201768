import React from 'react';
import { mapAvailabilityParamToFormValue, mapLabelsToCheckboxOptions, mapAvailabilityFormValueToParam, } from './utils';
import { useFilterProducts } from '../../hooks/useFilterProducts';
import { FILTER_PRODUCT_AVAILABILITY_LABELS, FILTER_PRODUCT_STATUS_LABELS } from './consts';
import { ProductsFilterView } from './ProductsFilterView';
export const ProductsFilterContainer = () => {
    const { filtersParams, setFiltersParams, resetFiltersParams, hasFilterParams } = useFilterProducts();
    const handleSubmit = (values) => {
        setFiltersParams({
            status: values.status.length ? values.status.join(',') : undefined,
            availability: mapAvailabilityFormValueToParam(values.availability),
            archived: values.archived || undefined,
        });
    };
    const getInitialValues = () => ({
        status: filtersParams.status ? filtersParams.status.split(',') : [],
        availability: mapAvailabilityParamToFormValue(filtersParams.availability),
        archived: !!filtersParams.archived,
    });
    return (React.createElement(ProductsFilterView, { initialValues: getInitialValues(), statuses: mapLabelsToCheckboxOptions(FILTER_PRODUCT_STATUS_LABELS), availabilityOptions: mapLabelsToCheckboxOptions(FILTER_PRODUCT_AVAILABILITY_LABELS), filterActive: hasFilterParams, onSubmit: handleSubmit, onReset: resetFiltersParams }));
};
