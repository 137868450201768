import styled from 'styled-components';
export const TiersContainer = styled.div `
  display: grid;
  gap: 12px;
`;
export const TiersLabel = styled.div `
  margin-bottom: 8px;
`;
export const RolesContainer = styled.div `
  margin-left: 36px;
`;
export const DiscordRoles = styled.div `
  margin: 12px -4px -4px;
`;
export const Errors = styled.div `
  display: grid;
  margin-top: 12px;
`;
