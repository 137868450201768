import { DndContext, KeyboardSensor, MouseSensor, PointerSensor, closestCenter, useSensor, useSensors, } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, sortableKeyboardCoordinates, } from '@dnd-kit/sortable';
import { AddButton, Loading } from '@fourthwall/components';
import { arrayMove } from '@fourthwall/utils/lib/array';
import React, { useEffect, useState } from 'react';
import { useTiersContext } from '../../hooks/useTiersContext';
import { PerksProvider } from '../../providers/PerksProvider';
import { isPaidTier } from '../../utils';
import { Tier } from '../Tier';
import * as S from './styled';
export const Tiers = () => {
    const { tiers, addTier, sortTier, sortFreeTier, isLoading } = useTiersContext();
    const [innerTiers, setInnerTiers] = useState(tiers);
    useEffect(() => {
        setInnerTiers(tiers);
    }, [tiers]);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    if (!innerTiers)
        return React.createElement(Loading, { isLoading: true });
    const handleDragEnd = ({ active, over }) => {
        const oldIndex = innerTiers.findIndex((tier) => tier.id === active.id);
        const newIndex = innerTiers.findIndex((tier) => tier.id === over?.id);
        const tier = innerTiers.find((tier) => tier.id === active.id);
        const newTier = innerTiers.find((tier) => tier.id === over?.id);
        if (!tier)
            return;
        if (!newTier)
            return;
        if (newIndex < 0)
            return;
        if (newIndex === oldIndex)
            return;
        setInnerTiers(arrayMove([...innerTiers], oldIndex, newIndex));
        if (isPaidTier(tier)) {
            sortTier(tier, newTier.position);
        }
        else {
            sortFreeTier(newTier.position);
        }
    };
    const renderAddTierButton = () => {
        if (!addTier)
            return null;
        return (React.createElement(S.AddTierButton, { type: "button", onClick: addTier },
            React.createElement(AddButton, { label: "Add a new tier" })));
    };
    const renderContent = () => {
        if (!innerTiers.length) {
            return React.createElement(S.AddTierButtonContainer, null, renderAddTierButton());
        }
        return (React.createElement(S.Grid, { count: innerTiers.length },
            innerTiers.map((tier) => (React.createElement(PerksProvider, { tierId: tier.id, key: tier.id },
                React.createElement(Tier, { tier: tier })))),
            renderAddTierButton()));
    };
    return (React.createElement(Loading, { isLoading: isLoading, size: "medium" },
        React.createElement(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragEnd: handleDragEnd },
            React.createElement(SortableContext, { items: innerTiers, strategy: rectSortingStrategy }, renderContent()))));
};
