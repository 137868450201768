import styled from 'styled-components';
export const Container = styled.ol `
  list-style-type: decimal;
  font-weight: 600;
`;
export const ItemContainer = styled.li `
  margin-bottom: 16px;
  margin-left: 24px;
  padding-left: 8px;
`;
export const ItemTitle = styled.div `
  font-size: 16px;
  line-height: 24px;
`;
export const ItemContent = styled.div `
  font-weight: 400;
  margin-top: 8px;
`;
