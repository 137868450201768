import { MOBILE_APP_ANDROID_LINK, MOBILE_APP_IOS_LINK } from '@fourthwall/utils/lib/links';
import React from 'react';
import { ForbiddenRouteState } from '../../common/ForbiddenRouteState';
import { useDashboardPermissions } from './useDashboardPermissions';
export const PermissionGuardedRoute = (props) => {
    const { showForbiddenState = true } = props;
    const { hasPermission, hasAnyPermission, hasRole, hasGroup } = useDashboardPermissions();
    const hasAccess = () => {
        if ('permission' in props) {
            return hasPermission(props.permission);
        }
        if ('roleName' in props) {
            return hasRole(props.roleName);
        }
        return false;
    };
    if (!hasAccess() && !showForbiddenState) {
        return null;
    }
    // ThankYou sander doesn't have dashboard access, can only send ThankYous via mobile app
    if (!hasAccess() && !hasAnyPermission() && hasGroup('THANK_YOU')) {
        return (React.createElement(ForbiddenRouteState, { title: "No dashboard access", subtitle: React.createElement(React.Fragment, null,
                "Your role permits you only to send thank you messages via mobile app. Download Fourthwall app for",
                ' ',
                React.createElement("a", { href: MOBILE_APP_IOS_LINK, target: "_blank", rel: "noreferrer" }, "iOS"),
                ' ',
                "or",
                ' ',
                React.createElement("a", { href: MOBILE_APP_ANDROID_LINK, target: "_blank", rel: "noreferrer" }, "Android")) }));
    }
    if (!hasAccess()) {
        return React.createElement(ForbiddenRouteState, null);
    }
    return props.element;
};
