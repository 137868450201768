import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import React from 'react';
import { ForbiddenRouteState } from '../../../../../components/common/ForbiddenRouteState';
import { useDashboardPermissions } from '../../../../../components/utils/DashboardPermissions';
import { useAnalyticsParams } from '../../hooks/useAnalyticsParams';
import { useReportType } from '../../hooks/useReportType';
import { AnalyticsReportView } from './AnalyticsReportView';
import { useAnalyticsReport } from './hooks/useAnalyticsReport';
export const AnalyticsReportContainer = () => {
    const { params, rangeSelect, rangePicker, precisionSelect, chartEntityGroupSelect, devicesSelect, intervalSelect, tiersSelect, } = useAnalyticsParams();
    const { title, backButtonLabel, backButtonTo, exportButton, chart, statsTable } = useAnalyticsReport(params);
    const { hasPermission } = useDashboardPermissions();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { reportType } = useReportType();
    const permissionsMap = {
        profit: hasPermission('analytics.profit'),
        orders: hasPermission('analytics.contributions'),
        'average-order-value': hasPermission('analytics.averageContributionValue'),
        'top-products-by-units-sold': hasPermission('analytics.topProducts'),
        'sales-by-country': hasPermission('analytics.salesByCountry'),
        'memberships-total-members': hasPermission('analytics.totalMembers'),
        'memberships-total-free-members': hasPermission('analytics.totalMembers') &&
            dashboardFeatureFlags.membershipsFreeAccounts.enabled,
        'memberships-members-change': hasPermission('analytics.membersChange'),
        'memberships-free-members-change': hasPermission('analytics.membersChange') &&
            dashboardFeatureFlags.membershipsFreeAccounts.enabled,
        'membership-top-tiers': hasPermission('analytics.topTiers'),
    };
    if (!permissionsMap[reportType]) {
        return React.createElement(ForbiddenRouteState, null);
    }
    return (React.createElement(AnalyticsReportView, { title: title, backButtonLabel: backButtonLabel, backButtonTo: backButtonTo, exportButton: exportButton, rangeSelect: rangeSelect, rangePicker: rangePicker, precisionSelect: precisionSelect, chartEntityGroupSelect: chartEntityGroupSelect, chart: chart, statsTable: statsTable, devicesSelect: devicesSelect, tiersSelect: tiersSelect, intervalSelect: intervalSelect }));
};
