import React from 'react';
import { Tag } from '@fourthwall/components';
const props = {
    ARCHIVED: { label: 'Archived', appearance: 'neutral' },
    HIDDEN: { label: 'Hidden', appearance: 'caution' },
    PUBLIC: { label: 'Public', appearance: 'success' },
};
export const OfferStatusChip = ({ status }) => {
    return React.createElement(Tag, { ...props[status], size: "small", display: "inline-flex" });
};
