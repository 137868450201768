import { Tag } from '@fourthwall/components';
import isNull from 'lodash-es/isNull';
import React from 'react';
import * as S from './StockContent.styled';
export const StockCellContent = ({ variants, showFullLabel = false }) => {
    const stockSum = variants.reduce((acc, variant) => acc + (variant.quantity || 0), 0);
    const isUnlimited = !variants.some((variant) => !isNull(variant.quantity));
    const isMixed = variants.some((variant) => isNull(variant.quantity)) &&
        variants.some((variant) => !isNull(variant.quantity));
    const isOutOfStock = stockSum === 0 && !isUnlimited;
    const getStockLabel = () => {
        if (isUnlimited) {
            return `Unlimited${showFullLabel ? ' stock' : ''}`;
        }
        if (isMixed) {
            return `Mixed${showFullLabel ? ' stock' : ''}`;
        }
        if (isOutOfStock) {
            return showFullLabel ? ('Out of stock') : (React.createElement(S.TagContainer, null,
                React.createElement(Tag, { label: "Out", appearance: "alert" })));
        }
        return `${stockSum}${showFullLabel ? ' in stock' : ''}`;
    };
    return React.createElement(S.Container, null, getStockLabel());
};
