import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGetAuthToken } from './hooks/useGetAuthToken';
import { useLoadEntri } from './hooks/useLoadEntri';
import { addEntriCloseEvent, checkDomain as originCheckDomain, removeEntriCloseEvent, showEntri, } from './utils';
import { defaultContextValue } from './consts';
export const EntriContext = createContext(defaultContextValue);
export const EntriContextProvider = ({ children }) => {
    const { getAuthToken } = useGetAuthToken();
    const { isEntriLoaded, loadEntriScript } = useLoadEntri();
    const [entriEnabled, setEntriEnabled] = useState(false);
    useEffect(() => {
        if (entriEnabled && !isEntriLoaded) {
            loadEntriScript();
            getAuthToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entriEnabled]);
    const getAuthEntriConfig = useCallback(async () => {
        const authToken = await getAuthToken();
        if (!authToken) {
            throw new Error('Invalid auth token');
        }
        const { applicationId, token } = authToken;
        return { applicationId, token };
    }, [getAuthToken]);
    const checkDomain = useCallback(async (domain, config) => {
        const authEntriConfig = await getAuthEntriConfig();
        return originCheckDomain(domain, { ...config, ...authEntriConfig });
    }, [getAuthEntriConfig]);
    const showEntriModal = useCallback(async (config) => {
        const authEntriConfig = await getAuthEntriConfig();
        return showEntri({ ...config, ...authEntriConfig });
    }, [getAuthEntriConfig]);
    const value = useMemo(() => ({
        isEntriLoaded,
        enableEntri: () => setEntriEnabled(true),
        checkDomain,
        showEntriModal,
        addEntriCloseEvent,
        removeEntriCloseEvent,
    }), [isEntriLoaded, checkDomain, showEntriModal]);
    return React.createElement(EntriContext.Provider, { value: value }, children);
};
export const useEntriContext = () => useContext(EntriContext);
