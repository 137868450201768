import { ShopRendererApiClient } from '@fourthwall/services/lib/ShopRendererApiClient';
import { useMutation } from '@tanstack/react-query';
export const useRemoveTiktokIntegrationMutation = (options) => {
    const removeTiktokIntegrationMutation = useMutation({
        mutationFn: async (params) => {
            const { data } = await ShopRendererApiClient.removeTiktokIntegration(...params);
            return data;
        },
        ...options,
    });
    return {
        removeTiktokIntegrationMutation,
    };
};
