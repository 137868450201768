import { HeadContent } from '@fourthwall/components';
import React from 'react';
import { SettingsTabs } from '@components/common/SettingsTabs';
import { Dashboard, PageLayout } from '@components/common';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { routing } from '@utils/routing';
export const Settings = ({ element }) => {
    const { hasPermission } = useDashboardPermissions();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const tabs = [
        {
            name: 'General',
            path: routing.memberships.settings.general,
            hidden: !hasPermission('memberships.settings.general'),
        },
        {
            name: 'Messages',
            path: routing.memberships.settings.messages,
            hidden: !dashboardFeatureFlags.membershipsMessages.enabled ||
                !hasPermission('memberships.settings.messages'),
        },
        {
            name: 'Welcome post',
            path: routing.memberships.settings.welcomePost,
            hidden: !hasPermission('memberships.settings.welcomePost'),
        },
        {
            name: 'Mobile app',
            path: routing.memberships.settings.mobileApp,
            hidden: !dashboardFeatureFlags.membershipsPwaSettings.enabled ||
                !hasPermission('memberships.settings.mobileApp'),
        },
        {
            name: 'Shop upselling',
            path: routing.memberships.settings.upselling,
            hidden: !dashboardFeatureFlags.membershipsUpsellingSettings.enabled ||
                !hasPermission('memberships.settings.upselling'),
        },
    ].filter(({ hidden }) => !hidden);
    return (React.createElement(PageLayout, { size: "small" },
        React.createElement(HeadContent, { title: "Memberships settings" }),
        React.createElement(Dashboard.Section, { name: "Memberships settings", bottomMargin: "large" },
            React.createElement(SettingsTabs, { tabs: tabs, element: element }))));
};
