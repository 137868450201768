import styled from 'styled-components';
export const ImageInputContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
`;
export const UploadContainer = styled.div `
  width: 100px;
  height: 100px;
  margin-top: 8px;
`;
