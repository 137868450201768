import { formatBytes } from '@fourthwall/utils/lib/file';
import React from 'react';
import { CloseIcon, FileDownloadIcon, QuestionMarkIcon, UploadIcon } from '@fourthwall/icons';
import { Upload } from '../../inputs/Upload';
import { Icon } from '../Icon';
import { Loader } from '../Loader';
import * as S from './FilesList.styled';
import { Tooltip } from '../Tooltip';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
export const FilesList = ({ accept, maxSize, data, disabled = false, onFileDrop, onFileDropRejected, onFileRemove, }) => {
    const media = useMediaQueryContext();
    const renderFile = (file) => {
        const handleFileDeleteClick = () => {
            if (onFileRemove) {
                onFileRemove(file);
            }
        };
        return (React.createElement(S.FileWrapper, { key: file.id },
            React.createElement(S.File, null,
                React.createElement(S.FileIcon, null,
                    React.createElement(Icon, { component: FileDownloadIcon, height: 24 })),
                React.createElement(S.FileName, null,
                    React.createElement(S.FileNameWrapper, null, file.name)),
                React.createElement(S.FileSize, { withoutMargin: disabled }, file.size),
                file.isUploading && React.createElement(Loader, { size: "small" }),
                !file.isUploading && !disabled && (React.createElement(S.FileDelete, { onClick: handleFileDeleteClick, type: "button", "data-testid": "FileList.ItemDelete" },
                    React.createElement(Icon, { component: CloseIcon, height: 10 })))),
            file.hasLocationMetadata && (React.createElement(S.FileWarning, null,
                "This file contains sensitive location data",
                ' ',
                React.createElement(Tooltip, { reference: React.createElement(Icon, { component: QuestionMarkIcon, height: 14 }) }, "Anyone who downloads this file will be able to view the EXIF data, containing details about the location where the image was captured.")))));
    };
    const renderTitle = () => {
        const max = maxSize ? ` (${formatBytes(maxSize, 0)})` : null;
        return (React.createElement("span", null,
            media.tablet ? (React.createElement(React.Fragment, null,
                "Drop new file or ",
                React.createElement("u", null, "browse"))) : ('Browse'),
            max && React.createElement("span", null, max)));
    };
    if (!data) {
        return null;
    }
    return (React.createElement(S.Container, null,
        React.createElement(S.Files, null, data.map(renderFile)),
        React.createElement(Upload, { accept: accept, maxSize: maxSize, title: renderTitle(), icon: UploadIcon, iconSize: 24, orientation: "horizontal", disabled: disabled, onFileDrop: onFileDrop, onFileDropRejected: onFileDropRejected })));
};
