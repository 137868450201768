import { ApiBase } from '../ApiBase';
class ShopsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.updateShop = (shopId, payload) => {
            return this.put(`/shops/${shopId}`, payload);
        };
        this.updateShopStatus = (shopId, payload) => {
            return this.put(`/shops/${shopId}/status`, payload);
        };
        this.updateOriginAddress = (shopId, payload) => {
            return this.put(`/shops/${shopId}/origin-address`, payload);
        };
        this.getCurrentShop = () => {
            return this.get('/shops/current');
        };
        this.getCurrentPermissions = () => {
            return this.get('/shops/permissions');
        };
        this.getConnectedShops = () => {
            return this.get('/shops/connected');
        };
        this.getRecentContent = (params) => {
            return this.get(`/shops/current/social-networks/${params.socialNetwork}/recent`);
        };
        this.getCurrentShopConfig = () => {
            return this.get('/v2/payment/shop/config');
        };
        this.setCurrentShopConfigChargeLimit = (payload) => {
            return this.put('/v2/payment/shop/config/charge-limit', payload);
        };
        this.updateCurrencySettings = (params, requestBody) => {
            return this.put(`/shops/${params.shopId}/settings/currency-setting`, requestBody);
        };
        this.updatePaymentSettings = (params, requestBody) => {
            return this.put(`/shops/${params.shopId}/settings/payment`, requestBody);
        };
        this.enableMemberships = (params, requestBody) => {
            return this.put(`/shops/${params.shopId}/settings/memberships`, requestBody);
        };
        this.setThemeEditorVisit = (pathParams) => {
            return this.put(`/shops/${pathParams.shopId}/theme-editor-visit`);
        };
        this.setDashboardVisit = (pathParams) => {
            return this.put(`/shops/${pathParams.shopId}/dashboard-visit`);
        };
        this.updateShopAnalytics = (pathParams, requestBody) => {
            return this.put(`/shops/${pathParams.shopId}/analytics`, requestBody);
        };
        this.createAdditionalShop = (payload) => {
            return this.post('/shops/additional', payload);
        };
    }
}
export const ShopsApiClient = new ShopsApiClientModel();
