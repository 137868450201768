import { DatePicker, Select } from '@fourthwall/components';
import React from 'react';
import { yearFormat } from '../../hooks/useAnalytics/utils';
import * as S from './styled';
export const AnalyticsToolbar = ({ rangeSelect, rangePicker, precisionSelect, chartEntityGroupSelect, devicesSelect, intervalSelect, tiersSelect, }) => {
    const renderDatePicker = () => {
        if (rangeSelect.value === 'custom') {
            return (React.createElement("div", null,
                React.createElement(DatePicker, { type: "range", startDate: rangePicker.value[0], endDate: rangePicker.value[1], size: "medium" // TODO: Should be default.
                    , startLabel: () => 'From', endLabel: () => 'To', showTimeInputs: false, dateFormat: yearFormat, onDatesChange: (dates) => {
                        let value = [dates.startDate, dates.endDate];
                        if (dates.startDate > dates.endDate) {
                            value = [dates.endDate, dates.startDate];
                        }
                        rangePicker.onValueChange(value);
                    } })));
        }
        return null;
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.Inner, null,
            React.createElement("div", null,
                React.createElement(S.DateRangeWrapper, null,
                    React.createElement(Select, { label: "Date range (UTC)", options: rangeSelect.options, size: "medium" // TODO: Should be default.
                        , value: rangeSelect.value, onValueChange: rangeSelect.onValueChange }))),
            renderDatePicker(),
            precisionSelect && (React.createElement(S.SelectWrapper, null,
                React.createElement(Select, { label: "Group by", options: precisionSelect.options, disabled: precisionSelect.disabled, size: "medium" // TODO: Should be default.
                    , value: precisionSelect.value, onValueChange: precisionSelect.onValueChange }))),
            chartEntityGroupSelect && (React.createElement(S.SelectWrapper, null,
                React.createElement(Select, { label: "Type", options: chartEntityGroupSelect.options, size: "medium" // TODO: Should be default.
                    , value: chartEntityGroupSelect.value, onValueChange: chartEntityGroupSelect.onValueChange }))),
            tiersSelect && (React.createElement(S.SelectWrapper, null,
                React.createElement(Select, { label: "Tiers", options: tiersSelect.options, size: "medium" // TODO: Should be default.
                    , value: tiersSelect.value, onValueChange: tiersSelect.onValueChange }))),
            intervalSelect && (React.createElement(S.SelectWrapper, null,
                React.createElement(Select, { label: "Subscription type", options: intervalSelect.options, size: "medium" // TODO: Should be default.
                    , value: intervalSelect.value, onValueChange: intervalSelect.onValueChange }))),
            devicesSelect && (React.createElement(S.SelectWrapper, null,
                React.createElement(Select, { label: "Device", options: devicesSelect.options, size: "medium" // TODO: Should be default.
                    , value: devicesSelect.value, onValueChange: devicesSelect.onValueChange }))))));
};
