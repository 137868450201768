import { CalculateProfitModal, CalculateProfitPricing } from '@fourthwall/components';
import React, { useState } from 'react';
import { getEstimatedPaymentProcessingFees, getUnitProfit } from '../CalculateProfit/utils';
export const getSizesPerCost = (perSizePrice, includeFees) => {
    return perSizePrice.map(({ price, cost, label }) => {
        const priceValue = Number(price);
        const unitProfit = getUnitProfit(priceValue, cost, includeFees);
        const feesValue = getEstimatedPaymentProcessingFees(priceValue);
        return {
            feesValue,
            unitProfit,
            price: priceValue.toString(),
            cost,
            label,
        };
    });
};
export const CalculateProfitForPerSizeProduct = ({ perSizePrice, }) => {
    const [includeFeesValue, setIncludeFeesValue] = useState(false);
    const [data, setData] = useState(perSizePrice);
    const sizesPerCost = getSizesPerCost(data, includeFeesValue);
    // eslint-disable-next-line default-param-last
    const handleSellingPriceChange = (value = '0', index) => {
        setData((prevState) => {
            const newState = [...prevState];
            newState[index].price = value;
            return newState;
        });
    };
    const handleToggleIncludeFeesValue = () => setIncludeFeesValue((prevState) => !prevState);
    return (React.createElement(CalculateProfitModal.Root, { title: "Profit on-demand for products", content: React.createElement(CalculateProfitPricing.PerSizePricing, { data: sizesPerCost, includeFeesValue: includeFeesValue, onPriceChange: handleSellingPriceChange }), includeFeesValue: includeFeesValue, suggestions: [
            'On-demand products have a single manufacturing price, regardless of how many are ordered.',
            'Selling price is automatically adjusted so you make the same amount on different sizes.',
        ], onToggleIncludeFeesValue: handleToggleIncludeFeesValue }));
};
