import { Toasts, useModalContext } from '@fourthwall/components';
import { ConfigureWebhookModal, } from '@modules/Dashboard/Settings/modules/ForDevelopers/modules/Webhooks/components/ConfigureWebhookModal';
import { getWebhookTypeLabel } from '../utils/getWebhookTypeLabel';
import { useCreateWebhookConfigurationMutation } from './useCreateWebhookConfigurationMutation';
import { useDeleteWebhookConfigurationMutation } from './useDeleteWebhookConfigurationMutation';
import { useTestWebhookConfigurationMutation } from './useTestWebhookConfigurationMutation';
import { useUpdateWebhookConfigurationMutation } from './useUpdateWebhookConfigurationMutation';
import { useWebhookConfigurationsQuery } from './useWebhookConfigurationsQuery';
import { useWebhookTypesQuery } from './useWebhookTypesQuery';
export const useWebhooks = () => {
    const { open } = useModalContext();
    const { webhookConfigurationsQuery, invalidateWebhookConfigurationsQuery } = useWebhookConfigurationsQuery();
    const { webhookTypesQuery } = useWebhookTypesQuery();
    const { createWebhookConfigurationMutation } = useCreateWebhookConfigurationMutation();
    const { updateWebhookConfigurationMutation } = useUpdateWebhookConfigurationMutation();
    const { deleteWebhookConfigurationMutation } = useDeleteWebhookConfigurationMutation();
    const { testWebhookConfigurationMutation } = useTestWebhookConfigurationMutation();
    const webhookTypes = webhookTypesQuery.data?.types.map((webhookType) => ({
        ...webhookType,
        label: getWebhookTypeLabel(webhookType.type),
    })) || [];
    const webhooks = webhookConfigurationsQuery.data?.configurations.map((webhook) => ({
        ...webhook,
        allowedTypes: webhook.allowedTypes.map((type) => ({
            type,
            label: getWebhookTypeLabel(type),
        })),
        onUpdate: () => onWebhookUpdate(webhook.id),
        onRemove: () => onWebhookRemove(webhook.id),
        onTest: () => onWebhookTest(webhook.id),
    })) || [];
    const onWebhookCreate = () => {
        open(ConfigureWebhookModal, {
            eventOptions: webhookTypes.map(({ type, label }) => ({
                value: type,
                label,
            })),
            onSubmit: (values) => {
                createWebhookConfigurationMutation.mutate([
                    {
                        url: values.url,
                        allowedTypes: values.events,
                        apiVersion: 'V1',
                    },
                ], {
                    onSuccess: () => {
                        invalidateWebhookConfigurationsQuery();
                    },
                });
            },
        });
    };
    const onWebhookUpdate = (webhookId) => {
        const webhook = webhooks.find(({ id }) => id === webhookId);
        open(ConfigureWebhookModal, {
            initialValues: {
                url: webhook?.url || '',
                events: webhook?.allowedTypes.map(({ type }) => type) || [],
            },
            eventOptions: webhookTypes.map(({ type, label }) => ({
                value: type,
                label,
            })),
            onSubmit: (values) => {
                updateWebhookConfigurationMutation.mutate([
                    {
                        webhookConfigurationId: webhookId,
                    },
                    {
                        url: values.url,
                        allowedTypes: values.events,
                        apiVersion: 'V1',
                    },
                ], {
                    onSuccess: () => {
                        invalidateWebhookConfigurationsQuery();
                    },
                });
            },
        });
    };
    const onWebhookRemove = (webhookId) => {
        deleteWebhookConfigurationMutation.mutate([{ webhookConfigurationId: webhookId }], {
            onSuccess: () => {
                invalidateWebhookConfigurationsQuery();
            },
        });
    };
    const onWebhookTest = (webhookId) => {
        const webhook = webhooks.find(({ id }) => id === webhookId);
        testWebhookConfigurationMutation.mutate([
            {
                webhookConfigurationId: webhookId,
            },
            {
                type: webhook?.allowedTypes[0].type,
            },
        ], {
            onSuccess: () => {
                Toasts.notify('Test notification has been sent', { type: 'success' });
            },
        });
    };
    return {
        webhooks,
        webhookTypes,
        isLoading: webhookConfigurationsQuery.isLoading,
        clientSecret: webhookConfigurationsQuery.data?.secret?.value,
        onWebhookCreate: webhookTypesQuery.isFetched ? onWebhookCreate : undefined,
        onWebhookUpdate,
        onWebhookRemove,
        onWebhookTest,
    };
};
