import { Dropdown, Icon, Tag, ToolbarButton } from '@fourthwall/components';
import { DotsHorizontalIcon, PayPalIcon, TickIcon } from '@fourthwall/icons';
import React from 'react';
import { CreditCardIcon } from '../CreditCardIcon';
import * as S from './styled';
export const PaymentMethodsList = ({ paymentMethods }) => (React.createElement(S.Container, null, paymentMethods.map((paymentMethod) => {
    const { id, selected, invalid, tagsProps, dropdownItemsProps, onClick } = paymentMethod;
    const renderTitle = () => {
        if (paymentMethod.type === 'CARD') {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.Title, null,
                    paymentMethod.brand,
                    " ending in ",
                    paymentMethod.number),
                React.createElement(S.Subtitle, null,
                    "Expires ",
                    paymentMethod.expires)));
        }
        // PAYPAL
        return React.createElement(S.Title, { withEllipsis: true }, paymentMethod.email);
    };
    return (React.createElement(S.PaymentMethodContainer, { key: id, selected: !!selected, selectable: !!onClick, invalid: !!invalid, onClick: onClick },
        selected && (React.createElement(S.SelectedMark, null,
            React.createElement(Icon, { component: TickIcon, height: 6 }))),
        React.createElement(S.IconContainer, null,
            paymentMethod.type === 'CARD' && React.createElement(CreditCardIcon, { brand: paymentMethod.brand }),
            paymentMethod.type === 'PAYPAL' && React.createElement(Icon, { component: PayPalIcon, height: 32 })),
        React.createElement(S.TitleContainer, null, renderTitle()),
        !!tagsProps?.length && (React.createElement(S.TagsContainer, null, tagsProps.map((tagProps) => (React.createElement(S.TagWrapper, { key: tagProps.label },
            React.createElement(Tag, { ...tagProps })))))),
        !!dropdownItemsProps?.length && (React.createElement(S.DropdownWrapper, null,
            React.createElement(Dropdown, { content: dropdownItemsProps.map((dropdownItemProps, index) => (React.createElement(Dropdown.Item, { key: index, ...dropdownItemProps }))), position: "bottom-end" },
                React.createElement(ToolbarButton, { icon: DotsHorizontalIcon }))))));
})));
