import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 0px;
`;
export const ContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
export const MainContent = styled.div `
  width: 100%;

  font-size: 16px;
  line-height: 24px;
  color: ${Colors.GS1000};
`;
export const AdditionalContent = styled.div `
  width: 100%;

  font-size: 14px;
  line-height: 100%;
  color: ${Colors.GS600};
`;
