import { Subsection } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import { getTimezoneAbbreviation } from '@fourthwall/utils/lib/timezone';
import dayjs from 'dayjs';
import React from 'react';
import * as S from './ReadOnlyPollFields.styled';
export const ReadOnlyPollFields = ({ pollFinishDate, pollOptions, votesAmount }) => (React.createElement(Subsection, { title: "Poll options" },
    React.createElement(S.OptionsContainer, null, pollOptions.map(({ title, votes }, index) => (React.createElement(S.Option, { key: index },
        React.createElement(S.Title, null, title),
        React.createElement(S.PercentageBar, { percentage: votes.percentage }),
        React.createElement(S.PercentageContainer, null,
            React.createElement(S.VotesAmount, null,
                votes.amount,
                " ",
                pluralize(votes.amount, 'vote', 'votes')),
            `${votes.percentage.toFixed(0)} %`))))),
    React.createElement(S.Divider, null),
    React.createElement(S.Info, null,
        React.createElement("span", null,
            votesAmount,
            " ",
            pluralize(votesAmount, 'vote', 'votes')),
        React.createElement("span", null,
            "Finish date: ",
            dayjs(pollFinishDate).format('MMMM D, h:mm'),
            " (",
            getTimezoneAbbreviation(),
            ")"))));
