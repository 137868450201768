import { DateString, Tag, useMediaQueryContext, usePagination } from '@fourthwall/components';
import dayjs from 'dayjs';
import React from 'react';
import { useDashboardFeatureFlags } from '../../../../../../components/utils/DashboardFeatureFlags';
import { reverse, routing } from '../../../../../../utils/routing';
import { MembershipCell } from '../components/MembershipCell';
import { MobileCell } from '../components/MobileCell';
import { SupporterCell } from '../components/SupporterCell';
import { useFilterMembers } from './useFilterMembers';
import { useMembersQuery } from './useMembersQuery';
export const useMembers = () => {
    const [currentPage, setCurrentPage] = usePagination();
    const { filtersParams, searchQueryParam } = useFilterMembers();
    const media = useMediaQueryContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const getStates = () => {
        const types = filtersParams.type?.split(',');
        const states = filtersParams.state?.split(',');
        if (dashboardFeatureFlags.membershipsFreeAccounts.enabled) {
            if (types?.length === 1 && types[0] === 'free') {
                return ['None', 'Former'];
            }
            if (states) {
                return states;
            }
            if (types?.length === 1 && types[0] === 'member') {
                return ['Active', 'Expiring', 'PastDue'];
            }
            return states;
        }
        return states
            ? states
            : ['Active', 'Expiring', 'Former', 'PastDue'];
    };
    const intervals = filtersParams.billingCycle?.split(',');
    const tierIds = filtersParams.tier?.split(',');
    const { membersQuery } = useMembersQuery([
        {
            page: currentPage,
            limit: 10,
            'states[]': getStates(),
            'intervals[]': intervals,
            'tier_ids[]': tierIds,
            search_query: searchQueryParam || undefined,
        },
    ]);
    const getMembershipCell = (member) => {
        if (member.state.type === 'Active') {
            const monthsCount = dayjs(new Date()).diff(dayjs(member.state.subscribedAt), 'months');
            const tagLabel = monthsCount ? `${monthsCount}-month` : 'New';
            return (React.createElement(MembershipCell, { info: `${member.tier?.name} tier`, tags: React.createElement(Tag, { label: `${tagLabel} member`, appearance: "success" }) }));
        }
        if (member.state.type === 'Expiring') {
            return (React.createElement(MembershipCell, { info: React.createElement(React.Fragment, null,
                    "Expires ",
                    React.createElement(DateString, { date: member.state.expiresAt, format: "MMM D, YYYY" })), tags: React.createElement(Tag, { label: "Expiring member", appearance: "caution" }) }));
        }
        if (member.state.type === 'PastDue') {
            return (React.createElement(MembershipCell, { info: React.createElement(React.Fragment, null,
                    "Payment failed",
                    ' ',
                    React.createElement(DateString, { date: member.state.previousPeriodEnd, format: "MMM D, YYYY" })), tags: React.createElement(Tag, { label: "Past due member", appearance: "caution" }) }));
        }
        if (member.state.type === 'Former') {
            return (React.createElement(MembershipCell, { info: React.createElement(React.Fragment, null,
                    "Expired ",
                    React.createElement(DateString, { date: member.state.expiredAt, format: "MMM D, YYYY" })), tags: React.createElement(React.Fragment, null,
                    React.createElement(Tag, { label: "Former member", appearance: "neutral" }),
                    dashboardFeatureFlags.membershipsFreeAccounts.enabled ? (React.createElement(Tag, { label: "Free account", appearance: "neutral" })) : null) }));
        }
        if (member.state.type === 'None') {
            return React.createElement(MembershipCell, { tags: React.createElement(Tag, { label: "Free account", appearance: "neutral" }) });
        }
    };
    const mobileHeaders = [
        {
            id: 'supporter',
            label: undefined,
            render: (member) => {
                return (React.createElement(MobileCell, { name: member.nickname, avatarUrl: member.avatarUrl, shadowbanned: member.shadowBanned }, getMembershipCell(member)));
            },
        },
    ];
    const headers = [
        {
            id: 'supporter',
            label: 'Supporter',
            render: (member) => {
                return (React.createElement(SupporterCell, { name: member.nickname, avatarUrl: member.avatarUrl, shadowBanned: member.shadowBanned }));
            },
            width: 2,
        },
        {
            id: 'membership',
            label: 'Membership',
            render: getMembershipCell,
        },
        {
            id: 'joined_date',
            label: 'Joined date',
            render: (member) => React.createElement(DateString, { date: member.joinedAt, format: "MMM D, YYYY" }),
        },
    ];
    const table = {
        data: membersQuery.data?.members || [],
        headers: media.laptop ? headers : mobileHeaders,
        // @ts-ignore
        getRowLink: ({ id }) => ({
            pathname: reverse(routing.memberships.members.member.self, { memberId: id }),
            search: location.search,
        }),
    };
    const pagination = {
        pageCount: membersQuery.data?.pagination?.pages || 1,
        currentPage,
        onChange: setCurrentPage,
    };
    return {
        members: {
            table,
            pagination,
            isLoading: membersQuery.isLoading,
        },
    };
};
