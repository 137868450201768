import styled, { css } from 'styled-components';
export const Container = styled.div `
  width: 128px;
  margin-left: 36px;
  padding-top: 8px;
  padding-bottom: 16px;

  & + & {
    padding-top: 0;
  }

  & > & {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${({ fluid }) => fluid &&
    css `
      width: auto;
    `}
`;
