import { Pagination, Table, Subsection } from '@fourthwall/components';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dashboard } from '../../../../components/common/Dashboard';
import { useDashboardPermissions } from '../../../../components/utils/DashboardPermissions';
import { getProductOrdersTableHeaders } from './getProductOrdersTableHeaders/getProductOrdersTableHeaders';
import { getTableRowLink } from './getTableRowLink/getTableRowLink';
export const ProductOrdersView = ({ orders, isLoading, totalPages, currentPage, onCurrentPageChange, }) => {
    const { hasPermission } = useDashboardPermissions();
    if (!orders || !orders.length) {
        return null;
    }
    return (React.createElement(Subsection, { title: "Orders for this product", borderless: true },
        React.createElement(Dashboard.Section.Item, null,
            React.createElement(Dashboard.Section.ScrollableContent, null,
                React.createElement(Table, { data: orders, headers: getProductOrdersTableHeaders({
                        hideProfitColumn: !hasPermission('contributions.profit'),
                    }), isLoading: isLoading, rowComponent: Link, getRowLink: getTableRowLink }))),
        !!totalPages && (React.createElement(Dashboard.Section.Item, null,
            React.createElement(Pagination, { pageCount: totalPages, currentPage: currentPage, onChange: onCurrentPageChange })))));
};
