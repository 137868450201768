import React from 'react';
import { SortableContext, useSortable, horizontalListSortingStrategy, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndContext } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { ColorItem } from '../ColorItem';
import { Tooltip } from '../Tooltip';
import * as S from './styled';
import { getSelected } from './utils';
const ColorwayItem = ({ item, size, multiselect, isSelected, direction, disabled, onClick, onHover, }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isSorting } = useSortable({
        id: item.label,
        disabled,
    });
    return (React.createElement(Tooltip, { reference: React.createElement("button", { type: "button", ref: setNodeRef, style: {
                transform: CSS.Transform.toString(transform),
                transition,
                display: 'block',
            }, ...attributes, ...listeners, 
            // NOTE: This makes clicking work.
            onPointerDown: (e) => {
                onClick(item);
                listeners?.onPointerDown(e);
            }, onClick: () => onClick(item), onMouseEnter: onHover ? () => onHover(item) : undefined, "data-testid": "Colorway.Item" },
            React.createElement(ColorItem, { size: size, color: item, selected: isSelected, appearance: multiselect ? 'multi-select' : undefined, hoverable: true })), placement: direction === 'vertical' ? 'left' : 'top', offset: multiselect ? undefined : [0, 14] }, isSorting ? null : React.createElement("span", { style: { whiteSpace: 'nowrap' } }, item.label)));
};
export const Colorway = ({ colors, direction = 'horizontal', size = 'medium', activeColor, multiselect, onColorHover, onChange, onSortChange, }) => {
    if (colors.length === 0)
        return null;
    const getIndex = (id) => {
        return colors.map((color) => color.label).indexOf(id);
    };
    const handleDragEnd = (event) => {
        if (!onSortChange)
            return;
        if (!event.over)
            return null;
        onSortChange(getIndex(`${event.active.id}`), getIndex(`${event.over.id}`));
    };
    const handleClick = (color) => {
        if (!multiselect && getSelected(color.label, activeColor))
            return;
        if (!onChange)
            return;
        onChange(color);
    };
    const sortableSettings = {
        horizontal: { modifier: restrictToHorizontalAxis, strategy: horizontalListSortingStrategy },
        vertical: { modifier: restrictToVerticalAxis, strategy: verticalListSortingStrategy },
    }[direction];
    return (React.createElement(DndContext, { modifiers: [sortableSettings.modifier], onDragEnd: handleDragEnd },
        React.createElement(SortableContext, { items: colors.map((color) => color.label), strategy: sortableSettings.strategy },
            React.createElement(S.Container, { direction: direction }, colors.map((item, index) => (React.createElement("div", { key: `${item.label}-${item.value}-${index}`, style: { position: 'relative' } },
                React.createElement(S.Backdrop, null),
                React.createElement(ColorwayItem, { item: item, size: size, isSelected: getSelected(item.label, activeColor), multiselect: multiselect, disabled: !onSortChange, direction: direction, onClick: handleClick, onHover: onColorHover }))))))));
};
