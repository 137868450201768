import { reverse } from 'named-urls';
import { routing } from '../../../../../../../utils/routing';
export const getType = (data) => {
    if (data.type === 'ORDER_PLACED') {
        return {
            label: 'Order',
            url: reverse(routing.contributions.orders.order.self, { orderId: data.orderId }),
        };
    }
    if (data.type === 'ORDER_REFUNDED') {
        return {
            label: 'Order refund',
        };
    }
    if (data.type === 'ORDER_CANCELED') {
        return {
            label: 'Order cancel',
        };
    }
    if (data.type === 'INVENTORY_STORAGE_FEE') {
        return {
            label: 'Storage',
            url: reverse(routing.settings.inventoryStocks.self, {
                snapshotId: data.inventoryStocksSnapshotId,
            }),
        };
    }
    return {
        label: '-',
    };
};
export const getMethod = (data) => {
    if (data.type === 'CARD') {
        return {
            type: 'CARD',
            brand: data.brand,
            last4Digits: data.last4Digits,
        };
    }
    if (data.type === 'PAYPAL') {
        return {
            type: 'PAYPAL',
            email: data.email,
        };
    }
    // UNKNOWN
    return {
        type: 'UNKNOWN',
    };
};
