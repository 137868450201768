import { Banner, ButtonClean, useModalContext } from '@fourthwall/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routing, reverse } from '../../../../../utils/routing';
import { ChangeToAnotherProductModal } from '../ChangeToAnotherProductModal/ChangeToAnotherProductModal';
import { useOfferMigrateProductOptionsQuery } from '../../../hooks/useOfferMigrateProductOptionsQuery/useOfferMigrateProductOptionsQuery';
export const ProductDiscontinuedBanner = ({ offer, product }) => {
    const navigate = useNavigate();
    const { open, close } = useModalContext();
    const { offerMigrateProductOptionsQuery } = useOfferMigrateProductOptionsQuery([
        { offerId: offer.id },
    ]);
    const handleClick = () => {
        open(ChangeToAnotherProductModal, {
            offerId: offer.id,
            async onConfirm(duplicatedOfferId) {
                navigate(reverse(routing.products.all.product.self, { productId: duplicatedOfferId }));
            },
            onClose() {
                close();
            },
        });
    };
    if (offerMigrateProductOptionsQuery.isFetching ||
        offerMigrateProductOptionsQuery.data?.migrationOptions.length === 0) {
        return null;
    }
    return (React.createElement(Banner, { appearance: "warning" },
        React.createElement("p", null,
            product?.name,
            " has been discontinued and is no longer available.",
            ' ',
            React.createElement(ButtonClean, { label: "Offer this design on a similar product", onClick: handleClick }))));
};
