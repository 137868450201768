import { Fieldset, RadioButton, Subsection } from '@fourthwall/components';
import { Field } from 'formik';
import React from 'react';
import { FormFields, Usage } from '../../types';
export const CommonFields = ({ disabled }) => (React.createElement(Subsection, { title: "Welcome post" },
    React.createElement(Fieldset, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null, "Show your members a little love with a personal welcome post that appears to your supporters when they join.")),
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { name: FormFields.usage, component: RadioButton, label: "Don\u2019t use welcome post", checkedValue: Usage.none, disabled: disabled })),
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { name: FormFields.usage, component: RadioButton, label: "Use the same welcome post for each tier", checkedValue: Usage.same, disabled: disabled })),
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { name: FormFields.usage, component: RadioButton, label: "Use different welcome post for each tier", checkedValue: Usage.different, disabled: disabled })))));
