export const isAvailableGift = (gift) => {
    return gift.type === 'GIFT_AVAILABLE';
};
export const isRedeemedGift = (gift) => {
    return gift.type === 'GIFT_REDEEMED';
};
export const isGiftWithoutWinner = (gift) => {
    return gift.type === 'GIFT_AVAILABLE_WITHOUT_WINNER';
};
export const isGiftCancelled = (gift) => {
    return gift.type === 'GIFT_CANCELLED';
};
export const isCancelledGiftWithoutWinner = (gift) => {
    return gift.type === 'GIFT_CANCELLED_WITHOUT_WINNER';
};
