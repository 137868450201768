import React from 'react';
import { ArrowShortLeftCenteredIcon, ArrowShortRightCenteredIcon } from '@fourthwall/icons';
import { Icon } from '../Icon';
import * as S from './styled';
export const ArrowsButton = ({ direction, disabled, size = 'medium', onClick, }) => {
    const component = { prev: ArrowShortLeftCenteredIcon, next: ArrowShortRightCenteredIcon }[direction];
    const height = { small: 12, medium: 14 }[size];
    return (React.createElement(S.Button, { disabled: disabled, size: size, onClick: onClick },
        React.createElement(Icon, { component: component, height: height })));
};
