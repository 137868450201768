import React from 'react';
import { PostTypeThumbnail } from '../PostTypeThumbnail';
import * as S from './PostCell.styled';
export const PostCell = ({ type, title, thumbnailUrl, resources, subtitle, pinned }) => {
    return (React.createElement(S.Container, { "data-testid": `PostCell.container-${title}` },
        React.createElement(PostTypeThumbnail, { type: type, thumbnailUrl: thumbnailUrl, resources: resources, pinned: pinned }),
        React.createElement(S.Description, null,
            React.createElement(S.Title, null, title),
            React.createElement(S.Subtitle, null, subtitle))));
};
