import React from 'react';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { QuantityBadge } from '@components/common/QuantityBadge';
import { StatusTag } from '@components/common/StatusTag';
import * as S from './styled';
export const CollectionListItem = ({ name, quantity, photoUrl, status, timeFrame, }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.Photo, null,
            React.createElement(PhotoThumbnail, { url: photoUrl, size: "extraLarge" }),
            quantity > 0 && React.createElement(QuantityBadge, { quantity: quantity })),
        React.createElement(S.Details, null,
            React.createElement("div", null, name),
            status && React.createElement(StatusTag, { status: status, timeFrame: timeFrame }))));
};
