import React from 'react';
import { StatusTag } from '@components/common/StatusTag';
import { getDefaultProductImage } from '@utils/products';
import { Checkbox, RadioButton, Tag, Tooltip, useMediaQueryContext } from '@fourthwall/components';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import * as S from './styled';
export const SelectProductsListItem = ({ product, mode, multiple, disabled, disabledMessage, value, onSelect, }) => {
    const media = useMediaQueryContext();
    const renderInput = () => {
        const input = mode === 'INVENTORY_SHIPMENT' || multiple ? (React.createElement(Checkbox, { value: value, onValueChange: onSelect(product), disabled: disabled })) : (React.createElement(RadioButton, { value: value, checkedValue: product.id, disabled: disabled, onChange: onSelect(product) }));
        return disabledMessage ? React.createElement(Tooltip, { reference: input }, disabledMessage) : input;
    };
    const renderStatus = () => {
        if (mode === 'INVENTORY_SHIPMENT') {
            return product.customsInformation.shortHsCode ? null : (React.createElement(Tag, { appearance: "caution", label: "Product category required", tooltip: "Product category required" }));
        }
        return React.createElement(StatusTag, { status: product.state.status, isSoldOut: !product.state.available });
    };
    return (React.createElement(S.Container, { disabled: disabled },
        React.createElement(S.OpacityWrapper, null, renderInput()),
        React.createElement(S.OpacityWrapper, null,
            React.createElement(PhotoThumbnail, { url: getDefaultProductImage(product), size: media.tablet ? 'large' : 'small' })),
        React.createElement(S.ProductInfo, null,
            React.createElement(S.OpacityWrapper, null, product.name),
            React.createElement(S.Status, null, media.tablet ? null : renderStatus())),
        media.tablet ? renderStatus() : null));
};
