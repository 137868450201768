import styled from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  padding: 24px 20px;
  background: ${Colors.GS0};
  border: 1px solid ${Colors.GS400};
  border-style: solid none;
  border-radius: 2px;

  ${mediaQueries.tablet} {
    padding: 24px 28px;
    border-style: solid;
  }
`;
export const Header = styled.header `
  margin-bottom: 24px;

  display: grid;
  gap: 20px;

  ${mediaQueries.tablet} {
    margin-bottom: 28px;

    grid-template-columns: 1fr auto;
    gap: 24px;
    align-items: center;
  }
`;
