import { getImageDirectUploadData } from '../../../../../utils/getImageDirectUploadData';
import { getCommonWelcomePostPayload } from './getCommonWelcomePostPayload';
export const getImageWelcomePostPayload = async (welcomePost) => {
    if (typeof welcomePost.image === 'object') {
        return {
            type: 'Image',
            attributes: getCommonWelcomePostPayload(welcomePost),
            imageDirectUploadData: await getImageDirectUploadData(welcomePost.image),
        };
    }
    return {
        type: 'Image',
        attributes: getCommonWelcomePostPayload(welcomePost),
    };
};
