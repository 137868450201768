import { OffersApiClient } from '@fourthwall/services/lib/OffersApiClient';
import { useInfiniteQuery } from '@tanstack/react-query';
import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
export const useOffersInfiniteQuery = (params, options) => {
    const offersInfiniteQuery = useInfiniteQuery(['offersInfinite', params], async ({ pageParam = 0 }) => {
        const { data } = await OffersApiClient.getOffers({ ...params, page: pageParam });
        return data;
    }, {
        getNextPageParam: ({ page: { hasNextPage, pageNumber } }) => hasNextPage && pageNumber + 1,
        select: (data) => {
            data.pages[0].offers = data.pages[0].offers.map((offer) => ({
                ...offer,
                name: decodeHtmlEntities(offer.name),
            }));
            return data;
        },
        ...options,
    });
    return {
        offersInfiniteQuery,
    };
};
