import { getCommonPerkPayload } from './getCommonPerkPayload';
export const getUpdateRssPerkPayload = (formValues) => {
    const commonPayload = getCommonPerkPayload(formValues);
    const getTags = () => {
        if (formValues.rssType === 'all') {
            return [];
        }
        return formValues.rssTags.map((tag) => {
            return {
                id: tag.id,
            };
        });
    };
    const getImage = () => {
        if (formValues.image) {
            return {
                type: 'New',
                data: formValues.image,
            };
        }
        if (formValues.removeImage) {
            return {
                type: 'Remove',
            };
        }
        return undefined;
    };
    return {
        ...commonPayload,
        tags: getTags(),
        image: getImage(),
        hidden_for_tiers: formValues.hidden,
    };
};
