import { BackButton, Button, ButtonClean, Icon, InfoTooltip, List, PriceString, Skeleton, Subsection, useModalContext, } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { Dashboard, PageLayout } from '@components/common';
import { SideSummary } from '@components/common/SideSummary';
import { routing } from '@utils/routing';
import { SamplesOrdersListItem } from './components/SamplesOrdersListItem';
import { validationSchema } from './consts';
import { SelectItemsModalContainer } from './modules/SelectItemsModal';
import * as S from './styled';
import { createListData, getCounterText, mapValuesToSummary, removeItemFromValues } from './utils';
export const SamplesOrderView = ({ credit, offers, button, initialValues = { items: [] }, showModal = false, onSubmit, }) => {
    const { open, close } = useModalContext();
    const formRef = useRef(null);
    const createOpenModal = useCallback((values, setValues) => () => {
        open(SelectItemsModalContainer, {
            initialValues: values,
            onSubmit: (newValues) => {
                setValues(newValues);
                close();
            },
        });
    }, [open, close]);
    useEffect(() => {
        if (!showModal)
            return;
        if (!formRef.current)
            return;
        createOpenModal(formRef.current.values, formRef.current.setValues)();
    }, [showModal, createOpenModal]);
    const handleRemoveItemButtonClick = (values, setValues) => (item) => {
        const newValues = removeItemFromValues(values, item.id);
        setValues(newValues);
    };
    const renderSummaryContent = (summary) => {
        if (summary.items <= 0) {
            return (React.createElement(S.SummaryItem, null,
                React.createElement("div", null, "Add items to the cart")));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(S.SummaryItem, null,
                React.createElement("div", null,
                    "Subtotal (",
                    summary.items,
                    " ",
                    summary.items === 1 ? 'item' : 'items',
                    ")"),
                React.createElement("div", null,
                    React.createElement(PriceString, { value: summary.subtotal }))),
            React.createElement(S.SummaryItem, null,
                React.createElement("div", null, "Shipping"),
                React.createElement("div", null, "-")),
            !!summary.samplesCredit && (React.createElement(S.SummaryItem, null,
                React.createElement("div", null, "Samples credit"),
                React.createElement("div", null,
                    React.createElement(PriceString, { value: summary.samplesCredit })))),
            !!summary.profitsBalance && (React.createElement(S.SummaryItem, null,
                React.createElement(S.SummaryItemTooltipLabelContainer, null,
                    React.createElement("div", null, "Profits balance"),
                    React.createElement(S.SummaryItemTooltipLabelTooltipContainer, null,
                        React.createElement(InfoTooltip, { content: "When samples credit do not cover the order, you pay for samples out of your profits", dark: true }))),
                React.createElement("div", null,
                    React.createElement(PriceString, { value: summary.profitsBalance })))),
            React.createElement(S.SummaryDivider, null),
            React.createElement(S.SummaryItem, null,
                React.createElement("div", null, "Total to pay"),
                React.createElement("div", null,
                    React.createElement(PriceString, { value: summary.total }))),
            React.createElement(S.SummaryFooter, null, "+ Shipping and taxes calculated at checkout")));
    };
    const renderSummary = (values) => {
        if (!credit || !offers)
            return null;
        const summary = mapValuesToSummary(values, credit, offers);
        if (!summary)
            return null;
        return (React.createElement(SideSummary, null,
            React.createElement("div", { style: { marginBottom: 8 } },
                React.createElement("p", { style: { fontWeight: 600, fontSize: 16, lineHeight: 1.5 } }, "Order summary")),
            React.createElement("div", null, renderSummaryContent(summary)),
            React.createElement("div", { style: { marginTop: 16 } },
                React.createElement(Button, { label: "Order now", type: "submit", appearance: "primary", fullWidth: true, disabled: !(summary.items > 0) || !formRef?.current?.isValid, loading: button.loading, analytics: "samplesOrderNow" }))));
    };
    return (React.createElement(Formik, { initialValues: initialValues, innerRef: formRef, validationSchema: validationSchema, validateOnMount: true, onSubmit: onSubmit }, ({ values, setValues }) => (React.createElement(Form, { noValidate: true },
        React.createElement(PageLayout, { leftContent: React.createElement(Subsection, { title: "Your cart" },
                React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: 4 } },
                    React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                        React.createElement("p", { style: { lineHeight: 1.5 } }, getCounterText(values))),
                    React.createElement("div", null,
                        React.createElement(Button, { label: "Add items", size: "small", leftIcon: React.createElement(Icon, { component: PlusIcon, height: 12 }), onClick: createOpenModal(values, setValues) }))),
                !!values.items.length && (React.createElement("div", null,
                    React.createElement(List, { data: createListData(values, offers), topBorder: false, renderItem: (orderItem, index) => (React.createElement(SamplesOrdersListItem, { orderItem: orderItem, index: index, quantity: values.items[index].quantity.toString(), onRemove: () => handleRemoveItemButtonClick(values, setValues)(orderItem), onSetQuantity: (quantity) => {
                                values.items[index].quantity = Number(quantity);
                                setValues({ ...values });
                            } })) })))), rightContent: renderSummary(values) },
            React.createElement(Dashboard.Section, { name: "Order samples", topContent: React.createElement(BackButton, { label: "Back", to: routing.settings.samples.self }), title: React.createElement(React.Fragment, null,
                    "Order pre-production samples of your products for quality checks and promotion. You can use sample credit (if available) or a credit card to pay for your samples.",
                    ' ',
                    React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.orderingSamples, target: "_blank" })) }, credit ? (React.createElement("p", { style: { marginTop: 8 - 24, marginBottom: 40, fontWeight: 600 } },
                "Your current samples credit: ",
                React.createElement(PriceString, { value: credit.value }))) : (React.createElement(Skeleton, null,
                React.createElement(Skeleton.Shape, { width: 200 })))))))));
};
