export const LABELS = {
    X_SMALL: 'XSmall',
    SMALL: 'Small',
    MEDIUM: 'Medium',
    LARGE: 'Large',
    X_LARGE: 'XLarge',
    CUSTOM: 'Custom',
};
export const EXAMPLES_BY_SIZES = {
    X_SMALL: ['Pin', 'greeting card', 'sticker sheet', 'magnet', 'key chain'],
    SMALL: ['Socks', 'CD', 'vinyl', 'small poster rolled in a tube'],
    MEDIUM: ['T-shirt', 'hat', 'mug', 'water bottle', 'sweatshirt', 'small plush'],
    LARGE: ['Hoodie', 'jacket', 'large plush', 'boxed puzzle'],
    X_LARGE: ['Large blanket', 'small bean bag', 'body pillow'],
    CUSTOM: [],
};
