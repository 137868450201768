import React from 'react';
import { CloseIcon } from '@fourthwall/icons';
import { Checkboard } from '../../partials/Checkboard';
import { Icon } from '../Icon';
import * as S from './UploadedAsset.styled';
export const UploadedAsset = ({ id, src, withCheckboard = false, disabled, appearance, onClick, onRemove, children, }) => {
    const handleRemove = (event) => {
        event.stopPropagation();
        onRemove(id);
    };
    const handleClick = () => {
        if (onClick) {
            onClick(src);
        }
    };
    return (React.createElement(S.Container, { key: id, disabled: disabled, appearance: appearance, onClick: handleClick, "data-testid": "UploadedAsset.Container" },
        withCheckboard && React.createElement(Checkboard, { size: 16, white: "#646560", grey: "#40413D" }),
        children,
        React.createElement(S.RemoveButton, { "aria-label": "Remove", onClick: handleRemove },
            React.createElement(Icon, { component: CloseIcon, height: 12 }))));
};
UploadedAsset.Video = function ({ src, controls = true }) {
    return React.createElement(S.Video, { src: src, controls: controls });
};
UploadedAsset.Image = function ({ src, alt = '' }) {
    return React.createElement(S.Image, { src: src, alt: alt });
};
