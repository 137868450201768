import { useQueryPage } from '@fourthwall/utils';
import { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useKudosOrdersQuery } from '../useKudosOrdersQuery';
import { createUseKudosOrdersQueryParams } from './utils';
export const useThankYous = () => {
    const [state, setState] = useQueryParam('state', StringParam);
    const activeTab = (state || 'WAITING');
    const { page, setPage } = useQueryPage();
    const params = createUseKudosOrdersQueryParams(page, activeTab);
    const { kudosOrdersQuery, invalidateKudosOrdersQuery } = useKudosOrdersQuery([params], {
        keepPreviousData: true,
    });
    const data = kudosOrdersQuery.data?.orders || [];
    const isLoading = kudosOrdersQuery.isFetching;
    const noThankYous = kudosOrdersQuery.data?.contributions === 'NONE';
    useEffect(() => {
        setPage(undefined);
    }, [state]);
    return {
        thankYous: {
            activeTab,
            data,
            isLoading,
            noThankYous,
            currentPage: page,
            totalPages: kudosOrdersQuery.data?.page.totalPages,
            setState,
            setPage,
            refetch: invalidateKudosOrdersQuery,
        },
    };
};
