import { Toasts } from '@fourthwall/components';
import { DnsApiClient } from '@fourthwall/services/lib/DnsApiClient';
import { useMutation } from '@tanstack/react-query';
export const useDeleteDomainMutation = () => {
    const deleteDomainMutation = useMutation({
        mutationFn: async () => {
            const { data } = await DnsApiClient.deleteDomain();
            return data;
        },
        onError: () => {
            Toasts.notify('Failed to delete domain', { type: 'error' });
        },
    });
    return {
        deleteDomainMutation,
    };
};
