import styled from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
import { DASHBOARD_STYLED_CONSTS } from '@components/common/Dashboard';
export const Container = styled.ul `
  display: flex;
  flex-direction: column;

  ${mediaQueries.tablet} {
    gap: 20px;
  }
`;
export const ItemContainer = styled.li `
  position: relative;
  left: -${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL}px;
  width: calc(100% + ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL * 2}px);
  border: 1px solid ${Colors.GS400};

  & + & {
    border-top: 0;
  }

  ${mediaQueries.tablet} {
    position: static;
    width: auto;

    & + & {
      border-top: 1px solid ${Colors.GS400};
    }
  }
`;
export const ItemInner = styled.a `
  padding: 24px 20px 28px;
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: 16px;

  ${mediaQueries.tablet} {
    padding: 24px 28px;
    gap: 40px;
    align-items: stretch;
  }
`;
export const ItemImageContainer = styled.div `
  height: 56px;
  aspect-ratio: 200 / 112;
  align-self: flex-start;

  ${mediaQueries.tablet} {
    width: 200px;
    height: 112px;
    aspect-ratio: initial;
    align-self: stretch;
  }
`;
export const ItemImage = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;

  display: block;
`;
export const ItemMain = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div `
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  ${mediaQueries.tablet} {
    font-size: 20px;
    line-height: 140%;
  }
`;
export const Description = styled.div `
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.14px;

  ${mediaQueries.tablet} {
    font-size: 16px;
    margin-top: 4px;
    flex: 1;
    letter-spacing: unset;
  }
`;
export const Footer = styled.footer `
  margin-top: 8px;

  ${mediaQueries.tablet} {
    display: flex;
    align-items: flex-end;
  }
`;
export const TimeToRead = styled.div `
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  color: ${Colors.GS600};
  display: flex;
  align-items: center;
  gap: 4px;

  ${mediaQueries.tablet} {
    font-size: 16px;
    flex: 1;
    letter-spacing: unset;
  }
`;
