import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px 20px;
  overflow-y: auto;

  ${mediaQueries.laptop} {
    padding: 0 24px 24px;
  }
`;
export const LoaderContainer = styled.div `
  flex: 1;

  height: 100px;
  min-height: 0;
`;
