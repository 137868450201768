import React from 'react';
import { useUpdateEmailMutation } from '../mutations';
import { useOrderIdParam } from '../hooks';
import { useOrderQuery } from '../queries';
import { OrderEmailView } from './OrderEmailView';
export const OrderEmailContainer = ({ onClose }) => {
    const { orderId } = useOrderIdParam();
    const { orderQuery, invalidateOrderQuery } = useOrderQuery([orderId]);
    const email = orderQuery.data?.email;
    const { updateEmailMutation } = useUpdateEmailMutation({
        onSuccess: () => {
            invalidateOrderQuery();
        },
        onSettled: () => {
            onClose();
        },
    });
    if (!email)
        return null;
    const handleSubmit = async (values) => {
        await updateEmailMutation.mutateAsync([{ orderId, email: values.email }]);
    };
    return React.createElement(OrderEmailView, { initialValues: { email }, onSubmit: handleSubmit, onClose: onClose });
};
