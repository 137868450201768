import { useCreatorIntegrationsV2Query } from '@queries/useCreatorIntegrationsV2Query';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { filterSalesApp, sortApp, mapApp } from './utils';
export const useIntegrationsRecommendation = () => {
    const { creatorIntegrationsV2Query } = useCreatorIntegrationsV2Query();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const apps = (() => {
        if (!creatorIntegrationsV2Query.isSuccess)
            return;
        return creatorIntegrationsV2Query.data.apps
            .filter(filterSalesApp)
            .sort(sortApp)
            .map(mapApp)
            .map((app) => {
            const enabled = app.flag ? dashboardFeatureFlags[app.flag].enabled : true;
            return {
                ...app,
                to: enabled ? app.to : undefined,
            };
        });
    })();
    return { apps };
};
