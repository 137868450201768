import { useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useTagManager } from '@hooks';
export const useAppConnectedEvent = (app) => {
    const [connectedParam, setConnectedParam] = useQueryParam('connected', StringParam);
    const tagManager = useTagManager();
    useEffect(() => {
        // Correct value is an empty string.
        if (connectedParam !== '')
            return;
        tagManager.trigger('app_connected', { app_name: app });
        setConnectedParam(undefined, 'replaceIn');
    }, [app, connectedParam, tagManager, setConnectedParam]);
};
