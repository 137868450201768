import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin: -16px 0;
  min-height: 96px;
`;
export const VariantsImageCellWrapper = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Price = styled.div `
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const CompareAtPrice = styled.div `
  text-decoration: line-through;
  display: inline-block;
  color: ${Colors.GS600};
`;
export const Details = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px 0;
`;
export const DetailsRow = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
`;
export const Arrow = styled.div `
  color: ${Colors.GS600};
`;
