const IOS_FOOTPRINT = 'iOSFourthwall';
const ANDROID_FOOTPRINT = 'AndroidFourthwall';
export const isWebView = (ua = window.navigator.userAgent) => {
    return [IOS_FOOTPRINT, ANDROID_FOOTPRINT].some((footprint) => ua.includes(footprint));
};
export const isIosApp = (ua = window.navigator.userAgent) => {
    return ua.includes(IOS_FOOTPRINT);
};
export const isAndroidApp = (ua = window.navigator.userAgent) => {
    return ua.includes(ANDROID_FOOTPRINT);
};
export const onWebViewModalOpen = () => {
    if (!isWebView())
        return;
    try {
        // @ts-ignore
        isIosApp() && window.webkit.messageHandlers.modalAppeared.postMessage(true);
        // @ts-ignore
        isAndroidApp() && window.Modal.modalAppeared();
    }
    catch (_a) {
        throw new Error('Calling window.webkit.messageHandlers.modalAppeared.postMessage or window.Modal.modalAppeared failed.');
    }
};
export const onWebViewModalClose = () => {
    if (!isWebView())
        return;
    try {
        // @ts-ignore
        isIosApp() && window.webkit.messageHandlers.modalDisappeared.postMessage(true);
        // @ts-ignore
        isAndroidApp() && window.Modal.modalDisappeared();
    }
    catch (_a) {
        throw new Error('Calling window.webkit.messageHandlers.modalDisappeared.postMessage or window.Modal.modalDisappeared failed.');
    }
};
