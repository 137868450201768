import { useEffect } from 'react';
// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
// According to the specification, to show the confirmation dialog an event
// handler should call preventDefault() on the event. However note that not all
// browsers support this method, and some instead require the event handler to
// implement one of two legacy methods:
// - assigning a string to the event's returnValue property
// - returning a string from the event handler.
const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    return '';
};
const addListener = () => {
    window.addEventListener('beforeunload', handleBeforeUnload);
};
const removeListener = () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
};
export const useBeforeUnload = (when) => {
    useEffect(() => {
        if (when) {
            addListener();
        }
        return removeListener;
    }, [when]);
};
