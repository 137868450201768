import React from 'react';
import { Tabs as TabsContainer } from 'react-tabs';
import { List } from './List';
import { Panel } from './Panel';
import { Tab } from './Tab';
import * as S from './styled';
export const Tabs = ({ direction = 'horizontal', defaultIndex, children, onSelect }) => {
    return (React.createElement(S.Container, { "$direction": direction },
        React.createElement(TabsContainer, { defaultIndex: defaultIndex, onSelect: onSelect }, children)));
};
Tabs.Item = Tab;
Tabs.Panel = Panel;
Tabs.List = List;
