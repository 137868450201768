import { getImageDirectUploadData } from '../../../../utils/getImageDirectUploadData';
import { getCommonFieldsPayload } from './getCommonFieldsPayload';
export const getAudioPostPayload = async (values) => {
    const payload = getCommonFieldsPayload(values);
    return {
        ...payload,
        imageDirectUploadData: typeof values.audioThumbnail === 'object'
            ? await getImageDirectUploadData(values.audioThumbnail)
            : undefined,
        new_media_direct_upload: typeof values.audio === 'object',
    };
};
