import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import logoSrc from '../../../assets/signets/discord.svg';
import { useOAuthDialog } from '../../../hooks/useOAuthDialog';
import { useCreateDiscordIntegrationMutation } from './useCreateDiscordIntegrationMutation';
import { useDeleteDiscordIntegrationMutation } from './useDeleteDiscordIntegrationMutation';
import { useDiscordIntegrationQuery } from './useDiscordIntegrationQuery';
import { useDiscordRolesQuery } from './useDiscordRolesQuery';
export const useDiscord = () => {
    const { open } = useModalContext();
    const { discordIntegrationQuery } = useDiscordIntegrationQuery();
    const { createDiscordIntegrationMutation } = useCreateDiscordIntegrationMutation();
    const { deleteDiscordIntegrationMutation } = useDeleteDiscordIntegrationMutation();
    const status = !!discordIntegrationQuery.data && !discordIntegrationQuery.isError
        ? 'CONNECTED'
        : 'NOT_CONNECTED';
    const { discordRolesQuery } = useDiscordRolesQuery(status === 'CONNECTED');
    const getErrorMessage = (error) => {
        switch (error) {
            case 'access_denied':
                return 'Connection access denied. Try connecting again.';
            case 'wrong_bot_permissions':
                return "Fourthwall bot doesn't have correct role permissions set.";
            case 'integration_already_exists':
                return 'This connection already exists.';
            case 'unknown_error':
            default:
                return 'Something went wrong. Please try again.';
        }
    };
    const { openOAuthDialog } = useOAuthDialog({
        height: 760,
        onComplete: () => {
            discordIntegrationQuery.refetch();
            discordRolesQuery.refetch();
        },
        onError: (event) => {
            Toasts.notify(getErrorMessage(event.data.description), { type: 'error' });
        },
    });
    const handleConnect = async () => {
        const integration = await createDiscordIntegrationMutation.mutateAsync();
        openOAuthDialog(integration.redirectTo);
    };
    const handleDisconnect = () => {
        deleteDiscordIntegrationMutation.mutate(undefined, {
            onSuccess: () => {
                discordIntegrationQuery.refetch();
                discordRolesQuery.refetch();
                Toasts.notify('Disconnected from Discord successfully.', { type: 'success' });
            },
        });
    };
    const getConnectBar = () => {
        const connectBarBase = {
            logoSrc,
            description: 'Connect to your Discord account',
            disabled: true,
        };
        if (status === 'NOT_CONNECTED') {
            return {
                ...connectBarBase,
                disabled: discordIntegrationQuery.isFetching,
                onConnect: handleConnect,
            };
        }
        if (status === 'CONNECTED') {
            return {
                ...connectBarBase,
                disabled: discordIntegrationQuery.isFetching,
                onDisconnect: () => {
                    open(ConfirmModal, {
                        title: 'Are you sure you want to disconnect from Discord?',
                        confirmLabel: 'Yes, disconnect',
                        confirmAppearance: 'destructive',
                        onConfirm: handleDisconnect,
                    });
                },
            };
        }
        return connectBarBase;
    };
    return {
        discord: {
            status,
            roles: discordRolesQuery.data?.roles,
            connectBar: getConnectBar(),
            isLoading: discordIntegrationQuery.isFetching || discordRolesQuery.isFetching,
            handleConnect,
            handleDisconnect,
        },
    };
};
