import { isProduction } from '@fourthwall/utils/lib/dev';
import { include } from 'named-urls';
// NOTE: https://github.com/tricoder42/named-urls#using-an-include-route
// we use "self" to get paths of items using include function
const home = '/admin/dashboard';
const helpCenter = 'https://help.fourthwall.com';
const docs = 'https://docs.fourthwall.dev';
export const getFourthwallUrl = () => {
    if (isProduction()) {
        return 'https://fourthwall.com';
    }
    return 'https://staging.fourthwall.com';
};
export const routing = {
    home,
    redirect: include(`${home}/redirect`, {
        googleAnalytics: 'google-analytics',
        meta: 'meta',
        streamlabs: 'streamlabs',
        twitch: 'twitch',
    }),
    connectIntegration: `${home}/connect-integration/:integration`,
    analytics: include(`${home}/analytics`, {
        self: '',
        reportContributions: 'report/contributions', // redirect
        report: 'report/:reportType',
        trackingPixels: 'tracking-pixels',
    }),
    apps: include(`${home}/apps`, {
        self: '',
        app: ':app',
        beehiiv: 'beehiiv',
        discord: 'discord',
        discordVerification: 'fwdiscord',
        instagram: 'instagram',
        klaviyo: 'klaviyo',
        laylo: 'laylo',
        meta: 'meta',
        shipStation: 'ship-station',
        streamelements: 'streamelements',
        streamlabs: 'streamlabs',
        tiktok: 'tiktok',
        tiktokShop: 'tiktok-partner',
        twitch: 'twitch',
        twitchMembershipsDiscounts: 'twitch-discounts',
        twitchMembershipsDiscountsLegacy: 'twitchMembershipsDiscounts',
        twitterShops: 'twitter-shops',
        youTubeProductShelf: 'youtube-product-shelf',
        youTubeProductShelfLegacy: 'youtube-merch-shelf',
    }),
    products: include(`${home}/products`, {
        self: '*',
        all: include('all', {
            self: '',
            product: include(':productId', {
                self: '',
                variants: 'variants',
                gallery: 'gallery',
                sections: 'sections',
                settings: 'settings',
                costs: 'costs',
            }),
        }),
        collections: include('collections', {
            self: '',
            create: include('create', { self: '' }),
            collection: include(':collectionId', { self: '' }),
        }),
        preview: ':productId/preview',
    }),
    productCatalog: {
        self: '/admin/products/',
        product: '/admin/products/:slug',
        favorites: '/admin/products/favorites',
    },
    productDesignerV3: include('/admin/v3/designer', {
        self: '',
        customizationEdit: ':customizationId/edit/offer/:productId',
    }),
    printfulEmbededDesigner: include('/admin/embedded/designer', {
        self: '',
        customizationEdit: ':customizationId/edit/offer/:productId',
    }),
    checkout: '/checkout/:checkoutId?/:stepId?',
    contributions: include(`${home}/contributions`, {
        self: '*',
        fulfillment: 'fulfillment',
        orders: include('orders', {
            all: '',
            order: include(':orderId', {
                self: '',
                edit: 'edit',
                cancel: 'cancel',
                refund: 'refund',
            }),
            donations: include('donations', {
                all: 'all',
                donation: include(':donationId', {
                    self: '',
                }),
            }),
            giveaways: include('giveaways', {
                all: 'all',
                giveaway: ':giveawayId',
            }),
        }),
        thankYous: include('thankyous', {
            self: '',
        }),
        creatorMessages: include('messages', {
            self: '',
            send: 'send',
            message: include(':messageId', {
                self: '',
            }),
        }),
    }),
    themeEditor: include(`${home}/store-design/`, {
        self: '',
        general: include('general', {
            self: '',
            fonts: 'fonts',
            fontChange: 'font-change',
            themes: 'themes',
            socialLinks: 'social-links',
            sections: 'sections/:sectionId',
            advanced: 'advanced',
        }),
        experimental: include('experimental', {
            self: '',
        }),
        layout: include('layout', {
            self: '',
            page: include(':pageId', {
                details: 'page-details',
                self: '',
                sections: 'sections/:sectionKey',
            }),
        }),
        navigations: include('navigations', {
            self: '',
            link: ':navigationHandle/:linkId',
        }),
        themes: include('themes', {
            self: '',
        }),
        pages: include('pages', {
            self: '',
        }),
    }),
    promotions: include(`${home}/promotions`, {
        self: '*',
        giveawayPackages: include('giveaway-packages', {
            self: '',
            create: include('create', {
                self: '',
            }),
            giveawayPackage: include(':giveawayPackageId', {
                self: '',
            }),
        }),
        promotionCodes: include('promotion-codes', {
            self: '',
            create: include('create', {
                self: '',
            }),
            promotionCode: include(':promotionId', {
                self: '',
            }),
            membershipsPromotion: include('memberships/:promotionId', {
                self: '',
            }),
            membershipsPromotionCreate: include('memberships/create', {
                self: '',
            }),
        }),
    }),
    settings: include(`${home}/settings`, {
        self: '*',
        general: 'general',
        team: 'team',
        appsLegacy: include('apps', {
            app: ':app',
        }),
        shipping: 'shipping',
        shippingGroup: include('shipping-group', {
            self: ':shippingGroupId',
            create: 'create',
        }),
        inventoryShipments: include('inventory-shipments', {
            self: ':shippingId',
            create: 'create',
        }),
        inventoryStocks: include('inventory-stocks', {
            self: ':snapshotId',
        }),
        checkout: 'checkout',
        forDevelopers: 'for-developers',
        domain: include('domain', {
            self: '',
            create: 'create',
            configuration: 'configuration',
        }),
        billing: include('billing', {
            self: '',
            transactions: 'transactions',
        }),
        samples: include('samples', {
            self: '',
            order: 'order',
        }),
    }),
    account: include(`${home}/account`, {
        self: '',
        security: 'security',
        emailNotifications: 'email-notifications',
        connectedSites: 'connected-sites',
    }),
    membershipsBeta: include(`${home}/memberships-beta`, {
        self: '',
    }),
    membershipsEnable: include(`${home}/memberships-enable`, {
        self: '',
    }),
    memberships: include(`${home}/memberships`, {
        self: '*',
        overview: 'overview',
        posts: include('posts', {
            self: '',
            create: 'create',
            post: include(':postId', {
                self: '',
            }),
            scheduled: include('scheduled', {
                all: 'all',
            }),
        }),
        series: include('series', {
            self: '',
            create: 'create',
            singleSeries: include(':singleSeriesId', {
                self: '',
            }),
        }),
        perks: 'perks',
        members: include('members', {
            self: '',
            member: include(':memberId', {
                self: '',
            }),
        }),
        messages: include('messages', {
            self: '',
            create: 'create',
            message: include(':messageId', {
                self: '',
            }),
        }),
        settings: include(`settings`, {
            self: '*',
            general: 'general',
            messages: 'messages',
            rewardPoints: 'reward-points',
            welcomePost: 'welcome-posts',
            mobileApp: 'mobile-app',
            upselling: 'upselling',
        }),
    }),
    internal: {
        customizeComingSoon: `${home}/store-design/layout/password/`,
    },
    external: {
        hubspot: {
            bookAcall: `https://meetings.hubspot.com/fourthwall/getstarted`,
        },
        helpCenter: {
            self: helpCenter,
            abandonedCheckoutEmails: `${helpCenter}/hc/en-us/articles/22988811094811`,
            beehiiv: `${helpCenter}/hc/en-us/articles/20819244391195`,
            buyNowPayLater: `${helpCenter}/hc/en-us/articles/22444724194715`,
            cookiePolicy: `${helpCenter}/hc/en-us/articles/22448093388443`,
            creditCard: `${helpCenter}/hc/en-us/articles/13331256450203`,
            giveawayLinks: `${helpCenter}/hc/en-us/articles/13331257130523`,
            googleTrackingPixels: `${helpCenter}/hc/en-us/articles/18176371774491`,
            klaviyo: `${helpCenter}/hc/en-us/articles/19419091421339`,
            laylo: `${helpCenter}/hc/en-us/articles/18537710201115`,
            meta: `${helpCenter}/hc/en-us/articles/25096229829147`,
            metaTrackingPixels: `${helpCenter}/hc/en-us/articles/13331225583771`,
            multicurrency: `${helpCenter}/hc/en-us/articles/18300828600347`,
            orderingSamples: `${helpCenter}/hc/en-us/articles/13331282778395`,
            selfFulfillment: `${helpCenter}/hc/en-us/articles/13331253379739`,
            selfSipping: `${helpCenter}/hc/en-us/articles/17385690128923`,
            shipStationIntegration: `${helpCenter}/hc/en-us/articles/18437332272411`,
            streamAlerts: `${helpCenter}/hc/en-us/articles/13331283282331`,
            thankYous: `${helpCenter}/hc/en-us/articles/13331252782235`,
            tiktokShop: `${helpCenter}/hc/en-us/articles/20059966612763`,
            tiktokShopPayment: `${helpCenter}/hc/en-us/articles/19638911329819`,
            twitchGifting: `${helpCenter}/hc/en-us/articles/13331277761563`,
            twitchMembershipsIntegrations: `${helpCenter}/hc/en-us/articles/14314760750235`,
            twitchShopDiscounts: `${helpCenter}/hc/en-us/articles/24358709408411`,
            youtubeProductShelf: `${helpCenter}/hc/en-us/articles/13331252530203`,
        },
        signup: `${getFourthwallUrl()}/signup`,
        productCatalog: `${getFourthwallUrl()}/products`,
        docs: {
            self: docs,
            openAPI: `${docs}/open-api/`,
        },
        shipStation: {
            apiSettings: 'https://ship12.shipstation.com/settings/api',
        },
        klaviyo: {
            dashboard: 'https://www.klaviyo.com/dashboard',
        },
        beehiiv: {
            apiDocs: 'https://developers.beehiiv.com/docs/v2/bktd9a7mxo67n-create-an-api-key?utm_source=fourthwall',
        },
        laylo: {
            apiDocs: 'https://laylo.com/settings/integrations',
        },
        tiktok: {
            orderDetails: `https://seller-us.tiktok.com/order/detail`,
            warehouse: `https://seller-us.tiktok.com/profile/account-setting/warehouse`,
            shippingTemplate: `https://seller-us.tiktok.com/logistics/shipping-template`,
        },
        youtube: {
            shoppingSettings: 'https://studio.youtube.com/channel/user/monetization/shopping',
        },
        partnerDashboard: isProduction()
            ? 'https://partners.fourthwall.com/'
            : 'https://partners.staging.fourthwall.com/',
    },
    blockedTransactions: `${home}/blocked-transactions`,
};
export { reverse } from 'named-urls';
