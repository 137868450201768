import { Button, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const FilterDropdownBox = ({ content, onReset, onSubmit }) => {
    const media = useMediaQueryContext();
    return (React.createElement(S.Container, null,
        React.createElement(S.Content, null,
            React.createElement(S.InnerContent, null, content)),
        React.createElement(S.Footer, null,
            React.createElement(Button, { label: "Reset", onClick: onReset, fullWidth: !media.tablet }),
            React.createElement(Button, { type: "submit", label: "Done", appearance: "primary", fullWidth: !media.tablet, onClick: onSubmit }))));
};
