import styled from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 20px;
  border: 1px solid ${Colors.GS400};
  position: relative;
`;
export const TextualContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const Title = styled.div `
  font-size: 16px;
  font-weight: 600;
  line-height: 150%; /* 24px */
`;
export const ButtonWrapper = styled.div `
  width: 100%;

  ${mediaQueries.tablet} {
    width: fit-content;
  }
`;
