import { SECTION_NAMES } from '@constants/sectionNames';
import { ButtonClean } from '@fourthwall/components';
import { routing } from '@utils/routing';
import React, { useEffect, useRef } from 'react';
import { AppsTwitchView } from './AppsTwitchView';
import { usePurchasedGifts } from './hooks/usePurchasedGifts';
import { useTwitch } from './hooks/useTwitch';
export const AppsTwitchContainer = () => {
    const formRef = useRef(null);
    const { status, twitch, streamelements, formProps, confirmBarProps, giftableProducts } = useTwitch();
    const { purchasedGifts } = usePurchasedGifts();
    useEffect(() => {
        formRef.current?.setFieldValue('includedProductsIds', giftableProducts.includedProducts.productsIds);
    }, [giftableProducts.includedProducts.productsIds]);
    useEffect(() => {
        formRef.current?.setFieldValue('excludedProductsIds', giftableProducts.excludedProducts.productsIds);
    }, [giftableProducts.excludedProducts.productsIds]);
    useEffect(() => {
        if (formRef.current?.values.platform === 'NOT_SELECTED' && twitch.status === 'CONNECTED') {
            formRef.current?.setFieldValue('platform', 'TWITCH');
        }
        if (formRef.current?.values.platform === 'NOT_SELECTED' &&
            streamelements.status === 'CONNECTED') {
            formRef.current?.setFieldValue('platform', 'OPEN_API');
        }
    }, [twitch.status, streamelements.status]);
    return (React.createElement(AppsTwitchView, { formikRef: formRef, status: status, title: SECTION_NAMES.appTwitchGifting, formProps: formProps, description: React.createElement(React.Fragment, null,
            "Like gifted subs, but for products and memberships. Viewers can purchase gifts for Chat when you\u2019re live. Winners are randomly selected by our giveaway bot and can easily claim their gift for free via your redemption link.",
            ' ',
            React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.twitchGifting, target: "_blank" })), twitchConnect: twitch.twitchConnect, streamelementsConnect: streamelements.streamelementsConnect, confirmBarProps: confirmBarProps, giftableProducts: giftableProducts, giveaways: !status ? purchasedGifts.giveaways : undefined }));
};
