import { Button, Fieldset, Input, LeavingGuard, ModalBox, Select } from '@fourthwall/components';
import { CHECKOUT_NON_SHIPPABLE_COUNTRIES, getCountriesOptions, STATE_OPTIONS, } from '@fourthwall/utils/lib/inputs';
import { isRequired } from '@fourthwall/utils/lib/validation';
import { Field, Form, Formik } from 'formik';
import React from 'react';
const countriesOptions = getCountriesOptions({
    exclude: CHECKOUT_NON_SHIPPABLE_COUNTRIES,
});
export const AddressForm = ({ isLoading, initialValues, onSubmit, onClose }) => {
    const isShippingToUS = (values) => values?.country === 'US';
    const getStateOptions = (values) => {
        return STATE_OPTIONS[values?.country];
    };
    const shouldDisplayStateDropdown = (values) => !!getStateOptions(values);
    const handleCountryChange = (setFieldValue, setFieldTouched) => {
        setFieldValue('state', '');
        setFieldTouched('state', false);
    };
    const renderState = (values) => {
        const isStateRequired = (value) => {
            if (getStateOptions(values)) {
                return isRequired('This field is required.')(value);
            }
            return undefined;
        };
        if (shouldDisplayStateDropdown(values)) {
            return (React.createElement(Field, { name: "state", component: Select, validate: isStateRequired, label: "State", options: getStateOptions(values), fluid: true }));
        }
        return (React.createElement(Field, { name: "state", component: Input, validate: isStateRequired, label: "State / Province", fluid: true }));
    };
    const renderCPFNumber = (values) => {
        if (values?.country !== 'BR') {
            return React.createElement(Fieldset.Item, null);
        }
        return (React.createElement(Fieldset.Item, null,
            React.createElement(Field, { name: "vatId", component: Input, validate: isRequired('This field is required.'), label: "CPF number", fluid: true })));
    };
    const renderNames = (values) => {
        // NOTE: handle old orders that are using both first and last name
        if (values?.firstName?.length && values?.lastName?.length) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: "firstName", component: Input, validate: isRequired('This field is required.'), label: "First Name", fluid: true })),
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: "lastName", component: Input, validate: isRequired('This field is required.'), label: "Last Name", fluid: true }))));
        }
        // NOTE: new orders use only firstName as fullName
        return (React.createElement(Fieldset.Item, null,
            React.createElement(Field, { name: "firstName", component: Input, validate: isRequired('This field is required.'), label: "Full Name", fluid: true })));
    };
    const renderForm = ({ dirty, values, setFieldValue, setFieldTouched }) => {
        return (React.createElement(Form, null,
            React.createElement(ModalBox, { title: "Edit shipping details", onClose: onClose, buttons: [
                    React.createElement(Button, { label: "Cancel", disabled: isLoading, onClick: onClose }),
                    React.createElement(Button, { type: "submit", label: "Update shipping details", appearance: "primary", loading: isLoading }),
                ] },
                React.createElement(LeavingGuard, { when: dirty }),
                React.createElement(Fieldset.Row, null, renderNames(values)),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { name: "address1", component: Input, validate: isRequired('This field is required.'), label: "Street Address", fluid: true }))),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { name: "address2", component: Input, label: "Street Address 2", fluid: true }))),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, { flex: 2 },
                        React.createElement(Field, { name: "country", component: Select, validate: isRequired('This field is required.'), label: "Country", options: countriesOptions, fluid: true, onValueChange: () => handleCountryChange(setFieldValue, setFieldTouched) })),
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { name: "zip", component: Input, label: "Zip Code", maskType: "zipCode", fluid: true }))),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { name: "city", component: Input, validate: isRequired('This field is required.'), label: "City", fluid: true })),
                    React.createElement(Fieldset.Item, null, renderState(values))),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { name: "phone", component: Input, validate: isShippingToUS(values) ? undefined : isRequired('This field is required.'), label: "Phone number", type: "tel", fluid: true })),
                    renderCPFNumber(values)))));
    };
    return (React.createElement(Formik, { initialValues: initialValues, isInitialValid: true, enableReinitialize: true, onSubmit: onSubmit }, renderForm));
};
