import isEqual from 'lodash-es/isEqual';
import React from 'react';
import { useFilterPromotionCodes } from './hooks/useFilterPromotionCodes';
import { PromotionCodesFilterView } from './PromotionCodesFilterView';
export const PromotionCodesFilterContainer = () => {
    const { filtersParams, setFiltersParams, resetFiltersParams, hasFilterParams } = useFilterPromotionCodes();
    const handleSubmit = (values) => {
        setFiltersParams({
            creationReason: values.showGiftRedemptions ? 'MANUAL,FROM_GIFT' : undefined,
        });
    };
    const getInitialValues = () => ({
        showGiftRedemptions: isEqual(filtersParams.creationReason, 'MANUAL,FROM_GIFT'),
    });
    return (React.createElement(PromotionCodesFilterView, { initialValues: getInitialValues(), filterActive: hasFilterParams, onSubmit: handleSubmit, onReset: resetFiltersParams }));
};
