import React from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { OrderApiClient } from '@fourthwall/services/lib/OrderApiClient';
import { FulfillmentsApiClient } from '@fourthwall/services/lib/FulfillmentsApiClient';
import { downloadBlob } from '@fourthwall/utils/lib/file';
import { Toasts } from '@fourthwall/components';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { FulfilledOrderIDsToast } from './FulfilledOrderIDsToast';
export const useOrdersFulfillment = () => {
    const { hasPermission } = useDashboardPermissions();
    const downloadMutation = useMutation({
        mutationFn: OrderApiClient.getFulfillmentsCSV,
    });
    const uploadCSVMutation = useMutation({
        mutationFn: FulfillmentsApiClient.uploadCsv,
        onSuccess: ({ data }) => {
            const { fulfilledOrderIds, errors } = data;
            // fulfilled order ids
            if (fulfilledOrderIds.length) {
                Toasts.notify(React.createElement(FulfilledOrderIDsToast, { ids: fulfilledOrderIds }), {
                    type: 'success',
                    autoClose: false,
                });
            }
            // upload errors
            if (errors.length) {
                for (const error of errors) {
                    Toasts.notify(error, { type: 'error' });
                }
            }
            if (fulfilledOrderIds.length === 0 && errors.length === 0) {
                Toasts.notify('No new carrier codes and tracking numbers were provided in this CSV.', {
                    type: 'info',
                });
            }
        },
        onError: (error) => {
            const message = axios.isAxiosError(error)
                ? error.response?.data?.detail || 'Upload failed'
                : 'Upload failed';
            Toasts.notify(message, { type: 'error' });
        },
    });
    const downloadCSV = async () => {
        const { data } = await downloadMutation.mutateAsync();
        downloadBlob(data, 'orders.csv');
    };
    const uploadCSV = async (variables) => {
        await uploadCSVMutation.mutateAsync(variables);
    };
    return {
        downloadCSV,
        isDownloading: downloadMutation.isLoading,
        uploadCSV: hasPermission('contributions.fulfillments.upload') ? uploadCSV : undefined,
        isUploading: uploadCSVMutation.isLoading,
    };
};
