// TODO: Don't display Colorway or Select component when having only one single option available.
import { Colorway, Loading, Select, SortableGallery, Upload, useMediaQueryContext, } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const OfferImagesView = ({ filters, gallery, isLoading, disabled = false, onColorFilterSortChange, onImageAdd, onImageAddError, }) => {
    const media = useMediaQueryContext();
    const renderFilter = (filter) => {
        if (filter.type === 'COLOR') {
            return (React.createElement(Colorway, { colors: filter.options.map((option) => ({ ...option, value: option.color })), activeColor: filter.value, onSortChange: filter.tag === 'COLOR' ? onColorFilterSortChange : undefined, onChange: (option) => filter.onChange(option.label) }));
        }
        return (React.createElement(Select, { label: filter.label, options: filter.options, value: filter.value, onValueChange: filter.onChange }));
    };
    return (React.createElement(S.Container, null,
        filters && filters.map(renderFilter),
        gallery && (React.createElement("div", null,
            React.createElement(Loading, { isLoading: isLoading },
                React.createElement(SortableGallery, { size: media.tablet ? 'small' : 'extraSmall', additionalItem: !disabled && (React.createElement(Upload, { title: media.tablet ? 'Drop or browse' : 'Browse', accept: {
                            'image/*': [],
                        }, onFileDrop: onImageAdd, onFileDropRejected: onImageAddError })), ...gallery }))))));
};
