import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { SECTION_NAMES } from '@constants/sectionNames';
import { Banner, ButtonClean, HeadContent, Loader, Skeleton, Subsection, Tag, } from '@fourthwall/components';
import { routing } from '@utils/routing';
import React from 'react';
import { ConnectBar } from '../../components/ConnectBar';
import { YouTubeVideoEntry } from '../../components/YouTubeVideoEntry';
import { mapStatusToTagProps } from '../../utils';
import { ConfigurationForm } from './components/ConfigurationForm';
import { ConfigurationList } from './components/ConfigurationList';
import { ErrorBanner } from './components/ErrorBanner';
import { PrerequisitesErrors } from './components/PrerequisitesErrors';
import { SettingsForm } from './components/SettingsForm';
import { useTiktokShop } from './hooks/useTiktokShop';
export const AppsTiktokShop = () => {
    const { tiktokShop } = useTiktokShop();
    const hasSynchronizationErrors = Object.keys(tiktokShop.synchronizationErrors).some((errorKey) => tiktokShop.synchronizationErrors[errorKey]);
    const renderStatusTag = () => {
        const props = mapStatusToTagProps(tiktokShop.status);
        if (!props)
            return null;
        return (React.createElement("div", { style: { marginBottom: 20 } },
            React.createElement(Tag, { ...props, size: "large", display: "inline-flex" })));
    };
    const renderSynchronizationErrors = () => {
        const helpDocLink = (React.createElement("a", { href: routing.external.helpCenter.tiktokShop, target: "_blank", rel: "noreferrer" }, "help doc"));
        return (React.createElement(React.Fragment, null,
            React.createElement(ErrorBanner, { enabled: tiktokShop.synchronizationErrors.sellerNotActive },
                "Your Tiktok seller account is inactive. Please follow Fourthwall ",
                helpDocLink,
                " for account set up."),
            React.createElement(ErrorBanner, { enabled: tiktokShop.synchronizationErrors.shopNotActive },
                "Your Tiktok shop is inactive. Please follow Fourthwall ",
                helpDocLink,
                " for shop set up."),
            React.createElement(ErrorBanner, { enabled: tiktokShop.synchronizationErrors.warehouseNotConfigured },
                "Your",
                ' ',
                React.createElement("a", { href: routing.external.tiktok.warehouse, target: "_blank", rel: "noreferrer" }, "warehouse configuration"),
                ' ',
                "is invalid. Please follow Fourthwall ",
                helpDocLink,
                " for warehouse set up."),
            React.createElement(ErrorBanner, { enabled: tiktokShop.synchronizationErrors.returnWarehouseNotConfigured },
                "Your",
                ' ',
                React.createElement("a", { href: routing.external.tiktok.warehouse, target: "_blank", rel: "noreferrer" }, "return warehouse configuration"),
                ' ',
                "is invalid. Please follow Fourthwall ",
                helpDocLink,
                " for warehouse set up."),
            React.createElement(ErrorBanner, { enabled: tiktokShop.synchronizationErrors.shippingTemplate },
                "Your",
                ' ',
                React.createElement("a", { href: routing.external.tiktok.shippingTemplate, target: "_blank", rel: "noreferrer" }, "shipping template configuration"),
                ' ',
                "is invalid. Please follow Fourthwall ",
                helpDocLink,
                " for shipping set up."),
            React.createElement(ErrorBanner, { enabled: tiktokShop.synchronizationErrors.incorrectShippingOption },
                "It looks like your shipping configuration is incorrect. To fix it:",
                React.createElement("ol", null,
                    React.createElement("li", null, "Log into your TikTok Seller account"),
                    React.createElement("li", null, "Go to: Orders \u2192 Shipping Settings \u2192 Shipping and Delivery"),
                    React.createElement("li", null, "Click \"Switch option\" to choose \"Seller shipping\""),
                    React.createElement("li", null, "Refresh this page and enable sales using the toggle below")))));
    };
    const renderNotConfiguredContent = () => {
        if (!tiktokShop.configurationStatus) {
            return (React.createElement("div", { style: { marginTop: 24, display: 'flex', justifyContent: 'center' } },
                React.createElement(Loader, { size: "large", appearance: "secondary" })));
        }
        if (tiktokShop.configurationStatus === 'SETUP_REQUIRED') {
            return (React.createElement(ConfigurationList, null,
                React.createElement(ConfigurationList.Item, { title: "Create and connect TikTok Shop" }),
                React.createElement(ConfigurationList.Item, { title: "Set up warehouse" }),
                React.createElement(ConfigurationList.Item, { title: "Create shipping template" })));
        }
        if (tiktokShop.configurationStatus === 'CONFIGURATION_REQUIRED') {
            return (React.createElement(React.Fragment, null,
                renderSynchronizationErrors(),
                React.createElement(ErrorBanner, { enabled: tiktokShop.configurationPrerequisitesErrors.length > 0 },
                    React.createElement(PrerequisitesErrors, { errors: tiktokShop.configurationPrerequisitesErrors })),
                React.createElement(ConfigurationForm, { initialValues: tiktokShop.configurationForm.initialValues, shopOptions: tiktokShop.configurationForm.shops, warehouseOptions: tiktokShop.configurationForm.warehouses, isLoading: tiktokShop.configurationForm.isLoading, onShopSelect: tiktokShop.configurationForm.onShopSelect, onSubmit: tiktokShop.configurationForm.onSubmit })));
        }
    };
    const renderConnectedContent = () => {
        return (React.createElement(SettingsForm, { errorBanners: React.createElement(React.Fragment, null,
                React.createElement(ErrorBanner, { enabled: tiktokShop.paymentError && !tiktokShop.hasActivePaymentMethod },
                    "TikTok Shop app requires active payment method to cover costs of orders (such as manufacturing or shipping we\u2019re handling).",
                    ' ',
                    React.createElement("button", { onClick: tiktokShop.onPaymentMethodUpdate }, "Set it up now"),
                    "."),
                React.createElement(ErrorBanner, { enabled: tiktokShop.paymentError && tiktokShop.hasActivePaymentMethod },
                    "We were unable to charge your payment method to cover costs of your TikTok orders. In order to enable TikTok Shop sales please",
                    ' ',
                    React.createElement("button", { onClick: tiktokShop.onPaymentMethodUpdate }, "update your payment method"),
                    "."),
                renderSynchronizationErrors(),
                React.createElement(ErrorBanner, { enabled: tiktokShop.configurationPrerequisitesErrors.length > 0 },
                    React.createElement(PrerequisitesErrors, { errors: tiktokShop.configurationPrerequisitesErrors })),
                React.createElement(ErrorBanner, { enabled: hasSynchronizationErrors && !tiktokShop.settingsForm.initialValues.salesEnabled, appearance: "warning" }, "Once you finalize the set up in TikTok Shop Seller Center please enable \"TikTok Shop sales\" below to synchronize your products once again.")), ...tiktokShop.settingsForm }));
    };
    const renderContent = () => {
        if (tiktokShop.isLoading) {
            return null;
        }
        if (tiktokShop.status === 'NOT_CONNECTED') {
            return (React.createElement(Subsection, { title: "Configure your TikTok Shop" }, "Connect your TikTok account first."));
        }
        if (tiktokShop.status === 'NOT_CONFIGURED') {
            return (React.createElement(Subsection, { title: "Configure your TikTok Shop" }, renderNotConfiguredContent()));
        }
        if (tiktokShop.status === 'CONNECTED') {
            return renderConnectedContent();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: SECTION_NAMES.appTikTokShop }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: SECTION_NAMES.appTikTokShop, topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }) },
                React.createElement(Subsection, null,
                    renderStatusTag(),
                    React.createElement("div", null,
                        "Showcase and sell products directly to your fans on TikTok.",
                        ' ',
                        React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.tiktokShop, target: "_blank" }))),
                React.createElement("div", { style: { marginTop: 20 } },
                    React.createElement(Banner, { appearance: "warning" },
                        "You get paid directly on TikTok. Fourthwall will charge your account for any costs related to the order.",
                        ' ',
                        React.createElement("a", { href: routing.external.helpCenter.tiktokShopPayment, target: "_blank", rel: "noreferrer" }, "Learn more"))),
                React.createElement("div", { style: { marginTop: 20, marginBottom: 40 } },
                    tiktokShop.status && (React.createElement("div", { style: { marginBlock: 20 } },
                        React.createElement(YouTubeVideoEntry, { title: "How to Set Up TikTok Shop", description: "Learn how to integrate TikTok Shop and let your followers easily discover and purchase your products directly through your TikTok content.", size: tiktokShop.status === 'NOT_CONNECTED' ? 'regular' : 'compact', videoId: "zWiBZ1z6aDI" }))),
                    tiktokShop.connectBar ? (React.createElement(ConnectBar, { ...tiktokShop.connectBar })) : (React.createElement(Skeleton, null,
                        React.createElement(Skeleton.Shape, { height: 64 })))),
                renderContent()))));
};
