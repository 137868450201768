import { Fieldset, Input, PriceString } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React from 'react';
import { FormFields } from '../../types';
import * as S from './styled';
export const YearDiscount = ({ annualDiscount }) => {
    const formik = useFormikContext();
    if (formik.values.price && parseFloat(formik.values.price) === 0)
        return null;
    if (!formik.values.discount || !annualDiscount)
        return null;
    const renderYearDiscountInfo = () => {
        if (!formik.values.price ||
            !formik.values.yearDiscount ||
            parseInt(formik.values.yearDiscount) > 100 ||
            parseInt(formik.values.yearDiscount) < 0 ||
            !annualDiscount)
            return null;
        const yearPrice = formik.values.price
            ? parseFloat(formik.values.price) * 12 -
                parseFloat(formik.values.price) * 12 * (annualDiscount / 100)
            : 0;
        return (React.createElement(S.DiscountInfo, null,
            React.createElement(S.DiscountInfoLabel, null, "First year"),
            React.createElement(PriceString, { value: yearPrice - yearPrice * (parseInt(formik.values.yearDiscount) / 100) })));
    };
    return (React.createElement(Fieldset.Row, { inner: true },
        React.createElement("div", null,
            React.createElement(S.DisountLabel, null, "Annual membership"),
            React.createElement(S.DiscountInputContainer, null,
                React.createElement(Field, { component: Input, name: FormFields.yearDiscount, label: "First year discount", type: "number", append: "%", hideSpinner: true }),
                renderYearDiscountInfo()))));
};
