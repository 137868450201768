import { useQueryPage } from '@fourthwall/utils';
import { StringParam, useQueryParams } from 'use-query-params';
export const useFilterContributions = () => {
    const { setPage } = useQueryPage();
    const [queryParams, setQueryParams] = useQueryParams({
        status: StringParam,
    });
    const resetFiltersParams = () => {
        setPage(undefined);
        setQueryParams({
            status: undefined,
        });
    };
    const setFiltersParams = (params) => {
        setPage(undefined);
        setQueryParams(params);
    };
    const hasFilterParams = Object.values(queryParams).some((param) => param !== undefined);
    return { filtersParams: queryParams, setFiltersParams, resetFiltersParams, hasFilterParams };
};
