import React from 'react';
import { PromotionStatusTag } from '@components/common/PromotionStatusTag';
import { PromotionCodeTableCell } from '../PromotionCodeTableCell';
import { PromotionUsageTableCell } from '../PromotionUsageTableCell';
export const TABLE_HEADERS = [
    {
        id: 'code',
        label: 'Code',
        render: (promotion) => React.createElement(PromotionCodeTableCell, { promotion: promotion }),
        width: 5,
    },
    {
        id: 'status',
        label: 'Status',
        render: (promotion) => React.createElement(PromotionStatusTag, { promotion: promotion }),
        width: 3,
    },
    {
        id: 'used',
        label: 'Used',
        render: (promotion) => React.createElement(PromotionUsageTableCell, { promotion: promotion }),
    },
];
