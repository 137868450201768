import React from 'react';
import { Icon, Tooltip, useMediaQueryContext } from '@fourthwall/components';
import { DuplicateIcon, TickIcon } from '@fourthwall/icons';
import { useCopyToClipboard } from '@components/hooks/useCopyToClipboard';
import * as S from './styled';
export const CopyLink = ({ appearance = 'primary', label, href }) => {
    const media = useMediaQueryContext();
    const { isCopied, copyToClipboard } = useCopyToClipboard({
        toastMessage: !media.laptop ? 'Link copied to clipboard' : undefined,
    });
    const renderIconButton = (iconComponent) => (React.createElement(S.IconButton, { appearance: appearance, type: "button", onClick: () => copyToClipboard(href) },
        React.createElement(Icon, { component: iconComponent, height: 12 })));
    const renderCopyButton = () => {
        const iconButton = renderIconButton(isCopied ? TickIcon : DuplicateIcon);
        if (media.laptop) {
            return (React.createElement(Tooltip, { reference: React.createElement(S.Reference, null, iconButton) }, isCopied ? 'Copied!' : 'Copy link'));
        }
        return iconButton;
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.Link, { appearance: appearance, href: href, target: "_blank" }, label),
        renderCopyButton()));
};
