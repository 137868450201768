import React from 'react';
import { DateString, PriceWithCurrency, Tag } from '@fourthwall/components';
export const tagsAppearances = {
    PAID: 'success',
    PROCESSING: 'caution',
    FAILED: 'alert',
};
export const headers = [
    {
        id: 'date',
        label: 'Date',
        render: ({ date }) => React.createElement(DateString, { date: date, format: "MMM DD, YYYY" }),
        width: 1,
        minHeight: 48,
    },
    {
        id: 'amount',
        label: 'Amount',
        render: ({ transferAmount, payoutAmount }) => (React.createElement("div", null,
            React.createElement(PriceWithCurrency, { ...transferAmount }),
            payoutAmount && payoutAmount.currency !== transferAmount.currency ? (React.createElement("div", null,
                "(",
                React.createElement(PriceWithCurrency, { ...payoutAmount }),
                ")")) : null)),
        width: 1.2,
        minHeight: 48,
    },
    {
        id: 'paidTo',
        label: 'Paid to',
        render: ({ paidTo }) => paidTo,
        width: 1,
        minHeight: 48,
    },
    {
        id: 'status',
        label: 'Status',
        render: ({ status }) => React.createElement(Tag, { label: status, appearance: tagsAppearances[status] }),
        width: 1,
        minHeight: 48,
    },
];
