import { Colors, mediaQueries } from '@fourthwall/components';
import { AppDownloadAppStoreIcon, AppDownloadGooglePlayIcon } from '@fourthwall/icons';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  width: 100%;
  margin: 48px auto;

  display: flex;
  flex-flow: row wrap-reverse;
  align-items: center;
  justify-content: center;
`;
export const CellphoneWrapper = styled.div `
  width: 384px;
  display: none;

  ${mediaQueries.laptop} {
    display: block;
  }
`;
export const InfoWrapper = styled.div `
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 32px;

  ${mediaQueries.laptop} {
    align-items: flex-start;
    margin-left: 67px;
  }
`;
export const DownloadAppIcons = styled.div `
  display: flex;
  margin-top: 20px;
  align-items: center;
`;
export const DownloadAppQrcodeIcon = styled.img `
  margin-right: 20px;
`;
export const DownloadLink = styled.a `
  margin-right: 16px;

  &:last-of-type {
    margin-right: 0;
  }
`;
const iconsStyles = css `
  height: 48px;
`;
export const AppDownloadAppStore = styled(AppDownloadAppStoreIcon) `
  ${iconsStyles};
  width: 143.5px;
`;
export const AppDownloadGooglePlay = styled(AppDownloadGooglePlayIcon) `
  ${iconsStyles};
  width: 163px;
`;
export const Message = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;

  h2 {
    text-align: center;
  }

  ${mediaQueries.laptop} {
    h2 {
      text-align: left;
    }
  }
`;
export const Subtitle = styled.div `
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.GS600};
  text-align: center;

  ${mediaQueries.laptop} {
    text-align: left;
  }
`;
