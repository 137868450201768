import { VolumeOffIcon, VolumeOnIcon } from '@fourthwall/icons';
import React, { useState } from 'react';
import AvatarImage from './assets/avatar@2x.jpg';
import PhilVideoMp4 from './assets/phil.mp4';
import PhoneImage from './assets/phone@2x.png';
import VideoThumbnailImage from './assets/video-thumbnail@2x.jpg';
import * as S from './Cellphone.styled';
import { Twitter } from './Twitter';
import { VideoShare } from './VideoShare';
export const Cellphone = ({ withSound = true, phoneWidth }) => {
    const [muted, setMuted] = useState(true);
    const toggleMuted = () => setMuted((prevMuted) => !prevMuted);
    return (React.createElement(S.Container, null,
        React.createElement(S.CellphoneWrapper, { phoneWidth: phoneWidth },
            React.createElement(S.Image, { src: PhoneImage }),
            React.createElement(S.VideoContainer, null,
                React.createElement(S.Video, null,
                    React.createElement("video", { autoPlay: true, loop: true, playsInline: true, muted: muted },
                        React.createElement("source", { src: PhilVideoMp4, type: "video/mp4" })))),
            withSound && (React.createElement(S.MuteWrapper, { onClick: toggleMuted }, muted ? React.createElement(VolumeOffIcon, null) : React.createElement(VolumeOnIcon, null)))),
        React.createElement(S.TwitterWidget, null,
            React.createElement(Twitter, { avatar: React.createElement(S.Image, { src: AvatarImage }), name: "Christina T.", nick: "@mildMeihem", url: "https://beautifulbastard.com/thank-you/ty_IlraEJQiRm6xE5Fxd7swDA" },
                React.createElement(S.TwitterMessage, null, "I could listen to @phillyd say my name all fucking day! Literally cried when I saw this, thank you!"),
                React.createElement(S.TwitterVideo, null,
                    React.createElement(VideoShare, { thumbnail: React.createElement(S.Image, { src: VideoThumbnailImage }), message: "@philyd sent you a message", url: "beautifulbastard.com" }))))));
};
