import { useMemo } from 'react';
import { useInstalledThemesQuery } from '../modules/InstalledThemes';
import { useCurrentShopContext } from './useCurrentShopContext';
export const useCurrentTheme = () => {
    const { currentShop } = useCurrentShopContext();
    const { installedThemesQuery } = useInstalledThemesQuery([{ shop_id: currentShop.id }]);
    const currentTheme = useMemo(() => {
        if (!installedThemesQuery.data)
            return;
        return installedThemesQuery.data.themes.find(({ role }) => role === 'main');
    }, [installedThemesQuery.data]);
    return {
        currentTheme,
    };
};
