import styled, { css } from 'styled-components';
import { Colors } from '../../../colors';
const heights = {
    small: 32,
    medium: 40,
    large: 64,
};
const paddings = {
    small: 4,
    medium: 8,
    large: 16,
};
export const Option = styled.div `
  display: flex;
  align-items: center;
  min-width: 120px;
  min-height: ${({ size }) => `${heights[size]}px`};
  padding: ${({ size }) => `${paddings[size]}px 16px`};
  cursor: ${({ cursor }) => cursor || 'default'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;

  &:hover {
    background: ${Colors.GS200};
  }

  ${({ appearance }) => appearance === 'destructive' &&
    css `
      /* temporarily disabled */
      /* color: ${Colors.R300}; */
    `};

  ${({ disabled }) => disabled &&
    css `
      color: ${Colors.GS600};
      cursor: default;

      &:hover {
        background: ${Colors.GS200};
      }
    `};

  ${({ active }) => active &&
    css `
      background: ${Colors.GS200};
    `};
`;
