import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
export const Note = styled.div `
  position: absolute;
  left: -11px;
  top: -31px;

  padding: 5px;

  background: ${Colors.R300};
  text-transform: uppercase;
  color: ${Colors.GS0};
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
  line-height: 1;
  pointer-events: none;
  opacity: 0;
`;
export const Container = styled.div `
  position: relative;

  outline: 1px dashed transparent;
  outline-offset: 10px;
  cursor: help;

  ${({ inline }) => inline &&
    css `
      display: inline;
    `};

  &:hover {
    outline-color: ${Colors.R300};

    ${Note} {
      opacity: 1;
    }
  }
`;
