import without from 'lodash-es/without';
import { useState } from 'react';
export const useAccordionState = (initialState, options) => {
    const [activeState, setActiveState] = useState(initialState);
    const toggle = (index) => {
        if (activeState.length - 1 >= index) {
            setActiveState((state) => {
                return state.map((element, elementIndex) => {
                    if (index === elementIndex) {
                        return !options?.allowZeroActive && element && without(state, false).length === 1
                            ? element
                            : !element;
                    }
                    return options?.allowMultipleActive ? element : false;
                });
            });
        }
    };
    return {
        activeState,
        setActiveState,
        toggle,
    };
};
