import React from 'react';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { OfferStatuses } from '@modules/Dashboard/Products/modules/OfferStatuses';
import { StockCellContent } from '@components/common/StockCellContent';
import { Colors, Dropdown, ToolbarButton } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import { getDefaultProductImage } from '@utils/products';
import * as S from './ProductListItem.styled';
export const ProductListItem = ({ product, dropdownItems }) => {
    const imageUrl = getDefaultProductImage(product);
    return (React.createElement(S.Container, null,
        React.createElement(S.ProductItemDetails, null,
            React.createElement(PhotoThumbnail, { url: imageUrl, size: "extraLarge" }),
            React.createElement(S.ProductDetails, null,
                React.createElement(S.ProductName, null, product.name),
                React.createElement(S.ProductStatus, null,
                    React.createElement(OfferStatuses, { offer: product })),
                React.createElement(S.ProductInventory, null,
                    React.createElement(StockCellContent, { showFullLabel: true, variants: product.variants }),
                    product.state && !product.state?.available && React.createElement("div", null, "Marked as sold out")))),
        React.createElement(S.ProductItemActions, null,
            React.createElement(Dropdown, { content: React.createElement(React.Fragment, null, dropdownItems
                    .filter(({ enabled = true }) => enabled)
                    .map((dropdownItem) => (React.createElement(Dropdown.Item, { label: dropdownItem.label, appearance: dropdownItem.appearance, onClick: (e) => {
                        e.preventDefault();
                        dropdownItem.onClick?.(product);
                    } })))) },
                React.createElement(ToolbarButton, { icon: DotsHorizontalIcon, color: Colors.GS600 })))));
};
