import React, { forwardRef } from 'react';
import { CloseIcon, DotsHorizontalIcon } from '@fourthwall/icons';
import { Loader } from '../../common/Loader';
import { Icon } from '../../common/Icon';
import { Dropdown } from '../../common/Dropdown';
import * as S from './styled';
import { TableBodyProvider, useTableBody } from './contexts/TableBodyContext';
const TableUI = forwardRef((props, ref) => {
    return (React.createElement(S.Root, { ref: ref, role: "table", ...props }, props.children));
});
const TableHead = ({ children }) => {
    return React.createElement(S.TableHead, null, children);
};
const TableHeadTr = ({ children }) => {
    return React.createElement(S.TableHeadTr, null, children);
};
const TableHeaderSelectionWrapper = ({ children, visible, }) => {
    return React.createElement(S.TableHeaderSelectionWrapper, { visible: visible }, children);
};
const TableHeadTh = ({ children, colSpan, textAlign = 'center', width, position, verticalSpacing, horizontalSpacing, }) => {
    return (React.createElement(S.TableHeadTh, { colSpan: colSpan, "$textAlign": textAlign, "$width": width, "$position": position, verticalSpacing: verticalSpacing, horizontalSpacing: horizontalSpacing }, children));
};
const TableBody = ({ children, isLoading }) => {
    return (React.createElement(TableBodyProvider, null,
        React.createElement(S.TableBody, { isLoading: isLoading }, children)));
};
const TableBodyTr = forwardRef(({ children, isDragged, isSelected, ...props }, ref) => {
    const { setDropdownKey } = useTableBody();
    return (React.createElement(S.TableBodyTr, { ref: ref, ...props, isDragged: isDragged, isSelected: isSelected, onMouseLeave: () => {
            setDropdownKey((key) => key + 1);
        } }, children));
});
const TableBodyTd = forwardRef(({ children, colSpan, alignContent, textAlign, width, height, borderTop, position, verticalSpacing, horizontalSpacing, verticalAlign, onClick, ...props }, ref) => {
    return (React.createElement(S.TableBodyTd, { ...props, ref: ref, colSpan: colSpan, "$alignContent": alignContent, "$textAlign": textAlign, "$width": width, "$height": height, "$borderTop": borderTop, "$position": position, "$verticalAlign": verticalAlign, verticalSpacing: verticalSpacing, horizontalSpacing: horizontalSpacing, onClick: onClick }, children));
});
const TableCellItemPositioner = ({ children, ...rest }) => {
    return React.createElement(S.TableCellItemPositioner, { ...rest }, children);
};
const TableLoader = () => {
    return (React.createElement(S.TableLoaderWrapper, null,
        React.createElement(Loader, { size: "large" })));
};
export const TableHeaderActions = ({ children }) => {
    return React.createElement(S.TableHeaderActions, null, children);
};
export const TableHeaderActionsItem = ({ children, onClick, }) => {
    return React.createElement(S.TableHeaderActionsItem, { onClick: onClick }, children);
};
export const TableRowActions = ({ children }) => {
    const { dropdownKey } = useTableBody();
    return (React.createElement(S.TableRowActionsWrapper, null,
        React.createElement(Dropdown, { key: dropdownKey, content: children, position: "bottom-end", optionsVerticalOffset: 0, optionsHorizontalOffset: 0 },
            React.createElement(S.TableRowActionsContainer, { type: "button" },
                React.createElement("div", null,
                    React.createElement(Icon, { component: DotsHorizontalIcon, height: 16 }))))));
};
export const TableRowActionsItem = ({ label, appearance, onClick, }) => {
    return React.createElement(Dropdown.Item, { label: label, appearance: appearance, onClick: onClick });
};
export const TableRowXActionIcon = ({ onClick }) => {
    return (React.createElement(S.TableRowActionsWrapper, null,
        React.createElement(S.TableRowActionsContainer, { type: "button", onClick: onClick },
            React.createElement(Icon, { component: CloseIcon, height: 16 }))));
};
export const TableRowSelection = ({ children, visible, }) => {
    return React.createElement(S.TableRowSelectionWrapper, { visible: visible }, children);
};
const TableHeaderHeading = S.TableHeaderActionsHeading;
const TableHeaderRoot = S.TableHeaderRoot;
const TableHeaderActionsHeading = S.TableHeaderActionsHeading;
const TableHeaderActionsItems = S.TableHeaderActionsItems;
TableUI.displayName = 'TableUI';
export { TableUI as Root, TableHead as Head, TableHeadTr as HeadTr, TableHeadTh as HeadTh, TableBody as Body, TableBodyTd as BodyTd, TableBodyTr as BodyTr, TableCellItemPositioner as CellItemPositioner, TableLoader as Loader, TableHeaderHeading as HeaderHeading, TableHeaderRoot as HeaderRoot, TableHeaderSelectionWrapper as HeaderSelectionWrapper, TableHeaderActions as HeaderActions, TableHeaderActionsItems as HeaderActionsItems, TableHeaderActionsItem as HeaderActionsItem, TableHeaderActionsHeading as HeaderActionsHeading, TableRowActions as RowActions, TableRowActionsItem as RowActionsItem, TableRowXActionIcon as RowXActionIcon, TableRowSelection as RowSelection, };
