import { usePagination, DateString } from '@fourthwall/components';
import React from 'react';
import { TableCell } from '@components/common/TableCell';
import { TableProductsList } from '@components/common/TableProductsList';
import { reverse, routing } from '@utils/routing';
import { useGiveawayContributionsQuery } from '@modules/Dashboard/Contributions/modules/Giveaways';
export const usePurchasedGifts = () => {
    const [currentPage, setCurrentPage] = usePagination();
    const { giveawayContributionsQuery } = useGiveawayContributionsQuery([{ page: currentPage - 1, size: 4 }], { keepPreviousData: true });
    const data = giveawayContributionsQuery.data?.result || [];
    const headers = [
        {
            id: 'date',
            label: 'Date',
            render: ({ createdAt }) => React.createElement(DateString, { date: createdAt, format: "MM/DD/YYYY" }),
        },
        {
            id: 'product',
            label: 'Product',
            render: ({ productImageUrl }) => (React.createElement(TableProductsList, { image: productImageUrl, offers: [], offersCancelled: [], quantity: 1 })),
        },
        {
            id: 'purchasedBy',
            label: 'Purchased by',
            render: ({ supporter }) => (React.createElement(TableCell, { mainContent: supporter.username || supporter.email, ellipsis: true })),
        },
        {
            id: 'claimed',
            label: 'Claimed',
            render: ({ claimedGifts, numberOfGifts }) => `${claimedGifts}/${numberOfGifts}`,
        },
    ];
    const table = {
        data,
        headers,
        // @ts-ignore
        getRowLink: ({ giveawayId }) => {
            return reverse(routing.contributions.orders.giveaways.giveaway, { giveawayId });
        },
    };
    const getPagination = (length) => {
        if (!length)
            return;
        return {
            pageCount: giveawayContributionsQuery.data?.page.totalPages || 1,
            currentPage,
            onChange: setCurrentPage,
        };
    };
    const getGiveaways = () => {
        if (giveawayContributionsQuery.isLoading)
            return undefined;
        return {
            table,
            pagination: getPagination(table.data.length),
        };
    };
    return {
        purchasedGifts: {
            giveaways: getGiveaways(),
        },
    };
};
