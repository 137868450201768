import styled, { css } from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const Container = styled.button `
  width: 100%;
  padding: 16px;
  background: ${Colors.GS200};

  display: grid;
  gap: 16px;

  text-align: initial;

  ${mediaQueries.tablet} {
    grid-template-columns: auto 1fr;
    gap: 24px;

    align-items: center;
  }

  ${({ size }) => size === 'compact' &&
    css `
      padding: 8px;
      gap: 20px;

      ${Thumbnail} {
        svg {
          height: 20px;
        }
      }

      ${ThumbnailImage} {
        height: 40px;
      }

      ${Description} {
        display: none;
      }

      ${mediaQueries.tablet} {
        gap: 20px;
      }
    `};
`;
export const Thumbnail = styled.div `
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  }
`;
export const ThumbnailImage = styled.img `
  display: block;
  width: 100%;

  aspect-ratio: 560 / 315;
  object-fit: cover;

  ${mediaQueries.tablet} {
    max-height: 120px;
  }
`;
export const Description = styled.div `
  margin-top: 4px;
`;
