import { ConfirmModal, useModalContext } from '@fourthwall/components';
import { arrayMove } from '@fourthwall/utils/lib/array';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useCurrentShopContext } from '../../hooks/useCurrentShopContext';
import { useThemeEditorContext } from '../../hooks/useThemeEditorContext';
import { getSocialIcon, getSocialLabel } from '../../utils/socials';
import { SocialLinksView } from './SocialLinksView';
import { useDeleteSocialLinkMutation } from './hooks/useDeleteSocialLinkMutation';
import { useSearchSocialLinksQuery } from './hooks/useSearchSocialLinksQuery';
import { useSocialLinksQuery } from './hooks/useSocialLinksQuery';
import { useUpdateSocialLinkMutation } from './hooks/useUpdateSocialLinkMutation';
import { getInitialValues, getNewSocialLinkData, getSocialName, getValidationSchema, isSearchOption, mapSearchOptions, mapSocialNetworks, } from './utils';
export const SocialLinksContainer = () => {
    const { currentShop } = useCurrentShopContext();
    const navigate = useNavigate();
    const { setIsPreviewLoading, resetPreviewTimestamp } = useThemeEditorContext();
    const [searchParams, setSearchParams] = useState();
    const [searchParamsDebounced] = useDebounce(searchParams, 500);
    const { socialLinksQuery } = useSocialLinksQuery([currentShop.id]);
    const { updateSocialLinkMutation } = useUpdateSocialLinkMutation();
    const { deleteSocialLinkMutation } = useDeleteSocialLinkMutation();
    const { searchSocialLinksQuery } = useSearchSocialLinksQuery([currentShop.id, searchParamsDebounced?.[0] || '', searchParamsDebounced?.[1] || ''], {
        enabled: !!searchParams && !!searchParamsDebounced?.[1].length,
    });
    const { open } = useModalContext();
    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks(mapSocialNetworks(socialLinksQuery.data?.socialNetworks || [], socialLinksQuery.data?.supportedSocialNetworks || []));
    }, [socialLinksQuery.data?.socialNetworks, socialLinksQuery.data?.supportedSocialNetworks]);
    const initialValues = getInitialValues(socialLinksQuery.data?.socialNetworks || []);
    const validationSchema = getValidationSchema(links);
    const availableLinkItems = socialLinksQuery.data?.supportedSocialNetworks
        .filter((availableLinkItem) => !links.some((link) => link.name === availableLinkItem.name))
        .map((availableLinkItem) => ({
        id: availableLinkItem.name,
        label: getSocialLabel(availableLinkItem.name),
        icon: getSocialIcon(getSocialName(availableLinkItem.name)),
    }));
    const handleSocialLinkCreate = (socialName) => {
        setLinks([
            ...links,
            getNewSocialLinkData(socialName, socialLinksQuery.data?.supportedSocialNetworks || []),
        ]);
    };
    const handleSocialLinkRemove = (socialName) => {
        open(ConfirmModal, {
            title: 'Are you sure you want to delete this Social link?',
            confirmLabel: 'Delete',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                const link = links.find((link) => link.name === socialName);
                setLinks((links) => links.filter((link) => link.name !== socialName));
                if (link?.dragDisabled) {
                    return;
                }
                setIsPreviewLoading(true);
                deleteSocialLinkMutation.mutate([currentShop.id, socialName], {
                    onSuccess: () => {
                        socialLinksQuery.refetch();
                        resetPreviewTimestamp();
                    },
                });
            },
        });
    };
    const handleSocialLinkEdit = (linkUrl) => {
        navigate(linkUrl);
    };
    const handleSocialLinkSearch = (socialName, search) => {
        setSearchParams([socialName, search]);
    };
    const handleValueChange = (socialName, value) => {
        if (!value) {
            return;
        }
        const handle = isSearchOption(value) ? value.id : value.toString();
        const publicUrl = isSearchOption(value) ? value.publicUrl : undefined;
        const profileData = isSearchOption(value)
            ? {
                name: value.name,
                image: value.image,
                description: value.description || '',
            }
            : {};
        setIsPreviewLoading(true);
        updateSocialLinkMutation.mutate([currentShop.id, socialName, { socialNetwork: { handle, publicUrl, profileData } }], {
            onSuccess: () => {
                socialLinksQuery.refetch();
                resetPreviewTimestamp();
            },
        });
    };
    const handleSortChange = (sourceIndex, destinationIndex) => {
        const sortedElement = links[sourceIndex];
        setLinks((links) => arrayMove(links, sourceIndex, destinationIndex));
        setIsPreviewLoading(true);
        updateSocialLinkMutation.mutate([
            currentShop.id,
            sortedElement.name,
            { socialNetwork: { handle: sortedElement.handle, position: destinationIndex + 1 } },
        ], {
            onSuccess: () => {
                socialLinksQuery.refetch();
                resetPreviewTimestamp();
            },
        });
    };
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: () => { } },
        React.createElement(SocialLinksView, { links: links, isLoadingLinks: socialLinksQuery.isLoading, availableLinkItems: availableLinkItems, search: {
                socialName: searchParamsDebounced?.[0],
                options: mapSearchOptions(searchSocialLinksQuery.data?.results),
                isLoading: searchSocialLinksQuery.isFetching,
            }, onSocialLinkCreate: handleSocialLinkCreate, onSocialLinkEdit: handleSocialLinkEdit, onSocialLinkRemove: handleSocialLinkRemove, onSocialLinkSearch: handleSocialLinkSearch, onValueChange: handleValueChange, onSortChange: handleSortChange })));
};
