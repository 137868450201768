import { Banner, Loader, useModalContext } from '@fourthwall/components';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useRef } from 'react';
import { SelectModal } from '@components/modals/SelectModal';
import * as S from './styled';
import { getItemInitialValues, getSelectModalMessage, isOfferItemType } from './utils';
import { SelectItemContainer } from './components/SelectItem';
import { useLoadMoreWhenNotScrollable } from './hooks/useLoadMoreWhenNotScrollable';
export const SelectItemsModalView = ({ inputProps, banner, selectItems, submitDisabled, onSubmit, }) => {
    const { close } = useModalContext();
    const scrollableContentRef = useRef(null);
    useLoadMoreWhenNotScrollable({
        itemsLength: selectItems.items.length,
        isLoading: selectItems.isLoading,
        hasNextPage: selectItems.hasNextPage,
        scrollableContentRef,
        loadMore: selectItems.onLoadMore,
    });
    const renderBanner = () => {
        if (!banner) {
            return null;
        }
        return (React.createElement(S.BannerWrapper, null,
            React.createElement(Banner, { ...banner })));
    };
    const renderModalContent = () => {
        if (selectItems.isLoading) {
            return (React.createElement(S.IdleContainer, null,
                React.createElement(Loader, { size: "large" })));
        }
        if (selectItems.emptyMessage) {
            return React.createElement(S.IdleContainer, null, selectItems.emptyMessage);
        }
        return (React.createElement(InfiniteScroll, { hasMore: selectItems.hasNextPage, loader: React.createElement(S.LoaderContainer, null,
                React.createElement(Loader, null)), dataLength: selectItems.offersCount, scrollableTarget: "selectModalContent", next: selectItems.onLoadMore },
            React.createElement(S.SelectItemsList, null, selectItems.items.map((item) => (React.createElement(SelectItemContainer, { key: isOfferItemType(item) ? item.id : item.variantId, item: item, initialValues: getItemInitialValues(item, selectItems.selectedItems), onChange: selectItems.onItemChange }))))));
    };
    return (React.createElement(SelectModal, { title: "Select items for samples order", content: React.createElement(S.ContentContainer, null,
            renderBanner(),
            renderModalContent()), contentRef: scrollableContentRef, message: selectItems.hasErrors ? undefined : getSelectModalMessage(selectItems.selectedItems), submitButtonLabel: "Select items", submitDisabled: submitDisabled, inputProps: inputProps, close: close, onSubmitClick: onSubmit }));
};
