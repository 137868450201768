export const getUpdateAccountSettingsUpsellingsPayload = (formValues) => {
    const { enabled, tier, value } = formValues;
    if (enabled) {
        return {
            upselling: {
                tierId: Number(tier),
                minimumOrderValue: value * 100,
            },
        };
    }
    return {};
};
