import styled, { css } from 'styled-components';
export const ProductsHeader = styled.div `
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
`;
export const CountWrapper = styled.div `
  display: flex;
  align-items: center;
`;
export const Count = styled.p `
  line-height: 1.5;
`;
export const SelectedProductsContainer = styled.div `
  ${({ theme, isEditable }) => theme.isWebDesktop &&
    css `
      margin-top: 8px ${isEditable ? '-46px' : '-30px'} 0;
    `}
  >
`;
