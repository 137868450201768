import styled from 'styled-components';
export const Header = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Name = styled.div `
  font-size: 20px;
  font-weight: 600;
`;
export const AvatarContainer = styled.div `
  padding-left: 16px;
  flex-shrink: 0;
`;
