import React from 'react';
import { Button } from '../../common/Button/Button';
import { ModalBox } from '../../partials/ModalBox';
export const AlertModal = ({ title, text, confirmLabel = 'Close', topContent, size, maxWidth, loading = false, onConfirm, close, }) => {
    const handleConfirm = () => {
        // NOTE: to allow opening different modals in onConfirm we have to call close before it
        close();
        if (onConfirm) {
            onConfirm();
        }
    };
    return (React.createElement(ModalBox, { title: title, topContent: topContent, buttons: confirmLabel
            ? [React.createElement(Button, { label: confirmLabel, appearance: "primary", onClick: handleConfirm })]
            : undefined, size: size, maxWidth: maxWidth, loading: loading, onClose: close }, text));
};
