import { Toasts } from '@fourthwall/components';
import React from 'react';
import { useDashboardFeatureFlags } from '../../../../../components/utils/DashboardFeatureFlags';
import { EmailNotificationsView } from './EmailNotificationsView';
import { ACCOUNT_NOTIFICATIONS_FIELDS, MARKETING_EMAILS_FIELDS } from './consts';
import { useNotificationPreferencesMutation } from './hooks/useNotificationPreferencesMutation';
import { useNotificationPreferencesQuery } from './hooks/useNotificationPreferencesQuery';
export const EmailNotificationsContainer = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { notificationPreferencesQuery, setNotificationPreferencesData } = useNotificationPreferencesQuery();
    const { notificationPreferencesMutation } = useNotificationPreferencesMutation();
    const getInitialValues = () => {
        if (!notificationPreferencesQuery.isSuccess) {
            return undefined;
        }
        return { ...notificationPreferencesQuery.data.mailPreferences };
    };
    const handleSubmit = async (values) => {
        const mailPreferences = { ...values };
        if (!mailPreferences.newDirectMessage) {
            mailPreferences.newDirectMessageWithTip = false;
        }
        if (!mailPreferences.newPostComments) {
            mailPreferences.newPostCommentsReply = false;
        }
        const data = await notificationPreferencesMutation.mutateAsync([{ mailPreferences }]);
        setNotificationPreferencesData(data);
        Toasts.notify('Email notifications settings have been updated', { type: 'success' });
    };
    const getMembershipsSections = () => {
        if (!dashboardFeatureFlags.memberships.enabled)
            return null;
        const fields = [
            { name: 'newMembers' },
            { name: 'newPostComments', innerField: { name: 'newPostCommentsReply' } },
            {
                name: 'newDirectMessage',
                innerField: { name: 'newDirectMessageWithTip' },
                enabled: dashboardFeatureFlags.membershipsMessages.enabled,
            },
            {
                name: 'messageUnlocks',
                enabled: dashboardFeatureFlags.membershipsMessages.enabled &&
                    dashboardFeatureFlags.membershipsMessagesPayPerView.enabled,
            },
        ];
        return { title: 'Memberships', fields: fields.filter(({ enabled }) => enabled !== false) };
    };
    const getSections = () => {
        const sections = [
            {
                title: 'Marketing emails',
                fields: MARKETING_EMAILS_FIELDS,
            },
            {
                title: 'Account notifications',
                subtitle: 'You may still receive occasional personalized emails from our team based on your account status.',
                fields: ACCOUNT_NOTIFICATIONS_FIELDS,
            },
        ];
        const membershipsSection = getMembershipsSections();
        if (membershipsSection) {
            sections.push(membershipsSection);
        }
        return sections;
    };
    return (React.createElement(EmailNotificationsView, { initialValues: getInitialValues(), sections: getSections(), onSubmit: handleSubmit }));
};
