import { Icon, PriceString, Tooltip } from '@fourthwall/components';
import { QuestionMarkIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './BreakdownSection.styled';
const infoIcon = React.createElement(Icon, { component: QuestionMarkIcon, height: 18 });
export const BreakdownSection = ({ title, cost, overriddenCost, isCostFluid, tooltipContent, costTooltipContent, entries, }) => {
    const renderTootltip = () => {
        if (!tooltipContent) {
            return null;
        }
        return (React.createElement(S.TooltipWrapper, null,
            React.createElement(Tooltip, { reference: infoIcon, placement: "top", fluid: true }, tooltipContent)));
    };
    const renderEntries = () => {
        if (!entries?.length) {
            return;
        }
        return (React.createElement(S.ItemsContainer, null, entries.map((entry) => (React.createElement(S.Item, { key: entry.title },
            React.createElement(S.ItemContent, null,
                React.createElement(S.Title, null,
                    React.createElement(S.TitleText, null, entry.title)),
                React.createElement(S.Quantity, null,
                    entry.quantity,
                    "x"),
                React.createElement(S.Cost, null,
                    entry.overriddenCost?.value && (React.createElement(S.OverriddenCost, null,
                        React.createElement(PriceString, { value: entry.overriddenCost.value, negative: true }))),
                    React.createElement(PriceString, { value: entry.cost.value, negative: true }))),
            entry.subtitle && React.createElement(S.Subtitle, null, entry.subtitle))))));
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.Content, null,
            React.createElement(S.Title, null,
                React.createElement(S.TitleText, null, title),
                " ",
                renderTootltip()),
            cost && (React.createElement(S.Cost, { fluid: isCostFluid },
                overriddenCost && React.createElement(S.OverriddenCost, null, overriddenCost),
                cost,
                costTooltipContent && (React.createElement(S.TooltipWrapper, null,
                    React.createElement(Tooltip, { reference: infoIcon, placement: "left", fluid: true }, costTooltipContent)))))),
        renderEntries()));
};
