import { Icon } from '@fourthwall/components';
import { PlaceholderPhotoIcon } from '@fourthwall/icons';
import React from 'react';
import { QuantityBadge } from '../../../../../../../components/common/QuantityBadge';
import * as S from './SeriesCell.styled';
export const SeriesCell = ({ title, quantity, thumbnailUrl }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.ThumbnailContainer, null,
            quantity > 0 && React.createElement(QuantityBadge, { quantity: quantity }),
            thumbnailUrl ? (React.createElement(S.Thumbnail, { src: thumbnailUrl })) : (React.createElement(Icon, { component: PlaceholderPhotoIcon, height: 32 }))),
        React.createElement(S.Description, null, title)));
};
