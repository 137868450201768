export const createListData = (values, offers) => {
    if (!offers)
        return [];
    return values.items.reduce((previous, current) => {
        const offer = offers.find((offer) => {
            return offer.variants.some((variant) => variant.id === current.variantId);
        });
        if (!offer)
            return previous;
        const variant = offer.variants.find((variant) => variant.id === current.variantId);
        if (!variant)
            return previous;
        const item = {
            id: variant.id,
            name: offer.name,
            description: variant.attributesDescription,
            image: offer.images[0] ? offer.images[0].url : undefined,
            stock: variant.quantity ? variant.quantity : undefined,
            price: variant.cost,
            oldPrice: variant.price,
        };
        return [...previous, item];
    }, []);
};
export const mapValuesToSummary = (values, credit, offers) => {
    const items = values.items.reduce((previous, current) => {
        const quantity = current.quantity ? parseInt(`${current.quantity}`) : 0;
        return previous + quantity;
    }, 0);
    const subtotal = values.items.reduce((previous, current) => {
        const offer = offers.find((offer) => offer.variants.some((variant) => variant.id === current.variantId));
        const variant = offer?.variants.find((variant) => variant.id === current.variantId);
        const currentCost = variant && variant.cost && current.quantity
            ? variant.cost.value * parseInt(`${current.quantity}`)
            : 0;
        return previous + currentCost;
    }, 0);
    const aboveCreditValue = subtotal - credit.value;
    return {
        items,
        subtotal,
        shipping: 0,
        samplesCredit: credit.value ? -Math.min(credit.value, subtotal) : undefined,
        profitsBalance: undefined, // not implemented yet
        total: aboveCreditValue > 0 ? aboveCreditValue : 0,
    };
};
export const mapSelectItemsModalValuesToSamplesOrderViewValues = (values) => ({
    items: values.items
        .filter((item) => !!item.checked)
        .map(({ checked, ...rest }) => ({ ...rest, quantity: parseInt(rest.quantity) })),
});
export const getCounterText = (values) => {
    const count = values.items.reduce((previous, item) => previous + parseInt(`${item.quantity}`), 0);
    return count > 0 ? `${count} ${count === 1 ? 'item' : 'items'} in cart` : 'No items in cart';
};
export const removeItemFromValues = (values, id) => {
    const items = values.items.filter((item) => item.variantId !== id);
    return { items };
};
export const isSamplesOrderLocationModalState = (state) => {
    if (!state || typeof state !== 'object')
        return false;
    return 'showModal' in state;
};
export const isSamplesOrderLocationCartState = (state) => {
    if (!state || typeof state !== 'object')
        return false;
    return 'cart' in state;
};
