import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Loader = styled.div `
  opacity: 0.6;
`;
export const Container = styled.button `
  border: 1px solid ${Colors.GS700};
  border-radius: 100px;
  padding: 8px 16px;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${Colors.GS550};

  &:hover {
    border-color: ${Colors.GS600};

    ${Loader} {
      opacity: 0.8;
    }
  }
`;
