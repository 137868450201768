import styled from 'styled-components';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
import { Colors } from '../../colors';
const getSpacing = ({ size }) => {
    return { small: 8, medium: 16, large: 24 }[size];
};
export const Container = styled.div `
  & + & {
    margin-top: ${getSpacing}px;
    padding-top: ${getSpacing}px;
    border-top: 1px solid ${getAutomatedStyleProp('pale', Colors.GS400)};
  }
`;
