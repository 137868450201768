import { Colors } from '@fourthwall/components';
import { PlayIcon as PlayIconComponent, TwitterIcon as TwitterIconComponent, } from '@fourthwall/icons';
import styled, { css } from 'styled-components';
export const Container = styled.a `
  display: block;
  background-color: ${Colors.GS100};
  padding: 16px;
`;
export const Header = styled.div `
  display: flex;
  flex-direction: row;
`;
export const Figure = styled.figure `
  flex: 1 1 auto;
  display: flex;
  align-items: center;
`;
export const Avatar = styled.div `
  flex: 0 0 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`;
export const Figcaption = styled.figcaption `
  flex: 1 1 auto;
  margin-left: 8px;
`;
const textStyles = css `
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0.01em;
`;
export const Name = styled.h5 `
  ${textStyles};
  color: ${Colors.GS1000};
`;
export const Nick = styled.p `
  ${textStyles};
  color: ${Colors.GS600};
`;
export const Logo = styled.div `
  flex: 0 0 auto;
  color: #6aace8;
`;
export const Content = styled.div `
  color: ${Colors.GS1000};
  margin-top: 18px;
`;
export const TwitterIcon = styled(TwitterIconComponent) `
  height: 28px;
`;
export const PlayIcon = styled(PlayIconComponent) `
  height: 28px;
`;
