import { getSlugFromString } from '@fourthwall/utils/lib/string';
export const isSearchedChannelType = (searchedChannel) => {
    return searchedChannel.channelUrl !== undefined;
};
const getPreonboarding = (values) => {
    if (values.siteForSomeoneElse) {
        const channelData = isSearchedChannelType(values.searchedChannel)
            ? values.searchedChannel
            : { channelUrl: values.channelUrl, name: undefined, handle: undefined };
        const { channelUrl, name: channelName, handle: channelHandle } = channelData;
        return {
            creatorName: values.creatorName,
            creatorLocation: values.location,
            platform: values.platform,
            channelUrl,
            channelName,
            channelHandle,
        };
    }
    return undefined;
};
export const getPayloadFromFormValues = (values) => ({
    siteName: values.siteName,
    slug: getSlugFromString(values.siteName),
    preonboarding: getPreonboarding(values),
});
