import { ApplePodcastsIcon, DiscordIcon, FacebookIcon, InstagramIcon, KickIcon, KofiIcon, LiveSpaceIcon, PatreonIcon, PinterestIcon, RedditIcon, SnapchatIcon, SoundCloudIcon, SpotifyIcon, ThreadsIcon, ThroneIcon, TikTokIcon, TwitchIcon, TwitterIcon, XIcon, YoutubeIcon, } from '@fourthwall/icons';
import { routing } from '../../../utils/routing';
export const YOUTUBE_URL_REGEX = /^(https?:\/\/)?(www\.)?(youtube.com\/)(channel\/)(?<handle>[\w._-]+)(\S+)?$/;
export const YOUTUBE_HANDLE_REGEX = /^([\w._-]+)$/;
export const getYoutubeHandle = (url) => {
    return url.match(YOUTUBE_URL_REGEX)?.groups?.handle;
};
export const INSTAGRAM_URL_REGEX = /^(https?:\/\/)?(www\.)?(instagram\.com\/)(?<handle>[\w._-]+)(\S+)?$/i;
export const INSTAGRAM_HANDLE_REGEX = /^([\w._-]+)$/;
export const getInstagramHandle = (url) => {
    return url.match(INSTAGRAM_URL_REGEX)?.groups?.handle;
};
export const FACEBOOK_URL_REGEX = /^(https?:\/\/)?(www\.)?(\w\w-\w\w\.)?(facebook\.com\/)(?<handle>[\w._-]+)(\S+)?$/i;
export const FACEBOOK_HANDLE_REGEX = /^([\w._-]+)$/;
export const getFacebookHandle = (url) => {
    return url.match(FACEBOOK_URL_REGEX)?.groups?.handle;
};
export const TWITTER_URL_REGEX = /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/(@)?(?<handle>[\w._-]+)(\S+)?$/i;
export const TWITTER_HANDLE_REGEX = /^(@)?([\w._-]+)$/;
export const getTwitterHandle = (url) => {
    return url.match(TWITTER_URL_REGEX)?.groups?.handle;
};
export const TIKTOK_URL_REGEX = /^(https?:\/\/)?(www\.)?(tiktok\.com)\/(@)?(?<handle>[\w._-]+)(\S+)?$/i;
export const TIKTOK_HANDLE_REGEX = /^(@)?(?<handle>[\w._-]+)$/;
export const getTiktokHandle = (url) => {
    const urlHandle = url.match(TIKTOK_URL_REGEX)?.groups?.handle;
    const textHandle = url.match(TIKTOK_HANDLE_REGEX)?.groups?.handle;
    const handle = urlHandle || textHandle;
    const parseHandle = (handle) => {
        return handle.startsWith('@') ? handle : `@${handle}`;
    };
    return handle ? parseHandle(handle) : undefined;
};
export const SNAPCHAT_URL_REGEX = /^(https?:\/\/)?(www\.)?(snapchat\.com)(\/add\/)(?<handle>[\w._-]+)(\S+)?$/i;
export const SNAPCHAT_HANDLE_REGEX = /^([\w._-]+)$/;
export const getSnapchatHandle = (url) => {
    return url.match(SNAPCHAT_URL_REGEX)?.groups?.handle;
};
export const DISCORD_URL_REGEX = /^(https?:\/\/)?(www\.)?(discord.(gg|io|me|li|com)|(discordapp\.com\/invite))\/(invite\/)?(?<handle>[\w._-]+)(\S+)?$/i;
export const DISCORD_HANDLE_REGEX = /^([\w._-]+)$/;
export const getDiscordHandle = (url) => {
    return url.match(DISCORD_URL_REGEX)?.groups?.handle;
};
export const TWITCH_URL_REGEX = /^(https?:\/\/)?(www\.)?(twitch\.tv)\/(?<handle>[\w._-]+)(\S+)?$/i;
export const TWITCH_HANDLE_REGEX = /^([\w._-]+)$/;
export const getTwitchHandle = (url) => {
    return url.match(TWITCH_URL_REGEX)?.groups?.handle;
};
export const SPOTIFY_URL_REGEX = /^(https?:\/\/)?(www\.)?(open\.spotify\.com)\/user\/(?<handle>[\w._-]+)(\S+)?$/i;
export const SPOTIFY_HANDLE_REGEX = /^([\w._-]+)$/;
export const getSpotifyHandle = (url) => {
    return url.match(SPOTIFY_URL_REGEX)?.groups?.handle;
};
export const REDDIT_URL_REGEX = /^(https?:\/\/)?(www\.)?(reddit\.com)\/r\/(?<handle>[\w._-]+)(\S+)?$/i;
export const REDDIT_HANDLE_REGEX = /^([\w._-]+)$/;
export const getRedditHandle = (url) => {
    return url.match(REDDIT_URL_REGEX)?.groups?.handle;
};
export const KICK_URL_REGEX = /^(https?:\/\/)?(www\.)?(kick\.com)\/(?<handle>[\w._-]+)(\S+)?$/i;
export const getKickHandle = (url) => {
    return url.match(KICK_URL_REGEX)?.groups?.handle;
};
export const KOFI_URL_REGEX = /^(https?:\/\/)?(www\.)?(ko-fi\.com)\/(?<handle>[\w._-]+)(\S+)?$/i;
export const getKofiHandle = (url) => {
    return url.match(KOFI_URL_REGEX)?.groups?.handle;
};
export const PATREON_URL_REGEX = /^(https?:\/\/)?(www\.)?(patreon\.com)\/(?<handle>[\w._-]+)(\S+)?$/i;
export const getPatreonHandle = (url) => {
    return url.match(PATREON_URL_REGEX)?.groups?.handle;
};
export const THREADS_URL_REGEX = /^(https?:\/\/)?(www\.)?(threads\.net)\/(@)?(?<handle>[\w._-]+)(\S+)?$/i;
export const THREADS_HANDLE_REGEX = /^(@)?(?<handle>[\w._-]+)$/;
export const getThreadsHandle = (url) => {
    const urlHandle = url.match(THREADS_URL_REGEX)?.groups?.handle;
    const textHandle = url.match(THREADS_HANDLE_REGEX)?.groups?.handle;
    const handle = urlHandle || textHandle;
    const parseHandle = (handle) => {
        return handle.startsWith('@') ? handle : `@${handle}`;
    };
    return handle ? parseHandle(handle) : undefined;
};
export const LIVESPACE_URL_REGEX = /^(https?:\/\/)?(www\.)?(live\.space)\/(?<handle>[\w._-]+)(\S+)?$/i;
export const getLiveSpaceHandle = (url) => {
    return url.match(LIVESPACE_URL_REGEX)?.groups?.handle;
};
export const PINTEREST_URL_REGEX = /^(https?:\/\/)?(www\.)?([a-z]{2}\.)?(pinterest\.com)\/(?<handle>[\w._-]+)(\S+)?$/i;
export const getPinterestHandle = (url) => {
    return url.match(PINTEREST_URL_REGEX)?.groups?.handle;
};
export const SOUNDCLOUD_URL_REGEX = /^(https?:\/\/)?(www\.)?(soundcloud\.com)\/(?<handle>[\w._-]+)(\S+)?$/;
export const getSoundCloudHandle = (url) => {
    return url.match(SOUNDCLOUD_URL_REGEX)?.groups?.handle;
};
export const THRONE_URL_REGEX = /^(https?:\/\/)?(www\.)?(throne\.com)\/(?<handle>[\w._-]+)(\S+)?$/i;
export const getThroneHandle = (url) => {
    return url.match(THRONE_URL_REGEX)?.groups?.handle;
};
const iconsMap = {
    social_youtube: YoutubeIcon,
    social_instagram: InstagramIcon,
    social_facebook: FacebookIcon,
    social_twitter: TwitterIcon,
    social_twitch: TwitchIcon,
    social_discord: DiscordIcon,
    social_snapchat: SnapchatIcon,
    social_tiktok: TikTokIcon,
    social_spotify: SpotifyIcon,
    social_reddit: RedditIcon,
    social_apple_podcasts: ApplePodcastsIcon,
    social_kick: KickIcon,
    social_kofi: KofiIcon,
    social_patreon: PatreonIcon,
    social_threads: ThreadsIcon,
    social_livespace: LiveSpaceIcon,
    social_pinterest: PinterestIcon,
    social_soundcloud: SoundCloudIcon,
    social_throne: ThroneIcon,
    social_x: XIcon,
};
export const getSocialIcon = (inputKey) => iconsMap[inputKey];
const socialHandlersMap = {
    social_youtube: getYoutubeHandle,
    social_instagram: getInstagramHandle,
    social_facebook: getFacebookHandle,
    social_twitter: getTwitterHandle,
    social_tiktok: getTiktokHandle,
    social_snapchat: getSnapchatHandle,
    social_discord: getDiscordHandle,
    social_twitch: getTwitchHandle,
    social_spotify: getSpotifyHandle,
    social_reddit: getRedditHandle,
    social_kick: getKickHandle,
    social_kofi: getKofiHandle,
    social_patreon: getPatreonHandle,
    social_threads: getThreadsHandle,
    social_livespace: getLiveSpaceHandle,
    social_pinterest: getPinterestHandle,
    social_soundcloud: getSoundCloudHandle,
    social_throne: getThroneHandle,
    social_x: getTwitterHandle,
};
export const getSocialHandler = (inputKey) => socialHandlersMap[inputKey];
const labelsMap = {
    youtube: 'YouTube',
    instagram: 'Instagram',
    facebook: 'Facebook',
    twitter: 'Twitter',
    twitch: 'Twitch',
    discord: 'Discord',
    snapchat: 'Snapchat',
    tiktok: 'TikTok',
    spotify: 'Spotify',
    reddit: 'Reddit',
    apple_podcasts: 'Apple Podcasts',
    kick: 'Kick',
    kofi: 'Ko-fi',
    patreon: 'Patreon',
    threads: 'Threads',
    livespace: 'LiveSpace',
    pinterest: 'Pinterest',
    soundcloud: 'SoundCloud',
    throne: 'Throne',
    x: 'X',
};
export const getSocialLabel = (inputKey) => labelsMap[inputKey] || inputKey;
const integrationUrlsMap = {
    instagram: routing.apps.instagram,
    twitch: routing.apps.twitch,
    discord: routing.apps.discord,
    tiktok: routing.apps.tiktok,
};
export const getSocialIntegrationUrl = (inputKey) => integrationUrlsMap[inputKey];
