import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDrawer } from '@fourthwall/components';
import { useLocation } from 'react-router-dom';
export const MenuDrawerContext = createContext({
    activeNavigationGroup: null,
    setActiveNavigationGroup: () => { },
    drawerActive: false,
    drawerRoot: document.createElement('div'),
    drawerOpen: () => { },
    drawerClose: () => { },
});
export const MenuDrawerProvider = ({ children }) => {
    const [activeNavigationGroup, setActiveNavigationGroup] = useState(null);
    const drawer = useDrawer('menu-drawer-root', { disableBodyScroll: true });
    const location = useLocation();
    useEffect(() => {
        drawer.drawerClose();
    }, [location]);
    const value = useMemo(() => ({
        ...drawer,
        activeNavigationGroup,
        setActiveNavigationGroup,
    }), [drawer, activeNavigationGroup, setActiveNavigationGroup]);
    return React.createElement(MenuDrawerContext.Provider, { value: value }, children);
};
export const useMenuDrawerContext = () => {
    return useContext(MenuDrawerContext);
};
