import { Colors } from '@fourthwall/components';
import { PlayFullIcon as PlayFullIconComponent } from '@fourthwall/icons';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  background-color: ${Colors.GS100};
  border-radius: 4px;
  border: 1px solid ${Colors.GS400};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
`;
export const Image = styled.div `
  position: relative;
  flex: 0 0 80px;
`;
export const Play = styled.div `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: ${Colors.GS100};
`;
export const Content = styled.div `
  flex: 1 1 auto;
  width: calc(100% - 80px);
  padding-left: 16px;
  padding-right: 16px;
`;
export const textStyles = css `
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  margin: 0;
`;
export const Message = styled.p `
  ${textStyles};
  color: ${Colors.GS1000};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const Url = styled.p `
  ${textStyles};
  color: ${Colors.GS600};
`;
export const PlayIcon = styled(PlayFullIconComponent) `
  height: 28px;
`;
