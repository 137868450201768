import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const TableContainer = styled.div `
  flex: 1;
  overflow: auto;
  overflow-x: hidden;

  /* NOTE: Hack for making checkbox focus outline visible */
  padding-left: 20px;
  margin-left: -20px;

  ${mediaQueries.tablet} {
    max-height: 457px;
  }
`;
