import React, { useLayoutEffect, useRef } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import * as S from './styled';
export const TooltipStatic = ({ children }) => {
    return React.createElement(Manager, null, children);
};
const TooltipStaticReference = ({ children }) => {
    return (React.createElement(Reference, null, ({ ref }) => React.createElement("div", { ref: ref }, children)));
};
const TooltipStaticBody = ({ placement = 'bottom', strategy = 'absolute', offset = [0, 10], withPadding = true, fluid = false, children, }) => {
    const positionUpdateRef = useRef(null);
    useLayoutEffect(() => {
        if (positionUpdateRef.current) {
            positionUpdateRef.current();
        }
    }, [children]);
    const renderTooltip = ({ ref, style, placement: dataPlacement, update }) => {
        positionUpdateRef.current = update;
        return (React.createElement(S.Tooltip, { ref: ref, placement: dataPlacement, withPadding: withPadding, fluid: fluid, style: style, role: "tooltip" }, children));
    };
    return (React.createElement(Popper, { placement: placement, strategy: strategy, modifiers: [
            { name: 'offset', options: { offset } },
            { name: 'preventOverflow', options: { padding: 8 } },
        ] }, renderTooltip));
};
TooltipStatic.Body = TooltipStaticBody;
TooltipStatic.Reference = TooltipStaticReference;
