import { ShopUsersApiClient } from '@fourthwall/services/lib/ShopUsersApiClient';
import { useMutation } from '@tanstack/react-query';
export const useResendInvitationMutation = () => {
    const resendInvitationMutation = useMutation({
        mutationFn: async (variables) => {
            const { data } = await ShopUsersApiClient.resendInvitation(variables);
            return data;
        },
    });
    return { resendInvitationMutation };
};
