import dayjs from 'dayjs';
import { isAvailableGift, isGiftWithoutWinner, isRedeemedGift } from '../../utils';
export const mapTableItems = (gifts, selectedTableItems, { onClick }) => {
    const availableWinners = gifts.filter(isAvailableGift).map((gift) => ({
        id: gift.giftId,
        name: gift.winner,
        isSelected: selectedTableItems.includes(gift.giftId),
        isDisabled: false,
        onClick: () => onClick(gift.giftId),
    }));
    const noWinners = gifts.filter(isGiftWithoutWinner).map((gift) => ({
        id: gift.giftId,
        name: 'No winner',
        isSelected: selectedTableItems.includes(gift.giftId),
        isDisabled: false,
        onClick: () => onClick(gift.giftId),
    }));
    const redeemedWinners = gifts.filter(isRedeemedGift).map((gift) => ({
        id: gift.giftId,
        name: gift.supporter.username || gift.supporter.email,
        isSelected: selectedTableItems.includes(gift.giftId),
        isDisabled: true,
        redeemDate: dayjs(gift.redeemedAt).format('MMM DD, YYYY'),
        onClick: () => onClick(gift.giftId),
    }));
    return [...availableWinners, ...noWinners, ...redeemedWinners];
};
