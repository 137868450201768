import { useEffect, useState } from 'react';
import { useShopPaymentMethodsQuery } from '../../../hooks/useShopPaymentMethodsQuery';
import { useUserPaymentMethodsQuery } from '../../../hooks/useUserPaymentMethodsQuery';
import { filterOutExistingPaymentMethods, mapUserPaymentMethods } from '../utils';
export const useUserPaymentMethods = () => {
    const { userPaymentMethodsQuery } = useUserPaymentMethodsQuery();
    const { shopPaymentMethodsQuery } = useShopPaymentMethodsQuery();
    const filteredPaymentMethods = filterOutExistingPaymentMethods(userPaymentMethodsQuery.data, shopPaymentMethodsQuery.data);
    const [paymentMethods, setPaymentMethods] = useState(filteredPaymentMethods ? mapUserPaymentMethods(filteredPaymentMethods) : undefined);
    useEffect(() => {
        if (filteredPaymentMethods) {
            setPaymentMethods(mapUserPaymentMethods(filteredPaymentMethods));
        }
    }, [userPaymentMethodsQuery.data, shopPaymentMethodsQuery.data]);
    const handleSetSelectedCreditPaymentMethod = (id) => {
        setPaymentMethods((currentPaymentMethods) => currentPaymentMethods?.map((paymentMethod) => ({
            ...paymentMethod,
            selected: paymentMethod.id === id,
        })));
    };
    return {
        userPaymentMethods: paymentMethods,
        selectedPaymentMethod: paymentMethods?.find((paymentMethod) => paymentMethod.selected),
        isLoadingUserPaymentMethods: userPaymentMethodsQuery.isLoading || shopPaymentMethodsQuery.isLoading,
        setSelectedPaymentMethod: handleSetSelectedCreditPaymentMethod,
    };
};
