import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
import { mediaQueries } from '../../providers/MediaQueryProvider';
export const Container = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10; // TODO: It should be removed, but it still causes some issues when there is Select component behind the modal.

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  padding: 16px;

  &::after {
    display: block;
    content: '';
    position: absolute;
    height: 50px;
    width: 100%;
    top: 100%;
    background: ${getAutomatedStyleProp('colorBackground', Colors.GS100)};
  }

  ${mediaQueries.tablet} {
    flex-direction: row;
    padding: 48px;

    &::after {
      display: none;
    }
  }

  ${({ blurOverlay }) => blurOverlay &&
    css `
      backdrop-filter: blur(100px);
    `};
`;
export const Overlay = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: ${Colors.GS1000};
  opacity: 0.8;
`;
const getMaxWidth = ({ size, maxWidth, width }) => {
    const maxWidths = {
        small: '480px',
        medium: '600px',
        large: '800px',
        fullScreen: 'none',
    };
    if (width)
        return '100%';
    return maxWidth ? `${maxWidth}px` : maxWidths[size];
};
export const Box = styled.div `
  flex: ${({ width }) => (width ? '0 1 auto' : '1 0 auto')};
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 24px 20px;
  background: ${getAutomatedStyleProp('colorBackground', Colors.GS100)};
  width: ${({ width }) => width || '100%'};
  max-height: calc(100% - 96px);
  overflow-y: auto;
  overflow-x: hidden;

  border-radius: ${getAutomatedStyleProp('inputCornerRadius')};

  color: ${getAutomatedStyleProp('colorOnBackground', Colors.GS1000)};

  ${({ withoutPadding }) => withoutPadding &&
    css `
      padding: 0;
    `};

  ${({ size }) => size === 'fullScreen' &&
    css `
      max-height: none;
      top: 0;
    `};

  ${mediaQueries.tablet} {
    position: relative;
    padding: 24px 32px;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    overflow: auto;
    max-width: ${getMaxWidth};
    max-height: calc(100vh - 96px);

    ${({ size }) => size === 'fullScreen' &&
    css `
        height: 100%;
      `};

    ${({ withoutPadding }) => withoutPadding &&
    css `
        padding: 0;
      `};

    ${({ disableScrolling }) => disableScrolling &&
    css `
        overflow: visible;
      `};
  }
`;
export const CloseButton = styled.button `
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;

  display: block;
  padding: 6px;

  color: ${getAutomatedStyleProp('colorOnBackground', Colors.GS600)};

  &:hover,
  &:focus {
    color: ${getAutomatedStyleProp('paleTextColor', Colors.GS1000)};
    outline: none;
  }

  ${mediaQueries.tablet} {
    padding: 12px;
    top: 0;
    right: 0;
  }
`;
export const TopContentContainer = styled.div `
  margin: -24px -32px 24px;

  ${({ withoutPadding }) => withoutPadding &&
    css `
      margin: 0 0 24px;
    `};
`;
export const TitleContainer = styled.div `
  margin-bottom: 16px;

  word-break: break-word;
  color: ${getAutomatedStyleProp('colorOnBackground', Colors.GS1000)};

  ${mediaQueries.tablet} {
    margin-bottom: 12px;
  }
`;
export const Footer = styled.div `
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 10;
  bottom: -24px;
  margin: 12px -20px -24px;
  padding: 12px 20px 20px;
  background: ${getAutomatedStyleProp('colorBackground', Colors.GS100)};

  ${mediaQueries.tablet} {
    bottom: -24px;
    margin: -24px;
    margin-top: 16px;
    padding: 24px;
    z-index: 2;
    flex-direction: row;
  }
`;
export const FooterContent = styled.div `
  display: flex;
  padding: 16px 0;
  justify-content: center;

  ${mediaQueries.tablet} {
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
`;
export const ButtonsContent = styled.div `
  flex: 1;
`;
export const ChildrenContainer = styled.div `
  flex: 1;

  flex-direction: column;
  display: flex;

  line-height: 150%;

  text-underline-offset: 0.15em;
  text-decoration-skip-ink: none;

  p + p {
    margin-top: 1em;
  }

  ${({ disableScrolling }) => disableScrolling &&
    css `
      max-height: 100%;
      min-height: 0;
    `};
`;
