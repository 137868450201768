import React from 'react';
import { AccountIcon } from '@fourthwall/icons';
import { generateColorFromText } from '../../utils/colors';
import * as S from './styled';
import { getInitialsFromName } from './utils';
import { Icon } from '../Icon';
export const Avatar = ({ name, id, imageUrl, size = 'regular', hoverable = false, active = false, onClick, }) => {
    const as = onClick ? 'button' : 'div';
    if (imageUrl) {
        return (React.createElement(S.Container, { as: as, size: size, hoverable: hoverable, active: active, onClick: onClick },
            React.createElement(S.Image, { src: imageUrl })));
    }
    if (!name) {
        return (React.createElement(S.EmptyContainer, { as: as, size: size, hoverable: hoverable, active: active, onClick: onClick },
            React.createElement(Icon, { component: AccountIcon, height: 16 })));
    }
    return (React.createElement(S.Container, { as: as, style: { backgroundColor: generateColorFromText(id || name) }, size: size, hoverable: hoverable, active: active, onClick: onClick },
        React.createElement(S.Initials, { size: size }, getInitialsFromName(name))));
};
