import clone from 'lodash-es/clone';
export const arrayMove = (array, fromIndex, toIndex) => {
    const newArray = clone(array);
    const element = newArray[fromIndex];
    if (element) {
        newArray.splice(fromIndex, 1);
        newArray.splice(toIndex, 0, element);
    }
    return [...newArray];
};
