import { Switch } from '@fourthwall/components';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import React from 'react';
import { useDashboardPermissions } from '../../utils/DashboardPermissions';
import * as S from './styled';
export const AdminModeSwitch = () => {
    const { hasRole } = useDashboardPermissions();
    const adminMode = useAdminModeContext();
    if (!hasRole('ROLE_FOURTHWALL_INTERNAL_ADMIN') || !adminMode)
        return null;
    return (React.createElement(S.Container, null,
        React.createElement(Switch, { side: "right", size: "small", label: "Admin mode", value: adminMode.isActive, onChange: adminMode.toggleActive })));
};
