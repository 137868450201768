import { Field, Formik } from 'formik';
import React from 'react';
import { Banner, ButtonClean, Checkbox, Fieldset, HeadContent, RadioButton, Subsection, } from '@fourthwall/components';
import { ANCHOR_IDS } from '@constants/anchorIds';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { routing } from '@utils/routing';
import { ConfirmBarContainer } from '../../../ConfirmBar';
import { SectionDescription } from '../General/components/SectionDescription';
import { CurrenciesSelect } from '../General/components/CurrenciesSelect';
import * as S from './CheckoutView.styled';
export const CheckoutView = (props) => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Checkout \u2013 Settings" }),
        React.createElement(Formik, { initialValues: props.initialValues, enableReinitialize: true, onSubmit: props.onSubmit }, ({ dirty, isSubmitting, submitForm, resetForm, handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
            React.createElement(Subsection, { title: "Accept PayPal" },
                React.createElement("div", null, "PayPal is one of the most trusted payment providers and offering it is known to increase sales, but it has higher payment processing rates at 3.49% + $0.49 per transactions (and an additional 1.5% for payments from outside the USA)."),
                React.createElement("div", { style: { marginTop: 16 } },
                    React.createElement(Fieldset, null,
                        React.createElement(Fieldset.Row, null,
                            React.createElement(Fieldset.Item, null,
                                React.createElement(Field, { component: Checkbox, name: "payPal", label: "Accept PayPal at checkout" })))))),
            React.createElement(Subsection, { title: "Accept buy now, pay later" },
                React.createElement("div", null,
                    "Buy now, pay later services (Afterpay, Klarna) allow your customers to pay over 4 interest-free installments (on orders above $10). You receive your profit immediately, but these orders have a higher payment processing rate of 6% + $.30 per transaction (and an additional 1% for payments outside the US).",
                    ' ',
                    React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.buyNowPayLater, target: "_blank" })),
                React.createElement("div", { style: { marginTop: 16 } },
                    React.createElement(Fieldset, null,
                        React.createElement(Fieldset.Row, null,
                            React.createElement(Fieldset.Item, null,
                                React.createElement(Field, { component: Checkbox, name: "deferred", label: "Accept buy now, pay later" })))))),
            dashboardFeatureFlags.multicurrencySettings.enabled && (React.createElement(Subsection, { title: "Local currencies" },
                !props.currency.enabled && (React.createElement("div", { style: { marginBottom: 16 } },
                    React.createElement(Banner, { title: React.createElement(React.Fragment, null,
                            "Your custom theme does not support multi-currency. Please",
                            ' ',
                            React.createElement("a", { href: "mailto:support@fourthwall.com" }, "contact our support"),
                            " team or your partner manager to enable multi-currency."), appearance: "warning" }))),
                props.currency.enabled && !props.currency.yenEnabled && (React.createElement("div", { style: { marginBottom: 16 } },
                    React.createElement(Banner, { title: React.createElement(React.Fragment, null,
                            "Your custom theme does not support JPY. Please",
                            ' ',
                            React.createElement("a", { href: "mailto:support@fourthwall.com" }, "contact our support"),
                            " team or your partner manager to enable JPY."), appearance: "warning" }))),
                React.createElement(SectionDescription, null,
                    "When enabled, we\u2019ll automatically convert prices to local currencies for supporters. You\u2019ll make the same profit per sale regardless of currency used.",
                    ' ',
                    React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.multicurrency, target: "_blank" })),
                React.createElement(Field, { name: "enabledCurrencies", component: CurrenciesSelect, currencies: props.currency.list, disabled: !props.currency.enabled }))),
            React.createElement(Subsection, { title: "Abandoned checkout reminders", id: ANCHOR_IDS.ABANDONED_CHECKOUT_REMINDERS },
                React.createElement(SectionDescription, null,
                    "An email reminder sent to people who start checking out but don\u2019t finish. On average, 10% of people who receive an abandoned cart reminder end up placing an order.",
                    ' ',
                    React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.abandonedCheckoutEmails, target: "_blank" })),
                React.createElement(S.SectionHeader, null, "Email frequency"),
                React.createElement(Fieldset, null, props.abandonedCheckout.options.map((option) => (React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { component: RadioButton, name: "abandonedCheckoutEmailFrequency", ...option }))))))),
            React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isSubmitting, onConfirm: submitForm, onCancel: resetForm }))))));
};
