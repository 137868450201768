export class BroadcastChannelManager {
    channel;
    constructor(channelName) {
        this.channel = new BroadcastChannel(channelName);
    }
    on(callback) {
        this.channel.addEventListener('message', callback);
    }
    off(callback) {
        this.channel.removeEventListener('message', callback);
    }
    emit(data) {
        this.channel.postMessage(data);
    }
    close() {
        this.channel.close();
    }
}
