import { HeadContent, Skeleton } from '@fourthwall/components';
import React from 'react';
import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { routing } from '@utils/routing';
import { useVerifyDiscord } from './hooks/useVerifyDiscord';
import { ConnectBar } from '../../components/ConnectBar';
export const AppsDiscordVerification = () => {
    const { connectBarProps } = useVerifyDiscord();
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Join our Discord community" }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: "Join our Discord community", topContent: React.createElement(DashboardBackButton, { label: "Home", to: routing.home }), bottomContent: React.createElement("div", { style: { lineHeight: 1.5 } },
                    React.createElement("p", null, "Connect with fellow creators and earn $5 in sample credit")) },
                React.createElement("div", { style: { marginTop: 16 - 24, marginBottom: 40 } }, connectBarProps ? (React.createElement(ConnectBar, { ...connectBarProps })) : (React.createElement(Skeleton, null,
                    React.createElement(Skeleton.Shape, { height: 64 }))))))));
};
