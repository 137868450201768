const VIDEO_TYPES = ['webm', 'mp4', 'x-matroska', 'ogg'];
const AUDIO_TYPES = ['webm', 'mp3', 'mp4', 'x-matroska', 'ogg', 'wav'];
const VIDEO_CODECS = [
    'vp9',
    'vp9.0',
    'vp8',
    'vp8.0',
    'avc1',
    'av1',
    'h265',
    'h.265',
    'h264',
    'h.264',
    'mpeg',
    'theora',
];
const AUDIO_CODECS = ['opus', 'vorbis', 'aac', 'mpeg', 'mp4a', 'pcm'];
const isTypeSupported = (mimeType) => MediaRecorder.isTypeSupported(mimeType);
const getSupportedVideoWithAudioMimeType = () => VIDEO_TYPES.flatMap((videoType) => VIDEO_CODECS.flatMap((videoCodec) => AUDIO_CODECS.map((audioCodec) => `video/${videoType};codecs=${videoCodec},${audioCodec}`))).find(isTypeSupported);
const getSupportedVideoMimeType = () => VIDEO_TYPES.flatMap((videoType) => VIDEO_CODECS.map((videoCodec) => `video/${videoType};codecs=${videoCodec}`)).find(isTypeSupported);
const getSupportedAudioMimeType = () => AUDIO_TYPES.flatMap((audioType) => AUDIO_CODECS.map((audioCodec) => `audio/${audioType};codecs=${audioCodec}`)).find(isTypeSupported);
export const useMimeType = (type) => {
    const videoMimeType = getSupportedVideoWithAudioMimeType() || getSupportedVideoMimeType();
    const audioMimeType = getSupportedAudioMimeType();
    return {
        VIDEO: videoMimeType,
        AUDIO: audioMimeType,
    }[type];
};
