import React from 'react';
import { EmptyState, List, Table, useMediaQueryContext } from '@fourthwall/components';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routing } from '@utils/routing';
import { TABLE_HEADERS } from './consts';
import { GiveawayPackageListItem } from '../GiveawayPackageListItem';
const getGiveawayPackageLink = (row) => {
    const giveawayPackageId = row.packageId;
    return {
        pathname: reverse(routing.promotions.giveawayPackages.giveawayPackage.self, {
            giveawayPackageId,
        }),
        search: location.search,
    };
};
export const GiveawayPackagesData = ({ data, isLoading }) => {
    const media = useMediaQueryContext();
    const emptyComponent = (React.createElement(EmptyState, { title: "You haven't created any giveaway links yet.", subtitle: "Giveaway links let recipients easily redeem free products from your store.", label: "Create giveaway link", to: routing.promotions.giveawayPackages.create.self }));
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, getItemLink: getGiveawayPackageLink, renderItem: (givewayPackage) => (React.createElement(GiveawayPackageListItem, { giveawayPackage: givewayPackage })), emptyComponent: emptyComponent }));
    }
    return (React.createElement(Table, { data: data, headers: TABLE_HEADERS, isLoading: isLoading, rowComponent: Link, emptyComponent: emptyComponent, getRowLink: getGiveawayPackageLink }));
};
