import { useRoles } from '@fourthwall/auth';
import isArray from 'lodash-es/isArray';
import React from 'react';
import { groupsPermissions } from './permissions/groupsPermissions';
import { membershipsDisabledPermissions } from './permissions/membershipsDisabledPermissions';
import { membershipsEditorPermissions } from './permissions/membershipsEditorPermissions';
export const DashboardPermissionsContext = React.createContext(undefined);
export const DashboardPermissionsProvider = ({ children }) => {
    const { hasRole, hasGroup } = useRoles();
    const buildGroupsPermissions = (permissions) => {
        const result = {};
        Object.entries(permissions).forEach(([permission, value]) => {
            if (isArray(value)) {
                result[permission] = value.some(hasGroup);
                return;
            }
            result[permission] = buildGroupsPermissions(value);
        });
        return result;
    };
    const getPermissions = () => {
        if (hasRole('ROLE_MEMBERSHIPS_EDITOR')) {
            return membershipsEditorPermissions;
        }
        if (hasRole('ROLE_MEMBERSHIPS_DISABLED')) {
            return membershipsDisabledPermissions;
        }
        return buildGroupsPermissions(groupsPermissions);
    };
    return (React.createElement(DashboardPermissionsContext.Provider, { value: getPermissions() }, children));
};
