import React from 'react';
import { useUpdateShippingAddressMutation } from './mutations/useUpdateShippingAddressMutation';
import { OrderShippingAddressView } from './OrderShippingAddressView';
import { useOrderIdParam } from '../hooks';
import { useOrderQuery } from '../queries';
export const OrderShippingAddress = ({ close }) => {
    const { orderId } = useOrderIdParam();
    const { orderQuery, invalidateOrderQuery } = useOrderQuery([orderId]);
    const { updateShippingAddressMutation } = useUpdateShippingAddressMutation({
        onSuccess: () => {
            invalidateOrderQuery();
            close();
        },
    });
    return (React.createElement(OrderShippingAddressView, { orderId: orderId, shippingAddress: orderQuery.data?.shipping.address, isFetching: updateShippingAddressMutation.isLoading, updateShippingAddressRequest: ({ id, data }) => {
            updateShippingAddressMutation.mutate([id, data]);
        }, close: close }));
};
