import { useState } from 'react';
import { loadScriptUrl } from '@fourthwall/utils/lib/common';
import { ENTRI_CDN_URL } from '../consts';
export const useLoadEntri = () => {
    const [isEntriScriptLoading, setIsEntriScriptLoading] = useState(false);
    const isEntriLoaded = !!window.entri && !isEntriScriptLoading;
    const loadEntriScript = async () => {
        try {
            setIsEntriScriptLoading(true);
            await loadScriptUrl(ENTRI_CDN_URL);
        }
        finally {
            setIsEntriScriptLoading(false);
        }
    };
    return { isEntriLoaded, loadEntriScript };
};
