import { ShippingApiClient } from '@fourthwall/services/lib/ShippingApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
export const useSaveFlatRatesMutation = () => {
    const queryClient = useQueryClient();
    const saveFlatRatesMutation = useMutation({
        mutationFn: async (payload) => {
            const { data } = await ShippingApiClient.saveFlatRates(payload);
            return data;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(['shopShippingFlatRates', undefined], data);
        },
    });
    return {
        saveFlatRatesMutation,
    };
};
