import { CurrencyInput, PriceString } from '@fourthwall/components';
import { formatCurrency } from '@fourthwall/utils/lib/currency';
import { Field } from 'formik';
import findIndex from 'lodash-es/findIndex';
import isNil from 'lodash-es/isNil';
import React from 'react';
import { PRODUCT_PRICE_MAX } from '@fourthwall/utils/lib/constants';
import { ErrorMessage } from '../components/ErrorMessage/ErrorMessage';
import { PricingTableCell } from '../components/PricingTableCell/PricingTableCell';
const findInputName = (variants, size, property = 'price') => {
    const index = findIndex(variants, (variant) => variant.size === size);
    return `variants[${index}].${property}.value`;
};
export const getTableHeaders = ({ variants, errors, applyToAllVariants, disabled, showCompareAtPrice, }) => {
    const tableHeaders = [
        {
            id: 'size',
            label: 'Size',
            minWidth: 100,
            minHeight: 72,
            alignItems: 'baseline',
            render: ({ size }) => (React.createElement(PricingTableCell, { mainContent: React.createElement("div", { style: { paddingTop: '12px' } }, size) })),
        },
        {
            id: 'price',
            label: 'Selling price',
            minWidth: 120,
            minHeight: 72,
            alignItems: 'baseline',
            render: ({ size }, index) => {
                // @ts-ignore
                const error = errors.variants?.[index]?.price?.value;
                return (React.createElement(PricingTableCell, { mainContent: React.createElement("div", { style: { maxWidth: 120 } },
                        React.createElement(Field, { component: CurrencyInput, size: "medium", name: findInputName(variants, size), displayError: false, disabled: disabled, onBlur: (e) => applyToAllVariants({ priceValue: e.target.value, size }) }),
                        error && React.createElement(ErrorMessage, null, error)) }));
            },
        },
        {
            id: 'compareAtPrice',
            label: 'Compare-at price',
            alignItems: 'baseline',
            render: ({ size }, index) => {
                // @ts-ignore
                const error = errors.variants?.[index]?.compareAtPrice?.value;
                return (React.createElement(PricingTableCell, { mainContent: React.createElement("div", { style: { maxWidth: 120 } },
                        React.createElement(Field, { component: CurrencyInput, size: "medium", name: findInputName(variants, size, 'compareAtPrice'), disabled: disabled, displayError: false, error: error, strikethrough: true, min: 0, max: PRODUCT_PRICE_MAX, onBlur: (e) => applyToAllVariants({ compareAtPriceValue: e.target.value || null, size }) }),
                        error && React.createElement(ErrorMessage, null, error)) }));
            },
            hidden: !showCompareAtPrice,
        },
        {
            id: 'profit',
            label: 'Profit per sale',
            minWidth: 100,
            minHeight: 72,
            alignItems: 'baseline',
            render: (variant) => {
                const cost = variant.cost?.value;
                const profit = !isNil(cost) ? formatCurrency(variant.price.value - cost) : 0;
                return (React.createElement(PricingTableCell, { mainContent: React.createElement("div", { style: { paddingTop: '12px' } }, !isNil(cost) ? React.createElement(PriceString, { value: profit }) : React.createElement(React.Fragment, null, "\u2013")) }));
            },
        },
    ];
    return tableHeaders.filter((header) => !header.hidden);
};
