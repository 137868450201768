import { Checkbox, CurrencyInput, Icon, Tooltip } from '@fourthwall/components';
import { QuestionMarkIcon } from '@fourthwall/icons';
import { Field } from 'formik';
import React from 'react';
import { CurrentMonthSpending } from '../CurrentMonthSpending';
import * as S from './styled';
export const ChargeLimitFormGroup = ({ isEnabled, limitEnabledFieldName = 'setChargeLimit', limitFieldName = 'chargeLimit', currentMonthSpending, }) => (React.createElement(React.Fragment, null,
    React.createElement(S.CheckboxContainer, null,
        React.createElement(Field, { component: Checkbox, name: limitEnabledFieldName, label: "Set a monthly charge limit" }),
        React.createElement(S.Tooltip, null,
            React.createElement(Tooltip, { reference: React.createElement(Icon, { component: QuestionMarkIcon, height: 15 }), placement: "top", fluid: true }, "This is the maximum amount your payment method will be charged during a calendar month (from 12AM UTC on the 1st of the month to 11:59PM UTC on the last day of the month)."))),
    isEnabled && (React.createElement(S.ContentContainer, null,
        React.createElement(S.CurrencyInputWrapper, null,
            React.createElement(Field, { component: CurrencyInput, name: limitFieldName, label: "Monthly charge limit", max: 9_999_999.99 })),
        currentMonthSpending && (React.createElement(S.CurrentMonthSpendingWrapper, null,
            React.createElement(CurrentMonthSpending, { value: currentMonthSpending })))))));
