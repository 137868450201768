export const getQuantityError = (item, quantity) => {
    const quantityNumber = Number(quantity);
    if (quantityNumber % 1 !== 0) {
        return `Must be whole number.`;
    }
    if (quantityNumber < 1) {
        return `At least 1 is required.`;
    }
    if ('stock' in item && !!item.stock && quantityNumber > item.stock) {
        return `Only ${item.stock} in stock.`;
    }
    return '';
};
