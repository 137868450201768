import React from 'react';
import { Icon, PriceString, ProfitString } from '@fourthwall/components';
import { ArrowShortRightIcon } from '@fourthwall/icons';
import isNil from 'lodash/isNil';
import * as S from './styled';
import { VariantImageCell } from '../VariantImageCell';
export const ProductVariantsListItem = ({ productVariant, previewImage, shouldDisplayPhoto, showPricing, showWeight, showSKU, sold, }) => {
    const compareAtPrice = productVariant.compareAtPrice?.value;
    const profit = productVariant.cost?.value
        ? productVariant.price.value - productVariant.cost.value
        : undefined;
    return (React.createElement(S.Container, null,
        shouldDisplayPhoto && (React.createElement(S.VariantsImageCellWrapper, null,
            React.createElement(VariantImageCell, { previewImage: previewImage, imagesCount: productVariant.images.length, isFirstOfGroup: productVariant.isFirstOfGroup, isLastOfGroup: productVariant.isLastOfGroup, shouldDisplayPhoto: shouldDisplayPhoto }))),
        React.createElement(S.Details, null,
            React.createElement("div", null, productVariant.attributesDescription),
            showPricing && (React.createElement(S.Price, null,
                compareAtPrice && (React.createElement(S.CompareAtPrice, { style: { textDecoration: 'line-through', display: 'inline-block' } },
                    React.createElement(PriceString, { value: compareAtPrice }),
                    ' ')),
                React.createElement(PriceString, { value: productVariant.price.value }),
                profit && React.createElement(ProfitString, { value: profit }))),
            React.createElement(S.DetailsRow, null,
                isNil(productVariant.quantity) ? 'Unlimited' : productVariant.quantity,
                " in stock | ",
                sold,
                ' ',
                "sold",
                ' '),
            showWeight && (React.createElement(S.DetailsRow, null,
                productVariant.weight?.value,
                " ",
                productVariant.weight?.unit)),
            showSKU && React.createElement(S.DetailsRow, null,
                "#",
                productVariant.sku)),
        React.createElement(S.Arrow, null,
            React.createElement(Icon, { component: ArrowShortRightIcon, height: 12 }))));
};
