import { Button, Input, useMediaQueryContext } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { normalizeDomain } from '../../utils';
import { validationSchema } from './consts';
import * as S from './styled';
export const DomainForm = ({ isLoading, onSubmit }) => {
    const media = useMediaQueryContext();
    const handleSubmit = (values, { setErrors }) => {
        onSubmit(values, (message) => setErrors({ domain: message }));
    };
    const formik = useFormik({
        initialValues: {
            domain: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });
    const handleDomainPaste = (event) => {
        event.preventDefault();
        formik.setFieldValue('domain', normalizeDomain(event.clipboardData.getData('Text')));
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(Input, { placeholder: "yourwebsite.com or shop.yourwebsite.com", suggestion: "Enter the URL of the domain you want to connect (e.g., yourwebsite.com or shop.yourwebsite.com)", disabled: isLoading, ...formik.getFieldProps('domain'), onPaste: handleDomainPaste }),
        React.createElement(S.ButtonContainer, null,
            React.createElement(Button, { label: "Connect domain", appearance: "primary", type: "submit", fullWidth: !media.tablet, loading: isLoading, disabled: !formik.dirty || !formik.isValid }))));
};
