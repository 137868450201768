import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const CheckboxContainer = styled.div `
  display: flex;
  align-items: center;
`;
export const Tooltip = styled.div `
  margin-left: 6px;
`;
export const ContentContainer = styled.div `
  padding-left: 36px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;

  ${mediaQueries.tablet} {
    flex-direction: row;
  }
`;
export const CurrencyInputWrapper = styled.div `
  flex: 1;

  ${mediaQueries.tablet} {
    width: 200px;
    flex: 0 1 auto;
  }
`;
export const CurrentMonthSpendingWrapper = styled.div `
  flex: 1;
`;
