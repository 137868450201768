import { ConfirmModal, useModalContext } from '@fourthwall/components';
import isEmpty from 'lodash-es/isEmpty';
import { useDashboardFeatureFlags } from '../../../../../components/utils/DashboardFeatureFlags';
import { checklistDataLayer } from '../../../../../utils/gtm/checklistDataLayer';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { useMembershipsOnboarding } from '../../../Memberships/modules/Overview/modules/Onboarding/hooks/useMembershipsOnboarding';
import { useChecklistActionVisibilityMutation } from '../useChecklistActionVisibilityMutation';
import { useChecklistVisibilityMutation } from '../useChecklistVisibilityMutation';
import { useOnboardingSummaryQuery } from '../../queries/useOnboardingSummaryQuery';
import { useScheduler } from '../useScheduler';
import { getShopChecklistItemsConfig, sortChecklistItems } from './utils';
export const useOnboarding = () => {
    const { open } = useModalContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const membershipsOnboarding = useMembershipsOnboarding({
        page: 'Home',
        title: 'Memberships setup',
    });
    const { scheduler } = useScheduler();
    const { currentShopQuery } = useCurrentShopQuery();
    const { onboardingSummaryQuery, invalidateOnboardingSummaryQuery } = useOnboardingSummaryQuery();
    const { checklistVisibilityMutation } = useChecklistVisibilityMutation({
        onSuccess: () => invalidateOnboardingSummaryQuery(),
    });
    const { checklistActionVisibilityMutation } = useChecklistActionVisibilityMutation({
        onSuccess: () => invalidateOnboardingSummaryQuery(),
    });
    const skipAllShopItems = async () => {
        return await checklistVisibilityMutation.mutateAsync([{ newStatus: 'Hidden' }]);
    };
    const skipItem = async (actionType) => {
        await checklistActionVisibilityMutation.mutateAsync([{ actionType }, { newStatus: 'Hidden' }]);
    };
    const shopChecklistItemsConfig = getShopChecklistItemsConfig({ openModal: open, skipItem });
    const shopChecklistItems = (() => {
        if (!onboardingSummaryQuery.isSuccess)
            return [];
        return onboardingSummaryQuery.data.checklist
            .sort(sortChecklistItems)
            .map((item) => {
            if (!(item.action.type in shopChecklistItemsConfig)) {
                return {};
            }
            return {
                key: item.action.type,
                title: shopChecklistItemsConfig[item.action.type].title,
                description: shopChecklistItemsConfig[item.action.type].description,
                imageUrl: shopChecklistItemsConfig[item.action.type].imageUrl,
                primaryButton: shopChecklistItemsConfig[item.action.type].primaryButton,
                completedButton: shopChecklistItemsConfig[item.action.type].completedButton,
                completed: item.status === 'Hidden' || item.action.status === 'Completed',
                node: shopChecklistItemsConfig[item.action.type].node,
                onSkip: () => {
                    open(ConfirmModal, {
                        title: `Are you sure you want to skip “${shopChecklistItemsConfig[item.action.type].title}” step?`,
                        text: 'This action cannot be reversed.',
                        confirmLabel: 'Skip',
                        confirmButtonAnalytics: 'skipChecklistStepConfirm',
                        onConfirm: () => {
                            shopChecklistItemsConfig[item.action.type].onSkipConfirm?.();
                        },
                    });
                    checklistDataLayer('skipChecklistStep', 'Home', 'Launch', shopChecklistItemsConfig[item.action.type].title);
                },
            };
        })
            .filter((item) => !isEmpty(item));
    })();
    const allShopChecklistItemsCompleted = !shopChecklistItems.some(({ completed }) => !completed);
    const onboardingType = (() => {
        if (!dashboardFeatureFlags.homeOnboarding.enabled)
            return 'NONE';
        if (!onboardingSummaryQuery.isSuccess ||
            membershipsOnboarding.isLoading ||
            !currentShopQuery.isSuccess) {
            return 'LOADING';
        }
        const wasEverActive = !!currentShopQuery.data.lastLiveAt;
        // Display only Home onboarding if memberships are disabled or someone skipped memberships in memberships tab
        if (!allShopChecklistItemsCompleted && membershipsOnboarding.hidden)
            return 'ONBOARDING_SHOP';
        if (!allShopChecklistItemsCompleted || membershipsOnboarding.active)
            return 'ONBOARDING_SHOP_MEMBERSHIPS';
        // Don't display scheduler when shop was ever launched
        if (scheduler.schedulerProps && !wasEverActive)
            return 'SCHEDULER';
        return 'NONE';
    })();
    const checklistItems = (() => {
        if (onboardingType === 'ONBOARDING_SHOP_MEMBERSHIPS') {
            return [...shopChecklistItems, ...membershipsOnboarding.items];
        }
        if (onboardingType === 'ONBOARDING_SHOP') {
            return shopChecklistItems;
        }
        if (onboardingType === 'ONBOARDING_MEMBERSHIPS') {
            return membershipsOnboarding.items;
        }
        return [];
    })();
    const completedItemsCount = checklistItems.filter(({ completed }) => completed).length;
    const percentage = Math.floor((completedItemsCount / checklistItems.length) * 100);
    const title = (() => {
        return `${percentage}% complete`;
    })();
    const getSkipLabel = () => {
        if (onboardingType === 'ONBOARDING_SHOP_MEMBERSHIPS' || onboardingType === 'ONBOARDING_SHOP') {
            return 'Skip launch checklist';
        }
        return '';
    };
    const onSkip = () => {
        if (onboardingType === 'ONBOARDING_SHOP_MEMBERSHIPS' || onboardingType === 'ONBOARDING_SHOP') {
            open(ConfirmModal, {
                title: 'Are you sure you want to skip launch checklist?',
                text: 'This action cannot be reversed.',
                confirmLabel: 'Skip',
                confirmButtonAnalytics: 'skipChecklistConfirm',
                onConfirm: () => {
                    if (onboardingType === 'ONBOARDING_SHOP_MEMBERSHIPS') {
                        membershipsOnboarding.onSkipConfirm();
                    }
                    skipAllShopItems();
                    checklistDataLayer('skipChecklistConfirm', 'Home', 'Launch');
                },
            });
            if (onboardingType === 'ONBOARDING_SHOP_MEMBERSHIPS') {
                checklistDataLayer('skipChecklist', 'Home', 'Memberships');
            }
            checklistDataLayer('skipChecklist', 'Home', 'Launch');
        }
        if (onboardingType === 'ONBOARDING_MEMBERSHIPS') {
            membershipsOnboarding.onSkip();
        }
    };
    const onboardingProps = {
        progress: { title, percentage },
        onboardingListProps: { items: checklistItems },
        skipLabel: getSkipLabel(),
        onSkip,
    };
    const getOnboardingProps = () => {
        if (onboardingType === 'ONBOARDING_SHOP' ||
            onboardingType === 'ONBOARDING_MEMBERSHIPS' ||
            onboardingType === 'ONBOARDING_SHOP_MEMBERSHIPS') {
            return onboardingProps;
        }
    };
    const schedulerProps = (() => {
        if (onboardingType === 'SCHEDULER' || onboardingType === 'SCHEDULER_MEMBERSHIPS') {
            return scheduler.schedulerProps;
        }
    })();
    const onboarding = {
        onboardingType,
        schedulerProps,
        onboardingProps: getOnboardingProps(),
    };
    return { onboarding };
};
