import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
`;
export const Quantity = styled.div `
  flex: 1;
`;
