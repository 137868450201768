import { useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useMediaQueryContext, useModalContext } from '@fourthwall/components';
import { AddProductToFavoritesModal } from '@fourthwall/module-product';
export const useAddProductToFavoritesModal = () => {
    const [fromProductParam, setFromProductParam] = useQueryParam('fromProduct', StringParam);
    const { tablet } = useMediaQueryContext();
    const { open } = useModalContext();
    useEffect(() => {
        if (fromProductParam && !tablet) {
            open(AddProductToFavoritesModal, { slug: fromProductParam });
        }
        setFromProductParam(undefined);
    }, [tablet, fromProductParam, open, setFromProductParam]);
};
