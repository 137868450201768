import { ApparelIcon, ButtonIcon, ChecklistIcon, CodeIcon, CogIcon, CollectionIcon, EmailIcon, FooterIcon, HeaderIcon, ImageBannerIcon, ImageWithTextIcon, InstagramIcon, MegaphoneIcon, PhotoIcon, PostIcon, TagIcon, TikTokIcon, VideoBannerIcon, YoutubeIcon, } from '@fourthwall/icons';
const iconMap = {
    'announcement-bar': MegaphoneIcon,
    content_for_header: CodeIcon,
    'custom-html': CodeIcon,
    debug: CogIcon,
    donations: TagIcon,
    'featured-collection': CollectionIcon,
    'featured-product': ApparelIcon,
    footer: FooterIcon,
    header: HeaderIcon,
    hero: ImageBannerIcon,
    'hero-video': VideoBannerIcon,
    'image-with-text': ImageWithTextIcon,
    image: PhotoIcon,
    'instagram-feed': InstagramIcon,
    'links-list': ButtonIcon,
    newsletter: EmailIcon,
    'recent-tiktoks': TikTokIcon,
    'recent-videos': YoutubeIcon,
    'rich-text': PostIcon,
    tiers: ChecklistIcon,
};
export const getIcon = (sectionName) => {
    return iconMap[sectionName] || PostIcon;
};
