import { defaultDropAnimationSideEffects, DndContext as DndKitDndContext, DragOverlay as DndKitDragOverlay, KeyboardSensor, MouseSensor, PointerSensor, useSensor, useSensors, } from '@dnd-kit/core';
import { SortableContext as DndSortableContext, sortableKeyboardCoordinates, useSortable, } from '@dnd-kit/sortable';
import React, { createContext, useContext, useMemo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { DragIcon } from '@fourthwall/icons';
import * as TableUI from '../TableUI';
import { useTableBody } from '../contexts/TableBodyContext';
import * as S from '../styled';
import { Icon } from '../../../common/Icon';
export const DndContext = ({ children, ...props }) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    return (React.createElement(DndKitDndContext, { sensors: sensors, ...props }, children));
};
export const SortableContext = ({ children, ...props }) => {
    return React.createElement(DndSortableContext, { ...props }, children);
};
export const TableSortableItemContext = createContext({
    attributes: {},
    listeners: undefined,
    ref() { },
});
export const TableSortableBodyTr = ({ children, id, isDragged, isSelected, }) => {
    const { setDropdownKey } = useTableBody();
    const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition, } = useSortable({ id });
    const context = useMemo(() => ({
        attributes,
        listeners,
        ref: setActivatorNodeRef,
    }), [attributes, listeners, setActivatorNodeRef]);
    const style = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
    };
    return (React.createElement(TableSortableItemContext.Provider, { value: context },
        React.createElement(S.TableBodyTr, { isDragged: isDragged, isSelected: isSelected, ref: setNodeRef, style: style, onMouseLeave: () => {
                setDropdownKey((key) => key + 1);
            } }, children)));
};
export const TableRowDragHandle = () => {
    const { attributes, listeners, ref } = useContext(TableSortableItemContext);
    return (React.createElement(S.TableRowDragHandle, { ...attributes, ...listeners, ref: ref },
        React.createElement(Icon, { component: DragIcon, height: 16 })));
};
export const dropAnimationConfig = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.4',
            },
        },
    }),
};
export const DragOverlay = ({ children }) => {
    return React.createElement(DndKitDragOverlay, { dropAnimation: dropAnimationConfig }, children);
};
export const TableSortableBody = TableUI.Body;
export const TableSortableBodyTd = TableUI.BodyTd;
