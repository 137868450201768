import { Button, Checkbox, Icon, Loader, ModalBox, RadioButton } from '@fourthwall/components';
import { SecureBadgeIcon } from '@fourthwall/icons';
import { PAYPAL_CONTAINER_ID } from '@modules/Dashboard/PaymentMethods/modules/AddPaymentMethodModal/consts';
import { Field, Formik } from 'formik';
import React from 'react';
import { ChargeLimitFormGroup } from '../../components/ChargeLimitFormGroup/ChargeLimitFormGroup';
import { PaymentMethodsList } from '../../components/PaymentMethodsList';
import payPalIcon from './assets/Paypal.svg';
import { StripeForm } from './components/StripeForm';
import * as S from './styled';
export const AddPaymentMethodModalView = ({ formikRef, title, subtitle, submitLabel, fields, paymentMethodsProps, initialValues, validationSchema, shouldRenderIsPaymentMethodDefaultCheckbox, currentMonthSpendingValue, isSubmitting, isLoading, onSubmit, onPaymentMethodTypeChange, onClose, }) => {
    const renderSubtitle = () => {
        if (!subtitle || isLoading) {
            return null;
        }
        if (!subtitle) {
            return (React.createElement(S.Subtitle, null, "You will only be charged if your account balance does not cover the cost of an action (for giveaways, promo codes, etc.)"));
        }
        return React.createElement(S.Subtitle, null, subtitle);
    };
    const renderIsPaymentMethodDefaultCheckbox = () => {
        if (!shouldRenderIsPaymentMethodDefaultCheckbox) {
            return null;
        }
        return (React.createElement(S.CheckboxWrapper, null,
            React.createElement(Field, { component: Checkbox, name: "isPaymentMethodDefault", label: "Set as default payment method" })));
    };
    const renderForm = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement(S.PaymentMethodTypeItem, null,
                React.createElement(Field, { component: RadioButton, name: "paymentMethodType", label: "Credit or debit card", checkedValue: "STRIPE", onValueChange: onPaymentMethodTypeChange }),
                React.createElement(S.StripeFormWrapper, null,
                    React.createElement(StripeForm, { fields: fields }))),
            React.createElement(S.PaymentMethodTypeItem, null,
                React.createElement(Field, { component: RadioButton, name: "paymentMethodType", label: React.createElement(S.PayPalIcon, { src: payPalIcon, alt: "PayPal" }), checkedValue: "PAYPAL", onValueChange: onPaymentMethodTypeChange })),
            renderIsPaymentMethodDefaultCheckbox()));
    };
    const renderNewPayment = () => {
        if (isLoading || !paymentMethodsProps || paymentMethodsProps.length) {
            return null;
        }
        return renderForm();
    };
    const renderPaymentChoice = ({ formType }) => {
        if (isLoading || !paymentMethodsProps?.length) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(S.RadioWrapper, null,
                React.createElement(Field, { component: RadioButton, name: "formType", label: "Add a payment method from another Fourthwall site you manage", checkedValue: "EXISTING" }),
                React.createElement(S.RadioContent, { isHidden: formType !== 'EXISTING' },
                    React.createElement(PaymentMethodsList, { paymentMethods: paymentMethodsProps }),
                    renderIsPaymentMethodDefaultCheckbox())),
            React.createElement(S.RadioWrapper, null,
                React.createElement(Field, { component: RadioButton, name: "formType", label: "Add a new payment method", checkedValue: "NEW" }),
                React.createElement(S.RadioContent, { isHidden: formType !== 'NEW' }, renderForm()))));
    };
    const renderTransactionLimitFormGroup = ({ setChargeLimit }) => {
        if (isLoading) {
            return null;
        }
        return (React.createElement(S.CheckboxWrapper, null,
            React.createElement(ChargeLimitFormGroup, { isEnabled: setChargeLimit, currentMonthSpending: currentMonthSpendingValue })));
    };
    const renderSubmitButton = ({ paymentMethodType }) => {
        return (React.createElement(React.Fragment, null,
            React.createElement(S.PayPalButtonContainer, null,
                paymentMethodType === 'PAYPAL' && isSubmitting && (React.createElement(S.PayPalButtonOverlay, null,
                    React.createElement(Loader, { size: "large" }))),
                React.createElement("div", { id: PAYPAL_CONTAINER_ID })),
            paymentMethodType === 'STRIPE' && (React.createElement(Button, { label: submitLabel, appearance: "primary", type: "submit", loading: isSubmitting, disabled: isLoading }))));
    };
    return (React.createElement(Formik, { innerRef: formikRef, initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: onSubmit }, ({ values, handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(ModalBox, { title: title, buttons: [
                React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: onClose }),
                renderSubmitButton(values),
            ], footer: React.createElement(Icon, { component: SecureBadgeIcon, height: 20 }), onClose: onClose },
            isLoading && (React.createElement(S.LoaderWrapper, null,
                React.createElement(Loader, { size: "large" }))),
            renderSubtitle(),
            renderPaymentChoice(values),
            renderNewPayment(),
            renderTransactionLimitFormGroup(values))))));
};
