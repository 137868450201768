import { useMediaQueryContext, useModalContext } from '@fourthwall/components';
import { isMobile } from '@fourthwall/utils/lib/mobile';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateOfferMutation } from '@mutations';
import { CreateSelfOwnedProductModal } from '../CreateSelfOwnedProductModal';
import { TilesModal } from '../../../../../components/modals/TilesModal';
import designProductImage from './assets/design.png';
import selfOwnedProductImage from './assets/selfOwned.png';
import digitalProductImage from './assets/digital.png';
import { useAdminModeContext } from '../../../../App/providers/AdminModeProvider';
import { reverse, routing } from '../../../../../utils/routing';
export const CreateProductModal = () => {
    const { createOfferMutation } = useCreateOfferMutation({
        onSuccess: ({ id }) => {
            close();
            navigate(`${reverse(routing.products.all.product.self, { productId: id })}${location.search}`);
        },
    });
    const navigate = useNavigate();
    const adminMode = useAdminModeContext();
    const { tablet } = useMediaQueryContext();
    const { open, close } = useModalContext();
    const handleProductDesignClick = () => {
        const link = isMobile() || !tablet ? routing.external.productCatalog : routing.productCatalog.self;
        window.location.href = link;
        close();
    };
    const handleSelfOwnedProductClick = () => {
        const isUserAdmin = adminMode?.isActive;
        if (isUserAdmin) {
            close();
            open(CreateSelfOwnedProductModal);
        }
        else {
            createOfferMutation.mutate([
                {
                    name: 'New Product',
                    description: '',
                    price: { value: 30, currency: 'USD' },
                    weight: { value: 10, unit: 'oz' },
                    manufacturingService: 'MANUFACTURED_BY_CREATOR',
                    settings: { requiresShipping: true },
                },
            ]);
        }
    };
    const handleDigitalProductClick = () => {
        createOfferMutation.mutate([
            {
                name: 'New Digital Product',
                description: '',
                price: { value: 30, currency: 'USD' },
                weight: { value: 10, unit: 'oz' },
                manufacturingService: 'DIGITAL_ITEM',
                settings: { requiresShipping: false },
            },
        ]);
    };
    const items = [
        {
            image: designProductImage,
            imageAlt: 'Design something new',
            heading: 'Design something new',
            onClick: handleProductDesignClick,
        },
        {
            image: selfOwnedProductImage,
            imageAlt: 'Sell something I have',
            heading: 'Sell something I have',
            onClick: handleSelfOwnedProductClick,
        },
        {
            image: digitalProductImage,
            imageAlt: 'Sell digital product',
            heading: 'Sell digital product',
            onClick: handleDigitalProductClick,
        },
    ];
    return React.createElement(TilesModal, { isLoading: createOfferMutation.isLoading, items: items });
};
