import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
import { MOBILE_FOOTER_HEIGHT } from '../../const';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  background-color: ${Colors.GS1000};
  color: ${Colors.GS100};
`;
export const MainContent = styled.div `
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
`;
export const ContentHeader = styled.div `
  position: absolute;
  top: 0;
  width: 100%;
  padding: 20px 20px 0;
`;
export const ContentFooter = styled.div `
  position: absolute;
  bottom: 32px;
`;
export const Footer = styled.div `
  padding: 24px 24px 48px;
  height: ${MOBILE_FOOTER_HEIGHT}px;
`;
