import { ShopRendererApiClient } from '@fourthwall/services/lib/ShopRendererApiClient';
import { useMutation } from '@tanstack/react-query';
export const useUpdateSocialLinkMutation = (options) => {
    const updateSocialLinkMutation = useMutation({
        mutationFn: async (params) => {
            const { data } = await ShopRendererApiClient.updateSocialNetwork(...params);
            return data;
        },
        ...options,
    });
    return {
        updateSocialLinkMutation,
    };
};
