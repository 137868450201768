import { Button, ModalBox } from '@fourthwall/components';
import dayjs from 'dayjs';
import capitalize from 'lodash-es/capitalize';
import React from 'react';
import { DATE_FORMAT } from './consts';
export const StatusConfirmationModal = ({ newStatus = 'PUBLIC', startDate, endDate, onConfirm, close, }) => {
    const renderNewStatus = () => capitalize(newStatus);
    const renderStartDate = () => {
        return startDate ? React.createElement(React.Fragment, null,
            " from ",
            dayjs(startDate).format(DATE_FORMAT)) : null;
    };
    const renderEndDate = () => {
        return endDate ? React.createElement(React.Fragment, null,
            " to ",
            dayjs(endDate).format(DATE_FORMAT)) : null;
    };
    const handleConfirm = (withStatusUpdate) => {
        close();
        onConfirm(withStatusUpdate);
    };
    return (React.createElement(ModalBox, { title: React.createElement(React.Fragment, null,
            "Do you want to change the status of products in this collection to ",
            renderNewStatus(),
            renderStartDate(),
            renderEndDate(),
            "?"), buttons: [
            React.createElement(Button, { label: "Cancel", onClick: close }),
            React.createElement(Button, { label: "No, don't change", appearance: "primary", onClick: () => handleConfirm(false) }),
            React.createElement(Button, { label: "Yes, change", appearance: "primary", onClick: () => handleConfirm(true) }),
        ], onClose: close },
        React.createElement(React.Fragment, null,
            "Changing the status on a collection optionally updates the status of all products added to this collection. Do you want to change the status of all products in this collection to",
            ' ',
            renderNewStatus(),
            renderStartDate(),
            renderEndDate(),
            "?")));
};
