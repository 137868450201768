import React from 'react';
import { Icon, ButtonClean } from '@fourthwall/components';
import { TickIcon, QuestionIcon, CloseIcon } from '@fourthwall/icons';
import * as S from './styled';
const getIconComponent = (status) => {
    const icons = {
        approved: TickIcon,
        pending: QuestionIcon,
        rejected: CloseIcon,
    };
    return icons[status];
};
export const AccordionHeading = ({ status, label, onSeeDetails }) => (React.createElement(S.Container, null,
    React.createElement(S.IconContainer, null,
        React.createElement(S.Icon, { status: status },
            React.createElement(Icon, { component: getIconComponent(status), height: 10 }))),
    React.createElement(S.Label, null, label),
    onSeeDetails && (React.createElement(S.ButtonContainer, null,
        React.createElement(ButtonClean, { label: "See details", onClick: onSeeDetails })))));
