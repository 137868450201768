import { getNumberString, NumberString } from '@fourthwall/components';
import { AnalyticsChart, useMembershipsChartQuery, mapChartToSeries, getDateFormat, } from '@fourthwall/module-analytics';
import dayjs from 'dayjs';
import React from 'react';
import { useDashboardPermissions } from '../../../../../../../../../components/utils/DashboardPermissions';
import { reverse, routing } from '../../../../../../../../../utils/routing';
import { prepareSingleValueChart } from '../../../../../../../Analytics/hooks/useAnalytics/utils';
import { analyticsQueryParams } from '../../../../../../../Analytics/hooks/useAnalyticsParams/types';
import { getFrom, getPrecision, getRangeQueryParam, to } from '../../utils';
import { Chart } from '../Chart';
import { useCurrentShop } from '../../../../../../../hooks/useCurrentShop';
export const MembersChangeChart = ({ range }) => {
    const from = getFrom(range);
    const { hasPermission } = useDashboardPermissions();
    const { currentShop } = useCurrentShop({ staleTime: Infinity });
    const { membershipsChartQuery: membersChangeQuery } = useMembershipsChartQuery([
        {
            from,
            to,
            precision: getPrecision(range),
            chartTypes: ['MEMBERS_CHANGE'],
            sid: [currentShop?.id],
        },
    ]);
    const series = mapChartToSeries(prepareSingleValueChart(membersChangeQuery.data?.current.membersChange?.data), {
        from,
        to,
        precision: getPrecision(range),
    });
    const total = membersChangeQuery?.data?.current.membersChange?.aggregatedValue?.value || 0;
    const chartTo = hasPermission('analytics.membersChange')
        ? `${reverse(routing.analytics.report, {
            reportType: 'memberships-members-change',
        })}?${analyticsQueryParams.range}=${getRangeQueryParam(range)}`
        : undefined;
    return (React.createElement(Chart, { total: React.createElement(React.Fragment, null,
            total > 0 ? '+' : '',
            React.createElement(NumberString, { value: total })), label: "Members change", to: chartTo, isLoading: !membersChangeQuery.data || membersChangeQuery.isFetching },
        React.createElement(AnalyticsChart, { label: "Members change", series: series, height: 90, showAxes: false, formatDate: (date) => dayjs(date)
                .utc()
                .format(getDateFormat(getPrecision(range))), formatValue: getNumberString })));
};
