import styled from 'styled-components';
export const HeaderContainer = styled.div `
  margin-bottom: 40px;
`;
export const SectionDescription = styled.div `
  margin-bottom: 16px;

  p {
    margin-bottom: 8px;
  }

  ol {
    list-style: decimal;
    padding-inline-start: 1.5em;
    margin-bottom: 8px;
  }
`;
export const DescriptionWrapper = styled.div `
  margin-bottom: 16px;
  line-height: 150%;
`;
export const PercentageInputContainer = styled.div `
  margin-left: 36px;
`;
export const ErrorContainer = styled.div `
  margin-top: 16px;
  margin-left: 36px;
`;
export const SuggestionContainer = styled.div `
  margin-top: -12px;
`;
