import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Text = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
  line-height: 1.5;
`;
export const PreviewContainer = styled.div `
  max-width: 376px;
`;
