import { fixRounding } from '@fourthwall/utils/lib/number';
const getFreeTierImagePayload = (formValues, id) => {
    if (id)
        return { type: 'New', id };
    if (formValues.removeImage)
        return { type: 'Remove' };
    return { type: 'Keep' };
};
export const getFreeTierPayload = (formValues, newImageId) => {
    const { title, description } = formValues;
    return {
        name: title,
        description,
        imageResource: getFreeTierImagePayload(formValues, newImageId),
    };
};
export const getTierPayload = (formValues) => {
    const { title, description, image, price, discount, monthDiscount, yearDiscount, timeLimit, startDate, endDate, removeImage, } = formValues;
    const commonPayload = {
        name: title,
        description,
        image,
        monthlyAmount: fixRounding(parseFloat(price) * 100, 2),
        remove_image: removeImage,
    };
    const getTimeLimitPayload = () => {
        if (timeLimit && startDate && endDate) {
            return {
                timeLimited: {
                    startDate,
                    endDate,
                },
            };
        }
        return {
            timeLimited: undefined,
        };
    };
    const getDiscountPayload = () => {
        if (discount && monthDiscount) {
            return {
                discount: {
                    percentOff: {
                        monthly: parseInt(monthDiscount),
                        annual: yearDiscount ? parseInt(yearDiscount) : undefined,
                    },
                    ...getTimeLimitPayload(),
                },
            };
        }
        return {
            discount: undefined,
        };
    };
    return {
        ...commonPayload,
        ...getDiscountPayload(),
    };
};
