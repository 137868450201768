import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { useState } from 'react';
import { getImageDirectUploadData } from '../utils/getImageDirectUploadData';
import { useConfirmImageResourceMutation } from './useConfirmImageResourceMutation';
import { useCreateImageResourceMutation } from './useCreateImageResourceMutation';
import { useUploadImageMutation } from './useUploadImageMutation';
export const useCreateImageResources = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { createImageResourceMutation } = useCreateImageResourceMutation();
    const { uploadImageMutation } = useUploadImageMutation();
    const { confirmImageResourceMutation } = useConfirmImageResourceMutation();
    const createImageResources = async ({ files, onSuccess }) => {
        const imageResources = [];
        setIsLoading(true);
        await Promise.all(files.map(async (file) => {
            try {
                const payload = await getImageDirectUploadData(file);
                const { uploadData, resourceId } = await createImageResourceMutation.mutateAsync([
                    { ...payload },
                ]);
                await uploadImageMutation.mutateAsync({
                    file,
                    headers: uploadData.headers,
                    signedId: uploadData.signedId,
                    uploadUrl: uploadData.url,
                });
                await confirmImageResourceMutation.mutateAsync([
                    resourceId,
                    { signedId: uploadData.signedId },
                ]);
                const { data } = await MembershipsCreatorApiClient.getResources({
                    'ids[]': [resourceId],
                });
                imageResources.push({
                    url: data.resources[0].url,
                    resizedUrl: data.resources[0].resizedUrl,
                    id: resourceId,
                });
            }
            catch { }
        }));
        setIsLoading(false);
        onSuccess(imageResources);
    };
    return {
        createImageResources,
        isLoading,
    };
};
