import { ApiBase } from './ApiBase';
class YouTubeMerchantCenterApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getIntegrations = () => {
            return this.get('/merchant-center/youtube/integrations');
        };
        this.reconnect = (params) => {
            return this.put('/merchant-center/youtube/reconnect', undefined, params);
        };
        this.getProducts = () => {
            return this.get('/merchant-center/youtube/products');
        };
    }
}
export const YouTubeMerchantCenterApiClient = new YouTubeMerchantCenterApiClientModel();
