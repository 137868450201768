import { PRODUCT_PROFIT_MIN } from '@fourthwall/utils/src/constants';
import { useCurrentShopConfigQuery } from '../../hooks/useCurrentShopConfigQuery';
import { useShopPaymentMethods } from '../../PaymentMethods';
export const useMinimumProfit = () => {
    const { currentShopConfigQuery } = useCurrentShopConfigQuery();
    const { hasActivePaymentMethod } = useShopPaymentMethods();
    const isChargingUnlimited = currentShopConfigQuery.data?.chargePolicy.type === 'NO_LIMIT';
    return {
        minimumProfit: isChargingUnlimited && hasActivePaymentMethod ? undefined : PRODUCT_PROFIT_MIN,
    };
};
