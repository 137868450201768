import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../providers/MediaQueryProvider';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;

  > * {
    flex: 1;
    min-width: fit-content;
  }

  ${mediaQueries.tablet} {
    flex-direction: row;
    gap: 20px;
  }
`;
export const RecommendationButtonGroup = ({ children }) => {
    return React.createElement(Container, null, children);
};
