import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const Photo = styled.div `
  position: relative;
`;
export const Details = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
