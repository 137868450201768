import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;
export const LeftSide = styled.div `
  flex: 1;
  display: flex;
  gap: 12px;
`;
export const Details = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;
export const RightSide = styled.div `
  display: flex;
  align-items: center;
`;
