import { Fieldset, Input, Subsection } from '@fourthwall/components';
import { Field } from 'formik';
import React from 'react';
import { TinyMCE } from '../../../../../../../components/adapters/inputs/TinyMCE';
import { FormFields } from '../../types';
export const DetailsFields = () => (React.createElement(Subsection, { title: "Video series details" },
    React.createElement(Fieldset, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { name: FormFields.title, component: Input, label: "Series title", required: true, fullWidth: true }))),
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { name: FormFields.description, component: TinyMCE, placeholder: "Series description" }))))));
