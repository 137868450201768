import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
const sizes = {
    tiny: { height: 17, padding: 6, fontSize: 10 },
    small: { height: 24, padding: 8, fontSize: 11 },
    big: { height: 32, padding: 16, fontSize: 12 },
    large: { height: 40, padding: 12, fontSize: 11 },
};
const appearances = {
    neutral: {
        background: Colors.GS400,
        hoverBackground: Colors.GS500,
        color: Colors.GS700,
        hoverColor: '',
    },
    'neutral-outlined': {
        background: 'none',
        hoverBackground: 'none',
        color: Colors.GS600,
        hoverColor: Colors.GS1000,
    },
    success: {
        background: Colors.G100,
        hoverBackground: Colors.G200,
        color: Colors.G500,
        hoverColor: '',
    },
    caution: {
        background: Colors.Y100,
        hoverBackground: Colors.Y200,
        color: Colors.Y500,
        hoverColor: '',
    },
    alert: {
        background: Colors.R100,
        hoverBackground: Colors.R200,
        color: Colors.R500,
        hoverColor: '',
    },
    dark: {
        background: Colors.GS1000,
        hoverBackground: Colors.GS1000,
        color: Colors.GS100,
        hoverColor: '',
    },
};
export const Container = styled.div `
  display: ${({ display }) => display};
  align-items: center;
  justify-content: center;

  height: ${({ size }) => sizes[size].height}px;
  padding: 0 ${({ size }) => sizes[size].padding}px;
  background: ${({ appearance }) => appearances[appearance].background};
  border: 1px solid;
  border-color: ${({ appearance }) => appearances[appearance].background};
  border-radius: 32px;

  font-weight: 600;
  font-size: ${({ size }) => sizes[size].fontSize}px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${({ appearance }) => appearances[appearance].color};

  cursor: default;

  ${({ hoverable, appearance }) => hoverable &&
    css `
      &:hover {
        background: ${appearances[appearance].hoverBackground};

        color: ${appearances[appearance].hoverColor || appearances[appearance].color};

        cursor: pointer;
      }
    `}
`;
export const IconContainer = styled.div `
  margin-left: 7px;
`;
