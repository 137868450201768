import { Fieldset, Upload } from '@fourthwall/components';
import { fileToBase64 } from '@fourthwall/utils/lib/file';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React, { useState } from 'react';
import { RemovableUpload, UploadedImage } from '../../../../../components';
import { FormFields } from '../../types';
import * as S from './styled';
export const ImageFields = ({ imageSrc }) => {
    const [initialImage, setInitialImage] = useState(imageSrc);
    const { values, setFieldValue } = useFormikContext();
    const handleFileDrop = async (files) => {
        if (files?.[0]) {
            const base64 = await fileToBase64(files[0]);
            setFieldValue(FormFields.image, base64);
            setFieldValue(FormFields.removeImage, false);
        }
    };
    const renderUpload = () => {
        const image = values.image || initialImage;
        if (image) {
            return (React.createElement(RemovableUpload, { onRemove: () => {
                    setFieldValue(FormFields.image, '');
                    setInitialImage(undefined);
                    setFieldValue(FormFields.removeImage, true);
                } },
                React.createElement(UploadedImage, { image: image })));
        }
        return (React.createElement(Upload, { accept: {
                'image/*': [],
            }, iconSize: 20, orientation: "horizontal", title: "", onFileDrop: handleFileDrop }));
    };
    return (React.createElement(Fieldset.Row, null,
        React.createElement(Fieldset.Item, null,
            React.createElement(S.ImageInputContainer, null,
                React.createElement(S.UploadContainer, null, renderUpload()),
                "RSS image (optional)"))));
};
