export const generateTimeArray = (timezoneOffset) => {
    const timeArray = [];
    for (let i = 0; i < 24; i++) {
        const hour = i > 12 ? i - 12 : i;
        const period = i < 12 ? 'AM' : 'PM';
        const value = (i + timezoneOffset + 24) % 24;
        const label = `${i === 0 ? 12 : hour}:00 ${period}`;
        timeArray.push({ label, value: Math.floor(value).toString() });
    }
    return timeArray;
};
