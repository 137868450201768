import { getShopRendererApiUrl } from '@fourthwall/env';
import { ApiBase } from '../ApiBase';
class ShopRendererThemeSettingsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getThemeSettings = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/themes/${pathParams.theme_id}/settings`);
        };
        this.updateThemeSettings = (pathParams, requestBody) => {
            return this.patch(`/shops/${pathParams.shop_id}/themes/${pathParams.theme_id}/settings`, requestBody);
        };
        this.updateLogo = (pathParams, requestBody) => {
            return this.put(`/shops/${pathParams.shop_id}/themes/${pathParams.theme_id}/logo`, requestBody);
        };
        this.updateFavicon = (pathParams, requestBody) => {
            return this.put(`/shops/${pathParams.shop_id}/themes/${pathParams.theme_id}/favicon`, requestBody);
        };
        this.getFont = (pathParams) => {
            return this.get(`/themes/fonts/${pathParams.handle}`);
        };
        this.getFonts = (queryParams) => {
            return this.get('/themes/fonts', queryParams);
        };
        this.getFontTags = () => {
            return this.get('/themes/tags/fonts/');
        };
    }
}
export const ShopRendererThemeSettingsApiClient = new ShopRendererThemeSettingsApiClientModel(getShopRendererApiUrl('api'));
