import { createDashboardPermissions } from '../utils';
export const membershipsDisabledPermissions = createDashboardPermissions(true, {
    memberships: {
        setup: false,
        overview: false,
        post: {
            view: false,
            create: false,
        },
        messages: false,
        video: {
            view: false,
            create: false,
        },
        members: false,
        perksAndTiers: {
            createPerk: false,
            createTier: false,
        },
        settings: {
            general: false,
            messages: false,
            welcomePost: false,
            mobileApp: false,
            upselling: false,
        },
    },
});
