import { Dashboard, PageLayout } from '@components/common';
import { SECTION_NAMES } from '@constants/sectionNames';
import { BackButton, ButtonClean, HeadContent, Input, LeavingGuard, SaveShortcutListener, Subsection, } from '@fourthwall/components';
import { ConfirmBarContainer } from '@modules/Dashboard/ConfirmBar';
import { routing } from '@utils/routing';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as S from './styled';
export const AnalyticsTrackingPixelsView = ({ initialValues, onSubmit, }) => {
    const title = 'Tracking pixels';
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: title, titleTemplate: title }),
        React.createElement(PageLayout, null,
            React.createElement(Dashboard.ItemViewHeader, { name: title, backButton: React.createElement(BackButton, { label: SECTION_NAMES.analytics, to: routing.analytics.self }) }),
            React.createElement(Formik, { initialValues: initialValues, onSubmit: onSubmit, enableReinitialize: true }, ({ dirty, isSubmitting, submitForm, resetForm }) => (React.createElement(Form, null,
                React.createElement(Subsection, { title: "Google Analytics Pixel" },
                    React.createElement(S.SectionDescription, null,
                        "Google Analytics is a free analytics tool that gives deep insight into your website traffic.",
                        ' ',
                        React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.googleTrackingPixels, target: "_blank" })),
                    React.createElement(S.SectionInput, null,
                        React.createElement(Field, { component: Input, name: "googleAnalyticsTrackingId", label: "Google Analytics 4 Tracking ID", suggestion: "Code beginning with G- found at the end of the Google Analytics setup process." }))),
                React.createElement(Subsection, { title: "Facebook Pixel" },
                    React.createElement(S.SectionDescription, null,
                        "Facebook Pixel helps you track conversions and click through rates from Facebook and Instagram ads.",
                        ' ',
                        React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.metaTrackingPixels, target: "_blank" })),
                    React.createElement(S.SectionInput, null,
                        React.createElement(Field, { component: Input, name: "facebookPixelId", label: "Facebook Pixel ID" }))),
                React.createElement(Subsection, { title: "TikTok Pixel" },
                    React.createElement(S.SectionDescription, null, "TikTok Pixel helps you track conversions and click through rates from TikTok ads."),
                    React.createElement(S.SectionInput, null,
                        React.createElement(Field, { component: Input, name: "tiktokPixelId", label: "TikTok Pixel ID", suggestion: "Pixel ID found in Pixel code in the TikTok Pixel setup process." }))),
                React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
                React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isSubmitting, onConfirm: submitForm, onCancel: resetForm }),
                React.createElement(LeavingGuard, { when: dirty })))))));
};
