import { AlertModal, ConfirmModal, useModalContext } from '@fourthwall/components';
import React, { createContext, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { routing } from '@utils/routing';
import { useAccountSettingsQuery } from '../../Settings/hooks/useAccountSettingsQuery';
import { FREE_TIER_ID } from '../consts';
import { useDeleteFreeTierMutation } from '../hooks/useDeleteFreeTierMutation';
import { useDeleteTierMutation } from '../hooks/useDeleteTierMutation';
import { useFreeTierQuery } from '../hooks/useFreeTierQuery';
import { usePublishFreeTierMutation } from '../hooks/usePublishFreeTierMutation';
import { usePublishTierMutation } from '../hooks/usePublishTierMutation';
import { useReorderFreeTierMutation } from '../hooks/useReorderFreeTierMutation';
import { useReorderTierMutation } from '../hooks/useReorderTierMutation';
import { useTiersQuery } from '../hooks/useTiersQuery';
import { useUnpublishTierMutation } from '../hooks/useUnpublishTierMutation';
import { TierModal } from '../modules/TierModal';
export const TiersContext = createContext(undefined);
export const TiersProvider = ({ children }) => {
    const navigate = useNavigate();
    const modal = useModalContext();
    const { hasPermission } = useDashboardPermissions();
    const { tiersQuery } = useTiersQuery();
    const { freeTierQuery } = useFreeTierQuery();
    const { accountSettingsQuery } = useAccountSettingsQuery();
    const { publishFreeTierMutation } = usePublishFreeTierMutation();
    const { reorderFreeTierMutation } = useReorderFreeTierMutation();
    const { deleteFreeTierMutation } = useDeleteFreeTierMutation();
    const { publishTierMutation } = usePublishTierMutation();
    const { unpublishTierMutation } = useUnpublishTierMutation();
    const { reorderTierMutation } = useReorderTierMutation();
    const { deleteTierMutation } = useDeleteTierMutation();
    const isLoading = tiersQuery.isFetching ||
        freeTierQuery.isFetching ||
        accountSettingsQuery.isFetching ||
        publishTierMutation.isLoading ||
        publishFreeTierMutation.isLoading ||
        unpublishTierMutation.isLoading ||
        reorderTierMutation.isLoading ||
        reorderFreeTierMutation.isLoading ||
        deleteTierMutation.isLoading ||
        deleteFreeTierMutation.isLoading;
    const addTier = useCallback(() => {
        modal.open(TierModal, {
            onSuccess: () => {
                tiersQuery.refetch();
                freeTierQuery.refetch();
            },
        });
    }, [modal, tiersQuery, freeTierQuery]);
    const editTier = useCallback((tier) => {
        modal.open(TierModal, {
            tier,
            onSuccess: () => {
                tiersQuery.refetch();
                freeTierQuery.refetch();
            },
        });
    }, [modal, tiersQuery, freeTierQuery]);
    const editFreeTier = useCallback((tier) => {
        modal.open(TierModal, {
            tier,
            onSuccess: () => {
                tiersQuery.refetch();
                freeTierQuery.refetch();
            },
        });
    }, [modal, tiersQuery, freeTierQuery]);
    const publishTier = useCallback((tier) => {
        return modal.open(ConfirmModal, {
            title: 'Publish tier',
            text: 'This tier will become visible on your site and available for new members to subscribe.',
            confirmLabel: 'Publish tier',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                publishTierMutation.mutate([tier.id], {
                    onSuccess: () => {
                        tiersQuery.refetch();
                        freeTierQuery.refetch();
                    },
                });
            },
        });
    }, [modal, publishTierMutation, tiersQuery, freeTierQuery]);
    const publishFreeTier = useCallback(() => {
        publishFreeTierMutation.mutate([], {
            onSuccess: () => {
                tiersQuery.refetch();
                freeTierQuery.refetch();
            },
        });
    }, [freeTierQuery, publishFreeTierMutation, tiersQuery]);
    const unpublishTier = useCallback((tier) => {
        if (tier.mobileWlaEnabled) {
            return modal.open(AlertModal, {
                title: 'Unpublishing this tier is not possible',
                text: 'A tier available on mobile apps cannot be unpublished.',
                size: 'small',
            });
        }
        if (accountSettingsQuery.data?.upselling?.tierId === tier.id) {
            return modal.open(ConfirmModal, {
                title: 'Unpublish action unavailable',
                text: 'This tier cannot be unpublished because it’s set in the shop upselling promotion. Deactivate the promotion or change its tier to continue.',
                confirmLabel: 'Edit upselling',
                confirmAppearance: 'destructive',
                onConfirm: () => {
                    navigate(routing.memberships.settings.upselling);
                },
            });
        }
        return modal.open(ConfirmModal, {
            title: 'Unpublish tier',
            text: (React.createElement(React.Fragment, null,
                React.createElement("p", { style: { marginBottom: 10 } }, "Unpublishing will hide this tier from your site. No new members will be able to subscribe."),
                React.createElement("p", null, "Tier will remain active for currently subscribed members. You can republish the tier at any time."))),
            confirmLabel: 'Unpublish tier',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                unpublishTierMutation.mutate([tier.id], {
                    onSuccess: () => {
                        tiersQuery.refetch();
                        freeTierQuery.refetch();
                    },
                });
            },
        });
    }, [
        accountSettingsQuery.data?.upselling?.tierId,
        modal,
        navigate,
        tiersQuery,
        freeTierQuery,
        unpublishTierMutation,
    ]);
    const sortTier = useCallback((tier, newPosition) => {
        reorderTierMutation.mutate([tier.id, { position: newPosition }], {
            onSuccess: () => {
                tiersQuery.refetch();
                freeTierQuery.refetch();
            },
        });
    }, [reorderTierMutation, tiersQuery, freeTierQuery]);
    const sortFreeTier = useCallback((newPosition) => {
        reorderFreeTierMutation.mutate([{ position: newPosition }], {
            onSuccess: () => {
                tiersQuery.refetch();
                freeTierQuery.refetch();
            },
        });
    }, [reorderFreeTierMutation, freeTierQuery, tiersQuery]);
    const deleteTier = useCallback((tier) => {
        if (tier.statistics.membersCount > 0) {
            return modal.open(AlertModal, {
                title: 'Deleting this tier is not possible',
                text: 'A tier with active members cannot be deleted.',
                size: 'small',
            });
        }
        if (accountSettingsQuery.data?.upselling?.tierId === tier.id) {
            return modal.open(ConfirmModal, {
                title: 'Delete action unavailable',
                text: 'This tier cannot be deleted because it’s set in the shop upselling promotion. Deactivate the promotion or change its tier to continue.',
                confirmLabel: 'Edit upselling',
                confirmAppearance: 'destructive',
                onConfirm: () => {
                    navigate(routing.memberships.settings.upselling);
                },
            });
        }
        return modal.open(ConfirmModal, {
            title: 'Delete tier',
            text: 'Are you sure you want to delete this tier?',
            confirmLabel: 'Delete tier',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                deleteTierMutation.mutate([tier.id], {
                    onSuccess: () => {
                        tiersQuery.refetch();
                        freeTierQuery.refetch();
                    },
                });
            },
        });
    }, [
        accountSettingsQuery.data?.upselling?.tierId,
        deleteTierMutation,
        modal,
        navigate,
        tiersQuery,
        freeTierQuery,
    ]);
    const deleteFreeTier = useCallback(() => {
        return modal.open(ConfirmModal, {
            title: 'Delete tier',
            text: 'Are you sure you want to delete this tier? Free members will lose access to their perks, but will still have access to your members only area as free members.',
            confirmLabel: 'Delete tier',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                deleteFreeTierMutation.mutate([], {
                    onSuccess: () => {
                        tiersQuery.refetch();
                        freeTierQuery.refetch();
                    },
                });
            },
        });
    }, [modal, deleteFreeTierMutation, freeTierQuery, tiersQuery]);
    const tiers = useMemo(() => {
        if (!tiersQuery.data)
            return [];
        if (!freeTierQuery.data)
            return [];
        if (freeTierQuery.data.status === 'Hidden') {
            return tiersQuery.data.tiers.sort((a, b) => a.position - b.position);
        }
        // Note: Paid and free tier could have the same id, so we need to add a unique id to the free tier
        return [...tiersQuery.data.tiers, { ...freeTierQuery.data, id: FREE_TIER_ID }].sort((a, b) => a.position - b.position);
    }, [freeTierQuery.data, tiersQuery.data]);
    const value = useMemo(() => ({
        isLoading,
        tiers,
        addTier: hasPermission('memberships.perksAndTiers.createTier') ? addTier : undefined,
        editTier,
        editFreeTier,
        publishFreeTier,
        deleteFreeTier,
        publishTier,
        unpublishTier,
        sortTier,
        sortFreeTier,
        deleteTier,
    }), [
        isLoading,
        tiers,
        hasPermission,
        addTier,
        editFreeTier,
        editTier,
        publishTier,
        publishFreeTier,
        unpublishTier,
        sortTier,
        sortFreeTier,
        deleteTier,
        deleteFreeTier,
    ]);
    return React.createElement(TiersContext.Provider, { value: value }, children);
};
