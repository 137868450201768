var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => reject(error);
    });
};
export const base64ToFile = (base64_1, ...args_1) => __awaiter(void 0, [base64_1, ...args_1], void 0, function* (base64, fileName = '', type = 'image/jpeg') {
    const data = yield fetch(base64);
    const blobData = yield data.blob();
    return new File([blobData], fileName, { type });
});
export const downloadBlob = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
};
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0)
        return '0 Bytes';
    const baseUnit = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const metric = Math.floor(Math.log(bytes) / Math.log(baseUnit));
    return `${parseFloat((bytes / Math.pow(baseUnit, metric)).toFixed(decimals))} ${sizes[metric]}`;
};
export const getFileImageDimensions = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    yield img.decode();
    const width = img.width;
    const height = img.height;
    return {
        width,
        height,
    };
});
