import { useModalContext } from '@fourthwall/components';
import { ApparelIcon, PostIcon, TagIcon } from '@fourthwall/icons';
import { reverse, routing } from '@utils/routing';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { CreateProductModal } from '../../Products';
import { CreatePromotionModal } from '../../Promotions/components/CreatePromotionModal';
export const useQuickActions = () => {
    const { open } = useModalContext();
    const { hasPermission } = useDashboardPermissions();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const promotionsPermissions = {
        shopPromoCodes: hasPermission('promotions.shopPromoCodes'),
        membershipsPromoCodes: dashboardFeatureFlags.memberships.enabled &&
            hasPermission('promotions.membershipsPromoCodes'),
        giveawayLinks: hasPermission('promotions.giveawayLinks'),
    };
    const promotionsPermissionsCount = Object.values(promotionsPermissions).filter(Boolean).length;
    const shouldOpenPromotionsModal = promotionsPermissionsCount > 1;
    const handleCreateProductClick = () => open(CreateProductModal);
    const handleLaunchPromotionClick = () => open(CreatePromotionModal);
    const getPromotionLink = () => {
        if (!shouldOpenPromotionsModal) {
            if (promotionsPermissions.shopPromoCodes) {
                return reverse(routing.promotions.promotionCodes.create.self);
            }
            if (promotionsPermissions.membershipsPromoCodes) {
                return reverse(routing.promotions.promotionCodes.createMemberships.self);
            }
            if (promotionsPermissions.giveawayLinks) {
                return reverse(routing.promotions.giveawayPackages.create.self);
            }
        }
    };
    const getDropdownItems = () => {
        const dropdownItems = [
            {
                enabled: dashboardFeatureFlags.memberships.enabled && hasPermission('memberships.post.create'),
                title: 'Add post',
                icon: PostIcon,
                subtitle: 'Create more content to engage with your members!',
                to: reverse(routing.memberships.posts.create),
            },
            {
                enabled: hasPermission('products.create'),
                title: 'Create product',
                icon: ApparelIcon,
                subtitle: 'Design a new product or list something you own.',
                onClick: handleCreateProductClick,
            },
            {
                enabled: !!promotionsPermissionsCount,
                title: 'Launch promotion',
                icon: TagIcon,
                subtitle: 'Setup promotions to increase your sales.',
                to: getPromotionLink(),
                onClick: shouldOpenPromotionsModal ? handleLaunchPromotionClick : undefined,
            },
        ];
        return dropdownItems.filter((dropdownItems) => dropdownItems.enabled);
    };
    return {
        dropdownItems: getDropdownItems(),
    };
};
