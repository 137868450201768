import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  margin-bottom: 40px;

  button {
    margin-right: 16px;
  }

  button:last-child {
    margin-right: 0;
  }
`;
