import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
import { IconButton } from './components/IconButton';
export const VideoElement = styled.video `
  width: 100%;
  height: 100%;
`;
export const AudioContainer = styled.div `
  gap: 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;
export const UploadMediaWrapper = styled.div `
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  opacity: 1;
  transition: opacity 0.3s;

  ${({ hidden }) => hidden &&
    css `
      opacity: 0;
      pointer-events: none;
    `};
`;
export const AudioElement = styled.audio `
  max-width: 500px;
  width: 100%;
  padding: 0 20px;
`;
export const LoaderContainer = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${mediaQueries.laptop} {
    top: 50%;
  }
`;
export const HeaderTabs = styled.div `
  display: flex;
  gap: 8px;
`;
export const StartRecordingButtonIcon = styled.div `
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${Colors.GS0};
`;
export const StopRecordButtonIcon = styled.div `
  width: 12px;
  height: 12px;
  background-color: ${Colors.R300};
`;
export const Timer = styled.div `
  font-size: 48px;
  line-height: 52px;
  color: ${Colors.GS0};
  font-variant-numeric: tabular-nums;
  transition: opacity 0.3s;
  pointer-events: none;
  transform-origin: center;

  ${({ small }) => small &&
    css `
      font-size: 24px;
      line-height: 32px;
    `};

  ${({ visible }) => !visible &&
    css `
      opacity: 0;
    `};
`;
export const FooterTimer = styled.div `
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
  color: ${Colors.GS100};
`;
export const FooterError = styled.div `
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.R300};
`;
export const MediaSourceButtons = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
export const ContentFooterContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;
export const SwitchCameraButton = styled(IconButton) `
  position: absolute;
  left: calc(100% + 40px);
  padding: 10px;
  top: calc(50% - 18px);
`;
export const ContentHeaderContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const ContentHeaderDetails = styled.div `
  opacity: 1;
  transition: opacity 0.3s;

  ${({ hidden }) => hidden &&
    css `
      opacity: 0;
      pointer-events: none;
    `};
`;
export const TimerWrapper = styled.div `
  position: absolute;
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
`;
