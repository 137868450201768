import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
import { THEME_EDITOR_STYLED_CONSTS } from './consts';
export const Sidebar = styled.aside `
  display: none;

  ${mediaQueries.laptop} {
    display: block;
    grid-area: aside;
    background-color: ${Colors.GS0};
    position: relative;
  }
`;
export const BottomSheetWrapper = styled.div `
  ${mediaQueries.laptop} {
    display: none;
  }
`;
export const SidebarInner = styled.div `
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, auto);
  height: 100%;
`;
export const SidebarLogo = styled.div `
  display: none;

  ${mediaQueries.laptop} {
    display: block;
  }
`;
export const Logo = styled.div `
  display: flex;
  align-items: center;
  height: 52px;

  ${mediaQueries.laptop} {
    height: 58px;
    padding: 0 24px;
  }
`;
export const Content = styled.main `
  flex: 1;
  position: relative;
  overflow: hidden;
  grid-area: main;
  background-color: ${Colors.GS200};
`;
export const Navbar = styled.div `
  height: ${THEME_EDITOR_STYLED_CONSTS.NAVBAR_HEIGHT}px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-color: ${Colors.GS100};
  border-bottom: 1px solid ${Colors.GS400};
  box-sizing: border-box;

  ${mediaQueries.laptop} {
    display: none;
  }
`;
export const NavbarContent = styled.div `
  flex: 1;
  min-width: 0;
`;
export const Main = styled.div `
  position: absolute;
  height: calc(
    100vh - ${THEME_EDITOR_STYLED_CONSTS.NAVBAR_HEIGHT}px -
      ${THEME_EDITOR_STYLED_CONSTS.BOTTOMSHEET_BOTTOM_OFFSET}px
  );
  top: ${THEME_EDITOR_STYLED_CONSTS.NAVBAR_HEIGHT}px;
  bottom: ${THEME_EDITOR_STYLED_CONSTS.BOTTOMSHEET_BOTTOM_OFFSET}px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;

  ${mediaQueries.laptop} {
    position: initial;
    display: grid;
    grid-template-areas:
      'aside main'
      'aside main';
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: 360px minmax(0, 1fr);
    height: 100vh;
  }
`;
