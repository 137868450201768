import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  margin-bottom: 8px;
`;
export const Content = styled.div `
  display: flex;
`;
export const Title = styled.div `
  min-width: 0; // needed for text-overflow to work

  display: flex;
  align-items: center;

  flex: 1;
`;
export const TitleText = styled.div `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Subtitle = styled.div `
  color: ${Colors.GS600};
`;
export const Cost = styled.div `
  width: 100px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  text-align: right;

  ${({ fluid }) => fluid &&
    css `
      width: auto;
    `}
`;
export const OverriddenCost = styled.span `
  display: inline-block;
  margin-right: 8px;

  text-decoration: line-through;
`;
export const Quantity = styled.div `
  width: 50px;

  text-align: right;
`;
export const TooltipWrapper = styled.div `
  margin-left: 6px;

  text-align: left;
`;
export const ItemsContainer = styled.div `
  margin-left: 24px;
`;
export const Item = styled.div `
  margin-top: 8px;
`;
export const ItemContent = styled.div `
  display: flex;
`;
export const Summary = styled.div `
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${Colors.GS400};

  font-weight: bold;
`;
