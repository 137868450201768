export const DASHBOARD_STYLED_CONSTS = {
    NAVBAR_HEIGHT: 48,
    HEADER_HEIGHT: 64,
    SIDEBAR_WIDTH: 224,
    SIDEBAR_PADDING: '22px 24px',
    CONTENT_PADDING_HORIZONTAL: 20,
    CONTENT_PADDING_TOP: 80,
    CONTENT_PADDING_BOTTOM: 20,
    CONTENT_PADDING_HORIZONTAL_LAPTOP: 48,
};
