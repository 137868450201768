import { Banner, ButtonClean, Checkbox, CurrencyInput, Fieldset, HeadContent, InputUI, LeavingGuard, Loading, Select, Subsection, } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { routing } from '../../../../../../../utils/routing';
import { ConfirmBarContainer } from '../../../../../ConfirmBar/ConfirmBarContainer';
import { useTiers } from '../../../Perks/hooks/useTiers';
import { useAccountSettingsQuery } from '../../hooks/useAccountSettingsQuery';
import { useUpdateAccountSettingsUpsellingsMutation } from '../../hooks/useUpdateAccountSettingsUpsellingsMutation';
import { validationSchema } from './consts';
import { FormFields } from './types';
import { getUpdateAccountSettingsUpsellingsPayload } from './utils';
export const Upselling = () => {
    const { accountSettingsQuery } = useAccountSettingsQuery();
    const { updateAccountSettingsUpsellingsMutation } = useUpdateAccountSettingsUpsellingsMutation();
    const { tiersQuery, tiers } = useTiers();
    const formik = useFormik({
        initialValues: {
            enabled: !!accountSettingsQuery.data?.upselling,
            tier: accountSettingsQuery.data?.upselling
                ? accountSettingsQuery.data?.upselling.tierId.toString()
                : undefined,
            value: accountSettingsQuery.data?.upselling
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                    accountSettingsQuery.data?.upselling?.minimumOrderValue / 100
                : undefined,
        },
        validationSchema,
        onSubmit: (values) => {
            updateAccountSettingsUpsellingsMutation.mutate([getUpdateAccountSettingsUpsellingsPayload(values)], {
                onSuccess: () => {
                    accountSettingsQuery.refetch();
                },
            });
        },
        enableReinitialize: true,
    });
    const isLoading = tiersQuery.isLoading ||
        accountSettingsQuery.isLoading ||
        updateAccountSettingsUpsellingsMutation.isLoading;
    const getSelectOptions = () => {
        if (!tiers)
            return [];
        const options = tiers.map((tier) => ({
            key: tier.id.toString(),
            value: tier.id.toString(),
            label: tier.title,
        }));
        return options;
    };
    const renderContent = () => {
        if (!tiers && !tiersQuery.isLoading) {
            return (React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Banner, { title: React.createElement(React.Fragment, null,
                            "You need to have ",
                            React.createElement(Link, { to: routing.memberships.perks }, "membership tiers"),
                            ' ',
                            "created to enable this offer."), appearance: "warning" }))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Checkbox, { name: FormFields.enabled, value: formik.values.enabled, label: "Offer free month of memberships with shop purchase", onChange: formik.handleChange }))),
            formik.values.enabled && (React.createElement(React.Fragment, null,
                React.createElement(Fieldset.Row, { inner: true },
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Select, { name: FormFields.tier, label: "Membership tier", options: getSelectOptions(), value: formik.values.tier, onChange: formik.handleChange }),
                        formik.errors.tier && React.createElement(InputUI.Error, null, formik.errors.tier)),
                    React.createElement(Fieldset.Item, null,
                        React.createElement(CurrencyInput, { name: FormFields.value, value: formik.values.value?.toString(), label: "Minimum order value", onChange: formik.handleChange }),
                        formik.errors.value && React.createElement(InputUI.Error, null, formik.errors.value))),
                React.createElement(Fieldset.Row, { inner: true },
                    React.createElement(InputUI.Suggestion, { withoutMargin: true },
                        "Combine this offer with members-only discounts to boost sales.",
                        React.createElement("br", null),
                        React.createElement(ButtonClean, { label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/20770026695323#:~:text=Members%2Donly%20discounts", appearance: "secondary", target: "_blank", withUnderline: true })))))));
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(HeadContent, { title: "Shop upselling" }),
        React.createElement(Loading, { isLoading: isLoading },
            React.createElement(Subsection, { title: "Offer free month of memberships with shop purchase" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            "When your supporters spend a certain amount at your shop, they will be presented with the option to add free month of membership during checkout. Promotion applies only to new members.",
                            ' ',
                            React.createElement(ButtonClean, { label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/20770026695323", target: "_blank", withUnderline: true }))),
                    renderContent()))),
        React.createElement(LeavingGuard, { when: formik.dirty || isLoading }),
        React.createElement(ConfirmBarContainer, { isOpen: formik.dirty, isLoading: isLoading, onConfirm: formik.submitForm, onCancel: formik.resetForm })));
};
