import { Loader, ModalBox, Toasts, useModalContext } from '@fourthwall/components';
import React from 'react';
import { CostsBreakdown } from '@components/common/CostsBreakdown';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import axios from 'axios';
import { useCostsBreakdownQuery } from './hooks/useCostsBreakdownQuery';
export const DonationCostsBreakdownModal = ({ donationId }) => {
    const { close } = useModalContext();
    const { donationCostsBreakdownQuery } = useCostsBreakdownQuery([donationId], {
        onError: (error) => {
            if (axios.isAxiosError(error)) {
                const errorMessage = errorMessageSelector(error.response?.data, 'Failed to fetch costs breakdown. Please try again.');
                Toasts.notify(errorMessage, { type: 'error' });
            }
            close();
        },
    });
    const renderContent = () => {
        if (!donationCostsBreakdownQuery.data) {
            return (React.createElement("div", { style: { height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                React.createElement(Loader, { size: "large" })));
        }
        return React.createElement(CostsBreakdown, { costsBreakdown: donationCostsBreakdownQuery.data });
    };
    return (React.createElement(ModalBox, { title: "Costs breakdown", onClose: close }, renderContent()));
};
