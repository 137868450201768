import React from 'react';
import { getProfitCopy } from './utils';
import { Colors } from '../../colors';
export const ProfitValue = ({ profit }) => {
    const color = {
        positive: 'inherit',
        negative: Colors.R300,
    }[profit < 0 ? 'negative' : 'positive'];
    return (React.createElement("span", { style: { color }, "data-testid": "ProfitValue" }, getProfitCopy(profit)));
};
