import React from 'react';
import { withFormikSupport } from '../../hocs/withFormikSupport';
import * as S from './styled';
export const SwitchComponent = ({ name, id, label, description, value, disabled, side = 'left', size = 'medium', onBlur, onChange, }) => {
    return (React.createElement(S.Container, { disabled: disabled, side: side, checked: !!value, size: size, "data-testid": "Switch" },
        React.createElement("div", null,
            React.createElement("input", { type: "checkbox", id: id, hidden: true, name: name, value: value?.toString(), checked: !!value, disabled: disabled, onChange: onChange, onBlur: onBlur }),
            React.createElement(S.Track, { size: size },
                React.createElement(S.Handle, { size: size }))),
        React.createElement("div", null,
            label && React.createElement(S.Label, null, label),
            description && React.createElement(S.Description, null, description))));
};
export const Switch = withFormikSupport(SwitchComponent);
