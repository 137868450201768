import { ApiBase } from './ApiBase';
class GiveawayConfigApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getGiveawayConfig = () => {
            return this.get('/giveaway/config');
        };
        this.updateGiveawayConfig = (payload) => {
            return this.put('/giveaway/config', payload);
        };
        this.getGiveawayConfigOAuthConnections = () => {
            return this.get('/giveaway/config/oauth-connections');
        };
        this.getGiveawayConfigStream = () => {
            return this.get('/giveaway/config/stream');
        };
    }
}
export const GiveawayConfigApiClient = new GiveawayConfigApiClientModel();
