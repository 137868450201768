import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
  height: 100%;
  border: 1px solid ${Colors.GS400};
  background-color: ${Colors.GS0};
`;
export const TopContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 0px 16px;
`;
export const Options = styled.div `
  display: flex;
`;
export const Option = styled.button `
  display: flex;

  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  color: ${Colors.GS600};
  background-color: ${Colors.GS0};

  &:hover {
    color: ${Colors.GS1000};
    background-color: ${Colors.GS200};
  }
`;
export const InfoContainer = styled.div `
  display: flex;

  flex-direction: column;
  align-items: center;

  border-bottom: 1px solid ${Colors.GS400};
  padding: 12px 20px 20px;

  text-align: center;
`;
export const Image = styled.img `
  margin-bottom: 12px;

  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 128px;
`;
export const Name = styled.div `
  color: ${Colors.GS1000};
  line-height: 1.5;
`;
export const Price = styled.div `
  font-weight: 700;
  line-height: 1.5;
  color: ${Colors.GS1000};
`;
export const Description = styled.div `
  margin-top: 4px;
  line-height: 1.5;
  font-size: 14px;
  color: ${Colors.GS600};
`;
export const MembersCount = styled.div `
  margin-top: 4px;
  line-height: 1.5;
  font-size: 14px;
  color: ${Colors.GS600};
`;
export const PerksContainer = styled.div `
  padding: 8px 0;
  min-height: 48px;
`;
export const AddPerkWrapper = styled.div `
  padding: 12px 24px;
`;
