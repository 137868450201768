import { reverse } from 'named-urls';
import { routing } from '@utils/routing';
export const getShippingGroupLink = (id) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('selfFulfilled', '1');
    const pathname = id
        ? reverse(routing.settings.shippingGroup.self, { shippingGroupId: id })
        : routing.settings.shippingGroup.create;
    return {
        pathname,
        search: searchParams.toString(),
    };
};
