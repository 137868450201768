import React, { useEffect, useRef } from 'react';
const MULTIPLIER = 3;
export const CanvasImage = ({ src, width, height }) => {
    const canvasEl = useRef(null);
    // NOTE: https://userscripts-mirror.org/scripts/review/80588
    useEffect(() => {
        const img = document.createElement('img');
        img.onload = () => {
            if (!canvasEl.current) {
                return;
            }
            const ratio = height / width;
            const ctx = canvasEl.current.getContext('2d');
            if (ctx) {
                ctx.imageSmoothingEnabled = false;
                ctx.drawImage(img, 0, 0, img.width, img.width * ratio, 0, 0, width * MULTIPLIER, height * MULTIPLIER);
            }
        };
        img.src = src;
    }, [src, width, height]);
    return React.createElement("canvas", { ref: canvasEl, width: width * MULTIPLIER, height: height * MULTIPLIER });
};
