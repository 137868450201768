import React from 'react';
import { Button, ButtonGroup, CurrencyInput, ModalBox, useModalContext, } from '@fourthwall/components';
import { Field, Form, Formik } from 'formik';
import { useMinimumProfit } from '@modules/Dashboard/Product/hooks/useMinimumProfit';
import { getValidationSchema } from './validationSchema';
import * as S from './styled';
export const SizeVariantSellingPriceModal = ({ offerVariant, onSubmit, isCompareAtPriceEnabled, }) => {
    const { close } = useModalContext();
    const { minimumProfit } = useMinimumProfit();
    const handleOnSubmit = (values) => {
        onSubmit(values);
        close();
    };
    return (React.createElement(ModalBox, { title: `Edit ${offerVariant.size} selling price`, onClose: close },
        React.createElement(Formik, { initialValues: {
                price: offerVariant.price,
                compareAtPrice: offerVariant.compareAtPrice,
            }, validationSchema: getValidationSchema({
                minimumProfit,
                variantCost: offerVariant.cost?.value,
            }), onSubmit: handleOnSubmit }, () => (React.createElement(Form, null,
            React.createElement(S.Container, null,
                React.createElement(S.Content, null,
                    React.createElement(Field, { component: CurrencyInput, label: "Selling price", name: "price.value", type: "number", size: "large", hideSpinner: true }),
                    isCompareAtPriceEnabled && (React.createElement(Field, { name: "compareAtPrice.value", label: "Compare-at price (optional)", component: CurrencyInput, type: "number", size: "large", strikethrough: true }))),
                React.createElement(ButtonGroup, { direction: "column", fullWidth: true, space: "medium" },
                    React.createElement(Button, { type: "submit", appearance: "primary", label: "Update", fullWidth: true }),
                    React.createElement(Button, { appearance: "secondary", label: "Cancel", fullWidth: true, onClick: close }))))))));
};
