import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { getCountryNameByCode } from '@fourthwall/utils/lib/inputs';
import React from 'react';
import { FixedShippingView } from './FixedShippingView';
export const FixedShippingContainer = () => {
    const formik = useFormikContext();
    if (formik.values.pricingMethod !== 'Fixed') {
        return null;
    }
    const domesticCountryCode = formik.values.shippingLocation?.country || 'US';
    return (React.createElement(FixedShippingView, { domesticRatesSubtitle: getCountryNameByCode(domesticCountryCode) || 'United States' }));
};
