import { SuspenseFallback } from '@fourthwall/components';
import React, { createContext, useContext, useMemo } from 'react';
import { useCurrentShopContext } from '../hooks/useCurrentShopContext';
import { useShopQuery } from '../hooks/useShopQuery';
export const ShopContext = createContext(undefined);
export const ShopProvider = ({ children }) => {
    const { currentShop } = useCurrentShopContext();
    const { shopQuery } = useShopQuery([{ shop_id: currentShop.id }]);
    const value = useMemo(() => {
        if (!shopQuery.data || shopQuery.isError) {
            return undefined;
        }
        return { shop: shopQuery.data };
    }, [shopQuery]);
    if (!value) {
        return React.createElement(SuspenseFallback, null);
    }
    return React.createElement(ShopContext.Provider, { value: value }, children);
};
export const useShopContext = () => {
    const shopContext = useContext(ShopContext);
    if (shopContext === undefined) {
        throw new Error('shopContext must be used within a ShopProvider');
    }
    return shopContext;
};
