import tinycolor from 'tinycolor2';
export const isLight = (color) => tinycolor(color).getLuminance() > 0.85;
export const isReadabilityGood = (color1, color2, breakpoint = 4) => {
    return tinycolor.readability(color1, color2) > breakpoint;
};
export const generateColorFromText = (text) => {
    const getHash = (text) => {
        return text
            .split('')
            .reduce((previous, current) => Math.abs(Math.sin(current.charCodeAt(0) + previous) * 360), 0);
    };
    return `hsl(${[getHash(text), '40%', '50%'].join()})`;
};
