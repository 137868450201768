import React from 'react';
import { Icon } from '../Icon';
import * as S from './PaymentProvidersIcons.styled';
import { getIcons } from './utils';
export const PaymentProvidersIcons = ({ type, size = 'normal' }) => {
    const isNative = (name) => name === 'ApplePay' || name === 'GooglePay';
    const getHeight = () => {
        if (size === 'small') {
            return 28;
        }
        return 32;
    };
    return (React.createElement(S.Container, null, getIcons(type).map(({ name, icon: IconComponent }) => (React.createElement(S.ItemContainer, { key: name, native: isNative(name) },
        React.createElement(Icon, { component: IconComponent, height: getHeight(), alt: name }))))));
};
