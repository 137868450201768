import { getCommonPerkPayload } from './getCommonPerkPayload';
export const getCreateRssPerkPayload = (formValues) => {
    const commonPayload = getCommonPerkPayload(formValues);
    const getTags = () => {
        if (formValues.rssType === 'all') {
            return [];
        }
        return formValues.rssTags.map((tag) => {
            return {
                id: tag.id,
            };
        });
    };
    return {
        ...commonPayload,
        tags: getTags(),
        image: formValues.image,
        hidden_for_tiers: formValues.hidden,
    };
};
