import React from 'react';
import { DateString, PriceString, ProfitString } from '@fourthwall/components';
import { transactionTypeTexts } from '../../../../constants';
import * as S from './styled';
import { TransactionIdLink } from '../TransactionIdLink';
export const TransactionsListItem = ({ transactionItem }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.LeftSide, null,
            React.createElement("div", null, transactionTypeTexts[transactionItem.type]),
            React.createElement(TransactionIdLink, { transaction: transactionItem }),
            React.createElement(S.Date, null,
                React.createElement(DateString, { date: transactionItem.transactionAt, format: "MMM DD, YYYY h:mm A" }))),
        React.createElement(S.RightSide, null,
            React.createElement(S.PriceWrapper, null, transactionItem.type === 'CREATOR_CHARGE' ? (React.createElement(PriceString, { value: transactionItem.value.value })) : (React.createElement(ProfitString, { value: transactionItem.value.value }))),
            React.createElement(S.SecondaryPriceWrapper, null,
                React.createElement(PriceString, { value: transactionItem.balance.value })))));
};
