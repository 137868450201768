import { routing } from '../../../../../../utils/routing';
export const getBackUrl = (scheduleStatus) => {
    if (scheduleStatus === 'Future') {
        return {
            pathname: routing.memberships.posts.scheduled.all,
            search: location.search,
        };
    }
    return {
        pathname: routing.memberships.posts.self,
        search: location.search,
    };
};
