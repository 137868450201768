import { createDashboardPermissions } from '../utils';
export const membershipsEditorPermissions = createDashboardPermissions(false, {
    memberships: {
        setup: false,
        overview: false,
        post: {
            view: true,
            create: true,
        },
        messages: true,
        video: {
            view: true,
            create: true,
        },
        members: false,
        perksAndTiers: {
            createPerk: false,
            createTier: false,
        },
        settings: {
            general: false,
            messages: false,
            welcomePost: false,
            mobileApp: false,
            upselling: false,
        },
    },
});
