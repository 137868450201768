import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { useMutation } from '@tanstack/react-query';
export const useDeleteKlaviyoMutation = () => {
    const deleteKlaviyoMutation = useMutation({
        mutationFn: async () => {
            const { data } = await CreatorIntegrationsApiClient.deleteCreatorIntegration({
                app: 'klaviyo',
            });
            return data;
        },
    });
    return { deleteKlaviyoMutation };
};
