import React from 'react';
import { WebViewRemover } from '@fourthwall/components';
import { BottomSheet } from '../BottomSheet';
import * as S from './styled';
export const ThemeEditorLayout = ({ logo, editorPanel, navbarContent, content, }) => (React.createElement("div", null,
    React.createElement(S.Navbar, null,
        React.createElement(WebViewRemover, null,
            React.createElement(S.Logo, null, logo)),
        navbarContent && React.createElement(S.NavbarContent, null, navbarContent)),
    React.createElement(S.Main, null,
        React.createElement(S.Sidebar, null,
            React.createElement(S.SidebarInner, null,
                React.createElement(S.SidebarLogo, null,
                    React.createElement(S.Logo, null, logo)),
                React.createElement("div", null, editorPanel))),
        React.createElement(S.Content, null, content),
        React.createElement(S.BottomSheetWrapper, null,
            React.createElement(BottomSheet, null, editorPanel)))));
