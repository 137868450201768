import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { mediaQueries } from '../../providers/MediaQueryProvider';
export const Container = styled.div `
  max-width: 100%;
`;
export const StateContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
export const ListItem = styled.div `
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.GS400};

  ${({ topBorder }) => topBorder &&
    css `
      &:first-of-type {
        border-top: 1px solid ${Colors.GS400};
      }
    `}

  ${mediaQueries.laptop} {
    padding: 12px 0;
  }

  ${({ highlighted }) => highlighted &&
    css `
      background: linear-gradient(
        270deg,
        rgba(0, 87, 255, 0) 0%,
        rgba(0, 87, 255, 0.08) 30.21%,
        rgba(0, 87, 255, 0.08) 68.75%,
        rgba(0, 87, 255, 0) 100%
      );
    `};
`;
