import { ACTION_ICON_SIZE, APPEND_MARGIN, ICON_MARGIN, ICON_SIZE, INPUT_PADDING, PREPEND_MARGIN, } from './styled';
const InputUIConsts = {
    INPUT_PADDING,
    PREPEND_MARGIN,
    APPEND_MARGIN,
    ICON_SIZE,
    ICON_MARGIN,
    ACTION_ICON_SIZE,
};
export * as InputUI from './InputUI';
export { InputUIConsts };
