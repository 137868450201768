import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  padding-top: 56.25%; /* 16:9 Aspect Ratio */

  .plyr {
    --plyr-color-main: ${Colors.B500};

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: inherit;

    input[type='range'] {
      cursor: pointer;
    }
  }
`;
export const Video = styled.video `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: inherit;
  width: 100%;
  height: 100%;
`;
