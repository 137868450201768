import axios from 'axios';
import { ApiBase } from '../ApiBase';
class MembershipsCreatorApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getMembershipsStatus = () => {
            return this.get('/creators/api/v1/accounts/account');
        };
        // Integrations
        this.getDiscordIntegration = () => {
            return this.get('/creators/api/v1/integrations/discord');
        };
        this.createDiscordIntegration = () => {
            return this.get('/creators/api/v1/integrations/discord/init');
        };
        this.getDiscordRoles = () => {
            return this.get('/creators/api/v1/integrations/discord/roles');
        };
        this.deleteDiscordIntegration = () => {
            return this.delete('/creators/api/v1/integrations/discord');
        };
        // Members
        this.getMembers = (params) => {
            return this.get('/creators/api/v1/members', params);
        };
        this.getMember = (id) => {
            return this.get(`/creators/api/v1/members/${id}`);
        };
        this.getMembersData = () => {
            return this.get('/creators/api/v1/reports/supporters/csv');
        };
        this.shadowBanMember = (params) => {
            return this.post(`/creators/api/v1/members/${params.member_id}/shadow_ban`);
        };
        this.removeMemberShadowBan = (params) => {
            return this.delete(`/creators/api/v1/members/${params.member_id}/shadow_ban`);
        };
        this.getMemberExitSurveys = (params) => {
            return this.get(`/creators/api/v1/members/${params.member_id}/exit_survey`);
        };
        // Tiers
        this.getTiers = () => {
            return this.get('/creators/api/v1/tiers');
        };
        this.createTier = (payload) => {
            return this.post('/creators/api/v1/tiers', payload);
        };
        this.updateTier = (id, payload) => {
            return this.put(`/creators/api/v1/tiers/${id}`, payload);
        };
        this.reorderTier = (id, payload) => {
            return this.post(`/creators/api/v1/tiers/${id}/reorder`, payload);
        };
        this.deleteTier = (id) => {
            return this.delete(`/creators/api/v1/tiers/${id}`);
        };
        this.publishTier = (id) => {
            return this.post(`/creators/api/v1/tiers/${id}/publish`);
        };
        this.unpublishTier = (id) => {
            return this.post(`/creators/api/v1/tiers/${id}/unpublish`);
        };
        // Free tier
        this.getFreeTier = () => {
            return this.get('/creators/api/v1/tiers/free');
        };
        this.updateFreeTier = (requestBody) => {
            return this.patch('/creators/api/v1/tiers/free', requestBody);
        };
        this.deleteFreeTier = () => {
            return this.delete('/creators/api/v1/tiers/free');
        };
        this.publishFreeTier = () => {
            return this.post('/creators/api/v1/tiers/free/publish');
        };
        this.reorderFreeTier = (requestBody) => {
            return this.post('/creators/api/v1/tiers/free/reorder', requestBody);
        };
        // Custom perk
        this.createCustomPerk = (payload) => {
            return this.post('/creators/api/v1/perks/customs', payload);
        };
        this.updateCustomPerk = (id, payload) => {
            return this.put(`/creators/api/v1/perks/customs/${id}`, payload);
        };
        this.deleteCustomPerk = (id) => {
            return this.delete(`/creators/api/v1/perks/customs/${id}`);
        };
        // Discord perk
        this.createDiscordPerk = (payload) => {
            return this.post('/creators/api/v1/perks/discords', payload);
        };
        this.updateDiscordPerk = (id, payload) => {
            return this.put(`/creators/api/v1/perks/discords/${id}`, payload);
        };
        this.deleteDiscordPerk = (id) => {
            return this.delete(`/creators/api/v1/perks/discords/${id}`);
        };
        // Product perk
        this.createProductPerk = (payload) => {
            return this.post('/creators/api/v1/perks/products', payload);
        };
        this.updateProductPerk = (id, payload) => {
            return this.put(`/creators/api/v1/perks/products/${id}`, payload);
        };
        this.deleteProductPerk = (id) => {
            return this.delete(`/creators/api/v1/perks/products/${id}`);
        };
        // RSS perk
        this.createRssPerk = (payload) => {
            return this.post('/creators/api/v1/perks/rsses', payload);
        };
        this.updateRssPerk = (id, payload) => {
            return this.put(`/creators/api/v1/perks/rsses/${id}`, payload);
        };
        this.deleteRssPerk = (id) => {
            return this.delete(`/creators/api/v1/perks/rsses/${id}`);
        };
        // Discount perk
        this.createDiscountPerk = (payload) => {
            return this.post('/creators/api/v1/perks/discounts', payload);
        };
        this.updateDiscountPerk = (id, payload) => {
            return this.put(`/creators/api/v1/perks/discounts/${id}`, payload);
        };
        this.deleteDiscountPerk = (id) => {
            return this.delete(`/creators/api/v1/perks/discounts/${id}`);
        };
        this.getTierPerks = (id) => {
            return this.get(`/creators/api/v1/perks/tiers/${id}`);
        };
        this.reorderPerk = (id, tierId, payload) => {
            return this.post(`/creators/api/v1/perks/tiers/${tierId}/reorder/${id}`, payload);
        };
        // Overview
        this.getOverview = () => {
            return this.get('/creators/api/v1/overview');
        };
        // Top comments
        this.getTopComments = (params) => {
            return this.get('/creators/api/v1/top_comments/overview', params);
        };
        // Settings
        this.getAccountSettings = () => {
            return this.get('/creators/api/v1/accounts/settings');
        };
        this.updateAccountSettingsTiers = (payload) => {
            return this.patch('/creators/api/v1/accounts/settings/tiers', payload);
        };
        this.updateAccountSettingsPwas = (payload) => {
            return this.patch('/creators/api/v1/accounts/settings/pwas', payload);
        };
        this.updateAccountSettingsUpsellings = (payload) => {
            return this.patch('/creators/api/v1/accounts/settings/upsellings', payload);
        };
        // Posts
        this.getPosts = (params) => {
            return this.get('/creators/api/v1/feed/posts', params);
        };
        this.getPost = (id) => {
            return this.get(`/creators/api/v1/feed/posts/${id}`);
        };
        this.deletePost = (id) => {
            return this.delete(`/creators/api/v1/feed/posts/${id}`);
        };
        this.pinPost = (params) => {
            return this.post(`/creators/api/v1/feed/posts/${params.post_id}/pin`);
        };
        this.unpinPost = (params) => {
            return this.delete(`/creators/api/v1/feed/posts/${params.post_id}/pin`);
        };
        // Text post
        this.createTextPost = (payload) => {
            return this.post('/creators/api/v1/feed/posts/texts', payload);
        };
        this.updateTextPost = (id, payload) => {
            return this.put(`/creators/api/v1/feed/posts/texts/${id}`, payload);
        };
        // Poll post
        this.createPollPost = (payload) => {
            return this.post('/creators/api/v1/feed/posts/polls', payload);
        };
        this.updatePollPost = (id, payload) => {
            return this.put(`/creators/api/v1/feed/posts/polls/${id}`, payload);
        };
        // Image post
        this.createImagePost = (payload) => {
            return this.post('/creators/api/v1/feed/posts/images', payload);
        };
        this.updateImagePost = (id, payload) => {
            return this.put(`/creators/api/v1/feed/posts/images/${id}`, payload);
        };
        // Video post
        this.createVideoPost = (payload) => {
            return this.post('/creators/api/v1/feed/posts/videos', payload);
        };
        this.updateVideoPost = (id, payload) => {
            return this.put(`/creators/api/v1/feed/posts/videos/${id}`, payload);
        };
        // Video embed post
        this.createVideoEmbedPost = (payload) => {
            return this.post('/creators/api/v1/feed/posts/video_embeds', payload);
        };
        this.updateVideoEmbedPost = (id, payload) => {
            return this.put(`/creators/api/v1/feed/posts/video_embeds/${id}`, payload);
        };
        // Audio post
        this.createAudioPost = (payload) => {
            return this.post('/creators/api/v1/feed/posts/audios', payload);
        };
        this.updateAudioPost = (id, payload) => {
            return this.put(`/creators/api/v1/feed/posts/audios/${id}`, payload);
        };
        // Resources
        this.getResources = (params) => {
            return this.get('/creators/api/v1/resources', params);
        };
        this.uploadImage = (file, { uploadUrl, headers, }) => {
            return axios.put(uploadUrl, file, {
                headers,
            });
        };
        this.createSubtitlesResource = (payload) => {
            return this.post('creators/api/v1/resources/videos/muxes/subtitles', payload);
        };
        this.confirmSubtitlesResource = (id, payload) => {
            return this.put(`/creators/api/v1/resources/videos/muxes/subtitles/${id}`, payload);
        };
        this.createImageResource = (payload) => {
            return this.post('creators/api/v1/resources/images/googles', payload);
        };
        this.confirmImageResource = (id, payload) => {
            return this.put(`/creators/api/v1/resources/images/googles/${id}`, payload);
        };
        this.confirmImageUpload = (id, payload) => {
            return this.put(`/creators/api/v1/resources/images/posts/${id}`, payload);
        };
        this.confirmMediaUpload = (id) => {
            return this.get(`/creators/api/v1/resources/media/posts/${id}`);
        };
        this.getDirectVideoUploadData = () => {
            return this.post('/creators/api/v1/resources/videos/muxes/direct');
        };
        this.getDirectAudioUploadData = () => {
            return this.post('/creators/api/v1/resources/audios/muxes/direct');
        };
        // Series
        this.getPostSeries = (id, params) => {
            return this.get(`/creators/api/v1/series/posts/posts/${id}`, params);
        };
        this.getSeries = (params) => {
            return this.get('/creators/api/v1/series/posts', params);
        };
        this.getSingleSeries = (id) => {
            return this.get(`/creators/api/v1/series/posts/${id}`);
        };
        this.createSingleSeries = (payload) => {
            return this.post('/creators/api/v1/series/posts', payload);
        };
        this.updateSingleSeries = (id, payload) => {
            return this.put(`/creators/api/v1/series/posts/${id}`, payload);
        };
        this.deleteSingleSeries = (id) => {
            return this.delete(`/creators/api/v1/series/posts/${id}`);
        };
        // Welcome posts
        this.getWelcomePosts = () => {
            return this.get('/creators/api/v1/welcome_posts');
        };
        this.createWelcomePosts = (payload) => {
            return this.post('/creators/api/v1/welcome_posts', payload);
        };
        this.updateWelcomePosts = (payload) => {
            return this.put('/creators/api/v1/welcome_posts', payload);
        };
        this.deleteWelcomePosts = () => {
            return this.delete('/creators/api/v1/welcome_posts');
        };
        // Tags
        this.getTags = (params) => {
            return this.get('/creators/api/v1/tags/posts', params);
        };
        // Onboarding
        this.getOnboarding = () => {
            return this.get('/creators/api/v1/onboardings/account');
        };
        this.hideOnboarding = () => {
            return this.patch(`/creators/api/v1/onboardings/account/hide`);
        };
        this.skipOnboardingStep = (id) => {
            return this.patch(`/creators/api/v1/onboardings/account/steps/${id}/skip`);
        };
        this.addMembershipsToHomepage = () => {
            return this.patch('/creators/api/v1/onboardings/account/steps/link_to_memberships/mark_as_completed');
        };
        // Apps
        this.getTwitchDiscount = () => {
            return this.get('/creators/api/v1/apps/twitch_discount');
        };
        this.createTwitchDiscount = (requestBody) => {
            return this.post('/creators/api/v1/apps/twitch_discount', requestBody);
        };
        this.updateTwitchDiscount = (requestBody) => {
            return this.put('/creators/api/v1/apps/twitch_discount', requestBody);
        };
        this.setTwitchDiscountStatus = (requestBody) => {
            return this.patch('/creators/api/v1/apps/twitch_discount/status', requestBody);
        };
    }
}
export const MembershipsCreatorApiClient = new MembershipsCreatorApiClientModel('/');
