import { BackButton, Button, ButtonClean, Loader, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dashboard, PageLayout } from '@components/common';
import { routing } from '@utils/routing';
import { DnsRecordsData } from './components/DnsRecordsData';
import { EmailSendModal } from './components/EmailSendModal/EmailSendModal';
import * as S from './DomainConfigurationView.styled';
import { dnsManagementUrls } from './consts';
const topContent = (React.createElement(BackButton, { label: "Domains", to: {
        pathname: routing.settings.domain.self,
        search: location.search,
    } }));
export const DomainConfigurationView = ({ domain, provider, mode, domainDnsRecords, isLoading, isVerificationInProgress, isAutomaticSetupInProgress, warning, onSend, onDomainVerification, onAutomaticSetup, }) => {
    const media = useMediaQueryContext();
    const navigate = useNavigate();
    const { open } = useModalContext();
    if (isLoading || !domainDnsRecords.length) {
        return (React.createElement(S.LoaderContainer, null,
            React.createElement(Loader, { size: "large" })));
    }
    const handleSendClick = () => {
        open(EmailSendModal, { onSubmit: onSend });
    };
    const handleVerifyLater = () => {
        navigate(routing.settings.domain.self);
    };
    const isEveryRecordValueLoaded = domainDnsRecords.every((record) => record.value != null);
    const renderMainDescription = () => {
        if (mode === 'MANUAL') {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.DescriptionParagraph, null,
                    "If you don\u2019t own this domain yet, we recommend using Porkbun to purchase it. Otherwise, follow the instructions below to set up your domain.",
                    ' ',
                    React.createElement(ButtonClean, { label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/13331283794587-How-do-I-use-a-custom-domain", target: "_blank" })),
                !provider && domain?.fullDomain && (React.createElement(S.DescriptionParagraph, null,
                    "If you just purchased ",
                    domain?.fullDomain,
                    ", we recommend waiting an hour for the new ownership records to update so you can use our easy, automated domain connection flow. If you\u2019d like to set up your domain now, you can use the manual setup process below."))));
        }
        return (React.createElement(S.DescriptionParagraph, null,
            "To get your new domain working we need to enter a couple settings (called DNS records) that tell the domain how to work with our hosting and email system. Follow the guide below for instructions on how to enter these records.",
            ' ',
            React.createElement(ButtonClean, { label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/13331283794587-How-do-I-use-a-custom-domain", target: "_blank" })));
    };
    const renderFirstStepParagraph = () => {
        if (domain?.fullDomain && domain?.mainDomain && provider && dnsManagementUrls[provider]) {
            return (React.createElement(S.DescriptionParagraph, null,
                "It looks like ",
                domain.fullDomain,
                " is managed by ",
                provider,
                ".",
                ' ',
                React.createElement(ButtonClean, { appearance: "primary", label: "Click here", href: dnsManagementUrls[provider]?.replace('{domain}', domain.mainDomain), target: "_blank" }),
                ' ',
                "to manage your domain records."));
        }
        return (React.createElement(S.DescriptionParagraph, null, "Login to your domain provider and find the DNS records editing page for this domain."));
    };
    const renderDescription = () => (React.createElement(S.DescriptionContent, null,
        renderMainDescription(),
        React.createElement(S.DescriptionPoint, null, "1. Go to your DNS records page"),
        renderFirstStepParagraph(),
        React.createElement(S.DescriptionPoint, null, "2. Enter the following values"),
        React.createElement(S.DescriptionParagraph, null,
            "Enter the follows DNS values for ",
            domain?.fullDomain || 'your domain'),
        warning && React.createElement(S.WarningContainer, null, warning)));
    return (React.createElement(PageLayout, { bottomContent: React.createElement(React.Fragment, null,
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(Dashboard.Section.ScrollableContent, null,
                    React.createElement(DnsRecordsData, { records: domainDnsRecords, isVerificationInProgress: isVerificationInProgress }))),
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(S.BottomContentContainer, null,
                    React.createElement(ButtonClean, { appearance: "primary", label: "Send this list via email", onClick: handleSendClick, disabled: !isEveryRecordValueLoaded }),
                    React.createElement(S.RightBottomContentContainer, null,
                        React.createElement(ButtonClean, { appearance: "primary", label: "Verify later", onClick: handleVerifyLater }),
                        React.createElement(Button, { appearance: "primary", label: "Verify records", fullWidth: !media.tablet, disabled: !isEveryRecordValueLoaded || isAutomaticSetupInProgress, loading: isVerificationInProgress, onClick: onDomainVerification }),
                        onAutomaticSetup && (React.createElement(Button, { appearance: "primary", label: "Automatic setup", fullWidth: !media.tablet, loading: isAutomaticSetupInProgress, disabled: isVerificationInProgress, onClick: onAutomaticSetup })))))), bottomContentMargin: "none" },
        React.createElement(Dashboard.Section, { name: "Set up your new domain", bottomMargin: "small", topContent: topContent, bottomContent: renderDescription() })));
};
