import { ApiBase } from './ApiBase';
class ShippingApiClientModel extends ApiBase {
    constructor() {
        /* SHIPPING-RATES-ENDPOINT */
        super(...arguments);
        // Calculate shipping rates for origin address
        // @ts-ignore
        // FIXME: add schema
        this.getAddressRates = (payload) => this.post('/shipping/rates/address', payload);
        this.validate = (payload) => this.post('/shipping/address/validate', payload);
        this.getLocationRates = (payload) => this.post('/v2/shipping/rates/location', payload);
        this.getFlatRates = () => this.get('/shipping/flat-rates');
        this.saveFlatRates = (payload) => this.post('/shipping/flat-rates', payload);
    }
}
export const ShippingApiClient = new ShippingApiClientModel();
