import React, { Fragment } from 'react';
import * as S from './styled';
export const FeatureFlagDecorator = ({ enabled, description, display = 'block', highlight = false, spacing = 'regular', descriptionPosition = 'inside', wrapper = Fragment, children, }) => {
    if (!enabled)
        return null;
    const Wrapper = wrapper;
    if (!highlight)
        return React.createElement(Wrapper, null, children);
    return (React.createElement(Wrapper, null,
        React.createElement(S.Container, { spacing: spacing, display: display },
            description && (React.createElement(S.Description, { position: descriptionPosition },
                React.createElement("div", null, description))),
            children)));
};
