import React from 'react';
import { ModalBox } from '../../partials/ModalBox';
import { useModalContext } from '../../providers/ModalProvider';
import { FeeCheckbox } from './components/FeeCheckbox';
import { SellingPriceInput } from './components/SellingPriceInput';
import * as S from './styled';
const Root = ({ title, content, includeFeesValue, suggestions, onToggleIncludeFeesValue, }) => {
    const { close } = useModalContext();
    const renderSuggestions = () => {
        if (!suggestions.length) {
            return null;
        }
        return (React.createElement(S.Suggestions, null, suggestions.map((suggestion, index) => (React.createElement("div", { key: index }, suggestion)))));
    };
    return (React.createElement(ModalBox, { size: "small", title: title, onClose: close },
        renderSuggestions(),
        content,
        React.createElement("div", null,
            React.createElement(FeeCheckbox, { value: includeFeesValue, onValueChange: onToggleIncludeFeesValue }))));
};
const PriceInput = ({ priceValue, onPriceChange, onSellingPriceBlur, }) => {
    return (React.createElement(S.SellingPriceInputWrapper, null,
        React.createElement(SellingPriceInput, { value: priceValue, onBlur: onSellingPriceBlur, onValueChange: onPriceChange })));
};
export { Root, PriceInput };
