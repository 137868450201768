import React from 'react';
import { Dropdown, Icon, LogoImage, ToolbarButton, useMediaQueryContext, } from '@fourthwall/components';
import { DotsHorizontalIcon, NewWindowIcon } from '@fourthwall/icons';
import * as S from './styled';
export const ConnectedSiteListItem = ({ connectedSite }) => {
    const media = useMediaQueryContext();
    return (React.createElement(S.Container, null,
        React.createElement(S.LeftSide, null,
            React.createElement(LogoImage, { src: connectedSite.image, backgroundUrl: connectedSite.background, placeholder: connectedSite.title.charAt(0), size: media.tablet ? 'large' : 'medium' }),
            React.createElement("div", null,
                React.createElement(S.Title, null,
                    connectedSite.title,
                    React.createElement(S.Href, { href: connectedSite.href, target: "_blank", title: `Open ${connectedSite.title} in new window` },
                        React.createElement(Icon, { component: NewWindowIcon, height: 12 }))),
                connectedSite.description && React.createElement(S.Description, null, connectedSite.description))),
        React.createElement(S.RightSide, null,
            React.createElement("div", null,
                React.createElement(Dropdown, { content: React.createElement(Dropdown.Item, { label: "Manage", onClick: connectedSite.onManage }), position: "bottom-end" },
                    React.createElement(ToolbarButton, { icon: DotsHorizontalIcon }))))));
};
