import { ConfirmModal, useModalContext } from '@fourthwall/components';
import React from 'react';
import { pluralize } from '@fourthwall/utils/lib/string';
import * as S from './styled';
export const ConfirmStoreDisconnectModal = ({ type, pendingFulfillmentsCount, onConfirm, }) => {
    const { close } = useModalContext();
    const title = type === 'DISCONNECT'
        ? 'Are you sure you want to disconnect from ShipStation store?'
        : 'Are you sure you want to change ShipStation store?';
    const text = pendingFulfillmentsCount ? (React.createElement(React.Fragment, null,
        "You currently have ",
        pendingFulfillmentsCount,
        " pending",
        ' ',
        pluralize(pendingFulfillmentsCount, 'order'),
        ". Changing ShipStation store will lose tracking of these orders. ",
        React.createElement(S.Strong, null, "This action cannot be reverted."))) : undefined;
    return (React.createElement(ConfirmModal, { title: title, text: text, confirmAppearance: "destructive", onConfirm: onConfirm, close: close }));
};
