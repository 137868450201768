import { Icon, Loader } from '@fourthwall/components';
import React from 'react';
import { DnsRecordInfoCell } from '../DnsRecordInfoCell';
import * as S from './DnsRecordStatusCell.styled';
export const DnsRecordStatusCell = ({ record, isVerificationInProgress, }) => {
    const getStatusContent = () => {
        if (isVerificationInProgress) {
            return (React.createElement(S.LoaderWrapper, null,
                React.createElement(Loader, { size: "small" })));
        }
        if (record.status === 'VALID') {
            return React.createElement(Icon, { component: S.StatusTickIcon, height: 24, width: 24 });
        }
        if (record.status === 'INVALID') {
            return React.createElement(Icon, { component: S.StatusCloseIcon, height: 24, width: 24 });
        }
        return null;
    };
    const statusContent = getStatusContent();
    return (React.createElement(S.Container, null,
        React.createElement(S.Info, null,
            React.createElement(DnsRecordInfoCell, { record: record })),
        statusContent ? React.createElement(S.StatusWrapper, null, statusContent) : null));
};
