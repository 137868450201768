import { Dashboard, PageLayout } from '@components/common';
import { DashboardPermissionGuard } from '@components/utils/DashboardPermissions';
import { Button, ButtonGroup, HeadContent, ToolbarButton, useMediaQueryContext, } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import { routing } from '@utils/routing';
import React from 'react';
import { CollectionsData } from './components/CollectionsData';
export const CollectionsView = ({ collections, isFetching }) => {
    const media = useMediaQueryContext();
    const getToolbarContent = () => {
        if (!media.tablet) {
            return (React.createElement(ButtonGroup, { space: "medium" },
                React.createElement(DashboardPermissionGuard, { permission: "collections.create" },
                    React.createElement(ToolbarButton, { label: "Create new product", icon: PlusIcon, to: routing.products.collections.create.self }))));
        }
        return (React.createElement(ButtonGroup, { space: "medium" },
            React.createElement(DashboardPermissionGuard, { permission: "collections.create" },
                React.createElement(Button, { to: routing.products.collections.create.self, appearance: "primary", label: "Create new collection", size: "small" }))));
    };
    return (React.createElement(PageLayout, { size: "large" },
        React.createElement(HeadContent, { title: "Collections" }),
        React.createElement(Dashboard.Section, { name: "Collections", toolbarContent: getToolbarContent() },
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(CollectionsData, { data: collections || [], isLoading: isFetching })))));
};
