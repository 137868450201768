import styled from 'styled-components';
export const Avatar = styled.div `
  display: flex;
  align-items: baseline;
`;
export const AvatarName = styled.div `
  margin-left: 24px;
`;
export const Additional = styled.div `
  display: flex;
  justify-content: end;
  padding-right: 16px;
`;
