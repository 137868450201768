import { ArrowShortDownIcon } from '@fourthwall/icons';
import find from 'lodash-es/find';
import findIndex from 'lodash-es/findIndex';
import isEmpty from 'lodash-es/isEmpty';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '../../common/Icon';
import { Loading } from '../../common/Loading';
import { VisuallyHidden } from '../../common/VisuallyHidden';
import { withFormikSupport } from '../../hocs/withFormikSupport';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Options } from '../../partials/Options/Options';
import { Input } from '../Input';
import * as S from './AutoComplete.styled';
import { KeyCode } from './consts';
const AutoCompleteComponent = ({ options, size = 'large', placement = 'bottom', maxHeight = 300, isLoading = false, blurOnSelect = true, withArrow = false, emptyState, onSelect, onChange, onFocus, onBlur, onSearch, onPaste, ...other }) => {
    const ref = useRef(null);
    const [activeOptionId, setActiveOptionId] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isSelectingOption, setIsSelectingOption] = useState(false);
    const changeActiveOption = (number) => {
        if (options && !isEmpty(options)) {
            const currentOptionIndex = findIndex(options, { id: activeOptionId });
            const lastOption = options[options.length - 1];
            const firstOption = options[0];
            if (number === 1) {
                if (!activeOptionId || activeOptionId === lastOption.id) {
                    return setActiveOptionId(firstOption.id);
                }
                return setActiveOptionId(options[currentOptionIndex + 1].id);
            }
            if (number === -1) {
                if (!activeOptionId || activeOptionId === firstOption.id) {
                    return setActiveOptionId(lastOption.id);
                }
                return setActiveOptionId(options[currentOptionIndex - 1].id);
            }
        }
        return null;
    };
    useEffect(() => {
        if (options && options.length && (isFocused || isSelectingOption)) {
            setActiveOptionId(options[0].id);
        }
    }, [options, isFocused, isSelectingOption]);
    useOutsideClick(ref, () => {
        setIsFocused(false);
        setIsSelectingOption(false);
    });
    const handleInputChange = (e) => {
        if (onChange) {
            onChange(e);
        }
        if (isFocused && onSearch) {
            onSearch(e.target.value);
        }
    };
    const handleInputFocus = (e) => {
        if (onFocus) {
            onFocus(e);
        }
        setIsFocused(true);
    };
    const handleInputBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
        setIsFocused(false);
    };
    const handleOptionSelect = (option) => {
        if (blurOnSelect) {
            setIsSelectingOption(false);
            setIsFocused(false);
        }
        if (onSelect) {
            onSelect(option);
        }
    };
    const handleStartSelectingOption = () => {
        setIsSelectingOption(true);
    };
    const handleKeyDown = (e) => {
        switch (e.keyCode) {
            case KeyCode.ArrowDown:
                e.preventDefault();
                changeActiveOption(1);
                break;
            case KeyCode.ArrowUp:
                e.preventDefault();
                changeActiveOption(-1);
                break;
            case KeyCode.Escape:
                e.preventDefault();
                setIsFocused(false);
                break;
            case KeyCode.Tab:
                setIsFocused(false);
                break;
            case KeyCode.Enter:
                if (activeOptionId) {
                    e.preventDefault();
                    const selectedOption = find(options, {
                        id: activeOptionId,
                    });
                    if (selectedOption) {
                        handleOptionSelect(selectedOption);
                    }
                }
                break;
            default:
        }
    };
    const renderOption = (option) => (React.createElement(Options.Item, { key: option.id, label: option.content, active: option.id === activeOptionId, onClick: () => handleOptionSelect(option) }));
    const renderSelectedOption = () => {
        const selectedOption = find(options, { id: activeOptionId });
        if (!selectedOption)
            return;
        return (React.createElement(VisuallyHidden, null,
            React.createElement("div", { "aria-live": "polite", "aria-atomic": "true" }, selectedOption.content)));
    };
    const renderOptions = () => {
        const containerProps = {
            size,
            placement,
            withError: !!other.error,
            onMouseDown: handleStartSelectingOption,
            onTouchStart: handleStartSelectingOption,
        };
        if (!options?.length && isLoading && isFocused) {
            return (React.createElement(S.OptionsContainer, { ...containerProps },
                React.createElement(Loading, { isLoading: true },
                    React.createElement(S.OptionsWrapper, { maxHeight: maxHeight },
                        React.createElement(Options, null,
                            React.createElement("div", { style: { height: 150 } }))))));
        }
        if (options && options.length && (isFocused || isSelectingOption)) {
            return (React.createElement(S.OptionsContainer, { ...containerProps },
                React.createElement(Loading, { isLoading: isLoading },
                    React.createElement(S.OptionsWrapper, { maxHeight: maxHeight },
                        React.createElement(Options, { borderless: true }, options.map(renderOption))),
                    renderSelectedOption())));
        }
        if (!options?.length && other.value?.length && isFocused && emptyState) {
            return (React.createElement(S.OptionsContainer, { ...containerProps },
                React.createElement(S.EmptyStateWrapper, { height: maxHeight }, emptyState)));
        }
        return null;
    };
    const getActionIconProps = () => {
        if (withArrow) {
            return {
                actionIcon: React.createElement(Icon, { component: ArrowShortDownIcon, height: 16 }),
                onActionIconClick: () => setIsFocused((prevIsFocused) => !prevIsFocused),
            };
        }
        return {
            actionIcon: 'actionIcon' in other ? other.actionIcon : undefined,
            onActionIconClick: 'onActionIconClick' in other ? other.onActionIconClick : undefined,
        };
    };
    return (React.createElement(S.Container, { ref: ref },
        React.createElement(Input, { ...other, role: "combobox", onChange: handleInputChange, onFocus: handleInputFocus, onKeyDown: handleKeyDown, onBlur: handleInputBlur, onPaste: onPaste, ...getActionIconProps() }),
        renderOptions()));
};
export const AutoComplete = withFormikSupport(AutoCompleteComponent);
