import Yup from '@fourthwall/utils/lib/yup';
export const INITIAL_SHIPPING_COUNTRIES = ['US'];
export const validationSchema = Yup.object().shape({
    selectedProductsIds: Yup.array()
        .min(1, 'At least one selected product is required')
        .required('At least one selected product is required'),
    selectedCountries: Yup.array()
        .min(1, 'At least one selected country is required')
        .required('At least one selected country is required'),
});
