import { pluralize } from '@fourthwall/utils/lib/string';
import isUndefined from 'lodash-es/isUndefined';
import React, { useLayoutEffect, useState } from 'react';
import { withFormikSupport } from '../../hocs/withFormikSupport/withFormikSupport';
import { InputUI } from '../../ui';
import * as S from './Textarea.styled';
const TextareaComponent = ({ placeholder, disabled, focused, tabIndex, fullWidth, label, name, value = '', rows = 2, invalid = false, required, maxLength, info, error, displayError = true, submitCount, suggestion, onChange, onValueChange, onBlur, onFocus, }) => {
    const [isFocused, setIsFocused] = useState(false);
    useLayoutEffect(() => {
        if (!isUndefined(focused)) {
            setIsFocused(focused);
        }
    }, [focused]);
    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
        }
        if (onValueChange) {
            onValueChange(e.target.value);
        }
    };
    const handleFocus = (e) => {
        setIsFocused(true);
        if (onFocus) {
            onFocus(e);
        }
    };
    const handleBlur = (e) => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(e);
        }
    };
    const getTextareaId = () => {
        if (!name) {
            return undefined;
        }
        return `${name}__textarea`;
    };
    const charactersLeft = (maxLength || Infinity) - (value?.length || 0);
    return (React.createElement(S.Container, { fullWidth: fullWidth },
        React.createElement(InputUI.Box, { invalid: invalid, fullWidth: fullWidth, fullHeight: true, disabled: disabled, focused: isFocused },
            React.createElement(S.Textarea, { rows: rows, placeholder: placeholder, disabled: disabled, required: required, name: name, tabIndex: tabIndex, id: getTextareaId(), value: value, "aria-invalid": invalid ? 'true' : 'false', maxLength: maxLength, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, "data-testid": "Textarea" }),
            React.createElement(S.Label, { htmlFor: getTextareaId() }, label),
            (maxLength || info) && (React.createElement(InputUI.Info, null, info || `${charactersLeft} ${pluralize(charactersLeft, 'character')} left`))),
        (!displayError || !error) && suggestion && (React.createElement(InputUI.Suggestion, null, suggestion)),
        displayError && !!error && (React.createElement(InputUI.Error, { errorTimestampKey: submitCount }, error))));
};
export const Textarea = withFormikSupport(TextareaComponent);
