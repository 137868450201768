import { Toasts, useModalContext } from '@fourthwall/components';
import { arrayMove } from '@fourthwall/utils/lib/array';
import { base64ToFile, fileToBase64 } from '@fourthwall/utils/lib/file';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { GalleryModal } from '../../../../../../../components/modals/GalleryModal';
import { ImageUploadModal } from '../../../../../../../components/modals/ImageUploadModal';
import { getCroppedImage } from '../../../../utils';
import { useUploadImageMutation } from '../useUploadImageMutation';
import { useOfferImagesFilters } from './useOfferImagesFilters';
import { createTags } from './utils';
const isImageMatchingFilters = (filters) => (image) => {
    return image.tags.every((tag) => {
        const filter = filters.find((filter) => filter.tag === tag.type);
        return filter ? filter.value === tag.name : true;
    });
};
const getGalleryData = ({ value, filters, }) => {
    return value.filter(isImageMatchingFilters(filters));
};
const mapIndexPosition = (item, index) => {
    return { ...item, position: index };
};
export const useOfferImages = ({ offer, offerVariantTypes, value, disabled = false, onChange, onColorFilterSort, onUploadStart, onUploadEnd, }) => {
    const { offerImagesFilters } = useOfferImagesFilters(offerVariantTypes);
    const { uploadImageMutation } = useUploadImageMutation();
    const [uploading, setUploading] = useState(false);
    const { open } = useModalContext();
    if (!offer || !offerVariantTypes)
        return { offerImagesProps: undefined };
    const galleryData = getGalleryData({ value, filters: offerImagesFilters });
    const mapFileToUploadPromise = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return uploadImageMutation.mutateAsync([formData]);
    };
    const uploadBlobs = async (blobs) => {
        onUploadStart();
        setUploading(true);
        try {
            const promises = blobs.map(mapFileToUploadPromise);
            const results = await Promise.all(promises);
            const items = results.map((result) => ({
                id: uuidv4(),
                url: result.files.length ? result.files[0].uri : '',
                tags: createTags(offerImagesFilters),
                position: 0, // Just to match the type. It gets mapped with mapIndexPosition function later on.
            }));
            onChange([...value, ...items].map(mapIndexPosition));
        }
        finally {
            setUploading(false);
            onUploadEnd();
        }
    };
    const handleImageAdd = async (acceptedFiles) => {
        if (!acceptedFiles || !acceptedFiles.length)
            return;
        open(ImageUploadModal, {
            image: await fileToBase64(acceptedFiles[0]),
            onConfirm: async (image, croppedArea) => {
                const file = croppedArea
                    ? await getCroppedImage(image, croppedArea, acceptedFiles[0].type)
                    : await base64ToFile(image, 'image', acceptedFiles[0].type);
                if (!file)
                    return;
                await uploadBlobs([file]);
            },
        });
    };
    const handleGalleryView = (item) => {
        open(GalleryModal, {
            images: galleryData,
            selectedImageId: `${item.id}`,
        });
    };
    const handleGalleryRemove = (item) => {
        onChange(value.filter(({ id }) => id !== item.id).map(mapIndexPosition));
    };
    const handleGallerySortChange = (oldIndex, newIndex) => {
        const valueOldIndex = value.findIndex(({ id }) => id === galleryData[oldIndex].id);
        const valueNewIndex = value.findIndex(({ id }) => id === galleryData[newIndex].id);
        onChange(arrayMove(value, valueOldIndex, valueNewIndex).map(mapIndexPosition));
    };
    const handleImageAddError = (rejectedFiles) => {
        rejectedFiles?.forEach(({ file, errors }) => {
            if (errors.length > 0) {
                Toasts.notify(`${file.name}: ${errors[0].message}`, { type: 'error' });
            }
        });
    };
    return {
        offerImagesProps: {
            filters: offerImagesFilters,
            gallery: {
                data: galleryData,
                disabled,
                onView: handleGalleryView,
                onRemove: handleGalleryRemove,
                onSortChange: handleGallerySortChange,
            },
            isLoading: uploading,
            disabled,
            onColorFilterSortChange: onColorFilterSort,
            onImageAdd: handleImageAdd,
            onImageAddError: handleImageAddError,
        },
    };
};
