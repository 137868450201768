import { NumberString } from '@fourthwall/components';
import dayjs from 'dayjs';
import React from 'react';
import { reverse, routing } from '../../../../../utils/routing';
import { OfferLink } from '../../components/OfferLink';
import { title } from '../../consts';
export const format = 'MMM D';
export const yearFormat = 'MMM D, YYYY';
export const createGetReportTo = (location) => (reportType) => {
    return {
        pathname: reverse(routing.analytics.report, { reportType }),
        search: location.search,
    };
};
export const prepareSingleValueChart = (chart) => {
    if (!chart) {
        return { timestamps: [], values: [] };
    }
    return {
        timestamps: chart.timestamps,
        values: chart.values.map(({ value }) => value),
    };
};
export const getEndDateFromRange = (range) => {
    if (!range)
        return;
    const to = dayjs(range.to).utc();
    return `As of ${to.format(format)}`;
};
export const mapOffersToAnalyticsRows = (topOfferAnalytics) => {
    if (!topOfferAnalytics)
        return [];
    return topOfferAnalytics.map((offer) => ({
        label: React.createElement(OfferLink, { name: offer.name, label: title, id: offer.offerId, status: offer.status }),
        value: React.createElement(NumberString, { value: offer.unitsSold }),
    }));
};
export const mapTopTiersToAnalyticsRows = (tiersCounts, tiersNames) => {
    if (!tiersCounts || !tiersCounts.length || !tiersNames)
        return [];
    const tiersData = tiersCounts.map((tiersCount) => ({
        ...tiersCount,
        ...tiersNames.find((tiersName) => tiersName.id === tiersCount.tierId),
    }));
    return tiersData.map(({ name, count }) => ({
        label: name,
        value: count,
    }));
};
