import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;
export const IconContainer = styled.div `
  margin-right: 12px;
`;
const getIconBackground = (status) => {
    return {
        approved: Colors.G300,
        pending: Colors.Y300,
        rejected: Colors.R300,
    }[status];
};
export const Icon = styled.div `
  width: 24px;
  height: 24px;
  background: ${({ status }) => getIconBackground(status)};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
`;
export const Label = styled.div `
  flex: 1;

  color: ${Colors.GS1000};

  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
`;
export const ButtonContainer = styled.div `
  margin-left: 12px;

  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
`;
