import styled from 'styled-components';
export const EmptyState = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
`;
export const ProductName = styled.div `
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 16px;
`;
export const ProductStatus = styled.div `
  display: flex;
  align-items: center;
  height: 100%;
`;
export const ProductSold = styled.div `
  display: flex;
  align-items: center;
  height: 100%;
  width: 150px;
`;
export const ProductInventory = styled.div `
  display: flex;
  align-items: center;
  height: 100%;
`;
