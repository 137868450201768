import React from 'react';
import { useLocation } from 'react-router-dom';
import { reverse } from 'named-urls';
import { useCreateSamplesCheckoutMutation } from '../../hooks/useCreateSamplesCheckoutMutation';
import { useSamplesBudgetBalance } from '../../hooks/useSamplesBudgetBalance';
import { SamplesOrderView } from './SamplesOrderView';
import { useSamplesOffers } from '../../hooks/useSamplesOffers';
import { isSamplesOrderLocationCartState, isSamplesOrderLocationModalState } from './utils';
import { routing } from '../../../../../../../utils/routing';
export const SamplesOrderContainer = () => {
    const { samplesBudgetBalance } = useSamplesBudgetBalance();
    const { createSamplesCheckoutMutation } = useCreateSamplesCheckoutMutation();
    const { samplesOffers } = useSamplesOffers();
    const location = useLocation();
    const handleSubmit = (values) => {
        return createSamplesCheckoutMutation.mutate([values], {
            onSuccess: (data) => window.location.assign(reverse(routing.checkout, { checkoutId: data.checkoutId })),
        });
    };
    const getInitialValues = () => {
        if (isSamplesOrderLocationCartState(location.state) && location.state.cart) {
            return location.state.cart;
        }
    };
    return (React.createElement(SamplesOrderView, { credit: samplesBudgetBalance, offers: samplesOffers.offers, button: { loading: createSamplesCheckoutMutation.isLoading }, initialValues: getInitialValues(), showModal: isSamplesOrderLocationModalState(location.state) && location.state.showModal, onSubmit: handleSubmit }));
};
