import React, { useEffect } from 'react';
import { CloseIcon } from '@fourthwall/icons';
import { useFocus } from '../../hooks/useFocus';
import { Icon } from '../Icon';
import * as S from './styled';
export const Alert = ({ message, type = 'info', onClose }) => {
    const [closeButtonRef, setCloseButtonFocus] = useFocus();
    useEffect(() => {
        // NOTE: we require this timeout due to Screen Reader issue with reading the content of this box
        const timeout = setTimeout(() => {
            setCloseButtonFocus();
        }, 200);
        return () => clearTimeout(timeout);
    }, [setCloseButtonFocus]);
    return (React.createElement(S.Container, { "$type": type, role: "alert" },
        React.createElement(S.Message, null, message),
        onClose && (React.createElement(S.Close, { title: "Close", onClick: onClose, "data-testid": "Alert.Close", ref: closeButtonRef },
            React.createElement(Icon, { component: CloseIcon, height: 16, ariaLabel: "close" })))));
};
