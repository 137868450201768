import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { useShippingProfilesQuery } from '@modules/Dashboard/hooks/useShippingProfilesQuery';
import { getShippingGroupLink } from './utils/getShippingGroupLink';
import { useDeleteShippingProfileMutation } from '../../hooks/useDeleteShippingProfileMutation';
import { ShippingGroupsYouShipView } from './ShippingGroupsYouShipView';
import { getShippingGroups } from './utils/getShippingGroups';
export const ShippingGroupsYouShipContainer = () => {
    const { open } = useModalContext();
    const navigate = useNavigate();
    const { deleteShippingProfileMutation } = useDeleteShippingProfileMutation();
    const { invalidateShippingProfilesQuery } = useShippingProfilesQuery();
    const { shippingProfilesQuery } = useShippingProfilesQuery();
    const shippingGroups = getShippingGroups(shippingProfilesQuery.data);
    const handleEdit = (id) => navigate(getShippingGroupLink(id));
    const handleDelete = (id, name) => {
        open(ConfirmModal, {
            title: `Are you sure that you want to remove ${name}?`,
            confirmLabel: 'Delete',
            confirmAppearance: 'destructive',
            onConfirm: async () => {
                await deleteShippingProfileMutation.mutateAsync([id], {
                    onSuccess: () => {
                        invalidateShippingProfilesQuery();
                        Toasts.notify(`${name} has been removed.`, { type: 'success' });
                    },
                });
            },
        });
    };
    return (React.createElement(ShippingGroupsYouShipView, { shippingGroups: shippingGroups, onEdit: handleEdit, onDelete: handleDelete }));
};
