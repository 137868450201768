import isNil from 'lodash-es/isNil';
import some from 'lodash-es/some';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
export const filterVariantsBySize = (variants) => variants.reduce((result, variant) => {
    const size = variant.size;
    const cost = variant.cost?.value;
    if (!size || isNil(cost)) {
        return result;
    }
    if (some(result, ['size', size])) {
        const prevSizeVariant = find(result, ['size', size]);
        const prevSizeVariantCost = get(prevSizeVariant, 'cost.value');
        if (prevSizeVariantCost && (!prevSizeVariant || cost < prevSizeVariantCost)) {
            return [...result, variant];
        }
        return result;
    }
    return [...result, variant];
}, []);
