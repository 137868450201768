import { Tag } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { ScheduleTag } from '../../components/ScheduleTag';
export const PostTag = ({ post, updatePost }) => {
    const { values } = useFormikContext();
    const updatePostData = (liveAt) => {
        updatePost({ ...values, liveAt });
    };
    if (post.statuses.schedule === 'Future') {
        return React.createElement(ScheduleTag, { currentDate: post.liveAt, onDateUpdate: updatePostData });
    }
    return React.createElement(Tag, { label: "Published", appearance: "success", size: "large" });
};
