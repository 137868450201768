import isEmpty from 'lodash-es/isEmpty';
const getPlusSizesWithHigherCostInfo = (sizesWithHigherCosts) => {
    if (!sizesWithHigherCosts) {
        return;
    }
    const sizesLength = sizesWithHigherCosts?.length || 0;
    if (sizesLength === 1) {
        return sizesWithHigherCosts[0];
    }
    if (sizesLength === 2) {
        return sizesWithHigherCosts.join(' and ');
    }
    if (sizesLength > 2) {
        return `${sizesWithHigherCosts[0]}+`;
    }
};
export const getSizePriceSuggestion = (hasDifferentSizes, sizesWithHigherCosts, isFourthwallFulfilled) => {
    const plusSizes = getPlusSizesWithHigherCostInfo(sizesWithHigherCosts);
    if (hasDifferentSizes && !isEmpty(sizesWithHigherCosts) && isFourthwallFulfilled && plusSizes) {
        return `Plus sizes (${plusSizes}) have slightly higher costs. Selling price is automatically adjusted so you make the same amount on plus size sales.`;
    }
};
