import { Fieldset, RadioButton, Subsection, Tooltip } from '@fourthwall/components';
import React from 'react';
import { Usage } from '../../types';
export const DisabledFields = () => (React.createElement(Subsection, { title: "Welcome post" },
    React.createElement(Fieldset, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null, "Show your members a little love with a personal welcome post that appears to your supporters when they join.")),
        React.createElement(Fieldset.Row, null,
            React.createElement(RadioButton, { label: "Don\u2019t use welcome post", value: Usage.none, checkedValue: Usage.none })),
        React.createElement(Fieldset.Row, null,
            React.createElement(Tooltip, { placement: "top", reference: React.createElement(RadioButton, { label: "Use the same welcome post for each tier", disabled: true }) }, "You need to create membership tiers first.")),
        React.createElement(Fieldset.Row, null,
            React.createElement(Tooltip, { placement: "top", reference: React.createElement(RadioButton, { label: "Use different welcome post for each tier", disabled: true }) }, "You need to create membership tiers first.")))));
