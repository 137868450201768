import React, { useEffect, useRef, useState } from 'react';
import * as S from './styled';
export const ScrollContent = ({ className, maxHeight, maskPercentage = 20, children, hideScrollbar, }) => {
    const containerRef = useRef(null);
    const topRef = useRef(null);
    const bottomRef = useRef(null);
    const [isScrolledTop, setIsScrolledTop] = useState(true);
    const [isScrolledBottom, setIsScrolledBottom] = useState(true);
    const [contentOverflow, setContentOverflow] = useState(false);
    useEffect(() => {
        if (topRef.current && bottomRef.current && maxHeight) {
            const topObserver = new IntersectionObserver((payload) => setIsScrolledTop(payload[0].isIntersecting));
            const bottomObserver = new IntersectionObserver((payload) => setIsScrolledBottom(payload[0].isIntersecting));
            topObserver.observe(topRef.current);
            bottomObserver.observe(bottomRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (containerRef.current) {
            setContentOverflow(containerRef.current.scrollHeight > containerRef.current.clientHeight);
        }
    }, [children]);
    if (!maxHeight) {
        return children;
    }
    return (React.createElement(S.Container, { ref: containerRef, className: className, maxHeight: maxHeight, isScrolledTop: isScrolledTop, isScrolledBottom: isScrolledBottom, maskPercentage: maskPercentage, hideScrollbar: hideScrollbar || !contentOverflow },
        React.createElement(S.ScrollChecker, { ref: topRef }),
        children,
        React.createElement(S.ScrollChecker, { ref: bottomRef })));
};
