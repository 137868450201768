import { getPrice } from '@fourthwall/utils';
import { SINGLE_UNIT_PRODUCT_ORDER_SHIPPING_FEES, SINGLE_UNIT_PRODUCT_ORDER_TAXES_FEES, SINGLE_UNIT_PRODUCT_PAYMENT_PROCESSING_FEE_PERCENT, SINGLE_UNIT_PRODUCT_PAYMENT_PROCESSING_FEE_VALUE, } from '@fourthwall/utils/lib/constants';
import React from 'react';
import { InfoTooltip } from '../../../../common/InfoTooltip';
import { Checkbox } from '../../../../inputs/Checkbox';
import * as S from './styled';
export const FeeCheckbox = ({ value, onValueChange }) => {
    return (React.createElement(S.Container, null,
        React.createElement(Checkbox, { label: "Include estimated payment processing fees", value: value, onValueChange: onValueChange }),
        React.createElement(S.TooltipWrapper, null,
            React.createElement(InfoTooltip, { size: "medium", content: React.createElement(React.Fragment, null,
                    "There is a payment processing fee of $",
                    SINGLE_UNIT_PRODUCT_PAYMENT_PROCESSING_FEE_PERCENT,
                    "% +",
                    getPrice(SINGLE_UNIT_PRODUCT_PAYMENT_PROCESSING_FEE_VALUE),
                    " per transaction (same as Shopify). The fee depends on the number of items a supporter buys, as well as any shipping and tax fees. We estimate it using an order that contains a single unit of this product and has $",
                    SINGLE_UNIT_PRODUCT_ORDER_SHIPPING_FEES,
                    " for shipping fees and $",
                    SINGLE_UNIT_PRODUCT_ORDER_TAXES_FEES,
                    " for taxes."), dark: true }))));
};
