import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid ${Colors.GS500};
  border-radius: 1000px;
  height: 32px;
  padding: 4px 10px;
`;
