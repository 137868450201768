import { ApiBase } from '../ApiBase';
class DonationApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getDonations = (payload) => {
            return this.get('/donations', payload);
        };
        this.getDonation = (payload) => {
            const { donationId } = payload;
            return this.get(`/donations/${donationId}`);
        };
        this.createDonation = (payload, headers) => {
            return this.post('/v2/donations', payload, undefined, { headers });
        };
        this.getDonationConfig = (params) => {
            return this.get('/v2/donations/config', params);
        };
    }
}
export const DonationApiClient = new DonationApiClientModel();
