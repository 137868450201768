import React from 'react';
import * as S from './NavTabs.styled';
export const NavTabs = ({ tabs, activeTab, tabComponent: TabComponent = 'button', toolbarContent, onTabClick, }) => (React.createElement(S.Container, { "data-testid": "NavTabs" },
    React.createElement(S.InnerContainer, null,
        React.createElement(S.TabsContainer, null, tabs.map(({ label, to, value }) => (React.createElement(S.Tab, { key: label, end: true, as: TabComponent, type: TabComponent === 'button' ? 'button' : undefined, to: to, active: !!activeTab && activeTab === value, onClick: () => {
                if (onTabClick && value) {
                    onTabClick(value);
                }
            } }, label)))),
        toolbarContent)));
