import map from 'lodash-es/map';
import React from 'react';
import { TableHeadCell } from '../TableHeadCell/TableHeadCell';
import * as Styled from './styled';
export const TableHead = ({ headers, sorting, sortable, compact, hasActions, onHeadCellClick, }) => {
    const renderHeadCell = (header) => {
        if (!header.label)
            return null;
        return (React.createElement(TableHeadCell, { key: header.id, sortable: header.sortable || false, direction: sorting && sorting.headerId === header.id ? sorting.direction : undefined, compact: compact, alignment: header?.alignment, width: header?.width, minWidth: header?.minWidth, onClick: header.sortable ? () => onHeadCellClick(header.id) : undefined }, header.label));
    };
    return (React.createElement(Styled.Container, { role: "rowgroup", compact: compact },
        sortable && React.createElement(Styled.SortableCell, { "data-testid": "TableHead.SortableCell" }),
        map(headers, renderHeadCell),
        hasActions && React.createElement(Styled.SortableCell, { "data-testid": "TableHead.SortableCell" })));
};
export default TableHead;
