import { Dropdown, Icon, LogoImage, Popper } from '@fourthwall/components';
import React, { useState } from 'react';
import { DotsHorizontalIcon, NewWindowIcon } from '@fourthwall/icons';
import * as S from './styled';
export const ShopItem = ({ adminLink, shopLink, logoUrl, label, labelDescription, }) => {
    const [isOpen, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen((prevIsOpen) => !prevIsOpen);
    };
    const handleContainerMouseLeave = () => {
        setOpen(false);
    };
    const handleDotsButtonClick = (e) => {
        e.preventDefault();
        toggleOpen();
    };
    const renderDots = () => (React.createElement(Popper, { isOpen: isOpen, referenceComponent: React.createElement(S.DotsButton, { type: "button", onClick: handleDotsButtonClick, "data-testid": "ShopItem.DotsButton" },
            React.createElement(Icon, { component: DotsHorizontalIcon, height: 14 })), popperProps: {
            placement: 'bottom-end',
            modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
        }, onClose: () => {
            setOpen(false);
        } },
        React.createElement(S.DropdownContainer, null,
            React.createElement(Dropdown.Item, { label: "Manage", href: adminLink }),
            React.createElement(Dropdown.Item, { label: React.createElement(S.ViewLabelInner, null,
                    "View ",
                    React.createElement(Icon, { component: NewWindowIcon, height: 12 })), href: shopLink, target: "_blank", onClick: toggleOpen }))));
    return (React.createElement(S.Container, { href: adminLink, onMouseLeave: handleContainerMouseLeave },
        React.createElement(S.ItemContainer, null,
            React.createElement(S.ImageContainer, null,
                React.createElement(LogoImage, { src: logoUrl, backgroundUrl: `${shopLink}/platform/theme/background.png`, placeholder: label.charAt(0), size: "small" })),
            React.createElement(S.LabelContainer, null,
                React.createElement(S.Label, null, label),
                React.createElement(S.LabelDescription, { title: labelDescription }, labelDescription))),
        renderDots()));
};
