import { ApiBase } from '../ApiBase';
class PayoutApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getShopPayout = () => {
            return this.get('/shop/payout');
        };
        this.deletePayoutConnection = () => {
            return this.delete('/shop/payout/connection');
        };
        this.getPayoutTransactions = (queryParams) => {
            return this.get('/shop/payout/transactions', queryParams);
        };
        this.startStripeOnboarding = () => {
            return this.post('/payout/onboarding/stripe');
        };
    }
}
export const PayoutApiClient = new PayoutApiClientModel();
