import React, { useState } from 'react';
import { Button, ButtonClean, ButtonGroup, Checkbox, DatePicker, Emoji, Fieldset, Heading, Input, isPastDate, Skeleton, } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import normalizeUrl from 'normalize-url';
import { CopyLink } from '@components/common/CopyLink';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { FeatureFlagDecorator } from '@components/utils/FeatureFlagDecorator';
import { reverse, routing } from '@utils/routing';
import * as S from './styled';
import { validationSchema } from './consts';
const renderDomain = (domain) => (React.createElement(S.CopyLinkWrapper, null,
    React.createElement(CopyLink, { label: normalizeUrl(domain, {
            stripProtocol: true,
            stripWWW: true,
        }), href: normalizeUrl(domain) })));
export const Scheduler = ({ previewUrl, submitting, domain, onSchedule, onGoLive, }) => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const [scheduleSelected, setScheduleSelected] = useState(false);
    const formik = useFormik({
        initialValues: {
            liveFrom: '',
            addPassword: false,
            password: '',
        },
        validationSchema,
        onSubmit: (values) => onSchedule(values),
    });
    const renderDescription = () => {
        if (scheduleSelected) {
            return (React.createElement(React.Fragment, null,
                "Your site will show a coming soon page until the launch time.",
                ' ',
                React.createElement(ButtonClean, { label: "Customize", to: reverse(routing.internal.customizeComingSoon, { pageId: 'password' }) })));
        }
        if (domain) {
            return React.createElement(React.Fragment, null,
                "You can preview your progress at ",
                renderDomain(domain));
        }
        return null;
    };
    const renderTitle = () => {
        if (scheduleSelected) {
            return 'Schedule your launch';
        }
        return (React.createElement(React.Fragment, null,
            "You\u2019re ready to launch! ",
            React.createElement(Emoji, { shortcode: "tada" })));
    };
    const renderHeading = () => (React.createElement("div", { style: { marginBottom: 20 } },
        React.createElement(Heading, { type: "h6", weight: "bold" }, renderTitle()),
        React.createElement(S.Description, null, renderDescription())));
    const renderContent = () => {
        if (scheduleSelected) {
            return (React.createElement("div", null,
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(DatePicker, { type: "single", startLabel: "Launch date & time", showTimeInputs: true, disableDates: isPastDate, ...formik.getFieldProps('liveFrom'), date: formik.values.liveFrom, onDateChange: (date) => {
                                    formik.setFieldValue('liveFrom', date);
                                } }))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Checkbox, { label: "Add a password for early access", ...formik.getFieldProps('addPassword') }))),
                    formik.values.addPassword && (React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Input, { label: "Password", ...formik.getFieldProps('password') })))))));
        }
        return null;
    };
    const renderButtons = () => {
        if (scheduleSelected) {
            return (React.createElement(S.ScheduleButtonsContainer, null,
                React.createElement(Button, { label: "Schedule launch", appearance: "primary", type: "submit", disabled: submitting || !formik.dirty, fullWidth: true }),
                React.createElement(S.ButtonCleanContainer, null,
                    "or",
                    ` `,
                    React.createElement(ButtonClean, { label: "go live now", disabled: submitting, onClick: onGoLive }))));
        }
        return (React.createElement("div", { style: { marginTop: 4 } },
            React.createElement(ButtonGroup, { direction: "column", space: "large" },
                React.createElement(Button, { label: "Go live now", appearance: "primary", fullWidth: true, onClick: onGoLive }),
                React.createElement(Button, { label: "Schedule launch", appearance: "secondary", fullWidth: true, onClick: () => setScheduleSelected(true) }))));
    };
    return (React.createElement(FeatureFlagDecorator, { ...dashboardFeatureFlags.homeScheduler },
        React.createElement("form", { onSubmit: formik.handleSubmit },
            React.createElement(S.Container, null,
                React.createElement(S.PreviewContainer, null,
                    React.createElement(S.PreviewWrapper, null,
                        !previewLoaded && React.createElement(Skeleton.Shape, { height: "100%" }),
                        React.createElement(S.Iframe, { scrolling: "no", src: previewUrl, onLoad: () => setPreviewLoaded(true) }))),
                React.createElement(S.Main, null,
                    renderHeading(),
                    renderContent(),
                    renderButtons())))));
};
