import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { WeightedShippingView } from './WeightedShippingView';
export const WeightedShippingContainer = () => {
    const formik = useFormikContext();
    if (formik.values.pricingMethod !== 'Weighted') {
        return null;
    }
    return React.createElement(WeightedShippingView, { showShippingFee: formik.values.useAdditionalShippingFee });
};
