import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../Icon';
import * as S from './ButtonClean.styled';
const getComponent = ({ to, href }) => {
    if (to)
        return Link;
    if (href)
        return 'a';
    return 'button';
};
export const ButtonClean = ({ appearance = 'primary', ariaLabel, analyticsKey, to, state, href, target, label, title, icon, withUnderline = true, disabled = false, type = 'button', onClick, }) => {
    return (React.createElement(S.Container, { appearance: appearance, as: getComponent({ to, href }), to: to || undefined, state: state, href: href || undefined, target: target || undefined, title: title, "$withUnderline": withUnderline, disabled: disabled, onClick: onClick || undefined, type: type, "aria-label": ariaLabel, "data-testid": "ButtonClean.Container", "data-analytics": analyticsKey },
        icon && (React.createElement(S.IconContainer, { "data-testid": "ButtonClean.Icon", "aria-hidden": "true" },
            React.createElement(Icon, { component: icon, height: 12 }))),
        label));
};
