import { getTierIds } from './getTierIds';
export const getCustomPerkPayload = (formValues) => {
    const { title, description, selectedTiers, type } = formValues;
    const tiers = getTierIds(type, selectedTiers);
    return {
        title,
        description,
        tiers,
    };
};
