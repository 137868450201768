import React from 'react';
import { DnsRecordCodeCell } from '../DnsRecordCodeCell';
import * as S from './DnsRecordListItem.styled';
import { DnsRecordValueCell } from '../DnsRecordValueCell';
import { DnsRecordStatusCell } from '../DnsRecordStatusCell';
import { getErrorTooltipText } from '../utils';
export const DnsRecordListItem = ({ record }) => (React.createElement(S.Container, null,
    React.createElement(S.Main, null,
        React.createElement(S.RecordValues, null,
            React.createElement(S.Group, null,
                React.createElement(S.Label, null, "Type"),
                React.createElement(S.Type, null, record.recordType)),
            React.createElement(S.Group, null,
                React.createElement(S.Label, null, "Host"),
                React.createElement(DnsRecordCodeCell, { value: record.zoneFile?.hostLabel || '', recordAlias: record.alias })),
            React.createElement(S.Group, null,
                React.createElement(S.Label, null, "Value"),
                React.createElement(DnsRecordValueCell, { record: record }))),
        React.createElement(DnsRecordStatusCell, { record: record, isVerificationInProgress: false })),
    record.lookupValidationError && React.createElement(S.Error, null, getErrorTooltipText(record))));
