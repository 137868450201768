import { ApiBase } from './ApiBase';
class GoogleAnalyticsConfigurationApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getConfiguration = () => {
            return this.get('/google-analytics/configuration');
        };
    }
}
export const GoogleAnalyticsConfigurationApiClient = new GoogleAnalyticsConfigurationApiClientModel();
