import { DateString, Dropdown, Icon, Loader, Tag } from '@fourthwall/components';
import { DotsHorizontalIcon, DuplicateIcon, ListIcon, RefreshIcon, RenameIcon, StarOutlineIcon, TrashIcon, } from '@fourthwall/icons';
import React, { useState } from 'react';
import { useDashboardFeatureFlags } from '../../../../../../components/utils/DashboardFeatureFlags';
import { FeatureFlagDecorator } from '../../../../../../components/utils/FeatureFlagDecorator';
import { useAdminModeContext } from '../../../../../App/providers/AdminModeProvider';
import { CanvasImage } from '../../../../components/CanvasImage';
import * as S from './Theme.styled';
export const Theme = ({ theme, onClick, onActivate, onDuplicate, onRename, onReset, onDelete, onChangelogDisplay, }) => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const adminMode = useAdminModeContext();
    const [isOpen, setIsOpen] = useState(false);
    const renderOptions = () => {
        return (React.createElement(S.OptionsContainer, null,
            React.createElement(Dropdown, { isOpen: isOpen, content: React.createElement(React.Fragment, null,
                    theme.isMain ? null : (React.createElement(Dropdown.Item, { label: React.createElement(S.DropdownItemLabel, null,
                            React.createElement(Icon, { component: StarOutlineIcon, height: 16 }),
                            "Set as active theme"), onClick: () => {
                            onActivate();
                            setIsOpen(false);
                        } })),
                    React.createElement(Dropdown.Item, { label: React.createElement(S.DropdownItemLabel, null,
                            React.createElement(Icon, { component: DuplicateIcon, height: 16 }),
                            "Duplicate"), onClick: () => {
                            onDuplicate();
                            setIsOpen(false);
                        } }),
                    React.createElement(Dropdown.Item, { label: React.createElement(S.DropdownItemLabel, null,
                            React.createElement(Icon, { component: RenameIcon, height: 16 }),
                            "Rename"), onClick: () => {
                            onRename();
                            setIsOpen(false);
                        } }),
                    React.createElement(Dropdown.Item, { label: React.createElement(S.DropdownItemLabel, null,
                            React.createElement(Icon, { component: RefreshIcon, height: 16 }),
                            "Reset to default"), onClick: () => {
                            onReset();
                            setIsOpen(false);
                        } }),
                    theme.isMain ? null : (React.createElement(Dropdown.Item, { label: React.createElement(S.DropdownItemLabel, null,
                            React.createElement(Icon, { component: TrashIcon, height: 16 }),
                            "Delete"), onClick: () => {
                            onDelete();
                            setIsOpen(false);
                        } })),
                    adminMode?.isActive && (React.createElement(Dropdown.Item, { label: React.createElement(S.DropdownItemLabel, null,
                            React.createElement(Icon, { component: ListIcon, height: 16 }),
                            "Changelog"), onClick: () => {
                            onChangelogDisplay();
                            setIsOpen(false);
                        } }))), onClose: () => setIsOpen(false) },
                React.createElement(S.DropdownButton, { isOpen: !!isOpen, onClick: () => setIsOpen(!isOpen) },
                    React.createElement(Icon, { component: DotsHorizontalIcon, height: 14 })))));
    };
    return (React.createElement(S.Container, { isActive: !!theme.isActive },
        React.createElement(S.Card, { onClick: onClick },
            theme.status && (React.createElement(S.Status, null,
                React.createElement(Loader, { size: "small", appearance: "primary" }),
                theme.status)),
            React.createElement(S.ImageContainer, { isTransparent: !!theme.isProcessing },
                theme.image && (React.createElement(CanvasImage, { key: `${theme.name}${theme.preset || ''}`, src: theme.image, width: 312, height: 200 })),
                theme.tags && (React.createElement(S.TagsContainer, null,
                    React.createElement(FeatureFlagDecorator, { ...dashboardFeatureFlags.storeDesignThemeVersion, spacing: "small", descriptionPosition: "outside" },
                        React.createElement(S.BadgesContainer, null,
                            theme.tags.map((tag) => (React.createElement(Tag, { key: tag, label: tag }))),
                            theme.isCustomized && React.createElement(Tag, { appearance: "caution", label: "Custom theme" })))))),
            React.createElement(S.DetailsContainer, null,
                React.createElement(S.ThemeName, null, theme.name),
                theme.isMain ? React.createElement(Tag, { label: "Live", appearance: "success" }) : React.createElement(Tag, { label: "Inactive" })),
            theme.updatedAt && (React.createElement(S.LastModified, null,
                "Last modified ",
                React.createElement(DateString, { date: theme.updatedAt, format: "MMM DD" })))),
        renderOptions()));
};
