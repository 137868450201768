import React from 'react';
import { Subsection, Banner, BaseContent, Button, DateString } from '@fourthwall/components';
import { IDENTITY_PROVIDERS } from './identity-providers';
import { CONNECTION_ICONS } from './connection-icons';
import { CredentialList } from './components';
export const CONNECTION_ICONS_MAP = {
    'twitter-v2': 'x',
    apple: 'apple',
    facebook: 'meta',
    google: 'google',
    twitch: 'twitch',
    youtube: 'youtube',
};
export const SecurityView = ({ password, passkeys, authenticators }) => {
    const renderBanner = () => {
        if (!passkeys || !authenticators)
            return null;
        if (passkeys.credentials.length + authenticators.credentials.length > 1)
            return null;
        return (React.createElement(Subsection, null,
            React.createElement(Banner, null,
                React.createElement("p", null, "We recommend adding more than one 2FA method to your account. This ensures that you can still sign in to your account, even if you lose one of your methods."))));
    };
    return (React.createElement(React.Fragment, null,
        renderBanner(),
        password && (React.createElement(Subsection, { title: "Password" },
            React.createElement("div", { style: { display: 'grid', gap: 12 } },
                password.identityProviders.map((identityProvider) => (React.createElement(React.Fragment, null,
                    React.createElement(BaseContent, null,
                        React.createElement("p", { style: { display: 'flex', gap: 8, alignItems: 'center' } },
                            React.createElement("img", { src: CONNECTION_ICONS[CONNECTION_ICONS_MAP[identityProvider]], alt: CONNECTION_ICONS_MAP[identityProvider], style: { width: 20, height: 20 } }),
                            React.createElement("strong", null,
                                "You\u2019re using ",
                                IDENTITY_PROVIDERS[identityProvider],
                                " to login to Fourthwall")))))),
                React.createElement(BaseContent, null, password.createdDate ? (React.createElement("p", null,
                    "You\u2019ve changed your password on",
                    ' ',
                    React.createElement(DateString, { date: password.createdDate, format: "MMM D, YYYY" }),
                    ".",
                    React.createElement("br", null),
                    React.createElement("a", { href: password.updatePasswordUrl }, "Change password"))) : (React.createElement("p", null,
                    "You\u2019ve never set your password.",
                    React.createElement("br", null),
                    React.createElement("a", { href: password.updatePasswordUrl }, "Set password"))))))),
        passkeys && (React.createElement(Subsection, { title: "Passkey (recommended)" },
            React.createElement(BaseContent, null,
                React.createElement("p", null, "Use your passkey (e.g., face or fingerprint scan) to sign in.")),
            !!passkeys.credentials.length && (React.createElement("div", { style: { marginTop: 12 } },
                React.createElement(CredentialList, { items: passkeys.credentials.map((credential) => ({
                        ...credential,
                        description: (React.createElement(DateString, { date: credential.createdDate, format: "[Created] MMM DD, YYYY [at] h:mm A" })),
                    })), onDelete: passkeys.onDelete }))),
            React.createElement("div", { style: { marginTop: 16, display: 'flex' } }, passkeys.credentials.length === 0 ? (React.createElement(Button, { label: "Set up passkey", href: passkeys.setUpUrl })) : (React.createElement(BaseContent, null,
                React.createElement("a", { href: passkeys.setUpUrl }, "Set up another passkey")))))),
        authenticators && (React.createElement(Subsection, { title: "Two-factor authenticator application" },
            React.createElement(BaseContent, null,
                React.createElement("p", null, "Enter a code from authenticator application to sign in.")),
            !!authenticators.credentials.length && (React.createElement("div", { style: { marginTop: 12 } },
                React.createElement(CredentialList, { items: authenticators.credentials.map((credential) => ({
                        ...credential,
                        description: (React.createElement(DateString, { date: credential.createdDate, format: "[Created] MMM DD, YYYY [at] h:mm A" })),
                    })), onDelete: authenticators.onDelete }))),
            React.createElement("div", { style: { marginTop: 16, display: 'flex' } }, authenticators.credentials.length === 0 ? (React.createElement(Button, { label: "Set up authenticator application", href: authenticators.setUpUrl })) : (React.createElement(BaseContent, null,
                React.createElement("a", { href: authenticators.setUpUrl }, "Set up another authenticator application"))))))));
};
