import { useModalContext } from '@fourthwall/components';
import React, { useState } from 'react';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { useSetShopDefaultPaymentMethodMutation } from '../../hooks/useSetShopDefaultPaymentMethodMutation';
import { useShopPaymentMethods } from '../../hooks/useShopPaymentMethods';
import { isPaymentMethodFailed } from '../../utils';
import { AddPaymentMethodModal } from '../AddPaymentMethodModal';
import { PaymentMethodRequiredModalView } from './PaymentMethodRequiredModalView';
import { getErrorBanner } from './utils';
export const PaymentMethodRequiredModalContainer = (props) => {
    const { title = 'Add a new payment method', subtitle, submitLabel = 'Save and continue', selectPaymentMethodSubtitle, onSuccess, } = props;
    const { close, open } = useModalContext();
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(undefined);
    const handlePaymentMethodsQuerySuccess = (data) => {
        const hasAnyShopPaymentMethod = data.result.length !== 0;
        const defaultPaymentMethod = data.result.find((paymentMethod) => paymentMethod.default);
        // NOTE: when there is no payment method just open AddPaymentMethodModal and finish process by calling onSuccess
        if (!hasAnyShopPaymentMethod) {
            close();
            open(AddPaymentMethodModal, {
                title,
                subtitle,
                submitLabel,
                onSuccess,
            });
        }
        if (defaultPaymentMethod && !isPaymentMethodFailed(defaultPaymentMethod)) {
            setSelectedPaymentMethodId(defaultPaymentMethod.id);
        }
    };
    const { shopPaymentMethods, isFetchingShopPaymentMethods, invalidateShopPaymentMethods } = useShopPaymentMethods({
        onSuccess: handlePaymentMethodsQuerySuccess,
    });
    const { invalidateCurrentShopQuery } = useCurrentShopQuery();
    const { setShopDefaultPaymentMethodMutation } = useSetShopDefaultPaymentMethodMutation();
    const shopPaymentMethodsProps = shopPaymentMethods?.map((paymentMethod) => ({
        ...paymentMethod,
        selected: selectedPaymentMethodId === paymentMethod.id,
        onClick: () => setSelectedPaymentMethodId(paymentMethod.id),
    }));
    const handleSubmit = () => {
        if (!selectedPaymentMethodId) {
            return;
        }
        setShopDefaultPaymentMethodMutation.mutate([selectedPaymentMethodId], {
            onSuccess: () => {
                close();
                onSuccess();
                invalidateShopPaymentMethods();
                invalidateCurrentShopQuery();
            },
        });
    };
    const handleAddPaymentMethod = () => {
        close();
        open(AddPaymentMethodModal, {
            title: 'Add a new payment method',
            onSuccess: () => {
                open(PaymentMethodRequiredModalContainer, props);
            },
        });
    };
    return (React.createElement(PaymentMethodRequiredModalView, { title: title, subtitle: selectPaymentMethodSubtitle, submitLabel: submitLabel, paymentMethodsProps: shopPaymentMethodsProps, errorBanner: getErrorBanner(shopPaymentMethodsProps), isSubmitting: setShopDefaultPaymentMethodMutation.isLoading, isLoading: isFetchingShopPaymentMethods, isDisabled: !selectedPaymentMethodId, onAddPaymentMethod: handleAddPaymentMethod, onSubmit: handleSubmit, onClose: close }));
};
