import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
`;
export const Description = styled.div `
  margin-left: 16px;
  max-width: 224px;

  ${mediaQueries.tablet} {
    max-width: none;
  }
`;
export const Title = styled.div `
  word-break: break-all;
`;
export const Subtitle = styled.div `
  margin-top: 5px;
  font-size: 14px;
  color: ${Colors.GS600};
`;
