import { ApiBase } from './ApiBase';
class AgencyApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getCurrentAgency = () => {
            return this.get('/agency/current');
        };
        this.getAgencyShops = (params) => {
            return this.get('/agency/shops', params);
        };
        this.createAgencyShop = (payload) => {
            return this.post('/agency/shops', payload);
        };
        this.getAgencyUsers = () => {
            return this.get('/agency/users');
        };
        this.getAgencyOffers = (params) => {
            return this.get('/agency/offers', params);
        };
    }
}
export const AgencyApiClient = new AgencyApiClientModel();
