import { ApiBase } from './ApiBase';
class StockApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.createStock = (payload) => {
            return this.post('/stock', payload);
        };
        // getStock = (queryParams: GetStock['QueryParams']) => {
        //   return this.get<GetStock['Response']>('/stock', queryParams)
        // }
        this.updateStock = (queryParams, payload) => {
            return this.put('/stock', payload, queryParams);
        };
        this.deleteStock = (queryParams) => {
            return this.delete('/stock', undefined, queryParams);
        };
    }
}
export const StockApiClient = new StockApiClientModel();
