import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: 6px;

  color: ${Colors.R300};

  cursor: help;
`;
