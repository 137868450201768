import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr;
`;
export const Header = styled.header `
  padding: 24px 24px 32px;
  background: ${Colors.GS1000};
  border-bottom: 1px solid ${Colors.GS800};

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  color: ${Colors.GS100};

  h6 {
    color: inherit;
  }
`;
export const HeaderLogo = styled.div `
  position: absolute;
  left: 24px;
  top: 20px;
`;
export const Headline = styled.div `
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const HeaderButtons = styled.div `
  margin-top: 16px;

  display: flex;
  justify-content: center;
`;
export const PreviewIframe = styled.iframe `
  display: block;
  width: 100%;
  height: 100%;
`;
