import { SECTION_NAMES } from '@constants/sectionNames';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { routing } from '@utils/routing';
import { useAdminModeContext } from '../../App/providers/AdminModeProvider';
import { useCurrentShopDashboardQuery } from './useCurrentShopDashboardQuery';
export const useNavigations = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { hasPermission, hasAnyPermission } = useDashboardPermissions();
    const { currentShopDashboardQuery } = useCurrentShopDashboardQuery(undefined, {
        enabled: hasAnyPermission(),
    });
    const adminMode = useAdminModeContext();
    const ordersToManuallyFulfill = currentShopDashboardQuery.data?.ordersToManuallyFulfill;
    const getNavigations = () => [
        {
            label: SECTION_NAMES.home,
            to: routing.home,
            exact: true,
            hidden: !hasPermission('home'),
        },
        {
            label: 'Orders',
            to: routing.contributions.self,
            hidden: !hasPermission('contributions'),
            items: [
                {
                    label: 'All orders',
                    to: routing.contributions.orders.all,
                    hidden: !hasPermission('contributions.orders.view'),
                },
                {
                    label: 'Thank yous',
                    to: routing.contributions.thankYous.self,
                    hidden: !hasPermission('contributions.thankYous'),
                },
                {
                    label: 'Fulfillment',
                    to: routing.contributions.fulfillment,
                    hidden: (!ordersToManuallyFulfill ||
                        ordersToManuallyFulfill === 0 ||
                        !hasPermission('contributions.fulfillments.view')) &&
                        !adminMode?.isActive,
                },
                {
                    label: 'Messages',
                    to: routing.contributions.creatorMessages.self,
                    hidden: !dashboardFeatureFlags.messages.enabled ||
                        !hasPermission('contributions.messages.view'),
                },
            ],
        },
        {
            label: 'Products',
            to: routing.products.self,
            hidden: !hasPermission('products') && !hasPermission('collections'),
            items: [
                {
                    label: 'All products',
                    to: routing.products.all.self,
                    hidden: !hasPermission('products.view'),
                },
                {
                    label: 'Collections',
                    to: routing.products.collections.self,
                    hidden: !hasPermission('collections.view'),
                },
            ],
        },
        {
            label: 'Promotions',
            to: routing.promotions.self,
            hidden: !hasPermission('promotions.shopPromoCodes') && !hasPermission('promotions.giveawayLinks'),
        },
        {
            label: 'Memberships',
            to: routing.memberships.self,
            hidden: !dashboardFeatureFlags.memberships.enabled || !hasPermission('memberships'),
            items: [
                {
                    label: 'Overview',
                    to: routing.memberships.overview,
                    hidden: !hasPermission('memberships.overview'),
                },
                {
                    label: 'Posts',
                    to: routing.memberships.posts.self,
                },
                {
                    label: 'Messages',
                    to: routing.memberships.messages.self,
                    hidden: !dashboardFeatureFlags.membershipsMessages.enabled ||
                        !hasPermission('memberships.messages'),
                },
                {
                    label: 'Video series',
                    to: routing.memberships.series.self,
                },
                {
                    label: 'Members',
                    to: routing.memberships.members.self,
                    hidden: !hasPermission('memberships.members'),
                },
                {
                    label: 'Perks & tiers',
                    to: routing.memberships.perks,
                    hidden: !hasPermission('memberships.perksAndTiers'),
                },
                {
                    label: 'Settings',
                    to: routing.memberships.settings.self,
                    hidden: !hasPermission('memberships.settings'),
                },
            ],
        },
        {
            label: 'Memberships',
            to: routing.membershipsEnable.self,
            hidden: !dashboardFeatureFlags.membershipsSplash.enabled || !hasPermission('memberships.setup'),
        },
        {
            label: SECTION_NAMES.themeEditor,
            to: routing.themeEditor.self,
            hidden: !hasPermission('themeEditor'),
        },
        {
            label: 'Analytics',
            to: routing.analytics.self,
            hidden: !hasPermission('analytics'),
        },
        {
            label: SECTION_NAMES.apps,
            to: routing.apps.self,
            hidden: !hasPermission('apps'),
        },
        {
            label: 'Settings',
            to: routing.settings.self,
            hidden: !hasPermission('settings'),
        },
    ];
    const getFirstVisibleNavigation = (scope) => {
        const navigations = getNavigations();
        const targetNavigation = scope
            ? navigations.find((navigation) => navigation.to === scope)
            : navigations.find((navigation) => !navigation.hidden);
        if (!targetNavigation) {
            return routing.home;
        }
        const targetItem = targetNavigation.items?.find((item) => !item.hidden);
        return targetItem?.to ?? targetNavigation.to;
    };
    return {
        navigations: getNavigations(),
        getFirstVisibleNavigation,
    };
};
