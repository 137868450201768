import { useModalContext } from '@fourthwall/components';
import { useLocation, useParams } from 'react-router-dom';
import { useDashboardPermissions } from '../../../../../../../components/utils/DashboardPermissions';
import { GiveawayCostsBreakdown, useGiveawayContributionCostsBreakdownQuery, } from '../../modules/GiveawayCostsBreakdown';
import { GiveawayTwitchRedo, useGiveawayGiftsQuery } from '../../modules/GiveawayTwitchRedo';
import { useGiveawayContributionQuery } from '../useGiveawayContributionQuery';
import { getBanners, getDate, getDetails, getItems, getItemsSummary, getSummary, getTitle, } from './utils';
export const useGiveaway = () => {
    const { giveawayId = '' } = useParams();
    const { hasPermission } = useDashboardPermissions();
    const { giveawayContributionQuery } = useGiveawayContributionQuery([{ giveawayId }]);
    const location = useLocation();
    const { open } = useModalContext();
    // NOTE: We use costs breakdown query hook to prefetch breakdown data before displaying the modal.
    useGiveawayContributionCostsBreakdownQuery([{ giveawayId }]);
    // NOTE: We use costs gifts query hook to prefetch gifts data before displaying the modal.
    useGiveawayGiftsQuery([{ giveawayId }]);
    const handleBreakdownShowClick = () => {
        open(GiveawayCostsBreakdown, { giveawayId });
    };
    const handleRedoGiveawayClick = () => {
        open(GiveawayTwitchRedo, { giveawayId });
    };
    const getToolbarDropdownItems = () => {
        if (!giveawayContributionQuery.data)
            return [];
        const dropdownItems = [];
        if (hasPermission('contributions.orders.redoGiveaway')) {
            dropdownItems.push({
                label: 'Redo giveaway on Twitch',
                onClick: handleRedoGiveawayClick,
            });
        }
        return dropdownItems;
    };
    return {
        giveaway: {
            title: getTitle(giveawayContributionQuery.data),
            date: getDate(giveawayContributionQuery.data),
            banners: getBanners(giveawayContributionQuery.data, location, {
                onRedoGiveawayClick: hasPermission('contributions.orders.redoGiveaway')
                    ? handleRedoGiveawayClick
                    : undefined,
            }),
            details: getDetails(giveawayContributionQuery.data),
            items: getItems(giveawayContributionQuery.data),
            itemsSummary: getItemsSummary(giveawayContributionQuery.data),
            summary: getSummary(giveawayContributionQuery.data, handleBreakdownShowClick, {
                withProfit: hasPermission('contributions.profit'),
            }),
            toolbarDropdownItems: getToolbarDropdownItems(),
            message: giveawayContributionQuery.data?.supporter.message,
            isLoading: giveawayContributionQuery.isLoading,
        },
    };
};
