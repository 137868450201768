import { Button, Fieldset, Icon, Input, Loading, ModalBox, Toasts, Upload, useModalContext, } from '@fourthwall/components';
import { ArrowShortDownIcon, ArrowShortUpIcon, UploadIcon } from '@fourthwall/icons';
import React, { useEffect, useState } from 'react';
import { getRejectionErrors } from '../../../../../../../utils/dropzone';
import { useCreateImageResources } from '../../../../hooks/useCreateImageResources';
import * as S from './styled';
export const ImageUploadModal = ({ src, width, height, alt, onConfirm }) => {
    const modal = useModalContext();
    const { createImageResources, isLoading } = useCreateImageResources();
    const [advancedVisible, setAdvancedVisible] = useState(false);
    const [imageSrc, setImageSrc] = useState(src || '');
    const [imageAlt, setImageAlt] = useState(alt || '');
    const [imageWidth, setImageWidth] = useState(width);
    const [imageHeight, setImageHeight] = useState(height);
    useEffect(() => {
        if (alt)
            setAdvancedVisible(true);
    }, []);
    const handleImageUpload = (files) => {
        if (!files)
            return;
        createImageResources({
            files: [files[0]],
            onSuccess: (images) => {
                const image = new Image();
                image.src = images[0].url;
                image.onload = () => {
                    setImageWidth(image.width);
                    setImageHeight(image.height);
                    setImageSrc(images[0].url);
                    image.remove();
                };
            },
        });
    };
    const handleDropRejection = (rejections) => {
        getRejectionErrors(rejections).forEach((rejection) => {
            Toasts.notify(rejection);
        });
    };
    const renderImagePreview = () => {
        if (!imageSrc)
            return null;
        return (React.createElement(S.ImageContainer, null,
            React.createElement(S.Image, { src: imageSrc, width: imageWidth, height: imageHeight })));
    };
    const renderUpload = () => {
        if (imageSrc)
            return null;
        return (React.createElement(S.UploadContainer, null,
            React.createElement(Loading, { isLoading: isLoading },
                React.createElement(Upload, { accept: {
                        'image/*': [],
                    }, title: "Drop image or browse", disabled: isLoading, icon: UploadIcon, iconSize: 20, maxSize: 10485760, orientation: "horizontal", onFileDrop: handleImageUpload, onFileDropRejected: handleDropRejection }))));
    };
    return (React.createElement(ModalBox, { title: src ? 'Edit image' : 'Upload image', size: "small", buttons: [
            React.createElement(Button, { label: "Cancel", onClick: modal.close }),
            React.createElement(Button, { label: "Confirm", appearance: "primary", type: "submit", disabled: isLoading || !imageSrc || !imageWidth || !imageHeight, onClick: () => {
                    onConfirm(imageSrc, imageWidth, imageHeight, imageAlt);
                    modal.close();
                } }),
        ], onClose: modal.close },
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                renderImagePreview(),
                renderUpload()),
            React.createElement(Fieldset.Row, null,
                React.createElement(S.AdvancedButton, { type: "button", onClick: () => setAdvancedVisible(!advancedVisible) },
                    "Advanced",
                    ' ',
                    React.createElement(Icon, { component: advancedVisible ? ArrowShortUpIcon : ArrowShortDownIcon, height: 12 }))),
            advancedVisible && (React.createElement(Fieldset.Row, { variant: "small" },
                React.createElement(Input, { label: "Image alt text", value: imageAlt, fullWidth: true, disabled: isLoading, suggestion: "Used to describe images for screen readers and provide context when images fail to load.", onChange: (e) => setImageAlt(e.target.value) }))))));
};
