import React from 'react';
import * as S from './EditorPanel.styled';
import { EditorPanelHeader } from './EditorPanelHeader/EditorPanelHeader';
import { EditorPanelSubheader } from './EditorPanelSubheader/EditorPanelSubheader';
import { EditorPanelWrapper } from './EditorPanelWrapper/EditorPanelWrapper';
export const EditorPanel = ({ children }) => {
    return React.createElement(S.Container, null, children);
};
EditorPanel.Wrapper = EditorPanelWrapper;
EditorPanel.Header = EditorPanelHeader;
EditorPanel.Subheader = EditorPanelSubheader;
