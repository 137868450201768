import { mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;
export const Wrapper = styled.div `
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  z-index: 0;

  transition-property: max-width, max-height;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);

  position: relative;
  user-select: none;

  overflow: hidden;

  ${mediaQueries.tablet} {
    ${({ previewMode }) => previewMode === 'mobile' &&
    css `
        max-width: 375px;
        max-height: 667px;
      `}
  }
`;
