var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { ApiBase } from '../ApiBase';
class ProductsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getProductBySlug = (slug) => {
            return this.get(`/products/slug/${slug}`);
        };
        this.getProductReviews = (productId, queryParams) => {
            return this.get(`/products/${productId}/reviews`, queryParams);
        };
        this.uploadReviewImage = (file) => __awaiter(this, void 0, void 0, function* () {
            const fileType = file.type || 'application/octet-stream';
            const response = yield this.post(`/reviews/images`, { fileName: file.name, contentType: fileType, size: file.size });
            const { uploadUrl } = response.data;
            yield axios.put(uploadUrl, file, {
                headers: {
                    'Content-Disposition': `attachment; filename="${file.name}"`,
                    'Content-Type': fileType,
                    'x-goog-content-length-range': `0,${file.size}`,
                },
            });
            return response;
        });
        this.addProductReview = (requestBody) => {
            return this.post(`/reviews/products`, requestBody);
        };
        this.voteForReview = (requestParams) => {
            return this.post(`/reviews/${requestParams.reviewId}/vote`);
        };
    }
}
export const ProductsApiClient = new ProductsApiClientModel();
