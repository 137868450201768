import { Toasts, Tooltip } from '@fourthwall/components';
import { TickIcon } from '@fourthwall/icons';
import React from 'react';
import { useCopyToClipboard } from '@components/hooks/useCopyToClipboard';
export const CopyableText = ({ text, tooltipText = 'Copy to clipboard', copiedTooltipText = 'Value copied to clipboard', children, }) => {
    const { isCopied, copyToClipboard } = useCopyToClipboard();
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            copyToClipboard(text);
            Toasts.notify(copiedTooltipText, { type: 'info' });
        }
    };
    const renderTooltip = () => {
        if (isCopied) {
            return (React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                React.createElement(TickIcon, null),
                "\u00A0",
                copiedTooltipText));
        }
        return tooltipText;
    };
    return (React.createElement("div", { role: "button", tabIndex: 0, onKeyDown: handleKeyDown, onClick: () => copyToClipboard(text) },
        React.createElement(Tooltip, { reference: children, placement: "top", fluid: true }, renderTooltip())));
};
