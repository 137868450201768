import { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { Toasts } from '@fourthwall/components';
const MESSAGES = {
    USER_ALREADY_CONNECTED: 'This StreamElements account is already connected to another Fourthwall site.',
};
const isValidError = (error) => {
    return Object.keys(MESSAGES).includes(error);
};
export const useErrorQueryParam = () => {
    const [errorQueryParam, setErrorQueryParam] = useQueryParam('error', StringParam);
    useEffect(() => {
        if (errorQueryParam) {
            if (!isValidError(errorQueryParam)) {
                throw new Error(`Unknown error query param: ${errorQueryParam}.`);
            }
            Toasts.notify(MESSAGES[errorQueryParam], { type: 'error' });
            setErrorQueryParam(undefined, 'replaceIn');
        }
    }, [errorQueryParam, setErrorQueryParam]);
};
