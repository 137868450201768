import React, { createContext, useContext, useState, useMemo } from 'react';
const TableBodyContext = createContext(undefined);
export const TableBodyProvider = ({ children }) => {
    const [dropdownKey, setDropdownKey] = useState(0);
    const value = useMemo(() => ({ dropdownKey, setDropdownKey }), [dropdownKey]);
    return React.createElement(TableBodyContext.Provider, { value: value }, children);
};
export const useTableBody = () => {
    const context = useContext(TableBodyContext);
    if (context === undefined) {
        throw new Error('useTable must be called within a TableBodyContextProvider');
    }
    return context;
};
