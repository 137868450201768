import { Select, Subsection } from '@fourthwall/components';
import { getTimezoneAbbreviation } from '@fourthwall/utils/lib/timezone';
import { Field } from 'formik';
import React from 'react';
import { SectionDescription } from '../SectionDescription';
import { generateTimeArray } from './generateTimeArray';
import * as S from './styled';
export const ThemeAutoUpdate = () => {
    const timezoneOffsetInHours = new Date().getTimezoneOffset() / 60;
    return (React.createElement(Subsection, { title: "Theme auto-updates" },
        React.createElement(SectionDescription, null, "We regularly update themes to improve their performance, fix issues, and add more customization options. Theme updates should not impact the look and feel of your site."),
        React.createElement(S.HourSelectWrapper, null,
            React.createElement(Field, { component: Select, name: "themeAutoUpdateHour", label: `Update time (${getTimezoneAbbreviation()})`, options: generateTimeArray(timezoneOffsetInHours) }))));
};
