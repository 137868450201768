import React from 'react';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { Icon, Input, Tooltip, useMediaQueryContext } from '@fourthwall/components';
import { max } from '@fourthwall/utils/lib/validation';
import { FastField } from 'formik';
import { CloseIcon } from '@fourthwall/icons';
import * as S from './styled';
import { VariantPrice } from '../VariantPrice';
import { QuantityDropdown } from '../QuantityDropdown';
export const SamplesOrdersListItem = ({ orderItem, index, quantity, onRemove, onSetQuantity, }) => {
    const media = useMediaQueryContext();
    const renderRemoveButton = () => (React.createElement(S.RemoveButton, { onClick: onRemove },
        React.createElement(Icon, { component: CloseIcon, height: media.tablet ? 16 : 14 })));
    return (React.createElement(S.Container, null,
        React.createElement(S.Product, null,
            React.createElement(PhotoThumbnail, { url: orderItem.image, size: media.tablet ? 'large' : 'medium' }),
            React.createElement(S.ProductDetails, null,
                React.createElement("div", null, orderItem.name),
                React.createElement(S.Description, null, orderItem.description),
                !media.tablet && (React.createElement(S.QuantityDropdownWrapper, null,
                    "Quantity:",
                    React.createElement(QuantityDropdown, { title: `${orderItem.name}${orderItem.description ? `, ${orderItem.description}` : ''}`, description: typeof orderItem.stock === 'number'
                            ? `${orderItem.stock} in stock`
                            : 'Unlimited stock', quantity: quantity, maxValue: orderItem.stock, onSubmit: onSetQuantity }))))),
        media.tablet && (React.createElement(S.Quantity, null,
            React.createElement(FastField, { component: Input, name: `items[${index}].quantity`, validate: orderItem.stock
                    ? max(orderItem.stock, `Only ${orderItem.stock} in stock.`)
                    : undefined, type: "number", size: "medium", fullWidth: true, min: 1, max: orderItem.stock }))),
        React.createElement(S.Price, null, orderItem.price && orderItem.oldPrice && (React.createElement(VariantPrice, { price: orderItem.price, oldPrice: orderItem.oldPrice, tooltip: "You only pay the manufacturing price for samples" }))),
        React.createElement(S.RemoveButtonWrapper, null, media.tablet ? (React.createElement(Tooltip, { reference: renderRemoveButton(), placement: "bottom" }, "Remove")) : (React.createElement(S.RemoveButton, { onClick: onRemove },
            React.createElement(Icon, { component: CloseIcon, height: media.tablet ? 16 : 14 }))))));
};
