import { Toasts } from '@fourthwall/components';
import { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useStartStripeOnboardingMutation } from '../useStartStripeOnboardingMutation';
export const SUCCESS_CODE = 'ONBOARDING_SUCCESS';
export const useStripeOnboardingFlow = () => {
    const { startStripeOnboardingMutation } = useStartStripeOnboardingMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [stripeOnboardingMessage, setStripeOnboardingMessage] = useQueryParam('msg_code', StringParam);
    useEffect(() => {
        if (stripeOnboardingMessage) {
            setStripeOnboardingMessage(undefined);
            if (stripeOnboardingMessage === SUCCESS_CODE) {
                Toasts.notify('Your payout method has been successfully added!', { type: 'success' });
            }
            else {
                Toasts.notify('We were unable to add your payout method. Please try again.', {
                    type: 'error',
                });
            }
        }
    }, [stripeOnboardingMessage]);
    const startStripeOnboarding = () => {
        setIsLoading(true);
        startStripeOnboardingMutation.mutate([], {
            onSuccess: ({ onboardingUrl }) => {
                window.location.assign(onboardingUrl);
            },
            onError: () => {
                setIsLoading(false);
            },
        });
    };
    return {
        isLoading: startStripeOnboardingMutation.isLoading || isLoading,
        startStripeOnboarding,
    };
};
