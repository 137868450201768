import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const ProductName = styled.div `
  display: flex;
  align-items: center;
`;
export const ProductStatus = styled.div `
  display: flex;
  align-items: center;
  height: 100%;
`;
export const ProductInventory = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;
export const ProductItemDetails = styled.div `
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const ProductItemActions = styled.div `
  display: flex;
  align-items: center;
`;
export const ProductDetails = styled.div `
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
