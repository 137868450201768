import { VIMEO_LIVESTREAM_REGEX } from '../../../../../consts';
export const getVimeoLiveStreamId = (link) => {
    if (!link.match(VIMEO_LIVESTREAM_REGEX)) {
        return null;
    }
    return {
        id: link.match(VIMEO_LIVESTREAM_REGEX)?.groups?.id,
        unlistedHash: link.match(VIMEO_LIVESTREAM_REGEX)?.groups?.unlistedHash,
    };
};
