import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  .tox-tinymce {
    border: none !important;
    border-radius: 0 !important;
  }

  .tox-editor-header {
    padding: 0 !important;
    box-shadow: none !important;

    z-index: initial !important;
  }

  .tox-toolbar {
    padding: 4px !important;
    background-image: none !important;

    gap: 4px !important;
  }

  .tox-toolbar__group {
    padding: 0 !important;

    flex: 1 !important;
    gap: 4px !important;
  }

  .tox-toolbar__group:nth-child(2) {
    margin-left: auto !important;
  }

  .tox-tbtn {
    width: 32px !important;
    height: 32px !important;
    margin: 0 !important;
    border-radius: 0 !important;

    cursor: pointer !important;
  }

  .tox-tbtn--bespoke,
  .tox-tbtn--bespoke:hover,
  .tox-tbtn--bespoke:focus {
    background: ${Colors.GS300} !important;
  }

  .tox-tbtn--select {
    width: auto !important;
  }

  .tox-tbtn svg {
    fill: ${Colors.GS1000} !important;
  }

  .tox-tbtn--disabled svg {
    opacity: 0.4 !important;
  }

  .tox-tbtn:hover,
  .tox-tbtn:focus {
    background: ${Colors.GS300} !important;
  }

  .tox-tbtn--enabled,
  .tox-tbtn--enabled:hover,
  .tox-tbtn--enabled:focus {
    background: ${Colors.GS400} !important;
  }

  .tox-tbtn[aria-label='Source code'],
  .tox-tbtn[aria-label='Fullscreen'] {
    margin-left: auto !important;
  }

  .tox-tbtn[aria-label='Source code'] + .tox-tbtn[aria-label='Fullscreen'] {
    margin-left: 0 !important;
  }

  /* disabled */
  .tox-tinymce--disabled .tox-toolbar,
  .tox-tinymce--disabled iframe {
    background: ${Colors.GS200} !important;
  }

  .tox-tinymce--disabled .tox-tbtn {
    cursor: not-allowed !important;
  }
`;
