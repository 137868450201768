import React from 'react';
import { useParams } from 'react-router-dom';
import { InventoryShipment } from '@components/common/InventoryShipment/InventoryShipment';
import { useInventoryShipmentsQuery } from '@components/hooks/useInventoryShipmentsQuery';
import { ButtonClean } from '@fourthwall/components';
import { routing } from '@utils/routing';
import * as S from './InventoryShipmentSection.styled';
export const InventoryShipmentSection = () => {
    const { productId = '' } = useParams();
    const { inventoryShipmentsQuery } = useInventoryShipmentsQuery([{ offerId: productId }]);
    if (!inventoryShipmentsQuery.data || inventoryShipmentsQuery.data.length === 0) {
        return null;
    }
    return (React.createElement(S.Container, null,
        React.createElement(S.Head, null,
            React.createElement("h4", null, "Inventory shipments of this product"),
            React.createElement(ButtonClean, { label: "View all", to: routing.settings.shipping })),
        React.createElement(S.Content, null,
            React.createElement(InventoryShipment, { data: inventoryShipmentsQuery.data || [], isLoading: inventoryShipmentsQuery.isLoading }))));
};
