import { InputUI, Loader, Loading, Subsection, Toasts, Upload, useMediaQueryContext, } from '@fourthwall/components';
import { UploadIcon } from '@fourthwall/icons';
import { arrayMove } from '@fourthwall/utils/lib/array';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import get from 'lodash/get';
import React from 'react';
import { getRejectionErrors } from '../../../../../../../utils/dropzone';
import { PostGallery } from '../../../../components/PostGallery/PostGallery';
import { RemovableUpload } from '../../../../components/RemovableUpload/RemovableUpload';
import { UploadedImage } from '../../../../components/UploadedImage/UploadedImage';
import { MAX_IMAGE_POST_IMAGES } from '../../../../consts';
import * as S from './styled';
export const ImagesFields = ({ imagesFieldName, subsectionTitle, isUploading, isLoading, onImagesUpload, }) => {
    const media = useMediaQueryContext();
    const { values, errors, setFieldValue } = useFormikContext();
    const handleImageUpload = async (files) => {
        if (files && files.length > MAX_IMAGE_POST_IMAGES) {
            return Toasts.notify(`You can upload up to ${MAX_IMAGE_POST_IMAGES} images within a post.`, {
                type: 'error',
            });
        }
        if (files && onImagesUpload) {
            onImagesUpload(files);
        }
    };
    const handleImageRemove = (index) => {
        if (values.images) {
            setFieldValue(imagesFieldName, values.images.filter((image, imageIndex) => imageIndex !== index));
        }
    };
    const handleImageChange = (newIndex, oldIndex) => {
        if (values.images) {
            setFieldValue(imagesFieldName, arrayMove(values.images, newIndex, oldIndex));
        }
    };
    const handleDropRejection = (rejections) => {
        getRejectionErrors(rejections).forEach((rejection) => {
            Toasts.notify(rejection);
        });
    };
    const renderImages = () => {
        const images = values[imagesFieldName];
        if (!images.length) {
            return null;
        }
        if (images.length === 1) {
            return (React.createElement(RemovableUpload, { onRemove: () => {
                    handleImageRemove(0);
                } },
                React.createElement(UploadedImage, { image: images[0].image, objectFit: "contain" })));
        }
        return (React.createElement(PostGallery, { images: images, onImageRemove: handleImageRemove, onImageSort: handleImageChange }));
    };
    const renderOverlay = () => {
        if (isUploading) {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.Overlay, null),
                React.createElement(S.MessageContainer, null,
                    React.createElement(Loader, { size: "large", appearance: "primary" }),
                    React.createElement(S.Message, null, "Uploading"))));
        }
    };
    const renderUpload = () => {
        const images = values[imagesFieldName];
        if (images.length === MAX_IMAGE_POST_IMAGES) {
            return null;
        }
        const hadUploadedImage = images.length > 0;
        const height = hadUploadedImage ? 80 : 120;
        let title = 'Browse';
        if (media.tablet) {
            title = hadUploadedImage ? (React.createElement("span", null,
                "Drop another image or ",
                React.createElement("u", null, "browse"))) : (React.createElement("span", null,
                "Drop images or ",
                React.createElement("u", null, "browse")));
        }
        return (React.createElement("div", { style: { height, position: 'relative' } },
            React.createElement(Upload, { accept: {
                    'image/*': [],
                }, title: title, invalid: !!get(errors, imagesFieldName), multiple: true, icon: UploadIcon, iconSize: 20, maxSize: 10485760, orientation: "horizontal", onFileDrop: handleImageUpload, onFileDropRejected: handleDropRejection })));
    };
    return (React.createElement(Loading, { isLoading: isLoading },
        React.createElement(Subsection, { title: subsectionTitle },
            React.createElement(S.Container, null,
                renderImages(),
                React.createElement("div", null,
                    renderUpload(),
                    get(errors, imagesFieldName) && (React.createElement(InputUI.Error, null, get(errors, imagesFieldName)))),
                renderOverlay()))));
};
