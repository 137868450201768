import { BackButton, Banner, Loader, Toasts } from '@fourthwall/components';
import { OffersApiClient } from '@fourthwall/services/lib/OffersApiClient';
import mapValues from 'lodash-es/mapValues';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { reverse, routing } from '../../../../../utils/routing';
import { CostsForm } from './CostsForm';
import { hasAnyVariantZeroCost } from './utils';
import { useOfferQuery } from '../../queries/useOfferQuery';
export const Costs = () => {
    const { productId = '' } = useParams();
    const { offerQuery, invalidateOfferQuery } = useOfferQuery([productId]);
    const data = offerQuery.data;
    const name = data?.name || '';
    const variants = data?.variants || [];
    const unavailableVariants = variants.filter((variant) => variant.status === 'UNAVAILABLE');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isFetching = offerQuery.isLoading;
    const displayZeroCostWarning = hasAnyVariantZeroCost(variants);
    const initialValues = variants.reduce((accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: currentValue.cost?.value,
    }), {});
    const handleSubmit = async (values) => {
        setIsSubmitting(true);
        try {
            const payload = {
                costs: mapValues(values, (value) => ({ value: parseFloat(value), currency: 'USD' })),
            };
            await OffersApiClient.replaceVariantsCosts(payload);
            Toasts.notify('success', { type: 'info' });
            setIsSubmitting(false);
            invalidateOfferQuery();
        }
        catch {
            Toasts.notify('error', { type: 'error' });
            setIsSubmitting(false);
        }
    };
    const renderContent = () => {
        if (!data || isFetching) {
            return (React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                React.createElement(Loader, { size: "large" })));
        }
        return (React.createElement(React.Fragment, null,
            data.isSettingCostsRequired && (React.createElement("div", { style: { marginBottom: 16 } },
                React.createElement(Banner, { appearance: "warning" }, "There are variants with missing cost value."))),
            unavailableVariants.length > 0 && (React.createElement("div", { style: { marginBottom: 16 } },
                React.createElement(Banner, { appearance: "warning" },
                    "There are ",
                    unavailableVariants.length,
                    " variants unavailable to buy at the moment."))),
            displayZeroCostWarning && (React.createElement("div", { style: { marginBottom: 16 } },
                React.createElement(Banner, { appearance: "warning" }, "Some of the variants have costs set to 0."))),
            React.createElement(CostsForm, { variants: variants, initialValues: initialValues, isSubmitting: isSubmitting, onSubmit: handleSubmit })));
    };
    return (React.createElement(PageLayout, { size: "small" },
        React.createElement(Dashboard.Section, { name: "Variants Costs", topContent: name && (React.createElement(BackButton, { label: name, to: reverse(routing.products.all.product.self, { productId }) })) }, renderContent())));
};
