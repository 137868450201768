export const getDevices = (devicesParam) => {
    if (devicesParam === 'Web' || devicesParam === 'Apple' || devicesParam === 'Android') {
        return [devicesParam];
    }
};
export const getDevicesSelect = (reportType, devicesParam, onValueChange) => {
    if (reportType === 'memberships-members-change' || reportType === 'memberships-total-members') {
        return {
            options: [
                { label: 'All', value: 'ALL' },
                { label: 'Web', value: 'Web' },
                { label: 'Apple', value: 'Apple' },
                { label: 'Android', value: 'Android' },
            ],
            value: devicesParam || 'ALL',
            onValueChange: (val) => {
                onValueChange(val);
            },
        };
    }
};
