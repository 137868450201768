import { ButtonClean, Calendar, InputUI, useOutsideClick, VisuallyHidden, } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { SideSummary } from '../../../../../../../components/common/SideSummary';
import { FormFields } from '../../types';
export const PostPublishedDate = () => {
    const { values, setFieldValue, errors } = useFormikContext();
    const containerRef = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen((prevIsOpen) => !prevIsOpen);
    };
    useOutsideClick(containerRef, () => {
        setOpen(false);
    });
    return (React.createElement(SideSummary.Item, { title: "Published date" },
        dayjs(values.liveAt).format('MMM DD, YYYY, hh:mm A'),
        React.createElement(Manager, null,
            React.createElement(VisuallyHidden, null,
                React.createElement("input", { type: "text", name: FormFields.liveAt })),
            React.createElement("div", { ref: containerRef },
                React.createElement(Reference, null, ({ ref }) => (React.createElement("div", { ref: ref },
                    React.createElement(ButtonClean, { label: "Edit", onClick: toggleOpen })))),
                isOpen && (React.createElement(Popper, { placement: "bottom-end" }, ({ ref, style }) => (React.createElement("div", { ref: ref, style: { ...style, paddingTop: 8, zIndex: 1 } },
                    React.createElement(Calendar, { type: "single", date: values.liveAt, onDateChange: (date) => {
                            setFieldValue('liveAt', date);
                        }, startTimeLabel: "Publish time", disableDates: values.mode === 'update'
                            ? (date) => dayjs(date.toISOString()).hour(dayjs().hour()).isAfter(dayjs())
                            : undefined }))))))),
        errors.liveAt && React.createElement(InputUI.Error, null, errors.liveAt)));
};
