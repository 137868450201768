import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
import { Container as CheckboxIconContainer } from './CheckboxIcon/CheckboxIcon.styled';
export const Container = styled.label(({ $disabled }) => css `
    display: flex;

    position: relative;

    user-select: none;

    & > * + * {
      margin-left: 12px;
    }

    ${!$disabled &&
    css `
      cursor: pointer;

      &:hover {
        ${CheckboxIconContainer} {
          border-color: ${getAutomatedStyleProp('paleTextColor', Colors.GS600)};
        }
      }
    `}

    ${$disabled &&
    css `
      cursor: not-allowed;
    `}
  `);
export const Input = styled.input `
  clip: rect(1px, 1px, 1px, 1px);

  position: absolute;
`;
export const LabelContainer = styled.div `
  position: relative;
  top: 1px;
`;
export const Label = styled.div `
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 150%;

  color: ${getAutomatedStyleProp('colorOnBackground', Colors.GS1000)};

  ${({ $disabled }) => $disabled &&
    css `
      color: ${getAutomatedStyleProp('paleTextColor', Colors.GS600)};
    `}
`;
export const Description = styled.div `
  color: ${Colors.GS600};
  font-size: 14px;
  line-height: 150%;

  a {
    text-decoration: underline;

    &:hover {
      color: ${Colors.B500};
    }
  }
`;
export const TooltipContainer = styled.div `
  margin-left: 6px;
`;
