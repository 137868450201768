import Yup from '@fourthwall/utils/lib/yup';
import { FormFields } from './types';
export const validationSchema = Yup.object().shape({
    [FormFields.email]: Yup.string().email().required(),
    // [FormFields.role]: Yup.string().required(),
});
export const ROLES = [
    {
        checkedValue: 'VIEWER',
        label: 'Viewer',
        suggestion: 'Viewers can see orders, product details and analytics but cannot make any changes.',
    },
    {
        checkedValue: 'MANAGER',
        label: 'Manager',
        suggestion: 'Managers have full admin rights but cannot change payout details or invite new team members.',
    },
    {
        checkedValue: 'OWNER',
        label: 'Owner',
        suggestion: 'Owners have full admin privileges.',
    },
    //  NEW ROLES
    // {
    //   checkedValue: 'SUPER_ADMIN',
    //   label: 'Super admin',
    //   suggestion:
    //     'Full admin privileges, including the ability to add and remove other super admins.',
    // },
    // {
    //   checkedValue: 'DESIGNER',
    //   label: 'Designer',
    //   suggestion:
    //     'Can design products and tweak website design. Cannot view analytics, order details, or make any other changes.',
    // },
    // {
    //   checkedValue: 'CUSTOMER_SUPPORT',
    //   label: 'Customer support',
    //   suggestion:
    //     'Can view, edit, cancel and refund orders. Cannot view analytics, invite admins, or make changes to payouts.',
    // },
    // {
    //   checkedValue: 'FULFILLMENT_SUPPORT',
    //   label: 'Fulfillment support',
    //   suggestion:
    //     "Can only view shipping information for products you're handling shipping for. No other permissions.",
    // },
];
