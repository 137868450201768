import styled from 'styled-components';
export const Container = styled.div `
  flex: 1;
`;
export const Total = styled.div `
  margin-bottom: 4px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
`;
export const LinkContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
  line-height: 1.5;
`;
