import { ShopUsersApiClient } from '@fourthwall/services/lib/ShopUsersApiClient';
import { useMutation } from '@tanstack/react-query';
export const useCancelInvitationsMutation = () => {
    const cancelInvitationsMutation = useMutation({
        mutationFn: async (variables) => {
            const { data } = await ShopUsersApiClient.cancelInvitations(variables);
            return data;
        },
    });
    return { cancelInvitationsMutation };
};
