import { getShopRendererApiUrl } from '@fourthwall/env';
import { ApiBase } from '../ApiBase';
class ShopRendererApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getPages = ({ shop_id, theme_id, }) => {
            return this.get(`/shops/${shop_id}/themes/${theme_id}/pages`);
        };
        this.getCustomPages = ({ shop_id }) => {
            return this.get(`/shops/${shop_id}/pages`);
        };
        this.getPageLayout = (shopId, themeId, pageId) => {
            return this.get(`/shops/${shopId}/themes/${themeId}/pages/${pageId}`);
        };
        this.updateSettingSection = (shopId, themeId, pageId, sectionId, payload) => {
            return this.put(`/shops/${shopId}/themes/${themeId}/pages/${pageId}/sections/${sectionId}/settings`, payload);
        };
        this.getAvailableSectionList = (shopId, themeId, blockId) => {
            const params = new URLSearchParams({ block_id: blockId }).toString();
            return this.get(`/shops/${shopId}/themes/${themeId}/sections?${params}`);
        };
        this.getPage = ({ shop_id, page_id, }) => {
            return this.get(`/shops/${shop_id}/pages/${page_id}`);
        };
        this.createPage = ({ shop_id }, requestBody) => {
            return this.post(`/shops/${shop_id}/pages`, requestBody);
        };
        this.updatePageSettings = ({ shop_id, page_id }, requestBody) => {
            return this.put(`/shops/${shop_id}/pages/${page_id}`, requestBody);
        };
        this.removePage = ({ shop_id, page_id, }) => {
            return this.delete(`/shops/${shop_id}/pages/${page_id}`);
        };
        this.createLayoutSection = ({ shop_id, theme_id, page_id, block_id }, requestBody) => {
            return this.post(`/shops/${shop_id}/themes/${theme_id}/pages/${page_id}/blocks/${block_id}/sections`, requestBody);
        };
        this.removeLayoutSection = ({ shop_id, theme_id, section_id, }) => {
            return this.delete(`/shops/${shop_id}/themes/${theme_id}/sections/${section_id}`);
        };
        this.updateLayoutSectionOrder = (pathParams, requestBody) => {
            return this.post(`/shops/${pathParams.shop_id}/themes/${pathParams.theme_id}/sections/${pathParams.section_id}/reorder`, requestBody);
        };
        this.updateSectionVisibility = ({ shop_id, theme_id, page_id, section_id }, requestBody) => {
            return this.put(`/shops/${shop_id}/themes/${theme_id}/pages/${page_id}/sections/${section_id}/visibility`, requestBody);
        };
        this.getNavigations = (shopId) => {
            return this.get(`/shops/${shopId}/navigations`);
        };
        this.createNavigationLink = ({ shop_id, navigation_handle }, requestBody) => {
            return this.post(`/shops/${shop_id}/navigations/${navigation_handle}/links`, requestBody);
        };
        this.updateNavigationLink = ({ shop_id, navigation_handle, link_id }, requestBody) => {
            return this.put(`/shops/${shop_id}/navigations/${navigation_handle}/links/${link_id}`, requestBody);
        };
        this.removeNavigationLink = (shopId, navigationHandle, linkId) => {
            return this.delete(`/shops/${shopId}/navigations/${navigationHandle}/links/${linkId}`);
        };
        this.getSocialNetworks = (shopId) => {
            return this.get(`/shops/${shopId}/social_networks`);
        };
        this.updateSocialNetwork = (shopId, socialNetworkName, body) => {
            return this.patch(`/shops/${shopId}/social_networks/${socialNetworkName}`, body);
        };
        this.deleteSocialNetwork = (shopId, socialNetworkName) => {
            return this.delete(`/shops/${shopId}/social_networks/${socialNetworkName}`);
        };
        this.searchSocialNetworks = (shopId, socialNetworkName, search) => {
            return this.get(`/shops/${shopId}/social_networks/${socialNetworkName}/search?handle=${search}`);
        };
        this.createTiktokIntegration = (shopId, body) => {
            return this.post(`/shops/${shopId}/integrations/tiktok`, body);
        };
        this.getTiktokIntegration = (shopId) => {
            return this.get(`/shops/${shopId}/integrations/tiktok`);
        };
        this.removeTiktokIntegration = (shopId) => {
            return this.delete(`/shops/${shopId}/integrations/tiktok`);
        };
        this.updateTiktokIntegration = (shopId, body) => {
            return this.put(`/shops/${shopId}/integrations/tiktok`, body);
        };
        this.createInstagramIntegration = (shopId, body) => {
            return this.post(`/shops/${shopId}/integrations/instagram`, body);
        };
        this.getInstagramIntegration = (shopId) => {
            return this.get(`/shops/${shopId}/integrations/instagram`);
        };
        this.removeInstagramIntegration = (shopId) => {
            return this.delete(`/shops/${shopId}/integrations/instagram`);
        };
        this.duplicateTheme = (shopId, themeId) => {
            return this.post(`/shops/${shopId}/themes/${themeId}/copy`);
        };
        this.renameTheme = (shopId, themeId, payload) => {
            return this.put(`/shops/${shopId}/themes/${themeId}`, payload);
        };
        this.deleteTheme = (shopId, themeId) => {
            return this.delete(`/shops/${shopId}/themes/${themeId}`);
        };
        this.getShop = ({ shop_id, }) => {
            return this.get(`/shops/${shop_id}`);
        };
        this.updateShop = ({ shop_id }, requestBody) => {
            return this.put(`/shops/${shop_id}`, requestBody);
        };
        this.updateSection = ({ shop_id, theme_id, section_id }, requestBody) => {
            return this.put(`/shops/${shop_id}/themes/${theme_id}/sections/${section_id}`, requestBody);
        };
        this.getThemeChangelog = ({ theme_handle, theme_version, }) => {
            return this.get(`/public/themes/${theme_handle}/${theme_version}/changelog`);
        };
        this.getSection = ({ shop_id, theme_id, section_key, }) => {
            return this.get(`/shops/${shop_id}/themes/${theme_id}/sections/${section_key}`);
        };
        this.updateSectionSettings = ({ shop_id, theme_id, section_id }, requestBody) => {
            return this.put(`/shops/${shop_id}/themes/${theme_id}/sections/${section_id}/settings`, requestBody);
        };
        this.getSectionSchema = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/section_schemas/themes/${pathParams.theme_id}/sections/${pathParams.section_id}`);
        };
        this.getSectionSchemas = (pathParams, queryParams) => {
            return this.get(`/shops/${pathParams.shop_id}/section_schemas/themes/${pathParams.theme_id}`, queryParams);
        };
        this.searchPublicSocialNetworks = (pathParams, queryParams) => {
            return this.get(`/public/social_networks/${pathParams.social_network_name}/search`, queryParams);
        };
        this.getIcons = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/icons`);
        };
        this.getMembershipsPostTypes = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/memberships/types/posts`);
        };
        this.getMembershipsPostTags = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/memberships/tags/posts`);
        };
        this.getMembershipsVideoSeries = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/memberships/series/posts`);
        };
        this.getMembershipsPostAccess = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/memberships/access/posts`);
        };
        this.getLinkables = (pathParams, queryParams) => {
            return this.get(`/shops/${pathParams.shop_id}/navigations/linkables`, queryParams);
        };
        this.disablePageTemplates = (pathParams, requestBody) => {
            return this.put(`/shops/${pathParams.shop_id}/theme_page_templates/disable`, requestBody);
        };
        this.enablePageTemplates = (pathParams, requestBody) => {
            return this.put(`/shops/${pathParams.shop_id}/theme_page_templates/enable`, requestBody);
        };
        this.createSection = (pathParams, requestBody) => {
            return this.post(`/shops/${pathParams.shop_id}/themes/${pathParams.theme_id}/sections`, requestBody);
        };
    }
}
export const ShopRendererApiClient = new ShopRendererApiClientModel(getShopRendererApiUrl('api'));
