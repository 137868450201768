import { HeadContent } from '@fourthwall/components';
import React from 'react';
import { PageLayout } from '@components/common/PageLayout';
import { Articles } from './components/Articles';
import { Header } from './components/Header';
import { Tiers } from './modules/Tiers';
import { TiersProvider } from './providers/TiersProvider';
export const Perks = () => (React.createElement(TiersProvider, null,
    React.createElement(PageLayout, null,
        React.createElement(HeadContent, { title: "Perks & tiers" }),
        React.createElement(Header, null),
        React.createElement(Tiers, null),
        React.createElement(Articles, null))));
