export const getVariants = ({ attributesList }) => {
    const variantsArray = [];
    const size = attributesList.find(({ type }) => type === 'SIZE');
    const color = attributesList.find(({ type }) => type === 'COLOR');
    if (size) {
        variantsArray.push({ prefix: 'Size', value: size.name });
    }
    if (color) {
        variantsArray.push({ prefix: 'Color', value: color.name });
    }
    return variantsArray;
};
export const getVariantsString = (variant) => {
    return getVariants(variant)
        .map(({ prefix, value }) => `${prefix}: ${value}`)
        .join(', ');
};
export const getAttributesText = (attributes, variantTypes) => {
    return Object.entries(attributes)
        .sort((a, b) => {
        const types = [...variantTypes].reverse();
        const aIndex = types.findIndex(({ type }) => type === a[0]);
        const bIndex = types.findIndex(({ type }) => type === b[0]);
        return -(aIndex - bIndex);
    }) // reflect variant types order
        .map(([key, value]) => [variantTypes.find(({ type }) => type === key)?.title || key, value]) // ["Color", "Red"], ["Size", "Small"]
        .map((entry) => entry.join(': ')) // ["Color: Red"], ["Size: Small"]
        .join(', '); // Color: Red, Size: Small
};
