import { useDashboardFeatureFlags } from '../components/utils/DashboardFeatureFlags';
import { useUserConnectionsQuery } from '../queries/useUserConnectionsQuery';
import { routing } from '../utils/routing';
export const usePartnerDashboard = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { userConnectionsQuery } = useUserConnectionsQuery(undefined, {
        enabled: dashboardFeatureFlags.partner.enabled,
        staleTime: Infinity,
    });
    const partnerDashboard = (() => {
        if (!userConnectionsQuery.isSuccess)
            return;
        if (!userConnectionsQuery.data.agencies || userConnectionsQuery.data.agencies.length === 0) {
            return;
        }
        return { url: routing.external.partnerDashboard };
    })();
    return { partnerDashboard };
};
