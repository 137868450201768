import React from 'react';
import { Checkbox, ColorItem, Input, useMediaQueryContext } from '@fourthwall/components';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { PhotoTableCellContent } from '@components/common/PhotoTableCellContent';
import { CheckboxColorLabel } from '../CheckboxColorLabel/CheckboxColorLabel';
import { isOfferItemType, isSingleVariantOfferItemType } from '../../utils';
import { QuantityDropdown } from '../../../../components/QuantityDropdown';
import { VariantPrice } from '../../../../components/VariantPrice';
import * as S from './styled';
export const SelectItemView = ({ item, checked, quantity, quantityError, onCheckboxValueChange, onQuantityValueChange, }) => {
    const media = useMediaQueryContext();
    const isSingleVariantType = isSingleVariantOfferItemType(item);
    const isOfferType = isOfferItemType(item);
    const photo = (isSingleVariantType || isOfferType) && (React.createElement(PhotoTableCellContent, { name: item.name, photo: item.image, photoSize: "small" }));
    const stock = !isOfferType && (typeof item.stock === 'number' ? `${item.stock} in stock` : 'Unlimited stock');
    const renderCheckboxWithLabel = (label) => (React.createElement(S.CheckboxWrapper, null,
        React.createElement(Checkbox, { value: checked, label: label, disabled: 'disabled' in item ? item.disabled : undefined, onValueChange: onCheckboxValueChange })));
    const renderName = () => {
        if (isOfferType) {
            return photo;
        }
        if (isSingleVariantType) {
            return renderCheckboxWithLabel(photo);
        }
        return (React.createElement(S.Indent, null, renderCheckboxWithLabel(React.createElement(CheckboxColorLabel, { label: item.name, color: item.color }))));
    };
    const renderQuantity = () => {
        if (isOfferType) {
            return null;
        }
        return (React.createElement(Input, { type: "number", hideSpinner: media.tablet, size: media.tablet ? 'small' : 'large', fullWidth: true, value: quantity, label: "Quantity", min: 1, max: item.stock, error: quantityError, disabled: item.disabled, onValueChange: onQuantityValueChange }));
    };
    const renderPrice = () => {
        if (isOfferType || !item.price) {
            return null;
        }
        return (React.createElement(VariantPrice, { price: item.price, oldPrice: item.oldPrice, tooltip: "You only pay the manufacturing price for samples" }));
    };
    const renderProduct = () => (React.createElement(S.Product, null,
        isSingleVariantType || isOfferType ? (React.createElement(PhotoThumbnail, { url: item.image, size: "small" })) : (item.color && React.createElement(ColorItem, { color: item.color })),
        React.createElement(S.ProductDetails, null,
            React.createElement("div", null, item.name),
            !isOfferType && (React.createElement(S.QuantityButton, null,
                "Quantity:",
                ' ',
                React.createElement("div", { onClick: (e) => e.preventDefault() },
                    React.createElement(QuantityDropdown, { title: item.name, description: stock || undefined, quantity: quantity, maxValue: item.stock, disabled: item.disabled, onSubmit: onQuantityValueChange })))),
            stock && React.createElement(S.Stock, null, stock))));
    const renderLeftContent = () => {
        if (isOfferType) {
            return renderProduct();
        }
        if (isSingleVariantType) {
            return renderCheckboxWithLabel(renderProduct());
        }
        return React.createElement(S.Indent, null, renderCheckboxWithLabel(renderProduct()));
    };
    const renderContent = () => {
        if (!media.tablet) {
            return React.createElement(S.LeftSide, null, renderLeftContent());
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(S.Name, null, renderName()),
            React.createElement(S.Stock, null, stock),
            React.createElement(S.Quantity, null, renderQuantity())));
    };
    return (React.createElement(S.Container, null,
        renderContent(),
        React.createElement(S.Price, null, renderPrice())));
};
