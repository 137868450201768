import { Toasts } from '@fourthwall/components';
import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import { useQuery, useQueryClient } from '@tanstack/react-query';
const queryKey = 'useDiscordRolesQuery';
export const useDiscordRolesQuery = (enabled = false) => {
    const queryClient = useQueryClient();
    const discordRolesQuery = useQuery({
        queryKey: [queryKey],
        queryFn: async () => {
            const { data } = await MembershipsCreatorApiClient.getDiscordRoles();
            return data;
        },
        enabled,
        onError: (error) => {
            if (error.response?.status === 404) {
                // Clear Discord roles when user removes integration
                queryClient.setQueryData([queryKey], undefined);
            }
            if (error.response?.status !== 404) {
                const errorMessage = errorMessageSelector(error.response?.data);
                Toasts.notify(errorMessage, { type: 'error' });
            }
        },
    });
    return {
        discordRolesQuery,
    };
};
