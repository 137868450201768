import { ApiBase } from '../ApiBase';
class KudosApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getKudosOrders = (params) => {
            return this.get('/kudos/orders', params);
        };
        this.getTopKudos = (params) => {
            return this.get('/kudos/top', params);
        };
        this.getTopKudosCount = (params) => {
            return this.get('/kudos/top/count', params);
        };
        this.uploadKudo = (params, payload) => {
            return this.post(`/kudos/${params.orderId}/upload`, payload);
        };
        this.skipKudo = (params) => {
            return this.put(`/kudos/orders/${params.orderId}/skipped`);
        };
        this.unskipKudo = (params) => {
            return this.delete(`/kudos/orders/${params.orderId}/skipped`);
        };
    }
}
export const KudosApiClient = new KudosApiClientModel();
