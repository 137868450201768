import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Layout = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;
export const Frame = styled.div `
  position: absolute;
  border: 3px solid ${Colors.B500};
`;
