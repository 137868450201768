import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
`;
export const Overlay = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${Colors.GS1000};
  opacity: 0.6;
`;
export const MessageContainer = styled.div `
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${Colors.GS100};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.03em;
`;
export const Message = styled.div `
  padding-top: 12px;
`;
