import styled from 'styled-components';
import { Colors } from '../../colors';
export const ActivityIndicator = styled.div `
  border-radius: 4px;
  padding: 2px 4px 1px;

  min-width: 12px;
  min-height: 12px;

  background-color: ${Colors.B500};

  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  color: ${Colors.GS0};

  &:empty {
    border-radius: 100%;
  }
`;
