import React from 'react';
import dayjs from 'dayjs';
import { DateString } from '@fourthwall/components';
export const TableItemDate = ({ date, now = new Date().toISOString() }) => {
    const day = dayjs(date);
    const renderYear = () => {
        if (day.isAfter(dayjs(now).subtract(1, 'year')))
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement("br", null),
            React.createElement(DateString, { date: date, format: "YYYY" })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DateString, { date: date, format: day.isAfter(dayjs(now).subtract(12, 'hour')) ? 'h:mm A' : 'MMM D' }),
        renderYear()));
};
