export const getNonCancellableReason = (reason) => {
    const map = {
        CANCELLATION_EXPIRED: 'Cancellation expired',
        IN_DELIVERY: 'Already in delivery',
        DIGITAL_ITEM: 'Digital item',
        IN_PRODUCTION: 'Already in production',
        ORDER_NOT_CANCELLABLE: 'Not cancellable',
    };
    return map[reason];
};
