import { Colors, mediaQueries, Tabs } from '@fourthwall/components';
import styled from 'styled-components';
export const TooltipContainer = styled.div `
  background: ${Colors.GS0};

  ${mediaQueries.tablet} {
    width: 282px;
    border: 1px solid ${Colors.GS500};
  }
`;
export const TabsList = styled(Tabs.List) `
  padding: 0 20px;
  border-bottom: 1px solid ${Colors.GS400};

  ${mediaQueries.tablet} {
    padding: 0 16px;
  }
`;
export const TabsPanelContainer = styled.div `
  padding: 0 4px;

  ${mediaQueries.tablet} {
    padding: 0;
  }
`;
export const PanelContainer = styled.div `
  display: flex;
`;
export const PanelColumn = styled.div `
  flex: 1;
  margin: 0 8px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;
export const ColumnLabel = styled.div `
  font-size: 12px;
  color: ${Colors.GS600};
  line-height: 16px;
  padding-top: 9px;
`;
export const ColumnValue = styled.div `
  font-size: 16px;
  color: ${Colors.GS1000};
  line-height: 22px;
`;
export const UnlimitedCheckboxContainer = styled.div `
  margin-bottom: 16px;
  padding: 0 20px;

  ${mediaQueries.tablet} {
    padding: 0 16px;
  }
`;
export const TooltipFooter = styled.div `
  display: flex;
  padding: 0 20px 24px;
  flex-direction: column-reverse;

  ${mediaQueries.tablet} {
    padding: 0 16px 16px;
  }
`;
