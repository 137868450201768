import { SuspenseFallback } from '@fourthwall/components';
import { isDevelopment } from '@fourthwall/utils/lib/dev';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentShop } from '../../../Dashboard/hooks/useCurrentShop';
export const IncorrectDomainGuard = ({ children }) => {
    const { pathname } = useLocation();
    const { currentShopQuery } = useCurrentShop();
    if (!currentShopQuery.isSuccess) {
        return React.createElement(SuspenseFallback, null);
    }
    if (!isDevelopment() && currentShopQuery.data.internalBaseUri !== window.location.origin) {
        window.location.assign(`${currentShopQuery.data.internalBaseUri}${pathname}`);
        return null;
    }
    return children;
};
