import { reverse } from 'named-urls';
import { routing } from '@utils/routing';
export const getGiveawayBreakdownLink = (giveawayBreakdown) => ({
    pathname: giveawayBreakdown.type === 'GIFT_REDEEM'
        ? reverse(routing.contributions.orders.order.self, {
            orderId: giveawayBreakdown.orderId,
        })
        : reverse(routing.contributions.orders.giveaways.giveaway, {
            giveawayId: giveawayBreakdown.id,
        }),
    search: location.search,
});
