import { Loading, ModalBox } from '@fourthwall/components';
import React from 'react';
import { useThemeChangelogQuery } from './hooks/useThemeChangelogQuery';
import * as S from './styled';
export const ThemeChangelogModal = ({ handle, version, close }) => {
    const { themeChangelogQuery } = useThemeChangelogQuery([
        { theme_version: version, theme_handle: handle },
    ]);
    const changelogData = themeChangelogQuery.data;
    return (React.createElement(ModalBox, { size: "large", title: `${handle} - ${version}`, onClose: close },
        React.createElement(Loading, { isLoading: themeChangelogQuery.isLoading },
            React.createElement(S.Container, null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: changelogData } })))));
};
