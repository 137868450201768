import { ButtonClean, HeadContent, LeavingGuard, Loader, SaveShortcutListener, Subsection, } from '@fourthwall/components';
import { Formik } from 'formik';
import React from 'react';
import { useDashboardFeatureFlags } from '../../../../../components/utils/DashboardFeatureFlags';
import { ConfirmBarContainer } from '../../../ConfirmBar';
import { Analytics } from './components/Analytics/Analytics';
import { CookieSettings } from './components/CookieSettings/CookieSettings';
import { CreatorDetails } from './components/CreatorDetails/CreatorDetails';
import { SiteDetails } from './components/SiteDetails/SiteDetails';
import { ThemeAutoUpdate } from './components/ThemeAutoUpdate/ThemeAutoUpdate';
export const GeneralView = ({ initialValues, validationSchema, isLoading, isSubmitting, onMarketingReportDownload, onSubmit, }) => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    if (isLoading) {
        return (React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
            React.createElement(Loader, { size: "large" })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "General \u2013 Settings" }),
        React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: onSubmit }, ({ dirty, submitForm, resetForm }) => (React.createElement(React.Fragment, null,
            React.createElement(CreatorDetails, null),
            React.createElement(SiteDetails, null),
            React.createElement(Subsection, { title: "Email marketing" },
                React.createElement(ButtonClean, { label: "Download subscribers list (CSV)", onClick: onMarketingReportDownload })),
            dashboardFeatureFlags.analyticsTrackingPixels.enabled && React.createElement(Analytics, null),
            React.createElement(ThemeAutoUpdate, null),
            React.createElement(CookieSettings, null),
            React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
            React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isSubmitting, onConfirm: submitForm, onCancel: resetForm }),
            React.createElement(LeavingGuard, { when: dirty }))))));
};
