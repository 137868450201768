import { Toasts } from '@fourthwall/components';
import { useState } from 'react';
import { useCheckMediaCreatedMutation } from '../../../../../hooks/useCheckMediaCreatedMutation';
import { useImageConfirmMutation } from '../../../../../hooks/useImageConfirmMutation';
import { useUploadImageMutation } from '../../../../../hooks/useUploadImageMutation';
import { uploadMedia } from '../../../../../utils/uploadMedia';
import { getCreateWelcomePostsPayload } from '../utils/getCreateWelcomePostsPayload';
import { useCreateWelcomePostsMutation } from './useCreateWelcomePostsMutation';
import { useUpdateWelcomePostsMutation } from './useUpdateWelcomePostsMutation';
export const useDifferentWelcomePostsCreate = () => {
    const [isLoading, setLoading] = useState(false);
    const [mediaUploadProgress, setMediaUploadProgress] = useState({});
    const { createWelcomePostsMutation } = useCreateWelcomePostsMutation();
    const { updateWelcomePostsMutation } = useUpdateWelcomePostsMutation();
    const { uploadImageMutation } = useUploadImageMutation();
    const { imageConfirmMutation } = useImageConfirmMutation();
    const { checkMediaCreatedMutation } = useCheckMediaCreatedMutation();
    const createDifferentWelcomePosts = async (values, update, handleSuccess) => {
        setLoading(true);
        const mutation = update ? updateWelcomePostsMutation : createWelcomePostsMutation;
        const payload = await getCreateWelcomePostsPayload(values.welcomePosts);
        const hasAnyImageToUpload = (uploadData) => uploadData.some((singleUploadData) => 'imageDirectUploadData' in singleUploadData);
        const hasAnyVideoToUpload = (uploadData) => uploadData.some((singleUploadData) => 'videoDirectUploadData' in singleUploadData);
        const { uploadData } = await mutation.mutateAsync(payload, {
            onSuccess: ({ uploadData }) => {
                // Update only text fields
                if (!hasAnyImageToUpload(uploadData) && !hasAnyVideoToUpload(uploadData)) {
                    handleSuccess();
                    setLoading(false);
                }
            },
            onError: () => setLoading(false),
        });
        // Upload image fields
        if (hasAnyImageToUpload(uploadData)) {
            const uploadDataWithImage = uploadData.filter((singleUploadData) => 'imageDirectUploadData' in singleUploadData);
            uploadDataWithImage.map(async (singleUploadData, index) => {
                if ('imageDirectUploadData' in singleUploadData && singleUploadData.imageDirectUploadData) {
                    const welcomePostsArray = Object.keys(values.welcomePosts).map((key) => ({
                        tierId: key,
                        welcomePost: values.welcomePosts[Number(key)],
                    }));
                    const tierId = welcomePostsArray[uploadData.indexOf(singleUploadData)].tierId;
                    await uploadImageMutation.mutateAsync({
                        file: values.welcomePosts[Number(tierId)].postType === 'Image'
                            ? values.welcomePosts[Number(tierId)].image
                            : values.welcomePosts[Number(tierId)].videoThumbnail,
                        ...singleUploadData.imageDirectUploadData,
                    }, {
                        onError: () => {
                            setLoading(false);
                            throw new Error('Memberships: different welcome post upload image error');
                        },
                    });
                    await imageConfirmMutation.mutateAsync({
                        id: singleUploadData.postId,
                        signedId: singleUploadData.imageDirectUploadData.signedId,
                    }, {
                        onSuccess: () => {
                            // Update only image fields
                            if (Number(index) === uploadDataWithImage.length - 1 &&
                                !hasAnyVideoToUpload(uploadData)) {
                                handleSuccess();
                                setLoading(false);
                            }
                        },
                        onError: () => {
                            setLoading(false);
                            throw new Error('Memberships: different welcome post confirm image error');
                        },
                    });
                }
            });
        }
        // Upload video fields
        if (hasAnyVideoToUpload(uploadData)) {
            const uploadDataWithVideo = uploadData.filter((singleUploadData) => 'videoDirectUploadData' in singleUploadData);
            uploadDataWithVideo.map(async (singleUploadData, index) => {
                if ('videoDirectUploadData' in singleUploadData && singleUploadData.videoDirectUploadData) {
                    const welcomePostsArray = Object.keys(values.welcomePosts).map((key) => ({
                        tierId: key,
                        welcomePost: values.welcomePosts[Number(key)],
                    }));
                    const tierId = welcomePostsArray[uploadData.indexOf(singleUploadData)].tierId;
                    await uploadMedia(singleUploadData.videoDirectUploadData.uploadUrl, values.welcomePosts[Number(tierId)].video, (progress) => {
                        setMediaUploadProgress((prev) => ({
                            ...prev,
                            [tierId]: Number(progress.toFixed()),
                        }));
                    }).catch(() => {
                        setLoading(false);
                        Toasts.notify('Media upload error. Please try again.', { type: 'error' });
                    });
                    await checkMediaCreatedMutation.mutate(singleUploadData.postId, {
                        onSuccess: () => {
                            if (Number(index) === uploadDataWithVideo.length - 1) {
                                setMediaUploadProgress((prev) => ({ ...prev, [tierId]: 0 }));
                                setLoading(false);
                                handleSuccess();
                            }
                        },
                        onError: () => setLoading(false),
                    });
                }
            });
        }
    };
    return {
        createDifferentWelcomePosts,
        mediaUploadProgress,
        isLoading,
    };
};
