import keycode from 'keycode';
import { useEffect } from 'react';
export const SaveShortcutListener = ({ disabled = false, onSave }) => {
    const handleKeyDown = (e) => {
        const isMac = navigator.platform.match('Mac');
        if (keycode(e) === 's' && (isMac ? e.metaKey : e.ctrlKey) && !disabled) {
            e.preventDefault();
            onSave();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onSave, disabled]);
    return null;
};
