import { parse } from '@plussub/srt-vtt-parser';
// Format a number to have a fixed number of digits.
// If the number has less digits than expected, it will be prefixed by 0.
export const format = (val, index) => {
    const expectedNumberOfDigits = index === 3 ? 3 : 2;
    const valueAsString = String(val);
    const missingDigitsCount = expectedNumberOfDigits - valueAsString.length;
    const missingDigits = missingDigitsCount > 0 ? '0'.repeat(missingDigitsCount) : '';
    return missingDigits + valueAsString;
};
// Convert a timestamp array ([hours, minutes, seconds and milliseconds]) to milliseconds.
export const timestampToMiliseconds = (ts) => (parseInt(ts[0]) * 3600 + parseInt(ts[1]) * 60 + parseInt(ts[2])) * 1000 + parseInt(ts[3]);
// Transform a number of millisecond into a subtitle timestamp format (00:00:00,000)
export function milisecondsToTimestamp(ms, msSeparator) {
    let msAbsolute = Math.abs(ms);
    const sign = ms < 0 ? '-' : '';
    const scale = [3600 * 1000, 60 * 1000, 1000, 1];
    const ts = scale
        .map((val) => {
        var unit = parseInt(String(msAbsolute / val));
        msAbsolute %= val;
        return unit;
    })
        .map(format);
    return sign + ts.slice(0, 3).join(':') + msSeparator + ts[ts.length - 1];
}
export const resync = (delay, srt) => srt.replace(/(?:(\d{2,}):)?(\d{2}):(\d{2})(?:([,\.])(\d{1,}))?/g, (match, hr, min, sec, msSeparator, ms) => {
    let timestampInMiliseconds = timestampToMiliseconds([hr, min, sec, ms]);
    timestampInMiliseconds += delay;
    return milisecondsToTimestamp(timestampInMiliseconds, msSeparator);
});
export const prepareSubtitles = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (event) => {
        if (event.target) {
            const fileContent = event.target.result;
            let entries = [];
            try {
                entries = parse(fileContent).entries;
            }
            catch (error) {
                return reject(error);
            }
            // if the first subtitle starts before 1 hour, we don't need to delay it
            if (entries[0].from < 3600000)
                return resolve(file);
            const delayedSubtitlesFileContent = resync(-3600000, fileContent);
            const newFile = new File([delayedSubtitlesFileContent], file.name, {
                type: file.type,
            });
            resolve(newFile);
        }
    };
    reader.readAsText(file);
    reader.onerror = reject;
});
