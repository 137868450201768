import { Dashboard, PageLayout } from '@components/common';
import { SearchBar } from '@components/common/SearchBar';
import { DashboardPermissionGuard, useDashboardPermissions, } from '@components/utils/DashboardPermissions';
import { Button, ButtonGroup, EmptyState, HeadContent, Pagination, ToolbarButton, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import { reverse } from 'named-urls';
import React from 'react';
import { routing } from '../../../utils/routing';
import { useSearchQuery } from '../hooks/useSearchQuery';
import { CreateProductModal } from './components/CreateProductModal';
import { useFilterProducts } from './hooks/useFilterProducts';
import { ProductsData } from './modules/ProductsData';
import { ProductsFilterContainer } from './modules/ProductsFilter';
import * as S from './styled';
export const ProductsView = ({ products, isLoading, totalPages, currentPage, onPageChange, onProductPositionChange, onProductPositionSet, onDuplicateOffer, onArchiveOffer, onArchiveManyOffers, onToggleMultipleOfferStatus, }) => {
    const media = useMediaQueryContext();
    const { hasPermission } = useDashboardPermissions();
    const { open } = useModalContext();
    const { searchQuery, searchValue, resetSearchValue, setSearchValue, searchOptions } = useSearchQuery();
    const { hasFilterParams } = useFilterProducts();
    const areSearchOrFiltersParamsApplied = searchQuery || hasFilterParams;
    const handleCreateProductClick = () => open(CreateProductModal);
    const getLinkToProduct = (productId) => {
        return {
            pathname: reverse(routing.products.all.product.self, { productId }),
            search: location.search,
        };
    };
    const renderSearchBar = () => (React.createElement(SearchBar, { name: "search", placeholder: "Search products", value: searchValue, maxLength: searchOptions.maxLength, onValueChange: (value) => setSearchValue(value), onActionIconClick: resetSearchValue }));
    const getToolbarContent = () => {
        if (!media.tablet) {
            return (React.createElement(ButtonGroup, { space: "medium" },
                React.createElement(DashboardPermissionGuard, { permission: "products.create" },
                    React.createElement(ToolbarButton, { label: "Create new product", icon: PlusIcon, onClick: handleCreateProductClick })),
                React.createElement(ProductsFilterContainer, null)));
        }
        return (React.createElement(ButtonGroup, { space: "medium" },
            React.createElement(ProductsFilterContainer, null),
            React.createElement(S.SearchBarWrapper, null, renderSearchBar()),
            React.createElement(DashboardPermissionGuard, { permission: "products.create" },
                React.createElement(Button, { label: "Create new product", appearance: "primary", size: "small", onClick: handleCreateProductClick }))));
    };
    const getEmptyStateProps = () => {
        if (areSearchOrFiltersParamsApplied) {
            return {
                title: 'No products found',
                subtitle: 'Adjust your filters or try different search term.',
            };
        }
        return {
            title: 'You haven’t created any products yet.',
            subtitle: 'Design a new product or list something you own.',
            buttonLabel: 'Create new product',
        };
    };
    const renderEmptyState = () => {
        const { title, subtitle, buttonLabel } = getEmptyStateProps();
        return (React.createElement(EmptyState, { title: title, subtitle: subtitle, variant: media.tablet ? 'primary' : 'secondary', label: hasPermission('products.create') ? buttonLabel : undefined, onClick: hasPermission('products.create') ? handleCreateProductClick : undefined }));
    };
    return (React.createElement(PageLayout, { size: "large" },
        React.createElement(HeadContent, { title: "Products" }),
        React.createElement(Dashboard.Section, { name: "Products", toolbarContent: getToolbarContent(), bottomContent: !media.tablet && renderSearchBar() },
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(ProductsData, { products: products, currentPage: currentPage, isLoading: isLoading, emptyComponent: renderEmptyState(), getLinkToProduct: getLinkToProduct, onArchiveSelectedOffersClick: onArchiveManyOffers, onDuplicateOffer: onDuplicateOffer, onArchiveOffer: onArchiveOffer, onProductPositionSet: onProductPositionSet, onProductPositionChange: onProductPositionChange, onToggleMultipleOfferStatus: onToggleMultipleOfferStatus })),
            !!totalPages && !!products.length && (React.createElement(Dashboard.Section.Item, null,
                React.createElement(Pagination, { pageCount: totalPages, currentPage: currentPage, onChange: onPageChange }))))));
};
