import { useEffect, useState } from 'react';
import { getSelectedPreset, mapTheme } from '../utils';
export const useThemes = (libraryThemes, installedThemes) => {
    const [themes, setThemes] = useState(libraryThemes.map((libraryTheme) => mapTheme(libraryTheme, installedThemes)));
    const [previewTheme, setPreviewTheme] = useState(null);
    useEffect(() => {
        setThemes(libraryThemes.map((libraryTheme) => mapTheme(libraryTheme, installedThemes)));
    }, [libraryThemes, installedThemes]);
    const setSelectedPreset = (theme, presetName) => {
        const updatedTheme = {
            ...theme,
            presets: theme.presets.map((preset) => ({
                ...preset,
                isSelected: preset.name === presetName,
            })),
        };
        setThemes((currentThemes) => {
            return currentThemes.map((currentTheme) => {
                return currentTheme.handle === theme.handle ? updatedTheme : currentTheme;
            });
        });
        if (theme.handle === previewTheme?.handle) {
            setPreviewTheme(updatedTheme);
        }
    };
    return {
        themes,
        previewTheme,
        previewSelectedPreset: previewTheme ? getSelectedPreset(previewTheme) : null,
        setPreviewTheme,
        setSelectedPreset,
    };
};
