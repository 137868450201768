import { CurrencyInput, Input, List, PriceString, Table, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import React from 'react';
import { getPreviewImage } from '@modules/Dashboard/Product/utils';
import { TableCell } from '@components/common/TableCell';
import { Field, useFormikContext } from 'formik';
import { StockTooltip } from '@components/common/StockTooltip';
import { WeightInput } from '@components/common/WeightInput';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import isEqual from 'lodash/isEqual';
import { ProductVariantsListItem } from '../ProductVariantsListItem';
import { VariantImageCell } from '../VariantImageCell';
import * as S from './styled';
import { ProductVariantModal } from '../ProductVariantModal';
export const ProductVariantsData = ({ data, allImages, showPricing, showWeight, disabled, stockDisabled, fulfillmentService, isCompareAtPriceEnabled, variantTypes, variantsAnalytics, variantsAnalyticsWhiplash, visualHints, onStockTooltipChange, }) => {
    const media = useMediaQueryContext();
    const { open } = useModalContext();
    const { hasPermission } = useDashboardPermissions();
    const { setValues } = useFormikContext();
    const showShipMonkStock = fulfillmentService === 'SHIP_MONK';
    const showWhiplashStock = fulfillmentService === 'WHIPLASH_3PL' || fulfillmentService === 'WHIPLASH_FREE';
    const showSKU = visualHints?.customSkus;
    const getSold = (id) => variantsAnalytics?.[id]?.unitsSold || 0;
    const getShipMonkAvailableStock = (id) => {
        if (!showShipMonkStock)
            return undefined;
        return variantsAnalyticsWhiplash?.[id]?.stockDetails?.shipMonkStockExists
            ? variantsAnalyticsWhiplash?.[id]?.stockDetails?.shipMonkStockAvailable
            : 'N/A';
    };
    const getWhiplashAvailableStock = (id) => {
        if (!showWhiplashStock)
            return undefined;
        return variantsAnalyticsWhiplash?.[id]?.stockDetails?.whiplashStockExists
            ? variantsAnalyticsWhiplash?.[id]?.stockDetails?.whiplashStockQuantity
            : 'N/A';
    };
    const handleProductVariantModalSubmit = (index) => (values) => {
        const updatedVariant = {
            ...data[index],
            ...values,
        };
        if (!isEqual(data[index], updatedVariant)) {
            setValues((prevState) => ({
                ...prevState,
                variants: prevState.variants.map((variant, i) => i === index ? updatedVariant : variant),
            }));
        }
    };
    const headers = [
        {
            id: 'name',
            label: 'Variant',
            minWidth: 250,
            render: ({ attributesDescription, images, isFirstOfGroup, isLastOfGroup, }) => (React.createElement("div", { style: { display: 'flex', alignItems: 'center', width: 'auto' } },
                React.createElement(VariantImageCell, { previewImage: getPreviewImage(images, allImages), imagesCount: images.length, isFirstOfGroup: isFirstOfGroup, isLastOfGroup: isLastOfGroup, shouldDisplayPhoto: variantTypes.some((variantType) => variantType.variesBy.imagery) }),
                React.createElement("div", null, attributesDescription))),
        },
        {
            id: 'price.value',
            label: 'Selling Price',
            hidden: !showPricing,
            width: 0,
            minWidth: 150,
            render: (variant, index) => (React.createElement(TableCell, { mainContent: React.createElement(S.PriceWrapper, null,
                    React.createElement(Field, { name: `variants[${index}].price.value`, component: CurrencyInput, size: "small", displayError: false, disabled: disabled })) })),
        },
        {
            id: 'compareAtPrice',
            label: 'Compare-at price',
            hidden: !showPricing || !isCompareAtPriceEnabled,
            width: 0,
            minWidth: 150,
            render: (variant, index) => (React.createElement(TableCell, { mainContent: React.createElement(S.PriceWrapper, null,
                    React.createElement(Field, { name: `variants[${index}].compareAtPrice.value`, component: CurrencyInput, strikethrough: true, size: "small", displayError: false, disabled: disabled })) })),
        },
        {
            id: 'quantity',
            label: 'In Stock',
            minWidth: 95,
            render: ({ id, quantity }) => (React.createElement(TableCell, { mainContent: React.createElement(StockTooltip, { quantity: quantity, disabled: stockDisabled, onChange: onStockTooltipChange(id) }) })),
        },
        {
            id: 'sold',
            label: 'Sold',
            hidden: !hasPermission('contributions.profit'),
            minWidth: 60,
            render: ({ id }) => React.createElement(TableCell, { mainContent: getSold(id) }),
        },
        {
            id: 'in_whiplash',
            label: 'In whiplash',
            hidden: !showWhiplashStock,
            minWidth: 60,
            render: ({ id }) => (React.createElement(TableCell, { mainContent: getWhiplashAvailableStock(id) })),
        },
        {
            id: 'in_shipmonk',
            label: 'In ShipMonk',
            hidden: !showShipMonkStock,
            minWidth: 60,
            render: ({ id }) => (React.createElement(TableCell, { mainContent: getShipMonkAvailableStock(id) })),
        },
        {
            id: 'weight.value',
            label: 'Weight',
            hidden: !showWeight,
            minWidth: 155,
            render: (_, index) => (React.createElement(TableCell, { mainContent: React.createElement(S.WeightContainer, null,
                    React.createElement(WeightInput, { weightName: `variants[${index}].weight.value`, unitName: `variants[${index}].weight.unit`, size: "small", weightWidth: 80, unitWidth: 76, disabled: disabled })) })),
        },
        {
            id: 'sku',
            label: 'SKU or UPC code',
            hidden: !visualHints?.customSkus,
            minWidth: 175,
            render: (_, index) => (React.createElement(TableCell, { mainContent: React.createElement(Field, { name: `variants[${index}].sku`, displayError: false, component: Input, size: "small", disabled: disabled }) })),
        },
        {
            id: 'profit',
            hidden: !showPricing || !hasPermission('contributions.profit'),
            label: 'Profit Per Sale',
            render: (variant) => {
                const cost = variant.cost?.value;
                return cost ? React.createElement(PriceString, { value: variant.price.value - cost }) : React.createElement(React.Fragment, null, "\u2013");
            },
        },
    ];
    if (!media.tablet) {
        return (React.createElement(List, { data: data, renderItem: (productVariant) => (React.createElement(ProductVariantsListItem, { productVariant: productVariant, sold: getSold(productVariant.id), previewImage: getPreviewImage(productVariant.images, allImages), shouldDisplayPhoto: variantTypes.some((variantType) => variantType.variesBy.imagery), showPricing: showPricing, showWeight: showWeight, showSKU: showSKU })), onClick: (productVariant, index) => {
                open(ProductVariantModal, {
                    productVariant,
                    sold: getSold(productVariant.id),
                    isPriceEditable: showPricing,
                    isCompareAtPriceEditable: showPricing && !!isCompareAtPriceEnabled,
                    isWeightEditable: showWeight,
                    isSKUEditable: showSKU,
                    shipMonkAvailableStock: getShipMonkAvailableStock(productVariant.id),
                    whiplashAvailableStock: getWhiplashAvailableStock(productVariant.id),
                    onStockTooltipChange: onStockTooltipChange(productVariant.id),
                    onSubmit: handleProductVariantModalSubmit(index),
                });
            } }));
    }
    return React.createElement(Table, { data: data, headers: headers.filter((header) => !header.hidden), minWidth: 0 });
};
