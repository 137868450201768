import { useQueryParam } from 'use-query-params';
export const useTiktokErrorQueryParams = () => {
    const [error, setError] = useQueryParam('error');
    const [errorDescription, setErrorDescription] = useQueryParam('error_description');
    return {
        tiktokErrorQueryParams: {
            error,
            errorDescription,
        },
        setTiktokErrorQueryParams: (params) => {
            setError(params.error);
            setErrorDescription(params.errorDescription);
        },
    };
};
