import { Toasts } from '@fourthwall/components';
import { useEffect, useMemo, useState } from 'react';
import { MAX_FILE_SIZE } from '../const';
import { useMediaRecorder } from './useMediaRecorder';
import { useTimer } from './useTimer';
export const useSendThankYouMedia = (thankYouType, thankYou) => {
    const timer = useTimer();
    const [uploaded, setUploadedFile] = useState();
    const [mediaImageSize, setMediaImageSize] = useState();
    const videoRecorder = useMediaRecorder({ type: 'VIDEO', active: thankYouType === 'VIDEO' });
    const audioRecorder = useMediaRecorder({ type: 'AUDIO', active: thankYouType === 'AUDIO' });
    const thankYouData = thankYou.thankYous[0];
    const isAlreadySent = thankYou.state === 'SENT' && !!thankYouData;
    const status = useMemo(() => {
        if ((videoRecorder.recordedMedia && thankYouType === 'VIDEO') ||
            (audioRecorder.recordedMedia && thankYouType === 'AUDIO') ||
            (uploaded && thankYouType === 'UPLOAD') ||
            isAlreadySent) {
            return 'PREVIEW';
        }
        if ((videoRecorder.state === 'LOADING' && thankYouType === 'VIDEO') ||
            (audioRecorder.state === 'LOADING' && thankYouType === 'AUDIO')) {
            return 'LOADING';
        }
        if ((videoRecorder.state === 'RECORDING' && thankYouType === 'VIDEO') ||
            (audioRecorder.state === 'RECORDING' && thankYouType === 'AUDIO')) {
            return 'IN_PROGRESS';
        }
        if ((videoRecorder.state === 'ERROR' && thankYouType === 'VIDEO') ||
            (audioRecorder.state === 'ERROR' && thankYouType === 'AUDIO')) {
            return 'MISSING_PERMISSIONS';
        }
        return 'IDLE';
    }, [
        thankYouType,
        videoRecorder.state,
        videoRecorder.recordedMedia,
        audioRecorder.state,
        audioRecorder.recordedMedia,
        uploaded,
        isAlreadySent,
    ]);
    const stream = useMemo(() => {
        if (thankYouType === 'VIDEO' && videoRecorder.mediaStream) {
            return videoRecorder.mediaStream;
        }
        if (thankYouType === 'AUDIO' && audioRecorder.mediaStream) {
            return audioRecorder.mediaStream;
        }
    }, [thankYouType, videoRecorder.mediaStream, audioRecorder.mediaStream]);
    const uploadedFileType = useMemo(() => {
        if (uploaded?.type.startsWith('video/')) {
            return 'VIDEO';
        }
        if (uploaded?.type.startsWith('audio/')) {
            return 'AUDIO';
        }
    }, [uploaded]);
    const getPreviewError = (file) => {
        if (file.size > MAX_FILE_SIZE) {
            return 'FILE_TOO_LARGE';
        }
        return undefined;
    };
    const preview = useMemo(() => {
        if (thankYouType === 'VIDEO' && videoRecorder.recordedMedia) {
            return {
                type: 'VIDEO',
                url: window.URL.createObjectURL(videoRecorder.recordedMedia),
                file: videoRecorder.recordedMedia,
                imageSize: mediaImageSize,
                error: getPreviewError(videoRecorder.recordedMedia),
            };
        }
        if (thankYouType === 'AUDIO' && audioRecorder.recordedMedia) {
            return {
                type: 'AUDIO',
                url: window.URL.createObjectURL(audioRecorder.recordedMedia),
                file: audioRecorder.recordedMedia,
                error: getPreviewError(audioRecorder.recordedMedia),
            };
        }
        if (isAlreadySent && (thankYouType === 'AUDIO' || thankYouType === 'VIDEO')) {
            return {
                type: thankYouType,
                url: thankYouData.mediaUrl,
            };
        }
        if (thankYouType === 'UPLOAD' && uploaded && uploadedFileType) {
            return {
                type: uploadedFileType,
                url: window.URL.createObjectURL(uploaded),
                file: uploaded,
                imageSize: mediaImageSize,
                error: getPreviewError(uploaded),
            };
        }
    }, [
        thankYouType,
        videoRecorder.recordedMedia,
        audioRecorder.recordedMedia,
        uploaded,
        uploadedFileType,
        thankYouData,
        mediaImageSize,
        isAlreadySent,
    ]);
    const sources = useMemo(() => {
        if (thankYouType === 'AUDIO') {
            return {
                ...audioRecorder.mediaSources,
                onAudioSourceChange: audioRecorder.onAudioSourceChange,
            };
        }
        if (thankYouType === 'VIDEO') {
            return {
                ...videoRecorder.mediaSources,
                onAudioSourceChange: videoRecorder.onAudioSourceChange,
                onVideoSourceChange: videoRecorder.onVideoSourceChange,
            };
        }
        return { audio: [], video: [] };
    }, [thankYouType, audioRecorder.mediaSources, videoRecorder.mediaSources]);
    useEffect(() => {
        if (preview?.type === 'VIDEO' && !mediaImageSize) {
            const video = document.createElement('video');
            video.autoplay = false;
            video.onloadedmetadata = () => {
                setMediaImageSize({ width: video.videoWidth, height: video.videoHeight });
            };
            video.src = preview.url;
        }
    }, [preview?.type, preview?.url, mediaImageSize]);
    const onReinitialize = () => {
        if (thankYouType === 'VIDEO') {
            videoRecorder.reinitialize();
        }
        if (thankYouType === 'AUDIO') {
            audioRecorder.reinitialize();
        }
    };
    const onRecordStart = () => {
        timer.start();
        if (thankYouType === 'VIDEO') {
            videoRecorder.onRecordStart();
        }
        if (thankYouType === 'AUDIO') {
            audioRecorder.onRecordStart();
        }
    };
    const onRecordStop = () => {
        timer.stop();
        if (thankYouType === 'VIDEO') {
            videoRecorder.onRecordStop(timer.time);
        }
        if (thankYouType === 'AUDIO') {
            audioRecorder.onRecordStop(timer.time);
        }
    };
    const onRecordDelete = () => {
        timer.reset();
        setMediaImageSize(undefined);
        if (thankYouType === 'VIDEO') {
            videoRecorder.onRecordDelete();
        }
        if (thankYouType === 'AUDIO') {
            audioRecorder.onRecordDelete();
        }
        if (thankYouType === 'UPLOAD') {
            setUploadedFile(undefined);
        }
    };
    const onFileDrop = (acceptedFiles) => {
        setUploadedFile(acceptedFiles?.[0] ?? undefined);
    };
    const onFileDropRejected = () => {
        Toasts.notify('Provided file is not supported. Please provide a video or audio file.');
    };
    return {
        status,
        duration: timer.time,
        stream,
        preview,
        sources,
        onReinitialize,
        onRecordStart,
        onRecordStop,
        onRecordDelete,
        onFileDrop,
        onFileDropRejected,
        onSwitchFacingMode: videoRecorder.onSwitchFacingMode,
    };
};
