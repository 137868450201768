import { Fieldset, Icon, RadioButton } from '@fourthwall/components';
import { ExclamationMarkIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './ManufacturingServiceSelect.styled';
const ITEMS = [
    {
        manufacturingService: 'FOURTHWALL_BESPOKE',
        label: 'Fourthwall Bespoke',
    },
    {
        manufacturingService: 'MANUFACTURED_BY_CREATOR',
        label: 'Creator Manufactured',
        rightContent: React.createElement(Icon, { component: ExclamationMarkIcon, height: 24 }),
    },
];
export const ManufacturingServiceSelect = ({ selectedManufacturingService, onSelect }) => {
    return (React.createElement(Fieldset, null, ITEMS.map(({ label, manufacturingService, rightContent }) => (React.createElement(Fieldset.Row, { key: manufacturingService },
        React.createElement(RadioButton, { name: manufacturingService, value: manufacturingService, label: label, onChange: () => onSelect(manufacturingService), checkedValue: selectedManufacturingService }),
        rightContent && (React.createElement(S.ItemRightContentContainer, null, rightContent)))))));
};
