// this is only till the backend is done
export const fakeCreatorMessagesProductsApi = (pageNum, list) => {
    return new Promise((resolve) => {
        const data = {
            products: list.slice(0, (pageNum + 1) * 5),
            page: {
                pageNumber: 0,
                hasNextPage: pageNum !== 3,
            },
        };
        setTimeout(() => {
            resolve({ data });
        }, 100);
    });
};
export const fakeCreatorMessagesCollectionsApi = (pageNum, list) => {
    return new Promise((resolve) => {
        const data = {
            collections: list.slice(0, (pageNum + 1) * 5),
            page: {
                pageNumber: 0,
                hasNextPage: pageNum !== 3,
            },
        };
        setTimeout(() => {
            resolve({ data });
        }, 100);
    });
};
export const fakeCreatorMessagesSupportersApi = ({ pageNum, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
query, list, }) => {
    return new Promise((resolve) => {
        const data = {
            supporters: list.slice(0, (pageNum + 1) * 5),
            page: {
                pageNumber: 0,
                hasNextPage: pageNum !== 3,
            },
        };
        setTimeout(() => {
            resolve({ data });
        }, 100);
    });
};
