import { useLocation } from 'react-router-dom';
export const useHash = () => {
    const location = useLocation();
    const hasHash = (value) => {
        return location.hash.includes(value);
    };
    const removeHash = () => {
        window.location.hash = '';
        history.pushState('', document.title, window.location.pathname + window.location.search);
    };
    const addHash = (value) => {
        window.location.hash = value;
    };
    return {
        hash: location.hash,
        hasHash,
        addHash,
        removeHash,
    };
};
