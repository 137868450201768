import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const LeftSide = styled.div `
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;

  ${mediaQueries.tablet} {
    gap: 20px;
  }
`;
export const RightSide = styled.div `
  display: flex;
  align-items: center;
`;
export const Title = styled.div `
  ${mediaQueries.tablet} {
    font-weight: 600;
  }
`;
export const Href = styled.a `
  display: inline-block;
  margin-left: 8px;

  &:hover {
    color: ${Colors.B500};
  }
`;
export const Description = styled.div `
  color: ${Colors.GS600};
  font-size: 14px;
  line-height: 150%;
`;
