import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import uniq from 'lodash-es/uniq';
import { formatCurrency } from '@fourthwall/utils/lib/currency';
import { getHasDifferentSizes, getInitialPricingType } from '../utils';
import { useOfferQuery } from '../queries/useOfferQuery';
import { getCostsPerSize, getSameSizesHaveDifferentCosts } from '../store/product/utils';
export const useProductPricing = () => {
    const [pricingType, setPricingType] = useState('IMPLICIT');
    const { productId = '' } = useParams();
    const { offerQuery } = useOfferQuery([productId]);
    const variantTypes = useMemo(() => offerQuery.data?.variantTypes || [], [offerQuery.data]);
    const variants = offerQuery.data?.variants || [];
    const profits = ((state) => {
        return state?.variants.reduce((result, currentVariant) => {
            const cost = currentVariant.cost?.value || null;
            if (cost) {
                const price = currentVariant.price.value;
                return [...result, Number(formatCurrency(price - cost))];
            }
            return result;
        }, []);
    })(offerQuery.data);
    const doProfitsVary = uniq(profits).length > 1;
    const doCompareAtPricesVary = (() => {
        if (variants.every((variant) => variant.compareAtPrice === null))
            return false;
        const baseCompareAtPrice = variants[0].compareAtPrice?.value || 0;
        const basePrice = variants[0].price.value;
        return variants.some((variant) => (variant.compareAtPrice?.value || 0) - variant.price.value !==
            baseCompareAtPrice - basePrice);
    })();
    const costsPerSize = getCostsPerSize(offerQuery.data);
    const sameSizesHaveDifferentCosts = getSameSizesHaveDifferentCosts(costsPerSize);
    const sizeVariant = variantTypes.find((variant) => variant.type === 'SIZE');
    const hasDifferentSizes = getHasDifferentSizes(sizeVariant);
    const initialPricingType = getInitialPricingType({
        doProfitsVary,
        hasDifferentSizes,
        sameSizesHaveDifferentCosts,
        doCompareAtPricesVary,
    });
    useEffect(() => {
        if (initialPricingType !== pricingType) {
            setPricingType(initialPricingType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialPricingType]);
    // NOTE: if sizes have different prices, we are able to modify them separately using explicit pricing type
    const anyVariantsOtherThanSizeHaveVaryingPrices = useMemo(() => variantTypes.some(({ type, variesBy }) => type !== 'SIZE' && variesBy.price), [variantTypes]);
    return {
        pricingType,
        showPricingPerVariant: anyVariantsOtherThanSizeHaveVaryingPrices,
        setPricingType,
    };
};
