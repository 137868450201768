import axios from 'axios';
import { ApiBase } from '../ApiBase';
import { collectionsFixture } from './__fixture__/collectionsFixture';
import { fakeCreatorMessagesCollectionsApi, fakeCreatorMessagesProductsApi, fakeCreatorMessagesSupportersApi, } from './__fixture__/fakeApi';
import { productsFixture } from './__fixture__/productsFixture';
import { supportersFixture } from './__fixture__/supportersFixture';
class CreatorMessagesApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getCreatorMessages = ({ page = 0, size = 10 }) => {
            return this.get(`/creator-messages?page=${page}&size=${size}`);
        };
        this.getCreatorMessage = (messageId) => {
            return this.get(`/creator-messages/${messageId}`);
        };
        this.getPotentialRecipients = (recipientFilter) => {
            const body = {
                recipientFilter,
                onlyWithMarketingConsent: true,
            };
            return this.post('/creator-messages/recipients/total', body);
        };
        this.createCreatorMessage = (body) => {
            const messageToRecipientsDto = Object.assign(Object.assign({}, body), { onlyWithMarketingConsent: true });
            return this.post('/creator-messages', messageToRecipientsDto);
        };
        this.generateAttachmentUrl = (body) => {
            return this.post('/creator-messages/generate/upload/url', body);
        };
        this.uploadAttachment = ({ uploadUrl, file }) => {
            return axios.put(uploadUrl, file, {
                headers: {
                    'x-goog-content-length-range': '0,104857600',
                    'Content-Type': file.type,
                },
            });
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.getCreatorMessageCollections = (page, size = 5) => {
            // return this.get('/creator-messages/collections?page=${page}&size=${size}')
            return fakeCreatorMessagesCollectionsApi(page, collectionsFixture);
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.getCreatorMessageProducts = (page, size = 5) => {
            // return this.get('/creator-messages/products?page=${page}&size=${size}')
            return fakeCreatorMessagesProductsApi(page, productsFixture);
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.getSupporters = ({ query, page, size = 5 }) => {
            // const payload: QueryPotentialRecipientXRequest = {
            //   recipientFilter,
            //   onlyWithMarketingConsent: true,
            // }
            // return this.post<QueryPotentialRecipientXRequest, NumberOfRecipientsXResponse>(
            //   '/creator-messages/recipients/total',
            //   payload,
            // )
            // return this.get('/creator-messages/products?page=${page}&size=${size}')
            return fakeCreatorMessagesSupportersApi({ pageNum: page, query, list: supportersFixture });
        };
    }
}
export const CreatorMessagesApiClient = new CreatorMessagesApiClientModel();
