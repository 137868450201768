import styled, { css } from 'styled-components';
import { styledUtils } from '@fourthwall/components';
export const Container = styled.div `
  max-height: ${({ maxHeight }) => maxHeight}px;
  overflow-y: scroll;
  mask-image: linear-gradient(
    180deg,
    transparent 0%,
    black
      ${({ isScrolledTop, isScrolledBottom, maskPercentage }) => `${isScrolledTop ? 0 : maskPercentage}% ${100 - (isScrolledBottom ? 0 : maskPercentage)}%`},
    transparent 100%
  );

  ${({ hideScrollbar }) => hideScrollbar &&
    css `
      ${styledUtils.hideScrollbar};
    `}
`;
export const ScrollChecker = styled.div `
  height: 1px;
`;
