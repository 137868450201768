import isEqual from 'lodash-es/isEqual';
import isNil from 'lodash-es/isNil';
export const mapLabelsToCheckboxOptions = (labels) => Object.entries(labels).map(([key, value]) => {
    return {
        label: value,
        value: key,
    };
});
export const mapAvailabilityParamToFormValue = (availabilityParam) => {
    if (isNil(availabilityParam)) {
        return [];
    }
    return availabilityParam ? ['AVAILABLE'] : ['SOLD_OUT'];
};
export const mapAvailabilityFormValueToParam = (availabilityValue) => {
    if (isEqual(availabilityValue, ['AVAILABLE'])) {
        return true;
    }
    if (isEqual(availabilityValue, ['SOLD_OUT'])) {
        return false;
    }
};
