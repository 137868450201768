import React from 'react';
import { ProductBox, useProductBox } from '@fourthwall/module-product';
export const AnotherProductThumbnail = ({ product, checked, onChange, }) => {
    const { activeProduct, activeColorVariant, setActiveProductionMethod } = useProductBox({
        product,
    });
    return (React.createElement(ProductBox.Radio, { key: product.id, label: product.name, name: product.name, value: product.id, checked: checked, onChange: () => {
            onChange?.(product);
        } },
        React.createElement(ProductBox, { href: "#", interactive: false, product: product, activeProduct: activeProduct, activeColorVariant: activeColorVariant, setActiveProductionMethod: setActiveProductionMethod })));
};
