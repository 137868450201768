import { Button, ModalBox, Toasts } from '@fourthwall/components';
import React from 'react';
import { useCurrentShopContext } from '../../../../hooks/useCurrentShopContext';
import { useDuplicateThemeMutation } from './hooks/useDuplicateThemeMutation';
export const ThemeDuplicateModal = ({ id, name, onSuccess, close }) => {
    const { currentShop } = useCurrentShopContext();
    const { duplicateThemeMutation } = useDuplicateThemeMutation();
    const handleSubmit = () => {
        duplicateThemeMutation.mutate([currentShop.id, id], {
            onSuccess: () => {
                Toasts.notify(`${name} has been duplicated`, { type: 'info' });
                onSuccess();
                close();
            },
        });
    };
    const handleClose = () => {
        if (!duplicateThemeMutation.isLoading) {
            close();
        }
    };
    return (React.createElement(ModalBox, { size: "small", title: `Duplicate ${name}?`, onClose: handleClose, buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", disabled: duplicateThemeMutation.isLoading, onClick: handleClose }),
            React.createElement(Button, { label: "Duplicate", appearance: "primary", loading: duplicateThemeMutation.isLoading, onClick: handleSubmit }),
        ] }, "This will copy your theme and all its customization. This process takes up to 2 minutes."));
};
