import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
`;
export const ItemContainer = styled(Link) `
  padding: 32px 24px;
  border: 1px solid ${Colors.GS400};
  margin: 0 -1px -1px 0;

  text-align: center;

  position: relative;

  &:hover {
    background: ${Colors.GS200};
  }
`;
export const ItemThumbnail = styled.img `
  width: 64px;
  height: 64px;
  margin: 0 auto 24px;
`;
export const ItemTitle = styled.div `
  color: ${Colors.GS1000};
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`;
export const ItemDescription = styled.div `
  margin-top: 6px;
`;
export const ItemTagContainer = styled.div `
  position: absolute;
  top: 7px; // 8 - 1
  left: 7px; // 8 - 1
`;
