import { Dropdown, Icon, useMediaQueryContext, useModalContext } from '@fourthwall/components';
import { DotsHorizontalIcon, PencilIcon, PreviewOffIcon, TrashIcon, TypingIcon, } from '@fourthwall/icons';
import { reverse } from 'named-urls';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useBottomSheetContext } from '../../../../components/BottomSheet';
import { routing } from '../../../../../../utils/routing';
import { useRemoveSection } from '../../../../hooks/useRemoveSection';
import { useSectionVisibility } from '../../../../hooks/useSectionVisibility';
import { useThemeEditorContext } from '../../../../hooks/useThemeEditorContext';
import { usePageLayoutContext } from '../../../../providers/PageLayoutProvider';
import { UpdateSectionTitleModal } from '../../../UpdateSectionTitleModal/UpdateSectionTitleModal';
import { useThemeLivePreviewContext } from '../../providers/ThemeLivePreviewProvider';
import * as S from './styled';
export const InspectorToolbar = () => {
    const media = useMediaQueryContext();
    const { rect, activeSectionName, activeSectionKey } = useThemeLivePreviewContext();
    const { open } = useModalContext();
    const navigate = useNavigate();
    const { currentPageId } = useThemeEditorContext();
    const { pageLayoutQuery } = usePageLayoutContext();
    const { removeSection } = useRemoveSection();
    const { changeSectionVisibility } = useSectionVisibility();
    const { triggerBounce: triggerBottomSheetBounce } = useBottomSheetContext();
    const section = pageLayoutQuery.data?.sections.blocks
        .flatMap((block) => block.sections)
        .find((section) => section.section_key === activeSectionKey);
    const borderWidth = 3;
    if (!activeSectionName)
        return null;
    const redirectToSection = () => {
        if (!activeSectionKey)
            return;
        !media.laptop && triggerBottomSheetBounce();
        navigate(reverse(routing.themeEditor.layout.page.sections, {
            pageId: currentPageId,
            sectionKey: activeSectionKey,
        }));
    };
    return (React.createElement(S.Layout, { style: {
            top: (rect.top > 0 ? 0 : Math.abs(rect.top)) - borderWidth,
            left: -borderWidth,
        } },
        React.createElement(S.List, null,
            React.createElement(S.ListItem, { onClick: redirectToSection },
                React.createElement(Icon, { component: PencilIcon, height: 12 }),
                "Edit ",
                activeSectionName),
            section?.modifiable && (React.createElement(Dropdown, { key: activeSectionKey, content: React.createElement(React.Fragment, null,
                    React.createElement(Dropdown.Item, { label: React.createElement(S.OptionItem, null,
                            React.createElement(Icon, { component: PreviewOffIcon, height: 16 }),
                            "Hide section"), onClick: () => changeSectionVisibility({
                            sectionKey: activeSectionKey,
                            visible: false,
                            forceReload: false,
                        }) }),
                    React.createElement(Dropdown.Item, { label: React.createElement(S.OptionItem, null,
                            React.createElement(Icon, { component: TypingIcon, height: 16 }),
                            "Rename section"), onClick: () => {
                            open(UpdateSectionTitleModal, {
                                id: section.id,
                                title: section.name,
                                onSuccess: () => {
                                    pageLayoutQuery.refetch();
                                },
                            });
                        } }),
                    React.createElement(Dropdown.Item, { label: React.createElement(S.OptionItem, null,
                            React.createElement(Icon, { component: TrashIcon, height: 16 }),
                            "Delete section"), onClick: () => removeSection(activeSectionKey) })) },
                React.createElement(S.ListItem, null,
                    React.createElement(Icon, { component: DotsHorizontalIcon, height: 12 })))))));
};
