import { ApiBase } from './ApiBase';
class PlatformApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getFeatureFlags = () => {
            return this.get('/platform/features.json');
        };
    }
}
export const PlatformApiClient = new PlatformApiClientModel('/');
