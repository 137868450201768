import { GiveawayConfigApiClient } from '@fourthwall/services/lib/GiveawayConfigApiClient';
import { useMutation } from '@tanstack/react-query';
export const useGiveawayConfigMutation = (options) => {
    const giveawayConfigMutation = useMutation({
        mutationFn: async (payload) => {
            const { data } = await GiveawayConfigApiClient.updateGiveawayConfig(payload);
            return data;
        },
        ...options,
    });
    return {
        giveawayConfigMutation,
    };
};
