import { useModalContext } from '@fourthwall/components';
import { reverse, routing } from '@utils/routing';
import { TransactionSummaryModal } from '../../../TransactionSummaryModal';
const getDonationPath = (donationId) => reverse(routing.contributions.orders.donations.donation.self, { donationId });
const getOrderPath = (orderId) => reverse(routing.contributions.orders.order.self, { orderId });
// prettier-ignore
const GET_PATHNAMES = {
    DONATION: (donationId) => {
        return donationId && getDonationPath(donationId);
    },
    DONATION_CANCEL: (donationId) => {
        return donationId && getDonationPath(donationId);
    },
    ORDER: (orderId) => {
        return orderId && getOrderPath(orderId);
    },
    ORDER_REFUND: (orderId) => {
        return orderId && getOrderPath(orderId);
    },
    ORDER_CANCEL: (orderId) => {
        return orderId && getOrderPath(orderId);
    },
    ORDER_PARTIAL_CANCEL: (orderId) => {
        return orderId && getOrderPath(orderId);
    },
    INVENTORY_SHIPMENT: (shippingId) => {
        return shippingId && reverse(routing.settings.inventoryShipments.self, { shippingId });
    },
    INVENTORY_STORAGE: (snapshotId) => {
        return snapshotId && reverse(routing.settings.inventoryStocks.self, { snapshotId });
    }
};
const getFromState = (location) => {
    return { from: { label: 'Earnings history', location } };
};
const getPathname = ({ type, originalId }) => {
    const pathname = GET_PATHNAMES[type];
    return pathname && pathname(originalId);
};
export const getTransactionProps = (transaction, location) => {
    const { open, close } = useModalContext();
    const pathname = getPathname(transaction);
    if (pathname) {
        return { to: { pathname, state: getFromState(location) } };
    }
    return { onClick: () => open(TransactionSummaryModal, { transaction, onClose: close }) };
};
