import { HeadContent, Pagination } from '@fourthwall/components';
import React from 'react';
import { Dashboard } from '@components/common';
import { GiveawaysData } from './components/GiveawaysData';
import { GIVEAWAYS_CONSTANTS } from './constants';
export const GiveawaysView = ({ list, table, pagination }) => (React.createElement(React.Fragment, null,
    React.createElement(HeadContent, { title: GIVEAWAYS_CONSTANTS.title }),
    React.createElement(Dashboard.Section.Item, null,
        React.createElement(GiveawaysData, { list: list, table: table })),
    pagination && (React.createElement(Dashboard.Section.Item, null,
        React.createElement(Pagination, { ...pagination })))));
