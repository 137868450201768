import { reverse } from 'named-urls';
import { routing } from '../../../../../../utils/routing';
export const redirectToDesigner = ({ customizationId, productId, designerVersion, }) => {
    if (designerVersion === 'V2' || designerVersion === 'V3') {
        const designerV3Url = reverse(routing.productDesignerV3.customizationEdit, {
            customizationId,
            productId,
        });
        window.location.assign(designerV3Url);
        return;
    }
    if (designerVersion === 'PRINTFUL') {
        const designerPrintfulUrl = reverse(routing.printfulEmbededDesigner.customizationEdit, {
            customizationId,
            productId,
        });
        window.location.assign(designerPrintfulUrl);
        return;
    }
    throw new Error('Cannot redirect. Invalid designer version');
};
