import { Input, Select } from '@fourthwall/components';
import { Field, useFormikContext } from 'formik';
import get from 'lodash-es/get';
import React, { useState } from 'react';
import { getWeightOptions } from '../../../utils/products';
import * as S from './styled';
export const WeightInput = ({ weightName, unitName, size = 'large', weightWidth = 136, unitWidth = 80, disabled = false, displayError = false, onWeightValueChange, onUnitValueChange, }) => {
    const [isWeightInputFocused, setIsWeightInputFocused] = useState(false);
    const [isUnitInputFocused, setIsUnitInputFocused] = useState(false);
    const { errors } = useFormikContext();
    const invalid = !!get(errors, weightName) || !!get(errors, unitName);
    return (React.createElement(React.Fragment, null,
        React.createElement(S.WeightWrapper, { active: isWeightInputFocused, style: { width: weightWidth }, "data-testid": "WeightInput.Weight" },
            React.createElement(Field, { component: Input, name: weightName, label: "Weight", type: "number", min: 0, hideSpinner: true, size: size, displayError: displayError, invalid: invalid, disabled: disabled, onValueChange: onWeightValueChange, onFocus: !disabled ? () => setIsWeightInputFocused(true) : undefined, onBlur: () => setIsWeightInputFocused(false) })),
        React.createElement(S.UnitWrapper, { active: isUnitInputFocused, style: { width: unitWidth }, "data-testid": "WeightInput.Unit" },
            React.createElement(Field, { component: Select, name: unitName, label: "Unit", options: getWeightOptions(), size: size, displayError: displayError, invalid: invalid, disabled: disabled, onValueChange: onUnitValueChange, onFocus: !disabled ? () => setIsUnitInputFocused(true) : undefined, onBlur: () => setIsUnitInputFocused(false) }))));
};
