import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;

  ${({ bold }) => bold &&
    css `
      font-weight: 600;
    `}
`;
export const Text = styled.div `
  display: flex;
  align-items: center;
`;
export const Cost = styled.div `
  width: 88px;
  margin-left: 16px;
  text-align: right;
`;
