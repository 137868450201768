import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowShortRightIcon } from '@fourthwall/icons';
import { Icon } from '../Icon';
import { BaseContent } from '../../typography';
import * as S from './styled';
export const RecommendationButton = (props) => {
    const { label, appearance = 'default', icon } = props;
    return (React.createElement(S.Container, { as: 'to' in props ? Link : 'button', to: 'to' in props ? props.to : undefined, appearance: appearance, onClick: 'onClick' in props ? props.onClick : undefined },
        React.createElement("div", null,
            React.createElement(Icon, { component: icon, height: 18 })),
        React.createElement(S.Label, null,
            React.createElement(BaseContent, null, label)),
        React.createElement("div", null,
            React.createElement(Icon, { component: ArrowShortRightIcon, height: 12 }))));
};
