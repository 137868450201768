import React from 'react';
import { useAuth } from '@fourthwall/auth';
import { usePartnerDashboard } from '@hooks/usePartnerDashboard';
import { MenuDrawerProvider } from '@modules/Dashboard/contexts/MenuDrawerContext';
import { useFirstTimeVisit } from '@modules/Dashboard/hooks/useFirstTimeVisit';
import { DashboardView } from './DashboardView';
import { useCurrentShop } from './hooks/useCurrentShop';
import { useAddProductToFavoritesModal } from './hooks/useAddProductToFavoritesModal';
import { useConnectedShopsQuery } from './hooks/useConnectedShopsQuery';
export const DashboardContainer = () => {
    const { currentShop } = useCurrentShop();
    const { user } = useAuth();
    const { partnerDashboard } = usePartnerDashboard();
    const { connectedShopsQuery } = useConnectedShopsQuery();
    useFirstTimeVisit();
    useAddProductToFavoritesModal();
    return (React.createElement(MenuDrawerProvider, null,
        React.createElement(DashboardView, { shopName: currentShop?.name, user: user ? { name: user.fullName, email: user.email } : undefined, connectedShops: connectedShopsQuery.data?.shops, partnerDashboard: partnerDashboard })));
};
