import { mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
import { PAGE_LAYOUT_STYLED_CONSTS } from './consts';
export const RIGHT_CONTENT_WIDTH = 272;
export const CONTENT_GAP = 56;
export const Container = styled.div(({ size }) => css `
    display: flex;
    flex-direction: column;

    height: 100%;
    margin-left: auto;
    margin-right: auto;

    ${size === 'tiny' &&
    css `
      max-width: ${PAGE_LAYOUT_STYLED_CONSTS.MAX_WIDTH_TINY}px;
    `};

    ${size === 'small' &&
    css `
      max-width: ${PAGE_LAYOUT_STYLED_CONSTS.MAX_WIDTH_SMALL}px;
    `};

    ${size === 'medium' &&
    css `
      max-width: ${PAGE_LAYOUT_STYLED_CONSTS.MAX_WIDTH_MEDIUM}px;
    `};

    ${size === 'large' &&
    css `
      max-width: ${PAGE_LAYOUT_STYLED_CONSTS.MAX_WIDTH_MEDIUM}px;

      ${mediaQueries.desktop} {
        max-width: 1200px;
      }
    `};

    ${size === 'full' &&
    css `
      max-width: none;
    `};
  `);
export const LeftContent = styled.div `
  flex: 1;
  min-width: 0;
`;
export const RightContent = styled.div `
  flex: 1;
  margin-top: 56px;

  ${mediaQueries.laptop} {
    flex: 0 1 ${RIGHT_CONTENT_WIDTH}px;

    width: ${RIGHT_CONTENT_WIDTH}px;
    margin-top: 0;
    margin-left: ${CONTENT_GAP}px;
  }
`;
export const BottomContent = styled.div(({ margin }) => css `
    ${margin === 'large' &&
    css `
      margin-top: 56px;
    `}

    ${margin === 'medium' &&
    css `
      margin-top: 28px;
    `}

    ${margin === 'small' &&
    css `
      margin-top: 14px;
    `}
  `);
export const InnerContainer = styled.div `
  display: flex;
  flex-direction: column;

  ${mediaQueries.laptop} {
    flex-direction: row;
  }
`;
