import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
const SIZES = {
    small: {
        fontSize: 14,
        itemMargin: 16,
    },
    large: {
        fontSize: 16,
        itemMargin: 16,
    },
};
const DASHED_STYLES = css `
  background-image: linear-gradient(${Colors.GS400} 50%, rgba(255, 255, 255, 0) 0);
  background-position: right;
  background-size: 1px 9px;
  background-repeat: repeat-y;
  background-color: transparent;
`;
export const IconContainer = styled.div(({ completed }) => css `
    position: relative;
    top: -1px;
    z-index: 1;
    flex-shrink: 0;
    margin-right: 22px;
    width: 9px;
    height: 9px;

    border: 1px solid ${Colors.GS1000};
    border-radius: 50%;

    background-color: ${completed ? Colors.GS1000 : '#fff'};
  `);
export const Description = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
export const Time = styled.div `
  color: ${Colors.GS600};
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;
export const Title = styled.div(({ size }) => css `
    max-width: 400px;

    ${size === 'small' &&
    css `
      max-width: 192px;
    `}
  `);
export const Container = styled.ul(({ size }) => css `
    position: relative;

    font-size: 16px;

    ${size === 'small' &&
    css `
      font-size: 14px;
    `}
  `);
export const DateSection = styled.div(({ size }) => css `
    display: flex;
    position: relative;
    margin-bottom: 24px;
    margin-top: 32px;
    margin-left: 32px;

    font-size: 11px;
    color: ${Colors.GS600};
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;

    &:first-child {
      margin-top: 0px;

      &::before {
        display: none;
      }
    }

    &:after {
      content: '';
      position: relative;
      top: 6px;
      flex: 1;
      margin-left: 16px;
      height: 1px;
      background: ${Colors.GS400};
    }

    &::before {
      content: '';
      position: absolute;
      left: -28px;
      top: -20px;
      display: block;
      height: calc(100% + 60px);
      width: 1px;
      background-color: ${Colors.GS400};
    }

    ${size === 'small' &&
    css `
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 10px;
    `}
  `);
export const Item = styled.li(({ size, completed }) => css `
    position: relative;
    display: flex;
    align-items: baseline;
    margin-bottom: ${SIZES[size].itemMargin}px;

    &:last-of-type {
      margin-bottom: 0px;

      &::before {
        display: none;
      }
    }

    ${size === 'small' &&
    css `
      &:last-of-type::after {
        content: '';
        display: block;
        position: absolute;
        top: 12px;
        left: 4px;
        width: 1px;
        height: calc(100% + 20px);
        background: #e3e2e1;
      }
    `}
    &::before {
      content: '';
      position: absolute;
      left: 4px;
      top: 8px;
      display: block;
      height: calc(100% + ${SIZES[size].itemMargin}px);
      width: 1px;
      background-color: ${Colors.GS400};
    }

    ${!completed &&
    css `
      &::before {
        ${DASHED_STYLES}
      }

      & + ${DateSection}:first-of-type::before, & + ${DateSection}::before {
        display: block;
        ${DASHED_STYLES}
      }
    `}

    ${size === 'small' &&
    css `
      margin-bottom: 16px;
    `}
  `);
