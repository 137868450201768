import styled from 'styled-components';
import { Padding } from './types';
const getContainerPadding = ({ padding }) => {
    if (padding === Padding.Small) {
        return 12;
    }
    if (padding === Padding.Medium) {
        return 20;
    }
};
export const Container = styled.div `
  padding: ${getContainerPadding}px;
`;
export const Title = styled.div `
  font-weight: 600;
  margin-bottom: 12px;
`;
export const TimelineContainer = styled.div `
  padding-right: 8px;
`;
export const EmptyNotice = styled.div `
  font-size: 14px;
`;
