import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  margin-bottom: 16px;
  border: 1px solid ${Colors.GS300};
`;
export const Header = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 72px;
  padding: 12px ${20 - 1}px;

  background: ${Colors.GS200};
`;
export const Subtitle = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
  line-height: 21px;
`;
