import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const Title = styled.div `
  margin-bottom: 8px;

  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
`;
export const Description = styled.div `
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;
