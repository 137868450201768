import React, { useState } from 'react';
import { ArrowShortDownIcon, ArrowShortUpIcon } from '@fourthwall/icons';
import { ColorItem } from '../../common/ColorItem';
import { ColorFormat, ColorPicker } from '../../common/ColorPicker';
import { Icon } from '../../common/Icon';
import { withFormikSupport } from '../../hocs/withFormikSupport';
import { Checkboard } from '../../partials/Checkboard';
import { InputUI } from '../../ui';
import * as S from './ColorInput.styled';
import { Popper } from '../../common/Popper';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
const ColorInputComponent = ({ label = 'Color', value, hexVisible = true, size = 'large', onChange, onBlur, onOpen, onClose, }) => {
    const media = useMediaQueryContext();
    const [isOpen, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen((prevIsOpen) => {
            if (!prevIsOpen && onOpen) {
                onOpen();
            }
            if (prevIsOpen && onClose) {
                onClose();
            }
            return !prevIsOpen;
        });
    };
    const handleChange = (color) => {
        if (onChange) {
            onChange(color);
        }
        if (onBlur) {
            onBlur(color);
        }
    };
    return (React.createElement(Popper, { isOpen: isOpen, referenceComponent: React.createElement(S.InputContainer, { onClick: toggleOpen, "aria-label": label },
            React.createElement(InputUI.Box, { size: size, focused: isOpen },
                React.createElement(S.ColorContainer, null,
                    React.createElement(Checkboard, null),
                    value && (React.createElement(S.ColorItemContainer, null,
                        React.createElement(ColorItem, { appearance: "multi-select", color: { label: '', value } })))),
                React.createElement(S.ChevronContainer, null,
                    isOpen && React.createElement(Icon, { component: ArrowShortUpIcon, height: 12 }),
                    !isOpen && React.createElement(Icon, { component: ArrowShortDownIcon, height: 12 })))), popperProps: {
            placement: 'bottom-start',
        }, style: media.tablet ? { paddingTop: 12, paddingBottom: 12 } : undefined, onClose: () => setOpen(false) },
        React.createElement(ColorPicker, { color: value, position: "bottom-start", format: ColorFormat.HEX, hexVisible: hexVisible, onValueChange: handleChange })));
};
export const ColorInput = withFormikSupport(ColorInputComponent);
