import { usePrevious } from '@fourthwall/components';
import { useEffect, useMemo, useState } from 'react';
import { useCurrentShopContext } from '../../../hooks/useCurrentShopContext';
import { useThemeEditorContext } from '../../../hooks/useThemeEditorContext';
import { getThemeList } from '../utils';
import { useInstalledThemesQuery } from './useInstalledThemesQuery';
import { useThemeLibraryQuery } from './useThemeLibraryQuery';
export const useThemeList = () => {
    const { currentShop } = useCurrentShopContext();
    const { resetPreviewTimestamp } = useThemeEditorContext();
    const [refetchInterval, setRefetchInterval] = useState(false);
    const { installedThemesQuery } = useInstalledThemesQuery([{ shop_id: currentShop.id }], {
        refetchInterval,
    });
    const { themeLibraryQuery } = useThemeLibraryQuery([], {
        refetchInterval,
    });
    const installedThemes = installedThemesQuery.data?.themes;
    const availableThemes = useMemo(() => installedThemes?.filter((theme) => theme.role !== 'main') || [], [installedThemes]);
    const libraryThemes = themeLibraryQuery.data?.themes || [];
    const currentTheme = installedThemes?.find((theme) => theme.role === 'main');
    const isAnyThemeProcessing = installedThemes?.some((theme) => theme.processing || theme.status === 'Duplicating');
    const [selectedThemeId, setSelectedThemeId] = useState();
    const [themeList, setThemeList] = useState(getThemeList(currentTheme, availableThemes, selectedThemeId));
    const selectedTheme = themeList.find((theme) => theme.id === selectedThemeId);
    const isSelectedThemeProcessing = !!selectedTheme?.isProcessing;
    const prevIsSelectedThemeProcessing = usePrevious(isSelectedThemeProcessing);
    useEffect(() => {
        setThemeList(getThemeList(currentTheme, availableThemes, selectedThemeId));
    }, [installedThemes, selectedThemeId, availableThemes]);
    useEffect(() => {
        if (currentTheme && availableThemes && !selectedTheme) {
            setSelectedThemeId(currentTheme?.id || availableThemes[0]?.id);
        }
    }, [currentTheme, availableThemes]);
    useEffect(() => {
        if (prevIsSelectedThemeProcessing && !isSelectedThemeProcessing) {
            resetPreviewTimestamp();
        }
    }, [prevIsSelectedThemeProcessing, isSelectedThemeProcessing]);
    useEffect(() => {
        if (isAnyThemeProcessing) {
            setRefetchInterval(2000);
        }
        else {
            setRefetchInterval(false);
        }
    }, [isAnyThemeProcessing]);
    const setThemeIsProcessing = (handle, isProcessing = true) => {
        setThemeList((themeList) => themeList.map((theme) => {
            if (theme.handle === handle) {
                return { ...theme, isProcessing };
            }
            return theme;
        }));
    };
    return {
        themeList,
        libraryThemes,
        currentTheme,
        selectedTheme,
        selectedThemeId,
        isLoadingThemeList: installedThemesQuery.isLoading,
        setSelectedThemeId,
        setThemeIsProcessing,
        refetchThemeList: installedThemesQuery.refetch,
    };
};
