import { Dashboard } from '@components/common';
import { NavTabs } from '@components/common/NavTabs';
import { DashboardPermissionGuard } from '@components/utils/DashboardPermissions';
import { AddButton, BaseContent, Button, ButtonClean, DateString, HeadContent, Heading, LeavingGuard, Loading, Pagination, PriceString, SaveShortcutListener, Subsection, } from '@fourthwall/components';
import { ChargesHistoryData } from '@modules/Dashboard/Settings/modules/Payouts/components/ChargesHistoryData';
import { routing } from '@utils/routing';
import { Formik } from 'formik';
import React from 'react';
import { ConfirmBarContainer } from '../../../ConfirmBar';
import { ChargeLimitFormGroup } from '../../../PaymentMethods';
import { PayoutsHistoryData } from './components/PayoutsHistoryData';
import * as S from './styled';
import { getSetupBy, getSetupTitle } from './utils';
export const PayoutsView = ({ initialValues, validationSchema, accountBalance, payoutsSetup, paymentMethods, tabsProps, payoutsHistory, chargesHistory, isSubmitting, onSubmit, }) => {
    const renderPayoutsSetup = () => {
        const { setup } = payoutsSetup;
        const under25Text = typeof accountBalance.value === 'number' && accountBalance.value <= 25
            ? ' (as long as you’ve earned over $25)'
            : '';
        if (setup.status === 'INACTIVE') {
            return (React.createElement(React.Fragment, null,
                React.createElement(BaseContent, null,
                    "At the start of each month we will transfer your earnings to a bank account of your choosing",
                    under25Text,
                    ".",
                    ' ',
                    React.createElement("a", { href: "https://help.fourthwall.com/hc/en-us/articles/13331326910363-How-do-I-get-paid", target: "_blank", rel: "noreferrer" }, "Learn more")),
                setup.onSetup && (React.createElement("div", { style: { marginTop: 16 } },
                    React.createElement(Button, { label: "Set up your payouts", appearance: "primary", onClick: setup.onSetup })))));
        }
        if (setup.status === 'ACTIVE') {
            return (React.createElement(React.Fragment, null,
                React.createElement(BaseContent, null, getSetupTitle(setup.inProgress, setup.info.partnerName)),
                setup.onEdit && (React.createElement("div", { style: { marginTop: 4 } },
                    React.createElement(ButtonClean, { label: "Edit bank connection", onClick: setup.onEdit }))),
                setup.onNewSetup && (React.createElement("div", { style: { marginTop: 4 } },
                    React.createElement(ButtonClean, { label: "Set up new payout bank account", onClick: setup.onNewSetup }))),
                React.createElement("div", { style: { marginTop: 12 } },
                    React.createElement(BaseContent, { appearance: "secondary", size: "small" },
                        "Set up by ",
                        getSetupBy(setup.info.ownerEmail, setup.info.ownerName),
                        " on",
                        ' ',
                        React.createElement(DateString, { date: setup.info.date, format: "DD MMM, YYYY" }),
                        "."))));
        }
        return null;
    };
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: onSubmit }, ({ values, dirty, submitForm, resetForm, handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(HeadContent, { title: "Billing and payouts \u2013 Settings" }),
        React.createElement(Subsection, { title: "Account balance" },
            accountBalance.value !== undefined && (React.createElement("div", { style: { marginBottom: 16 } },
                React.createElement(Heading, { type: "h5", weight: "bold" },
                    React.createElement(PriceString, { value: accountBalance.value })))),
            React.createElement(DashboardPermissionGuard, { permission: "settings.billing.payouts" },
                React.createElement(Loading, { isLoading: payoutsSetup.isLoading }, renderPayoutsSetup()))),
        React.createElement(DashboardPermissionGuard, { permission: "settings.billing.paymentMethods" },
            React.createElement(Subsection, { title: "Payment methods" },
                React.createElement(S.PaymentMethodsSectionText, null,
                    "Cover giveaways, refunds, and other actions that cost money. You will only be charged if your account balance does not cover the cost.",
                    ' ',
                    React.createElement("a", { href: routing.external.helpCenter.creditCard, target: "_blank", rel: "noopener noreferrer" }, "Learn more")),
                paymentMethods.list,
                React.createElement("div", { style: { marginTop: 16 } },
                    paymentMethods.buttonType === 'primary' && (React.createElement(Button, { label: "Add a payment method", appearance: "primary", onClick: paymentMethods.onAdd })),
                    paymentMethods.buttonType === 'add' && (React.createElement(AddButton, { label: "Add a payment method", onClick: paymentMethods.onAdd }))),
                React.createElement(S.SpendingLimitContainer, null,
                    React.createElement(ChargeLimitFormGroup, { isEnabled: values.setChargeLimit, currentMonthSpending: paymentMethods.chargedCurrentMonth })))),
        React.createElement(Subsection, { borderless: true },
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(S.TabsContainer, null,
                    React.createElement(NavTabs, { ...tabsProps }),
                    React.createElement(DashboardPermissionGuard, { permission: "settings.billing.history.full" },
                        React.createElement(ButtonClean, { label: "View full history", to: routing.settings.billing.transactions })))),
            tabsProps.activeTab === 'payouts' && (React.createElement(Dashboard.Section.Item, null,
                React.createElement(S.TableWrapper, null,
                    React.createElement(PayoutsHistoryData, { data: payoutsHistory.data, isLoading: payoutsHistory.isLoading }),
                    payoutsHistory.pagination && React.createElement(Pagination, { ...payoutsHistory.pagination })))),
            tabsProps.activeTab === 'charges' && (React.createElement(Dashboard.Section.Item, null,
                React.createElement(S.TableWrapper, null,
                    React.createElement(ChargesHistoryData, { data: chargesHistory.data, isLoading: chargesHistory.isLoading }),
                    chargesHistory.pagination && React.createElement(Pagination, { ...chargesHistory.pagination }))))),
        React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
        React.createElement(ConfirmBarContainer, { isOpen: dirty || isSubmitting, onConfirm: submitForm, onCancel: resetForm, isLoading: isSubmitting }),
        React.createElement(LeavingGuard, { when: dirty })))));
};
