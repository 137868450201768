export const isTimeFrameFrom = (timeFrame) => {
    return timeFrame['@type'] === 'TIME_FRAME_FROM';
};
export const isTimeFrameTo = (timeFrame) => {
    return timeFrame['@type'] === 'TIME_FRAME_TO';
};
export const isTimeFrameRange = (timeFrame) => {
    return timeFrame['@type'] === 'TIME_FRAME_RANGE';
};
export const isTimeFrameNone = (timeFrame) => {
    return timeFrame['@type'] === 'TIME_FRAME_NONE';
};
