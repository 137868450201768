import { HeadContent, Pagination } from '@fourthwall/components';
import isEmpty from 'lodash-es/isEmpty';
import React from 'react';
import { Dashboard } from '@components/common';
import { PromotionCodesData } from './components/PromotionCodesData';
export const PromotionCodesView = ({ promotions, currentPage, totalPages, isFetching, emptyStateProps, onPageChange, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Promo codes" }),
        React.createElement(Dashboard.Section.Item, null,
            React.createElement(PromotionCodesData, { data: promotions || [], isLoading: isFetching, emptyStateProps: emptyStateProps })),
        !isEmpty(promotions) && totalPages > 0 && (React.createElement(Dashboard.Section.Item, null,
            React.createElement(Pagination, { pageCount: totalPages, currentPage: currentPage, onChange: onPageChange })))));
};
