import { formatCurrency } from '@fourthwall/utils/lib/currency';
import { devConsoleError } from '@fourthwall/utils/lib/dev';
import cloneDeep from 'lodash-es/cloneDeep';
import groupBy from 'lodash-es/groupBy';
import map from 'lodash-es/map';
import set from 'lodash-es/set';
import unescape from 'lodash-es/unescape';
import { convertNumberToPrice } from './ProductPricing/utils/convertNumberToPrice';
import { convertNumberToMoney } from './ProductPricing/utils/convertNumberToMoney';
export const getInitialValues = (props) => {
    const { name, description, variants, visualHints, variantTypes, images, withMembersOnly, membersOnlyType, membersOnlyTierIds, digitalItems, isAvailable, fulfillmentService, manufacturingService, shipment, bespokeProductId, customsInformation, shippingPackageSize, } = props;
    const isCompareAtPriceEnabled = variants.some((variant) => !!variant.compareAtPrice);
    return {
        name: unescape(name),
        description: unescape(description),
        sellingPriceValue: formatCurrency(variants[0].price.value),
        isCompareAtPriceEnabled,
        compareAtPriceValue: isCompareAtPriceEnabled && variants[0].compareAtPrice
            ? formatCurrency(variants[0].compareAtPrice?.value)
            : null,
        visualHints,
        isSoldOut: !isAvailable,
        offerImages: images ? images.map((image) => ({ ...image, tags: image.tags.tags })) : [],
        withMembersOnly,
        membersOnlyType,
        membersOnlyTierIds,
        variantTypes,
        shipment: {
            type: shipment?.shipmentStartDate.type === 'UNSET' ||
                shipment?.shipmentStartDate.type === 'BUSINESS_DAYS_RANGE'
                ? 'DAYS_RANGE'
                : shipment?.shipmentStartDate.type || 'UNKNOWN',
            days: {
                daysMin: shipment?.shipmentStartDate.type === 'DAYS_RANGE'
                    ? shipment?.shipmentStartDate.daysMin.toString()
                    : undefined,
                daysMax: shipment?.shipmentStartDate.type === 'DAYS_RANGE'
                    ? shipment?.shipmentStartDate.daysMax.toString()
                    : undefined,
            },
            dates: {
                from: shipment?.shipmentStartDate.type === 'DATE_RANGE'
                    ? shipment?.shipmentStartDate.from.toString()
                    : undefined,
                to: shipment?.shipmentStartDate.type === 'DATE_RANGE'
                    ? shipment?.shipmentStartDate.to.toString()
                    : undefined,
            },
        },
        shipmentDateUpdatePendingOrders: true,
        shipmentDateNotifyPendingSupporters: true,
        shipmentDateUpdateReason: '',
        variants: variants.map((variant) => {
            let result = cloneDeep(variant);
            if (variant.price) {
                result = set(result, 'price', convertNumberToPrice(result.price.value, result.price.currency));
            }
            if (variant.compareAtPrice) {
                result = set(result, 'compareAtPrice', convertNumberToPrice(result.compareAtPrice.value, result.compareAtPrice.currency));
            }
            if (variant.weight) {
                result = set(result, 'weight.value', result.weight.value.toString());
            }
            return result;
        }),
        digitalItems: digitalItems.map((digitalItem) => ({
            id: digitalItem.uri,
            url: digitalItem.uri,
            name: digitalItem.name,
            size: digitalItem.metadata?.fileSize,
        })),
        fulfillmentService,
        manufacturingService,
        bespokeProductId,
        customsInformation,
        shippingPackageSize,
    };
};
export const getVariantTypesWithVaryingField = (types, fieldName) => {
    return types.filter((variantType) => variantType.variesBy[fieldName]);
};
export const getAttributeByType = (variant, type) => {
    return variant.attributesList.find((attribute) => attribute.type === type);
};
const getGroupLabel = (variant, variantsAttributesValues, glue = ',') => {
    return variantsAttributesValues.join(glue);
};
const getVariantTags = (variant, variantTypes) => {
    return variantTypes.map((variantType) => {
        const attribute = getAttributeByType(variant, variantType.type);
        return {
            type: variantType.type,
            name: attribute?.name,
            colorSwatch: attribute?.colorSwatch,
        };
    });
};
const getVariantAttributesValues = (variant, variantTypes) => {
    return variantTypes
        .map((variantType) => getAttributeByType(variant, variantType.type)?.name)
        .filter(Boolean);
};
const addVariantsProps = (variantGroups, variantTypes) => {
    return map(variantGroups, (variantGroup, key) => variantGroup.map((variant, index) => {
        const variantAttributesValues = getVariantAttributesValues(variant, variantTypes);
        return {
            ...variant,
            tags: getVariantTags(variant, variantTypes),
            groupId: getGroupLabel(variant, variantAttributesValues),
            groupLabel: getGroupLabel(variant, variantAttributesValues, ', '),
            isFirstOfGroup: index === 0,
            isLastOfGroup: index === variantGroups[key].length - 1,
        };
    }));
};
export const getVariantsGroups = (variants, variantTypes) => {
    const variantTypesWithVaryingImages = getVariantTypesWithVaryingField(variantTypes, 'imagery');
    const variantGroups = groupBy(variants, (variant) => {
        const variantAttributesValues = getVariantAttributesValues(variant, variantTypesWithVaryingImages);
        return getGroupLabel(variant, variantAttributesValues);
    });
    return addVariantsProps(variantGroups, variantTypesWithVaryingImages);
};
export const flattenVariantGroups = (variantGroups) => {
    return Object.keys(variantGroups).reduce((result, key) => [...result, ...variantGroups[key]], []);
};
export const getFirstVariantGroupItem = (variantGroups) => {
    return Object.keys(variantGroups).reduce((result, key) => [...result, variantGroups[key][0]], []);
};
export const getPreviewImage = (imageIds, allImages) => {
    if (!imageIds.length)
        return undefined;
    return allImages ? allImages.find((image) => image.id === imageIds[0])?.url : null;
};
const createImage = (url) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });
};
export const getCroppedImage = async (fileBase64, croppedArea, type) => {
    const image = await createImage(fileBase64);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxSize = Math.max(image.width, image.height);
    canvas.width = maxSize;
    canvas.height = maxSize;
    if (!ctx) {
        devConsoleError('Method `getCroppedImage` cannot receive canvas context.');
        return;
    }
    ctx.drawImage(image, maxSize / 2 - image.width * 0.5, maxSize / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, maxSize, maxSize);
    canvas.width = croppedArea.width;
    canvas.height = croppedArea.height;
    ctx.putImageData(data, 0 - maxSize / 2 + image.width * 0.5 - croppedArea.x, 0 - maxSize / 2 + image.height * 0.5 - croppedArea.y);
    return new Promise((resolve) => {
        canvas.toBlob((Blob) => resolve(Blob || undefined), type);
    });
};
export const getHasDifferentSizes = (sizeVariant) => {
    return !!sizeVariant && sizeVariant.options.length > 1;
};
export const getInitialPricingType = ({ doProfitsVary, hasDifferentSizes, sameSizesHaveDifferentCosts, doCompareAtPricesVary, }) => {
    return (doProfitsVary && hasDifferentSizes && !sameSizesHaveDifferentCosts) ||
        doCompareAtPricesVary
        ? 'EXPLICIT'
        : 'IMPLICIT';
};
export const convertCompareAtPriceToMoneyValueOrNull = (compareAtPrice) => compareAtPrice?.value ? convertNumberToMoney(compareAtPrice.value) : null;
