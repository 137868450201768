import React, { useEffect } from 'react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { getCountryNameByCode } from '@fourthwall/utils/lib/inputs';
import * as S from './styled';
export const CountryFlag = ({ countryCode, height = 18, withLabel = true }) => {
    const Flag = countryCode
        ? () => React.createElement(React.Fragment, null, getUnicodeFlagIcon(countryCode))
        : () => React.createElement(S.UndefinedFlag, { style: { height } }, "?");
    const countryName = countryCode ? getCountryNameByCode(countryCode) : 'Unidentified';
    useEffect(() => {
        import('country-flag-emoji-polyfill').then((module) => {
            module.polyfillCountryFlagEmojis();
        });
    }, []);
    return (React.createElement(S.Container, null,
        React.createElement(S.Flag, { height: height },
            React.createElement(Flag, null)),
        withLabel && countryName && React.createElement(S.Label, null, countryName)));
};
