import React from 'react';
import { UploadedImage } from '../UploadedImage/UploadedImage';
import { PostGalleryImage } from './PostGalleryImage';
import { SortableGrid } from './SortableGrid';
import * as S from './styled';
export const PostGallery = ({ images, onImageRemove, onImageSort }) => {
    const handleImageRemove = (id) => {
        const index = images.findIndex((image) => image.id === parseInt(id));
        onImageRemove(index);
    };
    return (React.createElement(SortableGrid, { items: images.map((image) => image.id.toString()), 
        // eslint-disable-next-line react/no-unstable-nested-components
        draggedElement: (imageId) => {
            const activeImage = images.find((image) => image.id.toString() == imageId);
            if (!activeImage)
                return;
            return (React.createElement(S.ImageContainer, { count: images.length, style: { cursor: 'grabbing' } },
                React.createElement(UploadedImage, { image: activeImage.image, objectFit: "contain" })));
        }, onSort: onImageSort },
        React.createElement(S.ImagesContainer, { count: images.length }, images.map((image) => (React.createElement(PostGalleryImage, { key: image.id.toString(), id: image.id.toString(), image: image.image, count: images.length, onImageRemove: handleImageRemove }))))));
};
