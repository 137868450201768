import React from 'react';
import { InventoryShipment } from '@components/common/InventoryShipment/InventoryShipment';
import { Button } from '@fourthwall/components';
import { routing } from '@utils/routing';
import { useInventoryShipmentsQuery } from '@components/hooks/useInventoryShipmentsQuery';
import * as S from './styled';
export const InventoryShipmentSection = () => {
    const { inventoryShipmentsQuery } = useInventoryShipmentsQuery();
    if (!inventoryShipmentsQuery.data || inventoryShipmentsQuery.data.length === 0) {
        return (React.createElement(S.EmptyState, null,
            React.createElement("p", null, "You don\u2019t have any inventory shipments yet."),
            React.createElement(Button, { to: routing.settings.inventoryShipments.create, label: "Send inventory to our fulfillment center", appearance: "secondary", fullWidth: false })));
    }
    return (React.createElement(InventoryShipment, { data: inventoryShipmentsQuery.data || [], isLoading: inventoryShipmentsQuery.isLoading }));
};
