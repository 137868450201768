import { BackButton, HeadContent, LeavingGuard, Loading, SaveShortcutListener, Toasts, } from '@fourthwall/components';
import { formatCurrency } from '@fourthwall/utils/lib/currency';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import map from 'lodash-es/map';
import set from 'lodash-es/set';
import unset from 'lodash-es/unset';
import { reverse } from 'named-urls';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Dashboard, PageLayout } from '@components/common';
import { routing } from '@utils/routing';
import { ConfirmBarContainer } from '../../ConfirmBar';
import { useCurrentShop } from '../../hooks/useCurrentShop';
import { AddButtons } from './components/AddButtons';
import { ProductVariantType } from './components/ProductVariantType';
import { validationSchema } from './consts';
export const ProductVariantsView = ({ name = '', productId, productVariantTypes, productIsFetching, updateVariantTypesRequest, }) => {
    const location = useLocation();
    const { currentShop } = useCurrentShop();
    const validate = (values) => {
        // NOTE: https://github.com/jaredpalmer/formik/issues/506
        try {
            validateYupSchema(values, validationSchema, true, { values });
        }
        catch (err) {
            return yupToFormErrors(err);
        }
        return {};
    };
    const getInitialValues = () => ({
        types: cloneDeep(productVariantTypes).map((variantType) => set(variantType, 'options', variantType.options.map((variant) => Object.assign(variant, {
            price: variant.price?.value
                ? { value: formatCurrency(variant.price.value), currency: variant.price.currency }
                : {},
            weight: variant.weight || {},
        })))),
    });
    const onlyVaryingBy = (variantType, variantTypes, fieldName) => {
        if (!variantType.variesBy[fieldName])
            return false;
        return !variantTypes.some((vt) => vt !== variantType && vt.variesBy[fieldName]);
    };
    const getPayload = (values) => {
        const payload = cloneDeep(values);
        payload.types.forEach((variantType) => {
            if (!onlyVaryingBy(variantType, payload.types, 'weight')) {
                variantType.options.forEach((variant) => unset(variant, 'weight'));
            }
            variantType.options.forEach((option) => {
                set(option, 'name', option.name.trim());
                set(option, 'price', null);
            });
            set(variantType, 'title', variantType.title.trim());
        });
        return payload;
    };
    const handleFormConfirm = (submitForm, isValid) => {
        submitForm();
        if (!isValid) {
            Toasts.notify('There are errors that need to be fixed before saving.', { type: 'error' });
        }
    };
    const handleFormSubmit = (values) => {
        updateVariantTypesRequest({ id: productId, data: getPayload(values) });
    };
    const initialValues = getInitialValues();
    const renderVariantType = (variantType, index, allValues, errors, setValues) => (React.createElement(ProductVariantType, { key: variantType.type, index: index, variantType: variantType, shopWeightUnit: currentShop?.settings.weightUnit, allValues: allValues, errors: errors, setValues: setValues }));
    return (React.createElement(Loading, { isLoading: productIsFetching },
        React.createElement(HeadContent, { title: `Variants \u2013 ${name}` }),
        React.createElement(PageLayout, { size: "large" },
            React.createElement(Dashboard.Section, { name: "Edit, add, or remove variants", topContent: React.createElement(BackButton, { label: name, to: {
                        pathname: reverse(routing.products.all.product.self, { productId }),
                        search: location.search,
                    } }) },
                React.createElement(Formik, { initialValues: initialValues, onSubmit: handleFormSubmit, validate: validate, enableReinitialize: true }, ({ values, errors, submitForm, setValues, resetForm, isValid }) => {
                    const isFormDirty = !isEqual(initialValues, values);
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", null, map(values.types, (variantType, index) => {
                            return renderVariantType(variantType, index, values.types, errors, setValues);
                        })),
                        React.createElement(AddButtons, { allValues: values.types, setValues: setValues }),
                        React.createElement(SaveShortcutListener, { disabled: !isFormDirty, onSave: submitForm }),
                        React.createElement(ConfirmBarContainer, { isOpen: isFormDirty, isLoading: productIsFetching, onConfirm: () => handleFormConfirm(submitForm, isValid), onCancel: () => resetForm({ values: initialValues }) }),
                        React.createElement(LeavingGuard, { when: isFormDirty })));
                })))));
};
