import React from 'react';
import { Link } from 'react-router-dom';
import { ActivityIndicator } from '../ActivityIndicator';
import { Icon } from '../Icon';
import { Loading } from '../Loading';
import { Tooltip } from '../Tooltip';
import * as S from './styled';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
const getComponent = ({ to, href }) => {
    if (to)
        return Link;
    if (href)
        return 'a';
    return 'button';
};
export const ToolbarButton = ({ label, to, href, target, placement, size = 'medium', icon, iconSize = 16, color, active = false, activityIndicator, activityIndicatorCount, disabled = false, isLoading = false, onClick, }) => {
    const media = useMediaQueryContext();
    const handleClick = (event) => {
        // This prevents Table row to be clicked when Dropdown component is triggered using ToolbarButton.
        if (!to) {
            event.preventDefault();
        }
        if (!onClick)
            return;
        onClick();
    };
    return (React.createElement(Tooltip, { placement: placement, reference: React.createElement(Loading, { isLoading: isLoading, size: "small" },
            React.createElement(S.Container, { as: getComponent({ to, href }), href: href, to: to, target: target, size: size, active: active, color: color, type: href ? undefined : 'button', disabled: disabled, onClick: handleClick, "data-testid": "ToolbarButton.Button" },
                React.createElement(Icon, { component: icon, height: iconSize }),
                activityIndicator && !active && (React.createElement(S.ActivityIndicatorWrapper, null,
                    React.createElement(ActivityIndicator, null, activityIndicatorCount))))) }, !media.laptop || active || disabled ? null : label));
};
