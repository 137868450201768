import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const DetailsContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const AvatarContainer = styled.div `
  flex-shrink: 0;
`;
export const Info = styled.div `
  font-size: 12px;
  color: ${Colors.GS600};
  line-height: 1.3;
  letter-spacing: 0.02em;
`;
