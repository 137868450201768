import { Toasts } from '@fourthwall/components';
import { getUpdateSingleSeriesPayload } from '../utils';
import { useSingleSeries } from './useSingleSeries';
import { useUpdateSingleSeriesMutation } from './useUpdateSingleSeriesMutation';
export const useSingleSeriesUpdate = (singleSeriesId) => {
    const { updateSingleSeriesMutation } = useUpdateSingleSeriesMutation();
    const { singleSeriesQuery } = useSingleSeries(Number(singleSeriesId));
    const updateSingleSeries = (values) => {
        updateSingleSeriesMutation.mutate({
            id: Number(singleSeriesId),
            ...getUpdateSingleSeriesPayload(values),
        }, {
            onSuccess: () => {
                singleSeriesQuery.refetch();
                Toasts.notify('Video series updated.', { type: 'info' });
            },
        });
    };
    return {
        updateSingleSeries,
        isLoading: updateSingleSeriesMutation.isLoading,
    };
};
