import { Table } from '@fourthwall/components';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
export const SelectableTable = ({ tableEmptyStateMsg = 'You haven’t created any items yet.', items, isFetching, headers, onLoadMoreItems, hasNextPage, }) => {
    if (!items && !isFetching) {
        return tableEmptyStateMsg;
    }
    return (React.createElement(InfiniteScroll, { dataLength: items?.length || 0, next: onLoadMoreItems, hasMore: hasNextPage, loader: null, scrollableTarget: "scrollableDiv", scrollThreshold: 1, style: {
            overflow: 'none',
        } },
        React.createElement("div", null,
            React.createElement(Table, { data: items || [], headers: headers, isLoading: isFetching, minWidth: 0 }))));
};
