import React, { createContext, useContext, useMemo, useRef, useState } from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
const DropdownContext = createContext(undefined);
export const DropdownProvider = ({ children }) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen((isOpen) => !isOpen);
    };
    const open = () => {
        setIsOpen(true);
    };
    const close = () => {
        setIsOpen(false);
    };
    useOutsideClick(ref, () => {
        close();
    });
    const value = useMemo(() => ({ ref, isOpen, toggle, open, close }), [ref, isOpen]);
    return React.createElement(DropdownContext.Provider, { value: value }, children);
};
export const useDropdown = () => {
    const context = useContext(DropdownContext);
    if (context === undefined) {
        throw new Error('useDropdownContext must be used within a DropdownProvider');
    }
    return context;
};
