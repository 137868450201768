import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  gap: 16px;
`;
export const ThumbnailContainer = styled.div `
  position: relative;
  width: 120px;
  height: 120px;
  flex-shrink: 0;
`;
export const PlayerContainer = styled.div `
  position: relative;
  height: 120px;
  width: 100%;
`;
export const OverlayContainer = styled.div `
  user-select: none;
  position: relative;
  height: 100%;
`;
