import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
`;
export const Title = styled.h1 `
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  margin: 25px 0 8px;
  text-align: center;
`;
export const Subtitle = styled.p `
  max-width: 454px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${Colors.GS600};

  a {
    text-decoration: underline;
  }

  a:hover {
    color: ${Colors.GS1000};
  }
`;
