import { Dropdown, Icon } from '@fourthwall/components';
import { ArrowShortDownIcon, ArrowShortUpIcon } from '@fourthwall/icons';
import React, { useState } from 'react';
import * as S from './styled';
export const MediaSourceButton = ({ options, icon, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleItemClick = (id) => {
        onChange?.(id);
        setIsOpen(false);
    };
    return (React.createElement(Dropdown, { maxWidth: 480, isOpen: isOpen, content: options.map((item) => (React.createElement(Dropdown.Item, { key: item.id, label: item.label, active: !!item.active, onClick: () => handleItemClick(item.id) }))), onClose: () => setIsOpen(false) },
        React.createElement(S.Container, { isOpen: isOpen, onClick: () => setIsOpen(true) },
            icon,
            " ",
            React.createElement(Icon, { component: isOpen ? ArrowShortUpIcon : ArrowShortDownIcon, height: 8 }))));
};
