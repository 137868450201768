import { useMutation } from '@tanstack/react-query';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
export const useDeleteCreatorIntegrationMutation = () => {
    const deleteCreatorIntegrationMutation = useMutation({
        mutationFn: async (app) => {
            const { data } = await CreatorIntegrationsApiClient.deleteCreatorIntegration({ app });
            return data;
        },
    });
    return { deleteCreatorIntegrationMutation };
};
