import { ApiBase } from '../ApiBase';
class DashboardV2ApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getDashboardStatistics = (params) => {
            return this.get('/v2/shops/current/dashboard/statistics', params);
        };
    }
}
export const DashboardV2ApiClient = new DashboardV2ApiClientModel();
