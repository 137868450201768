import React from 'react';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { Tag } from '@fourthwall/components';
import * as S from './styled';
export const ProductsStatusModalAccordionHeading = ({ imageSrc, name, tag, description, url, onClick, }) => (React.createElement(S.Container, { onClick: onClick },
    imageSrc && (React.createElement(S.ImageContainer, null,
        React.createElement(PhotoThumbnail, { url: imageSrc, size: "tiny" }))),
    React.createElement(S.NameContainer, null,
        React.createElement(S.Name, { href: url, target: "_blank", rel: "noopener noreferrer" }, name)),
    tag && (React.createElement("div", null,
        React.createElement(Tag, { ...tag }))),
    description && React.createElement(S.Description, null, description)));
