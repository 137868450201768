import { Banner, ButtonClean, DateString, Dropdown, HeadContent, Loading, PriceString, ProfitString, Subsection, Summary, Timeline, ToolbarButton, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import { getCountryNameByCode } from '@fourthwall/utils/lib/inputs';
import find from 'lodash-es/find';
import isEmpty from 'lodash-es/isEmpty';
import map from 'lodash-es/map';
import { reverse } from 'named-urls';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { ConcealedText } from '../../../../../components/common/ConcealedText';
import { ContributionSideSummaryHeader } from '../../../../../components/common/ContributionSideSummaryHeader';
import { ContributionSummary } from '../../../../../components/common/ContributionSummary';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { OrderTimelineEvent } from '../../../../../components/common/OrderTimelineEvent';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { ProductsList, ProductsListSize } from '../../../../../components/common/ProductsList';
import { SideSummary } from '../../../../../components/common/SideSummary';
import { StatusTag } from '../../../../../components/common/StatusTag';
import { UnavailableResource } from '../../../../../components/common/UnavailableResource';
import { DashboardBackButton } from '../../../../../components/utils/DashboardBackButton';
import { useDashboardPermissions } from '../../../../../components/utils/DashboardPermissions';
import { getNumberOfProducts } from '../../../../../utils/products';
import { routing } from '../../../../../utils/routing';
import { getAttributesText } from '../../../../../utils/variants';
import { SummaryRowLabel } from '../../components/SummaryRowLabel';
import { ErrorBadge } from './components/ErrorBadge';
import { useOrder } from './hooks/useOrder';
import { useOrderCancellationQuery } from './hooks/useOrderCancellationQuery';
import { useOrderRefundQuery } from './hooks/useOrderRefundQuery';
import { OrderCostsBreakdownModal } from './OrderCostsBreakdownModal';
import { OrderEmailContainer } from './OrderEmail';
import { OrderShippingAddress } from './OrderShippingAddress';
import * as S from './OrderView.styled';
export const OrderView = ({ 
// banners,
orderId, order, status, orderType, fullName, hasNoProfit, replacement, grandTotalPrice, shippingAddress, email, friendlyId, message, donation, discount, usedCreatorBudget, timelineEvents, timelineFulfillments, shippingMethod, date, isLoading, isUnavailable, timelineIsFetching, }) => {
    const { order: orderHook } = useOrder(order);
    const location = useLocation();
    const { open } = useModalContext();
    const media = useMediaQueryContext();
    const navigate = useNavigate();
    const { hasPermission } = useDashboardPermissions();
    const [isAddressModalOpen, setIsAddressModalOpen] = useQueryParam('editAddress', StringParam);
    const [isEmailModalOpen, setIsEmailModalOpen] = useQueryParam('editEmail', StringParam);
    const { orderRefundQuery } = useOrderRefundQuery([orderId]);
    const { orderCancellationQuery } = useOrderCancellationQuery([orderId]);
    const handleEditOrderClick = () => {
        navigate(reverse(routing.contributions.orders.order.edit, { orderId }));
    };
    const handleCancelOrderClick = () => {
        navigate(reverse(routing.contributions.orders.order.cancel, { orderId }));
    };
    const handleRefundOrderClick = () => {
        navigate(reverse(routing.contributions.orders.order.refund, { orderId }));
    };
    const handleShowBreakdownClick = () => {
        open(OrderCostsBreakdownModal, { orderId, order });
    };
    const getToolbarContent = () => {
        const fullyCancellable = orderCancellationQuery.data?.fullyCancellable;
        const partiallyCancellable = orderCancellationQuery.data?.partiallyCancellable;
        const refundable = orderRefundQuery.data?.remainingRefundAmount &&
            orderRefundQuery.data.remainingRefundAmount.value > 0;
        const showEditOrderButton = (fullyCancellable || partiallyCancellable) && hasPermission('contributions.orders.edit');
        const showCancelOrderButton = (fullyCancellable || partiallyCancellable) && hasPermission('contributions.orders.cancel');
        const showRefundOrderButton = refundable && hasPermission('contributions.orders.refund');
        if (showEditOrderButton || showCancelOrderButton || showRefundOrderButton) {
            return (React.createElement(Dropdown, { content: React.createElement(React.Fragment, null,
                    showEditOrderButton && (React.createElement(Dropdown.Item, { label: "Edit order", onClick: handleEditOrderClick })),
                    showCancelOrderButton && (React.createElement(Dropdown.Item, { label: "Cancel items", onClick: handleCancelOrderClick })),
                    showRefundOrderButton && (React.createElement(Dropdown.Item, { label: "Refund order", onClick: handleRefundOrderClick }))), position: "bottom-end" },
                React.createElement(ToolbarButton, { label: "Settings", icon: DotsHorizontalIcon, size: media.tablet ? 'medium' : 'small' })));
        }
        return null;
    };
    const getPromotionLabel = () => {
        if (!order.promotion)
            return null;
        return order.promotion.isAutoApplied ? order.promotion.title : order.promotion.code;
    };
    const getDiscountLabel = () => {
        const label = order.type === 'GIFT' ? 'Gift discount' : 'Discount';
        const promotionLabel = getPromotionLabel();
        return (React.createElement(React.Fragment, null,
            label,
            ' ',
            promotionLabel && (React.createElement(React.Fragment, null,
                "(",
                React.createElement(ButtonClean, { label: promotionLabel, to: reverse(routing.promotions.promotionCodes.promotionCode.self, {
                        promotionId: order.promotion.id,
                    }) }),
                ")"))));
    };
    const getTimelineEvents = () => {
        const events = timelineEvents?.map((timelineEvent) => ({
            ...timelineEvent,
            description: React.createElement(OrderTimelineEvent, { timelineEvent: timelineEvent }),
        })) || [];
        const fulfillments = timelineFulfillments
            ?.filter((timelineFulfillment) => timelineFulfillment.shipments[0]?.deliveryEstimate)
            .map((timelineFulfillment) => ({
            id: timelineFulfillment.fulfillmentId,
            description: React.createElement(OrderTimelineEvent, { timelineFulfillment: timelineFulfillment }),
        })) || [];
        return [...fulfillments, ...events];
    };
    const renderReplacementNotification = () => {
        if (replacement?.type === 'Replacement') {
            return (React.createElement(S.BannerWrapper, null,
                React.createElement(Banner, { appearance: "warning" },
                    "This is a replacement order.",
                    ' ',
                    React.createElement("a", { href: reverse(routing.contributions.orders.order.self, {
                            orderId: replacement?.sourceOrderId,
                        }), target: "_blank", rel: "noreferrer" }, "View original order"))));
        }
        return null;
    };
    const renderReplacementSourceNotification = () => {
        if (replacement?.type === 'Source') {
            const hasManyReplacements = replacement?.replacementOrderIds?.length > 1;
            const title = hasManyReplacements
                ? 'This order was issued replacements.'
                : 'This order was issued a replacement.';
            return (React.createElement(S.BannerWrapper, null,
                React.createElement(Banner, { appearance: "warning" },
                    title,
                    ' ',
                    replacement?.replacementOrderIds?.map((replacementOrderId, index) => (React.createElement(React.Fragment, null,
                        React.createElement("a", { href: reverse(routing.contributions.orders.order.self, {
                                orderId: replacementOrderId,
                            }), target: "_blank", rel: "noreferrer" }, hasManyReplacements
                            ? `Replacement order ${index + 1}`
                            : 'View replacement order'),
                        ' '))))));
        }
        return null;
    };
    const renderProfitNotification = () => {
        if (hasNoProfit) {
            return (React.createElement(S.BannerWrapper, null,
                React.createElement(Banner, { appearance: "danger" },
                    "Part of data relating to profit is not loading.",
                    ' ',
                    React.createElement("a", { href: "mailto:hello@fourthwall.com" }, "Contact our support"))));
        }
        return null;
    };
    const renderOffer = ({ variant, offer, quantity, price }) => (React.createElement(ProductsList.Item, { key: variant?.id, image: variant?.images[0]?.url, offerId: offer?.id, name: offer?.name, variants: getAttributesText(variant.attributes, offer.variantTypes), quantity: quantity, price: React.createElement(PriceString, { value: price.value }) }));
    const renderAddress = (address) => {
        if (address) {
            const { address1, address2, city, state, zip, country, phone } = address;
            return (React.createElement(React.Fragment, null,
                React.createElement("p", null, fullName),
                phone && React.createElement("p", null, phone),
                React.createElement("p", null, address1),
                React.createElement("p", null, address2),
                React.createElement("p", null, `${city} ${state}${zip ? ` ${zip}` : ''}`),
                React.createElement("p", null, getCountryNameByCode(country))));
        }
        return null;
    };
    const renderAdditionalField = (name, label) => {
        const additionalField = find(order?.additionalFields, { name });
        if (additionalField) {
            return React.createElement(SideSummary.Item, { title: label || name }, additionalField.value);
        }
        return null;
    };
    const renderDonation = () => {
        if (donation) {
            return (React.createElement(ContributionSummary, { title: "Donation" },
                React.createElement(ContributionSummary.Section, null,
                    React.createElement(ProductsList, { size: ProductsListSize.Large },
                        React.createElement(ProductsList.Item, { name: "Donation", price: React.createElement(PriceString, { ...donation }), donation: true })))));
        }
        return null;
    };
    const renderProfitSummaryRow = () => {
        if (!hasPermission('contributions.profit')) {
            return null;
        }
        const value = hasNoProfit ? (React.createElement(ErrorBadge, null)) : (React.createElement(React.Fragment, null,
            React.createElement("span", { style: { marginRight: 8, fontSize: 12, fontWeight: 400 } },
                React.createElement(ButtonClean, { label: "Show breakdown", appearance: "secondary", onClick: handleShowBreakdownClick })),
            React.createElement(ProfitString, { value: order?.profit.value })));
        return React.createElement(Summary.Row, { label: "Your profit", value: value, bold: true });
    };
    const renderLeftContent = () => (React.createElement(React.Fragment, null,
        renderReplacementNotification(),
        renderReplacementSourceNotification(),
        renderProfitNotification(),
        orderHook.banners &&
            orderHook.banners.map((banner, index) => (React.createElement(S.BannerWrapper, null,
                React.createElement(Banner, { key: index, ...banner })))),
        React.createElement(Subsection, { title: "Order summary", borderless: true },
            order?.offers?.length > 0 && (React.createElement(ContributionSummary, { status: React.createElement(StatusTag, { status: status }) },
                React.createElement(ContributionSummary.Section, null,
                    React.createElement(ProductsList, { size: ProductsListSize.Large }, map(order?.offers, renderOffer))),
                React.createElement(ContributionSummary.Section, null,
                    React.createElement(Summary, null,
                        React.createElement(Summary.Row, { label: `Subtotal (${getNumberOfProducts(order?.offers)} items)`, value: order?.currentAmounts.offers }),
                        shippingMethod && (React.createElement(Summary.Row, { label: React.createElement(SummaryRowLabel, { title: shippingMethod, tooltip: orderHook.shippingMethodTooltip }), value: order?.currentAmounts.shipping })),
                        React.createElement(Summary.Row, { label: "Taxes", value: order?.currentAmounts.tax }),
                        order?.paidPaymentFee && (React.createElement(Summary.Row, { label: "Payment processing fees", value: order?.paidPaymentFee })),
                        !!discount && (React.createElement(Summary.Row, { label: getDiscountLabel(), value: {
                                // eslint-disable-next-line no-unsafe-optional-chaining
                                value: order?.currentAmounts.discount.value * -1,
                                currency: order?.currentAmounts.discount.currency,
                            } })),
                        !!usedCreatorBudget && (React.createElement(Summary.Row, { label: "Samples budget", value: {
                                value: usedCreatorBudget.value * -1,
                                currency: usedCreatorBudget.currency,
                            } })),
                        React.createElement(Summary.Row, { label: "Total", value: order?.currentAmounts.total }))))),
            order?.offersCancelled?.length > 0 && (React.createElement(ContributionSummary, { title: "Canceled", status: React.createElement(StatusTag, { status: "CANCELLED" }) },
                React.createElement(ContributionSummary.Section, null,
                    React.createElement(ProductsList, { size: ProductsListSize.Large }, map(order?.offersCancelled, renderOffer))),
                React.createElement(ContributionSummary.Section, null,
                    React.createElement(Summary, null,
                        React.createElement(Summary.Row, { label: `Subtotal (${getNumberOfProducts(order?.offersCancelled)} items)`, value: order?.cancelledAmounts.offers }),
                        shippingMethod && (React.createElement(Summary.Row, { label: shippingMethod, value: order?.cancelledAmounts.shipping })),
                        React.createElement(Summary.Row, { label: "Taxes", value: order?.cancelledAmounts.tax }),
                        !!discount && (React.createElement(Summary.Row, { label: getDiscountLabel(), value: {
                                // eslint-disable-next-line no-unsafe-optional-chaining
                                value: order?.cancelledAmounts.discount.value * -1,
                                currency: order?.cancelledAmounts.discount.currency,
                            } })),
                        React.createElement(Summary.Row, { label: "Total", value: order?.cancelledAmounts.total }))))),
            renderDonation(),
            React.createElement("div", { style: { paddingLeft: 20, paddingRight: 20 } },
                React.createElement(Summary, null,
                    React.createElement(Summary.Row, { label: orderType === 'CREATOR_BUDGET' ? 'Net paid' : 'Net paid by supporter', value: grandTotalPrice, bold: true }),
                    !!orderRefundQuery.data?.totalRefundedAmount.value &&
                        orderRefundQuery.data.totalRefundedAmount.value > 0 && (React.createElement(Summary.Row, { label: "Refund total", value: {
                            value: orderRefundQuery.data.totalRefundedAmount.value * -1,
                            currency: orderRefundQuery.data.totalRefundedAmount.currency,
                        }, bold: true })),
                    renderProfitSummaryRow()))),
        message && React.createElement(Subsection, { title: "Message left" }, message),
        React.createElement(Subsection, { title: "Timeline", borderless: true },
            React.createElement(Loading, { isLoading: timelineIsFetching }, isEmpty(getTimelineEvents()) ? ('There are no events yet to display for this order.') : (React.createElement(Timeline, { events: getTimelineEvents(), size: "large" }))))));
    const renderName = () => {
        if (!shippingAddress) {
            return null;
        }
        return (React.createElement(SideSummary.Item, null,
            React.createElement(ContributionSideSummaryHeader, { name: fullName })));
    };
    const renderShippingAddress = () => {
        if (!shippingAddress) {
            return null;
        }
        return (React.createElement(SideSummary.Item, { title: "Shipping address" },
            React.createElement(ConcealedText, { showLabel: "View shipping address", hideLabel: "Hide shipping address" }, renderAddress(shippingAddress)),
            status !== 'COMPLETED' &&
                status !== 'CANCELLED' &&
                hasPermission('contributions.orders.edit') && (React.createElement(ButtonClean, { label: "Edit shipping address", onClick: () => setIsAddressModalOpen('true') }))));
    };
    const renderEmail = () => {
        if (order?.salesChannel?.type === 'TikTok') {
            return (React.createElement(SideSummary.Item, { title: "Email" },
                React.createElement(S.Email, null, "N/A (Purchased from TikTok)")));
        }
        return (React.createElement(SideSummary.Item, { title: "Email" },
            React.createElement(S.Email, { title: email }, email),
            hasPermission('contributions.orders.edit') && (React.createElement(ButtonClean, { label: "Edit", onClick: () => setIsEmailModalOpen('true') }))));
    };
    const renderRightContent = () => {
        return (React.createElement(SideSummary, null,
            renderName(),
            renderShippingAddress(),
            renderEmail(),
            renderAdditionalField('employer'),
            renderAdditionalField('occupation'),
            renderAdditionalField('username'),
            renderAdditionalField('messageSelectedOption', 'Message choice')));
    };
    const renderEditAddressModal = () => {
        if (isAddressModalOpen) {
            return React.createElement(OrderShippingAddress, { close: () => setIsAddressModalOpen('') });
        }
        return null;
    };
    const renderEditEmailModal = () => {
        if (isEmailModalOpen) {
            return React.createElement(OrderEmailContainer, { onClose: () => setIsEmailModalOpen('') });
        }
        return null;
    };
    const getSectionName = () => {
        const orderCopy = orderType === 'CREATOR_BUDGET' ? 'samples order' : 'order';
        const suffix = fullName?.slice(-1).toLowerCase() === 's' ? `' ${orderCopy}` : `'s ${orderCopy}`;
        if (!fullName && !friendlyId) {
            return orderCopy;
        }
        if (!fullName) {
            return `${orderCopy} (#${friendlyId})`;
        }
        if (!friendlyId) {
            return `${fullName}${suffix}`;
        }
        return `${fullName}${suffix} (#${friendlyId})`;
    };
    if (isUnavailable) {
        return React.createElement(UnavailableResource, { resource: "order" });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: getSectionName() }),
        renderEditAddressModal(),
        renderEditEmailModal(),
        React.createElement(Loading, { isLoading: isLoading || orderRefundQuery.isLoading || orderCancellationQuery.isLoading },
            React.createElement(PageLayout, { leftContent: renderLeftContent(), rightContent: renderRightContent() },
                React.createElement("div", null,
                    React.createElement(Dashboard.ItemViewHeader, { name: getSectionName(), title: React.createElement(DateString, { date: date, format: "[Placed] MMM D, YYYY [at] h:mm A" }), backButton: React.createElement(DashboardBackButton, { label: "Orders", to: {
                                pathname: routing.contributions.orders.all,
                                search: location.search,
                            } }), toolbar: getToolbarContent() }))))));
};
