import React from 'react';
import { AspectBox, Icon } from '@fourthwall/components';
import { PlaceholderPhotoIcon } from '@fourthwall/icons';
import * as S from './styled';
export const PhotoThumbnail = ({ url, size = 'medium' }) => {
    const renderPhoto = () => {
        if (!url) {
            return (React.createElement(S.Placeholder, null,
                React.createElement(Icon, { component: PlaceholderPhotoIcon, height: 32 })));
        }
        return React.createElement(S.Image, { src: url });
    };
    return (React.createElement(S.Container, { size: size },
        React.createElement(AspectBox, { ratio: "3:4" }, renderPhoto())));
};
