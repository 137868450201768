import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.a `
  min-height: 53px;
  padding: 14px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;

  position: relative;

  &:before {
    display: block;
    content: '';

    background: #ffffff;
    opacity: 0;

    position: absolute;
    inset: 0;

    will-change: opacity;
  }

  &:hover {
    &:before {
      opacity: 0.12;
    }
  }

  ${({ appearance }) => appearance === 'violet' &&
    css `
      background: rgba(255, 189, 20, 1);
      background: linear-gradient(
        90deg,
        rgba(119, 6, 34, 1) 0%,
        rgba(50, 2, 62, 1) 25%,
        rgba(38, 1, 96, 1) 50%,
        rgba(44, 0, 137, 1) 75%,
        rgba(119, 7, 179, 1) 100%
      );

      color: ${Colors.GS100};

      ${Description} {
        &:before {
          background: ${Colors.GS100};
        }
      }
    `};

  ${({ appearance }) => appearance === 'yellow' &&
    css `
      background: #f1d300;
      background: linear-gradient(94.04deg, #f1d300 31.57%, #ffb800 91.2%);

      color: ${Colors.GS1000};

      ${Description} {
        &:before {
          background: ${Colors.GS1000};
        }
      }
    `};

  ${({ appearance }) => appearance === 'twitch' &&
    css `
      background: #9c3aff;
      background: linear-gradient(94.04deg, #9c3aff 31.57%, #7e26ff 91.2%);

      color: ${Colors.GS100};

      ${Description} {
        &:before {
          background: ${Colors.GS100};
        }
      }
    `};

  ${mediaQueries.tablet} {
    padding: 10px 20px;
  }
`;
export const Main = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${mediaQueries.tablet} {
    flex-direction: row;
    align-items: center;
    gap: 28px;
  }
`;
export const Title = styled.div `
  font-weight: 600;
`;
export const Description = styled.div `
  opacity: 0.7;

  position: relative;

  &:before {
    display: none;
    content: '';

    width: 1px;
    height: 12px;

    opacity: 0.2;

    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${mediaQueries.tablet} {
    &:before {
      display: block;
    }
  }
`;
export const IconContainer = styled.div `
  margin-left: 10px;
`;
