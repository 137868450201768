import { Button, ButtonGroup, Checkbox, DatePicker, Fieldset, Popper, RadioButton, useMediaQueryContext, } from '@fourthwall/components';
import { ArrowShortDownIcon } from '@fourthwall/icons';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React, { useState } from 'react';
import { isTimeFrameFrom, isTimeFrameNone, isTimeFrameRange, isTimeFrameTo } from '@utils/timeframe';
import { StatusTag } from '../../common/StatusTag';
import { validationSchema } from './consts';
import * as S from './styled';
import { FormFields } from './types';
export const Status = ({ initialIsOpen = false, headerContent, status, timeFrame, isSoldOut, disabled = false, onSubmit, }) => {
    const media = useMediaQueryContext();
    const [isOpen, setOpen] = useState(initialIsOpen && !disabled);
    const getInitialValues = () => {
        const getStatusFormValue = () => {
            if (timeFrame && !isTimeFrameNone(timeFrame)) {
                return 'SCHEDULE';
            }
            return status;
        };
        let startDate;
        let endDate;
        let setEndDate = false;
        if (timeFrame) {
            startDate =
                isTimeFrameFrom(timeFrame) || isTimeFrameRange(timeFrame) ? timeFrame.from : undefined;
            endDate = isTimeFrameTo(timeFrame) || isTimeFrameRange(timeFrame) ? timeFrame.to : undefined;
            setEndDate = !!endDate;
        }
        return {
            status: getStatusFormValue(),
            startDate,
            setEndDate,
            endDate,
        };
    };
    const formik = useFormik({
        initialValues: getInitialValues(),
        onSubmit: (values) => {
            onSubmit(values);
            setOpen(false);
        },
        validationSchema,
        enableReinitialize: true,
    });
    const toggleOpen = () => {
        if (disabled) {
            return;
        }
        setOpen((prevIsOpen) => !prevIsOpen);
    };
    const renderTimeFrameRangeInput = () => (React.createElement(React.Fragment, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(RadioButton, { value: formik.values.status, checkedValue: "SCHEDULE", label: "Schedule as public", suggestion: "Choose a date to make product go public.", name: "status", onChange: formik.handleChange })),
        formik.values.status === 'SCHEDULE' && (React.createElement(React.Fragment, null,
            React.createElement(Fieldset.Row, { inner: true },
                React.createElement(DatePicker, { type: "single", date: formik.values.startDate, startLabel: "Launch date", showTimeInputs: true, onDateChange: (newStartDate) => {
                        formik.setFieldValue(FormFields.startDate, newStartDate);
                    } })),
            React.createElement(Fieldset.Row, { inner: true },
                React.createElement(Checkbox, { label: "Set end date", value: formik.values.setEndDate, name: "setEndDate", onChange: formik.handleChange })),
            formik.values.setEndDate && (React.createElement(Fieldset.Row, { inner: true },
                React.createElement(DatePicker, { type: "single", date: formik.values.endDate, startLabel: "End date", showTimeInputs: true, onDateChange: (newEndDate) => {
                        formik.setFieldValue(FormFields.endDate, newEndDate);
                    } })))))));
    const renderForm = () => (React.createElement(S.FormContainer, null,
        headerContent,
        React.createElement("form", { onSubmit: formik.handleSubmit },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(RadioButton, { value: formik.values.status, checkedValue: "PUBLIC", label: "Public", suggestion: "Everyone has access.", name: "status", onChange: formik.handleChange })),
                React.createElement(Fieldset.Row, null,
                    React.createElement(RadioButton, { value: formik.values.status, checkedValue: "HIDDEN", label: "Hidden", suggestion: "Everyone with link has access.", name: "status", onChange: formik.handleChange })),
                timeFrame && renderTimeFrameRangeInput()),
            React.createElement(S.ButtonsContainer, null,
                React.createElement(ButtonGroup, { space: "medium", justify: media.tablet ? 'right' : undefined, direction: media.tablet ? 'row' : 'column-reverse', fullWidth: !media.tablet },
                    React.createElement(Button, { label: "Cancel", size: media.tablet ? 'small' : 'medium', fullWidth: !media.tablet, onClick: () => {
                            formik.resetForm();
                            setOpen(false);
                        } }),
                    React.createElement(Button, { label: "Save", appearance: "primary", size: media.tablet ? 'small' : 'medium', fullWidth: !media.tablet, disabled: !formik.dirty || !formik.isValid, onClick: formik.submitForm }))))));
    return (React.createElement(Popper, { isOpen: isOpen, referenceComponent: React.createElement("button", { type: "button", onClick: toggleOpen },
            React.createElement(StatusTag, { status: status, timeFrame: timeFrame, size: "large", icon: !disabled ? ArrowShortDownIcon : undefined, isSoldOut: isSoldOut })), popperProps: {
            placement: 'bottom-end',
            modifiers: [
                { name: 'preventOverflow', enabled: false },
                { name: 'hide', enabled: false },
            ],
        }, style: { paddingTop: media.tablet ? 8 : 0 }, onClose: () => setOpen(false) }, renderForm()));
};
