import { ShopRendererApiClient } from '@fourthwall/services/lib/ShopRendererApiClient';
import { useMutation } from '@tanstack/react-query';
export const useDeleteSocialLinkMutation = (options) => {
    const deleteSocialLinkMutation = useMutation({
        mutationFn: async (params) => {
            const { data } = await ShopRendererApiClient.deleteSocialNetwork(...params);
            return data;
        },
        ...options,
    });
    return {
        deleteSocialLinkMutation,
    };
};
