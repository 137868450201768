import React, { createContext, useContext, useMemo } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { dismiss, dismissAll, notify, toast, update } from './Toasts';
import * as S from './ToastsProvider.styled';
const toastContainerPositionMap = {
    bottom: 'bottom-center',
    top: 'top-center',
    'top-right': 'top-right',
    'top-left': 'top-left',
    'bottom-right': 'bottom-right',
    'bottom-left': 'bottom-left',
};
const ToastsContext = createContext({
    notify,
    update,
    dismiss,
    dismissAll,
    toast,
});
export const ToastsProvider = ({ position = 'bottom', autoClose = 5000, children, }) => {
    const value = useMemo(() => ({ notify, update, dismiss, dismissAll, toast }), []);
    return (React.createElement(ToastsContext.Provider, { value: value },
        React.createElement(S.GlobalStyles, null),
        children,
        React.createElement(ToastContainer, { autoClose: autoClose, hideProgressBar: true, closeButton: false, closeOnClick: false, draggable: false, icon: false, position: toastContainerPositionMap[position], 
            // NOTE: we want to remove the role attr here as we have it included
            // in Alert component
            role: "", transition: Slide })));
};
export const useToastsContext = () => useContext(ToastsContext);
