import React from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { Select, Fieldset } from '@fourthwall/components';
import { countries } from 'countries-list';
import { HsCodeSelectFormik } from '../HsCodeSelect/HsCodeSelect';
import * as S from './TaxFields.styled';
const countriesOptions = Object.entries(countries)
    .map(([countryKey, country]) => ({
    value: countryKey,
    label: country.name,
}))
    .sort((a, b) => a.label.localeCompare(b.label));
export const TaxFields = React.memo(() => {
    const { values, errors, submitCount } = useFormikContext();
    if (
    // manual HS Code entry is available for sell-something-i-have products
    values.fulfillmentService !== 'FULFILLED_BY_CREATOR' &&
        // and ship monk
        values.fulfillmentService !== 'SHIP_MONK' &&
        // and bespoke
        values.manufacturingService !== 'FOURTHWALL_BESPOKE') {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { component: HsCodeSelectFormik, name: "customsInformation.shortHsCode", label: "HS Code", error: submitCount > 0 && getIn(errors, 'customsInformation.shortHsCode') }),
                React.createElement(S.HelperText, null, "Required for calculating sales tax."))),
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { component: Select, name: "customsInformation.countryOfOrigin", label: "Country product was manufactured in", error: submitCount > 0 && getIn(errors, 'customsInformation.countryOfOrigin'), options: countriesOptions }),
                React.createElement(S.HelperText, null, "Required for calculating sales tax.")))));
});
