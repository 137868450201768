import { useEffect, useState } from 'react';
import { useOrderCancellationQuery } from './useOrderCancellationQuery';
const getCancellableOffers = (offers, cancellableItems) => cancellableItems
    ?.map((cancellableItem) => {
    const offer = offers?.find((offer) => offer.variantId === cancellableItem.variantId);
    return offer ? { ...offer, quantity: cancellableItem.quantity } : undefined;
})
    ?.filter(Boolean);
const getNonCancellableOffers = (offers, nonCancellableItems) => nonCancellableItems
    ?.map((nonCancellableItem) => {
    const offer = offers?.find((offer) => offer.variantId === nonCancellableItem.variantId);
    return offer
        ? {
            ...offer,
            quantity: nonCancellableItem.quantity,
            nonCancellableReason: nonCancellableItem.reason,
        }
        : undefined;
})
    ?.filter(Boolean);
export const useCancellationOffers = (orderId, offers) => {
    const { orderCancellationQuery, invalidateOrderCancellationQuery } = useOrderCancellationQuery([
        orderId,
    ]);
    const [cancellableOffers, setCancellableOffers] = useState();
    const nonCancellableOffers = getNonCancellableOffers(offers, orderCancellationQuery.data?.nonCancellableItems);
    useEffect(() => {
        setCancellableOffers(getCancellableOffers(offers, orderCancellationQuery.data?.cancellableItems));
    }, [offers, orderCancellationQuery.data?.cancellableItems]);
    return {
        cancellableOffers,
        nonCancellableOffers,
        partiallyCancellable: !!orderCancellationQuery.data?.partiallyCancellable,
        fullyCancellable: !!orderCancellationQuery.data?.fullyCancellable,
        orderCancellationQuery,
        invalidateOrderCancellationQuery,
    };
};
