import { useState } from 'react';
import { PAYPAL_CONTAINER_ID } from '../consts';
import { loadPayPalScript } from '../utils';
export const usePayPal = () => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [isRendered, setIsRendered] = useState(false);
    const [button, setButton] = useState();
    const [payPalActions, setPayPalActions] = useState();
    const initialize = async (apiKey, userTokenId) => {
        await loadPayPalScript(apiKey, userTokenId);
        setIsInitialized(true);
    };
    const renderButton = ({ vaultSetupToken, onApprove, onCancel, onClick, onError, }) => {
        if (!isInitialized) {
            return;
        }
        const buttonsInstance = window.paypal.Buttons({
            locale: 'en_US',
            style: {
                color: 'gold',
                size: 'medium',
                shape: 'sharp',
                label: 'paypal',
                height: 48,
            },
            onInit: (_, actions) => {
                setPayPalActions(actions);
                setIsRendered(true);
            },
            createVaultSetupToken: async () => vaultSetupToken,
            onClick,
            onApprove,
            onCancel,
            onError,
        });
        setButton(buttonsInstance);
        buttonsInstance.render(`#${PAYPAL_CONTAINER_ID}`);
    };
    const destroyButton = () => {
        button?.close();
        setButton(undefined);
        setPayPalActions(undefined);
        setIsRendered(false);
    };
    return {
        isInitialized,
        isRendered,
        actions: payPalActions,
        initialize,
        renderButton,
        destroyButton,
        enable: () => payPalActions?.enable(),
        disable: () => payPalActions?.disable(),
    };
};
