import React from 'react';
import { useCurrentShop } from '../../../hooks/useCurrentShop';
import { useDnsQuery } from '../../../hooks/useDnsQuery';
import { DNS_REFETCH_INTERVAL } from '../../constants';
import { useVerifyDomainMutation } from '../../hooks/useVerifyDomainMutation';
import { DomainView } from './DomainView';
import { useDeleteDomainMutation } from './hooks/useDeleteDomainMutation';
import { useEntri } from '../../hooks/useEntri';
export const DomainContainer = () => {
    const { currentShop } = useCurrentShop();
    const { deleteDomainMutation } = useDeleteDomainMutation();
    const { verifyDomainMutation } = useVerifyDomainMutation();
    const { dnsQuery, invalidateDnsQuery } = useDnsQuery([], {
        refetchInterval: DNS_REFETCH_INTERVAL,
    });
    const dnsList = dnsQuery.data?.dnsList;
    const { isEntriLoaded, isAutomaticSetupInProgress, isGettingProviderDetailsInProgress, connectDomain, } = useEntri({
        enabled: !!dnsList?.retryAllowed,
    });
    const handleDomainDeletion = () => {
        deleteDomainMutation.mutate(undefined, {
            onSuccess: () => {
                invalidateDnsQuery();
            },
        });
    };
    const getOnRetryConnection = () => {
        if (dnsList && dnsList.retryAllowed && isEntriLoaded) {
            return () => connectDomain(dnsList);
        }
    };
    return (React.createElement(DomainView, { dnsList: dnsList, shopInternalDomain: currentShop?.internalDomain, shopPrimaryDomain: currentShop?.primaryDomain, isLoading: dnsQuery.isLoading, isDomainRemovalRequested: deleteDomainMutation.isLoading, isVerificationInProgress: verifyDomainMutation.isLoading, isRetryConnectionInProgress: isGettingProviderDetailsInProgress || isAutomaticSetupInProgress, onDomainDelete: handleDomainDeletion, onRetryConnection: getOnRetryConnection() }));
};
