import { Toasts } from '@fourthwall/components';
import { useState } from 'react';
import { useCheckMediaCreatedMutation } from '../../../hooks/useCheckMediaCreatedMutation';
import { useImageConfirmMutation } from '../../../hooks/useImageConfirmMutation';
import { useUploadImageMutation } from '../../../hooks/useUploadImageMutation';
import { uploadMedia } from '../../../utils/uploadMedia';
import { useUpdateAudioPostMutation } from './useUpdateAudioPostMutation';
export const useUpdateAudioPost = () => {
    const [isLoading, setLoading] = useState(false);
    const [mediaUploadProgress, setMediaUploadProgress] = useState(0);
    const { uploadImageMutation } = useUploadImageMutation();
    const { imageConfirmMutation } = useImageConfirmMutation();
    const { updateAudioPostMutation } = useUpdateAudioPostMutation();
    const { checkMediaCreatedMutation } = useCheckMediaCreatedMutation();
    const updateAudioPost = async (id, file, thumbnail, payload, handleSuccess) => {
        setLoading(true);
        const onSuccess = () => {
            setMediaUploadProgress(0);
            setLoading(false);
            handleSuccess();
        };
        const { postId, audioDirectUploadData, imageDirectUploadData } = await updateAudioPostMutation.mutateAsync({
            id: Number(id),
            ...payload,
        }, {
            onSuccess: ({ audioDirectUploadData, imageDirectUploadData }) => {
                // Update text fields only
                if (!audioDirectUploadData && !imageDirectUploadData) {
                    onSuccess();
                }
            },
            onError: () => setLoading(false),
        });
        if (imageDirectUploadData) {
            await uploadImageMutation.mutateAsync({ file: thumbnail, ...imageDirectUploadData }, {
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: update audio post upload image error');
                },
            });
            await imageConfirmMutation.mutateAsync({ id: postId, signedId: imageDirectUploadData.signedId }, {
                onSuccess: () => {
                    // Update image only
                    if (!audioDirectUploadData) {
                        onSuccess();
                    }
                },
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: update audio post confirm image error');
                },
            });
        }
        // Update audio
        if (audioDirectUploadData) {
            await uploadMedia(audioDirectUploadData.uploadUrl, file, (progress) => {
                setMediaUploadProgress(Number(progress.toFixed()));
            }).catch(() => {
                Toasts.notify('Media upload error. Please try again.', { type: 'error' });
            });
            checkMediaCreatedMutation.mutate(postId, {
                onSuccess,
                onError: () => setLoading(false),
            });
        }
    };
    return {
        updateAudioPost,
        isLoading,
        mediaUploadProgress,
    };
};
