import styled from 'styled-components';
export const ShippingLocationHeader = styled.div `
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-weight: 600;
`;
export const ShippingLocationTooltip = styled.div `
  margin-left: 6px;
`;
export const ShippingLocationContent = styled.div `
  font-size: 16px;
  line-height: 24px;
`;
export const ShippingLocationEditButtonContainer = styled.div `
  margin-top: 8px;
`;
