import React from 'react';
import { useDashboardFeatureFlags } from '../../../../../../components/utils/DashboardFeatureFlags';
import { useThemeEditorContext } from '../../../../hooks/useThemeEditorContext';
import { useThemeLivePreviewContext } from '../../providers/ThemeLivePreviewProvider';
import { AddSectionToolbar } from '../AddSectionToolbar/AddSectionToolbar';
import { InspectorToolbar } from '../InspectorToolbar/InspectorToolbar';
import * as S from './styled';
export const InspectorFrame = () => {
    const { rect, activeSectionKey, isInspectorVisible } = useThemeLivePreviewContext();
    const { isPreviewLoading } = useThemeEditorContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    // Don't render the frame if there's no active section
    if (!activeSectionKey)
        return null;
    return (React.createElement(S.Layout, null,
        React.createElement(S.Frame, { style: {
                top: rect.top,
                left: rect.left,
                width: rect.width,
                height: rect.height,
                display: isPreviewLoading || !isInspectorVisible ? 'none' : 'block',
            } },
            dashboardFeatureFlags.themeEditorInspectorSections.enabled &&
                rect.width > 400 &&
                rect.height > 60 && React.createElement(AddSectionToolbar, { position: "top" }),
            React.createElement(InspectorToolbar, null),
            dashboardFeatureFlags.themeEditorInspectorSections.enabled && rect.height > 60 && (React.createElement(AddSectionToolbar, { position: "bottom" })))));
};
