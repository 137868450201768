import React from 'react';
import { Colors, Dropdown, ToolbarButton, useMediaQueryContext } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
export const TeamMemberDropdown = ({ data, onResendInvitation, onInvitationCancel, onUserRemove, onChangeRole, }) => {
    const media = useMediaQueryContext();
    if (!data.permissions || !Object.values(data.permissions).filter(Boolean).length) {
        return null;
    }
    return (React.createElement(Dropdown, { content: React.createElement(React.Fragment, null,
            data.permissions.resendInvitation && (React.createElement(Dropdown.Item, { label: "Resend invitation", onClick: () => onResendInvitation(data.invitationId) })),
            data.permissions.cancelInvitation && (React.createElement(Dropdown.Item, { label: "Cancel invitation", onClick: () => onInvitationCancel(data.email) })),
            data.permissions.changeRole && (React.createElement(Dropdown.Item, { label: "Change role", onClick: () => onChangeRole(data.role, data.email, data.name) })),
            data.permissions.remove && (React.createElement(Dropdown.Item, { label: data.isYou ? 'Leave' : 'Remove', onClick: () => onUserRemove(data.email, data.name) }))), position: "bottom-start" },
        React.createElement(ToolbarButton, { label: "Settings", icon: DotsHorizontalIcon, color: media.tablet ? undefined : Colors.GS600 })));
};
