import { Icon } from '@fourthwall/components';
import { CloseIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
export const Tag = ({ label, disabled, onRemove }) => {
    const renderRemoveButton = () => {
        if (!onRemove)
            return null;
        return (React.createElement(S.RemoveButton, { type: "button", onClick: () => onRemove(label), "data-testid": "Tag.RemoveButton", disabled: disabled },
            React.createElement(Icon, { component: CloseIcon, height: 7 })));
    };
    return (React.createElement(S.Container, null,
        renderRemoveButton(),
        label));
};
