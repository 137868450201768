import { Dashboard } from '@components/common/Dashboard';
import { TabPills, PageLayout } from '@components/common';
import { SECTION_NAMES } from '@constants/sectionNames';
import { BaseContent, HeadContent } from '@fourthwall/components';
import React from 'react';
import { ConnectionsGrid } from './components/ConnectionsGrid';
export const AppsView = ({ tabs, apps }) => (React.createElement(React.Fragment, null,
    React.createElement(HeadContent, { title: SECTION_NAMES.apps }),
    React.createElement(PageLayout, null,
        React.createElement(Dashboard.Section, { name: SECTION_NAMES.apps, title: React.createElement(BaseContent, { appearance: "secondary" }, "Elevate your Fourthwall experience with our apps and integrations.") },
            React.createElement("div", { style: { marginBottom: 24 } },
                React.createElement(TabPills, { ...tabs })),
            React.createElement(ConnectionsGrid, { items: apps })))));
