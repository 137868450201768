import { getWelcomePostPayload } from './getWelcomePostPayload';
export const getCreateWelcomePostsPayload = async (welcomePosts) => {
    const posts = [];
    for await (const [tierId, welcomePost] of Object.entries(welcomePosts)) {
        const post = await getWelcomePostPayload(welcomePost);
        posts.push({
            post,
            tier: {
                id: Number(tierId),
            },
        });
    }
    return {
        welcomePost: {
            type: 'Different',
            posts,
        },
    };
};
