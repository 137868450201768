import React from 'react';
import { FingerprintJSPro, FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
export const FingerprintProvider = ({ children }) => {
    return (React.createElement(FpjsProvider, { loadOptions: {
            apiKey: '0q30m3QCRlX2Wha42mth',
            endpoint: ['https://fib.fourthwall.com', FingerprintJSPro.defaultEndpoint],
            scriptUrlPattern: [
                'https://fib.fourthwall.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
                FingerprintJSPro.defaultScriptUrlPattern,
            ],
        } }, children));
};
