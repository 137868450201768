import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const FormContainer = styled.div `
  width: 375px;
  background: ${Colors.GS0};
  border: 1px solid ${Colors.GS400};
  padding: 20px;
`;
export const ButtonsContainer = styled.div `
  padding-top: 16px;
`;
