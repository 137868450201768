import { getBaseApiUrl } from '@fourthwall/env';
import axios from 'axios';
export class ApiBase {
    constructor(customUrl) {
        this.baseUrl = '';
        this.getUrl = (customUrl) => {
            if (customUrl) {
                return customUrl;
            }
            return this.baseUrl || getBaseApiUrl('api');
        };
        this.setMaxRequestsCount = (maxRequestsCount = 5) => {
            const MAX_REQUESTS_COUNT = maxRequestsCount;
            const INTERVAL_MS = 10;
            let PENDING_REQUESTS = 0;
            this.instance.interceptors.request.use((config) => {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                            PENDING_REQUESTS++;
                            clearInterval(interval);
                            resolve(config);
                        }
                    }, INTERVAL_MS);
                });
            });
            this.instance.interceptors.response.use((response) => {
                PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
                return Promise.resolve(response);
            }, (error) => {
                PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
                return Promise.reject(error);
            });
        };
        this.getCancelTokenSource = () => {
            return axios.CancelToken.source();
        };
        this.getUnsafe = (path, params, headers) => {
            return this.instance.get(path, { params, headers });
        };
        this.get = (path, params, headers, config) => {
            return this.instance.get(path, Object.assign({ params, headers }, config));
        };
        this.getBlob = (path, params, headers) => {
            return this.instance.get(path, { params, headers, responseType: 'blob' });
        };
        this.post = (path, payload, params, config) => {
            return this.instance.post(path, payload, Object.assign({ params }, config));
        };
        this.put = (path, payload, params, config) => {
            return this.instance.put(path, payload, Object.assign({ params }, config));
        };
        this.patch = (path, payload, params, config) => {
            return this.instance.patch(path, payload, Object.assign({ params }, config));
        };
        this.delete = (path, payload, params) => {
            return this.instance.delete(path, { data: payload, params });
        };
        this.getLink = (path) => {
            return `${this.baseUrl}${path}`;
        };
        this.setAuthorizationHeader = (token) => {
            this.setHeader('Authorization', `Bearer ${token}`);
        };
        this.removeAuthorizationHeader = () => {
            this.removeHeader('Authorization');
        };
        this.setHeader = (key, value) => {
            this.instance.defaults.headers.common[key] = value;
        };
        this.removeHeader = (key) => {
            delete this.instance.defaults.headers.common[key];
        };
        this.addErrorResponseInterceptor = (errorInterceptor) => {
            this.instance.interceptors.response.use((success) => success, errorInterceptor);
        };
        this.addRequestInterceptor = (interceptor) => {
            return this.instance.interceptors.request.use(interceptor);
        };
        this.removeRequestInterceptor = (interceptorId) => {
            this.instance.interceptors.request.eject(interceptorId);
        };
        this.baseUrl = this.getUrl(customUrl);
        this.instance = axios.create({
            baseURL: this.baseUrl,
            paramsSerializer: {
                serialize: (params) => {
                    const searchParams = new URLSearchParams();
                    for (const [key, value] of Object.entries(params)) {
                        if (params[key] !== undefined) {
                            if (Array.isArray(value)) {
                                value.forEach((val) => searchParams.append(key, val));
                            }
                            else {
                                searchParams.set(key, value);
                            }
                        }
                    }
                    return searchParams.toString();
                },
            },
        });
    }
}
