import isEqual from 'lodash-es/isEqual';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Toasts } from '@fourthwall/components';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { reverse, routing } from '@utils/routing';
import { useOffersByIdsQuery } from '@hooks/useOffersByIdsQuery';
import { isTimeFrameFrom, isTimeFrameRange, isTimeFrameTo } from '@utils/timeframe';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
import { CollectionView } from './CollectionView';
import { useAvailabilityMutation } from './hooks/useAvailabilityMutation';
import { useStatusMutation } from './hooks/useStatusMutation';
import { getCollectionTimeFrame, getCreateCollectionPayload, getUpdateCollectionDetailsPayload, sleep, } from './utils';
import { useCollection, useCollectionOffers } from './queries';
import { useCreateCollectionMutation, useUpdateCollectionSlugMutation, useUpdateCollectionMutation, } from './mutations';
const CollectionContainer = () => {
    const { hasPermission } = useDashboardPermissions();
    const navigate = useNavigate();
    const { collectionId = '' } = useParams();
    const { currentShop } = useCurrentShop();
    const isCreating = !collectionId;
    const { statusMutation } = useStatusMutation();
    const { availabilityMutation } = useAvailabilityMutation();
    const { collectionQuery, invalidateCollectionQuery } = useCollection([{ collectionId }], {
        enabled: !!collectionId,
        keepPreviousData: false,
    });
    const { collectionOffersQuery, invalidateCollectionOffersQuery } = useCollectionOffers([{ collectionId }], { enabled: !!collectionId });
    const [additionalOffers, setAdditionalOffers] = React.useState([]);
    const { offersByIdsQuery } = useOffersByIdsQuery([additionalOffers], {
        enabled: !!additionalOffers.length,
    });
    const { createCollectionMutation } = useCreateCollectionMutation();
    const { updateCollectionMutation } = useUpdateCollectionMutation();
    const { updateCollectionSlugMutation } = useUpdateCollectionSlugMutation();
    const invalidateQueries = async () => {
        await sleep(1_000); // this gives time for the backend to return updated statuses
        await invalidateCollectionQuery();
        await invalidateCollectionOffersQuery();
    };
    const handleSubmit = (values, withStatusUpdate) => {
        if (isCreating) {
            return createCollectionMutation.mutate([getCreateCollectionPayload(values)], {
                onSuccess: (data) => {
                    const to = reverse(routing.products.collections.collection.self, {
                        collectionId: data.id,
                    });
                    navigate(to);
                },
            });
        }
        updateCollectionMutation.mutate([{ collectionId }, getUpdateCollectionDetailsPayload(values, withStatusUpdate)], { onSuccess: invalidateQueries });
        if (values.isSoldOut !== !collectionQuery.data?.state.available) {
            availabilityMutation.mutate([{ collectionId }, { available: !values.isSoldOut, shouldUpdateOffersStatus: true }], { onSuccess: () => invalidateQueries });
        }
        const newTimeFrame = getCollectionTimeFrame(values.isTimeLimited, values.startDate, values.endDate);
        if (!isEqual(newTimeFrame, collectionQuery.data?.timeFrame)) {
            statusMutation.mutate({ collectionId, payload: { newTimeFrame, shouldUpdateOffersStatus: withStatusUpdate } }, { onSuccess: invalidateQueries });
        }
    };
    const handleStatusSubmit = ({ status, startDate, endDate, setEndDate }, withStatusUpdate) => {
        const getPayload = () => {
            if (status === 'SCHEDULE') {
                if (startDate && endDate && setEndDate) {
                    return {
                        newTimeFrame: { '@type': 'TIME_FRAME_RANGE', from: startDate, to: endDate },
                        shouldUpdateOffersStatus: false,
                    };
                }
                if (startDate && !setEndDate) {
                    return {
                        newTimeFrame: { '@type': 'TIME_FRAME_FROM', from: startDate },
                        shouldUpdateOffersStatus: withStatusUpdate,
                    };
                }
                if (!startDate && endDate && setEndDate) {
                    return {
                        newTimeFrame: { '@type': 'TIME_FRAME_TO', to: endDate },
                        shouldUpdateOffersStatus: withStatusUpdate,
                    };
                }
            }
            return {
                newState: status !== 'SCHEDULE' ? status : undefined,
                newTimeFrame: { '@type': 'TIME_FRAME_NONE' },
                shouldUpdateOffersStatus: withStatusUpdate,
            };
        };
        statusMutation.mutate({ collectionId, payload: getPayload() }, {
            onSuccess: invalidateQueries,
            onError: () => {
                Toasts.notify("Couldn't update the status.", { type: 'error' });
            },
        });
    };
    const handleCollectionDelete = () => {
        statusMutation.mutate({
            collectionId,
            payload: {
                newTimeFrame: { '@type': 'TIME_FRAME_NONE' },
                newState: 'ARCHIVED',
                shouldUpdateOffersStatus: false,
            },
        }, {
            onSuccess: (data) => {
                navigate(reverse(routing.products.collections.self));
                Toasts.notify(`Collection "${data.name}" successfully deleted.`, { type: 'info' });
            },
        });
    };
    const handleSlugChange = (newSlug) => {
        updateCollectionSlugMutation.mutate([{ collectionId }, { newSlug }], {
            onSuccess: invalidateCollectionQuery,
        });
    };
    const getInitialValues = () => {
        if (isCreating || !collectionQuery.isSuccess) {
            return {
                name: '',
                isTimeLimited: false,
                isSoldOut: false,
                selectedProducts: [],
                startDate: undefined,
                endDate: undefined,
            };
        }
        const { name, timeFrame: tf, state } = collectionQuery.data;
        const startDate = isTimeFrameFrom(tf) || isTimeFrameRange(tf) ? tf.from : undefined;
        const endDate = isTimeFrameTo(tf) || isTimeFrameRange(tf) ? tf.to : undefined;
        const isTimeLimited = !!startDate || !!endDate;
        return {
            name: unescape(name),
            isTimeLimited,
            isSoldOut: !state.available,
            selectedProducts: collectionQuery.data.offerIds,
            startDate,
            endDate,
        };
    };
    const offers = (() => {
        const formAdditionalOffers = offersByIdsQuery.data || {};
        const collectionOffers = collectionOffersQuery.data?.offers.reduce((previous, current) => ({ ...previous, [current.id]: current }), {});
        // keep collectionOffers second as it's more likely a valid and up to date response
        return { ...formAdditionalOffers, ...collectionOffers };
    })();
    const hasDropProducts = Object.values(offers).some((offer) => !!offer.drop);
    const setSelectedOfferIds = (ids) => {
        const nonCollectionOfferIds = ids.filter((id) => !collectionQuery.data?.offerIds.includes(id));
        setAdditionalOffers(nonCollectionOfferIds);
    };
    return (React.createElement(CollectionView, { initialValues: getInitialValues(), collection: collectionQuery.data, offers: offers, shopBaseUri: currentShop?.baseUri, isCreating: isCreating, isFetching: collectionQuery.isInitialLoading ||
            collectionOffersQuery.isInitialLoading ||
            offersByIdsQuery.isInitialLoading, isSubmitting: createCollectionMutation.isLoading ||
            updateCollectionMutation.isLoading ||
            updateCollectionSlugMutation.isLoading, isError: collectionQuery.isError || collectionOffersQuery.isError || offersByIdsQuery.isError, isEditable: hasPermission('collections.edit'), hasDropProducts: hasDropProducts, totalSold: collectionOffersQuery.isSuccess ? collectionOffersQuery.data.sold : undefined, setSelectedOfferIds: setSelectedOfferIds, onSubmit: handleSubmit, onStatusSubmit: handleStatusSubmit, onCollectionDelete: handleCollectionDelete, onSlugChange: handleSlugChange }));
};
export default CollectionContainer;
