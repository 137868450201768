import React, { Children, cloneElement, isValidElement } from 'react';
export const AspectBox = ({ ratio, children }) => {
    return (React.createElement(React.Fragment, null, Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, {
                ...child.props,
                style: {
                    ...child.props.style,
                    aspectRatio: ratio.split(':').join('/'),
                },
            });
        }
    })));
};
