/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowLongRightIcon, CalendarIcon, TimeIcon } from '@fourthwall/icons';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Icon } from '../../common/Icon';
import { Popper } from '../../common/Popper';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Calendar, createLabel } from '../../partials/Calendar';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
import { InputUI, InputUIConsts } from '../../ui';
import { Input } from '../Input';
import * as S from './DatePicker.styled';
import { getFormattedDate } from './utils';
export const DatePicker = ({ type = 'range', date = '', startDate = '', endDate = '', startLabel = 'Starts', endLabel = 'Ends', name, startTimeLabel, endTimeLabel, scrollableCalendar = false, suggestion, error, submitCount, disableDates, size, dateFormat, showTimeInputs = false, placement = 'bottom', strategy, disabled = false, onDateChange, onDatesChange, onClear, }) => {
    const media = useMediaQueryContext();
    const [isOpen, setIsOpen] = useState(false);
    const [focusedInput, setFocusedInput] = useState('startDate');
    const containerRef = useRef(null);
    useOutsideClick(containerRef, () => {
        setIsOpen(false);
    });
    const handleDateChange = (date) => {
        if (onDateChange) {
            onDateChange(date);
        }
        // Needed to use 0 timeout in order to properly run set time callback on closing picker on outside-input click.
        setTimeout(() => {
            setIsOpen(false);
        }, 0);
    };
    const renderLeftIcon = () => {
        if (showTimeInputs) {
            return TimeIcon;
        }
        return (React.createElement(S.CalendarIconContainer, null,
            React.createElement(Icon, { component: CalendarIcon, height: InputUIConsts.ICON_SIZE })));
    };
    const renderInputs = () => {
        if (type === 'range') {
            return (React.createElement(React.Fragment, null,
                React.createElement(Input, { name: name?.[0], label: createLabel(startLabel), value: getFormattedDate(moment(startDate), dateFormat, showTimeInputs), leftIcon: renderLeftIcon(), size: size, disabled: disabled, fullWidth: true, transparent: true, readOnly: true }),
                React.createElement(Icon, { component: ArrowLongRightIcon, height: 16 }),
                React.createElement(Input, { name: name?.[1], label: createLabel(endLabel), value: getFormattedDate(moment(endDate), dateFormat, showTimeInputs), leftIcon: renderLeftIcon(), size: size, disabled: disabled, fullWidth: true, transparent: true, readOnly: true })));
        }
        return (React.createElement(Input, { name: typeof name === 'string' ? name : undefined, label: createLabel(startLabel), value: getFormattedDate(moment(date), dateFormat, showTimeInputs), leftIcon: renderLeftIcon(), size: size, disabled: disabled, fullWidth: true, transparent: true }));
    };
    return (React.createElement(Popper, { isOpen: isOpen, referenceComponent: React.createElement("div", { "data-testid": "DatePicker.Container", onClick: !disabled ? () => setIsOpen((isOpen) => !isOpen) : undefined },
            React.createElement(InputUI.Box, { size: size, invalid: !!error, focused: isOpen, disabled: disabled, fullWidth: true },
                React.createElement(S.InputsContainer, { "data-testid": "DatePicker.Inputs" }, renderInputs())),
            !error && suggestion && React.createElement(InputUI.Suggestion, null, suggestion),
            !!error && React.createElement(InputUI.Error, { errorTimestampKey: submitCount }, error)), popperProps: {
            placement,
            strategy,
        }, style: media.tablet ? { paddingTop: 8, paddingBottom: 8 } : undefined, onClose: () => setIsOpen(false) },
        React.createElement(S.CalendarContainer, null,
            React.createElement(Calendar, { type: type, date: date, startDate: startDate, endDate: endDate, focusedInput: focusedInput, scrollableContent: scrollableCalendar, startTimeLabel: startTimeLabel, endTimeLabel: endTimeLabel, disableDates: disableDates, showTimeInputs: showTimeInputs, onDateChange: handleDateChange, onDatesChange: onDatesChange, onFocusChange: (newFocusedInput) => {
                    return setFocusedInput(newFocusedInput || 'startDate');
                }, onClear: onClear }))));
};
