import { useParams } from 'react-router-dom';
import { useGetMessageQuery } from './useGetMessageQuery';
export const useGetMessage = () => {
    const { messageId = '' } = useParams();
    const { messageData, useMessageQuery } = useGetMessageQuery(messageId);
    return {
        messageData,
        isLoading: useMessageQuery.isLoading,
    };
};
