import uniq from 'lodash-es/uniq';
import { reverse } from 'named-urls';
import { routing } from '../../../utils/routing';
export const getCollectionTimeFrame = (isTimeLimited, startDate, endDate) => {
    if (!isTimeLimited) {
        return { '@type': 'TIME_FRAME_NONE' };
    }
    if (startDate && !endDate) {
        return { '@type': 'TIME_FRAME_FROM', from: startDate };
    }
    if (!startDate && endDate) {
        return { '@type': 'TIME_FRAME_TO', to: endDate };
    }
    if (startDate && endDate) {
        return { '@type': 'TIME_FRAME_RANGE', from: startDate, to: endDate };
    }
    return { '@type': 'TIME_FRAME_NONE' };
};
export const getCreateCollectionPayload = (formValues) => {
    const { name, isSoldOut, isTimeLimited, selectedProducts, endDate, startDate } = formValues;
    return {
        name,
        description: '',
        available: !isSoldOut,
        offerIds: selectedProducts,
        timeFrame: getCollectionTimeFrame(isTimeLimited, startDate, endDate),
    };
};
export const getUpdateCollectionDetailsPayload = (formValues, shouldUpdateOffersStatus) => {
    const { name, selectedProducts } = formValues;
    return {
        newName: name,
        newOfferIds: selectedProducts,
        shouldUpdateOffersStatus,
    };
};
export const getShouldDisplayStatusConfirmation = (status, selectedOffers) => {
    // there are no offers selected
    if (selectedOffers.length === 0) {
        return false;
    }
    // some offers are not loaded
    if (selectedOffers.includes(undefined)) {
        return true;
    }
    const selectedOffersStatuses = uniq(selectedOffers.map((offer) => offer?.state.status));
    if (
    // all offer statuses are equal
    selectedOffersStatuses.length === 1 &&
        // new status is not schedule
        status !== 'SCHEDULE' &&
        // new status is equal to offer status
        status === selectedOffersStatuses[0]) {
        return false;
    }
    // display confirmation by default
    return true;
};
export const getProductLink = (productId) => {
    return reverse(routing.products.all.product.self, { productId });
};
export const sleep = async (ms) => new Promise((resolve) => {
    setTimeout(resolve, ms);
});
