import styled from 'styled-components';
import { Colors } from '../../../colors';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  width: 48px;

  color: ${Colors.GS600};
  cursor: pointer;

  &:hover {
    color: ${Colors.GS1000};
  }
`;
