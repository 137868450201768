import React from 'react';
import * as S from './styled';
export const Layout = ({ appearance = 'DARK', content, contentHeader, contentFooter, contentFooterTop, contentFooterLeft, contentFooterRight, sidebar, }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.MainContent, { appearance: appearance },
            React.createElement("div", null,
                React.createElement(S.MainHeader, null, contentHeader),
                React.createElement(S.Content, null, content),
                React.createElement(S.MainFooter, null,
                    React.createElement(S.MainFooterLeft, null, contentFooterLeft),
                    React.createElement(S.MainFooterTop, null, contentFooterTop),
                    React.createElement(S.MainFooterContent, null, contentFooter),
                    React.createElement(S.MainFooterRight, null, contentFooterRight)))),
        sidebar && React.createElement(S.SidebarContent, null, sidebar)));
};
