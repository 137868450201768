import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { useMutation } from '@tanstack/react-query';
export const useDeleteStreamelementsMutation = () => {
    const deleteStreamelementsMutation = useMutation({
        mutationFn: async () => {
            const { data } = await CreatorIntegrationsApiClient.deleteCreatorIntegration({
                app: 'streamelements',
            });
            return data;
        },
    });
    return { deleteStreamelementsMutation };
};
