import { PositionChangeModal, useModalContext } from '@fourthwall/components';
import { arrayMove } from '@fourthwall/utils/lib/array';
import { useEffect, useRef, useState } from 'react';
import { getSelectedCollectionsByIds } from './helpers/getSelectedCollectionsByIds/getSelectedCollectionsByIds';
export const useSelectableList = ({ allItems, positionChangeModalTitle, maxItemsPerPage = 0, onItemSelectionChange, }) => {
    const memoedList = useRef(allItems);
    const { current: memoedOnItemSelectionChange } = useRef(onItemSelectionChange);
    const { open } = useModalContext();
    const [selectedItemsIds, setSelectedItemsIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [page, setPage] = useState(1);
    const showPagination = selectedItems.length > maxItemsPerPage;
    const pageCount = Math.ceil(selectedItems.length / maxItemsPerPage);
    const itemIndexShift = (page - 1) * maxItemsPerPage;
    useEffect(() => {
        memoedList.current = allItems;
    }, [allItems]);
    useEffect(() => {
        memoedOnItemSelectionChange(selectedItemsIds, selectedItems);
    }, [memoedOnItemSelectionChange, selectedItems, selectedItemsIds]);
    useEffect(() => {
        setSelectedItems(() => {
            return getSelectedCollectionsByIds(memoedList.current, selectedItemsIds);
        });
    }, [selectedItemsIds]);
    const sortSelectedCollections = (oldIndex, newIndex) => {
        setSelectedItems((prevState) => arrayMove(prevState, oldIndex, newIndex));
    };
    const changePosition = (currIndex) => {
        const selectedItemsLength = selectedItemsIds.length;
        open(PositionChangeModal, {
            title: positionChangeModalTitle,
            maxPosition: selectedItemsLength,
            currentPosition: currIndex + 1,
            onSubmit: (values) => {
                const { type = 'EXACT' } = values;
                const toIndex = {
                    TOP: 0,
                    BOTTOM: selectedItemsLength - 1,
                    EXACT: values.exactPosition - 1,
                }[type];
                sortSelectedCollections(currIndex, toIndex);
            },
        });
    };
    const handleOnRemove = (index) => {
        const selectedItemId = selectedItems[index].id;
        setSelectedItemsIds((prevState) => prevState.filter((id) => id !== selectedItemId));
    };
    const onPageChange = (index) => {
        setPage(index);
    };
    const handleOnSortChange = (oldIndex, newIndex) => {
        sortSelectedCollections(oldIndex + itemIndexShift, newIndex + itemIndexShift);
    };
    const handleOnPositionChange = (currentIndex) => {
        changePosition(currentIndex + itemIndexShift);
    };
    const getPaginatedSelectedItems = () => {
        if (showPagination && maxItemsPerPage) {
            const start = itemIndexShift;
            const end = start + maxItemsPerPage;
            return selectedItems.slice(start, end);
        }
        return selectedItems;
    };
    return {
        selectedListProps: {
            selectedItems: getPaginatedSelectedItems(),
            onSortChange: handleOnSortChange,
            onPositionChange: handleOnPositionChange,
            onRemove: handleOnRemove,
        },
        pageProps: {
            showPagination,
            currentPage: page,
            pageCount,
            onChange: onPageChange,
        },
        setSelectedItemsIds,
        selectedItemsIds,
    };
};
