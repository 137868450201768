import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
const weights = {
    regular: '400',
    semibold: '600',
    bold: '700',
};
const getWeight = ({ weight }) => getAutomatedStyleProp('fontWeightHeading', weights[weight || 'regular']);
export const Container = styled.h1 `
  color: ${getAutomatedStyleProp('colorOnBackground', Colors.GS1000)};
  line-height: 110%;

  font-family: ${getAutomatedStyleProp('fontFamilyHeading')};
  font-style: ${getAutomatedStyleProp('fontStyleHeading')};
  text-transform: ${getAutomatedStyleProp('textTransformHeading')};
  font-weight: ${getWeight};

  ${({ type }) => type === 'h1' &&
    css `
      font-size: 96px;
      letter-spacing: -0.02em;
    `};

  ${({ type }) => type === 'h2' &&
    css `
      font-size: 72px;
      letter-spacing: -0.01em;
    `};

  ${({ type }) => type === 'h3' &&
    css `
      font-size: 56px;
    `};

  ${({ type }) => type === 'h4' &&
    css `
      font-size: 48px;
    `};

  ${({ type }) => type === 'h5' &&
    css `
      font-size: 32px;
      line-height: 120%;
    `};

  ${({ type }) => type === 'h6' &&
    css `
      font-size: 24px;
      line-height: 140%;
    `};

  ${({ type }) => type === 'h7' &&
    css `
      font-size: 20px;
      line-height: 140%;
    `};
`;
