import styled from 'styled-components';
export const Container = styled.div `
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;

  justify-content: space-between;
  align-items: center;

  position: relative;
  height: 64px;
  padding: 0 24px;
`;
export const RightContent = styled.div `
  display: flex;
  justify-self: flex-end;
`;
export const CenterContent = styled.div `
  display: flex;
  justify-self: center;
`;
