var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormik, useFormikContext } from 'formik';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import mapValues from 'lodash-es/mapValues';
import merge from 'lodash-es/merge';
import { useCallback, useEffect, useState } from 'react';
import { flattenKeys, unflattenKeys } from './object';
const getErrors = (formik, everDirty, options = {}) => {
    var _a;
    const flatTouched = flattenKeys(formik.touched);
    const flatErrors = flattenKeys(formik.errors);
    const flatStatusErrors = flattenKeys(((_a = formik.status) === null || _a === void 0 ? void 0 : _a.errors) || {});
    if (!everDirty && options.hidePristineErrors && formik.submitCount === 0) {
        return {};
    }
    const visibleErrors = mapValues(merge(flatStatusErrors, flatErrors), (error, fieldName) => {
        const hasError = formik.submitCount > 0 || get(flatTouched, fieldName);
        return hasError ? error : undefined;
    });
    if (options.showOnlyFirstError && !isEmpty(visibleErrors)) {
        return {
            [Object.keys(visibleErrors)[0]]: Object.values(visibleErrors)[0],
        };
    }
    return unflattenKeys(visibleErrors);
};
const createGetFieldProps = (formik, errors) => (fieldName) => {
    const fieldProps = formik.getFieldProps(fieldName);
    const onChange = (e) => {
        var _a, _b;
        fieldProps.onChange(e);
        // NOTE: we'd like to clear status error if field changes
        if ((_b = (_a = formik.status) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b[fieldName]) {
            const _c = formik.status.errors, _d = fieldName, omittedError = _c[_d], restOfErrors = __rest(_c, [typeof _d === "symbol" ? _d : _d + ""]);
            formik.setStatus(Object.assign(Object.assign({}, formik.status), { errors: restOfErrors }));
        }
    };
    return Object.assign(Object.assign({}, fieldProps), { onChange, error: get(errors, fieldName), submitCount: formik.submitCount });
};
const useEverDirty = (isDirty, options = {}) => {
    const [everDirty, setEverDirty] = useState(false);
    useEffect(() => {
        if (isDirty && options.hidePristineErrors) {
            setEverDirty(true);
        }
    }, [isDirty, options.hidePristineErrors]);
    return everDirty;
};
const useCustomFormik = (config, options = {}) => {
    const formik = useFormik(config);
    const everDirty = useEverDirty(formik.dirty, options);
    const errors = getErrors(formik, everDirty, options);
    const getFieldProps = createGetFieldProps(formik, errors);
    return Object.assign(Object.assign({}, formik), { errors,
        getFieldProps });
};
const useCustomFormikContext = (options = {}) => {
    const formikContext = useFormikContext();
    const everDirty = useEverDirty(formikContext.dirty, options);
    const errors = getErrors(formikContext, everDirty, options);
    const getFieldProps = createGetFieldProps(formikContext, errors);
    return Object.assign(Object.assign({}, formikContext), { errors,
        getFieldProps });
};
const useFocusFirstErrorOnSubmit = (onNotInputFound) => {
    const { errors, submitCount } = useCustomFormikContext();
    const focusFirstInputWithError = useCallback((errors) => {
        const flattenErrors = flattenKeys(errors);
        const headInputNameWithError = Object.keys(flattenErrors).find((key) => flattenErrors[key] !== undefined);
        // NOTE: convert 'fieldName.0.fieldName' to 'fieldName[0].fieldName'
        const indexedInputName = headInputNameWithError === null || headInputNameWithError === void 0 ? void 0 : headInputNameWithError.replace(/\.(\d+)\./g, '[$1].');
        const inputWithError = document.querySelector(`[name="${indexedInputName}"]`);
        if (inputWithError) {
            inputWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
            inputWithError.focus({ preventScroll: true });
        }
        else {
            onNotInputFound === null || onNotInputFound === void 0 ? void 0 : onNotInputFound(errors);
        }
    }, [onNotInputFound]);
    useEffect(() => {
        if (submitCount && Object.keys(errors).length > 0) {
            focusFirstInputWithError(errors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);
};
export { useCustomFormik as useFormik, useCustomFormikContext as useFormikContext, useFocusFirstErrorOnSubmit, };
