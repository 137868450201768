import { useModalContext } from '@fourthwall/components';
import React, { createContext, useCallback, useMemo } from 'react';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { FREE_TIER_ID } from '../consts';
import { useReorderPerkMutation } from '../hooks/useReorderPerkMutation';
import { useTierPerksQuery } from '../hooks/useTierPerksQuery';
import { PerkModal } from '../PerkModal/PerkModal';
export const PerksContext = createContext(undefined);
export const PerksProvider = ({ tierId, children }) => {
    const modal = useModalContext();
    const { hasPermission } = useDashboardPermissions();
    const { tierPerksQuery } = useTierPerksQuery([
        {
            tier: {
                id: tierId,
                type: tierId === FREE_TIER_ID ? 'free' : 'paid',
            },
        },
    ], {
        staleTime: Infinity,
        keepPreviousData: true,
    });
    const { reorderPerkMutation } = useReorderPerkMutation();
    const isLoading = tierPerksQuery.isFetching || reorderPerkMutation.isLoading;
    const handleAddExisitingPerk = useCallback((perk, selectedTiers) => {
        const getSelectedTiers = () => {
            const tiers = {};
            perk.tiers.forEach((selectedTier) => {
                tiers[selectedTier.id] = true;
            });
            return { ...tiers, ...selectedTiers };
        };
        modal.close();
        // Required to reset Formik from previous modal
        setTimeout(() => {
            modal.open(PerkModal, { perk, selectedTiers: getSelectedTiers() });
        });
    }, [modal]);
    const editPerk = useCallback((perk) => {
        modal.open(PerkModal, { perk });
    }, [modal]);
    const addPerk = useCallback((tierId) => {
        modal.open(PerkModal, {
            selectedTiers: { [tierId]: true },
            onExistingPerkAdd: handleAddExisitingPerk,
        });
    }, [handleAddExisitingPerk, modal]);
    const sortPerk = useCallback((id, oldIndex, newIndex) => {
        reorderPerkMutation.mutate([
            {
                id,
            },
            {
                tier: {
                    id: tierId,
                    type: tierId === FREE_TIER_ID ? 'free' : 'paid',
                },
            },
            {
                position: newIndex,
            },
        ], {
            onSuccess: () => {
                tierPerksQuery.refetch();
            },
        });
    }, [reorderPerkMutation, tierId, tierPerksQuery]);
    const value = useMemo(() => ({
        isLoading,
        perks: tierPerksQuery.data?.perks,
        addPerk: hasPermission('memberships.perksAndTiers.createPerk') ? addPerk : undefined,
        editPerk,
        sortPerk,
    }), [addPerk, editPerk, hasPermission, isLoading, sortPerk, tierPerksQuery.data?.perks]);
    return React.createElement(PerksContext.Provider, { value: value }, children);
};
