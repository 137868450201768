import { AddButton, Dropdown, Icon, Loader } from '@fourthwall/components';
import React from 'react';
import * as S from './AddDropdown.styled';
export const AddDropdown = ({ items, label, position, onClick }) => {
    const renderItems = () => {
        if (!items) {
            return (React.createElement(S.ItemWrapper, null,
                React.createElement(S.LoaderContainer, null,
                    React.createElement(Loader, { size: "large" }))));
        }
        return items.map(({ id, label, icon }) => (React.createElement(Dropdown.Item, { key: id, label: React.createElement(S.ItemWrapper, null,
                icon && (React.createElement(S.ItemIcon, null,
                    React.createElement(Icon, { component: icon, height: 16 }))),
                React.createElement(S.ItemLabel, null, label)), onClick: () => onClick(id) })));
    };
    return (React.createElement(S.Container, null,
        React.createElement(Dropdown, { content: renderItems(), maxHeight: 300, position: position, optionsVerticalOffset: 15, optionsHorizontalOffset: 25 },
            React.createElement(S.DropdownButton, null,
                React.createElement(AddButton, { label: label })))));
};
