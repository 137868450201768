import { Button, Input, ModalBox, Toasts } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { useCurrentShopContext } from '../../../../hooks/useCurrentShopContext';
import { validationSchema } from './consts';
import { useRenameThemeMutation } from './hooks/useRenameThemeMutation';
import { FormFields } from './types';
export const ThemeRenameModal = ({ id, name, role, excludedNames, onSuccess, close }) => {
    const { renameThemeMutation } = useRenameThemeMutation();
    const { currentShop } = useCurrentShopContext();
    const formik = useFormik({
        initialValues: {
            name,
        },
        validationSchema: validationSchema({ excludedNames }),
        onSubmit: (values) => {
            renameThemeMutation.mutate([
                currentShop.id,
                id,
                {
                    theme: {
                        name: values.name,
                        role,
                    },
                },
            ], {
                onSuccess: () => {
                    Toasts.notify('Selected theme has been renamed', { type: 'info' });
                    onSuccess();
                    close();
                },
            });
        },
    });
    const handleClose = () => {
        if (!renameThemeMutation.isLoading) {
            close();
        }
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(ModalBox, { size: "small", title: "Rename theme", buttons: [
                React.createElement(Button, { label: "Cancel", type: "button", appearance: "secondary", disabled: renameThemeMutation.isLoading, onClick: handleClose }),
                React.createElement(Button, { label: "Update", appearance: "primary", loading: renameThemeMutation.isLoading, disabled: !formik.dirty, type: "submit" }),
            ], onClose: handleClose },
            React.createElement(Input, { label: "Name", ...formik.getFieldProps(FormFields.name) }))));
};
