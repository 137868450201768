// NOTE: This originally comes from jsoup defaults + shop renderer and is passed to OWASP configuration on the backend.
// https://github.com/jhy/jsoup/blob/dcf3c8ba5a0963260999f463ee80b6a787928bb9/src/main/java/org/jsoup/safety/Whitelist.java#L153
export const VALID_ELEMENTS = {
    '@': ['class', 'id', 'style', 'lang', 'dir'],
    a: ['download', 'href', 'rel', 'target', 'title', 'type'],
    b: true,
    blockquote: ['cite'],
    br: true,
    caption: true,
    cite: true,
    code: true,
    col: ['span', 'width'],
    colgroup: ['span', 'width'],
    dd: true,
    div: true,
    dl: true,
    dt: true,
    em: true,
    h1: true,
    h2: true,
    h3: true,
    h4: true,
    h5: true,
    h6: true,
    i: true,
    img: ['align', 'alt', 'height', 'src', 'title', 'width'],
    li: true,
    ol: ['reversed', 'start', 'type'],
    p: true,
    pre: true,
    q: ['cite'],
    small: true,
    span: true,
    strike: true,
    strong: true,
    sub: true,
    sup: true,
    table: ['border', 'height', 'summary', 'width'],
    tbody: true,
    td: ['abbr', 'axis', 'colspan', 'headers', 'height', 'rowspan', 'width'],
    tfoot: true,
    th: ['abbr', 'axis', 'colspan', 'headers', 'height', 'rowspan', 'scope', 'width'],
    thead: true,
    tr: true,
    u: true,
    ul: ['type'],
};
// NOTE: This comes from java-html-sanitizer default whitelist.
// https://github.com/OWASP/java-html-sanitizer/blob/main/src/main/java/org/owasp/html/CssSchema.java#L858
export const VALID_STYLES = [
    '-moz-border-radius',
    '-moz-border-radius-bottomleft',
    '-moz-border-radius-bottomright',
    '-moz-border-radius-topleft',
    '-moz-border-radius-topright',
    '-moz-box-shadow',
    '-moz-outline',
    '-moz-outline-color',
    '-moz-outline-style',
    '-moz-outline-width',
    '-o-text-overflow',
    '-webkit-border-bottom-left-radius',
    '-webkit-border-bottom-right-radius',
    '-webkit-border-radius',
    '-webkit-border-radius-bottom-left',
    '-webkit-border-radius-bottom-right',
    '-webkit-border-radius-top-left',
    '-webkit-border-radius-top-right',
    '-webkit-border-top-left-radius',
    '-webkit-border-top-right-radius',
    '-webkit-box-shadow',
    'azimuth',
    'background',
    'background-attachment',
    'background-color',
    'background-image',
    'background-position',
    'background-repeat',
    'border',
    'border-bottom',
    'border-bottom-color',
    'border-bottom-left-radius',
    'border-bottom-right-radius',
    'border-bottom-style',
    'border-bottom-width',
    'border-collapse',
    'border-color',
    'border-left',
    'border-left-color',
    'border-left-style',
    'border-left-width',
    'border-radius',
    'border-right',
    'border-right-color',
    'border-right-style',
    'border-right-width',
    'border-spacing',
    'border-style',
    'border-top',
    'border-top-color',
    'border-top-left-radius',
    'border-top-right-radius',
    'border-top-style',
    'border-top-width',
    'border-width',
    'box-shadow',
    'caption-side',
    'color',
    'cue',
    'cue-after',
    'cue-before',
    'direction',
    'elevation',
    'empty-cells',
    'font',
    'font-family',
    'font-size',
    'font-stretch',
    'font-style',
    'font-variant',
    'font-weight',
    'height',
    'image()',
    'letter-spacing',
    'line-height',
    'linear-gradient()',
    'list-style',
    'list-style-image',
    'list-style-position',
    'list-style-type',
    'margin',
    'margin-bottom',
    'margin-left',
    'margin-right',
    'margin-top',
    'max-height',
    'max-width',
    'min-height',
    'min-width',
    'outline',
    'outline-color',
    'outline-style',
    'outline-width',
    'padding',
    'padding-bottom',
    'padding-left',
    'padding-right',
    'padding-top',
    'pause',
    'pause-after',
    'pause-before',
    'pitch',
    'pitch-range',
    'quotes',
    'radial-gradient()',
    'rect()',
    'repeating-linear-gradient()',
    'repeating-radial-gradient()',
    'rgb()',
    'rgba()',
    'hsl()',
    'hsla()',
    'richness',
    'speak',
    'speak-header',
    'speak-numeral',
    'speak-punctuation',
    'speech-rate',
    'stress',
    'table-layout',
    'text-align',
    'text-decoration',
    'text-indent',
    'text-overflow',
    'text-shadow',
    'text-transform',
    'text-wrap',
    'unicode-bidi',
    'vertical-align',
    'voice-family',
    'volume',
    'white-space',
    'width',
    'word-spacing',
    'word-wrap',
];
