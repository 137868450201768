import { Avatar } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const SupporterCell = ({ name, avatarUrl, shadowBanned }) => {
    const getAvatar = () => {
        if (!avatarUrl)
            return;
        return avatarUrl;
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.AvatarContainer, null,
            React.createElement(Avatar, { name: name, imageUrl: getAvatar() })),
        React.createElement("div", null,
            React.createElement(S.Name, null, name),
            shadowBanned && React.createElement(S.Info, null, "Shadowbanned"))));
};
