export const getChartEntitySelect = (options, reportType, chartEntityGroup, onValueChange) => {
    if (reportType !== 'orders' && reportType !== 'profit')
        return;
    return {
        options,
        value: chartEntityGroup || options[0].value,
        onValueChange: (val) => {
            onValueChange(val);
        },
    };
};
