import get from 'lodash-es/get';
// TODO: we should get rid of this, the profit is too low and it's almost impossible to type this
export const dataSelector = (state) => state.data;
export const isFetchingSelector = (state) => state.isFetching;
export const isSubmittingSelector = (state) => state.isSubmitting;
export const totalPagesSelector = (state) => get(state, 'page.totalPages');
export const errorSelector = (state) => state.error;
export const isUnavailableSelector = (state, status) => {
    var _a, _b;
    return (state.isFetching === false && (((_b = (_a = state.error) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.status) === 404 || status === 'ARCHIVED'));
};
export const errorMessageSelector = (error, defaultMessage) => {
    if (error === null || error === void 0 ? void 0 : error.message) {
        return error.message;
    }
    if (error === null || error === void 0 ? void 0 : error.title) {
        return error.title;
    }
    return defaultMessage || 'Something went wrong. Please try again.';
};
