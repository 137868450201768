import { Dashboard } from '@components/common';
import kebabCase from 'lodash-es/kebabCase';
import { BookACall } from '@components/common/BookACall';
import React, { useEffect } from 'react';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useMediaQueryContext } from '@fourthwall/components';
import { matchPath, useLocation } from 'react-router-dom';
import { routing } from '@utils/routing';
import { useMenuDrawerContext } from '../contexts/MenuDrawerContext';
export const SidebarContent = ({ navigations }) => {
    const { hasPermission } = useDashboardPermissions();
    const media = useMediaQueryContext();
    const { activeNavigationGroup, setActiveNavigationGroup, drawerActive } = useMenuDrawerContext();
    const location = useLocation();
    const isMatched = (to) => !!matchPath({ path: to, end: to === routing.home }, location.pathname);
    const getMatchingNavigationGroupLabel = () => navigations.find(({ to }) => isMatched(to))?.label;
    useEffect(() => {
        if (!drawerActive) {
            const matchingNavigationGroupLabel = getMatchingNavigationGroupLabel();
            matchingNavigationGroupLabel && setActiveNavigationGroup(matchingNavigationGroupLabel);
        }
    }, [drawerActive]);
    const renderItem = ({ label, to, items }, parentLabel) => {
        const hasChildren = !!items?.length;
        const matched = isMatched(to);
        const displayChildren = hasChildren &&
            ((!media.tablet && activeNavigationGroup === label) || (media.tablet && matched));
        return (React.createElement(Dashboard.Menu.Item, { key: kebabCase(label), label: label, to: !media.tablet && hasChildren ? undefined : to, onClick: (e) => {
                e.stopPropagation();
                if (!parentLabel) {
                    setActiveNavigationGroup(activeNavigationGroup === label ? null : label);
                }
            } }, displayChildren && (React.createElement(Dashboard.Menu, null, items.map((item) => !item.hidden && renderItem(item, label))))));
    };
    return (React.createElement(Dashboard.Menu, null,
        navigations.map((item) => !item.hidden && renderItem(item)),
        hasPermission('bookACall') && React.createElement(BookACall, null)));
};
