import { useUpdateShopAnalyticsMutation } from '@modules/Dashboard/Analytics/modules/AnalyticsTrackingPixels/mutations/updateShopAnalyticsMutation';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
export const useAnalyticsTrackingPixels = () => {
    const { currentShop, invalidateCurrentShopQuery } = useCurrentShop();
    const { updateShopAnalyticsMutation } = useUpdateShopAnalyticsMutation();
    const initialValues = {
        googleAnalyticsTrackingId: currentShop?.settings.analytics?.googleAnalyticsTrackingId || '',
        facebookPixelId: currentShop?.settings.analytics?.facebookPixelId || '',
        tiktokPixelId: currentShop?.settings.analytics?.tiktokPixelId || '',
    };
    const handleSubmit = async (values) => {
        await updateShopAnalyticsMutation.mutateAsync([{ shopId: currentShop?.id }, values], {
            onSuccess: () => {
                invalidateCurrentShopQuery();
            },
        });
    };
    return {
        initialValues,
        onSubmit: handleSubmit,
    };
};
