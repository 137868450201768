import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const ImplicitContent = styled.div `
  display: flex;
  align-items: center;
`;
export const ImplicitInput = styled.div `
  width: 100%;

  ${mediaQueries.tablet} {
    max-width: 160px;
  }
`;
export const ProfitDescription = styled.div `
  line-height: 150%;
  margin: 16px 0;

  ${mediaQueries.tablet} {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 16px;
  }
`;
export const ProfitValueWrapper = styled.div `
  margin-bottom: 8px;

  ${mediaQueries.tablet} {
    margin-bottom: 0;
  }
`;
