import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  > * + * {
    margin-top: 16px;
  }
`;
export const Head = styled.div `
  display: grid;
  grid-template-columns: 1fr auto;
`;
export const Content = styled.div `
  border-top: 1px solid ${Colors.GS400};
`;
