import { Toasts } from '@fourthwall/components';
import { useTagManager } from '@hooks';
import { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useCreatorIntegrationsQuery } from '../../../queries/useCreatorIntegrationsQuery';
import { useTwitchTokenMutation } from './useTwitchTokenMutation';
export const useTwitchToken = () => {
    const { twitchTokenMutation } = useTwitchTokenMutation();
    const [query, setQuery] = useQueryParams({
        state: StringParam,
        code: StringParam,
        scope: StringParam,
    });
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const tagManager = useTagManager();
    const clearQuery = () => setQuery({ state: undefined, code: undefined, scope: undefined });
    useEffect(() => {
        const { state, code, scope } = query;
        if (state && code && scope) {
            const toastId = 'twitchTokenMutation';
            Toasts.notify('Connecting to Twitch...', {
                toastId,
                type: 'info',
                autoClose: false,
            });
            twitchTokenMutation.mutate({ state, code, scope }, {
                onSuccess: () => {
                    Toasts.dismiss(toastId);
                    clearQuery();
                    Toasts.notify('Twitch connected successfully.', { type: 'success' });
                    tagManager.trigger('app_connected', { app_name: 'twitch_gifting' });
                },
                onError: () => {
                    Toasts.dismiss(toastId);
                    clearQuery();
                    Toasts.notify('Unknown error. Please try again.', { type: 'error' });
                },
                onSettled: () => {
                    creatorIntegrationsQuery.refetch();
                },
            });
        }
    }, []);
};
