import { useModalContext } from '@fourthwall/components';
import Yup from '@fourthwall/utils/lib/yup';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useCurrentShopConfigQuery } from '../../../hooks/useCurrentShopConfigQuery';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { useSetCurrentShopConfigChargeLimitMutation } from '../../hooks/useSetCurrentShopConfigChargeLimitMutation';
import { useShopPaymentMethods } from '../../hooks/useShopPaymentMethods';
import { getChargeLimitValidationSchema } from '../../utils';
import { AddPaymentMethodModalView } from './AddPaymentMethodModalView';
import { useAddShopPaymentMethodMutation } from './hooks/useAddShopPaymentMethodMutation';
import { useAddUserPaymentMethodMutation } from './hooks/useAddUserPaymentMethodMutation';
import { useCompleteUserPaymentMethodSetupMutation } from './hooks/useCompleteUserPaymentMethodSetupMutation';
import { useInitializeUserPaymentMethodPayPalSetupQuery } from './hooks/useInitializeUserPaymentMethodPayPalSetupQuery';
import { useInitializeUserPaymentMethodSetupMutation } from './hooks/useInitializeUserPaymentMethodSetupMutation';
import { usePayPal } from './hooks/usePayPal';
import { useStripe } from './hooks/useStripe';
import { useUserPaymentMethods } from './hooks/useUserPaymentMethods';
import { getSetCurrentShopConfigChargeLimitPayload, hasChargeLimitValuesChanged } from './utils';
export const AddPaymentMethodModalContainer = ({ title = 'Add a new payment method', subtitle, submitLabel = 'Save', onSuccess, }) => {
    const { close } = useModalContext();
    const stripe = useStripe();
    const payPal = usePayPal();
    const { userPaymentMethods, selectedPaymentMethod, isLoadingUserPaymentMethods, setSelectedPaymentMethod, } = useUserPaymentMethods();
    const formRef = useRef(null);
    const { currentShopQuery } = useCurrentShopQuery();
    const { shopPaymentMethods, isLoadingShopPaymentMethods } = useShopPaymentMethods();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { currentShopConfigQuery, invalidateCurrentShopConfigQuery } = useCurrentShopConfigQuery();
    const { invalidateCurrentShopQuery } = useCurrentShopQuery();
    const { completeUserPaymentMethodSetupMutation } = useCompleteUserPaymentMethodSetupMutation();
    const { addUserPaymentMethodMutation } = useAddUserPaymentMethodMutation();
    const { initializeUserPaymentMethodSetupMutation } = useInitializeUserPaymentMethodSetupMutation();
    const { initializeUserPaymentMethodPayPalSetupQuery } = useInitializeUserPaymentMethodPayPalSetupQuery([], { cacheTime: 0 });
    const { addShopPaymentMethodMutation } = useAddShopPaymentMethodMutation();
    const { setCurrentShopConfigChargeLimitMutation } = useSetCurrentShopConfigChargeLimitMutation();
    const isLoading = isLoadingUserPaymentMethods ||
        isLoadingShopPaymentMethods ||
        currentShopConfigQuery.isLoading ||
        currentShopQuery.isLoading ||
        !payPal.isInitialized;
    const cardNumberRef = useRef(null);
    const cardExpiryRef = useRef(null);
    const cardCvcRef = useRef(null);
    const userPaymentMethodsProps = userPaymentMethods?.map((userPaymentMethod) => ({
        ...userPaymentMethod,
        onClick: () => {
            setSelectedPaymentMethod(userPaymentMethod.id);
        },
    }));
    const allFieldsInitialized = Object.values(stripe.fields).every(({ isInitialized }) => isInitialized);
    const allFieldsUnmounted = Object.values(stripe.fields).every(({ isMounted }) => !isMounted);
    const initialValues = {
        formType: 'EXISTING',
        paymentMethodType: 'STRIPE',
        isPaymentMethodDefault: true,
        setChargeLimit: currentShopConfigQuery.data?.chargePolicy?.type === 'LIMITED',
        chargeLimit: currentShopConfigQuery.data?.chargePolicy?.type === 'LIMITED'
            ? currentShopConfigQuery.data.chargePolicy.amount.value.toString()
            : '',
    };
    const validationSchema = Yup.object().shape({
        ...getChargeLimitValidationSchema(),
    });
    useLayoutEffect(() => {
        if (allFieldsInitialized &&
            allFieldsUnmounted &&
            cardNumberRef.current &&
            cardExpiryRef.current &&
            cardCvcRef.current) {
            stripe.fields.cardNumber.mount(cardNumberRef.current);
            stripe.fields.cardExpiry.mount(cardExpiryRef.current);
            stripe.fields.cardCvc.mount(cardCvcRef.current);
        }
    }, [
        allFieldsInitialized,
        allFieldsUnmounted,
        isLoadingUserPaymentMethods,
        userPaymentMethodsProps,
        cardNumberRef.current,
        cardExpiryRef.current,
        cardCvcRef.current,
    ]);
    useEffect(() => {
        if (initializeUserPaymentMethodPayPalSetupQuery.data &&
            currentShopQuery.data?.settings.payment.paypalClientId)
            payPal.initialize(currentShopQuery.data?.settings.payment.paypalClientId, initializeUserPaymentMethodPayPalSetupQuery.data.idToken);
    }, [
        initializeUserPaymentMethodPayPalSetupQuery.data?.idToken,
        currentShopQuery.data?.settings.payment.paypalClientId,
    ]);
    const setCurrentShopConfigChargeLimit = (values) => {
        if (!hasChargeLimitValuesChanged(values, initialValues)) {
            close();
            onSuccess();
            setIsSubmitting(false);
            return;
        }
        setCurrentShopConfigChargeLimitMutation.mutate([getSetCurrentShopConfigChargeLimitPayload(values)], {
            onSuccess: () => {
                invalidateCurrentShopConfigQuery();
                close();
                onSuccess();
            },
            onSettled: () => {
                setIsSubmitting(false);
            },
        });
    };
    const addShopPaymentMethod = (values, paymentMethodId, setDefault) => {
        addShopPaymentMethodMutation.mutate([{ paymentMethodId, setDefault }], {
            onSuccess: () => {
                setCurrentShopConfigChargeLimit(values);
                invalidateCurrentShopQuery();
            },
        });
    };
    const addNewStripeCard = async (values) => {
        try {
            const data = await initializeUserPaymentMethodSetupMutation.mutateAsync([]);
            const setupIntent = await stripe.submit(data.setupIntentClientSecret);
            if (setupIntent?.payment_method) {
                completeUserPaymentMethodSetupMutation.mutate([{ paymentMethodId: setupIntent.payment_method }], {
                    onSuccess: (data) => {
                        addShopPaymentMethod(values, data.paymentMethodId, values.isPaymentMethodDefault);
                    },
                    onError: () => {
                        setIsSubmitting(false);
                    },
                });
            }
            else {
                setIsSubmitting(false);
            }
        }
        catch (error) {
            setIsSubmitting(false);
        }
    };
    const handlePaymentMethodTypeChange = (paymentMethodType) => {
        if (paymentMethodType === 'PAYPAL') {
            payPal.renderButton({
                vaultSetupToken: initializeUserPaymentMethodPayPalSetupQuery.data?.setupToken,
                onClick: async () => {
                    await formRef?.current?.submitForm();
                    return Promise.resolve(!!formRef?.current?.isValid);
                },
                onApprove: async ({ vaultSetupToken }) => {
                    addUserPaymentMethodMutation.mutate([{ type: 'PAYPAL', paypalSetupToken: vaultSetupToken }], {
                        onSuccess: (data) => {
                            addShopPaymentMethod(formRef?.current?.values, data.paymentMethodId, formRef?.current?.values?.isPaymentMethodDefault);
                        },
                        onError: () => {
                            setIsSubmitting(false);
                        },
                    });
                },
                onError: () => {
                    setIsSubmitting(false);
                    // handle error
                },
                onCancel: () => {
                    setIsSubmitting(false);
                },
            });
        }
        else {
            payPal.destroyButton();
        }
    };
    const handleSubmit = (values) => {
        setIsSubmitting(true);
        if (values.formType === 'EXISTING' && selectedPaymentMethod) {
            addShopPaymentMethod(values, selectedPaymentMethod.id, values.isPaymentMethodDefault);
        }
        if (values.paymentMethodType === 'STRIPE' &&
            (values.formType === 'NEW' || !userPaymentMethods?.length)) {
            addNewStripeCard(values);
        }
        // NOTE: we handle PayPal submit in separate handler
    };
    return (React.createElement(AddPaymentMethodModalView, { formikRef: formRef, title: title, subtitle: subtitle, submitLabel: submitLabel, paymentMethodsProps: userPaymentMethodsProps, fields: {
            cardNumber: {
                error: stripe.fields.cardNumber.error,
                isFocused: stripe.fields.cardNumber.isFocused,
                ref: cardNumberRef,
            },
            cardExpiry: {
                error: stripe.fields.cardExpiry.error,
                isFocused: stripe.fields.cardExpiry.isFocused,
                ref: cardExpiryRef,
            },
            cardCvc: {
                error: stripe.fields.cardCvc.error,
                isFocused: stripe.fields.cardCvc.isFocused,
                ref: cardCvcRef,
            },
        }, initialValues: initialValues, validationSchema: validationSchema, shouldRenderIsPaymentMethodDefaultCheckbox: !isLoading && !!shopPaymentMethods && shopPaymentMethods.length > 0, currentMonthSpendingValue: currentShopConfigQuery.data?.chargePolicy.chargedCurrentMonth, isSubmitting: isSubmitting, isLoading: isLoading, onSubmit: handleSubmit, onPaymentMethodTypeChange: handlePaymentMethodTypeChange, onClose: close }));
};
