import { useDashboardPermissions } from '../../../../../../components/utils/DashboardPermissions';
import { routing } from '../../../../../../utils/routing';
import { useRecipientsQuery } from './queries/useRecipientsQuery';
export const useEmptyState = () => {
    const { recipientsData, recipientsQuery } = useRecipientsQuery();
    const { hasPermission } = useDashboardPermissions();
    const hasAnyRecipient = recipientsData?.count && recipientsData?.count > 0;
    return {
        isLoading: recipientsQuery.isLoading,
        subtitle: hasAnyRecipient
            ? 'Send messages to some or all of your fans to let them know about new products, deals or other announcements.'
            : 'It looks like you don’t have any contributions yet that would allow you to send messages to your fans.',
        buttonLabel: hasAnyRecipient && hasPermission('contributions.messages.send')
            ? 'Send a new message'
            : undefined,
        to: hasAnyRecipient ? routing.contributions.creatorMessages.send : undefined,
        showButton: !!hasAnyRecipient && hasPermission('contributions.messages.send'),
    };
};
