import React from 'react';
import { Button, useMediaQueryContext } from '@fourthwall/components';
import { routing } from '@utils/routing';
import { SamplesCreditBalance } from '../../components/SamplesCreditBalance';
import * as S from './SamplesRecommendationContentView.styled';
export const SamplesRecommendationContentView = ({ balance, }) => {
    const media = useMediaQueryContext();
    return (React.createElement(S.Container, null,
        React.createElement(S.ButtonWrapper, null,
            React.createElement(Button, { label: "Order samples", to: routing.settings.samples.self, fullWidth: !media.tablet })),
        balance && !!balance.value && (React.createElement(SamplesCreditBalance, { label: "Your sample credit", balance: balance, tooltip: "Sample credit can be used to order samples of your products to use for quality reviews, photography, and promotion videos!" }))));
};
