import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { reverse, routing } from '../../../../../utils/routing';
import { AnalyticsContainer } from '../../AnalyticsContainer';
import { REPORT_TYPES } from '../../consts';
import { AnalyticsReportContainer } from '../../modules/AnalyticsReport';
import { AnalyticsTrackingPixels } from '../../modules/AnalyticsTrackingPixels';
export const AnalyticsRouter = () => (React.createElement(Routes, null,
    React.createElement(Route, { index: true, element: React.createElement(AnalyticsContainer, null) }),
    React.createElement(Route, { path: routing.analytics.trackingPixels.replace(routing.analytics.self, ''), element: React.createElement(AnalyticsTrackingPixels, null) }),
    React.createElement(Route, { path: routing.analytics.report.replace(routing.analytics.self, ''), element: React.createElement(AnalyticsReportContainer, null) }),
    React.createElement(Route, { path: routing.analytics.reportContributions.replace(routing.analytics.self, ''), element: React.createElement(Navigate, { to: reverse(routing.analytics.report, { reportType: REPORT_TYPES.orders.slug }), replace: true }) })));
