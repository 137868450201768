import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;

  border-radius: 100px;
  padding: 5px 12px;

  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  color: ${Colors.GS600};
  background-color: ${Colors.GS200};

  &:hover {
    color: ${Colors.GS1000};
    background-color: ${Colors.GS300};
  }

  &.active {
    color: ${Colors.GS100};
    background-color: ${Colors.GS1000};
  }

  ${mediaQueries.laptop} {
    width: auto;
  }
`;
