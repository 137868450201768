import styled, { css } from 'styled-components';
import { Colors } from '../../../colors';
export const ItemContainer = styled.div(({ active }) => css `
    display: flex;
    align-items: center;

    min-height: 40px;
    padding-left: 16px;
    padding-right: 16px;

    font-size: 16px;
    color: ${Colors.GS1000};
    cursor: pointer;

    :hover {
      background: ${Colors.GS200};
    }

    ${active &&
    css `
      background: ${Colors.GS400} !important;
    `}
  `);
