import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
export const Container = styled.div `
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: ${getAutomatedStyleProp('colorOnBackground', Colors.GS1000)};

  ${({ small }) => small &&
    css `
      font-size: 14px;
    `}
`;
export const ItemContainer = styled.div `
  display: flex;

  & + & {
    margin-top: 8px;
  }

  ${({ bold }) => bold &&
    css `
      font-weight: 700;
    `}

  ${({ highlighted }) => highlighted &&
    css `
      color: ${Colors.B500};
    `}
`;
export const ItemLabel = styled.div `
  flex: 1;
`;
export const ItemLabelWithIcon = styled.div `
  flex: 1;
  display: flex;
  align-items: center;
`;
export const ItemLabeLIcon = styled.div `
  margin-left: 8px;
`;
