import { GiveawayContributionApiClient } from '@fourthwall/services/lib/GiveawayContributionApiClient';
import { useMutation } from '@tanstack/react-query';
export const useRedoGiveawayMutation = () => {
    const redoGiveawayMutation = useMutation({
        mutationFn: async ({ params, payload }) => {
            const { data } = await GiveawayContributionApiClient.redoGiveaway(params, payload);
            return data;
        },
    });
    return {
        redoGiveawayMutation,
    };
};
