import React, { useEffect, useState } from 'react';
import { Button, ModalBox } from '@fourthwall/components';
import { useHash } from '@utils/useHash/useHash';
import { AssistedShippingContent } from '@modules/Dashboard/Product/modules/shipping/shared/SelfOrAssistedShippingChoiceModal/components/AssistedShippingContent';
import { SelfShippingContent } from '@modules/Dashboard/Product/modules/shipping/shared/SelfOrAssistedShippingChoiceModal/components/SelfShippingContent';
import * as S from './styled';
export const SHIPPING_MODAL_HASH = '#shipping-modal-open';
export const SelfOrAssistedShippingChoiceModal = ({ initialOption, options, onConfirm, onClose, }) => {
    const [selectedOption, setSelectedOption] = useState(initialOption);
    const { hasHash, hash } = useHash();
    const handleConfirm = () => {
        onConfirm?.(selectedOption);
        onClose?.();
    };
    useEffect(() => {
        // NOTE: we need this so that the modal manages the visibility of the modal itself (for example, when the user clicks on the back button)
        if (!hasHash(SHIPPING_MODAL_HASH)) {
            onClose?.();
        }
    }, [hasHash, hash, onClose]);
    return (React.createElement(ModalBox, { size: "large", title: "Select who will ship orders", onClose: onClose, buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: onClose }),
            React.createElement(Button, { label: "Confirm", appearance: "primary", onClick: handleConfirm }),
        ] },
        React.createElement(S.Container, { id: SHIPPING_MODAL_HASH }, options.map((option) => (React.createElement(S.Column, { key: option.id, "$active": selectedOption.id === option.id, onClick: () => {
                setSelectedOption(option);
            } }, option.id === 'WEIGHT_BASED_SELF_SHIPPING' ? (React.createElement(SelfShippingContent, null)) : (React.createElement(AssistedShippingContent, null))))))));
};
