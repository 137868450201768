import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
`;
export const Description = styled.div `
  margin-left: 16px;
`;
export const ThumbnailContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 66px;
  height: 88px;
  background: ${Colors.GS300};
  color: ${Colors.GS500};
`;
export const Thumbnail = styled.img `
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
