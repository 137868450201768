import { Checkbox, HeadContent, Skeleton, Subsection } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { Dashboard } from '@components/common/Dashboard';
import { OrderedList } from '@components/common/OrderedList';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { routing } from '@utils/routing';
import { SECTION_NAMES } from '@constants/sectionNames';
import { ConfirmBarContainer } from '@modules/Dashboard/ConfirmBar/ConfirmBarContainer';
import { YouTubeVideoEntry } from '../../components/YouTubeVideoEntry';
import { ConnectBar } from '../../components/ConnectBar';
import { useTiktok } from './hooks/useTiktok';
export const AppsTiktok = () => {
    const { tiktok } = useTiktok();
    const formik = useFormik({
        initialValues: tiktok.settings.initialValues,
        enableReinitialize: true,
        onSubmit: tiktok.settings.onSubmit,
    });
    const renderSettings = () => {
        if (tiktok.status !== 'CONNECTED') {
            return null;
        }
        return (React.createElement("form", { onSubmit: formik.handleSubmit },
            React.createElement(Subsection, { title: "Share thank you notes" },
                React.createElement("div", null, "Optionally we can send all of your video thank you notes directly to your TikTok inbox where you\u2019ll be able to easily share them with everyone."),
                React.createElement("div", { style: { marginTop: 16 } },
                    React.createElement(Checkbox, { label: "Automatically send my video thank you notes to my TikTok inbox", ...formik.getFieldProps('kudosSync') }))),
            React.createElement(ConfirmBarContainer, { isOpen: formik.dirty, isLoading: tiktok.settings.isSubmitting, onConfirm: formik.submitForm, onCancel: formik.resetForm })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: SECTION_NAMES.appTikTok }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: SECTION_NAMES.appTikTok, topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }), bottomContent: React.createElement("div", { style: { lineHeight: 1.5 } },
                    React.createElement("p", null, "Highlight your recent TikTok videos on your homepage and easily share \u201Cthank you\u201D notes to your TikTok account."),
                    React.createElement(OrderedList, null,
                        React.createElement(OrderedList.Item, null, "Connect your TikTok account."),
                        React.createElement(OrderedList.Item, null,
                            "Add \u201CTikTok feed\u201D section to your homepage on ",
                            SECTION_NAMES.themeEditor,
                            " tab."))) },
                React.createElement("div", { style: { marginTop: 16 - 24, marginBottom: 40 } },
                    tiktok.status && (React.createElement("div", { style: { marginBlock: 20 } },
                        React.createElement(YouTubeVideoEntry, { title: "How to Display Your TikTok Feed on Your Shop", description: "Learn how to feature your TikTok videos on your shop page and keep your audience connected to your content.", size: tiktok.status === 'NOT_CONNECTED' ? 'regular' : 'compact', videoId: "ZPtXoO5XL_4" }))),
                    tiktok.connectBar ? (React.createElement(ConnectBar, { ...tiktok.connectBar })) : (React.createElement(Skeleton, null,
                        React.createElement(Skeleton.Shape, { height: 64 })))),
                renderSettings()))));
};
