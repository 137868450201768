import { useParams } from 'react-router-dom';
import React from 'react';
import { ProductVariantsView } from './ProductVariantsView';
import { useOfferQuery } from '../queries/useOfferQuery';
import { useUpdateVariantTypesMutation } from './mutations/useUpdateVariantTypesMutation';
export const ProductVariantsContainer = () => {
    const { productId = '' } = useParams();
    const { offerQuery, invalidateOfferQuery } = useOfferQuery([productId]);
    const { updateVariantTypesMutation } = useUpdateVariantTypesMutation();
    const updateVariantTypesRequest = (payload) => {
        updateVariantTypesMutation.mutate([productId, payload.data], {
            onSuccess: invalidateOfferQuery,
        });
    };
    return (React.createElement(ProductVariantsView, { productId: productId, productIsFetching: offerQuery.isFetching, productVariantTypes: offerQuery.data?.variantTypes || [], name: offerQuery.data?.name, updateVariantTypesRequest: updateVariantTypesRequest }));
};
