import styled from 'styled-components';
import { Colors } from '../../colors';
// Arrows
export const Container = styled.nav `
  display: flex;
  gap: 12px;
`;
// ArrowsButton
const buttonSizes = {
    small: 30,
    medium: 40,
};
export const Button = styled.button `
  display: block;
  width: ${({ size }) => buttonSizes[size]}px;
  aspect-ratio: 1;
  background: ${Colors.GS300};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.3;

    cursor: default;
  }

  &:hover:not(:disabled) {
    background: ${Colors.GS400};
  }

  &:active {
    background: ${Colors.GS300};
  }
`;
