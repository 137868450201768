import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  gap: 16px;
`;
export const Button = styled.button `
  color: ${Colors.GS600};
  cursor: pointer;

  &:hover {
    color: ${Colors.GS1000};
  }

  ${({ active }) => active &&
    css `
      color: ${Colors.GS1000};
    `};
`;
