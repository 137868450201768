import React, { useMemo } from 'react';
import { ALL_COLLECTION_SLUG } from '../../../consts/collection';
import { useOffersByIdsQuery } from '../../../hooks/useOffersByIdsQuery';
import { getDefaultProductImage } from '../../../utils/products';
import { CollectionsView } from './CollectionsView';
import { useCollectionsQuery } from './queries/useCollectionsQuery';
export const CollectionsContainer = () => {
    const { collectionsQuery } = useCollectionsQuery();
    const offerIds = useMemo(() => (collectionsQuery.data || [])
        ?.filter((collection) => collection.slug !== ALL_COLLECTION_SLUG)
        ?.map((collection) => collection.offerIds[0])
        .filter(Boolean), [collectionsQuery.data]);
    const { offersByIdsQuery } = useOffersByIdsQuery([offerIds], { enabled: !!offerIds.length });
    const collections = useMemo(() => collectionsQuery.data
        ?.filter((collection) => collection.slug !== ALL_COLLECTION_SLUG)
        .map((collection) => ({
        ...collection,
        name: unescape(collection.name),
        thumbnail: offersByIdsQuery.data && offersByIdsQuery.data?.[collection.offerIds[0]]
            ? getDefaultProductImage(offersByIdsQuery.data?.[collection.offerIds[0]])
            : undefined,
    })), [collectionsQuery.data, offersByIdsQuery.data]);
    return (React.createElement(CollectionsView, { collections: collections, isFetching: collectionsQuery.isInitialLoading }));
};
