import React from 'react';
import { TimeIcon } from '@fourthwall/icons';
import { Icon } from '../../Icon';
import * as S from './styled';
export const TableBreakdownFooter = ({ text }) => {
    return (React.createElement(S.Container, null,
        React.createElement("div", { style: { marginRight: 12 } },
            React.createElement(Icon, { component: TimeIcon, height: 16 })),
        React.createElement("div", null, text)));
};
