import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  ${mediaQueries.tablet} {
    display: flex;
  }
`;
export const Wrapper = styled.div `
  padding-top: 24px;
`;
export const UploadContainer = styled.div `
  background: ${Colors.GS200};
  aspect-ratio: 1/0.53;

  ${mediaQueries.tablet} {
    width: 240px;
  }
`;
export const SocialInfoContainer = styled.div `
  padding: 14px 20px;
  border: 1px solid ${Colors.GS500};
  border-width: 0 1px 1px 1px;

  color: ${Colors.GS600};
  font-size: 14px;
  line-height: 150%;

  ${mediaQueries.tablet} {
    width: 55%;
    border-width: 1px 1px 1px 0;
  }
`;
export const Name = styled.div `
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS1000};
`;
export const Description = styled.p `
  padding-top: 2px;
  padding-bottom: 12px;
  color: ${Colors.GS600};
  font-size: 14px;
`;
export const SocialDescription = styled.p `
  padding-top: 8px;
`;
export const Image = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
