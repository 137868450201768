import { Checkbox, InfoTooltip } from '@fourthwall/components';
import React from 'react';
import { Field } from 'formik';
import * as S from './styled';
export const CompareAtPriceCheckboxField = ({ name, disabled, }) => {
    return (React.createElement(S.Container, null,
        React.createElement(Field, { name: name, label: "Add compare-at price", component: Checkbox, disabled: disabled }),
        React.createElement(InfoTooltip, { appearance: "secondary", content: React.createElement(React.Fragment, null,
                "Show off a deal by displaying a higher value with a line through it next to your selling price (e.g., $",
                React.createElement("s", null, "29.99"),
                ")"), dark: true })));
};
