import React, { createContext, useContext, useMemo, useState } from 'react';
import { useShopContext } from './ShopProvider';
export const ActiveThemeContext = createContext(undefined);
export const ActiveThemeProvider = ({ children }) => {
    const { shop } = useShopContext();
    const [activeTheme, setActiveTheme] = useState({
        id: shop.shop.main_theme_id || 'main',
        name: 'Main',
    });
    const value = useMemo(() => ({ activeTheme, setActiveTheme }), [activeTheme]);
    return React.createElement(ActiveThemeContext.Provider, { value: value }, children);
};
export const useActiveThemeContext = () => {
    const activeThemeContext = useContext(ActiveThemeContext);
    if (activeThemeContext === undefined) {
        throw new Error('activeThemeContext must be used within a ActiveThemeProvider');
    }
    return activeThemeContext;
};
