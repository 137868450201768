import { useFormikContext } from 'formik';
import React from 'react';
import * as S from './styled';
// TODO: remove after uncommenting <ShippingFeeContainer />
/* eslint-disable @typescript-eslint/no-unused-vars */
export const WeightedShippingView = ({ showShippingFee }) => {
    const formik = useFormikContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Description, null, "We'll do our best to estimate the cost of shipping for a customer's order based on the weight of their order.")));
};
