import { getCommonFieldsPayload } from './getCommonFieldsPayload';
export const getMultipleImagePostPayload = (values) => {
    const commonPayload = getCommonFieldsPayload(values);
    return {
        post: commonPayload.attributes.core,
        access: commonPayload.access,
        notifications: commonPayload.notifications,
        imageResources: values.images?.map((image, index) => ({
            id: image.id,
            position: index,
        })) || [],
    };
};
