import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  max-height: 450px;
  overflow-y: auto;
`;
export const ProductContainer = styled.div `
  border-top: 1px solid ${Colors.GS400};
  display: flex;
  align-items: center;
  padding: 16px 0;
`;
export const ProductImageContainer = styled.div `
  margin-right: 16px;
  align-self: flex-start;
`;
export const ProductContentContainer = styled.div `
  flex: 1;
`;
export const ProductName = styled.a `
  &:hover {
    text-decoration: underline;
  }
`;
export const ProductDescription = styled.div `
  margin-top: 6px;
  color: ${Colors.GS600};
  font-size: 12px;
  line-height: 15.6px;
`;
export const ProductDescriptionItem = styled.div `
  margin-bottom: 6px;
`;
