import { closestCenter, DndContext, DragOverlay, KeyboardSensor, MouseSensor, PointerSensor, useSensor, useSensors, } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import React, { useState } from 'react';
import { routing } from '../../../../utils/routing';
import { AddDropdown } from '../../components/AddDropdown/AddDropdown';
import { Hint } from '../../components/Hint/Hint';
import { EditorPanel } from '../../components/EditorPanel';
import { EditorPanelLoader } from '../../components/EditorPanelLoader';
import { useThemeEditorContext } from '../../hooks/useThemeEditorContext';
import { SocialLinkInput } from './components/SocialLinkInput/SocialLinkInput';
import * as S from './styled';
export const SocialLinksView = ({ links, isLoadingLinks, availableLinkItems, search, onSocialLinkCreate, onSocialLinkEdit, onSocialLinkRemove, onSocialLinkSearch, onValueChange, onSortChange, }) => {
    const [activeLink, setActiveLink] = useState();
    const droppableId = 'socialLinks';
    const { isPreviewLoading } = useThemeEditorContext();
    const { setNodeRef } = useSortable({
        id: droppableId,
    });
    const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    const handleDragLinkStart = ({ active }) => {
        const activeLink = links.find((link) => link.id === active.id);
        setActiveLink(activeLink);
    };
    const handleDragLinkEnd = ({ active, over }) => {
        setActiveLink(null);
        const sourceIndex = links.findIndex((link) => link.id === active.id);
        const destinationIndex = links.findIndex((link) => link.id === over?.id);
        if (sourceIndex === destinationIndex)
            return;
        onSortChange(sourceIndex, destinationIndex);
    };
    const renderSocialLinkInput = (link) => (React.createElement(SocialLinkInput, { key: link.id, id: link.id, link: link, search: search, onSocialLinkEdit: onSocialLinkEdit, onSocialLinkRemove: onSocialLinkRemove, onSocialLinkSearch: onSocialLinkSearch, onValueChange: onValueChange }));
    const renderContent = () => {
        if (isLoadingLinks) {
            return React.createElement(EditorPanelLoader, null);
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(EditorPanel.Wrapper, null,
                React.createElement(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragStart: handleDragLinkStart, onDragEnd: handleDragLinkEnd },
                    React.createElement("div", { ref: links.length === 0 ? setNodeRef : undefined },
                        React.createElement(SortableContext, { id: droppableId, items: links.map((link) => link.id), strategy: verticalListSortingStrategy }, links.map((link) => renderSocialLinkInput(link)))),
                    React.createElement(DragOverlay, null, activeLink ? renderSocialLinkInput(activeLink) : null))),
            React.createElement(S.ButtonContainer, null,
                React.createElement(AddDropdown, { items: availableLinkItems, label: "Add social link", onClick: onSocialLinkCreate }))));
    };
    return (React.createElement(S.Container, null,
        React.createElement(EditorPanel.Header, { to: routing.themeEditor.general.self, title: "Social links", isLoading: isPreviewLoading }),
        React.createElement(EditorPanel.Subheader, null, "Add links to your socials to encourage fans to stay connected. Enter your social handle, username or full URL."),
        renderContent(),
        React.createElement(S.Footer, null,
            React.createElement(Hint, { title: "Unlock special features!", to: routing.apps.self }, "Connect your social accounts to unlock extra features, such as Twitch gifting, TikTok and Instagram feeds, and much more!"))));
};
