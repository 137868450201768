import styled from 'styled-components';
export const Container = styled.div `
  ul {
    display: grid;
    gap: 12px;
  }
`;
export const Item = styled.div `
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  align-items: center;
`;
