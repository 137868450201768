import { ButtonClean, Fieldset, HeadContent, Input, LeavingGuard, Loading, RadioButton, Subsection, } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import { reverse } from 'named-urls';
import React from 'react';
import { routing } from '../../../../../../../utils/routing';
import { ConfirmBarContainer } from '../../../../../ConfirmBar/ConfirmBarContainer';
import { useCurrentShopQuery } from '../../../../../hooks/useCurrentShopQuery';
import { useAccountSettingsQuery } from '../../hooks/useAccountSettingsQuery';
import { useUpdateAccountSettingsPwasMutation } from '../../hooks/useUpdateAccountSettingsPwasMutation';
import { Bar } from './components/Bar';
import { validationSchema } from './consts';
import * as S from './styled';
import { FormFields } from './types';
import { getUpdateAccountSettingsPwasPayload } from './utils';
export const MobileApp = () => {
    const { accountSettingsQuery } = useAccountSettingsQuery();
    const { updateAccountSettingsPwasMutation } = useUpdateAccountSettingsPwasMutation();
    const { currentShopQuery } = useCurrentShopQuery();
    const formik = useFormik({
        initialValues: {
            appNameSetting: accountSettingsQuery.data?.pwa.customName ? 'custom' : 'default',
            appName: accountSettingsQuery.data?.pwa.customName,
        },
        validationSchema,
        onSubmit: (values) => {
            updateAccountSettingsPwasMutation.mutate([getUpdateAccountSettingsPwasPayload(values)], {
                onSuccess: () => {
                    accountSettingsQuery.refetch();
                },
            });
        },
        enableReinitialize: true,
    });
    const isLoading = currentShopQuery.isLoading ||
        accountSettingsQuery.isLoading ||
        updateAccountSettingsPwasMutation.isLoading;
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(HeadContent, { title: "Mobile app" }),
        React.createElement(Loading, { isLoading: isLoading },
            React.createElement(Subsection, { title: "Mobile app" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            "We automatically create an app for you that your members can install to receive notifications about new posts. Instructions for installing the app will show up when members visit your site on their phone. Apps will not show up in the Apple or Android app stores.",
                            ' ',
                            React.createElement(ButtonClean, { href: "https://help.fourthwall.com/hc/en-us/articles/20092051359003", label: "Learn more", target: "_blank", withUnderline: true }))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(S.Text, null, "App name:")),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(RadioButton, { checkedValue: "default", label: `Same as site name (${currentShopQuery.data?.name})`, ...formik.getFieldProps(FormFields.appNameSetting) }))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(RadioButton, { checkedValue: "custom", label: "Custom name", ...formik.getFieldProps(FormFields.appNameSetting) }))),
                    formik.values.appNameSetting === 'custom' && (React.createElement(Fieldset.Row, { inner: true },
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Input, { label: "Custom app name", ...formik.getFieldProps(FormFields.appName) })))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(S.PreviewContainer, null,
                            React.createElement(S.Text, null, "Bottom bar preview:"),
                            React.createElement(Bar, { title: formik.values.appNameSetting === 'custom'
                                    ? formik.values.appName || currentShopQuery.data?.name
                                    : currentShopQuery.data?.name }),
                            React.createElement(S.Text, null,
                                "Members will see app installation instructions at the bottom when browsing your site on their phone. Your app icon is the same as your",
                                ' ',
                                React.createElement(ButtonClean, { label: "favicon", to: reverse(routing.themeEditor.general.sections, { sectionId: 0 }), withUnderline: true, appearance: "secondary" }),
                                ".")))))),
        React.createElement(LeavingGuard, { when: formik.dirty || isLoading }),
        React.createElement(ConfirmBarContainer, { isOpen: formik.dirty, isLoading: isLoading, onConfirm: formik.submitForm, onCancel: formik.resetForm })));
};
