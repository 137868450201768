import { getImageDirectUploadData } from '../../../../../utils/getImageDirectUploadData';
import { getCommonWelcomePostPayload } from './getCommonWelcomePostPayload';
export const getVideoWelcomePostPayload = async (welcomePost) => {
    return {
        type: 'Video',
        attributes: getCommonWelcomePostPayload(welcomePost),
        imageDirectUploadData: typeof welcomePost.videoThumbnail === 'object'
            ? await getImageDirectUploadData(welcomePost.videoThumbnail)
            : undefined,
        new_media_direct_upload: typeof welcomePost.video === 'object',
        remove_thumbnail: welcomePost.videoThumbnail === undefined,
    };
};
