import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './List.styled';
import { Loader } from '../Loader';
import { Loading } from '../Loading';
export const List = ({ data, isLoading, emptyComponent, locationState, topBorder = true, renderItem, getItemLink, getItemHighlighted, onClick, }) => {
    if (data.length === 0) {
        if (isLoading)
            return (React.createElement(S.StateContainer, null,
                React.createElement(Loader, null)));
        if (emptyComponent) {
            return React.createElement(S.StateContainer, null, emptyComponent);
        }
        return null;
    }
    return (React.createElement(Loading, { isLoading: isLoading },
        React.createElement(S.Container, null, data.map((item, index) => (React.createElement(S.ListItem, { "data-testid": "ListItem", key: typeof item === 'object' && 'id' in item ? item.id : index, as: getItemLink ? Link : 'div', to: getItemLink && getItemLink(item), state: locationState, topBorder: topBorder, highlighted: getItemHighlighted && getItemHighlighted(item), onClick: onClick && (() => onClick(item, index)) }, renderItem(item, index)))))));
};
