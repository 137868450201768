import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 480px;
  text-align: center;
  gap: 24px;
  padding: 20px;
`;
export const HeadingText = styled.span `
  color: ${Colors.GS0};
`;
export const Button = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
