import { Button, ModalBox } from '@fourthwall/components';
import React from 'react';
export const EmptyModal = ({ onCreateClick, close }) => {
    return (React.createElement(ModalBox, { title: "You don\u2019t have any video series created", buttons: [
            React.createElement(Button, { label: "Create video series", onClick: () => {
                    close();
                    onCreateClick();
                } }),
            React.createElement(Button, { label: "Close", appearance: "primary", onClick: close }),
        ], size: "small", onClose: close }));
};
