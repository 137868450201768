import React from 'react';
import { BackButton } from '@fourthwall/components';
import { isWebView } from '@fourthwall/utils';
import { useFocusFirstErrorOnSubmit } from '@fourthwall/utils/lib/formik';
import { Dashboard, PageLayout, SideSummary } from '@components/common';
import { routing } from '@utils/routing';
import { useTiers } from '../../../Perks/hooks/useTiers';
import { PostAccess } from '../../modules/PostAccess';
import { PostTypeFields } from '../../modules/PostTypeFields';
import { TagsFields } from '../../modules/TagsFields';
import * as S from './PostCreateLayout.styled';
export const PostCreateLayout = ({ disabled, children }) => {
    const { tiers } = useTiers();
    useFocusFirstErrorOnSubmit();
    if (!tiers) {
        return null;
    }
    return (React.createElement(PageLayout, { rightContent: React.createElement(SideSummary, null,
            React.createElement(PostAccess, { tiers: tiers, showNotification: true, disabled: disabled })), leftContent: React.createElement(React.Fragment, null,
            React.createElement(PostTypeFields, { disabled: disabled }),
            children,
            React.createElement(TagsFields, { disabled: disabled })) },
        React.createElement(S.HeaderContainer, null,
            React.createElement(Dashboard.ItemViewHeader, { name: "Create a new post", backButton: isWebView() ? undefined : (React.createElement(BackButton, { label: "Posts", to: routing.memberships.posts.self })) }))));
};
