import Yup from '@fourthwall/utils/lib/yup';
import dayjs from 'dayjs';
const formatMonth = (month) => dayjs()
    .month(parseInt(month) - 1)
    .format('MM');
const formatYear = (year) => dayjs().year(parseInt(year)).format('YY');
export const formatExpiresAt = (expires) => {
    return `${formatMonth(expires.month)}/${formatYear(expires.year)}`;
};
export const getChargeLimitValidationSchema = (setChargeLimitFieldName = 'setChargeLimit', chargeLimitFieldName = 'chargeLimit') => ({
    [setChargeLimitFieldName]: Yup.boolean(),
    [chargeLimitFieldName]: Yup.number().when(setChargeLimitFieldName, {
        is: true,
        then: Yup.number().required().min(0),
    }),
});
export const getPaymentMethodProps = (paymentMethod) => {
    const result = [];
    if (paymentMethod.default) {
        result.push({
            label: 'Default',
            appearance: 'neutral',
        });
    }
    if (paymentMethod.status === 'FAILED') {
        result.push({
            label: 'Failed',
            appearance: 'alert',
        });
    }
    return result;
};
export const isPaymentMethodFailed = (paymentMethod) => paymentMethod.status === 'FAILED';
export const getDefaultPaymentMethod = (paymentMethods) => paymentMethods?.find((paymentMethodProps) => paymentMethodProps.default);
export const mapPaymentMethods = (response) => response.result.map((paymentMethod) => {
    if (paymentMethod.details.type === 'CARD') {
        return {
            id: paymentMethod.id,
            type: 'CARD',
            brand: paymentMethod.details.brand,
            number: paymentMethod.details.last4Digits,
            expires: formatExpiresAt(paymentMethod.details.expiresAt),
            expired: paymentMethod.details.expired,
            tagsProps: getPaymentMethodProps(paymentMethod),
            default: paymentMethod.default,
            invalid: isPaymentMethodFailed(paymentMethod),
        };
    }
    // PAYPAL
    return {
        id: paymentMethod.id,
        type: 'PAYPAL',
        email: paymentMethod.details.email,
        tagsProps: getPaymentMethodProps(paymentMethod),
        default: paymentMethod.default,
        invalid: isPaymentMethodFailed(paymentMethod),
    };
});
