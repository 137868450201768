import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  padding: 12px 20px;
  flex-direction: column;
  display: flex;
  gap: 20px;
  font-size: 16px;
`;
export const Heading = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Description = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
`;
