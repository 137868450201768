import { DesktopIcon, MobileIcon } from '@fourthwall/icons';
import React from 'react';
import { ThemeStatus } from '../../../ThemeStatus';
import { IconSwitch } from '../../../../components/IconSwitch';
import { ToolbarContent } from '../../../../components/ToolbarContent';
export const Toolbar = ({ previewMode, isThemeMain, onPreviewModeChange }) => {
    return (React.createElement(ToolbarContent, { centerContent: React.createElement(IconSwitch, { items: [
                {
                    label: 'Desktop',
                    value: 'desktop',
                    icon: DesktopIcon,
                },
                {
                    label: 'Mobile',
                    value: 'mobile',
                    icon: MobileIcon,
                },
            ], value: previewMode, onChange: (id) => onPreviewModeChange(id) }), rightContent: React.createElement(ThemeStatus, { isActive: isThemeMain }) }));
};
