import styled from 'styled-components';
export const Buttons = styled.div `
  padding-top: 12px;
  display: flex;
`;
export const ButtonContainer = styled.div `
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
`;
