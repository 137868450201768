import { Checkbox, Fieldset, InputUI, Select } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React, { useMemo } from 'react';
import { useDiscord } from '@modules/Dashboard/Apps/modules/AppsDiscord';
import { Tag } from '../../../../../components/Tag';
import { FREE_TIER_ID } from '../../../consts';
import { useFreeTierQuery } from '../../../hooks/useFreeTierQuery';
import { useTiersQuery } from '../../../hooks/useTiersQuery';
import { FormFields } from '../../types';
import * as S from './styled';
export const TiersFields = () => {
    const { discord } = useDiscord();
    const { tiersQuery } = useTiersQuery();
    const { freeTierQuery } = useFreeTierQuery();
    const { values, errors, setFieldValue } = useFormikContext();
    const tiers = useMemo(() => {
        if (!freeTierQuery.data)
            return [];
        if (!tiersQuery.data)
            return [];
        if (freeTierQuery.data.status === 'Hidden') {
            return tiersQuery.data.tiers.sort((a, b) => a.position - b.position);
        }
        // Note: Paid and free tier could have the same id, so we need to add a unique id to the free tier
        return [...tiersQuery.data.tiers, { ...freeTierQuery.data, id: FREE_TIER_ID }].sort((a, b) => a.position - b.position);
    }, [freeTierQuery.data, tiersQuery.data]);
    const getDiscordRoleSelectOptions = (tierId) => {
        if (discord.roles) {
            const filteredRoles = [...discord.roles].filter((role) => {
                if (values.selectedRoles[tierId].indexOf(role.id) === -1)
                    return true;
                return false;
            });
            return [
                { label: 'Add new role', value: 'Add new role', disabled: true },
                ...filteredRoles.map((role) => ({
                    label: role.name,
                    value: role.id,
                    key: role.id,
                })),
            ];
        }
        return [];
    };
    const renderDiscordRoleSelect = (tierId) => {
        if (discord.roles && values.type === 'Discord') {
            const fieldName = `${FormFields.selectedRoles}.${tierId}`;
            const handleAddRole = (role) => {
                setFieldValue(fieldName, [...values.selectedRoles[tierId], role]);
            };
            const handleRemoveRole = (role) => {
                setFieldValue(fieldName, values.selectedRoles[tierId].filter((selectedRole) => selectedRole !== role));
            };
            return (React.createElement(S.RolesContainer, null,
                React.createElement(Select, { label: "Add new role", options: getDiscordRoleSelectOptions(tierId), value: "", disabled: discord.isLoading, onValueChange: handleAddRole }),
                React.createElement(S.DiscordRoles, null, values.selectedRoles[tierId].map((roleId) => {
                    const role = discord.roles?.find((role) => role.id === roleId);
                    return (React.createElement(Tag, { label: role?.name ? role.name : 'Role removed from server', disabled: discord.isLoading, onRemove: () => handleRemoveRole(roleId) }));
                }))));
        }
    };
    const renderTiers = () => tiers.map((tier) => {
        const isFreeTierDisabled = values.type !== 'Custom' && tier.id === FREE_TIER_ID;
        return (React.createElement(React.Fragment, null,
            React.createElement(Field, { component: Checkbox, name: `${FormFields.selectedTiers}.${tier.id}`, label: tier.name, checked: isFreeTierDisabled ? false : values.selectedTiers?.[tier.id], native: true, disabled: (values.type === 'Discord' && discord.isLoading) || isFreeTierDisabled, tooltip: isFreeTierDisabled ? "This perk can't be added to a free tier." : undefined }),
            values.selectedTiers?.[tier.id] &&
                tier.id !== FREE_TIER_ID &&
                renderDiscordRoleSelect(tier.id)));
    });
    return (React.createElement(Fieldset.Row, null,
        React.createElement(Fieldset.Item, null,
            React.createElement(S.TiersLabel, null, "Included in tiers:"),
            React.createElement(S.TiersContainer, null, renderTiers()),
            React.createElement(S.Errors, null,
                errors.selectedTiers && React.createElement(InputUI.Error, null, errors.selectedTiers),
                errors.selectedRoles && React.createElement(InputUI.Error, null, errors.selectedRoles)))));
};
