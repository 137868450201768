import styled from 'styled-components';
export const HeaderContainer = styled.div `
  margin-bottom: 18px;
`;
export const PinnedInfo = styled.div `
  display: grid;

  grid-auto-flow: column;
  gap: 6px;
  align-items: center;
  justify-content: start;

  margin-top: 4px;
`;
