import styled from 'styled-components';
export const Form = styled.form `
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const FormRow = styled.div `
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;
