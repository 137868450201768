import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
const SIZES = {
    small: 32,
    medium: 40,
    regular: 48,
};
const getSize = ({ size }) => SIZES[size];
export const Container = styled.div `
  display: flex;

  justify-content: center;
  align-items: center;

  border-radius: 50%;

  width: ${getSize}px;
  height: ${getSize}px;

  ${({ hoverable }) => hoverable &&
    css `
      cursor: pointer;

      &:hover {
        outline: 3px solid ${Colors.GS500};
      }
    `}

  ${({ active }) => active &&
    css `
      &,
      &:hover {
        outline: 3px solid ${Colors.GS1000};
      }
    `}
`;
export const EmptyContainer = styled(Container) `
  border: 1px dashed ${Colors.GS600};

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: ${Colors.GS600};
`;
export const Image = styled.img `
  border-radius: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const FONT_SIZES = {
    small: 11,
    medium: 14,
    regular: 14,
};
export const Initials = styled.div `
  position: relative;
  top: 1px;

  font-size: ${({ size }) => `${FONT_SIZES[size]}px`};
  font-weight: 700;
  line-height: 150%;
  color: ${Colors.GS0};

  ${({ size }) => size === 'small' &&
    css `
      top: 0;
    `}
`;
