import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { Colors } from '../../../colors';
import { getAutomatedStyleProp } from '../../../providers/ComponentsThemeProvider';
const getContainerSize = ({ size }) => {
    if (size === 'small')
        return 20;
    if (size === 'medium')
        return 24;
    if (size === 'large')
        return 32;
};
const getOutlineColor = ({ theme }) => {
    if (theme.automated?.styles?.colorPrimary) {
        return tinycolor(theme.automated?.styles.colorPrimary).setAlpha(0.4).toString();
    }
    return tinycolor(Colors.B500).setAlpha(0.4).toString();
};
export const Container = styled.div `
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${getContainerSize}px;
  min-width: ${getContainerSize}px;
  height: ${getContainerSize}px;
  min-height: ${getContainerSize}px;
  background: ${getAutomatedStyleProp('paleBackground', Colors.GS0)};
  border: 1px solid ${getAutomatedStyleProp('paleBackground', Colors.GS500)};
  color: transparent;

  input:checked + & {
    background: ${getAutomatedStyleProp('colorPrimary', Colors.B500)};
    border-color: ${getAutomatedStyleProp('colorPrimary', Colors.B500)};
    color: ${getAutomatedStyleProp('colorOnPrimary', Colors.GS100)};
  }

  input:disabled + & {
    cursor: not-allowed;
    background: ${Colors.GS400};
    border-color: ${Colors.GS500};
  }

  input:checked:disabled + & {
    background: ${Colors.GS400};
    color: ${Colors.GS500};
  }

  input:focus-visible + & {
    outline: 2px solid ${getOutlineColor};
    outline-offset: 1px;
  }

  input:indeterminate + & {
    background: ${getAutomatedStyleProp('colorPrimary', Colors.B500)};
    border-color: ${getAutomatedStyleProp('colorPrimary', Colors.B500)};
    color: ${getAutomatedStyleProp('colorOnPrimary', Colors.GS100)};
  }
`;
