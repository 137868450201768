import { Banner, ButtonClean, ButtonGroup, CurrencyInput, Loading, Subsection, useMediaQueryContext, } from '@fourthwall/components';
import React from 'react';
import { PRODUCT_PRICE_MAX } from '@fourthwall/utils/lib/constants';
import { Field } from 'formik';
import { ImplicitSellingPrice } from './components/ImplicitSellingPrice';
import { ExplicitSellingPrice } from './components/ExplicitSellingPrice';
import * as S from './styled';
import { CompareAtPriceCheckboxField } from '../components/CompareAtPriceCheckboxField';
export const ProductPricingView = ({ allowExplicitType, onlyExplicitTypeAvailable, implicitSellingPriceError, isAdmin, isLoading, profit, pricingType, sizePriceSuggestion, data, tableHeaders, costsLink, showMissingCostsBanner, disabled, isCompareAtPriceEnabled, applyToAllVariants, onToggleView, onLearnMoreClick, onPriceBlur, onCompareAtPriceBlur, }) => {
    const media = useMediaQueryContext();
    const isExplicit = pricingType === 'EXPLICIT';
    // TODO: until we don't have implemented modal "HowToCalculateProfit" for PER_SIZE products
    // we should hide this button for them
    const showHowToCalculateProfitButton = isExplicit;
    const renderSellingPriceContent = () => {
        if (showMissingCostsBanner) {
            const adminButton = React.createElement(ButtonClean, { label: "Set it up", to: costsLink });
            return (React.createElement(Banner, { appearance: "warning" },
                "Product is missing costs. ",
                isAdmin ? adminButton : 'Contact your administrator.'));
        }
        if (isExplicit) {
            return (React.createElement(ExplicitSellingPrice, { data: data, tableHeaders: tableHeaders, applyToAllVariants: applyToAllVariants, isCompareAtPriceEnabled: isCompareAtPriceEnabled }));
        }
        return (React.createElement(ImplicitSellingPrice, { allowExplicitType: allowExplicitType, fieldName: "sellingPriceValue", error: implicitSellingPriceError, sizePriceSuggestion: sizePriceSuggestion, profit: profit, disabled: disabled, onCustomizeClick: onToggleView, onLearnMoreClick: onLearnMoreClick, onBlur: (e) => onPriceBlur(e.target.value) }));
    };
    const renderTypeSwitchButton = () => {
        if (!allowExplicitType || disabled)
            return null;
        if (pricingType === 'IMPLICIT')
            return null;
        return (React.createElement(S.ButtonContainer, { small: !isExplicit },
            React.createElement(ButtonGroup, { space: "large", direction: media.tablet ? 'row' : 'column' },
                onlyExplicitTypeAvailable ? null : (React.createElement(ButtonClean, { appearance: isExplicit ? 'primary' : 'secondary', label: "Switch back to simple product pricing", onClick: onToggleView })),
                showHowToCalculateProfitButton && !disabled && (React.createElement(ButtonClean, { label: "How we calculate profits", onClick: onLearnMoreClick })))));
    };
    const renderCompareAtPriceCheckbox = () => (React.createElement(S.CompareAtPriceCheckboxContainer, null,
        React.createElement(CompareAtPriceCheckboxField, { name: "isCompareAtPriceEnabled" })));
    const renderCompareAtPriceInput = () => {
        if (!isCompareAtPriceEnabled || isExplicit)
            return null;
        return (React.createElement(S.CompareAtPriceInputContainer, null,
            React.createElement(Field, { component: CurrencyInput, name: "compareAtPriceValue", label: "Compare-at price", strikethrough: true, fullWidth: true, min: 0, max: PRODUCT_PRICE_MAX, onBlur: (e) => onCompareAtPriceBlur(e.target.value) })));
    };
    return (React.createElement(Subsection, { title: "Selling price", borderless: isExplicit },
        React.createElement(Loading, { isLoading: isLoading },
            renderSellingPriceContent(),
            renderTypeSwitchButton(),
            renderCompareAtPriceCheckbox(),
            renderCompareAtPriceInput())));
};
