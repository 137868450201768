import styled, { css, keyframes } from 'styled-components';
import { Colors } from '../../colors';
const getDimension = (dimension) => {
    if (typeof dimension === 'string')
        return dimension;
    if (typeof dimension === 'number')
        return `${dimension}px`;
};
const animationPulse = keyframes `
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;
export const Skeleton = styled.div `
  display: grid;
  gap: ${({ gap }) => gap}px;

  ${({ aligment }) => aligment === 'horizontal' &&
    css `
      grid-auto-columns: min-content;
      grid-auto-flow: column;
    `}
`;
export const Shape = styled.div `
  background-color: ${Colors.GS300};
  background-size: 300% 300%;
  animation: ${animationPulse} 1s ease-in-out infinite alternate;
  width: ${({ $width }) => getDimension($width)};
  height: ${({ $height }) => getDimension($height)};

  ${({ radius }) => radius &&
    css `
      border-radius: ${radius};
    `}
`;
