import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Wrapper = styled.div `
  border: 1px solid ${Colors.GS300};
  background-color: ${Colors.GS0};
  padding: 40px;

  p {
    margin-bottom: 24px;
  }

  .container {
    background-color: ${Colors.GS0} !important;
  }
`;
