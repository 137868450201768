import React, { createRef, useRef } from 'react';
import { TickIcon } from '@fourthwall/icons';
import { InputUI } from '../../ui';
import { Icon } from '../../common/Icon';
import * as S from './styled';
import { withFormikSupport } from '../../hocs/withFormikSupport';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
const GridSelectComponent = ({ name, value, options, error, disabled, numberOfColumns = 3, onChange, }) => {
    const media = useMediaQueryContext();
    const inputsRef = useRef(options.map(() => createRef())).current;
    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter') {
            inputsRef[index].current?.click();
        }
    };
    const getEmptyOptions = (numberOfColumns, optionsLength) => {
        const remainder = optionsLength % numberOfColumns;
        return remainder ? [...Array(numberOfColumns - remainder).keys()] : [];
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Container, { numberOfColumns: numberOfColumns, error: !!error },
            options.map((option, index) => {
                const id = `${name}.${option.value}`;
                return (React.createElement(S.Option, { key: option.value, active: option.value === value, "data-selected": option.value === value, tabIndex: 0, htmlFor: id, disabled: disabled, onKeyDown: (event) => handleKeyDown(event, index) },
                    React.createElement(S.Input, { id: id, ref: inputsRef[index], "aria-hidden": "true", type: "radio", name: name, value: option.value, checked: option.value === value, disabled: disabled, onChange: onChange }),
                    option.value === value && (React.createElement(S.ActiveTickContainer, { "data-testid": "GridSelect.Tick" },
                        React.createElement(Icon, { component: TickIcon, height: media.tablet ? 8 : 6 }))),
                    option.label));
            }),
            getEmptyOptions(numberOfColumns, options.length).map((option) => {
                return React.createElement(S.Option, { key: `empty${option}`, as: "div", empty: true, "data-testid": "GridSelect.Empty" });
            })),
        !!error && React.createElement(InputUI.Error, null, error)));
};
export const GridSelect = withFormikSupport(GridSelectComponent);
