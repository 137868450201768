import { Toasts } from '@fourthwall/components';
import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import { useQuery, useQueryClient } from '@tanstack/react-query';
const queryKey = 'useWelcomePostsQuery';
export const useWelcomePostsQuery = () => {
    const queryClient = useQueryClient();
    const welcomePostsQuery = useQuery({
        queryKey: [queryKey],
        queryFn: async () => {
            const { data } = await MembershipsCreatorApiClient.getWelcomePosts();
            return data;
        },
        refetchInterval: (data) => data?.posts.some((post) => post.statuses.resources === 'Processing') ? 2000 : false,
        onError: (error) => {
            if (error.response?.status === 404) {
                // Clear welcome posts when user removes it
                queryClient.setQueryData([queryKey], undefined);
            }
            if (error.response?.status !== 404) {
                const errorMessage = errorMessageSelector(error.response?.data);
                Toasts.notify(errorMessage, { type: 'error' });
            }
        },
    });
    return {
        welcomePostsQuery,
    };
};
