import { ApiBase } from '../ApiBase';
class MembershipsCreatorV2ApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.createImagePost = (requestBody) => {
            return this.post('/creators/api/v2/posts/images', requestBody);
        };
        this.updateImagePost = (params, requestBody) => {
            return this.put(`/creators/api/v2/posts/images/${params.id}`, requestBody);
        };
        this.getTierPerks = (query) => {
            const getQuery = () => {
                if (!query)
                    return '';
                if (query.tier.type === 'free')
                    return `tier[type]=free`;
                return `tier[id]=${query.tier.id}&tier[type]=paid`;
            };
            return this.get(`/creators/api/v2/perks/tiers?${getQuery()}`);
        };
        this.reorderTierPerks = (params, query, body) => {
            const getQuery = () => {
                if (!query)
                    return '';
                if (query.tier.type === 'free')
                    return `tier[type]=free`;
                return `tier[type]=paid&tier[id]=${query.tier.id}`;
            };
            return this.post(`/creators/api/v2/perks/tiers/reorder/${params.id}?${getQuery()}`, body);
        };
    }
}
export const MembershipsCreatorV2ApiClient = new MembershipsCreatorV2ApiClientModel('/');
