import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  flex: 1;

  height: 100px;
  overflow-y: auto;
`;
export const Footer = styled.div `
  margin-top: 24px;
  padding: 0 20px 20px;

  ${mediaQueries.laptop} {
    padding: 0 24px 24px;
  }
`;
export const ButtonContainer = styled.div `
  padding: 0 12px;
`;
