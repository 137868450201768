export const getSetCurrentShopConfigChargeLimitPayload = (values) => {
    if (!values.setChargeLimit) {
        return {
            chargePolicy: {
                type: 'NO_LIMIT',
            },
        };
    }
    return {
        chargePolicy: {
            type: 'LIMITED',
            amount: { currency: 'USD', value: parseFloat(values.chargeLimit) },
        },
    };
};
export const getSetupTitle = (inProgress, partnerName) => {
    if (inProgress) {
        return 'Payouts set up in progress...';
    }
    if (partnerName) {
        return `At the start of each month we will transfer your earnings to the connected bank account managed by ${partnerName}.`;
    }
    return 'At the start of each month we will transfer your earnings to the connected bank account.';
};
export const getSetupBy = (email, name) => {
    return name ? `${name} (${email})` : email;
};
