export const INSERTER = 'inserter';
export const getToolbar = (buttons) => {
    const blocks = buttons.blocks ? 'blocks' : '';
    const strikethrough = ''; // permanently disabled for now
    const table = buttons.table ? 'table' : '';
    const code = buttons.code ? 'code' : '';
    const fullscreen = buttons.fullscreen ? 'fullscreen' : '';
    const alignment = buttons.alignment ? 'alignleft aligncenter alignright alignjustify' : '';
    const image = buttons.image ? 'image' : '';
    const customImage = buttons.customImage ? 'customImage' : '';
    return `${blocks} bold italic underline ${strikethrough} bullist numlist ${image} ${customImage} ${alignment} link ${table} ${INSERTER} ${code} ${fullscreen}`;
};
export const setupInserter = (editor, inserterOptions) => {
    if (inserterOptions && inserterOptions.length) {
        editor.ui.registry.addMenuButton(INSERTER, {
            text: 'Insert',
            icon: 'addtag',
            fetch: (success) => {
                const menu = inserterOptions.map((option) => ({
                    type: 'menuitem',
                    text: option.label,
                    onAction: () => editor.insertContent(option.text),
                }));
                success(menu);
            },
        });
    }
};
export const mapValidElementsToString = (validElements) => {
    const elements = Object.keys(validElements).map((key) => {
        const attributes = validElements[key];
        return attributes === true ? key : `${key}[${attributes.join('|')}]`;
    });
    return elements.join(',');
};
export const mapValidStyles = (validStyles) => ({
    '*': validStyles.map((item) => item.replace('()', '')).join(','),
});
