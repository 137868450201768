import { Button, Fieldset, Select } from '@fourthwall/components';
import { Field, Formik } from 'formik';
import React from 'react';
import { ConfigurationFormValidationSchema } from './consts';
export const ConfigurationForm = ({ initialValues, shopOptions, warehouseOptions, isLoading, onShopSelect, onSubmit, }) => {
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: ConfigurationFormValidationSchema, enableReinitialize: true, validateOnMount: true, onSubmit: onSubmit }, (formik) => (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { component: Select, name: "shopId", options: shopOptions, label: "Select your TikTok Shop", disabled: !shopOptions.length, displayError: false, onValueChange: onShopSelect }))),
            !formik.values.shopId ||
                (!!warehouseOptions?.length && warehouseOptions?.length > 0 && (React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { component: Select, name: "warehouseId", options: warehouseOptions, label: "Select warehouse", displayError: false })))))),
        React.createElement("div", { style: { marginTop: 16 } },
            React.createElement(Button, { type: "submit", label: "Confirm selection", appearance: "primary", disabled: !formik.isValid, loading: isLoading }))))));
};
