import { useAuth } from '@fourthwall/auth';
import { Banner, Button, ButtonClean, ButtonGroup, Heading, useMediaQueryContext, } from '@fourthwall/components';
import { captureMessage } from '@fourthwall/sentry';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../utils/routing';
import { useCurrentShop } from '../hooks/useCurrentShop';
import { MembershipsSplashPageSkeleton } from './MembershipsSplashPageSkeleton';
import { FEATURES } from './consts';
import { useCheckMembershipsEnabledMutation } from './hooks/useCheckMembershipsEnabledMutation';
import { useEnableMembershipsMutation } from './hooks/useEnableMembershipsMutation';
import * as S from './styled';
export const MembershipsSplashPage = () => {
    const { user } = useAuth();
    const { enableMembershipsMutation } = useEnableMembershipsMutation();
    const { checkMembershipsEnabled } = useCheckMembershipsEnabledMutation();
    const { currentShop, currentShopQuery } = useCurrentShop();
    const navigate = useNavigate();
    const media = useMediaQueryContext();
    const [state, setState] = useState('IDLE');
    if (!user?.email || !currentShop?.id) {
        return React.createElement(MembershipsSplashPageSkeleton, null);
    }
    const handleEnableMembershipsSuccess = () => {
        checkMembershipsEnabled.mutate(undefined, {
            onSuccess: async () => {
                await currentShopQuery.refetch();
                navigate(routing.memberships.overview);
            },
            onError: () => {
                setState('ERROR');
                captureMessage("Couldn't enable memberships in expected time", {
                    extra: { shopId: currentShop.id },
                });
            },
        });
    };
    const handleEnableMemberships = () => {
        setState('LOADING');
        enableMembershipsMutation.mutateAsync([
            {
                shopId: currentShop.id,
            },
            {
                enabled: true,
            },
        ], {
            onSuccess: handleEnableMembershipsSuccess,
            onError: () => setState('ERROR'),
        });
    };
    const renderButtons = () => {
        if (state === 'IDLE' || state === 'LOADING') {
            return (React.createElement(ButtonGroup, { space: "large", direction: media.tablet ? 'row' : 'column', fullWidth: media.tablet },
                React.createElement(Button, { appearance: "primary", label: "Add memberships to your site", loading: state === 'LOADING', onClick: handleEnableMemberships, fullWidth: !media.tablet }),
                React.createElement(Button, { label: "Learn more", href: "https://fourthwall.com/memberships", target: "_blank" })));
        }
        return null;
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.HeadingContainer, null,
            React.createElement(Heading, { type: "h5", weight: "semibold" }, "Memberships your community will love")),
        React.createElement(S.SubheadingContainer, null,
            "Allow fans to support you with memberships. Create tiers and offer exclusive content, perks and more...",
            ' ',
            React.createElement(ButtonClean, { label: "Learn more", href: "https://fourthwall.com/memberships", target: "_blank" })),
        React.createElement(S.VideoContainer, null,
            React.createElement("iframe", { width: "100%", height: "100%", src: "https://www.youtube.com/embed/HffvS7if72s?si=2gBIKilAj7pbUOkN&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3&controls=20&showinfo=0", title: "YouTube video player", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", referrerPolicy: "strict-origin-when-cross-origin", allowFullScreen: true })),
        React.createElement(S.ButtonsContainer, null,
            renderButtons(),
            state === 'ERROR' && (React.createElement(Banner, { appearance: "danger" }, "Something went wrong. Please refresh page and try again."))),
        React.createElement(S.Features, null, FEATURES.map(({ icon, label }) => (React.createElement(S.Feature, null,
            React.createElement(S.FeatureIcon, null, icon),
            label))))));
};
