import { Button, ButtonClean, PriceString, ProductsList, Tag } from '@fourthwall/components';
import { MOBILE_APP_ANDROID_LINK, MOBILE_APP_IOS_LINK } from '@fourthwall/utils/lib/links';
import { reverse, routing } from '@utils/routing';
import React, { useEffect, useRef, useState } from 'react';
import downloadAppQrcodeIcon from '../../../../../../../assets/donwload-app-qrcode.svg';
import AndroidIcon from './assets/Android.svg';
import iOSIcon from './assets/iOS.svg';
import * as S from './styled';
export const Sidebar = ({ thankYou }) => {
    const scrollableContentRef = useRef(null);
    const [scrollableContentHeight, setScrollableContentHeight] = useState(undefined);
    useEffect(() => {
        if (scrollableContentRef.current) {
            const rect = scrollableContentRef.current.getBoundingClientRect();
            setScrollableContentHeight(rect.height);
        }
    }, []);
    const getLink = () => {
        switch (thankYou.contributionId.type) {
            case 'ORDER':
                return reverse(routing.contributions.orders.order.self, {
                    orderId: thankYou.contributionId.orderId,
                });
            case 'DONATION':
                return reverse(routing.contributions.orders.donations.donation.self, {
                    donationId: thankYou.contributionId.donationId,
                });
            case 'GIVEAWAY':
                return reverse(routing.contributions.orders.giveaways.giveaway, {
                    giveawayId: thankYou.contributionId.giveawayId,
                });
        }
    };
    const renderTags = () => {
        if (!thankYou.tags.length) {
            return null;
        }
        return (React.createElement(S.TagsContainer, null, thankYou.tags.map((tag, index) => (React.createElement(Tag, { key: index, label: tag, size: "tiny", display: "inline-flex" })))));
    };
    const renderProducts = () => {
        if (!thankYou.offers.length && !thankYou.donation) {
            return null;
        }
        return (React.createElement(ProductsList, null, thankYou.offers.map((offer, index) => (React.createElement(ProductsList.Item, { key: index, image: offer.thumbnailImageUrl, name: offer.title, size: "small" })))));
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.Content, null,
            React.createElement(S.Section, null,
                React.createElement(S.SectionHeader, null, "Send thank you to"),
                React.createElement(S.Header, null, thankYou.supporter.fullName)),
            thankYou.state === 'SENT' && thankYou.thankYous[0] && (React.createElement(S.Section, null,
                React.createElement(Button, { label: "Download recording", href: thankYou.thankYous[0].mediaUrl, download: `${thankYou.thankYous[0].id}.${thankYou.thankYous[0].mediaType.subtype}`, target: "_blank", fullWidth: true }))),
            React.createElement(S.ScrollableContentContainer, { ref: scrollableContentRef }, !!scrollableContentHeight && (React.createElement(S.ScrollableContent, { maxHeight: scrollableContentHeight },
                React.createElement(S.Section, null,
                    React.createElement(S.SectionHeader, null, "Message left"),
                    React.createElement(S.Message, null, thankYou.message ? `"${thankYou.message}"` : '-')),
                React.createElement(S.Section, null,
                    React.createElement(S.SectionHeader, null,
                        "Placed order for ",
                        React.createElement(PriceString, { ...thankYou.total }),
                        React.createElement(S.DetailsButtonContainer, null,
                            React.createElement(ButtonClean, { label: "View details", appearance: "secondary", to: getLink(), target: "_blank" }))),
                    renderTags(),
                    renderProducts()))))),
        React.createElement(S.Footer, null,
            React.createElement(S.DownloadAppQrcodeIcon, { src: downloadAppQrcodeIcon, alt: "Download app" }),
            React.createElement(S.FooterContent, null,
                "You can also send thank you notes using our app for",
                ' ',
                React.createElement(S.PlatformLink, { href: MOBILE_APP_IOS_LINK, target: "_blank", rel: "noreferrer" },
                    React.createElement(S.PlatformIcon, { src: iOSIcon, alt: "iOS" }),
                    "iOS"),
                ' ',
                "or",
                ' ',
                React.createElement(S.PlatformLink, { href: MOBILE_APP_ANDROID_LINK, target: "_blank", rel: "noreferrer" },
                    React.createElement(S.PlatformIcon, { src: AndroidIcon, alt: "iOS" }),
                    "Android"),
                "."))));
};
