import { List, Tag } from '@fourthwall/components';
import { routing } from '@utils/routing';
import { reverse } from 'named-urls';
import React from 'react';
import * as S from './InventoryShipment.styled';
import { OfferPreview } from './OfferPreview/OfferPreview';
const TAG_APPEARANCE_MAP = {
    ACTION_REQUIRED: 'alert',
    CANCELLED: 'caution',
    ITEMS_RECEIVED: 'success',
    RECEIVING_IN_PROGRESS: 'neutral',
    WAITING_FOR_DELIVERY: 'neutral',
    WAITING_FOR_TRACKING: 'neutral',
};
const TAG_LABEL_MAP = {
    ACTION_REQUIRED: 'Action required',
    CANCELLED: 'Canceled',
    ITEMS_RECEIVED: 'Items received',
    RECEIVING_IN_PROGRESS: 'Receiving',
    WAITING_FOR_DELIVERY: 'Waiting for delivery',
    WAITING_FOR_TRACKING: 'Waiting for tracking',
};
const countItemsInShipment = (shipment) => {
    let itemCount = 0;
    shipment.offers.forEach((offer) => {
        offer.variants.forEach((variant) => {
            itemCount += Number(variant.declaredQuantity);
        });
    });
    return itemCount;
};
const firstAvailableImageUrl = (shipment) => {
    return shipment.offers.find((offer) => offer.imageUrl)?.imageUrl || '';
};
export const InventoryShipment = ({ data, isLoading }) => {
    return (React.createElement(List, { data: data, topBorder: false, isLoading: isLoading, getItemLink: (shipment) => ({
            pathname: reverse(routing.settings.inventoryShipments.self, {
                shippingId: shipment.id,
            }),
            search: location.search,
        }), renderItem: (shipment) => (React.createElement(S.ShipmentContainer, null,
            React.createElement(S.Info, null,
                React.createElement(OfferPreview, { image: firstAvailableImageUrl(shipment), imagesCount: shipment.offers.length }),
                React.createElement(S.ShipmentDetails, null,
                    React.createElement(S.ShipmentName, null,
                        "Shipment #",
                        shipment.friendlyId),
                    React.createElement(S.ShipmentItemsQuantity, null,
                        countItemsInShipment(shipment),
                        " items"))),
            React.createElement(Tag, { label: TAG_LABEL_MAP[shipment.status], appearance: TAG_APPEARANCE_MAP[shipment.status] }))) }));
};
