import React from 'react';
import * as S from './styled';
import videoIcon from './assets/video.svg';
import audioIcon from './assets/audio.svg';
import stopIcon from './assets/stop.svg';
import sendIcon from './assets/send.svg';
import uploadIcon from './assets/upload.svg';
import { BUTTON_SIZE, STROKE_WIDTH } from './consts';
const radius = BUTTON_SIZE / 2 - STROKE_WIDTH / 2;
const cx = BUTTON_SIZE / 2;
const cy = BUTTON_SIZE / 2;
export const ActionButton = ({ actionType, onClick }) => {
    const iconSrc = {
        video: videoIcon,
        audio: audioIcon,
        photo: undefined,
        upload: uploadIcon,
        stop: stopIcon,
        send: sendIcon,
    }[actionType];
    return (React.createElement(S.Container, { actionType: actionType, onClick: onClick },
        iconSrc && React.createElement("img", { src: iconSrc }),
        actionType === 'photo' && (React.createElement(React.Fragment, null,
            React.createElement(S.StaticRing, null),
            React.createElement(S.InnerCircle, null))),
        actionType === 'stop' && (React.createElement(S.ProgressRing, { viewBox: `0 0 ${BUTTON_SIZE} ${BUTTON_SIZE}` },
            React.createElement(S.BackgroundCircle, { r: radius, cx: cx, cy: cy }),
            React.createElement(S.Circle, { circumference: radius * 2 * Math.PI, r: radius, cx: cx, cy: cy })))));
};
