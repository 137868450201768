import { BackButton, Button, CurrencyInput, Division, HeadContent, Loading, ProductsList, } from '@fourthwall/components';
import { getPrice } from '@fourthwall/utils';
import { reverse } from 'named-urls';
import React, { useState } from 'react';
import { Dashboard } from '../../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../../components/common/PageLayout';
import { SideSummary } from '../../../../../../components/common/SideSummary';
import { routing } from '../../../../../../utils/routing';
import { getVariantsString } from '../../../../../../utils/variants';
import * as S from './OrderRefundView.styled';
export const OrderRefundView = ({ orderId, friendlyId = '', order, donation, remainingRefundAmount = 0, isLoading, onOrderRefund, }) => {
    const [refundAmount, setRefundAmount] = useState('0');
    const parsedRefund = parseFloat(refundAmount);
    const handleRefundOrderClick = () => onOrderRefund(refundAmount);
    const renderOffer = ({ variant, offer, quantity, price }) => (React.createElement(Division, { size: "small" },
        React.createElement(ProductsList.Item, { name: offer.name, description: getVariantsString(variant), image: variant?.images[0]?.url, quantity: quantity, price: price })));
    const renderDonation = () => {
        if (!donation) {
            return null;
        }
        return (React.createElement(Division, { size: "small" },
            React.createElement(ProductsList.Item, { name: "Donation", quantity: 1, price: donation, type: "donation" })));
    };
    const productsList = (React.createElement(React.Fragment, null,
        React.createElement(Division, { size: "small" }),
        React.createElement(Division, { size: "small" },
            React.createElement(ProductsList, null,
                order?.offers.map(renderOffer),
                renderDonation())),
        React.createElement(Division, { size: "small" })));
    const rightContent = (React.createElement(SideSummary, null,
        React.createElement(S.SummaryHeader, null, "Refund amount"),
        React.createElement(CurrencyInput, { value: refundAmount, max: 1_000_000, suggestion: React.createElement(React.Fragment, null,
                getPrice(remainingRefundAmount),
                " available for refund"), onValueChange: setRefundAmount }),
        React.createElement(S.SummaryButton, null,
            React.createElement(Button, { label: `Refund ${getPrice(parseFloat(refundAmount) || 0)}`, appearance: "primary", fullWidth: true, multiline: true, disabled: !parsedRefund || parsedRefund <= 0.99 || parsedRefund > remainingRefundAmount, onClick: handleRefundOrderClick }))));
    return (React.createElement(Loading, { isLoading: isLoading },
        React.createElement(HeadContent, { title: `Refund order (#${friendlyId})` }),
        React.createElement(PageLayout, { leftContent: productsList, rightContent: rightContent },
            React.createElement("div", null,
                React.createElement(Dashboard.ItemViewHeader, { name: "Refund order", backButton: React.createElement(BackButton, { label: `Order #${friendlyId}`, to: { pathname: reverse(routing.contributions.orders.order.self, { orderId }) } }) })))));
};
