import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
import { DASHBOARD_STYLED_CONSTS } from './consts';
export const Header = styled.header `
  height: ${DASHBOARD_STYLED_CONSTS.HEADER_HEIGHT}px;
  padding: 0 24px;
  background: ${Colors.GS200};

  position: sticky;
  top: 0;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Main = styled.div `
  ${mediaQueries.laptop} {
    min-height: calc(100vh - ${DASHBOARD_STYLED_CONSTS.HEADER_HEIGHT}px);

    display: flex;
  }
`;
export const Sidebar = styled.aside `
  flex: 0 0 ${DASHBOARD_STYLED_CONSTS.SIDEBAR_WIDTH}px;
  border-right: 1px solid ${Colors.GS400};
`;
export const SidebarInner = styled.div `
  padding: ${DASHBOARD_STYLED_CONSTS.SIDEBAR_PADDING};

  position: sticky;
  top: ${DASHBOARD_STYLED_CONSTS.HEADER_HEIGHT}px;
`;
export const Body = styled.main `
  flex: 1;

  display: flex;
  flex-direction: column;
`;
export const Content = styled.div `
  flex: 1;

  width: 100%;

  padding: ${({ isWebView }) => `${isWebView
    ? `${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL}px`
    : `${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_TOP}px ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL}px
    ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_BOTTOM}px;`}`};

  margin-left: auto;
  margin-right: auto;

  ${mediaQueries.laptop} {
    padding: ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL_LAPTOP}px;
    // This makes some room at the bottom of the page to avoid widgets floating over pagination.
    padding-bottom: ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL_LAPTOP + 24}px;
  }
`;
export const Navbar = styled.div `
  padding: 0 20px;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  background: ${Colors.GS0};
  height: ${DASHBOARD_STYLED_CONSTS.NAVBAR_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaQueries.laptop} {
    transform: initial;
    position: static;
    padding: 24px 24px 12px 24px;
  }
`;
export const BurgerButton = styled.button `
  position: relative;
  padding: 10px;
  left: -10px;
`;
export const NavbarActions = styled.div `
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const Toolbar = styled.div `
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const BannersWrapper = styled.div `
  margin: ${DASHBOARD_STYLED_CONSTS.NAVBAR_HEIGHT}px 0 -${DASHBOARD_STYLED_CONSTS.NAVBAR_HEIGHT}px;

  ${mediaQueries.laptop} {
    margin: 0;
  }
`;
