import { ShopRendererApiClient } from '@fourthwall/services/lib/ShopRendererApiClient';
import { useMutation } from '@tanstack/react-query';
export const useRemoveInstagramIntegrationMutation = (options) => {
    const removeInstagramIntegrationMutation = useMutation({
        mutationFn: async (params) => {
            const { data } = await ShopRendererApiClient.removeInstagramIntegration(...params);
            return data;
        },
        ...options,
    });
    return {
        removeInstagramIntegrationMutation,
    };
};
