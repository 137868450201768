import { useIsPresent } from 'framer-motion';
import { usePortal } from '@fourthwall/utils/lib/hooks/usePortal';
import { useEffect, useState } from 'react';
export const useDrawer = (id, options) => {
    const isPresent = useIsPresent();
    const drawerRoot = usePortal(id);
    const [drawerActive, setDrawerActive] = useState(false);
    const drawerOpen = () => {
        setDrawerActive(true);
    };
    const drawerClose = () => {
        setDrawerActive(false);
    };
    useEffect(() => {
        // Detect if drawer is still present (may be removed
        // by parent component), if not close itself.
        !isPresent && drawerClose();
    }, [isPresent]);
    useEffect(() => {
        if (options?.disableBodyScroll) {
            document.body.style.overflow = drawerActive ? 'hidden' : '';
        }
    }, [drawerActive, options?.disableBodyScroll]);
    return {
        drawerRoot,
        drawerActive,
        drawerOpen,
        drawerClose,
    };
};
