import React from 'react';
import { Link } from 'react-router-dom';
import { FwIcon } from '@fourthwall/icons';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { Loader } from '../Loader';
import * as S from './styled';
export const ErrorPage = ({ to, heading, description, actions, loader }) => {
    return (React.createElement(S.Container, null,
        to && (React.createElement(S.LogoContainer, { as: Link, to: to },
            React.createElement(Icon, { component: FwIcon, height: 28 }))),
        !to && (React.createElement(S.LogoContainer, null,
            React.createElement(Icon, { component: FwIcon, height: 28 }))),
        React.createElement(S.Main, null,
            loader && (React.createElement(S.LoaderContainer, null,
                React.createElement(Loader, { size: "large" }))),
            React.createElement(Heading, { type: "h5", weight: "bold", asTag: "h2" }, heading),
            description && React.createElement(S.Description, null, description),
            actions && React.createElement(S.ActionsContainer, null, actions))));
};
