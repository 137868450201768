import { ButtonClean, Checkbox, Input, Subsection } from '@fourthwall/components';
import { Field } from 'formik';
import React from 'react';
import cloneDeep from 'lodash-es/cloneDeep';
import isNil from 'lodash-es/isNil';
import set from 'lodash-es/set';
import { useLocation } from 'react-router-dom';
import { StockTooltip } from '@components/common/StockTooltip';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import { reverse, routing } from '@utils/routing';
import { ProductVariantsData } from './components/ProductVariantsData';
import { flattenVariantGroups, getVariantsGroups } from '../../utils';
import { ProductMembershipSettings } from '../ProductMembershipSettings';
import * as S from './styled';
import { CompareAtPriceCheckboxField } from '../CompareAtPriceCheckboxField';
export const ProductVariants = ({ productId, variantTypes, data, variantsAnalytics, variantsAnalyticsWhiplash, visualHints, allImages, membershipTiers, offerType, showPricing, isCompareAtPriceEnabled, disabled = false, stockDisabled = false, setFieldValue, fulfillmentService, onStockCreate, onStockUpdate, onStockDelete, }) => {
    const location = useLocation();
    const adminMode = useAdminModeContext();
    if (!data?.length) {
        return null;
    }
    const allowVariantsEdit = offerType === 'MANUAL' || !!adminMode?.isActive;
    const shouldDisplayTable = data.length >= 2;
    const showWeight = variantTypes.some((variantType) => variantType.variesBy.weight);
    const getShipMonkAvailableStock = (id = data[0].id) => variantsAnalyticsWhiplash?.[id]?.stockDetails?.shipMonkStockExists
        ? variantsAnalyticsWhiplash?.[id]?.stockDetails?.shipMonkStockAvailable
        : 'N/A';
    const handleSkuChange = (value) => {
        setFieldValue('variants', cloneDeep(data).map((variant) => set(variant, 'sku', value)));
    };
    const handleStockTooltipChange = (id) => (oldValue, newValue) => {
        if (isNil(oldValue)) {
            return onStockCreate({ id, quantity: newValue });
        }
        if (isNil(newValue)) {
            return onStockDelete(id);
        }
        return onStockUpdate({ id, data: { from: oldValue, to: newValue } });
    };
    const getVariantsData = () => {
        const variantGroups = getVariantsGroups(data, variantTypes);
        return flattenVariantGroups(variantGroups);
    };
    const getButtonLabel = () => {
        if (!shouldDisplayTable) {
            return 'Add variants (different colors, sizes, etc.)';
        }
        return 'Edit, add or remove variants';
    };
    const renderContent = () => {
        if (!shouldDisplayTable) {
            if ((offerType === 'ON_DEMAND' || offerType === 'MOQ') && !adminMode?.isActive) {
                const hasQuantity = data.some(({ quantity }) => !!quantity);
                return React.createElement("p", null,
                    "In stock: ",
                    hasQuantity ? 'Manually set by Partner Manager' : 'Unlimited');
            }
            const firstVariantQuantity = data[0].quantity;
            return (React.createElement(S.SingleVariantContainer, null,
                React.createElement(S.SingleVariantItem, null,
                    React.createElement(S.SingleVariantLabel, null, "In stock:"),
                    React.createElement(StockTooltip, { quantity: firstVariantQuantity, disabled: stockDisabled, onChange: handleStockTooltipChange(data[0].id) })),
                fulfillmentService === 'SHIP_MONK' && (React.createElement(S.SingleVariantItem, null,
                    React.createElement(S.SingleVariantLabel, null, "In ShipMonk:"),
                    getShipMonkAvailableStock()))));
        }
        return (React.createElement(S.DataContainer, null,
            React.createElement(ProductVariantsData, { data: getVariantsData(), variantTypes: variantTypes, showPricing: showPricing, showWeight: showWeight, allImages: allImages, disabled: disabled, fulfillmentService: fulfillmentService, isCompareAtPriceEnabled: isCompareAtPriceEnabled, stockDisabled: stockDisabled || !allowVariantsEdit, variantsAnalytics: variantsAnalytics, variantsAnalyticsWhiplash: variantsAnalyticsWhiplash, visualHints: visualHints, onStockTooltipChange: handleStockTooltipChange })));
    };
    const renderSkuInput = () => {
        if ((shouldDisplayTable && allowVariantsEdit) || !visualHints?.customSkus)
            return null;
        return (React.createElement(S.FooterInputContainer, null,
            React.createElement(Field, { component: Input, name: "variants[0].sku", label: "SKU or UPC code", disabled: disabled, onValueChange: handleSkuChange })));
    };
    return (React.createElement(S.Container, null,
        React.createElement(Subsection, { title: shouldDisplayTable ? 'Inventory and variants' : 'Inventory', borderless: shouldDisplayTable },
            renderContent(),
            allowVariantsEdit && !disabled && (React.createElement(ButtonClean, { label: getButtonLabel(), to: {
                    pathname: reverse(routing.products.all.product.variants, { productId }),
                    search: location.search,
                } })),
            React.createElement(S.Footer, null,
                showPricing && (React.createElement(CompareAtPriceCheckboxField, { name: "isCompareAtPriceEnabled", disabled: disabled })),
                React.createElement(Field, { name: "isSoldOut", label: "Mark as sold out", component: Checkbox, disabled: disabled }),
                allowVariantsEdit && (React.createElement(React.Fragment, null,
                    React.createElement(Field, { name: "visualHints.customSkus", component: Checkbox, label: shouldDisplayTable
                            ? 'Each variant has an identification code (such as a UPC or SKU #)'
                            : 'This product has an identification code (such as a UPC or SKU #)', disabled: disabled }),
                    renderSkuInput())),
                React.createElement(ProductMembershipSettings, { membershipTiers: membershipTiers, disabled: disabled })))));
};
