import * as yup from 'yup';
import { FormFields } from './types';
export const getValidationSchema = ({ isFreeTierCreated, isEditingPaidTier, isCreatingNewTier, }) => {
    const getPriceValidation = () => {
        if (isFreeTierCreated && isEditingPaidTier) {
            return yup
                .number()
                .max(1500000)
                .required()
                .test('price', 'You cannot change paid tier to free tier.', (value) => {
                if (value === 0)
                    return false;
                return true;
            })
                .test('price', 'Must be a min. of $1', (value) => {
                if (typeof value !== 'undefined' && value < 1 && isEditingPaidTier)
                    return false;
                return true;
            });
        }
        if (isFreeTierCreated && isCreatingNewTier) {
            return yup
                .number()
                .max(1500000)
                .required()
                .test('price', 'You cannot create more than one free tier', (value) => value !== 0)
                .min(1, 'Must a min. of $1');
        }
        return yup
            .number()
            .max(1500000)
            .required()
            .test('price', 'You cannot change paid tier to free tier.', (value) => {
            if (value === 0 && isEditingPaidTier)
                return false;
            return true;
        })
            .test('price', 'Must be a min. of $1', (value) => {
            if (typeof value !== 'undefined' && value < 1 && isEditingPaidTier)
                return false;
            return true;
        })
            .test('price', 'Must be either $0 or a min. of $1', (value) => {
            if (value === 0)
                return true;
            if (typeof value !== 'undefined' && value < 1)
                return false;
            return true;
        });
    };
    return yup.object().shape({
        [FormFields.annualDiscount]: yup.number(),
        [FormFields.title]: yup.string().required().max(40),
        [FormFields.description]: yup.string().max(600),
        [FormFields.price]: getPriceValidation(),
        [FormFields.discount]: yup.boolean(),
        [FormFields.monthDiscount]: yup.number().when([FormFields.discount], (discount) => {
            if (!discount)
                return yup.number();
            return yup
                .number()
                .integer()
                .min(1)
                .max(100)
                .required()
                .test('monthDiscount', 'Must be either $0 or a min. of $1', function () {
                const { price, monthDiscount } = this.parent;
                const currentPrice = price - price * (monthDiscount / 100);
                if (currentPrice === 0 || currentPrice >= 1)
                    return true;
                return false;
            });
        }),
        [FormFields.yearDiscount]: yup.number().when([FormFields.discount], (discount) => {
            if (!discount)
                return yup.number().nullable(true);
            return yup
                .number()
                .integer()
                .min(1)
                .max(100)
                .nullable(true)
                .test('yearDiscount', 'Must be either $0 or a min. of $1', function () {
                const { price, yearDiscount, annualDiscount } = this.parent;
                const yearPrice = price * 12 - price * 12 * (annualDiscount / 100);
                const currentPrice = yearPrice - yearPrice * (yearDiscount / 100);
                // NOTE: If there is no value for year discount (it is not required)
                if (!yearDiscount)
                    return true;
                // NOTE: If global annual discounts are disabled
                if (!annualDiscount)
                    return true;
                if (currentPrice === 0 || currentPrice >= 1)
                    return true;
                return false;
            });
        }),
        [FormFields.timeLimit]: yup.boolean(),
        [FormFields.startDate]: yup
            .string()
            .when([FormFields.timeLimit, FormFields.discount], (timeLimit, discount) => {
            return timeLimit && discount
                ? yup.string().required().nullable(true)
                : yup.string().nullable(true);
        }),
        [FormFields.endDate]: yup
            .string()
            .when([FormFields.timeLimit, FormFields.discount], (timeLimit, discount) => {
            return timeLimit && discount
                ? yup.string().required().nullable(true)
                : yup.string().nullable(true);
        }),
        [FormFields.image]: yup.string(),
        [FormFields.removeImage]: yup.boolean().required(),
    });
};
