import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { useEffect, useMemo, useState } from 'react';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
import { useAuth } from '@fourthwall/auth';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import { isProduction } from '@fourthwall/utils';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useSetDashboardVisitMutation } from './useSetDashboardVisitMutation';
export const useFirstTimeVisit = () => {
    const adminMode = useAdminModeContext();
    const isAdmin = !!adminMode?.isActive;
    const [visitTracked, setVisitTracked] = useState(false);
    const { getData } = useVisitorData(undefined, { immediate: false });
    const { currentShop, invalidateCurrentShopQuery } = useCurrentShop();
    const { user } = useAuth();
    const { setDashboardVisitMutation } = useSetDashboardVisitMutation();
    const { hasPermission } = useDashboardPermissions();
    const visited = useMemo(() => {
        return currentShop?.metafields?.['dashboard-visited'] || false;
    }, [currentShop]);
    const shouldTrackVisit = useMemo(() => isProduction() && !isAdmin && hasPermission('visitState') && !visited && !visitTracked, [isAdmin, visited, visitTracked, hasPermission]);
    useEffect(() => {
        if (!shouldTrackVisit || !user || !currentShop)
            return;
        getData({
            ignoreCache: true,
            linkedId: user.id,
            tag: {
                userId: user.id,
                userName: user.fullName,
                userEmail: user.email,
                shopId: currentShop.id,
                shopUri: currentShop.internalBaseUri,
                creatorName: currentShop.creatorName,
            },
        });
        setVisitTracked(true);
        setDashboardVisitMutation.mutate([{ shopId: currentShop.id }], {
            onSuccess: invalidateCurrentShopQuery,
        });
    }, [
        user,
        isAdmin,
        getData,
        visited,
        currentShop,
        shouldTrackVisit,
        invalidateCurrentShopQuery,
        setDashboardVisitMutation,
    ]);
};
