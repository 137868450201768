import { useMediaQueryContext, usePagination } from '@fourthwall/components';
import React from 'react';
import { reverse, routing } from '../../../../../../utils/routing';
import { useTiersQuery } from '../../Perks/hooks/useTiersQuery';
import { DateCell } from '../components/DateCell';
import { PostCell } from '../components/PostCell';
import { mapAccessTierNames } from '../utils/mapAccessTierNames';
import { useFilterPosts } from './useFilterPosts';
import { usePostsQuery } from './usePostsQuery';
export const usePosts = (state) => {
    const { filtersParams, searchQueryParam } = useFilterPosts();
    const media = useMediaQueryContext();
    const types = filtersParams.types?.split(',');
    const [currentPage, setCurrentPage] = usePagination();
    const { postsQuery } = usePostsQuery([
        {
            state,
            page: currentPage,
            'types[]': types,
            search_query: searchQueryParam || undefined,
        },
    ], {
        refetchInterval: (data) => data?.posts.some((post) => post.statuses.resources === 'Processing') ? 60000 : false,
    });
    const { tiersQuery } = useTiersQuery();
    const getPostCell = (post) => {
        const tiers = tiersQuery.data?.tiers;
        if ('image' in post && post.image && 'thumbnailUrl' in post.image) {
            return (React.createElement(PostCell, { type: post.type, title: post.title, thumbnailUrl: post.image.thumbnailUrl, resources: post.statuses.resources, subtitle: mapAccessTierNames(post.access, tiers), pinned: !!post.pinnedAt }));
        }
        return (React.createElement(PostCell, { type: post.type, title: post.title, resources: post.statuses.resources, subtitle: mapAccessTierNames(post.access, tiers), pinned: !!post.pinnedAt }));
    };
    const mobileHeaders = [
        {
            id: 'post',
            label: undefined,
            render: getPostCell,
        },
    ];
    const headers = [
        {
            id: 'date',
            label: 'Date',
            render: ({ liveAt }) => React.createElement(DateCell, { date: liveAt }),
            width: 3,
        },
        {
            id: 'post',
            label: 'Post',
            render: getPostCell,
            width: 18,
        },
        {
            id: 'comments',
            label: 'Comments',
            render: ({ comments }) => comments.count,
            width: 2,
        },
    ];
    const table = {
        data: postsQuery.data?.posts || [],
        headers: media.laptop ? headers : mobileHeaders,
        // @ts-ignore
        getRowLink: ({ id }) => ({
            pathname: reverse(routing.memberships.posts.post.self, { postId: id }),
            search: location.search,
        }),
    };
    const pagination = {
        pageCount: postsQuery.data?.pagination?.pages || 1,
        currentPage,
        onChange: setCurrentPage,
    };
    return {
        posts: {
            table,
            pagination,
            isLoading: postsQuery.isFetching,
        },
    };
};
