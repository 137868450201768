import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const OptionsContainer = styled.div `
  display: grid;
  gap: 16px;
`;
export const Option = styled.div `
  position: relative;

  display: flex;

  justify-content: flex-start;
  align-items: center;

  border: 1px solid ${Colors.GS400};

  min-height: 56px;
`;
export const Title = styled.div `
  padding: 15px;
  z-index: 1;
`;
export const PercentageBar = styled.div `
  position: absolute;
  width: ${(props) => `${props.percentage}%`};
  height: 100%;
  background-color: ${Colors.GS300};
`;
export const PercentageContainer = styled.div `
  display: flex;
  z-index: 1;

  align-items: center;

  margin-left: auto;
  padding: 15px;

  white-space: nowrap;
`;
export const VotesAmount = styled.div `
  margin-right: 16px;
  color: ${Colors.GS600};
  font-size: 14px;
  line-height: 1.5;
`;
export const Divider = styled.div `
  margin: 20px 0;
  height: 1px;
  background-color: ${Colors.GS400};
`;
export const Info = styled.div `
  display: grid;

  grid-auto-flow: column;
  gap: 16px;
  justify-content: start;

  color: ${Colors.GS600};
  font-size: 14px;
  line-height: 1.5;
`;
