import cloneDeep from 'lodash-es/cloneDeep';
import set from 'lodash-es/set';
import { convertNumberToPrice } from './convertNumberToPrice';
export const setVariantsCompareAtValue = (variants, compareAtPriceValue, size) => {
    return cloneDeep(variants).map((variant) => {
        if (size && variant.size !== size)
            return variant;
        if (compareAtPriceValue === null || compareAtPriceValue === '') {
            return set(variant, 'compareAtPrice', null);
        }
        const newCompareAtPriceValue = parseFloat(compareAtPriceValue);
        const difference = newCompareAtPriceValue - (variants[0].price?.value || 0);
        const newCompareAtPriceValueWithDifference = Number(variant.price?.value || 0) + Number(difference);
        return set(variant, 'compareAtPrice', convertNumberToPrice(size ? newCompareAtPriceValue : newCompareAtPriceValueWithDifference));
    });
};
