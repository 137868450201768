import { OffersApiClient } from '@fourthwall/services/lib/OffersApiClient';
import { useMutation } from '@tanstack/react-query';
export const useStatusMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({ offerId, params }) => {
            const { data } = await OffersApiClient.setState(offerId, params);
            return data;
        },
    });
    return {
        status: mutation.data,
        statusMutation: mutation,
    };
};
