import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { useFormikContext } from 'formik';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import { useState } from 'react';
import { createAttachment } from './helpers/createAttachment';
import { getFileTypes } from './helpers/getFileTypes';
import { getViewState } from './helpers/getViewState';
import { useUploadQuery } from './useUploadQuery';
export const useUpload = () => {
    const [file, setFile] = useState('');
    const [fileType, setType] = useState('');
    const { uploadFileMutation, uploadFile } = useUploadQuery();
    const { setFieldValue } = useFormikContext();
    const { open, close } = useModalContext();
    const uploadFileHandler = (files) => {
        if (files && files[0]) {
            const file = files[0];
            const payload = getFileTypes(file);
            const { rootType } = payload;
            setType(rootType);
            setFile(URL.createObjectURL(file));
            uploadFile({ file, payload }, {
                onSuccess: ({ fileUrl }) => {
                    const attachment = createAttachment({ fileUrl, rootType });
                    setFieldValue('attachment', attachment);
                },
                onError: (error) => {
                    const message = errorMessageSelector(error.response?.data, 'There was an error when uploading file.');
                    Toasts.notify(message, { type: 'error' });
                },
            });
        }
    };
    const handleDropRejection = () => {
        const message = 'You can only upload video or audio files. Try to upload the file again.';
        Toasts.notify(message, { type: 'info' });
    };
    const removeFile = () => {
        open(ConfirmModal, {
            title: 'Are you sure you want to remove this recording?',
            confirmLabel: 'Remove',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                setFile('');
                setType('');
                close();
            },
        });
    };
    return {
        uploadFile: uploadFileHandler,
        removeFile,
        onFileRejection: handleDropRejection,
        vm: {
            viewStatus: getViewState(uploadFileMutation, file),
            file,
            fileType,
        },
    };
};
