import React from 'react';
import * as S from './Twitter.styled';
export const Twitter = ({ avatar, name, nick, url, children }) => (React.createElement(S.Container, { href: url, target: "_blank", rel: "noopener noreferrer" },
    React.createElement(S.Header, null,
        React.createElement(S.Figure, null,
            React.createElement(S.Avatar, null, avatar),
            React.createElement(S.Figcaption, null,
                React.createElement(S.Name, null, name),
                React.createElement(S.Nick, null, nick))),
        React.createElement(S.Logo, null,
            React.createElement(S.TwitterIcon, { width: "100%" }))),
    React.createElement(S.Content, null, children)));
