import { CustomizationApiClient } from '@fourthwall/services/lib/CustomizationApiClient';
import { useQuery } from '@tanstack/react-query';
export const useCustomizationQuery = (customizationId) => {
    const customizationQuery = useQuery({
        queryKey: ['useCustomizationQuery', customizationId],
        queryFn: async () => {
            if (customizationId) {
                const { data } = await CustomizationApiClient.getDraft(customizationId);
                return data;
            }
        },
        enabled: !!customizationId,
    });
    return {
        customization: customizationQuery.data,
        customizationQuery,
    };
};
