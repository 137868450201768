import * as Yup from 'yup';
export const ConnectValidationSchema = Yup.object().shape({
    apiKey: Yup.string()
        .trim()
        .min(30)
        .matches(/^[a-zA-Z0-9]{30,}$/, 'Only alphanumeric characters')
        .required(),
    apiSecret: Yup.string()
        .trim()
        .min(30)
        .matches(/^[a-zA-Z0-9]{30,}$/, 'Only alphanumeric characters')
        .required(),
});
