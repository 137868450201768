import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { ShopsApiClient } from '@fourthwall/services/lib/ShopsApiClient';
import { useMutation } from '@tanstack/react-query';
const MAX_ITERATIONS = 20;
export const useCheckMembershipsEnabledMutation = () => {
    const checkMembershipsEnabled = useMutation({
        mutationFn: async () => {
            const { data: { settings }, } = await ShopsApiClient.getCurrentShop();
            const { data: { status }, } = await MembershipsCreatorApiClient.getMembershipsStatus();
            return new Promise((resolve, reject) => {
                if (settings.memberships.enabled && status === 'Live') {
                    resolve(true);
                }
                reject(new Error('Memberships are not enabled'));
            });
        },
        retry: (retryCount) => retryCount < MAX_ITERATIONS,
        retryDelay: 1000,
        onError: () => {
            // NOTE: We have to ignore default error behavior since it means section is not added but will be in the future
        },
    });
    return {
        checkMembershipsEnabled,
    };
};
