var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { ApiBase } from './ApiBase';
class OffersApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getOffers = (params) => {
            return this.get('/offers', Object.assign({ 
                // NOTE: By default API returns only 'LIVE' products.
                status: 'DRAFT,HIDDEN,LIVE,PRIVATE,DISABLED' }, params));
        };
        this.getOffersByIds = (offerIds) => {
            return this.get(`/offers/ids?offerIds=${offerIds.join(',')}`);
        };
        this.getOffer = (offerId) => {
            return this.get(`/offers/${offerId}`);
        };
        this.setAvailability = (offerId, params) => {
            return this.post(`/offers/${offerId}/availability`, params);
        };
        this.setState = (offerId, params) => {
            return this.post(`/offers/${offerId}/state`, params);
        };
        this.updateOffer = (offerId, payload) => {
            return this.put(`/offers/${offerId}`, payload);
        };
        this.deleteOffer = (pathParams) => {
            return this.delete(`/offers/${pathParams.offerId}`);
        };
        this.createDefaultOffer = () => {
            return this.post('/offers/create/default');
        };
        this.createOffer = (payload) => {
            return this.post('/offers/', payload);
        };
        this.duplicateOffer = (params) => {
            return this.post(`/offers/${params.path.offerId}`);
        };
        this.migrate = (params, body) => {
            return this.post(`/offers/${params.offerId}/migrate`, body);
        };
        this.migrationToAnotherProductOptions = (params) => {
            return this.get(`/offers/${params.offerId}/migration-to-another-product-options`);
        };
        this.migrationToAnotherProductionMethodOptions = (params) => {
            return this.get(`/offers/${params.offerId}/migration-to-another-production-method-options`);
        };
        this.updateVariantTypes = (offerId, payload) => {
            return this.post(`/offers/${offerId}/types`, payload);
        };
        this.updateOfferSections = (pathParams, payload) => {
            return this.put(`/offers/${pathParams.offerId}/sections`, payload);
        };
        this.updateOfferSlug = (pathParams, payload) => {
            return this.put(`/offers/${pathParams.offerId}/slug`, payload);
        };
        this.getVariant = (variantId) => {
            return this.get(`/offers/variants/${variantId}`);
        };
        this.getSlugSuggestion = (parameters) => {
            return this.get(`/offers/${parameters.offerId}/slug-suggestion`);
        };
        this.uploadImage = (file) => {
            return this.post('/offers/images', file);
        };
        this.assignImages = (offerId, payload) => {
            return this.put(`/offers/${offerId}/images`, payload);
        };
        this.assignBulkImages = (offerId, payload) => {
            return this.put(`/offers/${offerId}/bulk/images`, payload);
        };
        this.replaceVariantsCosts = (payload) => {
            return this.put('/offers/variants/costs', payload); // TODO: Response
        };
        this.uploadFile = (offerId, file) => __awaiter(this, void 0, void 0, function* () {
            const fileType = file.type || 'application/octet-stream';
            const response = yield this.post(`/offers/${offerId}/files`, { fileName: file.name, contentType: fileType, size: file.size });
            const { uploadUrl } = response.data;
            yield axios.put(uploadUrl, file, {
                headers: {
                    'Content-Disposition': `attachment; filename="${file.name}"`,
                    'Content-Type': fileType,
                    'x-goog-content-length-range': `0,${file.size}`,
                },
            });
            return response;
        });
        this.updateShippingType = (parameters, requestBody) => {
            return this.put(`/offers/${parameters.offerId}/shipping-type`, requestBody);
        };
    }
}
export const OffersApiClient = new OffersApiClientModel();
