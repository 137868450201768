import styled, { css } from 'styled-components';
import { Colors } from '../../../colors';
import { DRAG_HELPER_CLASS } from '../consts';
export const DragHandle = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;

  opacity: 0;
  color: ${Colors.GS600};

  cursor: move;
  cursor: grab;

  &:hover {
    color: ${Colors.GS1000};
  }
`;
export const Options = styled.div `
  display: flex;
  align-items: stretch;

  opacity: 0;
`;
export const Wrapper = styled.div `
  background: #fff; // body background color
`;
export const Container = styled.div ``;
export const Row = styled.div `
  position: relative;
  display: flex;
  align-items: stretch;

  &.${DRAG_HELPER_CLASS} {
    pointer-events: auto !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    z-index: 999;

    ${DragHandle} {
      cursor: grabbing;
    }
  }

  &:hover {
    ${DragHandle}, ${Options} {
      opacity: 1;
    }
  }

  ${({ hoverable }) => hoverable &&
    css `
      cursor: pointer;

      &:hover {
        background-image: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0,
          #f9f8f7 25%,
          #f9f8f7 71%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    `};

  ${({ $highlighted }) => $highlighted &&
    css `
      background: linear-gradient(
        270deg,
        rgba(0, 87, 255, 0) 0%,
        rgba(0, 87, 255, 0.08) 30.21%,
        rgba(0, 87, 255, 0.08) 68.75%,
        rgba(0, 87, 255, 0) 100%
      );
    `};
`;
export const ChildrenContainer = styled.div `
  border-bottom: 1px solid ${Colors.GS400};

  flex: 1;
`;
export const Children = styled.div `
  display: flex;
  align-items: stretch;
`;
export const Footer = styled.footer `
  background: ${Colors.GS0};
`;
