import React from 'react';
import { useOffersTotal } from '@hooks';
import { HomeProductsView } from './HomeProductsView';
export const HomeProductsContainer = () => {
    const offersTotal = useOffersTotal();
    if (!offersTotal)
        return null;
    const getCopyProps = () => {
        const count = offersTotal.all;
        // no products
        if (count === 0) {
            return {
                title: 'Create your first product',
                subtitle: 'Design a new product or list something you own',
            };
        }
        // 1 to 4 products created
        if (count <= 4) {
            return {
                title: 'Create another product',
                subtitle: 'We recommend giving supporters 3-5 options to choose from',
            };
        }
        // > 4 products created
        return {
            title: 'Create another product',
            subtitle: 'Give supporters more options to choose from',
        };
    };
    return React.createElement(HomeProductsView, { ...getCopyProps() });
};
