import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px 40px;
  border: 1px solid ${Colors.GS400};
  background-color: ${Colors.GS0};
`;
export const Image = styled.img `
  max-width: 270px;
  margin: 12px 0;
`;
export const Subtitle = styled.div `
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS600};
  margin: 4px 0;
`;
export const Heading = styled.div `
  margin-right: 12px;
`;
export const Description = styled.div `
  font-size: 12px;
  line-height: 150%;
  color: ${Colors.GS600};
  margin: 4px 0 16px;
`;
export const LoaderContainer = styled.div `
  height: 100%;
  display: flex;
  justify-content: center;
`;
