import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const CheckboxContainer = styled.div `
  width: 311px;
  background: ${Colors.GS0};
  padding: 20px;
  border-top: 1px solid ${Colors.GS500};
  border-left: 1px solid ${Colors.GS500};
  border-right: 1px solid ${Colors.GS500};

  &:only-child {
    border-bottom: 1px solid ${Colors.GS500};
  }
`;
