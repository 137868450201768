import { BackButton } from '@fourthwall/components';
import React from 'react';
import { useLocation } from 'react-router-dom';
export const DashboardBackButton = (props) => {
    const location = useLocation();
    if (location.state?.from) {
        return (React.createElement(BackButton, { ...props, label: location.state.from.label, to: {
                pathname: location.state.from.location.pathname,
                search: location.state.from.location.search,
            }, state: location.state.from.state }));
    }
    return React.createElement(BackButton, { ...props });
};
