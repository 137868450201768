import { ConfirmModal, Fieldset, RadioButton, useModalContext } from '@fourthwall/components';
import React, { useState } from 'react';
export const SyncFulfillmentsModal = ({ onConfirm }) => {
    const { close } = useModalContext();
    const [syncType, setSyncType] = useState('SYNC_NEW');
    const handleConfirm = () => {
        onConfirm(syncType);
    };
    return (React.createElement(ConfirmModal, { title: "Which orders do you want to sync?", text: React.createElement("div", { style: { marginTop: 4 } },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(RadioButton, { value: syncType, label: "Sync only new orders", checkedValue: "SYNC_NEW", onChange: () => setSyncType('SYNC_NEW') })),
                React.createElement(Fieldset.Row, null,
                    React.createElement(RadioButton, { value: syncType, label: "Sync all orders, including current unfulfilled orders", checkedValue: "SYNC_ALL", onChange: () => setSyncType('SYNC_ALL') })))), onConfirm: handleConfirm, close: close }));
};
