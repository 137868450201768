import { TableCell } from '@components/common/TableCell';
import { TableItemDate } from '@components/utils/TableItemDate';
import { InProgress, PriceString, ProfitString, TextBlockEllipsis } from '@fourthwall/components';
import React from 'react';
export const getTableHeaders = (state, { hideProfitColumn }) => {
    const headers = [
        {
            id: state === 'SENT' ? 'updatedAt' : 'createdAt',
            label: 'Date',
            render: ({ createdAt, updatedAt }) => (React.createElement("div", null,
                React.createElement(TableItemDate, { date: state === 'SENT' ? updatedAt : createdAt }))),
        },
        {
            id: 'supporter.fullName',
            label: 'Supporter',
            render: ({ supporter }) => (React.createElement(TableCell, { mainContent: supporter.fullName || supporter.email, ellipsis: true })),
            width: 2,
        },
        {
            id: 'message',
            label: 'Message left',
            render: ({ message }) => (React.createElement(TableCell, { mainContent: message ? React.createElement(TextBlockEllipsis, { component: "blockquote", text: message }) : React.createElement(React.Fragment, null, "\u2013") })),
            width: 3,
        },
        {
            id: 'total.value',
            label: 'Order value',
            render: ({ total }) => React.createElement(TableCell, { mainContent: React.createElement(PriceString, { value: total.value }) }),
        },
        {
            id: 'profit',
            label: 'Profit',
            hidden: hideProfitColumn,
            render: () => (React.createElement(InProgress, null,
                React.createElement(ProfitString, { value: 32.32 }))),
        },
    ];
    return headers.filter((column) => !column.hidden);
};
