import styled from 'styled-components';
export const Container = styled.div `
  font-weight: 600;
`;
export const TooltipContainer = styled.div `
  margin-left: 8px;

  display: inline-block;
  vertical-align: middle;
`;
