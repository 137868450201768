import styled, { css } from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  ${mediaQueries.tablet} {
    gap: 12px;
  }
`;
export const Tab = styled.button `
  padding: 6px 12px;
  border-radius: 100px;

  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: 600;
  line-height: 130%;

  white-space: nowrap;

  ${mediaQueries.tablet} {
    padding: 6px 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }

  ${({ appearance, active }) => appearance === 'primary' &&
    css `
      background: ${Colors.GS200};
      color: ${Colors.GS600};

      &:hover {
        background: ${Colors.GS300};
        color: ${Colors.GS1000};
      }

      ${active &&
        css `
        &,
        &:hover {
          background: ${Colors.GS1000};
          color: ${Colors.GS100};
        }
      `};
    `};

  ${({ appearance, active }) => appearance === 'secondary' &&
    css `
      background: ${Colors.GS800};
      color: ${Colors.GS550};

      &:hover {
        background: ${Colors.GS700};
      }

      ${active &&
        css `
        &,
        &:hover {
          background: ${Colors.GS100};
          color: ${Colors.GS1000};
        }
      `};
    `};

  ${({ appearance, active }) => appearance === 'outline' &&
    css `
      border: 1px solid;
      border-color: ${Colors.GS400};
      color: ${Colors.GS600};

      &:hover {
        background: ${Colors.GS300};
        color: ${Colors.GS1000};
      }

      ${active &&
        css `
        &,
        &:hover {
          background: none;
          border-color: ${Colors.GS1000};
          color: ${Colors.GS1000};
        }
      `};
    `};
`;
