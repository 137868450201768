import { HeadContent, Pagination } from '@fourthwall/components';
import isEmpty from 'lodash-es/isEmpty';
import React from 'react';
import { Dashboard } from '@components/common';
import { OrdersData } from './components/OrdersData';
export const OrdersView = ({ orders, currentPage, isFetching, totalPages, onPageChange, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Orders" }),
        React.createElement(Dashboard.Section.Item, null,
            React.createElement(OrdersData, { data: orders || [], isLoading: isFetching })),
        !isEmpty(orders) && totalPages && (React.createElement(Dashboard.Section.Item, null,
            React.createElement(Pagination, { pageCount: totalPages, currentPage: currentPage, onChange: onPageChange })))));
};
