import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
import { Appearance, Size } from './types';
const getContainerWidth = ({ $fullwidth }) => {
    if ($fullwidth) {
        return '100%';
    }
};
const getContainerHeight = ({ size }) => {
    if (size === Size.Small) {
        return 40;
    }
    if (size === Size.Medium) {
        return 48;
    }
    if (size === Size.Large) {
        return 56;
    }
};
const getContainerHorizontalPadding = ({ size }) => {
    if (size === Size.Small) {
        return 16;
    }
    if (size === Size.Medium) {
        return 20;
    }
    if (size === Size.Large) {
        return 24;
    }
};
const getContainerBackground = ({ appearance, theme }) => {
    if (appearance === Appearance.Primary && theme.automated?.styles?.colorPrimary) {
        return theme.automated?.styles?.colorPrimary;
    }
    if (appearance === Appearance.Primary) {
        return Colors.B500;
    }
    if (appearance === Appearance.Secondary && theme.automated?.styles?.buttonBackground) {
        return theme.automated?.styles.buttonBackground;
    }
    if (appearance === Appearance.Secondary) {
        return Colors.GS300;
    }
    if (appearance === Appearance.Destructive) {
        return Colors.R300;
    }
    if (appearance === 'destructive-inverted') {
        return Colors.GS0;
    }
};
const getContainerColor = ({ appearance, theme }) => {
    if (appearance === Appearance.Primary && theme.automated?.styles?.colorOnPrimary) {
        return theme.automated?.styles?.colorOnPrimary;
    }
    if (appearance === Appearance.Primary) {
        return Colors.GS0;
    }
    if (appearance === Appearance.Secondary && theme.automated?.styles?.colorOnBackground) {
        return theme.automated?.styles.colorOnBackground;
    }
    if (appearance === Appearance.Secondary) {
        return Colors.GS1000;
    }
    if (appearance === Appearance.Destructive) {
        return Colors.GS0;
    }
    if (appearance === 'destructive-inverted') {
        return Colors.R300;
    }
};
const getContainerHoverBackground = ({ appearance, theme }) => {
    if (appearance === Appearance.Primary && theme.automated?.styles?.palePrimaryLight) {
        return theme.automated?.styles?.palePrimaryLight;
    }
    if (appearance === Appearance.Primary) {
        return Colors.B600;
    }
    if (appearance === Appearance.Secondary && theme.automated?.styles?.buttonBackgroundHover) {
        return theme.automated?.styles.buttonBackgroundHover;
    }
    if (appearance === Appearance.Secondary) {
        return Colors.GS400;
    }
    if (appearance === Appearance.Destructive) {
        return Colors.R500;
    }
    if (appearance === 'destructive-inverted') {
        return Colors.GS400;
    }
};
const getContainerActiveBackground = ({ appearance, theme }) => {
    if (appearance === Appearance.Primary && theme.automated?.styles?.palePrimaryLight) {
        return theme.automated?.styles?.palePrimaryLight;
    }
    if (appearance === Appearance.Primary) {
        return Colors.B700;
    }
    if (appearance === Appearance.Secondary) {
        return Colors.GS1000;
    }
    if (appearance === Appearance.Destructive) {
        return Colors.R500;
    }
    if (appearance === 'destructive-inverted') {
        return Colors.GS400;
    }
};
const getContainerActiveColor = ({ appearance }) => {
    if (appearance === Appearance.Secondary) {
        return Colors.GS0;
    }
};
const getContainerFocusBackground = ({ appearance, theme }) => {
    if (appearance === Appearance.Primary && theme.automated?.styles?.colorPrimary) {
        return theme.automated?.styles?.colorPrimary;
    }
    if (appearance === Appearance.Primary) {
        return Colors.B500;
    }
    if (appearance === Appearance.Secondary && theme.automated?.styles?.colorOnBackground) {
        return tinycolor
            .mix(theme.automated?.styles.colorBackground, theme.automated?.styles.colorOnBackground, 10)
            .toHexString();
    }
    if (appearance === Appearance.Secondary) {
        return Colors.GS300;
    }
    if (appearance === Appearance.Destructive) {
        return Colors.R300;
    }
    if (appearance === 'destructive-inverted') {
        return Colors.GS0;
    }
};
const getContainerFocusColor = ({ appearance, theme }) => {
    if (appearance === Appearance.Primary && theme.automated?.styles?.colorOnPrimary) {
        return theme.automated?.styles?.colorOnPrimary;
    }
    if (appearance === Appearance.Secondary && theme.automated?.styles?.colorOnBackground) {
        return theme.automated?.styles?.colorOnBackground;
    }
    if (appearance === Appearance.Secondary) {
        return Colors.GS1000;
    }
};
const getContainerDisabledBackground = (props) => {
    return tinycolor(getContainerBackground(props)).setAlpha(0.4).toRgbString();
};
const getContainerDisabledColor = ({ appearance, theme }) => {
    if (theme.automated?.styles?.colorOnPrimary) {
        return tinycolor
            .mix(theme.automated?.styles.colorBackground, theme.automated?.styles.colorOnPrimary, 40)
            .toHexString();
    }
    if (appearance === Appearance.Secondary) {
        return tinycolor(Colors.GS1000).setAlpha(0.4).toRgbString();
    }
    return Colors.GS100;
};
export const Container = styled.button((props) => {
    const { $loading, $multiline } = props;
    return css `
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${getContainerWidth(props)};
    height: ${getContainerHeight(props)}px;
    padding-left: ${getContainerHorizontalPadding(props)}px;
    padding-right: ${getContainerHorizontalPadding(props)}px;
    background: ${getContainerBackground(props)};
    outline: none;
    position: relative;

    border-radius: ${getAutomatedStyleProp('buttonCornerRadius')};

    white-space: nowrap;

    font-weight: ${getAutomatedStyleProp('fontWeightBaseBold', '600')};
    text-transform: ${getAutomatedStyleProp('textTransformBase')};

    font-size: 16px;
    font-family: inherit;
    line-height: 24px;
    color: ${getContainerColor(props)};

    appearance: none !important; // NOTE: Required for Safari.

    ${$multiline &&
        css `
      height: auto;
      min-height: ${getContainerHeight(props)}px;

      white-space: normal;
      word-break: break-all;
    `}
    ${!$loading &&
        css `
      &:hover {
        background: ${getContainerHoverBackground(props)};

        color: ${getContainerColor(props)};
      }

      &:active {
        background: ${getContainerActiveBackground(props)};

        color: ${getContainerActiveColor(props)};
      }

      &:focus {
        background: ${getContainerFocusBackground(props)};

        color: ${getContainerFocusColor(props)};

        &:after {
          content: '';
          position: absolute;
          padding: 2px;
          border: 1px solid ${getContainerFocusBackground(props)};
          width: 100%;
          height: 100%;
          border-radius: ${getAutomatedStyleProp('buttonCornerRadius')};
        }
      }
    `}
    &:disabled {
      cursor: not-allowed;

      ${!$loading &&
        css `
        background: ${getContainerDisabledBackground(props)};
        color: ${getContainerDisabledColor(props)};
      `}
    }
  `;
});
export const IconContainer = styled.div `
  display: flex;
  align-self: center;

  ${({ color }) => color &&
    css `
      color: ${color};
    `}

  + span {
    padding-left: 10px;
  }
`;
export const Label = styled.span `
  display: flex;

  > span + ${IconContainer} {
    padding-left: 10px;
  }

  ${({ $loading }) => $loading &&
    css `
      visibility: hidden;
    `}
`;
export const LoaderContainer = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;
