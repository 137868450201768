import { Colors, Heading as HeadingComponent, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;

  ${mediaQueries.tablet} {
    flex-direction: row;
  }
`;
export const Item = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  border-bottom: 1px solid ${Colors.GS400};
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  ${mediaQueries.tablet} {
    flex-direction: column;
    justify-content: center;
    flex: 1;
    gap: 20px;
    padding: 24px 24px 32px;
    position: relative;
    border-bottom: none;

    &:hover {
      background-color: ${Colors.GS300};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: ${Colors.GS400};
    }

    &:last-child::after {
      display: none;
    }
  }
`;
export const ItemImageContainer = styled.div `
  min-width: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 120 / 84;

  ${mediaQueries.tablet} {
    width: 100%;
  }
`;
export const Image = styled.img `
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${mediaQueries.tablet} {
    gap: 8px;
    align-items: center;
  }
`;
export const Heading = styled(HeadingComponent) `
  font-size: 16px;
`;
export const Subheading = styled.p `
  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GS600};
  text-align: left;

  ${mediaQueries.tablet} {
    font-size: 16px;
    text-align: center;
  }
`;
