import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${mediaQueries.tablet} {
    flex-direction: row;
    width: auto;
  }
`;
export const ButtonWrapper = styled.div `
  width: 100%;

  ${mediaQueries.tablet} {
    width: auto;
  }
`;
