import { DashboardLogo, LeavingGuard, ToolbarButton } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import React from 'react';
import { routing } from '@utils/routing';
import { ThemePreviewContent } from './components/ThemePreviewContent';
import { EditorPanel } from '../../components/EditorPanel';
import { EditorPanelLoader } from '../../components/EditorPanelLoader';
import { ThemeEditorLayout } from '../../components/ThemeEditorLayout/ThemeEditorLayout';
import { NavigationTabs } from '../NavigationTabs/NavigationTabs';
import { Theme } from './components/Theme/Theme';
import * as S from './styled';
import { NavbarToolbar } from './components/NavbarToolbar';
export const InstalledThemesView = ({ themeList, isLoadingThemeList, leavingGuardProps, selectedTheme, onAddThemeClick, onThemeClick, onThemeReset, onThemeDuplicate, onThemeRename, onThemeDelete, onThemeActivate, onChangelogDisplay, }) => {
    const renderThemeList = () => {
        if (isLoadingThemeList) {
            return (React.createElement(S.LoaderContainer, null,
                React.createElement(EditorPanelLoader, null)));
        }
        return (React.createElement(S.Container, null, themeList.map((theme) => (React.createElement(Theme, { key: theme.id, theme: theme, onClick: () => onThemeClick(theme), onActivate: () => onThemeActivate(theme), onDuplicate: () => onThemeDuplicate(theme), onRename: () => onThemeRename(theme), onReset: () => onThemeReset(theme), onDelete: () => onThemeDelete(theme), onChangelogDisplay: () => onChangelogDisplay(theme) })))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(LeavingGuard, { ...leavingGuardProps }),
        React.createElement(ThemeEditorLayout, { logo: React.createElement(DashboardLogo, { to: routing.home }), editorPanel: React.createElement(EditorPanel, null,
                React.createElement(NavigationTabs, null),
                React.createElement(EditorPanel.Header, { title: "Your themes", actionContent: React.createElement(ToolbarButton, { label: "Add new theme", icon: PlusIcon, onClick: onAddThemeClick }) }),
                renderThemeList()), navbarContent: React.createElement(NavbarToolbar, { isThemeMain: selectedTheme?.isMain }), content: React.createElement(ThemePreviewContent, { shopUrl: selectedTheme?.previewUrl, isThemeMain: !!selectedTheme?.isMain }) })));
};
