import styled from 'styled-components';
export const Row = styled.div `
  display: flex;
  align-items: center;

  a,
  button {
    margin: 0 3px;
  }
`;
export const LoaderWrapper = styled.div `
  margin-left: 5px;
`;
