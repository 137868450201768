import { PRODUCT_PRICE_MAX } from '@fourthwall/utils/lib/constants';
import React from 'react';
import { CurrencyInput } from '../../../../inputs/CurrencyInput';
import * as S from './styled';
export const SellingPriceInput = ({ value, onValueChange, onBlur }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.Label, null, "Your selling price"),
        React.createElement(S.InputContainer, null,
            React.createElement(CurrencyInput, { size: "small", fullWidth: true, min: 0, max: PRODUCT_PRICE_MAX, value: value, onValueChange: onValueChange, onBlur: onBlur }))));
};
