import { getBaseApiUrl } from '@fourthwall/env';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
import { useCreatorIntegrationsV2Query } from '@queries';
export const useTwitterShops = () => {
    const { creatorIntegrationsV2Query } = useCreatorIntegrationsV2Query();
    const { currentShop } = useCurrentShop();
    const twitterShopsApp = creatorIntegrationsV2Query.data?.apps.find((app) => app.app === 'TWITTER_SHOP');
    return {
        status: twitterShopsApp?.status,
        productsLink: `${getBaseApiUrl('api')}/twitter/shop/${currentShop?.id}/products.xml`,
    };
};
