import { ApiBase } from './ApiBase';
class OffersV2ApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.assignBulkImages = (pathParams, queryParams, payload) => {
            return this.put(`/v2/offers/${pathParams.offerId}/bulk/images`, payload, queryParams);
        };
    }
}
export const OffersV2ApiClient = new OffersV2ApiClientModel();
