import styled, { css } from 'styled-components';
export const WeightWrapper = styled.div(({ active }) => css `
    position: relative;

    ${active &&
    css `
      z-index: 2;
    `}
  `);
export const UnitWrapper = styled.div(({ active }) => css `
    position: relative;

    margin-left: -1px;

    ${active &&
    css `
      z-index: 2;
    `}
  `);
