import { GlobalStyle, HeadContent, MediaQueryProvider, PTMonoFont, SuisseIntlFont, SuspenseFallback, ToastsProvider, } from '@fourthwall/components';
import 'normalize.css';
import React, { Suspense } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { GlobalStyles } from './components/assets/GlobalStyles';
import { WebviewEventsBusProvider } from './contexts/WebviewEventsBusContext';
import { App } from './modules/App';
import { queryClient } from './queryClient';
export const Root = () => (React.createElement(React.Fragment, null,
    React.createElement(GlobalStyle, null),
    React.createElement(SuisseIntlFont, null),
    React.createElement(PTMonoFont, null),
    React.createElement(GlobalStyles, null),
    React.createElement(HeadContent, { titleTemplate: "%s \u2013 Dashboard", defaultTitle: "Dashboard" }),
    React.createElement(Suspense, { fallback: React.createElement(SuspenseFallback, null) },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(MediaQueryProvider, null,
                React.createElement(ToastsProvider, null,
                    React.createElement(BrowserRouter, null,
                        React.createElement(QueryParamProvider, { adapter: ReactRouter6Adapter },
                            React.createElement(WebviewEventsBusProvider, null,
                                React.createElement(App, null)))))),
            React.createElement(ReactQueryDevtools, { position: "bottom-right" })))));
