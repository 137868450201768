import { LocalStorage } from '@fourthwall/utils/lib/LocalStorage';
const LOCAL_STORAGE_KEY = 'fourthwall.dashboard';
const localStorage = new LocalStorage();
export const useDashboardStorage = () => {
    const dashboardStorage = {
        get: (key) => {
            const store = localStorage.getObjectItem(LOCAL_STORAGE_KEY);
            return store ? store[key] : undefined;
        },
        set: (key, value) => {
            const store = localStorage.getObjectItem(LOCAL_STORAGE_KEY);
            return localStorage.setObjectItem(LOCAL_STORAGE_KEY, { ...store, [key]: value });
        },
    };
    return { dashboardStorage };
};
