import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { SECTION_NAMES } from '@constants/sectionNames';
import { useCreatorIntegrationsV2Query } from '@queries';
import { routing } from '@utils/routing';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { thumbnails } from '../../assets/thumbnails';
import { LearnMore } from '../../components/LearnMore';
import { useInstagram } from '../../modules/AppsInstagram';
import { useTiktok } from '../../modules/AppsTiktok';
import { mapStatusToTagProps } from '../../utils';
import { CATEGORIES, CATEGORY_LABELS } from './categories';
import { isConnected } from './utils';
export const useApps = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { instagram } = useInstagram(); // INSTAGRAM_FEED
    const { tiktok } = useTiktok(); // TIKTOK_FEED
    const { creatorIntegrationsV2Query } = useCreatorIntegrationsV2Query();
    const [categoryParam, setCategoryParam] = useQueryParam('c', StringParam);
    const findApp = (app) => {
        return creatorIntegrationsV2Query.data?.apps.find((item) => item.app === app);
    };
    const allApps = [
        {
            category: 'social_commerce',
            status: findApp('YOUTUBE_PRODUCT_SHELF')?.status,
            connected: isConnected(findApp('YOUTUBE_PRODUCT_SHELF')?.status),
            title: SECTION_NAMES.appYoutubeProductShelf,
            description: 'Promote featured products from your shop on your YouTube channels.',
            docs: routing.external.helpCenter.youtubeProductShelf,
            thumbnail: thumbnails.youtubeThumbnail,
            to: routing.apps.youTubeProductShelf,
        },
        {
            category: 'social_commerce',
            status: findApp('TIKTOK_SHOP')?.status,
            connected: isConnected(findApp('TIKTOK_SHOP')?.status),
            title: SECTION_NAMES.appTikTokShop,
            description: 'Showcase and sell products from your shop directly to your fans on TikTok.',
            docs: routing.external.helpCenter.tiktokShop,
            thumbnail: thumbnails.tiktokThumbnail,
            to: routing.apps.tiktokShop,
        },
        {
            category: 'social_commerce',
            status: findApp('INSTAGRAM_SHOP')?.status,
            connected: isConnected(findApp('INSTAGRAM_SHOP')?.status),
            title: SECTION_NAMES.appMeta,
            description: 'Showcase and sell products from your shop directly to your fans on Instagram and Facebook.',
            thumbnail: thumbnails.metaThumbnail,
            to: routing.apps.meta,
            hidden: !dashboardFeatureFlags.integrationMeta.enabled,
        },
        {
            category: 'social_commerce',
            status: findApp('TWITTER_SHOP')?.status,
            connected: isConnected(findApp('TWITTER_SHOP')?.status),
            title: SECTION_NAMES.appTwitterShops,
            description: 'Show off your products on your X (Twitter) profile.',
            thumbnail: thumbnails.twitterShopsThumbnail,
            to: routing.apps.twitterShops,
        },
        {
            category: 'social_commerce',
            status: findApp('TWITCH_GIFTING')?.status,
            connected: isConnected(findApp('TWITCH_GIFTING')?.status),
            title: SECTION_NAMES.appTwitchGifting,
            description: "Allow your viewers to gift products and memberships for chat when you're live.",
            docs: routing.external.helpCenter.twitchGifting,
            thumbnail: thumbnails.twitchGiftingThumbnail,
            to: routing.apps.twitch,
        },
        {
            category: 'social_commerce',
            status: findApp('TWITCH_DISCOUNTS_FOR_SUBS')?.status,
            connected: isConnected(findApp('TWITCH_DISCOUNTS_FOR_SUBS')?.status),
            title: 'Discounts for Twitch subs',
            description: 'Offer Twitch subscribers discounts on membership tiers.',
            docs: routing.external.helpCenter.twitchMembershipsIntegrations,
            thumbnail: thumbnails.twitchMembershipsThumbnail,
            to: routing.apps.twitchMembershipsDiscounts,
        },
        {
            category: 'alerts',
            status: findApp('STREAMELEMENTS')?.status,
            connected: isConnected(findApp('STREAMELEMENTS')?.status),
            title: SECTION_NAMES.appStreamElements,
            description: 'Show stream alerts for product purchases and gifts via StreamElements.',
            docs: routing.external.helpCenter.streamAlerts,
            thumbnail: thumbnails.streamelementsThumbnail,
            to: routing.apps.streamelements,
        },
        {
            category: 'alerts',
            status: findApp('STREAMLABS')?.status,
            connected: isConnected(findApp('STREAMLABS')?.status),
            title: SECTION_NAMES.appStreamlabs,
            description: 'Show stream alerts for product purchases and gifts via Streamlabs.',
            docs: routing.external.helpCenter.streamAlerts,
            thumbnail: thumbnails.streamlabsThumbnail,
            to: routing.apps.streamlabs,
        },
        {
            category: 'memberships',
            status: findApp('FWDISCORD')?.status,
            connected: isConnected(findApp('FWDISCORD')?.status),
            title: 'Members-only Discord server',
            description: 'Allows you to offer access to your Discord server with your members.',
            thumbnail: thumbnails.discordThumbnail,
            to: routing.apps.discord,
            hidden: !dashboardFeatureFlags.memberships.enabled,
        },
        {
            category: 'social_feeds',
            status: tiktok.status,
            connected: isConnected(tiktok.status),
            title: SECTION_NAMES.appTikTok,
            description: 'Highlight TikTok videos on your homepage and share thank you notes to your TikTok account.',
            thumbnail: thumbnails.tiktokThumbnail,
            to: routing.apps.tiktok,
        },
        {
            category: 'social_feeds',
            status: instagram.status,
            connected: isConnected(instagram.status),
            title: SECTION_NAMES.appInstagram,
            description: 'Showcase your Instagram feed on your homepage.',
            thumbnail: thumbnails.instagramThumbnail,
            to: routing.apps.instagram,
        },
        {
            category: 'email_marketing',
            status: findApp('KLAVIYO')?.status,
            connected: isConnected(findApp('KLAVIYO')?.status),
            title: 'Klaviyo',
            description: 'Use your Klaviyo account to sync support emails and send custom emails.',
            docs: routing.external.helpCenter.klaviyo,
            thumbnail: thumbnails.klaviyoThumbnail,
            to: routing.apps.klaviyo,
        },
        {
            category: 'email_marketing',
            status: findApp('BEEHIIV')?.status,
            connected: isConnected(findApp('BEEHIIV')?.status),
            title: 'beehiiv',
            description: 'Use your beehiiv account to sync supporter emails and newsletter signups.',
            docs: routing.external.helpCenter.beehiiv,
            thumbnail: thumbnails.beehiivThumbnail,
            to: routing.apps.beehiiv,
        },
        {
            category: 'email_marketing',
            status: findApp('LAYLO')?.status,
            connected: isConnected(findApp('LAYLO')?.status),
            title: 'Laylo',
            description: 'Use Laylo to announce and promote your drops directly to your supporters.',
            docs: routing.external.helpCenter.laylo,
            thumbnail: thumbnails.layloThumbnail,
            to: routing.apps.laylo,
        },
        {
            category: 'shipping',
            status: findApp('SHIPSTATION')?.status,
            connected: isConnected(findApp('SHIPSTATION')?.status),
            title: 'ShipStation',
            description: 'Manage the shipping experience of products you’re responsible for fulfilling.',
            docs: routing.external.helpCenter.shipStationIntegration,
            thumbnail: thumbnails.shipstationThumbnail,
            to: routing.apps.shipStation,
        },
        // NOTE: Use following thumbnail in case it's missing for a new app.
        {
            category: 'social_commerce',
            status: 'CONNECTED',
            connected: true,
            title: 'App fallback',
            description: 'Very cool and short description for an app goes here.',
            docs: 'https://help.fourthwall.com/',
            thumbnail: thumbnails.fallbackThumbnail,
            to: routing.apps.self,
            hidden: true,
        },
    ];
    const enabledApps = allApps.filter((app) => !app.hidden);
    const displayedApps = enabledApps.filter((app) => {
        return categoryParam ? app.category === categoryParam : true;
    });
    const displayedCategories = CATEGORIES.filter((category) => {
        return enabledApps.some((app) => app.category === category);
    });
    const tabs = {
        options: [
            { label: 'All', value: '' },
            ...displayedCategories.map((category) => ({
                label: CATEGORY_LABELS[category],
                value: category,
            })),
        ],
        value: categoryParam || '',
        onChange: (value) => setCategoryParam(value),
    };
    const apps = displayedApps.map((app) => ({
        ...app,
        description: (React.createElement(React.Fragment, null,
            app.description,
            " ",
            app.docs && React.createElement(LearnMore, { href: app.docs }))),
        tag: mapStatusToTagProps(app.status, false),
    }));
    return {
        tabs,
        apps,
        isAnyAppConnected: enabledApps.some((item) => item.connected),
    };
};
