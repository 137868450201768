import { BackButton, Dropdown, HeadContent, SaveShortcutListener, ToolbarButton, useMediaQueryContext, } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { SideSummary } from '../../../../../components/common/SideSummary';
import { routing } from '../../../../../utils/routing';
import { ConfirmBarContainer } from '../../../ConfirmBar/ConfirmBarContainer';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { validationSchema } from './consts';
import { useDeleteSingleSeries } from './hooks/useDeleteSingleSeries';
import { useSingleSeries } from './hooks/useSingleSeries';
import { useSingleSeriesUpdate } from './hooks/useSingleSeriesUpdate';
import { ArtworkFields } from './modules/ArtworkFields';
import { DetailsFields } from './modules/DetailsFields';
import { VideosFields } from './modules/VideosFields';
import { SingleSeriesSkeleton } from './SingleSeriesSkeleton';
export const SingleSeries = () => {
    const media = useMediaQueryContext();
    const { singleSeriesId = '' } = useParams();
    const { singleSeries } = useSingleSeries(Number(singleSeriesId));
    const { updateSingleSeries, isLoading } = useSingleSeriesUpdate(singleSeriesId);
    const { deleteSingleSeries } = useDeleteSingleSeries();
    const { currentShopQuery } = useCurrentShopQuery();
    if (!singleSeries || !currentShopQuery.data) {
        return React.createElement(SingleSeriesSkeleton, null);
    }
    const initialValues = {
        title: singleSeries.title,
        description: singleSeries.description,
        coverImage: singleSeries.coverImage?.url || '',
    };
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: updateSingleSeries }, ({ dirty, submitForm, resetForm }) => (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: singleSeries.title }),
        React.createElement(PageLayout, { leftContent: React.createElement(React.Fragment, null,
                React.createElement(DetailsFields, null),
                React.createElement(ArtworkFields, null),
                React.createElement(VideosFields, null)), rightContent: React.createElement(SideSummary, null,
                React.createElement(SideSummary.Item, { title: "Stats" }, "Coming soon")) },
            React.createElement(Dashboard.ItemViewHeader, { name: singleSeries.title, backButton: React.createElement(BackButton, { label: "Video series", to: {
                        pathname: routing.memberships.series.self,
                        search: location.search,
                    } }), toolbar: React.createElement(Dropdown, { content: React.createElement(Dropdown.Item, { label: "Delete", onClick: () => {
                            deleteSingleSeries(Number(singleSeriesId));
                        } }), position: "bottom-end" },
                    React.createElement(ToolbarButton, { label: "Settings", icon: DotsHorizontalIcon, size: media.tablet ? 'medium' : 'small' })), link: {
                    label: `${currentShopQuery.data.baseUri}/supporters/videos/series/${singleSeriesId}`,
                    url: `${currentShopQuery.data.baseUri}/supporters/videos/series/${singleSeriesId}`,
                } })),
        React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
        React.createElement(ConfirmBarContainer, { isOpen: dirty, onConfirm: submitForm, onCancel: resetForm, isLoading: isLoading })))));
};
