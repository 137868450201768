import styled from 'styled-components';
import { mediaQueries, hideScrollbar, Colors } from '@fourthwall/components';
export const Header = styled.div `
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const EmptyState = styled.div `
  padding: 80px 40px;
  background: ${Colors.GS200};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
`;
export const Nav = styled.nav `
  display: none;

  ${mediaQueries.tablet} {
    display: block;

    &:has(button:disabled + button:disabled) {
      display: none;
    }
  }
`;
export const Slider = styled.div `
  --n: 2;
  --gap: 24px;

  display: grid;
  gap: var(--gap);
  grid-auto-flow: column;
  grid-auto-columns: calc((100% - (var(--n) - 1) * var(--gap)) / var(--n));

  overflow-x: auto;
  scroll-snap-type: x mandatory;
  ${hideScrollbar};

  ${mediaQueries.tablet} {
    --n: 4;
  }

  ${mediaQueries.laptop} {
    --n: 5;
  }
`;
export const Slide = styled.a `
  display: block;

  scroll-snap-align: start end;
`;
export const Footer = styled.footer `
  margin-top: 24px;

  text-align: center;

  ${mediaQueries.laptop} {
    margin-top: 16px;
  }
`;
