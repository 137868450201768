import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: ${({ direction }) => direction};

  ${({ fullWidth }) => fullWidth &&
    css `
      width: 100%;
    `}

  ${({ justify }) => justify === 'right' &&
    css `
      justify-content: flex-end;
    `}

  ${({ align }) => align === 'center' &&
    css `
      align-items: center;
    `}
`;
const getButtonWrapperMargin = (space) => {
    if (space === 'small') {
        return 2;
    }
    if (space === 'medium') {
        return 8;
    }
    if (space === 'large') {
        return 16;
    }
    if (space === 'xlarge') {
        return 20;
    }
};
export const ButtonWrapper = styled.div `
  ${({ fullWidth }) => fullWidth &&
    css `
      width: 100%;
    `}

  ${({ direction, space }) => direction === 'column' &&
    css `
      margin-top: ${getButtonWrapperMargin(space)}px;

      &:first-child {
        margin-top: 0;
      }
    `}

  ${({ direction, space }) => direction === 'column-reverse' &&
    css `
      margin-bottom: ${getButtonWrapperMargin(space)}px;

      &:first-child {
        margin-bottom: 0;
      }
    `}

  ${({ direction, space }) => direction === 'row' &&
    css `
      margin-left: ${getButtonWrapperMargin(space)}px;

      &:first-child {
        margin-left: 0;
      }
    `}

  ${({ direction, space }) => direction === 'row-reverse' &&
    css `
      margin-right: ${getButtonWrapperMargin(space)}px;

      &:first-child {
        margin-right: 0;
      }
    `}

  ${({ align }) => align === 'stretch' &&
    css `
      width: 100%;
    `}
`;
