import styled, { css } from 'styled-components';
import { Colors } from '../../../colors';
import { getAutomatedStyleProp } from '../../../providers/ComponentsThemeProvider';
import { mediaQueries } from '../../../providers/MediaQueryProvider';
export const Container = styled.li `
  font-size: ${({ size }) => (size === 'tiny' ? '14px' : '16px')};
  line-height: 150%;
  color: ${getAutomatedStyleProp('colorOnBackground', Colors.GS1000)};

  & + & {
    margin-top: 12px;
  }
`;
export const Row = styled.div `
  display: flex;
  align-items: center;
`;
export const ImageContainer = styled.div `
  margin-right: 12px;
  background: ${getAutomatedStyleProp('paleBackground', Colors.GS200)};
  display: flex;
  align-items: center;

  ${({ size }) => size === 'tiny' &&
    css `
      width: 33px;
      min-height: 44px;
    `};

  ${({ size }) => size === 'small' &&
    css `
      width: 56px;
      min-height: 74px;
      font-size: 32px;

      ${mediaQueries.laptop} {
        margin-right: 20px;
      }
    `};

  ${({ size }) => size === 'medium' &&
    css `
      width: 66px;
      min-height: 88px;
      margin-right: 20px;
      font-size: 36px;
    `};

  ${({ size }) => size === 'large' &&
    css `
      width: 60px;
      min-height: 80px;
      font-size: 42px;

      ${mediaQueries.laptop} {
        width: 80px;
        min-height: 106px;
        margin-right: 16px;
      }
    `};

  img,
  svg {
    width: 100%;
    border-radius: ${getAutomatedStyleProp('imageCornerRadius')};
  }
`;
export const Placeholder = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getAutomatedStyleProp('pale', Colors.GS400)};
`;
export const Donation = styled.div `
  flex: 1;
  text-align: center;
  user-select: none;
  color: ${getAutomatedStyleProp('paleTextColor', Colors.GS600)};
`;
export const Description = styled.div `
  flex: 1;
  margin-right: 12px;

  overflow: hidden;

  ${mediaQueries.laptop} {
    margin-right: 16px;
  }
`;
export const TagContainer = styled.div `
  margin-top: 4px;
  display: flex;
`;
export const Variant = styled.div `
  color: ${getAutomatedStyleProp('paleTextColor', Colors.GS600)};
`;
export const Downloads = styled.div `
  margin-top: 4px;

  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  overflow: hidden;

  /* NOTE: This is needed due to some printing issues in Chrome browser when having this style
  enabled. This element is not needed on the printed page so it doesn't break anything. Check
  SLA-156 issue to get more details. We might want to revert this change at some point. */
  @media print {
    gap: initial;
  }
`;
export const DownloadsItem = styled.div `
  overflow: hidden;

  ${mediaQueries.tablet} {
    display: flex;
  }
`;
export const DownloadsItemName = styled.div `
  display: flex;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const DownloadsItemNameIcon = styled.div `
  margin-right: 8px;
`;
export const DownloadsItemNameText = styled.div `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const DownloadsItemDownload = styled.div `
  margin-top: 4px;

  ${mediaQueries.tablet} {
    margin-top: 0;
    margin-left: 8px;
  }
`;
export const Quantity = styled.div `
  margin-right: 12px;

  ${mediaQueries.laptop} {
    margin-right: 16px;
  }
`;
export const Price = styled.div `
  flex: 0 0 68px;

  text-align: right;
`;
