import isEmpty from 'lodash-es/isEmpty';
import omitBy from 'lodash-es/omitBy';
import React from 'react';
import { Helmet } from 'react-helmet';
import { isWebView } from '@fourthwall/utils';
export const HeadContent = ({ meta, title, titleTemplate, defaultTitle, stylesUrl, children, }) => {
    const props = omitBy({ title, titleTemplate, defaultTitle, meta }, isEmpty);
    return (React.createElement(Helmet, { ...props },
        stylesUrl && React.createElement("link", { rel: "stylesheet", href: stylesUrl }),
        isWebView() && (React.createElement("meta", { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" })),
        children));
};
