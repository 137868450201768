import { Fieldset, Toasts, Upload } from '@fourthwall/components';
import { fileToBase64 } from '@fourthwall/utils/lib/file';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React, { useState } from 'react';
import { getRejectionErrors } from '@utils/dropzone';
import { RemovableUpload, UploadedImage } from '../../../../../../components';
import { FormFields } from '../../types';
import * as S from './styled';
export const Image = ({ imageSrc }) => {
    const formik = useFormikContext();
    const [initialImage, setInitialImage] = useState(imageSrc);
    const handleFileDrop = async (files) => {
        const file = files?.[0];
        if (file) {
            const base64 = await fileToBase64(file);
            formik.setFieldValue(FormFields.image, base64);
            formik.setFieldValue(FormFields.imageFile, file);
            formik.setFieldValue(FormFields.removeImage, false);
        }
    };
    const handleFileDropRejected = (rejectedFiles) => {
        getRejectionErrors(rejectedFiles, {
            'file-too-large': 'File is larger than 10 megabytes',
        }).forEach((rejection) => {
            Toasts.notify(rejection);
        });
    };
    const renderUpload = () => {
        const image = formik.values.image || initialImage;
        if (image) {
            return (React.createElement(RemovableUpload, { onRemove: () => {
                    formik.setFieldValue(FormFields.image, '');
                    setInitialImage(undefined);
                    formik.setFieldValue(FormFields.removeImage, true);
                } },
                React.createElement(UploadedImage, { image: image })));
        }
        return (React.createElement(Upload, { accept: {
                'image/*': [],
            }, iconSize: 20, orientation: "horizontal", title: "", maxSize: 10485760, onFileDrop: handleFileDrop, onFileDropRejected: handleFileDropRejected }));
    };
    return (React.createElement(Fieldset.Row, null,
        React.createElement(Fieldset.Item, null,
            React.createElement(S.ImageInputContainer, null,
                React.createElement(S.UploadContainer, null, renderUpload()),
                "Tier image (optional)"))));
};
