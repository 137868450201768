import React, { useMemo } from 'react';
import * as S from './UploadedImage.styled';
export const UploadedImage = ({ image, objectFit = 'cover' }) => {
    const src = useMemo(() => {
        if (typeof image === 'string') {
            return image;
        }
        return URL.createObjectURL(image);
    }, [image]);
    return React.createElement(S.Image, { src: src, "data-testid": "UploadedImage", objectFit: objectFit });
};
