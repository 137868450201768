import { DndContext, KeyboardSensor, MouseSensor, PointerSensor, closestCenter, useSensor, useSensors, } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, sortableKeyboardCoordinates, } from '@dnd-kit/sortable';
import { Loading } from '@fourthwall/components';
import { arrayMove } from '@fourthwall/utils/lib/array';
import React, { useEffect, useState } from 'react';
import { usePerksContext } from '../../hooks/usePerksContext';
import { Perk } from '../Perk';
import * as S from './styled';
export const Perks = () => {
    const { perks, sortPerk, isLoading } = usePerksContext();
    const [innerPerks, setInnerPerks] = useState(perks);
    useEffect(() => {
        setInnerPerks(perks);
    }, [perks]);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    if (!innerPerks)
        return (React.createElement("div", { style: { height: 48 } },
            React.createElement(Loading, { isLoading: true, size: "medium" })));
    const handleDragEnd = ({ active, over }) => {
        const oldIndex = innerPerks.findIndex((perk) => perk.id === active.id);
        const newIndex = innerPerks.findIndex((perk) => perk.id === over?.id);
        if (newIndex < 0)
            return;
        if (newIndex === oldIndex)
            return;
        setInnerPerks(arrayMove([...innerPerks], oldIndex, newIndex));
        sortPerk(innerPerks[oldIndex].id, oldIndex, newIndex);
    };
    return (React.createElement(Loading, { isLoading: isLoading, size: "medium" },
        React.createElement(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragEnd: handleDragEnd },
            React.createElement(SortableContext, { items: innerPerks, strategy: rectSortingStrategy },
                React.createElement(S.PerksContainer, null, innerPerks.map((perk, index) => (React.createElement(S.PerkContainer, { key: index },
                    React.createElement(S.Placeholder, null),
                    React.createElement(Perk, { perk: perk })))))))));
};
