import { isWebView } from '@fourthwall/utils';
import { createGlobalStyle } from 'styled-components';
import { Colors } from '../colors';
export const GlobalStyle = createGlobalStyle `
  * {
    margin: 0;
    box-sizing: border-box;
    border: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  body {
    background: ${Colors.GS0};

    font-family: "Suisse Int'l", Helvetica, Arial, sans-serif;
    color: ${Colors.GS1000};

    user-select: ${isWebView() ? 'none' : 'initial'};
  }

  button {
    padding: 0;
    background: none;
    border: none;

    color: inherit;

    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;

    font-weight: 400;
  }

  img,
  svg {
    display: block;

    user-drag: none;
  }

  fieldset {
    border: none;
    padding: 0;
  }

  input {
    border-radius: 0;
    font: inherit;
  }

  input[type='email'],
  input[type='text'] {
    -webkit-appearance: none;
  }

  textarea {
    font: inherit;
  }

  label {
    cursor: inherit;
  }

  ul,
  ol {
    padding: 0;

    list-style: none;
  }

  ::-moz-selection {
    background: ${Colors.B500};

    color: ${Colors.GS100};
  }

  ::selection {
    background: ${Colors.B500};

    color: ${Colors.GS100};
  }
`;
