import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const Subtitle = styled.div `
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.GS600};
  text-align: center;

  a {
    text-decoration: underline;

    &:hover {
      color: ${Colors.GS1000};
    }
  }
`;
export const ButtonWrapper = styled.div `
  margin-top: 24px;
  margin-bottom: 24px;
`;
