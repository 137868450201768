import { Button, ButtonGroup, ToolbarButton, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Dashboard } from '../../../components/common/Dashboard';
import { NavTabs } from '../../../components/common/NavTabs';
import { PageLayout } from '../../../components/common/PageLayout';
import { useDashboardPermissions } from '../../../components/utils/DashboardPermissions';
import { routing } from '../../../utils/routing';
import { CreatePromotionModal } from './components/CreatePromotionModal';
import { PromotionCodesFilter } from './modules/PromotionCodes';
export const Promotions = ({ element }) => {
    const { open } = useModalContext();
    const media = useMediaQueryContext();
    const { hasPermission } = useDashboardPermissions();
    const location = useLocation();
    const shouldRenderFilters = location.pathname === routing.promotions.promotionCodes.self &&
        hasPermission('promotions.shopPromoCodes');
    const handleCreatePromotionClick = () => open(CreatePromotionModal);
    const getToolbarContent = () => {
        if (!media.tablet) {
            return (React.createElement(ButtonGroup, { space: "medium" },
                shouldRenderFilters && React.createElement(PromotionCodesFilter, null),
                React.createElement(ToolbarButton, { label: "Create promotion", icon: PlusIcon, onClick: handleCreatePromotionClick })));
        }
        return (React.createElement(ButtonGroup, { space: "medium" },
            shouldRenderFilters && React.createElement(PromotionCodesFilter, null),
            React.createElement(Button, { label: "Create promotion", appearance: "primary", size: "small", onClick: handleCreatePromotionClick })));
    };
    const getNavbarTabs = () => {
        const tabs = [];
        if (hasPermission('promotions.shopPromoCodes')) {
            tabs.push({
                label: 'Promo codes',
                to: routing.promotions.promotionCodes.self,
            });
        }
        if (hasPermission('promotions.giveawayLinks')) {
            tabs.push({
                label: 'Giveaway links',
                to: routing.promotions.giveawayPackages.self,
            });
        }
        return tabs;
    };
    return (React.createElement(PageLayout, { size: "large" },
        React.createElement(Dashboard.Section, { name: "Promotions", toolbarContent: getToolbarContent() },
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(NavTabs, { tabs: getNavbarTabs(), tabComponent: NavLink })),
            element)));
};
