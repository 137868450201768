import { Skeleton } from '@fourthwall/components';
import React from 'react';
import { PageLayout } from '../../../../../components/common/PageLayout';
import * as S from './Post.styled';
export const PostViewSkeleton = () => {
    return (React.createElement(PageLayout, { rightContent: React.createElement(Skeleton, null,
            React.createElement(Skeleton.Shape, { width: "100%", height: 260 })), leftContent: React.createElement(Skeleton, { gap: 8 },
            React.createElement(Skeleton.Shape, { width: 150, height: 22 }),
            React.createElement(Skeleton.Shape, { width: "100%", height: 60 }),
            React.createElement(Skeleton.Shape, { width: "100%", height: 250 })) },
        React.createElement(S.HeaderContainer, null,
            React.createElement(Skeleton, { gap: 8 },
                React.createElement(Skeleton.Shape, { width: 150, height: 22 }),
                React.createElement(Skeleton.Shape, { width: 300, height: 40 }),
                React.createElement(Skeleton.Shape, { width: 450, height: 22 })))));
};
