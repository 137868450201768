export var Offer;
(function (Offer) {
    Offer.FULFILLING_SERVICES = [
        'BOLT_STITCH',
        'CHIVA_TOYS',
        'COSMOPOLITAN',
        'CTC',
        'DIGITAL_ITEM',
        'DOD',
        'DUBOW',
        'DUBOW_ORDER_DESK',
        'FULFILLED_BY_CREATOR',
        'FULFILLED_BY_FOURTHWALL',
        'FULFILLED_BY_MANUFACTURER',
        'GNARLYWOOD',
        'HOMEMADE_MERCH',
        'KNITWISE',
        'MOGUL_MERCH_LOCAL',
        'MOGUL_MERCH_SHIP_BOB',
        'MWW',
        'OFFLINE_TV_SHIPSTATION',
        'OPTIGER',
        'OVERCAST',
        'P3',
        'P3_ORDER_DESK',
        'PRINTFUL',
        'RVLT_SHIP_HERO',
        'SEVENTY_EIGHT_STRANGE',
        'SHIP_MONK',
        'SPOKE',
        'SUPER_CULTURE',
        'TRIBE',
        'USOURCED',
        'WHIPLASH_3PL',
        'WHIPLASH_FREE',
        'WLM',
        'WLM_3PL',
    ];
    Offer.FULFILLING_SERVICES_WITH_SAMPLES = [
        'PRINTFUL',
        'SPOKE',
        'DUBOW_ORDER_DESK',
        'WHIPLASH_3PL',
        'WHIPLASH_FREE',
        'KNITWISE',
    ];
    Offer.MANUFACTURING_SERVICES = [
        'FOURTHWALL_BESPOKE',
        'MANUFACTURED_BY_CREATOR',
        'SPOKE',
        'PRINTFUL',
        'WLM',
        'DIGITAL_ITEM',
        'DUBOW',
        'DUBOW_DTFX',
        'TRIBE',
        'OPTIGER',
        'OVERCAST',
        'MWW',
        'P3',
        'DEMO_PRODUCT',
        'KNITWISE',
        'DOD',
    ];
    Offer.OFFER_STATUSES = ['HIDDEN', 'PUBLIC', 'ARCHIVED'];
    Offer.OFFER_STATUSES_WITH_SAMPLES = ['PUBLIC', 'HIDDEN'];
})(Offer || (Offer = {}));
