import { Offer } from '@fourthwall/services/lib/models/offer';
const VALID_MANUFACTURER_FULFILLER_PAIRS = [
    ...['FOURTHWALL_BESPOKE', 'MANUFACTURED_BY_CREATOR', 'DIGITAL_ITEM', 'DEMO_PRODUCT'].flatMap((manufacturer) => Offer.FULFILLING_SERVICES.map((fulfiller) => ({
        fulfiller,
        manufacturer,
    }))),
    { manufacturer: 'SPOKE', fulfiller: 'SPOKE' },
    { manufacturer: 'PRINTFUL', fulfiller: 'PRINTFUL' },
    { manufacturer: 'WLM', fulfiller: 'WLM' },
    { manufacturer: 'DUBOW', fulfiller: 'DUBOW' },
    { manufacturer: 'TRIBE', fulfiller: 'TRIBE' },
    { manufacturer: 'OPTIGER', fulfiller: 'OPTIGER' },
    { manufacturer: 'OVERCAST', fulfiller: 'OVERCAST' },
    { manufacturer: 'MWW', fulfiller: 'MWW' },
    { manufacturer: 'P3', fulfiller: 'P3' },
];
export const useManufacturerFulfillerPairs = () => {
    const validateManufacturerFulfillerPair = (value) => VALID_MANUFACTURER_FULFILLER_PAIRS.some(({ manufacturer, fulfiller }) => manufacturer === value.manufacturer && fulfiller === value.fulfiller);
    return {
        validateManufacturerFulfillerPair,
    };
};
