import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
import { DASHBOARD_STYLED_CONSTS } from '../../../../../components/common/Dashboard/consts';
import { PAGE_LAYOUT_STYLED_CONSTS } from '../../../../../components/common/PageLayout/consts';
export const Container = styled.div `
  width: 100%;
  height: ${DASHBOARD_STYLED_CONSTS.HEADER_HEIGHT}px;
  background: ${Colors.GS1000};

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10; // TODO: This cannot stay as it is.

  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
  transition: transform 0.1s;

  display: flex;
`;
export const LogoContainer = styled.div `
  display: none;

  ${mediaQueries.laptop} {
    width: ${DASHBOARD_STYLED_CONSTS.SIDEBAR_WIDTH}px;
    padding: 0 24px;

    display: flex;
    align-items: center;
  }
`;
export const Content = styled.div `
  max-width: ${PAGE_LAYOUT_STYLED_CONSTS.MAX_WIDTH_MEDIUM}px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: content-box;

  flex: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${mediaQueries.laptop} {
    padding: 0 ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL_LAPTOP}px;
  }
`;
export const Message = styled.div `
  flex: 1;

  color: ${Colors.GS100};
`;
