import React, { forwardRef } from 'react';
import { Icon } from '../../common/Icon';
import { Loader } from '../../common/Loader';
import * as S from './styled';
const UploadArea = forwardRef(({ children, ...props }, ref) => {
    return (React.createElement(S.ContentContainer, { ref: ref, ...props }, children));
});
export const UploadAreaContentWrapper = ({ children, accepted, rejected, invalid, disabled = false, size, }) => {
    return (React.createElement(S.DefaultContentContainer, { accepted: accepted, rejected: rejected, invalid: invalid, disabled: disabled, size: size },
        children,
        React.createElement(S.DefaultContentContainerBackground, null)));
};
export const UploadAreaLoader = ({ size, orientation }) => {
    return (React.createElement(S.LoaderContainer, null,
        React.createElement(S.Headline, { size: size, orientation: orientation }, "Uploading..."),
        React.createElement(Loader, null)));
};
export const UploadAreaHero = ({ orientation, size, children }) => {
    return (React.createElement(S.Headline, { orientation: orientation, size: size }, children));
};
export const UploadAreaIcon = ({ orientation, icon, iconSize }) => {
    return (React.createElement(S.IconContainer, { orientation: orientation },
        React.createElement(Icon, { component: icon, height: iconSize })));
};
export { UploadArea as Root, UploadAreaContentWrapper as ContentWrapper, UploadAreaLoader as Loader, UploadAreaHero as Hero, UploadAreaIcon as HeroIcon, };
