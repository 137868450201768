import { Button, HeadContent, Pagination, ToolbarButton, useMediaQueryContext, usePagination, } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import { SeriesData } from '@modules/Dashboard/Memberships/modules/Series/components/SeriesData';
import { useSeriesQuery } from '@modules/Dashboard/Memberships/modules/Series/hooks/useSeriesQuery';
import React from 'react';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { useDashboardPermissions } from '../../../../../components/utils/DashboardPermissions/useDashboardPermissions';
import { routing } from '../../../../../utils/routing';
export const Series = () => {
    const media = useMediaQueryContext();
    const { hasPermission } = useDashboardPermissions();
    const [currentPage, setCurrentPage] = usePagination();
    const { seriesQuery } = useSeriesQuery([{ page: currentPage, postsType: 'video', limit: 9 }]);
    const pageCount = seriesQuery.data?.pagination?.pages || 1;
    const renderToolbarContent = () => {
        if (!hasPermission('memberships.video.create'))
            return null;
        if (!media.tablet) {
            return (React.createElement(ToolbarButton, { label: "Add new video series", icon: PlusIcon, to: routing.memberships.series.create }));
        }
        return (React.createElement(Button, { size: "small", label: "Add new video series", appearance: "primary", to: routing.memberships.series.create }));
    };
    return (React.createElement(PageLayout, { size: "large" },
        React.createElement(Dashboard.Section, { name: "Video series", bottomMargin: "medium", toolbarContent: renderToolbarContent() },
            React.createElement(HeadContent, { title: "Video series" }),
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(SeriesData, { data: seriesQuery.data?.series ?? [], isLoading: seriesQuery.isLoading })),
            pageCount !== 1 && (React.createElement(Dashboard.Section.Item, null,
                React.createElement(Pagination, { pageCount: pageCount, currentPage: currentPage, onChange: setCurrentPage }))))));
};
