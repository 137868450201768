import { useState } from 'react';
export const useSessionStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.sessionStorage.getItem(key);
            return value ? JSON.parse(value) : initialValue;
        }
        catch {
            return initialValue;
        }
    });
    const setValue = (value) => {
        try {
            window.sessionStorage.setItem(key, JSON.stringify(value));
            setStoredValue(value);
        }
        catch { }
    };
    return [storedValue, setValue];
};
