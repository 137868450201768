import { Checkbox, ConfirmModal, Icon, Input, SortableList, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { CloseIcon } from '@fourthwall/icons';
import { arrayMove } from '@fourthwall/utils/lib/array';
import { Field } from 'formik';
import cloneDeep from 'lodash-es/cloneDeep';
import filter from 'lodash-es/filter';
import map from 'lodash-es/map';
import set from 'lodash-es/set';
import React from 'react';
import { ProductVariant } from '../ProductVariant';
import * as S from './styled';
const typesLabelMap = {
    COLOR: 'Add different colors',
    SIZE: 'Add different sizes',
    CUSTOM: 'Add custom variants',
    MANUFACTURER_PREVIEW: '',
};
const addInputLabelMap = {
    COLOR: 'Add color',
    SIZE: 'Add size',
    CUSTOM: 'Add variant',
    MANUFACTURER_PREVIEW: '',
};
const variesByPriceMap = {
    COLOR: 'Different colors have different prices',
    SIZE: 'Different sizes have different prices',
    CUSTOM: 'Different variants have different prices',
    MANUFACTURER_PREVIEW: '',
};
const variesByImageryMap = {
    COLOR: 'Different colors have different photography',
    SIZE: 'Different sizes have different photography',
    CUSTOM: 'Different variants have different photography',
    MANUFACTURER_PREVIEW: '',
};
const variesByWeightMap = {
    COLOR: 'Different colors have different weights',
    SIZE: 'Different sizes have different weights',
    CUSTOM: 'Different variants have different weights',
    MANUFACTURER_PREVIEW: '',
};
export const ProductVariantType = ({ index, variantType, shopWeightUnit, allValues, errors, setValues, }) => {
    const media = useMediaQueryContext();
    const { open } = useModalContext();
    const { options, type, variesBy } = variantType;
    const otherHasVaryingValue = (fieldName) => variantType.variesBy[fieldName] &&
        allValues.some((value) => value !== variantType && value.variesBy[fieldName]);
    const showWeightsInfo = otherHasVaryingValue('weight');
    const getDefaultWeightUnit = () => options[0]?.weight?.unit || shopWeightUnit;
    const createVariant = (newVariantName) => {
        if (newVariantName) {
            setValues({
                types: set(cloneDeep(allValues), `[${index}].options`, [
                    ...allValues[index].options,
                    {
                        name: newVariantName,
                        images: [],
                        colorSwatch: type === 'COLOR' ? '#000000' : undefined,
                        weight: variantType.variesBy.weight ? { unit: getDefaultWeightUnit() } : undefined,
                    },
                ]),
            });
        }
    };
    const removeVariant = (variantIndex) => {
        const filteredOptions = filter(allValues[index].options, (item, optionIndex) => optionIndex !== variantIndex);
        setValues({ types: set(cloneDeep(allValues), `[${index}].options`, filteredOptions) });
    };
    const removeVariantType = () => {
        setValues({ types: allValues.filter((value, valueIndex) => index !== valueIndex) });
    };
    const handleVariantRemove = (variantIndex) => {
        const variant = variantType.options[variantIndex];
        if (variant) {
            open(ConfirmModal, {
                title: `Are you sure you want to delete ${variant.name} variant?`,
                confirmLabel: 'Yes, delete',
                cancelLabel: 'No, cancel',
                onConfirm: () => removeVariant(variantIndex),
            });
        }
    };
    const handleTypeRemove = () => {
        open(ConfirmModal, {
            title: `Are you sure you want to delete ${variantType.title} variant type?`,
            text: `This will permanently delete all ${variantType.title} variants`,
            confirmLabel: 'Yes, delete',
            cancelLabel: 'No, cancel',
            onConfirm: removeVariantType,
        });
    };
    const handleSortChange = (oldVariantIndex, newVariantIndex) => {
        const sortedArray = arrayMove(cloneDeep(allValues[index].options), oldVariantIndex, newVariantIndex);
        setValues({ types: set(cloneDeep(allValues), `[${index}].options`, sortedArray) });
    };
    const handleVariesByWeightChange = (value) => {
        if (value) {
            const updatedOptions = cloneDeep(allValues[index].options).map((option) => set(option, 'weight.unit', getDefaultWeightUnit()));
            const valuesWithUpdatedWeight = cloneDeep(set(allValues, `[${index}].variesBy.weight`, true));
            setValues({ types: set(valuesWithUpdatedWeight, `[${index}].options`, updatedOptions) });
        }
    };
    const sortableListFooter = (React.createElement(SortableList.FooterItem, null,
        React.createElement(S.AddInputContainer, { "data-testid": "ProductVariantType.AddInputContainer" },
            React.createElement(Input, { label: addInputLabelMap[type], onValueChange: createVariant, size: media.tablet ? 'large' : 'medium' }))));
    const renderSortableListItem = ({ id }, variantIndex) => (React.createElement(SortableList.Item, { key: variantIndex },
        React.createElement(ProductVariant, { variantTypeIndex: index, index: variantIndex, type: type, errors: errors, shouldAutofocusName: !id, variesByWeight: variesBy.weight, showWeightsInfo: showWeightsInfo })));
    return (React.createElement(S.Container, null,
        React.createElement(S.RemoveContainer, { onClick: handleTypeRemove, "data-testid": "ProductVariantType.Remove" },
            React.createElement(Icon, { component: CloseIcon, height: 12 })),
        React.createElement(S.Label, null, typesLabelMap[type]),
        React.createElement(S.InputsContainer, null,
            React.createElement(S.InputWrapper, null,
                React.createElement(Field, { name: `types[${index}].title`, component: Input, displayError: false, label: "Title", maxLength: 80 }))),
        React.createElement(S.SortableListContainer, null,
            React.createElement(SortableList, { footer: sortableListFooter, onRemove: handleVariantRemove, onSortChange: handleSortChange }, map(options, renderSortableListItem))),
        React.createElement(S.CheckboxesContainer, null,
            type !== 'SIZE' && (React.createElement(S.CheckboxWrapper, null,
                React.createElement(Field, { name: `types[${index}].variesBy.price`, component: Checkbox, label: variesByPriceMap[type], size: "medium" }))),
            React.createElement(S.CheckboxWrapper, null,
                React.createElement(Field, { name: `types[${index}].variesBy.imagery`, component: Checkbox, label: variesByImageryMap[type], size: "medium" })),
            (type === 'SIZE' || type === 'CUSTOM') && (React.createElement(S.CheckboxWrapper, null,
                React.createElement(Field, { name: `types[${index}].variesBy.weight`, component: Checkbox, onValueChange: handleVariesByWeightChange, label: variesByWeightMap[type], size: "medium" }))))));
};
