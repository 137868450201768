import { useAuth } from '@fourthwall/auth';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { routing } from '@utils/routing';
import logoSrc from '../../../assets/signets/discord.svg';
import { useCreatorIntegrationsQuery } from '../../../queries/useCreatorIntegrationsQuery';
export const useVerifyDiscord = () => {
    const { keycloak } = useAuth();
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const returnUrl = `${window.location.origin}${routing.settings.samples.self}`;
    const link = CreatorIntegrationsApiClient.appAuthorizationUrl({
        app: 'fwdiscord',
        token: keycloak?.token,
        returnUrl,
    });
    const alreadyVerified = creatorIntegrationsQuery.data?.fwdiscord.status === 'CONNECTED' || false;
    const openVerificationLink = () => window.location.assign(link);
    const getConnectBarProps = () => {
        if (creatorIntegrationsQuery.isSuccess) {
            return {
                description: 'Verify and join Discord',
                logoSrc,
                connectButtonLabel: alreadyVerified ? 'Verified' : 'Verify',
                disabled: alreadyVerified,
                onConnect: openVerificationLink,
            };
        }
    };
    return {
        alreadyVerified,
        openVerificationLink,
        connectBarProps: getConnectBarProps(),
    };
};
