import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  margin-bottom: 24px;

  ${mediaQueries.tablet} {
    margin-bottom: 20px;
  }
`;
export const Inner = styled.div `
  display: flex;
  flex-direction: column;
  margin: -8px;

  > div {
    padding: 8px;
  }

  ${mediaQueries.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
export const DateRangeWrapper = styled.div `
  ${mediaQueries.tablet} {
    max-width: 180px;
  }
`;
export const SelectWrapper = styled.div `
  ${mediaQueries.tablet} {
    max-width: 177px;
  }
`;
