import { Icon } from '@fourthwall/components';
import { PhotoIcon } from '@fourthwall/icons';
import React from 'react';
import { QuantityBadge } from '@components/common/QuantityBadge';
import * as S from './styled';
export const OfferPreview = ({ image, imagesCount }) => {
    return (React.createElement(S.Container, null,
        image ? (React.createElement(S.Image, { src: image })) : (React.createElement(S.PreviewIcon, null,
            React.createElement(Icon, { component: PhotoIcon, height: 24 }))),
        imagesCount && imagesCount > 1 && React.createElement(QuantityBadge, { quantity: imagesCount })));
};
