import React from 'react';
import { SelectedItems } from '@components/common/SelectedItems';
import { CountryFlag } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import { getCountriesOptions } from '@fourthwall/utils/lib/inputs';
import * as S from './styled';
export const SelectedCountries = ({ countriesCount, onAddCountry, onRemoveCountry, data: originData, ...props }) => {
    const data = getCountriesOptions({
        include: originData,
        withMostPopular: true,
        mostPopularWithDivider: false,
    }).map((country) => country.value);
    return (React.createElement(SelectedItems, { data: data, maxHeight: 290, itemsCount: countriesCount, header: React.createElement(React.Fragment, null,
            countriesCount || 'No',
            " ",
            pluralize(countriesCount, 'country', 'countries'),
            " selected"), addItemsButtonLabel: "Add countries", renderItem: (country) => (React.createElement(S.CountryFlagWrapper, null,
            React.createElement(CountryFlag, { countryCode: country }))), onAddItems: onAddCountry, onRemoveItem: (country) => onRemoveCountry(country), ...props }));
};
