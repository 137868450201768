import isNil from 'lodash-es/isNil';
import { useMemo } from 'react';
export const useAvailableSections = ({ sections }) => {
    const sectionGroups = useMemo(() => {
        if (!sections)
            return;
        const groupedSections = sections.reduce((acc, section) => {
            const groupName = section.additionalInfo?.groupName || 'Others';
            if (!acc[groupName]) {
                acc[groupName] = [];
            }
            acc[groupName].push(section);
            return acc;
        }, {});
        const groupOrder = [
            'Layout',
            'Shop',
            'Memberships',
            'Social feeds',
            'Default',
            'Header',
            'Footer',
            'Others',
        ];
        const result = groupOrder.reduce((acc, group) => {
            if (groupedSections[group]) {
                groupedSections[group].sort((a, b) => {
                    if (isNil(a.additionalInfo?.position) || isNil(b.additionalInfo?.position))
                        return 0;
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    return a.additionalInfo?.position - b.additionalInfo?.position;
                });
                acc.push({ group, sections: groupedSections[group] });
            }
            return acc;
        }, []);
        return result;
    }, [sections]);
    return {
        sectionGroups,
    };
};
