import { Banner } from '@fourthwall/components';
import React from 'react';
export const PrerequisitesErrors = ({ errors }) => {
    if (errors.length === 1) {
        return (React.createElement(Banner, { appearance: "danger" },
            "Your TikTok Shop has been disabled by TikTok due to: ",
            errors[0]));
    }
    return (React.createElement(Banner, { appearance: "danger" },
        "Your TikTok Shop has been disabled by TikTok due to:",
        ' ',
        React.createElement("ul", null, errors.map((error) => (React.createElement("li", null, error))))));
};
