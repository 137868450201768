export const getTiers = (tiersIdsParam) => {
    if (!tiersIdsParam || tiersIdsParam === 'ALL') {
        return undefined;
    }
    return [tiersIdsParam];
};
export const getTiersSelect = (reportType, tiersIdsParam, membershipAccountQuery, onValueChange) => {
    if (reportType === 'memberships-members-change' || reportType === 'memberships-total-members') {
        const getTiersOptions = () => {
            const defaultOption = [{ label: 'All', value: 'ALL' }];
            if (membershipAccountQuery.data?.tiers) {
                const tiers = membershipAccountQuery.data?.tiers.map(({ id, name }) => {
                    return { label: name, value: id };
                });
                defaultOption.push(...tiers);
            }
            return defaultOption;
        };
        return {
            options: getTiersOptions(),
            value: tiersIdsParam || 'ALL',
            onValueChange,
        };
    }
};
