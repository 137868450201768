import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Wrapper = styled.div ``;
export const Tags = styled.div `
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
`;
export const Tag = styled.div `
  padding: 4px;
`;
export const Description = styled.div `
  margin-top: 8px;
  font-size: 14px;
  color: ${Colors.GS600};
`;
