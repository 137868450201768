import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: grid;
  gap: 12px;
`;
export const Text = styled.div `
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.01em;
  overflow: hidden;
`;
export const Comment = styled.a `
  display: grid;

  &:hover {
    ${Text} {
      text-decoration: underline;
    }
  }
`;
export const Top = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const User = styled.div `
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
`;
export const Date = styled.div `
  font-size: 12px;
  color: ${Colors.GS600};
  line-height: 1.3;
`;
export const Title = styled.div `
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 12px;
  color: ${Colors.GS600};
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.3;
  text-overflow: ellipsis;
`;
export const ButtonContainer = styled.div `
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  color: ${Colors.GS600};
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.02em;
`;
