import { CustomizationApiClient } from '@fourthwall/services/lib/CustomizationApiClient';
import { useQuery } from '@tanstack/react-query';
export const useDraftPricingCalculationQuery = (customizationId, params, options) => {
    const draftPricingCalculationQuery = useQuery({
        queryKey: ['useDraftPricingCalculationQuery', customizationId, params],
        queryFn: async () => {
            if (customizationId) {
                const { data } = await CustomizationApiClient.getDraftPricingCalculation(customizationId, params);
                return data;
            }
        },
        ...options,
    });
    return {
        draftPricingCalculation: draftPricingCalculationQuery.data,
        draftPricingCalculationQuery,
    };
};
