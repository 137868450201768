import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  background-color: ${Colors.B500};
  color: ${Colors.GS0};
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.B600};
  }
`;
export const Body = styled.div `
  display: flex;
`;
export const ActionLabelContainer = styled.div `
  display: flex;
`;
export const ActionIconContainer = styled.div `
  margin-left: 16px;
`;
export const IconContainer = styled.div `
  margin-right: 16px;
`;
