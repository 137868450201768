import { getPrice } from '@fourthwall/utils';
export const getRecommendationBadge = (item) => {
    if (!item)
        return;
    if (item.action.status === 'Completed')
        return;
    if (item.action.completionBonus.type === 'NONE')
        return;
    const reward = getPrice(item.action.completionBonus.amount.value, {
        currency: item.action.completionBonus.amount.currency,
    });
    const badges = {
        CreateNewProduct: {
            label: `💰 Earn ${reward}`,
            tooltip: `You'll receive ${reward} in free samples credit after you create your first product!`,
        },
        VerifyDiscord: {
            label: `💰 Earn ${reward}`,
            tooltip: `You'll receive ${reward} in free samples credit after you join our Discord community!`,
        },
    };
    if (item.action.type in badges) {
        return { ...badges[item.action.type], appearance: 'caution', hoverable: true };
    }
};
