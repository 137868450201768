import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const DotsButtonContainer = styled.div `
  display: none;
`;
export const Container = styled.a `
  padding: 8px 16px;

  display: flex;
  align-items: center;

  &:hover,
  &:focus-within {
    background: ${Colors.GS200};

    ${DotsButtonContainer} {
      display: block;
    }
  }
`;
export const ItemContainer = styled.div `
  width: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
`;
export const ImageContainer = styled.div `
  margin-right: 12px;
`;
export const LabelContainer = styled.div `
  flex: 1;
  padding-right: 20px;
  overflow: hidden;

  line-height: 150%;

  > div {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export const Label = styled.div `
  font-size: 16px;
`;
export const LabelDescription = styled.div `
  margin-top: -3px;

  font-size: 14px;

  color: ${Colors.GS600};
`;
export const DotsButton = styled.button `
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${Colors.GS600};

  cursor: pointer;

  &:hover,
  &:focus-within {
    background: ${Colors.GS400};

    color: ${Colors.GS1000};
  }
`;
export const DropdownContainer = styled.div `
  padding: 8px 0;
  background: ${Colors.GS0};
  border: 1px solid ${Colors.GS500};
`;
export const ViewLabelInner = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
