import { mediaQueries, Tabs } from '@fourthwall/components';
import styled from 'styled-components';
import { DASHBOARD_STYLED_CONSTS } from '@components/common';
export const Container = styled.div `
  margin-top: 20px;

  ${mediaQueries.tablet} {
    flex: 1;
    margin-top: 0;
    margin-left: 80px;
  }
`;
export const ListWrapper = styled.div `
  padding: 12px 0;
  margin-top: -12px;
`;
export const Tab = styled(Tabs.Item) `
  &:last-child {
    padding-right: ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL}px;

    ${mediaQueries.tablet} {
      padding-right: 0;
    }
  }
`;
