import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  border-bottom: 1px solid ${Colors.GS400};
`;
export const Label = styled.div `
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${Colors.GS600};
`;
export const Content = styled.div `
  display: flex;
  flex-flow: column;
  padding: 4px 12px;
`;
export const Variant = styled.div `
  display: flex;
  padding: 4px 0;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GS1000};
`;
export const Name = styled.span `
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const StockCount = styled.span `
  min-width: 62px;
  margin-left: 8px;
  text-align: right;
`;
