import includes from 'lodash-es/includes';
import some from 'lodash-es/some';
import min from 'lodash-es/min';
import without from 'lodash-es/without';
import uniq from 'lodash-es/uniq';
export const isDigitalFulfillment = (fulfillmentService) => fulfillmentService === 'DIGITAL_ITEM';
const isScreenPrintedFulfillment = (fulfillmentService) => fulfillmentService === 'WLM' || fulfillmentService === 'DUBOW';
const isFulfilledByCreator = (fulfillmentService) => fulfillmentService === 'FULFILLED_BY_CREATOR';
export const getFulfillmentType = (fulfillmentService) => {
    if (isDigitalFulfillment(fulfillmentService)) {
        return 'DIGITAL';
    }
    if (isScreenPrintedFulfillment(fulfillmentService)) {
        return 'SCREEN_PRINTED';
    }
    if (isFulfilledByCreator(fulfillmentService)) {
        return 'FULFILLED_BY_CREATOR';
    }
    if (fulfillmentService === 'SHIP_MONK') {
        return 'SHIP_MONK';
    }
    return 'ON_DEMAND';
};
export const isFourthwallFulfilled = (fulfillmentType) => {
    return fulfillmentType === 'ON_DEMAND' || fulfillmentType === 'SCREEN_PRINTED';
};
export const isShipmentStartDateEditable = (manufacturingService) => {
    return ['MANUFACTURED_BY_CREATOR', 'FOURTHWALL_BESPOKE', 'DUBOW', 'OVERCAST', 'P3'].includes(manufacturingService);
};
export const getOfferTypeUtil = (manufacturingService) => {
    const offerTypeMap = {
        DEMO_PRODUCT: 'ON_DEMAND',
        DIGITAL_ITEM: 'DIGITAL',
        DUBOW: 'MOQ',
        DUBOW_DTFX: 'ON_DEMAND',
        FOURTHWALL_BESPOKE: 'MANUAL',
        MANUFACTURED_BY_CREATOR: 'MANUAL',
        MWW: 'ON_DEMAND',
        OPTIGER: 'ON_DEMAND',
        OVERCAST: 'MOQ',
        P3: 'MOQ',
        PRINTFUL: 'ON_DEMAND',
        SPOKE: 'ON_DEMAND',
        TRIBE: 'MANUAL',
        WLM: 'MOQ',
        KNITWISE: 'ON_DEMAND',
        DOD: 'ON_DEMAND',
    };
    return offerTypeMap[manufacturingService];
};
export const YOU_MANU = ['MANUFACTURED_BY_CREATOR'];
export const YOU_FULFILL = [
    'FULFILLED_BY_CREATOR',
    'COSMOPOLITAN',
    'BOLT_STITCH',
    'SUPER_CULTURE',
    'HOMEMADE_MERCH',
    'SEVENTY_EIGHT_STRANGE',
    'MOGUL_MERCH_LOCAL',
    'RVLT_SHIP_HERO',
    'MOGUL_MERCH_SHIP_BOB',
    'OFFLINE_TV_SHIPSTATION',
    'GNARLYWOOD',
];
export const getListingTypeUtil = (state) => {
    // NOTE: https://popshop.atlassian.net/browse/SLA-667
    if (!state) {
        return undefined;
    }
    const { manufacturingService, fulfillingService } = state;
    if (YOU_MANU.includes(manufacturingService) && YOU_FULFILL.includes(fulfillingService)) {
        return 'YOU';
    }
    const ON_DEMAND_MANU = ['PRINTFUL', 'SPOKE'];
    if (ON_DEMAND_MANU.includes(manufacturingService)) {
        return 'ON_DEMAND';
    }
    const MOQ_MANU = ['WLM', 'P3', 'DUBOW', 'OVERCAST'];
    if (MOQ_MANU.includes(manufacturingService)) {
        return 'MOQ';
    }
    const DIGITAL_MANU = ['DIGITAL_ITEM'];
    if (DIGITAL_MANU.includes(manufacturingService)) {
        return 'DIGITAL';
    }
    // We handle shipping
    return 'WE';
};
export const getCostsPerSize = (state) => {
    return state?.variants.reduce((result, currentVariant) => {
        const size = currentVariant.size;
        if (!size)
            return result;
        const cost = currentVariant.cost?.value || null;
        if (size in result) {
            if (includes(result[size], cost)) {
                return result;
            }
            return {
                ...result,
                [size]: [...result[size], cost],
            };
        }
        return {
            ...result,
            [size]: [cost],
        };
    }, {});
};
export const getSameSizesHaveDifferentCosts = (costsPerSizeResult) => {
    return some(costsPerSizeResult, (size) => size.length > 1);
};
export const getCosts = (state) => {
    return state?.variants.reduce((result, currentVariant) => {
        const cost = currentVariant.cost?.value || null;
        return [...result, cost];
    }, []);
};
export const getSizesWithHigherCosts = (state, costs) => {
    const lowestCost = min(without(costs, null));
    return state?.variants.reduce((result, currentVariant) => {
        const size = currentVariant.size;
        const cost = currentVariant.cost?.value;
        if (lowestCost && cost && cost > lowestCost && size && !includes(result, size)) {
            return [...result, size];
        }
        return result;
    }, []);
};
export const getSizesPerCost = (costs, costsPerSize) => {
    return (costs &&
        uniq(costs).map((cost) => {
            const sizes = (costsPerSize &&
                Object.entries(costsPerSize).reduce((result, [key, value]) => {
                    return value.includes(cost) && !result.includes(key) ? [...result, key] : result;
                }, [])) ||
                [];
            return { cost, sizes };
        }));
};
