import styled, { css } from 'styled-components';
import { Colors } from '../../../colors';
import { mediaQueries } from '../../../providers/MediaQueryProvider';
import { HELPER_CLASS } from '../consts';
export const Container = styled.div `
  margin-left: 16px;
  position: relative;

  &:before {
    content: '';
    height: 1px;
    width: 16px;
    background-color: ${Colors.GS500};
    position: absolute;
    left: -16px;
    top: 50%;
  }

  &:after {
    content: '';
    height: calc(100% + 12px);
    width: 1px;
    background-color: ${Colors.GS500};
    position: absolute;
    top: 0;
    left: -16px;
  }

  ${({ isLastSection }) => isLastSection &&
    css `
      &:last-child:after {
        height: 50%;
      }
    `}
`;
export const ToolbarWrapper = styled.div `
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  ${mediaQueries.tablet} {
    display: none;
    right: 20px;
  }
`;
export const Wrapper = styled.div `
  position: relative;
  background-color: ${Colors.GS200};
  padding: 12px;
  margin-bottom: 12px;

  ${({ isDragged }) => !isDragged &&
    css `
      &:hover ${ToolbarWrapper} {
        display: block;
      }
    `}

  &.${HELPER_CLASS} {
    pointer-events: auto !important;
    z-index: 999;
  }

  &.${HELPER_CLASS} ${ToolbarWrapper} {
    display: block;
  }
`;
export const Placeholder = styled.div `
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e3e2e1;
`;
