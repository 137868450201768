import { Button, ButtonAppearance, Checkbox, ModalBox } from '@fourthwall/components';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { DEFAULT_ASPECT } from './consts';
import * as S from './ImageUploadModal.styled';
export const ImageUploadModal = ({ image, onConfirm, close }) => {
    const [imageSize, setImageSize] = useState();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [aspect, setAspect] = useState();
    const [croppedAreaPixels, setCroppedAreaPixels] = useState();
    // eslint-disable-next-line no-unsafe-optional-chaining
    const imageAspect = imageSize ? imageSize?.naturalWidth / imageSize?.naturalHeight : undefined;
    const handleZoomChange = (zoomValue) => {
        setZoom(zoomValue);
    };
    const handleCropComplete = (percentages, pixels) => {
        setCroppedAreaPixels(pixels);
    };
    const handleConfirmClick = () => {
        if (onConfirm) {
            const isOriginalImage = zoom === 1 && aspect === imageAspect;
            onConfirm(image, isOriginalImage ? undefined : croppedAreaPixels);
        }
        close();
    };
    const handleRatioCheckboxChange = (value) => {
        if (value) {
            setAspect(DEFAULT_ASPECT);
        }
        else {
            setAspect(imageAspect);
        }
    };
    const handleMediaLoad = (mediaSize) => {
        setAspect(mediaSize.naturalWidth / mediaSize.naturalHeight);
        setImageSize(mediaSize);
    };
    return (React.createElement(ModalBox, { title: "Upload new photo", size: "large", onClose: close },
        React.createElement(S.CropperContainer, null,
            React.createElement(Cropper, { image: image, crop: crop, zoom: zoom, aspect: aspect, showGrid: false, onCropChange: setCrop, onMediaLoaded: handleMediaLoad, style: {
                    cropAreaStyle: {
                        color: 'rgba(255,255,255,0.75)',
                    },
                }, onCropComplete: handleCropComplete, onZoomChange: handleZoomChange })),
        React.createElement(S.Footer, null,
            imageAspect !== DEFAULT_ASPECT && (React.createElement(Checkbox, { value: aspect === DEFAULT_ASPECT, label: "Crop to 4:3 ratio", onValueChange: handleRatioCheckboxChange })),
            React.createElement(S.ButtonContainer, null,
                React.createElement(Button, { label: "Add photo", appearance: ButtonAppearance.Primary, onClick: handleConfirmClick })))));
};
