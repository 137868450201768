import { Button, Fieldset, ModalBox, RadioButton } from '@fourthwall/components';
import React from 'react';
import { useFormik } from '@fourthwall/utils/lib/formik';
import * as Yup from 'yup';
export const ToggleStatusModal = ({ onSubmit, close, getCount, }) => {
    const count = getCount();
    const formik = useFormik({
        initialValues: {
            status: 'PUBLIC',
        },
        onSubmit: async (values) => {
            await onSubmit(values.status);
            close();
        },
        validationSchema: Yup.object().shape({
            status: Yup.string().required(),
        }),
        enableReinitialize: true,
        validateOnMount: true,
    });
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(ModalBox, { onClose: close, size: "small", title: `Change status for ${count} ${count > 1 ? 'products' : 'product'}`, buttons: [
                React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close, disabled: formik.isSubmitting }),
                React.createElement(Button, { label: "Update", appearance: "primary", type: "submit", loading: formik.isSubmitting }),
            ] },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(RadioButton, { value: formik.values.status, checkedValue: "PUBLIC", label: "Public", suggestion: "Everyone has access.", name: "status", onChange: formik.handleChange })),
                React.createElement(Fieldset.Row, null,
                    React.createElement(RadioButton, { value: formik.values.status, checkedValue: "HIDDEN", label: "Hidden", suggestion: "Everyone with link has access.", name: "status", onChange: formik.handleChange }))))));
};
