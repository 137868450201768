export const supportersFixture = [
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FDwa',
        name: 'Tom Lee',
        contributed: {
            price: {
                value: 123.54,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FQwa',
        name: 'Somyytytyt Lee',
        contributed: {
            price: {
                value: 532.33,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FWwa',
        name: 'Zomx Lee',
        contributed: {
            price: {
                value: 5.5,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FRwa',
        name: 'Bom1 Lee',
        contributed: {
            price: {
                value: 1111.24,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FTwa',
        name: 'Zoo Lee',
        contributed: {
            price: {
                value: 0.11,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FYwa',
        name: 'QQQ Lee',
        contributed: {
            price: {
                value: 12.2,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FUwa',
        name: 'GGG Lee',
        contributed: {
            price: {
                value: 99.09,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FIwa',
        name: 'Andy Lee',
        contributed: {
            price: {
                value: 1223.54,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FOwa',
        name: 'Piotr X Lee',
        contributed: {
            price: {
                value: 253.14,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FPwa',
        name: 'Tomomomomomom Lexexexexexee',
        contributed: {
            price: {
                value: 823.54,
                currency: 'USD',
            },
        },
    },
    {
        id: 'xxx_ZByafOisQpyzfkmhI0FAwa',
        name: 'Tom Lee',
        contributed: {
            price: {
                value: 63.54,
                currency: 'USD',
            },
        },
    },
];
