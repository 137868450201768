import { getCommonFieldsPayload } from './getCommonFieldsPayload';
export const getPollPostPayload = (values) => {
    return {
        ...getCommonFieldsPayload(values),
        options: values.pollOptions || [],
        poll_length: {
            days: Number(values.pollLength?.days) || 0,
            hours: Number(values.pollLength?.hours) || 0,
            minutes: Number(values.pollLength?.minutes) || 0,
        },
        votes_boost: false,
    };
};
