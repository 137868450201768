import { useAuth } from '@fourthwall/auth';
import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { routing } from '@utils/routing';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import logoSrc from '../../../assets/signets/streamelements.png';
import { useAppConnectedEvent } from '../../../hooks/useAppConnectedEvent';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
import { useCreatorIntegrationsQuery } from '../../../queries/useCreatorIntegrationsQuery';
import { useDeleteStreamelementsMutation } from './useDeleteStreamelementsMutation';
import { useErrorQueryParam } from './useErrorQueryParam';
const connectBarBase = {
    logoSrc,
    logoAlt: 'StreamElements',
    description: 'Connect to your StreamElements account',
    disabled: false,
};
export const useStreamelements = () => {
    const { keycloak } = useAuth();
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const { open } = useModalContext();
    const { deleteStreamelementsMutation } = useDeleteStreamelementsMutation();
    const appConnectEvent = useAppConnectEvent('stream_elements_alerts');
    const [flowQueryParam] = useQueryParam('flow', StringParam);
    const navigate = useNavigate();
    useErrorQueryParam();
    useAppConnectedEvent('stream_elements_alerts');
    useEffect(() => {
        if (flowQueryParam === 'register') {
            navigate(routing.home);
        }
    }, [flowQueryParam, navigate]);
    const status = creatorIntegrationsQuery.data?.streamelements.status;
    const getConnectBar = () => {
        // unknown
        if (!creatorIntegrationsQuery.data)
            return undefined;
        // not connected
        switch (status) {
            case 'NOT_CONNECTED': {
                const onConnect = () => {
                    appConnectEvent.trigger();
                    const url = CreatorIntegrationsApiClient.appAuthorizationUrl({
                        app: 'streamelements',
                        token: keycloak?.token,
                    });
                    window.location.assign(url);
                };
                return {
                    ...connectBarBase,
                    onConnect,
                };
            }
            case 'CONNECTED': {
                const onDisconnect = () => {
                    open(ConfirmModal, {
                        title: 'Are you sure you want to disconnect from StreamElements?',
                        confirmLabel: 'Yes, disconnect',
                        confirmAppearance: 'destructive',
                        onConfirm: () => {
                            const toastId = 'deleteStreamelementsMutation';
                            Toasts.notify('Disconnecting from StreamElements...', {
                                toastId,
                                type: 'info',
                                autoClose: false,
                            });
                            deleteStreamelementsMutation.mutate(undefined, {
                                onSuccess: () => {
                                    creatorIntegrationsQuery.refetch();
                                    Toasts.notify('Disconnected from StreamElements successfully.', {
                                        type: 'success',
                                    });
                                    Toasts.dismiss(toastId);
                                },
                            });
                        },
                    });
                };
                return {
                    ...connectBarBase,
                    description: '',
                    onDisconnect,
                };
            }
        }
    };
    return {
        streamelements: {
            status,
            connectBar: getConnectBar(),
        },
    };
};
