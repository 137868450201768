import { Icon, Tooltip } from '@fourthwall/components';
import { QuestionMarkIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
export const SummaryRowLabel = ({ title, tooltip }) => {
    return (React.createElement(S.Container, null,
        title,
        tooltip && (React.createElement(S.TooltipContainer, null,
            React.createElement(Tooltip, { reference: React.createElement(Icon, { component: QuestionMarkIcon, height: 16 }) }, tooltip)))));
};
