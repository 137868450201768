import { ApiBase } from './ApiBase';
class GiveawayContributionApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getGiveawayContributions = (params) => {
            return this.get(`/giveaway-contribution`, params);
        };
        this.getGiveawayContribution = ({ giveawayId }) => {
            return this.get(`/giveaway-contribution/${giveawayId}`);
        };
        this.getGiveawayContributionCostsBreakdown = ({ giveawayId, }) => {
            return this.get(`/giveaway-contribution/${giveawayId}/costs-breakdown`);
        };
        this.getGiveawayGifts = ({ giveawayId }) => {
            return this.get(`/giveaways/${giveawayId}/gifts`);
        };
        this.redoGiveaway = ({ giveawayId }, payload) => {
            return this.post(`/giveaways/${giveawayId}/redo`, payload);
        };
    }
}
export const GiveawayContributionApiClient = new GiveawayContributionApiClientModel();
