import React, { useLayoutEffect, useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import * as S from './Tooltip.styled';
import { TooltipBox } from './TooltipBox';
export const Tooltip = ({ placement = 'top', content, children, size = 'large', onOpen, onClose, }) => {
    const [isVisible, setIsVisible] = useState(false);
    const positionUpdateRef = useRef(null);
    useLayoutEffect(() => {
        if (positionUpdateRef.current) {
            positionUpdateRef.current();
        }
    }, [content]);
    const showTip = () => {
        if (onOpen) {
            onOpen();
        }
        setIsVisible(true);
    };
    const hideTip = () => {
        if (onClose) {
            onClose();
        }
        setIsVisible(false);
    };
    const renderReferenceChildren = ({ ref }) => {
        return React.createElement(S.ChildrenContainer, { ref: ref }, children);
    };
    const renderPopperChildren = ({ ref, style, placement: dataPlacement, update, }) => {
        positionUpdateRef.current = update;
        return (React.createElement(S.ContentContainer, { ref: ref, "data-placement": dataPlacement, style: { ...style, paddingTop: 4, paddingBottom: 4, zIndex: 1 } },
            React.createElement(TooltipBox, { size: size }, content)));
    };
    return (React.createElement(Manager, null,
        React.createElement(S.Container, { onMouseEnter: showTip, onTouchStart: showTip, onMouseLeave: hideTip, onTouchEnd: hideTip },
            React.createElement(Reference, null, renderReferenceChildren),
            isVisible && (React.createElement(Popper, { placement: placement, modifiers: [
                    { name: 'preventOverflow', enabled: false },
                    { name: 'hide', enabled: false },
                ] }, renderPopperChildren)))));
};
