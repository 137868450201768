import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';
import { Colors } from '../../colors';
export const Container = styled.div(
/* NOTE: No need for relative position when !isLoading since it may break all kind of tooltips. */
({ isLoading }) => css `
    height: inherit;
    flex: 1;

    ${isLoading &&
    css `
      position: relative;
    `}
  `);
const getBackground = ({ variant, theme }) => {
    if (variant === 'transparent' && theme.automated?.styles?.colorBackground) {
        return tinycolor(theme.automated?.styles.colorBackground).setAlpha(0.8).toRgbString();
    }
    if (variant === 'solid' && theme.automated?.styles?.colorBackground) {
        return theme.automated?.styles.colorBackground;
    }
    if (variant === 'transparent') {
        return 'rgba(255, 255, 255, 0.8)';
    }
    if (variant === 'solid') {
        return Colors.GS0;
    }
};
export const OverlayContainer = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  width: 100%;
  height: 100%;

  background: ${getBackground};
`;
