import { Toasts } from '@fourthwall/components';
import { useCurrentShop } from '../../../../hooks/useCurrentShop';
import { useShopRendererDataQuery } from './useShopRendererDataQuery';
import { useUpdateShopMutation } from './useUpdateShopMutation';
import { useUpdateShopRendererMutation } from './useUpdateShopRendererMutation';
export const useGeneralShopSettings = () => {
    const { invalidateCurrentShopQuery, currentShop } = useCurrentShop();
    const { shopRendererDataQuery, invalidateShopRendererDataQuery } = useShopRendererDataQuery([{ shop_id: currentShop?.id }], {
        enabled: false,
    });
    const { updateShopMutation } = useUpdateShopMutation();
    const { updateShopRendererMutation } = useUpdateShopRendererMutation();
    const handleUpdateShop = async (values) => {
        if (currentShop?.id) {
            try {
                await Promise.all([
                    await updateShopMutation.mutateAsync([
                        currentShop.id,
                        {
                            name: values.name,
                            customerSupportEmail: values.customerSupportEmail,
                            creatorName: values.creatorName,
                            description: values.description,
                        },
                    ]),
                    await updateShopRendererMutation.mutateAsync([
                        { shop_id: currentShop.id },
                        {
                            shop: {
                                themeAutoUpdateHour: Number(values.themeAutoUpdateHour),
                                ogImage: {
                                    data: values.socialImageType === 'New' ? values.socialImage : '',
                                    type: values.socialImageType,
                                },
                                cookiePolicy: values.cookiePolicy === false ? 'Hide' : 'ShowInEu',
                            },
                        },
                    ]),
                ]);
                invalidateCurrentShopQuery();
                invalidateShopRendererDataQuery();
                Toasts.notify('Settings saved', { type: 'info' });
            }
            catch (error) {
                Toasts.notify('Failed to update shop details. Please try again.');
            }
        }
    };
    return {
        currentShop,
        shopRendererDataQuery,
        handleUpdateShop,
        isSubmitting: updateShopMutation.isLoading || updateShopRendererMutation.isLoading,
    };
};
