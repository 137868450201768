import React from 'react';
import { BaseContent, Button } from '@fourthwall/components';
import * as S from './styled';
import image from './google-analytics.svg';
export const GoogleAnalyticsBar = ({ title, description, button }) => (React.createElement(S.Container, null,
    React.createElement("div", null,
        React.createElement("img", { src: image, alt: "Google Analytics logo" })),
    React.createElement("div", { style: { display: 'grid', gap: 1 } },
        React.createElement(BaseContent, null,
            React.createElement("strong", null, title)),
        React.createElement(BaseContent, { appearance: "secondary", size: "small" }, description)),
    React.createElement("div", null,
        React.createElement(Button, { ...button }))));
