import React from 'react';
import { TilesModal } from '../../../../../components/modals/TilesModal';
import { useDashboardFeatureFlags } from '../../../../../components/utils/DashboardFeatureFlags';
import { useDashboardPermissions } from '../../../../../components/utils/DashboardPermissions';
import { routing } from '../../../../../utils/routing';
import giveawayLinksImage from './assets/giveawayLinks.png';
import membershipsPromoImage from './assets/membershipsPromo.png';
import shopPromoImage from './assets/shopPromo.png';
export const CreatePromotionModal = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const getItems = () => {
        const items = [
            {
                enabled: hasPermission('promotions.shopPromoCodes'),
                image: shopPromoImage,
                imageAlt: 'Design something new',
                heading: 'Shop promo',
                subheading: 'Offer discounts on products in your shop',
                to: routing.promotions.promotionCodes.create.self,
            },
            {
                enabled: dashboardFeatureFlags.memberships.enabled &&
                    hasPermission('promotions.membershipsPromoCodes'),
                image: membershipsPromoImage,
                imageAlt: 'Sell something I have',
                heading: 'Memberships promo',
                subheading: 'Offer discounts on memberships pricing',
                to: routing.promotions.promotionCodes.membershipsPromotionCreate.self,
            },
            {
                enabled: hasPermission('promotions.giveawayLinks'),
                image: giveawayLinksImage,
                imageAlt: 'Sell digital product',
                heading: 'Giveaway links',
                subheading: 'Give products away for free through unique links',
                to: routing.promotions.giveawayPackages.create.self,
            },
        ];
        return items.filter((item) => item.enabled);
    };
    const items = getItems();
    if (!items.length) {
        return null;
    }
    return React.createElement(TilesModal, { items: items });
};
