import React from 'react';
import { ArrowShortDownIcon, ArrowShortUpIcon } from '@fourthwall/icons';
import { Icon } from '../../Icon';
import * as S from './styled';
export const AccordionItem = ({ heading, footer, spacing = 'regular', expanded = false, disabled, onChange, children, }) => {
    return (React.createElement(S.Container, { "$spacing": spacing, "aria-expanded": expanded, "data-testid": "Accordion.Item" },
        React.createElement(S.HeadingContainer, null,
            React.createElement(S.Heading, null, heading),
            onChange && (React.createElement(S.ExpandButton, { disabled: disabled, onClick: onChange },
                React.createElement(Icon, { component: expanded ? ArrowShortUpIcon : ArrowShortDownIcon, height: 12 })))),
        expanded && children,
        footer && footer));
};
