import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const RemoveButton = styled.button.attrs({ type: 'button' }) `
  padding: 4px;

  ${mediaQueries.laptop} {
    visibility: hidden;
  }
`;
export const Container = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 12px;

  ${mediaQueries.laptop} {
    &:hover {
      ${RemoveButton} {
        visibility: visible;
      }
    }
  }
`;
export const RemoveButtonWrapper = styled.div `
  &:hover {
    ${RemoveButton} {
      visibility: visible;
    }
  }

  ${mediaQueries.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    left: 100%;
    padding: 12px;

    &:hover {
      ${RemoveButton} {
        visibility: visible;
      }
    }
  }
`;
export const Product = styled.div `
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;

  ${mediaQueries.tablet} {
    gap: 16px;
  }
`;
export const ProductDetails = styled.div `
  display: flex;
  flex-direction: column;
`;
export const Description = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
`;
export const Quantity = styled.div `
  width: 90px;
`;
export const Price = styled.div `
  width: 90px;
`;
export const QuantityDropdownWrapper = styled.div `
  font-size: 14px;
  display: flex;
  gap: 6px;
  color: ${Colors.GS600};
`;
