import { Banner, Button, Input, ModalBox, PriceString } from '@fourthwall/components';
import { getPrice } from '@fourthwall/utils';
import React from 'react';
import * as S from './styled';
export const OrderRefundConfirmModalView = ({ amount, error, reasonInputProps, isLoading, onClose, onConfirm, }) => {
    return (React.createElement(ModalBox, { title: React.createElement(React.Fragment, null,
            "Are you sure you want to refund ",
            React.createElement(PriceString, { value: amount }),
            "?"), buttons: [
            React.createElement(Button, { label: "Back", appearance: "secondary", onClick: onClose }),
            React.createElement(Button, { label: `Yes, refund ${getPrice(parseFloat(amount))}`, appearance: "destructive", loading: isLoading, onClick: onConfirm }),
        ], onClose: onClose },
        error && (React.createElement(S.ErrorBannerContainer, null,
            React.createElement(Banner, { appearance: "danger" }, error))),
        React.createElement(S.Info, null, "This cannot be undone. The cost of refunding will be taken from your account. Refunds will be sent directly to the supporter's payment method (it may take up to 48 hours to process)."),
        React.createElement(Input, { label: "Refund reason (optional)", suggestion: "Only you and other staff can see it.", ...reasonInputProps })));
};
