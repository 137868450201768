import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const Main = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
`;
export const RecordValues = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const Group = styled.div `
  flex: 1;
  display: flex;
  gap: 12px;
`;
export const Label = styled.div `
  width: 40px;
  min-width: 40px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: ${Colors.GS600};
  text-transform: uppercase;
`;
export const Type = styled.div `
  font-size: 12px;
  line-height: 15px;
`;
export const Error = styled.div `
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: ${Colors.R300};
  align-self: flex-end;
  text-align: right;
`;
