import CAProvinces from 'provinces-ca';
// @ts-ignore
import { UsaStates } from 'usa-states';
export const US_STATES = new UsaStates({
    includeTerritories: true,
    exclude: ['PW', 'MH', 'FM'],
    ignoreCharacter: '$',
    contiguousOnly: false,
}).states.map(({ name, abbreviation }) => ({
    name,
    abbreviation,
}));
export const MILITARY_US_STATES = [
    { name: 'Armed Forces America', abbreviation: 'AA' },
    { name: 'Armed Forces Europe', abbreviation: 'AE' },
    { name: 'Armed Forces Pacific', abbreviation: 'AP' },
];
export const CA_STATES = CAProvinces.map(({ name, abbreviation }) => ({
    name,
    abbreviation,
}));
export const JAPAN_STATES = [
    { name: 'Hokkaido', abbreviation: 'Hokkaido' },
    { name: 'Aomori', abbreviation: 'Aomori' },
    { name: 'Iwate', abbreviation: 'Iwate' },
    { name: 'Miyagi', abbreviation: 'Miyagi' },
    { name: 'Akita', abbreviation: 'Akita' },
    { name: 'Yamagata', abbreviation: 'Yamagata' },
    { name: 'Fukushima', abbreviation: 'Fukushima' },
    { name: 'Ibaraki', abbreviation: 'Ibaraki' },
    { name: 'Tochigi', abbreviation: 'Tochigi' },
    { name: 'Gunma', abbreviation: 'Gunma' },
    { name: 'Saitama', abbreviation: 'Saitama' },
    { name: 'Chiba', abbreviation: 'Chiba' },
    { name: 'Tokyo', abbreviation: 'Tokyo' },
    { name: 'Kanagawa', abbreviation: 'Kanagawa' },
    { name: 'Niigata', abbreviation: 'Niigata' },
    { name: 'Toyama', abbreviation: 'Toyama' },
    { name: 'Ishikawa', abbreviation: 'Ishikawa' },
    { name: 'Fukui', abbreviation: 'Fukui' },
    { name: 'Yamanashi', abbreviation: 'Yamanashi' },
    { name: 'Nagano', abbreviation: 'Nagano' },
    { name: 'Gifu', abbreviation: 'Gifu' },
    { name: 'Shizuoka', abbreviation: 'Shizuoka' },
    { name: 'Aichi', abbreviation: 'Aichi' },
    { name: 'Mie', abbreviation: 'Mie' },
    { name: 'Shiga', abbreviation: 'Shiga' },
    { name: 'Kyoto', abbreviation: 'Kyoto' },
    { name: 'Osaka', abbreviation: 'Osaka' },
    { name: 'Hyogo', abbreviation: 'Hyogo' },
    { name: 'Nara', abbreviation: 'Nara' },
    { name: 'Wakayama', abbreviation: 'Wakayama' },
    { name: 'Tottori', abbreviation: 'Tottori' },
    { name: 'Shimane', abbreviation: 'Shimane' },
    { name: 'Okayama', abbreviation: 'Okayama' },
    { name: 'Hiroshima', abbreviation: 'Hiroshima' },
    { name: 'Yamaguchi', abbreviation: 'Yamaguchi' },
    { name: 'Tokushima', abbreviation: 'Tokushima' },
    { name: 'Kagawa', abbreviation: 'Kagawa' },
    { name: 'Ehime', abbreviation: 'Ehime' },
    { name: 'Kochi', abbreviation: 'Kochi' },
    { name: 'Fukuoka', abbreviation: 'Fukuoka' },
    { name: 'Saga', abbreviation: 'Saga' },
    { name: 'Nagasaki', abbreviation: 'Nagasaki' },
    { name: 'Kumamoto', abbreviation: 'Kumamoto' },
    { name: 'Oita', abbreviation: 'Oita' },
    { name: 'Miyazaki', abbreviation: 'Miyazaki' },
    { name: 'Kagoshima', abbreviation: 'Kagoshima' },
    { name: 'Okinawa', abbreviation: 'Okinawa' },
];
export const AUSTRALIA_STATES = [
    { name: 'Australian Capital Territory', abbreviation: 'ACT' },
    { name: 'New South Wales', abbreviation: 'NSW' },
    { name: 'Northern Territory', abbreviation: 'NT' },
    { name: 'Queensland', abbreviation: 'QLD' },
    { name: 'South Australia', abbreviation: 'SA' },
    { name: 'Tasmania', abbreviation: 'TAS' },
    { name: 'Victoria', abbreviation: 'VIC' },
    { name: 'Western Australia', abbreviation: 'WA' },
];
export const BRAZIL_STATES = [
    { name: 'Acre', abbreviation: 'AC' },
    { name: 'Alagoas', abbreviation: 'AL' },
    { name: 'Amazonas', abbreviation: 'AM' },
    { name: 'Amapá', abbreviation: 'AP' },
    { name: 'Bahia', abbreviation: 'BA' },
    { name: 'Ceará', abbreviation: 'CE' },
    { name: 'Distrito Federal', abbreviation: 'DF' },
    { name: 'Espírito Santo', abbreviation: 'ES' },
    { name: 'Goiás', abbreviation: 'GO' },
    { name: 'Maranhão', abbreviation: 'MA' },
    { name: 'Minas Gerais', abbreviation: 'MG' },
    { name: 'Mato Grosso do Sul', abbreviation: 'MS' },
    { name: 'Mato Grosso', abbreviation: 'MT' },
    { name: 'Pará', abbreviation: 'PA' },
    { name: 'Paraíba', abbreviation: 'PB' },
    { name: 'Pernambuco', abbreviation: 'PE' },
    { name: 'Piauí', abbreviation: 'PI' },
    { name: 'Paraná', abbreviation: 'PR' },
    { name: 'Rio de Janeiro', abbreviation: 'RJ' },
    { name: 'Rio Grande do Norte', abbreviation: 'RN' },
    { name: 'Rondônia', abbreviation: 'RO' },
    { name: 'Roraima', abbreviation: 'RR' },
    { name: 'Rio Grande do Sul', abbreviation: 'RS' },
    { name: 'Santa Catarina', abbreviation: 'SC' },
    { name: 'Sergipe', abbreviation: 'SE' },
    { name: 'São Paulo', abbreviation: 'SP' },
    { name: 'Tocantins', abbreviation: 'TO' },
];
