import { Button, ConfirmLeaveModal, Fieldset, ModalBox, useModalContext, } from '@fourthwall/components';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from '@utils/routing';
import { useCreateImageResources } from '../../../../hooks/useCreateImageResources';
import { useAccountSettingsQuery } from '../../../Settings/hooks/useAccountSettingsQuery';
import { useCreateTierMutation } from '../../hooks/useCreateTierMutation';
import { useFreeTierQuery } from '../../hooks/useFreeTierQuery';
import { usePublishFreeTierMutation } from '../../hooks/usePublishFreeTierMutation';
import { useUpdateFreeTierMutation } from '../../hooks/useUpdateFreeTierMutation';
import { useUpdateTierMutation } from '../../hooks/useUpdateTierMutation';
import { isPaidTier } from '../../utils';
import { getValidationSchema } from './consts';
import { Description } from './modules/Description';
import { Discount } from './modules/Discount';
import { Image } from './modules/Image';
import { Limit } from './modules/Limit';
import { MonthDiscount } from './modules/MonthDiscount';
import { Price } from './modules/Price';
import { Title } from './modules/Title';
import { YearDiscount } from './modules/YearDiscount';
import { getFreeTierPayload, getTierPayload } from './utils';
export const TierModal = ({ tier, onSuccess }) => {
    const navigate = useNavigate();
    const modal = useModalContext();
    const { freeTierQuery } = useFreeTierQuery();
    const { accountSettingsQuery } = useAccountSettingsQuery();
    const { createTierMutation } = useCreateTierMutation();
    const { updateTierMutation } = useUpdateTierMutation();
    const { updateFreeTierMutation } = useUpdateFreeTierMutation();
    const { publishFreeTierMutation } = usePublishFreeTierMutation();
    const { createImageResources, isLoading: createImageResourcesIsLoading } = useCreateImageResources();
    const [leavingGuardVisible, setLeavingGuardVisible] = useState(false);
    const [leavingGuardRedirect, setLeavingGuardRedirect] = useState(false);
    if (!freeTierQuery.data || !accountSettingsQuery.data) {
        return null;
    }
    const annualDiscount = (accountSettingsQuery.data.tiers.annual.discount.enabled &&
        accountSettingsQuery.data.tiers.annual.discount.percentOff) ||
        undefined;
    const freeTier = freeTierQuery.data.status !== 'Hidden';
    const getInitialValues = () => {
        if (!tier) {
            return {
                annualDiscount,
                title: '',
                description: '',
                price: '',
                discount: false,
                monthDiscount: undefined,
                yearDiscount: undefined,
                timeLimit: false,
                startDate: undefined,
                endDate: undefined,
                image: undefined,
                imageFile: undefined,
                removeImage: false,
            };
        }
        if (isPaidTier(tier)) {
            return {
                annualDiscount,
                title: tier.name,
                description: tier.description,
                price: (tier.variants.monthly.amount / 100).toString(),
                discount: !!(tier.discount?.percentOff.monthly || tier.discount?.percentOff.annual),
                monthDiscount: tier.discount?.percentOff.monthly.toString(),
                yearDiscount: tier.discount?.percentOff.annual?.toString(),
                timeLimit: !!(tier.discount?.timeLimited?.startDate || tier.discount?.timeLimited?.endDate),
                startDate: tier.discount?.timeLimited?.startDate,
                endDate: tier.discount?.timeLimited?.endDate,
                image: undefined,
                imageFile: undefined,
                removeImage: false,
            };
        }
        return {
            annualDiscount,
            title: tier.name,
            description: tier.description,
            price: '0',
            discount: undefined,
            monthDiscount: undefined,
            yearDiscount: undefined,
            timeLimit: undefined,
            startDate: undefined,
            endDate: undefined,
            image: undefined,
            imageFile: undefined,
            removeImage: false,
        };
    };
    return (React.createElement(Formik, { initialValues: getInitialValues(), validationSchema: getValidationSchema({
            isCreatingNewTier: !tier,
            isFreeTierCreated: !!freeTier,
            isEditingPaidTier: !!(tier && isPaidTier(tier)),
        }), onSubmit: (values) => {
            if (values.price && parseFloat(values.price) === 0) {
                if (values.imageFile) {
                    return createImageResources({
                        files: [values.imageFile],
                        onSuccess: (images) => {
                            updateFreeTierMutation.mutate([getFreeTierPayload(values, images[0].id)], {
                                onSuccess: () => {
                                    if (tier) {
                                        onSuccess();
                                        modal.close();
                                    }
                                    else {
                                        publishFreeTierMutation.mutate([], {
                                            onSuccess: () => {
                                                onSuccess();
                                                modal.close();
                                            },
                                        });
                                    }
                                },
                            });
                        },
                    });
                }
                return updateFreeTierMutation.mutate([getFreeTierPayload(values)], {
                    onSuccess: () => {
                        if (tier) {
                            onSuccess();
                            modal.close();
                        }
                        else {
                            publishFreeTierMutation.mutate([], {
                                onSuccess: () => {
                                    onSuccess();
                                    modal.close();
                                },
                            });
                        }
                    },
                });
            }
            if (!tier) {
                return createTierMutation.mutate([getTierPayload(values)], {
                    onSuccess: () => {
                        onSuccess();
                        modal.close();
                    },
                });
            }
            return updateTierMutation.mutate([tier.id, getTierPayload(values)], {
                onSuccess: () => {
                    onSuccess();
                    modal.close();
                },
            });
        } }, ({ dirty, submitForm }) => {
        const handleClose = () => (dirty ? setLeavingGuardVisible(true) : modal.close());
        return (React.createElement(React.Fragment, null,
            React.createElement(ModalBox, { title: tier ? 'Edit tier' : 'Create a new tier', size: "medium", buttons: [
                    React.createElement(Button, { label: "Cancel", onClick: handleClose }),
                    React.createElement(Button, { label: tier ? 'Update' : 'Add tier', appearance: "primary", loading: createTierMutation.isLoading ||
                            updateTierMutation.isLoading ||
                            updateFreeTierMutation.isLoading ||
                            publishFreeTierMutation.isLoading ||
                            createImageResourcesIsLoading, onClick: submitForm }),
                ], onClose: handleClose },
                React.createElement(Fieldset, null,
                    React.createElement(Title, null),
                    React.createElement(Description, null),
                    React.createElement(Price, { membersCount: tier?.statistics.membersCount, annualDiscount: annualDiscount, tier: tier, onAnnualClick: () => {
                            if (dirty)
                                setLeavingGuardRedirect(true);
                            handleClose();
                        } }),
                    React.createElement(Discount, null),
                    React.createElement(MonthDiscount, null),
                    React.createElement(YearDiscount, null),
                    React.createElement(Limit, null),
                    React.createElement(Image, { imageSrc: tier?.image?.url }))),
            leavingGuardVisible && (React.createElement(ConfirmLeaveModal, { title: "Would you like to save your changes?", text: "If you leave this page, all unsaved changes will be discarded.", close: () => {
                    setLeavingGuardVisible(false);
                    setLeavingGuardRedirect(false);
                }, onDiscard: () => {
                    modal.close();
                    setLeavingGuardVisible(false);
                    if (leavingGuardRedirect) {
                        navigate(routing.memberships.settings.general);
                    }
                }, onSave: () => {
                    setLeavingGuardVisible(false);
                    submitForm();
                } }))));
    }));
};
