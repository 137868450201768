import { ShopRendererApiClient } from '@fourthwall/services/lib/ShopRendererApiClient';
import { useMutation } from '@tanstack/react-query';
export const useCreateTiktokIntegrationMutation = (options) => {
    const createTiktokIntegrationMutation = useMutation({
        mutationFn: async (params) => {
            const { data } = await ShopRendererApiClient.createTiktokIntegration(...params);
            return data;
        },
        ...options,
    });
    return {
        createTiktokIntegrationMutation,
    };
};
