import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
`;
export const Buttons = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const List = styled.div `
  margin-top: 12px;
  border-top: 1px solid ${Colors.GS400};
  border-bottom: 1px solid ${Colors.GS400};
`;
export const RemoveButton = styled.button `
  display: none;
  padding: 8px;
`;
export const SeriesListItem = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0px;

  &:hover {
    ${RemoveButton} {
      display: block;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid ${Colors.GS400};
  }
`;
