import { ThirdPartyApiClient } from '@fourthwall/services/lib/ThirdPartyApiClient';
import { useMutation } from '@tanstack/react-query';
export const useDisconnectStreamlabsMutation = () => {
    const disconnectStreamlabsMutation = useMutation({
        mutationFn: async () => {
            const { data } = await ThirdPartyApiClient.disconnectStreamlabs();
            return data;
        },
    });
    return { disconnectStreamlabsMutation };
};
