import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { RemovableUpload } from '../RemovableUpload/RemovableUpload';
import { UploadedImage } from '../UploadedImage/UploadedImage';
import * as S from './styled';
export const PostGalleryImage = ({ id, image, count, onImageRemove }) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
        opacity: isDragging ? 0.5 : undefined,
        cursor: 'grab',
    };
    return (React.createElement(S.ImageContainer, { ref: setNodeRef, style: style, count: count, ...attributes, ...listeners },
        React.createElement(RemovableUpload, { onRemove: () => {
                onImageRemove(id);
            } },
            React.createElement(UploadedImage, { image: image, objectFit: "contain" }))));
};
