import { Toasts } from '@fourthwall/components';
import { ShopUsersApiClient } from '@fourthwall/services/lib/ShopUsersApiClient';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import { useMutation } from '@tanstack/react-query';
export const useSendVerifyEmailMutation = () => {
    const sendVerifyEmailMutation = useMutation({
        mutationFn: async () => {
            const { data } = await ShopUsersApiClient.sendVerifyEmail();
            return data;
        },
        onSuccess: () => {
            Toasts.notify('You will receive an verification email shortly.', { type: 'info' });
        },
        onError: (error) => {
            const message = errorMessageSelector(error.response?.data, 'There was an error when sending verification email.');
            Toasts.notify(message, { type: 'error' });
        },
    });
    return { sendVerifyEmailMutation };
};
