import React, { useMemo, useState } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
export const useTableSortable = ({ list, onPositionChange, }) => {
    const [activeId, setActiveId] = useState('');
    const [listItemsOrder, setListItemsOrder] = React.useState([]);
    const listItemsOrdered = React.useMemo(() => {
        if (!listItemsOrder.length) {
            return list;
        }
        return [...list].sort((a, b) => {
            const aIndex = listItemsOrder.indexOf(a.id);
            const bIndex = listItemsOrder.indexOf(b.id);
            // if there is no index, it means that list should be sorted by default
            if (aIndex === -1 || bIndex === -1) {
                return 0;
            }
            return aIndex - bIndex;
        });
    }, [list, listItemsOrder]);
    const items = useMemo(() => listItemsOrdered?.map(({ id }) => id), [listItemsOrdered]);
    const dndContextProps = {
        onDragStart: ({ active }) => {
            setActiveId(active.id);
        },
        onDragEnd: ({ active, over }) => {
            if (over && active.id !== over?.id) {
                setListItemsOrder(() => {
                    const oldIndex = items.indexOf(active.id);
                    const newIndex = items.indexOf(over.id);
                    onPositionChange(oldIndex, newIndex);
                    return arrayMove(items, oldIndex, newIndex);
                });
            }
            setActiveId('');
        },
        onDragCancel: () => {
            setActiveId('');
        },
    };
    const sortableContextProps = {
        items,
    };
    return {
        activeId,
        listItemsOrdered,
        dndContextProps,
        sortableContextProps,
    };
};
