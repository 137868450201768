import { useRoles } from '@fourthwall/auth';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useSessionStorage } from '@fourthwall/components';
export const AdminModeContext = createContext(undefined);
export const AdminModeProvider = ({ children }) => {
    const { isLoadingRoles, hasRole } = useRoles();
    const [isActive, setIsActive] = useSessionStorage('isActive', hasRole('ROLE_FOURTHWALL_INTERNAL_ADMIN'));
    useEffect(() => {
        if (!isLoadingRoles) {
            setIsActive(hasRole('ROLE_FOURTHWALL_INTERNAL_ADMIN'));
        }
    }, [isLoadingRoles]);
    const toggleActive = useCallback(() => {
        const newValue = !isActive;
        setIsActive(newValue);
    }, [isActive, setIsActive]);
    const value = useMemo(() => ({ isActive: isActive && hasRole('ROLE_FOURTHWALL_INTERNAL_ADMIN'), toggleActive }), [hasRole, isActive, toggleActive]);
    return React.createElement(AdminModeContext.Provider, { value: value }, children);
};
export const useAdminModeContext = () => {
    return useContext(AdminModeContext);
};
