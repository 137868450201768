import { getShopRendererApiUrl } from '@fourthwall/env';
import { ApiBase } from '../ApiBase';
class ShopRendererThemesApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getThemeLibrary = () => {
            return this.get('/themes/library');
        };
        this.getInstalledThemes = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/themes`);
        };
        this.installTheme = (pathParams, requestBody) => {
            return this.post(`/shops/${pathParams.shop_id}/themes`, requestBody);
        };
        this.activateTheme = (pathParams, requestBody) => {
            return this.put(`/shops/${pathParams.shop_id}/themes/${pathParams.theme_id}`, requestBody);
        };
        this.reset = (pathParams) => {
            return this.post(`/shops/${pathParams.shop_id}/themes/${pathParams.shop_theme_id}/reset`);
        };
    }
}
export const ShopRendererThemesApiClient = new ShopRendererThemesApiClientModel(getShopRendererApiUrl('api'));
