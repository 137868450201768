import React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useAuth } from '@fourthwall/auth';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { useModalContext, ConfirmModal, Toasts } from '@fourthwall/components';
import { useDeleteCreatorIntegrationMutation } from '@mutations';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { useGoogleAnalyticsConfigurationQuery } from './useGoogleAnalyticsConfigurationQuery';
import { GoogleAnalyticsBar } from './GoogleAnalyticsBar';
const PROVIDER = 'google-analytics';
export const GoogleAnalyticsBarContainer = () => {
    const { keycloak } = useAuth();
    const { open } = useModalContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const [connected] = useQueryParam('connected', StringParam);
    const isConnected = typeof connected === 'string';
    const enabled = dashboardFeatureFlags.analyticsGoogleAnalytics.enabled;
    const { googleAnalyticsConfigurationQuery, invalidateGoogleAnalyticsConfigurationQuery } = useGoogleAnalyticsConfigurationQuery(undefined, {
        enabled,
        refetchInterval: (data) => (isConnected && data?.type === 'NOT_CONNECTED' ? 2_000 : false),
    });
    const { deleteCreatorIntegrationMutation } = useDeleteCreatorIntegrationMutation();
    const { isSuccess, data } = googleAnalyticsConfigurationQuery;
    if (!enabled || !isSuccess)
        return null;
    const connectHref = CreatorIntegrationsApiClient.appAuthorizationUrl({
        app: PROVIDER,
        token: keycloak?.token,
    }).toString();
    const disconnect = () => {
        open(ConfirmModal, {
            title: 'Are you sure you want to disconnect from Google Analytics?',
            confirmLabel: 'Yes, disconnect',
            confirmAppearance: 'destructive',
            onConfirm: async () => {
                await deleteCreatorIntegrationMutation.mutateAsync(PROVIDER, {
                    onSuccess: () => {
                        invalidateGoogleAnalyticsConfigurationQuery();
                        Toasts.notify('Disconnected from Google Analytics successfully.', { type: 'success' });
                    },
                });
            },
        });
    };
    const getProps = () => {
        switch (data.type) {
            case 'NOT_CONNECTED':
                return {
                    title: 'Connect Google Analytics to see views and other advanced analytics',
                    description: 'See how many people visit your site along and more advanced analytics',
                    button: {
                        label: isConnected ? 'Connecting...' : 'Connect for free',
                        href: connectHref,
                        target: '_blank',
                        disabled: isConnected,
                    },
                };
            case 'PROVISIONED':
                return {
                    title: 'See page views and other advanced analytics',
                    description: (React.createElement(React.Fragment, null,
                        "Connected to ",
                        data.email,
                        " ",
                        React.createElement("button", { onClick: disconnect }, "Disconnect"))),
                    button: { label: 'Accept Terms & Conditions', href: data.termsAndConditionsUri },
                };
            case 'IN_PROGRESS':
                return {
                    title: 'See page views and other advanced analytics',
                    description: (React.createElement(React.Fragment, null,
                        "Connected to ",
                        data.email,
                        " ",
                        React.createElement("button", { onClick: disconnect }, "Disconnect"))),
                    button: { label: 'Setup in progress...', disabled: true },
                };
            case 'CONNECTED':
                return {
                    title: 'See page views and other advanced analytics',
                    description: (React.createElement(React.Fragment, null,
                        "Connected to ",
                        data.email,
                        " ",
                        React.createElement("button", { onClick: disconnect }, "Disconnect"))),
                    button: {
                        label: 'View Google Analytics',
                        href: data.googleAnalyticsDashboardUri,
                        target: '_blank',
                    },
                };
        }
    };
    return React.createElement(GoogleAnalyticsBar, { ...getProps() });
};
