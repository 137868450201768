import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  min-width: 288px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: calc(100vh - 96px);
`;
export const Content = styled.div `
  overflow-y: auto;
  padding-bottom: 88px;
  padding-right: 20px;

  ${mediaQueries.tablet} {
    padding-bottom: 0;
  }
`;
export const InnerContent = styled.div `
  padding: 12px 0 0 20px;
`;
export const Footer = styled.div `
  display: flex;
  justify-content: stretch;
  gap: 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: ${Colors.GS0};
  padding: 12px 20px;

  ${mediaQueries.tablet} {
    position: relative;
    justify-content: space-between;
  }
`;
