import { ApiBase } from '../ApiBase';
class ThirdPartyApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getStreamlabsAuthUrl = () => {
            return this.get('/thirdparty-integrations/streamlabs/authUrl');
        };
        this.disconnectStreamlabs = () => {
            return this.delete('/thirdparty-integrations/streamlabs/token');
        };
    }
}
export const ThirdPartyApiClient = new ThirdPartyApiClientModel();
