import { SeriesCell } from '@modules/Dashboard/Memberships/modules/Series/components/SeriesCell';
import React from 'react';
export const tableHeaders = [
    {
        id: 'series',
        label: 'Series',
        render: (series) => {
            return (React.createElement(SeriesCell, { title: series.title, quantity: series.episodesCount, thumbnailUrl: series.coverImage?.url }));
        },
    },
];
