import { Icon, useModalContext } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import React from 'react';
import { usePageLayoutContext } from '../../../../providers/PageLayoutProvider';
import { AddSectionModalContainer } from '../../../PageCustom/modules/LayoutBlocks/modules/AddSectionModal';
import { useThemeLivePreviewContext } from '../../providers/ThemeLivePreviewProvider';
import * as S from './styled';
export const AddSectionToolbar = ({ position }) => {
    const { rect, activeSectionName, activeSectionKey } = useThemeLivePreviewContext();
    const { pageLayoutQuery } = usePageLayoutContext();
    const { open } = useModalContext();
    const borderWidth = 3;
    const block = pageLayoutQuery.data?.sections.blocks.find((block) => block.sections.some((section) => section.section_key === activeSectionKey));
    const sectionIndex = pageLayoutQuery.data?.sections.blocks.reduce((acc, block) => {
        const index = block.sections.findIndex((section) => section.section_key === activeSectionKey);
        if (index !== -1) {
            acc = index;
        }
        return acc;
    }, -1);
    const handleModalOpen = () => {
        if (!block || sectionIndex === undefined)
            return;
        open(AddSectionModalContainer, {
            blockId: block.id,
            type: block?.type || '',
            position: position === 'top' ? sectionIndex + 1 : sectionIndex + 2,
        });
    };
    if (!activeSectionName || !block?.modifiable || rect.top + rect.height < 60)
        return null;
    return (React.createElement(S.Layout, { style: {
            top: position === 'top' ? (rect.top > 0 ? 0 : Math.abs(rect.top)) - borderWidth : undefined,
            bottom: position === 'bottom'
                ? (rect.bottom > 0 ? 0 : Math.abs(rect.bottom)) - borderWidth
                : undefined,
            left: '50%',
            transform: 'translateX(-50%)',
        } },
        React.createElement(S.List, null,
            React.createElement(S.ListItem, { onClick: () => {
                    handleModalOpen();
                } },
                React.createElement(Icon, { component: PlusIcon, height: 12 }),
                "Add section"))));
};
