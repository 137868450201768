import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
import { ShopsApiClient } from '@fourthwall/services/lib/ShopsApiClient';
import { createQueryHook } from '@fourthwall/utils/lib/queries';
export const useCurrentShopQuery = createQueryHook(ShopsApiClient.getCurrentShop, {
    queryKey: ['currentShop'],
    select: (data) => {
        data.name = decodeHtmlEntities(data.name);
        data.creatorName = decodeHtmlEntities(data.creatorName);
        data.description = decodeHtmlEntities(data.description);
        return data;
    },
});
