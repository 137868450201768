import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { ButtonClean, Checkbox, Fieldset, HeadContent, Loading, RadioButton, SaveShortcutListener, Select, Subsection, Tooltip, } from '@fourthwall/components';
import { isProduction } from '@fourthwall/utils/lib/dev';
import { useFormik } from '@fourthwall/utils/lib/formik';
import range from 'lodash-es/range';
import React from 'react';
import { ConfirmBarContainer } from '../../../../../ConfirmBar/ConfirmBarContainer';
import { useFreeTierQuery } from '../../../Perks/hooks/useFreeTierQuery';
import { useAccountSettingsQuery } from '../../hooks/useAccountSettingsQuery';
import { useUpdateAccountSettingsTiersMutation } from '../../hooks/useUpdateAccountSettingsTiersMutation';
import { validationSchema } from './consts';
import { FormFields } from './types';
import { getUpdateAccountSettingsTiersPayload } from './utils';
export const General = () => {
    const { accountSettingsQuery } = useAccountSettingsQuery();
    const { freeTierQuery } = useFreeTierQuery();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { updateAccountSettingsTiersMutation } = useUpdateAccountSettingsTiersMutation();
    const formik = useFormik({
        initialValues: {
            annualEnabled: !!accountSettingsQuery.data?.tiers?.annual?.discount?.enabled,
            freeAccounts: accountSettingsQuery.data?.freeAccounts.displayEnabled ? 'show' : 'hide',
            contentVisibility: accountSettingsQuery.data?.tiers?.contentVisibility || 'ShowLockedOnlyHigherLevel',
            anualDiscount: accountSettingsQuery.data?.tiers?.annual?.discount?.percentOff
                ? accountSettingsQuery.data?.tiers?.annual?.discount?.percentOff?.toString()
                : '10',
            membersCountEnabled: !!accountSettingsQuery.data?.tiers?.membersCountEnabled,
            adultContent: !!accountSettingsQuery.data?.adultContent.present,
            audioDownload: !!accountSettingsQuery.data?.mediaDownload.audio.enabled,
        },
        validationSchema,
        onSubmit: (values) => {
            updateAccountSettingsTiersMutation.mutate(getUpdateAccountSettingsTiersPayload(values), {
                onSuccess: () => {
                    accountSettingsQuery.refetch();
                },
            });
        },
        enableReinitialize: true,
    });
    const selectOptions = range(1, 26).map((number) => ({
        value: number.toString(),
        label: number.toString(),
    }));
    const renderShowFreeAccounts = () => {
        if (freeTierQuery.data && freeTierQuery.data.status === 'Published') {
            return (React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Tooltip, { reference: React.createElement(RadioButton, { label: "Show free account signup link", suggestion: "Users will see tiers selection and a link to create a free account", disabled: true, value: "1" }), placement: "top" }, "If you want to enable this option you have to remove your free tier inside Perks & tiers tab."))));
        }
        return (React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(RadioButton, { value: formik.values.freeAccounts, checkedValue: "show", label: "Show free account signup link", suggestion: "Users will see tiers selection and a link to create a free account", name: FormFields.freeAccounts, onChange: formik.handleChange }))));
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(HeadContent, { title: "General" }),
        React.createElement(Loading, { isLoading: accountSettingsQuery.isFetching ||
                updateAccountSettingsTiersMutation.isLoading ||
                freeTierQuery.isLoading },
            React.createElement(Subsection, { title: "Annual subscription" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null, "Supporters will be able to pay up front for a whole year of membership. The subscription renews automatically each year and members can cancel it at any time. A good practice is to offer a lower price for the annual option.")),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Checkbox, { ...formik.getFieldProps('annualEnabled'), label: "Offer annual subscription" }))),
                    (formik.values.anualDiscount || formik.values.annualEnabled) && (React.createElement(Fieldset.Row, { inner: true },
                        React.createElement(Select, { ...formik.getFieldProps('anualDiscount'), options: selectOptions, label: "Discount value", prepend: "%", disabled: !formik.values.annualEnabled }))))),
            dashboardFeatureFlags.membershipsFreeAccounts.enabled && (React.createElement(Subsection, { title: "Free account visibility" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            "Show a link to creating a free account in places where memberships tiers are displayed.",
                            ' ',
                            React.createElement(ButtonClean, { href: "https://help.fourthwall.com/hc/en-us/articles/25005490238235", label: "Learn more", target: "_blank" }))),
                    renderShowFreeAccounts(),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(RadioButton, { value: formik.values.freeAccounts, checkedValue: "hide", label: "Hide free account signup link", suggestion: "Users will only see tiers selection", name: FormFields.freeAccounts, onChange: formik.handleChange }))))),
            React.createElement(Subsection, { title: "Post previews for lower tier members" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null, "Encourage lower-tier members to upgrade by showing blurred-out post previews of posts that are only accessible to higher tiers.")),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(RadioButton, { value: formik.values.contentVisibility, checkedValue: "ShowLockedOnlyHigherLevel", label: "Yes, show post previews to lower tier members", name: "contentVisibility", onChange: formik.handleChange }))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(RadioButton, { value: formik.values.contentVisibility, checkedValue: "ShowOnlyAccessible", label: "No, do not show post previews", name: "contentVisibility", onChange: formik.handleChange }))))),
            React.createElement(Subsection, { title: "Downloadable audio files" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Checkbox, { ...formik.getFieldProps('audioDownload'), label: "Allow members to download audio posts" }))))),
            React.createElement(Subsection, { title: "Publicly show the number of members in each tier" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null, "Allow anyone to see how many active members are in each membership tier. The number will be visible on all pages where your membership tiers are shown.")),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Checkbox, { ...formik.getFieldProps('membersCountEnabled'), label: "Show active members count for each membership tier" }))))),
            !isProduction() && (React.createElement(Subsection, { title: "Adult/mature content" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null, "Ask visitors to confirm they are over 18 years old and alert them that some of my content may be for adults only.")),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Checkbox, { ...formik.getFieldProps('adultContent'), label: "Ask visitors to confirm they are over 18 before they view my content" }))))))),
        React.createElement(SaveShortcutListener, { disabled: !formik.dirty, onSave: formik.submitForm }),
        React.createElement(ConfirmBarContainer, { isOpen: formik.dirty, isLoading: updateAccountSettingsTiersMutation.isLoading, onConfirm: formik.submitForm, onCancel: formik.resetForm })));
};
