import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Subtitle = styled.span `
  color: ${Colors.B500};
  line-height: 1.5;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 4px;
`;
export const Paragraph = styled.p `
  color: ${Colors.GS600};
  font-size: 16px;
  line-height: 1.5;

  margin-top: 4px;
`;
