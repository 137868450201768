import React from 'react';
export const getPermissionsDescription = (configurationData, commercesData, onClick) => {
    if (!commercesData)
        return null;
    const { businesses, pages } = commercesData;
    const businessesDescription = businesses.map((business) => business.name).join(', ');
    const pagesDescription = pages.map((page) => page.name).join(', ');
    const commerces = commercesData.businesses.reduce((prev, curr) => [...prev, ...curr.cms], []);
    const commercesDescription = commerces.map(({ name }) => name).join(', ');
    const selectedPermissionsDescription = (() => {
        if (!configurationData || configurationData.type !== 'CONFIGURED')
            return '';
        const { metaBusinessId, metaCMSId } = configurationData;
        const business = businesses.find(({ id }) => id === metaBusinessId);
        const commerce = commerces.find(({ id }) => id === metaCMSId);
        if (!business || !commerce)
            return '';
        // Don't forget to add space at the end of the string.
        return `You have selected permissions for: ${business.name}, ${commerce.name}. `;
    })();
    const allPermissionsDescriptionPrefix = selectedPermissionsDescription
        ? 'All provided permissions'
        : 'You have provided following permissions';
    return (React.createElement(React.Fragment, null,
        selectedPermissionsDescription,
        allPermissionsDescriptionPrefix,
        ": Businesses (",
        businessesDescription,
        "), Pages (",
        pagesDescription,
        "), Commerces (",
        commercesDescription,
        ").",
        ' ',
        React.createElement("button", { onClick: onClick }, "Change permissions")));
};
export const findPageByCmsId = (cmsId, data) => {
    if (!data)
        return;
    const { businesses, pages } = data;
    const business = businesses.find((business) => business.cms.find((cms) => cms.id === cmsId));
    if (!business)
        return;
    const cms = business.cms.find((cms) => cms.id === cmsId);
    if (!cms)
        return;
    const page = pages.find((page) => page.id === cms.merchantPageId);
    return page;
};
export const createPreviewProps = (merchantPageId, data) => {
    if (!merchantPageId || !data)
        return;
    const page = data.pages.find((page) => page.id === merchantPageId);
    if (!page)
        return;
    const business = data.businesses.find((business) => business.cms.find((cms) => cms.merchantPageId === merchantPageId));
    if (!business)
        return;
    const cms = business.cms.find((cms) => cms.merchantPageId === merchantPageId);
    if (!cms)
        return;
    return {
        pageTitle: page.name,
        pageDescription: 'Facebook Page',
        pageFooter: `ID: ${page.id}`,
        commerceName: cms.name,
        commerceDescription: 'Merchant Commerce',
        commerceFooter: `ID: ${cms.id}`,
        image: page.pictureUrl,
    };
};
