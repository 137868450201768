import isObject from 'lodash-es/isObject';
import merge from 'lodash-es/merge';
import reduce from 'lodash-es/reduce';
import set from 'lodash-es/set';
export const flattenKeys = (obj, path = []) => {
    if (!isObject(obj)) {
        return { [path.join('.')]: obj };
    }
    return reduce(obj, (cum, next, key) => merge(cum, flattenKeys(next, [...path, key])), {});
};
export const unflattenKeys = (obj) => reduce(obj, (cum, next, key) => set(cum, key, next), {});
