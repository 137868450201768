import { FILTER_PRODUCT_STATUS } from '../../modules/ProductsFilter/consts';
export const getProductsStatus = (statusParam, showArchived) => {
    const statuses = statusParam ? statusParam.split(',') : FILTER_PRODUCT_STATUS;
    return (showArchived ? [...statuses, 'ARCHIVED'] : statuses);
};
export const getIdsFromOffers = (offers) => offers.map(({ id }) => id);
export const getProductsWithAnalytics = (offers, analyticsItems) => offers.map((offer) => ({
    ...offer,
    sold: analyticsItems?.[offer.id]?.sold || 0,
}));
