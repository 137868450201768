import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const TableContainer = styled.div `
  word-wrap: anywhere;
  min-width: 768px;
`;
export const Headers = styled.div `
  display: flex;
  flex: 1;
  gap: 20px;
  border-bottom: 1px solid ${Colors.GS400};
  padding-bottom: 17px;
`;
export const Row = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 16px 0 17px;
  border-bottom: 1px solid ${Colors.GS400};
`;
export const Content = styled.div `
  display: flex;
  gap: 20px;
  align-items: flex-start;
`;
export const Error = styled.div `
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  padding-top: 8px;
  color: ${Colors.R300};
  text-align: right;
`;
const alignmentStyles = {
    left: css `
    justify-content: flex-start;
  `,
    center: css `
    justify-content: center;
  `,
    right: css `
    justify-content: flex-end;
  `,
};
export const Cell = styled.div `
  display: flex;
  font-size: 14px;
  line-height: 25px;
  align-items: center;
  flex: ${({ flex }) => flex || 1};

  ${({ alignment }) => alignmentStyles[alignment || 'left']};
`;
export const HeadCell = styled(Cell) `
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  color: ${Colors.GS600};
  text-transform: uppercase;
`;
