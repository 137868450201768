import { checklistDataLayer } from '../../../../../../../utils/gtm/checklistDataLayer';
import { routing } from '../../../../../../../utils/routing';
import addMembershipsImage from './assets/add-memberships.png';
import createPerksImage from './assets/create-perks.png';
import createPostImage from './assets/create-post.png';
import { LINK_TO_HOMEPAGE_STEP_TYPE, PERKS_STEP_TYPE, POST_STEP_TYPE } from './consts';
export const mergeStep = (step, apiStep, onSkip) => {
    const completed = apiStep.completed || apiStep.skipped;
    return {
        ...step,
        key: apiStep.id.toString(),
        completed,
        onSkip: !completed && apiStep.skippable ? onSkip : undefined,
    };
};
export const getSteps = (page) => {
    return [
        {
            title: 'Create perks and tiers',
            description: 'Create a foundation of your Memberships by defining subscription tiers, pricing and unique benefits.',
            imageUrl: createPerksImage,
            type: PERKS_STEP_TYPE,
            primaryButton: {
                label: 'Create tiers',
                to: routing.memberships.perks,
                onClick: () => {
                    checklistDataLayer('checklistStep', page, 'Memberships', 'Create perks and tiers');
                },
            },
            completedButton: {
                label: 'Edit',
                to: routing.memberships.perks,
            },
        },
        {
            title: 'Create your first post',
            description: 'Add your first, exclusive members-only content or simply give your members an idea of what’s coming!',
            imageUrl: createPostImage,
            type: POST_STEP_TYPE,
            primaryButton: {
                label: 'Create post',
                to: routing.memberships.posts.create,
                onClick: () => {
                    checklistDataLayer('checklistStep', page, 'Memberships', 'Create your first post');
                },
            },
            completedButton: {
                label: 'Create another',
                to: routing.memberships.posts.create,
            },
        },
        {
            title: 'Add Memberships to your homepage',
            description: 'Now that you’re launch ready, promote your Memberships to your community on your homepage!',
            imageUrl: addMembershipsImage,
            type: LINK_TO_HOMEPAGE_STEP_TYPE,
            primaryButton: {
                label: 'Add memberships',
                to: `${routing.themeEditor.general.self}?${new URLSearchParams({
                    // 'addMembershipsModal' param comes from ThemeEditor/modules/Theme module
                    addMembershipsModal: 'true',
                }).toString()}`,
                onClick: () => {
                    checklistDataLayer('checklistStep', page, 'Memberships', 'Add Memberships to your homepage');
                },
            },
        },
    ];
};
