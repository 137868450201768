import { ButtonClean, Fieldset, Icon, Input, useModalContext, Subsection, } from '@fourthwall/components';
import { NewWindowIcon } from '@fourthwall/icons';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React from 'react';
import { FormFields } from '../../types';
import * as S from './styled';
import { YouTubeStreamModal } from './YouTubeStreamModal/YouTubeStreamModal';
export const LivestreamFields = ({ disabled }) => {
    const { values, setFieldValue } = useFormikContext();
    const modal = useModalContext();
    const getInfo = () => {
        if (values.livestreamType === 'YouTube Live') {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.NewWindowLink, null,
                    React.createElement(ButtonClean, { label: "Start an unlisted YouTube livestream", withUnderline: true, onClick: () => modal.open(YouTubeStreamModal) }),
                    React.createElement(Icon, { component: NewWindowIcon, height: 12 })),
                "\u00A0and paste URL below.\u00A0",
                React.createElement(ButtonClean, { label: "Learn more", withUnderline: true })));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(S.NewWindowLink, null,
                React.createElement(ButtonClean, { label: "Start a Vimeo livestream", withUnderline: true }),
                React.createElement(Icon, { component: NewWindowIcon, height: 12 })),
            "\u00A0and paste URL below.\u00A0",
            React.createElement(ButtonClean, { label: "Learn more", withUnderline: true })));
    };
    return (React.createElement(Subsection, null,
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(S.Tabs, null, ['YouTube Live', 'Vimeo'].map((type) => (React.createElement(S.Tab, { active: values.livestreamType === type, onClick: () => setFieldValue(FormFields.livestreamType, type) }, type)))))),
            React.createElement(Fieldset.Row, null, getInfo()),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: FormFields.livestreamUrl, component: Input, label: `${values.livestreamType} livestream URL`, required: true, disabled: disabled, fullWidth: true, suggestion: React.createElement(ButtonClean, { label: "Where can I find this link?", withUnderline: true, appearance: "secondary" }) }))))));
};
