import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  position: relative;
`;
const getOptionsContainerOffset = (size) => {
    if (size === 'large')
        return '64px';
    if (size === 'medium')
        return '56px';
    if (size === 'small')
        return '48px';
};
const getOptionsContainerPlacement = ({ size, placement, withError }) => {
    if (placement === 'top') {
        return css `
      bottom: calc(${getOptionsContainerOffset(size)} + ${withError ? 28 : 0}px);
    `;
    }
    if (placement === 'bottom') {
        return css `
      top: ${() => getOptionsContainerOffset(size)};
    `;
    }
};
export const OptionsContainer = styled.div `
  position: absolute;
  z-index: 1;
  width: 100%;
  border: 1px solid ${Colors.GS500};
  background: ${Colors.GS0};
  ${getOptionsContainerPlacement}
`;
export const OptionsWrapper = styled.div `
  ${({ maxHeight }) => css `
    max-height: ${maxHeight}px;
    overflow-y: scroll;
  `};
`;
export const EmptyStateWrapper = styled.div `
  height: ${({ height }) => `${height}px`};
`;
