import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const ButtonBackground = styled.div `
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  background-color: ${Colors.GS1000};
`;
export const Button = styled.button `
  position: absolute;
  display: none;
  top: 4px;
  right: 4px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  color: ${Colors.GS0};

  &:hover {
    ${ButtonBackground} {
      opacity: 1;
    }
  }
`;
export const Container = styled.div `
  position: relative;
  height: 100%;
  z-index: 1;
  background-color: ${Colors.GS300}};

  &:hover {
    ${Button} {
      display: flex;
    }
  }
`;
