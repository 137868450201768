import { routing } from '@utils/routing';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDashboardPermissions } from '../../../../../../../components/utils/DashboardPermissions';
import { useSamplesBudgetBalance } from '../../hooks/useSamplesBudgetBalance';
import { useSamplesOffers } from '../../hooks/useSamplesOffers';
import { SamplesCreditView } from './SamplesCreditView';
export const SamplesCreditContainer = () => {
    const { samplesBudgetBalance } = useSamplesBudgetBalance();
    const { samplesOffers } = useSamplesOffers();
    const { hasPermission } = useDashboardPermissions();
    const navigate = useNavigate();
    const getProductStatus = () => {
        if (!hasPermission('settings.samples.create'))
            return 'HIDDEN';
        if (samplesOffers.isLoading)
            return 'LOADING';
        if (!samplesOffers.enabled)
            return 'NO_PRODUCTS';
        return 'AVAILABLE';
    };
    const handleProductsManage = () => {
        navigate(routing.products.all.self);
    };
    return (React.createElement(SamplesCreditView, { credit: samplesBudgetBalance, products: { status: getProductStatus(), onManage: handleProductsManage } }));
};
