import { ButtonClean, Checkbox, CurrencyInput, Fieldset, HeadContent, Loading, SaveShortcutListener, Subsection, } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { ConfirmBarContainer } from '../../../../../ConfirmBar/ConfirmBarContainer';
import { useDirectMessagesSettingsQuery } from '../../../../hooks/useDirectMessagesSettingsQuery';
import { useUpdateDirectMessagesSettingsMutation } from '../../hooks/useUpdateDirectMessagesSettingsMutation';
import { validationSchema } from './consts';
import { FormFields } from './types';
import { getUpdateDirectMessagesPayload } from './utils';
export const Messages = () => {
    const { directMessagesSettingsQuery } = useDirectMessagesSettingsQuery();
    const { updateDirectMessagesSettingsMutation } = useUpdateDirectMessagesSettingsMutation();
    const formik = useFormik({
        initialValues: {
            messagesEnabled: directMessagesSettingsQuery.data?.enabled,
            tipsEnabled: !!directMessagesSettingsQuery.data?.tips,
            minTipEnabled: !!directMessagesSettingsQuery.data?.tips?.minimumAmount,
            minTipAmount: directMessagesSettingsQuery.data?.tips?.minimumAmount
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                    directMessagesSettingsQuery.data?.tips?.minimumAmount / 100
                : 3,
        },
        validationSchema,
        onSubmit: (values) => {
            updateDirectMessagesSettingsMutation.mutate([getUpdateDirectMessagesPayload(values)], {
                onSuccess: () => {
                    directMessagesSettingsQuery.refetch();
                },
            });
        },
        enableReinitialize: true,
    });
    const handleMessagesEnableChange = (e) => {
        formik.setFieldValue(FormFields.messagesEnabled, e.target.checked);
        if (!formik.values.tipsEnabled) {
            formik.setFieldValue(FormFields.tipsEnabled, true);
        }
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(HeadContent, { title: "Messages" }),
        React.createElement(Loading, { isLoading: directMessagesSettingsQuery.isFetching || updateDirectMessagesSettingsMutation.isLoading },
            React.createElement(Subsection, { title: "Messages" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            "Chat with your members. Send individual or mass messages that include free or paid content.",
                            ' ',
                            React.createElement(ButtonClean, { href: "https://help.fourthwall.com/hc/en-us/articles/22555386644251-Direct-messages-tips", label: "Learn more", target: "_blank", withUnderline: true }))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Checkbox, { ...formik.getFieldProps(FormFields.messagesEnabled), label: "Enable messages", onChange: handleMessagesEnableChange }))))),
            formik.values.messagesEnabled && (React.createElement(Subsection, { title: "Tips" },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null, "Members will be able to send you tips via messages.")),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Checkbox, { ...formik.getFieldProps(FormFields.tipsEnabled), label: "Allow members to send tips" }))),
                    formik.values.tipsEnabled && (React.createElement(React.Fragment, null,
                        React.createElement(Fieldset.Row, { inner: true },
                            React.createElement(Fieldset.Item, null,
                                React.createElement(Checkbox, { ...formik.getFieldProps(FormFields.minTipEnabled), label: "Set minimum tip requirement for members to send a message" }))),
                        formik.values.minTipEnabled && (React.createElement(Fieldset.Row, { inner: true },
                            React.createElement(Fieldset.Item, null,
                                React.createElement("div", { style: { width: 160 } },
                                    React.createElement(CurrencyInput, { ...formik.getFieldProps(FormFields.minTipAmount), label: "Minimum tip" }))))))))))),
        React.createElement(SaveShortcutListener, { disabled: !formik.dirty, onSave: formik.submitForm }),
        React.createElement(ConfirmBarContainer, { isOpen: formik.dirty, isLoading: updateDirectMessagesSettingsMutation.isLoading, onConfirm: formik.submitForm, onCancel: formik.resetForm })));
};
