import { HeadContent, Skeleton, ButtonClean, Banner } from '@fourthwall/components';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { routing } from '@utils/routing';
import { ANCHOR_IDS } from '@constants/anchorIds';
import { ConnectBar } from '../../components/ConnectBar';
export const AppsKlaviyoView = ({ connectBar }) => (React.createElement(React.Fragment, null,
    React.createElement(HeadContent, { title: "Klaviyo" }),
    React.createElement(PageLayout, { size: "tiny" },
        React.createElement(Dashboard.Section, { name: "Klaviyo", title: React.createElement(React.Fragment, null,
                "Use Klaviyo to automatically sync all customer emails. Klaviyo allows you to message past buyers and send custom cart abandonment emails.",
                ' ',
                React.createElement(ButtonClean, { label: "Learn More", href: routing.external.helpCenter.klaviyo, target: "_blank" }),
                "."), topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }) },
            React.createElement("div", { style: { marginTop: 16 - 24 } }, connectBar ? (React.createElement(ConnectBar, { ...connectBar })) : (React.createElement(Skeleton, null,
                React.createElement(Skeleton.Shape, { height: 64 })))),
            React.createElement("div", { style: { marginTop: 20 } },
                React.createElement(Banner, { appearance: "warning" },
                    "Fourthwall's default abandoned cart emails are turned on. If you're using Klaviyo to send abandoned cart emails make sure to",
                    ' ',
                    React.createElement(HashLink, { to: {
                            pathname: routing.settings.checkout,
                            hash: ANCHOR_IDS.ABANDONED_CHECKOUT_REMINDERS,
                        } }, "disable Fourthwall cart abandonment emails"),
                    "."))))));
