import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 8px;

  ${mediaQueries.laptop} {
    justify-content: flex-start;
    width: auto;
  }
`;
export const Link = styled.a `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  height: 48px;
  background-color: ${Colors.GS300};
  flex: 1;

  &:hover {
    background-color: ${Colors.GS500};
  }

  ${mediaQueries.laptop} {
    width: 48px;
    flex: none;
  }
`;
