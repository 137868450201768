import { SideSummary } from '@components/common/SideSummary';
import { UnavailableResource } from '@components/common/UnavailableResource';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { AlertModal, ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { arrayMove } from '@fourthwall/utils/lib/array';
import { formatCurrency } from '@fourthwall/utils/lib/currency';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import { pluralize } from '@fourthwall/utils/lib/string';
import { DigitalProductUpdateModal } from '@modules/Dashboard/Product/components/DigitalProductUpdateModal';
import { ProductSlugUpdateModal } from '@modules/Dashboard/Product/components/ProductSlugUpdateModal';
import { useOfferSlugSuggestionMutation } from '@modules/Dashboard/Product/hooks/useOfferSlugSuggestionMutation';
import { useShippingValidator } from '@modules/Dashboard/Product/modules/shipping/useShippingValidator';
import { productValidatorSchema } from '@modules/Dashboard/Product/validators/productValidatorSchema';
import { validate } from '@modules/Dashboard/Product/validators/validate';
import { useDeleteOfferMutation } from '@mutations';
import { routing } from '@utils/routing';
import { getMapFromTierIds, getTierIdsFromMap } from '@utils/tiers';
import { Formik } from 'formik';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import isNil from 'lodash-es/isNil';
import set from 'lodash-es/set';
import unescape from 'lodash-es/unescape';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdminModeContext } from '../../App/providers/AdminModeProvider';
import { useCurrentShop } from '../hooks/useCurrentShop';
import { useMembershipAccountQuery } from '../hooks/useMembershipAccountQuery';
import { useShopShippingFlatRatesQuery } from '../hooks/useShopShippingFlatRatesQuery';
import { OfferImagesContainer } from './components/OfferImages/OfferImagesContainer';
import { LABEL_BY_FULFILLMENT_SERVICE, LABEL_BY_MANUFACTURING_SERVICE } from './consts';
import { useAssignBulkImagesMutation } from './hooks/useAssignBulkImagesMutation';
import { useAvailabilityMutation } from './hooks/useAvailabilityMutation';
import { useCustomizationQuery } from './hooks/useCustomizationQuery';
import { useManufacturerFulfillerPairs } from './hooks/useManufacturerFulfillerPairs';
import { useMinimumProfit } from './hooks/useMinimumProfit';
import { useOfferOrdersByVariantsAnalyticsQuery } from './hooks/useOfferOrdersByVariantsAnalyticsQuery';
import { useOfferPendingOrdersQuery } from './hooks/useOfferPendingOrdersQuery';
import { useOrderSamples } from './hooks/useOrderSamples';
import { useProductBySlugQuery } from './hooks/useProductBySlugQuery';
import { useProductPricing } from './hooks/useProductPricing';
import { useStatusMutation } from './hooks/useStatusMutation';
import { useVariantsAnalyticsQuery } from './hooks/useVariantsAnalyticsQuery';
import { useCreateStockMutation, useDeleteStockMutation, useUpdateOfferMutation, useUpdateOfferSlugMutation, useUpdateStockMutation, } from './mutations';
import { useProductBanners } from './ProductBanners/hooks/useProductBanners';
import { setVariantsCompareAtValue } from './ProductPricing/utils/setVariantsCompareAtValue';
import { setVariantsPriceValue } from './ProductPricing/utils/setVariantsPriceValue';
import { CatalogProductDetails } from './ProductSidebar/modules/CatalogProductDetails/CatalogProductDetails';
import { CatalogProductionMethod } from './ProductSidebar/modules/CatalogProductionMethod/CatalogProductionMethod';
import { OfferListingType } from './ProductSidebar/modules/OfferListingType';
import { ProductView } from './ProductView';
import { useOfferQuery } from './queries/useOfferQuery';
import { getListingTypeUtil, getOfferTypeUtil, isShipmentStartDateEditable, } from './store/product/utils';
import { convertCompareAtPriceToMoneyValueOrNull, getInitialValues } from './utils';
let initialValues;
export const Product = () => {
    const { productId = '' } = useParams();
    const { offerQuery, invalidateOfferQuery, setOfferData } = useOfferQuery([productId]);
    const navigate = useNavigate();
    const { open } = useModalContext();
    const adminMode = useAdminModeContext();
    const { hasPermission } = useDashboardPermissions();
    const { currentShop } = useCurrentShop();
    const { createStockMutation } = useCreateStockMutation();
    const { updateStockMutation } = useUpdateStockMutation();
    const { deleteStockMutation } = useDeleteStockMutation();
    const product = offerQuery.data;
    const bespokeProductId = product?.bespokeProductId;
    const name = product?.name || '';
    const slug = product?.slug || '';
    const productStatus = product?.state.status;
    const withMembersOnly = !isNil(product?.requirements?.allowedTiers);
    const membersOnlyType = product?.requirements?.allowedTiers?.type || 'ALL_TIERS';
    const tiersIds = product?.requirements?.allowedTiers?.type === 'SELECTED_TIERS'
        ? product.requirements?.allowedTiers.tiersIds
        : [];
    const images = product?.images;
    const variantTypes = product?.variantTypes || [];
    const productIsDeleting = false; // TODO
    const { updateOfferMutation } = useUpdateOfferMutation();
    const productUpdateIsSubmitting = updateOfferMutation.isLoading;
    const productIsFetching = offerQuery.isLoading;
    const isError = offerQuery.isError;
    const visualHints = product?.visualHints || { customSkus: false };
    const description = product?.description || '';
    const variants = product?.variants || [];
    const digitalItems = product?.digitalItems || [];
    const [isUploadingImages, setIsUploadingImages] = useState(false);
    const fulfillmentService = product?.fulfillingService || 'FULFILLED_BY_CREATOR';
    const manufacturingService = product?.manufacturingService || 'FOURTHWALL_BESPOKE';
    const offerType = getOfferTypeUtil(manufacturingService);
    const listingType = getListingTypeUtil(product);
    const shipment = product?.shipment;
    const { getSlugSuggestionMutation } = useOfferSlugSuggestionMutation();
    const { availabilityMutation } = useAvailabilityMutation();
    const { statusMutation } = useStatusMutation();
    const { customization } = useCustomizationQuery(product?.customizationId);
    const { shopShippingFlatRatesQuery } = useShopShippingFlatRatesQuery();
    const { productBySlugQuery } = useProductBySlugQuery([customization?.product.slug], {
        enabled: !!customization?.product.slug,
    });
    const productBanners = useProductBanners({
        offer: product || undefined,
        product: productBySlugQuery.data,
    });
    const { validateManufacturerFulfillerPair } = useManufacturerFulfillerPairs();
    const { membershipAccountQuery } = useMembershipAccountQuery();
    const { minimumProfit } = useMinimumProfit();
    const { onOrderSamples } = useOrderSamples(product);
    const membersOnlyTierIds = getMapFromTierIds(tiersIds, membershipAccountQuery.data?.tiers);
    const formRef = useRef(null);
    const { assignBulkImagesMutation } = useAssignBulkImagesMutation();
    const { offerOrdersByVariantsAnalyticsQuery } = useOfferOrdersByVariantsAnalyticsQuery([
        { offerId: productId },
    ]);
    const { offerPendingOrdersQuery } = useOfferPendingOrdersQuery([{ offerId: productId }], {
        enabled: !!productId,
    });
    const ids = Object.keys(offerOrdersByVariantsAnalyticsQuery.data?.notArchivedVariantsSales || {});
    const { variantsAnalyticsQuery } = useVariantsAnalyticsQuery([{ ids }], { enabled: !!ids.length });
    const totalUnitsSold = offerOrdersByVariantsAnalyticsQuery.data?.totalUnitsSold;
    const { pricingType, setPricingType, showPricingPerVariant } = useProductPricing();
    const pendingOrdersCount = offerPendingOrdersQuery?.data?.count;
    const maxDigitalFilesSize = product?.config.digitalFileProperties?.maxDigitalFileSize;
    const { updateOfferSlugMutation } = useUpdateOfferSlugMutation();
    const { deleteOfferMutation } = useDeleteOfferMutation();
    // validators
    const shippingValidatorSchema = useShippingValidator();
    const getShipmentStartDate = (shipmentValue, manufacturingService) => {
        if (!isShipmentStartDateEditable(manufacturingService)) {
            return { type: 'UNKNOWN' };
        }
        if (shipmentValue.type === 'DATE_RANGE') {
            return { type: shipmentValue.type, ...shipmentValue.dates };
        }
        if (shipmentValue.type === 'DAYS_RANGE') {
            return { type: shipmentValue.type, ...shipmentValue.days };
        }
        if (shipmentValue.type === 'UNKNOWN') {
            return { type: shipmentValue.type };
        }
    };
    const handleStockCreate = (stock) => {
        if (stock.quantity === undefined)
            return;
        createStockMutation.mutate([{ variantId: stock.id, quantity: stock.quantity }], {
            onSuccess: () => {
                invalidateOfferQuery();
            },
        });
    };
    const handleStockUpdate = (stock) => {
        updateStockMutation.mutate([{ variantId: stock.id }, stock.data], {
            onSuccess: () => {
                invalidateOfferQuery();
            },
        });
    };
    const handleStockDelete = (stock) => {
        deleteStockMutation.mutate([{ variantId: stock }], {
            onSuccess: () => {
                invalidateOfferQuery();
            },
        });
    };
    const handleAvailabilityChange = (isSoldOut) => {
        availabilityMutation.mutate({ offerId: productId, params: { available: !isSoldOut } }, {
            onSuccess: (data) => {
                setOfferData(data); // TODO: decodeHtmlEntities
            },
            onError: (error) => {
                Toasts.notify(errorMessageSelector(error.response?.data), { type: 'error' });
            },
        });
    };
    const handleChangeUrl = (slug) => {
        updateOfferSlugMutation.mutate([{ offerId: productId }, { newSlug: slug }], {
            onSuccess: (data) => {
                setOfferData(data); // TODO: decodeHtmlEntities
            },
        });
    };
    const handleArchive = () => {
        deleteOfferMutation.mutate([{ offerId: productId }], {
            onSuccess: () => {
                navigate(routing.products.all.self);
            },
        });
    };
    const handleStatusChange = (newStatus) => {
        statusMutation.mutate({ offerId: productId, params: { status: newStatus } }, {
            onSuccess: (data) => {
                setOfferData(data); // TODO: decodeHtmlEntities
            },
            onError: (error) => {
                Toasts.notify(error.message, { type: 'error' });
            },
        });
    };
    const handleNameChange = () => {
        getSlugSuggestionMutation.mutate([{ offerId: productId }], {
            onSuccess: (data) => {
                if (data.type === 'SUGGESTION') {
                    open(ProductSlugUpdateModal, {
                        baseUrl: `${currentShop?.baseUri}/products/`,
                        newSlug: data.slug,
                        prevSlug: product?.slug || '',
                        onConfirm: () => {
                            handleChangeUrl(data.slug);
                        },
                    });
                }
            },
        });
    };
    const handleSubmit = async (values) => {
        let updatedValues = { ...values };
        // NOTE: This is only for admins - force changing fulfillmentService before publishing product
        if (adminMode?.isActive &&
            getOfferType() === 'MANUAL' &&
            values.fulfillmentService === 'FULFILLED_BY_FOURTHWALL' &&
            initialValues.isSoldOut &&
            !values.isSoldOut) {
            return open(AlertModal, {
                title: 'Unable to publish product',
                text: 'Make sure to change fulfiller from Fulfilled by Fourthwall before marking product as not sold out.',
            });
        }
        try {
            const hasValidManufacturerAndFulfillerPair = validateManufacturerFulfillerPair({
                manufacturer: values.manufacturingService,
                fulfiller: values.fulfillmentService,
            });
            if (adminMode?.isActive && !hasValidManufacturerAndFulfillerPair) {
                await new Promise((resolve, reject) => {
                    open(ConfirmModal, {
                        title: 'Please confirm this manufacturer + fulfiller pair',
                        text: `We do not frequently use '${LABEL_BY_MANUFACTURING_SERVICE[values.manufacturingService]}' with '${LABEL_BY_FULFILLMENT_SERVICE[values.fulfillmentService]}'. Please click save below to confirm`,
                        onCancel: () => reject(new Error('Invalid manufacturer + fulfiller pair')),
                        onConfirm: () => resolve(),
                    });
                });
            }
            if (!isEqual(initialValues.digitalItems, values.digitalItems) &&
                totalUnitsSold &&
                totalUnitsSold > 0) {
                await new Promise((resolve, reject) => {
                    open(DigitalProductUpdateModal, {
                        onConfirm: (digitalItemsUpdateOrders) => {
                            updatedValues = { ...updatedValues, digitalItemsUpdateOrders };
                            resolve();
                        },
                        onCancel: () => reject(new Error('Digital items update canceled')),
                    });
                });
            }
            if (!isEqual(initialValues.shipment, values.shipment) &&
                ['DATE_RANGE', 'DAYS_RANGE'].includes(values.shipment.type) &&
                values.shipmentDateUpdatePendingOrders &&
                values.shipmentDateNotifyPendingSupporters &&
                pendingOrdersCount) {
                const supportersCount = `${pendingOrdersCount || 0} ${pluralize(pendingOrdersCount || 0, 'supporter')}`;
                await new Promise((resolve, reject) => {
                    open(ConfirmModal, {
                        title: `Are you sure you want to email ${supportersCount}?`,
                        text: `Please confirm you want to notify ${supportersCount} about the updated delivery time.`,
                        confirmLabel: 'Yes, continue',
                        onConfirm: () => resolve(),
                        onCancel: () => reject(new Error('Shipment date update canceled')),
                    });
                });
            }
        }
        catch (e) {
            return;
        }
        return await updateProduct(updatedValues);
    };
    const updateProduct = async (values) => {
        const shouldUpdateAvailability = initialValues.isSoldOut !== values.isSoldOut;
        const images = values.offerImages.map((item) => ({
            ...item,
            tags: { tags: item.tags },
        }));
        await assignBulkImagesMutation.mutateAsync([
            { offerId: productId },
            { replaceAll: true },
            { images },
        ]);
        const { withMembersOnly, membersOnlyType, membersOnlyTierIds, fulfillmentService, shipment, manufacturingService, bespokeProductId, shipmentDateNotifyPendingSupporters, shipmentDateUpdateReason, ...rest } = values;
        const shipmentStartDate = getShipmentStartDate(shipment, manufacturingService);
        const productData = {
            ...rest,
            // @ts-expect-error - null is not included in compareAtPrice type
            variants: values.variants.map((variant) => {
                if (variant.compareAtPrice !== null) {
                    return {
                        ...variant,
                        compareAtPrice: convertCompareAtPriceToMoneyValueOrNull(variant.compareAtPrice),
                    };
                }
                return variant;
            }),
            requirements: {
                // @ts-expect-error - should be fixed with schema update
                allowedTiers: withMembersOnly
                    ? { type: membersOnlyType, tiersIds: getTierIdsFromMap(membersOnlyTierIds) }
                    : null,
            },
            digitalItems: values.digitalItems.map((item) => ({ name: item.name, uri: item.url })),
            fulfillingService: fulfillmentService,
            manufacturingService,
            // @ts-expect-error - should be fixed with schema update
            shipmentStartDate,
            shipmentDateNotifyPendingSupporters: shipmentStartDate?.type === 'UNKNOWN' ? false : shipmentDateNotifyPendingSupporters,
            shipmentDateUpdateReason: shipmentStartDate?.type === 'UNKNOWN' ? '' : shipmentDateUpdateReason,
            bespokeProductId,
            soundScanInfo: product?.soundScanInfo,
        };
        if (shouldUpdateAvailability) {
            handleAvailabilityChange(values.isSoldOut);
        }
        updateOfferMutation.mutate([productId, productData], {
            onSuccess: (data) => {
                setOfferData(data); // TODO: decodeHtmlEntities
                if (initialValues.name !== values.name) {
                    handleNameChange();
                }
                Toasts.notify('Product has been updated successfully!', { type: 'info' });
            },
        });
    };
    if (isError) {
        return React.createElement(UnavailableResource, { resource: "product" });
    }
    if (!product) {
        return null;
    }
    initialValues = getInitialValues({
        name,
        description,
        variants,
        visualHints,
        digitalItems,
        variantTypes,
        images,
        withMembersOnly,
        membersOnlyType,
        membersOnlyTierIds: membersOnlyTierIds || {},
        isAvailable: product.state.available,
        fulfillmentService,
        manufacturingService,
        shipment,
        bespokeProductId,
        customsInformation: product.customsInformation,
        shippingPackageSize: product.config.shippingConfig.shippingPackageSize,
    });
    // offer type should be picked from a catalog product type
    // at first place. If there's no catalog product connected,
    // it should pick it from the selector
    const getOfferType = () => {
        return productBySlugQuery.data?.fulfillmentType || offerType;
    };
    // listing type should be equal to a fulfillment type
    // if an offer is connected with any catalog product
    // one manufacturer can support more than one fulfillment type
    const getListingType = () => {
        return productBySlugQuery.data?.fulfillmentType || listingType;
    };
    const applyToAllVariants = ({ priceValue, compareAtPriceValue, size, }) => {
        let newVariants = formRef.current?.values.variants || [];
        if (priceValue) {
            newVariants = setVariantsPriceValue(newVariants, priceValue, size);
        }
        if (compareAtPriceValue !== undefined) {
            newVariants = setVariantsCompareAtValue(newVariants, compareAtPriceValue, size);
            if (compareAtPriceValue === null) {
                formRef.current?.setFieldValue('compareAtPriceValue', null);
            }
        }
        formRef.current?.setFieldValue('variants', newVariants);
    };
    const togglePricingType = () => {
        setPricingType((prevValue) => {
            if (prevValue === 'EXPLICIT') {
                const headVariant = formRef.current?.values.variants[0];
                if (headVariant) {
                    const priceValue = formatCurrency(headVariant.price.value);
                    const compareAtPriceValue = headVariant.compareAtPrice?.value
                        ? formatCurrency(headVariant.compareAtPrice.value)
                        : null;
                    formRef.current?.setFieldValue('sellingPriceValue', priceValue);
                    formRef.current?.setFieldValue('compareAtPriceValue', compareAtPriceValue);
                    applyToAllVariants({ priceValue, compareAtPriceValue });
                }
            }
            return prevValue === 'EXPLICIT' ? 'IMPLICIT' : 'EXPLICIT';
        });
    };
    return (React.createElement(Formik, { innerRef: formRef, initialValues: initialValues, validate: (values) => validate(values)({
            productSchema: productValidatorSchema({
                manufacturingService,
                variants,
                minimumProfit,
                shouldValidateSellingPriceValue: pricingType === 'IMPLICIT',
            }),
            shippingSchema: shippingValidatorSchema(values),
        }), enableReinitialize: true, onSubmit: handleSubmit }, ({ values, setFieldValue }) => (React.createElement(ProductView, { productId: productId, shippingPackageSize: product.config.shippingPackageSize, productCustomizationId: product?.customizationId, bespokeProductId: bespokeProductId, productIsDeleting: productIsDeleting, productUpdateError: updateOfferMutation.isError, productUpdateIsSubmitting: productUpdateIsSubmitting, productIsFetching: productIsFetching || updateOfferSlugMutation.isLoading, shippingFlatRatesAreFetching: shopShippingFlatRatesQuery.isLoading, productStatusIsUpdating: statusMutation.isLoading, productStockIsSubmitting: createStockMutation.isLoading ||
            updateStockMutation.isLoading ||
            deleteStockMutation.isLoading, customsInformation: product.customsInformation, offerImages: React.createElement(OfferImagesContainer, { offer: product || undefined, offerVariantTypes: values.variantTypes, value: values.offerImages, disabled: !hasPermission('products.edit.details'), onChange: (value) => {
                setFieldValue('offerImages', value);
            }, onColorFilterSort: (oldIndex, newIndex) => {
                const colorVariantTypeIndex = values.variantTypes.findIndex(({ type }) => type === 'COLOR');
                const colorVariantType = values.variantTypes[colorVariantTypeIndex];
                if (!colorVariantType)
                    return;
                const path = `[${colorVariantTypeIndex}].options`;
                const newOptions = arrayMove(colorVariantType.options, oldIndex, newIndex);
                const newVariantTypes = set(cloneDeep(variantTypes), path, newOptions);
                setFieldValue('variantTypes', newVariantTypes);
            }, onUploadStart: () => setIsUploadingImages(true), onUploadEnd: () => setIsUploadingImages(false) }), images: images, rightContent: React.createElement(SideSummary, null,
            React.createElement(SideSummary.Item, { title: "Listing Type" },
                React.createElement(OfferListingType, { product: productBySlugQuery.data, listingType: getListingType() })),
            hasPermission('contributions.profit') && (React.createElement(SideSummary.Item, { title: "Analytics" }, totalUnitsSold ? `${totalUnitsSold} sold` : 'No sales yet')),
            productBySlugQuery.data && (React.createElement(React.Fragment, null,
                React.createElement(SideSummary.Item, { title: "Product details" },
                    React.createElement(CatalogProductDetails, { product: productBySlugQuery.data })),
                React.createElement(SideSummary.Item, { title: "Production method" },
                    React.createElement(CatalogProductionMethod, { offer: product, product: productBySlugQuery.data }))))), productBanners: productBanners, withMembersOnly: withMembersOnly, membersOnlyType: membersOnlyType, membersOnlyTierIds: membersOnlyTierIds || {}, membershipTiers: membershipAccountQuery.data?.tiers, status: productStatus, name: unescape(name), description: unescape(description), variants: variants, variantsAnalytics: offerOrdersByVariantsAnalyticsQuery.isSuccess &&
            offerOrdersByVariantsAnalyticsQuery.data.notArchivedVariantsSales
            ? offerOrdersByVariantsAnalyticsQuery.data.notArchivedVariantsSales
            : undefined, variantsAnalyticsWhiplash: variantsAnalyticsQuery.isSuccess ? variantsAnalyticsQuery.data.items : undefined, variantTypes: variantTypes, visualHints: visualHints, digitalItems: digitalItems, maxDigitalFilesSize: maxDigitalFilesSize, slug: slug, shopBaseUri: currentShop?.baseUri, isAvailable: product.state.available, offerType: getOfferType(), shipment: shipment, isUploadingImages: isUploadingImages, fulfillmentService: fulfillmentService, manufacturingService: manufacturingService, isSettingCostsRequired: product.isSettingCostsRequired, onChangeUrl: handleChangeUrl, onOrderSamples: onOrderSamples, onArchive: handleArchive, onStatusChange: handleStatusChange, onStockCreate: handleStockCreate, onStockUpdate: handleStockUpdate, onStockDelete: handleStockDelete, pricingType: pricingType, showPricingPerVariant: showPricingPerVariant, togglePricingType: togglePricingType, applyToAllVariants: applyToAllVariants }))));
};
