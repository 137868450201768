import React from 'react';
import { useParams } from 'react-router-dom';
import { reverse, routing } from '../../utils/routing';
import { useOfferQuery } from '../Dashboard/Product';
import { useCurrentShopQuery } from '../Dashboard/hooks/useCurrentShopQuery';
import { OfferPreviewView } from './OfferPreviewView';
export const OfferPreviewContainer = () => {
    const { productId = '' } = useParams();
    const { currentShopQuery } = useCurrentShopQuery(undefined, { staleTime: Infinity });
    const { offerQuery } = useOfferQuery([productId]);
    if (!currentShopQuery.isSuccess || !offerQuery.isSuccess)
        return null;
    const getUrl = (path) => {
        const src = new URL(currentShopQuery.data?.internalBaseUri);
        src.pathname = path; // TODO: Create shop renderer utils.
        return src.toString();
    };
    return (React.createElement(OfferPreviewView, { title: offerQuery.data.name, headline: "Congratulations! Your product is", status: offerQuery.data.state.status, productUrl: getUrl(`/products/${offerQuery.data.slug}`), editTo: reverse(routing.products.all.product.self, { productId }), dashboardTo: routing.home, iframeSrc: getUrl(`/theme_editor/products/${offerQuery.data.slug}`) }));
};
