import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Events = styled.div `
  display: flex;
  flex-direction: column;
  gap: 64px;

  ${mediaQueries.tablet} {
    flex-direction: row;

    & > * {
      width: 50%;
    }
  }
`;
