import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const ImageContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${Colors.GS300};
`;
export const Image = styled.img `
  max-width: 100%;
  height: auto;
  max-height: 400px;
`;
export const UploadContainer = styled.div `
  height: 200px;
  width: 100%;
`;
export const Or = styled.div `
  text-align: center;
  width: 100%;
`;
export const AdvancedButton = styled.button `
  display: grid;
  grid-auto-flow: column;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  color: ${Colors.GS600};

  &:hover {
    color: ${Colors.B500};
  }
`;
