import { createGlobalStyle } from 'styled-components';
export const Global = createGlobalStyle `
  .ReactModal__Html--open {
    overflow: hidden;
  }

  .ReactModalPortal {
    z-index: 1000;
  }
`;
