import styled from 'styled-components';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
const getTextColor = () => getAutomatedStyleProp('colorOnBackground', Colors.GS1000);
export const Container = styled.div `
  position: relative;

  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;
export const Textarea = styled.textarea `
  width: 100%;
  padding: 15px;
  background: transparent;
  border: 0;
  outline: 0;

  font-size: 16px;
  color: ${getTextColor};
  line-height: 24px;
  vertical-align: top;
  resize: none;

  ::placeholder {
    color: ${getAutomatedStyleProp('paleTextColor', Colors.GS600)};
  }

  :disabled {
    cursor: not-allowed;
  }

  // https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
  &:-webkit-autofill,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover {
    -webkit-text-fill-color: ${getTextColor};
    -webkit-background-clip: text;
    caret-color: ${getTextColor};
  }
`;
export const Label = styled.label `
  clip: rect(1px, 1px, 1px, 1px);

  position: absolute;
`;
