import { useEffect } from 'react';
export const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
        if (!ref.current || ref.current.contains(e.target)) {
            return;
        }
        callback(e);
    };
    const handleBlur = () => {
        if (document.activeElement?.tagName === 'IFRAME') {
            callback();
        }
    };
    const addListener = () => {
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('touchstart', handleClick);
        window.addEventListener('blur', handleBlur);
    };
    const removeListener = () => {
        document.removeEventListener('mousedown', handleClick);
        document.removeEventListener('touchstart', handleClick);
        window.removeEventListener('blur', handleBlur);
    };
    useEffect(() => {
        addListener();
        return removeListener;
    });
};
