import React from 'react';
import { TableItemDate } from '@components/utils/TableItemDate';
import { PriceString, ProfitString, Tag } from '@fourthwall/components';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { pluralize } from '@fourthwall/utils/lib/string';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import * as S from './styled';
export const GiveawayListItem = ({ giveaway }) => {
    const { hasPermission } = useDashboardPermissions();
    return (React.createElement(S.Container, null,
        React.createElement(S.Photo, null,
            React.createElement(PhotoThumbnail, { url: giveaway.productImageUrl, size: "extraLarge" })),
        React.createElement(S.Details, null,
            React.createElement(S.Supporter, null, giveaway.supporter.username || giveaway.supporter.email),
            React.createElement(S.Date, null,
                React.createElement(TableItemDate, { date: giveaway.createdAt })),
            React.createElement(Tag, { label: `Gift (Redeemed ${giveaway.claimedGifts}/${giveaway.numberOfGifts})`, appearance: "success" }),
            React.createElement(S.RedeemsLeft, null, giveaway.giftsLeftToRedeem
                ? `${giveaway.giftsLeftToRedeem} ${pluralize(giveaway.giftsLeftToRedeem, 'redeem')} left`
                : undefined)),
        React.createElement(S.Calculations, null,
            React.createElement(PriceString, { value: giveaway.total.value }),
            hasPermission('contributions.profit') && (React.createElement(S.Profit, null,
                React.createElement(ProfitString, { value: giveaway.profit.value }))))));
};
