import { BackButton, HeadContent, SaveShortcutListener } from '@fourthwall/components';
import { Formik } from 'formik';
import React from 'react';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { routing } from '../../../../../utils/routing';
import { ConfirmBarContainer } from '../../../ConfirmBar/ConfirmBarContainer';
import { validationSchema } from './consts';
import { useSingleSeriesCreate } from './hooks/useSingleSeriesCreate';
import { ArtworkFields } from './modules/ArtworkFields';
import { DetailsFields } from './modules/DetailsFields';
export const SingleSeriesCreate = () => {
    const { createSingleSeries, isLoading } = useSingleSeriesCreate();
    const initialValues = {
        title: '',
        description: '',
        coverImage: '',
    };
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: createSingleSeries }, ({ dirty, submitForm, resetForm }) => (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Create new video series" }),
        React.createElement(PageLayout, { leftContent: React.createElement(React.Fragment, null,
                React.createElement(DetailsFields, null),
                React.createElement(ArtworkFields, null)) },
            React.createElement(Dashboard.ItemViewHeader, { name: "Add new video series", backButton: React.createElement(BackButton, { label: "Video series", to: routing.memberships.series.self }) })),
        React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
        React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isLoading, onConfirm: submitForm, onCancel: resetForm })))));
};
