import React, { createContext, useContext, useMemo } from 'react';
import { useMedia } from 'use-media';
import { breakpoints, mediaQueriesExpressions } from './consts';
const initialValue = {
    mobileM: false,
    tablet: false,
    laptop: false,
    desktop: false,
    breakpoints,
    currentBreakpoint: 'mobileM',
};
const MediaQueryContext = createContext(initialValue);
export const MediaQueryProvider = ({ children }) => {
    const mobileM = useMedia(mediaQueriesExpressions.mobileM);
    const tablet = useMedia(mediaQueriesExpressions.tablet);
    const laptop = useMedia(mediaQueriesExpressions.laptop);
    const desktop = useMedia(mediaQueriesExpressions.desktop);
    const currentBreakpoint = useMemo(() => {
        if (desktop) {
            return 'desktop';
        }
        if (laptop) {
            return 'laptop';
        }
        if (tablet) {
            return 'tablet';
        }
        if (mobileM) {
            return 'mobileM';
        }
        return 'mobileM';
    }, [desktop, laptop, mobileM, tablet]);
    const value = useMemo(() => ({
        mobileM,
        tablet,
        laptop,
        desktop,
        breakpoints,
        currentBreakpoint,
    }), [mobileM, tablet, laptop, desktop, currentBreakpoint]);
    return React.createElement(MediaQueryContext.Provider, { value: value }, children);
};
export const useMediaQueryContext = () => {
    return useContext(MediaQueryContext);
};
