import React from 'react';
import { Skeleton, useMediaQueryContext } from '@fourthwall/components';
import * as S from './styled';
const size = 'medium';
export const SuggestionSkeleton = () => {
    const media = useMediaQueryContext();
    const renderButton = () => {
        const width = media.tablet ? 200 : '100%';
        return React.createElement(Skeleton.Shape, { height: 48, width: width });
    };
    const renderImage = () => {
        const width = media.tablet ? 200 : 100;
        const height = media.tablet ? 140 : 70;
        return (React.createElement(S.ImageContainer, null,
            React.createElement(Skeleton.Shape, { width: width, height: height })));
    };
    return (React.createElement(S.Container, { size: size },
        React.createElement(S.Main, null,
            React.createElement("div", { style: { width: '100%' } },
                React.createElement(Skeleton, { gap: 4 },
                    React.createElement(Skeleton.Shape, { height: 30, width: "60%" }),
                    React.createElement(Skeleton.Shape, { height: 24, width: "80%" }))),
            !media.tablet && renderImage(),
            media.tablet && React.createElement(React.Fragment, null, renderButton())),
        media.tablet && renderImage(),
        !media.tablet && React.createElement(React.Fragment, null, renderButton())));
};
