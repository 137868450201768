import { useNotificationShopPreferencesMutation } from './queries/useNotificationShopPreferencesMutation';
import { useNotificationShopPreferencesQuery } from './queries/useNotificationShopPreferencesQuery';
import { getPayload } from './utils/getPayload';
import { getInitialValues } from './utils/getInitialValues';
export const useAbandonedCheckoutRemindersSettings = () => {
    const { notificationShopPreferencesQuery, invalidateNotificationShopPreferencesQuery } = useNotificationShopPreferencesQuery();
    const { notificationShopPreferencesMutation } = useNotificationShopPreferencesMutation();
    const update = async (values) => {
        await notificationShopPreferencesMutation.mutateAsync([getPayload(values)], {
            onSuccess: () => {
                invalidateNotificationShopPreferencesQuery();
            },
        });
    };
    return {
        abandonedCheckoutRemindersSettings: {
            initialValues: getInitialValues(notificationShopPreferencesQuery.data),
            abandonedCheckout: {
                options: [
                    { checkedValue: 'recommended', label: 'Recommended (two email reminders)' },
                    { checkedValue: 'light', label: 'Light (one email reminder)' },
                    { checkedValue: 'off', label: 'Off' },
                ],
            },
            update,
        },
    };
};
