import styled from 'styled-components';
import { Colors } from '../../../colors';
export const RowGroup = styled.div `
  position: relative;
`;
export const Placeholder = styled.div `
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${Colors.GS200};
`;
export const EmptyComponent = styled.div `
  margin-top: 80px;
  margin-bottom: 80px;
`;
export const EmptyMessage = styled.div `
  margin-top: 32px;
  margin-bottom: 32px;

  text-align: center;
`;
