import styled from 'styled-components';
import { Colors } from '../../../colors';
export const Container = styled.div `
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${Colors.GS1000};
`;
