import { Button, ButtonClean, List, Subsection } from '@fourthwall/components';
import { WebhookListItem } from '@modules/Dashboard/Settings/modules/ForDevelopers/modules/Webhooks/components/WebhookListItem';
import React from 'react';
import { routing } from '../../../../../../../utils/routing';
import * as S from './styled';
export const WebhooksView = ({ webhooksListProps, clientSecret, onWebhookCreate, }) => {
    return (React.createElement(Subsection, { title: "Webhooks" },
        React.createElement(S.Description, null,
            "You can subscribe to events for your products and orders by creating webhooks that will push JSON notifications to a given URL.",
            ' ',
            React.createElement(ButtonClean, { label: "Learn more", href: routing.external.docs.self, target: "_blank" })),
        React.createElement(S.ButtonContainer, null,
            React.createElement(Button, { label: "Create webhook", size: "small", disabled: !onWebhookCreate, onClick: onWebhookCreate })),
        React.createElement(List, { renderItem: (webhookItem) => React.createElement(WebhookListItem, { ...webhookItem }), ...webhooksListProps }),
        clientSecret && (React.createElement(S.ClientSecretContainer, null,
            "Webhooks will be signed with secret token so you can verify their integrity:",
            React.createElement("br", null),
            React.createElement(S.ClientSecretContainerValue, null, clientSecret)))));
};
