import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Section = styled.div `
  & + & {
    margin-top: 48px;
  }
`;
export const SectionHeader = styled.div `
  padding-bottom: 12px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${Colors.GS400};
`;
export const SummaryHeader = styled.div `
  margin-bottom: 8px;
  font-weight: 600;
`;
export const SummarySubsection = styled.div `
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0 0;
  color: ${Colors.GS600};
`;
export const SummaryDivider = styled.div `
  height: 1px;
  margin: 12px 0;
  background: ${Colors.GS400};
`;
export const SummaryCheckbox = styled.div `
  margin-top: 16px;
`;
export const SummaryButton = styled.div `
  margin-top: 24px;
`;
export const ProductItemFooter = styled.div `
  margin: 8px 0 16px;
`;
export const ProductItemFooterSummary = styled.div `
  margin-bottom: 12px;
`;
