import { useModalContext } from '@fourthwall/components';
import { getPrice } from '@fourthwall/utils';
import React, { useState } from 'react';
import { PaymentMethodRequiredModal } from '../../../../../../PaymentMethods';
import { useGetRefundEstimatedCostsQuery } from './hooks/useGetRefundEstimatedCostsQuery';
import { OrderRefundConfirmModalView } from './OrderRefundConfirmModalView';
export const OrderRefundConfirmModalContainer = ({ orderId, amount, initialReason, error, close, onConfirm, }) => {
    const { open } = useModalContext();
    const [reason, setReason] = useState(initialReason || '');
    const { getRefundEstimatedCostsQuery } = useGetRefundEstimatedCostsQuery([
        orderId,
        {
            refundAmount: parseFloat(amount),
            refundCurrency: 'USD',
        },
    ]);
    const openPaymentMethodRequiredModal = () => {
        const chargeDetails = getRefundEstimatedCostsQuery.data?.chargeDetails;
        const fromBalance = getRefundEstimatedCostsQuery.data?.fromBalance;
        if (!fromBalance || chargeDetails?.type !== 'REQUIRED') {
            return;
        }
        const amountPrice = getPrice(parseFloat(amount));
        const balancePrice = getPrice(fromBalance.value);
        const remainingPrice = getPrice(chargeDetails.amount.value);
        const balanceSplitCopy = fromBalance.value > 0
            ? `${balancePrice} will be deducted from your account balance and the remaining ${remainingPrice} will be charged to your payment method. `
            : '';
        const subtitle = (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                balanceSplitCopy,
                "Refunds are sent directly to the supporter's original payment method."),
            React.createElement("p", null,
                React.createElement("b", null,
                    "Payment method to cover ",
                    remainingPrice))));
        open(PaymentMethodRequiredModal, {
            title: `Are you sure you want to refund ${amountPrice}?`,
            subtitle,
            selectPaymentMethodSubtitle: subtitle,
            submitLabel: `Refund ${amountPrice}`,
            onSuccess: () => {
                onConfirm({
                    fromBalance,
                    charge: chargeDetails.amount,
                }, reason.length ? reason : undefined);
            },
        });
    };
    const handleConfirm = () => {
        if (!getRefundEstimatedCostsQuery.data?.fromBalance) {
            return;
        }
        close();
        if (getRefundEstimatedCostsQuery.data?.chargeDetails.type === 'REQUIRED') {
            openPaymentMethodRequiredModal();
            return;
        }
        onConfirm({
            fromBalance: getRefundEstimatedCostsQuery.data?.fromBalance,
            charge: { value: 0, currency: 'USD' },
        }, reason.length ? reason : undefined);
    };
    return (React.createElement(OrderRefundConfirmModalView, { amount: amount, error: error, reasonInputProps: {
            value: reason,
            onValueChange: setReason,
        }, isLoading: getRefundEstimatedCostsQuery.isFetching, onConfirm: handleConfirm, onClose: close }));
};
