import { useEffect, useState } from 'react';
import { usePrevious } from '@fourthwall/components';
export const useOfferImagesFilters = (variantTypes) => {
    const [state, setState] = useState({});
    const previousVariantTypesLength = usePrevious(variantTypes ? variantTypes.length : 0);
    useEffect(() => {
        if (!variantTypes)
            return;
        if (variantTypes.length === previousVariantTypesLength)
            return;
        const newState = variantTypes.reduce((previous, current) => ({ ...previous, [current.type]: current.options[0].id }), {});
        setState(newState);
    }, [variantTypes, previousVariantTypesLength]);
    const getFilterValue = (key) => {
        return state[key];
    };
    const setFilterValue = (key) => (value) => {
        return setState((current) => ({ ...current, [key]: value }));
    };
    const offerImagesFilters = variantTypes
        ? variantTypes
            .filter((variantType) => variantType.variesBy.imagery)
            .map((variantType) => ({
            type: variantType.type === 'COLOR' ? 'COLOR' : 'SELECT',
            tag: variantType.type,
            label: variantType.title,
            options: variantType.options.map((option) => ({
                label: option.name,
                value: option.id,
                color: option.colorSwatch || '',
            })),
            value: getFilterValue(variantType.type),
            onChange: setFilterValue(variantType.type),
        }))
        : [];
    return { offerImagesFilters };
};
