import { ConfirmModal, useModalContext } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const ProductSlugUpdateModal = ({ baseUrl, newSlug, prevSlug, onConfirm, }) => {
    const { close } = useModalContext();
    return (React.createElement(ConfirmModal, { size: "medium", maxWidth: 700, title: "Do you want to update product URL?", text: "You\u2019ve changed product name. Do you also want to update the URL to reflect this change? The new URL will be:", additionalContent: React.createElement(React.Fragment, null,
            React.createElement(S.LinkContainer, null,
                baseUrl,
                React.createElement(S.SlugContainer, null, newSlug)),
            React.createElement(S.InfoContainer, null,
                "Current URL: ",
                baseUrl,
                React.createElement(S.SlugContainer, null, prevSlug))), confirmLabel: "Update URL", cancelLabel: "No, thanks", close: close, onConfirm: onConfirm }));
};
