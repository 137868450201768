import styled from 'styled-components';
export const Heading = styled.h3 `
  font-size: 20px;
  font-weight: 600;
`;
export const List = styled.ul `
  padding-left: 14px;
  list-style: disc;

  & > * + * {
    margin-top: 8px;
  }
`;
export const Content = styled.article `
  font-size: 14px;
  line-height: 21px;

  & > * + * {
    margin-top: 8px;
  }
`;
export const LoaderWrapper = styled.div `
  display: flex;
  justify-content: center;
  margin: 104px auto;
`;
export const WithTooltipSpan = styled.span `
  display: inline-flex;
  align-items: baseline;
  gap: 6px;
`;
