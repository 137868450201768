import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import { useGetEntriAuthTokenMutation } from './useGetEntriAuthTokenMutation';
const decodeAuthToken = (encodedToken) => jwtDecode(encodedToken);
export const useGetAuthToken = () => {
    const [authToken, setAuthToken] = useState(null);
    const { entriAuthTokenMutation } = useGetEntriAuthTokenMutation();
    const getAuthToken = async () => {
        const isTokenValid = !!authToken && authToken.exp > Math.floor(Date.now() / 1000);
        if (isTokenValid) {
            return authToken;
        }
        try {
            const { authToken: encodedToken } = await entriAuthTokenMutation.mutateAsync([]);
            const decodedToken = decodeAuthToken(encodedToken);
            const newAuthToken = {
                token: encodedToken,
                ...decodedToken,
            };
            setAuthToken(newAuthToken);
            return newAuthToken;
        }
        catch (e) {
            throw new Error('Unable to get auth token');
        }
    };
    return { getAuthToken };
};
