import { Fieldset, Input, Subsection } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React from 'react';
import { PostTypeButtons } from '../../../../../Post/components/PostTypeButtons';
import { TYPE_BUTTONS } from './consts';
import { TinyMCE } from '../../../../../../../../../components/adapters/inputs/TinyMCE';
export const PostFields = ({ postTypeFieldName, titleFieldName, descriptionFieldName, postType, subsectionTitle, disabled, children, }) => {
    const { setFieldValue } = useFormikContext();
    return (React.createElement(Subsection, { title: subsectionTitle },
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(PostTypeButtons, { buttons: TYPE_BUTTONS, activeButton: postType, disabled: disabled, onClick: (type) => {
                            setFieldValue(postTypeFieldName, type);
                        } }))),
            children,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: titleFieldName, component: Input, label: "Post title", required: true, fullWidth: true, disabled: disabled }))),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: descriptionFieldName, component: TinyMCE, placeholder: "Post description", disabled: disabled }))))));
};
