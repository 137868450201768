import { Checkbox, CountryFlag, Icon, Tooltip, withFormikSupport } from '@fourthwall/components';
import { QuestionMarkIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
const CurrenciesSelectComponent = ({ currencies, value, disabled = false, onChange, onBlur, }) => {
    const handleCheckboxValueChange = (currency) => {
        if (value?.includes(currency)) {
            onChange?.(value.filter((selectedCurrency) => selectedCurrency !== currency));
        }
        else {
            onChange?.([...(value || []), currency]);
        }
    };
    const renderCurrency = (currencyItem) => {
        const { currency, flag, tooltip } = currencyItem;
        return (React.createElement(S.CurrencyListItem, { key: currency },
            React.createElement(Checkbox, { value: !!value?.includes(currency), disabled: disabled || currencyItem.disabled, label: React.createElement(S.CurrencyFlagContainer, null,
                    React.createElement(CountryFlag, { countryCode: flag, withLabel: false }),
                    React.createElement(S.CurrencyLabel, null, currency),
                    tooltip && (React.createElement(S.TooltipContainer, null,
                        React.createElement(Tooltip, { reference: React.createElement(Icon, { component: QuestionMarkIcon, height: 16 }), placement: "top", strategy: "fixed" }, tooltip)))), onValueChange: () => handleCheckboxValueChange(currency), onBlur: onBlur })));
    };
    return React.createElement(S.CurrencyList, null, currencies.map(renderCurrency));
};
export const CurrenciesSelect = withFormikSupport(CurrenciesSelectComponent);
