import { GiftsApiClient } from '@fourthwall/services/lib/GiftsApiClient';
import { createQueryHook } from '@fourthwall/utils/lib/queries';
import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
export const useGiveawayPackagesQuery = createQueryHook(GiftsApiClient.getGiftPackages, {
    queryKey: ['giveawayPackages'],
    select: (data) => {
        data.packages = data.packages.map((packageItem) => {
            return {
                ...packageItem,
                items: packageItem.items.map((item) => ({
                    ...item,
                    name: decodeHtmlEntities(item.name),
                })),
            };
        });
        return data;
    },
});
