import { useQueryPage } from '@fourthwall/utils';
import React from 'react';
import { useBillingQuery } from './hooks/useBillingQuery';
import { useBillingReport } from './hooks/useBillingReport';
import { TransactionsView } from './TransactionsView';
export const Transactions = () => {
    const { page, setPage } = useQueryPage();
    const { billing, billingQuery } = useBillingQuery(page);
    const { billingReport } = useBillingReport();
    return (React.createElement(TransactionsView, { download: { isLoading: billingReport.isLoading, onClick: billingReport.download }, transactions: billing?.transactions, page: page, totalPages: billing?.page.totalPages, isLoading: billingQuery.isLoading, onPageChange: setPage }));
};
