import { useThemeLivePreviewContext } from '../modules/LivePreview/providers/ThemeLivePreviewProvider';
import { useActiveThemeContext } from '../providers/ActiveThemeProvider';
import { usePageLayoutContext } from '../providers/PageLayoutProvider';
import { useCurrentShopContext } from './useCurrentShopContext';
import { useThemeEditorContext } from './useThemeEditorContext';
import { useUpdateSectionVisibilityMutation } from './useUpdateSectionVisibilityMutation';
export const useSectionVisibility = () => {
    const { currentPageId, resetPreviewTimestamp } = useThemeEditorContext();
    const { currentShop } = useCurrentShopContext();
    const { pageLayoutQuery } = usePageLayoutContext();
    const { channel, setInspectorVisible } = useThemeLivePreviewContext();
    const { updateSectionVisibilityMutation } = useUpdateSectionVisibilityMutation();
    const { activeTheme } = useActiveThemeContext();
    const changeSectionVisibility = ({ sectionKey, visible, forceReload = true, }) => {
        const block = pageLayoutQuery.data?.sections.blocks.find((block) => block.sections.some((section) => section.section_key === sectionKey));
        setInspectorVisible(false);
        if (!block?.id || !currentPageId || !sectionKey) {
            throw new Error('changeSectionVisibility: Missing block ID or current page ID');
        }
        if (!forceReload) {
            channel.emit({
                action: 'ThemePreview:RemoveSection',
                payload: { id: sectionKey },
            });
        }
        updateSectionVisibilityMutation.mutate([
            {
                shop_id: currentShop.id,
                theme_id: activeTheme.id,
                page_id: currentPageId,
                section_id: sectionKey,
            },
            {
                visible,
            },
        ], {
            onSuccess: () => {
                pageLayoutQuery.refetch();
                if (forceReload)
                    resetPreviewTimestamp();
            },
        });
    };
    return {
        changeSectionVisibility,
    };
};
