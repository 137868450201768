import { Dashboard, PageLayout } from '@components/common';
import { SECTION_NAMES } from '@constants/sectionNames';
import { BaseContent, Button, HeadContent, Icon, Loading, ToolbarButton, useMediaQueryContext, } from '@fourthwall/components';
import { DownloadIcon, UploadIcon } from '@fourthwall/icons';
import { routing } from '@utils/routing';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import * as S from './styled';
export const OrdersFulfillmentView = ({ isDownloading, isUploading, onDownload, onUpload, }) => {
    const media = useMediaQueryContext();
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles[0] && onUpload) {
            const payload = new FormData();
            payload.append('csv', acceptedFiles[0]);
            onUpload(payload);
        }
    }, [onUpload]);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'text/csv': ['.csv'] },
        maxSize: 104857600,
        disabled: isUploading,
        multiple: false,
    });
    const renderToolbar = () => {
        if (!media.tablet) {
            return (React.createElement(ToolbarButton, { label: "Download CSV template", icon: DownloadIcon, isLoading: isDownloading, onClick: onDownload }));
        }
        return (React.createElement(Button, { label: "Download CSV template", size: "small", loading: isDownloading, onClick: onDownload }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: SECTION_NAMES.fulfillment }),
        React.createElement(PageLayout, { size: "large" },
            React.createElement(Dashboard.Section, { name: SECTION_NAMES.fulfillment, toolbarContent: renderToolbar(), title: React.createElement(BaseContent, { appearance: "secondary" },
                    React.createElement("button", { onClick: onDownload }, "Download our CSV template"),
                    ", add tracking numbers, then upload the completed CSV below.",
                    ' ',
                    React.createElement("a", { href: routing.external.helpCenter.selfFulfillment, target: "_blank", rel: "noreferrer" }, "Learn more")), titleSpacing: "large" }, onUpload && (React.createElement(Loading, { isLoading: isUploading },
                React.createElement(S.UploadContainer, { ...getRootProps() },
                    React.createElement("input", { ...getInputProps() }),
                    React.createElement(S.UploadContent, null,
                        React.createElement("div", { style: { marginBottom: 20 } },
                            React.createElement(Icon, { component: UploadIcon, height: 22 })),
                        media.tablet && (React.createElement(React.Fragment, null,
                            React.createElement("div", { style: { marginBottom: 26 } }, "Drag and drop file here"),
                            React.createElement(S.Divider, null, "or"))),
                        React.createElement(Button, { label: "Browse file", appearance: "primary" })))))))));
};
