import { ArrowShortDownIcon } from '@fourthwall/icons';
import isNil from 'lodash-es/isNil';
import React, { useState } from 'react';
import { Icon } from '../../common/Icon';
import { withFormikSupport } from '../../hocs/withFormikSupport';
import { Input } from '../Input';
import * as S from './Select.styled';
const SelectComponent = ({ options, name, disabled, invalid, label, value = '', error, displayError = true, submitCount, autoComplete, prepend, suggestion, size = 'large', transparent = false, onChange, onBlur, onValueChange, }) => {
    const [isFocused, setIsFocused] = useState(false);
    const renderOptions = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement("option", { hidden: true, disabled: true, value: "" }),
            options.map((item) => (React.createElement("option", { key: !isNil(item.key) ? item.key : item.value, value: item.value, disabled: item.disabled }, item.label)))));
    };
    const getInputValue = () => {
        const selectedOption = options.find((item) => item.value === value);
        return selectedOption ? selectedOption.label : '';
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
        setIsFocused(false);
    };
    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
        }
        if (onValueChange) {
            onValueChange(e.target.value);
        }
    };
    return (React.createElement(S.Container, null,
        React.createElement("div", { "aria-hidden": "true" },
            React.createElement(Input, { disabled: disabled, label: label, value: getInputValue(), error: error, displayError: displayError, submitCount: submitCount, prepend: prepend, suggestion: suggestion, size: size, transparent: transparent, invalid: invalid, focused: isFocused, tabIndex: -1, autoComplete: "off", actionIcon: (!transparent || isFocused) && (React.createElement(Icon, { component: ArrowShortDownIcon, height: size === 'small' ? 12 : 15 })) })),
        React.createElement(S.Select, { name: name, disabled: disabled, value: value, inputSize: size, autoComplete: autoComplete, onFocus: handleFocus, onBlur: handleBlur, onChange: handleChange, "aria-label": label, "data-testid": "Select.Select" }, renderOptions())));
};
export const Select = withFormikSupport(SelectComponent);
