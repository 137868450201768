const descriptionMap = {
    'custom-html': 'Embed your own code.',
    donations: 'Ask your fans for donations.',
    'featured-collection': 'Showcase your products.',
    hero: 'Combine image, text, and buttons.',
    'hero-video': 'Combine video, text, and buttons.',
    'image-with-text': 'Combine image with text.',
    image: 'Upload an image.',
    'instagram-feed': 'Embed your latest Instagram posts.',
    'links-list': 'Add multiple links with icon and text.',
    newsletter: 'Add email signup form.',
    'recent-tiktoks': 'Embed your latest TikTok posts.',
    'recent-videos': 'Embed your latest YouTube videos.',
    'rich-text': 'Add headlines and descriptions.',
    tiers: 'Promote your memberships tiers.',
};
export const getDescription = (sectionName) => descriptionMap[sectionName];
