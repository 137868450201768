import { Checkbox } from '@fourthwall/components';
import React from 'react';
import { TableCell } from '../../../../../../../components/common/TableCell';
import { SeriesCell } from '../../../Series/components/SeriesCell';
export const getTableHeaders = (selectedSeries, onSelect) => {
    return [
        {
            id: 'select',
            label: '',
            render: (singleSeries) => {
                const input = (React.createElement(Checkbox, { value: !!selectedSeries?.find((selectedSingleSeries) => selectedSingleSeries.id === singleSeries.id), onValueChange: onSelect(singleSeries) }));
                return React.createElement(TableCell, { mainContent: input });
            },
            width: 0.5,
        },
        {
            id: 'name',
            label: '',
            render: (singleSeries) => (React.createElement(SeriesCell, { title: singleSeries.title, quantity: singleSeries.episodesCount, thumbnailUrl: singleSeries.coverImage?.url })),
            width: 12,
        },
    ];
};
