import { ApiBase } from '../ApiBase';
class BillingApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getBilling = (params) => {
            return this.get('/billing', params);
        };
        this.getOrderCostsBreakdown = (orderId) => {
            return this.get(`/billing/order/${orderId}/costs-breakdown`);
        };
        this.getDonationCostsBreakdown = (donationId) => {
            return this.get(`/billing/donation/${donationId}/costs-breakdown`);
        };
        this.getBillingEntityBreakdown = (id) => {
            return this.get(`/billing/${id}/costs-breakdown`);
        };
        this.getBillingSummary = () => {
            return this.get('/billing/summary');
        };
    }
}
export const BillingApiClient = new BillingApiClientModel();
