import { Checkbox } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { FilterDropdown } from '../../../../../../../components/common/FilterDropdown';
import * as S from './styled';
export const PromotionCodesFilterView = ({ initialValues, filterActive, onSubmit, onReset, }) => {
    const formik = useFormik({
        initialValues,
        onSubmit,
    });
    const handleReset = () => {
        formik.resetForm();
        onReset && onReset();
    };
    return (React.createElement(FilterDropdown, { content: React.createElement(S.Form, null,
            React.createElement(S.FormRow, null,
                React.createElement(Checkbox, { label: "Show gift redemptions", ...formik.getFieldProps('showGiftRedemptions') }))), activityIndicator: filterActive, onSubmit: formik.handleSubmit, onReset: handleReset }));
};
