import React from 'react';
import { DnsRecordCodeCell } from './DnsRecordCodeCell';
import { DnsRecordValueCell } from './DnsRecordValueCell';
import { DnsRecordStatusCell } from './DnsRecordStatusCell';
export const getTableHeaders = (isVerificationInProgress) => [
    {
        id: 'type',
        label: 'TYPE',
        render: ({ recordType }) => recordType,
        width: 1,
    },
    {
        id: 'host',
        label: 'HOST',
        render: ({ alias, zoneFile }) => (React.createElement(DnsRecordCodeCell, { value: zoneFile?.hostLabel || '', recordAlias: alias })),
        width: 5,
    },
    {
        id: 'value',
        label: 'VALUE',
        render: (record) => React.createElement(DnsRecordValueCell, { record: record }),
        width: 5,
    },
    {
        id: 'status',
        label: '',
        render: (record) => (React.createElement(DnsRecordStatusCell, { record: record, isVerificationInProgress: isVerificationInProgress })),
        width: 1,
        alignment: 'right',
    },
];
export const getErrorTooltipText = (record) => {
    const error = record.lookupValidationError;
    if (error?.type === 'RECORD_NOT_FOUND') {
        return 'This record was not found';
    }
    if (error?.type === 'INCORRECT_TYPE_OR_VALUE') {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null, "This record contains the incorrect value:"),
            React.createElement("ul", null, error.matchedRecords.map(({ type, value }) => (React.createElement("li", null,
                type,
                " ",
                value))))));
    }
    if (error?.type === 'DUPLICATED_BASE_DOMAIN') {
        return `The base domain was duplicated as ${error.domainWithDuplicatedBase} instead of ${record.host}`;
    }
    if (error?.type === 'SURPLUS_MX_ENTRIES') {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null, "Unnecessary DNS records detected that should be removed:"),
            React.createElement("ul", null, error.additionalMXEntries.map((entry) => (React.createElement("li", null, entry))))));
    }
    if (error?.type === 'RECORD_NOT_UNIQUE') {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null, "There is an extra record that needs to be removed:"),
            React.createElement("ul", null, error.duplicatedRecords.map(({ value }) => (React.createElement("li", null, value))))));
    }
    if (error?.type === 'A_TYPE_AND_AAAA_TYPE_RECORD_CONFLICT') {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null, "There is a conflicting AAAA record that needs to be removed: "),
            React.createElement("ul", null, error.conflictingRecords.map(({ value }) => (React.createElement("li", null, value))))));
    }
    return 'Unknown error. Please contact support';
};
