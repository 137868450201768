import { BackButton, HeadContent, Loading } from '@fourthwall/components';
import React from 'react';
import { Dashboard } from '../../../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../../../components/common/PageLayout';
import { routing } from '../../../../../../../utils/routing';
import { useGetMessage } from './hooks/useGetMessage';
import { MessageBody } from './MessageBody/MessageBody';
export const CreatorMessageContainer = () => {
    const { messageData, isLoading } = useGetMessage();
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Message" }),
        React.createElement(Loading, { isLoading: isLoading },
            React.createElement(PageLayout, { leftContent: React.createElement(MessageBody, { preview: messageData?.preview }) },
                React.createElement(Dashboard.ItemViewHeader, { name: messageData?.content.subject, backButton: React.createElement(BackButton, { label: "Messages", to: routing.contributions.creatorMessages.self }) })))));
};
