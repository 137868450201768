export const analyticsQueryParams = {
    devices: 'devices',
    range: 'range',
    intervals: 'intervals',
    tiersIds: 'tiersIds',
    from: 'from',
    to: 'to',
    precision: 'precision',
    chartEntity: 'chartEntity',
};
