import { useNavigate } from 'react-router-dom';
import { routing } from '../../../../../../utils/routing';
import { getCreateSingleSeriesPayload } from '../utils';
import { useCreateSingleSeriesMutation } from './useCreateSingleSeriesMutation';
export const useSingleSeriesCreate = () => {
    const navigate = useNavigate();
    const { createSingleSeriesMutation } = useCreateSingleSeriesMutation();
    const createSingleSeries = (values) => {
        createSingleSeriesMutation.mutate(getCreateSingleSeriesPayload(values), {
            onSuccess: () => navigate(routing.memberships.series.self),
        });
    };
    return {
        createSingleSeries,
        isLoading: createSingleSeriesMutation.isLoading,
    };
};
