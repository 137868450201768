import { ApiBase } from '../ApiBase';
class ShopUsersApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getUsers = () => {
            return this.get('v3/shops/users');
        };
        this.getShopGroupStats = () => {
            return this.get('v3/shops/groups/stats');
        };
        this.inviteUser = (payload) => {
            return this.post('v3/shops/users/invite', payload);
        };
        this.updateUser = (payload) => {
            return this.put('v3/shops/users', payload);
        };
        this.resendInvitation = (payload) => {
            return this.post('v3/shops/users/resend-invitation', payload);
        };
        this.cancelInvitations = (payload) => {
            return this.put('v3/shops/users/invitations/cancel-by-email', payload);
        };
        this.removeUser = (payload) => {
            return this.delete('v3/shops/users', payload);
        };
        this.sendVerifyEmail = () => {
            return this.put('v3/shops/users/send-verify-email');
        };
    }
}
export const ShopUsersApiClient = new ShopUsersApiClientModel();
