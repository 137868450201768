export var Usage;
(function (Usage) {
    Usage["none"] = "none";
    Usage["same"] = "same";
    Usage["different"] = "different";
})(Usage || (Usage = {}));
export var FormFields;
(function (FormFields) {
    FormFields["usage"] = "usage";
    FormFields["welcomePost"] = "welcomePost";
    FormFields["welcomePosts"] = "welcomePosts";
})(FormFields || (FormFields = {}));
