import dayjs from 'dayjs';
import { getTierIds } from '../../../../utils/getTierIds';
export const getCommonFieldsPayload = (values) => {
    const tiers = getTierIds(values.tierIds);
    const getLiveAt = () => {
        if (values.mode === 'create') {
            return values.liveAt ? values.liveAt : dayjs(Date.now()).toISOString();
        }
        if (values.mode === 'update' && values.liveAt) {
            return values.liveAt ? values.liveAt : undefined;
        }
    };
    return {
        attributes: {
            core: {
                title: values.title,
                description: values.description,
                liveAt: getLiveAt(),
                tags: values.tags,
            },
        },
        access: {
            type: values.access,
            tiers,
        },
        notifications: {
            enabled: !!values.notifyMembers,
        },
    };
};
