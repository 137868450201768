import { Button, ModalBox, Toasts } from '@fourthwall/components';
import React from 'react';
import { useCurrentShopContext } from '../../../../hooks/useCurrentShopContext';
import { useDeleteThemeMutation } from './hooks/useDeleteThemeMutation';
export const ThemeDeleteModal = ({ id, name, onSuccess, close }) => {
    const { currentShop } = useCurrentShopContext();
    const { deleteThemeMutation } = useDeleteThemeMutation();
    const handleSubmit = () => {
        deleteThemeMutation.mutate([currentShop.id, id], {
            onSuccess: () => {
                Toasts.notify(`${name} has been deleted`, { type: 'info' });
                onSuccess();
                close();
            },
        });
    };
    const handleClose = () => {
        if (!deleteThemeMutation.isLoading) {
            close();
        }
    };
    return (React.createElement(ModalBox, { size: "small", title: `Delete ${name}`, onClose: handleClose, buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", disabled: deleteThemeMutation.isLoading, onClick: handleClose }),
            React.createElement(Button, { label: "Delete theme", appearance: "destructive", loading: deleteThemeMutation.isLoading, onClick: handleSubmit }),
        ] }, "This will remove all of your customization on this theme. Are you sure you want to continue? This process takes up to minutes and cannot be reversed."));
};
