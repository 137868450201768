const replaceKeyWords = (initString, inserterOptions) => {
    let resultString = initString;
    inserterOptions.forEach((option) => {
        const regex = new RegExp(option.value, 'gm');
        resultString = resultString.replace(regex, `&lt;${option.type}&gt;`);
    });
    return resultString;
};
export const extractContent = (initString, inserterOptions) => {
    var span = document.createElement('span');
    span.innerHTML = initString;
    const string = span.textContent || span.innerText;
    const strWithoutWhiteSpaces = string
        .replace(/[\n\r]+/g, ' ')
        .replace(/\s{2,10}/g, ' ')
        .trim();
    return replaceKeyWords(strWithoutWhiteSpaces, inserterOptions);
};
