import { Icon } from '@fourthwall/components';
import { PauseFullIcon, PlayFullIcon, VolumeOnFullIcon } from '@fourthwall/icons';
import { millisecondsToTime } from '@modules/Dashboard/ThankYous/modules/SendThankYouModal/utils';
import RcSlider from 'rc-slider';
import React, { useRef, useState } from 'react';
import * as S from './styled';
export const AudioPlayer = ({ src }) => {
    const audioRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const withHours = duration >= 3600;
    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(audioRef.current?.duration);
            setVolume(audioRef.current.volume);
        }
    };
    const handleTimeUpdate = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };
    const handlePlay = () => {
        audioRef.current?.play();
        setIsPlaying(true);
    };
    const handlePause = () => {
        audioRef.current?.pause();
        setIsPlaying(false);
    };
    const handleCurrentTimeChange = (value) => {
        if (Array.isArray(value) || !audioRef.current)
            return;
        if (!audioRef.current.paused) {
            handlePause();
        }
        audioRef.current.currentTime = value;
        setCurrentTime(value);
    };
    const handleVolumeChange = (value) => {
        if (Array.isArray(value) || !audioRef.current)
            return;
        audioRef.current.volume = value;
        setVolume(value);
    };
    const handleVolumeToggle = () => {
        if (!audioRef.current)
            return;
        const newVolume = audioRef.current?.volume < 1 ? 1 : 0;
        audioRef.current.volume = newVolume;
        setVolume(newVolume);
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.AudioPlayerTrack, null,
            React.createElement(RcSlider, { min: 0, max: duration, step: 0.01, value: currentTime, onChange: handleCurrentTimeChange })),
        React.createElement(S.AudioPlayerControls, null,
            React.createElement(S.AudioPlayerControl, { paddingLeft: isPlaying ? 0 : 2, onClick: isPlaying ? handlePause : handlePlay },
                React.createElement(Icon, { component: isPlaying ? PauseFullIcon : PlayFullIcon, height: 16 })),
            React.createElement(S.AudioPlayerControlsTimer, null,
                React.createElement(S.Time, null, millisecondsToTime(currentTime * 1000, { withHours })),
                React.createElement("div", null, "/"),
                React.createElement(S.Time, null, millisecondsToTime(duration * 1000, { withHours }))),
            React.createElement(S.AudioPlayerControl, { onClick: handleVolumeToggle },
                React.createElement(Icon, { component: VolumeOnFullIcon, height: 16 })),
            React.createElement(S.AudioPlayerAudio, null,
                React.createElement(S.AudioPlayerTrack, null,
                    React.createElement(RcSlider, { min: 0, max: 1, step: 0.001, value: volume, onChange: handleVolumeChange })))),
        React.createElement(S.AudioElement, { ref: audioRef, src: src, onLoadedMetadata: handleLoadedMetadata, onTimeUpdate: handleTimeUpdate, onEnded: handlePause })));
};
