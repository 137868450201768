import { useAuth } from '@fourthwall/auth';
import { isProduction } from '@fourthwall/utils/lib/dev';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useCurrentShop } from '../../Dashboard/hooks/useCurrentShop';
export const TagManagerProvider = ({ children }) => {
    const { user } = useAuth();
    const { currentShop } = useCurrentShop();
    useEffect(() => {
        if (!user?.id || !currentShop)
            return;
        TagManager.dataLayer({
            dataLayer: {
                userId: user?.id,
                userEmail: user?.email,
                leadTier: currentShop.leadTier,
                shopId: currentShop.id,
            },
        });
        if (isProduction()) {
            TagManager.initialize({ gtmId: 'GTM-NNJWLDT' });
        }
    }, [user?.id, currentShop]);
    return React.createElement("div", null, children);
};
