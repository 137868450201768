import { useEffect, useRef } from 'react';
export const useOAuthDialog = ({ width = 450, height = 660, onComplete, onError }) => {
    const listenerRef = useRef();
    const removeListener = () => {
        if (!listenerRef.current)
            return;
        window.removeEventListener('message', listenerRef.current);
    };
    useEffect(() => {
        return () => {
            removeListener();
        };
    }, []);
    const openOAuthDialog = (url) => {
        const popup = window.open(url, '_blank', `popup=yes,width=${width},height=${height}`);
        listenerRef.current = (event) => {
            const trustedUrl = new URL(url);
            if (!event.origin.includes(trustedUrl.hostname) &&
                !event.origin.includes(window.location.hostname))
                return;
            if (event.data.status === 'success') {
                popup?.close();
                removeListener();
                onComplete();
            }
            if (event.data.status === 'error') {
                popup?.close();
                removeListener();
                onError?.(event);
            }
        };
        // Website opened in popup above will message us with authorization status through postMessage api.
        // We just need to listen here for message event, check if origin of the domain is our site and
        // if there is a success status we can close popup and update our state to 'CONNECTED'
        window.addEventListener('message', listenerRef.current, false);
    };
    return {
        openOAuthDialog,
    };
};
