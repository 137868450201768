import { BackButton, Banner, ButtonGroup, HeadContent, Loading, Pagination, ToolbarButton, } from '@fourthwall/components';
import { DownloadIcon } from '@fourthwall/icons';
import React from 'react';
import { Dashboard, PageLayout } from '@components/common';
import { routing } from '@utils/routing';
import { TransactionsData } from './components/TransactionsData';
export const TransactionsView = ({ download, transactions = [], page, totalPages, isLoading, onPageChange, }) => {
    const title = 'Transactions history';
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: `${title} \u2013 Settings` }),
        React.createElement(PageLayout, null,
            React.createElement(Dashboard.Section, { name: title, titleSpacing: "medium", toolbarContent: React.createElement(ButtonGroup, null,
                    React.createElement(Loading, { isLoading: download.isLoading },
                        React.createElement(ToolbarButton, { label: "Export", icon: DownloadIcon, onClick: download.onClick }))), topContent: React.createElement(BackButton, { label: "Payouts", to: routing.settings.billing.self }), bottomContent: transactions.length ? (React.createElement(Banner, { title: "Profits are pending while they can be canceled or changed by the supporter.", appearance: "warning" })) : undefined }),
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(TransactionsData, { data: transactions, isLoading: isLoading })),
            React.createElement(Dashboard.Section.Item, null, !!totalPages && (React.createElement(Pagination, { pageCount: totalPages, currentPage: page, onChange: onPageChange }))))));
};
