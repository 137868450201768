import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
  display: grid;
  gap: 16px;
`;
export const Overlay = styled.div `
  position: absolute;
  top: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  background: ${Colors.GS1000};
  opacity: 0.6;
`;
export const MessageContainer = styled.div `
  position: absolute;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: ${Colors.GS100};
  font-weight: 600;
`;
export const Message = styled.div `
  padding-top: 12px;
`;
