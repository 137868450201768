import { Fieldset, RadioButton, Subsection } from '@fourthwall/components';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { useSendToSubsection } from './hooks/useSendToSubsection';
import { Collections } from './modules/Collections/Collections';
import { Products } from './modules/Products/Products';
import { useAdminModeContext } from '../../../../../../../../App/providers/AdminModeProvider';
const FIELDS = {
    ALL: 'ALL',
    TIME_BASED_SUPPORTERS: 'TIME_BASED_SUPPORTERS',
    COLLECTIONS: 'COLLECTIONS',
    PRODUCTS: 'PRODUCTS',
    // SUPPORTERS: 'SUPPORTERS',
};
const RADIO_NAME = 'sendTo.type';
export const SendToSubsection = ({ vm, setFinalSupportersCount }) => {
    const adminMode = useAdminModeContext();
    const formikContext = useFormikContext();
    const setSendToFieldValue = (recipientFilter) => formikContext.setFieldValue('sendTo', recipientFilter);
    const { type } = formikContext.getFieldMeta('sendTo').value;
    const { radioButtonLabels, setRadioButtonLabels, setFieldValue } = useSendToSubsection({
        setSendToFieldValue,
        setFinalSupportersCount,
    });
    return (React.createElement(Subsection, { title: "Send to" },
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Field, { component: RadioButton, name: RADIO_NAME, checkedValue: FIELDS.ALL, label: vm.allSupportersRadioButtonLabel, disabled: vm.disabled, onChange: (e) => setFieldValue(e, { type: FIELDS.ALL }) })),
            adminMode?.isActive && (React.createElement(React.Fragment, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: RADIO_NAME, checkedValue: FIELDS.TIME_BASED_SUPPORTERS, label: vm.last90DaysSupportersRadioButtonLabel, disabled: vm.disabled, onChange: (e) => setFieldValue(e, { type: FIELDS.TIME_BASED_SUPPORTERS, daysTillNow: 90 }) })),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: RADIO_NAME, checkedValue: FIELDS.COLLECTIONS, label: radioButtonLabels[FIELDS.COLLECTIONS], disabled: vm.disabled, onChange: (e) => setFieldValue(e, { type: FIELDS.COLLECTIONS, ids: [] }) })),
                type === FIELDS.COLLECTIONS && (React.createElement(Fieldset.Row, null,
                    React.createElement(Collections, { onItemSelectionChange: setRadioButtonLabels(FIELDS.COLLECTIONS) }))),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: RADIO_NAME, checkedValue: FIELDS.PRODUCTS, label: radioButtonLabels[FIELDS.PRODUCTS], disabled: vm.disabled, onChange: (e) => setFieldValue(e, { type: FIELDS.PRODUCTS, ids: [] }) })),
                type === FIELDS.PRODUCTS && (React.createElement(Fieldset.Row, null,
                    React.createElement(Products, { onItemSelectionChange: setRadioButtonLabels(FIELDS.PRODUCTS) }))))))));
};
