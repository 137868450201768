import { createQueryHook } from '@fourthwall/utils';
import { OrderApiClient } from '@fourthwall/services/lib/OrderApiClient';
import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
export const useOrderQuery = createQueryHook(OrderApiClient.getOrder, {
    queryKey: ['order'],
    select: (data) => ({
        ...data,
        offers: data.offers.map((offer) => ({
            ...offer,
            offer: {
                ...offer.offer,
                name: decodeHtmlEntities(offer.offer.name),
            },
        })),
    }),
});
