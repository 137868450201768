import React from 'react';
import { InfoTooltip } from '../../common/InfoTooltip';
import { withFormikSupport } from '../../hocs/withFormikSupport';
import { CheckboxIcon } from './CheckboxIcon';
import * as S from './styled';
export const CheckboxComponent = ({ label, description, tooltip, size = 'medium', indeterminate, id, name, value, checked, disabled, native, onChange, onValueChange, onBlur, }) => {
    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
        }
        if (onValueChange) {
            onValueChange(e.target.checked);
        }
    };
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
    };
    const renderTooltip = () => {
        if (!tooltip)
            return null;
        return (React.createElement(S.TooltipContainer, null,
            React.createElement(InfoTooltip, { content: tooltip, dark: true })));
    };
    const getChecked = () => {
        if (native)
            return checked;
        return typeof value === 'boolean' ? value : undefined;
    };
    return (React.createElement(S.Container, { "$disabled": disabled, "data-testid": "Checkbox" },
        React.createElement("div", null,
            React.createElement(S.Input, { type: "checkbox", id: id, name: name, value: typeof value === 'string' ? value : undefined, checked: getChecked(), disabled: disabled, onChange: handleChange, onBlur: handleBlur }),
            React.createElement(CheckboxIcon, { size: size, indeterminate: indeterminate })),
        (label || description) && (React.createElement(S.LabelContainer, null,
            label && (React.createElement(S.Label, { "$disabled": disabled },
                label,
                renderTooltip())),
            description && React.createElement(S.Description, null, description)))));
};
export const Checkbox = withFormikSupport(CheckboxComponent);
