import { BillingApiClient } from '@fourthwall/services/lib/BillingApiClient';
import { useQuery } from '@tanstack/react-query';
export const useBillingQuery = (page) => {
    const billingQuery = useQuery({
        queryKey: ['useBillingQuery', page],
        queryFn: async () => {
            const { data } = await BillingApiClient.getBilling({ size: 20, page: page - 1 });
            return data;
        },
    });
    return {
        billing: billingQuery.data,
        billingQuery,
    };
};
