import { Toasts, useModalContext } from '@fourthwall/components';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../../../../../utils/routing';
import { useCreatorMessages } from '../../hooks/useCreatorMessages';
import { SendMessageConfirmModal } from './components/SendMessageConfirmModal/SendMessageConfirmModal';
import { CreatorMessagesSendView } from './CreatorMessagesSendView';
import { useCreatorMessagesSend } from './hooks/useCreatorMessagesSend';
export const CreatorMessagesSendContainer = () => {
    const navigate = useNavigate();
    const { open, close } = useModalContext();
    const { invalidateCreatorMessages } = useCreatorMessages();
    const { sendMessagesViewModel: vm, sendMessage, setFinalSupportersCount, } = useCreatorMessagesSend();
    const handleOnConfirmSendMessages = (values) => {
        sendMessage(values, {
            onSuccess: () => {
                invalidateCreatorMessages();
                close();
                /**
                 * "setTimeout" was used here to not trigger LeavingGuard
                 */
                setTimeout(() => {
                    navigate(routing.contributions.creatorMessages.self);
                    Toasts.notify('Messages have been sent successfully.', { type: 'info' });
                }, 0);
            },
            onError: (error) => {
                const message = errorMessageSelector(error.response?.data, 'There was an error when sending messages.');
                Toasts.notify(message, { type: 'error' });
            },
        });
    };
    const handleOnSubmit = (values) => {
        open(SendMessageConfirmModal, {
            onConfirmClick: () => handleOnConfirmSendMessages(values),
            vm: {
                count: vm.recipientsCount,
            },
        });
    };
    return (React.createElement(CreatorMessagesSendView, { vm: vm, setFinalSupportersCount: setFinalSupportersCount, onSubmit: handleOnSubmit }));
};
