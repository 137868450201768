import React from 'react';
import { useWebhooks } from './hooks/useWebhooks';
import { WebhooksView } from './WebhooksView';
export const WebhooksContainer = () => {
    const { webhooks, clientSecret, isLoading, onWebhookCreate } = useWebhooks();
    return (React.createElement(WebhooksView, { webhooksListProps: {
            data: webhooks,
            isLoading,
            emptyComponent: 'There are no webhooks created yet.',
        }, clientSecret: clientSecret, onWebhookCreate: onWebhookCreate }));
};
