import styled from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const ContentContainer = styled.div `
  min-height: 520px;

  ${mediaQueries.tablet} {
    padding-right: 16px;
  }
`;
export const BannerWrapper = styled.div `
  margin-bottom: 18px;
`;
export const IdleContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0;

  flex-direction: column;
  gap: 16px;

  & > p {
    text-align: center;
  }
`;
export const SelectItemsList = styled.div `
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors.GS400};
  width: 100%;

  ${mediaQueries.laptop} {
    width: 100%;
    min-width: fit-content;
  }
`;
export const LoaderContainer = styled.div `
  display: flex;
  justify-content: center;
  padding: 18px 0;
`;
