import { Toasts } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { fileToBase64 } from '@fourthwall/utils/lib/file';
import { getRejectionErrors } from '../../../../../../../../utils/dropzone';
import { FormFields } from '../../../types';
export const useSocialImage = () => {
    const { setFieldValue } = useFormikContext();
    const setImageTypeValue = (type) => {
        setFieldValue(FormFields.socialImageType, type);
    };
    const handleFileDrop = async (files) => {
        if (files?.[0]) {
            const base64 = await fileToBase64(files[0]);
            setFieldValue(FormFields.socialImage, base64);
            setImageTypeValue('New');
        }
    };
    const handleRemove = async () => {
        setFieldValue(FormFields.socialImage, undefined);
        setImageTypeValue('Remove');
    };
    const handleFileDropRejected = (rejectedFiles) => {
        getRejectionErrors(rejectedFiles, {
            'file-too-large': 'File is larger than 1 megabyte',
        }).forEach((rejection) => {
            Toasts.notify(rejection);
        });
    };
    return {
        handleFileDrop,
        handleFileDropRejected,
        handleRemove,
    };
};
