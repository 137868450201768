import { Banner, ButtonClean, Input, InputUI, Subsection, Tabs, Toasts, Upload, useMediaQueryContext, VisuallyHidden, } from '@fourthwall/components';
import { ExclamationMarkIcon, UploadIcon } from '@fourthwall/icons';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import get from 'lodash-es/get';
import React, { useMemo, useState } from 'react';
import { useDashboardFeatureFlags } from '../../../../../../../components/utils/DashboardFeatureFlags';
import { getRejectionErrors } from '../../../../../../../utils/dropzone';
import { RemovableUpload } from '../../../../components/RemovableUpload/RemovableUpload';
import VideoPlayer from '../../../../components/VideoPlayer';
import { ALLOWED_VIDEO_EXTENSIONS } from '../../../../consts';
import { isFirefox, isSafari } from '../../../../utils/browserDetect';
import { Overlay } from '../../components/Overlay';
import { getVimeoEmbedURL, getVimeoVideoId, getYouTubeEmbedURL, getYouTubeVideoId } from './utils';
import { getStreamableEmbedURL } from './utils/getStreamableEmbedURL';
import { getStreamableVideoId } from './utils/getStreamableVideoId';
import { getVimeoLiveStreamEmbedURL } from './utils/getVimeoLiveStreamEmbedURL';
import { getVimeoLiveStreamId } from './utils/getVimeoLiveStreamId';
import { prepareSubtitles } from './utils/prepareSubtitles';
import * as S from './VideoFields.styled';
const TABS = ['upload', 'embed'];
export const VideoFields = ({ type, typeFieldName, video, videoFieldName, thumbnail, thumbnailFieldName, subtitles, subtitlesFieldName, url, urlFieldName, subsectionTitle, isUploading, uploadProgress, isThumbnailCustom, thumbnailUrl, disabled, status, }) => {
    const media = useMediaQueryContext();
    const { values, errors, setFieldValue } = useFormikContext();
    const [banner, setBanner] = useState(null);
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const videoSrc = useMemo(() => {
        if (!video) {
            return undefined;
        }
        if (typeof video === 'string') {
            return video;
        }
        return URL.createObjectURL(video);
    }, [video]);
    const thumbnailSrc = useMemo(() => {
        if (!thumbnail) {
            return undefined;
        }
        if (typeof thumbnail === 'string') {
            return thumbnail;
        }
        return URL.createObjectURL(thumbnail);
    }, [thumbnail]);
    const handleVideoThumbnailUpload = (file) => {
        if (file) {
            setFieldValue(thumbnailFieldName, file);
        }
    };
    const handleSubtitlesUpload = async (file) => {
        if (file && subtitlesFieldName) {
            prepareSubtitles(file)
                .then((subtitlesFile) => {
                setFieldValue(subtitlesFieldName, subtitlesFile);
            })
                .catch(() => {
                Toasts.notify('Unable to process the subtitle file.');
            });
        }
    };
    const handleVideoUpload = (files) => {
        setBanner(null);
        if (files?.[0]) {
            if (files[0].type === 'video/x-matroska' && (isSafari() || isFirefox())) {
                setBanner('Instant preview of MKV files is not supported by Safari and Firefox browsers. Video will be available after processing.');
            }
            setFieldValue(videoFieldName, files[0]);
        }
    };
    const handleVideoRemove = () => {
        setBanner(null);
        setFieldValue(videoFieldName, undefined);
    };
    const renderThumbnailButton = () => {
        if (type === 'upload' && !video) {
            return null;
        }
        if (type === 'embed' && !url) {
            return null;
        }
        if (!thumbnail || !(isThumbnailCustom || typeof thumbnail === 'object')) {
            return (React.createElement("div", null,
                React.createElement(Upload, { accept: {
                        'image/*': [],
                    }, maxSize: 10485760, 
                    // eslint-disable-next-line react/no-unstable-nested-components
                    content: () => (React.createElement(ButtonClean, { label: "Upload custom thumbnail", disabled: isUploading || disabled })), onFileDropRejected: handleFileReject, onFileDrop: (files) => handleVideoThumbnailUpload(files?.[0]) })));
        }
        return (React.createElement("div", null,
            React.createElement(ButtonClean, { label: "Remove custom thumbnail", disabled: isUploading || disabled, onClick: () => {
                    setFieldValue(thumbnailFieldName, isThumbnailCustom ? undefined : thumbnailUrl);
                } })));
    };
    const renderSubtitlesButton = () => {
        if (!dashboardFeatureFlags.membershipsVideoSubtitles.enabled) {
            return null;
        }
        if (!subtitlesFieldName) {
            return null;
        }
        if (type === 'upload' && !video) {
            return null;
        }
        if (type === 'embed' && !url) {
            return null;
        }
        if (!subtitles) {
            return (React.createElement("div", null,
                React.createElement(Upload, { accept: {
                        'text/vtt': ['.vtt'],
                        'text/srt': ['.srt'],
                    }, maxSize: 10485760, 
                    // eslint-disable-next-line react/no-unstable-nested-components
                    content: () => (React.createElement(ButtonClean, { label: "Upload custom subtitles", disabled: isUploading || disabled })), onFileDropRejected: handleFileReject, onFileDrop: (files) => handleSubtitlesUpload(files?.[0]) })));
        }
        return (React.createElement("div", null,
            React.createElement(ButtonClean, { label: "Remove custom subtitles", disabled: isUploading || disabled, onClick: () => {
                    setFieldValue(subtitlesFieldName, false);
                } })));
    };
    const renderOverlay = () => {
        if (status === 'Errored') {
            return (React.createElement(Overlay, { type: "error", showLoader: false, title: "An error occured during video processing. Please re-upload your video.", icon: ExclamationMarkIcon }));
        }
        if (status === 'Processing' || status === 'Created') {
            return (React.createElement(Overlay, { title: "Processing", subTitle: "This process can take up to an hour, depending on the video file size." }));
        }
    };
    const renderUploadingOverlay = () => {
        const getMessage = () => {
            if (uploadProgress === 0) {
                return 'Uploading';
            }
            return `Uploading (${uploadProgress}%)`;
        };
        return React.createElement(Overlay, { title: getMessage() });
    };
    const renderVideo = () => {
        if (!videoSrc) {
            return null;
        }
        if (status === 'Processing' || status === 'Errored') {
            return React.createElement(S.OverlayContainer, null, renderOverlay());
        }
        return (React.createElement(S.PlayerContainer, null,
            React.createElement(RemovableUpload, { onRemove: handleVideoRemove },
                React.createElement(VideoPlayer, { videoSrc: videoSrc, thumbnailSrc: thumbnailSrc, hls: typeof video === 'string' })),
            isUploading && renderUploadingOverlay()));
    };
    const handleFileReject = (rejectedFiles) => {
        getRejectionErrors(rejectedFiles).forEach((rejection) => {
            Toasts.notify(rejection);
        });
    };
    const renderUploader = () => {
        if (videoSrc) {
            return null;
        }
        return (React.createElement(S.UploadContainer, null,
            React.createElement(Upload, { accept: ALLOWED_VIDEO_EXTENSIONS, title: media.tablet ? (React.createElement("span", null,
                    "Drop video or ",
                    React.createElement("u", null, "browse"))) : ('Browse'), maxSize: 53687091200, invalid: !!get(errors, videoFieldName), icon: UploadIcon, iconSize: 20, orientation: "horizontal", onFileDrop: handleVideoUpload, onFileDropRejected: handleFileReject, disabled: values.access === 'Public' && !dashboardFeatureFlags.membershipsPublicVideo.enabled })));
    };
    const renderPublicPostAlert = () => {
        if (values.access === 'Public' && !dashboardFeatureFlags.membershipsPublicVideo.enabled) {
            return (React.createElement("div", { style: { marginBottom: 16 } },
                React.createElement(Banner, { title: "Due to bandwidth costs, uploaded videos can only be offered to members. To share a video everyone can watch, use the 'Embed video' option.", appearance: "warning" })));
        }
    };
    const renderUploadVideoSection = () => {
        return (React.createElement(S.Container, null,
            React.createElement(VisuallyHidden, null,
                React.createElement("input", { type: "text", name: videoFieldName })),
            renderPublicPostAlert(),
            renderVideo(),
            renderUploader(),
            get(errors, videoFieldName) && (React.createElement(InputUI.Error, null, get(errors, videoFieldName))),
            React.createElement(S.Buttons, null,
                renderThumbnailButton(),
                renderSubtitlesButton()),
            banner && (React.createElement("div", { style: { marginTop: 16 } },
                React.createElement(Banner, null, banner)))));
    };
    const renderEmbedVideoSection = () => {
        const renderIFrame = (url) => (React.createElement(S.IFrameContainer, null,
            React.createElement("iframe", { src: url, width: "100%", height: "310" }),
            isUploading && renderUploadingOverlay()));
        const renderEmbedVideo = () => {
            if (url) {
                const youtubeVideoId = getYouTubeVideoId(url);
                const vimeoVideoData = getVimeoVideoId(url);
                const vimeoLiveStreamData = getVimeoLiveStreamId(url);
                const streamableVideoId = getStreamableVideoId(url);
                if (youtubeVideoId) {
                    return renderIFrame(getYouTubeEmbedURL(youtubeVideoId));
                }
                if (vimeoVideoData?.id) {
                    return renderIFrame(getVimeoEmbedURL(vimeoVideoData.id, vimeoVideoData.unlistedHash));
                }
                if (vimeoLiveStreamData?.id) {
                    return renderIFrame(getVimeoLiveStreamEmbedURL(vimeoLiveStreamData.id, vimeoLiveStreamData.unlistedHash));
                }
                if (streamableVideoId) {
                    return renderIFrame(getStreamableEmbedURL(streamableVideoId));
                }
            }
        };
        return (React.createElement(S.Container, null,
            React.createElement(Field, { name: urlFieldName, placeholder: "Video URL", component: Input, disabled: isUploading, suggestion: "Supports YouTube, Vimeo and Streamable embeds" }),
            renderEmbedVideo()));
    };
    if (status) {
        return (React.createElement(Subsection, { title: subsectionTitle }, type === 'embed' ? renderEmbedVideoSection() : renderUploadVideoSection()));
    }
    return (React.createElement(Subsection, { title: subsectionTitle },
        React.createElement(Tabs, { defaultIndex: TABS.indexOf(type), onSelect: (index) => setFieldValue(typeFieldName, TABS[index]) },
            React.createElement(Tabs.List, null,
                React.createElement(Tabs.Item, { name: "Upload", variant: "dimmed" }),
                React.createElement(Tabs.Item, { name: "Embed", variant: "dimmed" })),
            React.createElement(Tabs.Panel, null, renderUploadVideoSection()),
            React.createElement(Tabs.Panel, null, renderEmbedVideoSection()))));
};
