import React from 'react';
import * as S from './styled';
const supportedHTMLElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'legend'];
export const Heading = ({ type, weight = 'regular', asTag, children, ...props }) => {
    const getType = () => {
        if (asTag) {
            return asTag;
        }
        if (supportedHTMLElements.find((element) => element === type)) {
            return type;
        }
        return 'div';
    };
    return (React.createElement(S.Container, { as: getType(), type: type, weight: weight, "data-testid": "Heading", ...props }, children));
};
