import { ApiBase } from '../ApiBase';
class CustomizationV4ApiModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getCustomization = (customizationId) => {
            return this.get(`/v4/customization/${customizationId}`);
        };
        this.updateCustomizationState = (params, body) => {
            return this.put(`/v4/customization/${params.customizationId}/state/`, body);
        };
        this.updateCustomizationAttributes = (params, body) => {
            return this.put(`/v4/customization/${params.customizationId}/attributes`, body);
        };
    }
}
export const CustomizationV4ApiClient = new CustomizationV4ApiModel();
