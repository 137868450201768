export const FILTER_PRODUCT_STATUS = ['PUBLIC', 'HIDDEN'];
export const FILTER_PRODUCT_STATUS_LABELS = {
    PUBLIC: 'Public',
    HIDDEN: 'Hidden',
};
export const FILTER_PRODUCT_AVAILABILITY = [
    'AVAILABLE',
    'SOLD_OUT',
];
export const FILTER_PRODUCT_AVAILABILITY_LABELS = {
    AVAILABLE: 'Available',
    SOLD_OUT: 'Sold out',
};
