import React, { Children, isValidElement, cloneElement } from 'react';
import * as S from './styled';
import { ArrowsButton } from './ArrowsButton';
export const Arrows = ({ size = 'medium', children }) => (React.createElement(S.Container, null, Children.map(children, (child) => {
    if (!isValidElement(child))
        return null;
    // TODO: any
    return cloneElement(child, { size });
})));
Arrows.Button = ArrowsButton;
