import { Icon, Loader } from '@fourthwall/components';
import React from 'react';
import * as S from './Overlay.styled';
export const Overlay = ({ type = 'default', title, subTitle, icon, showLoader = true }) => {
    return (React.createElement(React.Fragment, null,
        type === 'default' && React.createElement(S.Overlay, { "data-testid": "Overlay" }),
        type === 'error' && React.createElement(S.ErrorOverlay, null),
        React.createElement(S.Container, null,
            showLoader && (React.createElement(S.IconContainer, null,
                React.createElement(Loader, { size: "large", appearance: "primary" }))),
            icon && (React.createElement(S.IconContainer, null,
                React.createElement(Icon, { component: icon, height: 23 }))),
            title && React.createElement(S.Title, null, title),
            subTitle && React.createElement(S.SubTitle, null, subTitle))));
};
