import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Grid = styled.div `
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(274px, 1fr));
  gap: 32px;
`;
export const AddTierButtonContainer = styled.div `
  display: grid;
  height: 200px;
`;
export const AddTierButton = styled.button `
  display: grid;

  justify-content: center;
  align-items: center;

  border: 1px dashed ${Colors.GS500};

  padding: 28px 0;

  cursor: pointer;

  :hover {
    border-color: ${Colors.GS1000};
    color: ${Colors.B700} !important;
  }
`;
