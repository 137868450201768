import { useAuth } from '@fourthwall/auth';
import { useShopPayoutQuery } from '../../../../hooks/useShopPayoutQuery';
import { useDashboardFeatureFlags } from '../../../../../../components/utils/DashboardFeatureFlags';
import { useDeletePayoutConnectionMutation } from './useDeletePayoutConnectionMutation';
import { useStripeOnboardingFlow } from './useStripeOnboardingFlow';
import { useDashboardPermissions } from '../../../../../../components/utils/DashboardPermissions';
const isActivePayoutInfo = (data) => data.status === 'ACTIVE';
const isInProgressPayoutInfo = (data) => data.status === 'IN_PROGRESS';
export const usePayoutsSetup = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const { startStripeOnboarding, isLoading: isStripeOnboardingLoading } = useStripeOnboardingFlow();
    const { user } = useAuth();
    const { deletePayoutConnectionMutation } = useDeletePayoutConnectionMutation();
    const { shopPayoutQuery } = useShopPayoutQuery();
    const isUserTheAccountCreator = shopPayoutQuery.data &&
        (isActivePayoutInfo(shopPayoutQuery.data) || isInProgressPayoutInfo(shopPayoutQuery.data)) &&
        user?.email === shopPayoutQuery.data.owner.accountCreator.email;
    const canUserEditPayouts = isUserTheAccountCreator &&
        hasPermission('settings.billing.payouts.edit') &&
        dashboardFeatureFlags.payoutSetup.enabled;
    const canUserSetupPayouts = hasPermission('settings.billing.payouts.setup') && dashboardFeatureFlags.payoutSetup.enabled;
    const canUserSetupNewPayouts = !isUserTheAccountCreator && canUserSetupPayouts;
    const handleEdit = canUserEditPayouts ? startStripeOnboarding : undefined;
    const handleNewSetup = canUserSetupNewPayouts
        ? () => {
            deletePayoutConnectionMutation.mutate([], {
                onSuccess: startStripeOnboarding,
            });
        }
        : undefined;
    const getInfo = (data) => {
        const { owner, connectedAt } = data;
        return {
            partnerName: owner.type === 'PARTNER' ? owner.partnerName : undefined,
            ownerName: owner.accountCreator.fullName,
            ownerEmail: owner.accountCreator.email,
            date: connectedAt,
        };
    };
    const getPayoutsSetup = () => {
        if (shopPayoutQuery.isSuccess && shopPayoutQuery.data.status !== 'INACTIVE') {
            return {
                status: 'ACTIVE',
                inProgress: shopPayoutQuery.data.status === 'IN_PROGRESS',
                info: getInfo(shopPayoutQuery.data),
                onEdit: handleEdit,
                onNewSetup: handleNewSetup,
            };
        }
        return {
            status: 'INACTIVE',
            onSetup: canUserSetupPayouts ? startStripeOnboarding : undefined,
        };
    };
    return {
        setup: getPayoutsSetup(),
        isLoading: deletePayoutConnectionMutation.isLoading || isStripeOnboardingLoading,
    };
};
