import { Loader, Tooltip } from '@fourthwall/components';
import React from 'react';
import { CopyableText } from '../../../../../../../../components/common/CopyableText';
import * as S from './DnsRecordCodeCell.styled';
const mapAliasToLoaderTooltip = (alias) => {
    switch (alias) {
        case 'SENDGRID_MAIL_CNAME':
        case 'SENDGRID_DOMAIN_KEY1':
        case 'SENDGRID_DOMAIN_KEY2':
            return 'We are waiting for SendGrid to let us know what you should input in that instance';
        case 'ZENDESK_VERIFICATION':
            return 'We are waiting for Zendesk to let us know what you should input in that instance';
        default:
            return 'We are waiting for one of external services to let us know what you should input in that instance';
    }
};
export const DnsRecordCodeCell = ({ value, recordAlias }) => {
    if (value?.length) {
        return (React.createElement(CopyableText, { text: value },
            React.createElement(S.CodeCell, { pointer: true }, value)));
    }
    if (value === '')
        return React.createElement(S.CodeCell, null, "\u00A0");
    return (React.createElement(Tooltip, { reference: React.createElement(Loader, { size: "small" }), placement: "top", fluid: true }, mapAliasToLoaderTooltip(recordAlias)));
};
