import { SuspenseFallback } from '@fourthwall/components';
import React, { createContext, useMemo } from 'react';
import { useCurrentShopQuery } from '../../Dashboard/hooks/useCurrentShopQuery';
export const CurrentShopContext = createContext(undefined);
export const CurrentShopProvider = ({ children }) => {
    const { currentShopQuery } = useCurrentShopQuery();
    if (!currentShopQuery.data || currentShopQuery.isError) {
        return React.createElement(SuspenseFallback, null);
    }
    const value = useMemo(() => ({ currentShop: currentShopQuery.data }), [currentShopQuery.data]);
    return React.createElement(CurrentShopContext.Provider, { value: value }, children);
};
