import styled, { css } from 'styled-components';
export const Container = styled.div(({ minWidth, hasActions, sortable }) => css `
    min-width: ${minWidth}px;

    ${hasActions &&
    css `
      margin-right: -48px;
    `}

    ${sortable &&
    css `
      margin-left: -48px;
    `}
  `);
