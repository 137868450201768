import { Colors, hideScrollbar } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const SelectedImageContainer = styled.div `
  flex: 1;
  height: 100%;
`;
export const SelectedImage = styled.img `
  width: 100%;
  height: 100%;

  object-fit: contain;
`;
export const ImagesList = styled.div `
  order: -1;
  flex: 0 0 120px;
  height: 100%;
  margin-right: 32px;

  overflow: auto;
`;
export const ImagesListItem = styled.div `
  width: 100%;
  max-height: 180px;
  background-color: ${Colors.GS200};
  border: 1px solid ${({ active }) => (active ? Colors.GS1000 : 'transparent')};

  cursor: pointer;

  & + & {
    margin-top: 8px;
  }
`;
export const ImagesListItemImage = styled.img `
  width: 100%;
  height: auto;
  object-fit: contain;
`;
export const SliderContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Slider = styled.div `
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  gap: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding-inline: 20px;
  transition: transform 0.1s;
  max-height: 90vh;

  ${hideScrollbar};
`;
export const Slide = styled.div `
  flex: 0 0 100%;
  scroll-snap-align: start end;
`;
