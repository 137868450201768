import padStart from 'lodash-es/padStart';
export const millisecondsToTime = (milliseconds, { withHours } = { withHours: true }) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (60 * 1000)) % 60);
    const hours = Math.floor((milliseconds / (60 * 60 * 1000)) % 60);
    const formatValue = (value) => padStart(value.toString(), 2, '0');
    if (withHours) {
        return `${formatValue(hours)}:${formatValue(minutes)}:${formatValue(seconds)}`;
    }
    return `${formatValue(minutes)}:${formatValue(seconds)}`;
};
export const getThankYouTypeFromData = (thankYou) => {
    const thankYouData = thankYou.thankYous[0];
    if (thankYouData?.mediaType.type === 'video')
        return 'VIDEO';
    if (thankYouData?.mediaType.type === 'audio')
        return 'AUDIO';
    // default
    return 'VIDEO';
};
