import { PriceString } from '@fourthwall/components';
import React from 'react';
import { SelectedList } from '../../../../../../../../../../../components/common/SelectedList';
import { getFormattedSupportersLabel } from '../../utils/helpers/getFormattedSupportersLabel';
import * as S from './SelectedItemsList.styled';
export const SelectedItemsList = ({ selectedItems, onSortChange, onPositionChange, onRemove, }) => {
    return (React.createElement(S.Wrapper, null,
        React.createElement(SelectedList, { onRemove: onRemove, onSortChange: onSortChange, onPositionChange: onPositionChange }, selectedItems.map((item) => {
            const commonProps = {
                key: item.id,
                id: item.id,
                name: item.name,
            };
            if (item.showAvatar) {
                return (React.createElement(SelectedList.Item, { ...commonProps, showAvatar: item.showAvatar, rightContent: React.createElement(S.RightContent, null,
                        React.createElement(PriceString, { value: item.contributedAmount }),
                        " contributed") }));
            }
            return (React.createElement(SelectedList.Item, { ...commonProps, photoUrl: item.imageUrl, quantity: item.productsTotal, rightContent: React.createElement(S.RightContent, null, getFormattedSupportersLabel(item.supportersTotal)) }));
        }))));
};
