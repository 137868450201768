import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
import { ScrollContent } from '@components/common/ScrollContent';
export const Header = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
export const Title = styled.div `
  font-weight: 600;
  line-height: 1.5;
`;
export const Content = styled(ScrollContent) `
  min-height: 80px;
  padding-right: 20px;
  margin-right: -20px;
`;
export const EmptyMessage = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: inherit;
  background-color: ${Colors.GS200};
`;
