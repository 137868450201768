import React, { useState } from 'react';
import { CustomPicker } from 'react-color';
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common';
import tinycolor from 'tinycolor2';
import { Checkboard } from '../../partials/Checkboard';
import { automatedThemeDefaults, ComponentsThemeProvider, getInternalTheme, } from '../../providers/ComponentsThemeProvider';
import { Button, ButtonAppearance, ButtonSize } from '../Button';
import * as S from './ColorPicker.styled';
import { ColorPickerInput } from './ColorPickerInput';
import { ColorFormat } from './types';
const CustomCircle = () => React.createElement(S.CustomCircle, null);
const CustomPointer = () => React.createElement(S.CustomPointer, null);
const CustomPointerAlpha = () => React.createElement(S.CustomPointer, { alpha: true });
const ColorPickerComponent = ({ hex, hsl, rgb, hsv, position = 'bottom-start', format = ColorFormat.RGB, hexVisible = false, onChange, onValueChange, }) => {
    const [showHex, setShowHex] = useState(false);
    const [showOpacity, setShowOpacity] = useState(false);
    const handleChange = (data) => {
        if (onChange) {
            onChange(data);
        }
        if (onValueChange) {
            const color = tinycolor(data);
            if (format === ColorFormat.RGB) {
                onValueChange(color.toRgbString());
            }
            if (format === ColorFormat.HEX) {
                onValueChange(color.toHexString());
            }
        }
    };
    const handleOpacityInputChange = (data) => {
        let opacityValue = parseInt(data) / 100;
        if (opacityValue < 0) {
            opacityValue = 0;
        }
        else if (opacityValue > 1) {
            opacityValue = 1;
        }
        return handleChange({
            r: rgb?.r,
            g: rgb?.g,
            b: rgb?.b,
            a: opacityValue,
        });
    };
    const renderOpacity = () => {
        if (showOpacity) {
            return (React.createElement(S.Alpha, null,
                React.createElement(Alpha
                // @ts-ignore
                , { 
                    // @ts-ignore
                    rgb: rgb, hsl: hsl, direction: "vertical", pointer: CustomPointerAlpha, onChange: handleChange })));
        }
        return null;
    };
    const renderHexButton = () => {
        if (!showHex && !hexVisible) {
            return (React.createElement(S.ButtonContainer, null,
                React.createElement(Button, { label: "Show HEX", appearance: ButtonAppearance.Primary, size: ButtonSize.Small, fullWidth: true, onClick: () => setShowHex(true) })));
        }
        return null;
    };
    const renderOpacityButton = () => {
        if (!showOpacity) {
            return (React.createElement(S.ButtonContainer, null,
                React.createElement(Button, { label: "Show opacity", appearance: ButtonAppearance.Primary, size: ButtonSize.Small, fullWidth: true, onClick: () => setShowOpacity(true) })));
        }
        return null;
    };
    const renderButtons = () => {
        if (!showHex || !showOpacity) {
            return (React.createElement(S.Buttons, null,
                renderHexButton(),
                format === ColorFormat.RGB && renderOpacityButton()));
        }
        return null;
    };
    const renderHexInput = () => {
        if (showHex || hexVisible) {
            return (React.createElement(S.Input, null,
                React.createElement(ColorPickerInput, { label: "Hex", value: hex, onChange: handleChange })));
        }
        return null;
    };
    const renderOpacityInput = () => {
        if (showOpacity) {
            return (React.createElement(S.Input, null,
                React.createElement(ColorPickerInput, { label: "Opacity", value: Math.round(rgb?.a ? rgb.a * 100 : 0).toString(), onChange: handleOpacityInputChange })));
        }
        return null;
    };
    const renderInputs = () => {
        if (showHex || showOpacity || hexVisible) {
            return (React.createElement(S.Inputs, { withMargin: (format === ColorFormat.RGB && !showOpacity) || (!showHex && !hexVisible) },
                renderHexInput(),
                renderOpacityInput()));
        }
        return null;
    };
    return (React.createElement(ComponentsThemeProvider, { automatedTheme: getInternalTheme(automatedThemeDefaults.light), mode: "solid" },
        React.createElement(S.Container, { pickerPosition: position, "data-testid": "ColorPicker" },
            React.createElement(S.ColorsContainer, null,
                React.createElement(S.Saturation, null,
                    React.createElement(Saturation
                    // @ts-ignore
                    , { 
                        // @ts-ignore
                        hsv: hsv, hsl: hsl, pointer: CustomCircle, onChange: handleChange })),
                React.createElement("div", null,
                    React.createElement(S.SelectedContainer, null,
                        React.createElement(Checkboard, null),
                        React.createElement(S.Selected, { rgb: rgb })),
                    React.createElement(S.Modifiers, null,
                        React.createElement(S.Hue, null,
                            React.createElement(Hue
                            // @ts-ignore
                            , { 
                                // @ts-ignore
                                hsl: hsl, pointer: CustomPointer, direction: "vertical", onChange: handleChange })),
                        renderOpacity()))),
            renderButtons(),
            renderInputs())));
};
export const ColorPicker = CustomPicker(ColorPickerComponent);
