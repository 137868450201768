import React from 'react';
import { PriceString } from '../../../../../../functional/PriceString';
import * as S from './styled';
export const AccordionHeading = ({ quantity, isMinimumQuantity, profit, showProfit }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.Quantity, null,
            quantity,
            " sold ",
            isMinimumQuantity && React.createElement("span", null, "(minimum)")),
        showProfit && (React.createElement("div", null,
            React.createElement(PriceString, { value: profit }),
            " profit"))));
};
