import styled from 'styled-components';
export const ShippingPricingMethodContainer = styled.div `
  margin-top: 12px;
  margin-bottom: 16px;
`;
export const ShippingLocationContainer = styled.div `
  margin-bottom: 24px;
`;
export const ShippingGroupsYouShipContainer = styled.div `
  margin-bottom: 32px;
`;
