import React from 'react';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import * as S from './styled';
export const OrderDetails = ({ thankYou }) => {
    return (React.createElement(S.Container, null,
        !!thankYou.tags.length && (React.createElement(S.Badges, null, thankYou.tags.map((tag) => (React.createElement(S.Badge, { key: tag }, tag))))),
        !!thankYou.offers.length && (React.createElement(S.Products, null, thankYou.offers.map((offer, index) => (React.createElement(S.Product, { key: index },
            React.createElement(PhotoThumbnail, { url: offer.thumbnailImageUrl, size: "small" }),
            React.createElement(S.ProductTitle, null, offer.title)))))),
        !!thankYou.message.length && React.createElement(S.Message, null,
            "\u201C",
            thankYou.message,
            "\u201D")));
};
