import { useAuth } from '@fourthwall/auth';
import { ButtonClean, ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { getDefaultErrorMessage } from '@fourthwall/utils/lib/queryClient';
import { useIsPaymentMethodModalRequiredMutation } from '@hooks/useIsPaymentMethodModalRequiredMutation';
import { useCreatorIntegrationsQuery } from '@modules/Dashboard/Apps';
import { useProductApprovalStatus } from '@modules/Dashboard/Apps/hooks/useProductApprovalStatus';
import { PaymentMethodRequiredModal } from '@modules/Dashboard/PaymentMethods';
import { routing } from '@utils/routing';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import logoSrc from '../../../assets/signets/tiktok.svg';
import { useAppConnectedEvent } from '../../../hooks/useAppConnectedEvent';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
import { useShopPaymentMethods } from '../../../hooks/useShopPaymentMethods';
import { DisconnectErrorModal } from '../components/DisconnectErrorModal';
import { DEFAULT_PRICE_PERCENTAGE_INCREASE } from '../consts';
import { getConfigurationPrerequisitesErrors } from '../utils';
import { useDeleteTiktokShopConfigurationMutation } from './useDeleteTiktokShopConfigurationMutation';
import { useTiktokErrorQueryParams } from './useTiktokErrorQueryParams';
import { useTiktokShopConfigurationQuery } from './useTiktokShopConfigurationQuery';
import { useTiktokShopProductsQuery } from './useTiktokShopProductsQuery';
import { useTiktokShopsQuery } from './useTiktokShopsQuery';
import { useTiktokShopWarehousesQuery } from './useTiktokShopWarehousesQuery';
import { useUpdateTiktokShopConfigurationMutation } from './useUpdateTiktokShopConfigurationMutation';
import { useUpdateTiktokShopConfigurationStatusMutation } from './useUpdateTiktokShopConfigurationStatusMutation';
export const useTiktokShop = () => {
    const { open } = useModalContext();
    const { keycloak } = useAuth();
    const { tiktokErrorQueryParams, setTiktokErrorQueryParams } = useTiktokErrorQueryParams();
    const { creatorIntegrationsQuery, invalidateCreatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const { tiktokShopConfigurationQuery, invalidateTiktokShopConfigurationQuery } = useTiktokShopConfigurationQuery([], {
        enabled: creatorIntegrationsQuery.data?.tiktokPartner?.status !== 'NOT_CONNECTED',
    });
    const { tiktokShopWarehousesQuery, invalidateTiktokShopWarehousesQuery } = useTiktokShopWarehousesQuery([], {
        enabled: creatorIntegrationsQuery.data?.tiktokPartner?.status === 'NOT_CONFIGURED' &&
            tiktokShopConfigurationQuery.data?.type === 'CONFIGURED',
    });
    const { tiktokShopsQuery } = useTiktokShopsQuery([], {
        enabled: creatorIntegrationsQuery.data?.tiktokPartner?.status === 'NOT_CONFIGURED',
    });
    const { tiktokShopProductsQuery } = useTiktokShopProductsQuery([], {
        enabled: creatorIntegrationsQuery.data?.tiktokPartner?.status === 'CONNECTED',
    });
    const { hasActivePaymentMethod } = useShopPaymentMethods();
    const { updateTiktokShopConfigurationMutation } = useUpdateTiktokShopConfigurationMutation();
    const { updateTiktokShopConfigurationStatusMutation } = useUpdateTiktokShopConfigurationStatusMutation();
    const { deleteTiktokShopConfigurationMutation } = useDeleteTiktokShopConfigurationMutation();
    const { isPaymentMethodModalRequiredMutation } = useIsPaymentMethodModalRequiredMutation();
    const { productApprovalStatus } = useProductApprovalStatus();
    const appConnectEvent = useAppConnectEvent('tiktok_shop');
    const pricePercentageIncrease = tiktokShopConfigurationQuery.data?.type === 'CONFIGURED'
        ? tiktokShopConfigurationQuery.data?.pricePercentageIncrease
        : undefined;
    useAppConnectedEvent('tiktok_shop');
    const status = creatorIntegrationsQuery.data?.tiktokPartner.status;
    const currentShopId = tiktokShopConfigurationQuery.data?.type === 'CONFIGURED'
        ? tiktokShopConfigurationQuery.data?.tiktokShopId
        : undefined;
    const currentWarehouseId = tiktokShopConfigurationQuery.data?.type === 'CONFIGURED'
        ? tiktokShopConfigurationQuery.data?.warehouseId
        : undefined;
    const configurationPrerequisitesErrors = getConfigurationPrerequisitesErrors(tiktokShopConfigurationQuery.data?.type === 'CONFIGURED'
        ? tiktokShopConfigurationQuery.data?.prerequisites
        : undefined);
    const configurationStatus = useMemo(() => {
        if (status !== 'NOT_CONFIGURED' ||
            tiktokShopConfigurationQuery.isInitialLoading ||
            tiktokShopsQuery.isInitialLoading)
            return null;
        if (status === 'NOT_CONFIGURED' && !!tiktokShopsQuery.data?.data.shop_list.length)
            return 'CONFIGURATION_REQUIRED';
        return 'SETUP_REQUIRED';
    }, [
        status,
        tiktokShopConfigurationQuery.isInitialLoading,
        tiktokShopWarehousesQuery.isInitialLoading,
        tiktokShopConfigurationQuery.data,
        tiktokShopsQuery.isInitialLoading,
        tiktokShopsQuery.data,
    ]);
    useEffect(() => {
        if (tiktokErrorQueryParams.error && tiktokErrorQueryParams.errorDescription) {
            Toasts.notify(tiktokErrorQueryParams.errorDescription, { type: 'error' });
            setTiktokErrorQueryParams({ error: undefined, errorDescription: undefined });
        }
    }, [tiktokErrorQueryParams.error, tiktokErrorQueryParams.errorDescription]);
    const setConfigurationStatus = async (status) => {
        await updateTiktokShopConfigurationStatusMutation.mutateAsync([{ status }], {
            onSuccess: () => {
                invalidateTiktokShopConfigurationQuery();
            },
        });
    };
    const getExpirationInfo = () => {
        const expiresAt = tiktokShopConfigurationQuery.data?.type === 'CONFIGURED'
            ? tiktokShopConfigurationQuery.data?.authorizationExpiresAt
            : undefined;
        const expired = tiktokShopConfigurationQuery.error?.response?.data?.code ===
            'TIKTOK_INVALID_TIKTOK_TOKEN';
        if (expiresAt) {
            return `Connection expires on ${dayjs(expiresAt).format('MMM D, YYYY')}`;
        }
        if (expired) {
            return 'Connection has expired';
        }
        return undefined;
    };
    const getConnectBar = () => {
        // unknown
        if (!creatorIntegrationsQuery.data)
            return undefined;
        const connectBarBase = {
            logoSrc,
            logoAlt: 'TikTok Shop',
            disabled: false,
        };
        const onConnect = () => {
            appConnectEvent.trigger();
            const url = CreatorIntegrationsApiClient.appAuthorizationUrl({
                app: 'tiktok-partner',
                token: keycloak?.token,
            });
            window.location.assign(url);
        };
        const onDisconnect = () => {
            open(ConfirmModal, {
                title: 'Are you sure you want to disconnect from TikTok Shop?',
                confirmLabel: 'Yes, disconnect',
                confirmAppearance: 'destructive',
                onConfirm: () => {
                    const toastId = 'deleteTiktokMutation';
                    Toasts.notify('Disconnecting from TikTok Shop...', {
                        toastId,
                        type: 'info',
                        autoClose: false,
                    });
                    deleteTiktokShopConfigurationMutation.mutate([], {
                        onSuccess: () => {
                            invalidateCreatorIntegrationsQuery();
                            Toasts.notify('Disconnected from TikTok Shop successfully.', {
                                type: 'success',
                            });
                            Toasts.dismiss(toastId);
                        },
                        onError: (error) => {
                            Toasts.dismiss(toastId);
                            if (axios.isAxiosError(error) &&
                                error.response?.data.code === 'TIKTOK_ORDERS_NOT_COMPLETED') {
                                open(DisconnectErrorModal, {
                                    isEnabled: tiktokShopConfigurationQuery.data?.type === 'CONFIGURED' &&
                                        tiktokShopConfigurationQuery.data?.status === 'ACTIVE',
                                    onDisable: () => {
                                        setConfigurationStatus('DISABLED');
                                    },
                                });
                                return;
                            }
                            Toasts.notify(getDefaultErrorMessage(error), {
                                type: 'error',
                            });
                        },
                    });
                },
            });
        };
        // not connected
        if (status === 'NOT_CONNECTED') {
            return {
                ...connectBarBase,
                description: 'Connect to your TikTok account',
                isConnecting: isPaymentMethodModalRequiredMutation.isLoading,
                onConnect,
            };
        }
        // connected
        return {
            ...connectBarBase,
            info: getExpirationInfo(),
            onDisconnect,
            onReconnect: getExpirationInfo() ? onConnect : undefined,
        };
    };
    const mapProduct = (product) => ({
        id: product.offerId,
        imageSrc: product.imageUrl,
        name: product.name,
        issues: product.issues.map((issue) => ({
            description: issue.detail,
            url: issue.documentation,
        })),
    });
    const hasSynchronizationPausedReason = (reason) => {
        return (tiktokShopConfigurationQuery.data?.type === 'CONFIGURED' &&
            tiktokShopConfigurationQuery.data.synchronizationPausedReasons.includes(reason));
    };
    const getProducts = () => {
        if (productApprovalStatus !== 'APPROVED')
            return;
        if (!tiktokShopProductsQuery.isSuccess)
            return;
        return {
            active: tiktokShopProductsQuery.data.active.map(mapProduct),
            pending: tiktokShopProductsQuery.data.pending.map(mapProduct),
            denied: tiktokShopProductsQuery.data.denied.map(mapProduct),
        };
    };
    const openPaymentMethodRequiredModal = ({ onSuccess } = {}) => {
        const subtitle = (React.createElement(React.Fragment, null,
            "For TikTok Shop orders, TikTok collects the money from the sale and sends you the profit directly. Fourthwall will deduct any costs of TikTok orders (such as manufacturing or shipping we\u2019re handling) from your account balance. If your account balance does not cover the costs we will charge the amount to your payment method.",
            ' ',
            React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.tiktokShop, target: "_blank" })));
        open(PaymentMethodRequiredModal, {
            title: 'Payment method required for TikTok Shops',
            subtitle,
            selectPaymentMethodSubtitle: subtitle,
            onSuccess: onSuccess || (() => setConfigurationStatus('ACTIVE')),
        });
    };
    const activateSales = () => {
        isPaymentMethodModalRequiredMutation.mutate([], {
            onSuccess: (data) => {
                if (data.isPaymentMethodModalRequired) {
                    openPaymentMethodRequiredModal();
                }
                else {
                    setConfigurationStatus('ACTIVE');
                }
            },
        });
    };
    const handleShopSelect = async (shopId) => {
        updateTiktokShopConfigurationMutation.mutate([
            {
                tiktokShopId: shopId,
                status: 'DISABLED',
            },
        ], {
            onSuccess: () => {
                invalidateTiktokShopConfigurationQuery();
                invalidateTiktokShopWarehousesQuery();
            },
        });
    };
    const handleConfigurationSubmit = async (values) => {
        updateTiktokShopConfigurationMutation.mutate([
            {
                tiktokShopId: values.shopId,
                warehouseId: values.warehouseId,
                status: 'DISABLED',
            },
        ], {
            onSuccess: (data) => {
                invalidateCreatorIntegrationsQuery();
                invalidateTiktokShopConfigurationQuery();
                const configurationPrerequisitesErrors = getConfigurationPrerequisitesErrors(data.type === 'CONFIGURED' ? data.prerequisites : undefined);
                if (!configurationPrerequisitesErrors.length) {
                    activateSales();
                }
            },
        });
    };
    const synchronizationErrors = {
        sellerNotActive: hasSynchronizationPausedReason('SELLER_NOT_ACTIVE'),
        shopNotActive: hasSynchronizationPausedReason('SHOP_NOT_ACTIVE'),
        warehouseNotConfigured: hasSynchronizationPausedReason('WAREHOUSE_NOT_CONFIGURED'),
        incorrectShippingOption: hasSynchronizationPausedReason('INCORRECT_SHIPPING_OPTION'),
        returnWarehouseNotConfigured: hasSynchronizationPausedReason('RETURN_WAREHOUSE_NOT_CONFIGURED') ||
            tiktokShopWarehousesQuery.error?.response?.data?.code ===
                'TIKTOK_RETURN_NOT_FW_WAREHOUSE_CONFIGURED',
        shippingTemplate: hasSynchronizationPausedReason('SHIPPING_TEMPLATES_NOT_IN_WAREHOUSE'),
    };
    const displaySuccessToast = () => {
        Toasts.notify('Settings saved', { type: 'info' });
    };
    const handleSettingsSubmit = async (values) => {
        const updateTiktokShopConfiguration = async () => {
            await updateTiktokShopConfigurationMutation.mutateAsync([
                {
                    tiktokShopId: currentShopId,
                    warehouseId: currentWarehouseId,
                    status: 'ACTIVE',
                    pricePercentageIncrease: values.priceIncreaseEnabled
                        ? Number(values.priceIncrease) / 100
                        : 0,
                },
            ], {
                onSuccess: () => {
                    invalidateTiktokShopConfigurationQuery();
                    displaySuccessToast();
                },
            });
        };
        try {
            if (!values.salesEnabled || !currentShopId || !currentWarehouseId) {
                await setConfigurationStatus('DISABLED');
                displaySuccessToast();
                return;
            }
            const { isPaymentMethodModalRequired } = await isPaymentMethodModalRequiredMutation.mutateAsync([]);
            if (isPaymentMethodModalRequired) {
                openPaymentMethodRequiredModal({
                    onSuccess: updateTiktokShopConfiguration,
                });
            }
            else {
                await updateTiktokShopConfiguration();
            }
        }
        catch (error) {
            Toasts.notify('Failed to save settings. Please try again.');
        }
    };
    return {
        tiktokShop: {
            isLoading: creatorIntegrationsQuery.isLoading,
            status: creatorIntegrationsQuery.data?.tiktokPartner?.status,
            configurationStatus,
            connectBar: getConnectBar(),
            paymentError: tiktokShopConfigurationQuery.data?.type === 'CONFIGURED' &&
                tiktokShopConfigurationQuery.data?.status === 'BLOCKED',
            configurationPrerequisitesErrors,
            hasActivePaymentMethod,
            configurationForm: {
                initialValues: {
                    shopId: currentShopId || '',
                    warehouseId: currentWarehouseId || '',
                },
                shops: tiktokShopsQuery.data?.data.shop_list.map((shop) => ({
                    value: shop.shop_id,
                    label: shop.shop_name,
                })) || [],
                warehouses: tiktokShopWarehousesQuery.data?.data.warehouse_list.map((warehouse) => ({
                    value: warehouse.warehouse_id,
                    label: warehouse.warehouse_name,
                })) || [],
                isLoading: tiktokShopConfigurationQuery.isLoading ||
                    tiktokShopWarehousesQuery.isInitialLoading ||
                    tiktokShopsQuery.isInitialLoading ||
                    updateTiktokShopConfigurationMutation.isLoading ||
                    updateTiktokShopConfigurationStatusMutation.isLoading,
                onShopSelect: handleShopSelect,
                onSubmit: handleConfigurationSubmit,
            },
            synchronizationErrors,
            settingsForm: {
                initialValues: {
                    salesEnabled: tiktokShopConfigurationQuery.data?.type === 'CONFIGURED' &&
                        tiktokShopConfigurationQuery.data?.status === 'ACTIVE',
                    priceIncreaseEnabled: pricePercentageIncrease ? pricePercentageIncrease > 0 : false,
                    priceIncrease: pricePercentageIncrease
                        ? Math.round(pricePercentageIncrease * 100)
                        : DEFAULT_PRICE_PERCENTAGE_INCREASE,
                },
                syncProducts: {
                    products: getProducts(),
                    warnings: {
                        synchronizationLimitReached: hasSynchronizationPausedReason('PRODUCT_CREATION_LIMIT_REACHED'),
                    },
                },
                isLoading: isPaymentMethodModalRequiredMutation.isLoading ||
                    updateTiktokShopConfigurationMutation.isLoading ||
                    updateTiktokShopConfigurationStatusMutation.isLoading ||
                    tiktokShopConfigurationQuery.isLoading,
                isDisabled: tiktokShopConfigurationQuery.data?.type === 'CONFIGURED' &&
                    (tiktokShopConfigurationQuery.data?.status === 'BLOCKED' ||
                        (configurationPrerequisitesErrors.length > 0 &&
                            tiktokShopConfigurationQuery.data?.status !== 'ACTIVE')),
                onSubmit: handleSettingsSubmit,
            },
            onPaymentMethodUpdate: () => openPaymentMethodRequiredModal(),
        },
    };
};
