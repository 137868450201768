import React from 'react';
import { isPromotionMultiple, isShopPromotion } from '../../../../utils';
export const PromotionUsageTableCell = ({ promotion }) => {
    const renderLimit = () => {
        if (!isShopPromotion(promotion)) {
            return null;
        }
        if (!promotion.limits.maximumUsesNumber) {
            return null;
        }
        const limit = isPromotionMultiple(promotion)
            ? promotion.limits.maximumUsesNumber * promotion.codes.length
            : promotion.limits.maximumUsesNumber;
        return React.createElement(React.Fragment, null,
            "/",
            limit);
    };
    return (React.createElement(React.Fragment, null,
        promotion.usageCount,
        renderLimit()));
};
