import { Editor } from '@tinymce/tinymce-react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Colors } from '../../colors';
import { withFormikSupport } from '../../hocs/withFormikSupport';
import { InputUI } from '../../ui';
import { VALID_ELEMENTS, VALID_STYLES } from './constants';
import * as S from './styled';
import { getToolbar, mapValidElementsToString, mapValidStyles, setupInserter } from './utils';
const toolbarDefaults = {
    alignment: false,
    blocks: false,
    code: false,
    fullscreen: false,
    table: false,
    image: false,
    customImage: false,
};
// eslint-disable-next-line camelcase
const content_style = `
  html {
    min-height: 100%;

    cursor: text;
  }

  .mce-content-body {
    margin: 16px;

    font-family: "Suisse Int'l", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 150%;
    color: ${Colors.GS1000};
  }

  .mce-content-body,
  .mce-content-body > *:first-child {
    margin-top: 8px;
  }

  .mce-content-body a {
    color: ${Colors.B500};
  }

  .mce-content-body [data-mce-selected=inline-boundary] {
    background: ${Colors.B200};
  }

  .mce-content-body img {
    display: block;
    margin: 1em auto;
    max-width: 100%;
    height: auto;
  }
`;
export const TinyMCE = withFormikSupport(({ tinymceScriptSrc, placeholder, resize = false, statusbar = false, buttons, inserterOptions, height = 160, name = '', initialValue, value, disabled = false, invalid = false, error, submitCount, imagesUploadHandler, onChange, onValueChange, onSetup, onBlur, }) => {
    const [focused, setFocused] = useState(false);
    const touchedRef = useRef(false);
    const init = useMemo(() => ({
        plugins: 'lists link table code fullscreen autoresize',
        menubar: false,
        toolbar: getToolbar({ ...toolbarDefaults, ...buttons }),
        toolbar_mode: 'wrap',
        contextmenu: false,
        elementpath: false,
        max_height: 800,
        min_height: 160,
        branding: false,
        resize,
        statusbar: statusbar || resize,
        setup: (editor) => {
            setupInserter(editor, inserterOptions);
            // FIX for https://popshop.atlassian.net/browse/PRD-2381
            // solution: https://stackoverflow.com/a/67166716/7518346
            editor.on('paste cut', () => {
                editor.setDirty(true); // this updates the editor.undoManager
            });
            onSetup?.(editor);
        },
        placeholder,
        width: '100%',
        height,
        // eslint-disable-next-line camelcase
        content_style,
        valid_styles: mapValidStyles(VALID_STYLES),
        valid_elements: mapValidElementsToString(VALID_ELEMENTS),
        relative_urls: false,
        images_upload_handler: imagesUploadHandler,
        image_dimensions: false,
        paste_data_images: false,
        object_resizing: false,
        remove_script_host: false,
    }), [buttons, height, placeholder, inserterOptions, onSetup, resize, statusbar]);
    const handleEditorChange = useCallback((value) => {
        if (!touchedRef.current)
            return;
        if (onChange && name)
            onChange({ target: { name, value } });
        if (onValueChange)
            onValueChange(value);
    }, [onChange, name, onValueChange]);
    const handleFocus = useCallback(() => {
        if (!touchedRef.current)
            touchedRef.current = true;
        setFocused(true);
    }, []);
    const handleBlur = useCallback((event, editor) => {
        setFocused(false);
        const value = editor.getContent({ format: 'html' });
        if (onBlur)
            onBlur({ target: { name, value } });
    }, [onBlur, name]);
    const editor = useMemo(() => {
        return (React.createElement(Editor, { tinymceScriptSrc: tinymceScriptSrc, init: init, initialValue: initialValue, value: value, disabled: disabled, onEditorChange: handleEditorChange, onFocus: handleFocus, onBlur: handleBlur }));
    }, [
        tinymceScriptSrc,
        init,
        initialValue,
        value,
        disabled,
        handleEditorChange,
        handleFocus,
        handleBlur,
    ]);
    return (React.createElement(S.Container, { "data-testid": "TinyMCE" },
        React.createElement(InputUI.Box, { fullHeight: true, disabled: disabled, focused: focused, invalid: invalid }, editor),
        !!error && React.createElement(InputUI.Error, { errorTimestampKey: submitCount }, error)));
});
