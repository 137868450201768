import { useMediaQueryContext } from '@fourthwall/components';
import { isMobile } from '@fourthwall/utils/lib/mobile';
import fixWebmDuration from 'fix-webm-duration';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MOBILE_FOOTER_HEIGHT } from '../const';
import { useMimeType } from './useMimeType';
const isPortraitMode = () => isMobile() ? window.matchMedia('(orientation: portrait)').matches : false;
export const useMediaRecorder = ({ type, active }) => {
    const mediaQuery = useMediaQueryContext();
    const [previewMediaRecorder, setPreviewMediaRecorder] = useState();
    const [mediaRecorder, setMediaRecorder] = useState();
    const [mediaStream, setMediaStream] = useState();
    const [state, setState] = useState('LOADING');
    const streamChunks = useRef([]);
    const [recordedMedia, setRecordedMedia] = useState();
    const mediaDuration = useRef(0);
    const [audioSources, setAudioSources] = useState([]);
    const [videoSources, setVideoSources] = useState([]);
    const [selectedAudioSource, setSelectedAudioSource] = useState();
    const [selectedVideoSource, setSelectedVideoSource] = useState();
    const [facingMode, setFacingMode] = useState('user');
    const videoAspectRatio = useMemo(() => mediaQuery.laptop ? 16 / 9 : window.innerWidth / (window.innerHeight - MOBILE_FOOTER_HEIGHT), [mediaQuery]);
    const [isPortrait, setIsPortrait] = useState(isPortraitMode());
    const mimeType = useMimeType(type);
    const updateIsPortrait = () => setIsPortrait(isPortraitMode());
    useEffect(() => {
        window.addEventListener('orientationchange', updateIsPortrait);
        return () => {
            window.removeEventListener('orientationchange', updateIsPortrait);
        };
    }, []);
    const videoConstraints = useMemo(() => {
        if (mediaQuery.laptop) {
            return {
                aspectRatio: videoAspectRatio,
                facingMode: !selectedVideoSource ? 'user' : undefined,
                deviceId: selectedVideoSource ? { exact: selectedVideoSource } : undefined,
            };
        }
        const width = Math.ceil(window.innerWidth / 2) * 2;
        const height = Math.ceil((window.innerHeight - MOBILE_FOOTER_HEIGHT) / 2) * 2;
        return {
            facingMode,
            width: { exact: isPortrait ? height : width },
            height: { exact: isPortrait ? width : height },
        };
    }, [facingMode, isPortrait, mediaQuery.laptop, selectedVideoSource, videoAspectRatio]);
    const userMediaConfig = useMemo(() => {
        const audioConstraints = selectedAudioSource
            ? { deviceId: { exact: selectedAudioSource } }
            : true;
        return {
            audio: audioConstraints,
            video: type === 'AUDIO' ? false : videoConstraints,
        };
    }, [selectedAudioSource, type, videoConstraints]);
    const mediaSources = useMemo(() => {
        const audio = audioSources.map((source) => ({
            ...source,
            active: selectedAudioSource === source.id,
        }));
        const video = videoSources.map((source) => ({
            ...source,
            active: selectedVideoSource === source.id,
        }));
        if (type === 'AUDIO') {
            return {
                audio,
                video: [],
            };
        }
        // VIDEO
        return {
            audio,
            video,
        };
    }, [audioSources, videoSources, type, selectedVideoSource, selectedAudioSource]);
    const createUserMediaRecorder = async () => {
        if (state === 'RECORDING')
            return;
        mediaStream?.getTracks().forEach((track) => track.stop());
        try {
            const userMedia = await navigator?.mediaDevices?.getUserMedia(userMediaConfig);
            setMediaStream(userMedia);
        }
        catch (error) {
            setState('ERROR');
        }
    };
    const createUserMediaSources = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter((device) => device.kind === 'audioinput');
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
        setAudioSources(audioDevices.map((device) => ({ id: device.deviceId, label: device.label })));
        setVideoSources(videoDevices.map((device) => ({ id: device.deviceId, label: device.label })));
    };
    useEffect(() => {
        createUserMediaSources();
    }, []);
    useEffect(() => {
        createUserMediaRecorder();
    }, [userMediaConfig]);
    useEffect(() => {
        if (!mediaStream)
            return;
        mediaStream.getTracks().forEach((track) => {
            if (track.kind === 'video') {
                setSelectedVideoSource(track.getSettings().deviceId);
            }
            if (track.kind === 'audio') {
                setSelectedAudioSource(track.getSettings().deviceId);
            }
        });
        setPreviewMediaRecorder(new MediaRecorder(mediaStream, { mimeType }));
        const newMediaRecorder = new MediaRecorder(mediaStream, { mimeType });
        newMediaRecorder.ondataavailable = (event) => {
            if (!event.data?.size)
                return;
            streamChunks.current = [...streamChunks.current, event.data];
        };
        newMediaRecorder.onstop = () => {
            const type = newMediaRecorder.mimeType.split(';')[0];
            const recorderMediaBlob = new Blob(streamChunks.current, { type });
            fixWebmDuration(recorderMediaBlob, mediaDuration.current).then((fixedBlob) => {
                setRecordedMedia(fixedBlob);
                streamChunks.current = [];
                setState('INITIALIZED');
            });
        };
        setMediaRecorder(newMediaRecorder);
        setState('INITIALIZED');
        return () => {
            newMediaRecorder.ondataavailable = null;
            newMediaRecorder.onstop = null;
            mediaStream?.getTracks().forEach((track) => track.stop());
        };
    }, [mediaStream]);
    useEffect(() => {
        if (active && previewMediaRecorder?.state !== 'recording') {
            previewMediaRecorder?.start();
        }
        if (!active && previewMediaRecorder?.state === 'recording') {
            previewMediaRecorder?.stop();
        }
    }, [active, previewMediaRecorder]);
    const onRecordStart = () => {
        if (!mediaRecorder)
            return;
        mediaDuration.current = 0;
        streamChunks.current = [];
        setRecordedMedia(undefined);
        mediaRecorder.start();
        setState('RECORDING');
    };
    const onRecordStop = (duration) => {
        mediaDuration.current = duration;
        mediaRecorder?.stop();
    };
    const onRecordDelete = () => {
        mediaDuration.current = 0;
        setRecordedMedia(undefined);
    };
    const onSwitchFacingMode = () => {
        setFacingMode(facingMode === 'user' ? 'environment' : 'user');
    };
    return {
        state,
        mediaStream,
        mediaSources,
        recordedMedia,
        reinitialize: createUserMediaRecorder,
        onRecordStart,
        onRecordStop,
        onRecordDelete,
        onSwitchFacingMode,
        onAudioSourceChange: setSelectedAudioSource,
        onVideoSourceChange: setSelectedVideoSource,
    };
};
