import React from 'react';
// @ts-ignore
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
// @ts-ignore
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import * as S from './TextBlockEllipsis.styled';
// NOTE: Using HTML truncation to support rich text
const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);
export const TextBlockEllipsis = ({ text, maxLine = 2, component = 'div' }) => (React.createElement(React.Fragment, null,
    React.createElement(S.Global, null),
    React.createElement(ResponsiveEllipsis, { unsafeHTML: text, maxLine: maxLine, component: component, className: "TextBlockEllipsis", 
        // NOTE: https://github.com/xiaody/react-lines-ellipsis/issues/59
        style: { whiteSpace: 'pre-wrap' } })));
