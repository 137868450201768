import { AlertModal, Banner, Button, ButtonClean, ConfirmModal, Fieldset, Icon, InputUI, } from '@fourthwall/components';
import { DiscordIcon } from '@fourthwall/icons';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import reduce from 'lodash-es/reduce';
import React, { useState } from 'react';
import { useDiscord } from '@modules/Dashboard/Apps/modules/AppsDiscord';
import * as S from './styled';
export const DiscordIntegrationFields = () => {
    const [isDisconnectModalVisible, setIsDisconnectModalVisible] = useState(false);
    const { discord } = useDiscord();
    const { values } = useFormikContext();
    const renderInfo = () => {
        const selectedRolesIds = reduce(values.selectedRoles, (sum, roles) => {
            return [...sum, ...roles];
        }, []);
        const hasSelectedUnavailableRole = discord.roles?.some((role) => role.available === false && selectedRolesIds.indexOf(role.id) > -1);
        if (!hasSelectedUnavailableRole)
            return null;
        return (React.createElement(Fieldset.Row, null,
            React.createElement(Banner, { appearance: "warning", title: "Selected roles are placed above the Fourthwall bot role in your Discord server. Please visit Discord and move the Fourthwall role above all the selected roles." })));
    };
    const renderButton = () => {
        if (discord.status === 'CONNECTED') {
            return (React.createElement(S.Container, null,
                React.createElement(Icon, { component: DiscordIcon, height: 20 }),
                " Connected to Discord",
                ' ',
                React.createElement(ButtonClean, { label: "Disconnect", disabled: discord.isLoading, onClick: () => setIsDisconnectModalVisible(true) })));
        }
        return (React.createElement(Button, { label: "Connect to Discord", type: "button", leftIcon: React.createElement(Icon, { component: DiscordIcon, height: 14 }), appearance: "primary", disabled: discord.isLoading, onClick: discord.handleConnect }));
    };
    const renderDiscordModal = () => {
        if (values.mode === 'edit') {
            return (React.createElement(AlertModal, { title: "Please remove Discord perk first", text: "Please remove the Discord perk first before removing the server connection. You can then remove the Discord integration from the Settings / Apps tab.", size: "small", close: () => setIsDisconnectModalVisible(false), onConfirm: () => setIsDisconnectModalVisible(false) }));
        }
        return (React.createElement(ConfirmModal, { title: "Are you sure you want to disconnect from Discord?", confirmLabel: "Yes, disconnect", confirmAppearance: "destructive", close: () => setIsDisconnectModalVisible(false), onConfirm: discord.handleDisconnect }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null, renderButton())),
        React.createElement("div", { style: { marginTop: -4 } },
            React.createElement(InputUI.Suggestion, null, "In order to use Discord as a membership perk you\u2019ll have to create roles in your Discord server settings."),
            renderInfo()),
        isDisconnectModalVisible && renderDiscordModal()));
};
