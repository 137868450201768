import { Loading, ModalBox, useModalContext } from '@fourthwall/components';
import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './TilesModal.styled';
const getComponent = ({ to, href }) => {
    if (to) {
        return Link;
    }
    if (href) {
        return 'a';
    }
    return 'button';
};
const ITEM_DEFAULT_WIDTH = 288;
export const TilesModal = ({ items, isLoading, ...props }) => {
    const { close } = useModalContext();
    return (React.createElement(ModalBox, { maxWidth: items.length * ITEM_DEFAULT_WIDTH, withoutPadding: true, onClose: close, ...props },
        React.createElement(Loading, { isLoading: isLoading },
            React.createElement(S.Container, null, items.map(({ to, href, image, imageAlt, heading, subheading, onClick }) => (React.createElement(S.Item, { as: getComponent({ to, href }), to: to, onClick: onClick || close },
                React.createElement(S.ItemImageContainer, null,
                    React.createElement(S.Image, { alt: imageAlt, src: image })),
                React.createElement(S.Content, null,
                    React.createElement(S.Heading, { type: "h7", weight: "semibold" }, heading),
                    subheading && React.createElement(S.Subheading, null, subheading)))))))));
};
