import { pluralize } from '@fourthwall/utils/lib/string';
import React from 'react';
import { SideSummary } from '../../../../../../../components/common/SideSummary';
export const PostAnalytics = ({ commentsCount, likesCount }) => (React.createElement(SideSummary.Item, { title: "Analytics" },
    React.createElement("div", null,
        likesCount,
        " ",
        pluralize(likesCount, 'like')),
    React.createElement("div", null,
        commentsCount,
        " ",
        pluralize(commentsCount, 'comment'))));
