import { getCommonPerkPayload } from './getCommonPerkPayload';
export const getCreateDiscordPerkPayload = (formValues) => {
    const { selectedRoles } = formValues;
    const commonPayload = getCommonPerkPayload(formValues);
    const tiers = commonPayload.tiers
        .map((tier) => {
        if (selectedRoles[tier.id].length) {
            return {
                id: tier.id,
                roles: selectedRoles[tier.id].map((roleId) => ({
                    id: roleId,
                })),
            };
        }
        return undefined;
    })
        .filter((item) => !!item);
    return {
        ...commonPayload,
        tiers,
    };
};
