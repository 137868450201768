import { css } from 'styled-components';
export const hideScrollbar = css `
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
export const userSelect = (value) => `
  -webkit-user-select: ${value};
  -ms-user-select: ${value};
  user-select: ${value};
`;
export const styledUtils = {
    hideScrollbar,
    userSelect,
};
