import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const SocialLinkHoverContent = styled.div `
  display: flex;
  visibility: hidden;
  margin-top: -4px;
  margin-right: -2px;
  padding-left: 8px;
`;
export const SocialLinkWrapper = styled.div `
  padding-bottom: 16px;

  &:hover {
    ${SocialLinkHoverContent} {
      visibility: visible;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
export const SocialLinkIcon = styled.div `
  cursor: pointer;
  color: ${Colors.GS600};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${Colors.GS300};
    color: ${Colors.GS1000};
  }
`;
export const DragHandle = styled(SocialLinkIcon) `
  cursor: grab;
`;
