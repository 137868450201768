import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AddButton, Dropdown, Icon, PriceString, Tag } from '@fourthwall/components';
import { CogIcon, DragIcon } from '@fourthwall/icons';
import { pluralize } from '@fourthwall/utils/lib/string';
import React from 'react';
import { usePerksContext } from '../../hooks/usePerksContext';
import { useTiersContext } from '../../hooks/useTiersContext';
import { isPaidTier } from '../../utils';
import { Perks } from '../Perks';
import * as S from './styled';
export const Tier = ({ tier }) => {
    const { editTier, editFreeTier, deleteFreeTier, deleteTier, publishTier, unpublishTier } = useTiersContext();
    const { addPerk } = usePerksContext();
    const { isDragging, setNodeRef, transform, attributes, listeners } = useSortable({
        id: tier.id,
    });
    const style = {
        transform: CSS.Translate.toString(transform),
        zIndex: isDragging ? 999 : undefined,
    };
    const renderStatus = () => {
        if (tier.status === 'Unpublished') {
            return React.createElement(Tag, { appearance: "neutral", label: "Unpublished" });
        }
        if (tier.status === 'Published') {
            return React.createElement(Tag, { appearance: "success", label: "Published" });
        }
    };
    const renderPrice = () => {
        if (isPaidTier(tier)) {
            return React.createElement(PriceString, { value: tier.variants.monthly.amount / 100 });
        }
        return 'Free';
    };
    return (React.createElement("div", { ref: setNodeRef, style: style },
        React.createElement(S.Container, { "data-testid": `Tier.container_${tier.name}` },
            React.createElement(S.TopContainer, null,
                renderStatus(),
                React.createElement(S.Options, { "data-testid": "Tier.options" },
                    React.createElement(Dropdown, { content: React.createElement(React.Fragment, null,
                            React.createElement(Dropdown.Item, { label: "Edit tier", onClick: () => {
                                    isPaidTier(tier) ? editTier(tier) : editFreeTier(tier);
                                } }),
                            React.createElement(Dropdown.Item, { label: "Delete tier", onClick: () => {
                                    isPaidTier(tier) ? deleteTier(tier) : deleteFreeTier();
                                } }),
                            isPaidTier(tier) && (React.createElement(Dropdown.Item, { label: tier.status === 'Unpublished' ? 'Publish tier' : 'Unpublish tier', onClick: () => {
                                    tier.status === 'Unpublished' ? publishTier(tier) : unpublishTier(tier);
                                } }))) },
                        React.createElement(S.Option, { type: "button", "data-testid": "Tier.OptionsIcon" },
                            React.createElement(Icon, { component: CogIcon, height: 12 }))),
                    React.createElement(S.Option, { type: "button", "data-testid": "Tier.DragIcon", ...listeners, ...attributes },
                        React.createElement(Icon, { component: DragIcon, height: 12 })))),
            React.createElement(S.InfoContainer, null,
                tier.image?.url ? React.createElement(S.Image, { src: tier.image.url }) : null,
                React.createElement(S.Name, { "data-testid": "Tier.name" }, tier.name),
                React.createElement(S.Price, { "data-testid": "Tier.price" }, renderPrice()),
                tier.description ? React.createElement(S.Description, null, tier.description) : null,
                React.createElement(S.MembersCount, { "data-testid": "Tier.members" },
                    tier.statistics.membersCount,
                    " ",
                    pluralize(tier.statistics.membersCount, 'member'))),
            React.createElement(S.PerksContainer, { "data-testid": "Tier.perksContainer" },
                React.createElement(Perks, null),
                addPerk && (React.createElement(S.AddPerkWrapper, null,
                    React.createElement(AddButton, { label: "Add perk", onClick: () => addPerk(tier.id) })))))));
};
