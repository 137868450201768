import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { preloadImage } from '@fourthwall/utils/lib/preloadImage';
import React, { useEffect, useMemo } from 'react';
import { useCurrentShopContext } from '../../hooks/useCurrentShopContext';
import { useActiveThemeContext } from '../../providers/ActiveThemeProvider';
import { InstalledThemesView } from './InstalledThemesView';
import { useActivateThemeMutation } from './hooks/useActivateThemeMutation';
import { useInstallThemeMutation } from './hooks/useInstallThemeMutation';
import { useResetThemeMutation } from './hooks/useResetThemeMutation';
import { useThemeList } from './hooks/useThemeList';
import { ThemeChangelogModal } from './modules/ThemeChangelogModal/ThemeChangelogModal';
import { ThemeDeleteModal } from './modules/ThemeDeleteModal/ThemeDeleteModal';
import { ThemeDuplicateModal } from './modules/ThemeDuplicateModal/ThemeDuplicateModal';
import { ThemeModal } from './modules/ThemeModal';
import { ThemeRenameModal } from './modules/ThemeRenameModal/ThemeRenameModal';
import { getPresetImages } from './utils';
export const InstalledThemesContainer = () => {
    const { currentShop } = useCurrentShopContext();
    const { open } = useModalContext();
    const { activateThemeMutation } = useActivateThemeMutation();
    const { installThemeMutation } = useInstallThemeMutation();
    const { resetThemeMutation } = useResetThemeMutation();
    const { setActiveTheme } = useActiveThemeContext();
    const { themeList, libraryThemes, currentTheme, selectedTheme, isLoadingThemeList, refetchThemeList, setThemeIsProcessing, setSelectedThemeId, } = useThemeList();
    const presetImages = useMemo(() => getPresetImages(libraryThemes), [libraryThemes]);
    const preloadImages = async () => {
        try {
            await Promise.all(presetImages.map(preloadImage));
        }
        catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        preloadImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetImages]);
    const handleActivateSelectedTheme = ({ id }) => {
        setSelectedThemeId(id);
        if (currentTheme) {
            activateThemeMutation.mutate([
                {
                    shop_id: currentShop.id,
                    theme_id: id,
                },
                {
                    theme: {
                        role: 'main',
                    },
                },
            ], {
                onSuccess: (data) => {
                    Toasts.notify('Selected theme has been activated', { type: 'info' });
                    setActiveTheme({
                        id: data.theme.id,
                        name: 'Main',
                    });
                    refetchThemeList();
                },
            });
        }
    };
    const handleThemeInstall = ({ id, presetHandle, }) => {
        if (installThemeMutation.isLoading)
            return;
        Toasts.notify('Installation in progress. Please wait for a few seconds.', { type: 'info' });
        installThemeMutation.mutate([
            { shop_id: currentShop.id },
            {
                presetHandle: presetHandle || '',
                themeLibraryItemVersionId: id,
                shopThemeRole: 'unpublished',
            },
        ], {
            onSuccess: () => {
                refetchThemeList();
            },
        });
    };
    const handleThemeReset = ({ id, handle }) => {
        open(ConfirmModal, {
            title: 'Reset theme to default settings',
            text: 'This will remove all of your customization on this theme. Are you sure you want to continue? This action cannot be reversed.',
            confirmLabel: 'Reset to default settings',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                setThemeIsProcessing(handle);
                resetThemeMutation.mutate([
                    {
                        shop_id: currentShop.id,
                        shop_theme_id: id,
                    },
                ], {
                    onSuccess: () => {
                        refetchThemeList();
                    },
                });
            },
        });
    };
    const handleChangelogDisplay = ({ version, handle, }) => {
        open(ThemeChangelogModal, {
            version,
            handle,
        });
    };
    const handleThemeDuplicate = ({ id, name }) => {
        open(ThemeDuplicateModal, {
            id,
            name,
            onSuccess: refetchThemeList,
        });
    };
    const handleThemeRename = ({ id, name, role }) => {
        open(ThemeRenameModal, {
            id,
            name,
            role,
            excludedNames: themeList.map(({ name }) => name).filter((themeName) => themeName !== name),
            onSuccess: refetchThemeList,
        });
    };
    const handleThemeDelete = ({ id, name }) => {
        open(ThemeDeleteModal, {
            id,
            name,
            onSuccess: refetchThemeList,
        });
    };
    const handleAddThemeClick = () => open(ThemeModal, {
        title: 'Add new theme',
        onAddTheme: handleThemeInstall,
    });
    return (React.createElement(InstalledThemesView, { themeList: themeList, isLoadingThemeList: isLoadingThemeList, selectedTheme: selectedTheme, leavingGuardProps: {
            when: !!selectedTheme && !!currentTheme && selectedTheme?.id !== currentTheme?.id,
        }, onAddThemeClick: handleAddThemeClick, onThemeClick: (theme) => setSelectedThemeId(theme.id), onThemeActivate: handleActivateSelectedTheme, onThemeDuplicate: handleThemeDuplicate, onThemeRename: handleThemeRename, onThemeReset: handleThemeReset, onThemeDelete: handleThemeDelete, onChangelogDisplay: handleChangelogDisplay }));
};
