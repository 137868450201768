import React from 'react';
import * as S from './styled';
import { PriceString } from '../../functional/PriceString';
export const PriceWithCurrency = (props) => {
    if (!props.currency)
        return React.createElement(PriceString, { ...props });
    return (React.createElement(React.Fragment, null,
        React.createElement(PriceString, { ...props }),
        React.createElement(S.Currency, null,
            "\u00A0",
            props.currency)));
};
