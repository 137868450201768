import React from 'react';
import { Button, ButtonClean, useMediaQueryContext, useModalContext } from '@fourthwall/components';
import { CreateProductModal } from '@modules/Dashboard/Products';
import { routing } from '@utils/routing';
import { useFavoritesCountQuery } from './queries/useFavoritesCountQuery';
import * as S from './CreateProductRecommendationContent.styled';
export const CreateProductRecommendationContent = () => {
    const { open } = useModalContext();
    const media = useMediaQueryContext();
    const { favoritesCountQuery } = useFavoritesCountQuery();
    const count = favoritesCountQuery.data?.count || 0;
    return (React.createElement(S.Container, null,
        React.createElement(Button, { label: "Create a new product", fullWidth: !media.tablet, onClick: () => open(CreateProductModal) }),
        count > 0 && (React.createElement(ButtonClean, { appearance: "secondary", label: `View favorites (${count})`, href: routing.productCatalog.favorites }))));
};
