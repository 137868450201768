import React from 'react';
import { Link } from 'react-router-dom';
import { useDropdown } from '../DropdownContext';
import * as S from './styled';
const getComponent = ({ to, href, disabled, }) => {
    if (!disabled && to) {
        return Link;
    }
    if (!disabled && href) {
        return 'a';
    }
    return 'div';
};
export const DropdownItem = ({ active = false, cursor = 'pointer', disabled = false, to, href, target, label, appearance = 'default', size = 'medium', analytics, onClick, testid, }) => {
    const { toggle } = useDropdown();
    const handleClick = (event) => {
        if (disabled)
            return;
        onClick?.(event);
        toggle();
    };
    return (React.createElement(S.Option, { as: getComponent({ to, href, disabled }), to: to, href: href, target: target, active: active, cursor: cursor, disabled: disabled, appearance: appearance, size: size, "data-analytics": analytics, "data-testid": testid, role: "menuitem", onClick: handleClick }, label));
};
