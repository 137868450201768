import SparkMD5 from 'spark-md5';
export const generateFileChecksum = (file) => {
    return new Promise((resolve, reject) => {
        const blobSlice = File.prototype.slice;
        const chunkSize = 2097152; // 2 MB
        const chunksCount = Math.ceil(file.size / chunkSize);
        const spark = new SparkMD5.ArrayBuffer();
        let currentChunk = 0;
        const onFileReaderLoad = (e) => {
            spark.append(e.target.result);
            currentChunk += 1;
            if (currentChunk < chunksCount) {
                loadChunk();
            }
            else {
                resolve(spark.end());
            }
        };
        const loadChunk = () => {
            const fileReader = new FileReader();
            const start = currentChunk * chunkSize;
            const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
            fileReader.onload = onFileReaderLoad;
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        };
        loadChunk();
    });
};
