import { Button, ButtonClean, Fieldset, HeadContent, Input, Subsection, Tag, } from '@fourthwall/components';
import { Field, Formik, Form } from 'formik';
import React from 'react';
import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { routing } from '@utils/routing';
import { ConnectValidationSchema } from './consts';
import { useLaylo } from './hooks/useLaylo';
import { mapStatusToTagProps } from '../../utils';
export const AppsLaylo = () => {
    const { laylo } = useLaylo();
    const renderStatusTag = () => {
        const props = mapStatusToTagProps(laylo.status);
        if (!props)
            return null;
        return (React.createElement("div", { style: { marginBottom: 20 } },
            React.createElement(Tag, { ...props, size: "large", display: "inline-flex" })));
    };
    const renderForm = () => {
        if (laylo.isLoading || laylo.status !== 'NOT_CONNECTED') {
            return null;
        }
        return (React.createElement(Subsection, { title: "Connection" },
            React.createElement(Formik, { initialValues: laylo.connection.initialValues, validationSchema: ConnectValidationSchema, onSubmit: laylo.connection.onSubmit }, ({ isSubmitting }) => (React.createElement(Form, null,
                React.createElement("div", { style: { marginBottom: 16 } },
                    "Connect your Laylo account using your API key from the",
                    ' ',
                    React.createElement(ButtonClean, { label: "Laylo API Docs", href: routing.external.laylo.apiDocs, target: "_blank" }),
                    "."),
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Field, { component: Input, name: "apiKey", label: "API key" })))),
                React.createElement("div", { style: { marginTop: 16 } },
                    React.createElement(Button, { type: "submit", label: "Connect to Laylo", appearance: "primary", loading: isSubmitting })))))));
    };
    const renderConnectedContent = () => {
        if (laylo.isLoading || laylo.status === 'NOT_CONNECTED') {
            return null;
        }
        return (React.createElement(Subsection, { title: "Connection" },
            React.createElement("div", { style: { marginBottom: 16 } },
                React.createElement("p", { style: { marginBottom: 16 } },
                    "Connected to Laylo account.",
                    ' ',
                    React.createElement(ButtonClean, { label: "Disconnect", onClick: laylo.onDisconnect })))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Laylo" }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: "Laylo", topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }) },
                React.createElement(Subsection, null,
                    renderStatusTag(),
                    React.createElement("div", null, "Use Laylo to announce and promote your drops directly to your supporters.")),
                renderForm(),
                renderConnectedContent()))));
};
