import React from 'react';
import mapValues from 'lodash-es/mapValues';
import isObject from 'lodash-es/isObject';
export const FeatureFlagsContext = React.createContext(undefined);
export const FeatureFlagsProvider = ({ featureFlags, children }) => {
    const contextFeatureFlags = mapValues(featureFlags, (flag) => {
        if (isObject(flag))
            return flag;
        return { enabled: flag, highlight: false };
    });
    return (React.createElement(FeatureFlagsContext.Provider, { value: contextFeatureFlags }, children));
};
