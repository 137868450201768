import { Toasts } from '@fourthwall/components';
import { useState } from 'react';
import { useCheckMediaCreatedMutation } from '../../../hooks/useCheckMediaCreatedMutation';
import { useImageConfirmMutation } from '../../../hooks/useImageConfirmMutation';
import { useUploadImageMutation } from '../../../hooks/useUploadImageMutation';
import { uploadMedia } from '../../../utils/uploadMedia';
import { useCreateSubtitlesResource } from './useCreateSubtitlesResource';
import { useCreateVideoPostMutation } from './useCreateVideoPostMutation';
export const useCreateVideoPost = () => {
    const [isLoading, setLoading] = useState(false);
    const [mediaUploadProgress, setMediaUploadProgress] = useState(0);
    const { createVideoPostMutation } = useCreateVideoPostMutation();
    const { checkMediaCreatedMutation } = useCheckMediaCreatedMutation();
    const { uploadImageMutation } = useUploadImageMutation();
    const { imageConfirmMutation } = useImageConfirmMutation();
    const { createSubtitlesResource } = useCreateSubtitlesResource();
    const getSubtitlesResourceIdPayload = async (subtitles) => {
        if (typeof subtitles === 'object') {
            const id = await createSubtitlesResource(subtitles, () => {
                setLoading(false);
                throw new Error('Memberships: create subtitle error');
            });
            return id;
        }
        return undefined;
    };
    const createVideoPost = async (file, thumbnail, subtitles, payload, handleSuccess) => {
        setLoading(true);
        const subtitlesResourceId = await getSubtitlesResourceIdPayload(subtitles);
        const { postId, videoDirectUploadData, imageDirectUploadData } = await createVideoPostMutation.mutateAsync({ ...payload, subtitlesResourceId }, {
            onError: () => setLoading(false),
        });
        if (imageDirectUploadData) {
            await uploadImageMutation.mutateAsync({ file: thumbnail, ...imageDirectUploadData }, {
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: create video post upload image error');
                },
            });
            await imageConfirmMutation.mutateAsync({ id: postId, signedId: imageDirectUploadData.signedId }, {
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: create video post confirm image error');
                },
            });
        }
        if (videoDirectUploadData) {
            await uploadMedia(videoDirectUploadData.uploadUrl, file, (progress) => {
                setMediaUploadProgress(Number(progress.toFixed()));
            }).catch(() => {
                Toasts.notify('Media upload error. Please try again.', { type: 'error' });
            });
            checkMediaCreatedMutation.mutate(postId, {
                onSuccess: () => {
                    setMediaUploadProgress(0);
                    setLoading(false);
                    handleSuccess();
                },
                onError: () => setLoading(false),
            });
        }
    };
    return {
        createVideoPost,
        isLoading,
        mediaUploadProgress,
    };
};
