import React from 'react';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import { Dropdown } from '../../Dropdown/Dropdown';
import { Icon } from '../../Icon';
import * as S from './styled';
export const TableOptionsCell = ({ dropdownKey, actions, row }) => {
    if (!actions)
        return null;
    return (React.createElement(Dropdown, { key: dropdownKey, content: actions.map(({ label, onClick }) => (React.createElement(Dropdown.Item, { key: label, label: label, onClick: () => onClick(row) }))), position: "bottom-end", optionsVerticalOffset: 40, optionsHorizontalOffset: 20 },
        React.createElement(S.Container, { "data-testid": "TableOptionsCell" },
            React.createElement("div", null,
                React.createElement(Icon, { component: DotsHorizontalIcon, height: 16 })))));
};
