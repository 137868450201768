import styled from 'styled-components';
export const InputContainer = styled.div `
  width: 80px;
  margin-right: 12px;
`;
export const ExactPositionContainer = styled.div `
  display: flex;
  align-items: center;
`;
export const InputErrorContainer = styled.div `
  margin-left: 36px;
`;
