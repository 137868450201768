import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { Banner, Drawer, Icon, useMediaQueryContext, WebViewRemover } from '@fourthwall/components';
import { isWebView } from '@fourthwall/utils';
import { BurgerIcon, CloseIcon } from '@fourthwall/icons';
import { useMenuDrawerContext } from '@modules/Dashboard/contexts/MenuDrawerContext';
import { DashboardItemViewHeader } from './DashboardItemViewHeader';
import { DashboardMenu } from './DashboardMenu';
import { DashboardProfileDropdown } from './DashboardProfileDropdown';
import { DashboardSection } from './DashboardSection';
import * as S from './styled';
export const Dashboard = ({ dashboardLogo, toolbarContent, sidebarContent, drawerFooterContent, bannersProps, content, }) => {
    const mq = useMediaQueryContext();
    const { drawerActive, drawerRoot, drawerOpen, drawerClose } = useMenuDrawerContext();
    const renderHeader = () => {
        if (!mq.laptop)
            return null;
        return (React.createElement(S.Header, null,
            React.createElement("div", null, dashboardLogo),
            toolbarContent && React.createElement(S.Toolbar, null, toolbarContent)));
    };
    const renderSidebar = () => {
        if (mq.laptop) {
            return (!!sidebarContent && (React.createElement(S.Sidebar, null,
                React.createElement(S.SidebarInner, null, sidebarContent))));
        }
        return (React.createElement(WebViewRemover, null,
            React.createElement(S.Navbar, null,
                React.createElement(S.BurgerButton, { onClick: drawerOpen },
                    React.createElement(Icon, { component: BurgerIcon, width: 20, height: 20 })),
                React.createElement(S.NavbarActions, null, toolbarContent),
                createPortal(React.createElement(Drawer, null, drawerActive && (React.createElement(Drawer.Body, { position: "left", width: mq.tablet ? 300 : '100%', overlay: React.createElement(Drawer.Overlay, { onClick: drawerClose }) },
                    React.createElement(Drawer.Header, null,
                        React.createElement(Drawer.Navigation, null,
                            dashboardLogo,
                            React.createElement("button", { onClick: drawerClose },
                                React.createElement(Icon, { component: CloseIcon, width: 18, height: 18 })))),
                    React.createElement(Drawer.Content, null,
                        React.createElement(Drawer.Section, null, sidebarContent)),
                    React.createElement(Drawer.Footer, null, drawerFooterContent)))), drawerRoot))));
    };
    const renderBanners = useCallback(() => {
        if (!bannersProps)
            return;
        if (isWebView())
            return;
        return (React.createElement(S.BannersWrapper, null, bannersProps.map((bannerProps) => (React.createElement(Banner, { ...bannerProps })))));
    }, [bannersProps]);
    return (React.createElement("div", null,
        renderHeader(),
        React.createElement(S.Main, null,
            renderSidebar(),
            React.createElement(S.Body, null,
                renderBanners(),
                content && React.createElement(S.Content, { isWebView: isWebView() }, content)))));
};
Dashboard.Menu = DashboardMenu;
Dashboard.Section = DashboardSection;
Dashboard.ItemViewHeader = DashboardItemViewHeader;
Dashboard.ProfileDropdown = DashboardProfileDropdown;
