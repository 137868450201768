import styled from 'styled-components';
import { Colors } from '../../../colors';
import { getAutomatedStyleProp } from '../../../providers/ComponentsThemeProvider';
export const Container = styled.div `
  flex: 1;
`;
export const Box = styled.div `
  width: 264px;
  padding: 12px 16px;
  border: 1px solid ${getAutomatedStyleProp('pale', Colors.GS400)};

  background-color: ${getAutomatedStyleProp('paleBackground', Colors.GS0)};
`;
