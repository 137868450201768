import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  display: flex;
  align-items: center;
`;
export const UndefinedFlag = styled.div `
  aspect-ratio: 3/2;
  background-color: ${Colors.GS500};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${Colors.GS0};
  font-size: 12px;
  font-weight: 700;
`;
export const Label = styled.div `
  margin-left: 20px;

  font-size: 16px;
  line-height: 150%;
`;
export const Flag = styled.span `
  font-size: ${({ height }) => height}px;
  /* NOTE: This font is provided by "country-flag-emoji-polyfill" package when unicode emoji flags are not supported */
  font-family: 'Twemoji Country Flags', 'Helvetica', 'Comic Sans', serif;
`;
