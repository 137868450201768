import { useQueryPage } from '@fourthwall/utils';
import { getCreatorMessagesViewModel } from '../utils/getCreatorMessagesViewModel/getCreatorMessagesListViewModel';
import { useCreatorMessagesQuery } from './queries/useCreatorMessagesQuery';
import { useEmptyState } from './useEmptyState';
export const useCreatorMessages = () => {
    const { page } = useQueryPage();
    const emptyState = useEmptyState();
    const { creatorMessagesData, creatorMessagesQuery, invalidateCreatorMessagesQuery } = useCreatorMessagesQuery(page);
    return {
        creatorMessagesViewModel: getCreatorMessagesViewModel(creatorMessagesData, creatorMessagesQuery, emptyState),
        showSendNewMessageButton: emptyState.showButton,
        invalidateCreatorMessages: invalidateCreatorMessagesQuery,
    };
};
