export const getQueryParams = (currentPage, dateHeaderId, sorting) => ({
    page: currentPage - 1,
    sortDirection: sorting && sorting.headerId === dateHeaderId
        ? sorting.direction.toUpperCase()
        : undefined,
});
// NOTE: Remove memberships gifts redeems.
export const filterMembershipsGiftsRedeems = (item) => {
    return !(item.type === 'GIFT_REDEEM' && item.orderId === '00000000-0000-0000-0000-000000000000');
};
