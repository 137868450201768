import get from 'lodash-es/get';
import React from 'react';
import { OrderTimelineEvent } from '@components/common/OrderTimelineEvent';
export const getBackerName = ({ shipping, email }) => {
    if (!shipping.address) {
        return email;
    }
    const { firstName, lastName } = shipping.address;
    return [firstName, lastName].filter(Boolean).join(' ');
};
export const getTimelineEvents = (id, timelines) => {
    const events = get(timelines, [id, 'data', 'events']);
    const fulfillments = get(timelines, [id, 'data', 'fulfillments']);
    if (!events && !fulfillments) {
        return undefined;
    }
    const timelineEvents = events?.map((timelineEvent) => ({
        ...timelineEvent,
        description: React.createElement(OrderTimelineEvent, { timelineEvent: timelineEvent }),
    })) || [];
    const timelineFulfillments = fulfillments
        ?.filter((timelineFulfillment) => timelineFulfillment.shipments[0]?.deliveryEstimate)
        .map((timelineFulfillment) => ({
        id: timelineFulfillment.fulfillmentId,
        description: React.createElement(OrderTimelineEvent, { timelineFulfillment: timelineFulfillment }),
    })) || [];
    return [...timelineFulfillments, ...timelineEvents];
};
