import { Button, ButtonClean, Icon, Input, Loader, Subsection } from '@fourthwall/components';
import { PreviewOffIcon, PreviewOnIcon } from '@fourthwall/icons';
import React, { useState } from 'react';
import { routing } from '../../../../../../../utils/routing';
import { CopyButton } from './components/CopyButton/CopyButton';
import * as S from './styled';
export const OpenAPIView = ({ user, isLoading, onUserCreate, onUserRemove }) => {
    const [passwordInputType, setPasswordInputType] = useState('password');
    const togglePasswordInputType = () => {
        setPasswordInputType((currentPasswordType) => currentPasswordType === 'password' ? 'text' : 'password');
    };
    return (React.createElement(Subsection, { title: "Open API" },
        React.createElement(S.Description, null,
            "Authorize through Basic auth to integrate with Fourthwall\u2019s API.",
            ' ',
            React.createElement(ButtonClean, { label: "Learn more", href: routing.external.docs.openAPI, target: "_blank" })),
        !user && onUserCreate && (React.createElement(S.ButtonContainer, null,
            React.createElement(Button, { label: "Create API user", size: "small", loading: isLoading, onClick: onUserCreate }))),
        user && (React.createElement(React.Fragment, null,
            React.createElement(S.InputContainer, null,
                React.createElement(Input, { label: "Username", value: user.username, append: React.createElement(S.AppendContainer, null,
                        React.createElement(CopyButton, { label: "Copy", copyValue: user.username, size: "small" })), readOnly: true })),
            React.createElement(S.InputContainer, null,
                React.createElement(Input, { label: "Password", value: user.password, type: passwordInputType, append: React.createElement(S.AppendContainer, null,
                        React.createElement(S.AppendIcon, { onClick: togglePasswordInputType },
                            React.createElement(Icon, { component: passwordInputType === 'password' ? PreviewOnIcon : PreviewOffIcon, height: 16 })),
                        React.createElement(CopyButton, { label: "Copy", copyValue: user.password, size: "small" })), readOnly: true })),
            React.createElement(S.ButtonContainer, null,
                React.createElement(ButtonClean, { label: "Remove API user", disabled: isLoading, onClick: onUserRemove }),
                isLoading && (React.createElement(S.LoaderContainer, null,
                    React.createElement(Loader, { size: "small" }))))))));
};
