import { useRef, useState, useEffect } from 'react';
// NOTE: unfortunately without this polyfill scrollIntoViewIfNeeded doesn't work correctly in Firefox
// and have minor issues in Chrome in Safari
import scrollIntoView from 'scroll-into-view-if-needed';
const DATA_ROLE = 'slider_item';
export const useSlider = () => {
    const containerRef = useRef(null);
    const sliderRef = useRef(null);
    const [allSlidesVisibility, setAllSlidesVisibility] = useState({});
    const slides = Object.entries(allSlidesVisibility).flatMap(([, value]) => value);
    const firstVisibleSlideIndex = slides.indexOf(true);
    const lastVisibleSlideIndex = slides.lastIndexOf(true);
    const goPrevEnabled = firstVisibleSlideIndex !== 0;
    const goNextEnabled = lastVisibleSlideIndex !== slides.length - 1;
    useEffect(() => {
        if (!containerRef.current || !sliderRef.current)
            return;
        // @ts-expect-error
        const items = [...sliderRef.current.querySelectorAll(`[data-role="${DATA_ROLE}"]`)];
        const handleIntersect = (entries) => {
            setAllSlidesVisibility((prevState) => {
                const newState = { ...prevState };
                entries.forEach((e) => {
                    const index = items.findIndex((el) => el === e.target);
                    newState[index] = e.isIntersecting;
                });
                return newState;
            });
        };
        const observer = new IntersectionObserver(handleIntersect, {
            root: containerRef.current,
            rootMargin: '0px',
            threshold: 0.75,
        });
        items.forEach((el) => {
            observer.observe(el);
        });
        return () => {
            observer.disconnect();
        };
    }, [containerRef, sliderRef]);
    const setActiveSlide = (index) => {
        if (!sliderRef.current)
            return;
        // @ts-expect-error
        const items = [...sliderRef.current.querySelectorAll(`[data-role="${DATA_ROLE}"]`)];
        scrollIntoView(items[index], { behavior: 'smooth', block: 'nearest' });
    };
    const goPrev = () => {
        goPrevEnabled && setActiveSlide(firstVisibleSlideIndex - 1);
    };
    const goNext = () => {
        goNextEnabled && setActiveSlide(lastVisibleSlideIndex + 1);
    };
    return {
        getContainerProps: () => ({ ref: containerRef }),
        getSliderProps: () => ({ ref: sliderRef }),
        getSlideProps: () => ({ 'data-role': DATA_ROLE }),
        getPrevButtonProps: () => ({ disabled: !goPrevEnabled, onClick: goPrev }),
        getNextButtonProps: () => ({ disabled: !goNextEnabled, onClick: goNext }),
    };
};
