import discordSignet from './discord.svg';
import instagramSignet from './instagram.svg';
import klaviyoSignet from './klaviyo.svg';
import layloSignet from './laylo.svg';
import metaSignet from './meta.svg';
import shipstationSignet from './shipstation.svg';
import streamelementsSignet from './streamelements.png';
import streamlabsSignet from './streamlabs.svg';
import tiktokSignet from './tiktok.svg';
import twitchSignet from './twitch.svg';
import youtubeSignet from './youtube.svg';
export const signets = {
    discordSignet,
    instagramSignet,
    klaviyoSignet,
    layloSignet,
    metaSignet,
    shipstationSignet,
    streamelementsSignet,
    streamlabsSignet,
    tiktokSignet,
    twitchSignet,
    youtubeSignet,
};
