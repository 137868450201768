import { useState } from 'react';
import { useImageConfirmMutation } from '../../../hooks/useImageConfirmMutation';
import { useUploadImageMutation } from '../../../hooks/useUploadImageMutation';
import { useCreateImagePostMutation } from './useCreateImagePostMutation';
export const useCreateImagePost = () => {
    const [isLoading, setLoading] = useState(false);
    const { createImagePostMutation } = useCreateImagePostMutation();
    const { uploadImageMutation } = useUploadImageMutation();
    const { imageConfirmMutation } = useImageConfirmMutation();
    const createImagePost = async (file, payload, handleSuccess) => {
        setLoading(true);
        const { postId, imageDirectUploadData } = await createImagePostMutation.mutateAsync(payload, {
            onError: () => setLoading(false),
        });
        await uploadImageMutation.mutateAsync({ file, ...imageDirectUploadData }, { onError: () => setLoading(false) });
        imageConfirmMutation.mutate({ id: postId, signedId: imageDirectUploadData.signedId }, {
            onSuccess: () => {
                setLoading(false);
                handleSuccess();
            },
            onError: () => setLoading(false),
        });
    };
    return {
        createImagePost,
        isLoading,
    };
};
