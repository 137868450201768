export const SECTION_NAMES = {
    accountSecurity: 'Security',
    analytics: 'Analytics',
    appInstagram: 'Instagram feed',
    appMeta: 'Instagram & FB Shop',
    appStreamElements: 'StreamElements alerts',
    appStreamlabs: 'Streamlabs alerts',
    appTikTok: 'TikTok feed',
    appTikTokShop: 'TikTok Shop',
    appTwitchGifting: 'Twitch gifting',
    appTwitterShops: 'X Shops',
    appYoutubeProductShelf: 'YouTube Product Shelf',
    apps: 'Apps',
    fulfillment: 'Fulfillment',
    home: 'Home',
    themeEditor: 'Site design',
};
