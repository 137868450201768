import { HeadContent, Skeleton } from '@fourthwall/components';
import React from 'react';
import { Dashboard } from '@components/common/Dashboard';
import { OrderedList } from '@components/common/OrderedList';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { routing } from '@utils/routing';
import { ConnectBar } from '../../components/ConnectBar';
import { useDiscord } from './hooks/useDiscord';
export const AppsDiscord = () => {
    const { discord } = useDiscord();
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Members-only Discord server" }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: "Members-only Discord server", topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }), bottomContent: React.createElement("div", { style: { lineHeight: 1.5 } },
                    React.createElement("p", null, "Allows you to offer access to your own Discord server directly with your fans."),
                    React.createElement(OrderedList, null,
                        React.createElement(OrderedList.Item, null, "Connect your Discord account."),
                        React.createElement(OrderedList.Item, null, "Add \"Discord\u201D perk to your tiers on Perks & tiers tab inside Memberships settings."))) },
                React.createElement("div", { style: { marginTop: 16 - 24, marginBottom: 40 } }, discord.connectBar ? (React.createElement(ConnectBar, { ...discord.connectBar })) : (React.createElement(Skeleton, null,
                    React.createElement(Skeleton.Shape, { height: 64 }))))))));
};
