import { PlusIcon } from '@fourthwall/icons';
import React from 'react';
import { Icon } from '../Icon';
import * as S from './styled';
export const AddButton = ({ label, disabled, onClick }) => {
    return (React.createElement(S.Container, { onClick: onClick, "data-testid": "AddButton", disabled: disabled, as: onClick ? 'button' : 'div' },
        React.createElement(S.IconContainer, null,
            React.createElement(Icon, { component: PlusIcon, height: 8 })),
        label));
};
