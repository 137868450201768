import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const HeadingContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;

  ${mediaQueries.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;
export const Heading = styled.div `
  width: 100%;
  word-break: break-all;

  ${mediaQueries.tablet} {
    width: auto;
  }
`;
export const Description = styled.div `
  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GS600};
  margin: 16px 0;

  ${mediaQueries.tablet} {
    margin: 4px 0 16px;
  }
`;
export const RemoveDomainTextContainer = styled.div `
  color: ${Colors.GS600};
`;
export const TagWithTooltip = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
