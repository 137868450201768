import { useState } from 'react';
import { usePost } from './usePost';
import { useResourcesQuery } from './useResourcesQuery';
export const useImageResources = (id) => {
    const [images, setImages] = useState(null);
    const { post } = usePost(id);
    const imageResourcesIds = post && 'imageResources' in post
        ? post.imageResources.map((resource) => resource.resourceId)
        : [];
    const enabled = !!imageResourcesIds?.length;
    const { resourcesQuery } = useResourcesQuery([{ 'ids[]': imageResourcesIds }], {
        enabled,
        onSuccess: (data) => {
            setImages(data.resources.map((image) => ({
                image: image.resizedUrl,
                id: image.id,
            })));
        },
    });
    return {
        images,
        isLoading: enabled && resourcesQuery.isLoading,
    };
};
