import React from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader';
import * as S from './Button.styled';
import { Appearance, Type } from './types';
const getComponent = ({ to, href, disabled }) => {
    if (!disabled && to) {
        return Link;
    }
    if (!disabled && href) {
        return 'a';
    }
    return 'button';
};
const getLoaderAppearance = (buttonAppearance) => {
    if (buttonAppearance === Appearance.Secondary) {
        return 'secondary';
    }
    return 'primary';
};
export const Button = ({ forwardedRef, label, appearance = Appearance.Secondary, size = 'medium', icon, leftIcon, iconColor, to, state, href, target, download, disabled = false, loading = false, fullWidth = false, multiline = false, type = Type.Button, className, analytics, onClick, }) => {
    return (React.createElement(S.Container, { ref: forwardedRef, as: getComponent({ to, href, disabled }), role: "button", appearance: appearance, size: size, to: !disabled ? to : undefined, state: state, href: !disabled ? href : undefined, target: target, download: download, disabled: disabled || loading, "$loading": loading, "$fullwidth": fullWidth, "$multiline": multiline, type: type, onClick: onClick, className: className, "data-analytics": analytics, "data-testid": "button" },
        React.createElement(S.Label, { "$loading": loading },
            leftIcon && (React.createElement(S.IconContainer, { color: iconColor, "aria-hidden": "true" }, leftIcon)),
            label && React.createElement("span", null, label),
            icon && (React.createElement(S.IconContainer, { color: iconColor, "aria-hidden": "true" }, icon))),
        loading && (React.createElement(S.LoaderContainer, null,
            React.createElement(Loader, { appearance: getLoaderAppearance(appearance), size: size === 'small' ? 'medium' : 'large' })))));
};
