import { Dropdown, Toasts, useModalContext } from '@fourthwall/components';
import React from 'react';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { routing } from '@utils/routing';
import { CreateNewSiteModalContainer } from './modals/CreateNewSiteModal';
export const CreateNewSiteDropdownItem = () => {
    const { open, close } = useModalContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const onCreateNewSiteClick = () => open(CreateNewSiteModalContainer, {
        onSuccess: (data) => {
            close();
            Toasts.notify(`${data.name} site created successfully.`, { type: 'success' });
            window.location.assign(`${data.internalBaseUri}${routing.home}`);
        },
    });
    if (!dashboardFeatureFlags.createNewSite.enabled) {
        return null;
    }
    return React.createElement(Dropdown.Item, { label: "Create a new site", onClick: onCreateNewSiteClick });
};
