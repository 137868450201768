import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Layout = styled.div `
  position: absolute;
  pointer-events: auto;
`;
export const List = styled.div `
  display: flex;
  align-items: center;
  list-style: none;
  color: ${Colors.GS0};
  gap: 1px;
  background-color: ${Colors.B500};
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
`;
export const ListItem = styled.button `
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 15px;
  height: 32px;
  font-size: 14px;
  background-color: ${Colors.B500};

  &:hover {
    background-color: #1f59ff;
  }
`;
