import React from 'react';
import { pluralize } from '@fourthwall/utils/lib/string';
export const FulfilledOrderIDsToast = ({ ids }) => (React.createElement("div", null,
    React.createElement("p", null,
        "Created ",
        ids.length,
        " fulfillment ",
        pluralize(ids.length, 'ID'),
        ":"),
    React.createElement("ol", null, ids.map((id) => (React.createElement("li", { key: id }, id))))));
