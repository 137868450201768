import { Button as ButtonComponent, Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;

  padding: 24px 0 0 16px;

  ${mediaQueries.tablet} {
    padding: 24px 0 0 32px;
  }
`;
export const TitleContainer = styled.div `
  margin-bottom: 16px;
  padding-right: 20px;

  ${mediaQueries.tablet} {
    margin-bottom: 20px;
    padding-right: 32px;
  }
`;
export const InputContainer = styled.div `
  margin-bottom: 16px;
  padding-right: 20px;

  ${mediaQueries.tablet} {
    margin-bottom: 20px;
    padding-right: 32px;
  }
`;
export const SelectAllContainer = styled.div `
  margin-bottom: 16px;
  width: fit-content;
`;
export const ContentContainer = styled.div `
  flex: 1;
  overflow: auto;
  padding-right: 20px;

  ${mediaQueries.tablet} {
    padding-right: 16px;
  }
`;
export const Bar = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 20px 20px;
  border-top: 1px solid ${Colors.GS400};
  background-color: ${Colors.GS100};
  gap: 8px;

  ${mediaQueries.tablet} {
    flex-direction: row;
    padding: 0 32px;
    align-items: center;
    justify-content: flex-end;
    min-height: 80px;
  }
`;
export const Button = styled(ButtonComponent) `
  /*
   NOTE: This is a workaround to fix the button. The static position prevents the button from showing through when a
   dropdown is present within the modal.
  */
  position: static;

  &:focus::after {
    display: none;
  }
`;
export const Message = styled.div `
  margin-right: auto;
  font-size: 14px;

  color: ${Colors.GS600};

  ${mediaQueries.tablet} {
    font-size: initial;
  }
`;
