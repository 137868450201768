import React, { useEffect, useState } from 'react';
import { SelectItemView } from './SelectItemView';
import { isOfferItemType } from '../../utils';
import { getQuantityError } from './utils';
export const SelectItemContainer = ({ item, initialValues, onChange, }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [checked, setChecked] = useState(initialValues.checked);
    const [quantity, setQuantity] = useState(initialValues.quantity);
    const [quantityError, setQuantityError] = useState('');
    const handleItemChange = () => !isOfferItemType(item) &&
        onChange({
            variantId: item.variantId,
            quantity,
            price: item.price,
            error: !!quantityError,
            checked,
        });
    useEffect(() => {
        if (isDirty) {
            handleItemChange();
        }
    }, [checked, isDirty]);
    useEffect(() => {
        if (checked && isDirty) {
            handleItemChange();
        }
        setQuantityError(getQuantityError(item, quantity));
    }, [quantity, quantityError]);
    const handleCheckboxChange = (value) => {
        setIsDirty(true);
        setChecked(value);
    };
    const handleQuantityChange = (value) => {
        setIsDirty(true);
        setQuantity(value);
    };
    return (React.createElement(SelectItemView, { item: item, checked: checked, quantity: quantity, quantityError: quantityError, onCheckboxValueChange: handleCheckboxChange, onQuantityValueChange: handleQuantityChange }));
};
