import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const SIZE = {
    small: 200,
    medium: 264,
    large: 296,
};
export const Container = styled.div `
  min-width: ${({ size }) => SIZE[size]}px;
  border: 1px solid ${Colors.GS400};
  background-color: ${Colors.GS0};
`;
