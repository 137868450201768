import Hls from 'hls.js/dist/hls.light.min';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import React, { useEffect, useRef } from 'react';
import * as S from './VideoPlayer.styled';
export const VideoPlayer = ({ videoSrc, thumbnailSrc, hls }) => {
    const videoElement = useRef(null);
    const controls = [
        'play-large',
        'play',
        'progress',
        'mute',
        'current-time',
        'duration',
        'airplay',
        'volume',
        'settings',
        'fullscreen',
    ];
    const fullscreen = { enabled: true, fallback: true, iosNative: true };
    const settings = ['speed'];
    const speed = {
        selected: 1,
        options: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
    };
    const ratio = '16:9';
    const i18n = {
        qualityLabel: {
            1: 'Auto',
        },
    };
    const defaultOptions = {
        controls,
        fullscreen,
        settings,
        speed,
        ratio,
        i18n,
    };
    const setPoster = () => {
        if (videoElement.current) {
            videoElement.current.poster = thumbnailSrc || '';
        }
    };
    useEffect(() => {
        if (!hls && videoElement.current) {
            videoElement.current.src = videoSrc;
            new Plyr(videoElement.current, {
                controls,
            });
            setPoster();
        }
        if (hls && videoElement.current) {
            const hls = new Hls();
            const handleUpdateQuality = (newQuality) => {
                if (newQuality === 1) {
                    hls.currentLevel = -1;
                }
                else {
                    hls.levels.forEach((level, levelIndex) => {
                        if (level.height === newQuality) {
                            hls.currentLevel = levelIndex;
                        }
                    });
                }
            };
            if (!Hls.isSupported()) {
                videoElement.current.src = videoSrc;
                new Plyr(videoElement.current, defaultOptions);
                setPoster();
            }
            else {
                hls.loadSource(videoSrc);
                hls.attachMedia(videoElement.current);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    const availableQualities = hls.levels.map((l) => l.height);
                    if (videoElement.current) {
                        new Plyr(videoElement.current, {
                            ...defaultOptions,
                            settings: ['speed', 'quality'],
                            quality: {
                                // 1 is custom numeric value that represents 'Auto' quality
                                default: 1,
                                options: [...availableQualities.slice().reverse(), 1],
                                forced: true,
                                onChange: (quality) => handleUpdateQuality(quality),
                            },
                        });
                        setPoster();
                    }
                });
            }
        }
    }, [videoSrc, hls, thumbnailSrc]);
    return (React.createElement(S.Container, { "data-testid": "VideoPlayer" },
        React.createElement(S.Video, { preload: "none", controls: true, ref: videoElement })));
};
