import { useState } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
export const useQueryParamsToTableSorting = ({ initialSorting }) => {
    const [query, setQuery] = useQueryParams({ orderBy: StringParam, order: StringParam });
    const [pristine, setPristine] = useState(true);
    const getSorting = () => {
        if (pristine)
            return initialSorting;
        if (!query.orderBy || !query.order)
            return null;
        return {
            headerId: query.orderBy,
            direction: query.order,
        };
    };
    const handleSortingChange = (sorting) => {
        setPristine(false);
        if (!sorting) {
            return setQuery({ orderBy: undefined, order: undefined });
        }
        return setQuery({ orderBy: sorting.headerId, order: sorting.direction });
    };
    return {
        sorting: getSorting(),
        onSortingChange: handleSortingChange,
    };
};
