import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Summary = styled.div `
  color: ${Colors.GS600};
  font-size: 14px;
  line-height: 21px;
`;
export const Code = styled.div `
  line-height: 24px;
`;
