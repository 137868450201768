import { DateString, NumberString, PriceString, ProfitString } from '@fourthwall/components';
import { addZeroEntriesForMissingDates, AnalyticsCountry, createAllRangeDates, createChartStatsTableColumnsTotalFreeMembers, createChartStatsTableColumnsTotalMembers, createChartStatsTableTotalFreeMembers, createChartStatsTableTotalMembers, createTopProductsByUnitsSoldData, getDateFormat, mapCurrentDataToTimestampsMap, mapCurrentDataToTimestampsTotalMembers, mapRangeToText, negative, SUMMARY_ROW_ID, } from '@fourthwall/module-analytics';
import get from 'lodash-es/get';
import pick from 'lodash-es/pick';
import React from 'react';
import { OfferLink } from '../../../../components/OfferLink';
import { REPORT_TYPES } from '../../../../consts';
import { mapTopTiersToAnalyticsRows, prepareSingleValueChart, } from '../../../../hooks/useAnalytics/utils';
export const mapCurrentProfitDataToChartSeries = (currentProfitData, params) => {
    if (!currentProfitData)
        return [];
    const { timestamps, values } = addZeroEntriesForMissingDates(currentProfitData, params);
    return timestamps.map((label, index) => ({
        date: new Date(label),
        value: values[index],
    }));
};
export const createChart = (analyticsChartQuery, membershipsChartQuery, params, reportType) => {
    const { analyticsChartQuery: analyticsChartQueryName, chartValueQuery: chartValueQueryName, getDateFormatter, formatValue, endpoint, } = REPORT_TYPES[reportType];
    const query = endpoint === 'membership-chart' ? membershipsChartQuery : analyticsChartQuery;
    if (!analyticsChartQueryName || !getDateFormatter || !formatValue)
        return undefined;
    const chartValueData = chartValueQueryName
        ? get(query.data?.current, [chartValueQueryName], undefined)
        : undefined;
    const reportCurrent = get(query.data?.current, [analyticsChartQueryName], undefined);
    const getValue = () => {
        const data = chartValueData || reportCurrent;
        if (!data)
            return undefined;
        const value = data.aggregatedValue ?? data.value ?? 0; // NOTE: aggregatedValue can be null
        if ('currency' in data) {
            return { value, currency: data.currency };
        }
        if (data.aggregatedValue.hasOwnProperty('value')) {
            return data.aggregatedValue.value;
        }
        return value;
    };
    if (reportType === REPORT_TYPES['memberships-members-change'].slug) {
        const getValueChange = () => {
            const value = getValue();
            return value <= 0 ? value : `+${value}`;
        };
        return {
            title: REPORT_TYPES[reportType].title,
            value: getValueChange(),
            description: membershipsChartQuery.data
                ? mapRangeToText(membershipsChartQuery.data.current.range)
                : undefined,
            series: mapCurrentProfitDataToChartSeries(prepareSingleValueChart(reportCurrent?.data), params),
            formatDate: getDateFormatter(params.precision),
            formatValue,
            isLoading: membershipsChartQuery.isLoading,
            isError: membershipsChartQuery.isError,
        };
    }
    if (reportType === REPORT_TYPES['memberships-free-members-change'].slug) {
        const getValueChange = () => {
            const value = getValue();
            return value <= 0 ? value : `+${value}`;
        };
        return {
            title: REPORT_TYPES[reportType].title,
            value: getValueChange(),
            description: membershipsChartQuery.data
                ? mapRangeToText(membershipsChartQuery.data.current.range)
                : undefined,
            series: mapCurrentProfitDataToChartSeries(prepareSingleValueChart(reportCurrent?.data), params),
            formatDate: getDateFormatter(params.precision),
            formatValue,
            isLoading: membershipsChartQuery.isLoading,
            isError: membershipsChartQuery.isError,
        };
    }
    if (reportType === REPORT_TYPES['memberships-total-members'].slug) {
        return {
            title: REPORT_TYPES[reportType].title,
            value: getValue(),
            description: membershipsChartQuery.data
                ? mapRangeToText(membershipsChartQuery.data.current.range)
                : undefined,
            series: mapCurrentProfitDataToChartSeries(reportCurrent?.data, params),
            formatDate: getDateFormatter(params.precision),
            formatValue,
            isLoading: membershipsChartQuery.isLoading,
            isError: membershipsChartQuery.isError,
        };
    }
    if (reportType === REPORT_TYPES['memberships-total-free-members'].slug) {
        return {
            title: REPORT_TYPES[reportType].title,
            value: getValue(),
            description: membershipsChartQuery.data
                ? mapRangeToText(membershipsChartQuery.data.current.range)
                : undefined,
            series: mapCurrentProfitDataToChartSeries(reportCurrent?.data, params),
            formatDate: getDateFormatter(params.precision),
            formatValue,
            isLoading: membershipsChartQuery.isLoading,
            isError: membershipsChartQuery.isError,
        };
    }
    return {
        title: REPORT_TYPES[reportType].title,
        value: getValue(),
        description: analyticsChartQuery.data
            ? mapRangeToText(analyticsChartQuery.data.current.range)
            : undefined,
        series: mapCurrentProfitDataToChartSeries(reportCurrent?.data, params),
        formatDate: getDateFormatter(params.precision),
        formatValue,
        isLoading: analyticsChartQuery.isLoading,
        isError: analyticsChartQuery.isError,
    };
};
export const createChartStatsTableColumns = (params) => [
    {
        header: params.precision === 'HOUR' ? 'Time' : 'Day',
        render: (item) => {
            if (item.id === SUMMARY_ROW_ID)
                return item.day;
            return React.createElement(DateString, { date: item.day, format: getDateFormat(params.precision), utc: true });
        },
    },
    { header: 'Transaction Value', render: (item) => React.createElement(PriceString, { value: item.value }) },
    { header: 'Cost', render: (item) => React.createElement(PriceString, { value: item.cost }) },
    { header: 'Contributions', render: (item) => item.contribution },
    { header: 'Profit', render: (item) => React.createElement(ProfitString, { value: item.profit }) },
];
// TODO: Use createChartStatsTableData.
export const createProfitData = (current, params) => {
    if (!current)
        return [];
    const allRangeDates = createAllRangeDates(params).reverse();
    const grossSales = mapCurrentDataToTimestampsMap(current.grossSales?.data);
    const discounts = mapCurrentDataToTimestampsMap(current.discounts?.data);
    const costs = mapCurrentDataToTimestampsMap(current.cost?.data);
    const cancellations = mapCurrentDataToTimestampsMap(current.cancellations?.data);
    const refunds = mapCurrentDataToTimestampsMap(current.refunds?.data);
    const profits = mapCurrentDataToTimestampsMap(current.profit?.data);
    const statsTableData = allRangeDates.map((date) => ({
        id: date,
        day: date,
        grossSales: grossSales[date] || 0,
        discounts: negative(discounts[date]) || 0,
        cost: negative(costs[date]) || 0,
        cancellations: negative(cancellations[date]) || 0,
        refunds: negative(refunds[date]) || 0,
        profit: profits[date] || 0,
    }));
    statsTableData.unshift({
        id: SUMMARY_ROW_ID,
        day: 'Summary',
        grossSales: current.grossSales?.aggregatedValue || 0,
        discounts: negative(current.discounts?.aggregatedValue) || 0,
        cost: negative(current.cost?.aggregatedValue) || 0,
        cancellations: negative(current.cancellations?.aggregatedValue) || 0,
        refunds: negative(current.refunds?.aggregatedValue) || 0,
        profit: current.profit?.aggregatedValue || 0,
    });
    return statsTableData;
};
export const createChartStatsTableData = (current, params, keys) => {
    if (!current)
        return [];
    const allRangeDates = createAllRangeDates(params).reverse();
    const averageProfit = mapCurrentDataToTimestampsMap(current.averageProfit?.data);
    const averageSales = mapCurrentDataToTimestampsMap(current.averageSales?.data);
    const cancellations = mapCurrentDataToTimestampsMap(current.cancellations?.data);
    const contribution = mapCurrentDataToTimestampsMap(current.contribution?.data);
    const contributionDonations = mapCurrentDataToTimestampsMap(current.contributionDonations?.data);
    const contributionGiftsOnCreatorCost = mapCurrentDataToTimestampsMap(current.contributionGiftsOnCreatorCost?.data);
    const contributionGiveaway = mapCurrentDataToTimestampsMap(current.contributionGiveaway?.data);
    const contributionOrders = mapCurrentDataToTimestampsMap(current.contributionOrders?.data);
    const contributionSubscriptions = mapCurrentDataToTimestampsMap(current.contributionSubscriptions?.data);
    const contributionTotal = mapCurrentDataToTimestampsMap(current.contributionTotal?.data);
    const costs = mapCurrentDataToTimestampsMap(current.cost?.data);
    const discounts = mapCurrentDataToTimestampsMap(current.discounts?.data);
    const grossSales = mapCurrentDataToTimestampsMap(current.grossSales?.data);
    const profits = mapCurrentDataToTimestampsMap(current.profit?.data);
    const refunds = mapCurrentDataToTimestampsMap(current.refunds?.data);
    const values = mapCurrentDataToTimestampsMap(current.income?.data);
    const statsTableData = allRangeDates.map((date) => ({
        id: date,
        day: date,
        averageProfit: averageProfit[date] || 0,
        averageSales: averageSales[date] || 0,
        cancellations: cancellations[date] || 0,
        contribution: contribution[date] || 0,
        contributionDonations: contributionDonations[date] || 0,
        contributionGiftsOnCreatorCost: contributionGiftsOnCreatorCost[date] || 0,
        contributionGiveaway: contributionGiveaway[date] || 0,
        contributionOrders: contributionOrders[date] || 0,
        contributionSubscriptions: contributionSubscriptions[date] || 0,
        contributionTotal: contributionTotal[date] || 0,
        cost: costs[date] || 0,
        discounts: discounts[date] || 0,
        grossSales: grossSales[date] || 0,
        profit: profits[date] || 0,
        refunds: refunds[date] || 0,
        value: values[date] || 0,
    }));
    statsTableData.unshift({
        id: SUMMARY_ROW_ID,
        day: 'Summary',
        averageProfit: current.averageProfitValue?.value || 0,
        // averageProfit: current.averageProfit?.aggregatedValue || 0,
        averageSales: current.averageGrossSalesValue?.value || 0,
        // averageSales: current.averageSales?.aggregatedValue || 0,
        cancellations: current.cancellations?.aggregatedValue || 0,
        contribution: current.contribution?.aggregatedValue || 0,
        contributionDonations: current.contributionDonations?.aggregatedValue || 0,
        contributionGiftsOnCreatorCost: current.contributionGiftsOnCreatorCost?.aggregatedValue || 0,
        contributionGiveaway: current.contributionGiveaway?.aggregatedValue || 0,
        contributionOrders: current.contributionOrders?.aggregatedValue || 0,
        contributionSubscriptions: current.contributionSubscriptions?.aggregatedValue || 0,
        contributionTotal: current.contributionTotal?.aggregatedValue || 0,
        cost: current.cost?.aggregatedValue || 0,
        discounts: current.discounts?.aggregatedValue || 0,
        grossSales: current.grossSales?.aggregatedValue || 0,
        profit: current.profit?.aggregatedValue || 0,
        refunds: current.refunds?.aggregatedValue || 0,
        value: current.income?.aggregatedValue || 0,
    });
    // TODO: Cleanup and tests.
    return statsTableData.map((item) => {
        if (!keys || !keys.length)
            return item;
        return pick(item, ['id', 'day', ...keys]);
    });
};
export const createStatsTable = (reportType, params, analyticsChartQuery, membershipsChartQuery, topOfferFullTableQuery, saleByCountryFullTableQuery, membershipsTopTiersTableQuery, membershipAccountQuery) => {
    if (reportType === REPORT_TYPES.profit.slug) {
        return {
            columns: [
                {
                    header: params.precision === 'HOUR' ? 'Time' : 'Day',
                    render: (item) => {
                        if (item.id === SUMMARY_ROW_ID)
                            return item.day;
                        return React.createElement(DateString, { date: item.day, format: getDateFormat(params.precision), utc: true });
                    },
                },
                {
                    header: 'Gross Sales',
                    tooltip: 'This includes the total amount of money spent across all of your Fourthwall transactions (excluding any discounts)',
                    render: (item) => React.createElement(PriceString, { value: item.grossSales }),
                },
                {
                    header: 'Discounts',
                    tooltip: 'This includes discounts from your promotional campaigns',
                    render: (item) => React.createElement(PriceString, { value: item.discounts }),
                },
                {
                    header: 'Costs',
                    tooltip: 'This includes all product costs, shipping costs, taxes, fulfillment costs, digital product fees, membership fees, and payment fees',
                    render: (item) => React.createElement(PriceString, { value: item.cost }),
                },
                {
                    header: 'Cancels',
                    tooltip: 'This includes all canceled transactions',
                    render: (item) => React.createElement(PriceString, { value: item.cancellations }),
                },
                {
                    header: 'Refunds',
                    tooltip: 'This includes any transaction that was refunded by you but was not canceled',
                    render: (item) => React.createElement(PriceString, { value: item.refunds }),
                },
                {
                    header: 'Profit',
                    render: (item) => React.createElement(ProfitString, { value: item.profit }),
                },
            ],
            data: createProfitData(analyticsChartQuery.data?.current, params),
            emptyMessage: 'No contributions found in this date range.',
            isLoading: analyticsChartQuery.isLoading,
            getRowBold: ({ id }) => id === SUMMARY_ROW_ID,
        };
    }
    if (reportType === REPORT_TYPES['memberships-total-members'].slug) {
        return {
            columns: createChartStatsTableColumnsTotalMembers(params),
            data: createChartStatsTableTotalMembers(membershipsChartQuery.data?.current, params),
            emptyMessage: 'No data found in this date range.',
            isLoading: membershipsChartQuery.isLoading,
        };
    }
    if (reportType === REPORT_TYPES['memberships-total-free-members'].slug) {
        return {
            columns: createChartStatsTableColumnsTotalFreeMembers(params),
            data: createChartStatsTableTotalFreeMembers(membershipsChartQuery.data?.current, params),
            emptyMessage: 'No data found in this date range.',
            isLoading: membershipsChartQuery.isLoading,
        };
    }
    if (reportType === REPORT_TYPES['memberships-members-change'].slug) {
        return {
            columns: createChartStatsTableColumnsMembersChange(params),
            data: createChartStatsTableMembersChange(membershipsChartQuery.data?.current, params),
            emptyMessage: 'No data found in this date range.',
            isLoading: membershipsChartQuery.isLoading,
        };
    }
    if (reportType === REPORT_TYPES['memberships-free-members-change'].slug) {
        return {
            columns: createChartStatsTableColumnsMembersChange(params),
            data: createChartStatsTableFreeMembersChange(membershipsChartQuery.data?.current, params),
            emptyMessage: 'No data found in this date range.',
            isLoading: membershipsChartQuery.isLoading,
        };
    }
    if (reportType === 'orders') {
        return {
            columns: [
                {
                    header: params.precision === 'HOUR' ? 'Time' : 'Day',
                    render: (item) => {
                        if (item.id === SUMMARY_ROW_ID)
                            return item.day;
                        return React.createElement(DateString, { date: item.day, format: getDateFormat(params.precision), utc: true });
                    },
                },
                {
                    header: 'Shop Orders',
                    render: (item) => item.contributionOrders,
                },
                {
                    header: 'Donations',
                    render: (item) => item.contributionDonations,
                },
                {
                    header: 'Twitch Gifts',
                    render: (item) => item.contributionGiveaway,
                },
                {
                    header: 'Giveaway Links',
                    render: (item) => item.contributionGiftsOnCreatorCost,
                },
                {
                    header: 'Memberships',
                    render: (item) => item.contributionSubscriptions,
                },
                {
                    header: 'Total',
                    render: (item) => item.contribution,
                },
            ],
            data: createChartStatsTableData(analyticsChartQuery.data?.current, params, [
                'contributionOrders',
                'contributionDonations',
                'contributionGiveaway',
                'contributionGiftsOnCreatorCost',
                'contributionSubscriptions',
                'contribution',
            ]),
            emptyMessage: 'No contributions found in this date range.',
            isLoading: analyticsChartQuery.isLoading,
            getRowBold: ({ id }) => id === SUMMARY_ROW_ID,
        };
    }
    if (reportType === REPORT_TYPES['average-order-value'].slug) {
        return {
            columns: [
                {
                    header: params.precision === 'HOUR' ? 'Time' : 'Day',
                    render: (item) => {
                        if (item.id === SUMMARY_ROW_ID)
                            return item.day;
                        return React.createElement(DateString, { date: item.day, format: getDateFormat(params.precision), utc: true });
                    },
                },
                {
                    header: 'Average Sales Value',
                    render: (item) => React.createElement(PriceString, { value: item.averageSales }),
                },
                {
                    header: 'Average Profit',
                    render: (item) => React.createElement(PriceString, { value: item.averageProfit }),
                },
            ],
            data: createChartStatsTableData(analyticsChartQuery.data?.current, params, [
                'averageSales',
                'averageProfit',
            ]),
            emptyMessage: 'No contributions found in this date range.',
            isLoading: analyticsChartQuery.isLoading,
            getRowBold: ({ id }) => id === SUMMARY_ROW_ID,
        };
    }
    if (reportType === REPORT_TYPES['top-products-by-units-sold'].slug) {
        return {
            columns: [
                {
                    header: 'Product',
                    render: (item) => (React.createElement(OfferLink, { name: item.name, label: REPORT_TYPES['top-products-by-units-sold'].title, id: item.offerId, status: item.status })),
                },
                { header: 'Units sold', render: (item) => React.createElement(NumberString, { value: item.unitsSold }) },
            ],
            data: createTopProductsByUnitsSoldData(topOfferFullTableQuery.data?.current.data),
            emptyMessage: 'No contributions found in this date range.',
            isLoading: topOfferFullTableQuery.isLoading,
        };
    }
    if (reportType === REPORT_TYPES['sales-by-country'].slug) {
        return {
            columns: [
                { header: 'Country', render: (item) => React.createElement(AnalyticsCountry, { countryCode: item.country }) },
                { header: 'Contribution', render: (item) => React.createElement(NumberString, { value: item.contribution }) },
                { header: 'Income', render: (item) => React.createElement(ProfitString, { value: item.income }) },
            ],
            data: saleByCountryFullTableQuery.data?.current.data || [],
            emptyMessage: 'No contributions found in this date range.',
            isLoading: saleByCountryFullTableQuery.isLoading,
        };
    }
    if (reportType === REPORT_TYPES['membership-top-tiers'].slug) {
        return {
            columns: [
                { header: 'Tier', render: (item) => item.label },
                { header: 'Supporters', render: (item) => React.createElement(NumberString, { value: item.value }) },
            ],
            data: mapTopTiersToAnalyticsRows(membershipsTopTiersTableQuery.data?.current.data, membershipAccountQuery.data?.tiers) || [],
            emptyMessage: 'No data found in this date range.',
            isLoading: membershipsTopTiersTableQuery.isLoading || membershipAccountQuery.isLoading,
        };
    }
    // this should never happen
    return {
        columns: createChartStatsTableColumns(params),
        data: createChartStatsTableData(analyticsChartQuery.data?.current, params, []),
        emptyMessage: 'No contributions found in this date range.',
        isLoading: analyticsChartQuery.isLoading,
        getRowBold: ({ id }) => id === SUMMARY_ROW_ID,
    };
};
export const createChartStatsTableColumnsMembersChange = (params) => [
    {
        header: params.precision === 'HOUR' ? 'Time' : 'Day',
        render: (item) => {
            if (item.id === SUMMARY_ROW_ID)
                return item.day;
            return React.createElement(DateString, { date: item.day, format: getDateFormat(params.precision), utc: true });
        },
    },
    {
        header: 'Change',
        render: (item) => React.createElement("span", null, (item.change <= 0 ? '' : '+') + item.change),
    },
];
export const createChartStatsTableMembersChange = (current, params) => {
    if (!current)
        return [];
    const allRangeDates = createAllRangeDates(params).reverse();
    const change = mapCurrentDataToTimestampsTotalMembers('value', current.membersChange?.data);
    const statsTableData = allRangeDates.map((date) => ({
        id: date,
        day: date,
        change: change[date] || 0,
    }));
    return statsTableData;
};
export const createChartStatsTableFreeMembersChange = (current, params) => {
    if (!current)
        return [];
    const allRangeDates = createAllRangeDates(params).reverse();
    const change = mapCurrentDataToTimestampsTotalMembers('value', current.freeAccountsChange?.data);
    const statsTableData = allRangeDates.map((date) => ({
        id: date,
        day: date,
        change: change[date] || 0,
    }));
    return statsTableData;
};
export const getCsvData = (statsTableData) => {
    return statsTableData
        .filter(({ id }) => id !== SUMMARY_ROW_ID)
        .map(({ id, children, ...rest }) => rest); // removes ids and children
};
