import React, { useState } from 'react';
import { PriceString, NumberString } from '@fourthwall/components';
import { createDateRangeParams, useAnalyticsChartQuery, mapChartToSeries, getValue, formatMoneyValue, formatNumberValue, getDateFormatter, } from '@fourthwall/module-analytics';
import { useCurrentShop } from '../../../../hooks/useCurrentShop';
import { routing } from '../../../../../../utils/routing';
const dateRangeParams = createDateRangeParams(new Date());
const OPTIONS = [
    { label: 'Today', value: 'today', params: dateRangeParams.today },
    { label: '7 days', value: 'last7days', params: dateRangeParams.last7days },
    { label: '30 days', value: 'last30days', params: dateRangeParams.last30days },
    { label: '90 days', value: 'last90days', params: dateRangeParams.last90days },
];
const isValidValue = (value) => {
    return OPTIONS.some((option) => option.value === value);
};
const displayValue = (value) => {
    if (value === undefined) {
        return 0;
    }
    if (typeof value === 'object') {
        return React.createElement(PriceString, { value: value.value });
    }
    if (typeof value === 'string') {
        return value;
    }
    return React.createElement(NumberString, { value: value });
};
export const useHomeAnalytics = () => {
    const [tabsValue, setTabsValue] = useState(OPTIONS[2].value);
    const { currentShop } = useCurrentShop({ staleTime: Infinity });
    const option = OPTIONS.find((option) => option.value === tabsValue);
    const optionParams = option?.params;
    const params = {
        from: '',
        to: '',
        precision: tabsValue === 'today' ? 'HOUR' : 'DAY',
        sid: [currentShop?.id],
        chartTypes: ['PROFIT', 'CONTRIBUTION', 'CONTRIBUTION_AVG', 'GROSS_SALES'],
        ...optionParams,
    };
    const { analyticsChartQuery } = useAnalyticsChartQuery([params], {
        staleTime: 60_000, // 1 minute,
    });
    const to = `${routing.analytics.self}?range=${tabsValue}`;
    const hasValues = !!analyticsChartQuery.data?.current.profit?.aggregatedValue ||
        !!analyticsChartQuery.data?.current.contribution?.aggregatedValue ||
        !!analyticsChartQuery.data?.current.contribution?.aggregatedValue;
    return {
        tabs: {
            options: [...OPTIONS],
            value: tabsValue,
            onChange: (value) => {
                if (!isValidValue(value))
                    return;
                setTabsValue(value);
            },
        },
        stats: [
            {
                label: 'Estimated profit',
                to,
                value: displayValue(getValue(analyticsChartQuery.data?.current.profit)),
                chart: {
                    series: mapChartToSeries(analyticsChartQuery.data?.current.profit?.data, params, {
                        futureDates: false,
                    }),
                    formatDate: getDateFormatter(params.precision),
                    formatValue: formatMoneyValue,
                },
            },
            {
                label: 'Orders',
                to,
                value: displayValue(getValue(analyticsChartQuery.data?.current.contribution)),
                chart: {
                    series: mapChartToSeries(analyticsChartQuery.data?.current.contribution?.data, params, {
                        futureDates: false,
                    }),
                    formatDate: getDateFormatter(params.precision),
                    formatValue: formatNumberValue,
                },
            },
            {
                label: 'Average order value',
                to,
                value: displayValue(getValue(analyticsChartQuery.data?.current.averageGrossSalesValue)),
                chart: {
                    series: mapChartToSeries(analyticsChartQuery.data?.current.contributionAvg?.data, params, { futureDates: false }),
                    formatDate: getDateFormatter(params.precision),
                    formatValue: formatMoneyValue,
                },
            },
        ].map((stat) => {
            const getChart = () => {
                if (analyticsChartQuery.isLoading) {
                    return { series: [], formatDate: () => '', formatValue: () => '' };
                }
                if (!hasValues) {
                    return undefined;
                }
                return stat.chart;
            };
            return {
                ...stat,
                chart: getChart(),
            };
        }),
    };
};
