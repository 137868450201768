import { isOfferItemType } from '@modules/Dashboard/Settings/modules/Samples/modules/SamplesOrder/modules/SelectItemsModal/utils';
export const getItemsFromOffers = (offers) => {
    if (!offers || !offers.length)
        return [];
    return offers.reduce((previous, current) => {
        if (current.variants.length < 2) {
            const singleVariantOffer = {
                type: 'SINGLE_VARIANT_OFFER',
                id: current.id,
                name: current.name,
                image: current.images[0] ? current.images[0].url : undefined,
                variantId: current.variants[0].id,
                stock: current.variants[0].quantity,
                price: current.variants[0].cost,
                oldPrice: current.variants[0].price,
                disabled: current.variants[0].quantity === 0,
            };
            return [...previous, singleVariantOffer];
        }
        const offer = {
            type: 'OFFER',
            id: current.id,
            name: current.name,
            image: current.images[0] ? current.images[0].url : undefined,
        };
        const offerVariants = current.variants.map((variant) => ({
            type: 'VARIANT',
            variantId: variant.id,
            name: variant.attributesDescription,
            color: variant.colorAttribute && variant.colorAttribute.colorSwatch
                ? {
                    label: variant.colorAttribute.name,
                    value: variant.colorAttribute.colorSwatch,
                }
                : undefined,
            stock: variant.quantity,
            price: variant.cost,
            oldPrice: variant.price,
            disabled: variant.quantity === 0,
        }));
        return [...previous, offer, ...offerVariants];
    }, []);
};
const getSelectedItemPrice = (variantId, items) => {
    const itemWithPrice = items.find((item) => 'variantId' in item && item.variantId === variantId);
    if (!itemWithPrice || isOfferItemType(itemWithPrice))
        return undefined;
    return itemWithPrice.price;
};
export const getInitialSelectedItems = (initialValues, items) => initialValues.items.reduce((acc, { variantId, quantity }) => [
    ...acc,
    {
        variantId,
        quantity: quantity.toString(),
        price: getSelectedItemPrice(variantId, items),
    },
], []);
