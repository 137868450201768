import { useDashboardFeatureFlags } from '../../../../../../components/utils/DashboardFeatureFlags';
import { getAudioPostPayload, getImagePostPayload, getMultipleImagePostPayload, getPollPostPayload, getTextPostPayload, getVideoEmbedPostPayload, getVideoPostPayload, } from '../utils/forms';
import { useCreateAudioPost } from './useCreateAudioPost';
import { useCreateImagePost } from './useCreateImagePost';
import { useCreateMultipleImagePostMutation } from './useCreateMultipleImagePostMutation';
import { useCreatePollPostMutation } from './useCreatePollPostMutation';
import { useCreateTextPostMutation } from './useCreateTextPostMutation';
import { useCreateVideoEmbedPost } from './useCreateVideoEmbedPost';
import { useCreateVideoPost } from './useCreateVideoPost';
export const usePostCreate = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { createTextPostMutation } = useCreateTextPostMutation();
    const { createPollPostMutation } = useCreatePollPostMutation();
    const { createMultipleImagePostMutation } = useCreateMultipleImagePostMutation();
    const { createImagePost, isLoading: isImageLoading } = useCreateImagePost();
    const { createVideoPost, mediaUploadProgress: videoUploadProgress, isLoading: isVideoLoading, } = useCreateVideoPost();
    const { createVideoEmbedPost, isLoading: isVideoEmbedLoading } = useCreateVideoEmbedPost();
    const { createAudioPost, mediaUploadProgress: audioUploadProgress, isLoading: isAudioLoading, } = useCreateAudioPost();
    const createPost = async (values, handleSuccess) => {
        if (values.postType === 'Text') {
            createTextPostMutation.mutate(getTextPostPayload(values), {
                onSuccess: handleSuccess,
            });
        }
        if (values.postType === 'Poll') {
            createPollPostMutation.mutate(getPollPostPayload(values), {
                onSuccess: handleSuccess,
            });
        }
        if (values.postType === 'Image') {
            if (dashboardFeatureFlags.membershipsMultipleImages.enabled) {
                createMultipleImagePostMutation.mutate([getMultipleImagePostPayload(values)], {
                    onSuccess: handleSuccess,
                });
            }
            else {
                const payload = await getImagePostPayload(values);
                createImagePost(values.image, payload, handleSuccess);
            }
        }
        if (values.postType === 'Video') {
            if (values.videoType === 'upload') {
                const payload = await getVideoPostPayload(values);
                const subtitles = typeof values.videoSubtitles === 'object' ? values.videoSubtitles : undefined;
                createVideoPost(values.video, values.videoThumbnail, subtitles, payload, handleSuccess);
            }
            if (values.videoType === 'embed') {
                const payload = await getVideoEmbedPostPayload(values);
                createVideoEmbedPost(values.videoThumbnail, payload, handleSuccess);
            }
        }
        if (values.postType === 'Audio') {
            const payload = await getAudioPostPayload(values);
            createAudioPost(values.audio, values.audioThumbnail, payload, handleSuccess);
        }
    };
    return {
        createPost,
        mediaUploadProgress: videoUploadProgress || audioUploadProgress,
        isLoading: createTextPostMutation.isLoading ||
            createPollPostMutation.isLoading ||
            createMultipleImagePostMutation.isLoading ||
            isImageLoading ||
            isVideoLoading ||
            isVideoEmbedLoading ||
            isAudioLoading,
    };
};
