import { useCallback, useMemo, useState } from 'react';
import { getRadioLabel } from './helpers/getRadioLabel';
import { getSupportersNumberByType } from './helpers/getSupportersNumberByType';
const DEFAULT_LABELS = {
    COLLECTIONS: 'Supporters of selected collections',
    PRODUCTS: 'Supporters of selected products',
    SUPPORTERS: 'Selected supporters',
};
export const useSendToSubsection = ({ setSendToFieldValue, setFinalSupportersCount, }) => {
    const [radioButtonLabels, setRadioButtonLabels] = useState(DEFAULT_LABELS);
    const handleSetRadioButtonsLabel = useCallback((type) => (selectedItems) => {
        const supportersSum = getSupportersNumberByType({ selectedItems, type });
        const label = getRadioLabel({ supportersSum, type, defaultLabels: DEFAULT_LABELS });
        setRadioButtonLabels({ ...DEFAULT_LABELS, [type]: label });
        setFinalSupportersCount(type, supportersSum);
    }, [setFinalSupportersCount]);
    const handleSetFieldValue = useCallback((e, recipientFilter) => {
        if (e.target.checked) {
            setSendToFieldValue(recipientFilter);
            setFinalSupportersCount(recipientFilter.type);
        }
    }, [setSendToFieldValue, setFinalSupportersCount]);
    return useMemo(() => ({
        radioButtonLabels,
        setRadioButtonLabels: handleSetRadioButtonsLabel,
        setFieldValue: handleSetFieldValue,
    }), [radioButtonLabels, handleSetRadioButtonsLabel, handleSetFieldValue]);
};
