import { Fieldset, InputUI, PaymentProvidersIcons } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const StripeField = ({ field, append }) => (React.createElement("div", null,
    React.createElement(InputUI.Box, { invalid: !!field.error, focused: field.isFocused, fullWidth: true },
        React.createElement(S.Input, { ref: field.ref }),
        append),
    field.error && React.createElement(InputUI.Error, null, field.error)));
export const StripeForm = ({ fields }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(StripeField, { field: fields.cardNumber, append: React.createElement(S.CardIconsWrapper, null,
                        React.createElement(PaymentProvidersIcons, { type: ['creditCards'] })) }))),
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(StripeField, { field: fields.cardExpiry })),
            React.createElement(Fieldset.Item, null,
                React.createElement(StripeField, { field: fields.cardCvc })))));
};
