import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const LoaderContainer = styled.div `
  height: 100%;
  display: flex;
  justify-content: center;
`;
export const BottomContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  ${mediaQueries.tablet} {
    gap: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const RightBottomContentContainer = styled.div `
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 12px;
  width: 100%;

  ${mediaQueries.tablet} {
    flex-direction: row;
    gap: 16px;
    width: auto;
  }
`;
export const DescriptionPoint = styled.div `
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 4px;
`;
export const DescriptionContent = styled.div `
  line-height: 24px;
`;
export const DescriptionParagraph = styled.div `
  margin-bottom: 24px;
  font-size: 16px;
`;
export const WarningContainer = styled.div `
  margin-bottom: 24px;
  padding: 24px;
  background: ${Colors.GS200};
  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GS600};
`;
