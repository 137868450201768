import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ExifReader from 'exifreader';
import { useMutation } from '@tanstack/react-query';
import { FilesList, Toasts } from '@fourthwall/components';
import { formatBytes } from '@fourthwall/utils/lib/file';
import { OffersApiClient } from '@fourthwall/services/lib/OffersApiClient';
import { getRejectionErrors } from '@utils/dropzone';
const supportedExifTypes = [
    'image/jpeg',
    'image/tiff',
    'image/png',
    'image/heic',
    'image/webp',
    'image/gif',
];
export const ProductFiles = ({ offerId, files, maxSize = 2147483648, isFormEditable, }) => {
    const { setFieldValue } = useFormikContext();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [rejectedFileId, setRejectedFileId] = useState(null);
    const uploadFileMutation = useMutation({
        mutationFn: async ({ offerId, file }) => {
            const result = await OffersApiClient.uploadFile(offerId, file);
            if (!('data' in result)) {
                throw new Error('Failed to upload file');
            }
            return result.data;
        },
    });
    useEffect(() => {
        if (uploadedFiles.length > 0) {
            setFieldValue('digitalItems', files.map((item) => {
                const uploadedFile = uploadedFiles.find((file) => file.id === item.id);
                if (uploadedFile) {
                    return { ...item, isUploading: false, url: uploadedFile.url };
                }
                return item;
            }));
        }
    }, [uploadedFiles]);
    useEffect(() => {
        if (rejectedFileId) {
            setFieldValue('digitalItems', files
                .map((item) => (item.id === rejectedFileId ? null : item))
                .filter((item) => item !== null));
        }
    }, [rejectedFileId]);
    const uploadFile = async ({ id, offerId, file }) => {
        await uploadFileMutation.mutateAsync({ offerId, file }, {
            onSuccess: (data) => {
                setUploadedFiles((state) => [...state, { id, name: file.name, url: data.fileUrl }]);
            },
            onError: (error) => {
                setRejectedFileId(id);
                if (error instanceof Error) {
                    Toasts.notify(error.message, { type: 'error' });
                }
            },
        });
    };
    const getHasLocationMetadata = async (file) => {
        if (!supportedExifTypes.includes(file.type)) {
            return false;
        }
        const metadataTags = await ExifReader.load(file);
        return Object.keys(metadataTags).some((tag) => tag.includes('GPS'));
    };
    const handleFileDrop = async (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles[0] && offerId) {
            const file = acceptedFiles[0];
            const tmpImageId = uuidv4();
            const hasLocationMetadata = await getHasLocationMetadata(file);
            const value = [
                ...files,
                {
                    id: tmpImageId,
                    name: file.name,
                    size: formatBytes(file.size),
                    isUploading: true,
                    hasLocationMetadata,
                },
            ];
            setFieldValue('digitalItems', value);
            uploadFile({ id: tmpImageId, offerId, file });
        }
    };
    const handleFileRemove = (file) => {
        const value = files.filter((digitalItem) => digitalItem.id !== file.id);
        setFieldValue('digitalItems', value);
    };
    const handleDropRejection = (rejections) => {
        getRejectionErrors(rejections).forEach((rejection) => {
            Toasts.notify(rejection, { toastId: rejection });
        });
    };
    return (React.createElement(FilesList, { maxSize: maxSize, data: files, disabled: !isFormEditable, onFileDrop: handleFileDrop, onFileDropRejected: handleDropRejection, onFileRemove: handleFileRemove }));
};
