import React from 'react';
import * as S from './styled';
export const LayoutMobile = ({ content, contentHeader, contentFooter, footer, hideFooter, }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.MainContent, null,
            content,
            React.createElement(S.ContentHeader, null, contentHeader),
            React.createElement(S.ContentFooter, null, contentFooter)),
        !hideFooter && React.createElement(S.Footer, null, footer)));
};
