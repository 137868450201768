import { Banner, Loader, ModalBox, Toasts, useModalContext } from '@fourthwall/components';
import React from 'react';
import { CostsBreakdown } from '@components/common/CostsBreakdown';
import { routing } from '@utils/routing';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import axios from 'axios';
import { useCostsBreakdownQuery } from './hooks/useCostsBreakdownQuery';
export const OrderCostsBreakdownModal = ({ orderId, order }) => {
    const { close } = useModalContext();
    const { orderCostsBreakdownQuery } = useCostsBreakdownQuery([orderId], {
        onError: (error) => {
            if (axios.isAxiosError(error)) {
                const errorMessage = errorMessageSelector(error.response?.data, 'Failed to fetch costs breakdown. Please try again.');
                Toasts.notify(errorMessage, { type: 'error' });
            }
            close();
        },
    });
    const renderBanners = () => {
        const banners = [];
        if (orderCostsBreakdownQuery?.data && order?.salesChannel.type === 'TikTok') {
            banners.push({
                children: (React.createElement(React.Fragment, null,
                    "Below you see only Fourthwall manufacturing and shipping costs for this order. To see the profit you made visit",
                    ' ',
                    React.createElement("a", { href: `${routing.external.tiktok.orderDetails}?order_no=${order?.salesChannel?.id}&shop_region=US`, target: "_blank", rel: "noopener noreferrer" }, "TikTok Seller Center"),
                    ".")),
                appearance: 'warning',
            });
        }
        return banners.map((banner, index) => (React.createElement("div", { style: { marginBottom: 20 } },
            React.createElement(Banner, { key: index, ...banner }))));
    };
    const renderContent = () => {
        if (!orderCostsBreakdownQuery.data) {
            return (React.createElement("div", { style: { height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                React.createElement(Loader, { size: "large" })));
        }
        return React.createElement(CostsBreakdown, { costsBreakdown: orderCostsBreakdownQuery.data });
    };
    return (React.createElement(ModalBox, { title: "Costs breakdown", onClose: close },
        renderBanners(),
        renderContent()));
};
