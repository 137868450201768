import styled from 'styled-components';
export const InputWrapper = styled.div `
  cursor: pointer;
  position: relative;

  & input {
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
`;
