import { BaseContent, Button, Heading } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const MissingPermissions = ({ onTryAgain }) => {
    return (React.createElement(S.Container, null,
        React.createElement(Heading, { type: "h6", weight: "semibold" },
            React.createElement(S.HeadingText, null, "Allow to use your camera and microphone")),
        React.createElement(BaseContent, { appearance: "secondary" }, "To record thank you notes, please grant permission to access your camera and microphone then refresh this page."),
        React.createElement(S.Button, null,
            React.createElement(Button, { appearance: "primary", label: "Try again", onClick: onTryAgain }))));
};
