import { getImageDirectUploadData } from '../../../../utils/getImageDirectUploadData';
import { getCommonFieldsPayload } from './getCommonFieldsPayload';
export const getVideoPostPayload = async (values) => {
    const payload = getCommonFieldsPayload(values);
    return {
        ...payload,
        attributes: {
            core: payload.attributes.core,
            series: values.postType === 'Video' ? values.videoSeries : undefined,
        },
        imageDirectUploadData: typeof values.videoThumbnail === 'object'
            ? await getImageDirectUploadData(values.videoThumbnail)
            : undefined,
        new_media_direct_upload: typeof values.video === 'object',
        remove_thumbnail: values.videoThumbnail === undefined,
    };
};
