import React from 'react';
import { generateColorFromText } from '../../utils/colors';
import * as S from './styled';
export const LogoImage = ({ src, backgroundUrl, placeholder, placeholderVariant = 'mono', size = 'large', aspectRatio = 1.375, }) => {
    const placeholderBackground = placeholderVariant === 'color' && typeof placeholder === 'string'
        ? generateColorFromText(placeholder)
        : undefined;
    const placeholderColor = placeholderBackground ? '#fff' : undefined;
    return (React.createElement(S.Container, { size: size, aspectRatio: aspectRatio, style: { background: src && backgroundUrl ? `url(${backgroundUrl}` : undefined } }, src ? (React.createElement(S.Image, { src: src })) : (React.createElement(S.ImagePlaceholder, { size: size, style: { background: placeholderBackground, color: placeholderColor } }, placeholder))));
};
