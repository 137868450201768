import { useModalContext } from '@fourthwall/components';
import { useFormikContext } from 'formik';
import get from 'lodash-es/get';
import uniqueBy from 'lodash-es/uniqBy';
import { reverse } from 'named-urls';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { routing } from '@utils/routing';
import { useCustomizationQuery } from '../hooks/useCustomizationQuery';
import { useProductBySlugQuery } from '../hooks/useProductBySlugQuery';
import { getFulfillmentType, isFourthwallFulfilled, getOfferTypeUtil, getCostsPerSize, getSameSizesHaveDifferentCosts, getCosts, getSizesWithHigherCosts, } from '../store/product/utils';
import { getHasDifferentSizes } from '../utils';
import { CalculateProfit } from './components/CalculateProfit';
import { CalculateProfitForPerSizeProduct } from './components/CalculateProfitForPerSizeProduct/CalculateProfitForPerSizeProduct';
import { ProductPricingView } from './ProductPricingView';
import { calculateProfit } from './utils/calculateProfit';
import { filterVariantsBySize } from './utils/filterVariantsBySize';
import { getSizePriceSuggestion } from './utils/getSizePriceSuggestion';
import { getTableHeaders } from './utils/getTableHeaders';
import { useAdminModeContext } from '../../../App/providers/AdminModeProvider';
import { useOfferQuery } from '../queries/useOfferQuery';
export const ProductPricingContainer = ({ pricingType, togglePricingType, applyToAllVariants, }) => {
    const { productId = '' } = useParams();
    const { offerQuery } = useOfferQuery([productId]);
    // context hooks
    const adminMode = useAdminModeContext();
    const { hasPermission } = useDashboardPermissions();
    const { open } = useModalContext();
    const { errors, values: { variants, fulfillmentService, isCompareAtPriceEnabled }, } = useFormikContext();
    const product = offerQuery.data;
    const manufacturingService = product?.manufacturingService || 'FOURTHWALL_BESPOKE';
    const offerType = getOfferTypeUtil(manufacturingService);
    const variantTypes = product?.variantTypes || [];
    const costsPerSize = getCostsPerSize(offerQuery.data);
    const sameSizesHaveDifferentCosts = getSameSizesHaveDifferentCosts(costsPerSize);
    const productCosts = getCosts(offerQuery.data);
    const sizesWithHigherCosts = getSizesWithHigherCosts(offerQuery.data, productCosts);
    // react-query hooks
    // TODO: this is a temporary solution, we should use the FLAG that is set in the
    // fw-product admin to determine if we should show Explicit or Implicit pricing
    const { customizationQuery, customization } = useCustomizationQuery(product?.customizationId);
    const { productBySlugQuery } = useProductBySlugQuery([customization?.product.slug], {
        enabled: customizationQuery.isSuccess,
    });
    const isLoading = customizationQuery.isInitialLoading || productBySlugQuery.isInitialLoading;
    // computed values
    const isOnlyExplicitPricingType = productBySlugQuery.data?.pricingType === 'MULTI_PRICE';
    const data = filterVariantsBySize(variants);
    const sizeVariant = variantTypes.find((variant) => variant.type === 'SIZE');
    const hasDifferentSizes = getHasDifferentSizes(sizeVariant);
    const isMissingCosts = !!productCosts && productCosts.includes(null) && !!product?.isSettingCostsRequired;
    const sizePriceSuggestion = getSizePriceSuggestion(hasDifferentSizes, sizesWithHigherCosts, isFourthwallFulfilled(getFulfillmentType(fulfillmentService)));
    const isAdmin = !!adminMode?.isActive;
    const isDisabled = !hasPermission('products.edit.details');
    // NOTES: For per size products, we always want to show ONLY the explicit pricing type
    useEffect(() => {
        if (isOnlyExplicitPricingType && pricingType === 'IMPLICIT') {
            togglePricingType();
        }
    }, [isOnlyExplicitPricingType, pricingType]);
    useEffect(() => {
        if (!isCompareAtPriceEnabled) {
            applyToAllVariants({ compareAtPriceValue: null });
        }
    }, [isCompareAtPriceEnabled]);
    const handlePriceValueBlur = (priceValue) => applyToAllVariants({ priceValue });
    const handleCompareAtPriceValueBlur = (compareAtPriceValue) => applyToAllVariants({ compareAtPriceValue });
    const openCalculateProfitModal = () => {
        if (isOnlyExplicitPricingType) {
            const perSizePrice = variants.map((variant) => ({
                price: variant.price.value.toString(),
                cost: variant.cost?.value || 0,
                label: variant.size || '',
            }));
            const uniquePerSizePrice = uniqueBy(perSizePrice, 'label');
            open(CalculateProfitForPerSizeProduct, {
                perSizePrice: uniquePerSizePrice,
            });
            return;
        }
        open(CalculateProfit, {
            productId,
            pricingType,
            sizePriceSuggestion: pricingType === 'IMPLICIT' ? sizePriceSuggestion : undefined,
            initialPriceValue: variants[0].price.value,
        });
    };
    const handleToggleView = () => {
        applyToAllVariants({});
        togglePricingType();
    };
    return (React.createElement(ProductPricingView, { allowExplicitType: hasDifferentSizes && !isMissingCosts && !sameSizesHaveDifferentCosts, onlyExplicitTypeAvailable: isOnlyExplicitPricingType, implicitSellingPriceError: get(errors, 'sellingPriceValue'), pricingType: pricingType, sizePriceSuggestion: sizePriceSuggestion, isAdmin: isAdmin, isLoading: isLoading, profit: calculateProfit(variants, offerType), costsLink: reverse(routing.products.all.product.costs, { productId }), data: data, tableHeaders: getTableHeaders({
            variants,
            errors,
            applyToAllVariants,
            disabled: isDisabled,
            showCompareAtPrice: isCompareAtPriceEnabled,
        }), showMissingCostsBanner: ['ON_DEMAND', 'MOQ'].includes(offerType) && isMissingCosts, disabled: isDisabled, isCompareAtPriceEnabled: isCompareAtPriceEnabled, applyToAllVariants: applyToAllVariants, onLearnMoreClick: openCalculateProfitModal, onToggleView: handleToggleView, onPriceBlur: handlePriceValueBlur, onCompareAtPriceBlur: handleCompareAtPriceValueBlur }));
};
