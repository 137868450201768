import { useModalContext } from '@fourthwall/components';
import React from 'react';
import { ThemeEditorProvider } from '../../../../providers/ThemeEditorProvider';
import { useThemeList } from '../../hooks/useThemeList';
import { ThemeModalView } from './ThemeModalView';
import { useThemes } from './hooks/useThemes';
import { getPresetColor, getPresetsColors, getSelectedPreset } from './utils';
const ThemeModalContainerComponent = ({ title, onAddTheme }) => {
    const { close } = useModalContext();
    const { libraryThemes, themeList } = useThemeList();
    const { themes, previewTheme, previewSelectedPreset, setPreviewTheme, setSelectedPreset } = useThemes(libraryThemes, themeList);
    const handleAddTheme = (theme) => {
        onAddTheme({
            id: theme.id,
            presetHandle: getSelectedPreset(theme)?.handle,
        });
    };
    const handlePresetSelect = ({ label }, theme) => {
        setSelectedPreset(theme, label);
    };
    const getThemePrimaryButtonProps = (theme) => {
        return {
            label: 'Add this theme',
            mobileLabel: 'Add',
            onClick: () => {
                handleAddTheme(theme);
                close();
            },
        };
    };
    const getCardSecondaryButtonProps = (theme) => ({
        label: 'Preview theme',
        onClick: () => setPreviewTheme(theme),
    });
    const getThemeCardProps = () => {
        return themes.map((theme) => {
            const selectedPreset = getSelectedPreset(theme);
            return {
                theme,
                primaryButtonProps: getThemePrimaryButtonProps(theme),
                secondaryButtonProps: getCardSecondaryButtonProps(theme),
                colorwayProps: {
                    colors: getPresetsColors(theme.presets),
                    activeColor: getPresetColor(selectedPreset),
                    onChange: (color) => handlePresetSelect(color, theme),
                },
            };
        });
    };
    return (React.createElement(ThemeModalView, { title: title, themeCardProps: getThemeCardProps(), preview: previewTheme
            ? {
                theme: previewTheme,
                toolbar: {
                    primaryButtonProps: getThemePrimaryButtonProps(previewTheme),
                    colorwayProps: {
                        colors: getPresetsColors(previewTheme?.presets),
                        activeColor: getPresetColor(previewSelectedPreset),
                        onChange: (color) => handlePresetSelect(color, previewTheme),
                    },
                },
            }
            : undefined, onPreviewClose: () => setPreviewTheme(null), onModalClose: close }));
};
export const ThemeModalContainer = (props) => (React.createElement(ThemeEditorProvider, null,
    React.createElement(ThemeModalContainerComponent, { ...props })));
