import { TextBlockEllipsis, Upload, UploadedAsset, useMediaQueryContext, } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { useCurrentShop } from '../../../../../hooks/useCurrentShop';
import { useSocialImage } from './hooks/useSocialImage';
import * as S from './styled';
export const SocialImage = () => {
    const media = useMediaQueryContext();
    const { values } = useFormikContext();
    const { currentShop } = useCurrentShop();
    const { handleFileDrop, handleFileDropRejected, handleRemove } = useSocialImage();
    const renderUpload = () => {
        const image = values.socialImage;
        if (image) {
            return (React.createElement(UploadedAsset, { id: "1", src: image, onRemove: handleRemove },
                React.createElement(S.Image, { src: image })));
        }
        return (React.createElement(Upload, { accept: {
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
            }, title: media.tablet ? 'Drop image or browse' : 'Browse', maxSize: 1048576, onFileDrop: handleFileDrop, onFileDropRejected: handleFileDropRejected }));
    };
    return (React.createElement(S.Wrapper, null,
        React.createElement(S.Name, null, "Social sharing image"),
        React.createElement(S.Description, null,
            React.createElement("p", null, "Image that appears when you share your site on social media."),
            React.createElement("p", null, "Recommended size: 1200 x 628 px")),
        React.createElement(S.Container, null,
            React.createElement(S.UploadContainer, null, renderUpload()),
            React.createElement(S.SocialInfoContainer, null,
                React.createElement(S.Name, null,
                    React.createElement(TextBlockEllipsis, { maxLine: 1, text: values.name })),
                React.createElement("span", null,
                    React.createElement(TextBlockEllipsis, { maxLine: 1, text: `https://${currentShop?.domain}` })),
                React.createElement(S.SocialDescription, null,
                    React.createElement(TextBlockEllipsis, { maxLine: 2, text: values.description }))))));
};
