import reduce from 'lodash-es/reduce';
import { FREE_TIER_ID } from '../../consts';
export const getTierIds = (type, formTiers) => {
    return reduce(formTiers, (sum, enabled, id) => {
        if (parseInt(id) === FREE_TIER_ID && type !== 'Custom')
            return sum;
        if (parseInt(id) === FREE_TIER_ID && enabled) {
            sum.push({
                type: 'free',
            });
            return sum;
        }
        if (enabled) {
            sum.push({
                type: 'paid',
                id: parseInt(id),
            });
        }
        return sum;
    }, []);
};
