import { ButtonClean, PriceString } from '@fourthwall/components';
import React from 'react';
import { formatDate, isSameDay } from '../../../utils/date';
import * as S from './OrderTimelineEvent.styled';
export const OrderTimelineEvent = ({ timelineEvent, timelineFulfillment }) => {
    if (timelineEvent?.eventType.type === 'ORDER_PAID') {
        return (React.createElement(S.Container, null,
            "Order placed for ",
            React.createElement(PriceString, { value: timelineEvent.eventType.money?.value })));
    }
    if (timelineEvent?.eventType.type === 'SHIPPING_LABEL_CREATED') {
        const { shippingLabelCarrier, shippingLabelTrackingCode, trackerUrl } = timelineEvent.eventType.shipmentTracker;
        return (React.createElement(S.Container, null,
            "Shipping label created for ",
            shippingLabelCarrier,
            " (",
            shippingLabelTrackingCode,
            ")",
            ' ',
            trackerUrl && React.createElement(ButtonClean, { href: trackerUrl, target: "_blank", label: "[Track Package]" })));
    }
    if (timelineEvent?.eventType.type === 'SHIPMENT_SHIPPED') {
        const { shippingLabelCarrier, shippingLabelTrackingCode, trackerUrl } = timelineEvent.eventType.shipmentTracker;
        return (React.createElement(S.Container, null,
            "Package shipped with ",
            shippingLabelCarrier,
            " (",
            shippingLabelTrackingCode,
            ")",
            ' ',
            trackerUrl && React.createElement(ButtonClean, { href: trackerUrl, target: "_blank", label: "[Track Package]" })));
    }
    if (timelineEvent?.eventType.type === 'SHIPMENT_DELIVERED') {
        const { shippingLabelTrackingCode } = timelineEvent.eventType.shipmentTracker;
        return React.createElement(S.Container, null,
            "Package (",
            shippingLabelTrackingCode,
            ") was delivered");
    }
    if (timelineFulfillment?.shipments[0]?.deliveryEstimate) {
        const from = timelineFulfillment.shipments[0].deliveryEstimate.from;
        const to = timelineFulfillment.shipments[0].deliveryEstimate.to;
        const dateString = isSameDay(from, to)
            ? `on ${formatDate(from, 'dddd, MMM D')}`
            : `between ${formatDate(from, 'dddd, MMM D')} and ${formatDate(to, 'dddd, MMM D')}`;
        return React.createElement(S.Container, null,
            "Estimated delivery ",
            dateString);
    }
    return React.createElement(S.Container, null, timelineEvent?.description);
};
