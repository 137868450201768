import get from 'lodash-es/get';
import isNil from 'lodash-es/isNil';
import React from 'react';
export const withFormikSupport = (Component) => function ({ invalid = false, error, field, form, onChange, onBlur, ...other }) {
    const formikTouched = field && form && get(form.touched, field.name);
    const formikError = field && form && get(form.errors, field.name);
    const getValue = (eventOrValue) => {
        if (eventOrValue?.target?.type === 'checkbox') {
            return !isNil(eventOrValue?.target?.checked) ? eventOrValue?.target?.checked : eventOrValue;
        }
        return !isNil(eventOrValue?.target?.value) ? eventOrValue?.target?.value : eventOrValue;
    };
    const handleChange = (newValue) => {
        if (form && field) {
            form.setFieldValue(field.name, getValue(newValue));
        }
        if (onChange) {
            return onChange(newValue);
        }
        return null;
    };
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
        if (form && field) {
            form.setFieldTouched(field.name, true);
        }
    };
    return (React.createElement(Component, { ...other, ...field, invalid: invalid || error || ((formikTouched || form?.submitCount) && !!formikError), error: error || ((formikTouched || form?.submitCount) && formikError), onChange: handleChange, onBlur: handleBlur }));
};
