export var FormFields;
(function (FormFields) {
    FormFields["mode"] = "mode";
    FormFields["liveAt"] = "liveAt";
    FormFields["access"] = "access";
    FormFields["postType"] = "postType";
    FormFields["title"] = "title";
    FormFields["description"] = "description";
    FormFields["notifyMembers"] = "notifyMembers";
    FormFields["image"] = "image";
    FormFields["images"] = "images";
    FormFields["videoType"] = "videoType";
    FormFields["video"] = "video";
    FormFields["videoThumbnail"] = "videoThumbnail";
    FormFields["videoSubtitles"] = "videoSubtitles";
    FormFields["videoUrl"] = "videoUrl";
    FormFields["videoSeries"] = "videoSeries";
    FormFields["audio"] = "audio";
    FormFields["audioThumbnail"] = "audioThumbnail";
    FormFields["pollOptions"] = "pollOptions";
    FormFields["pollLength"] = "pollLength";
    FormFields["livestreamType"] = "livestreamType";
    FormFields["livestreamUrl"] = "livestreamUrl";
    FormFields["tags"] = "tags";
    FormFields["tierIds"] = "tierIds";
})(FormFields || (FormFields = {}));
