import React from 'react';
import { DropdownProvider, useDropdown } from './DropdownContext';
import { DropdownDivider } from './DropdownDivider/DropdownDivider';
import { DropdownItem } from './DropdownItem/DropdownItem';
import { Popper } from '../Popper';
import * as S from './styled';
export const Dropdown = (props) => {
    return (React.createElement(DropdownProvider, null,
        React.createElement(DropdownContent, { ...props })));
};
export const DropdownContent = ({ isOpen: isOpenProp, content, footer, position = 'bottom-start', optionsVerticalOffset = 8, optionsHorizontalOffset = 0, fluid = false, maxHeight, maxWidth = 240, testid = 'Dropdown', children, onClose, }) => {
    const { isOpen, close, toggle } = useDropdown();
    const isExternallyControlled = isOpenProp !== undefined;
    return (React.createElement(Popper, { isOpen: isExternallyControlled ? isOpenProp : isOpen, referenceComponent: React.createElement(S.Container, { "data-testid": testid }, React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    onClick: () => {
                        child.props.onClick?.();
                        !isExternallyControlled && toggle();
                    },
                });
            }
        })), popperProps: {
            placement: position,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [optionsHorizontalOffset, optionsVerticalOffset],
                    },
                },
            ],
        }, onClose: isExternallyControlled && onClose ? onClose : close },
        React.createElement(S.Dropdown, { role: "menu", fluid: fluid, maxWidth: maxWidth },
            React.createElement(S.Options, { maxHeight: maxHeight }, content),
            footer && (React.createElement(React.Fragment, null,
                React.createElement(DropdownDivider, null),
                footer)))));
};
Dropdown.Item = DropdownItem;
Dropdown.Divider = DropdownDivider;
