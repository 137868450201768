import React from 'react';
import { List, useMediaQueryContext } from '@fourthwall/components';
import { DnsRecordListItem } from './DnsRecordListItem';
import * as S from './DnsRecordsData.styled';
import { getErrorTooltipText, getTableHeaders } from './utils';
export const DnsRecordsData = ({ records, isVerificationInProgress }) => {
    const media = useMediaQueryContext();
    if (!media.tablet) {
        return (React.createElement(List, { data: records, renderItem: (record) => React.createElement(DnsRecordListItem, { record: record }) }));
    }
    const tableHeaders = getTableHeaders(isVerificationInProgress);
    const renderData = () => records.map((record, index) => (React.createElement(S.Row, { key: index },
        React.createElement(S.Content, null, tableHeaders.map(({ id, render, width, alignment }) => (React.createElement(S.Cell, { key: `${id}${index}`, flex: width, alignment: alignment }, render(record, index))))),
        record.lookupValidationError && React.createElement(S.Error, null, getErrorTooltipText(record)))));
    return (React.createElement(S.TableContainer, null,
        React.createElement(S.Headers, null, tableHeaders.map(({ id, width, alignment, label }) => (React.createElement(S.HeadCell, { key: id, id: id, flex: width, alignment: alignment }, label)))),
        renderData()));
};
