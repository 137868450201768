import React from 'react';
import { Fieldset, Subsection } from '@fourthwall/components';
import { ShipmentWeightField } from '@modules/Dashboard/Product/modules/shipping/shared/ShippingWeight/ShipmentWeightField';
import { ShippingStartDate } from '@modules/Dashboard/Product/modules/shipping/shared/ShippingStartDate/ShippingStartDate';
import { TaxFields } from '@modules/Dashboard/Product/modules/shipping/shared/TaxFields/TaxFields';
import * as S from './styled';
export const ProductShippingNotConfigurable = ({ initialValues, }) => {
    return (React.createElement(Subsection, { title: "Shipping" },
        React.createElement(S.Container, null,
            React.createElement("p", null, "This product is produced to order. During checkout, supporters pay the cost of shipping, based on the weight of their order.")),
        React.createElement(Fieldset, null,
            React.createElement(ShipmentWeightField, null),
            React.createElement(ShippingStartDate, { initialValues: initialValues }),
            React.createElement(TaxFields, null))));
};
