import React, { useState } from 'react';
import { QuestionMarkFilledDarkIcon, QuestionMarkFilledIcon, QuestionMarkIcon, } from '@fourthwall/icons';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import * as S from './styled';
const getSize = (size) => {
    switch (size) {
        case 'xsmall':
            return 12;
        case 'small':
            return 15;
        case 'medium':
        default:
            return 18;
    }
};
export const InfoTooltip = ({ appearance = 'primary', dark, size = 'small', content, placement, fluid = false, }) => {
    const [isTooltipHover, setIsTooltipHover] = useState(false);
    const getComponent = () => {
        if (isTooltipHover) {
            if (dark) {
                return QuestionMarkFilledDarkIcon;
            }
            return QuestionMarkFilledIcon;
        }
        return QuestionMarkIcon;
    };
    if (!content) {
        return null;
    }
    return (React.createElement(S.TooltipContainer, { isOpen: isTooltipHover, appearance: appearance, onMouseEnter: () => setIsTooltipHover(true), onMouseLeave: () => setIsTooltipHover(false), "data-testid": "InfoTooltip" },
        React.createElement(Tooltip, { reference: React.createElement(Icon, { component: getComponent(), height: getSize(size) }), fluid: fluid, strategy: "fixed", placement: placement }, content)));
};
