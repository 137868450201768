import { useModalContext } from '@fourthwall/components';
import { getPrice } from '@fourthwall/utils';
import React from 'react';
import { PaymentMethodRequiredModal } from '../../../../../../PaymentMethods';
import { useCalculateCancelRefundsQuery } from '../../hooks/useCalculateCancelRefundsQuery';
import { OrderCancelConfirmModalView } from './OrderCancelConfirmModalView';
export const OrderCancelConfirmModalContainer = ({ orderId, itemsToCancel, content, error, reasonOptions, initialReason, close, onConfirm, }) => {
    const { open } = useModalContext();
    const { calculateCancelRefundsQuery } = useCalculateCancelRefundsQuery([
        orderId,
        { itemsToCancel },
    ]);
    const openPaymentMethodRequiredModal = (values) => {
        if (calculateCancelRefundsQuery.data?.estimatedCost.type !== 'LOSS' ||
            calculateCancelRefundsQuery.data?.estimatedCost.chargeDetails.type !== 'REQUIRED') {
            return;
        }
        const chargeDetails = calculateCancelRefundsQuery.data?.estimatedCost.chargeDetails;
        const fromBalance = calculateCancelRefundsQuery.data?.estimatedCost.fromBalance;
        const balancePrice = getPrice(fromBalance.value);
        const remainingPrice = getPrice(chargeDetails.amount.value);
        const balanceSplitCopy = fromBalance.value > 0
            ? `${balancePrice} will be deducted from your account balance and the remaining ${remainingPrice} will be charged to your payment method. `
            : '';
        const subtitle = (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                balanceSplitCopy,
                "Refunds are sent directly to the supporter's original payment method."),
            React.createElement("p", null,
                React.createElement("b", null,
                    "Payment method to cover ",
                    remainingPrice))));
        open(PaymentMethodRequiredModal, {
            title: `Are you sure you want to cancel these items?`,
            subtitle,
            selectPaymentMethodSubtitle: subtitle,
            submitLabel: `Confirm`,
            onSuccess: () => {
                onConfirm({
                    fromBalance,
                    charge: chargeDetails.amount,
                }, values.reason.length ? values.reason : undefined);
            },
        });
    };
    const handleSubmit = (values) => {
        close();
        if (calculateCancelRefundsQuery.data?.estimatedCost.type === 'LOSS' &&
            calculateCancelRefundsQuery.data?.estimatedCost.chargeDetails.type === 'REQUIRED') {
            openPaymentMethodRequiredModal(values);
            return;
        }
        if (calculateCancelRefundsQuery.data?.estimatedCost.type === 'LOSS' &&
            calculateCancelRefundsQuery.data?.estimatedCost.chargeDetails.type === 'NOT_REQUIRED') {
            onConfirm({
                fromBalance: calculateCancelRefundsQuery.data?.estimatedCost.fromBalance,
                charge: { value: 0, currency: 'USD' },
            }, values.reason.length ? values.reason : undefined);
            return;
        }
        onConfirm(undefined, values.reason.length ? values.reason : undefined);
    };
    return (React.createElement(OrderCancelConfirmModalView, { content: content, error: error, initialValues: { reason: initialReason || '' }, reasonOptions: reasonOptions, isLoading: calculateCancelRefundsQuery.isFetching, onSubmit: handleSubmit, onClose: close }));
};
