import { AudioIcon, PhotoIcon, PollIcon, TypographyIcon, VideoIcon } from '@fourthwall/icons';
import React from 'react';
import { routing } from '../../../../../../../utils/routing';
import { addUtmSourceParamToUrl } from '../../../../../../../utils/url';
import { NewPostLinks } from '../../components/NewPostLinks/NewPostLinks';
import createNewPost from './assets/create-a-new-post.png';
import membersOnlyDiscord from './assets/members-only-discord.png';
import membersOnlyProducts from './assets/members-only-products.png';
import membersOnlyPromotions from './assets/members-only-promotions.png';
import setUpPerks from './assets/set-up-perks.png';
import setUpWelcomePost from './assets/set-up-welcome-post.png';
import twitchDiscounts from './assets/twitch-discounts.png';
export const POST_LINKS = [
    {
        icon: TypographyIcon,
        type: 'Text',
    },
    {
        icon: PhotoIcon,
        type: 'Image',
    },
    {
        icon: VideoIcon,
        type: 'Video',
    },
    {
        icon: AudioIcon,
        type: 'Audio',
    },
    {
        icon: PollIcon,
        type: 'Poll',
    },
    // {
    //   icon: StreamIcon,
    //   type: 'LiveStream',
    // },
];
export const tiersSuggestion = {
    title: 'Set up your perks and tiers',
    description: 'Perks determine the benefits your members receive',
    imageUrl: setUpPerks,
    buttonLabel: 'Set up perks and tiers',
    buttonTo: routing.memberships.perks,
};
export const newPostSuggestion = {
    title: 'Create a new post',
    description: 'Add more content to engage with your members',
    content: (React.createElement(NewPostLinks, { links: POST_LINKS, getLinkHref: ({ type }) => `${routing.memberships.posts.create}?type=${type}` })),
    imageUrl: createNewPost,
};
export const welcomePostSuggestion = {
    title: 'Create welcome post',
    description: 'Create a post that appears to new members after they subscribe',
    imageUrl: setUpWelcomePost,
    buttonLabel: 'Create welcome post',
    buttonTo: routing.memberships.settings.welcomePost,
};
export const discordSuggestion = {
    title: 'Set up members-only Discord access',
    description: 'Grant members exclusive roles and access on Discord',
    imageUrl: membersOnlyDiscord,
    buttonLabel: 'Set up Discord perk',
    buttonTo: routing.memberships.perks,
};
export const productsSuggestion = {
    title: 'Offer members-only products',
    description: 'Add exclusive products that only members can buy',
    imageUrl: membersOnlyProducts,
    buttonLabel: 'Learn more',
    buttonHref: addUtmSourceParamToUrl('https://help.fourthwall.com/hc/en-us/articles/13331279583387-Perks-A-Guide-to-Rewarding-Your-Members#members-only-products', 'onboarding'),
    buttonTarget: '_blank',
};
export const promotionsSuggestion = {
    title: 'Offer members-only promotions',
    description: 'Let your members purchase selected products at a discount',
    imageUrl: membersOnlyPromotions,
    buttonLabel: 'Learn more',
    buttonHref: addUtmSourceParamToUrl('https://help.fourthwall.com/hc/en-us/articles/13331279583387-Perks-A-Guide-to-Rewarding-Your-Members#members-only-discounts', 'onboarding'),
    buttonTarget: '_blank',
};
export const twitchDiscountSuggestion = {
    title: 'Offer membership discounts for Twitch subs',
    description: 'Offer your Twitch subscribers discounts on membership tiers',
    imageUrl: twitchDiscounts,
    buttonLabel: 'Set up discounts for Twitch subs',
    buttonTo: routing.apps.twitchMembershipsDiscounts,
};
