import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: inline-block;
  margin-bottom: 16px;
  cursor: pointer;
`;
export const Form = styled.form `
  width: 100%;
  background: ${Colors.GS0};
  padding: 20px;

  ${mediaQueries.tablet} {
    width: 315px;
    border: 1px solid ${Colors.GS400};
  }
`;
export const ButtonsContainer = styled.div `
  margin-top: 20px;
`;
