import { ConfirmModal, HeadContent, LeavingGuard, SaveShortcutListener, useModalContext, } from '@fourthwall/components';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { useDashboardFeatureFlags } from '../../../../../components/utils/DashboardFeatureFlags';
import { routing } from '../../../../../utils/routing';
import { ConfirmBarContainer } from '../../../ConfirmBar/ConfirmBarContainer';
import { ScheduleInput } from '../../components/ScheduleInput';
import { useCreateImageResources } from '../../hooks/useCreateImageResources';
import { useTiers } from '../Perks/hooks/useTiers';
import { PostViewSkeleton } from './PostSkeleton';
import { PostCreateLayout } from './components/PostCreateLayout';
import { getValidationSchema } from './consts';
import { usePostCreate } from './hooks/usePostCreate';
import { AudioFields } from './modules/AudioFields';
import { CommonFields } from './modules/CommonFields';
import { ImageFields } from './modules/ImageFields';
import { ImagesFields } from './modules/ImagesFields';
import { LivestreamFields } from './modules/LivestreamFields';
import { PollFields } from './modules/PollFields';
import { SeriesFields } from './modules/SeriesFields';
import { VideoFields } from './modules/VideoFields';
import { FormFields } from './types';
import { getSelectedTierIds } from './utils/getSelectedTierIds';
export const PostCreate = () => {
    const navigate = useNavigate();
    const modal = useModalContext();
    const [queryParams] = useQueryParams({
        type: StringParam,
    });
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { tiers } = useTiers();
    const { createPost, mediaUploadProgress, isLoading } = usePostCreate();
    const { createImageResources, isLoading: createImageResourcesIsLoading } = useCreateImageResources();
    if (!tiers) {
        return React.createElement(PostViewSkeleton, null);
    }
    const getTiers = getSelectedTierIds([], tiers);
    const initialValues = {
        mode: 'create',
        postType: queryParams.type || 'Text',
        access: 'MembersOnly',
        tierIds: getTiers,
        title: '',
        description: '',
        tags: [],
        notifyMembers: true,
        images: [],
        videoType: 'upload',
        pollOptions: ['', ''],
        pollLength: {
            days: '3',
            hours: '',
            minutes: '',
        },
        livestreamType: 'YouTube Live',
    };
    const getConfirmBarButtonLabel = (values) => {
        if (!values.liveAt)
            return 'Publish';
        return dayjs(values.liveAt).isAfter(dayjs()) ? 'Schedule' : 'Publish';
    };
    const handleCreatePost = async (values, resetForm) => {
        createPost(values, () => {
            resetForm({ values });
            // "setTimeout" was used here to not trigger LeavingGuard
            setTimeout(() => {
                navigate(dayjs(values.liveAt).isAfter(dayjs())
                    ? routing.memberships.posts.scheduled.all
                    : routing.memberships.posts.self);
            }, 0);
        });
    };
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: getValidationSchema({
            pollLenghValidation: true,
            multipleImages: dashboardFeatureFlags.membershipsMultipleImages.enabled,
            publicVideo: dashboardFeatureFlags.membershipsPublicVideo.enabled,
        }), enableReinitialize: true, onSubmit: (values, { resetForm }) => {
            if (values.notifyMembers && dayjs(values.liveAt).isBefore(dayjs())) {
                return modal.open(ConfirmModal, {
                    title: 'Are you sure you want to backdate this post?',
                    text: `Your post will display as if it was published on ${dayjs(values.liveAt).format('MMM DD, YYYY [at] hh A')}. If you don’t want your members to be notified about this backdated post, turn off the “Notify members about this post”.`,
                    confirmLabel: 'Publish and notify members',
                    onConfirm: () => handleCreatePost(values, resetForm),
                });
            }
            if (dayjs(values.liveAt).isBefore(dayjs())) {
                return modal.open(ConfirmModal, {
                    title: 'Backdate this post?',
                    text: `Your post will be displayed as if it was published on ${dayjs(values.liveAt).format('MMM DD, YYYY [at] hh A')} and may not be at the top of your page.`,
                    confirmLabel: 'Publish',
                    onConfirm: () => handleCreatePost(values, resetForm),
                });
            }
            handleCreatePost(values, resetForm);
        } }, ({ values, dirty, submitForm, setFieldValue, resetForm }) => {
        const handleImagesUpload = (files) => {
            createImageResources({
                files,
                onSuccess: (images) => setFieldValue(FormFields.images, [
                    ...values.images,
                    ...images.map((image) => ({ image: image.resizedUrl, id: image.id })),
                ]),
            });
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(HeadContent, { title: "Create new post" }),
            React.createElement(PostCreateLayout, { disabled: isLoading },
                values.postType === 'Text' && React.createElement(CommonFields, { disabled: isLoading }),
                values.postType === 'Image' && (React.createElement(React.Fragment, null,
                    dashboardFeatureFlags.membershipsMultipleImages.enabled ? (React.createElement(ImagesFields, { imagesFieldName: FormFields.images, subsectionTitle: "Images", isUploading: isLoading || createImageResourcesIsLoading, onImagesUpload: handleImagesUpload })) : (React.createElement(ImageFields, { imageFieldName: FormFields.image, image: values.image, subsectionTitle: "Image", isUploading: isLoading })),
                    React.createElement(CommonFields, { disabled: isLoading }))),
                values.postType === 'Video' && (React.createElement(React.Fragment, null,
                    React.createElement(VideoFields, { type: values.videoType, typeFieldName: FormFields.videoType, video: values.video, videoFieldName: FormFields.video, thumbnail: values.videoThumbnail, subtitles: values.videoSubtitles, thumbnailFieldName: FormFields.videoThumbnail, subtitlesFieldName: FormFields.videoSubtitles, url: values.videoUrl, urlFieldName: FormFields.videoUrl, subsectionTitle: "Video", isUploading: isLoading, uploadProgress: mediaUploadProgress }),
                    React.createElement(CommonFields, { disabled: isLoading },
                        React.createElement(SeriesFields, { disabled: isLoading })))),
                values.postType === 'Audio' && (React.createElement(React.Fragment, null,
                    React.createElement(AudioFields, { isUploading: isLoading, uploadProgress: mediaUploadProgress }),
                    React.createElement(CommonFields, { disabled: isLoading }))),
                values.postType === 'Poll' && (React.createElement(React.Fragment, null,
                    React.createElement(CommonFields, { disabled: isLoading }),
                    React.createElement(PollFields, { disabled: isLoading }))),
                values.postType === 'LiveStream' && (React.createElement(React.Fragment, null,
                    React.createElement(LivestreamFields, { disabled: isLoading }),
                    React.createElement(CommonFields, { disabled: isLoading })))),
            React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
            React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isLoading || createImageResourcesIsLoading, confirmLabel: getConfirmBarButtonLabel(values), message: "Unsaved post", additionalElement: React.createElement(ScheduleInput, { date: values.liveAt, onDateChange: (date) => {
                        setFieldValue(FormFields.liveAt, date);
                    } }), onConfirm: submitForm, onCancel: resetForm }),
            React.createElement(LeavingGuard, { when: dirty || isLoading })));
    }));
};
