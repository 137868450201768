import { BackButton, HeadContent, LeavingGuard, Loading, SaveShortcutListener, } from '@fourthwall/components';
import { Formik } from 'formik';
import React from 'react';
import { Dashboard } from '../../../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../../../components/common/PageLayout';
import { routing } from '../../../../../../../utils/routing';
import { ConfirmBarContainer } from '../../../../../ConfirmBar/ConfirmBarContainer';
import { CreatorMessagesSendFormContent } from './components/SendFormContent/CreatorMessagesSendFormContent';
import { validationSchema } from './validators/sendMessageValidator';
const initialValues = {
    sendTo: {
        type: 'ALL',
    },
    message: '',
    subject: '',
    attachment: undefined,
};
export const CreatorMessagesSendView = ({ vm: { allSupportersRadioButtonLabel, last90DaysSupportersRadioButtonLabel, loading, submitFormButtonLabel, sendMessageSuccess, }, onSubmit, setFinalSupportersCount, }) => {
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: validationSchema }, ({ dirty, submitForm, resetForm }) => {
        return (React.createElement(React.Fragment, null,
            React.createElement(HeadContent, { title: "Send" }),
            React.createElement(Loading, { isLoading: loading },
                React.createElement(PageLayout, { leftContent: React.createElement(CreatorMessagesSendFormContent, { vm: {
                            allSupportersRadioButtonLabel,
                            last90DaysSupportersRadioButtonLabel,
                            disabled: loading,
                        }, setFinalSupportersCount: setFinalSupportersCount }) },
                    React.createElement(Dashboard.ItemViewHeader, { name: "Send a new message", backButton: React.createElement(BackButton, { label: "Messages", to: routing.contributions.creatorMessages.self }) }))),
            React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
            React.createElement(ConfirmBarContainer, { isOpen: true, isLoading: loading, confirmLabel: submitFormButtonLabel, onConfirm: submitForm, onCancel: resetForm }),
            React.createElement(LeavingGuard, { when: dirty && !sendMessageSuccess })));
    }));
};
