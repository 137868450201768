import React from 'react';
import { Loader } from '../../../common/Loader';
import { Tabs } from '../../../common/Tabs';
import { CostItem, EstimatedFees, EstimatedProfit, SellingPrice } from '../components/CostItem';
import { LoaderContainer, TabsItemStyle, TabsListStyle } from '../styled';
import * as S from './styled';
export const OnDemandPricing = ({ includeFeesValue, data }) => {
    const renderTabs = () => {
        return (React.createElement(Tabs, null,
            React.createElement(S.TabsListContainer, null,
                React.createElement(Tabs.List, { style: TabsListStyle }, data?.map((tab) => (React.createElement(Tabs.Item, { key: tab.label, style: TabsItemStyle, name: tab.label, variant: "dimmed" }))))),
            data?.map((tab, index) => {
                return (React.createElement(Tabs.Panel, { key: `${tab.price}-${index}` },
                    React.createElement(SellingPrice, { value: tab.price, autoAdjusted: index !== 0 }),
                    React.createElement(CostItem, { name: "Manufacturing costs", value: tab.cost || 0, negative: true }),
                    includeFeesValue && React.createElement(EstimatedFees, { value: tab.feesValue }),
                    React.createElement(EstimatedProfit, { value: tab.unitProfit })));
            })));
    };
    const renderContent = () => {
        if (!data.length) {
            return (React.createElement(LoaderContainer, null,
                React.createElement(Loader, { size: "large" })));
        }
        if (data.length > 1) {
            return renderTabs();
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(CostItem, { name: "Manufacturing costs", value: data[0].cost || 0, negative: true }),
            includeFeesValue && React.createElement(EstimatedFees, { value: data[0].feesValue }),
            React.createElement(EstimatedProfit, { value: data[0].unitProfit })));
    };
    return React.createElement("div", null, renderContent());
};
