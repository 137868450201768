import { Button, ButtonClean, Icon, Tooltip } from '@fourthwall/components';
import { QuestionMarkIcon } from '@fourthwall/icons';
import { getCountryNameByCode, getStateNameByCode } from '@fourthwall/utils/lib/inputs';
import React from 'react';
import * as S from './styled';
export const ShippingLocationView = ({ shippingLocation, onSetupShippingLocation }) => {
    const renderState = () => {
        if (shippingLocation?.state && shippingLocation?.country) {
            return getStateNameByCode(shippingLocation?.country, shippingLocation.state);
        }
        return shippingLocation?.state;
    };
    const renderCountry = () => {
        if (shippingLocation?.country) {
            return getCountryNameByCode(shippingLocation?.country);
        }
        return shippingLocation?.country;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(S.ShippingLocationHeader, null,
            "Your shipping location",
            React.createElement(S.ShippingLocationTooltip, null,
                React.createElement(Tooltip, { reference: React.createElement(Icon, { component: QuestionMarkIcon, height: 18.75 }), placement: "top", fluid: true },
                    "This address is used to calculate shipping costs and taxes. Your supporters do not see it.",
                    ' '))),
        !shippingLocation && (React.createElement("div", { style: { marginTop: '16px' } },
            React.createElement(Button, { label: "Setup your shipping location", appearance: "primary", onClick: onSetupShippingLocation }))),
        shippingLocation && (React.createElement(React.Fragment, null,
            React.createElement(S.ShippingLocationContent, null,
                shippingLocation.address1,
                " ",
                React.createElement("br", null),
                shippingLocation.address2 && (React.createElement(React.Fragment, null,
                    shippingLocation.address2,
                    React.createElement("br", null))),
                renderState(),
                " ",
                shippingLocation.zip,
                " ",
                shippingLocation.city,
                " ",
                React.createElement("br", null),
                renderCountry()),
            React.createElement(S.ShippingLocationEditButtonContainer, null,
                React.createElement(ButtonClean, { label: "Edit shipping location", onClick: onSetupShippingLocation }))))));
};
