import flatMap from 'lodash-es/flatMap';
import { AmericanExpressIcon, ApplePayIcon, DiscoverIcon, GooglePayIcon, MastercardIcon, PayPalIcon, VisaIcon, } from '@fourthwall/icons';
const providers = {
    americanExpress: {
        name: 'American Express',
        icon: AmericanExpressIcon,
    },
    discover: {
        name: 'Discover',
        icon: DiscoverIcon,
    },
    mastercard: {
        name: 'Mastercard',
        icon: MastercardIcon,
    },
    payPal: {
        name: 'PayPal',
        icon: PayPalIcon,
    },
    visa: {
        name: 'Visa',
        icon: VisaIcon,
    },
    applePay: {
        name: 'ApplePay',
        icon: ApplePayIcon,
    },
    googlePay: {
        name: 'GooglePay',
        icon: GooglePayIcon,
    },
};
export const getIconByName = (iconName) => {
    const key = Object.keys(providers).find((providerKey) => providers[providerKey].name.toLowerCase() === iconName.toLowerCase());
    return key ? providers[key].icon : null;
};
export const getIcons = (type) => {
    return flatMap(type, (typeItem) => {
        if (typeItem === 'creditCards') {
            return [providers.mastercard, providers.visa, providers.americanExpress, providers.discover];
        }
        if (typeItem === 'payPal') {
            return [providers.payPal];
        }
        if (typeItem === 'native') {
            return [providers.applePay, providers.googlePay];
        }
        return [];
    });
};
