import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { Avatar, ButtonClean, HeadContent, Loading, ProfitString, Subsection, Summary, Timeline, } from '@fourthwall/components';
import { getPrice } from '@fourthwall/utils';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ContributionSideSummaryHeader } from '../../../../../components/common/ContributionSideSummaryHeader';
import { ContributionSummary } from '../../../../../components/common/ContributionSummary';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { ProductsList, ProductsListSize } from '../../../../../components/common/ProductsList';
import { SideSummary } from '../../../../../components/common/SideSummary';
import { DashboardBackButton } from '../../../../../components/utils/DashboardBackButton';
import { routing } from '../../../../../utils/routing';
import * as S from './DonationView.styled';
export const DonationView = ({ title, subtitle, value, profit, username, email, message, timelineEvents, isFetching, onBreakdownClick, }) => {
    const location = useLocation();
    const { hasPermission } = useDashboardPermissions();
    const renderProfitSummaryRow = () => {
        if (!hasPermission('contributions.profit') || !profit?.value) {
            return null;
        }
        return (React.createElement(Summary.Row, { label: "Your profit", value: React.createElement(React.Fragment, null,
                React.createElement(S.BreakdownButtonContainer, null,
                    React.createElement(ButtonClean, { label: "Show breakdown", appearance: "secondary", onClick: onBreakdownClick })),
                React.createElement(ProfitString, { value: profit.value })), bold: true }));
    };
    const renderLeftContent = () => {
        if (!value) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Subsection, { title: "Order summary", borderless: true },
                React.createElement(ContributionSummary, { title: "Digital products / donations" },
                    React.createElement(ContributionSummary.Section, null,
                        React.createElement(ProductsList, { size: ProductsListSize.Large },
                            React.createElement(ProductsList.Item, { name: "Donation", price: getPrice(value.value, { currency: value.currency }), donation: true }))),
                    React.createElement(ContributionSummary.Section, null,
                        React.createElement(Summary, null,
                            React.createElement(Summary.Row, { label: "Subtotal (1 item)", value: value }),
                            React.createElement(Summary.Row, { label: "Taxes", value: 0 }),
                            React.createElement(Summary.Row, { label: "Total", value: value })))),
                React.createElement(S.ProfitSummaryContainer, null,
                    React.createElement(Summary, null,
                        React.createElement(Summary.Row, { label: "Net paid by supporter", value: value, bold: true }),
                        renderProfitSummaryRow()))),
            message && React.createElement(Subsection, { title: "Message left" }, message),
            timelineEvents && (React.createElement(Subsection, { title: "Timeline", borderless: true },
                React.createElement(Timeline, { events: timelineEvents, size: "large" })))));
    };
    const renderSummaryHeader = () => {
        if (username) {
            return React.createElement(ContributionSideSummaryHeader, { name: username });
        }
        return React.createElement(Avatar, { name: email });
    };
    const renderRightContent = () => (React.createElement(SideSummary, null,
        React.createElement(SideSummary.Item, null, renderSummaryHeader()),
        React.createElement(SideSummary.Item, { title: "Contact e-mail" }, email)));
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: title }),
        React.createElement(Loading, { isLoading: isFetching },
            React.createElement(PageLayout, { leftContent: renderLeftContent(), rightContent: renderRightContent() },
                React.createElement("div", null,
                    React.createElement(Dashboard.ItemViewHeader, { name: title, title: subtitle, backButton: React.createElement(DashboardBackButton, { label: "Donations", to: {
                                pathname: routing.contributions.orders.donations.all,
                                search: location.search,
                            } }) }))))));
};
