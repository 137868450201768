import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const CodeCell = styled.div `
  font-family: 'PT Mono', monospace;
  background: ${Colors.GS300};
  padding: 2px 4px;
  cursor: default;
  font-size: 12px;
  word-break: break-all;

  ${mediaQueries.tablet} {
    padding: 2px 8px;
    font-size: 14px;
    word-break: initial;

    ${({ pointer }) => pointer &&
    css `
        cursor: pointer;
      `}
  }
`;
