import React from 'react';
import omit from 'lodash-es/omit';
import { Formik } from 'formik';
import { CreateSiteProvider, useCreateSite } from '@fourthwall/module-create-site';
import { getValidationSchema } from './utils/getValidationSchema';
import { getPayloadFromFormValues } from './utils/getPayloadFromFormValues';
import { CreateNewSiteModalView } from './CreateNewSiteModalView';
import { useCreateAdditionalShopMutation } from './queries/useCreateAdditionalShopMutation';
const CreateNewSiteModalContainerComponent = ({ onSuccess }) => {
    const { initialValues, searchChannel } = useCreateSite();
    const { createAdditionalShopMutation } = useCreateAdditionalShopMutation();
    const handleSubmit = async (values) => {
        await createAdditionalShopMutation.mutateAsync([getPayloadFromFormValues(values)], {
            onSuccess,
        });
    };
    return (React.createElement(Formik, { validationSchema: getValidationSchema(searchChannel.manualURLType), initialValues: {
            ...omit(initialValues, 'theme'),
            siteForSomeoneElse: false,
        }, enableReinitialize: true, onSubmit: handleSubmit },
        React.createElement(CreateNewSiteModalView, { searchChannel: searchChannel })));
};
export const CreateNewSiteModalContainer = ({ ...props }) => (React.createElement(CreateSiteProvider, { options: { themesLibraryEnabled: false } },
    React.createElement(CreateNewSiteModalContainerComponent, { ...props })));
