import { BillingApiClient } from '@fourthwall/services/lib/BillingApiClient';
import { useQuery } from '@tanstack/react-query';
export const useBillingSummaryQuery = () => {
    const billingSummaryQuery = useQuery({
        queryKey: ['useBillingSummaryQuery'],
        queryFn: async () => {
            const { data } = await BillingApiClient.getBillingSummary();
            return data;
        },
    });
    return {
        billingSummary: billingSummaryQuery.data,
        billingSummaryQuery,
    };
};
