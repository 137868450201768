import { ToolbarButton } from '@fourthwall/components';
import { CommentIcon } from '@fourthwall/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { routing } from '@utils/routing';
import { useDirectMessagesWidgetQuery } from './hooks/useDirectMessagesWidgetQuery';
export const MessagesWidget = ({ iconSize = 18 }) => {
    const navigate = useNavigate();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { directMessagesWidgetQuery } = useDirectMessagesWidgetQuery([], {
        refetchInterval: (data) => (data && data.unreadConversationsCount ? 5_000 : 60_000),
        enabled: dashboardFeatureFlags.membershipsMessages.enabled,
    });
    if (!dashboardFeatureFlags.membershipsMessages.enabled)
        return null;
    return (React.createElement(ToolbarButton, { label: "Messages", icon: CommentIcon, activityIndicator: !!directMessagesWidgetQuery.data?.unreadConversationsCount, activityIndicatorCount: directMessagesWidgetQuery.data?.unreadConversationsCount, iconSize: iconSize, onClick: () => navigate(routing.memberships.messages.self) }));
};
