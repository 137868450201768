import React from 'react';
import { Button, ButtonClean, Select, Subsection, Tooltip, useModalContext, } from '@fourthwall/components';
import { useNavigate, useParams } from 'react-router-dom';
import { countries } from 'countries-list';
import isEqual from 'lodash/isEqual';
import { Field, getIn, useFormikContext } from 'formik';
import { routing } from '@utils/routing';
import { PaymentMethodRequiredModal } from '@modules/Dashboard/PaymentMethods';
import { useIsPaymentMethodModalRequiredMutation } from '@hooks/useIsPaymentMethodModalRequiredMutation';
import { useOfferQuery } from '@modules/Dashboard/Product';
import { FeatureFlagDecorator } from '@components/utils/FeatureFlagDecorator';
import { HsCodeSelectFormik } from '@modules/Dashboard/Product/modules/shipping/shared/HsCodeSelect/HsCodeSelect';
import { ShippingStartDate } from '@modules/Dashboard/Product/modules/shipping/shared/ShippingStartDate/ShippingStartDate';
import { useDashboardFeatureFlags } from '@components';
import { PackageSizeInputFormik } from '@modules/Dashboard/Product/modules/shipping/shared/PackageSizeSelect/PackageSizeInput';
import { useShippingChoiceModal } from '../shared/SelfOrAssistedShippingChoiceModal';
import { Shipping3plDescription } from './Description/Shipping3plDescription';
import * as S from './ProductShipping3PL.styled';
import { InventoryShipmentSection } from './InventoryShipmentSection/InventoryShipmentSection';
const countriesOptions = Object.entries(countries)
    .map(([countryKey, country]) => ({
    value: countryKey,
    label: country.name,
}))
    .sort((a, b) => a.label.localeCompare(b.label));
export const ProductShipping3PL = ({ initialValues }) => {
    const { open } = useModalContext();
    const { productId = '' } = useParams();
    const navigate = useNavigate();
    const { errors, values } = useFormikContext();
    const isFormDirty = !isEqual(initialValues, values);
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const isShippingDisabled = getIn(errors, 'customsInformation.shortHsCode') ||
        getIn(errors, 'customsInformation.countryOfOrigin') ||
        getIn(errors, 'shippingPackageSize') ||
        isFormDirty;
    const { offerQuery } = useOfferQuery([productId]);
    const { shippingConfig } = offerQuery?.data?.config || {};
    const { openShippingModal } = useShippingChoiceModal();
    const { isPaymentMethodModalRequiredMutation } = useIsPaymentMethodModalRequiredMutation();
    const { isShippingTypeEditable } = shippingConfig || {};
    const linkToCreateInventoryShipment = {
        pathname: routing.settings.inventoryShipments.create,
        search: `?offerId=${productId}`,
    };
    const openPaymentMethodRequiredModal = async () => {
        return isPaymentMethodModalRequiredMutation.mutateAsync([], {
            onSuccess: (data) => {
                if (data.isPaymentMethodModalRequired) {
                    open(PaymentMethodRequiredModal, {
                        title: 'Payment method required',
                        subtitle: (React.createElement("p", null,
                            "A payment method is required to use our shipping service. Your card will only be charged if your account balance does not cover a fulfillment cost (such as",
                            ' ',
                            React.createElement("u", null, "monthly storage"),
                            " and ",
                            React.createElement("u", null, "per order fulfillment fees)"),
                            ".")),
                        onSuccess: () => {
                            navigate(linkToCreateInventoryShipment);
                        },
                    });
                }
                else {
                    navigate(linkToCreateInventoryShipment);
                }
            },
        });
    };
    const sendButton = () => {
        return (React.createElement(Button, { type: "button", fullWidth: true, label: "Send inventory to our fulfillment center", disabled: isShippingDisabled, href: `${linkToCreateInventoryShipment.pathname}${linkToCreateInventoryShipment.search}`, onClick: async (e) => {
                e.preventDefault();
                await openPaymentMethodRequiredModal();
            } }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Subsection, { title: "Shipping" },
            React.createElement(S.Container, null,
                React.createElement(S.ShippingSubtitle, null,
                    React.createElement("strong", null, "We\u2019ll handle shipping for you"),
                    isShippingTypeEditable && (React.createElement(ButtonClean, { label: "Change", onClick: () => openShippingModal() }))),
                React.createElement(Shipping3plDescription, { shippingConfig: shippingConfig }),
                React.createElement(Field, { component: HsCodeSelectFormik, name: "customsInformation.shortHsCode", label: "Product Category", error: getIn(errors, 'customsInformation.shortHsCode') }),
                React.createElement(Field, { component: Select, name: "customsInformation.countryOfOrigin", label: "Country product was manufactured in", error: getIn(errors, 'customsInformation.countryOfOrigin'), options: countriesOptions }),
                React.createElement(Field, { component: PackageSizeInputFormik, shippingConfig: shippingConfig, name: "shippingPackageSize", label: "Product size", error: getIn(errors, 'shippingPackageSize') }),
                isShippingDisabled ? (React.createElement(Tooltip, { reference: sendButton(), placement: "top" },
                    React.createElement("div", null, "Please save your changes before sending your inventory to our fulfillment center."))) : (sendButton()))),
        React.createElement(FeatureFlagDecorator, { ...dashboardFeatureFlags.hsCodes, wrapper: Subsection },
            React.createElement(ShippingStartDate, { initialValues: initialValues })),
        React.createElement(Subsection, null,
            React.createElement(InventoryShipmentSection, null))));
};
