import { ArrowShortDownIcon, PencilIcon } from '@fourthwall/icons';
import { ButtonClean, Dropdown, Input, InputUI, withFormikSupport } from '@fourthwall/components';
import React, { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { useHSCodesQuery } from '@modules/Dashboard/Product/hooks/useHSCodesQuery';
import * as S from './HSCodeSelect.styled';
const HsCodeSelect = React.memo(({ error, value, onChange }) => {
    const { submitCount } = useFormikContext();
    const showError = Boolean(submitCount > 0 && error);
    const customHSCodeRef = React.useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isCustomHSCode, setIsCustomHSCode] = useState(false);
    const { hSCodesQuery: { data: hSCodesData }, } = useHSCodesQuery([{ query: searchQuery }]);
    const matchedCode = hSCodesData?.find((hsCode) => hsCode.code === value);
    const handleOptionClick = useCallback((optionValue) => {
        onChange(optionValue);
    }, [onChange]);
    const getInputValue = () => {
        if (!matchedCode) {
            if (searchQuery) {
                return searchQuery;
            }
            if (value) {
                return value;
            }
            return '';
        }
        return `${matchedCode.type}${matchedCode.textile ? ` (${matchedCode.textile})` : ''} (HS ${matchedCode.code})`;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Dropdown, { fluid: true, maxHeight: 300, content: hSCodesData?.map((hsCode) => (React.createElement(Dropdown.Item, { key: hsCode.code, active: hsCode.code === value, label: React.createElement(S.OptionLabel, null,
                        React.createElement("span", null,
                            hsCode.type,
                            hsCode.textile && ` (${hsCode.textile})`),
                        "\u00A0\u00A0",
                        React.createElement(S.OptionLabelExtra, null,
                            "HS ",
                            hsCode.code)), onClick: () => {
                        setIsCustomHSCode(false);
                        handleOptionClick(hsCode.code);
                    } }))), footer: React.createElement(Dropdown.Item, { label: React.createElement(S.AdditionalElement, { onClick: () => {
                            setIsCustomHSCode(true);
                            handleOptionClick('');
                            setTimeout(() => {
                                customHSCodeRef.current?.focus();
                            }, 0);
                        } },
                        React.createElement(PencilIcon, null),
                        "I can't find my product (enter custom HS and tax codes)") }) },
                React.createElement("div", null,
                    React.createElement(InputUI.Root, { fullWidth: true },
                        React.createElement(InputUI.Box, { invalid: showError },
                            React.createElement(InputUI.Container, null,
                                React.createElement(InputUI.IconContainer, { style: { right: 15, top: 18 } },
                                    React.createElement(ArrowShortDownIcon, null)),
                                React.createElement(InputUI.Input, { name: "customsInformation.shortHsCode", inputSize: "large", id: "hsCode__input", type: "text", value: getInputValue(), hasLabel: Boolean(getInputValue()), "aria-labelledby": "hsCode__label", onChange: (event) => {
                                        setSearchQuery(event.target.value);
                                        setIsCustomHSCode(false);
                                        onChange('');
                                    } }),
                                React.createElement(InputUI.Label, { htmlFor: getInputValue() ? 'hsCode__label' : undefined, size: "large", id: "hsCode__label", hasValue: Boolean(getInputValue()) }, "Product category")))),
                    !isCustomHSCode && error ? React.createElement(InputUI.Error, null, error) : null))),
        isCustomHSCode && (React.createElement(Input, { inputRef: customHSCodeRef, suggestion: React.createElement(React.Fragment, null,
                "HS codes are an international system for categorizing products.",
                ` `,
                React.createElement(ButtonClean, { label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/15831926700443-Self-fulfillment-HS-codes-and-why-are-they-needed", target: "_blank", appearance: "secondary" })), value: value, name: "customsInformation.shortHsCode", error: showError ? error : undefined, label: "HS code of your product", onChange: (event) => {
                onChange(event.target.value);
            } }))));
});
export const HsCodeSelectFormik = withFormikSupport(HsCodeSelect);
