import merge from 'lodash-es/merge';
export const createDashboardPermissions = (defaultValue, mergedObject = {}) => {
    const defaultPermissions = {
        home: {
            analytics: defaultValue,
            fulfillments: defaultValue,
            thankYous: defaultValue,
            recommendations: defaultValue,
            guidesAndTips: defaultValue,
        },
        contributions: {
            orders: {
                view: defaultValue,
                edit: defaultValue,
                cancel: defaultValue,
                refund: defaultValue,
                redoGiveaway: defaultValue,
            },
            thankYous: defaultValue,
            fulfillments: {
                view: defaultValue,
                upload: defaultValue,
            },
            messages: {
                view: defaultValue,
                send: defaultValue,
            },
        },
        products: {
            view: defaultValue,
            create: defaultValue,
            edit: {
                details: defaultValue,
                stock: defaultValue,
                additionalSections: defaultValue,
                status: defaultValue,
                url: defaultValue,
            },
            duplicate: defaultValue,
            orderSamples: defaultValue,
            advancedSettings: defaultValue,
            archive: defaultValue,
        },
        collections: {
            view: defaultValue,
            create: defaultValue,
            edit: defaultValue,
        },
        promotions: {
            shopPromoCodes: defaultValue,
            membershipsPromoCodes: defaultValue,
            giveawayLinks: defaultValue,
        },
        analytics: {
            profit: defaultValue,
            totalMembers: defaultValue,
            membersChange: defaultValue,
            contributions: defaultValue,
            averageContributionValue: defaultValue,
            topProducts: defaultValue,
            salesByCountry: defaultValue,
            topTiers: defaultValue,
        },
        apps: defaultValue,
        themeEditor: {
            general: defaultValue,
            layout: defaultValue,
        },
        memberships: {
            setup: defaultValue,
            overview: defaultValue,
            post: {
                view: defaultValue,
                create: defaultValue,
            },
            video: {
                view: defaultValue,
                create: defaultValue,
            },
            members: defaultValue,
            perksAndTiers: {
                createPerk: defaultValue,
                createTier: defaultValue,
            },
            settings: {
                general: defaultValue,
                messages: defaultValue,
                welcomePost: defaultValue,
                mobileApp: defaultValue,
                upselling: defaultValue,
            },
        },
        settings: {
            general: defaultValue,
            team: {
                view: defaultValue,
                invite: defaultValue,
                changeRole: defaultValue,
                remove: defaultValue,
            },
            shipping: defaultValue,
            domain: defaultValue,
            billing: {
                view: defaultValue,
                payouts: {
                    setup: defaultValue,
                    edit: defaultValue,
                },
                paymentMethods: defaultValue,
                history: {
                    payouts: defaultValue,
                    charges: defaultValue,
                    full: defaultValue,
                },
            },
            samples: {
                view: defaultValue,
                create: defaultValue,
            },
        },
        accountSettings: defaultValue,
        bookACall: defaultValue,
        visitState: defaultValue,
    };
    return merge(defaultPermissions, mergedObject);
};
