import React from 'react';
import { useDropzone } from 'react-dropzone';
import { ActionButton } from '../ActionButton';
import { FILE_TYPES, MAX_FILE_SIZE } from '../../const';
export const UploadActionButton = ({ onFileDrop, onFileDropRejected }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onFileDrop,
        onDropRejected: onFileDropRejected,
        maxSize: MAX_FILE_SIZE,
        accept: FILE_TYPES,
    });
    return (React.createElement("div", { ...getRootProps() },
        React.createElement("input", { ...getInputProps() }),
        React.createElement(ActionButton, { actionType: "upload" })));
};
