import { mapQueryToPaginationProps, usePagination } from '@fourthwall/components';
import { useChargesQuery } from '../useChargesQuery';
import { getMethod, getType } from './utils';
export const CHARGE_STATUS = {
    PENDING: 'PROCESSING',
    INITIALIZED: 'PROCESSING',
    SUCCESSFUL: 'PAID',
    FAILED: 'FAILED',
};
export const useChargesHistory = ({ enabled, }) => {
    const [currentPage, setCurrentPage] = usePagination();
    const { chargesQuery } = useChargesQuery([{ size: 6, page: currentPage - 1 }], {
        enabled,
        keepPreviousData: true,
    });
    const data = chargesQuery.data?.charges.map(({ id, createdAt, amount, reason, paymentMethodDetails, status }) => ({
        id,
        date: createdAt,
        amount,
        type: getType(reason),
        method: getMethod(paymentMethodDetails),
        status: CHARGE_STATUS[status],
    })) || [];
    const pagination = mapQueryToPaginationProps(chargesQuery, currentPage, setCurrentPage);
    return {
        data,
        isLoading: chargesQuery.isLoading,
        pagination,
    };
};
