import { ScrollContent } from '@components/common/ScrollContent';
import { TabPills } from '@components/common';
import { Button, ButtonGroup, Icon, Loader, ModalBox, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { MicrophoneIcon, SwitchIcon, VideoIcon } from '@fourthwall/icons';
import { AudioPlayer } from '@modules/Dashboard/ThankYous/modules/SendThankYouModal/components/AudioPlayer';
import startCase from 'lodash-es/startCase';
import React, { useEffect, useState } from 'react';
import { ActionButton } from './components/ActionButton';
import { FooterTabs } from './components/FooterTabs';
import { Layout } from './components/Layout';
import { LayoutMobile } from './components/LayoutMobile';
import { MediaSourceButton } from './components/MediaSourceButton';
import { MissingPermissions } from './components/MissingPermissions';
import { OrderDetails } from './components/OrderDetails';
import { Sidebar } from './components/Sidebar';
import { SkipButton } from './components/SkipButton';
import { TopBar } from './components/TopBar';
import { UploadActionButton } from './components/UploadActionButton';
import { UploadLoader } from './components/UploadLoader';
import { UploadMedia } from './components/UploadMedia';
import { UploadSuccess } from './components/UploadSuccess';
import * as S from './styled';
import { millisecondsToTime } from './utils';
export const SendThankYouModalView = ({ thankYou, thankYouType, thankYouTypes, media, isSkipping, isUploading, isSuccess, onThankYouTypeChange, onThankYouSkip, onThankYouSend, onContinue, onClose, }) => {
    const videoStreamRef = React.useRef(null);
    const [showOrderDetails, setShowOrderDetails] = useState(true);
    const { laptop } = useMediaQueryContext();
    const { close } = useModalContext();
    useEffect(() => {
        if (videoStreamRef.current && media.stream && media.status === 'IDLE') {
            videoStreamRef.current.srcObject = media.stream;
        }
    }, [media.status, media.stream]);
    const handleClose = () => {
        onClose?.();
        close();
    };
    const renderMainContentHeader = () => {
        if (isUploading || isSuccess)
            return null;
        if (media.status === 'IDLE' ||
            media.status === 'MISSING_PERMISSIONS' ||
            media.status === 'LOADING') {
            return (React.createElement(S.HeaderTabs, null,
                React.createElement(TabPills, { appearance: "secondary", value: thankYouType, options: thankYouTypes.map((tab) => ({
                        label: startCase(tab.toLocaleLowerCase()),
                        value: tab,
                    })), onChange: (value) => onThankYouTypeChange(value) })));
        }
        return null;
    };
    const renderMainContentFooter = () => {
        if (media.status === 'MISSING_PERMISSIONS' ||
            media.status === 'LOADING' ||
            isUploading ||
            isSuccess ||
            thankYou.state === 'SENT')
            return null;
        if (media.status === 'IDLE' && (thankYouType === 'VIDEO' || thankYouType === 'AUDIO')) {
            return (React.createElement(Button, { key: "start-recording", leftIcon: React.createElement(S.StartRecordingButtonIcon, null), appearance: "destructive", label: "Start recording", onClick: media.onRecordStart }));
        }
        if (media.status === 'IN_PROGRESS') {
            return (React.createElement(Button, { key: "stop-recording", leftIcon: React.createElement(S.StopRecordButtonIcon, null), appearance: "destructive-inverted", label: "Stop recording", onClick: media.onRecordStop }));
        }
        if (media.status === 'PREVIEW') {
            return (React.createElement(ButtonGroup, { space: "xlarge" },
                React.createElement(Button, { appearance: "secondary", label: "Cancel", onClick: media.onRecordDelete }),
                React.createElement(Button, { appearance: "primary", label: "Send thank you", onClick: onThankYouSend, disabled: !!media.preview?.error })));
        }
        return null;
    };
    const renderPreviewError = () => {
        if (media.preview?.error === 'FILE_TOO_LARGE' && thankYouType === 'UPLOAD') {
            return `Uploaded file exceeds the maximum size of 50MB.`;
        }
        if (media.preview?.error === 'FILE_TOO_LARGE' && thankYouType === 'AUDIO') {
            return `Recorded audio exceeds the maximum size of 50MB.`;
        }
        if (media.preview?.error === 'FILE_TOO_LARGE' && thankYouType === 'VIDEO') {
            return `Recorded video exceeds the maximum size of 50MB.`;
        }
        return null;
    };
    const renderMainContentFooterTop = () => {
        if (media.status === 'IN_PROGRESS' && thankYouType === 'VIDEO') {
            return React.createElement(S.FooterTimer, null, millisecondsToTime(media.duration));
        }
        if (media.status === 'PREVIEW' && !!media.preview?.error) {
            return React.createElement(S.FooterError, null, renderPreviewError());
        }
        return null;
    };
    const renderMainContentFooterRight = () => {
        if (isUploading || isSuccess)
            return null;
        if (media.status === 'IDLE' && onThankYouSkip) {
            return React.createElement(SkipButton, { isLoading: isSkipping, onClick: onThankYouSkip });
        }
        return null;
    };
    const renderMainContentFooterLeft = () => {
        if (isUploading || isSuccess)
            return null;
        if (media.status === 'IDLE' && (thankYouType === 'VIDEO' || thankYouType === 'AUDIO')) {
            return (React.createElement(S.MediaSourceButtons, null,
                media.sources.video.length > 0 && (React.createElement(MediaSourceButton, { options: media.sources.video, icon: React.createElement(Icon, { component: VideoIcon, height: 20 }), onChange: media.sources.onVideoSourceChange })),
                media.sources.audio.length > 0 && (React.createElement(MediaSourceButton, { options: media.sources.audio, icon: React.createElement(Icon, { component: MicrophoneIcon, height: 20 }), onChange: media.sources.onAudioSourceChange }))));
        }
        return null;
    };
    const renderContent = () => {
        if (isUploading)
            return React.createElement(UploadLoader, null);
        if (isSuccess)
            return (React.createElement(UploadSuccess, { supporterName: thankYou.supporter.fullName, onContinue: onContinue, onClose: handleClose }));
        if (media.status === 'MISSING_PERMISSIONS') {
            return React.createElement(MissingPermissions, { onTryAgain: media.onReinitialize });
        }
        if ((media.status === 'LOADING' || media.status === 'IDLE' || media.status === 'IN_PROGRESS') &&
            thankYouType === 'VIDEO') {
            return (React.createElement(React.Fragment, null,
                media.status === 'LOADING' && (React.createElement(S.LoaderContainer, null,
                    React.createElement(Loader, { size: "large", appearance: "primary" }))),
                React.createElement(S.VideoElement, { ref: videoStreamRef, key: "record", autoPlay: true, muted: true, playsInline: true })));
        }
        if (media.status === 'LOADING' && thankYouType === 'AUDIO') {
            return (React.createElement(S.LoaderContainer, null,
                React.createElement(Loader, { size: "large", appearance: "primary" })));
        }
        if ((media.status === 'IDLE' || media.status === 'IN_PROGRESS') && thankYouType === 'AUDIO') {
            return (React.createElement(S.Timer, { visible: laptop || !showOrderDetails }, millisecondsToTime(media.duration)));
        }
        if ((media.status === 'IDLE' || media.status === 'IN_PROGRESS') && thankYouType === 'UPLOAD') {
            return (React.createElement(S.UploadMediaWrapper, { hidden: !laptop && showOrderDetails },
                React.createElement(UploadMedia, { onFileDrop: media.onFileDrop, onFileDropRejected: media.onFileDropRejected })));
        }
        if (media.status === 'PREVIEW' && media.preview?.type === 'VIDEO') {
            return (React.createElement(S.VideoElement, { src: media.preview.url, key: "preview", controls: laptop, autoPlay: !laptop, loop: !laptop, playsInline: true }));
        }
        if (media.status === 'PREVIEW' && media.preview?.type === 'AUDIO') {
            return React.createElement(AudioPlayer, { src: media.preview.url });
        }
        return null;
    };
    const renderContentFooter = () => {
        if (media.status === 'MISSING_PERMISSIONS' ||
            media.status === 'LOADING' ||
            isUploading ||
            isSuccess ||
            thankYou.state === 'SENT')
            return null;
        if (media.status === 'IDLE' && (thankYouType === 'VIDEO' || thankYouType === 'AUDIO')) {
            return (React.createElement(S.ContentFooterContainer, null,
                React.createElement(ActionButton, { actionType: thankYouType === 'VIDEO' ? 'video' : 'audio', onClick: media.onRecordStart }),
                thankYouType === 'VIDEO' && (React.createElement(S.SwitchCameraButton, { component: SwitchIcon, height: 16, onClick: media.onSwitchFacingMode })),
                thankYouType === 'AUDIO' && (React.createElement(S.TimerWrapper, null,
                    React.createElement(S.Timer, { small: true, visible: showOrderDetails }, millisecondsToTime(media.duration))))));
        }
        if (media.status === 'IDLE' && thankYouType === 'UPLOAD') {
            return (React.createElement(S.ContentFooterContainer, null,
                React.createElement(UploadActionButton, { onFileDrop: media.onFileDrop, onFileDropRejected: media.onFileDropRejected })));
        }
        if (media.status === 'IN_PROGRESS') {
            return (React.createElement(S.ContentFooterContainer, null,
                React.createElement(ActionButton, { actionType: "stop", onClick: media.onRecordStop }),
                thankYouType === 'AUDIO' && (React.createElement(S.TimerWrapper, null,
                    React.createElement(S.Timer, { small: true, visible: showOrderDetails }, millisecondsToTime(media.duration))))));
        }
        if (media.status === 'PREVIEW') {
            return (React.createElement(S.ContentFooterContainer, null,
                React.createElement(ActionButton, { actionType: "send", onClick: onThankYouSend })));
        }
    };
    if (!laptop) {
        return (React.createElement(ModalBox, { withoutPadding: true, withoutCloseButton: true, size: "fullScreen" },
            React.createElement(LayoutMobile, { content: renderContent(), contentHeader: React.createElement(S.ContentHeaderContainer, null,
                    React.createElement(TopBar, { thankYou: thankYou, media: media, isSuccess: isSuccess, isUploading: isUploading, showOrderDetails: showOrderDetails, onToggleShowOrderDetails: () => setShowOrderDetails((state) => !state), onClose: handleClose, onSkip: onThankYouSkip }),
                    ['IDLE', 'IN_PROGRESS'].includes(media.status) && (React.createElement(S.ContentHeaderDetails, { hidden: !showOrderDetails },
                        React.createElement(ScrollContent, { maxHeight: window.innerHeight / 2.5, hideScrollbar: true },
                            React.createElement(OrderDetails, { thankYou: thankYou }))))), contentFooter: renderContentFooter(), hideFooter: media.status === 'MISSING_PERMISSIONS' ||
                    media.status === 'LOADING' ||
                    isUploading ||
                    isSuccess ||
                    thankYou.state === 'SENT', footer: media.status === 'IDLE' ? (React.createElement(FooterTabs, { tabs: thankYouTypes, activeTab: thankYouType, onTabChange: onThankYouTypeChange })) : null })));
    }
    return (
    // NOTE: width is calculated to maintain 16/9 video aspect ratio and sidebar content
    React.createElement(ModalBox, { size: "large", width: "calc(88vh + 400px)", withoutPadding: true, onClose: handleClose },
        React.createElement(Layout, { appearance: isSuccess ? 'LIGHT' : 'DARK', content: renderContent(), contentHeader: renderMainContentHeader(), contentFooter: renderMainContentFooter(), contentFooterTop: renderMainContentFooterTop(), contentFooterRight: renderMainContentFooterRight(), contentFooterLeft: renderMainContentFooterLeft(), sidebar: !isSuccess ? React.createElement(Sidebar, { thankYou: thankYou }) : null })));
};
