import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.code `
  display: inline-block;

  padding: 2px 8px;
  background: ${Colors.GS300};

  color: ${Colors.GS1000};
  font-family: 'PT Mono';
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;
