import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  word-break: break-word;

  & + & {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid ${Colors.GS400};
  }
`;
export const Title = styled.div `
  margin-bottom: 8px;

  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  line-height: 15px;
  color: ${Colors.GS600};
`;
export const Children = styled.div `
  line-height: 24px;
`;
