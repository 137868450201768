import { ButtonGroup, HeadContent, Loading, Pagination, Table, ToolbarButton, WebViewRemover, useMediaQueryContext, } from '@fourthwall/components';
import { DownloadIcon } from '@fourthwall/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { useFilterMembers } from './hooks/useFilterMembers';
import { useMembers } from './hooks/useMembers';
import { useMembersDataExport } from './hooks/useMembersDataExport';
import { MembersFilterContainer } from './modules/MembersFilter';
import { MembersSearch } from './modules/MembersSearch';
export const Members = () => {
    const { members } = useMembers();
    const { hasFilterParams } = useFilterMembers();
    const { exportMembersData, isMembersDataLoading } = useMembersDataExport();
    const media = useMediaQueryContext();
    const getToolbarContent = () => (React.createElement(ButtonGroup, { space: "medium" },
        React.createElement(WebViewRemover, null,
            React.createElement(Loading, { isLoading: isMembersDataLoading },
                React.createElement(ToolbarButton, { label: "Export members", icon: DownloadIcon, onClick: exportMembersData }))),
        React.createElement(MembersFilterContainer, null),
        media.tablet && React.createElement(MembersSearch, null)));
    return (React.createElement(PageLayout, { size: "large" },
        React.createElement(Dashboard.Section, { name: "Members", bottomMargin: media.laptop ? 'medium' : 'small', toolbarContent: getToolbarContent(), bottomContent: !media.tablet && React.createElement(MembersSearch, null) },
            React.createElement(HeadContent, { title: "Members" }),
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(Dashboard.Section.ScrollableContent, null,
                    React.createElement(Table, { ...members.table, rowComponent: Link, isLoading: members.isLoading, emptyMessage: hasFilterParams
                            ? 'No members found. Adjust your filters.'
                            : "You don't have any members yet", minWidth: media.laptop ? undefined : 0 }))),
            members.pagination.pageCount !== 1 && (React.createElement(Dashboard.Section.Item, null,
                React.createElement(Pagination, { ...members.pagination }))))));
};
