import { getShopRendererApiUrl } from '@fourthwall/env';
import { ApiBase } from '../ApiBase';
class ShopRendererMembershipsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getTiers = (pathParams) => {
            return this.get(`/shops/${pathParams.shop_id}/memberships/tiers`);
        };
    }
}
export const ShopRendererMembershipsApiClient = new ShopRendererMembershipsApiClientModel(getShopRendererApiUrl('api'));
