import { useMutation } from '@tanstack/react-query';
import { getEntriConfig } from '../utils';
import { useEntriContext } from '../../../providers/Entri';
export const useDetectDomainProviderGetMutation = () => {
    const { checkDomain } = useEntriContext();
    const detectDomainProviderGetMutation = useMutation({
        mutationFn: async ({ dnsList, shopId }) => {
            const config = getEntriConfig(dnsList, shopId);
            const domainType = await checkDomain(dnsList.domain, config);
            return {
                config,
                domainType,
            };
        },
    });
    return {
        detectDomainProviderGetMutation,
    };
};
