import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useThemeEditorContext } from '../../../../hooks/useThemeEditorContext';
const BottomSheetContext = createContext(undefined);
export const BottomSheetContextProvider = ({ children }) => {
    const { isPreviewLoading } = useThemeEditorContext();
    const [didBounceInitially, setDidBounceInitially] = useState(false);
    const [position, setPosition] = useState('bottom');
    const [shouldBounce, setShouldBounce] = useState(false);
    const triggerBounce = (options) => {
        setShouldBounce(options ? { options } : true);
        setTimeout(() => setShouldBounce(false), 0);
    };
    useEffect(() => {
        if (!didBounceInitially && !isPreviewLoading && position === 'bottom') {
            triggerBounce({ delay: 1.5 });
            setDidBounceInitially(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreviewLoading]);
    const value = useMemo(() => ({
        position,
        setPosition,
        shouldBounce,
        triggerBounce,
    }), [shouldBounce, position]);
    return React.createElement(BottomSheetContext.Provider, { value: value }, children);
};
export const useBottomSheetContext = () => {
    const context = useContext(BottomSheetContext);
    if (context === undefined) {
        throw new Error('useBottomSheetContext must be used within an BottomSheetProvider');
    }
    return context;
};
