import React from 'react';
import { EmptyState, List, Table, useMediaQueryContext } from '@fourthwall/components';
import { Link } from 'react-router-dom';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { routing } from '@utils/routing';
import { reverse } from 'named-urls';
import { DonationListItem } from './components/DonationListItem';
import { getTableHeaders } from './utils/getTableHeaders';
const getDonationLink = (donation) => ({
    pathname: reverse(routing.contributions.orders.donations.donation.self, {
        donationId: donation.id,
    }),
    search: location.search,
});
export const DonationsData = ({ data, isLoading }) => {
    const { hasPermission } = useDashboardPermissions();
    const media = useMediaQueryContext();
    const emptyComponent = (React.createElement(EmptyState, { variant: media.tablet ? 'primary' : 'secondary', title: "You haven\u2019t received any standalone donations yet", subtitle: React.createElement(React.Fragment, null,
            "Orders with an added donation are in the",
            ' ',
            React.createElement(Link, { to: routing.contributions.orders.all }, "orders tab"),
            ".") }));
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, emptyComponent: emptyComponent, renderItem: (donation) => React.createElement(DonationListItem, { donation: donation }), getItemLink: getDonationLink }));
    }
    return (React.createElement(Table, { data: data, headers: getTableHeaders({ hideProfitColumn: !hasPermission('contributions.profit') }), isLoading: isLoading, emptyComponent: emptyComponent, rowComponent: Link, getRowLink: getDonationLink }));
};
