import * as Yup from 'yup';
import { FormFields } from './types';
export const getValidationSchema = (maxPosition, currentPosition) => Yup.object().shape({
    [FormFields.type]: Yup.string().required(),
    [FormFields.exactPosition]: Yup.number().when(FormFields.type, (type) => {
        return type === 'EXACT'
            ? Yup.number()
                .required('Enter a new position to move the product to')
                .max(maxPosition, `New position can not be higher than ${maxPosition}`)
                .min(1, 'New position can not be lower than 1')
                .not([currentPosition], 'New position can not be the same as current')
            : Yup.number();
    }),
});
