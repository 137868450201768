import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HeadContent } from '@fourthwall/components';
import { SettingsTabs } from '@components/common/SettingsTabs';
import { Dashboard, PageLayout } from '@components/common';
import { PermissionGuardedRoute } from '@components/utils/DashboardPermissions';
import { routing } from '@utils/routing';
import { SECTION_NAMES } from '@constants/sectionNames';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { CONNECTED_SITES_TITLE, ConnectedSitesContainer } from './modules/ConnectedSites';
import { EmailNotificationsContainer } from './modules/EmailNotifications/EmailNotificationsContainer';
import { GENERAL_VIEW_TITLE, GeneralContainer } from './modules/General';
import { SecurityContainer } from './modules/Security';
const createPath = (path) => {
    return path.replace(routing.account.self, '');
};
export const AccountView = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const tabs = [
        {
            name: GENERAL_VIEW_TITLE,
            path: routing.account.self,
            element: (React.createElement(PermissionGuardedRoute, { permission: "accountSettings", element: React.createElement(GeneralContainer, null) })),
        },
        {
            name: SECTION_NAMES.accountSecurity,
            path: routing.account.security,
            element: React.createElement(SecurityContainer, null),
            enabled: dashboardFeatureFlags.accountSettingsSecurity.enabled,
        },
        {
            name: 'Email notifications',
            path: routing.account.emailNotifications,
            element: (React.createElement(PermissionGuardedRoute, { permission: "accountSettings", element: React.createElement(EmailNotificationsContainer, null) })),
        },
        {
            name: CONNECTED_SITES_TITLE,
            path: routing.account.connectedSites,
            element: (React.createElement(PermissionGuardedRoute, { permission: "accountSettings", element: React.createElement(ConnectedSitesContainer, null) })),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Account settings" }),
        React.createElement(PageLayout, { size: "small" },
            React.createElement(Dashboard.Section, { name: "Account settings" },
                React.createElement(SettingsTabs, { tabs: tabs.filter(({ enabled = true }) => !!enabled), element: React.createElement(Routes, null, tabs.map(({ name, path, element }) => (React.createElement(Route, { key: name, path: createPath(path), element: element })))) })))));
};
