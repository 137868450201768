import { Subsection, useMediaQueryContext, useModalContext } from '@fourthwall/components';
import React from 'react';
import { useFormikContext } from 'formik';
import { SelectCountryModal } from '@components/modals/SelectCountryModal';
import { SelectedCountries } from '@components/common/SelectedCountries';
export const Countries = () => {
    const media = useMediaQueryContext();
    const { open } = useModalContext();
    const { values, setFieldValue, errors, submitCount } = useFormikContext();
    const openSelectCountryModal = () => open(SelectCountryModal, {
        initialCountries: values.selectedCountries,
        title: 'Select countries to offer shipping to',
        onConfirm: (countries) => {
            setFieldValue('selectedCountries', countries);
        },
    });
    return (React.createElement(Subsection, { title: "Countries to offer shipping to", borderless: true, noMargin: true },
        React.createElement("div", { style: { marginTop: -8 } },
            React.createElement(SelectedCountries, { maxHeight: media.laptop ? 400 : 290, data: values.selectedCountries, countriesCount: values.selectedCountries.length, error: submitCount ? errors.selectedCountries : undefined, onAddCountry: openSelectCountryModal, onRemoveCountry: (removedCountry) => {
                    setFieldValue('selectedCountries', values.selectedCountries.filter((country) => country !== removedCountry));
                } }))));
};
