import { ShopsApiClient } from '@fourthwall/services/lib/ShopsApiClient';
import { useMutation } from '@tanstack/react-query';
import { useCurrentShopQuery } from '../../../../hooks/useCurrentShopQuery';
export const useUpdateShopOriginLocationMutation = (shopId) => {
    const { setCurrentShopData } = useCurrentShopQuery(undefined, { enabled: false });
    const updateShopOriginLocationMutation = useMutation({
        mutationFn: async (payload) => {
            const { data } = await ShopsApiClient.updateOriginAddress(shopId, payload);
            return data;
        },
        onSuccess: (data) => {
            setCurrentShopData(data);
        },
    });
    return {
        updateShopOriginLocationMutation,
    };
};
