import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
`;
export const Name = styled.div `
  margin-left: 20px;
`;
export const Photo = styled.div `
  position: relative;
`;
