export const getShippingGroups = (shippingProfilesResponse) => {
    if (!shippingProfilesResponse || shippingProfilesResponse.shippingProfiles.length === 0) {
        return [];
    }
    return shippingProfilesResponse.shippingProfiles.map((profile, index) => ({
        id: profile.id || `shippingGroup${index}`,
        offerIds: profile.offerIds,
        countries: profile.countries,
    }));
};
