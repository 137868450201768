import { useCurrentShopContext } from '../../../../../../../hooks/useCurrentShopContext';
import { useActiveThemeContext } from '../../../../../../../providers/ActiveThemeProvider';
import { useSectionSchemasQuery } from './useSectionSchemasQuery';
export const useSectionConfigurations = (blockType) => {
    const { currentShop } = useCurrentShopContext();
    const { activeTheme } = useActiveThemeContext();
    const { sectionSchemasQuery } = useSectionSchemasQuery([
        { shop_id: currentShop.id, theme_id: activeTheme.id },
        {
            platform_block_type: blockType,
        },
    ]);
    return {
        sectionConfigurations: sectionSchemasQuery.data?.sectionSchemas,
    };
};
