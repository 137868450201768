import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  min-height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.GS400};
  padding: 6px 0;
  gap: 12px;

  ${mediaQueries.laptop} {
    gap: 20px;
    padding: 8px 0;
  }
`;
export const Indent = styled.div `
  padding-left: 24px;
  height: 100%;

  ${mediaQueries.laptop} {
    padding-left: 48px;
  }
`;
export const Name = styled.div `
  width: 230px;
  height: 100%;
  display: flex;
  align-items: center;

  label {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${mediaQueries.laptop} {
    flex: 5;
  }
`;
export const QuantityButton = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
  display: flex;
  gap: 4px;
`;
export const Stock = styled.div `
  width: 130px;
  font-size: 14px;
  color: ${Colors.GS600};

  ${mediaQueries.laptop} {
    flex: 2;
    font-size: 16px;
    color: ${Colors.GS1000};
  }
`;
export const Quantity = styled.div `
  flex: 1;

  ${mediaQueries.tablet} {
    flex: none;
    width: 70px;
  }

  ${mediaQueries.laptop} {
    flex: 1;
  }
`;
export const Price = styled.div `
  min-width: 60px;
  font-size: 14px;

  ${mediaQueries.laptop} {
    flex: 1;
  }
`;
export const LeftSide = styled.div `
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    align-items: center;
  }
`;
export const CheckboxWrapper = styled.div `
  -webkit-tap-highlight-color: transparent;
`;
export const Product = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const ProductDetails = styled.div `
  display: flex;
  flex-direction: column;
`;
