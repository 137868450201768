import React, { useLayoutEffect, useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import * as S from './OrderSummaryTooltip.styled';
export const OrderSummaryTooltip = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const positionUpdateRef = useRef(null);
    useLayoutEffect(() => {
        if (positionUpdateRef.current) {
            positionUpdateRef.current();
        }
    }, [content]);
    const showTip = () => {
        setIsVisible(true);
    };
    const hideTip = () => {
        setIsVisible(false);
    };
    const renderReferenceChildren = ({ ref }) => {
        return React.createElement("div", { ref: ref }, children);
    };
    const renderPopperChildren = ({ ref, style, placement: dataPlacement, update, }) => {
        positionUpdateRef.current = update;
        return (React.createElement("div", { ref: ref, "data-placement": dataPlacement, style: { ...style, paddingTop: 4, paddingBottom: 4, zIndex: 1 } },
            React.createElement(S.Box, null, content)));
    };
    return (React.createElement(Manager, null,
        React.createElement(S.Container, { onMouseEnter: showTip, onTouchStart: showTip, onMouseLeave: hideTip, onTouchEnd: hideTip, "data-testid": "OrderSummaryTooltip" },
            React.createElement(Reference, null, renderReferenceChildren),
            isVisible && (React.createElement(Popper, { placement: "bottom-start", modifiers: [
                    { name: 'preventOverflow', enabled: false },
                    { name: 'hide', enabled: false },
                ] }, renderPopperChildren)))));
};
