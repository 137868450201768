import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex: ${({ width }) => width};
  padding: 8px 20px 8px 0px;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : '0')};

  &:last-child {
    padding-right: 0;
  }

  ${({ height }) => height
    ? css `
          height: ${height}px;
        `
    : css `
          min-height: 89px;
        `};

  ${({ minHeight }) => minHeight &&
    css `
      min-height: ${minHeight}px;
    `};

  ${({ alignment }) => alignment === 'right' &&
    css `
      justify-content: flex-end;
    `};

  ${({ compact }) => compact &&
    css `
      padding: 0 20px 0 0;
      min-height: 0;
    `};
`;
