import React, { useCallback, useMemo } from 'react';
import { StatusTag } from '@components/common/StatusTag';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { getDefaultProductImage } from '@utils/products';
import { Colors, Dropdown, ToolbarButton } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import * as S from './styled';
export const SelectedProductsListItem = ({ product, disabled, onRemove, onPositionChange, }) => {
    const dropdownAction = useCallback((event, action) => {
        event.preventDefault();
        action();
    }, []);
    const dropdownContent = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement(Dropdown.Item, { label: "Remove from collection", onClick: (e) => dropdownAction(e, onRemove) }),
        React.createElement(Dropdown.Item, { label: "Change position", onClick: (e) => dropdownAction(e, onPositionChange) }))), [dropdownAction, onRemove, onPositionChange]);
    return (React.createElement(S.Container, null,
        React.createElement(S.LeftSide, null,
            React.createElement(PhotoThumbnail, { url: getDefaultProductImage(product), size: "medium" }),
            React.createElement(S.Details, null,
                React.createElement("div", null, product.name),
                React.createElement(StatusTag, { status: product.state.status, isSoldOut: !product.state.available }))),
        !disabled && (React.createElement(S.RightSide, null,
            React.createElement(Dropdown, { content: dropdownContent },
                React.createElement(ToolbarButton, { icon: DotsHorizontalIcon, color: Colors.GS600 }))))));
};
