import { AlertModal, Toasts, useModalContext } from '@fourthwall/components';
import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import { useMutation } from '@tanstack/react-query';
export const useDeleteDiscordIntegrationMutation = () => {
    const { open } = useModalContext();
    const deleteDiscordIntegrationMutation = useMutation({
        mutationFn: async () => {
            const { data } = await MembershipsCreatorApiClient.deleteDiscordIntegration();
            return data;
        },
        onError: (error) => {
            if (error.response?.status === 409) {
                return open(AlertModal, {
                    title: 'Please remove Discord perk first',
                    text: 'Please remove the Discord perk first before removing the server connection.',
                    size: 'small',
                });
            }
            const errorMessage = errorMessageSelector(error.response?.data);
            Toasts.notify(errorMessage, { type: 'error' });
        },
    });
    return { deleteDiscordIntegrationMutation };
};
