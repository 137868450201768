import { automatedThemeDefaults } from './const';
export const getCssVariable = (variableName) => {
    return getComputedStyle(document.documentElement).getPropertyValue(variableName);
};
export const getColorsScheme = (base) => {
    if (getCssVariable('--color-primary').length) {
        const colorPrimary = getCssVariable('--color-primary');
        const colorOnPrimary = getCssVariable('--color-on-primary');
        const colorBackground = getCssVariable('--color-background');
        const colorOnBackground = getCssVariable('--color-on-background');
        return {
            colorBackground,
            colorOnBackground,
            colorPrimary,
            colorOnPrimary,
        };
    }
    const defaultTheme = base.content?.checkout?.type === 'DARK'
        ? automatedThemeDefaults.highContrastDark
        : automatedThemeDefaults.highContrastLight;
    return {
        ...defaultTheme,
    };
};
