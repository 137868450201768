import { HeadContent, Skeleton } from '@fourthwall/components';
import React from 'react';
import { Dashboard } from '@components/common/Dashboard';
import { OrderedList } from '@components/common/OrderedList';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { routing } from '@utils/routing';
import { SECTION_NAMES } from '@constants/sectionNames';
import { YouTubeVideoEntry } from '../../components/YouTubeVideoEntry';
import { ConnectBar } from '../../components/ConnectBar';
import { useInstagram } from './hooks/useInstagram';
export const AppsInstagram = () => {
    const { instagram } = useInstagram();
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: SECTION_NAMES.appInstagram }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: SECTION_NAMES.appInstagram, topContent: React.createElement(DashboardBackButton, { label: SECTION_NAMES.apps, to: routing.apps.self }), bottomContent: React.createElement("div", { style: { lineHeight: 1.5 } },
                    React.createElement("p", null, "Showcase your Instagram feed on your homepage."),
                    React.createElement(OrderedList, null,
                        React.createElement(OrderedList.Item, null, "Connect your Instagram account."),
                        React.createElement(OrderedList.Item, null,
                            "Add \u201CInstagram feed\u201D section to your homepage on ",
                            SECTION_NAMES.themeEditor,
                            " tab."))) },
                React.createElement("div", { style: { marginTop: 16 - 24, marginBottom: 40 } },
                    instagram.status && (React.createElement("div", { style: { marginBlock: 20 } },
                        React.createElement(YouTubeVideoEntry, { title: "Showcase Your Instagram Feed on Your Shop", description: "Add your Instagram feed directly to your Fourthwall shop and keep your followers engaged with your latest content.", size: instagram.status === 'NOT_CONNECTED' ? 'regular' : 'compact', videoId: "oCYOQ3Wpw1w" }))),
                    instagram.connectBar ? (React.createElement(ConnectBar, { ...instagram.connectBar })) : (React.createElement(Skeleton, null,
                        React.createElement(Skeleton.Shape, { height: 64 }))))))));
};
