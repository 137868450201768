import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { swatchSizes } from './constants';
export const Swatch = styled.div `
  position: relative;
  z-index: 0;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;

  width: ${({ size }) => swatchSizes[size]}px;
  height: ${({ size }) => swatchSizes[size]}px;

  ${({ variant, disabled }) => variant === 'checkable' &&
    css `
      display: flex;
      align-items: center;
      justify-content: center;

      ${!disabled &&
        css `
        cursor: pointer;
      `}
    `}

  ${({ variant, active, disabled }) => variant === 'selectable' &&
    css `
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        display: ${active ? 'block' : 'none'};
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 2px solid ${Colors.GS1000};
      }

      ${!disabled &&
        css `
        cursor: pointer;

        &:hover {
          &::before {
            display: block;
          }
        }
      `}
    `}

  ${({ disabled }) => disabled &&
    css `
      cursor: not-allowed;
      pointer-events: none;
    `}
`;
export const CrossOut = styled.div `
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  width: 2px;
  height: 133%;
  background-color: ${Colors.GS900};
  transform: rotate(-45deg) translate(2px, -1px);
  transform-origin: 100% 100%;
  box-shadow: -1px 1px 0px 1px #ffffff;
`;
