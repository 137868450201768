import React from 'react';
import { OnDemandListing } from './modules/OnDemandListing';
import { ExplanationText } from '../../../components/ExplanationText';
export const OfferListingType = ({ product, listingType }) => {
    return (React.createElement(React.Fragment, null,
        listingType === 'YOU' && (React.createElement(ExplanationText, { title: "You handle shipping", subtitle: "You're responsible for packing and shipping orders." })),
        listingType === 'ON_DEMAND' && !product && (React.createElement(ExplanationText, { title: "On demand", subtitle: "This product is produced to order. Orders arrive within a few days of being placed." })),
        listingType === 'ON_DEMAND' && product && React.createElement(OnDemandListing, { product: product }),
        listingType === 'DIGITAL' && (React.createElement(ExplanationText, { title: "Digital product", subtitle: "Downloads will be emailed immediately upon purchase." })),
        listingType === 'WE' && (React.createElement(ExplanationText, { title: "We handle shipping", subtitle: "We'll ship orders out within one business day of receiving them, as long as they are in stock." }))));
};
