import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { TABLE_QUERIES_LIMIT, formatMoneyValue, getValue, mapChartToSeries, mapRangeToText, mapSaleByCountryTableDataItemToAnalyticsRow, useAnalyticsChartQuery, useMembershipsChartQuery, useMembershipsTopTiersTableQuery, useSaleByCountryTableQuery, useTopOfferTableQuery, } from '@fourthwall/module-analytics';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDashboardPermissions } from '../../../../../components/utils/DashboardPermissions';
import { useCurrentShop } from '../../../hooks/useCurrentShop';
import { useMembershipAccountQuery } from '../../../hooks/useMembershipAccountQuery';
import { REPORT_TYPES } from '../../consts';
import { createGetReportTo, getEndDateFromRange, mapOffersToAnalyticsRows, mapTopTiersToAnalyticsRows, prepareSingleValueChart, } from './utils';
export const useAnalytics = (params) => {
    const { hasPermission } = useDashboardPermissions();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { currentShop } = useCurrentShop({ staleTime: Infinity });
    const membershipsEnabled = !!currentShop?.settings.memberships?.enabled;
    const { analyticsChartQuery: profitQuery } = useAnalyticsChartQuery([
        { ...params, chartEntityGroup: undefined, chartTypes: ['PROFIT'], sid: [currentShop?.id] },
    ]);
    const { analyticsChartQuery: contributionQuery } = useAnalyticsChartQuery([
        {
            ...params,
            chartEntityGroup: undefined,
            chartTypes: ['CONTRIBUTION'],
            sid: [currentShop?.id],
        },
    ]);
    const { analyticsChartQuery: contributionAvgQuery } = useAnalyticsChartQuery([
        {
            ...params,
            chartEntityGroup: undefined,
            chartTypes: ['CONTRIBUTION', 'CONTRIBUTION_AVG', 'GROSS_SALES'],
            sid: [currentShop?.id],
        },
    ]);
    const { membershipsChartQuery: membersTotalQuery } = useMembershipsChartQuery([
        {
            ...params,
            tiersIds: undefined,
            intervals: undefined,
            devices: undefined,
            chartTypes: ['MEMBERS_TOTAL'],
            sid: [currentShop?.id],
        },
    ], { enabled: membershipsEnabled });
    const { membershipsChartQuery: membersTotalFreeQuery } = useMembershipsChartQuery([
        {
            ...params,
            tiersIds: undefined,
            intervals: undefined,
            devices: undefined,
            chartTypes: ['FREE_ACCOUNTS_TOTAL'],
            sid: [currentShop?.id],
        },
    ], { enabled: membershipsEnabled });
    const { membershipsChartQuery: membersChangeQuery } = useMembershipsChartQuery([
        {
            ...params,
            tiersIds: undefined,
            intervals: undefined,
            devices: undefined,
            chartTypes: ['MEMBERS_CHANGE'],
            sid: [currentShop?.id],
        },
    ], { enabled: membershipsEnabled });
    const { membershipsChartQuery: freeMembersChangeQuery } = useMembershipsChartQuery([
        {
            ...params,
            tiersIds: undefined,
            intervals: undefined,
            devices: undefined,
            chartTypes: ['FREE_ACCOUNTS_CHANGE'],
            sid: [currentShop?.id],
        },
    ], { enabled: membershipsEnabled });
    const { topOfferTableQuery } = useTopOfferTableQuery([
        { ...params, limit: TABLE_QUERIES_LIMIT, sid: [currentShop?.id] },
    ]);
    const { saleByCountryTableQuery } = useSaleByCountryTableQuery([
        { ...params, limit: TABLE_QUERIES_LIMIT, sid: [currentShop?.id] },
    ]);
    const { membershipsTopTiersTableQuery } = useMembershipsTopTiersTableQuery([{ ...params, limit: TABLE_QUERIES_LIMIT, sid: [currentShop?.id] }], { enabled: membershipsEnabled });
    const { membershipAccountQuery } = useMembershipAccountQuery();
    const location = useLocation();
    const getReportTo = createGetReportTo(location);
    const profit = useMemo(() => {
        if (!hasPermission('analytics.profit')) {
            return undefined;
        }
        return {
            title: REPORT_TYPES.profit.title,
            reportTo: getReportTo(REPORT_TYPES.profit.slug),
            range: mapRangeToText(profitQuery.data?.current.range),
            value: getValue(profitQuery.data?.current.profit),
            series: mapChartToSeries(profitQuery.data?.current.profit?.data, params),
            isLoading: profitQuery.isLoading,
            isError: profitQuery.isError,
            formatDate: REPORT_TYPES.profit.getDateFormatter(params.precision),
            formatValue: REPORT_TYPES.profit.formatValue,
        };
    }, [getReportTo, profitQuery, params, hasPermission]);
    const membershipsTotalMembers = useMemo(() => {
        if (!membershipsEnabled || !hasPermission('analytics.totalMembers')) {
            return undefined;
        }
        return {
            title: REPORT_TYPES['memberships-total-members'].title,
            reportTo: getReportTo(REPORT_TYPES['memberships-total-members'].slug),
            range: mapRangeToText(membersTotalQuery.data?.current.range),
            value: getValue(membersTotalQuery.data?.current.totalMembers),
            series: mapChartToSeries(membersTotalQuery.data?.current.totalMembers?.data, params),
            isLoading: membersTotalQuery.isLoading,
            isError: membersTotalQuery.isError,
            formatDate: REPORT_TYPES['memberships-total-members'].getDateFormatter(params.precision),
            formatValue: REPORT_TYPES['memberships-total-members'].formatValue,
        };
    }, [getReportTo, membersTotalQuery, params, membershipsEnabled, hasPermission]);
    const membershipsTotalFreeMembers = useMemo(() => {
        if (!membershipsEnabled ||
            !hasPermission('analytics.totalMembers') ||
            !dashboardFeatureFlags.membershipsFreeAccounts.enabled) {
            return undefined;
        }
        return {
            title: REPORT_TYPES['memberships-total-free-members'].title,
            tooltip: REPORT_TYPES['memberships-total-free-members'].tooltip,
            reportTo: getReportTo(REPORT_TYPES['memberships-total-free-members'].slug),
            range: mapRangeToText(membersTotalFreeQuery.data?.current.range),
            value: getValue(membersTotalFreeQuery.data?.current.totalFreeAccounts),
            series: mapChartToSeries(membersTotalFreeQuery.data?.current.totalFreeAccounts?.data, params),
            isLoading: membersTotalFreeQuery.isLoading,
            isError: membersTotalFreeQuery.isError,
            formatDate: REPORT_TYPES['memberships-total-free-members'].getDateFormatter(params.precision),
            formatValue: REPORT_TYPES['memberships-total-free-members'].formatValue,
        };
    }, [getReportTo, membersTotalFreeQuery, params, membershipsEnabled, hasPermission]);
    const membershipsMembersChange = useMemo(() => {
        const getMembershipsMembersChangeValue = () => {
            const value = getValue(membersChangeQuery.data?.current.membersChange);
            if (!value || typeof value !== 'number') {
                return 0;
            }
            return value <= 0 ? value : `+${value}`;
        };
        if (!membershipsEnabled || !hasPermission('analytics.membersChange')) {
            return undefined;
        }
        return {
            title: REPORT_TYPES['memberships-members-change'].title,
            reportTo: getReportTo(REPORT_TYPES['memberships-members-change'].slug),
            range: mapRangeToText(membersChangeQuery.data?.current.range),
            value: getMembershipsMembersChangeValue(),
            series: mapChartToSeries(prepareSingleValueChart(membersChangeQuery.data?.current.membersChange?.data), params),
            isLoading: membersChangeQuery.isLoading,
            isError: membersChangeQuery.isError,
            formatDate: REPORT_TYPES['memberships-members-change'].getDateFormatter(params.precision),
            formatValue: REPORT_TYPES['memberships-members-change'].formatValue,
        };
    }, [getReportTo, membersChangeQuery, params, membershipsEnabled, hasPermission]);
    const membershipsFreeMembersChange = useMemo(() => {
        const getMembershipsFreeMembersChangeValue = () => {
            const value = getValue(freeMembersChangeQuery.data?.current.freeAccountsChange);
            if (!value || typeof value !== 'number') {
                return 0;
            }
            return value <= 0 ? value : `+${value}`;
        };
        if (!membershipsEnabled ||
            !hasPermission('analytics.membersChange') ||
            !dashboardFeatureFlags.membershipsFreeAccounts.enabled) {
            return undefined;
        }
        return {
            title: REPORT_TYPES['memberships-free-members-change'].title,
            reportTo: getReportTo(REPORT_TYPES['memberships-free-members-change'].slug),
            range: mapRangeToText(freeMembersChangeQuery.data?.current.range),
            value: getMembershipsFreeMembersChangeValue(),
            series: mapChartToSeries(prepareSingleValueChart(freeMembersChangeQuery.data?.current.freeAccountsChange?.data), params),
            isLoading: freeMembersChangeQuery.isLoading,
            isError: freeMembersChangeQuery.isError,
            formatDate: REPORT_TYPES['memberships-free-members-change'].getDateFormatter(params.precision),
            formatValue: REPORT_TYPES['memberships-free-members-change'].formatValue,
        };
    }, [getReportTo, freeMembersChangeQuery, params, membershipsEnabled, hasPermission]);
    const contributions = useMemo(() => {
        if (!hasPermission('analytics.contributions')) {
            return undefined;
        }
        return {
            title: REPORT_TYPES.orders.title,
            reportTo: getReportTo(REPORT_TYPES.orders.slug),
            range: mapRangeToText(contributionQuery.data?.current.range),
            value: getValue(contributionQuery.data?.current.contribution),
            series: mapChartToSeries(contributionQuery.data?.current.contribution?.data, params),
            isLoading: contributionQuery.isLoading,
            isError: contributionQuery.isError,
            formatDate: REPORT_TYPES.orders.getDateFormatter(params.precision),
            formatValue: REPORT_TYPES.orders.formatValue,
        };
    }, [getReportTo, contributionQuery, params, hasPermission]);
    const contributionValue = useMemo(() => {
        if (!hasPermission('analytics.averageContributionValue')) {
            return undefined;
        }
        return {
            title: REPORT_TYPES['average-order-value'].title,
            reportTo: getReportTo(REPORT_TYPES['average-order-value'].slug),
            range: mapRangeToText(contributionAvgQuery.data?.current.range),
            value: getValue(contributionAvgQuery.data?.current.averageGrossSalesValue),
            series: mapChartToSeries(contributionAvgQuery.data?.current.contributionAvg?.data, params),
            isLoading: contributionAvgQuery.isLoading,
            isError: contributionAvgQuery.isError,
            formatDate: REPORT_TYPES['average-order-value'].getDateFormatter(params.precision),
            formatValue: REPORT_TYPES['average-order-value'].formatValue,
        };
    }, [getReportTo, contributionAvgQuery, params, hasPermission]);
    const topProducts = useMemo(() => {
        if (!hasPermission('analytics.topProducts')) {
            return undefined;
        }
        return {
            title: REPORT_TYPES['top-products-by-units-sold'].title,
            reportTo: getReportTo(REPORT_TYPES['top-products-by-units-sold'].slug),
            range: mapRangeToText(topOfferTableQuery.data?.current.range),
            rows: mapOffersToAnalyticsRows(topOfferTableQuery.data?.current.data),
            isLoading: topOfferTableQuery.isLoading,
            isError: topOfferTableQuery.isError,
            formatValue: formatMoneyValue,
        };
    }, [getReportTo, topOfferTableQuery, hasPermission]);
    const salesByCountry = useMemo(() => {
        if (!hasPermission('analytics.salesByCountry')) {
            return undefined;
        }
        return {
            title: REPORT_TYPES['sales-by-country'].title,
            reportTo: getReportTo(REPORT_TYPES['sales-by-country'].slug),
            range: mapRangeToText(saleByCountryTableQuery.data?.current.range),
            rows: (saleByCountryTableQuery.data?.current.data || []).map(mapSaleByCountryTableDataItemToAnalyticsRow),
            isLoading: saleByCountryTableQuery.isLoading,
            isError: saleByCountryTableQuery.isError,
            formatValue: formatMoneyValue,
        };
    }, [getReportTo, saleByCountryTableQuery, hasPermission]);
    const membershipsTopTiers = useMemo(() => {
        if (!membershipsEnabled || !hasPermission('analytics.topTiers')) {
            return undefined;
        }
        return {
            title: REPORT_TYPES['membership-top-tiers'].title,
            reportTo: getReportTo(REPORT_TYPES['membership-top-tiers'].slug),
            range: getEndDateFromRange(membershipsTopTiersTableQuery.data?.current.range),
            rows: mapTopTiersToAnalyticsRows(membershipsTopTiersTableQuery.data?.current.data, membershipAccountQuery.data?.tiers),
            isLoading: membershipsTopTiersTableQuery.isLoading,
            isError: membershipsTopTiersTableQuery.isError,
            formatValue: formatMoneyValue,
        };
    }, [
        getReportTo,
        membershipsTopTiersTableQuery,
        membershipAccountQuery.data?.tiers,
        membershipsEnabled,
        hasPermission,
    ]);
    return {
        analytics: {
            profit,
            membershipsTotalMembers,
            membershipsTotalFreeMembers,
            membershipsMembersChange,
            membershipsFreeMembersChange,
            contributions,
            contributionValue,
            topProducts,
            salesByCountry,
            membershipsTopTiers,
        },
    };
};
