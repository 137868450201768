export const Colors = {
    // Grayscale
    GS0: '#FFFFFF',
    GS100: '#FDFCFB',
    GS200: '#F5F4F3',
    GS300: '#EAEAE9',
    GS400: '#E3E2E1',
    GS500: '#CDCCCA',
    GS550: '#8E8E89',
    GS600: '#6F6F69',
    GS700: '#40413D',
    GS800: '#272825',
    GS900: '#1B1D19',
    GS1000: '#10110D',
    // Blue
    B100: '#F2F5FE',
    B200: '#CED9FC',
    B300: '#9DB4F9',
    B400: '#6C90F7',
    B500: '#0042FF',
    B600: '#0338BE',
    B700: '#002481',
    // Green
    G100: '#B4EDE5',
    G200: '#9AE2D8',
    G300: '#44B59E',
    G400: '#146F5D',
    G500: '#1A4B41',
    // Yellow
    Y100: '#FAE5A7',
    Y200: '#EBCE78',
    Y300: '#DFA931',
    Y400: '#98721E',
    Y500: '#5F4813',
    // Red
    R100: '#F8D3DB',
    R200: '#EBB1BC',
    R300: '#C52F40',
    R400: '#9C2D3A',
    R500: '#701620',
};
