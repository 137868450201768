import { HeadContent, Pagination } from '@fourthwall/components';
import isEmpty from 'lodash-es/isEmpty';
import React from 'react';
import { Dashboard } from '@components/common';
import { DonationsData } from './components/DonationsData';
export const DonationsView = ({ donations, currentPage, totalPages, isFetching, onPageChange, }) => {
    const renderPagination = () => {
        if (!isEmpty(donations) && totalPages > 0) {
            return (React.createElement(Dashboard.Section.Item, null,
                React.createElement(Pagination, { pageCount: totalPages, currentPage: currentPage, onChange: onPageChange })));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Donations" }),
        React.createElement(Dashboard.Section.Item, null,
            React.createElement(DonationsData, { data: donations || [], isLoading: isFetching })),
        renderPagination()));
};
