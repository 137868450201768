import React from 'react';
import { BackButton, ButtonGroup, ConfirmModal, HeadContent, ToolbarButton, useModalContext, } from '@fourthwall/components';
import { AnalyticsCard, AnalyticsChart, StatsTable } from '@fourthwall/module-analytics';
import { DownloadIcon } from '@fourthwall/icons';
import { isWebView } from '@fourthwall/utils';
import { Dashboard, PageLayout } from '@components/common';
import { AnalyticsToolbar } from '../../components/AnalyticsToolbar/AnalyticsToolbar';
export const AnalyticsReportView = ({ title, backButtonLabel, backButtonTo, exportButton, rangeSelect, rangePicker, precisionSelect, chartEntityGroupSelect, chart, statsTable, devicesSelect, intervalSelect, tiersSelect, }) => {
    const { open } = useModalContext();
    const handleExportClick = () => {
        open(ConfirmModal, {
            title: 'Export report',
            text: 'Report will be exported as CSV table.',
            confirmLabel: 'Export',
            onConfirm: exportButton.onConfirm,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: title, titleTemplate: isWebView() ? title : undefined }),
        React.createElement(PageLayout, null,
            React.createElement(Dashboard.Section, { name: isWebView() ? undefined : title, topContent: isWebView() ? undefined : React.createElement(BackButton, { label: backButtonLabel, to: backButtonTo }), toolbarContent: isWebView() ? undefined : (React.createElement(ButtonGroup, null,
                    React.createElement(ToolbarButton, { label: "Export", icon: DownloadIcon, onClick: handleExportClick }))) },
                React.createElement(AnalyticsToolbar, { rangeSelect: rangeSelect, rangePicker: rangePicker, precisionSelect: precisionSelect, chartEntityGroupSelect: chartEntityGroupSelect, devicesSelect: devicesSelect, intervalSelect: intervalSelect, tiersSelect: tiersSelect }),
                React.createElement("div", { style: { display: 'grid', gap: 20 } },
                    chart && (React.createElement(AnalyticsCard, { title: chart.title, value: chart.value, statistics: React.createElement(AnalyticsChart, { label: chart.title, description: chart.description, series: chart.series, formatDate: chart.formatDate, formatValue: chart.formatValue }), isLoading: chart.isLoading, isError: chart.isError })),
                    React.createElement(StatsTable, { columns: statsTable.columns, data: statsTable.data, emptyMessage: statsTable.emptyMessage, isLoading: statsTable.isLoading, getRowBold: statsTable.getRowBold }))))));
};
