import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
const today = dayjs().utc().startOf('day');
export const getFrom = (range) => {
    if (range === 7) {
        return today.subtract(7 - 1, 'days').toISOString();
    }
    if (range === 30) {
        return today.subtract(30 - 1, 'days').toISOString();
    }
    return today.toISOString();
};
export const to = today.endOf('day').toISOString();
export const getPrecision = (range) => {
    if (range === 1) {
        return 'HOUR';
    }
    return 'DAY';
};
export const getRangeQueryParam = (range) => {
    if (range === 7) {
        return 'last7days';
    }
    if (range === 30) {
        return 'last30days';
    }
    return 'today';
};
