import { useQueryClient } from '@tanstack/react-query';
export const useAllPerks = () => {
    const queryClient = useQueryClient();
    const allPerks = queryClient
        .getQueriesData(['tierPerks'])
        .reduce((sum, tierPerks) => {
        if (tierPerks[1]?.perks) {
            return [...sum, ...tierPerks[1].perks];
        }
        return [...sum];
    }, []);
    return {
        allPerks,
        getPerk: (perkType) => allPerks.find((perk) => perk.type === perkType),
    };
};
