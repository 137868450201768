import React from 'react';
import { TableItemDate } from '@components/utils/TableItemDate';
import { InProgress, PriceString, ProfitString } from '@fourthwall/components';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import * as S from './styled';
export const ThankYouListItem = ({ thankYou }) => {
    const { hasPermission } = useDashboardPermissions();
    return (React.createElement(S.Container, null,
        React.createElement(S.Details, null,
            React.createElement(S.Supporter, null, thankYou.supporter.fullName),
            React.createElement(S.Tags, null, thankYou.tags.map((tag, index) => (React.createElement(S.Tag, { key: `${tag}${index}` }, tag)))),
            thankYou.message && React.createElement(S.Message, null, thankYou.message),
            React.createElement(S.Date, null,
                React.createElement(TableItemDate, { date: thankYou.state === 'SENT' ? thankYou.updatedAt : thankYou.createdAt }))),
        React.createElement(S.Calculations, null,
            React.createElement(PriceString, { value: thankYou.total.value }),
            hasPermission('contributions.profit') && (React.createElement(InProgress, null,
                React.createElement(S.Profit, null,
                    React.createElement(ProfitString, { value: 0.01 })))))));
};
