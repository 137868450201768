import { useMemo } from 'react';
import { useOffersQuery } from '@queries/useOffersQuery';
export const useProductApprovalStatus = () => {
    const { offersQuery: allOffersQuery } = useOffersQuery([{ size: 5 }]);
    const { offersQuery: publicOffersQuery } = useOffersQuery([{ status: ['PUBLIC'], size: 5 }]);
    const hasAnyOffer = !!allOffersQuery.data?.offers?.length;
    const hasAnyPublicOffer = !!publicOffersQuery.data?.offers?.length;
    const productApprovalStatus = useMemo(() => {
        if (!allOffersQuery.data || !publicOffersQuery.data) {
            return 'LOADING';
        }
        if (!hasAnyOffer) {
            return 'NO_OFFERS';
        }
        if (!hasAnyPublicOffer) {
            return 'NO_PUBLIC_OFFERS';
        }
        return 'APPROVED';
    }, [allOffersQuery.data, publicOffersQuery.data, hasAnyOffer, hasAnyPublicOffer]);
    return {
        productApprovalStatus,
    };
};
