import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import inRange from 'lodash-es/inRange';
import { useQueryParam } from 'use-query-params';
import { useQueryPage } from '@fourthwall/utils';
const DEFAULT_OPTIONS = {
    delay: 250,
    minLength: 3,
    maxLength: 512,
};
export const useSearchQuery = (options) => {
    const searchOptions = { ...DEFAULT_OPTIONS, ...options };
    const [searchQuery, setSearchQuery] = useQueryParam('q');
    const [searchValue, setSearchValue] = useState(searchQuery || '');
    const [searchValueDebounced] = useDebounce(searchValue, searchOptions.delay);
    const { setPage } = useQueryPage();
    const removeSearchQuery = () => setSearchQuery(undefined);
    const resetSearchValue = () => setSearchValue('');
    const handleSetQuery = (query) => {
        setPage(undefined);
        setSearchQuery(query);
    };
    useEffect(() => {
        if (searchValueDebounced.length < searchOptions.minLength) {
            removeSearchQuery();
        }
        else {
            handleSetQuery(searchValueDebounced);
        }
    }, [searchValueDebounced]);
    useEffect(() => {
        if (searchQuery) {
            if (inRange(searchQuery.length, searchOptions.minLength, searchOptions.maxLength)) {
                setSearchValue(searchQuery);
            }
            else {
                removeSearchQuery();
            }
        }
    }, [searchQuery]);
    return { searchValue, searchQuery, searchOptions, setSearchValue, resetSearchValue };
};
