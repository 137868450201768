export const getPresetColor = (preset) => ({
    value: preset.color,
    label: preset.name,
});
export const getPresetsColors = (presets) => presets.map(getPresetColor);
export const getSelectedPreset = (theme) => theme.presets.find((preset) => preset.isSelected);
export const mapPreset = (preset, index, installedTheme) => ({
    name: preset.name || '',
    handle: preset.handle || '',
    color: preset.color || '',
    previewUrl: preset.settings?.previewUrl,
    desktopImage: preset.screenshots?.desktopUrl,
    mobileImage: preset.screenshots?.mobileUrl,
    isSelected: index === 0,
    isInstalled: installedTheme?.preset === preset.name,
});
export const mapTheme = (theme, installedThemes) => {
    const installedTheme = installedThemes?.find((installedTheme) => theme.name === installedTheme.name);
    return {
        id: theme.versions?.[0]?.id,
        name: theme.name || '',
        handle: theme.handle || '',
        version: theme.versions?.[0]?.version,
        presets: theme.versions?.[0]?.presets?.map((theme, index) => mapPreset(theme, index, installedTheme)) || [],
        isInstalled: !!installedTheme,
        isCurrent: installedTheme?.role === 'main' || false,
    };
};
