import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  .simplebar-scrollbar:before {
    background-color: ${Colors.GS500};
    opacity: 1;
    width: 4px;
  }

  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
`;
