import { Modal, Pagination } from '@fourthwall/components';
import React, { useState } from 'react';
import { Search } from '../../../../../../../../../../../components/common/Search/Search';
import { SelectableList } from '../../../../../../../../../SelectableList/SelectableList/SelectableList';
import { SelectedItemsList } from '../../components/SelectedItemsList/SelectedItemsList';
import * as S from './Collections.styled';
import { useCreatorMessageCollections } from './hooks/useCreatorMessageCollections';
const CollectionsComponent = ({ onItemSelectionChange }) => {
    const [isModalVisible, setModalVisible] = useState(false);
    const { selectableListProps, selectedItemsListProps, pageProps: { showPagination, ...paginationProps }, } = useCreatorMessageCollections({ onItemSelectionChange });
    const closeModal = () => {
        setModalVisible(false);
    };
    return (React.createElement(S.Wrapper, null,
        React.createElement(Search, { placeholder: "Search collections", onBrowseClick: () => setModalVisible(true) }),
        React.createElement(SelectedItemsList, { ...selectedItemsListProps }),
        showPagination && (React.createElement(S.PaginationWrapper, null,
            React.createElement(Pagination, { ...paginationProps }))),
        React.createElement(Modal, { isOpen: isModalVisible, onRequestClose: closeModal },
            React.createElement(SelectableList, { ...selectableListProps, close: closeModal }))));
};
export const Collections = React.memo(CollectionsComponent);
