import apple from './Icon=Apple.svg';
import discord from './Icon=Discord.svg';
import facebook from './Icon=Facebook.svg';
import google from './Icon=Google.svg';
import instagram from './Icon=Instagram.svg';
import meta from './Icon=Meta.svg';
import streamelements from './Icon=StreamElements.svg';
import streamlabs from './Icon=Streamlabs.svg';
import tiktok from './Icon=TikTok.svg';
import twitch from './Icon=Twitch.svg';
import x from './Icon=X.svg';
import youtube from './Icon=YouTube.svg';
export const CONNECTION_ICONS = {
    apple,
    discord,
    facebook,
    google,
    instagram,
    meta,
    streamelements,
    streamlabs,
    tiktok,
    twitch,
    x,
    youtube,
};
