import tinycolor from 'tinycolor2';
import { automatedThemeDefaults, defaultColors } from './const';
import { getColorsScheme, getCssVariable } from './utils';
const getColorAlpha = (minAlpha, maxAlpha, referenceColorBrightness) => {
    return maxAlpha - ((maxAlpha - minAlpha) * referenceColorBrightness) / 255;
};
const getFonts = () => {
    if (getCssVariable('--font-family-base').length) {
        const fontFamilyBase = getCssVariable('--font-family-base');
        const fontWeightBase = getCssVariable('--font-weight-base');
        const fontWeightBaseBold = getCssVariable('--font-weight-base-bold');
        const fontStyleBase = getCssVariable('--font-style-base');
        const textTransformBase = getCssVariable('--text-transform-base');
        const fontFamilyBaseName = getCssVariable('--font-family-base-name');
        const fontFamilyBaseUrl = getCssVariable('--font-family-base-url');
        const fontFamilyHeading = getCssVariable('--font-family-heading');
        const fontWeightHeading = getCssVariable('--font-weight-heading');
        const fontStyleHeading = getCssVariable('--font-style-heading');
        const textTransformHeading = getCssVariable('--text-transform-heading');
        return {
            fontFamilyBase,
            fontWeightBase,
            fontWeightBaseBold,
            fontStyleBase,
            textTransformBase,
            fontFamilyBaseName,
            fontFamilyBaseUrl,
            fontFamilyHeading,
            fontWeightHeading,
            fontStyleHeading,
            textTransformHeading,
        };
    }
};
const getRadiuses = () => {
    if (getCssVariable('--button-corner-radius').length) {
        const buttonCornerRadius = getCssVariable('--button-corner-radius');
        const imageCornerRadius = getCssVariable('--image-corner-radius');
        const inputCornerRadius = getCssVariable('--input-corner-radius');
        const maxBorder = 20;
        return {
            buttonCornerRadius,
            imageCornerRadius: parseInt(imageCornerRadius) > maxBorder ? `${maxBorder}px` : imageCornerRadius,
            inputCornerRadius: parseInt(inputCornerRadius) > maxBorder ? `${maxBorder}px` : inputCornerRadius,
        };
    }
};
const getLogoWidth = () => {
    if (getCssVariable('--logo-width').length) {
        const logoWidth = getCssVariable('--logo-width');
        return {
            logoWidth,
        };
    }
};
export const themeAutomator = (colorsScheme, internal) => {
    const { colorBackground, colorOnBackground, colorPrimary, buttonBackground, buttonBackgroundHover, inputBackground, inputBorder, inputErrorText, } = colorsScheme;
    const colorBackgroundBrightness = tinycolor(colorBackground).getBrightness();
    const palePrimaryLight = tinycolor.mix(colorBackground, colorPrimary, 80).toHexString();
    const paleBackgroundAlpha = getColorAlpha(0.04, 0.1, colorBackgroundBrightness);
    const paleBackground = tinycolor
        .mix(colorBackground, colorOnBackground, paleBackgroundAlpha * 100)
        .toHexString();
    const pale = tinycolor.mix(colorBackground, colorOnBackground, 20).toHexString();
    const paleTextColorAlpha = getColorAlpha(0.6, 0.5, colorBackgroundBrightness);
    const paleTextColor = tinycolor
        .mix(colorBackground, colorOnBackground, paleTextColorAlpha * 100)
        .toHexString();
    const paleBorderAlpha = getColorAlpha(0.16, 0.2, colorBackgroundBrightness);
    const paleBorder = tinycolor
        .mix(colorBackground, colorOnBackground, paleBorderAlpha * 100)
        .toHexString();
    const isBackgroundDark = tinycolor(colorBackground).isDark();
    return {
        styles: {
            ...colorsScheme,
            pale,
            paleBackground,
            paleBackgroundAlpha,
            paleBorder,
            palePrimaryLight,
            paleTextColor,
            buttonBackground: buttonBackground || tinycolor.mix(colorBackground, colorOnBackground, 10).toHexString(),
            buttonBackgroundHover: buttonBackgroundHover || pale,
            inputBackground: inputBackground || paleBackground,
            inputBorder: inputBorder || 'transparent',
            inputError: defaultColors.inputError,
            inputErrorText: inputErrorText || defaultColors.white,
            ...(internal ? {} : getRadiuses()),
            ...(internal ? {} : getFonts()),
            ...(internal ? {} : getLogoWidth()),
        },
        config: {
            isBackgroundDark,
        },
    };
};
export const getAutomatedTheme = (base) => {
    const mode = base.content?.checkout?.mode;
    if (mode === 'LIGHT_CONTRAST' || mode === 'DARK_CONTRAST') {
        // NOTE: calculate theme without getting colors scheme
        const contrastTheme = {
            LIGHT_CONTRAST: automatedThemeDefaults.highContrastLight,
            DARK_CONTRAST: automatedThemeDefaults.highContrastDark,
        };
        return themeAutomator(contrastTheme[mode]);
    }
    return themeAutomator(getColorsScheme(base));
};
