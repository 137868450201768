import React from 'react';
import { DateString, Fieldset, Subsection } from '@fourthwall/components';
import { TaxFields } from '@modules/Dashboard/Product/modules/shipping/shared/TaxFields/TaxFields';
import { FeatureFlagDecorator } from '@components/utils/FeatureFlagDecorator';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field, getIn } from 'formik';
import { PackageSizeInputFormik } from '@modules/Dashboard/Product/modules/shipping/shared/PackageSizeSelect/PackageSizeInput';
import { useOfferQuery } from '@modules/Dashboard/Product';
import { useParams } from 'react-router-dom';
import { ShipmentWeightField } from '@modules/Dashboard/Product/modules/shipping/shared/ShippingWeight/ShipmentWeightField';
import { ShippingStartDate } from '@modules/Dashboard/Product/modules/shipping/shared/ShippingStartDate/ShippingStartDate';
import { getFulfillmentType } from '@modules/Dashboard/Product/store/product/utils';
export const ProductShippingBespoke = ({ initialValues }) => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { values, errors } = useFormikContext();
    const fulfillmentType = getFulfillmentType(values.fulfillmentService);
    const offerShipmentType = values.shipment.type;
    const { productId = '' } = useParams();
    const { offerQuery } = useOfferQuery([productId]);
    const { shippingConfig } = offerQuery?.data?.config || {};
    return (React.createElement(React.Fragment, null,
        React.createElement(Subsection, { title: "Shipping" },
            React.createElement("p", null, "All the orders are produced and shipped at once. During checkout, supporters pay the cost of shipping, based on the weight of their order."),
            offerShipmentType === 'DATE_RANGE' &&
                values.shipment.dates.from &&
                values.shipment.dates.to && (React.createElement("p", null,
                "Orders arrive between ",
                React.createElement(DateString, { date: values.shipment.dates.from, format: "MMM D" }),
                ' ',
                "at ",
                React.createElement(DateString, { date: values.shipment.dates.from, format: "hha" }),
                " and",
                ' ',
                React.createElement(DateString, { date: values.shipment.dates.to, format: "MMM D" }),
                " at",
                ' ',
                React.createElement(DateString, { date: values.shipment.dates.to, format: "hha" }),
                ".")),
            React.createElement(ShipmentWeightField, null),
            React.createElement(ShippingStartDate, { initialValues: initialValues })),
        React.createElement(FeatureFlagDecorator, { ...dashboardFeatureFlags.hsCodes, wrapper: Subsection },
            React.createElement(TaxFields, null),
            fulfillmentType === 'SHIP_MONK' && (React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { component: PackageSizeInputFormik, shippingConfig: shippingConfig, name: "shippingPackageSize", label: "Product size", error: getIn(errors, 'shippingPackageSize') })))))));
};
