import React from 'react';
import { useFilterPosts } from '../../hooks/useFilterPosts';
import { FILTER_TYPE_LABELS } from './consts';
import { PostsFilterView } from './PostsFilterView';
import { mapLabelsToCheckboxOptions } from './utils';
export const PostsFilterContainer = () => {
    const { filtersParams, setFiltersParams, resetFiltersParams, hasFilterParams } = useFilterPosts();
    const handleSubmit = (values) => {
        setFiltersParams({
            types: values.types.length ? values.types.join(',') : undefined,
        });
    };
    const getInitialValues = () => ({
        types: filtersParams.types ? filtersParams.types.split(',') : [],
    });
    return (React.createElement(PostsFilterView, { initialValues: getInitialValues(), types: mapLabelsToCheckboxOptions(FILTER_TYPE_LABELS), filterActive: hasFilterParams, onSubmit: handleSubmit, onReset: resetFiltersParams }));
};
