import { ApiBase } from './ApiBase';
class AnalyticsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getAnalyticsChart = (queryParams) => {
            return this.get('/shops/stats/chart', queryParams);
        };
        this.getMembershipCharts = (queryParams) => {
            return this.get(`/shops/stats/membership-chart`, queryParams);
        };
        this.getTopOfferTable = (queryParams) => {
            return this.get(`/shops/stats/table/top-offer`, queryParams);
        };
        this.getTopOfferFullTable = (queryParams) => {
            return this.get(`/shops/stats/table/top-offer/full`, queryParams);
        };
        this.getSaleByCountryTable = (queryParams) => {
            return this.get(`/shops/stats/table/top-sale-by-country`, queryParams);
        };
        this.getSaleByCountryFullTable = (queryParams) => {
            return this.get(`/shops/stats/table/top-sale-by-country/full`, queryParams);
        };
        this.getMembershipTopTiersTable = (queryParams) => {
            return this.get(`/shops/stats/table/membership-top-tiers`, queryParams);
        };
    }
}
export const AnalyticsApiClient = new AnalyticsApiClientModel();
