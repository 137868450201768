import { BackButton, Button, ButtonClean, Checkbox, Division, HeadContent, Heading, Loading, PriceString, ProductsList, Summary, useModalContext, } from '@fourthwall/components';
import isUndefined from 'lodash-es/isUndefined';
import { reverse } from 'named-urls';
import React from 'react';
import { Dashboard } from '../../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../../components/common/PageLayout';
import { SideSummary } from '../../../../../../components/common/SideSummary';
import { routing } from '../../../../../../utils/routing';
import { getVariantsString } from '../../../../../../utils/variants';
import { getNonCancellableReason } from '../utils';
import { OrderEditConfirmModal } from './components/OrderEditConfirmModal/OrderEditConfimModal';
import { OrderVariantsModal } from './components/OrderVariantsModal';
import { ProductItemSummary } from './components/ProductItemSummary/ProductItemSummary';
import * as S from './OrderEditView.styled';
import { aggregateOffers } from './utils';
export const OrderEditView = ({ orderId, friendlyId = '', editableOffers, nonEditableOffers, replacements, donation, subtotal, shipping, taxes, discount, total, paidTotal, shouldRestock, isEditButtonDisabled, isLoading, onVariantEdit, onOrderEdit, onRestockChange, }) => {
    const { open } = useModalContext();
    const currentOffersLength = editableOffers?.reduce((sum, offer) => sum + offer.current.quantity, 0);
    const aggregatedOffers = aggregateOffers(editableOffers?.map((editableOffer) => editableOffer.current));
    const summaryContent = (React.createElement(React.Fragment, null,
        !isUndefined(subtotal) && editableOffers && (React.createElement(Summary.Row, { label: `Subtotal (${currentOffersLength} items)`, value: subtotal })),
        !isUndefined(shipping) && React.createElement(Summary.Row, { label: "Shipping", value: shipping }),
        !isUndefined(taxes) && taxes?.value > 0 && React.createElement(Summary.Row, { label: "Sales tax", value: taxes }),
        !isUndefined(discount) && discount?.value > 0 && (React.createElement(Summary.Row, { label: "Discount", value: {
                // eslint-disable-next-line no-unsafe-optional-chaining
                value: discount?.value * -1,
                currency: discount.currency,
            } })),
        !isUndefined(total) && (React.createElement(React.Fragment, null,
            React.createElement(S.SummaryDivider, null),
            React.createElement(Summary.Row, { label: "Total", value: total }))),
        !isUndefined(paidTotal) && React.createElement(Summary.Row, { label: "Paid by customer", value: paidTotal }),
        !isUndefined(total) && !isUndefined(paidTotal) && paidTotal > total && (React.createElement(Summary.Row, { label: "Remaining amount", value: {
                value: paidTotal.value - total.value,
                currency: paidTotal.currency,
            }, bold: true })),
        !isUndefined(total) && !isUndefined(paidTotal) && total.value > paidTotal.value && (React.createElement(Summary.Row, { label: "Additional charge", value: {
                value: total.value - paidTotal.value,
                currency: paidTotal.currency,
            }, bold: true }))));
    const openOrderVariantsModal = (editableOffer) => {
        open(OrderVariantsModal, {
            offer: editableOffer.current,
            replacements,
            onConfirm: (offer) => {
                onVariantEdit({
                    ...editableOffer,
                    current: offer,
                });
            },
        });
    };
    const handleRevertVariantChange = (editableOffer) => {
        onVariantEdit({
            ...editableOffer,
            current: editableOffer.original,
        });
    };
    const isEditableOffer = (offer) => {
        return 'current' in offer;
    };
    const renderOffer = (orderOffer, withFooter) => {
        const currentOffer = isEditableOffer(orderOffer) ? orderOffer.current : orderOffer;
        const { variant, offer, quantity, price } = currentOffer;
        const shouldRenderSummary = isEditableOffer(orderOffer) && orderOffer.original.variantId !== orderOffer.current.variantId;
        return (React.createElement(Division, { size: "small", key: isEditableOffer(orderOffer) ? orderOffer.id : offer.id },
            React.createElement(ProductsList.Item, { name: offer.name, description: getVariantsString(variant), tag: !isEditableOffer(orderOffer) && 'nonCancellableReason' in orderOffer
                    ? getNonCancellableReason(orderOffer.nonCancellableReason)
                    : undefined, image: variant?.images[0]?.url, quantity: quantity, price: price, footer: withFooter && isEditableOffer(orderOffer) ? (React.createElement(S.ProductItemFooter, null,
                    shouldRenderSummary && (React.createElement(S.ProductItemFooterSummary, null,
                        React.createElement(ProductItemSummary, { label: `Changed variant from ${orderOffer.original.variant.attributesDescription} to
                      ${orderOffer.current.variant.attributesDescription}`, onRemoveModificationClick: () => handleRevertVariantChange(orderOffer) }))),
                    React.createElement(ButtonClean, { label: "Edit variant", appearance: "secondary", onClick: () => openOrderVariantsModal(orderOffer) }))) : undefined })));
    };
    const renderDonation = () => {
        if (!donation)
            return null;
        return (React.createElement(Division, { size: "small" },
            React.createElement(ProductsList.Item, { name: "Donation", type: "donation", quantity: 1, price: donation })));
    };
    const renderOffers = (offers, withFooter) => {
        return offers?.map((offer) => renderOffer(offer, withFooter));
    };
    const renderTotalDifferenceNotice = () => {
        if (!isUndefined(total) && !isUndefined(paidTotal) && paidTotal.value > total.value) {
            return (React.createElement(React.Fragment, null,
                "Remaining amount (",
                React.createElement(PriceString, { value: paidTotal.value - total.value, currency: paidTotal.currency }),
                ") will be added to your profits"));
        }
        if (!isUndefined(total) && !isUndefined(paidTotal) && total.value > paidTotal.value) {
            return (React.createElement(React.Fragment, null,
                "Charge amount (",
                React.createElement(PriceString, { value: total.value - paidTotal.value }),
                ") will be deducted from your profits"));
        }
        return null;
    };
    const handleEditConfirm = (reason) => {
        onOrderEdit(reason);
    };
    const handleEditOrderClick = () => {
        if (!aggregatedOffers?.length) {
            return;
        }
        open(OrderEditConfirmModal, {
            content: (React.createElement(React.Fragment, null,
                React.createElement(Division, { size: "small" }),
                React.createElement(Division, { size: "small" },
                    React.createElement(ProductsList, null, renderOffers(aggregatedOffers, false))),
                React.createElement(Division, { size: "small" },
                    React.createElement(Summary, null, summaryContent)))),
            footer: total !== paidTotal ? (React.createElement(React.Fragment, null,
                renderTotalDifferenceNotice(),
                ". Supporter will be notified about the changes via email.")) : null,
            onConfirm: handleEditConfirm,
        });
    };
    const leftContent = (React.createElement(React.Fragment, null,
        editableOffers && editableOffers.length > 0 && (React.createElement(S.Section, null,
            React.createElement(Division, { size: "small" },
                React.createElement(ProductsList, null, renderOffers(editableOffers, true))),
            React.createElement(Division, { size: "small" }))),
        (donation || (nonEditableOffers && nonEditableOffers.length > 0)) && (React.createElement(S.Section, null,
            React.createElement(S.SectionHeader, null,
                React.createElement(Heading, { type: "h7", weight: "semibold" }, "Items that cannot be edited")),
            React.createElement(Division, { size: "small" },
                React.createElement(ProductsList, null,
                    nonEditableOffers && nonEditableOffers.length > 0 && (React.createElement(React.Fragment, null, renderOffers(nonEditableOffers, false))),
                    renderDonation())),
            React.createElement(Division, { size: "small" })))));
    const summary = (React.createElement(SideSummary, null,
        React.createElement(S.SummaryHeader, null, "Summary"),
        React.createElement(Summary, { small: true }, summaryContent),
        !isUndefined(total) && !isUndefined(paidTotal) && total !== paidTotal && (React.createElement(S.SummarySubsection, null, renderTotalDifferenceNotice())),
        React.createElement(S.SummaryButton, null,
            React.createElement(Button, { label: "Update order", appearance: "primary", fullWidth: true, disabled: isEditButtonDisabled, onClick: handleEditOrderClick })),
        React.createElement(S.SummaryCheckbox, null,
            React.createElement(Checkbox, { value: shouldRestock, label: "Restock items", onValueChange: onRestockChange }))));
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: `Edit order (#${friendlyId})` }),
        React.createElement(Loading, { isLoading: isLoading },
            React.createElement(PageLayout, { leftContent: leftContent, rightContent: summary },
                React.createElement(Dashboard.ItemViewHeader, { name: "Edit order", backButton: React.createElement(BackButton, { label: `Order #${friendlyId}`, to: { pathname: reverse(routing.contributions.orders.order.self, { orderId }) } }) })))));
};
