import styled from 'styled-components';
export const Section = styled.section `
  margin-top: 16px;
  max-width: 445px;
  display: flex;
`;
export const UploadWrapper = styled.section `
  max-width: 132px;
`;
export const IncentiveWrapper = styled.section `
  display: flex;
  margin-left: 24px;
`;
