import * as yup from 'yup';
import { getTierIds } from '../../../utils/getTierIds';
import { FREE_TIER_ID } from '../consts';
import { FormFields } from './types';
export const validationSchema = yup.object().shape({
    [FormFields.type]: yup.string().required(),
    [FormFields.title]: yup.string().required().max(200),
    [FormFields.description]: yup
        .string()
        .when(FormFields.type, (type) => (type === 'Rss' ? yup.string() : yup.string().max(240))),
    [FormFields.image]: yup.string(),
    [FormFields.rssType]: yup
        .string()
        .when(FormFields.type, (type) => (type === 'Rss' ? yup.string().required() : yup.string())),
    [FormFields.rssTags]: yup.array().of(yup.object()),
    [FormFields.hidden]: yup.boolean().required(),
    [FormFields.selectedTiers]: yup
        .object()
        .test('isEmpty', 'At least one selected tier is required.', (value) => Object.values(value).filter(Boolean).length > 0)
        .test('isEmpty', 'At least one selected paid tier is required.', function (selectedTiers) {
        const { type } = this.parent;
        const tierIds = getTierIds(selectedTiers);
        if (type != 'Custom' && tierIds.length === 1 && tierIds[0].id === FREE_TIER_ID) {
            return false;
        }
        return true;
    }),
    [FormFields.removeImage]: yup.boolean().required(),
    [FormFields.selectedRoles]: yup
        .object()
        .test('selectedRoles', 'Each selected tier must have at least one role.', function (selectedRoles) {
        const { type, selectedTiers } = this.parent;
        if (type === 'Discord') {
            const selectedTiersIds = Object.keys(selectedTiers).filter((key) => selectedTiers[key] && parseInt(key) !== FREE_TIER_ID);
            const rolesForSelectedTiers = selectedTiersIds.map((id) => selectedRoles[id]);
            return !Object.values(rolesForSelectedTiers).some((rolesArray) => rolesArray.length <= 0);
        }
        return true;
    }),
});
