import { useState } from 'react';
import { useDebounce, usePagination } from '@fourthwall/components';
import { useFilterMembers } from '../../hooks/useFilterMembers';
export const useMembersSearch = () => {
    const [, setCurrentPage] = usePagination();
    const { searchQueryParam, setSearchQueryParam } = useFilterMembers();
    const [value, setValue] = useState(searchQueryParam || '');
    const handleSearch = useDebounce((value) => {
        if (value == searchQueryParam) {
            return null;
        }
        if (value.length === 0) {
            handleInputClear();
        }
        if (value.length >= 3) {
            setCurrentPage(1);
            setSearchQueryParam(value);
        }
    }, 500);
    const handleInputChange = (value) => {
        setValue(value);
        handleSearch(value);
    };
    const handleInputClear = () => {
        setValue('');
        setSearchQueryParam(undefined);
    };
    return {
        value,
        onInputChange: handleInputChange,
        onInputClear: handleInputClear,
    };
};
