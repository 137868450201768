import React from 'react';
const getSrc = (videoId, overrides) => {
    const parameters = {
        autoplay: false,
        rel: false,
        ...overrides,
    };
    const srcUrl = new URL(`https://www.youtube.com/embed/${videoId}`);
    srcUrl.searchParams.set('autoplay', parameters.autoplay ? '1' : '0');
    srcUrl.searchParams.set('rel', parameters.rel ? '1' : '0');
    return srcUrl.toString();
};
export const YouTubeEmbed = ({ videoId, parameters }) => (React.createElement("iframe", { 
    // width="560"
    // height="315"
    src: getSrc(videoId, parameters), title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", referrerPolicy: "strict-origin-when-cross-origin", allowFullScreen: true, style: { display: 'block', width: '100%', aspectRatio: '560 / 315', borderRadius: 2 } }));
