import styled from 'styled-components';
export const CurrencyList = styled.ul `
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  gap: 12px;
`;
export const CurrencyListItem = styled.li ``;
export const CurrencyFlagContainer = styled.div `
  display: flex;
  align-items: center;
`;
export const CurrencyLabel = styled.div `
  margin-left: 8px;
`;
export const TooltipContainer = styled.div `
  margin-left: 8px;
  cursor: pointer;
`;
