import { Toasts, useModalContext } from '@fourthwall/components';
import { getDefaultErrorMessage } from '@fourthwall/utils/lib/queryClient';
import axios from 'axios';
import { reverse } from 'named-urls';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from '@utils/routing';
import { useOrderRefundQuery } from '../hooks/useOrderRefundQuery';
import { useRefundOrderMutation } from './hooks/useRefundOrderMutation';
import { OrderRefundConfirmModal } from './modules/OrderRefundConfirmModal';
import { OrderRefundView } from './OrderRefundView';
import { useOrderIdParam } from '../hooks';
import { useOrderQuery } from '../queries';
export const OrderRefundContainer = () => {
    const { orderId } = useOrderIdParam();
    const navigate = useNavigate();
    const { open } = useModalContext();
    const { orderQuery } = useOrderQuery([orderId]);
    const { orderRefundQuery, invalidateOrderRefundQuery } = useOrderRefundQuery([orderId]);
    const { refundOrderMutation } = useRefundOrderMutation({ onError: undefined });
    const refundOrder = (amount, expectedCostSplit, reason) => {
        refundOrderMutation.mutate([
            orderId,
            {
                amount: { value: parseFloat(amount), currency: 'USD' },
                reason,
                expectedCostSplit,
            },
        ], {
            onSuccess: () => {
                invalidateOrderRefundQuery();
                navigate(reverse(routing.contributions.orders.order.self, { orderId }));
                Toasts.notify('Order has been refunded successfully.', { type: 'info' });
            },
            onError: (error) => {
                if (axios.isAxiosError(error) &&
                    error.response?.data.code === 'ORDER_REFUND_EXPECTED_CHARGE_AMOUNT_CHANGED') {
                    open(OrderRefundConfirmModal, {
                        orderId,
                        initialReason: reason,
                        error: 'Your balance has changed and cannot cover refund amount. Please try again.',
                        amount,
                        onConfirm: (expectedCostSplit, reason) => {
                            refundOrder(amount, expectedCostSplit, reason);
                        },
                    });
                    return;
                }
                Toasts.notify(getDefaultErrorMessage(error), { type: 'error' });
            },
        });
    };
    const handleOrderRefund = (amount) => {
        open(OrderRefundConfirmModal, {
            orderId,
            amount,
            onConfirm: (expectedCostSplit, reason) => {
                refundOrder(amount, expectedCostSplit, reason);
            },
        });
    };
    return (React.createElement(OrderRefundView, { orderId: orderId, friendlyId: orderQuery.data?.friendlyId, order: orderQuery.data, donation: orderQuery.data?.donation || undefined, isLoading: orderQuery.isLoading || orderRefundQuery.isLoading || refundOrderMutation.isLoading, remainingRefundAmount: orderRefundQuery.data?.remainingRefundAmount.value, onOrderRefund: handleOrderRefund }));
};
