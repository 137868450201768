import React from 'react';
import { ButtonClean, DateString, PriceString, Tag } from '@fourthwall/components';
import { PaymentMethod } from '../components/PaymentMethod';
import { tagsAppearances } from '../consts';
export const getHeaders = (state) => [
    {
        id: 'date',
        label: 'Date',
        render: ({ date }) => React.createElement(DateString, { date: date, format: "MMM DD, YYYY" }),
        width: 1.4,
    },
    {
        id: 'type',
        label: 'Type',
        render: ({ type }) => {
            if (type.url) {
                return React.createElement(ButtonClean, { to: type.url, state: state, label: type.label });
            }
            return type.label;
        },
        width: 1.3,
    },
    {
        id: 'amount',
        label: 'Amount',
        render: ({ amount }) => React.createElement(PriceString, { value: amount.value, currency: amount.currency }),
        width: 1.1,
    },
    {
        id: 'method',
        label: 'Method',
        render: ({ method }) => React.createElement(PaymentMethod, { method: method }),
        width: 1,
    },
    {
        id: 'status',
        label: 'Status',
        render: ({ status }) => React.createElement(Tag, { label: status, appearance: tagsAppearances[status] }),
        width: 1.3,
    },
];
