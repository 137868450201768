import { useActiveTab } from '@components/hooks/useActiveTab';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useModalContext } from '@fourthwall/components';
import Yup from '@fourthwall/utils/lib/yup';
import React, { useMemo } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';
import { useCurrentShopConfigQuery } from '../../../hooks/useCurrentShopConfigQuery';
import { AddPaymentMethodModal, getChargeLimitValidationSchema, ShopPaymentMethods, useSetCurrentShopConfigChargeLimitMutation, useShopPaymentMethods, } from '../../../PaymentMethods';
import { TAB_TITLES } from './consts';
import { useBillingSummary } from './hooks/useBillingSummary';
import { useChargesHistory } from './hooks/useChargesHistory';
import { usePayoutsHistory } from './hooks/usePayoutsHistory';
import { usePayoutsSetup } from './hooks/usePayoutsSetup';
import { PayoutsView } from './PayoutsView';
import { getSetCurrentShopConfigChargeLimitPayload } from './utils';
export const PayoutsContainer = () => {
    const [, setQueryParams] = useQueryParams({
        page: NumberParam,
    });
    const { hasPermission } = useDashboardPermissions();
    const TABS = [
        {
            label: TAB_TITLES.payouts,
            value: 'payouts',
            visible: hasPermission('settings.billing.history.payouts'),
        },
        {
            label: TAB_TITLES.charges,
            value: 'charges',
            visible: hasPermission('settings.billing.history.charges'),
        },
    ].filter(({ visible }) => visible);
    const { activeTab, setTabParam } = useActiveTab({
        tabValues: TABS.map((tab) => tab.value),
    });
    const { open } = useModalContext();
    const { shopPaymentMethods, invalidateShopPaymentMethods } = useShopPaymentMethods();
    const { setCurrentShopConfigChargeLimitMutation } = useSetCurrentShopConfigChargeLimitMutation();
    const { currentShopConfigQuery } = useCurrentShopConfigQuery();
    const { accountBalance } = useBillingSummary();
    const chargesHistory = useChargesHistory({ enabled: activeTab === 'charges' });
    const payoutsHistory = usePayoutsHistory({
        enabled: activeTab === 'payouts',
    });
    const payoutsSetup = usePayoutsSetup();
    const initialValues = useMemo(() => {
        return {
            setChargeLimit: currentShopConfigQuery.data?.chargePolicy?.type === 'LIMITED',
            chargeLimit: currentShopConfigQuery.data?.chargePolicy?.type === 'LIMITED'
                ? currentShopConfigQuery.data.chargePolicy.amount.value.toString()
                : '',
        };
    }, [currentShopConfigQuery.data?.chargePolicy]);
    const validationSchema = Yup.object().shape({
        ...getChargeLimitValidationSchema(),
    });
    const handleAddPaymentMethod = () => {
        open(AddPaymentMethodModal, {
            onSuccess: () => {
                invalidateShopPaymentMethods();
            },
        });
    };
    const handleSubmit = (values, formikHelpers) => {
        formikHelpers.resetForm({ values });
        setCurrentShopConfigChargeLimitMutation.mutate([getSetCurrentShopConfigChargeLimitPayload(values)], {
            onSuccess: () => {
                currentShopConfigQuery.refetch();
            },
        });
    };
    const paymentMethods = {
        list: (React.createElement(ShopPaymentMethods, null)),
        buttonType: (shopPaymentMethods?.length ? 'add' : 'primary'),
        chargedCurrentMonth: currentShopConfigQuery.data?.chargePolicy?.chargedCurrentMonth,
        onAdd: handleAddPaymentMethod,
    };
    const tabsProps = {
        tabs: TABS,
        activeTab,
        onTabClick: (tab) => {
            setTabParam(tab);
            setQueryParams({ page: undefined });
        },
    };
    return (React.createElement(PayoutsView, { initialValues: initialValues, validationSchema: validationSchema, accountBalance: accountBalance, paymentMethods: paymentMethods, tabsProps: tabsProps, payoutsSetup: payoutsSetup, payoutsHistory: payoutsHistory, chargesHistory: chargesHistory, isSubmitting: setCurrentShopConfigChargeLimitMutation.isLoading, onSubmit: handleSubmit }));
};
