import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: space-between;

  ${mediaQueries.mobileM} {
    gap: 8px;
  }

  ${mediaQueries.laptop} {
    justify-content: flex-start;
  }
`;
