import styled from 'styled-components';
export const Container = styled.div `
  padding: 0 12px;
`;
export const ItemWrapper = styled.div `
  width: 240px;
  display: flex;
  align-items: center;
`;
export const ItemIcon = styled.div `
  flex: 0;
  width: 24px;
  margin-right: 12px;
`;
export const ItemLabel = styled.div `
  flex: 1;
`;
export const DropdownButton = styled.div `
  padding: 12px 0;
`;
export const LoaderContainer = styled.div `
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;
