import { Fieldset, Input, Select, Textarea } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React from 'react';
import { FormFields } from '../../types';
export const CommonFields = () => {
    const { values } = useFormikContext();
    const selectOptions = [
        { value: 'Custom', label: 'Custom perk' },
        { value: 'Discord', label: 'Discord roles' },
        { value: 'Discount', label: 'Members-only discounts' },
        { value: 'Product', label: 'Members-only products' },
        { value: 'Rss', label: 'Private RSS feed' },
    ];
    const renderDescriptionInput = () => {
        if (['Custom', 'Rss', 'Discord'].indexOf(values.type) >= 0) {
            return null;
        }
        return (React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { component: Textarea, name: FormFields.description, label: "Description (optional)", placeholder: "Description (optional)" }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { name: FormFields.type, component: Select, label: "Perk type", options: selectOptions, disabled: values.mode === 'edit' }))),
        React.createElement(Fieldset.Row, null,
            React.createElement(Fieldset.Item, null,
                React.createElement(Field, { component: Input, name: FormFields.title, label: "Perk title" }))),
        renderDescriptionInput()));
};
