import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 20px;
  border: 1px solid ${Colors.GS400};
  position: relative;
`;
export const Label = styled.div `
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 21px;
  color: ${Colors.GS600};
`;
export const Actions = styled.div `
  position: absolute;
  display: flex;
  top: 4px;
  right: 4px;
  gap: 2px;
  align-items: center;
`;
