import { ApiBase } from '../ApiBase';
class DnsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getDns = () => {
            return this.get('/dns');
        };
        this.sendEntriesViaEmail = (body) => {
            return this.put('/dns/action/send-entries-via-email', body);
        };
        this.createDomain = (body) => {
            return this.post('/dns', body);
        };
        this.deleteDomain = () => {
            return this.delete('/dns');
        };
        this.verifyDomain = () => {
            return this.put('/dns/action/validate');
        };
        this.updateEntriProviderDetails = (params, body) => {
            return this.put(`/dns/${params.domainId}/entri-provider-details`, body);
        };
        this.getEntriAuthToken = () => {
            return this.get('/dns/entri-auth-token');
        };
    }
}
export const DnsApiClient = new DnsApiClientModel();
