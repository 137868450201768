import React from 'react';
import styled from 'styled-components';
import { MOBILE_APP_IOS_LINK, MOBILE_APP_ANDROID_LINK } from '@fourthwall/utils/lib/links';
import appStoreBadge from './app-store-badge.svg';
import googlePlayBadge from './google-play-badge.svg';
const Container = styled.div `
  display: flex;
  gap: 16px;

  a {
    display: block;
  }

  img {
    display: block;
    height: 40px;
    width: auto;
  }
`;
export const DownloadCreatorApp = ({ analytics }) => (React.createElement(Container, { "data-analytics": analytics },
    React.createElement("div", null,
        React.createElement("a", { href: MOBILE_APP_IOS_LINK, target: "_blank", rel: "noreferrer" },
            React.createElement("img", { src: appStoreBadge, alt: "App Store Badge" }))),
    React.createElement("div", null,
        React.createElement("a", { href: MOBILE_APP_ANDROID_LINK, target: "_blank", rel: "noreferrer" },
            React.createElement("img", { src: googlePlayBadge, alt: "Google Play Badge" })))));
