import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import Modal from 'react-modal';
import * as S from './ModalProvider.styled';
export const ModalContext = createContext({
    open: () => { },
    close: () => { },
});
export const ModalProvider = ({ children, onModalOpen, onModalClose }) => {
    const [modalState, setModalState] = useState();
    const openModal = useCallback((component, props) => {
        setModalState({ component, props });
        if (onModalOpen) {
            onModalOpen();
        }
    }, [setModalState, onModalOpen]);
    const closeModal = useCallback(() => {
        setModalState({ component: null, props: {} });
        if (onModalClose) {
            onModalClose();
        }
    }, [setModalState, onModalClose]);
    const value = useMemo(() => ({
        open: openModal,
        close: closeModal,
    }), [openModal, closeModal]);
    const renderModal = useCallback(({ close }) => {
        const Component = modalState?.component;
        const props = modalState?.props || {};
        if (!Component) {
            return null;
        }
        /**
         * Allows custom control over the `onRequestClose` prop.
         * By default, the modal closes when the Esc key is pressed.
         * To override this behavior, supply a custom `onRequestClose` function as a prop.
         *
         * Example usage:
         * open(ProductModal, {
         *   initialValues: {}, // Initial values for the modal form (customize as needed)
         *   onRequestClose: () => {
         *     close(); // Closes the modal programmatically
         *     console.log('Modal has been closed'); // Custom logic to run when the modal is closed with the Esc key
         *   }
         * });
         */
        return (React.createElement(Modal, { isOpen: !!modalState?.component, overlayClassName: "modal__overlay", htmlOpenClassName: "ReactModal__Html--open", className: "modal", aria: {
                labelledby: 'modal-box',
                describedby: 'modal-box',
            }, onRequestClose: props?.onRequestClose || close },
            React.createElement(Component, { close: close, ...props })));
    }, [modalState]);
    return (React.createElement(ModalContext.Provider, { value: value },
        React.createElement(S.Global, null),
        children,
        React.createElement(ModalContext.Consumer, null, renderModal)));
};
export const useModalContext = () => {
    return useContext(ModalContext);
};
