import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
export const Tooltip = styled.div(({ withPadding, fluid }) => css `
    position: relative;
    z-index: 1;

    width: auto;
    max-width: 250px;
    border: 1px solid ${Colors.GS700};
    background-color: ${Colors.GS1000};

    color: ${Colors.GS100};
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-transform: none;
    white-space: normal;
    text-align: left;

    ${withPadding &&
    css `
      padding: 5px 8px;
    `}

    ${fluid &&
    css `
      width: auto;
    `}
  `);
