import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    status: Yup.string().required(),
    startDate: Yup.date().when(['status', 'endDate'], (status, endDate) => {
        return status === 'SCHEDULE' && !endDate ? Yup.date().required() : Yup.date();
    }),
    setEndDate: Yup.boolean(),
    endDate: Yup.date().when(['status', 'setEndDate'], (status, setEndDate) => {
        if (status === 'SCHEDULE' && setEndDate) {
            return Yup.date().required();
        }
        return Yup.date();
    }),
});
