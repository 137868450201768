import isEqual from 'lodash-es/isEqual';
export const getWelcomePostsFormData = (welcomePostsQuery, tiers) => {
    if (welcomePostsQuery.data?.type === 'Different') {
        const posts = welcomePostsQuery.data.posts;
        return tiers.reduce((welcomePosts, tier) => {
            const welcomePost = posts.find((post) => isEqual(post.access.tiers?.[0], { id: tier.id }));
            if (welcomePost) {
                welcomePosts[tier.id] = {
                    postType: welcomePost.type,
                    title: welcomePost.title,
                    description: welcomePost.description,
                    image: 'image' in welcomePost && welcomePost.image && 'url' in welcomePost.image
                        ? welcomePost.image.url
                        : undefined,
                    video: 'mediaUrl' in welcomePost ? welcomePost.mediaUrl : undefined,
                    videoThumbnail: welcomePost.type == 'Video' &&
                        'image' in welcomePost &&
                        welcomePost.image &&
                        'url' in welcomePost.image
                        ? welcomePost.image.url
                        : undefined,
                    videoType: 'upload',
                    imageType: 'image' in welcomePost && welcomePost.image ? welcomePost.image.type : undefined,
                    status: welcomePost.statuses.resources,
                };
            }
            else {
                welcomePosts[tier.id] = {
                    postType: 'Text',
                    title: '',
                    videoType: 'upload',
                };
            }
            return welcomePosts;
        }, {});
    }
    return tiers.reduce((tiers, tier) => {
        tiers[tier.id] = {
            postType: 'Text',
            title: '',
            videoType: 'upload',
        };
        return tiers;
    }, {});
};
