import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { HELPER_CLASS } from '../SortableList/consts';
import { mediaQueries } from '../../providers/MediaQueryProvider';
export const Container = styled.div `
  margin: -16px;
`;
export const ItemContainer = styled.div `
  position: relative;
`;
export const Item = styled.div `
  position: relative;
`;
export const Placeholder = styled.div `
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.GS300};
`;
export const ToolbarContainer = styled.div `
  display: flex;
  position: absolute;
  top: 29px;
  right: 16px;

  ${mediaQueries.tablet} {
    display: none;
    top: 37px;
  }
`;
export const Element = styled.div `
  position: relative;
  background-color: ${Colors.GS0};
  margin-bottom: 16px;
  padding: 8px 16px;

  ${({ isDragged }) => !isDragged &&
    css `
      &:hover ${ToolbarContainer} {
        display: flex;
      }
    `}

  &.${HELPER_CLASS} {
    pointer-events: auto !important;
    z-index: 999;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.1);
  }

  &.${HELPER_CLASS} ${ToolbarContainer} {
    display: flex;
  }

  ${mediaQueries.tablet} {
    padding: 16px;
  }
`;
export const Handle = styled.div `
  margin-left: 16px;

  color: ${Colors.GS600};
  cursor: pointer;

  &:hover {
    color: ${Colors.GS1000};
  }
`;
export const DragHandle = styled(Handle) `
  cursor: move;
  cursor: grab;

  .${HELPER_CLASS} & {
    cursor: grabbing;
  }
`;
