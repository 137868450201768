import { pluralize } from '@fourthwall/utils/lib/string';
export const getSyncedProductsHeading = (status, productsLength) => {
    const headingLabelMap = {
        active: 'synced to TikTok',
        pending: 'available to sync to TikTok',
        denied: 'not eligible for TikTok Shop',
    };
    return `${productsLength} ${pluralize(productsLength, 'product')} ${headingLabelMap[status]}`;
};
export const getConfigurationPrerequisitesErrors = (prerequisites) => {
    const prerequisitesKeys = Object.keys(prerequisites ?? {});
    return prerequisitesKeys.reduce((prev, key) => {
        const prerequisite = prerequisites?.[key];
        if (prerequisite?.type === 'NOT_COMPLETED' && prerequisite.message) {
            return [...prev, prerequisite.message];
        }
        return prev;
    }, []);
};
