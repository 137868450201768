import { getImageDirectUploadData } from '../../../../utils/getImageDirectUploadData';
import { getCommonFieldsPayload } from './getCommonFieldsPayload';
export const getVideoEmbedPostPayload = async (values) => {
    const payload = getCommonFieldsPayload(values);
    return {
        ...payload,
        attributes: {
            core: payload.attributes.core,
            series: values.videoSeries,
        },
        embed: {
            url: values.videoUrl,
        },
        imageDirectUploadData: typeof values.videoThumbnail === 'object'
            ? await getImageDirectUploadData(values.videoThumbnail)
            : undefined,
        remove_thumbnail: values.videoThumbnail === undefined,
    };
};
