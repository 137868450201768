import { getPrice } from '@fourthwall/utils/lib/common';
import { pluralize } from '@fourthwall/utils/lib/string';
export const isOfferItemType = (item) => item.type === 'OFFER';
export const isSingleVariantOfferItemType = (item) => item.type === 'SINGLE_VARIANT_OFFER';
export const getItemInitialValues = (item, selectedItems) => {
    let checked = false;
    let quantity = '1';
    if (!isOfferItemType(item)) {
        const selectedItem = selectedItems.find((selectedItem) => selectedItem.variantId === item.variantId);
        if (selectedItem) {
            checked = true;
            quantity = selectedItem.quantity;
        }
    }
    return {
        checked,
        quantity,
    };
};
export const getSelectModalMessage = (selectedItems) => {
    const totalQuantity = selectedItems.reduce((total, { quantity }) => total + Number(quantity), 0);
    const totalPrice = selectedItems.reduce((total, { price, quantity }) => {
        if (!price) {
            return total;
        }
        return total + price.value * parseInt(quantity);
    }, 0);
    const itemsText = pluralize(totalQuantity, 'item');
    const totalPriceText = !totalQuantity ? '' : ` (${getPrice(totalPrice)} total)`;
    return `${totalQuantity} ${itemsText} selected${totalPriceText}`;
};
export const removeItemFromSelectedItems = (variantId, selectedItems) => selectedItems.filter((selectedItem) => selectedItem.variantId !== variantId);
export const modifySelectedItem = (item, selectedItems) => selectedItems.map((selectedItem) => {
    if (selectedItem.variantId === item.variantId) {
        return { ...item };
    }
    return selectedItem;
});
