import React from 'react';
import { HeadContent, LeavingGuard, SaveShortcutListener } from '@fourthwall/components';
import { Dashboard, PageLayout } from '@components/common';
import { DashboardBackButton } from '@components/utils';
import { routing } from '@utils/routing';
import { SideSummary } from '@components/common/SideSummary';
import { Formik } from 'formik';
import { getCountryNameByCode, getStateNameByCode } from '@fourthwall/utils/lib/inputs';
import { ConfirmBarContainer } from '@modules/Dashboard/ConfirmBar';
import { Products } from './modules/Products';
import { Countries } from './modules/Countries';
import { validationSchema } from './consts';
export const ShippingGroupView = ({ initialValues, isSelfFulfilled, shippingLocation, onSubmit, }) => {
    const title = `Custom shipping group for products ${isSelfFulfilled ? 'you ship' : 'we ship for you'}`;
    const renderState = () => {
        if (shippingLocation?.state && shippingLocation?.country) {
            return getStateNameByCode(shippingLocation?.country, shippingLocation.state);
        }
        return shippingLocation?.state;
    };
    const renderCountry = () => {
        if (shippingLocation?.country) {
            return getCountryNameByCode(shippingLocation?.country);
        }
        return shippingLocation?.country;
    };
    const renderShippingLocation = () => {
        if (isSelfFulfilled) {
            return shippingLocation ? (React.createElement(React.Fragment, null,
                shippingLocation.address1,
                " ",
                React.createElement("br", null),
                shippingLocation.address2 && (React.createElement(React.Fragment, null,
                    shippingLocation.address2,
                    React.createElement("br", null))),
                renderState(),
                " ",
                shippingLocation.zip,
                " ",
                shippingLocation.city,
                " ",
                React.createElement("br", null),
                renderCountry())) : ('-');
        }
        return 'Fulfilled by Fourthwall';
    };
    const getBackToShippingLink = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('selfFulfilled');
        return {
            pathname: routing.settings.shipping,
            search: searchParams.toString(),
        };
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: title }),
        React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: onSubmit }, ({ dirty, isSubmitting, submitForm, resetForm }) => (React.createElement(React.Fragment, null,
            React.createElement(PageLayout, { size: "medium", leftContent: React.createElement(React.Fragment, null,
                    React.createElement(Products, { isSelfFulfilled: isSelfFulfilled }),
                    React.createElement(Countries, null)), rightContent: shippingLocation && (React.createElement(SideSummary, null,
                    React.createElement(SideSummary.Item, { title: "Shipping from" }, renderShippingLocation()))) },
                React.createElement(Dashboard.ItemViewHeader, { name: title, backButton: React.createElement(DashboardBackButton, { label: "Shipping", to: getBackToShippingLink() }) })),
            React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
            React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isSubmitting, onConfirm: submitForm, onCancel: resetForm }),
            React.createElement(LeavingGuard, { when: dirty }))))));
};
