import React from 'react';
import { useModalContext } from '@fourthwall/components';
import { VideoEntry } from './VideoEntry';
import { VideoModal } from './VideoModal';
import { YouTubeEmbed } from './YouTubeEmbed';
export const YouTubeVideoEntry = ({ title, description, size, videoId, }) => {
    const { open } = useModalContext();
    return (React.createElement(VideoEntry, { title: title, description: description, thumbnail: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`, size: size, onClick: () => open(VideoModal, {
            title,
            description,
            video: React.createElement(YouTubeEmbed, { videoId: videoId, parameters: { autoplay: true, rel: false } }),
        }) }));
};
