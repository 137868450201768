import { hasMembershipsWlaEnabled } from './hasMembershipsWlaEnabled';
const SHOPS_IDS = [
    // Down Bad Show
    'sh_2ac44fb0-656a-4853-bcc2-a654f2d5cafd',
];
export const hasMembershipsMessagesEnabled = (shopid) => {
    if (SHOPS_IDS.includes(shopid))
        return true;
    return !hasMembershipsWlaEnabled(shopid);
};
