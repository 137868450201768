import validateBarcode from 'barcode-validator';
import Yup from '@fourthwall/utils/lib/yup';
export const PRODUCT_SETTINGS_VIEW_TITLE = 'Advanced settings';
export const validationSchema = Yup.object().shape({
    isSoundScanProduct: Yup.boolean(),
    format: Yup.string(),
    soundScanCode: Yup.string().when('isSoundScanProduct', {
        is: true,
        then: Yup.string()
            .required()
            .test('validateBarcode', 'This field is not valid EAN/UPC code.', validateBarcode),
    }),
});
