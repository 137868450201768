import React from 'react';
import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { Button, ButtonGroup, ToolbarButton, useMediaQueryContext } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import { isWebView } from '@fourthwall/utils';
import { Dashboard } from '@components/common/Dashboard';
import { NavTabs } from '@components/common/NavTabs';
import { PageLayout } from '@components/common/PageLayout';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions/useDashboardPermissions';
import { routing } from '@utils/routing';
import { PostsFilterContainer } from './modules/PostsFilter';
import { PostsSearch } from './modules/PostsSearch';
import { PublishedPosts } from './modules/PublishedPosts';
import { ScheduledPosts } from './modules/ScheduledPosts';
export const Posts = () => {
    const media = useMediaQueryContext();
    const { pathname } = useLocation();
    const { hasPermission } = useDashboardPermissions();
    const getNavbarTabs = () => {
        return [
            {
                label: 'Published',
                to: routing.memberships.posts.self,
                element: React.createElement(PublishedPosts, null),
            },
            {
                label: 'Scheduled',
                to: routing.memberships.posts.scheduled.all,
                element: React.createElement(ScheduledPosts, null),
            },
        ];
    };
    const getToolbarContent = () => {
        if (!media.tablet) {
            return (React.createElement(ButtonGroup, { space: "medium" },
                hasPermission('memberships.post.create') && (React.createElement(ToolbarButton, { label: "Create new post", icon: PlusIcon, to: routing.memberships.posts.create })),
                React.createElement(PostsFilterContainer, null)));
        }
        return (React.createElement(ButtonGroup, { space: "medium" },
            React.createElement(PostsFilterContainer, null),
            React.createElement(PostsSearch, null),
            hasPermission('memberships.post.create') && (React.createElement(Button, { label: "Create new post", to: routing.memberships.posts.create, appearance: "primary", size: "small" }))));
    };
    const renderBottomContent = () => {
        if (media.tablet) {
            return null;
        }
        return React.createElement(PostsSearch, null);
    };
    // This is a temporary solution that should be fixed once whole dashboard router is migrated to react-router 6.
    const getElement = (pathname) => {
        const tabs = getNavbarTabs();
        return (tabs[tabs.findIndex(({ to }) => pathname.replace(/\/$/, '') === to.replace(/\/$/, ''))]
            ?.element || React.Fragment);
    };
    return (React.createElement(PageLayout, { size: "large" },
        React.createElement(Dashboard.Section, { name: isWebView() ? undefined : 'Posts', bottomContent: renderBottomContent(), toolbarContent: getToolbarContent(), bottomMargin: "medium" },
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(NavTabs, { tabs: getNavbarTabs(), tabComponent: NavLink })),
            React.createElement(Routes, null,
                React.createElement(Route, { index: true, element: getElement(pathname) })))));
};
