import { Checkbox, Fieldset, RadioButton } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React, { useMemo } from 'react';
import { TagSelect } from '../../../../../components/TagSelect';
import { useTagsQuery } from '../../../../../hooks/useTagsQuery';
import { FormFields, RssType } from '../../types';
export const RssTypeFields = () => {
    const { tagsQuery } = useTagsQuery([{ limit: 9999 }]);
    const { values, setFieldValue } = useFormikContext();
    const handleTagAdd = (tagName) => {
        const tagObject = tagsQuery.data?.tags?.find((tag) => {
            return tag.name === tagName;
        });
        if (tagObject) {
            setFieldValue(FormFields.rssTags, [...values.rssTags, tagObject]);
        }
    };
    const handleTagRemove = (tagName) => {
        setFieldValue(FormFields.rssTags, values.rssTags.filter((tag) => tag.name !== tagName));
    };
    const tags = useMemo(() => {
        return tagsQuery.data?.tags?.map((tag) => tag.name) || [];
    }, [tagsQuery.data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { component: RadioButton, name: FormFields.rssType, checkedValue: RssType.all, label: "Include all audio posts" })),
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { component: RadioButton, name: FormFields.rssType, checkedValue: RssType.selected, label: "Include audio posts with selected tags" })),
        values.rssType === 'selected' && (React.createElement(Fieldset.Row, { inner: true },
            React.createElement(Fieldset.Item, null,
                React.createElement(TagSelect, { selectedTags: values.rssTags.map((tag) => tag.name), tags: tags, label: tags.length === 0 ? 'You haven’t created any tags yet' : 'Select tags', disabled: tags.length === 0, onAdd: handleTagAdd, onRemove: handleTagRemove })))),
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { component: Checkbox, name: FormFields.hidden, label: "Hide this perk from tiers list" }))));
};
