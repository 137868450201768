import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { CloseIcon, DragIcon } from '@fourthwall/icons';
import { Icon } from '../../Icon';
import * as S from './SortableListItemToolbar.styled';
const DragHandle = SortableHandle(() => (React.createElement(S.DragHandle, { title: "Drag", "data-testid": "SortableListItemToolbar.DragHandle" },
    React.createElement(Icon, { component: DragIcon, height: 12 }))));
export const SortableListItemToolbar = ({ onRemove }) => (React.createElement(S.Container, null,
    React.createElement(DragHandle, null),
    React.createElement(S.Handle, { title: "Remove", onClick: onRemove, "data-testid": "SortableListItemToolbar.RemoveHandle" },
        React.createElement(Icon, { component: CloseIcon, height: 12 }))));
