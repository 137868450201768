import { useModalContext } from '@fourthwall/components';
import { Offer } from '@fourthwall/services/lib/models/offer';
import { useNavigate } from 'react-router-dom';
import { useDashboardPermissions } from '../../../../../components/utils/DashboardPermissions';
import { routing } from '../../../../../utils/routing';
import { OrderSamplesVariantSelectModal } from '../../components/OrderSamplesVariantSelectModal';
import { getSelectOptionsFromVariants } from './utils';
export const useOrderSamples = (product) => {
    const { open } = useModalContext();
    const { hasPermission } = useDashboardPermissions();
    const navigate = useNavigate();
    const areAnyItemsInStock = product?.variants.some((variant) => variant.quantity !== 0);
    const enabled = product &&
        Offer.FULFILLING_SERVICES_WITH_SAMPLES.includes(product.fulfillingService) &&
        Offer.OFFER_STATUSES_WITH_SAMPLES.includes(product.state.status) &&
        areAnyItemsInStock &&
        hasPermission('settings.samples.create');
    const redirectToSamplesOrder = (variantId) => navigate(routing.settings.samples.order, {
        state: {
            cart: { items: [{ variantId, quantity: 1 }] },
        },
    });
    const handleOrderSamples = () => {
        if (!product)
            return;
        if (product.variants.length === 1) {
            return redirectToSamplesOrder(product.variants[0].id);
        }
        open(OrderSamplesVariantSelectModal, {
            options: getSelectOptionsFromVariants(product.variants),
            onConfirm: redirectToSamplesOrder,
        });
    };
    return {
        onOrderSamples: enabled ? handleOrderSamples : undefined,
    };
};
