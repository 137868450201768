import { ConfirmModal, useModalContext } from '@fourthwall/components';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../../../../utils/routing';
import { useDeletePostMutation } from './useDeletePostMutation';
export const useDeletePost = () => {
    const modal = useModalContext();
    const navigate = useNavigate();
    const { deletePostMutation } = useDeletePostMutation();
    const handleDeletePost = (id) => {
        if (!id)
            return;
        return modal.open(ConfirmModal, {
            title: 'Are you sure you want to delete this post?',
            text: 'Deleting this post will permanently remove it, so members can no longer view it. You cannot undo this.',
            confirmLabel: 'Delete post',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                deletePostMutation.mutate({ id }, {
                    onSuccess: () => {
                        navigate(routing.memberships.posts.self);
                    },
                });
            },
        });
    };
    return {
        handleDeletePost,
        isLoading: deletePostMutation.isLoading,
    };
};
