import { useEffect, useRef, useState } from 'react';
export const useTimer = () => {
    const [timestamp, setTimestamp] = useState();
    const intervalRef = useRef();
    const [time, setTime] = useState(0);
    useEffect(() => {
        if (timestamp) {
            intervalRef.current = setInterval(() => {
                setTime(Date.now() - timestamp);
            }, 100);
        }
        return () => clearInterval(intervalRef.current);
    }, [timestamp]);
    const start = () => {
        setTime(0);
        setTimestamp(Date.now());
    };
    const stop = () => {
        if (timestamp) {
            setTime(Date.now() - timestamp);
        }
        setTimestamp(undefined);
    };
    const reset = () => {
        setTime(0);
    };
    return {
        time,
        start,
        stop,
        reset,
    };
};
