import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;
export const Details = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  gap: 4px;
`;
export const Supporter = styled.div `
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
`;
export const Tags = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin: 2px 0 8px;
`;
export const Tag = styled.div `
  padding: 3px 8px;
  background: ${Colors.GS300};
`;
export const Calculations = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
`;
export const Profit = styled.div `
  font-size: 14px;
`;
export const Date = styled.div `
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: ${Colors.GS600};
  display: flex;
  gap: 2px;
`;
export const Message = styled.div `
  font-size: 14px;
  line-height: 150%; /* 21px */
  letter-spacing: 0.14px;
`;
