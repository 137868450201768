import { Checkbox, Fieldset, InputUI, RadioButton } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React from 'react';
import { SideSummary } from '../../../../../../../components/common/SideSummary';
import { useDashboardFeatureFlags } from '../../../../../../../components/utils/DashboardFeatureFlags';
import { FormFields } from '../../types';
export const PostAccess = ({ tiers, showNotification = false, disabled }) => {
    const { values, errors } = useFormikContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const renderTiers = () => {
        if (values.access !== 'Plans') {
            return null;
        }
        return (React.createElement(React.Fragment, null, tiers.map((tier) => (React.createElement(Fieldset.Row, { inner: true },
            React.createElement(Field, { name: `tierIds.${tier.id}`, component: Checkbox, label: tier.title, disabled: disabled }))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SideSummary.Item, { title: "Viewable by" },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: FormFields.access, checkedValue: "MembersOnly", label: "All members", disabled: disabled })),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: FormFields.access, checkedValue: "Plans", label: "Selected tiers", disabled: disabled })),
                renderTiers(),
                errors.tierIds && React.createElement(InputUI.Error, null, errors.tierIds),
                dashboardFeatureFlags.membershipsPublicPost.enabled && (React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: FormFields.access, checkedValue: "Public", label: "Everyone", tooltip: values.mode === 'update' &&
                            values.postType === 'Video' &&
                            !dashboardFeatureFlags.membershipsPublicVideo.enabled
                            ? "Due to bandwidth costs, uploaded videos can only be offered to members. To share a video everyone can watch, use the 'Embed video' option."
                            : 'Anyone can see this post, even if they are not a member.', disabled: disabled }))))),
        showNotification && (React.createElement(SideSummary.Item, { title: "Notifications" },
            React.createElement(Field, { name: FormFields.notifyMembers, component: Checkbox, label: "Notify members about this post", disabled: disabled })))));
};
