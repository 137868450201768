export var Appearance;
(function (Appearance) {
    Appearance["Primary"] = "primary";
    Appearance["Secondary"] = "secondary";
    Appearance["Destructive"] = "destructive";
    Appearance["DestructiveInverted"] = "destructive-inverted";
})(Appearance || (Appearance = {}));
export var Size;
(function (Size) {
    Size["Small"] = "small";
    Size["Medium"] = "medium";
    Size["Large"] = "large";
})(Size || (Size = {}));
export var Type;
(function (Type) {
    Type["Button"] = "button";
    Type["Submit"] = "submit";
    Type["Reset"] = "reset";
})(Type || (Type = {}));
