import { AspectBox } from '@fourthwall/components';
import isEmpty from 'lodash-es/isEmpty';
import map from 'lodash-es/map';
import React from 'react';
import { Link } from 'react-router-dom';
import { reverse, routing } from '../../../../utils/routing';
import { PhotoThumbnail } from '../../PhotoThumbnail';
import { VirtualProductThumbnail } from '../../VirtualProductThumbnail';
import { Size } from '../types';
import * as S from './ProductsListItem.styled';
export const ProductsListItem = ({ image, name, offerId, quantity, price, variants, size, tag, donation, }) => {
    const renderImage = () => {
        if (donation) {
            return React.createElement(VirtualProductThumbnail, { size: size });
        }
        return React.createElement(PhotoThumbnail, { url: image, size: "large" });
    };
    const renderVariants = () => {
        if (isEmpty(variants))
            return null;
        if (typeof variants === 'string') {
            return React.createElement(S.Details, null, variants);
        }
        let variantsArray;
        if (size === Size.Small) {
            variantsArray = map(variants, ({ value }) => value && value);
        }
        else {
            variantsArray = map(variants, ({ prefix, value }) => value && `${prefix}: ${value}`);
        }
        return React.createElement(S.Details, null, variantsArray.filter(Boolean).join(', '));
    };
    const renderName = () => {
        if (!offerId) {
            return name;
        }
        return (React.createElement(S.NameLink, { as: Link, to: reverse(routing.products.all.product.self, { productId: offerId }) }, name));
    };
    return (React.createElement(S.ItemContainer, { size: size },
        React.createElement(S.ImageContainer, null,
            React.createElement(AspectBox, { ratio: "3:4" }, renderImage())),
        React.createElement(S.Name, null,
            renderName(),
            renderVariants()),
        React.createElement(S.Quantity, null, quantity),
        React.createElement(S.Price, null, price),
        tag && (React.createElement(S.Tag, null,
            React.createElement(S.TagWrapper, null, tag)))));
};
