import every from 'lodash-es/every';
import some from 'lodash-es/some';
import * as Yup from 'yup';
import { FormFields } from './types';
const MIN_PERCENTAGE_OFF = 1;
const MAX_PERCENTAGE_OFF = 80;
export const validationSchema = Yup.object().shape({
    [FormFields.accessType]: Yup.string().required(),
    [FormFields.percentageOff]: Yup.number().when('accessType', (accessType, schema) => accessType === 'AllTiers'
        ? schema.min(MIN_PERCENTAGE_OFF).max(MAX_PERCENTAGE_OFF).required()
        : schema.optional()),
    [FormFields.tiers]: Yup.object()
        .test('tiers', 'At least one tier is required.', function (tiers) {
        const { accessType } = this.parent;
        if (accessType === 'SelectedTiers' && every(tiers, (tier) => tier.enabled === false)) {
            return false;
        }
        return true;
    })
        .test('tiers', 'Each selected tier must have discount set.', function (tiers) {
        const { accessType } = this.parent;
        if (accessType === 'SelectedTiers' &&
            some(tiers, (tier) => tier.enabled && (!tier.percentageOff || tier.percentageOff === '0'))) {
            return false;
        }
        return true;
    })
        .test('tiers', 'Percentage off must be min. 1 and max. 80.', function (tiers) {
        const { accessType } = this.parent;
        if (accessType === 'SelectedTiers' &&
            some(tiers, (tier) => tier.enabled &&
                (tier.percentageOff < MIN_PERCENTAGE_OFF || tier.percentageOff > MAX_PERCENTAGE_OFF)))
            return false;
        return true;
    }),
});
