import { useModalContext } from '@fourthwall/components';
import { useCreatorIntegrationsQuery } from '@modules/Dashboard/Apps';
import { useTwitchMembershipsDiscountQuery } from '@modules/Dashboard/Apps/modules/AppsTwitchDiscounts';
import React from 'react';
import { Suggestion } from '../../../../../../../components/common/Suggestion';
import { useDashboardFeatureFlags } from '../../../../../../../components/utils/DashboardFeatureFlags';
import { useSocialLinksQuery } from '../../../../../../ThemeEditor/modules/SocialLinks';
import { SectionTitle } from '../../../../../Home/components/SectionTitle';
import { useCurrentShopQuery } from '../../../../../hooks/useCurrentShopQuery';
import { useTiers } from '../../../Perks/hooks/useTiers';
import { PerkModal } from '../../../Perks/PerkModal/PerkModal';
import { useMembershipsOverview } from '../../hooks/useMembershipsOverview';
import { useMembershipsOnboardingData } from '../Onboarding/hooks/useMembershipsOnboardingData';
import { discordSuggestion, newPostSuggestion, productsSuggestion, promotionsSuggestion, tiersSuggestion, twitchDiscountSuggestion, welcomePostSuggestion, } from './consts';
import * as S from './styled';
import { SuggestionsSkeleton } from './SuggestionsSkeleton';
export const Suggestions = () => {
    const modal = useModalContext();
    const { tiersQuery, tiers, hasAnyTier } = useTiers();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { membershipsOverviewQuery, discordPerk, welcomePost } = useMembershipsOverview();
    const { membershipsOnboardingQuery, isOnboardingCompleted } = useMembershipsOnboardingData();
    const { currentShopQuery } = useCurrentShopQuery(undefined);
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery(undefined);
    const { socialLinksQuery } = useSocialLinksQuery([currentShopQuery.data?.id], {
        enabled: currentShopQuery.isSuccess && !!currentShopQuery.data.id,
    });
    const { twitchMembershipsDiscountQuery } = useTwitchMembershipsDiscountQuery(undefined, {
        // Error is expected, it means that there is no discount app set up
        onError: () => { },
    });
    if (!tiersQuery.isSuccess ||
        !membershipsOverviewQuery.isSuccess ||
        !membershipsOnboardingQuery.isSuccess ||
        !currentShopQuery.isSuccess ||
        !creatorIntegrationsQuery.isSuccess ||
        !socialLinksQuery.isSuccess ||
        twitchMembershipsDiscountQuery.isLoading) {
        return React.createElement(SuggestionsSkeleton, null);
    }
    if (dashboardFeatureFlags.membershipsOnboarding.enabled && !hasAnyTier) {
        return null;
    }
    const getTitle = () => {
        if (dashboardFeatureFlags.membershipsOnboarding.enabled) {
            return 'Grow your Memberships';
        }
        if (!hasAnyTier) {
            return 'Set up your memberships';
        }
        return 'Grow your Memberships';
    };
    const getTiersSuggestion = () => {
        if (dashboardFeatureFlags.membershipsOnboarding.enabled) {
            return [];
        }
        if (!hasAnyTier) {
            return [tiersSuggestion];
        }
        return [];
    };
    const getWelcomePostSuggestion = () => {
        if (!hasAnyTier) {
            return [];
        }
        if (dashboardFeatureFlags.membershipsOnboarding.enabled) {
            if (isOnboardingCompleted && !welcomePost) {
                return [welcomePostSuggestion];
            }
            return [];
        }
        if (welcomePost) {
            return [];
        }
        return [welcomePostSuggestion];
    };
    const getDiscordSuggestion = () => {
        if (discordPerk || !hasAnyTier || !tiers) {
            return [];
        }
        const onButtonClick = () => {
            modal.open(PerkModal, {
                selectedType: 'Discord',
                selectedTiers: tiers.reduce((sum, tier) => ({
                    ...sum,
                    [tier.id]: true,
                }), {}),
            });
        };
        return [
            {
                ...discordSuggestion,
                onButtonClick,
            },
        ];
    };
    const getProductsSuggestion = () => {
        if (hasAnyTier)
            return [productsSuggestion];
        return [];
    };
    const getPromotionsSuggestion = () => {
        if (hasAnyTier)
            return [promotionsSuggestion];
        return [];
    };
    const getTwitchDiscountSuggestion = () => {
        if (hasAnyTier &&
            socialLinksQuery.data.socialNetworks.some(({ name }) => name === 'twitch') &&
            !twitchMembershipsDiscountQuery.data?.status)
            return [twitchDiscountSuggestion];
        return [];
    };
    const getSuggestions = () => {
        return [
            ...getTiersSuggestion(),
            newPostSuggestion,
            ...getWelcomePostSuggestion(),
            ...getDiscordSuggestion(),
            ...getProductsSuggestion(),
            ...getPromotionsSuggestion(),
            ...getTwitchDiscountSuggestion(),
        ];
    };
    return (React.createElement("div", null,
        React.createElement(SectionTitle, { title: getTitle() }),
        React.createElement(S.Suggestions, null, getSuggestions().map((suggestionProps) => (React.createElement(S.SuggestionWrapper, { key: suggestionProps.title },
            React.createElement(Suggestion, { ...suggestionProps })))))));
};
