import React, { Children, cloneElement, isValidElement } from 'react';
import { DashboardMenuItem } from './DashboardMenuItem';
import * as S from './styled';
export const DashboardMenu = ({ children }) => (React.createElement(S.Container, null, Children.map(children, (child) => {
    if (isValidElement(child)) {
        return cloneElement(child, {
            ...child.props,
        });
    }
})));
DashboardMenu.Item = DashboardMenuItem;
