import styled, { css } from 'styled-components';
export const Container = styled.div `
  position: relative;
`;
export const Input = styled.select ``;
const getSelectHeight = ({ inputSize }) => {
    if (inputSize === 'large') {
        return '56px';
    }
    if (inputSize === 'medium') {
        return '48px';
    }
    if (inputSize === 'small') {
        return '40px';
    }
};
export const Select = styled.select `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${getSelectHeight};
  opacity: 0;
  cursor: pointer;

  ${({ disabled }) => disabled &&
    css `
      cursor: not-allowed;
    `}
`;
