import React, { useEffect, useState } from 'react';
import { Button, Icon } from '@fourthwall/components';
import { ResetIcon, SearchIcon } from '@fourthwall/icons';
import { routing } from '@utils/routing';
import { useSelectItems } from './hooks/useSelectItems';
import { SelectItemsModalView } from './SelectItemsModalView';
import { SEARCH_MIN_LENGTH } from './consts';
import { modifySelectedItem, removeItemFromSelectedItems } from './utils';
export const SelectItemsModalContainer = ({ initialValues, onSubmit, }) => {
    const [inputSuggestion, setInputSuggestion] = useState(undefined);
    const { banner, search, selection } = useSelectItems({ initialValues });
    useEffect(() => {
        setInputSuggestion(undefined);
    }, [search.value]);
    const handleKeyDown = (event) => {
        if (search.value.length && search.value.length < SEARCH_MIN_LENGTH && event.key === 'Enter') {
            setInputSuggestion(`Enter at least ${SEARCH_MIN_LENGTH} characters to start the search.`);
        }
    };
    const handleItemChange = ({ checked, ...item }) => {
        selection.setSelectedItems((selectedItems) => {
            if (!checked) {
                return removeItemFromSelectedItems(item.variantId, selectedItems);
            }
            const isItemAlreadySelected = selectedItems.some((selectedItem) => selectedItem.variantId === item.variantId);
            if (isItemAlreadySelected) {
                return modifySelectedItem(item, selectedItems);
            }
            return [...selectedItems, item];
        });
    };
    const handleSubmit = () => {
        const items = selection.selectedItems.map(({ variantId, quantity }) => ({
            variantId,
            quantity: parseInt(quantity),
        }));
        onSubmit({ items });
    };
    const inputProps = {
        value: search.value,
        placeholder: 'Search products',
        leftIcon: SearchIcon,
        actionIcon: search.value.length ? React.createElement(Icon, { component: ResetIcon, height: 18 }) : undefined,
        suggestion: inputSuggestion,
        onKeyDown: handleKeyDown,
        onValueChange: search.setValue,
        onActionIconClick: search.resetValue,
    };
    const getEmptyMessage = () => {
        if (!selection.offersCount) {
            if (!search.debouncedValue.length) {
                return (React.createElement(React.Fragment, null,
                    React.createElement("p", null, "There are no products created which are eligible for samples ordering."),
                    React.createElement(Button, { label: "Create new product", to: routing.products.all.self, onClick: close })));
            }
            return 'No products found';
        }
    };
    const getSelectItems = () => {
        const { items, selectedItems, isLoading, offersCount, hasErrors, hasNextPage, loadMoreItems } = selection;
        return {
            items,
            isLoading,
            selectedItems,
            offersCount,
            hasErrors,
            hasNextPage,
            emptyMessage: getEmptyMessage(),
            onLoadMore: loadMoreItems,
            onItemChange: handleItemChange,
        };
    };
    return (React.createElement(SelectItemsModalView, { inputProps: inputProps, banner: banner, selectItems: getSelectItems(), submitDisabled: selection.hasErrors, onSubmit: handleSubmit }));
};
