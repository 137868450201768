import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const ProductSummaryContainer = styled.div `
  padding: 8px 0;
  border-top: 1px solid ${Colors.GS400};
  border-bottom: 1px solid ${Colors.GS400};
`;
export const EmptyVariantsNotice = styled.div `
  padding: 16px 0;
`;
export const VariantsContainer = styled.div `
  maring-left: -10px;
  padding-left: 10px;
  max-height: 350px;
  overflow: auto;
`;
export const VariantContainer = styled.div `
  padding: 20px 0;
  font-size: 16px;
  line-height: 24px;

  & + & {
    border-top: 1px solid ${Colors.GS400};
  }

  &:last-child {
    border-bottom: 1px solid ${Colors.GS400};
  }
`;
export const OptionLabel = styled.div `
  display: flex;
  flex: 1;
  justify-content: space-between;

  ${({ disabled }) => disabled &&
    css `
      color: ${Colors.GS600};
    `}
`;
