import { useEffect, useState } from 'react';
import isEqual from 'lodash-es/isEqual';
import { Toasts } from '@fourthwall/components';
import { useNavigate } from 'react-router-dom';
import { getEntriConfig, getUpdateEntriProviderDetailsBody } from './utils';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { useDetectDomainProviderGetMutation } from './hooks/useDetectDomainProviderGetMutation';
import { useUpdateEntriProviderDetailsMutation } from './hooks/useUpdateEntriProviderDetails';
import { useVerifyDomainMutation } from '../useVerifyDomainMutation';
import { useEntriContext } from '../../providers/Entri';
import { useDnsQuery } from '../../../hooks/useDnsQuery';
import { routing } from '../../../../../utils/routing';
export const useEntri = (options = { enabled: true }) => {
    const navigate = useNavigate();
    const { currentShopQuery } = useCurrentShopQuery();
    const { updateEntriProviderDetailsMutation } = useUpdateEntriProviderDetailsMutation();
    const { verifyDomainMutation } = useVerifyDomainMutation();
    const { invalidateDnsQuery } = useDnsQuery();
    const { detectDomainProviderGetMutation } = useDetectDomainProviderGetMutation();
    const [isAutomaticSetupInProgress, setIsAutomaticSetupInProgress] = useState(false);
    const shopId = currentShopQuery.data?.id;
    const { isEntriLoaded, enableEntri, addEntriCloseEvent, removeEntriCloseEvent, showEntriModal } = useEntriContext();
    useEffect(() => {
        if (options.enabled) {
            enableEntri();
        }
    }, [enableEntri, options.enabled]);
    const getProviderDetails = (dnsList, options) => {
        if (!shopId)
            return;
        detectDomainProviderGetMutation.mutate({ dnsList, shopId }, {
            ...options,
            onSuccess: async (data) => {
                const providerDetails = data.domainType && getUpdateEntriProviderDetailsBody(data.domainType);
                if (providerDetails && !isEqual(providerDetails, dnsList.entriProviderDetails)) {
                    await updateEntriProviderDetailsMutation.mutate([
                        { domainId: dnsList.domainId },
                        providerDetails,
                    ]);
                }
                options?.onSuccess?.(data, { dnsList, shopId }, undefined);
            },
        });
    };
    const verifyDomain = async (event, options) => {
        if (event.detail.success) {
            await verifyDomainMutation.mutateAsync([], {
                ...options,
            });
        }
        else {
            navigate(routing.settings.domain.configuration);
        }
        removeEntriCloseEvent(verifyDomain);
        setIsAutomaticSetupInProgress(false);
    };
    const setUpDomainAutomatically = (config, options) => {
        setIsAutomaticSetupInProgress(true);
        showEntriModal(config);
        addEntriCloseEvent((event) => verifyDomain(event, options));
    };
    const navigateToManualConfiguration = () => navigate(routing.settings.domain.configuration);
    const connectDomain = (dnsList, onConnect) => {
        getProviderDetails(dnsList, {
            onSuccess: ({ config, domainType }) => {
                if (!domainType || domainType?.setupType === 'Manual') {
                    navigateToManualConfiguration();
                }
                if (domainType.setupType === 'Automatic') {
                    setUpDomainAutomatically(config, {
                        onSuccess: ({ dnsList }) => {
                            invalidateDnsQuery();
                            const toastMessage = dnsList.areAllEntriesComplete
                                ? `${dnsList.domain} was added and is currently syncing.`
                                : `${dnsList.domain} was added but requires additional verification.`;
                            Toasts.notify(toastMessage, {
                                type: 'info',
                            });
                        },
                        onError: navigateToManualConfiguration,
                        onSettled: () => {
                            navigate(routing.settings.domain.self);
                        },
                    });
                }
            },
            onError: navigateToManualConfiguration,
            onSettled: onConnect,
        });
    };
    return {
        isEntriLoaded,
        isAutomaticSetupInProgress,
        isGettingProviderDetailsInProgress: detectDomainProviderGetMutation.isLoading,
        getEntriConfig,
        getProviderDetails,
        connectDomain,
        setUpDomainAutomatically,
    };
};
