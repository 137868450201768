import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { useModalContext } from '@fourthwall/components';
import { ThankYousModal } from '@modules/Dashboard/ThankYous/components/ThankYousModal';
import React from 'react';
import { useThankYous } from './hooks/useThankYous';
import { SendThankYouModal } from './modules/SendThankYouModal';
import { ThankYousView } from './ThankYousView';
export const ThankYousContainer = () => {
    const { thankYous } = useThankYous();
    const { open } = useModalContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const openThankYouModal = (item) => {
        if (dashboardFeatureFlags.thankYousSending.enabled) {
            open(SendThankYouModal, {
                thankYous: [item],
                onSuccess: thankYous.refetch,
            });
            return;
        }
        open(ThankYousModal);
    };
    return (React.createElement(ThankYousView, { tabs: [
            { label: 'Remaining', value: 'WAITING' },
            { label: 'Skipped', value: 'SKIPPED' },
            { label: 'Sent', value: 'SENT' },
        ], activeTab: thankYous.activeTab, data: thankYous.data, isLoading: thankYous.isLoading, noThankYous: thankYous.noThankYous, currentPage: thankYous.currentPage, totalPages: thankYous.totalPages, onTabClick: thankYous.setState, onCurrentPageChange: thankYous.setPage, onRowClick: openThankYouModal, onBarModalSuccess: thankYous.refetch }));
};
