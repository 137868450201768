import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  user-select: none;
`;
export const Badges = styled.div `
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
export const Badge = styled.div `
  background: rgba(234, 234, 233, 0.2);
  backdrop-filter: blur(2px);
  padding: 2px 8px;
  width: fit-content;
`;
export const Products = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const Product = styled.div `
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const ProductTitle = styled.div `
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const Message = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  font-size: 20px;
  font-weight: 600;
  line-height: 140%; /* 28px */
`;
