import { ApiBase } from './ApiBase';
class NotificationShopPreferencesApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getNotificationShopPreferences = () => {
            return this.get('/notification/shop/preferences');
        };
        this.updateNotificationShopPreferences = (payload) => {
            return this.put('/notification/shop/preferences', payload);
        };
    }
}
export const NotificationShopPreferencesApiClient = new NotificationShopPreferencesApiClientModel();
