import { ButtonClean, Icon } from '@fourthwall/components';
import { CloseIcon, QuestionIcon, TickIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
const statusIcon = {
    active: TickIcon,
    pending: QuestionIcon,
    denied: CloseIcon,
};
export const ProductsSyncStatusHeading = ({ status, label, onSeeDetails, }) => (React.createElement(S.Container, null,
    React.createElement(S.IconContainer, null,
        React.createElement(S.Icon, { status: status },
            React.createElement(Icon, { component: statusIcon[status], height: 10 }))),
    React.createElement(S.Label, null, label),
    onSeeDetails && (React.createElement(S.ButtonContainer, null,
        React.createElement(ButtonClean, { label: "See details", onClick: onSeeDetails })))));
