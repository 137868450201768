const SHOPS_IDS = [
    // TMG Staging
    'sh_713e8b07-4059-4209-8b2c-64775c9017a6',
    // TMG
    'sh_10a20816-d098-4f06-b2f7-ec47a4d5602f',
    // UTI
    'sh_2e0df2cb-e6e1-4c2f-adb4-e2cad30c5b76',
    // Down Bad Show
    'sh_2ac44fb0-656a-4853-bcc2-a654f2d5cafd',
    // Armchair History TV
    'sh_813cacde-885f-4930-bb30-1b67813b3636',
    // The Ben and Emil Show
    'sh_d261a805-7e19-4ab2-bfbd-ea97ff731348',
];
export const hasMembershipsWlaEnabled = (shopid) => {
    return SHOPS_IDS.includes(shopid);
};
