import { Dashboard } from '@components/common';
import { AdminModeSwitch } from '@components/common/AdminModeSwitch';
import { UserDetails } from '@components/common/UserDetails';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { PermissionGuardedRoute, useDashboardPermissions, } from '@components/utils/DashboardPermissions';
import { FeatureFlagDecorator } from '@components/utils/FeatureFlagDecorator';
import { NavigateWithSearch } from '@components/utils/NavigateWithSearch';
import { useAuth } from '@fourthwall/auth';
import { Colors, DashboardLogo, Dropdown, ScrollToTop, ToolbarButton, useMediaQueryContext, } from '@fourthwall/components';
import { PlusBoldIcon } from '@fourthwall/icons';
import { Redirect } from '@fourthwall/utils/lib/components/Redirect';
import { lazyWithRetry } from '@fourthwall/utils/lib/lazyWithRetry';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import { ConnectIntegration } from '@modules/ConnectIntegration';
import AccountContainer from '@modules/Dashboard/Account/AccountContainer';
import { AppsContainer as SettingsAppsContainer } from '@modules/Dashboard/Apps/AppsContainer';
import { AppsBeehiiv } from '@modules/Dashboard/Apps/modules/AppsBeehiiv';
import { AppsDiscord } from '@modules/Dashboard/Apps/modules/AppsDiscord';
import { AppsDiscordVerification } from '@modules/Dashboard/Apps/modules/AppsDiscordVerification';
import { AppsInstagram } from '@modules/Dashboard/Apps/modules/AppsInstagram';
import { AppsKlaviyo } from '@modules/Dashboard/Apps/modules/AppsKlaviyo';
import { AppsLaylo } from '@modules/Dashboard/Apps/modules/AppsLaylo';
import { AppsMetaContainer } from '@modules/Dashboard/Apps/modules/AppsMeta';
import { AppsShipStation } from '@modules/Dashboard/Apps/modules/AppsShipStation';
import { AppsStreamelementsContainer } from '@modules/Dashboard/Apps/modules/AppsStreamelements';
import { AppsStreamlabsContainer } from '@modules/Dashboard/Apps/modules/AppsStreamlabs';
import { AppsTiktok } from '@modules/Dashboard/Apps/modules/AppsTiktok';
import { AppsTiktokShop } from '@modules/Dashboard/Apps/modules/AppsTiktokShop';
import { AppsTwitchContainer } from '@modules/Dashboard/Apps/modules/AppsTwitch';
import { AppsTwitchDiscounts } from '@modules/Dashboard/Apps/modules/AppsTwitchDiscounts';
import { AppsTwitterShops } from '@modules/Dashboard/Apps/modules/AppsTwitterShops';
import CollectionContainer from '@modules/Dashboard/Collection/CollectionContainer';
import { SidebarContent } from '@modules/Dashboard/SidebarContent';
import { routing } from '@utils/routing';
import React, { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { OfferPreviewContainer } from '../OfferPreview';
import { AnalyticsRouter } from './Analytics/routers/AnalyticsRouter';
import { CollectionsContainer } from './Collections/CollectionsContainer';
import { Contributions } from './Contributions';
import { CreatorMessagesContainer } from './Contributions/modules/CreatorMessages/CreatorMessagesContainer';
import { CreatorMessageContainer } from './Contributions/modules/CreatorMessages/modules/Message/CreatorMessageContainer';
import { CreatorMessagesSendContainer } from './Contributions/modules/CreatorMessages/modules/Send/CreatorMessagesSendContainer';
import { DonationContainer } from './Contributions/modules/Donation/DonationContainer';
import { OrderContainer } from './Contributions/modules/Order';
import { OrderCancelContainer } from './Contributions/modules/Order/OrderCancel/OrderCancelContainer';
import { OrderEditContainer } from './Contributions/modules/Order/OrderEdit/OrderEditContainer';
import { OrderRefundContainer } from './Contributions/modules/Order/OrderRefund/OrderRefundContainer';
import { HomeContainer } from './Home/HomeContainer';
import { useBanners } from './hooks/useBanners';
import { useNavigations } from './hooks/useNavigations';
import { Member } from './Memberships/modules/Member/Member';
import { Members } from './Memberships/modules/Members/Members';
import { Overview } from './Memberships/modules/Overview/Overview';
import { Perks } from './Memberships/modules/Perks/Perks';
import { Post } from './Memberships/modules/Post/Post';
import { PostCreate } from './Memberships/modules/Post/PostCreate';
import { Posts } from './Memberships/modules/Posts/Posts';
import { Series } from './Memberships/modules/Series/Series';
import { Settings as MembershipsSettings } from './Memberships/modules/Settings';
import { General } from './Memberships/modules/Settings/modules/General/General';
import { Messages } from './Memberships/modules/Settings/modules/Messages/Messages';
import { MobileApp } from './Memberships/modules/Settings/modules/MobileApp/MobileApp';
import { Upselling } from './Memberships/modules/Settings/modules/Upselling/Upselling';
import { WelcomePost } from './Memberships/modules/Settings/modules/WelcomePost/WelcomePost';
import { SingleSeries } from './Memberships/modules/SingleSeries/SingleSeries';
import { SingleSeriesCreate } from './Memberships/modules/SingleSeries/SingleSeriesCreate';
import { MembershipsSplashPage } from './MembershipsSplashPage';
import { MessagesWidget } from './MessagesWidget';
import { OrdersFulfillmentContainer } from './OrdersFulfillment/OrdersFulfillmentContainer';
import { Product } from './Product';
import { Costs } from './Product/modules/costs/Costs';
import { ProductSectionsContainer } from './Product/ProductSections';
import { ProductSettings } from './Product/ProductSettings';
import { ProductVariantsContainer } from './Product/ProductVariants';
import { ProductsContainer } from './Products/ProductsContainer';
import { GiveawayPackages } from './Promotions/modules/GiveawayPackages';
import { PromotionCodes } from './Promotions/modules/PromotionCodes';
import { Promotions } from './Promotions/Promotions';
import { QuickActionsDropdown } from './QuickActionsDropdown';
import { CheckoutContainer } from './Settings/modules/Checkout/CheckoutContainer';
import { DomainContainer } from './Settings/modules/Domain/DomainContainer';
import { DomainConfigurationContainer } from './Settings/modules/DomainConfiguration';
import { ForDevelopersContainer } from './Settings/modules/ForDevelopers/ForDevelopersContainer';
import { GeneralContainer } from './Settings/modules/General/GeneralContainer';
import { NewDomain } from './Settings/modules/NewDomain/NewDomainContainer';
import { PayoutsContainer } from './Settings/modules/Payouts/PayoutsContainer';
import { SamplesCreditContainer } from './Settings/modules/Samples';
import { SamplesOrderContainer } from './Settings/modules/Samples/modules/SamplesOrder';
import { ShippingContainer } from './Settings/modules/Shipping/ShippingContainer';
import { ShippingGroupContainer } from './Settings/modules/ShippingGroup/ShippingGroupContainer';
import { TeamContainer } from './Settings/modules/Team';
import { Transactions } from './Settings/modules/Transactions/TransactionsContainer';
import { ShopSwitch } from './ShopSwitch';
import * as S from './styled';
import { ThankYousContainer } from './ThankYous';
// const GiveawaysContainer = lazyWithRetry(() => import('./Contributions/modules/Giveaways/GiveawaysContainer'))
const GiveawayContainer = lazyWithRetry(() => import('./Contributions/modules/Giveaway/GiveawayContainer'));
const ThemeEditor = lazyWithRetry(() => import('../ThemeEditor/modules/ThemeEditor/ThemeEditorContainer'));
const PromotionCodeContainer = lazyWithRetry(() => import('./Promotions/modules/PromotionCode'));
const MembershipsPromotion = lazyWithRetry(() => import('./Promotions/modules/MembershipsPromotion'));
const GiveawayPackageContainer = lazyWithRetry(() => import('./Promotions/modules/GiveawayPackage'));
const Settings = lazyWithRetry(() => import('./Settings/Settings'));
const BlockedTransactionsContainer = lazyWithRetry(() => import('./BlockedTransactions/BlockedTransactionsContainer'));
const AppsYouTubeProductShelfContainer = lazyWithRetry(() => import('@modules/Dashboard/Apps/modules/AppsYouTubeProductShelf'));
const MembershipsMessages = lazyWithRetry(() => import('./Memberships/modules/Messages/Messages'));
const InventoryShipmentCreate = lazyWithRetry(() => import('@modules/Dashboard/Settings/modules/InventoryShipments/create/InventoryShipmentCreate'));
const InventoryShipmentsShow = lazyWithRetry(() => import('@modules/Dashboard/Settings/modules/InventoryShipments/show/InventoryShipmentsShow'));
const InventoryStockSnapshot = lazyWithRetry(() => import('@modules/Dashboard/Settings/modules/InventoryStocks/InventoryStockSnapshot'));
export const DashboardView = ({ shopName, user, partnerDashboard, connectedShops, }) => {
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    const { logout } = useAuth();
    const location = useLocation();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { hasPermission, hasAnyPermission } = useDashboardPermissions();
    const { navigations, getFirstVisibleNavigation } = useNavigations();
    const { bannersProps } = useBanners();
    const firstVisibleNavigation = getFirstVisibleNavigation();
    const admin = useAdminModeContext();
    const media = useMediaQueryContext();
    const avatarLabel = admin?.isActive ? 'admin' : undefined;
    const toolbarIconSize = media.laptop ? 18 : 20;
    const routes = (React.createElement(Routes, null,
        React.createElement(Route, { path: routing.redirect.googleAnalytics, element: React.createElement(NavigateWithSearch, { to: routing.analytics.self }) }),
        React.createElement(Route, { path: routing.redirect.meta, element: React.createElement(NavigateWithSearch, { to: routing.apps.meta }) }),
        React.createElement(Route, { path: routing.redirect.streamlabs, element: React.createElement(NavigateWithSearch, { to: routing.apps.streamlabs }) }),
        React.createElement(Route, { path: routing.redirect.twitch, element: React.createElement(NavigateWithSearch, { to: routing.apps.twitch }) }),
        React.createElement(Route, { path: routing.home, element: firstVisibleNavigation !== routing.home ? (React.createElement(Navigate, { to: firstVisibleNavigation, replace: true })) : (React.createElement(PermissionGuardedRoute, { permission: "home", element: React.createElement(HomeContainer, null) })) }),
        React.createElement(Route, { path: `${routing.analytics.self}*`, element: React.createElement(PermissionGuardedRoute, { permission: "analytics", element: React.createElement(AnalyticsRouter, null) }) }),
        React.createElement(Route, { path: routing.apps.self, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(SettingsAppsContainer, null) }) }),
        React.createElement(Route, { path: routing.membershipsBeta.self, element: React.createElement(NavigateWithSearch, { to: routing.membershipsEnable.self }) }),
        React.createElement(Route, { path: routing.membershipsEnable.self, element: React.createElement(MembershipsSplashPage, null) }),
        React.createElement(Route, { path: routing.products.self, element: React.createElement(Navigate, { to: routing.products.all.self, replace: true }) }),
        React.createElement(Route, { path: routing.products.all.self, element: React.createElement(PermissionGuardedRoute, { permission: "products.view", element: React.createElement(ProductsContainer, null) }) }),
        React.createElement(Route, { path: routing.products.all.product.self, element: React.createElement(PermissionGuardedRoute, { permission: "products.view", element: React.createElement(Product, null) }) }),
        React.createElement(Route, { path: routing.products.all.product.variants, element: React.createElement(PermissionGuardedRoute, { permission: "products.edit.details", element: React.createElement(ProductVariantsContainer, null) }) }),
        React.createElement(Route, { path: routing.products.all.product.gallery, element: React.createElement(Navigate, { to: "../", relative: "path", replace: true }) }),
        React.createElement(Route, { path: routing.products.all.product.sections, element: React.createElement(PermissionGuardedRoute, { permission: "products.edit.additionalSections", element: React.createElement(ProductSectionsContainer, null) }) }),
        React.createElement(Route, { path: routing.products.all.product.settings, element: React.createElement(PermissionGuardedRoute, { permission: "products.advancedSettings", element: React.createElement(ProductSettings, null) }) }),
        React.createElement(Route, { path: routing.products.all.product.costs, element: React.createElement(PermissionGuardedRoute, { roleName: "ROLE_COSTS_WRITE", element: React.createElement(Costs, null) }) }),
        React.createElement(Route, { path: routing.contributions.self, element: React.createElement(Navigate, { to: routing.contributions.orders.all, replace: true }) }),
        React.createElement(Route, { path: routing.contributions.orders.order.self, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.orders.view", element: React.createElement(OrderContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.orders.order.edit, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.orders.edit", element: React.createElement(OrderEditContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.orders.order.cancel, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.orders.cancel", element: React.createElement(OrderCancelContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.orders.order.refund, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.orders.refund", element: React.createElement(OrderRefundContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.orders.all, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.orders.view", element: React.createElement(Contributions, null) }) }),
        React.createElement(Route, { path: routing.contributions.orders.donations.all, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.orders.view", element: React.createElement(Contributions, null) }) }),
        React.createElement(Route, { path: routing.contributions.orders.giveaways.all, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.orders.view", element: React.createElement(Contributions, null) }) }),
        React.createElement(Route, { path: routing.contributions.orders.donations.donation.self, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.orders.view", element: React.createElement(DonationContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.fulfillment, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.fulfillments.view", element: React.createElement(OrdersFulfillmentContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.thankYous.self, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.thankYous", element: React.createElement(ThankYousContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.orders.giveaways.giveaway, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.orders.view", element: React.createElement(GiveawayContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.creatorMessages.send, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.messages.send", element: React.createElement(CreatorMessagesSendContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.creatorMessages.message.self, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.messages.view", element: React.createElement(CreatorMessageContainer, null) }) }),
        React.createElement(Route, { path: routing.contributions.creatorMessages.self, element: React.createElement(PermissionGuardedRoute, { permission: "contributions.messages.view", element: React.createElement(CreatorMessagesContainer, null) }) }),
        React.createElement(Route, { path: routing.promotions.self, element: React.createElement(Navigate, { to: routing.promotions.promotionCodes.self, replace: true }) }),
        React.createElement(Route, { path: routing.promotions.promotionCodes.self, element: React.createElement(PermissionGuardedRoute, { permission: "promotions.shopPromoCodes", element: React.createElement(Promotions, { element: React.createElement(PromotionCodes, null) }) }) }),
        React.createElement(Route, { path: routing.promotions.giveawayPackages.self, element: React.createElement(PermissionGuardedRoute, { permission: "promotions.giveawayLinks", element: React.createElement(Promotions, { element: React.createElement(GiveawayPackages, null) }) }) }),
        React.createElement(Route, { path: routing.promotions.giveawayPackages.create.self, element: React.createElement(PermissionGuardedRoute, { permission: "promotions.giveawayLinks", element: React.createElement(GiveawayPackageContainer, null) }) }),
        React.createElement(Route, { path: routing.promotions.giveawayPackages.giveawayPackage.self, element: React.createElement(PermissionGuardedRoute, { permission: "promotions.giveawayLinks", element: React.createElement(GiveawayPackageContainer, null) }) }),
        React.createElement(Route, { path: routing.promotions.promotionCodes.create.self, element: React.createElement(PermissionGuardedRoute, { permission: "promotions.shopPromoCodes", element: React.createElement(PromotionCodeContainer, null) }) }),
        React.createElement(Route, { path: routing.promotions.promotionCodes.promotionCode.self, element: React.createElement(PermissionGuardedRoute, { permission: "promotions.shopPromoCodes", element: React.createElement(PromotionCodeContainer, null) }) }),
        React.createElement(Route, { path: routing.promotions.promotionCodes.membershipsPromotion.self, element: React.createElement(PermissionGuardedRoute, { permission: "promotions.membershipsPromoCodes", element: React.createElement(MembershipsPromotion, null) }) }),
        React.createElement(Route, { path: routing.promotions.promotionCodes.membershipsPromotionCreate.self, element: React.createElement(PermissionGuardedRoute, { permission: "promotions.membershipsPromoCodes", element: React.createElement(MembershipsPromotion, null) }) }),
        React.createElement(Route, { path: routing.products.collections.self, element: React.createElement(PermissionGuardedRoute, { permission: "collections.view", element: React.createElement(CollectionsContainer, null) }) }),
        React.createElement(Route, { path: routing.products.collections.create.self, element: React.createElement(PermissionGuardedRoute, { permission: "collections.create", element: React.createElement(CollectionContainer, null) }) }),
        React.createElement(Route, { path: routing.products.collections.collection.self, element: React.createElement(PermissionGuardedRoute, { permission: "collections.view", element: React.createElement(CollectionContainer, null) }) }),
        React.createElement(Route, { path: routing.settings.self, element: React.createElement(Navigate, { to: routing.settings.general, replace: true }) }),
        React.createElement(Route, { path: routing.settings.general, element: React.createElement(PermissionGuardedRoute, { permission: "settings.general", element: React.createElement(Settings, { element: React.createElement(GeneralContainer, null) }) }) }),
        React.createElement(Route, { path: routing.settings.team, element: React.createElement(PermissionGuardedRoute, { permission: "settings.team.view", element: React.createElement(Settings, { element: React.createElement(TeamContainer, null) }) }) }),
        React.createElement(Route, { path: routing.settings.appsLegacy.app, element: React.createElement(Redirect, { to: routing.apps.app }) }),
        React.createElement(Route, { path: routing.apps.twitchMembershipsDiscountsLegacy, element: React.createElement(Redirect, { to: routing.apps.twitchMembershipsDiscounts }) }),
        React.createElement(Route, { path: routing.settings.shipping, element: React.createElement(PermissionGuardedRoute, { permission: "settings.shipping", element: React.createElement(Settings, { element: React.createElement(ShippingContainer, null) }) }) }),
        React.createElement(Route, { path: routing.settings.shippingGroup.self, element: React.createElement(PermissionGuardedRoute, { permission: "settings.shipping", element: React.createElement(ShippingGroupContainer, null) }) }),
        React.createElement(Route, { path: routing.settings.shippingGroup.create, element: React.createElement(PermissionGuardedRoute, { permission: "settings.shipping", element: React.createElement(ShippingGroupContainer, null) }) }),
        React.createElement(Route, { path: routing.settings.inventoryShipments.self, element: React.createElement(PermissionGuardedRoute, { permission: "settings.shipping", element: React.createElement(InventoryShipmentsShow, null) }) }),
        React.createElement(Route, { path: routing.settings.inventoryShipments.create, element: React.createElement(PermissionGuardedRoute, { permission: "settings.shipping", element: React.createElement(InventoryShipmentCreate, null) }) }),
        React.createElement(Route, { path: routing.settings.inventoryStocks.self, element: React.createElement(PermissionGuardedRoute, { permission: "settings.shipping", element: React.createElement(InventoryStockSnapshot, null) }) }),
        React.createElement(Route, { path: routing.settings.checkout, element: React.createElement(PermissionGuardedRoute, { permission: "settings.checkout", element: React.createElement(Settings, { element: React.createElement(CheckoutContainer, null) }) }) }),
        React.createElement(Route, { path: routing.settings.forDevelopers, element: React.createElement(PermissionGuardedRoute, { permission: "settings.forDevelopers", element: React.createElement(Settings, { element: React.createElement(ForDevelopersContainer, null) }) }) }),
        React.createElement(Route, { path: routing.settings.domain.self, element: React.createElement(PermissionGuardedRoute, { permission: "settings.domain", element: React.createElement(Settings, { element: React.createElement(DomainContainer, null) }) }) }),
        React.createElement(Route, { path: routing.settings.billing.self, element: React.createElement(PermissionGuardedRoute, { permission: "settings.billing.view", element: React.createElement(Settings, { element: React.createElement(PayoutsContainer, null) }) }) }),
        React.createElement(Route, { path: routing.settings.samples.self, element: React.createElement(PermissionGuardedRoute, { permission: "settings.samples.view", element: React.createElement(Settings, { element: React.createElement(SamplesCreditContainer, null) }) }) }),
        React.createElement(Route, { path: routing.apps.streamlabs, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsStreamlabsContainer, null) }) }),
        React.createElement(Route, { path: routing.apps.twitch, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsTwitchContainer, null) }) }),
        React.createElement(Route, { path: routing.apps.twitchMembershipsDiscounts, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsTwitchDiscounts, null) }) }),
        React.createElement(Route, { path: routing.apps.tiktok, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsTiktok, null) }) }),
        React.createElement(Route, { path: routing.apps.tiktokShop, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsTiktokShop, null) }) }),
        React.createElement(Route, { path: routing.apps.twitterShops, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsTwitterShops, null) }) }),
        React.createElement(Route, { path: routing.apps.streamelements, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsStreamelementsContainer, null) }) }),
        React.createElement(Route, { path: routing.apps.instagram, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsInstagram, null) }) }),
        React.createElement(Route, { path: routing.apps.discord, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsDiscord, null) }) }),
        React.createElement(Route, { path: routing.apps.discordVerification, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsDiscordVerification, null) }) }),
        React.createElement(Route, { path: routing.apps.youTubeProductShelf, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsYouTubeProductShelfContainer, null) }) }),
        React.createElement(Route, { path: routing.apps.youTubeProductShelfLegacy, element: React.createElement(Redirect, { to: routing.apps.youTubeProductShelf }) }),
        React.createElement(Route, { path: routing.apps.shipStation, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsShipStation, null) }) }),
        React.createElement(Route, { path: routing.apps.klaviyo, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsKlaviyo, null) }) }),
        React.createElement(Route, { path: routing.apps.beehiiv, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsBeehiiv, null) }) }),
        React.createElement(Route, { path: routing.apps.laylo, element: React.createElement(PermissionGuardedRoute, { permission: "apps", element: React.createElement(AppsLaylo, null) }) }),
        React.createElement(Route, { path: routing.apps.meta, element: React.createElement(AppsMetaContainer, null) }),
        React.createElement(Route, { path: routing.settings.domain.create, element: React.createElement(PermissionGuardedRoute, { permission: "settings.domain", element: React.createElement(NewDomain, null) }) }),
        React.createElement(Route, { path: routing.settings.domain.configuration, element: React.createElement(PermissionGuardedRoute, { permission: "settings.domain", element: React.createElement(DomainConfigurationContainer, null) }) }),
        React.createElement(Route, { path: routing.settings.billing.transactions, element: React.createElement(PermissionGuardedRoute, { permission: "settings.billing.history.full", element: React.createElement(Transactions, null) }) }),
        React.createElement(Route, { path: routing.settings.samples.order, element: React.createElement(PermissionGuardedRoute, { permission: "settings.samples.create", element: React.createElement(SamplesOrderContainer, null) }) }),
        React.createElement(Route, { path: `${routing.account.self}*`, element: React.createElement(PermissionGuardedRoute, { permission: "accountSettings", element: React.createElement(AccountContainer, null) }) }),
        React.createElement(Route, { path: routing.memberships.self, element: React.createElement(Navigate, { to: routing.memberships.overview, replace: true }) }),
        React.createElement(Route, { path: routing.memberships.overview, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.overview", element: React.createElement(Overview, null) }) }),
        React.createElement(Route, { path: routing.memberships.posts.self, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.post.view", element: React.createElement(Posts, null) }) }),
        React.createElement(Route, { path: routing.memberships.posts.scheduled.all, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.post.view", element: React.createElement(Posts, null) }) }),
        React.createElement(Route, { path: routing.memberships.posts.create, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.post.create", element: React.createElement(PostCreate, null) }) }),
        React.createElement(Route, { path: routing.memberships.posts.post.self, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.post.create", element: React.createElement(Post, null) }) }),
        React.createElement(Route, { path: routing.memberships.series.self, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.video.view", element: React.createElement(Series, null) }) }),
        React.createElement(Route, { path: routing.memberships.series.create, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.video.create", element: React.createElement(SingleSeriesCreate, null) }) }),
        React.createElement(Route, { path: routing.memberships.series.singleSeries.self, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.video.create", element: React.createElement(SingleSeries, null) }) }),
        React.createElement(Route, { path: routing.memberships.members.self, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.members", element: React.createElement(Members, null) }) }),
        React.createElement(Route, { path: routing.memberships.members.member.self, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.members", element: React.createElement(Member, null) }) }),
        React.createElement(Route, { path: routing.memberships.perks, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.perksAndTiers", element: React.createElement(Perks, null) }) }),
        React.createElement(Route, { path: `${routing.memberships.messages.self}*`, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.messages", element: React.createElement(MembershipsMessages, null) }) }),
        React.createElement(Route, { path: routing.memberships.settings.self, element: React.createElement(Navigate, { to: routing.memberships.settings.general, replace: true }) }),
        React.createElement(Route, { path: routing.memberships.settings.general, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.settings.general", element: React.createElement(MembershipsSettings, { element: React.createElement(General, null) }) }) }),
        React.createElement(Route, { path: routing.memberships.settings.messages, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.settings.messages", element: React.createElement(MembershipsSettings, { element: React.createElement(Messages, null) }) }) }),
        React.createElement(Route, { path: routing.memberships.settings.welcomePost, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.settings.welcomePost", element: React.createElement(MembershipsSettings, { element: React.createElement(WelcomePost, null) }) }) }),
        React.createElement(Route, { path: routing.memberships.settings.mobileApp, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.settings.mobileApp", element: React.createElement(MembershipsSettings, { element: React.createElement(MobileApp, null) }) }) }),
        React.createElement(Route, { path: routing.memberships.settings.upselling, element: React.createElement(PermissionGuardedRoute, { permission: "memberships.settings.upselling", element: React.createElement(MembershipsSettings, { element: React.createElement(Upselling, null) }) }) }),
        React.createElement(Route, { path: routing.blockedTransactions, element: React.createElement(BlockedTransactionsContainer, null) })));
    const profileDropdownChildren = (React.createElement(React.Fragment, null,
        user && React.createElement(UserDetails, { name: user.name || 'My Account', email: user.email }),
        React.createElement(S.AdminModeSwitchWrapper, null,
            React.createElement(AdminModeSwitch, null)),
        React.createElement(Dropdown.Divider, null),
        React.createElement(ShopSwitch, { connectedShops: connectedShops }),
        dashboardFeatureFlags.account?.enabled && hasPermission('accountSettings') && (React.createElement(Dropdown.Item, { label: React.createElement(FeatureFlagDecorator, { ...dashboardFeatureFlags.account, display: "inline", spacing: "small" }, "Account settings"), to: routing.account.self, onClick: () => setIsProfileDropdownOpen(false) })),
        partnerDashboard && (React.createElement(Dropdown.Item, { label: "Partner dashboard", href: partnerDashboard.url, target: "_blank" })),
        React.createElement(Dropdown.Item, { label: "Help center", href: routing.external.helpCenter.self, target: "_blank", analytics: "helpCenter" }),
        React.createElement(Dropdown.Item, { label: "Logout", testid: "logout", onClick: () => logout?.() })));
    return (React.createElement(Routes, null,
        React.createElement(Route, { path: routing.connectIntegration, element: React.createElement(ConnectIntegration, null) }),
        React.createElement(Route, { path: "*", element: React.createElement(ScrollToTop, { location: location.pathname },
                React.createElement(Dashboard, { dashboardLogo: React.createElement(DashboardLogo, { name: shopName, to: routing.home }), toolbarContent: React.createElement(React.Fragment, null,
                        React.createElement(QuickActionsDropdown, null,
                            React.createElement(ToolbarButton, { label: "Create", icon: PlusBoldIcon, iconSize: toolbarIconSize })),
                        React.createElement(MessagesWidget, { iconSize: toolbarIconSize }),
                        React.createElement("div", { style: { marginLeft: media.laptop ? 6 : 8 } },
                            React.createElement(Dashboard.ProfileDropdown, { name: user ? user.name : '', label: avatarLabel, testid: "profile", avatarSize: media.laptop ? 'medium' : 'small', backgroundColor: media.laptop ? Colors.GS200 : Colors.GS100, isOpen: isProfileDropdownOpen, setIsOpen: setIsProfileDropdownOpen }, profileDropdownChildren))), sidebarContent: hasAnyPermission() ? React.createElement(SidebarContent, { navigations: navigations }) : undefined, drawerFooterContent: React.createElement(Dashboard.Menu, null,
                        React.createElement(Dashboard.ProfileDropdown, { name: user ? user.name : '', label: avatarLabel, variant: "menu-item", testid: "profile", backgroundColor: Colors.GS0, isOpen: isProfileDropdownOpen, setIsOpen: setIsProfileDropdownOpen }, profileDropdownChildren)), content: routes, bannersProps: hasAnyPermission() ? bannersProps : [] })) }),
        React.createElement(Route, { path: `${routing.themeEditor.self}*`, element: React.createElement(PermissionGuardedRoute, { permission: "themeEditor", element: React.createElement(ThemeEditor, null) }) }),
        React.createElement(Route, { path: routing.products.preview, element: React.createElement(OfferPreviewContainer, null) })));
};
