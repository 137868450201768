import styled from 'styled-components';
export const BottomPartnerContent = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const BottomGroupContent = styled.div `
  display: flex;
  flex-direction: column;
`;
export const BottomText = styled.div `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
