import { HasuraApiClient } from '@fourthwall/services/lib/HasuraApiClient';
import { useMutation } from '@tanstack/react-query';
import { downloadCsvFile } from '@fourthwall/utils/lib/downloadCsvFile';
import { useCurrentShopQuery } from '../../../../hooks/useCurrentShopQuery';
import { mapTransactionTypeTexts } from './utils';
export const useBillingReport = () => {
    const { currentShopQuery } = useCurrentShopQuery();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (shopId) => {
            const { data } = await HasuraApiClient.getBillingReport(shopId);
            return data;
        },
    });
    const download = () => {
        if (!currentShopQuery.data)
            return;
        mutate(currentShopQuery.data.id, {
            onSuccess: (data) => {
                downloadCsvFile(mapTransactionTypeTexts(data.billing), `${currentShopQuery.data.name}-transactions-report`, [
                    'id',
                    'original_id',
                    'transaction_at',
                    'transaction_type',
                    'sub_type',
                    'order.order.friendly_id',
                    'order.order.status',
                    'profit',
                    'income',
                    'cost',
                    'currency',
                    'products.price',
                    'products.cost',
                    'shipping_cost',
                    'shipping_price',
                    'tax',
                    'donation',
                    'discount',
                    'fulfillment_cost',
                    'payment_fee',
                    'refund_value',
                    'order.sample_credit_used',
                ]);
            },
        });
    };
    return {
        billingReport: {
            download,
            isLoading,
        },
    };
};
