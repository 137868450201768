export const productsFixture = [
    {
        id: 'col_ZByafOisQpyzfkmhI0FDwa',
        name: 'lorem ipsum 1',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 123,
        productsTotal: 654,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FDwb',
        name: 'lorem ipsum 2',
        imageUrl: 'https://imgproxy.fourthwall.com/xbUUXqQYEJP46_l3boFVAcHlQkf0b41pe3LyH5Tuj38/w:640/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_a770864c-ac52-45bc-9e66-4314ef3ef294/300eef27-94c8-4859-9d4a-54948f7ebf09.jpeg',
        supportersTotal: 2135,
        productsTotal: 4753,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FDwc',
        name: 'lorem ipsum 3',
        imageUrl: 'https://imgproxy.fourthwall.com/rcq602Ur4aEt7wz7cchLw0N0J5C4G4Z0r0dZUR-2bvE/w:640/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_a770864c-ac52-45bc-9e66-4314ef3ef294/86b61212-b7ee-40b7-b3e5-18c6c8bd3e30.jpeg',
        supportersTotal: 976,
        productsTotal: 666,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FDwd',
        name: 'name name shorts',
        imageUrl: 'https://imgproxy.fourthwall.com/uWhaHztxWLVzZ3oq5bw9dif-peBIMPsoOhl-rukTZjE/w:640/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_a770864c-ac52-45bc-9e66-4314ef3ef294/da320156-6b40-45e0-8a0e-fbdccb4aa887.jpeg',
        supportersTotal: 422,
        productsTotal: 420,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FDwe',
        name: 'name name text',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 6969,
        productsTotal: 868,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FDwf',
        name: 'name name ppp',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 98,
        productsTotal: 23,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FDwg',
        name: 'rrrr name',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 32,
        productsTotal: 11,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FDwh',
        name: 'zzz21 name3',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 522,
        productsTotal: 298,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FDwi',
        name: 'xxxx name2',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 743,
        productsTotal: 989,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FAza',
        name: 'lorem ipsum 1',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 123,
        productsTotal: 654,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FAzb',
        name: 'lorem ipsum 2',
        imageUrl: 'https://imgproxy.fourthwall.com/xbUUXqQYEJP46_l3boFVAcHlQkf0b41pe3LyH5Tuj38/w:640/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_a770864c-ac52-45bc-9e66-4314ef3ef294/300eef27-94c8-4859-9d4a-54948f7ebf09.jpeg',
        supportersTotal: 2135,
        productsTotal: 4753,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FAzc',
        name: 'lorem ipsum 3',
        imageUrl: 'https://imgproxy.fourthwall.com/rcq602Ur4aEt7wz7cchLw0N0J5C4G4Z0r0dZUR-2bvE/w:640/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_a770864c-ac52-45bc-9e66-4314ef3ef294/86b61212-b7ee-40b7-b3e5-18c6c8bd3e30.jpeg',
        supportersTotal: 976,
        productsTotal: 666,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FAzd',
        name: 'name name shorts',
        imageUrl: 'https://imgproxy.fourthwall.com/uWhaHztxWLVzZ3oq5bw9dif-peBIMPsoOhl-rukTZjE/w:640/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_a770864c-ac52-45bc-9e66-4314ef3ef294/da320156-6b40-45e0-8a0e-fbdccb4aa887.jpeg',
        supportersTotal: 422,
        productsTotal: 420,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FAze',
        name: 'name name text',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 6969,
        productsTotal: 868,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FAzf',
        name: 'name name ppp',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 98,
        productsTotal: 23,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FAzg',
        name: 'rrrr name',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 32,
        productsTotal: 11,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FAzh',
        name: 'zzz21 name3',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 522,
        productsTotal: 298,
    },
    {
        id: 'col_ZByafOisQpyzfkmhI0FAzi',
        name: 'xxxx name2',
        imageUrl: 'https://cdn.staging.fourthwall.com/customization/sh_c6dda40d-fa8b-4249-a13b-0e73dd3bddca/8b9a1984-7942-447c-82d1-24e9f828280c.jpeg',
        supportersTotal: 743,
        productsTotal: 989,
    },
];
