import styled from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  padding: 0 4px 8px;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  ${mediaQueries.tablet} {
    padding: 2px 0;
  }
`;
export const IconWrapper = styled.div `
  width: 20px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ContentWrapper = styled.div `
  display: flex;
  white-space: initial;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.tablet} {
    width: 164px;
  }
`;
export const Title = styled.div `
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
export const Subtitle = styled.div `
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
  color: ${Colors.GS600};

  ${mediaQueries.tablet} {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
`;
