import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TeamMemberWrapper = styled.div `
  flex: 1;
  max-width: calc(100% - 40px);
  overflow: hidden;
`;
