import dayjs from 'dayjs';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, BackButton, Button, ButtonClean, ConfirmModal, DateString, Dropdown, HeadContent, Heading, Loading, Subsection, Tag, ToolbarButton, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import { isWebView } from '@fourthwall/utils';
import { Dashboard, PageLayout, SideSummary } from '@components/common';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { reverse, routing } from '@utils/routing';
import { ChatCreateParams } from '../Messages/types';
import { MemberSkeleton } from './MemberSkeleton';
import { MemberBadge } from './components/MemberBadge/MemberBadge';
import { SurveyHeading } from './components/SurveyHeading/SurveyHeading';
import { SurveyList } from './components/SurveyList/SurveyList';
import { REASONS_MAP } from './consts';
import { useMemberExitSurveysQuery } from './hooks/useMemberExitSurveysQuery';
import { useMemberQuery } from './hooks/useMemberQuery';
import { useRemoveMemberShadowBanMutation } from './hooks/useRemoveMemberShadowBanMutation';
import { useShadowBanMemberMutation } from './hooks/useShadowBanMemberMutation';
import * as S from './styled';
export const Member = () => {
    const modal = useModalContext();
    const media = useMediaQueryContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { memberId } = useParams();
    const { memberQuery } = useMemberQuery([Number(memberId)]);
    const { memberExitSurveysQuery } = useMemberExitSurveysQuery([{ member_id: Number(memberId) }]);
    const { shadowBanMemberMutation } = useShadowBanMemberMutation();
    const { removeMemberShadowBanMutation } = useRemoveMemberShadowBanMutation();
    if (!memberQuery.isSuccess || !memberExitSurveysQuery.isSuccess) {
        return React.createElement(MemberSkeleton, null);
    }
    const member = memberQuery.data;
    const exitSurveys = memberExitSurveysQuery.data.exitSurveys;
    const getName = () => {
        return (React.createElement(S.NameContainer, null,
            React.createElement(S.AvatarContainer, null,
                React.createElement(Avatar, { name: member.nickname, imageUrl: member.avatarUrl })),
            React.createElement(Heading, { type: "h5", weight: "bold" }, member.nickname)));
    };
    const getSubscriptionTierInfo = () => {
        const billingCycle = member.tier?.billingCycle === 'Monthly' ? 'Paid monthly' : 'Paid annually';
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                member.tier?.name,
                " tier"),
            React.createElement("p", null, billingCycle)));
    };
    const getSubscriptionInfo = () => {
        if (member.state.type === 'Active') {
            const monthsCount = dayjs(new Date()).diff(dayjs(member.state.subscribedAt), 'months');
            const tagLabel = monthsCount ? `${monthsCount}-month member` : 'New member';
            return (React.createElement(React.Fragment, null,
                React.createElement(S.TagContainer, null,
                    React.createElement(Tag, { appearance: "success", label: tagLabel })),
                getSubscriptionTierInfo(),
                React.createElement("p", null,
                    "Subscribed ",
                    React.createElement(DateString, { date: member.state.subscribedAt, format: "MMM D, YYYY" }))));
        }
        if (member.state.type === 'Expiring') {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.TagContainer, null,
                    React.createElement(Tag, { appearance: "caution", label: "Expiring member" })),
                getSubscriptionTierInfo(),
                React.createElement("p", null,
                    "Subscribed ",
                    React.createElement(DateString, { date: member.state.subscribedAt, format: "MMM D, YYYY" })),
                React.createElement("p", null,
                    "Expires ",
                    React.createElement(DateString, { date: member.state.expiresAt, format: "MMM D, YYYY" }))));
        }
        if (member.state.type === 'PastDue') {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.TagContainer, null,
                    React.createElement(Tag, { appearance: "caution", label: "Past due member" })),
                getSubscriptionTierInfo(),
                React.createElement("p", null,
                    "Subscribed ",
                    React.createElement(DateString, { date: member.state.subscribedAt, format: "MMM D, YYYY" })),
                React.createElement("p", null,
                    "Payment failed ",
                    React.createElement(DateString, { date: member.state.previousPeriodEnd, format: "MMM D, YYYY" }))));
        }
        if (member.state.type === 'Former') {
            return (React.createElement(React.Fragment, null,
                React.createElement(S.TagContainer, null,
                    React.createElement(Tag, { appearance: "neutral", label: "Former member" })),
                getSubscriptionTierInfo(),
                React.createElement("p", null,
                    "Subscribed ",
                    React.createElement(DateString, { date: member.state.subscribedAt, format: "MMM D, YYYY" })),
                React.createElement("p", null,
                    "Expired ",
                    React.createElement(DateString, { date: member.state.expiredAt, format: "MMM D, YYYY" }))));
        }
        if (member.state.type === 'None') {
            return React.createElement("p", null, "No subscription");
        }
    };
    const renderTitle = () => {
        return (React.createElement(S.TitleContainer, null,
            !media.tablet ? renderSendMessageButton() : null,
            member.shadowBanned && (React.createElement("div", null,
                React.createElement("span", { style: { fontWeight: 600 } }, "Shadowbanned"),
                " (",
                React.createElement(ButtonClean, { withUnderline: true, label: "remove ban", onClick: removeShadowBan }),
                ")"))));
    };
    const getMemberBadge = () => {
        if (member.state.type === 'Active' ||
            member.state.type === 'PastDue' ||
            member.state.type === 'Expiring') {
            const monthsCount = dayjs(new Date()).diff(dayjs(member.state.subscribedAt), 'months');
            const tagLabel = monthsCount ? `${monthsCount}-month member` : 'New member';
            return React.createElement(MemberBadge, { label: tagLabel, emoji: "\uD83D\uDD25" });
        }
        if (member.state.type === 'Former') {
            const monthsCount = dayjs(dayjs(member.state.expiredAt)).diff(dayjs(member.state.subscribedAt), 'months');
            return React.createElement(MemberBadge, { label: `${monthsCount}-month member`, emoji: "\uD83D\uDD25" });
        }
    };
    const getAccountOrigin = () => {
        if (member.accountOrigin === 'FreeAccount') {
            return 'Created through "Create free account" signup flow';
        }
        if (member.accountOrigin === 'Checkout' && member.state.type === 'None') {
            return 'Started a paid memberships checkout but did not complete checkout';
        }
        return 'Created through checkout flow';
    };
    const shadowBanMember = () => {
        modal.open(ConfirmModal, {
            title: `Are you sure you want to shadowban ${member.nickname} (${member.email})?`,
            text: 'Shadowbanned members will not be aware they have been shadowbanned and can still access the members-only area, but comments they leave will only be visible to them and not be visible to you or any other members.',
            confirmLabel: 'Shadowban member',
            onConfirm: () => {
                shadowBanMemberMutation.mutateAsync([{ member_id: member.id }], {
                    onSuccess: () => {
                        memberQuery.refetch();
                    },
                });
            },
        });
    };
    const removeShadowBan = () => {
        modal.open(ConfirmModal, {
            title: `Are you sure you want to remove shadowban from ${member.nickname} (${member.email})?`,
            text: 'All comments of this member will become visible to everyone again.',
            confirmLabel: 'Remove shadowban',
            onConfirm: () => {
                removeMemberShadowBanMutation.mutateAsync([{ member_id: member.id }], {
                    onSuccess: () => {
                        memberQuery.refetch();
                    },
                });
            },
        });
    };
    const renderSendMessageButton = () => {
        if (!dashboardFeatureFlags.membershipsMessages.enabled)
            return null;
        if (member.conversationId) {
            return (React.createElement(Button, { label: "Send message", appearance: "primary", size: "small", to: reverse(routing.memberships.messages.message.self, {
                    messageId: member.conversationId,
                }) }));
        }
        return (React.createElement(Button, { label: "Send message", appearance: "primary", size: "small", to: `${routing.memberships.messages.create}?${ChatCreateParams.memberId}=${member.id}` }));
    };
    const renderToolbar = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement(Dropdown, { content: member.shadowBanned ? (React.createElement(Dropdown.Item, { label: "Remove shadowban", onClick: () => {
                        removeShadowBan();
                    } })) : (React.createElement(Dropdown.Item, { label: "Shadowban", onClick: () => {
                        shadowBanMember();
                    } })), position: "bottom-end" },
                React.createElement(ToolbarButton, { label: "Settings", icon: DotsHorizontalIcon, size: media.tablet ? 'medium' : 'small' })),
            media.tablet && renderSendMessageButton()));
    };
    const renderExitSurveys = () => {
        if (exitSurveys.length === 0)
            return null;
        return (React.createElement(Subsection, { title: "Exit survey" },
            React.createElement(SurveyList, null, exitSurveys.map((survey) => (React.createElement("div", { key: survey.id },
                React.createElement(SurveyHeading, null,
                    "Survey filled on ",
                    React.createElement(DateString, { date: survey.updatedAt, format: "h:mm A, MMM D, YYYY" })),
                React.createElement("ul", { style: { listStyle: 'disc', paddingLeft: 20 } }, survey.reasons.map((reason) => (React.createElement("li", { key: reason }, REASONS_MAP[reason] || `Other: ${reason}`))))))))));
    };
    return (React.createElement(Loading, { isLoading: shadowBanMemberMutation.isLoading ||
            removeMemberShadowBanMutation.isLoading ||
            memberQuery.isFetching },
        React.createElement(HeadContent, { title: member.nickname }),
        React.createElement(PageLayout, { leftContent: React.createElement(React.Fragment, null,
                React.createElement(Subsection, { title: "Account creation" },
                    React.createElement("ul", { style: { listStyle: 'disc', paddingLeft: 20 } },
                        React.createElement("li", null,
                            "Joined ",
                            React.createElement(DateString, { date: member.joinedAt, format: "h:mm A, MMM D, YYYY" })),
                        React.createElement("li", null, getAccountOrigin()))),
                renderExitSurveys()), rightContent: React.createElement(SideSummary, null,
                React.createElement(SideSummary.Item, { title: "Supporter details" },
                    React.createElement("p", null, member.nickname),
                    React.createElement("p", null, member.email)),
                React.createElement(SideSummary.Item, { title: "Membership" }, getSubscriptionInfo())) },
            React.createElement(Dashboard.Section, { name: getName(), topContent: isWebView() ? undefined : (React.createElement(BackButton, { label: "Members", to: {
                        pathname: routing.memberships.members.self,
                        search: location.search,
                    } })), titleSpacing: "medium", bottomMargin: "large", title: renderTitle(), toolbarContent: renderToolbar(), bottomContent: getMemberBadge() }))));
};
