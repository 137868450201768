import { Toasts } from '@fourthwall/components';
import { useDashboardFeatureFlags } from '../../../../components/utils/DashboardFeatureFlags';
import { useCurrentShopQuery } from '../../hooks/useCurrentShopQuery';
import { useShopStatusMutation } from './useShopStatusMutation';
export const useScheduler = () => {
    const { shopStatusMutation } = useShopStatusMutation();
    const { currentShopQuery } = useCurrentShopQuery();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const getSchedulerProps = () => {
        if (!dashboardFeatureFlags.homeScheduler.enabled)
            return;
        if (!currentShopQuery.data)
            return;
        if (currentShopQuery.data.status === 'LIVE')
            return;
        if (currentShopQuery.data.settings.schedule)
            return;
        return {
            previewUrl: `${currentShopQuery.data.internalBaseUri}/theme_editor`,
            domain: currentShopQuery.data?.primaryDomain,
            submitting: shopStatusMutation.isLoading,
            onSchedule: (values) => {
                if (!currentShopQuery.data)
                    return;
                Toasts.notify('Updating site settings...', { type: 'info' });
                shopStatusMutation.mutate({
                    shopId: currentShopQuery.data.id,
                    payload: {
                        status: values.addPassword ? 'PASSWORD_PROTECTED' : 'COMING_SOON',
                        schedule: {
                            scheduleDate: values.liveFrom,
                            countdownEnabled: false,
                        },
                        password: values.addPassword ? values.password : '',
                    },
                }, {
                    onSuccess: () => {
                        Toasts.notify('Your site is now scheduled.', { type: 'success' });
                        currentShopQuery.refetch();
                    },
                    onSettled: () => {
                        Toasts.dismiss('info');
                    },
                });
            },
            onGoLive: () => {
                if (!currentShopQuery.data)
                    return;
                Toasts.notify('Updating site settings...', { type: 'info' });
                shopStatusMutation.mutate({
                    shopId: currentShopQuery.data.id,
                    payload: { status: 'LIVE' },
                }, {
                    onSuccess: () => {
                        Toasts.notify('Your site went live.', { type: 'success' });
                        currentShopQuery.refetch();
                    },
                    onSettled: () => {
                        Toasts.dismiss('info');
                    },
                });
            },
        };
    };
    return {
        scheduler: {
            schedulerProps: getSchedulerProps(),
        },
    };
};
