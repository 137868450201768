import React from 'react';
import { useDashboardFeatureFlags } from '../../../../../../../components/utils/DashboardFeatureFlags';
import { useTiers } from '../../../Perks/hooks/useTiers';
import { useFilterMembers } from '../../hooks/useFilterMembers';
import { MembersFilterView } from './MembersFilterView';
import { FILTER_BILLING_CYCLE_LABELS, FILTER_STATE_LABELS, FILTER_TYPE_LABELS } from './consts';
import { mapLabelsToCheckboxOptions } from './utils';
export const MembersFilterContainer = () => {
    const { filtersParams, setFiltersParams, resetFiltersParams, hasFilterParams } = useFilterMembers();
    const { tiers } = useTiers();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    if (!tiers) {
        return null;
    }
    const getFilterStatusLabels = () => {
        const labels = {};
        tiers?.forEach((tier) => {
            labels[tier.id] = tier.title;
        });
        return labels;
    };
    const handleSubmit = (values) => {
        setFiltersParams({
            type: values.type.length ? values.type.join(',') : undefined,
            tier: values.tier.length ? values.tier.join(',') : undefined,
            billingCycle: values.billingCycle.length ? values.billingCycle.join(',') : undefined,
            state: values.state.length ? values.state.join(',') : undefined,
        });
    };
    const getInitialValues = () => ({
        type: filtersParams.type ? filtersParams.type.split(',') : [],
        tier: filtersParams.tier ? filtersParams.tier.split(',') : [],
        billingCycle: filtersParams.billingCycle ? filtersParams.billingCycle.split(',') : [],
        state: filtersParams.state ? filtersParams.state.split(',') : [],
    });
    return (React.createElement(MembersFilterView, { initialValues: getInitialValues(), types: dashboardFeatureFlags.membershipsFreeAccounts.enabled
            ? mapLabelsToCheckboxOptions(FILTER_TYPE_LABELS)
            : undefined, tiers: mapLabelsToCheckboxOptions(getFilterStatusLabels()), billingCycles: mapLabelsToCheckboxOptions(FILTER_BILLING_CYCLE_LABELS), states: mapLabelsToCheckboxOptions(FILTER_STATE_LABELS), filterActive: hasFilterParams, onSubmit: handleSubmit, onReset: resetFiltersParams }));
};
