import React from 'react';
import * as S from './VideoShare.styled';
export const VideoShare = ({ thumbnail, message, url }) => (React.createElement(S.Container, null,
    React.createElement(S.Image, null,
        React.createElement(S.Play, null,
            React.createElement(S.PlayIcon, null)),
        thumbnail),
    React.createElement(S.Content, null,
        React.createElement(S.Message, null, message),
        React.createElement(S.Url, null, url))));
