import React from 'react';
import { useFilterContributions } from '../../../../hooks/useFilterContributions';
import { STATUSES_LABELS } from './consts';
import { mapLabelsToStatuses } from './utils';
import { OrdersFilterView } from './OrderFilterView';
export const OrdersFilterContainer = () => {
    const { filtersParams, setFiltersParams, resetFiltersParams, hasFilterParams } = useFilterContributions();
    const handleSubmit = (values) => {
        setFiltersParams({
            status: values.status.length ? values.status.join(',') : undefined,
        });
    };
    const getInitialValues = () => ({
        status: filtersParams.status ? filtersParams.status.split(',') : [],
    });
    return (React.createElement(OrdersFilterView, { initialValues: getInitialValues(), statuses: mapLabelsToStatuses(STATUSES_LABELS), filterActive: hasFilterParams, onSubmit: handleSubmit, onReset: resetFiltersParams }));
};
