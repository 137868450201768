import styled from 'styled-components';
import { Colors } from '../../colors';
export const Item = styled.div `
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const ItemLabel = styled.div `
  margin-bottom: 4px;

  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GS600};
`;
export const ItemContent = styled.div `
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS1000};
`;
