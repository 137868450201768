import { ApiBase } from '../ApiBase';
class HarmonizedCodeApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.searchHarmonizedCodes = (params) => {
            return this.get('/harmonized-codes/search', params);
        };
    }
}
export const HarmonizedCodeApiClient = new HarmonizedCodeApiClientModel();
