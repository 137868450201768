import { usePostQuery } from './usePostQuery';
export const usePost = (id) => {
    const { postQuery } = usePostQuery([Number(id)], {
        refetchInterval: (data) => (data?.statuses.resources === 'Processing' ? 2000 : false),
    });
    return {
        postQuery,
        post: postQuery.data || null,
    };
};
