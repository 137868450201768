import { CreatorMessagesApiClient } from '@fourthwall/services/lib/CreatorMessagesApiClient';
import { useMutation } from '@tanstack/react-query';
export const useSendMessageMutation = () => {
    const sendMessageMutation = useMutation({
        mutationKey: ['useSendMessageMutation'],
        mutationFn: async (payload) => {
            const { data } = await CreatorMessagesApiClient.createCreatorMessage(payload);
            return data;
        },
    });
    return {
        sendMessage: sendMessageMutation.mutate,
        sendMessageMutation,
    };
};
