import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { CheckboxIcon } from './CheckboxIcon';
import * as S from './styled';
export const CheckboxIndeterminateComponent = forwardRef(({ indeterminate, ...rest }, ref) => {
    const inputRef = useRef(null);
    const [isIndeterminate, setIsIndeterminate] = React.useState(false);
    useImperativeHandle(ref, () => ({
        inputRef: inputRef.current,
    }));
    useEffect(() => {
        if (inputRef.current && typeof indeterminate === 'boolean') {
            inputRef.current.indeterminate = !rest.checked && indeterminate;
            setIsIndeterminate(!rest.checked && indeterminate);
        }
    }, [inputRef, indeterminate, rest.checked]);
    return (React.createElement(S.Container, { "$disabled": rest.disabled },
        React.createElement("div", null,
            React.createElement(S.Input
            // @ts-ignore
            , { 
                // @ts-ignore
                ref: inputRef, type: "checkbox", ...rest }),
            React.createElement(CheckboxIcon, { size: "small", indeterminate: isIndeterminate }))));
});
export const CheckboxIndeterminate = React.memo(CheckboxIndeterminateComponent);
