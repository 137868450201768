import { Toasts } from '@fourthwall/components';
import cloneDeep from 'lodash-es/cloneDeep';
import { reverse } from 'named-urls';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { routing } from '@utils/routing';
import { useCancellationOffers } from '../hooks/useCancellationOffers';
import { useCalculateItemsReplacementsQuery } from './hooks/useCalculateItemsReplacementsQuery';
import { useItemsReplacementMutation } from './hooks/useItemsReplacementMutation';
import { OrderEditView } from './OrderEditView';
import { flattenOffers, getReplacementsPayload } from './utils';
import { useOrderIdParam } from '../hooks';
import { useOrderQuery } from '../queries';
export const OrderEditContainer = () => {
    const navigate = useNavigate();
    const { orderId } = useOrderIdParam();
    const { orderQuery } = useOrderQuery([orderId]);
    const [editableOffers, setEditableOffers] = useState();
    const [shouldRestock, setShouldRestock] = useState(true);
    const replacements = getReplacementsPayload(editableOffers);
    const { cancellableOffers, nonCancellableOffers, partiallyCancellable, orderCancellationQuery, invalidateOrderCancellationQuery, } = useCancellationOffers(orderId, orderQuery.data?.offers);
    const { calculateItemsReplacementsQuery } = useCalculateItemsReplacementsQuery([
        orderId,
        {
            replacements,
            restockReplacedItems: shouldRestock,
            skipStatusVerification: false,
            skipThirdPartyOrdersSync: false,
        },
    ], { retry: false, keepPreviousData: true });
    const { itemsReplacementMutation } = useItemsReplacementMutation(orderId);
    const calculateItemsReplacements = calculateItemsReplacementsQuery.data;
    useEffect(() => {
        if (cancellableOffers) {
            setEditableOffers(flattenOffers(cancellableOffers).map((offer) => ({
                id: uuidv4(),
                original: cloneDeep(offer),
                current: cloneDeep(offer),
            })));
        }
    }, [cancellableOffers]);
    const handleVariantEdit = (editableOffer) => {
        setEditableOffers((currentEditableOffers) => currentEditableOffers?.map((currentEditableOffer) => {
            if (editableOffer.id === currentEditableOffer.id) {
                return editableOffer;
            }
            return currentEditableOffer;
        }));
    };
    const handleOrderEdit = (reason) => {
        itemsReplacementMutation.mutate({
            replacements: getReplacementsPayload(editableOffers),
            restockReplacedItems: shouldRestock,
            reason,
            skipStatusVerification: false,
            skipThirdPartyOrdersSync: false,
        }, {
            onSuccess: () => {
                invalidateOrderCancellationQuery();
                navigate(reverse(routing.contributions.orders.order.self, { orderId }));
                Toasts.notify('Order has been updated successfully.', { type: 'info' });
            },
        });
    };
    return (React.createElement(OrderEditView, { orderId: orderId, friendlyId: orderQuery.data?.friendlyId, editableOffers: editableOffers, nonEditableOffers: nonCancellableOffers, replacements: replacements, donation: orderQuery.data?.donation || undefined, promotion: orderQuery.data?.promotion, subtotal: calculateItemsReplacements?.afterReplacement.items, shipping: calculateItemsReplacements?.afterReplacement.shipping, taxes: calculateItemsReplacements?.afterReplacement.tax, discount: calculateItemsReplacements?.afterReplacement.discount, total: calculateItemsReplacements?.afterReplacement.total, paidTotal: calculateItemsReplacements?.beforeReplacement.total, shouldRestock: shouldRestock, isEditButtonDisabled: !partiallyCancellable ||
            !editableOffers?.length ||
            calculateItemsReplacementsQuery.isFetching ||
            orderCancellationQuery.isFetching ||
            calculateItemsReplacementsQuery.isError ||
            orderCancellationQuery.isError ||
            itemsReplacementMutation.isError, isLoading: orderQuery.isLoading ||
            calculateItemsReplacementsQuery.isLoading ||
            orderCancellationQuery.isLoading ||
            itemsReplacementMutation.isLoading, onVariantEdit: handleVariantEdit, onOrderEdit: handleOrderEdit, onRestockChange: setShouldRestock }));
};
