import { GiveawayContributionApiClient } from '@fourthwall/services/lib/GiveawayContributionApiClient';
import { createQueryHook } from '@fourthwall/utils/lib/queries';
import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
export const useGiveawayContributionQuery = createQueryHook(GiveawayContributionApiClient.getGiveawayContribution, {
    queryKey: ['giveawayContribution'],
    select: (data) => {
        data.offer.productName = decodeHtmlEntities(data.offer.productName);
        return data;
    },
});
