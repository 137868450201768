import Yup from '@fourthwall/utils/lib/yup';
export const validationSchema = Yup.object().shape({
    name: Yup.string().required().noHTML(),
    isTimeLimited: Yup.boolean(),
    available: Yup.boolean(),
    startDate: Yup.date().when(['isTimeLimited', 'endDate'], (isTimeLimited, endDate) => {
        return isTimeLimited && !endDate ? Yup.date().required() : Yup.date();
    }),
    endDate: Yup.date(),
});
