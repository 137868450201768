import React, { useEffect } from 'react';
import * as S from './styled';
const isElementVisible = (el, offset) => {
    if (!el)
        return false;
    const rect = el.getBoundingClientRect();
    return (rect.top >= offset &&
        rect.left >= offset &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - offset &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) - offset);
};
export const Tab = ({ name, direction = 'horizontal', size = 'medium', style, selected, variant = 'default', noWrap, bold, scrollIntoView = false, scrollIntoViewOffset = 0, onClick, ...other }) => {
    const ref = React.useRef(null);
    useEffect(() => {
        if (scrollIntoView && selected && !isElementVisible(ref.current, scrollIntoViewOffset)) {
            // setTimeout is needed to trigger scroll into view function, it does not work without it
            setTimeout(() => {
                ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }, 0);
        }
    }, [scrollIntoView, scrollIntoViewOffset, selected]);
    return (React.createElement(S.TabContainer, { "$direction": direction, "$marginSize": size, ...other },
        React.createElement(S.Tab, { ref: ref, type: "button", isActive: selected, "$direction": direction, size: size, variant: variant, style: style, onClick: onClick }, name)));
};
Tab.tabsRole = 'Tab';
