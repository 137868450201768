import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
`;
export const FormContainer = styled.div `
  background: ${Colors.GS0};
  padding: 20px;
  border: 1px solid ${Colors.GS400};

  ${mediaQueries.tablet} {
    width: 375px;
  }
`;
export const ButtonsContainer = styled.div `
  margin-top: 20px;
`;
