import { Banner } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const ErrorBanner = ({ enabled = true, appearance = 'danger', children, }) => {
    if (!enabled) {
        return null;
    }
    return (React.createElement(S.Container, null,
        React.createElement(Banner, { appearance: appearance }, children)));
};
