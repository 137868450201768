import React from 'react';
import { Arrows, BaseContent, useSlider } from '@fourthwall/components';
import { ProductColorVariantProvider } from '@fourthwall/module-product';
import { TabPills } from '@components/common';
import { routing } from '@utils/routing';
import { HomeProductsProductBox } from '../HomeProductsProductBox';
import * as S from './styled';
import { getProductHref } from './utils';
export const HomeProductsSlider = ({ categories, products, onProductClick, }) => {
    const slider = useSlider();
    const renderProducts = () => {
        if (!products.length) {
            return (React.createElement(S.EmptyState, null,
                React.createElement(BaseContent, null,
                    React.createElement("p", null, "You don\u2019t have any favorite products added yet"),
                    React.createElement("p", null,
                        React.createElement("a", { href: routing.external.productCatalog, target: "_blank", rel: "noreferrer" }, "Browse product catalog")))));
        }
        return (React.createElement(S.Slider, { ...slider.getSliderProps() }, products.map((product) => {
            const href = getProductHref(product.slug);
            return (React.createElement(S.Slide, { key: product.id, href: href, target: "_blank", onClick: () => onProductClick(product), ...slider.getSlideProps() },
                React.createElement(ProductColorVariantProvider, { colorVariants: product.variants.matched },
                    React.createElement(HomeProductsProductBox, { product: product, href: href }))));
        })));
    };
    return (React.createElement("div", { ...slider.getContainerProps() },
        React.createElement(S.Header, null,
            categories && (React.createElement("div", null,
                React.createElement(TabPills, { appearance: "outline", ...categories }))),
            !!products.length && (React.createElement(S.Nav, null,
                React.createElement(Arrows, { size: "small" },
                    React.createElement(Arrows.Button, { direction: "prev", ...slider.getPrevButtonProps() }),
                    React.createElement(Arrows.Button, { direction: "next", ...slider.getNextButtonProps() }))))),
        renderProducts(),
        !!products.length && (React.createElement(S.Footer, null,
            React.createElement(BaseContent, { appearance: "secondary" },
                React.createElement("a", { href: routing.external.productCatalog, target: "_blank", rel: "noreferrer" }, "See more products"))))));
};
