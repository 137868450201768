export const emojis = {
    camera: {
        // 📷
        name: 'camera',
        html: '&#128247;',
    },
    chartIncreasing: {
        // 📈
        name: 'chart increasing',
        html: '&#128200;',
    },
    mobilePhone: {
        // 📱
        name: 'mobile phone',
        html: '&#128241;',
    },
    moneyWithWings: {
        // 💸
        name: 'money with wings',
        html: '&#128184;',
    },
    muscle: {
        // 💪
        name: 'flexed biceps',
        html: '&#128170;',
    },
    sparkles: {
        // ✨
        name: 'sparkles',
        html: '&#10024;',
    },
    speachBalloon: {
        // 💬
        name: 'speech balloon',
        html: '&#128172;',
    },
    tada: {
        // 🎉
        name: 'tada',
        html: '&#127881;',
    },
    trophy: {
        // 🏆
        name: 'trophy',
        html: '&#127942;',
    },
    tshirt: {
        // 👕
        name: 't-shirt',
        html: '&#128085;',
    },
    warning: {
        // ⚠️
        name: 'warning',
        html: '&#9888;&#65039;',
    },
    wave: {
        // 👋
        name: 'waving hand',
        html: '&#128075;',
    },
    wrappedGift: {
        // 🎁
        name: 'wrapped gift',
        html: '&#127873;',
    },
};
