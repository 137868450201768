import styled from 'styled-components';
import { Colors } from '../../colors';
const containerSizeWidths = {
    extraSmall: 48,
    small: 55,
    medium: 66,
    big: 88,
    large: 112,
};
export const Container = styled.div `
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  width: ${({ size }) => containerSizeWidths[size]}px;
`;
export const Image = styled.img `
  width: 100%;
  height: 100%;

  object-fit: contain;
`;
const imagePlaceholderSizeFontSizes = {
    extraSmall: 16,
    small: 16,
    medium: 16,
    big: 24,
    large: 32,
};
export const ImagePlaceholder = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: ${({ size }) => imagePlaceholderSizeFontSizes[size]}px;

  font-weight: 600;
  text-transform: uppercase;
  line-height: 150%;

  color: ${Colors.GS550};
  background: ${Colors.GS300};
`;
