import { List, Table, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { TAB_TITLES } from '../../consts';
import { ChargesHistoryListItem } from './components/ChargesHistoryListItem';
import { getHeaders } from './utils/getHeaders';
export const ChargesHistoryData = ({ data, isLoading }) => {
    const location = useLocation();
    const media = useMediaQueryContext();
    const emptyMessage = "You haven't received any charges yet.";
    const locationState = {
        from: { label: TAB_TITLES.charges, location },
    };
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, emptyComponent: emptyMessage, renderItem: (chargesHistoryItem) => (React.createElement(ChargesHistoryListItem, { locationState: locationState, chargesHistoryItem: chargesHistoryItem })) }));
    }
    return (React.createElement(Table, { headers: getHeaders(locationState), data: data, emptyMessage: emptyMessage, isLoading: isLoading, minWidth: 0 }));
};
