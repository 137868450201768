import { useEffect, useState } from 'react';
import { useRecipientsQuery } from '../../../hooks/queries/useRecipientsQuery';
import { numberFormatter } from '../../../utils/numberFormatter/numberFormatter';
import { useSendMessageMutation } from './queries/useSendMessageMutation';
export const useCreatorMessagesSend = () => {
    const { recipientsData, recipientsQuery } = useRecipientsQuery();
    const { recipientsData: last90DaysRecipientsData, recipientsQuery: last90DaysRecipientsQuery } = useRecipientsQuery({ type: 'TIME_BASED_SUPPORTERS', daysTillNow: 90 });
    const { sendMessage, sendMessageMutation } = useSendMessageMutation();
    const [finalSupportersCount, setFinalSupportersCount] = useState('');
    const recipientsCount = recipientsData?.count ? numberFormatter(recipientsData.count) : '';
    const last90DaysRecipientsCount = last90DaysRecipientsData?.count
        ? numberFormatter(last90DaysRecipientsData.count)
        : '';
    useEffect(() => {
        /**
         * Set the Submit Button's initial value to match the total number of recipients
         */
        if (recipientsCount) {
            setFinalSupportersCount(recipientsCount);
        }
    }, [recipientsCount]);
    const handleSetFinalSupportersCount = (type, recipients) => {
        switch (type) {
            case 'ALL': {
                setFinalSupportersCount(recipientsCount);
                break;
            }
            case 'TIME_BASED_SUPPORTERS': {
                setFinalSupportersCount(last90DaysRecipientsCount);
                break;
            }
            // case 'SUPPORTERS'
            case 'COLLECTIONS':
            case 'PRODUCTS': {
                if (recipients) {
                    setFinalSupportersCount(numberFormatter(recipients));
                    break;
                }
                setFinalSupportersCount('');
                break;
            }
            default: {
                setFinalSupportersCount('');
            }
        }
    };
    const mapFormValueToSendMessageDto = (formValues) => {
        return {
            content: {
                subject: formValues.subject,
                body: formValues.message,
                attachments: formValues.attachment ? [formValues.attachment] : [],
            },
            recipientFilter: formValues.sendTo,
        };
    };
    const handleSendMessage = (formValues, options) => {
        return sendMessage(mapFormValueToSendMessageDto(formValues), options);
    };
    return {
        sendMessagesViewModel: {
            recipientsCount: finalSupportersCount,
            allSupportersRadioButtonLabel: recipientsCount
                ? `All supporters (${recipientsCount})`
                : 'All supporters',
            last90DaysSupportersRadioButtonLabel: last90DaysRecipientsCount
                ? `Supporters from the last 90 days (${last90DaysRecipientsCount})`
                : 'Supporters from the last 90 days',
            submitFormButtonLabel: finalSupportersCount
                ? `Send to (${finalSupportersCount}) supporters`
                : 'Send to supporters',
            loading: recipientsQuery.isLoading ||
                sendMessageMutation.isLoading ||
                last90DaysRecipientsQuery.isLoading,
            submitting: sendMessageMutation.isLoading,
            sendMessageSuccess: sendMessageMutation.isSuccess,
            sendMessageError: sendMessageMutation.error,
        },
        sendMessage: handleSendMessage,
        setFinalSupportersCount: handleSetFinalSupportersCount,
    };
};
