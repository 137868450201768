import styled from 'styled-components';
export const Winners = styled.div `
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
`;
export const Buttons = styled.div `
  margin-top: 24px;
`;
export const TooltipContent = styled.div `
  text-align: center;
`;
