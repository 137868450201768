import { Cellphone } from '@components/common/Cellphone';
import { ButtonClean, ModalBox } from '@fourthwall/components';
import { MOBILE_APP_ANDROID_LINK, MOBILE_APP_IOS_LINK } from '@fourthwall/utils/lib/links';
import React from 'react';
import * as S from './ThankYousModal.styled';
export const ThankYousModal = ({ close }) => {
    return (React.createElement(ModalBox, { onClose: close, size: "large", withoutPadding: true },
        React.createElement(S.Container, null,
            React.createElement(S.Left, null,
                React.createElement(S.Title, null, "Download our app to send thank yous"),
                React.createElement(S.Content, null, "Currently we support sending thank yous only from our mobile apps. We're hoping to add desktop support in the near future!"),
                React.createElement(S.DownloadButtons, null,
                    React.createElement(S.DownloadLink, { href: MOBILE_APP_IOS_LINK, target: "_blank", "aria-label": "Download Application from App Store" },
                        React.createElement(S.AppDownloadAppStore, null)),
                    React.createElement(S.DownloadLink, { href: MOBILE_APP_ANDROID_LINK, target: "_blank", "aria-label": "Download Application from Google Play" },
                        React.createElement(S.AppDownloadGooglePlay, null))),
                React.createElement(S.Footer, null,
                    "Any questions or issues?",
                    React.createElement("br", null),
                    "Contact ",
                    React.createElement(ButtonClean, { label: "hello@fourthwall.com", href: "mailto:hello@fourthwall.com" }))),
            React.createElement(S.Right, null,
                React.createElement(Cellphone, { withSound: false, phoneWidth: 270 })))));
};
