import React from 'react';
import { ArrowShortRightIcon } from '@fourthwall/icons';
import { Icon } from '../Icon';
import * as S from './styled';
export const ActionBar = ({ title, actionLabel, icon, onClick }) => {
    return (React.createElement(S.Container, { onClick: onClick, "data-testid": "ActionBar" },
        React.createElement(S.Body, null,
            icon ? React.createElement(S.IconContainer, null, icon) : null,
            title),
        React.createElement(S.ActionLabelContainer, null,
            actionLabel,
            React.createElement(S.ActionIconContainer, null,
                React.createElement(Icon, { component: ArrowShortRightIcon, height: 16 })))));
};
