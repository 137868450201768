import { Button, Icon } from '@fourthwall/components';
import React from 'react';
import { getIcon } from '../../utils/getIcon';
import * as S from './styled';
import { getDescription } from './utils/getDescription';
export const SectionItem = ({ section, onButtonClick }) => {
    const { sectionType, name, additionalInfo } = section;
    return (React.createElement(S.Item, null,
        React.createElement(S.Container, null,
            React.createElement(S.Wrapper, null,
                React.createElement(Icon, { component: getIcon(sectionType), height: 24 })),
            React.createElement(S.Text, null,
                React.createElement("span", null, name),
                React.createElement(S.Description, null, additionalInfo?.description || getDescription(sectionType)))),
        React.createElement(S.Wrapper, null,
            React.createElement(Button, { label: "Add", size: "small", onClick: onButtonClick }))));
};
