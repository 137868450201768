import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  min-height: 300px;

  h1 {
    margin: 25px 0;
    font-weight: 700;
    padding-bottom: 5px;
    border-bottom: 1px solid ${Colors.GS400};
  }

  h2 {
    margin: 20px 0;
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: 1px solid ${Colors.GS400};
  }

  h3 {
    margin: 5px 0;
    font-weight: 600;
  }

  ul {
    list-style: inside;
  }
`;
