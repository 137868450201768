import { TimeIcon } from '@fourthwall/icons';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DayPickerRangeController, DayPickerSingleDateController, isInclusivelyAfterDay, } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { ButtonClean } from '../../common/ButtonClean';
import { Input } from '../../inputs/Input/Input';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
import * as S from './Calendar.styled';
import { createLabel, getFormattedTime, getTimeProposal } from './utils';
export { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
export const isPastDate = (day) => {
    return !isInclusivelyAfterDay(day, moment());
};
export const Calendar = ({ type = 'range', date = '', startDate = '', endDate = '', focusedInput = 'startDate', startTimeLabel = 'Start time', endTimeLabel = 'End time', clearButtonLabel = 'Clear scheduled date', disableDates, showTimeInputs = true, scrollableContent = false, onFocusChange, onDateChange, onDatesChange, onClear, }) => {
    const parsedDate = date ? moment(date) : null;
    const parsedStartDate = startDate ? moment(startDate) : null;
    const parsedEndDate = endDate ? moment(endDate) : null;
    const [startInputValue, setStartInputValue] = useState(getFormattedTime(parsedStartDate));
    const [endInputValue, setEndInputValue] = useState(getFormattedTime(parsedEndDate));
    const [dateInputValue, setDateInputValue] = useState(getFormattedTime(parsedDate));
    const media = useMediaQueryContext();
    const startRef = useRef();
    const endRef = useRef();
    const dateRef = useRef();
    const parsedDateRef = useRef();
    const parsedStartDateRef = useRef();
    const parsedEndDateRef = useRef();
    useEffect(() => {
        return () => {
            if (type === 'range') {
                setTimes();
            }
            if (type === 'single') {
                setTime();
            }
        };
    }, []);
    useEffect(() => {
        startRef.current = startInputValue;
        endRef.current = endInputValue;
        dateRef.current = dateInputValue;
        parsedStartDateRef.current = startDate;
        parsedEndDateRef.current = endDate;
        parsedDateRef.current = date;
    }, [startInputValue, endInputValue, dateInputValue, startDate, endDate, date]);
    useEffect(() => {
        setDateInputValue(getFormattedTime(parsedDate));
    }, [date]);
    useEffect(() => {
        setStartInputValue(getFormattedTime(parsedStartDate));
    }, [startDate]);
    useEffect(() => {
        setEndInputValue(getFormattedTime(parsedEndDate));
    }, [endDate]);
    const setTime = () => {
        if (!onDateChange)
            return;
        const timeProposal = getTimeProposal(dateRef.current);
        if (timeProposal) {
            return onDateChange(moment(parsedDateRef.current || Date())
                .set({ hour: timeProposal.getHours(), minute: timeProposal.getMinutes() })
                .toISOString());
        }
        return setDateInputValue(getFormattedTime(moment(parsedDateRef.current)));
    };
    const setTimes = () => {
        if (!onDatesChange)
            return;
        const startTimeProposal = getTimeProposal(startRef.current);
        const endTimeProposal = getTimeProposal(endRef.current);
        if (startTimeProposal && endTimeProposal) {
            return onDatesChange({
                startDate: moment(parsedStartDateRef.current || Date())
                    .set({ hour: startTimeProposal.getHours(), minute: startTimeProposal.getMinutes() })
                    .toISOString(),
                endDate: moment(parsedEndDateRef.current || Date())
                    .set({ hour: endTimeProposal.getHours(), minute: endTimeProposal.getMinutes() })
                    .toISOString(),
            });
        }
        setStartInputValue(getFormattedTime(moment(parsedStartDateRef.current)));
        setEndInputValue(getFormattedTime(moment(parsedEndDateRef.current)));
    };
    const handleFocusChange = (focusInputShape) => {
        if (onFocusChange) {
            onFocusChange(focusInputShape);
        }
    };
    const handleDateChange = (newDate) => {
        if (!onDateChange)
            return null;
        return onDateChange(moment(newDate)
            .set({ hour: parsedDate?.hours(), minute: parsedDate?.minutes() })
            .toISOString());
    };
    const handleDatesChange = (newDates) => {
        if (!onDatesChange)
            return null;
        return onDatesChange({
            startDate: newDates.startDate
                ? moment(newDates.startDate)
                    .set({ hour: parsedStartDate?.hours(), minute: parsedStartDate?.minutes() })
                    .toISOString()
                : startDate,
            endDate: newDates.endDate
                ? moment(newDates.endDate)
                    .set({ hour: parsedEndDate?.hours(), minute: parsedEndDate?.minutes() })
                    .toISOString()
                : endDate,
        });
    };
    const renderCalendar = () => {
        if (type === 'single') {
            return (React.createElement(DayPickerSingleDateController, { date: parsedDate, daySize: 39, horizontalMonthPadding: 8, hideKeyboardShortcutsPanel: true, noBorder: true, focused: true, 
                // NOTE: This prop is required but we are not using it
                initialVisibleMonth: null, isOutsideRange: disableDates, onFocusChange: () => { }, onDateChange: handleDateChange }));
        }
        return (React.createElement(DayPickerRangeController, { startDate: parsedStartDate, endDate: parsedEndDate, numberOfMonths: media.tablet ? 2 : 1, minimumNights: 0, daySize: 39, horizontalMonthPadding: 8, focusedInput: focusedInput, noBorder: true, hideKeyboardShortcutsPanel: true, initialVisibleMonth: null, isOutsideRange: disableDates, onFocusChange: handleFocusChange, onDatesChange: handleDatesChange }));
    };
    const renderTimeInputs = () => {
        if (!showTimeInputs)
            return null;
        if (type === 'range') {
            return (React.createElement(S.InputsContainer, null,
                React.createElement(S.InputContainer, null,
                    React.createElement(Input, { label: createLabel(startTimeLabel), value: startInputValue, disabled: !startInputValue, leftIcon: TimeIcon, onValueChange: setStartInputValue, onBlur: setTimes })),
                React.createElement(S.InputContainer, null,
                    React.createElement(Input, { label: createLabel(endTimeLabel), value: endInputValue, disabled: !endInputValue, leftIcon: TimeIcon, onValueChange: setEndInputValue, onBlur: setTimes }))));
        }
        return (React.createElement(S.InputsContainer, null,
            React.createElement(S.InputContainer, null,
                React.createElement(Input, { label: createLabel(startTimeLabel), value: dateInputValue, disabled: !dateInputValue, leftIcon: TimeIcon, onValueChange: setDateInputValue, onBlur: setTime }))));
    };
    const renderClearButton = () => {
        if (!date || !onClear)
            return null;
        return (React.createElement(S.ClearButtonContainer, null,
            React.createElement(ButtonClean, { label: clearButtonLabel, appearance: "secondary", onClick: onClear })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(S.StyleOverride, null),
        React.createElement(S.Container, { scrollableContent: scrollableContent, "data-testid": "Calendar" },
            React.createElement(S.CalendarContainer, null, renderCalendar()),
            renderTimeInputs(),
            renderClearButton())));
};
