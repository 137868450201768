import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Priority = styled.div `
  font-size: 12px;
  line-height: 150%;

  ${mediaQueries.tablet} {
    font-size: 14px;
    line-height: 150%;
    margin-left: 12px;
  }
`;
