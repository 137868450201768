import { SECTION_NAMES } from '@constants/sectionNames';
import { appsSignets, appsThumbnails } from '@modules/Dashboard/Apps';
import { routing } from '@utils/routing';
export const APPS_MAP = {
    BEEHIIV: {},
    FWDISCORD: {},
    INSTAGRAM_CHECKOUT: {
        flag: 'integrationMeta',
        name: SECTION_NAMES.appMeta,
        description: 'Sell products directly to your fans on Instagram or Facebook.',
        thumbnail: appsThumbnails.metaThumbnail,
        signet: appsSignets.metaSignet,
        to: routing.apps.meta,
        docs: routing.external.helpCenter.meta,
    },
    INSTAGRAM_SHOP: {},
    INSTAGRAM_FEED: {},
    KLAVIYO: {},
    LAYLO: {},
    SHIPSTATION: {},
    STREAMELEMENTS: {
        name: SECTION_NAMES.appStreamElements,
        thumbnail: appsThumbnails.streamelementsThumbnail,
        description: 'Show stream alerts for product purchases and gifts.',
        signet: appsSignets.streamelementsSignet,
        to: routing.apps.streamelements,
        docs: routing.external.helpCenter.streamAlerts,
    },
    STREAMLABS: {
        name: SECTION_NAMES.appStreamlabs,
        thumbnail: appsThumbnails.streamlabsThumbnail,
        description: 'Show stream alerts for product purchases and gifts.',
        signet: appsSignets.streamlabsSignet,
        to: routing.apps.streamlabs,
        docs: routing.external.helpCenter.streamAlerts,
    },
    TIKTOK_FEED: {},
    TIKTOK_SHOP: {
        name: SECTION_NAMES.appTikTokShop,
        description: 'Sell products from your shop directly to your fans on TikTok.',
        thumbnail: appsThumbnails.tiktokThumbnail,
        signet: appsSignets.tiktokSignet,
        to: routing.apps.tiktokShop,
        docs: routing.external.helpCenter.tiktokShop,
    },
    TWITCH_GIFTING: {
        name: SECTION_NAMES.appTwitchGifting,
        thumbnail: appsThumbnails.twitchGiftingThumbnail,
        description: "Allow your viewers to gift products for chat when you're live.",
        signet: appsSignets.twitchSignet,
        to: routing.apps.twitch,
        docs: routing.external.helpCenter.twitchGifting,
    },
    TWITCH_DISCOUNTS_FOR_SUBS: {},
    TWITTER_SHOP: {
        name: SECTION_NAMES.appTwitterShops,
        thumbnail: appsThumbnails.twitterShopsThumbnail,
        description: 'Show off your products on your X (Twitter) profile.',
        to: routing.apps.twitterShops,
    },
    YOUTUBE_PRODUCT_SHELF: {
        name: SECTION_NAMES.appYoutubeProductShelf,
        description: 'Promote products from your shop on your YouTube channels.',
        thumbnail: appsThumbnails.youtubeThumbnail,
        signet: appsSignets.youtubeSignet,
        to: routing.apps.youTubeProductShelf,
        docs: routing.external.helpCenter.youtubeProductShelf,
    },
};
