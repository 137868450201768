import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  gap: 12px;
  padding: 8px 0px;
`;
export const Image = styled.img `
  border-radius: 100%;
  width: 40px;
  height: 40px;
`;
export const Info = styled.div `
  max-width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const Meta = styled.div `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: ${Colors.GS600};
`;
export const Title = styled.div `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
