import { Button } from '@fourthwall/components';
import React, { useEffect, useRef, useState } from 'react';
import * as S from './styled';
export const CopyButton = ({ copyValue, copiedLabel = 'Copied', ...props }) => {
    const [label, setLabel] = useState(props.label);
    const setTimeoutRef = useRef();
    useEffect(() => {
        return () => {
            clearTimeout(setTimeoutRef.current);
        };
    }, []);
    const handleCopy = () => {
        window.navigator.clipboard.writeText(copyValue);
        setLabel(copiedLabel);
        clearTimeout(setTimeoutRef.current);
        setTimeoutRef.current = setTimeout(() => {
            setLabel(props.label);
        }, 2000);
    };
    const handleClick = (e) => {
        handleCopy();
        props.onClick?.(e);
    };
    return (React.createElement(S.Container, null,
        React.createElement(Button, { ...props, label: label, fullWidth: true, onClick: handleClick })));
};
