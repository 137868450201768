import normalizeUrl from 'normalize-url';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useAuth } from '@fourthwall/auth';
import { Emoji } from '@fourthwall/components';
import { ShopStatusDropdown } from '@fourthwall/module-shop-status-switch';
import { addSourceParamToUrl } from '@utils/url';
import { CopyLink } from '@components/common/CopyLink';
import { useOffersQuery } from '@queries/useOffersQuery';
import { useCurrentShopQuery } from '../../hooks/useCurrentShopQuery';
import { useOnboarding } from './useOnboarding';
import * as S from '../styled';
// TODO: Disable default home queries until onboarding is resolved.
const renderDomain = (domain) => (React.createElement(S.CopyLinkWrapper, null,
    React.createElement(CopyLink, { label: normalizeUrl(domain, {
            stripProtocol: true,
            stripWWW: true,
        }), href: addSourceParamToUrl(domain, 'dashboard') })));
export const useHome = () => {
    const { onboarding } = useOnboarding();
    const { user } = useAuth();
    const { currentShopQuery } = useCurrentShopQuery();
    const { offersQuery } = useOffersQuery();
    useEffect(() => {
        if (offersQuery.data) {
            TagManager.dataLayer({
                dataLayer: { event: 'offers_count', offersCount: offersQuery.data.page.elementsTotal },
            });
        }
    }, [offersQuery.data]);
    const getWelcomeNameMessage = () => {
        if (!user?.firstName)
            return (React.createElement(React.Fragment, null,
                "!\u00A0",
                React.createElement(Emoji, { shortcode: "wave" })));
        return (React.createElement(React.Fragment, null,
            ",",
            ` `,
            user.firstName,
            "!\u00A0",
            React.createElement(Emoji, { shortcode: "wave" })));
    };
    const getNameAndTitle = () => {
        if (!currentShopQuery.data?.primaryDomain)
            return { name: undefined, title: undefined };
        if (onboarding.onboardingType === 'SCHEDULER')
            return { name: undefined, title: undefined };
        if ((onboarding.onboardingType === 'ONBOARDING_SHOP' ||
            onboarding.onboardingType === 'ONBOARDING_SHOP_MEMBERSHIPS') &&
            !currentShopQuery?.data.lastLiveAt) {
            return {
                name: (React.createElement(React.Fragment, null,
                    "Welcome",
                    getWelcomeNameMessage(),
                    " You\u2019re just a few steps away from being ready to launch.")),
                title: (React.createElement(S.Title, null,
                    React.createElement("div", null, "You can preview your progress at"),
                    renderDomain(currentShopQuery.data.baseUri))),
            };
        }
        return {
            name: React.createElement(React.Fragment, null,
                "Welcome back",
                getWelcomeNameMessage()),
            title: (React.createElement(S.Title, null,
                React.createElement("div", { style: { height: 24, flexShrink: 0 } },
                    "Your site is",
                    ' ',
                    React.createElement("div", { style: { display: 'inline-block', position: 'relative', top: -2 } },
                        React.createElement(ShopStatusDropdown, { size: "small", position: "bottom-start" })),
                    ' ',
                    "at"),
                renderDomain(currentShopQuery.data.baseUri))),
        };
    };
    const { name, title } = getNameAndTitle();
    const home = {
        name,
        title,
        onboarding,
    };
    return { home };
};
