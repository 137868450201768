import { Subsection, Upload, useMediaQueryContext } from '@fourthwall/components';
import { UploadIcon } from '@fourthwall/icons';
import { fileToBase64 } from '@fourthwall/utils/lib/file';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { RemovableUpload, UploadedImage } from '../../../../components';
import { FormFields } from '../../types';
export const ArtworkFields = () => {
    const media = useMediaQueryContext();
    const { values, setFieldValue } = useFormikContext();
    const handleFileDrop = async (files) => {
        if (files?.[0]) {
            const base64 = await fileToBase64(files[0]);
            setFieldValue(FormFields.coverImage, base64);
        }
    };
    const renderUpload = () => {
        if (values.coverImage) {
            return (React.createElement("div", { style: { height: 212 } },
                React.createElement(RemovableUpload, { onRemove: () => {
                        setFieldValue(FormFields.coverImage, '');
                    } },
                    React.createElement(UploadedImage, { image: values.coverImage }))));
        }
        return (React.createElement("div", { style: { height: 120 } },
            React.createElement(Upload, { accept: {
                    'image/*': [],
                }, title: media.tablet ? (React.createElement("span", null,
                    "Drop image or ",
                    React.createElement("u", null, "browse"))) : ('Browse'), icon: UploadIcon, iconSize: 20, orientation: "horizontal", onFileDrop: handleFileDrop })));
    };
    return React.createElement(Subsection, { title: "Cover photo" }, renderUpload());
};
