import { ApiBase } from '../ApiBase';
class CollectionsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getCollections = () => {
            return this.get('/collections');
        };
        this.getCollection = (params) => {
            return this.get(`/collections/${params.collectionId}`);
        };
        this.getCollectionBySlug = (params) => {
            return this.get(`/collections/slug/${params.slug}`);
        };
        this.getCollectionOffers = (pathParams) => {
            return this.get(`/collections/${pathParams.collectionId}/offers`);
        };
        this.createCollection = (requestBody) => {
            return this.post('/collections', requestBody);
        };
        this.updateCollection = (pathParams, payload) => {
            return this.put(`/collections/${pathParams.collectionId}`, payload);
        };
        this.updateCollectionSlug = (params, requestBody) => {
            return this.put(`/collections/${params.collectionId}/slug`, requestBody);
        };
        this.updateCollectionState = (params, requestBody) => {
            return this.put(`/collections/${params.collectionId}/state`, requestBody);
        };
        this.updateCollectionAvailability = (params, requestBody) => {
            return this.put(`/collections/${params.collectionId}/availability`, requestBody);
        };
        this.updateCollectionOrder = (params, requestBody) => {
            return this.put(`/collections/slug/${params.slug}/order`, requestBody);
        };
    }
}
export const CollectionsApiClient = new CollectionsApiClientModel();
