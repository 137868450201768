import { ApiBase } from '../ApiBase';
class ProductCatalogApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getProducts = (params) => {
            return this.get('/v2/product-catalog', params);
        };
        this.getProduct = (slug) => {
            return this.get(`/v2/product-catalog/product/${slug}`);
        };
        this.getRecommendations = () => {
            return this.get('/product-catalog/recommendations');
        };
        this.getProductRecommendations = (slug, options) => {
            return this.get(`/v2/product-catalog/product/${slug}/recommendations`, options);
        };
        this.getAdditionalProductsForDesignDuplication = (slug) => {
            return this.get(`/product-catalog/product/${slug}/design-duplication-additional-products`);
        };
        this.createAvailabilityAlert = (slug, body) => {
            return this.put(`/v2/product-catalog/product/${slug}/availability-alert`, body);
        };
        this.addProductToFavorite = (slug) => {
            return this.put(`/product-catalog/product/${slug}/favourite`);
        };
        this.deleteProductFromFavorite = (slug) => {
            return this.delete(`/product-catalog/product/${slug}/favourite`);
        };
        this.getFavoritesCount = () => {
            return this.get('/product-catalog/favourites/count');
        };
        this.getManufacturers = (params) => {
            return this.get('/v2/product-catalog/manufacturers', params);
        };
        this.getManufacturer = (params) => {
            return this.get(`/v2/product-catalog/manufacturers/${params}`, params);
        };
        this.getManufacturerReviews = (params, body) => {
            return this.get(`/v2/product-catalog/manufacturers/${params}/reviews`, body);
        };
        this.getPersonalizedRecommendations = () => {
            return this.get('/product-catalog/recommendations');
        };
        this.getBespokeRecommendations = () => {
            return this.get('/product-catalog/bespoke-recommendations');
        };
    }
}
export const ProductCatalogApiClient = new ProductCatalogApiClientModel();
