import React, { useMemo } from 'react';
import { ButtonClean } from '@fourthwall/components';
import { useDnsQuery } from '../../../../../hooks/useDnsQuery';
import { DNS_REFETCH_INTERVAL } from '../../../../constants';
import { getIllegalDnsProviderOrNull } from './utils';
import { useCurrentShop } from '../../../../../hooks/useCurrentShop';
import { useEntri } from '../../../../hooks/useEntri';
export const useDnsConfiguration = () => {
    const { dnsQuery, invalidateDnsQuery } = useDnsQuery([], {
        refetchInterval: DNS_REFETCH_INTERVAL,
    });
    const { currentShop } = useCurrentShop();
    const dnsList = dnsQuery.data?.dnsList;
    const mode = dnsList?.entriProviderDetails?.mode;
    const provider = dnsList?.entriProviderDetails?.name;
    const shouldVerifyProviderDetails = !!dnsList?.retryAllowed;
    const isAutomaticSetupAllowed = mode === 'AUTOMATIC' && !!dnsList?.retryAllowed && !!currentShop?.id;
    const { isAutomaticSetupInProgress, isEntriLoaded, getProviderDetails, getEntriConfig, setUpDomainAutomatically, } = useEntri({
        enabled: shouldVerifyProviderDetails || isAutomaticSetupAllowed,
    });
    const getVerifyProviderDetails = () => {
        if (shouldVerifyProviderDetails && isEntriLoaded) {
            return () => getProviderDetails(dnsList);
        }
    };
    const getSetUpAutomatically = () => {
        if (isAutomaticSetupAllowed && isEntriLoaded) {
            const config = getEntriConfig(dnsList, currentShop.id);
            return () => setUpDomainAutomatically(config, {
                onSuccess: () => invalidateDnsQuery(),
            });
        }
    };
    const illegalDnsProviderName = useMemo(() => getIllegalDnsProviderOrNull(dnsList?.nameservers), [dnsList?.nameservers]);
    const warning = useMemo(() => {
        if (illegalDnsProviderName) {
            return (React.createElement(React.Fragment, null,
                "Your DNS provider (",
                illegalDnsProviderName,
                ") does not support custom mail records (MX records). This does not impact your core Fourthwall experience, but we will not be able to use this domain\u2019s branding when your fans e-mail with customer support inquiries. All requests will go to: ",
                React.createElement("u", null, currentShop?.contactInfo.contactEmail),
                ".",
                ' ',
                React.createElement(ButtonClean, { label: "Learn more", appearance: "secondary", href: "https://help.fourthwall.com/hc/en-us/articles/15798008076955-Troubleshooting-your-domain-and-DNS-records", target: "_blank" })));
        }
    }, [illegalDnsProviderName, currentShop?.contactInfo.contactEmail]);
    const domain = {
        fullDomain: dnsList?.domain,
        subdomain: dnsList?.list[0]?.zoneFile?.subdomainPart,
        mainDomain: dnsList?.list[0]?.zoneFile?.mainPart,
    };
    return {
        domain,
        provider: provider === 'Unknown' ? undefined : provider,
        warning,
        mode,
        domainDnsRecords: dnsList?.list && dnsList?.domain ? dnsList.list : [],
        isLoading: dnsQuery.isLoading,
        isAutomaticSetupInProgress,
        invalidateDnsQuery,
        verifyProviderDetails: getVerifyProviderDetails(),
        setUpAutomatically: getSetUpAutomatically(),
    };
};
