import { AspectBox } from '@fourthwall/components';
import React from 'react';
import { getOrderImageUrl } from '@components/utils/getOrderImageUrl';
import { OrderPopover, OrderPopoverPadding } from '../OrderPopover';
import { QuantityBadge } from '../QuantityBadge';
import { Tooltip } from '../Tooltip';
import * as S from './TableProductsList.styled';
import { PhotoThumbnail } from '../PhotoThumbnail';
export const TableProductsList = ({ image, offers, offersCancelled, donation, quantity, }) => {
    const getImageUrl = () => {
        if (image)
            return image;
        return getOrderImageUrl(offers, offersCancelled);
    };
    const renderContent = (hasTooltip) => (React.createElement(S.ImageContainer, { hasTooltip: hasTooltip },
        React.createElement(AspectBox, { ratio: "3:4" },
            React.createElement(PhotoThumbnail, { url: getImageUrl(), size: "large" }),
            quantity > 1 ? React.createElement(QuantityBadge, { quantity: quantity }) : null)));
    if (!offers.length) {
        return renderContent(false);
    }
    return (React.createElement(Tooltip, { content: React.createElement(OrderPopover, { products: offers, donation: donation?.value, padding: OrderPopoverPadding.Small }), placement: "bottom-start" }, renderContent(true)));
};
