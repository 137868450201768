import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useThemeEditorContext } from '../../../hooks/useThemeEditorContext';
import { usePageLayoutContext } from '../../../providers/PageLayoutProvider';
import { BroadcastChannelManager } from '../core/broadcastChannelManager';
const ThemeLivePreviewContext = createContext(undefined);
export const ThemeLivePreviewProvider = ({ children }) => {
    const { currentSectionKey } = useThemeEditorContext();
    const [activeSectionName, setActiveSectionName] = useState();
    const [activeSectionKey, setActiveSectionKey] = useState(currentSectionKey);
    const [isInspectorVisible, setInspectorVisible] = useState(false);
    const { getSectionBySectionKey } = usePageLayoutContext();
    const [rect, setRect] = useState({
        height: 0,
        width: 0,
        x: 0,
        y: 0,
    });
    const channel = useMemo(() => new BroadcastChannelManager('theme-preview'), []);
    useEffect(() => {
        const handleEvent = (event) => {
            if (event.data.action === 'ThemePreview:ClearPreview') {
                setActiveSectionKey(undefined);
                setActiveSectionName(undefined);
            }
            if (event.data.action === 'ThemePreview:SectionHover') {
                const key = event.data.payload.id.replace('fw-section-', '');
                const section = getSectionBySectionKey(key);
                setActiveSectionKey(section?.section_key);
                setActiveSectionName(section?.name);
                setRect(event.data.payload.rect);
            }
            if (event.data.action === 'ThemePreview:SectionHoverFrame') {
                const key = event.data.payload.id.replace('fw-section-', '');
                const section = getSectionBySectionKey(key);
                setActiveSectionKey(section?.section_key);
                setActiveSectionName(undefined);
                setRect(event.data.payload.rect);
            }
            if (event.data.action === 'ThemePreview:Scroll') {
                setRect(event.data.payload.rect);
            }
        };
        channel.on(handleEvent);
        return () => {
            channel.off(handleEvent);
        };
    }, [channel, getSectionBySectionKey]);
    const value = useMemo(() => ({
        isInspectorVisible,
        channel,
        rect,
        activeSectionName,
        activeSectionKey,
        setActiveSectionKey,
        setInspectorVisible,
    }), [activeSectionKey, activeSectionName, channel, isInspectorVisible, rect]);
    return (React.createElement(ThemeLivePreviewContext.Provider, { value: value }, children));
};
export const useThemeLivePreviewContext = () => {
    const themeLivePreviewContext = useContext(ThemeLivePreviewContext);
    if (themeLivePreviewContext === undefined) {
        throw new Error('themeLivePreviewContext must be used within a ThemeLivePreviewContext');
    }
    return themeLivePreviewContext;
};
