import mapValues from 'lodash-es/mapValues';
export const breakpoints = {
    mobileM: 375,
    tablet: 768,
    laptop: 1024,
    desktop: 1920,
};
export const mediaQueriesExpressions = mapValues(breakpoints, (item) => {
    return `(min-width: ${item}px)`;
});
export const mediaQueries = mapValues(mediaQueriesExpressions, (item) => {
    return `@media ${item}`;
});
