import { Icon } from '@fourthwall/components';
import { PayPalIcon } from '@fourthwall/icons';
import { CreditCardIcon } from '@modules/Dashboard/PaymentMethods/components/CreditCardIcon';
import React from 'react';
import * as S from './styled';
export const PaymentMethod = ({ method }) => {
    if (method.type === 'CARD') {
        return (React.createElement(S.Container, null,
            React.createElement(CreditCardIcon, { brand: method.brand, size: 24 }),
            React.createElement("span", null, method.last4Digits)));
    }
    if (method.type === 'PAYPAL') {
        return (React.createElement(S.Container, null,
            React.createElement(Icon, { component: PayPalIcon, height: 24 })));
    }
    // Unknown
    return React.createElement(React.Fragment, null, "-");
};
