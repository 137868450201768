import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const TitleWrapper = styled.div `
  margin-bottom: 16px;
  width: calc(100% - 60px);

  ${mediaQueries.tablet} {
    width: 360px;
  }
`;
