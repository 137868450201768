import { Banner, Dropdown, HeadContent, Loader, PriceString, Subsection, Summary, Tag, ToolbarButton, useMediaQueryContext, } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import kebabCase from 'lodash-es/kebabCase';
import React from 'react';
import { ContributionSummary } from '../../../../../components/common/ContributionSummary';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { ProductsList, ProductsListSize } from '../../../../../components/common/ProductsList';
import { SideSummary } from '../../../../../components/common/SideSummary';
import { DashboardBackButton } from '../../../../../components/utils/DashboardBackButton';
import { routing } from '../../../../../utils/routing';
import { SummaryRowLabel } from '../../components/SummaryRowLabel';
import { GIVEAWAYS_CONSTANTS } from '../Giveaways';
export const GiveawayView = ({ title, date, banners, details, items, itemsSummary, summary, toolbarDropdownItems, message, isLoading, }) => {
    const media = useMediaQueryContext();
    if (isLoading) {
        return (React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
            React.createElement(Loader, { size: "large" })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: title }),
        React.createElement(PageLayout, { leftContent: React.createElement(React.Fragment, null,
                banners &&
                    banners.map((banner, index) => (React.createElement("div", { style: { marginBottom: 32 }, key: index },
                        React.createElement(Banner, { ...banner })))),
                items && (React.createElement(Subsection, { title: "Order summary", borderless: true },
                    React.createElement(ContributionSummary, { title: "Gift", status: React.createElement(Tag, { label: "Gift", appearance: "success" }) },
                        items && (React.createElement(ContributionSummary.Section, null,
                            React.createElement(ProductsList, { size: ProductsListSize.Large }, items.map((item) => (React.createElement(ProductsList.Item, { key: item.title, name: item.title, image: item.image, quantity: item.quantity, offerId: item.offerId, price: React.createElement(PriceString, { ...item.price }) })))))),
                        itemsSummary && (React.createElement(ContributionSummary.Section, null,
                            React.createElement(Summary, null, itemsSummary.map((item) => (React.createElement(Summary.Row, { key: kebabCase(item.label), label: React.createElement(SummaryRowLabel, { title: item.label, tooltip: item.tooltip }), value: item.value }))))))),
                    React.createElement("div", { style: { paddingLeft: 20, paddingRight: 20 } }, summary && (React.createElement(Summary, null, summary.map((item, index) => (React.createElement(Summary.Row, { key: index, label: item.label, value: item.value, bold: true })))))))),
                message && React.createElement(Subsection, { title: "Message left" }, message)), rightContent: React.createElement(SideSummary, null, details && React.createElement(SideSummary.Item, { title: "Contact e-mail" }, details.email)) },
            React.createElement(Dashboard.ItemViewHeader, { name: title, title: date, backButton: React.createElement(DashboardBackButton, { label: GIVEAWAYS_CONSTANTS.title, to: {
                        pathname: routing.contributions.orders.giveaways.all,
                        search: location.search,
                    } }), toolbar: toolbarDropdownItems.length > 0 && (React.createElement(Dropdown, { content: toolbarDropdownItems.map((toolbarDropdownItem, index) => (React.createElement(Dropdown.Item, { key: index, ...toolbarDropdownItem }))), position: "bottom-end" },
                    React.createElement(ToolbarButton, { label: "Settings", icon: DotsHorizontalIcon, size: media.tablet ? 'medium' : 'small' }))) }))));
};
