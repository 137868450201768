import { Button, Subsection } from '@fourthwall/components';
import { routing } from '@utils/routing';
import React from 'react';
import * as S from './styled';
export const Analytics = () => {
    return (React.createElement(Subsection, { title: "Analytics and tracking pixels" },
        React.createElement(S.Description, null, "You can manage your tracking pixels from the Analytics tab."),
        React.createElement(S.ButtonWrapper, null,
            React.createElement(Button, { label: "Manage tracking pixels", to: routing.analytics.trackingPixels }))));
};
