import { useModalContext } from '@fourthwall/components';
import React from 'react';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { ConfirmRemovePaymentMethodModal } from '../../components/ConfirmRemovePaymentMethodModal';
import { SelectNewDefaultPaymentMethod } from '../../components/SelectNewDefaultPaymentMethod';
import { useRemoveShopPaymentMethodMutation } from '../../hooks/useRemoveShopPaymentMethodMutation';
import { useSetShopDefaultPaymentMethodMutation } from '../../hooks/useSetShopDefaultPaymentMethodMutation';
import { useShopPaymentMethods } from '../../hooks/useShopPaymentMethods';
import { ShopPaymentMethodsView } from './ShopPaymentMethodsView';
import { getErrorBanner } from './utils';
export const ShopPaymentMethodsContainer = () => {
    const { open } = useModalContext();
    const { shopPaymentMethods, invalidateShopPaymentMethods } = useShopPaymentMethods();
    const { removeShopPaymentMethodMutation } = useRemoveShopPaymentMethodMutation();
    const { setShopDefaultPaymentMethodMutation } = useSetShopDefaultPaymentMethodMutation();
    const { invalidateCurrentShopQuery } = useCurrentShopQuery();
    const setAsDefault = (shopPaymentMethodId) => {
        setShopDefaultPaymentMethodMutation.mutate([shopPaymentMethodId], {
            onSuccess: () => {
                invalidateShopPaymentMethods();
                invalidateCurrentShopQuery();
            },
        });
    };
    const getDropdownItemsProps = (paymentMethod) => {
        const dropdownItems = [];
        if (!paymentMethod.default) {
            dropdownItems.push({
                label: 'Set as default',
                onClick: () => setAsDefault(paymentMethod.id),
            });
        }
        dropdownItems.push({
            label: 'Remove',
            onClick: () => handleRemove(paymentMethod),
        });
        return dropdownItems;
    };
    const removeShopPaymentMethod = (id) => {
        removeShopPaymentMethodMutation.mutate([id], {
            onSuccess: () => {
                invalidateShopPaymentMethods();
            },
        });
    };
    const handleRemove = (paymentMethod) => {
        if (!shopPaymentMethods)
            return;
        if (paymentMethod.default && shopPaymentMethods.length > 1) {
            return open(SelectNewDefaultPaymentMethod, {
                removedPaymentMethod: paymentMethod,
                remainingPaymentMethods: shopPaymentMethods.filter((method) => !method.default),
                onSubmit: ({ defaultPaymentMethodId }) => {
                    removeShopPaymentMethod(paymentMethod.id);
                    if (defaultPaymentMethodId) {
                        setAsDefault(defaultPaymentMethodId);
                    }
                },
            });
        }
        open(ConfirmRemovePaymentMethodModal, {
            paymentMethodProps: paymentMethod,
            onConfirm: () => {
                removeShopPaymentMethod(paymentMethod.id);
            },
        });
    };
    const paymentMethodsProps = shopPaymentMethods?.map((method) => ({
        ...method,
        dropdownItemsProps: getDropdownItemsProps(method),
    })) || [];
    return (React.createElement(ShopPaymentMethodsView, { paymentMethodsProps: paymentMethodsProps, errorBanner: getErrorBanner(paymentMethodsProps) }));
};
