import { ButtonClean, HeadContent, LeavingGuard, Loading, SaveShortcutListener, Select, Subsection, } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { usePlatformFeatureFlags } from '@components/utils/DashboardFeatureFlags/usePlatformFeatureFlags';
import { routing } from '@utils/routing';
import { ConfirmBarContainer } from '@modules/Dashboard/ConfirmBar';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import { FixedShippingContainer } from './modules/FixedShipping/FixedShippingContainer';
import { ShippingLocationContainer } from './modules/ShippingLocation/ShippingLocationContainer';
import { WeightedShippingContainer } from './modules/WeightedShipping/WeightedShippingContainer';
import * as S from './styled';
import { InventoryShipmentSection } from './modules/InventoryShipmentSection/InventoryShipmentSection';
import { ShippingGroupsYouShipContainer } from './modules/ShippingGroupsYouShip/ShippingGroupsYouShipContainer';
export const ShippingView = ({ shippingPricingMethodOptions, isLoading, isSubmitting }) => {
    const formik = useFormikContext();
    const { hasFeatureFlag } = usePlatformFeatureFlags();
    const adminMode = useAdminModeContext();
    const resetFixedShippingForm = () => {
        formik.setFieldTouched('domesticFirst', false);
        formik.setFieldTouched('domesticAdditional', false);
        formik.setFieldTouched('internationalFirst', false);
        formik.setFieldTouched('internationalAdditional', false);
    };
    const resetWeightedShippingForm = () => {
        formik.setFieldTouched('additionalShippingFee', false);
    };
    const handleValueChange = (value) => {
        if (value === 'Fixed') {
            resetFixedShippingForm();
        }
        if (value === 'Weighted') {
            resetWeightedShippingForm();
        }
    };
    return (React.createElement(Loading, { isLoading: isLoading },
        React.createElement(HeadContent, { title: "Shipping \u2013 Settings" }),
        React.createElement(Subsection, { title: "Shipping pricing for products you ship" },
            React.createElement(Select, { label: "Shipping pricing method", options: shippingPricingMethodOptions, ...formik.getFieldProps('pricingMethod'), onValueChange: handleValueChange })),
        React.createElement(S.ShippingPricingMethodContainer, null,
            React.createElement(FixedShippingContainer, null),
            React.createElement(WeightedShippingContainer, null)),
        React.createElement(S.ShippingLocationContainer, null,
            React.createElement(ShippingLocationContainer, null)),
        React.createElement(S.ShippingGroupsYouShipContainer, null,
            React.createElement(ShippingGroupsYouShipContainer, null)),
        React.createElement(Subsection, { title: "Shipping pricing for products we ship for you" }, "Your supporters will pay the true cost of shipping based on the weight of their order."),
        (hasFeatureFlag('assisted_product_shipping') || Boolean(adminMode?.isActive)) && (React.createElement(Subsection, { title: "Inventory shipments", rightContent: React.createElement(ButtonClean, { label: "Send inventory to our fulfillment center", to: routing.settings.inventoryShipments.create }) },
            React.createElement(InventoryShipmentSection, null))),
        React.createElement(SaveShortcutListener, { disabled: !formik.dirty, onSave: formik.submitForm }),
        React.createElement(ConfirmBarContainer, { isOpen: formik.dirty, onConfirm: formik.submitForm, onCancel: formik.resetForm, isLoading: isSubmitting }),
        React.createElement(LeavingGuard, { when: formik.dirty })));
};
