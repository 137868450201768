import styled, { css } from 'styled-components';
import { Size } from './types';
export const Container = styled.table `
  width: 100%;
  border-collapse: collapse;
  margin: -4px 0;

  ${({ size }) => size === Size.Small &&
    css `
      margin: -4px 0;
    `};
`;
