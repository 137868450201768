import * as yup from 'yup';
import { FormFields } from './types';
export const validationSchema = yup.object().shape({
    [FormFields.messagesEnabled]: yup.boolean(),
    [FormFields.tipsEnabled]: yup.boolean(),
    [FormFields.minTipEnabled]: yup.boolean(),
    [FormFields.minTipAmount]: yup
        .number()
        .when([FormFields.messagesEnabled, FormFields.tipsEnabled, FormFields.minTipEnabled], {
        is: (messagesEnabled, tipsEnabled, minTipEnabled) => messagesEnabled && tipsEnabled && minTipEnabled,
        then: yup.number().required().min(1).max(100),
        otherwise: yup.number(),
    }),
});
