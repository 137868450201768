import { Button, ButtonGroup, Fieldset, Popper, RadioButton, Tag, useMediaQueryContext, } from '@fourthwall/components';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as S from './styled';
export const StatusTag = ({ tagProps, initialValues, onSubmit }) => {
    if (!onSubmit || !initialValues) {
        return (React.createElement(S.Container, null,
            React.createElement(Tag, { ...tagProps })));
    }
    const [isOpen, setOpen] = useState(false);
    const media = useMediaQueryContext();
    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            onSubmit(values);
            setOpen(false);
        },
        enableReinitialize: true,
    });
    const toggleOpen = () => {
        setOpen((prevIsOpen) => !prevIsOpen);
    };
    const renderForm = () => (React.createElement(S.Form, { onSubmit: formik.handleSubmit },
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(RadioButton, { value: formik.values.status, checkedValue: "Active", label: "Active", suggestion: "App is fully functional.", name: "status", onChange: formik.handleChange })),
            React.createElement(Fieldset.Row, null,
                React.createElement(RadioButton, { value: formik.values.status, checkedValue: "Inactive", label: "Inactive", suggestion: "App is temporarily disabled.", name: "status", onChange: formik.handleChange }))),
        React.createElement(S.ButtonsContainer, null,
            React.createElement(ButtonGroup, { space: "medium", justify: media.tablet ? 'right' : undefined, direction: media.tablet ? 'row' : 'column-reverse', fullWidth: !media.tablet },
                React.createElement(Button, { type: "button", label: "Cancel", fullWidth: !media.tablet, onClick: () => {
                        formik.resetForm();
                        setOpen(false);
                    } }),
                React.createElement(Button, { type: "button", appearance: "primary", label: "Save", fullWidth: !media.tablet, disabled: !formik.dirty || !formik.isValid, onClick: formik.submitForm })))));
    return (React.createElement(Popper, { isOpen: isOpen, referenceComponent: React.createElement(S.Container, { onClick: toggleOpen },
            React.createElement(Tag, { ...tagProps })), popperProps: {
            placement: 'bottom-start',
            modifiers: [
                { name: 'preventOverflow', enabled: false },
                { name: 'hide', enabled: false },
            ],
        }, style: { paddingTop: media.tablet ? 8 : 0 }, onClose: () => setOpen(false) }, renderForm()));
};
