import { EmailIcon, VideoIcon, VolumeOnIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './Thumbnail.styled';
export const ThumbnailComponent = ({ type, thumbnail }) => {
    const getContent = () => {
        if (type === 'VIDEO') {
            if (thumbnail) {
                return React.createElement(S.VideoPreview, { "data-testid": "video-preview", src: thumbnail, alt: "thumbnail" });
            }
            return (React.createElement(S.Icon, null,
                React.createElement(VideoIcon, { "data-testid": "video-icon", height: 16, width: 16 })));
        }
        if (type === 'AUDIO') {
            return (React.createElement(S.Icon, null,
                React.createElement(VolumeOnIcon, { "data-testid": "audio-icon", height: 16, width: 16 })));
        }
        return (React.createElement(S.Icon, null,
            React.createElement(EmailIcon, { "data-testid": "message-icon", height: 16, width: 16 })));
    };
    return React.createElement(S.ThumbnailWrapper, null, getContent());
};
