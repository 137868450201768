import { useEffect, useState } from 'react';
import { getCardStyle } from '../utils';
export const useStripeElement = (elementType, elements, options = {}) => {
    const [element, setElement] = useState();
    const [error, setError] = useState();
    const [isComplete, setIsComplete] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const handleElementChange = (response) => {
        setError(response?.error?.message);
        setIsComplete(!!response?.complete);
    };
    const handleElementReady = () => {
        setError(undefined);
    };
    const initializeElement = () => {
        if (!elements) {
            return;
        }
        const elementInstance = elements.create(elementType, {
            style: getCardStyle(),
            ...options,
        });
        elementInstance.on('change', handleElementChange);
        elementInstance.on('ready', handleElementReady);
        elementInstance.on('focus', () => setIsFocused(true));
        elementInstance.on('blur', () => setIsFocused(false));
        setElement(elementInstance);
    };
    const mountElement = (node) => {
        if (!element) {
            return;
        }
        setIsMounted(true);
        element.mount(node);
    };
    const unmountElement = () => {
        if (!element) {
            return;
        }
        setIsMounted(false);
        element.unmount();
    };
    useEffect(() => {
        initializeElement();
    }, [elements]);
    return {
        element,
        error,
        isInitialized: !!element,
        isComplete,
        isFocused,
        isMounted,
        setError,
        mountElement,
        unmountElement,
    };
};
