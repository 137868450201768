export const mapQueryToPaginationProps = (query, currentPage, setCurrentPage) => {
    if (!query.isSuccess)
        return;
    const { page } = query.data;
    if (!(page.totalPages > 1))
        return;
    return {
        currentPage,
        pageCount: page.totalPages,
        onChange: setCurrentPage,
    };
};
