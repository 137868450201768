import React from 'react';
import { Icon } from '../Icon';
import * as S from './styled';
import { Tooltip } from '../Tooltip';
export const Tag = ({ label, appearance = 'neutral', icon, tooltip, size = 'small', hoverable = false, display = 'flex', }) => {
    const tag = (React.createElement(S.Container, { appearance: appearance, size: size, hoverable: hoverable, display: display },
        label,
        icon && (React.createElement(S.IconContainer, null,
            React.createElement(Icon, { component: icon, height: 11 })))));
    if (!tooltip) {
        return tag;
    }
    return (React.createElement(Tooltip, { reference: tag, placement: "top", strategy: "fixed" }, tooltip));
};
