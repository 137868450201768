import { Dropdown, ToolbarButton } from '@fourthwall/components';
import { FilterIcon } from '@fourthwall/icons';
import React, { useState } from 'react';
import { FilterDropdownBox } from './components/FilterDropdownBox';
import { GroupTitle } from './components/GroupTitle';
export const FilterDropdown = ({ activityIndicator, content, onReset, onSubmit }) => {
    const [isOpen, setOpen] = useState(false);
    const handleReset = () => {
        onReset();
        setOpen(false);
    };
    const handleSubmit = () => {
        onSubmit();
        setOpen(false);
    };
    return (React.createElement(Dropdown, { isOpen: isOpen, content: React.createElement(FilterDropdownBox, { content: content, onReset: handleReset, onSubmit: handleSubmit }), position: "bottom-end", maxWidth: 500, onClose: () => setOpen(false) },
        React.createElement(ToolbarButton, { label: "Filter", icon: FilterIcon, activityIndicator: activityIndicator, onClick: () => setOpen((status) => !status) })));
};
FilterDropdown.GroupTitle = GroupTitle;
