import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  padding: 0 20px;
  margin-bottom: 10px;

  font-size: 16px;
  color: ${Colors.GS1000};

  ${mediaQueries.laptop} {
    padding: 0 24px;
  }
`;
export const BackButton = styled.button `
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: -8px;

  color: ${Colors.GS1000};

  &:hover {
    background: ${Colors.GS300};
  }
`;
export const LoaderContainer = styled.div `
  width: 24px;
  cursor: not-allowed;
`;
export const Wrapper = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  gap: 8px;
`;
export const Title = styled.div `
  line-height: 20px;
  flex: 1;
  font-weight: 600;
`;
