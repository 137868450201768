import React from 'react';
import kebabCase from 'lodash-es/kebabCase';
import * as S from './styled';
export const ArticleList = ({ items }) => (React.createElement(S.Container, null, items.map((item) => (React.createElement(S.ItemContainer, { key: kebabCase(item.title) },
    React.createElement(S.ItemInner, { href: item.url, target: "_blank" },
        React.createElement(S.ItemMain, null,
            React.createElement(S.Title, null, item.title),
            React.createElement(S.Description, null, item.description),
            React.createElement(S.Footer, null,
                React.createElement(S.TimeToRead, null,
                    React.createElement("time", null, item.timeToRead),
                    React.createElement("span", null, "\u2197")))),
        React.createElement(S.ItemImageContainer, null,
            React.createElement(S.ItemImage, { src: item.imageSrc }))))))));
