import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-gap: 8px;
`;
export const Controls = styled.div `
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  margin-right: -4px;
`;
export const Nickname = styled.div `
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  text-overflow: ellipsis;
  overflow: hidden;
`;
