import React from 'react';
import { useLocation } from 'react-router-dom';
import { Emoji } from '@fourthwall/components';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
import { routing } from '@utils/routing';
import { SECTION_NAMES } from '@constants/sectionNames';
import { useCreatorIntegrationsQuery } from '@modules/Dashboard/Apps';
export const useRecommendationBars = () => {
    const { currentShop } = useCurrentShop();
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const location = useLocation();
    const locationState = { from: { label: SECTION_NAMES.home, location } };
    const hasTwitchPlatform = currentShop?.metafields?.onboarding?.['oauth-flow'] === true &&
        currentShop?.metafields?.onboarding?.['creator-platform'] === 'twitch';
    const hasTwitchSocial = !!currentShop?.social.twitch;
    const shouldShowTwitchBar = (hasTwitchPlatform || hasTwitchSocial) &&
        creatorIntegrationsQuery.data?.twitch.status !== 'CONNECTED';
    const recommendationBars = [];
    const twitchBar = {
        title: (React.createElement(React.Fragment, null,
            "Boost sales with Twitch gifting \u00A0",
            React.createElement(Emoji, { shortcode: "wrappedGift" }))),
        description: 'Grow your sales by 50%+ by letting supporters gift products products to chat',
        to: routing.apps.twitch,
        state: locationState,
        appearance: 'twitch',
    };
    if (currentShop && creatorIntegrationsQuery.isFetched && shouldShowTwitchBar) {
        recommendationBars.push(twitchBar);
    }
    return { recommendationBars };
};
