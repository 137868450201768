import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  padding-right: 48px;
  flex-direction: column;

  ${mediaQueries.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;
export const NameWrapper = styled.div `
  position: relative;
  width: 100%;

  ${({ active }) => !active &&
    css `
      &:hover {
        z-index: 1;
      }
    `}

  ${({ active }) => active &&
    css `
      z-index: 2;
    `}

  ${mediaQueries.tablet} {
    width: 224px;
  }
`;
export const ColorWrapper = styled.div `
  display: flex;
`;
export const ColorSwatchWrapper = styled.div `
  min-width: 76px;
  position: relative;
  margin-left: -1px;

  ${({ active }) => !active &&
    css `
      &:hover {
        z-index: 1;
      }
    `}

  ${({ active }) => active &&
    css `
      z-index: 2;
    `}

  ${mediaQueries.tablet} {
    min-width: 88px;
  }
`;
export const PriceWrapper = styled.div `
  width: 136px;
  margin-left: 16px;
`;
export const WeightContainer = styled.div `
  display: flex;
  width: 100%;
  margin-top: 12px;

  ${mediaQueries.tablet} {
    margin-top: 0;
    margin-left: 16px;
    width: 168px;
  }
`;
export const InfoText = styled.div `
  flex: 1;
  line-height: 24px;
  font-size: 14px;
  color: ${Colors.GS600};
  font-weight: 300;
  margin-top: 8px;

  ${mediaQueries.tablet} {
    margin-top: 0;
    margin-left: 16px;
    font-size: 16px;
  }
`;
