import { ButtonGroup, Checkbox, Heading, Input, ModalBox, useMediaQueryContext, } from '@fourthwall/components';
import React from 'react';
import * as S from './SelectModal.styled';
export const SelectModal = ({ title, content, contentRef, message, submitDisabled, cancelButtonLabel = 'Cancel', submitButtonLabel = 'Save', close, onSubmitClick, inputProps = {
    disabled: true,
}, selectAllCheckboxProps, }) => {
    const media = useMediaQueryContext();
    return (React.createElement(ModalBox, { size: media.tablet ? 'large' : 'fullScreen', withoutPadding: true, onClose: close, disableScrolling: true },
        React.createElement(S.Container, null,
            React.createElement(S.TitleContainer, null,
                React.createElement(Heading, { type: media.tablet ? 'h6' : 'h7', weight: "semibold" }, title)),
            !!inputProps.onValueChange && inputProps.value !== undefined && (React.createElement(S.InputContainer, null,
                React.createElement(Input, { size: media.tablet ? 'large' : 'medium', ...inputProps }))),
            selectAllCheckboxProps && (React.createElement(S.SelectAllContainer, null,
                React.createElement(Checkbox, { indeterminate: true, ...selectAllCheckboxProps }))),
            React.createElement(S.ContentContainer, { id: "selectModalContent", ref: contentRef }, content)),
        React.createElement(S.Bar, null,
            message && React.createElement(S.Message, null, message),
            React.createElement(ButtonGroup, { space: "medium", direction: "row", fullWidth: !media.tablet },
                React.createElement(S.Button, { label: cancelButtonLabel, size: media.tablet ? 'small' : 'medium', onClick: close, fullWidth: !media.tablet }),
                React.createElement(S.Button, { label: submitButtonLabel, disabled: submitDisabled, appearance: "primary", size: media.tablet ? 'small' : 'medium', fullWidth: !media.tablet, onClick: onSubmitClick })))));
};
