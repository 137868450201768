import { OffersApiClient } from '@fourthwall/services/lib/OffersApiClient';
import { createQueryHook } from '@fourthwall/utils/lib/queries';
import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
export const useOffersQuery = createQueryHook(OffersApiClient.getOffers, {
    queryKey: ['offers'],
    select: (data) => {
        data.offers = data.offers.map((offer) => ({ ...offer, name: decodeHtmlEntities(offer.name) }));
        return data;
    },
});
