import { getPrice } from '@fourthwall/utils/lib';
import React from 'react';
import { Accordion } from '../../../../../../common/Accordion';
import { AccordionHeading } from '../AccordionHeading';
import { AccordionPanel } from '../AccordionPanel';
import * as S from './styled';
export const AccordionItem = ({ autoAdjustedSellingPrice, data, expanded, isMinimumQuantity, includeFeesValue, onChange, ...props }) => {
    return (React.createElement(Accordion.Item, { expanded: expanded, heading: React.createElement(AccordionHeading, { isMinimumQuantity: isMinimumQuantity, profit: data.unitProfit, quantity: data.numberOfUnits, showProfit: !expanded }), footer: React.createElement(S.TotalProfitContainer, null,
            React.createElement(S.TotalProfitCopy, null, `${getPrice(data.totalProfit)} for ${data.numberOfUnits} sold`)), onChange: onChange, ...props },
        React.createElement(AccordionPanel, { cost: data.cost, price: data.price, profit: data.unitProfit, feesValue: data.feesValue, includeFeesValue: includeFeesValue, autoAdjustedSellingPrice: autoAdjustedSellingPrice })));
};
