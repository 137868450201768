import { PhotoTableCellContent } from '@components/common/PhotoTableCellContent';
import { TableCell } from '@components/common/TableCell';
import React from 'react';
export const TABLE_HEADERS = [
    {
        id: 'giveaway',
        label: 'Giveaway',
        render: (giveawayPackage) => (React.createElement(TableCell, { mainContent: React.createElement(PhotoTableCellContent, { name: giveawayPackage.items[0]?.name, photo: giveawayPackage.items[0]?.image, photoSize: "large" }) })),
        width: 2,
    },
    {
        id: 'used',
        label: 'Used',
        render: (giveawayPackage) => (React.createElement(TableCell, { mainContent: React.createElement(React.Fragment, null,
                giveawayPackage.stats.usage.redeemed,
                " / ",
                giveawayPackage.stats.usage.all) })),
        width: 0,
        minWidth: 150,
    },
];
