import styled from 'styled-components';
import { Colors } from '../../../colors';
import { mediaQueries } from '../../../providers/MediaQueryProvider';
import { HELPER_CLASS } from '../consts';
export const Container = styled.div `
  display: flex;

  ${mediaQueries.tablet} {
    flex-direction: row;
  }
`;
export const Handle = styled.div `
  color: ${Colors.GS1000};
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    color: ${Colors.GS600};
  }

  ${mediaQueries.tablet} {
    margin-left: 16px;
  }
`;
export const DragHandle = styled(Handle) `
  cursor: move;
  cursor: grab;

  .${HELPER_CLASS} & {
    cursor: grabbing;
  }
`;
