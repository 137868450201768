import { ApiBase } from './ApiBase';
class NotificationPreferencesApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getNotificationPreferences = () => {
            return this.get('/notification/preferences');
        };
        this.updateNotificationPreferences = (payload) => {
            return this.put('/notification/preferences', payload);
        };
    }
}
export const NotificationPreferencesApiClient = new NotificationPreferencesApiClientModel();
