import { useEffect, useState } from 'react';
import { Toasts } from '@fourthwall/components';
export const useCopyToClipboard = ({ time = 2000, toastMessage } = {}) => {
    const [isCopied, setIsCopied] = useState(false);
    useEffect(() => {
        if (isCopied) {
            resetIsCopied();
            if (toastMessage) {
                Toasts.notify(toastMessage, { type: 'info' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCopied]);
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
    };
    const resetIsCopied = () => {
        const timeout = setTimeout(() => {
            setIsCopied(false);
        }, time);
        return () => clearTimeout(timeout);
    };
    return {
        isCopied,
        copyToClipboard,
    };
};
