import styled from 'styled-components';
export const NameContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 12px;
  word-break: break-word;
`;
export const AvatarContainer = styled.div `
  flex-shrink: 0;
`;
export const TagContainer = styled.p `
  display: flex;
  margin-bottom: 4px;
`;
export const TitleContainer = styled.div `
  display: grid;
  gap: 12px;
`;
