import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;

  display: flex;
  align-items: center;
`;
export const ImageContainer = styled.div `
  margin-right: 20px;
`;
export const NameContainer = styled.div `
  line-height: 150%;
`;
export const Name = styled.div `
  font-size: 16px;
`;
export const Description = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
`;
