import React from 'react';
import { BaseContent, Button, useMediaQueryContext } from '@fourthwall/components';
import * as S from './styled';
export const ShippingGroupEmptyState = ({ to }) => {
    const media = useMediaQueryContext();
    return (React.createElement(S.Container, null,
        React.createElement(S.TextualContent, null,
            React.createElement(S.Title, null, "Shipping group"),
            React.createElement(BaseContent, null, "Select the products you\u2019d like to set shipping limitations for")),
        React.createElement(S.ButtonWrapper, null,
            React.createElement(Button, { appearance: "primary", label: media.tablet ? 'Select products to limit shipping to' : 'Select products', to: to }))));
};
