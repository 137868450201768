import { Toasts } from '@fourthwall/components';
import { CollectionsApiClient } from '@fourthwall/services/lib/CollectionsApiClient';
import { useMutation } from '@tanstack/react-query';
export const mapErrorToMessage = (error) => {
    if (!error)
        return 'Something went wrong';
    if (error.violations?.some((violation) => violation.field == 'newTimeFrame.to' && violation.title == 'must be a future date')) {
        return 'End date must be in the future';
    }
    return error.title || 'Something went wrong';
};
export const useStatusMutation = () => {
    const mutationKey = 'useStatusMutation';
    const mutation = useMutation({
        mutationKey: [mutationKey],
        mutationFn: async ({ collectionId, payload }) => {
            const { data } = await CollectionsApiClient.updateCollectionState({ collectionId }, payload);
            return data;
        },
        onError: (error) => {
            Toasts.notify(mapErrorToMessage(error.response?.data), {
                toastId: mutationKey,
                type: 'error',
            });
        },
    });
    return {
        statusMutation: mutation,
    };
};
