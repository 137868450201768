import React from 'react';
import { AddressForm } from './components/AddressForm';
export const OrderShippingAddressView = ({ orderId, shippingAddress, isFetching, updateShippingAddressRequest, close, }) => {
    const handleSubmit = (values, { resetForm }) => {
        resetForm({ values });
        updateShippingAddressRequest({
            id: orderId,
            data: { address: values },
        });
    };
    if (!shippingAddress) {
        return null;
    }
    return (React.createElement(AddressForm, { initialValues: shippingAddress, isLoading: isFetching, onSubmit: handleSubmit, onClose: close }));
};
