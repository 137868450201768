import { useActiveThemeContext } from '../providers/ActiveThemeProvider';
import { useCurrentShopContext } from './useCurrentShopContext';
import { usePagesQuery } from './usePagesQuery';
import { useThemeEditorContext } from './useThemeEditorContext';
export const usePages = () => {
    const { currentPageId } = useThemeEditorContext();
    const { currentShop } = useCurrentShopContext();
    const { activeTheme } = useActiveThemeContext();
    const { pagesQuery } = usePagesQuery([{ shop_id: currentShop.id, theme_id: activeTheme.id }]);
    const pages = pagesQuery.data?.pages;
    const currentPage = pages?.find((page) => page.id === currentPageId);
    return {
        pages,
        currentPage,
        refetchPages: pagesQuery.refetch,
    };
};
