import React, { useState } from 'react';
import { ModalBox } from '@fourthwall/components/lib/partials/ModalBox';
import { Button, PriceString, RadioButton, TableUI } from '@fourthwall/components';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable, } from '@tanstack/react-table';
import * as S from './PackageSizeModal.styled';
import { LABELS } from './labels';
const columnHelper = createColumnHelper();
const columns = (checkedValue) => [
    columnHelper.accessor('size', {
        id: 'size',
        header: 'Size category',
        cell: (info) => (React.createElement(S.Size, null,
            React.createElement("div", { style: { textTransform: 'capitalize' } },
                React.createElement(RadioButton, { label: React.createElement("strong", null, LABELS[info.getValue()]), value: info.getValue(), checkedValue: checkedValue })),
            React.createElement("p", null, info.row.original.examples.join(', ')))),
    }),
    columnHelper.accessor('maxSize', {
        id: 'maxSize',
        header: 'Max size (L x W x H)',
        cell: (info) => (React.createElement(S.MaxSize, null,
            React.createElement("span", null,
                React.createElement("strong", null, info.getValue().dimensions)),
            React.createElement("div", null, info.getValue().volume))),
    }),
    columnHelper.accessor('storage', {
        id: 'storage',
        header: 'Storage fee per item',
        cell: (info) => (React.createElement(S.Storage, null,
            React.createElement(PriceString, { value: info.getValue().feePerMonth, minimumFractionDigits: 2 }),
            " / month")),
    }),
];
export const PackageSizeModal = ({ sizesData, currentValue, onClose, onConfirm, }) => {
    const [checkedValue, setCheckedValue] = useState(currentValue || 'SMALL');
    const handleCancelClick = () => {
        onClose?.();
    };
    const handleConfirmClick = () => {
        onClose?.();
        if (onConfirm) {
            onConfirm(checkedValue);
        }
    };
    const table = useReactTable({
        data: sizesData,
        columns: columns(checkedValue),
        getCoreRowModel: getCoreRowModel(),
    });
    return (React.createElement(ModalBox, { title: "Select product size", size: "large", buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: handleCancelClick }),
            React.createElement(Button, { label: "Confirm", appearance: "primary", onClick: handleConfirmClick }),
        ], buttonsDirection: "row", onClose: onClose || handleCancelClick },
        React.createElement(TableUI.Root, { hoverable: false, "$minWidth": "600px" },
            React.createElement(TableUI.Head, null, table.getHeaderGroups().map((headerGroup) => {
                return (React.createElement(TableUI.HeadTr, { key: headerGroup.id }, headerGroup.headers.map((header) => {
                    return (React.createElement(TableUI.HeadTh, { key: header.id, colSpan: header.colSpan, textAlign: "left" }, header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())));
                })));
            })),
            React.createElement(TableUI.Body, null, table.getRowModel().rows.map((row) => (React.createElement(TableUI.BodyTr, { key: row.id }, row.getVisibleCells().map((cell) => (React.createElement(TableUI.BodyTd, { key: cell.id, verticalAlign: "top", onClick: () => {
                    setCheckedValue(cell.row.original.size);
                } }, flexRender(cell.column.columnDef.cell, cell.getContext())))))))))));
};
