import { Checkbox } from '@fourthwall/components';
import React from 'react';
import { useFormik } from '@fourthwall/utils/lib/formik';
import { FilterDropdown } from '@components/common/FilterDropdown';
import * as S from './styled';
export const OrdersFilterView = ({ statuses, initialValues, filterActive, onSubmit, onReset, }) => {
    const formik = useFormik({
        initialValues,
        onSubmit,
    });
    const handleReset = () => {
        formik.resetForm();
        onReset && onReset();
    };
    const handleCheckboxGroupChange = (checked, value, name) => {
        const fieldValue = checked
            ? [...formik.values[name], value]
            : formik.values[name].filter((v) => v !== value);
        formik.setFieldValue(name, fieldValue);
    };
    return (React.createElement(FilterDropdown, { content: React.createElement(S.Form, null, !!statuses?.length && (React.createElement(S.FiltersColumn, null,
            React.createElement(FilterDropdown.GroupTitle, { title: "Status" }),
            React.createElement(S.CheckboxGroup, { role: "group" }, statuses.map((status) => (React.createElement(Checkbox, { native: true, key: status.value, label: status.label, value: status.value, checked: formik.values.status.includes(status.value), onValueChange: (checked) => handleCheckboxGroupChange(checked, status.value, 'status') }))))))), activityIndicator: filterActive, onSubmit: formik.handleSubmit, onReset: handleReset }));
};
