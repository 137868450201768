import { formatCurrency } from '@fourthwall/utils/lib/currency';
import isNaN from 'lodash-es/isNaN';
import React from 'react';
import { withFormikSupport } from '../../hocs/withFormikSupport';
import { Input } from '../Input';
import { BLOCKED_NUMBER_CHARACTERS, MAXIMUM_AMOUNT } from './constants';
import { validateValue } from './utils';
const CurrencyInputComponent = ({ step = 0.01, shouldFormat = true, value, max = MAXIMUM_AMOUNT, error, onBlur, onChange, onValueChange, ...props }) => {
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
        if (onChange && value && !isNaN(parseFloat(value))) {
            onChange(shouldFormat ? formatCurrency(value) : value);
        }
    };
    const handleKeyDown = (e) => {
        if (BLOCKED_NUMBER_CHARACTERS.includes(e.key)) {
            e.preventDefault();
        }
    };
    const handleChange = (e) => {
        if (!validateValue(e.target.value, step, max)) {
            return;
        }
        if (onChange) {
            onChange(e);
        }
    };
    const handleValueChange = (v) => {
        if (!validateValue(v, step, max)) {
            return;
        }
        if (onValueChange) {
            onValueChange(v);
        }
    };
    return (React.createElement(Input, { value: value, error: error, type: "number", prepend: "$", hideSpinner: true, step: step, onKeyDown: handleKeyDown, onChange: handleChange, onValueChange: handleValueChange, onBlur: handleBlur, ...props }));
};
export const CurrencyInput = withFormikSupport(CurrencyInputComponent);
