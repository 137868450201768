import * as yup from 'yup';
import { FormFields } from './types';
const welcomePostSchema = yup.object().shape({
    type: yup.string(),
    title: yup.string().required().min(3),
    description: yup.string().nullable(),
    image: yup.mixed().when('postType', (postType) => {
        if (postType === 'Image') {
            return yup.mixed().required();
        }
        return yup.mixed();
    }),
    videoThumbnail: yup.mixed(),
    videoType: yup.string().required(),
    video: yup.mixed().when(['postType', 'videoType'], (postType, videoType) => {
        if (postType === 'Video' && videoType === 'upload') {
            return yup.mixed().required();
        }
        return yup.mixed();
    }),
    videoUrl: yup.string().when(['postType', 'videoType'], (postType, videoType) => {
        if (postType === 'Video' && videoType === 'embed') {
            return yup.string().required();
        }
        return yup.string();
    }),
});
export const getValidationSchema = (tiers) => {
    return yup.object().shape({
        [FormFields.usage]: yup.string().required(),
        [FormFields.welcomePost]: yup.object().when('usage', (usage) => {
            if (usage === 'same') {
                return welcomePostSchema;
            }
            return yup.object();
        }),
        [FormFields.welcomePosts]: yup.object().when('usage', (usage) => {
            const shape = tiers?.reduce((sum, tier) => {
                sum[tier.id] = welcomePostSchema;
                return sum;
            }, {});
            if (usage === 'different' && shape) {
                return yup.object().shape(shape);
            }
            return yup.object();
        }),
    });
};
