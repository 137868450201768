import React from 'react';
import { StatusTag } from '@components/common/StatusTag';
import { CollectionTableCell } from '../CollectionTableCell';
export const TABLE_HEADERS = [
    {
        id: 'name',
        label: 'Collection',
        render: ({ name, offerIds, thumbnail }) => (React.createElement(CollectionTableCell, { name: name, quantity: offerIds.length, photoUrl: thumbnail })),
        width: 5,
    },
    {
        id: 'status',
        label: 'Status',
        render: ({ state, timeFrame }) => React.createElement(StatusTag, { status: state.status, timeFrame: timeFrame }),
        width: 2,
    },
];
