import React from 'react';
import { useSendThankYou } from './hooks/useSendThankYou';
import { SendThankYouModalView } from './SendThankYouModalView';
export const SendThankYouModalContainer = ({ thankYous, onSuccess, }) => {
    const { thankYou, thankYouType, thankYouTypes, media, isSkipping, isUploading, isSuccess, setThankYouType, onThankYouSkip, onThankYouSend, onContinue, onClose, } = useSendThankYou({
        thankYous,
        onSuccess,
    });
    return (React.createElement(SendThankYouModalView, { thankYou: thankYou, thankYouType: thankYouType, thankYouTypes: thankYouTypes, media: media, isSkipping: isSkipping, isUploading: isUploading, isSuccess: isSuccess, onThankYouTypeChange: setThankYouType, onThankYouSkip: onThankYouSkip, onThankYouSend: onThankYouSend, onContinue: onContinue, onClose: onClose }));
};
