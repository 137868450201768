export const groupsPermissions = {
    home: {
        analytics: ['OWNER', 'MANAGER'],
        fulfillments: ['OWNER', 'MANAGER'],
        recommendations: ['OWNER', 'MANAGER'],
        guidesAndTips: ['OWNER', 'MANAGER'],
    },
    contributions: {
        profit: ['OWNER', 'MANAGER'],
        orders: {
            view: ['OWNER', 'MANAGER', 'CS', 'FULFILLMENT'],
            edit: ['OWNER', 'MANAGER', 'CS', 'FULFILLMENT'],
            cancel: ['OWNER', 'MANAGER', 'CS', 'FULFILLMENT'],
            refund: ['OWNER', 'MANAGER', 'CS', 'FULFILLMENT'],
            redoGiveaway: ['OWNER', 'MANAGER'],
        },
        donations: ['OWNER', 'MANAGER', 'CS'],
        thankYous: ['OWNER', 'MANAGER', 'CS', 'FULFILLMENT'],
        fulfillments: {
            view: ['OWNER', 'MANAGER', 'CS', 'FULFILLMENT'],
            upload: ['OWNER', 'MANAGER', 'CS', 'FULFILLMENT'],
        },
        messages: {
            view: ['OWNER'],
            send: ['OWNER'],
        },
    },
    products: {
        view: ['OWNER', 'MANAGER', 'CS', 'DESIGNER'],
        create: ['OWNER', 'MANAGER', 'DESIGNER'],
        edit: {
            additionalSections: ['OWNER', 'MANAGER', 'DESIGNER'],
            details: ['OWNER', 'MANAGER', 'DESIGNER'],
            status: ['OWNER', 'MANAGER', 'DESIGNER'],
            stock: ['OWNER', 'MANAGER'],
            url: ['OWNER', 'MANAGER', 'DESIGNER'],
        },
        thirdPartyLogistics: {
            view: ['OWNER', 'MANAGER', 'CS', 'FULFILLMENT'],
            edit: ['OWNER', 'MANAGER', 'CS', 'FULFILLMENT'],
        },
        duplicate: ['OWNER', 'MANAGER', 'DESIGNER'],
        orderSamples: ['OWNER', 'MANAGER', 'DESIGNER'],
        advancedSettings: ['OWNER', 'MANAGER', 'DESIGNER'],
        archive: ['OWNER', 'MANAGER', 'DESIGNER'],
    },
    collections: {
        view: ['OWNER', 'MANAGER', 'CS', 'DESIGNER'],
        create: ['OWNER', 'MANAGER', 'CS', 'DESIGNER'],
        edit: ['OWNER', 'MANAGER', 'CS', 'DESIGNER'],
    },
    promotions: {
        shopPromoCodes: ['OWNER', 'MANAGER'],
        membershipsPromoCodes: ['OWNER', 'MANAGER'],
        giveawayLinks: ['OWNER', 'MANAGER'],
    },
    analytics: {
        profit: ['OWNER', 'MANAGER'],
        totalMembers: ['OWNER', 'MANAGER'],
        membersChange: ['OWNER', 'MANAGER'],
        contributions: ['OWNER', 'MANAGER'],
        averageContributionValue: ['OWNER', 'MANAGER'],
        topProducts: ['OWNER', 'MANAGER'],
        salesByCountry: ['OWNER', 'MANAGER'],
        topTiers: ['OWNER', 'MANAGER'],
    },
    apps: ['OWNER', 'MANAGER'],
    themeEditor: {
        general: ['OWNER', 'MANAGER', 'DESIGNER'],
        layout: ['OWNER', 'MANAGER', 'DESIGNER'],
    },
    memberships: {
        setup: ['OWNER', 'MANAGER'],
        overview: ['OWNER', 'MANAGER'],
        post: {
            view: ['OWNER', 'MANAGER'],
            create: ['OWNER', 'MANAGER'],
        },
        messages: ['OWNER', 'MANAGER'],
        video: {
            view: ['OWNER', 'MANAGER'],
            create: ['OWNER', 'MANAGER'],
        },
        members: ['OWNER', 'MANAGER'],
        perksAndTiers: {
            createPerk: ['OWNER', 'MANAGER'],
            createTier: ['OWNER', 'MANAGER'],
        },
        settings: {
            general: ['OWNER', 'MANAGER'],
            messages: ['OWNER', 'MANAGER'],
            welcomePost: ['OWNER', 'MANAGER'],
            mobileApp: ['OWNER', 'MANAGER'],
            upselling: ['OWNER', 'MANAGER'],
        },
    },
    settings: {
        general: ['OWNER', 'MANAGER'],
        team: {
            view: ['OWNER', 'MANAGER'],
            invite: ['OWNER', 'MANAGER'],
            changeRole: ['OWNER', 'MANAGER'],
            remove: ['OWNER'],
        },
        shipping: ['OWNER', 'MANAGER'],
        domain: ['OWNER', 'MANAGER'],
        billing: {
            view: ['OWNER', 'MANAGER'],
            payouts: {
                setup: ['OWNER'],
                edit: ['OWNER'],
            },
            paymentMethods: ['OWNER', 'MANAGER'],
            history: {
                payouts: ['OWNER', 'MANAGER'],
                charges: ['OWNER', 'MANAGER'],
                full: ['OWNER', 'MANAGER'],
            },
        },
        samples: {
            view: ['OWNER', 'MANAGER'],
            create: ['OWNER', 'MANAGER'],
        },
        checkout: ['OWNER', 'MANAGER'],
        forDevelopers: {
            openAPI: ['OWNER', 'MANAGER'],
            webhooks: ['OWNER', 'MANAGER'],
        },
    },
    accountSettings: ['OWNER', 'MANAGER'],
    bookACall: ['OWNER', 'MANAGER'],
    visitState: ['OWNER', 'MANAGER'],
};
