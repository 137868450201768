import dayjs from 'dayjs';
import { Parser } from '@json2csv/plainjs';
import { flatten } from '@json2csv/transforms';
const deleteNullKeys = (obj) => {
    Object.keys(obj).forEach((key) => {
        if (obj[key] === null) {
            delete obj[key];
        }
        if (typeof obj[key] === 'object') {
            deleteNullKeys(obj[key]);
            if (Object.keys(obj[key]).length === 0) {
                delete obj[key];
            }
        }
    });
    return obj;
};
export const arrayToCsv = (data, fields) => {
    const parser = new Parser({ transforms: [deleteNullKeys, flatten()], fields });
    return parser.parse(data);
};
export const createCsvFilename = (basename) => {
    const date = dayjs(new Date()).format('YYYY-MM-DD');
    return `${basename}-${date}.csv`;
};
export const downloadTextFile = (content, filename) => {
    const button = document.createElement('a');
    const obj = new Blob([content], { type: 'text/plain' });
    button.href = window.URL.createObjectURL(obj);
    button.download = filename;
    button.click();
};
export const downloadCsvFile = (data, basename, fields) => {
    const content = arrayToCsv(data, fields);
    const filename = createCsvFilename(basename);
    downloadTextFile(content, filename);
};
