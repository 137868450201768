import React from 'react';
import { Field, Form, useFormikContext } from 'formik';
import { Checkbox, Fieldset } from '@fourthwall/components';
import { useFocusFirstErrorOnSubmit } from '@fourthwall/utils/lib/formik';
import { ChannelAutoComplete, ChannelInput, CreateSiteModal, CreatorNameInput, LocationSelect, PlatformSelect, SiteNameInput, isPlatformSearchable, } from '@fourthwall/module-create-site';
import * as S from './CreateNewSiteModalView.styled';
export const CreateNewSiteModalView = ({ searchChannel }) => {
    const { values, isSubmitting } = useFormikContext();
    useFocusFirstErrorOnSubmit();
    const renderChannel = (platformValue, isSubmitting) => {
        if (isPlatformSearchable(platformValue) && !searchChannel.manualURLType) {
            return (React.createElement(Field, { component: ChannelAutoComplete, name: "searchedChannel", platformValue: platformValue, autoCompletePlacement: "top", disabled: isSubmitting, ...searchChannel }));
        }
        return (React.createElement(Field, { component: ChannelInput, name: "channelUrl", platformValue: platformValue, disabled: isSubmitting }));
    };
    return (React.createElement(Form, null,
        React.createElement(CreateSiteModal, { isSubmitting: isSubmitting },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { component: SiteNameInput, name: "siteName", disabled: isSubmitting }))),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { component: Checkbox, name: "siteForSomeoneElse", label: "This site is for someone else", disabled: isSubmitting }))),
                values.siteForSomeoneElse && (React.createElement(React.Fragment, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Field, { component: CreatorNameInput, name: "creatorName", disabled: isSubmitting }))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Field, { component: LocationSelect, name: "location", label: "Creator's location", disabled: isSubmitting }))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(S.FieldLabel, null, "Creator's primary platform"),
                            React.createElement(Field, { component: PlatformSelect, name: "platform", disabled: isSubmitting }))),
                    values.platform && (React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null, renderChannel(values.platform, isSubmitting))))))))));
};
