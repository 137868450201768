export const STATUSES = {
    LIVE: {
        label: 'LIVE',
        appearance: 'success',
    },
    ENDED: {
        label: 'ENDED',
        appearance: 'neutral',
    },
    ARCHIVED: {
        label: 'ARCHIVED',
        appearance: 'neutral',
    },
    ALL_USED: {
        label: 'ALL USED',
        appearance: 'neutral',
    },
};
