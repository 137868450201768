import styled, { css } from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;
export const Tab = styled.button.attrs({ type: 'button' }) `
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: ${Colors.GS100};
  position: relative;
  -webkit-tap-highlight-color: transparent;
  padding: 4px;

  &::after {
    content: '';
    display: none;
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${Colors.GS100};
  }

  ${({ active }) => active &&
    css `
      &::after {
        display: block;
      }
    `}
`;
