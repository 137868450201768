import { Icon, Loader } from '@fourthwall/components';
import { RefreshIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
export const SkipButton = ({ isLoading, onClick }) => {
    const renderIcon = () => {
        if (isLoading) {
            return (React.createElement(S.Loader, null,
                React.createElement(Loader, { size: "small", appearance: "primary" })));
        }
        return React.createElement(Icon, { component: RefreshIcon, height: 18 });
    };
    return (React.createElement(S.Container, { onClick: onClick },
        renderIcon(),
        "Skip"));
};
