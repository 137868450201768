import { PriceString } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import React from 'react';
import { getPromotionTitle, isShopPromotion } from '../../../../utils';
import * as S from './styled';
export const PromotionCodeTableCell = ({ promotion }) => {
    const getDiscountType = () => {
        if (promotion.type === 'SINGLE' || promotion.type === 'MEMBERSHIPS_SINGLE') {
            return 'Promo code';
        }
        if (promotion.type === 'AUTO_APPLYING') {
            return 'Auto-applied';
        }
        return 'Multiple promo codes';
    };
    const getDiscountValue = () => {
        if (promotion.discount.type === 'FLAT_RATE') {
            return (React.createElement(React.Fragment, null,
                React.createElement(PriceString, { value: promotion.discount.money.value }),
                " off"));
        }
        if (promotion.discount.type === 'PERCENTAGE' ||
            promotion.discount.type === 'MEMBERSHIPS_PERCENTAGE') {
            return `${promotion.discount.percentage}% off`;
        }
        if (promotion.discount.type === 'FREE_SHIPPING') {
            return 'free shipping for';
        }
        return '';
    };
    const getDiscountProducts = () => {
        if (!isShopPromotion(promotion)) {
            return null;
        }
        if (promotion.appliesTo.type === 'SELECTED_PRODUCTS' && promotion.appliesTo.products.length) {
            return `${pluralize(promotion.appliesTo.products.length, 'a product', `${promotion.appliesTo.products.length} products`)}`;
        }
        return 'entire order';
    };
    const getTiers = () => {
        if (isShopPromotion(promotion)) {
            return null;
        }
        if (promotion.appliesToTiers.type === 'MEMBERSHIPS_TIERS_ALL_TIERS') {
            return 'all membership tiers';
        }
        if (promotion.appliesToTiers.type === 'MEMBERSHIPS_TIERS_SELECTED_TIERS') {
            return 'selected membership tiers';
        }
    };
    const getRequirements = () => {
        if (!isShopPromotion(promotion)) {
            return undefined;
        }
        if (promotion.requirements.minimumOrderValue?.value) {
            return (React.createElement(React.Fragment, null,
                "over ",
                React.createElement(PriceString, { value: promotion.requirements.minimumOrderValue.value })));
        }
        return undefined;
    };
    return (React.createElement("div", null,
        React.createElement(S.Code, null, getPromotionTitle(promotion)),
        React.createElement(S.Summary, null,
            getDiscountType(),
            " for ",
            getDiscountValue(),
            " ",
            getTiers(),
            " ",
            getDiscountProducts(),
            ' ',
            getRequirements())));
};
