import { Button, Fieldset, InputUI, ModalBox, RadioButton, useModalContext, } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { PaymentMethodsList } from '../PaymentMethodsList';
const validationSchema = Yup.object().shape({
    defaultPaymentMethodId: Yup.string().when('choice', {
        is: (choice) => choice === 'select-new-default',
        then: Yup.string().required(),
    }),
});
export const SelectNewDefaultPaymentMethod = ({ removedPaymentMethod, remainingPaymentMethods, onSubmit, }) => {
    const { close } = useModalContext();
    const formik = useFormik({
        initialValues: {
            choice: 'select-new-default',
            removedPaymentMethodId: removedPaymentMethod.id,
            defaultPaymentMethodId: '',
        },
        validationSchema,
        onSubmit: ({ defaultPaymentMethodId }) => {
            onSubmit({ defaultPaymentMethodId });
            close();
        },
    });
    useEffect(() => {
        if (formik.values.choice === 'dont-select-new-default') {
            formik.setFieldValue('defaultPaymentMethodId', '');
            formik.setFieldTouched('defaultPaymentMethodId', false);
        }
    }, [formik.values.choice]);
    const paymentMethods = remainingPaymentMethods.map((paymentMethod) => {
        const selected = formik.values.defaultPaymentMethodId === paymentMethod.id;
        return {
            ...paymentMethod,
            selected,
            onClick: () => {
                formik.setFieldValue('defaultPaymentMethodId', selected ? '' : paymentMethod.id);
                if (!selected && formik.values.choice !== 'select-new-default') {
                    formik.setFieldValue('choice', 'select-new-default');
                }
            },
        };
    });
    const renderDescription = () => {
        if (removedPaymentMethod.type === 'CARD') {
            return (React.createElement("p", null,
                "You\u2019re about to remove a default payment method (",
                removedPaymentMethod.type,
                " ending in",
                ' ',
                removedPaymentMethod.number,
                "). We recommend selecting a new default payment method."));
        }
        // PAYPAL
        return (React.createElement("p", null,
            "You\u2019re about to remove a default payment method (PayPal: ",
            removedPaymentMethod.email,
            "). We recommend selecting a new default payment method."));
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(ModalBox, { title: "Select a new default payment method", buttons: [
                React.createElement(Button, { label: "Cancel", onClick: close }),
                React.createElement(Button, { label: "Proceed", appearance: "primary", type: "submit" }),
            ], onClose: close },
            renderDescription(),
            React.createElement("div", { style: { marginTop: 20 } },
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(RadioButton, { label: "Select a new default payment method:", checkedValue: "select-new-default", ...formik.getFieldProps('choice') }),
                            React.createElement("div", { style: { marginTop: 12, marginLeft: 36 } },
                                React.createElement(PaymentMethodsList, { paymentMethods: paymentMethods }),
                                formik.errors.defaultPaymentMethodId && (React.createElement(InputUI.Error, null, formik.errors.defaultPaymentMethodId))))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(RadioButton, { label: "I don\u2019t want to use a default payment method", suggestion: "Not having a default payment method will result in transactions that incur losses (such as giveaways) being blocked if your account balance is not sufficient to cover the cost.", checkedValue: "dont-select-new-default", ...formik.getFieldProps('choice') }))))))));
};
