export const getRolesTableHeaders = () => [
    {
        id: 'role',
        label: 'Role',
        render: ({ name }) => name,
        width: 60,
    },
    {
        id: 'membersAssigned',
        label: 'Members Assigned',
        render: ({ membersCount }) => membersCount,
        width: 35,
    },
];
