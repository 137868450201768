import { Avatar, Checkbox, NumberString, PriceString } from '@fourthwall/components';
import React from 'react';
import { PhotoTableCellContent } from '@components/common/PhotoTableCellContent';
import { TableCell } from '@components/common/TableCell';
import * as S from './Table.styled';
export const getTableHeaders = (selectedItemsIds, onCheckboxClick) => {
    return [
        {
            id: 'select',
            label: '',
            render: (item) => (React.createElement(TableCell, { mainContent: React.createElement(Checkbox, { value: !!selectedItemsIds.find((itemId) => item.id === itemId), onValueChange: onCheckboxClick(item) }) })),
            width: 0,
        },
        {
            id: 'name',
            label: '',
            render: (item) => {
                if (item.showAvatar) {
                    return (React.createElement(S.Avatar, null,
                        React.createElement(Avatar, { id: item.id, name: item.name }),
                        " ",
                        React.createElement(S.AvatarName, null, item.name)));
                }
                return (React.createElement(TableCell, { mainContent: React.createElement(PhotoTableCellContent, { name: item.name, photo: item.imageUrl, photoSize: "large" }) }));
            },
            width: 8,
        },
        {
            id: 'additional',
            label: '',
            render: (item) => {
                if (item.supportersTotal) {
                    return (React.createElement(TableCell, { mainContent: React.createElement(S.Additional, null,
                            React.createElement(NumberString, { value: item.supportersTotal }),
                            " supporters") }));
                }
                if (item.contributedAmount) {
                    return (React.createElement(TableCell, { mainContent: React.createElement(S.Additional, null,
                            React.createElement(PriceString, { value: item.contributedAmount }),
                            " contributed") }));
                }
            },
            width: 4,
        },
    ];
};
