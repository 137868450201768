import { Button, ButtonGroup, Checkbox, ModalBox, Table, Tooltip, useMediaQueryContext, } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const GiveawayTwitchRedoView = ({ tableItems, submitButton, close, }) => {
    const media = useMediaQueryContext();
    const renderSubtitle = () => {
        if (!tableItems?.length) {
            return (React.createElement(React.Fragment, null, "This gift currently has no winner. You can redo the giveaway to immediately begin a new entry window on your stream."));
        }
        return (React.createElement(React.Fragment, null, "Select the giveaways you want to redo. Existing winners will no longer be able to redeem. The new giveaways will begin immediately on your stream."));
    };
    const renderTable = () => {
        if (!tableItems?.length) {
            return null;
        }
        return (React.createElement(S.Winners, null,
            React.createElement(Table, { data: tableItems, minWidth: 0, headers: [
                    {
                        id: 'winner',
                        label: 'Twitch gifting winner',
                        height: 48,
                        width: 1,
                        render: ({ name, isSelected, isDisabled, onClick }) => (React.createElement(Checkbox, { value: isSelected, disabled: isDisabled, label: name, onValueChange: onClick })),
                    },
                    {
                        id: 'redeemed',
                        label: 'Redemeed',
                        height: 48,
                        width: 0,
                        minWidth: 120,
                        alignment: 'right',
                        render: ({ redeemDate }) => redeemDate || 'Not redeemed',
                    },
                ] })));
    };
    return (React.createElement(ModalBox, { title: "Redo giveaway on Twitch", onClose: close, size: "small" },
        renderSubtitle(),
        renderTable(),
        React.createElement(S.Buttons, null,
            React.createElement(ButtonGroup, { justify: "right", align: "center", space: media.tablet ? 'large' : 'medium', direction: media.tablet ? 'row' : 'column' },
                React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close }),
                React.createElement(Tooltip, { reference: React.createElement(Button, { label: submitButton.label, appearance: "primary", disabled: submitButton.disabled, loading: submitButton.loading, onClick: submitButton.onClick }), placement: "top", fluid: true }, submitButton.tooltip && React.createElement(S.TooltipContent, null, submitButton.tooltip))))));
};
