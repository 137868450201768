import React, { useState } from 'react';
import { SelectModal } from '../../../../components/modals/SelectModal';
import { getTableHeaders } from '../components/Table/getHeaders';
import { SelectableTable } from '../components/Table/Table';
import { getMessage } from './utils/getMessage';
import * as S from './styled';
export const SelectableList = ({ selectedItemsIds = [], items, isFetching = false, hasNextPage = false, onLoadMoreItems, initialInputValue, onSave, close, itemName = '', tableEmptyStateMsg = '', tableTitle = '', searchPlaceholder = '', disabledInput = true, }) => {
    const [inputValue, setInputValue] = useState(initialInputValue);
    const [innerItemsIds, setInnerItemsIds] = useState(selectedItemsIds);
    const handleSubmitClick = () => {
        if (onSave) {
            onSave(innerItemsIds, inputValue);
        }
        if (close) {
            close();
        }
    };
    const handleCheckboxChange = (item) => (checked) => {
        if (checked) {
            return setInnerItemsIds((prevInnerItemsIds) => [item.id, ...prevInnerItemsIds]);
        }
        return setInnerItemsIds((prevInnerItemsIds) => prevInnerItemsIds.filter((itemId) => itemId !== item.id));
    };
    return (React.createElement(SelectModal, { title: tableTitle, message: getMessage({ itemName, itemsCount: innerItemsIds.length }), inputProps: {
            value: inputValue,
            placeholder: searchPlaceholder,
            disabled: disabledInput,
            onValueChange: setInputValue,
        }, content: React.createElement(S.TableContainer, { id: "scrollableDiv" },
            React.createElement(SelectableTable, { items: items, headers: getTableHeaders(innerItemsIds, handleCheckboxChange), tableEmptyStateMsg: tableEmptyStateMsg, isFetching: isFetching, hasNextPage: hasNextPage, onLoadMoreItems: onLoadMoreItems })), close: close, onSubmitClick: handleSubmitClick }));
};
