import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
const getColor = ({ appearance, theme }) => {
    if (theme.automated?.styles?.paleTextColor) {
        return theme.automated?.styles.paleTextColor;
    }
    if (appearance === 'primary') {
        return Colors.GS1000;
    }
    if (appearance === 'destructive') {
        return Colors.R300;
    }
    return Colors.GS600;
};
export const IconContainer = styled.div `
  position: relative;
  top: -1px;
  display: flex;
  align-self: center;
  padding-right: 8px;
`;
const getHoverColor = ({ disabled, appearance, theme }) => {
    if (!disabled) {
        if (theme.automated?.styles?.colorOnBackground) {
            return theme.automated?.styles.colorOnBackground;
        }
        if (appearance === 'primary') {
            return Colors.B500;
        }
        if (appearance === 'destructive') {
            return Colors.R500;
        }
        return Colors.GS1000;
    }
};
export const Container = styled.button `
  display: inline-flex;

  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 150%;
  color: ${getColor};

  appearance: none !important; // NOTE: Required for Safari.

  &:hover,
  &:active,
  &:focus {
    color: ${getHoverColor};
  }

  &:hover {
    ${IconContainer} {
      svg > path {
        stroke: ${getHoverColor};
      }
    }
  }

  ${({ $withUnderline }) => $withUnderline &&
    css `
      text-decoration-line: underline;
    `}

  ${({ disabled }) => disabled &&
    css `
      cursor: not-allowed;
    `}
`;
