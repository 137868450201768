import { Button, Fieldset, Input, ModalBox, Select, useModalContext } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React, { useMemo } from 'react';
import { Tag } from '../../../../../../../Memberships/components/Tag';
import { validationSchema } from './consts';
import * as S from './styled';
export const ConfigureWebhookModal = ({ initialValues, eventOptions, onSubmit }) => {
    const { close } = useModalContext();
    const formik = useFormik({
        initialValues: {
            url: initialValues?.url || '',
            events: initialValues?.events || [],
        },
        validationSchema,
        onSubmit: (values) => {
            close();
            onSubmit(values);
        },
    });
    const selectOptions = useMemo(() => {
        return eventOptions.filter((option) => !formik.values.events.includes(option.value));
    }, [eventOptions, formik.values.events]);
    const handleAddEvent = (event) => {
        const activeElement = window.document.activeElement;
        activeElement?.blur();
        formik.setFieldValue('events', [...formik.values.events, event]);
    };
    const handleRemoveEvent = (event) => {
        formik.setFieldValue('events', formik.values.events.filter((existingEvent) => existingEvent !== event));
    };
    const getTagLabel = (event) => {
        return eventOptions.find((option) => option.value === event)?.label || '';
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(ModalBox, { title: "Create webhook", size: "small", buttons: [
                React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close }),
                React.createElement(Button, { label: "Save", appearance: "primary", type: "submit" }),
            ], onClose: close },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, { flex: 1 },
                        React.createElement(Input, { label: "URL", ...formik.getFieldProps('url') }))),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, { flex: 1 },
                        React.createElement(Select, { label: "Add event", value: "", disabled: !selectOptions.length, options: selectOptions, error: formik.getFieldProps('events').error, onValueChange: handleAddEvent }))),
                formik.values.events.length > 0 && (React.createElement(S.EventsContainer, null, formik.values.events.map((event) => (React.createElement(Tag, { key: event, label: getTagLabel(event), onRemove: () => handleRemoveEvent(event) })))))))));
};
