import { Subsection, useMediaQueryContext, useModalContext } from '@fourthwall/components';
import React from 'react';
import { useFormikContext } from 'formik';
import { SelectProducts } from '@modules/Dashboard/SelectProducts';
import { SelectedProducts } from '@components/common/SelectedProducts';
import { useOffersByIdsQuery } from '@hooks/useOffersByIdsQuery';
import { YOU_FULFILL } from '@modules/Dashboard/Product/store/product/utils';
export const Products = ({ isSelfFulfilled }) => {
    const media = useMediaQueryContext();
    const { open } = useModalContext();
    const { values, setFieldValue, errors, submitCount } = useFormikContext();
    const { offersByIdsQuery } = useOffersByIdsQuery([values.selectedProductsIds || []]);
    const openSelectProductsModal = () => open(SelectProducts, {
        selectedProductsIds: values.selectedProductsIds,
        submitButtonLabel: 'Confirm',
        params: {
            fulfillmentService: isSelfFulfilled ? YOU_FULFILL : undefined,
        },
        onSave: (selectedProductsIds) => {
            setFieldValue('selectedProductsIds', selectedProductsIds);
        },
    });
    return (React.createElement(Subsection, { title: "Products in this group", borderless: true, noMargin: true },
        React.createElement("div", { style: { marginTop: -8 } },
            React.createElement(SelectedProducts, { maxHeight: media.laptop ? 400 : 290, data: Object.values(offersByIdsQuery.data || []), isLoading: offersByIdsQuery.isLoading, productsCount: values.selectedProductsIds.length, error: submitCount ? errors.selectedProductsIds : undefined, onAddProducts: openSelectProductsModal, onRemoveProduct: (removedProductId) => {
                    setFieldValue('selectedProductsIds', values.selectedProductsIds.filter((productId) => productId !== removedProductId));
                } }))));
};
