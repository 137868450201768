export const getUpdateAccountSettingsTiersPayload = (formValues) => {
    const { annualEnabled, freeAccounts, contentVisibility, membersCountEnabled, anualDiscount, adultContent, audioDownload, } = formValues;
    const getAnnualPayload = () => {
        if (annualEnabled && anualDiscount) {
            return {
                annual: {
                    percentOff: parseInt(anualDiscount),
                },
            };
        }
        return undefined;
    };
    return {
        contentVisibility,
        freeAccounts: {
            displayEnabled: freeAccounts === 'show',
        },
        membersCountEnabled,
        ...getAnnualPayload(),
        adultContent: {
            present: adultContent,
        },
        mediaDownload: {
            audio: {
                enabled: audioDownload,
            },
        },
    };
};
