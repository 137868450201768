import { themeAutomator } from './themeAutomator';
export const getInternalTheme = (colorsScheme) => {
    return themeAutomator(colorsScheme, true);
};
export const getAutomatedStyleProp = (prop, defaultValue) => {
    return ({ theme }) => {
        if (!theme.automated) {
            return defaultValue;
        }
        return theme.automated?.styles?.[prop] || defaultValue;
    };
};
