import { addUtmSourceParamToUrl } from '../../../utils/url';
import membershipsStrategies from './assets/5-memberships-strategies.png';
import launchingMemberships from './assets/launching-memberships.png';
import membershipsQuickStart from './assets/memberships-quick-start.png';
import perksGuide from './assets/perks-guide.png';
export const ALLOWED_AUDIO_EXTENSIONS = {
    'audio/mpeg': ['.mp3'],
    'audio/wav': ['.wav', '.x-wav'],
    'audio/ogg': ['.ogg'],
    'audio/x-m4a': ['.m4a', '.x-m4a'],
    'audio/aac': ['.aac'],
    'audio/flac': ['.flac'],
    'audio/x-aiff': ['.aiff', '.aifc', '.x-aiff'],
};
export const ALLOWED_VIDEO_EXTENSIONS = {
    'video/mp4': ['.mp4'],
    'video/quicktime': ['.mov', '.qt'],
    'video/x-ms-wmv': ['.wmv'],
    'video/x-ms-asf': ['.asf'],
    'video/avi': ['.avi', '.avf'],
    'application/x-troff-msvideo': ['.avf'],
    'video/x-matroska': ['.mkv'],
    'video/ogg': ['.ogg', '.ogv'],
    'video/webm': ['.webm'],
};
export const MIN_POLL_OPTIONS = 2;
export const MAX_POLL_OPTIONS = 5;
export const MAX_IMAGE_POST_IMAGES = 50;
export const YOUTUBE_REGEX = /^(http(s)?:\/\/)?(m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(v\/|watch\?v=|live\/|watch\?t=\d+&v=))(?<id>(\w|-){11})((\?|\&)\S*)?$/;
export const VIMEO_REGEX = /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?<id>\d+)\/?(?<unlistedHash>[\w-]*)?(?:\?\S*)?$/;
export const VIMEO_LIVESTREAM_REGEX = /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/event\/(?<id>\d+)\/?(?<unlistedHash>[\w-]*)?(?:\?\S*)?$/;
export const STREAMABLE_REGEX = /^(?:https?:\/\/)?(?:www\.)?streamable\.com(?:\/[eo])?\/(?<id>[\w-]+)\??/;
export const launchingMembershipsArticle = {
    title: 'Launching Memberships',
    description: 'Posts, promotions, and other best practices for a successful Memberships launch',
    imageSrc: launchingMemberships,
    timeToRead: '4 min read',
    url: addUtmSourceParamToUrl('https://help.fourthwall.com/hc/en-us/articles/13331336631963-Launching-Memberships-', 'onboarding'),
};
export const quickStartGuideArticle = {
    title: 'Memberships Quick Start Guide',
    description: 'Your guide to setting up memberships, creating tiers and perks, and preparing for launch',
    imageSrc: membershipsQuickStart,
    timeToRead: '6 min read',
    url: addUtmSourceParamToUrl('https://help.fourthwall.com/hc/en-us/articles/13331327040539-Memberships-Quick-Start-Guide', 'onboarding'),
};
export const membershipsStrategiesArticle = {
    title: '5 Memberships Strategies from Fourthwall Creators',
    description: 'Inspiration to help you select the right strategy for you, from a passive-only rewards to exclusive content (and everything in between)',
    imageSrc: membershipsStrategies,
    timeToRead: '7 min read',
    url: addUtmSourceParamToUrl('https://fourthwall.com/blog/5-memberships-strategies-from-fourthwall-creators/', 'onboarding'),
};
export const perksGuideArticle = {
    title: 'Perks: A Guide to Rewarding Your Members',
    description: 'Learn about the different approaches to offering memberships, from passive rewards to exclusive content (and everything in between)',
    imageSrc: perksGuide,
    timeToRead: '7 min read',
    url: addUtmSourceParamToUrl('https://help.fourthwall.com/hc/en-us/articles/13331279583387-Perks-A-Guide-to-Rewarding-Your-Members', 'onboarding'),
};
