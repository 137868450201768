import styled from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  padding: 17px;
  border: 1px solid ${Colors.GS400};

  display: grid;
  gap: 16px;
  align-items: center;

  ${mediaQueries.tablet} {
    padding: 17px 24px;

    grid-template-columns: auto 1fr auto;
    gap: 24px;
  }
`;
