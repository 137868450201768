import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import React from 'react';
dayjs.extend(advancedFormat);
dayjs.extend(utc);
export const DateString = ({ date, format = 'DD MMM YYYY', utc = false }) => {
    let day = dayjs(date);
    if (!day.isValid()) {
        return null;
    }
    if (utc) {
        day = day.utc();
    }
    return React.createElement("time", { dateTime: date }, day.format(format));
};
