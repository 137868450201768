import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const CountriesContainer = styled.div `
  height: 100%;
  overflow-y: auto;
  border-top: 1px solid ${Colors.GS400};

  ${mediaQueries.tablet} {
    height: 456px;
  }
`;
export const CountryContainer = styled.div `
  padding: 16px 0;
  display: flex;
  align-items: center;

  & + & {
    border-top: 1px solid ${Colors.GS400};
  }
`;
export const CountryCheckboxLabel = styled.div `
  margin-left: 8px;
`;
export const EmptyMessageContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
