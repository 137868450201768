import { useModalContext } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { ShippingLocationModal } from '../../components/ShippingLocationModal/ShippingLocationModal';
import { ShippingLocationView } from './ShippingLocationView';
export const ShippingLocationContainer = () => {
    const formik = useFormikContext();
    const { open } = useModalContext();
    const handleShippingLocationModalSubmit = (values) => {
        formik.setFieldValue('shippingLocation', values);
    };
    const handleSetupShippingLocation = () => {
        open(ShippingLocationModal, {
            shippingLocation: formik.values.shippingLocation,
            title: formik.values.shippingLocation
                ? 'Edit your shipping location'
                : 'Setup your shipping location',
            onSubmit: handleShippingLocationModalSubmit,
        });
    };
    return (React.createElement(ShippingLocationView, { shippingLocation: formik.values.shippingLocation, onSetupShippingLocation: handleSetupShippingLocation }));
};
