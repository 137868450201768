import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { DashboardBackButton } from '@components/utils';
import { Banner, BaseContent, Button, ButtonClean, Fieldset, HeadContent, Input, InputUI, Select, Subsection, Tag, } from '@fourthwall/components';
import { ConfirmBarContainer } from '@modules/Dashboard/ConfirmBar';
import { routing } from '@utils/routing';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { mapStatusToTagProps } from '../../utils';
import { ConnectValidationSchema } from './consts';
import { useShipStation } from './hooks/useShipStation';
export const AppsShipStation = () => {
    const { shipStation } = useShipStation();
    const renderStatusTag = () => {
        const props = mapStatusToTagProps(shipStation.status);
        if (!props)
            return null;
        return (React.createElement("div", { style: { marginBottom: 20 } },
            React.createElement(Tag, { ...props, size: "large", display: "inline-flex" })));
    };
    const renderConnectionForm = () => {
        if (shipStation.isLoading || shipStation.status !== 'NOT_CONNECTED') {
            return null;
        }
        return (React.createElement(Subsection, { title: "Connection" },
            React.createElement(Formik, { initialValues: shipStation.connection.initialValues, validationSchema: ConnectValidationSchema, onSubmit: shipStation.connection.onSubmit }, () => (React.createElement(Form, null,
                React.createElement("div", { style: { marginBottom: 16 } },
                    "Connect your ShipStation account using your API key and secret from your",
                    ' ',
                    React.createElement(ButtonClean, { label: "ShipStation API keys page", href: routing.external.shipStation.apiSettings, target: "_blank" }),
                    ' ',
                    "in your ShipStation account."),
                React.createElement(Fieldset, null,
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Field, { component: Input, name: "apiKey", label: "API key" }))),
                    React.createElement(Fieldset.Row, null,
                        React.createElement(Fieldset.Item, null,
                            React.createElement(Field, { component: Input, name: "apiSecret", label: "API Secret" })))),
                React.createElement("div", { style: { marginTop: 16 } },
                    React.createElement(Button, { type: "submit", label: "Connect to ShipStation", appearance: "primary", loading: shipStation.connection.isSubmitting })))))));
    };
    const renderSettingsForm = () => {
        if (shipStation.isLoading || shipStation.status === 'NOT_CONNECTED') {
            return null;
        }
        return (React.createElement(Subsection, { title: "Connection" },
            React.createElement(Formik, { initialValues: shipStation.settings.initialValues, enableReinitialize: true, onSubmit: shipStation.settings.onSubmit }, (settingsFormik) => (React.createElement(React.Fragment, null,
                React.createElement(ConfirmBarContainer, { isLoading: shipStation.settings.isSubmitting, isOpen: settingsFormik.dirty, onConfirm: settingsFormik.handleSubmit, onCancel: settingsFormik.resetForm }),
                shipStation.status === 'CONNECTED' && (React.createElement("div", { style: { marginBottom: 16 } },
                    React.createElement("p", null,
                        "Connected to ShipStation account.",
                        ' ',
                        React.createElement(ButtonClean, { label: "Disconnect", onClick: shipStation.onDisconnect })))),
                shipStation.status === 'NOT_CONFIGURED' && (React.createElement("div", { style: { marginBottom: 16 } },
                    React.createElement(Banner, { appearance: "warning" }, "Please select your store to finish configuration"))),
                React.createElement("form", { onSubmit: settingsFormik.handleSubmit },
                    React.createElement(Fieldset, null,
                        React.createElement(Fieldset.Row, null,
                            React.createElement(Fieldset.Item, null,
                                React.createElement(Field, { component: Select, name: "storeId", options: shipStation.settings.stores, label: "ShipStation store", disabled: !shipStation.settings.stores.length, displayError: false }),
                                !shipStation.settings.isLoading && !shipStation.settings.stores.length && (React.createElement(InputUI.Error, null, "Please add a store in your ShipStation account and refresh this page.")))))),
                React.createElement("div", { style: { marginTop: 16 } },
                    React.createElement(BaseContent, null,
                        React.createElement("ul", null,
                            React.createElement("li", null, "Orders canceled on ShipStation are automatically cancelled on Fourthwall as well."),
                            React.createElement("li", null, "Orders edited on ShipStation (quantity change, product removal, etc.) will NOT automatically be edited on Fourthwall."),
                            React.createElement("li", null, "It takes 24 hours for new orders to be synced. Cancellations are synced instantly.")))))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "ShipStation" }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: "ShipStation", topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }) },
                React.createElement(Subsection, null,
                    renderStatusTag(),
                    React.createElement("div", null,
                        "Use ShipStation to manage the shipping of products you\u2019re responsible for fulfilling.",
                        ' ',
                        React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.shipStationIntegration, target: "_blank" }))),
                renderConnectionForm(),
                renderSettingsForm()))));
};
