import { useModalContext } from '@fourthwall/components';
import { useState } from 'react';
import { getThankYouTypeFromData } from '../utils';
import { useSendThankYouMedia } from './useSendThankYouMedia';
import { useSkipKudoMutation } from './useSkipKudoMutation';
import { useUploadKudoMutation } from './useUploadKudoMutation';
export const useSendThankYou = ({ thankYous, onSuccess }) => {
    const { close } = useModalContext();
    const [currentThankYouIndex, setCurrentThankYouIndex] = useState(0);
    const thankYou = thankYous[currentThankYouIndex];
    const [thankYouType, setThankYouType] = useState(getThankYouTypeFromData(thankYou));
    const { skipKudoMutation } = useSkipKudoMutation();
    const { uploadKudoMutation } = useUploadKudoMutation();
    const [isAnyThankYouSentOrSkipped, setIsAnyThankYouSentOrSkipped] = useState(false);
    const isLastThankYou = currentThankYouIndex === thankYous.length - 1;
    const thankYouMedia = useSendThankYouMedia(thankYouType, thankYou);
    const onThankYouSkip = () => {
        skipKudoMutation.mutate([{ orderId: thankYou.id }], {
            onSuccess: () => {
                setIsAnyThankYouSentOrSkipped(true);
                if (isLastThankYou) {
                    onSuccess?.();
                    close();
                }
                else {
                    setCurrentThankYouIndex((prev) => prev + 1);
                }
            },
        });
    };
    const onThankYouSend = () => {
        if (!thankYouMedia.preview || !thankYouMedia.preview.file)
            return;
        const payload = new FormData();
        payload.append('file', thankYouMedia.preview.file);
        payload.append('metadata', new Blob([
            JSON.stringify({
                recordedAt: new Date().toISOString(),
                imageSize: thankYouMedia.preview.imageSize,
                source: 'Web',
            }),
        ], {
            type: 'application/json',
        }));
        uploadKudoMutation.mutate([{ orderId: thankYou.id }, payload], {
            onSuccess: () => {
                setIsAnyThankYouSentOrSkipped(true);
            },
        });
    };
    const onContinue = () => {
        setCurrentThankYouIndex((prev) => prev + 1);
        thankYouMedia.onRecordDelete();
        uploadKudoMutation.reset();
    };
    const onClose = () => {
        if (isAnyThankYouSentOrSkipped) {
            onSuccess?.();
        }
    };
    return {
        thankYou,
        thankYouType,
        thankYouTypes: ['VIDEO', 'AUDIO', 'UPLOAD'],
        media: thankYouMedia,
        isSkipping: skipKudoMutation.isLoading,
        isUploading: uploadKudoMutation.isLoading,
        isSuccess: uploadKudoMutation.isSuccess,
        setThankYouType,
        onThankYouSkip: thankYou.state === 'WAITING' ? onThankYouSkip : undefined,
        onThankYouSend,
        onContinue: isLastThankYou ? undefined : onContinue,
        onClose,
    };
};
