import { CheckboxIndeterminate, List, TableSortableUI, TableUI, useMediaQueryContext, } from '@fourthwall/components';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { reverse } from 'named-urls';
import { routing } from '@utils/routing';
import { useProductsTableColumns } from './hooks/useProductsTableColumns';
import * as S from './ProductsData.styled';
import { ProductListItem } from './components/ProductListItem';
export const ProductsData = ({ products, currentPage, isLoading, emptyComponent, onArchiveSelectedOffersClick, getLinkToProduct, onDuplicateOffer, onArchiveOffer, onProductPositionSet, onProductPositionChange, onToggleMultipleOfferStatus, }) => {
    const media = useMediaQueryContext();
    const [rowSelection, setRowSelection] = useState({});
    const { hasPermission } = useDashboardPermissions();
    const { activeId, listItemsOrdered, dndContextProps, sortableContextProps } = TableSortableUI.useTableSortable({
        list: products,
        onPositionChange: onProductPositionChange,
    });
    useEffect(() => {
        setRowSelection({});
    }, [currentPage]);
    const columns = useProductsTableColumns({
        onArchiveSelectedOffersClick: (offerIds) => {
            onArchiveSelectedOffersClick(offerIds, () => {
                setRowSelection({});
            });
        },
        onToggleMultipleOfferStatus: (offerIds) => {
            onToggleMultipleOfferStatus(offerIds, () => {
                setRowSelection({});
            });
        },
    });
    const table = useReactTable({
        data: listItemsOrdered,
        columns,
        state: {
            rowSelection,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
    });
    const selectedRow = useMemo(() => {
        if (!activeId) {
            return null;
        }
        return table.getRowModel().rows.find(({ original }) => original.id === activeId);
    }, [activeId, table]);
    const dropdownItems = [
        {
            label: 'Change position',
            onClick: (tableData) => {
                onProductPositionSet(tableData);
            },
        },
        {
            label: 'Duplicate',
            enabled: hasPermission('products.duplicate'),
            onClick: (tableData) => {
                onDuplicateOffer(tableData);
            },
        },
        {
            enabled: hasPermission('products.archive'),
            label: 'Archive',
            appearance: 'destructive',
            onClick: (tableData) => {
                onArchiveOffer(tableData);
            },
        },
    ];
    if (!media.tablet) {
        return (React.createElement(List, { data: products, isLoading: isLoading, emptyComponent: emptyComponent, getItemLink: (product) => ({
                pathname: reverse(routing.products.all.product.self, { productId: product.id }),
                search: location.search,
            }), renderItem: (product) => (React.createElement(ProductListItem, { product: product, dropdownItems: dropdownItems })) }));
    }
    return (React.createElement(TableUI.Root, { hoverable: true, "$minWidth": "600px" },
        React.createElement(TableUI.Head, null, table
            .getHeaderGroups()
            .filter((header) => {
            if (table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) {
                // NOTE: there is no nice way to access the header group other than checking the depth or id
                // NOTE: we want to render only the first header group if there are selected rows
                return header.depth === 0;
            }
            // NOTE: otherwise we want to render only the second header group
            return header.depth === 1;
        })
            .map((headerGroup) => {
            return (React.createElement(TableUI.HeadTr, { key: headerGroup.id }, headerGroup.headers.map((header) => {
                return (React.createElement(TableUI.HeadTh, { key: header.id, colSpan: header.colSpan, textAlign: header.column.columnDef.meta?.textAlign, width: header.column.columnDef.meta?.width, position: header.column.columnDef.meta?.position, verticalSpacing: "6px" }, header.isPlaceholder ? null : (React.createElement(React.Fragment, null, flexRender(header.column.columnDef.header, header.getContext())))));
            })));
        })),
        // eslint-disable-next-line no-nested-ternary
        products.length > 0 ? (React.createElement(TableSortableUI.DndContext, { ...dndContextProps },
            React.createElement(TableSortableUI.SortableContext, { ...sortableContextProps },
                React.createElement(TableSortableUI.Body, { isLoading: isLoading }, table.getRowModel().rows.map((row) => {
                    return (React.createElement(TableSortableUI.BodyTr, { key: row.original.id, id: row.original.id, isSelected: row.getIsSelected() },
                        row.getVisibleCells().map((cell) => {
                            const isLeftPlaceholder = cell.column.columnDef.id === 'row-left-side-placeholder';
                            const shouldWorkAsSelectionToggle = table.getIsSomeRowsSelected() ||
                                table.getIsAllRowsSelected() ||
                                isLeftPlaceholder;
                            return (React.createElement(TableSortableUI.BodyTd, { key: cell.id, textAlign: cell.column.columnDef.meta?.textAlign, width: cell.column.columnDef.meta?.width, position: cell.column.columnDef.meta?.position, onClick: () => {
                                    if (isLeftPlaceholder) {
                                        return;
                                    }
                                    if (shouldWorkAsSelectionToggle) {
                                        row.toggleSelected();
                                    }
                                } }, !shouldWorkAsSelectionToggle ? (React.createElement(Link, { to: getLinkToProduct(cell.row.original.id) }, flexRender(cell.column.columnDef.cell, cell.getContext()))) : (React.createElement(React.Fragment, null, flexRender(cell.column.columnDef.cell, cell.getContext())))));
                        }),
                        table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() ? null : (React.createElement(TableSortableUI.BodyTd, { key: "table-row-actions", width: "0px", position: "relative" },
                            React.createElement(TableUI.TableRowActions, null,
                                React.createElement(React.Fragment, null, dropdownItems
                                    .filter(({ enabled = true }) => enabled)
                                    .map((action) => (React.createElement(TableUI.TableRowActionsItem, { label: action.label, appearance: action.appearance, onClick: () => action.onClick(row.original) })))))))));
                }))),
            React.createElement(TableSortableUI.DragOverlay, null, activeId ? (React.createElement(TableUI.Root, { hoverable: true },
                React.createElement(TableUI.Body, { isLoading: false },
                    React.createElement(TableUI.BodyTr, { key: `overlay-${activeId}`, isDragged: true },
                        React.createElement(TableSortableUI.BodyTd, { key: "left-placeholder", width: "0px", position: "relative" },
                            React.createElement(TableUI.RowSelection, { visible: true },
                                React.createElement(CheckboxIndeterminate, { checked: selectedRow?.getIsSelected(), disabled: !selectedRow?.getCanSelect(), indeterminate: selectedRow?.getIsSomeSelected(), onChange: selectedRow?.getToggleSelectedHandler() }))),
                        selectedRow?.getVisibleCells().map((cell) => (React.createElement(TableUI.BodyTd, { key: cell.id, textAlign: cell.column.columnDef.meta?.textAlign, width: cell.column.columnDef.meta?.width }, flexRender(cell.column.columnDef.cell, cell.getContext())))))))) : null))) : !isLoading ? (React.createElement("tbody", null,
            React.createElement("tr", null,
                React.createElement("td", { colSpan: table.getAllLeafColumns().length },
                    React.createElement(S.EmptyState, null, emptyComponent))))) : (React.createElement(TableUI.Body, { isLoading: isLoading },
            React.createElement("tr", null,
                React.createElement(TableUI.BodyTd, { colSpan: table.getAllLeafColumns().length },
                    React.createElement(TableUI.Loader, null)))))));
};
