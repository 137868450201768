import { ApiBase } from '../ApiBase';
class MembershipsDirectMessagesApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.sendDirectMessagesMessage = (requestBody) => {
            return this.post('/creators/api/v1/direct_messages/messages', requestBody);
        };
        this.getDirectMessagesConversationPreviews = (params) => {
            return this.get('/creators/api/v1/direct_messages/conversations/previews', params);
        };
        this.getDirectMessagesConversation = (params) => {
            return this.get(`/creators/api/v1/direct_messages/conversations/${params.id}`);
        };
        this.markDirectMessagesConversationAsRead = (params) => {
            return this.patch(`/creators/api/v1/direct_messages/conversations/${params.conversation_id}/mark_as_read`);
        };
        this.markDirectMessagesConversationAsUnread = (params) => {
            return this.patch(`/creators/api/v1/direct_messages/conversations/${params.conversation_id}/mark_as_unread`);
        };
        this.getDirectMessagesConversationMessages = (params, query) => {
            return this.get(`/creators/api/v1/direct_messages/conversations/${params.conversation_id}/messages`, query);
        };
        this.getDirectMessagesMessage = (params) => {
            return this.get(`/creators/api/v1/direct_messages/messages/${params.id}`);
        };
        this.getDirectMessagesSettings = () => {
            return this.get('/creators/api/v1/accounts/settings/direct_messages');
        };
        this.updateDirectMessagesSettings = (requestBody) => {
            return this.patch('/creators/api/v1/accounts/settings/direct_messages', requestBody);
        };
        this.getDirectMessagesWidget = () => {
            return this.get('/creators/api/v1/direct_messages/widget');
        };
        this.getDirectMessagesMessageGroupsProgress = () => {
            return this.get('/creators/api/v1/direct_messages/message_groups/progress');
        };
        this.deleteDirectMessagesMessageWithErroredMuxUpload = (params) => {
            return this.delete(`/creators/api/v1/direct_messages/messages/${params.message_id}/destroy_with_errored_mux_upload`);
        };
    }
}
export const MembershipsDirectMessagesApiClient = new MembershipsDirectMessagesApiClientModel('/');
