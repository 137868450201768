import { Banner, Button, ButtonAppearance, ModalBox } from '@fourthwall/components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateOfferMutation } from '@mutations';
import { usePlatformFeatureFlags } from '@components/utils/DashboardFeatureFlags/usePlatformFeatureFlags';
import { reverse, routing } from '@utils/routing';
import { SHIPPING_MODAL_HASH } from '@modules/Dashboard/Product/modules/shipping/shared/SelfOrAssistedShippingChoiceModal';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import * as S from './CreateSelfOwnedProductModal.styled';
import { ManufacturingServiceSelect } from './ManufacturingServiceSelect';
export const CreateSelfOwnedProductModal = ({ close }) => {
    const [selectedSelfOwnedManufacturingService, setSelectedSelfOwnedManufacturingService] = useState();
    const navigate = useNavigate();
    const { hasFeatureFlag } = usePlatformFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const adminMode = useAdminModeContext();
    const { createOfferMutation } = useCreateOfferMutation();
    const createSelfOwnedProduct = (manufacturingService) => {
        if (!manufacturingService) {
            return;
        }
        const withShippingChoiceModalOpen = ((hasFeatureFlag('assisted_product_shipping') &&
            hasPermission('products.thirdPartyLogistics.edit')) ||
            Boolean(adminMode?.isActive)) &&
            manufacturingService === 'MANUFACTURED_BY_CREATOR'
            ? SHIPPING_MODAL_HASH
            : '';
        return createOfferMutation.mutate([
            {
                name: 'New Product',
                description: '',
                price: { value: 30, currency: 'USD' },
                weight: { value: 10, unit: 'oz' },
                manufacturingService,
                settings: { requiresShipping: true },
            },
        ], {
            onSuccess: ({ id }) => {
                close();
                navigate(`${reverse(routing.products.all.product.self, { productId: id })}${location.search}${withShippingChoiceModalOpen ? SHIPPING_MODAL_HASH : ''}`);
            },
        });
    };
    return (React.createElement(ModalBox, { onClose: close, buttons: [
            React.createElement(Button, { label: "Create", appearance: ButtonAppearance.Primary, onClick: () => createSelfOwnedProduct(selectedSelfOwnedManufacturingService), disabled: !selectedSelfOwnedManufacturingService, loading: createOfferMutation.isLoading }),
        ], size: "small" },
        React.createElement(ManufacturingServiceSelect, { selectedManufacturingService: selectedSelfOwnedManufacturingService, onSelect: setSelectedSelfOwnedManufacturingService }),
        React.createElement(S.InfoContainer, null,
            React.createElement(Banner, { appearance: "warning" }, "This view is only visible for Fourthwall admins. \"Creator Manufactured\" will always be selected when a creator chooses this option."))));
};
