import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  display: ${({ direction }) => (direction === 'horizontal' ? 'flex' : 'inline-flex')};
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => (direction === 'horizontal' ? 'row' : 'column')};
`;
export const Backdrop = styled.div `
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${Colors.GS300};

  position: absolute;
  z-index: -1;
`;
