import { routing } from '@utils/routing';
import { DeliveryTruckIcon, ExclamationMarkIcon, HeartIcon } from '@fourthwall/icons';
import { useCurrentShopDashboardQuery } from '@modules/Dashboard/hooks/useCurrentShopDashboardQuery';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useDnsQuery } from '@modules/Dashboard/hooks/useDnsQuery';
import { pluralize } from '@fourthwall/utils/lib/string';
import { useGetTopKudosQuery } from '@hooks/useGetTopKudosQuery';
export const useActionsBars = () => {
    const { dashboardPermissions, hasPermission } = useDashboardPermissions();
    const { currentShopDashboardQuery } = useCurrentShopDashboardQuery();
    const { dnsQuery } = useDnsQuery();
    const { getTopKudosQuery } = useGetTopKudosQuery([{ size: 1000 }]);
    const { ordersToManuallyFulfill = 0 } = currentShopDashboardQuery.data ?? {};
    const topSupportersCount = getTopKudosQuery.data?.page.elementsTotal || 0;
    const domainDnsRecords = dnsQuery.data;
    const actionsBars = [
        {
            enabled: hasPermission('home.fulfillments') && ordersToManuallyFulfill > 0,
            label: `${ordersToManuallyFulfill} ${pluralize(ordersToManuallyFulfill, 'order')} left to send`,
            to: routing.contributions.fulfillment,
            appearance: 'warning',
            icon: DeliveryTruckIcon,
        },
        {
            enabled: dashboardPermissions.contributions.thankYous && !!topSupportersCount,
            to: routing.contributions.thankYous.self,
            label: `Thank top ${topSupportersCount} ${pluralize(topSupportersCount, 'supporter')}`,
            icon: HeartIcon,
        },
        {
            enabled: !!domainDnsRecords &&
                domainDnsRecords?.dnsList !== null &&
                !domainDnsRecords?.dnsList?.isEachWebsiteEntryVerified,
            to: routing.settings.domain.configuration,
            label: 'Verify your domain',
            icon: ExclamationMarkIcon,
        },
    ];
    return { actionsBars: actionsBars.filter((action) => action.enabled) };
};
