import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
const getContainerColor = ({ $appearance, $color }) => {
    if ($color) {
        return $color;
    }
    if ($appearance === 'dark') {
        return Colors.GS100;
    }
    return Colors.GS1000;
};
const getHoverColor = (appearance) => {
    if (appearance === 'dark')
        return;
    return Colors.GS1000;
};
export const Container = styled.div `
  display: flex;
  align-items: ${({ $align }) => $align};

  color: ${getContainerColor};

  overflow: hidden;

  ${({ $appearance, $hoverable }) => $hoverable &&
    css `
      position: relative;
      z-index: 0;

      &:hover {
        color: ${getHoverColor($appearance)};
      }
    `};

  ${({ $align }) => $align === 'flex-start' &&
    css `
      ${LogoContainer} {
        margin-top: 1px;
      }
    `};
`;
export const LogoContainer = styled.div ``; // referenced in Container
export const NameContainer = styled.div `
  max-width: calc(100% - 38px); // 26px logo width + margin
  margin-left: 12px;

  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`;
export const Name = styled.div `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ImageContainer = styled.div `
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid ${Colors.GS500};
`;
export const Image = styled.img `
  height: 20px;
  max-width: 300px;

  object-fit: contain;
`;
