import { CreatorMessagesApiClient } from '@fourthwall/services/lib/CreatorMessagesApiClient';
import { useMutation } from '@tanstack/react-query';
export const useUploadQuery = () => {
    const uploadFileMutation = useMutation({
        mutationFn: async ({ payload, file }) => {
            const { data: attachmentUrls } = await CreatorMessagesApiClient.generateAttachmentUrl({
                contentType: payload,
            });
            await CreatorMessagesApiClient.uploadAttachment({
                file,
                uploadUrl: attachmentUrls.uploadUrl,
            });
            return attachmentUrls;
        },
    });
    return {
        uploadFile: uploadFileMutation.mutate,
        uploadFileMutation,
    };
};
