import { getSupportersAmountLabel } from '../../../../utils/getSupportersAmountLabel';
export const getAllProducts = (collectionsData) => {
    if (!collectionsData) {
        return [];
    }
    return collectionsData.map((product) => {
        return {
            id: product.id,
            name: product.name,
            imageUrl: product.imageUrl,
            supportersTotal: product.supportersTotal,
            supportersTotalLabel: getSupportersAmountLabel(product.supportersTotal, 'product'),
            productsTotal: product.productsTotal,
        };
    });
};
