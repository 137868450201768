import { Toasts } from '@fourthwall/components';
import { useState } from 'react';
import { useCheckMediaCreatedMutation } from '../../../hooks/useCheckMediaCreatedMutation';
import { useImageConfirmMutation } from '../../../hooks/useImageConfirmMutation';
import { useUploadImageMutation } from '../../../hooks/useUploadImageMutation';
import { uploadMedia } from '../../../utils/uploadMedia';
import { useCreateAudioPostMutation } from './useCreateAudioPostMutation';
export const useCreateAudioPost = () => {
    const [isLoading, setLoading] = useState(false);
    const [mediaUploadProgress, setMediaUploadProgress] = useState(0);
    const { uploadImageMutation } = useUploadImageMutation();
    const { imageConfirmMutation } = useImageConfirmMutation();
    const { createAudioPostMutation } = useCreateAudioPostMutation();
    const { checkMediaCreatedMutation } = useCheckMediaCreatedMutation();
    const createAudioPost = async (file, thumbnail, payload, handleSuccess) => {
        setLoading(true);
        const { postId, audioDirectUploadData, imageDirectUploadData } = await createAudioPostMutation.mutateAsync(payload, {
            onError: () => setLoading(false),
        });
        await uploadImageMutation.mutateAsync({ file: thumbnail, ...imageDirectUploadData }, {
            onError: () => {
                setLoading(false);
                throw new Error('Memberships: create audio post upload image error');
            },
        });
        await imageConfirmMutation.mutateAsync({ id: postId, signedId: imageDirectUploadData.signedId }, {
            onError: () => {
                setLoading(false);
                throw new Error('Memberships: create audio post confirm image error');
            },
        });
        if (audioDirectUploadData) {
            await uploadMedia(audioDirectUploadData.uploadUrl, file, (progress) => {
                setMediaUploadProgress(Number(progress.toFixed()));
            }).catch(() => {
                Toasts.notify('Media upload error. Please try again.', { type: 'error' });
            });
            checkMediaCreatedMutation.mutate(postId, {
                onSuccess: () => {
                    setMediaUploadProgress(0);
                    setLoading(false);
                    handleSuccess();
                },
                onError: () => setLoading(false),
            });
        }
    };
    return {
        createAudioPost,
        isLoading,
        mediaUploadProgress,
    };
};
