import React from 'react';
import { Dropdown, Icon, useMediaQueryContext } from '@fourthwall/components';
import * as S from './styled';
export const QuickActionsDropdownItem = ({ title, subtitle, icon, ...props }) => {
    const media = useMediaQueryContext();
    return (React.createElement(Dropdown.Item, { label: React.createElement(S.Container, null,
            React.createElement(S.IconWrapper, null,
                React.createElement(Icon, { component: icon, height: media.tablet ? 18 : 20 })),
            React.createElement(S.ContentWrapper, null,
                React.createElement(S.Title, null, title),
                React.createElement(S.Subtitle, null, subtitle))), ...props }));
};
