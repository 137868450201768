import { createGlobalStyle } from 'styled-components';
export const Global = createGlobalStyle `
  blockquote.TextBlockEllipsis > div:before  {
    content: open-quote;
  }
  
  blockquote.TextBlockEllipsis > div:after  {
    content: close-quote;
  }
`;
