import { ButtonClean, DateString, PriceString, ProfitString } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import React from 'react';
import { Link } from 'react-router-dom';
import { reverse, routing } from '../../../../../../../utils/routing';
import { isCancelledGiftWithoutWinner, isGiftCancelled, isGiftWithoutWinner } from '../../utils';
export const getTitle = (giveaway) => {
    if (!giveaway)
        return;
    return `${giveaway.supporter.email} order #${giveaway.friendlyId}`;
};
export const getDate = (giveaway) => {
    if (!giveaway)
        return;
    return React.createElement(DateString, { date: giveaway.createdAt, format: "[Placed] MMM D, YYYY [at] h:mm A" });
};
const isMembershipsGift = (orderFriendlyId) => orderFriendlyId.match(/^0+$/);
export const getBanners = (giveaway, location, { onRedoGiveawayClick }) => {
    if (!giveaway)
        return;
    const giftsWithAvailableWinners = giveaway.gifts.filter((gift) => ['GIFT_REDEEMED', 'GIFT_AVAILABLE', 'GIFT_CHANGED_TO_PROMOTION'].includes(gift.type));
    const giftsWithCancelledWinners = giveaway.gifts.filter(isGiftCancelled);
    const giftsWithWinners = [...giftsWithAvailableWinners, ...giftsWithCancelledWinners];
    const giftsWithoutWinners = giveaway.gifts.filter((gift) => isGiftWithoutWinner(gift) || isCancelledGiftWithoutWinner(gift));
    const getChildren = () => {
        const getOrderLinkTo = (orderId) => {
            const locationState = {
                from: { label: `Giveaway ${giveaway.friendlyId}`, location },
            };
            return {
                pathname: reverse(routing.contributions.orders.order.self, { orderId }),
                state: locationState,
            };
        };
        const getPromotionLinkTo = (promotionId) => {
            return {
                pathname: reverse(routing.promotions.promotionCodes.promotionCode.self, { promotionId }),
            };
        };
        if (!giftsWithWinners.length) {
            const redoButton = onRedoGiveawayClick ? (React.createElement(React.Fragment, null,
                "You may ",
                React.createElement(ButtonClean, { label: "redo the giveaway", onClick: onRedoGiveawayClick }))) : null;
            return React.createElement(React.Fragment, null,
                "This gift currently has no winners. ",
                redoButton);
        }
        if (giveaway.gifts.length === 1) {
            const gift = giveaway.gifts[0];
            if (gift.type === 'GIFT_REDEEMED') {
                const { supporter, orderId, orderFriendlyId } = gift;
                return (React.createElement(React.Fragment, null,
                    "This gift has a selected winner:",
                    React.createElement("br", null),
                    supporter.username || supporter.email,
                    !isMembershipsGift(orderFriendlyId) && (React.createElement(React.Fragment, null,
                        ' ',
                        "(",
                        React.createElement(Link, { to: getOrderLinkTo(orderId) },
                            "#",
                            orderFriendlyId),
                        ")"))));
            }
            if (gift.type === 'GIFT_AVAILABLE') {
                return (React.createElement(React.Fragment, null,
                    "This gift has a selected winner:",
                    React.createElement("br", null),
                    gift.winner,
                    " (not redeemed yet)"));
            }
            if (gift.type === 'GIFT_CANCELLED') {
                return (React.createElement(React.Fragment, null,
                    "This gift has a canceled winner:",
                    React.createElement("br", null),
                    gift.winner));
            }
            if (gift.type === 'GIFT_CHANGED_TO_PROMOTION') {
                return (React.createElement(React.Fragment, null,
                    "This gift has a selected winner:",
                    React.createElement("br", null),
                    gift.winner,
                    " (redeemed as",
                    ' ',
                    React.createElement(Link, { to: getPromotionLinkTo(gift.promotionId) }, "promo code"),
                    ")"));
            }
            return null;
        }
        return (React.createElement(React.Fragment, null,
            giftsWithAvailableWinners.length > 0 && (React.createElement(React.Fragment, null,
                "This gift has ",
                giftsWithAvailableWinners.length,
                " selected winners:",
                React.createElement("ul", null, giveaway.gifts.map((gift) => {
                    if (gift.type === 'GIFT_REDEEMED') {
                        const { supporter, orderId, orderFriendlyId } = gift;
                        return (React.createElement("li", null,
                            supporter.username || supporter.email,
                            !isMembershipsGift(orderFriendlyId) && (React.createElement(React.Fragment, null,
                                ' ',
                                "(",
                                React.createElement(Link, { to: getOrderLinkTo(orderId) },
                                    "#",
                                    orderFriendlyId),
                                ")"))));
                    }
                    if (gift.type === 'GIFT_AVAILABLE') {
                        return React.createElement("li", null,
                            gift.winner,
                            " (not redeemed yet)");
                    }
                    if (gift.type === 'GIFT_CHANGED_TO_PROMOTION') {
                        return (React.createElement("li", null,
                            gift.winner,
                            " (redeemed as",
                            ' ',
                            React.createElement(Link, { to: getPromotionLinkTo(gift.promotionId) }, "promo code"),
                            ")"));
                    }
                    return null;
                })))),
            giftsWithCancelledWinners.length > 0 && (React.createElement(React.Fragment, null,
                giftsWithAvailableWinners.length > 0 && React.createElement("br", null),
                "Canceled gift winners:",
                React.createElement("ul", null, giftsWithCancelledWinners.map((gift) => (React.createElement("li", null, gift.winner)))))),
            giftsWithoutWinners.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("br", null),
                giftsWithoutWinners.length,
                ' ',
                pluralize(giftsWithoutWinners.length, `item has no winner yet.`, `items have no winners yet.`)))));
    };
    return [
        {
            children: getChildren(),
            appearance: 'warning',
        },
    ];
};
export const getDetails = (giveaway) => {
    if (!giveaway)
        return;
    return {
        email: giveaway.supporter.email,
    };
};
export const getItems = (giveaway) => {
    if (!giveaway)
        return;
    const item = {
        offerId: giveaway.offer.offerId,
        title: `Gift: ${giveaway.offer.productName}`,
        image: giveaway.offer.imgUrl,
        quantity: giveaway.offer.quantity,
        price: giveaway.offer.unitPrice,
    };
    return [item];
};
export const getItemsSummary = (giveaway) => {
    if (!giveaway)
        return;
    return [
        {
            label: 'Subtotal (1 item)', // it's always one item
            value: React.createElement(PriceString, { ...giveaway.amounts.subtotal }),
        },
        {
            label: 'Pre-paid shipping',
            value: React.createElement(PriceString, { ...giveaway.amounts.shippingBuffer }),
            tooltip: "Gifter covers the average shipping costs so that a winner doesn't have to pay anything when redeeming the gift.",
        },
        {
            label: 'Taxes',
            value: React.createElement(PriceString, { ...giveaway.amounts.tax }),
        },
        {
            label: 'Total',
            value: React.createElement(PriceString, { ...giveaway.amounts.total }),
        },
    ];
};
export const getSummary = (giveaway, onBreakdownShowClick, { withProfit }) => {
    if (!giveaway)
        return;
    return [
        {
            label: 'Net paid by supporter',
            value: React.createElement(PriceString, { ...giveaway.amounts.paidBySupporter }),
        },
        withProfit
            ? {
                label: 'Your profit',
                value: (React.createElement(React.Fragment, null,
                    React.createElement("span", { style: { marginRight: 8, fontSize: 12, fontWeight: 400 } },
                        React.createElement(ButtonClean, { label: "Show breakdown", appearance: "secondary", onClick: onBreakdownShowClick })),
                    React.createElement(ProfitString, { ...giveaway.amounts.profit }))),
            }
            : undefined,
    ].filter(Boolean);
};
