import { useLocation } from 'react-router-dom';
import { useTagManager } from '@hooks';
import { routing } from '@utils/routing';
export const useAppConnectEvent = (app) => {
    const tagManager = useTagManager();
    const location = useLocation();
    const getEventLocation = () => {
        const state = location.state; // TODO: Make this more type safe.
        return state && state.from.location.pathname === routing.home ? 'creator_dashboard' : 'apps';
    };
    const trigger = () => {
        tagManager.trigger('connect_apps_click', {
            location: getEventLocation(),
            app_name: app,
        });
    };
    return { trigger };
};
