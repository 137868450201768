import { Toasts } from '@fourthwall/components';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDashboardPermissions } from '../../../../components/utils/DashboardPermissions';
import { reverse, routing } from '../../../../utils/routing';
import { useOfferMutation } from '../hooks/useOfferMutation';
import { useOfferQuery } from '../queries/useOfferQuery';
import { ProductSettingsView } from './ProductSettingsView';
export const ProductSettingsContainer = () => {
    const { productId = '' } = useParams();
    const location = useLocation();
    const { hasPermission } = useDashboardPermissions();
    const { offerQuery, setOfferData } = useOfferQuery([productId]);
    const { offerMutation } = useOfferMutation({
        onSuccess: () => {
            Toasts.notify('Product has been updated successfully!', { type: 'info' });
        },
    });
    const maxDigitalFilesSize = offerQuery?.data?.config.digitalFileProperties?.maxDigitalFileSize;
    const initialValues = {
        isSoundScanProduct: !!offerQuery.data?.soundScanInfo,
        format: offerQuery.data?.soundScanInfo?.format || 'SINGLE',
        soundScanCode: offerQuery.data?.soundScanInfo?.soundScanCode || '',
        digitalItemsEnabled: !!offerQuery.data?.digitalItems?.length,
        digitalItems: offerQuery.data?.digitalItems.map((item) => ({
            id: item.uri,
            url: item.uri,
            name: item.name,
            size: item?.metadata?.fileSize,
        })) || [],
    };
    const digitalItemsEditable = !(offerQuery.data?.manufacturingService === 'DIGITAL_ITEM');
    const getDigitalItemsPayload = (values) => {
        if (!digitalItemsEditable) {
            return offerQuery.data?.digitalItems;
        }
        if (!values.digitalItemsEnabled) {
            return [];
        }
        return values.digitalItems
            .filter((i) => !i.isUploading && i.url)
            .map((item) => ({ uri: item.url, name: item.name }));
    };
    const getPayload = ({ isSoundScanProduct, format, soundScanCode, ...values }) => ({
        ...offerQuery.data,
        soundScanInfo: isSoundScanProduct ? { format, soundScanCode } : undefined,
        digitalItems: getDigitalItemsPayload(values),
    });
    const handleSubmit = async (values) => {
        await offerMutation.mutateAsync([productId, getPayload(values)], {
            onSuccess: (data) => {
                setOfferData(data); // TODO: decodeHtmlEntities
            },
        });
    };
    return (React.createElement(ProductSettingsView, { backButtonProps: {
            label: offerQuery.data?.name || '',
            to: {
                pathname: reverse(routing.products.all.product.self, { productId }),
                search: location.search,
            },
        }, initialValues: initialValues, productData: offerQuery.data, productName: offerQuery.data?.name, isLoading: offerQuery.isLoading, isSubmitting: offerMutation.isLoading, isFormEditable: hasPermission('products.edit.details'), digitalItemsEditable: digitalItemsEditable, maxDigitalFilesSize: maxDigitalFilesSize, onSubmit: handleSubmit }));
};
