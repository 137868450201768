import { useSamplesBudgetBalanceQuery } from './useSamplesBudgetBalanceQuery';
export const useSamplesBudgetBalance = () => {
    const { samplesBudgetBalanceQuery } = useSamplesBudgetBalanceQuery();
    const getSamplesBudgetBalance = () => {
        if (samplesBudgetBalanceQuery.isLoading)
            return undefined;
        if (!samplesBudgetBalanceQuery.data?.balance) {
            return { value: 0, currency: 'USD' };
        }
        return samplesBudgetBalanceQuery.data.balance;
    };
    return {
        samplesBudgetBalance: getSamplesBudgetBalance(),
    };
};
