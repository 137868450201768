import React from 'react';
import { useParams } from 'react-router-dom';
import { BackButton, ButtonClean, Dropdown, Icon, Loading, ToolbarButton, useMediaQueryContext, } from '@fourthwall/components';
import { isWebView } from '@fourthwall/utils';
import { DotsHorizontalIcon, PinIcon } from '@fourthwall/icons';
import { useFocusFirstErrorOnSubmit } from '@fourthwall/utils/lib/formik';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { Dashboard, PageLayout, SideSummary } from '@components/common';
import { useCurrentShopQuery } from '../../../../../hooks/useCurrentShopQuery';
import { useTiers } from '../../../Perks/hooks/useTiers';
import { useDeletePost } from '../../hooks/useDeletePost';
import { usePost } from '../../hooks/usePost';
import { usePostUpdate } from '../../hooks/usePostUpdate';
import { PostAccess } from '../../modules/PostAccess';
import { PostAnalytics } from '../../modules/PostAnalytics';
import { PostPublishedDate } from '../../modules/PostPublishedDate';
import { PostTag } from '../../modules/PostTag';
import { TagsFields } from '../../modules/TagsFields';
import { getBackUrl } from '../../utils';
import * as S from './PostLayout.styled';
export const PostLayout = ({ disabled, children }) => {
    const media = useMediaQueryContext();
    const { postId = '' } = useParams();
    const { post, postQuery } = usePost(postId);
    const { tiers } = useTiers();
    const { currentShopQuery } = useCurrentShopQuery();
    const { isLoading: isPostLoading, updatePost, pinPost, unpinPost } = usePostUpdate(postId);
    const { isLoading: isDeleteLoading, handleDeletePost } = useDeletePost();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    useFocusFirstErrorOnSubmit();
    if (!post || !currentShopQuery.data || !tiers) {
        return null;
    }
    const renderPinnedOption = () => {
        if (!dashboardFeatureFlags.membershipsPinnedPosts.enabled)
            return null;
        if (post.statuses.post !== 'Live' && post.statuses.post !== 'Finished')
            return null;
        return (React.createElement(Dropdown.Item, { label: post.pinnedAt ? 'Unpin post' : 'Pin post', onClick: () => {
                post.pinnedAt ? unpinPost() : pinPost();
            } }));
    };
    const renderToolbar = () => {
        return (React.createElement(Dropdown, { content: React.createElement(React.Fragment, null,
                renderPinnedOption(),
                React.createElement(Dropdown.Item, { label: "Delete", onClick: () => {
                        handleDeletePost(post.id);
                    } })), position: "bottom-end" },
            React.createElement(ToolbarButton, { label: "Settings", icon: DotsHorizontalIcon, size: media.tablet ? 'medium' : 'small' })));
    };
    const renderPinnedInfo = () => {
        if (!post.pinnedAt)
            return null;
        return (React.createElement(S.PinnedInfo, null,
            React.createElement(Icon, { component: PinIcon, height: 14 }),
            "Pinned post ",
            React.createElement(ButtonClean, { label: "Unpin", onClick: unpinPost })));
    };
    return (React.createElement(Loading, { isLoading: isPostLoading || isDeleteLoading || postQuery.isFetching },
        React.createElement(PageLayout, { rightContent: React.createElement(SideSummary, null,
                React.createElement(PostAccess, { tiers: tiers, showNotification: post.statuses.schedule === 'Future', disabled: disabled }),
                post.statuses.schedule === 'Done' && React.createElement(PostPublishedDate, null),
                React.createElement(PostAnalytics, { commentsCount: post.comments.count, likesCount: post.likes.count })), leftContent: React.createElement(React.Fragment, null,
                children,
                React.createElement(TagsFields, { disabled: disabled })) },
            React.createElement(S.HeaderContainer, null,
                React.createElement(Dashboard.ItemViewHeader, { name: post.title, title: renderPinnedInfo(), backButton: React.createElement(BackButton, { label: "Posts", to: getBackUrl(post.statuses.schedule) }), link: !isWebView()
                        ? {
                            label: `${currentShopQuery.data.baseUri}${post.path}`,
                            url: `${currentShopQuery.data.baseUri}${post.path}`,
                        }
                        : undefined, status: React.createElement(PostTag, { post: post, updatePost: updatePost }), toolbar: renderToolbar() })))));
};
