import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Item = styled.div `
  display: flex;
  padding: 18px 0;
  justify-content: space-between;

  border-style: solid;
  border-color: ${Colors.GS400};
  border-width: 0 0 1px 0;

  &:first-of-type {
    border-width: 1px 0 1px 0;
  }

  ${mediaQueries.laptop} {
    &:nth-child(-n + 2) {
      border-width: 1px 0 1px;
    }
  }
`;
export const Wrapper = styled.div `
  align-self: center;
`;
export const Container = styled.div `
  display: flex;
  gap: 24px;
  align-self: center;
`;
export const Text = styled.div `
  display: flex;
  flex-direction: column;
`;
export const Description = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
`;
