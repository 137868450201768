import * as Yup from 'yup';
import { getSocialIntegrationUrl, getSocialLabel } from '../../utils/socials';
export const getSocialName = (social) => `social_${social}`;
const isSearchable = (socialName, supportedSocialNetworks) => !!supportedSocialNetworks.find((supportedSocialNetwork) => supportedSocialNetwork.name === socialName)?.autocomplete;
const mapSocialNetwork = (socialNetwork, supportedSocialNetworks) => ({
    id: socialNetwork.id,
    name: socialNetwork.name,
    handle: socialNetwork.handle,
    disabled: !!socialNetwork.data.managed,
    searchable: isSearchable(socialNetwork.name, supportedSocialNetworks),
    editUrl: socialNetwork.data.managed ? getSocialIntegrationUrl(socialNetwork.name) : undefined,
    dragDisabled: false,
    data: {
        type: 'text',
        label: `${getSocialLabel(socialNetwork.name)} link ${socialNetwork.data.managed ? ' (connected)' : ''}`,
        value: socialNetwork.profileData?.name || socialNetwork.handle,
        key: getSocialName(socialNetwork.name),
        id: getSocialName(socialNetwork.name),
    },
});
export const mapSocialNetworks = (socialNetworks, supportedSocialNetworks) => socialNetworks.map((socialNetwork) => mapSocialNetwork(socialNetwork, supportedSocialNetworks));
export const mapSearchOptions = (searchResults) => searchResults?.map((searchResult) => ({
    id: searchResult.handle,
    name: searchResult.name,
    publicUrl: searchResult.publicUrl,
    title: searchResult.name,
    description: searchResult.description,
    image: searchResult.image,
}));
export const getInitialValues = (socialNetworks) => socialNetworks.reduce((previousValue, socialNetwork) => ({
    ...previousValue,
    [getSocialName(socialNetwork.name)]: socialNetwork.profileData?.name || socialNetwork.handle,
}), {});
export const getNewSocialLinkData = (socialName, supportedSocialNetworks) => {
    return {
        id: socialName,
        name: socialName,
        handle: '',
        disabled: false,
        searchable: isSearchable(socialName, supportedSocialNetworks),
        dragDisabled: true,
        data: {
            type: 'text',
            label: `${getSocialLabel(socialName)} link`,
            value: '',
            key: getSocialName(socialName),
            id: getSocialName(socialName),
        },
    };
};
export const getValidationSchema = (socialLinks) => Yup.object().shape(socialLinks.reduce((previousValue, socialNetwork) => ({
    ...previousValue,
    [getSocialName(socialNetwork.name)]: Yup.string().required(),
}), {}));
export const isSearchOption = (value) => {
    return value.id !== undefined;
};
