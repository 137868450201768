import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tag } from '@fourthwall/components';
import { isWebView } from '@fourthwall/utils';
import { reverse, routing } from '@utils/routing';
export const OfferLink = ({ name, label, id, status }) => {
    const location = useLocation();
    if (status === 'ARCHIVED') {
        return (React.createElement("div", { style: { display: 'inline-flex', alignItems: 'center' } },
            React.createElement("div", null, name),
            React.createElement("div", { style: { marginLeft: 8 } },
                React.createElement(Tag, { label: "Archived" }))));
    }
    // don't link to offer inside web view
    if (!id || isWebView()) {
        return name;
    }
    const to = reverse(routing.products.all.product.self, { productId: id });
    const state = { from: { label, location } };
    return (React.createElement(Link, { to: to, state: state }, name));
};
