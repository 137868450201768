import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';
import { Colors } from '../../colors';
export const RemoveButton = styled.button.attrs({
    type: 'button',
}) `
  position: absolute;
  top: 4px;
  right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  background: ${Colors.GS0};
  visibility: hidden;

  color: ${Colors.GS1000};
`;
export const Container = styled.div `
  position: relative;

  height: 100%;

  overflow: hidden;

  background: ${({ appearance }) => appearance === 'dark' ? Colors.GS1000 : tinycolor(Colors.GS800).setAlpha(0.2).toString()};

  cursor: pointer;

  &:hover {
    ${RemoveButton} {
      visibility: visible;
    }
  }

  ${({ disabled }) => disabled &&
    css `
      opacity: 0.4;
    `}
`;
export const Image = styled.img `
  position: relative;

  width: 100%;
  height: 100%;

  object-fit: contain;
`;
export const Video = styled.video `
  position: relative;

  width: 100%;
  height: 100%;

  object-fit: contain;
`;
