import { BackButton, ConfirmModal, HeadContent, LeavingGuard, Loading, SaveShortcutListener, SortableBlocks, useModalContext, } from '@fourthwall/components';
import { Formik } from 'formik';
import filter from 'lodash-es/filter';
import isEqual from 'lodash-es/isEqual';
import { reverse } from 'named-urls';
import { arrayMove } from '@fourthwall/utils/lib/array';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Dashboard, PageLayout } from '@components/common';
import { routing } from '@utils/routing';
import { ConfirmBarContainer } from '../../ConfirmBar/ConfirmBarContainer';
import { AddButtons } from './components/AddButtons';
import { ProductSection } from './components/ProductSection';
import { validationSchema } from './consts';
export const ProductSectionsView = ({ sections, productName = '', productId, isDigital, productIsFetching, onSubmit, }) => {
    const { open } = useModalContext();
    const location = useLocation();
    const getInitialValues = () => ({
        additionalSections: sections.map((section) => ({ ...section, bodyHtml: section.bodyHtml })),
    });
    const [initialValues, setInitialValues] = useState(getInitialValues());
    useEffect(() => {
        setInitialValues(getInitialValues());
    }, [sections]);
    const createPayloadFromValues = (values) => ({
        additionalSections: values.additionalSections.map((section) => ({
            ...section,
            bodyHtml: section.bodyHtml || '',
        })),
    });
    const handleSortChange = (values, setValues, oldVariantIndex, newVariantIndex) => {
        const sortedArray = arrayMove([...values.additionalSections], oldVariantIndex, newVariantIndex);
        setValues({ additionalSections: sortedArray });
    };
    const remove = (values, setValues, index) => {
        const filteredSections = filter([...values.additionalSections], (section, sectionIndex) => sectionIndex !== index);
        setValues({ additionalSections: filteredSections });
    };
    const handleRemove = (values, setValues, index) => {
        const section = values.additionalSections[index];
        if (section) {
            open(ConfirmModal, {
                title: `Are you sure you want to delete ${section.title} section?`,
                confirmLabel: 'Yes, delete',
                cancelLabel: 'No, cancel',
                onConfirm: () => remove(values, setValues, index),
            });
        }
    };
    const handleSubmit = (values) => {
        onSubmit(createPayloadFromValues(values));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: `Sections \u2013 ${productName}` }),
        React.createElement(Loading, { isLoading: productIsFetching },
            React.createElement(PageLayout, { size: "large" },
                React.createElement(Dashboard.Section, { name: "Edit additional sections", title: "These sections, often appearing below the description, add additional information to help your fans make their purchase.", topContent: React.createElement(BackButton, { label: productName, to: {
                            pathname: reverse(routing.products.all.product.self, { productId }),
                            search: location.search,
                        } }), bottomMargin: "large" },
                    React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: handleSubmit }, ({ values, submitForm, resetForm, setValues, isSubmitting }) => {
                        const isFormDirty = !isEqual(createPayloadFromValues(initialValues), createPayloadFromValues(values));
                        return (React.createElement(React.Fragment, null,
                            React.createElement("div", { style: { maxWidth: 584 } },
                                React.createElement(SortableBlocks, { onSortChange: (oldIndex, newIndex) => handleSortChange(values, setValues, oldIndex, newIndex), onRemove: (index) => handleRemove(values, setValues, index) }, values.additionalSections.map(({ type }, index) => (React.createElement(SortableBlocks.Item, { key: type },
                                    React.createElement(ProductSection, { key: index, index: index })))))),
                            React.createElement(AddButtons, { allValues: values, setValues: setValues, isDigitalProduct: isDigital }),
                            React.createElement(SaveShortcutListener, { disabled: !isFormDirty, onSave: submitForm }),
                            React.createElement(ConfirmBarContainer, { isOpen: isFormDirty, isLoading: isSubmitting, onConfirm: submitForm, onCancel: () => resetForm({ values: initialValues }) }),
                            React.createElement(LeavingGuard, { when: isFormDirty })));
                    }))))));
};
