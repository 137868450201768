import { InputUI } from '@fourthwall/components';
import styled from 'styled-components';
export const ConnectBarWrapper = styled.div `
  margin-top: 12px;
  margin-left: 36px;

  display: grid;
  gap: 12px;
`;
export const SwitchLabelWrapper = styled.div `
  font-weight: 600;
`;
export const NumberInputWrapper = styled.div `
  width: 180px;
`;
export const LoaderContainer = styled.div `
  display: flex;
  justify-content: center;
  padding: 32px;
`;
export const HeaderContainer = styled.div `
  margin-bottom: 20px;
`;
export const DescriptionWrapper = styled.div `
  margin-bottom: 16px;
  line-height: 150%;
`;
export const InputError = styled(InputUI.Error) `
  margin-top: 0;
`;
