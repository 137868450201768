import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const Button = styled.button `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(100% / 3);
  height: 56px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  border: 1px solid ${Colors.GS500};

  &:hover {
    background-color: ${Colors.GS300};
  }

  &:disabled {
    color: ${Colors.GS500};
    cursor: not-allowed;
  }

  ${({ active }) => active &&
    css `
      box-shadow: inset 0px 0px 0px 2px ${Colors.GS1000};
    `};

  ${mediaQueries.laptop} {
    gap: 12px;
    flex-direction: row;
    font-size: 16px;

    border-left-width: 0;

    &:nth-child(3n + 1) {
      border-left-width: 1px;
    }

    &:nth-child(n + 4) {
      border-top-width: 0;
    }
  }
`;
export const IconContainer = styled.div `
  position: absolute;
  left: 5px;
  top: 5px;
`;
