import React from 'react';
import { useActiveTab } from '@components/hooks/useActiveTab';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { TeamView } from './TeamView';
import { useTeamTab } from './hooks/useTeamTab';
import { useRolesTab } from './hooks/useRolesTab';
export const TeamContainer = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const TABS = [
        { label: 'Team', value: 'team', visible: true },
        { label: 'Roles', value: 'roles', visible: dashboardFeatureFlags.roles.enabled },
    ];
    const { activeTab, setTabParam } = useActiveTab({
        tabValues: TABS.map((tab) => tab.value),
    });
    const { teamTab } = useTeamTab({
        options: {
            enabled: activeTab === 'team',
        },
    });
    const { rolesTab } = useRolesTab();
    const tabsProps = {
        tabs: TABS.filter(({ visible }) => visible),
        activeTab,
        onTabClick: (tab) => setTabParam(tab),
    };
    return React.createElement(TeamView, { tabsProps: tabsProps, teamTab: teamTab, rolesTab: rolesTab });
};
