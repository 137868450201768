import React from 'react';
import { Avatar, EntityTableCell, LogoImage, Tag, useMediaQueryContext, } from '@fourthwall/components';
import * as S from './styled';
const pendingLabel = 'Invitation sent';
export const TeamMember = ({ data }) => {
    const media = useMediaQueryContext();
    const pending = data.invitationStatus === 'INVITED';
    if (data.isPartner) {
        return (React.createElement(EntityTableCell, { top: data.name || '', bottom: React.createElement(S.BottomPartnerContent, null,
                React.createElement(Tag, { label: "partner" }),
                pending && pendingLabel), image: React.createElement(LogoImage, { src: data.logoUri, placeholder: data.name?.charAt(0), size: "extraSmall", aspectRatio: 1 }) }));
    }
    const getBottom = () => {
        const label = pending ? pendingLabel : data.email;
        if (!media.tablet) {
            return (React.createElement(S.BottomGroupContent, null,
                React.createElement(S.BottomText, null, label),
                React.createElement(S.BottomText, null, data.roleName)));
        }
        return label;
    };
    const displayedName = data.isYou ? `${data.name} (you)` : data.name;
    return (React.createElement(EntityTableCell, { top: (pending ? data.email : displayedName) || '', bottom: getBottom(), image: React.createElement(Avatar, { name: pending ? undefined : data.name }) }));
};
