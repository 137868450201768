import { BaseContent, Button, ButtonGroup, Heading, useMediaQueryContext, } from '@fourthwall/components';
import React, { useRef } from 'react';
import * as S from './styled';
const GIFS = [
    'https://cdn.fourthwall.com/mobile/superman.gif',
    'https://cdn.fourthwall.com/mobile/notbad.gif',
    'https://cdn.fourthwall.com/mobile/crazy.gif',
    'https://cdn.fourthwall.com/mobile/ohyoucreazy.gif',
    'https://cdn.fourthwall.com/mobile/awesome.gif',
    'https://cdn.fourthwall.com/mobile/wow.gif',
];
export const UploadSuccess = ({ supporterName, onContinue, onClose }) => {
    const media = useMediaQueryContext();
    const gifIndex = useRef(Math.floor(Math.random() * GIFS.length));
    return (React.createElement(S.Container, null,
        React.createElement(S.Gif, { src: GIFS[gifIndex.current] }),
        React.createElement(S.Content, null,
            React.createElement(Heading, { type: "h6", weight: "semibold" },
                React.createElement(S.Message, null,
                    "You just made ",
                    supporterName,
                    "\u2019s day!")),
            React.createElement(BaseContent, null,
                React.createElement("span", null,
                    "Did you know that fans that you thank are ",
                    React.createElement("br", null),
                    " 5x more likely to come back and buy again?"))),
        React.createElement(S.Footer, null,
            React.createElement(ButtonGroup, { space: "medium", align: "center", fullWidth: !media.laptop, direction: media.laptop ? 'row' : 'column-reverse' },
                React.createElement(Button, { fullWidth: !media.laptop, label: "I\u2019m done for today", onClick: onClose }),
                onContinue && (React.createElement(Button, { fullWidth: !media.laptop, label: "Thank another supporter", appearance: "primary", onClick: onContinue }))))));
};
