import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
import { HELPER_CLASS } from './consts';
export const Container = styled.div ``;
export const Item = styled.div `
  position: relative;
`;
export const Placeholder = styled.div `
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${Colors.GS300};
`;
export const Handle = styled.div `
  color: ${Colors.GS600};
  opacity: 0;

  cursor: pointer;

  &:hover {
    color: ${Colors.GS1000};
  }
`;
export const DragHandle = styled(Handle) `
  cursor: grab;
`;
export const Element = styled.div(({ isDragged }) => css `
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 16px;

    background: ${Colors.GS0};
    cursor: grab;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 46px;
      right: 46px;
      height: 1px;
      background: ${Colors.GS400};
    }

    ${!isDragged &&
    css `
      &:hover ${Handle} {
        opacity: 1;
      }
    `}
    &.${HELPER_CLASS} {
      pointer-events: auto !important;
      z-index: 999;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      cursor: grabbing;

      ${Handle} {
        cursor: grabbing;
      }
    }
  `);
export const ImageContainer = styled.div `
  position: relative;
  margin-right: 16px;
  margin-left: 16px;
`;
export const Name = styled.div `
  flex: 2;
  margin-right: 12px;

  a:hover {
    color: inherit;
    text-decoration: underline;
  }
`;
export const RightContent = styled.div `
  flex: 1;
  margin-right: 16px;
`;
