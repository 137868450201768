import React from 'react';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { QuantityBadge } from '@components/common/QuantityBadge';
import { getNumberOfProducts } from '@utils/products';
import { TableItemDate } from '@components/utils/TableItemDate';
import { PriceString, ProfitString, Tag } from '@fourthwall/components';
import { StatusTag } from '@components/common/StatusTag';
import { getOrderImageUrl } from '@components/utils/getOrderImageUrl';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import * as S from './styled';
import { getBackerName } from '../../utils/getOrdersTableHeaders/utils';
export const OrderListItem = ({ order }) => {
    const { hasPermission } = useDashboardPermissions();
    const quantity = getNumberOfProducts(order.offers, order.donation);
    return (React.createElement(S.Container, null,
        React.createElement(S.Photo, null,
            React.createElement(PhotoThumbnail, { url: getOrderImageUrl(order.offers, order.offersCancelled), size: "extraLarge" }),
            quantity > 0 && React.createElement(QuantityBadge, { quantity: quantity })),
        React.createElement(S.Details, null,
            React.createElement(S.Supporter, null, getBackerName(order)),
            React.createElement(S.Date, null,
                React.createElement(TableItemDate, { date: order.createdAt })),
            React.createElement(StatusTag, { status: order.status }),
            order.salesChannel.type === 'TikTok' && React.createElement(Tag, { label: "From TikTok" })),
        React.createElement(S.Calculations, null,
            React.createElement(PriceString, { value: order.totalPrice.value }),
            hasPermission('contributions.profit') && order.profit && (React.createElement(S.Profit, null,
                React.createElement(ProfitString, { value: order.profit.value }))))));
};
