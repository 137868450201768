import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: row;

  ${({ compact }) => compact &&
    css `
      margin-bottom: 8px;
      line-height: 15px;
    `}
`;
export const SortableCell = styled.div `
  width: 48px;
`;
