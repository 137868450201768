import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const List = styled.ol `
  list-style: decimal;
  padding-inline-start: 1.5em;

  a {
    text-decoration: underline;
  }

  li {
    margin-bottom: 4px;
  }

  code {
    font-family: 'PT Mono', monospace;
    background: ${Colors.GS300};
    padding: 4px 0;
  }
`;
