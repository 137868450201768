import { useDashboardFeatureFlags } from '../../../../../../components/utils/DashboardFeatureFlags';
import { useCurrentTheme } from '../../../../../ThemeEditor';
export const useCurrencySettings = () => {
    const { currentTheme } = useCurrentTheme();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const yenEnabled = dashboardFeatureFlags.multicurrencyCustomThemeYen.enabled ||
        currentTheme?.customisation !== 'Fully';
    const currencyList = [
        {
            currency: 'USD',
            flag: 'US',
            disabled: true,
            tooltip: 'USD is a base currency and cannot be disabled',
        },
        { currency: 'EUR', flag: 'EU' },
        { currency: 'CAD', flag: 'CA' },
        { currency: 'GBP', flag: 'GB' },
        { currency: 'AUD', flag: 'AU' },
        { currency: 'NZD', flag: 'NZ' },
        { currency: 'SEK', flag: 'SE' },
        { currency: 'NOK', flag: 'NO' },
        { currency: 'DKK', flag: 'DK' },
        { currency: 'INR', flag: 'IN' },
        { currency: 'PLN', flag: 'PL' },
        { currency: 'JPY', flag: 'JP', disabled: !yenEnabled },
    ];
    return {
        currencySettings: {
            list: currencyList,
            enabled: dashboardFeatureFlags.multicurrencyCustomTheme.enabled ||
                currentTheme?.customisation !== 'Fully',
            yenEnabled,
        },
    };
};
