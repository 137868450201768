import { Upload, useMediaQueryContext } from '@fourthwall/components';
import { UploadIcon } from '@fourthwall/icons';
import React from 'react';
import { FILE_TYPES, MAX_FILE_SIZE } from '../../const';
import * as S from './styled';
export const UploadMedia = ({ onFileDrop, onFileDropRejected }) => {
    const media = useMediaQueryContext();
    return (React.createElement(S.Container, null,
        React.createElement(Upload, { title: React.createElement(React.Fragment, null,
                React.createElement(S.Title, null, media.laptop ? (React.createElement(React.Fragment, null,
                    "Drop video or audio file here or ",
                    React.createElement(S.Underline, null, "browse"))) : (React.createElement(S.Underline, null, "Browse"))),
                React.createElement(S.Subtitle, null,
                    "Max ",
                    MAX_FILE_SIZE / (1024 * 1024),
                    " MB file size")), icon: UploadIcon, maxSize: MAX_FILE_SIZE, accept: FILE_TYPES, onFileDrop: onFileDrop, onFileDropRejected: onFileDropRejected })));
};
