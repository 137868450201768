import * as UpChunk from '@mux/upchunk';
// @mux/upchunk 3.x doen't support huge files in Safari browser.
// Since 3.x is much faster than 2.x and we don't want to loose its benefits so
// we use 3.x for all browsers except Safari.
import * as UpChunkOld from '@mux/upchunk-old';
import { isSafari } from './browserDetect';
export const uploadMedia = (url, file, onProgress) => {
    if (isSafari()) {
        return new Promise((resolve, reject) => {
            const upload = UpChunkOld.createUpload({
                file,
                endpoint: url,
                chunkSize: 102400,
            });
            upload.on('error', reject);
            upload.on('progress', ({ detail }) => {
                onProgress(detail);
            });
            upload.on('success', resolve);
        });
    }
    return new Promise((resolve, reject) => {
        const upload = UpChunk.createUpload({
            file,
            endpoint: url,
            chunkSize: 102400,
        });
        upload.on('error', reject);
        upload.on('progress', ({ detail }) => {
            onProgress(detail);
        });
        upload.on('success', resolve);
    });
};
