import { getShopRendererApiUrl } from '@fourthwall/env';
import { ApiBase } from './ApiBase';
class ShopRendererPlatformApiModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getGeolocation = () => {
            return this.get(`/geo.json`);
        };
    }
}
export const ShopRendererPlatformApiClient = new ShopRendererPlatformApiModel(getShopRendererApiUrl('platform'));
