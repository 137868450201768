import { BackButton, Checkbox, Fieldset, HeadContent, LeavingGuard, Loading, SaveShortcutListener, Subsection, } from '@fourthwall/components';
import { Field, Formik } from 'formik';
import React from 'react';
import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { ConfirmBarContainer } from '../../ConfirmBar';
import { ProductFiles } from '../modules/ProductFiles';
import { SoundScan } from './components/SoundScan';
import { PRODUCT_SETTINGS_VIEW_TITLE, validationSchema } from './consts';
import * as S from './styled';
export const ProductSettingsView = ({ backButtonProps, initialValues, productData, productName = '', isLoading, isSubmitting, isFormEditable, digitalItemsEditable, maxDigitalFilesSize, onSubmit, }) => {
    if (!productData)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: `${PRODUCT_SETTINGS_VIEW_TITLE} \u2013 ${productName}` }),
        React.createElement(Loading, { isLoading: isLoading },
            React.createElement(PageLayout, null,
                React.createElement(Dashboard.Section, { name: PRODUCT_SETTINGS_VIEW_TITLE, topContent: React.createElement(BackButton, { ...backButtonProps }) },
                    React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: onSubmit }, ({ values, dirty, submitForm, resetForm }) => (React.createElement(React.Fragment, null,
                        React.createElement(SoundScan, { isSoundScanProduct: values.isSoundScanProduct, disabled: !isFormEditable }),
                        digitalItemsEditable && (React.createElement(Subsection, { title: "Digital download" },
                            React.createElement(Fieldset, null,
                                React.createElement(Field, { component: Checkbox, name: "digitalItemsEnabled", label: "Add digital download to this product", disabled: !isFormEditable }),
                                values.digitalItemsEnabled && (React.createElement(Fieldset.Row, { inner: true },
                                    React.createElement(S.FileListWrapper, null,
                                        React.createElement(S.SeparatorLine, null),
                                        React.createElement(ProductFiles, { maxSize: maxDigitalFilesSize, offerId: productData.id, files: values.digitalItems, isFormEditable: isFormEditable }))))))),
                        React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
                        React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isSubmitting || values.digitalItems.some(({ isUploading }) => isUploading), onConfirm: submitForm, onCancel: resetForm }),
                        React.createElement(LeavingGuard, { when: dirty })))))))));
};
