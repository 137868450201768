import React from 'react';
import { Stat, StatGroup } from '@fourthwall/module-analytics';
import { Dashboard, TabPills } from '@components/common';
import * as S from './styled';
export const HomeAnalyticsView = ({ tabs, stats }) => (React.createElement("div", null,
    React.createElement("div", { style: { marginBottom: 24 } },
        React.createElement(TabPills, { ...tabs })),
    React.createElement(Dashboard.Section.ScrollableContent, { hideScrollbar: true, fullWidth: true },
        React.createElement(StatGroup, null, stats.map((props, index) => (React.createElement(S.StatWrapper, { key: index },
            React.createElement(Stat, { ...props }))))))));
