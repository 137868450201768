import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  margin-bottom: 56px;
  position: relative;
`;
export const RemoveContainer = styled.div `
  position: absolute;
  right: 20px;
  top: 22px;
  cursor: pointer;

  &:hover {
    color: ${Colors.GS600};
  }

  ${mediaQueries.tablet} {
    display: none;
  }

  ${Container}:hover & {
    display: block;
  }
`;
export const Label = styled.div `
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 16px;
`;
export const AddInputContainer = styled.div `
  width: 100%;

  ${mediaQueries.tablet} {
    width: 224px;
  }
`;
export const InputsContainer = styled.div `
  display: flex;
  position: relative;
`;
export const InputWrapper = styled.div `
  width: 176px;
  position: relative;

  &:after {
    content: '';
    width: 1px;
    height: 16px;
    background-color: ${Colors.GS500};
    position: absolute;
    top: 100%;
    left: 0;
  }
`;
export const SortableListContainer = styled.div `
  margin-bottom: 20px;
`;
export const CheckboxesContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const CheckboxWrapper = styled.div `
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;
