import { Skeleton } from '@fourthwall/components';
import React from 'react';
import { Onboarding as OnboardingComponent } from '../../../../../../../components/common/Onboarding';
import { useMembershipsOnboarding } from './hooks/useMembershipsOnboarding';
export const Onboarding = () => {
    const { items, progress, skipLabel, active, isLoading, onSkip } = useMembershipsOnboarding({
        page: 'MembershipsOverview',
    });
    if (isLoading) {
        return (React.createElement(Skeleton, null,
            React.createElement(Skeleton.Shape, { width: "100%", height: 293 })));
    }
    if (!active) {
        return null;
    }
    return (React.createElement("div", null,
        React.createElement(OnboardingComponent, { progress: progress, onboardingListProps: {
                items,
            }, skipLabel: skipLabel, onSkip: onSkip })));
};
