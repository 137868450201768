import React, { useState } from 'react';
import { ArrowShortDownIcon } from '@fourthwall/icons';
import { Icon } from '../../common/Icon';
import { withFormikSupport } from '../../hocs/withFormikSupport';
import { Input } from '../Input';
import * as S from './styled';
const CustomSelectComponent = ({ name, disabled, invalid, label, value = '', error, submitCount, prepend, size = 'large', transparent = false, onBlur, onSelect, }) => {
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        !disabled && setIsFocused(true);
    };
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
        setIsFocused(false);
    };
    return (React.createElement(S.InputWrapper, { tabIndex: 0, onClick: disabled ? undefined : onSelect, "aria-label": label, onFocus: handleFocus, onBlur: handleBlur },
        React.createElement(Input, { name: name, "aria-hidden": "true", tabIndex: -1, focused: isFocused, actionIcon: React.createElement(Icon, { component: ArrowShortDownIcon, height: 15 }), disabled: disabled, label: label, value: value, error: error, submitCount: submitCount, prepend: prepend, size: size, transparent: transparent, invalid: invalid, autoComplete: "off" })));
};
export const CustomSelect = withFormikSupport(CustomSelectComponent);
