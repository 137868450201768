import { ColorInput, Input, useMediaQueryContext } from '@fourthwall/components';
import { Field } from 'formik';
import get from 'lodash-es/get';
import React, { useEffect, useRef, useState } from 'react';
import { WeightInput } from '../../../../../../components/common/WeightInput';
import * as S from './styled';
export const ProductVariant = ({ variantTypeIndex, index, type, errors, variesByWeight, showWeightsInfo, shouldAutofocusName = false, }) => {
    const media = useMediaQueryContext();
    const [isColorInputFocused, setIsColorInputFocused] = useState(false);
    const [isColorSwatchInputFocused, setIsColorSwatchInputFocused] = useState(false);
    const nameWrapperRef = useRef(null);
    const fieldSize = media.tablet ? 'large' : 'medium';
    useEffect(() => {
        if (shouldAutofocusName) {
            nameWrapperRef.current?.getElementsByTagName('input')[0]?.focus();
        }
    }, []);
    const hasError = (fieldName) => {
        return !!get(errors, `types[${variantTypeIndex}].options[${index}].${fieldName}`);
    };
    const renderColorControl = () => (React.createElement(S.ColorWrapper, null,
        React.createElement(S.NameWrapper, { ref: nameWrapperRef, active: isColorInputFocused || hasError('name') },
            React.createElement(Field, { name: `types[${variantTypeIndex}].options[${index}].name`, component: Input, displayError: false, label: "Color name", size: fieldSize, onFocus: () => setIsColorInputFocused(true), onBlur: () => setIsColorInputFocused(false) })),
        React.createElement(S.ColorSwatchWrapper, { active: isColorSwatchInputFocused },
            React.createElement(Field, { name: `types[${variantTypeIndex}].options[${index}].colorSwatch`, component: ColorInput, size: fieldSize, onOpen: () => setIsColorSwatchInputFocused(true), onClose: () => setIsColorSwatchInputFocused(false) }))));
    const renderSizeControl = () => (React.createElement(S.NameWrapper, { ref: nameWrapperRef },
        React.createElement(Field, { name: `types[${variantTypeIndex}].options[${index}].name`, component: Input, label: "Size name", displayError: false, size: fieldSize })));
    const renderCustomControl = () => (React.createElement(S.NameWrapper, { ref: nameWrapperRef },
        React.createElement(Field, { name: `types[${variantTypeIndex}].options[${index}].name`, component: Input, label: "Variant name", displayError: false, size: fieldSize })));
    const renderWeightControl = () => {
        if (!variesByWeight || showWeightsInfo)
            return null;
        return (React.createElement(S.WeightContainer, null,
            React.createElement(WeightInput, { weightName: `types[${variantTypeIndex}].options[${index}].weight.value`, unitName: `types[${variantTypeIndex}].options[${index}].weight.unit`, weightWidth: media.tablet ? 88 : '100%', unitWidth: media.tablet ? 80 : '100%', size: fieldSize })));
    };
    return (React.createElement(S.Container, null,
        type === 'COLOR' && renderColorControl(),
        type === 'SIZE' && renderSizeControl(),
        type === 'CUSTOM' && renderCustomControl(),
        renderWeightControl(),
        showWeightsInfo && React.createElement(S.InfoText, null, "Enter weight on the product page")));
};
