export const LABEL_BY_FULFILLMENT_SERVICE = {
    BOLT_STITCH: 'Bolt Stitch',
    CHIVA_TOYS: 'Chiva Toys',
    COSMOPOLITAN: 'Cosmopolitan',
    CTC: 'CTC',
    DIGITAL_ITEM: 'Digital item',
    DOD: 'DOD',
    DUBOW: 'Dubow',
    DUBOW_ORDER_DESK: 'Dubow OrderDesk',
    FULFILLED_BY_CREATOR: 'Fulfilled by Creator',
    FULFILLED_BY_FOURTHWALL: 'Fulfilled by Fourthwall',
    FULFILLED_BY_MANUFACTURER: 'Fulfilled by Manufacturer',
    GNARLYWOOD: 'Gnarlywood',
    HOMEMADE_MERCH: 'Homemade Merch (TMG)',
    KNITWISE: 'Knitwise',
    MOGUL_MERCH_LOCAL: 'Mogul Merch (ShipStation)',
    MOGUL_MERCH_SHIP_BOB: 'Mogul Merch (ShipBob)',
    MWW: 'MWW',
    OFFLINE_TV_SHIPSTATION: 'OfflineTv (ShipStation)',
    OPTIGER: 'OPTiger',
    OVERCAST: 'Overcast',
    P3: 'P3',
    P3_ORDER_DESK: 'P3 OrderDesk',
    PRINTFUL: 'Printful',
    RVLT_SHIP_HERO: 'Revolt (ShipHero)',
    SEVENTY_EIGHT_STRANGE: '78 Strange (Pointcrow / Mogul)',
    SHIP_MONK: 'ShipMonk',
    SPOKE: 'Spoke',
    SUPER_CULTURE: 'Super Culture (MogulMerch / ShipBob)',
    TRIBE: 'Tribe',
    USOURCED: 'USourced',
    WHIPLASH_3PL: 'Whiplash 3PL',
    WHIPLASH_FREE: 'Whiplash Free',
    WLM: 'WLM',
    WLM_3PL: 'WLM 3PL',
};
export const LABEL_BY_MANUFACTURING_SERVICE = {
    FOURTHWALL_BESPOKE: 'Fourthwall Bespoke',
    MANUFACTURED_BY_CREATOR: 'Manufactured by Creator',
    SPOKE: 'Spoke',
    PRINTFUL: 'Printful',
    WLM: 'WLM',
    DIGITAL_ITEM: 'Digital Item',
    DUBOW: 'Dubow',
    DUBOW_DTFX: 'Dubow DTFX',
    TRIBE: 'Tribe',
    OPTIGER: 'OP Tiger',
    OVERCAST: 'Overcast',
    MWW: 'MWW',
    P3: 'P3',
    DEMO_PRODUCT: 'Demo Product',
    KNITWISE: 'Knitwise',
    DOD: 'DOD',
};
