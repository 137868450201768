import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Column = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const Row = styled.div `
  display: flex;
  gap: 16px;
`;
export const Label = styled.div `
  font-size: 14px;
  color: ${Colors.GS600};
`;
export const StockTooltipWrapper = styled.div `
  display: inline-block;
`;
export const CompareAtPrice = styled.div `
  text-decoration: line-through;
`;
export const WeightInputContainer = styled.div `
  display: flex;
  gap: 8px;
`;
