import { Heading } from '@fourthwall/components';
import { MOBILE_APP_ANDROID_LINK, MOBILE_APP_IOS_LINK } from '@fourthwall/utils/lib/links';
import React from 'react';
import downloadAppQrcodeIcon from '../../../../../assets/donwload-app-qrcode.svg';
import { Cellphone } from '../../../../../components/common/Cellphone';
import * as S from './styled';
export const NoThankYousContent = () => (React.createElement(S.Container, null,
    React.createElement(S.CellphoneWrapper, null,
        React.createElement(Cellphone, null)),
    React.createElement(S.InfoWrapper, null,
        React.createElement(S.Message, null,
            React.createElement(Heading, { type: "h6", asTag: "h2", weight: "semibold" }, "You haven\u2019t sent any thank yous yet"),
            React.createElement(S.Subtitle, null, "Download our app to send personal thank yous to your fans!")),
        React.createElement(S.DownloadAppIcons, null,
            React.createElement(S.DownloadAppQrcodeIcon, { src: downloadAppQrcodeIcon, alt: "Download app" }),
            React.createElement(S.DownloadLink, { href: MOBILE_APP_IOS_LINK, target: "_blank", "aria-label": "Download Application from App Store" },
                React.createElement(S.AppDownloadAppStore, null)),
            React.createElement(S.DownloadLink, { href: MOBILE_APP_ANDROID_LINK, target: "_blank", "aria-label": "Download Application from Google Play" },
                React.createElement(S.AppDownloadGooglePlay, null))))));
