import { Toasts } from '@fourthwall/components';
import { useState } from 'react';
import { useCheckMediaCreatedMutation } from '../../../hooks/useCheckMediaCreatedMutation';
import { useImageConfirmMutation } from '../../../hooks/useImageConfirmMutation';
import { useUploadImageMutation } from '../../../hooks/useUploadImageMutation';
import { uploadMedia } from '../../../utils/uploadMedia';
import { useCreateSubtitlesResource } from './useCreateSubtitlesResource';
import { useUpdateVideoPostMutation } from './useUpdateVideoPostMutation';
export const useUpdateVideoPost = () => {
    const [isLoading, setLoading] = useState(false);
    const [mediaUploadProgress, setMediaUploadProgress] = useState(0);
    const { updateVideoPostMutation } = useUpdateVideoPostMutation();
    const { checkMediaCreatedMutation } = useCheckMediaCreatedMutation();
    const { uploadImageMutation } = useUploadImageMutation();
    const { imageConfirmMutation } = useImageConfirmMutation();
    const { createSubtitlesResource } = useCreateSubtitlesResource();
    const getSubtitlesResourcePayload = async (subtitles) => {
        if (typeof subtitles === 'object') {
            const id = await createSubtitlesResource(subtitles, () => {
                setLoading(false);
                throw new Error('Memberships: create subtitle error');
            });
            return {
                type: 'New',
                id,
            };
        }
        if (subtitles === false) {
            return {
                type: 'Remove',
            };
        }
        return {
            type: 'Keep',
        };
    };
    const updateVideoPost = async (id, file, thumbnail, subtitles, payload, handleSuccess) => {
        setLoading(true);
        const onSuccess = () => {
            setMediaUploadProgress(0);
            setLoading(false);
            handleSuccess();
        };
        const subtitlesResource = await getSubtitlesResourcePayload(subtitles);
        const { postId, videoDirectUploadData, imageDirectUploadData } = await updateVideoPostMutation.mutateAsync({
            id: Number(id),
            ...payload,
            subtitlesResource,
        }, {
            onSuccess: ({ videoDirectUploadData, imageDirectUploadData }) => {
                // Update text fields only
                if (!videoDirectUploadData && !imageDirectUploadData) {
                    onSuccess();
                }
            },
            onError: () => setLoading(false),
        });
        if (imageDirectUploadData) {
            await uploadImageMutation.mutateAsync({ file: thumbnail, ...imageDirectUploadData }, {
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: update video post upload image error');
                },
            });
            await imageConfirmMutation.mutate({ id: postId, signedId: imageDirectUploadData.signedId }, {
                onSuccess: () => {
                    // Update image only
                    if (!videoDirectUploadData) {
                        onSuccess();
                    }
                },
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: update video post confirm image error');
                },
            });
        }
        // Update video
        if (videoDirectUploadData) {
            await uploadMedia(videoDirectUploadData.uploadUrl, file, (progress) => {
                setMediaUploadProgress(Number(progress.toFixed()));
            }).catch(() => {
                Toasts.notify('Media upload error. Please try again.', { type: 'error' });
            });
            checkMediaCreatedMutation.mutate(postId, {
                onSuccess,
                onError: () => setLoading(false),
            });
        }
    };
    return {
        updateVideoPost,
        isLoading,
        mediaUploadProgress,
    };
};
