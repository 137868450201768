import { useQueryPage } from '@fourthwall/utils';
import isNil from 'lodash-es/isNil';
import { StringParam, useQueryParams } from 'use-query-params';
export const useFilterPromotionCodes = () => {
    const { setPage } = useQueryPage();
    const [queryParams, setQueryParams] = useQueryParams({
        creationReason: StringParam,
    });
    const resetFiltersParams = () => {
        setPage(undefined);
        setQueryParams({
            creationReason: undefined,
        });
    };
    const setFiltersParams = (params) => {
        setPage(undefined);
        setQueryParams(params);
    };
    const hasFilterParams = Object.values(queryParams).some((param) => !isNil(param));
    return {
        filtersParams: queryParams,
        setFiltersParams,
        resetFiltersParams,
        hasFilterParams,
    };
};
