import React from 'react';
import { ArrowShortDownIcon } from '@fourthwall/icons';
import { Icon } from '../../Icon';
import * as Styled from './styled';
export const TableHeadCell = ({ sortable, direction, compact, alignment = 'left', forOption, width = 1, minWidth, onClick, children, }) => {
    const renderIcon = () => {
        if (!sortable)
            return null;
        return (React.createElement(Styled.IconContainer, { direction: direction },
            React.createElement(Icon, { component: ArrowShortDownIcon, height: 10 })));
    };
    return (React.createElement(Styled.Container, { role: "columnheader", compact: compact, alignment: alignment, forOption: forOption, "$width": width, minWidth: minWidth, onClick: onClick, "data-testid": "TableHeadCell" },
        children,
        renderIcon()));
};
