import { Checkbox, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import { useFormik } from '@fourthwall/utils/lib/formik';
import { FilterDropdown } from '@components/common/FilterDropdown';
import * as S from './styled';
export const ProductsFilterView = ({ statuses, availabilityOptions, initialValues, filterActive, onSubmit, onReset, }) => {
    const media = useMediaQueryContext();
    const formik = useFormik({
        initialValues,
        onSubmit,
    });
    const handleReset = () => {
        formik.resetForm();
        onReset && onReset();
    };
    const handleCheckboxGroupChange = (checked, value, name) => {
        const fieldValue = checked
            ? [...formik.values[name], value]
            : formik.values[name].filter((v) => v !== value);
        formik.setFieldValue(name, fieldValue);
    };
    const renderStatuses = () => !!statuses?.length && (React.createElement(S.FiltersColumn, null,
        React.createElement(FilterDropdown.GroupTitle, { title: "Status" }),
        React.createElement(S.CheckboxGroup, { role: "group" }, statuses.map((status) => (React.createElement(Checkbox, { native: true, key: status.value, label: status.label, value: status.value, checked: formik.values.status.includes(status.value), onValueChange: (checked) => handleCheckboxGroupChange(checked, status.value, 'status') }))))));
    const renderAvailabilitiesOptions = () => !!availabilityOptions?.length && (React.createElement(S.FiltersColumn, null,
        React.createElement(FilterDropdown.GroupTitle, { title: "Stock" }),
        React.createElement(S.CheckboxGroup, { role: "group" }, availabilityOptions.map((option) => (React.createElement(Checkbox, { native: true, key: option.value, label: option.label, value: option.value, checked: formik.values.availability.includes(option.value), onValueChange: (checked) => handleCheckboxGroupChange(checked, option.value, 'availability') }))))));
    const renderContent = () => {
        if (!media.tablet) {
            return (React.createElement(S.Form, null,
                renderStatuses(),
                renderAvailabilitiesOptions(),
                React.createElement(S.FiltersColumn, null,
                    React.createElement(FilterDropdown.GroupTitle, { title: "Archived products" }),
                    React.createElement(Checkbox, { label: "Include archived products", ...formik.getFieldProps('archived') }))));
        }
        return (React.createElement(S.Form, null,
            React.createElement(S.FormRow, null,
                renderStatuses(),
                renderAvailabilitiesOptions()),
            React.createElement(Checkbox, { label: "Include archived products", ...formik.getFieldProps('archived') })));
    };
    return (React.createElement(FilterDropdown, { content: renderContent(), activityIndicator: filterActive || !!formik.values.availability.length, onSubmit: formik.handleSubmit, onReset: handleReset }));
};
