import React from 'react';
import { useOfferImages } from './hooks/useOfferImages';
import { OfferImagesView } from './OfferImagesView';
export const OfferImagesContainer = ({ offer, offerVariantTypes, value = [], disabled = false, onChange, onColorFilterSort, onUploadStart, onUploadEnd, }) => {
    const { offerImagesProps } = useOfferImages({
        offer,
        offerVariantTypes,
        value,
        disabled,
        onChange,
        onColorFilterSort,
        onUploadStart,
        onUploadEnd,
    });
    if (!offerImagesProps)
        return null;
    return React.createElement(OfferImagesView, { ...offerImagesProps });
};
