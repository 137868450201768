import { ApiBase } from './ApiBase';
class PaymentShopBlockedTransactionsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getPaymentShopBlockedTransactions = (params) => {
            return this.get('/v2/payment/blocked-transactions', params);
        };
    }
}
export const PaymentShopBlockedTransactionsApiClient = new PaymentShopBlockedTransactionsApiClientModel();
