import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { useQuery } from '@tanstack/react-query';
export const useAccountSettingsQuery = () => {
    const accountSettingsQuery = useQuery({
        queryKey: ['useAccountSettingsQuery'],
        queryFn: async () => {
            const { data } = await MembershipsCreatorApiClient.getAccountSettings();
            return data;
        },
    });
    return { accountSettingsQuery };
};
