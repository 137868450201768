import styled from 'styled-components';
import { Colors } from '../../colors';
import { mediaQueries } from '../../providers/MediaQueryProvider';
export const Container = styled.div `
  width: 100%;

  cursor: pointer;
`;
export const InputsContainer = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${Colors.GS600};
  pointer-events: none;
`;
export const CalendarIconContainer = styled.div `
  margin-left: 8px;
  margin-top: 4px;
`;
export const CalendarContainer = styled.div `
  width: 100%;
  background: ${Colors.GS0};
  display: flex;
  justify-content: center;
  align-items: flex-start;

  ${mediaQueries.tablet} {
    width: auto;
    display: inline;
    min-height: 0;
  }
`;
