import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import logoSrc from '../../../assets/signets/streamlabs.svg';
import { useDisconnectStreamlabsMutation } from './useDisconnectStreamlabsMutation';
import { useStreamlabsAuthUrlQuery } from './useStreamlabsAuthUrlQuery';
import { useAppConnectedEvent } from '../../../hooks/useAppConnectedEvent';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
export const useStreamlabs = () => {
    const { streamlabsAuthUrlQuery } = useStreamlabsAuthUrlQuery();
    const { disconnectStreamlabsMutation } = useDisconnectStreamlabsMutation();
    const { open } = useModalContext();
    const appConnectEvent = useAppConnectEvent('stream_labs_alerts');
    useAppConnectedEvent('stream_labs_alerts');
    const getStatus = () => {
        if (!streamlabsAuthUrlQuery.data)
            return undefined;
        if (streamlabsAuthUrlQuery.data.streamLabsAuthenticationUrl)
            return 'NOT_CONNECTED';
        if (!streamlabsAuthUrlQuery.data.streamLabsAuthenticationUrl)
            return 'CONNECTED';
    };
    const status = getStatus();
    const getConnectBar = () => {
        const connectBarBase = {
            logoSrc,
            description: 'Connect to your Streamlabs account',
            disabled: false,
        };
        if (!status)
            return undefined;
        if (status === 'NOT_CONNECTED') {
            if (!streamlabsAuthUrlQuery.data)
                return;
            const onConnect = () => {
                appConnectEvent.trigger();
                if (streamlabsAuthUrlQuery.data.streamLabsAuthenticationUrl) {
                    const url = new URL(streamlabsAuthUrlQuery.data.streamLabsAuthenticationUrl);
                    url.searchParams.append('connected', '');
                    window.location.assign(url.toString());
                }
            };
            return {
                ...connectBarBase,
                onConnect,
            };
        }
        if (status === 'CONNECTED') {
            const onDisconnect = () => {
                open(ConfirmModal, {
                    title: 'Are you sure you want to disconnect from Streamlabs?',
                    confirmLabel: 'Yes, disconnect',
                    confirmAppearance: 'destructive',
                    onConfirm: () => {
                        const toastId = 'disconnectStreamlabsMutation';
                        Toasts.notify('Disconnecting from Streamlabs...', {
                            toastId,
                            type: 'info',
                            autoClose: false,
                        });
                        disconnectStreamlabsMutation.mutate(undefined, {
                            onSuccess: () => {
                                streamlabsAuthUrlQuery.refetch();
                                Toasts.notify('Disconnected from Streamlabs successfully.', { type: 'success' });
                                Toasts.dismiss(toastId);
                            },
                        });
                    },
                });
            };
            return {
                ...connectBarBase,
                disabled: disconnectStreamlabsMutation.isLoading,
                onDisconnect,
            };
        }
    };
    return {
        streamlabs: {
            status,
            connectBar: getConnectBar(),
        },
    };
};
