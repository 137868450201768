import { UserSecurityApiClient } from '@fourthwall/services/lib/UserSecurityApiClient';
import { isIdentityProvider } from './identity-providers';
import { useUserSecurityQuery } from './queries';
export const CREDENTIAL_TYPES = {
    passkey: 'webauthn',
    authenticator: 'otp',
};
export const useSecurity = () => {
    const { userSecurityQuery } = useUserSecurityQuery();
    const identityProviders = (() => {
        if (!userSecurityQuery.isSuccess)
            return [];
        const { identityProviders } = userSecurityQuery.data;
        if (!identityProviders.length)
            return [];
        return identityProviders
            .map(({ identityProvider }) => identityProvider)
            .filter(isIdentityProvider);
    })();
    const password = (() => {
        if (!userSecurityQuery.isSuccess)
            return null;
        const { credentials } = userSecurityQuery.data;
        const passwordCredential = credentials.find((credential) => credential.type === 'password');
        return {
            identityProviders,
            createdDate: passwordCredential ? passwordCredential.createdDate : null,
            updatePasswordUrl: UserSecurityApiClient.getUserSecurityRedirectUrl({
                action: 'UPDATE_PASSWORD',
            }),
        };
    })();
    const deleteCredential = (id) => {
        const url = UserSecurityApiClient.getUserSecurityRedirectUrl({
            action: 'fw_delete_credential',
            id,
        });
        window.location.assign(url);
    };
    const passkeys = (() => {
        if (!userSecurityQuery.isSuccess)
            return null;
        const { credentials } = userSecurityQuery.data;
        return {
            credentials: credentials
                .filter((credential) => credential.type === CREDENTIAL_TYPES.passkey)
                .map((credential) => ({ ...credential, name: credential.userLabel || 'Unknown passkey' })),
            setUpUrl: UserSecurityApiClient.getUserSecurityRedirectUrl({ action: 'webauthn-register' }),
            onDelete: deleteCredential,
        };
    })();
    const authenticators = (() => {
        if (!userSecurityQuery.isSuccess)
            return null;
        const { credentials } = userSecurityQuery.data;
        return {
            credentials: credentials
                .filter((credential) => credential.type === CREDENTIAL_TYPES.authenticator)
                .map((credential) => ({
                ...credential,
                name: credential.userLabel || 'Unknown authenticator',
            })),
            setUpUrl: UserSecurityApiClient.getUserSecurityRedirectUrl({ action: 'CONFIGURE_TOTP' }),
            onDelete: deleteCredential,
        };
    })();
    return { password, passkeys, authenticators };
};
