import get from 'lodash-es/get';
import isNaN from 'lodash-es/isNaN';
import isNumber from 'lodash-es/isNumber';
import Yup from '@fourthwall/utils/lib/yup';
Yup.addMethod(Yup.mixed, 'requiredIfVary', function (message, fieldName) {
    return this.test('requiredIfVary', message, function (value) {
        const context = this.options.context;
        const variantType = get(context.values, `${this.path.split('.')[0]}`);
        const occurrences = context.values.types.filter((item) => item.variesBy[fieldName]).length;
        if (variantType.variesBy[fieldName] && occurrences === 1) {
            if (isNumber(value)) {
                return !isNaN(value);
            }
            return value && value.length;
        }
        return true;
    });
});
export const validationSchema = Yup.object().shape({
    types: Yup.array().of(Yup.object().shape({
        title: Yup.string()
            .required()
            .max(80)
            .test('unique', 'unique', function (value) {
            const context = this.options.context;
            const occurrences = context.values.types.filter((item) => item.title.toLowerCase() === value?.toLowerCase()).length;
            return occurrences <= 1;
        }),
        options: Yup.array().of(Yup.object().shape({
            name: Yup.string()
                .required()
                .test('unique', 'unique', function (value) {
                const context = this.options.context;
                const variantType = get(context.values, `${this.path.split('.')[0]}`);
                const occurences = variantType?.options.filter((item) => item.name.toLowerCase() === value?.toLowerCase()).length;
                return occurences <= 1;
            }),
            weight: Yup.object().shape({
                value: Yup.number().requiredIfVary('conditionalRequired', 'weight'),
                unit: Yup.string().requiredIfVary('conditionalRequired', 'weight'),
            }),
        })),
        variesBy: Yup.object().shape({
            price: Yup.boolean(),
            imagery: Yup.boolean(),
            weight: Yup.boolean(),
        }),
    })),
});
