import styled from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
import { ButtonsContainer } from '@components/composed/Status/styled';
export const Container = styled.div `
  ${mediaQueries.tablet} {
    display: flex;
    align-items: center;
    gap: 64px;
  }
`;
export const PreviewContainer = styled.div `
  margin-bottom: 20px;

  ${mediaQueries.tablet} {
    width: 52%;
    max-width: 460px;
    margin-bottom: 0;
  }
`;
export const PreviewWrapper = styled.div `
  aspect-ratio: 460 / 340;
  overflow: hidden;
  position: relative;
  border: 1px solid ${Colors.GS400};
`;
export const Iframe = styled.iframe `
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 400%;
  height: 400%;
  transform-origin: 0 0;
  transform: scale(0.25);
`;
export const Image = styled.img `
  display: block;
  width: 100%;
`;
export const Main = styled.div `
  ${mediaQueries.tablet} {
    flex: 1;
  }
`;
export const Description = styled.div `
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0 4px;
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS1000};
  margin-top: 4px;
`;
export const ScheduleButtonsContainer = styled(ButtonsContainer) `
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-top: 20px;
  gap: 8px;
`;
export const ButtonCleanContainer = styled.div `
  margin: 0 auto;
`;
export const CopyLinkWrapper = styled.div `
  display: block;

  flex: 1;
  overflow: hidden;
  min-width: 120px;
`;
