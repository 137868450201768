import { isPositiveIntegerString, pluralize } from '@fourthwall/utils/lib/string';
import React, { useRef, useState } from 'react';
import { ArrowLongRightIcon } from '@fourthwall/icons';
import { Icon } from '../../common/Icon';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { InputUI } from '../../ui';
import { Input } from '../Input';
import * as S from './DayRange.styled';
export const DayRange = ({ value, name, fromLabel = 'From', toLabel = 'To', width, suggestion, error, submitCount, disabled = false, onChange, onValueChange, }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isFromFocused, setIsFromFocused] = useState(false);
    const [isToFocused, setIsToFocused] = useState(false);
    const [from = '', to = ''] = value || [];
    const ref = useRef(null);
    useOutsideClick(ref, () => {
        setIsFocused(false);
    });
    const formatValue = (value, isFocused) => {
        if (!value || isFocused) {
            return value;
        }
        return `${value} ${pluralize(parseInt(value), 'day')}`;
    };
    const parseValue = (value) => {
        return [
            !value?.[0] || isPositiveIntegerString(value[0]) ? value[0] : from,
            !value?.[1] || isPositiveIntegerString(value[1]) ? value[1] : to,
        ];
    };
    const handleValueChange = (value) => {
        const parsedValue = parseValue(value);
        if (onChange) {
            onChange(parsedValue);
        }
        if (onValueChange) {
            onValueChange(parsedValue);
        }
    };
    const renderInputs = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement(S.InputWrapper, null,
                React.createElement(Input, { name: name?.[0], hideSpinner: true, label: fromLabel, transparent: true, value: formatValue(from, isFromFocused), disabled: disabled, onFocus: () => setIsFromFocused(true), onBlur: () => setIsFromFocused(false), onValueChange: (newFrom) => handleValueChange([newFrom, to]) })),
            React.createElement(S.IconContainer, null,
                React.createElement(Icon, { component: ArrowLongRightIcon, height: 16 })),
            React.createElement(S.InputWrapper, null,
                React.createElement(Input, { name: name?.[1], hideSpinner: true, label: toLabel, transparent: true, value: formatValue(to, isToFocused), disabled: disabled, onFocus: () => setIsToFocused(true), onBlur: () => setIsToFocused(false), onValueChange: (newTo) => handleValueChange([from, newTo]) }))));
    };
    return (React.createElement(S.InputContainer, { onClick: !disabled ? () => setIsFocused(true) : undefined, ref: ref, "data-testid": "DayRange.Container" },
        React.createElement(S.InputBoxContainer, { width: width },
            React.createElement(InputUI.Box, { invalid: !!error, focused: isFocused },
                React.createElement(S.InputsContainer, null, renderInputs()))),
        !error && suggestion && React.createElement(InputUI.Suggestion, null, suggestion),
        !!error && React.createElement(InputUI.Error, { errorTimestampKey: submitCount }, error)));
};
