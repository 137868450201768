import { Banner, Loader } from '@fourthwall/components';
import { routing } from '@utils/routing';
import React from 'react';
import * as S from './styled';
export const MissingTermsBanner = ({ isReconnecting, onReconnect }) => (React.createElement(Banner, { appearance: "warning", size: "small" },
    "To finish connecting to YouTube you have to: ",
    React.createElement("br", null),
    React.createElement(S.Row, null,
        "1. Enter your",
        React.createElement("a", { href: routing.external.youtube.shoppingSettings, target: "_blank", rel: "noreferrer" }, "YouTube Studio")),
    React.createElement(S.Row, null, "2. If you don't have any store connected yet, do it through \"Get started \u2192 Stores\" section by clicking on \"Connect\" button. If you do have some store connected already, scroll down to \"Manage products \u2192 Stores\" section, click on more options icon and select \"Add another store\" there."),
    React.createElement(S.Row, null, "3. Select Fourthwall and agree to the terms of service"),
    React.createElement(S.Row, null,
        "4.",
        React.createElement("button", { onClick: onReconnect, disabled: isReconnecting }, "Click here"),
        "in order to reconnect",
        isReconnecting && (React.createElement(S.LoaderWrapper, null,
            React.createElement(Loader, { size: "small" }))))));
