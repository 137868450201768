import { ButtonClean, Dropdown, ToolbarButton } from '@fourthwall/components';
import { DotsHorizontalIcon } from '@fourthwall/icons';
import React from 'react';
import * as S from './styled';
export const WebhookListItem = ({ id, allowedTypes, url, onUpdate, onRemove, onTest }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.Content, null,
            React.createElement(S.Title, null, allowedTypes.map(({ label }) => label).join(', ')),
            React.createElement(S.Url, null, url),
            React.createElement(ButtonClean, { label: "Send test notification", onClick: () => onTest(id) })),
        React.createElement(Dropdown, { content: React.createElement(React.Fragment, null,
                React.createElement(Dropdown.Item, { label: "Update", onClick: () => onUpdate(id) }),
                React.createElement(Dropdown.Item, { label: "Remove", onClick: () => onRemove(id) })), position: "bottom-end" },
            React.createElement(ToolbarButton, { icon: DotsHorizontalIcon }))));
};
