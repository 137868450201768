import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Content = styled.div `
  margin-top: 16px;
  line-height: 24px;
`;
export const Error = styled.div `
  color: ${Colors.R300};
  margin-top: 24px;
`;
