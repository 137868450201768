import { CreatorIntegrationsApiClient } from '@fourthwall/services/lib/CreatorIntegrationsApiClient';
import { useMutation } from '@tanstack/react-query';
export const useDeleteTwitchMutation = () => {
    const deleteTwitchMutation = useMutation({
        mutationFn: async () => {
            const { data } = await CreatorIntegrationsApiClient.deleteCreatorIntegration({
                app: 'twitch',
            });
            return data;
        },
    });
    return { deleteTwitchMutation };
};
