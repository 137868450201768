export const codeMessages = {
    'file-invalid-type': 'File type is not supported.',
    'too-many-files': 'Multiple files upload is not supporterd.',
};
export const getRejectionErrors = (rejectedFiles, enhancedCodeMessages) => {
    const codes = { ...codeMessages, ...enhancedCodeMessages };
    return rejectedFiles.map((rejectedFile) => {
        const fileErrors = rejectedFile.errors.map((error) => {
            return codes[error.code] || error.message || 'Upload error.';
        });
        return `"${rejectedFile.file.name}": ${fileErrors.join(' ')}`;
    });
};
