import { Tab as ReactTab } from 'react-tabs';
import styled, { css } from 'styled-components';
import { Colors } from '../../../colors';
export const TabContainer = styled(ReactTab) `
  ${({ $direction }) => $direction === 'vertical' &&
    css `
      margin-left: 0 !important; // NOTE: I still have no idea why it's needed here. For some reason, CSS class for "margin-left" styles is applied as well in this scenario.
    `};

  ${({ $direction, $marginSize }) => $direction === 'horizontal' &&
    $marginSize === 'medium' &&
    css `
      & + & {
        margin-left: 16px;
      }
    `};

  ${({ $direction, $marginSize }) => $direction === 'horizontal' &&
    $marginSize === 'large' &&
    css `
      & + & {
        margin-left: 32px;
      }
    `};
`;
export const Tab = styled.button `
  position: relative;

  font-size: 16px;
  line-height: 150%;

  ${({ $direction, size }) => $direction === 'vertical' &&
    size === 'medium' &&
    css `
      margin-bottom: 10px;
    `};

  ${({ $direction, size }) => $direction === 'vertical' &&
    size === 'large' &&
    css `
      margin-bottom: 16px;
    `};

  ${({ variant }) => variant === 'dimmed' &&
    css `
      color: ${Colors.GS600};
    `};

  ${({ variant }) => variant === 'scrollable' &&
    css `
      color: ${Colors.GS600};
      white-space: nowrap;
      font-weight: 600;
    `};

  cursor: pointer;

  &:hover,
  &:focus {
    color: ${Colors.B500};
    outline: none;
  }

  &:after {
    content: '';

    position: absolute;
    bottom: 0;

    display: block;
    width: 100%;
    height: 2px;
    background: ${Colors.GS1000};

    opacity: 0;
  }

  ${({ isActive }) => isActive &&
    css `
      color: ${Colors.GS1000} !important;

      &:after {
        opacity: 1;
      }
    `};
`;
