const mapDomainDnsRecordsToEntriDnsRecords = (dnsList) => dnsList.list.map(({ host = '', value = '', priority = 10, recordType, zoneFile }) => ({
    host: zoneFile?.hostLabel || host,
    value,
    type: recordType,
    ttl: 300,
    priority,
}));
export const getEntriConfig = (dnsList, shopId) => ({
    userId: shopId,
    dnsRecords: mapDomainDnsRecordsToEntriDnsRecords(dnsList),
    prefilledDomain: dnsList.domain,
});
export const getUpdateEntriProviderDetailsBody = (data) => ({
    name: data.provider,
    mode: data.setupType.toUpperCase(),
});
