import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { NumberString, useModalContext } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import { useCurrentShopDashboardQuery } from '@modules/Dashboard/hooks/useCurrentShopDashboardQuery';
import { ThankYousModal } from '@modules/Dashboard/ThankYous/components/ThankYousModal';
import { SendThankYouModal } from '@modules/Dashboard/ThankYous/modules/SendThankYouModal';
import { routing } from '@utils/routing';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetTopKudosQuery } from '@hooks/useGetTopKudosQuery';
import { ThankYousBarView } from './ThankYousBarView';
export const ThankYousBarContainer = ({ type, onModalSuccess }) => {
    const navigate = useNavigate();
    const { currentShopDashboardQuery } = useCurrentShopDashboardQuery();
    const { getTopKudosQuery, invalidateGetTopKudosQuery } = useGetTopKudosQuery([{ size: 1000 }]);
    const { open } = useModalContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { dashboardPermissions } = useDashboardPermissions();
    const handleSendThankYouModalSuccess = () => {
        onModalSuccess?.();
        invalidateGetTopKudosQuery();
    };
    const handleActionBarClick = () => {
        if (type === 'REDIRECT') {
            navigate(routing.contributions.thankYous.self);
            return;
        }
        // type === 'MODAL'
        if (dashboardFeatureFlags.thankYousSending.enabled) {
            open(SendThankYouModal, {
                thankYous: getTopKudosQuery.data?.orders,
                onSuccess: handleSendThankYouModalSuccess,
            });
            return;
        }
        open(ThankYousModal);
    };
    const getTitle = () => {
        const topSupportersCount = getTopKudosQuery.data?.page.elementsTotal;
        if (!topSupportersCount) {
            return React.createElement(React.Fragment, null, "Thank your supporters");
        }
        if (type === 'REDIRECT') {
            return (React.createElement(React.Fragment, null,
                "Thank ",
                React.createElement(NumberString, { value: topSupportersCount }),
                " top",
                ' ',
                pluralize(topSupportersCount, 'supporter')));
        }
        if (type === 'MODAL') {
            return (React.createElement(React.Fragment, null,
                "Start thanking top ",
                React.createElement(NumberString, { value: topSupportersCount }),
                ' ',
                pluralize(topSupportersCount, 'supporter')));
        }
    };
    if (!dashboardPermissions.contributions.thankYous) {
        return null;
    }
    if ((type === 'MODAL' && !getTopKudosQuery.data?.orders) ||
        !currentShopDashboardQuery.data?.remainingThankYousAmount) {
        return null;
    }
    return React.createElement(ThankYousBarView, { title: getTitle(), onClick: handleActionBarClick });
};
