import { useDashboardFeatureFlags } from '@components';
import { ConfirmModal, useModalContext } from '@fourthwall/components';
import { useCreatorIntegrationsQuery } from '@modules/Dashboard/Apps';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
import { routing } from '@utils/routing';
import twitchPromotion from '../assets/twitch-promotion.png';
import { useRecommendationsDismiss } from '../useRecommendationsDismiss';
export const useTwitchPromotionRecommendation = () => {
    const { currentShop } = useCurrentShop();
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const { recommendationsDismiss } = useRecommendationsDismiss();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { open } = useModalContext();
    const hasTwitchPlatform = currentShop?.metafields?.onboarding?.['oauth-flow'] === true &&
        currentShop?.metafields?.onboarding?.['creator-platform'] === 'twitch';
    const hasTwitchSocial = !!currentShop?.social.twitch;
    const shouldShowTwitchRecommendation = (hasTwitchPlatform || hasTwitchSocial) &&
        creatorIntegrationsQuery.data?.twitch.status === 'CONNECTED';
    const getDescription = () => {
        if (dashboardFeatureFlags.memberships.enabled) {
            return 'Offer your Twitch subscribers deals on products and/or memberships';
        }
        return 'Offer your Twitch subscribers exclusive deals';
    };
    const handleDismiss = () => {
        open(ConfirmModal, {
            title: 'Are you sure you want to hide this recommendation?',
            text: 'This action cannot be reversed.',
            confirmLabel: 'Hide recommendation',
            onConfirm: recommendationsDismiss.twitchPromotion.dismiss,
        });
    };
    return {
        twitchPromotionRecommendation: {
            enabled: shouldShowTwitchRecommendation && recommendationsDismiss.twitchPromotion.visible,
            suggestionProps: {
                type: 'props',
                props: {
                    title: 'Offer discounts for Twitch subs',
                    description: getDescription(),
                    imageUrl: twitchPromotion,
                    analytics: 'twitchPromotion',
                    buttonLabel: 'Set up discounts for Twitch subs',
                    buttonTo: routing.apps.twitchMembershipsDiscounts,
                    onDismiss: handleDismiss,
                },
            },
        },
    };
};
