import { useEffect } from 'react';
// Force loading more when there are no enough items to make the container scrollable
// (only scrolling to the very bottom triggers InfiniteScroll next function)
// https://github.com/ankeetmaini/react-infinite-scroll-component/issues/309
export const useLoadMoreWhenNotScrollable = ({ itemsLength, isLoading = false, hasNextPage, scrollableContentRef, loadMore, }) => {
    useEffect(() => {
        const isScrollable = scrollableContentRef.current
            ? scrollableContentRef.current?.scrollHeight > scrollableContentRef.current?.clientHeight
            : false;
        if (!isLoading && hasNextPage && !isScrollable) {
            loadMore();
        }
    }, [itemsLength, isLoading, hasNextPage, scrollableContentRef, loadMore]);
};
