import { useState } from 'react';
import { useShopStage, useTagManager } from '@hooks';
import { useDashboardFeatureFlags } from '@components';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
import { usePersonalizedRecommendationsQuery } from '../queries/usePersonalizedRecommendationsQuery';
import { useBespokeRecommendationsQuery } from '../queries/useBespokeRecommendationsQuery';
import { useProductsQuery } from '../queries/useProductsQuery';
import { CATEGORIES } from '../categories';
import { removeMinorAttributes } from './utils';
export const staleTime = Infinity; // each response gives different results and it crashes the slider
export const useHomeProducts = () => {
    const [activeCategory, setActiveCategory] = useState('recommendations');
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const TagManager = useTagManager();
    const { currentShop } = useCurrentShop();
    const { personalizedRecommendationsQuery } = usePersonalizedRecommendationsQuery(undefined, {
        staleTime,
    });
    const { bespokeRecommendationsQuery } = useBespokeRecommendationsQuery(undefined, { staleTime });
    const { productsQuery } = useProductsQuery([{ favourite: true }], { staleTime });
    const { shopStage } = useShopStage();
    if (!dashboardFeatureFlags.homeProducts.enabled)
        return null;
    if (!shopStage.currentStage)
        return null;
    if (!personalizedRecommendationsQuery.isSuccess ||
        !bespokeRecommendationsQuery.isSuccess ||
        !productsQuery.isSuccess) {
        return null;
    }
    const recommendationsProducts = (() => {
        return shopStage.stage4
            ? bespokeRecommendationsQuery.data
            : personalizedRecommendationsQuery.data;
    })();
    const productsPerCategory = {
        recommendations: recommendationsProducts.map(removeMinorAttributes),
        favorites: productsQuery.data.products.map(removeMinorAttributes),
    };
    const categories = (() => ({
        options: CATEGORIES,
        value: activeCategory,
        onChange: setActiveCategory,
    }))();
    const onProductClick = (product) => {
        if (!currentShop)
            return;
        TagManager.trigger('recommendation_click', {
            recommendation_type: 'create_new_product',
            flow: 'product',
            product_id: product.id,
            product_name: product.name,
            shop_id: currentShop.id,
        });
    };
    return {
        categories,
        products: productsPerCategory[activeCategory],
        onProductClick,
    };
};
