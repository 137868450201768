import React from 'react';
import { Heading } from '../Heading';
import { Button } from '../Button';
import * as Styled from './styled';
export const EmptyState = ({ title, subtitle, label, href, to, disabled = false, variant = 'primary', onClick, }) => {
    return (React.createElement(Styled.Container, null,
        React.createElement(Heading, { type: variant === 'primary' ? 'h6' : 'h7', asTag: "h2", weight: "bold" }, title),
        subtitle && React.createElement(Styled.Subtitle, null, subtitle),
        label && variant === 'primary' && (React.createElement(Styled.ButtonWrapper, null,
            React.createElement(Button, { label: label, to: to, href: href, appearance: "primary", size: "medium", disabled: disabled, onClick: onClick })))));
};
