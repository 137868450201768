import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { mediaQueries } from '../../providers/MediaQueryProvider';
export const Container = styled.div `
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${({ numberOfColumns }) => numberOfColumns}, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1px;
  background: ${Colors.GS500};
  border: 1px solid ${Colors.GS500};

  ${({ error }) => error &&
    css `
      border-color: ${Colors.R300};
    `}
`;
export const Option = styled.label `
  display: flex;
  justify-content: center;
  position: relative;
  padding: 12px 8px;
  outline-offset: -1px;
  cursor: pointer;
  background-color: ${Colors.GS0};

  ${({ active }) => active
    ? css `
          outline: 2px solid ${Colors.GS1000};
        `
    : css `
          &:focus {
            outline: 1px solid ${Colors.GS1000};
          }
        `}

  ${({ empty }) => empty &&
    css `
      cursor: default;
      background-color: ${Colors.GS100};
    `}

  ${({ disabled }) => disabled &&
    css `
      background: ${Colors.GS200};
      cursor: not-allowed;

      &:focus {
        outline: none;
      }
    `}

  ${mediaQueries.tablet} {
    padding: 16px;
  }
`;
export const ActiveTickContainer = styled.div `
  position: absolute;
  left: 3px;
  top: 3px;
  border-radius: 100%;
  padding: 4px;
  background-color: ${Colors.GS1000};
  color: ${Colors.GS0};

  ${mediaQueries.tablet} {
    left: 5px;
    top: 5px;
  }
`;
export const Input = styled.input `
  visibility: hidden;
  width: 0;
  height: 0;
  opacity: 0;
`;
