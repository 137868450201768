import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
