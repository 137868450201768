import React from 'react';
import { Dashboard, DASHBOARD_STYLED_CONSTS } from '@components/common';
import { Tabs, useMediaQueryContext } from '@fourthwall/components';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './styled';
export const SettingsTabs = ({ tabs, element }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const media = useMediaQueryContext();
    const direction = media.tablet ? 'vertical' : 'horizontal';
    const mobileTabProps = !media.tablet
        ? {
            variant: 'scrollable',
            size: 'medium',
            scrollIntoView: true,
            scrollIntoViewOffset: DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL,
        }
        : {};
    const defaultIndex = Math.max(tabs.findIndex(({ path }) => path === pathname), 0);
    const handleTabSelect = (index) => {
        navigate(tabs[index].path);
    };
    return (React.createElement(Tabs, { direction: direction, defaultIndex: defaultIndex, onSelect: handleTabSelect },
        React.createElement(Dashboard.Section.ScrollableContent, { hideScrollbar: true, fullWidth: true },
            React.createElement(S.ListWrapper, null,
                React.createElement(Tabs.List, { direction: direction }, tabs.map(({ name }) => (React.createElement(S.Tab, { key: name, name: name, direction: direction, size: "large", ...mobileTabProps })))))),
        React.createElement(S.Container, null, element)));
};
