export const STATUSES_LABELS = {
    CONFIRMED: 'Order placed',
    PARTIALLY_IN_PRODUCTION: 'Partially in production',
    IN_PRODUCTION: 'In production',
    PARTIALLY_SHIPPED: 'Partially shipped',
    SHIPPED: 'Shipped',
    PARTIALLY_DELIVERED: 'Partially delivered',
    DELIVERED: 'Delivered',
    COMPLETED: 'Completed',
    CANCELLED: 'Canceled',
};
