import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Colors } from '@fourthwall/components';
import { THEME_EDITOR_STYLED_CONSTS } from '../ThemeEditorLayout/consts';
const DRAGGABLE_HEIGHT = 36;
const TABS_HEIGHT = 45;
export const Container = styled(motion.div) `
  width: 100%;
  height: calc(100% - ${THEME_EDITOR_STYLED_CONSTS.NAVBAR_HEIGHT}px);
  position: fixed;
  top: ${THEME_EDITOR_STYLED_CONSTS.NAVBAR_HEIGHT}px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors.GS400};
  background-color: ${Colors.GS0};
  will-change: transform;

  // NOTE: to prevent screening when drag to the very top
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: ${THEME_EDITOR_STYLED_CONSTS.NAVBAR_HEIGHT}px;
    background-color: ${Colors.GS0};
    top: 100%;
  }
`;
export const Content = styled.div `
  flex: 1;
  overflow-y: auto;
  max-height: 100vh;

  ${({ centered, bottomSheetHeight }) => centered &&
    css `
      transition: max-height 0.3s ease-in-out;
      max-height: calc(${bottomSheetHeight}px + ${TABS_HEIGHT}px + ${DRAGGABLE_HEIGHT}px);
      );
    `}
`;
export const Draggable = styled.button.attrs({ type: 'button' }) `
  width: 100%;
  height: ${DRAGGABLE_HEIGHT}px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  // NOTE: touch-action: none is crucial for dragging to work
  touch-action: none;

  ${({ extendedHandle }) => extendedHandle &&
    css `
      &::after {
        display: block;
        content: '';

        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 100px;
        z-index: 1;
      }
    `};
`;
export const Handle = styled.div `
  position: absolute;
  top: 16px;
  height: 4px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  width: 80px;
  background: ${Colors.GS400};
`;
