import { useMutation } from '@tanstack/react-query';
import { ShopsApiClient } from '@fourthwall/services/lib/ShopsApiClient';
import { createMutationHook } from '@fourthwall/utils/lib/queries';
import { AgencyImagesApiClient } from '@fourthwall/services/lib/AgencyImagesApiClient';
export const useShopStatusMutation = () => {
    const shopStatusMutation = useMutation({
        mutationFn: async ({ shopId, payload }) => {
            const { data } = await ShopsApiClient.updateShopStatus(shopId, payload);
            return data;
        },
    });
    return { shopStatusMutation };
};
export const useUploadAgencyImageMutation = createMutationHook(AgencyImagesApiClient.uploadImage, {
    mutationKey: ['uploadAgencyImage'],
});
