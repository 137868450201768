import React from 'react';
import { routing } from '../../../../../utils/routing';
import { ConnectedSitesView } from './ConnectedSitesView';
import { useConnectedShopsQuery } from './hooks/useConnectedShopsQuery';
export const ConnectedSitesContainer = () => {
    const { connectedShopsQuery } = useConnectedShopsQuery();
    const handleItemManage = (item) => {
        window.location.href = `${item.internalBaseUri}${routing.home}`;
    };
    const items = connectedShopsQuery.data?.shops.map((item) => ({
        title: item.name,
        href: item.baseUri,
        image: item.logoUri,
        background: `${item.baseUri}/platform/theme/background.png`,
        onManage: () => handleItemManage(item),
    }));
    return React.createElement(ConnectedSitesView, { items: items });
};
