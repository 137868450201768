import styled, { css } from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const OpacityWrapper = styled.div ``;
export const ProductInfo = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const Container = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  ${mediaQueries.tablet} {
    gap: 20px;
    margin: -4px 0;
  }

  ${({ disabled }) => disabled &&
    css `
      ${OpacityWrapper} {
        opacity: 0.6;
      }
    `}
`;
export const Status = styled.div `
  display: flex;
`;
