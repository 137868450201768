export const getGiveawayBreakdownTagProps = (status) => {
    const props = {
        OPEN: { label: 'Open' },
        CONFIRMED: { label: 'Confirmed' },
        PARTIALLY_IN_PRODUCTION: { label: 'Partially in Production' },
        IN_PRODUCTION: { label: 'In Production' },
        PARTIALLY_SHIPPED: { label: 'Partially Shipped' },
        SHIPPED: { label: 'Shipped' },
        PARTIALLY_DELIVERED: { label: 'Partially Delivered' },
        DELIVERED: { label: 'Delivered', appearance: 'success' },
        COMPLETED: { label: 'Completed' },
        ABANDONED: { label: 'Abandoned' },
        CANCELLED: { label: 'Canceled' },
        FAILED: { label: 'Failed' },
        GIFT_PURCHASE: { label: 'Gift Purchase', appearance: 'success' },
    };
    return props[status];
};
