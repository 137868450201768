import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
export const Label = styled.div `
  padding: 4px 0;
  display: flex;
  align-items: center;
  font-size: inherit;
  color: ${getAutomatedStyleProp('colorOnBackground', Colors.GS1000)};
`;
export const Track = styled.div `
  height: 31px;
  width: 51px;
  padding: 2px;
  border-radius: 27px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  background: ${Colors.GS500};

  ${({ size }) => size === 'small' &&
    css `
      height: 20px;
      width: 32px;
      padding: 2px;
      border-radius: 16px;
    `}
`;
export const Handle = styled.div `
  height: 27px;
  width: 27px;
  border-radius: 100%;
  background: ${Colors.GS0};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

  ${({ size }) => size === 'small' &&
    css `
      height: 16px;
      width: 16px;
    `}
`;
export const Container = styled.label `
  display: flex;
  align-items: flex-start;
  gap: 16px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  ${({ size }) => size === 'small' &&
    css `
      gap: 9px;
    `}

  ${({ side }) => side === 'right' &&
    css `
      flex-direction: row-reverse;
    `}

  ${({ disabled }) => disabled &&
    css `
      cursor: not-allowed;
      opacity: 0.4;

      ${Label} {
        cursor: not-allowed;
      }

      ${Track} {
        cursor: not-allowed;
      }

      ${Handle} {
        box-shadow: none;
      }
    `}

  ${({ checked }) => checked &&
    css `
      ${Track} {
        justify-content: flex-end;
        background: ${Colors.B500};
      }
    `}
`;
export const Description = styled.div `
  margin-top: 4px;
  color: ${Colors.GS600};
  font-size: 14px;
  line-height: 150%;

  a {
    text-decoration: underline;

    &:hover {
      color: ${Colors.B500};
    }
  }
`;
