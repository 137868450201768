import { useQueryPage } from '@fourthwall/utils';
import isNil from 'lodash-es/isNil';
import { StringParam, useQueryParam, useQueryParams } from 'use-query-params';
export const useFilterMembers = () => {
    const { setPage } = useQueryPage();
    const [queryParams, setQueryParams] = useQueryParams({
        type: StringParam,
        tier: StringParam,
        billingCycle: StringParam,
        state: StringParam,
    });
    const [searchQueryParam, setSearchQueryParam] = useQueryParam('searchQuery', StringParam);
    const resetFiltersParams = () => {
        setPage(undefined);
        setQueryParams({
            type: undefined,
            tier: undefined,
            billingCycle: undefined,
            state: undefined,
        });
    };
    const setFiltersParams = (params) => {
        setPage(undefined);
        setQueryParams(params);
    };
    const hasFilterParams = Object.values(queryParams).some((param) => !isNil(param));
    return {
        filtersParams: queryParams,
        setFiltersParams,
        searchQueryParam,
        setSearchQueryParam,
        resetFiltersParams,
        hasFilterParams,
    };
};
