import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  position: absolute;
  top: -6px;
  right: -6px;

  min-width: 16px;

  padding: 0px 4px;
  border-radius: 25px;

  background-color: ${Colors.GS1000};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: ${Colors.GS0};
  font-weight: 600;
  text-align: center;
`;
