import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const Details = styled.div `
  font-size: 14px;
`;
