import { Fieldset, Subsection } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { TagSelect } from '../../../../components/TagSelect';
import { useTagsQuery } from '../../../../hooks/useTagsQuery';
import { FormFields } from '../../types';
export const TagsFields = ({ disabled }) => {
    const { tagsQuery } = useTagsQuery([{ limit: 9999 }]);
    const { values, setFieldValue } = useFormikContext();
    const tags = tagsQuery.data?.tags.map((tag) => tag.name);
    const handleTagAdd = (tag) => {
        setFieldValue(FormFields.tags, [...values.tags, tag]);
    };
    const handleTagRemove = (tag) => {
        setFieldValue(FormFields.tags, values.tags.filter((existingTag) => existingTag !== tag));
    };
    return (React.createElement(Subsection, { title: "Tags" },
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null, "Add tags that members can use to find posts they are interested in.")),
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(TagSelect, { selectedTags: values.tags, tags: tags || [], withAddButton: true, disabled: disabled, onAdd: handleTagAdd, onRemove: handleTagRemove }))))));
};
