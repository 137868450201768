import { AutoComplete as AutoCompleteField, useOutsideClick, withFormikSupport, } from '@fourthwall/components';
import React, { useRef, useState } from 'react';
import { Info } from '../../components/Info/Info';
export const AutoCompleteComponent = ({ data, options, value, name, icon, isLoading, invalid, disabled, error, append, onChange, onSearch, onBlur, onSettingUpdate, }) => {
    const { id, label, value: initialValue, info } = data;
    const [lastValue, setLastValue] = useState(initialValue);
    const [internalError, setInternalError] = useState('');
    const ref = useRef(null);
    useOutsideClick(ref, () => {
        if (value !== lastValue) {
            setInternalError('Please select a channel from search results.');
        }
    });
    const handleSelect = ({ id, value }) => {
        setInternalError('');
        if (lastValue === id) {
            return;
        }
        setLastValue(value || id);
        onSettingUpdate(id, id);
    };
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
        if (!value && lastValue !== '') {
            setInternalError('');
            setLastValue('');
            return onSettingUpdate(id, '');
        }
        if (value === lastValue) {
            return setInternalError('');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: ref },
            React.createElement(AutoCompleteField, { value: value, label: label, name: name, placeholder: label, invalid: invalid, disabled: disabled, leftIcon: icon, options: options, isLoading: isLoading, error: internalError || error, append: append, onSelect: handleSelect, onSearch: onSearch, onChange: onChange, onBlur: handleBlur })),
        React.createElement(Info, null, info)));
};
export const AutoComplete = withFormikSupport(AutoCompleteComponent);
