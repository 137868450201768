import styled, { css } from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  display: grid;
  border: 1px solid ${Colors.GS500};

  & > * + * {
    border-top: 1px solid ${Colors.GS500};
  }

  ${mediaQueries.tablet} {
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-auto-flow: column;

    & > * + * {
      border-top: 0px;
      border-left: 1px solid ${Colors.GS500};
    }

    & > :nth-child(n + 4) {
      border-top: 1px solid ${Colors.GS500};
    }
  }
`;
export const Column = styled.div `
  padding: 24px;
  cursor: pointer;

  ${({ $active }) => $active &&
    css `
      position: relative;
      z-index: 0;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        outline: 2px solid ${Colors.GS1000};
        outline-offset: -1px;
        pointer-events: none;
      }
    `}

  & > * + * {
    margin-top: 12px;
  }
`;
export const Heading = styled.h3 `
  font-size: 20px;
  font-weight: 600;
`;
export const Content = styled.article `
  font-size: 14px;
  line-height: 21px;

  ul {
    padding-left: 14px;
    list-style: disc;

    & > * + * {
      margin-top: 8px;
    }
  }
`;
export const Lead = styled.p `
  margin-bottom: 14px;
`;
