import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { Button, ConfirmModal, useMediaQueryContext, useModalContext } from '@fourthwall/components';
import { DiscordIcon } from '@fourthwall/icons';
import { MOBILE_APP_IOS_LINK } from '@fourthwall/utils/lib/links';
import { useShopStage } from '@hooks';
import { useCreatorIntegrationsQuery } from '@modules/Dashboard/Apps';
import { useVerifyDiscord } from '@modules/Dashboard/Apps/modules/AppsDiscordVerification';
import { CreateProductModal } from '@modules/Dashboard/Products';
import { CreateProductRecommendationContent } from '@modules/Dashboard/Products/modules/CreateProductRecommendationContent';
import { useSocialLinksQuery } from '@modules/ThemeEditor/modules/SocialLinks';
import { useDashboardStatisticsQuery } from '@queries/useDashboardStatisticsQuery';
import { useOffersQuery } from '@queries/useOffersQuery';
import { useUsersQuery } from '@queries/useUsersQuery';
import { routing } from '@utils/routing';
import { addUtmSourceParamToUrl } from '@utils/url';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useCurrentShopQuery } from '../../../hooks/useCurrentShopQuery';
import { useShopPayoutQuery } from '../../../hooks/useShopPayoutQuery';
import { usePromotionsQuery } from '../../../Promotions/modules/PromotionCodes';
// import setUpYourPerksAndTiers from './assets/set-up-your-perks-and-tiers.png'
import { SamplesRecommendationContentContainer, useSamplesOffers, } from '../../../Settings/modules/Samples';
import { useKudosOrdersQuery } from '../../../ThankYous';
import { HomeProductsContainer } from '../../modules/HomeProducts';
import { IntegrationsRecommendation } from '../../modules/IntegrationsRecommendation';
// import setUpWelcomeMessage from './assets/set-up-welcome-post.png'
import { useOnboardingSummaryQuery } from '../../queries/useOnboardingSummaryQuery';
import { useOnboarding } from '../useOnboarding';
import addSocialLinks from './assets/add-social-links.png';
import checkOutTheHelpCenter from './assets/check-out-the-help-center.png';
// import createANewPost from './assets/create-a-new-post.png'
import createANewProduct from './assets/create-a-new-product.png';
import downloadFourthwallsCreatorApp from './assets/download-fourthwalls-creator-app.png';
import inviteTeamMembers from './assets/invite-team-members.png';
import joinDiscord from './assets/join-discord.png';
import levelUpWithMemberships from './assets/level-up-with-memberships.png';
import offerADigitalProduct from './assets/offer-a-digital-product.png';
// import offerMembersOnlyProducts from './assets/offer-members-only-products.png'
// import offerMembersOnlyPromotions from './assets/offer-members-only-promotions.png'
import orderSamples from './assets/order-samples.png';
// import runAGiveaway from './assets/run-a-giveaway.png'
import sendAPersonalizedThankYouVideo from './assets/send-a-personalized-thank-you-video.png';
import setUpACustomDomain from './assets/set-up-a-custom-domain.png';
// import setUpMembersOnlyDiscordServer from './assets/set-up-members-only-discord-server.png'
import setUpPromotions from './assets/set-up-promotions.png';
import setUpTwitchGifting from './assets/set-up-twitch-gifting.png';
import setUpYourPayouts from './assets/set-up-your-payouts.png';
import { DownloadCreatorApp } from './components/DownloadCreatorApp';
import { useReferralRecommendation } from './hooks/useReferralRecommendation';
import { useTwitchPromotionRecommendation } from './hooks/useTwitchPromotionRecommendation';
import { useRecommendationsDismiss } from './useRecommendationsDismiss';
import { getRecommendationBadge } from './utils';
const staleTime = 60_000; // 1 minute
export const useRecommendations = () => {
    const media = useMediaQueryContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { onboarding } = useOnboarding();
    const { samplesOffers } = useSamplesOffers({ size: 5 });
    const { currentShopQuery } = useCurrentShopQuery(undefined, { staleTime });
    const { usersQuery } = useUsersQuery();
    const { dashboardStatisticsQuery } = useDashboardStatisticsQuery(undefined, { staleTime });
    const { shopPayoutQuery } = useShopPayoutQuery(undefined, {
        enabled: dashboardFeatureFlags.payoutSetup.enabled, // TODO: Move into dedicated hook.
    });
    const { promotionsQuery } = usePromotionsQuery(undefined, { staleTime });
    const { open } = useModalContext();
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery(undefined, { staleTime });
    const { offersQuery: digitalOffersQuery } = useOffersQuery([{ fulfillmentService: ['DIGITAL_ITEM'], size: 5 }], { staleTime });
    const { kudosOrdersQuery } = useKudosOrdersQuery([{ state: ['SENT'], size: 5 }], {
        staleTime,
    });
    const { socialLinksQuery } = useSocialLinksQuery([currentShopQuery.data?.id], {
        enabled: currentShopQuery.isSuccess && !!currentShopQuery.data.id,
        staleTime,
    });
    const { recommendationsDismiss } = useRecommendationsDismiss();
    const { shopStage } = useShopStage();
    const { referralConfigurationQuery, referralRecommendation } = useReferralRecommendation();
    const { twitchPromotionRecommendation } = useTwitchPromotionRecommendation();
    const { alreadyVerified, openVerificationLink } = useVerifyDiscord();
    const { onboardingSummaryQuery } = useOnboardingSummaryQuery();
    useEffect(() => {
        if (shopStage.currentStage) {
            TagManager.dataLayer({ dataLayer: { shopStage: shopStage.currentStage } });
        }
    }, [shopStage.currentStage]);
    const createProductItem = onboarding.onboardingProps?.onboardingListProps.items.find(({ key }) => key === 'CreateNewProduct');
    // It's ok to return 0 as long as there is no "up to X profit" logic needed for any recommendation.
    const profit = (() => {
        if (!dashboardStatisticsQuery.isSuccess)
            return 0;
        if (dashboardStatisticsQuery.data.status !== 'DASHBOARD_STATISTICS')
            return 0;
        return dashboardStatisticsQuery.data.profit.amount.value;
    })();
    const createOnDismiss = (onConfirm) => () => {
        open(ConfirmModal, {
            title: 'Are you sure you want to hide this recommendation?',
            text: 'This action cannot be reversed.',
            confirmLabel: 'Hide recommendation',
            onConfirm,
        });
    };
    const recommendations = (() => {
        const isLoading = [
            creatorIntegrationsQuery,
            currentShopQuery,
            dashboardStatisticsQuery,
            digitalOffersQuery,
            kudosOrdersQuery,
            shopPayoutQuery,
            promotionsQuery,
            referralConfigurationQuery,
            socialLinksQuery,
            usersQuery,
        ].some((query) => query.isLoading);
        if (isLoading)
            return;
        return [
            {
                enabled: dashboardFeatureFlags.homeProducts.enabled &&
                    onboardingSummaryQuery.isSuccess &&
                    onboardingSummaryQuery.data.recommendations.find((item) => item.action.type === 'CreateNewProduct')?.action.status === 'Completed',
                suggestionProps: { type: 'node', node: React.createElement(HomeProductsContainer, null) },
            },
            {
                // Move discord recommendation panel to top - should be the first recommendation panel
                enabled: !alreadyVerified && recommendationsDismiss.joinDiscord.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Join our Discord community',
                        description: 'Connect with fellow creators in the Fourthwall community',
                        badge: getRecommendationBadge(onboardingSummaryQuery.data?.recommendations.find((item) => item.action.type === 'VerifyDiscord')),
                        content: (React.createElement(Button, { fullWidth: !media.tablet, leftIcon: React.createElement(DiscordIcon, { height: 20, color: "#5865f2" }), label: "Verify and join Discord", analytics: "joinDiscord", onClick: openVerificationLink })),
                        imageUrl: joinDiscord,
                        onDismiss: createOnDismiss(recommendationsDismiss.joinDiscord.dismiss),
                    },
                },
            },
            {
                // 1st: Create new  product - display when in checklist create first product is made, before first product checklist item is not done do not display recommendation
                enabled: (() => {
                    if (dashboardFeatureFlags.homeProducts.enabled) {
                        return false;
                    }
                    if (['ONBOARDING_SHOP', 'ONBOARDING_SHOP_MEMBERSHIPS'].includes(onboarding.onboardingType) &&
                        !createProductItem?.completed) {
                        return false;
                    }
                    return recommendationsDismiss.createProduct.visible;
                })(),
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Create a new product',
                        description: 'Design a new product or list something you own',
                        badge: getRecommendationBadge(onboardingSummaryQuery.data?.recommendations.find((item) => item.action.type === 'CreateNewProduct')),
                        imageUrl: createANewProduct,
                        analytics: 'createANewProduct',
                        content: React.createElement(CreateProductRecommendationContent, null),
                        onDismiss: createOnDismiss(recommendationsDismiss.createProduct.dismiss),
                    },
                },
            },
            {
                enabled: true,
                suggestionProps: {
                    type: 'node',
                    node: React.createElement(IntegrationsRecommendation, null),
                },
            },
            {
                // 2nd: Order samples (shows up ONLY when at least 1 product is created, but then is visible all the time)
                enabled: !!samplesOffers.enabled && recommendationsDismiss.orderSamples.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Order samples',
                        description: 'Order samples of your products for quality checks and promotion',
                        imageUrl: orderSamples,
                        content: React.createElement(SamplesRecommendationContentContainer, null),
                        analytics: 'orderSamples',
                        onDismiss: createOnDismiss(recommendationsDismiss.orderSamples.dismiss),
                    },
                },
            },
            referralRecommendation,
            twitchPromotionRecommendation,
            {
                // 4th (NEW here!): Setup your payouts (shows up ONLY after creator made any profit, meaning profit higher than $0, hides after payouts are set)
                enabled: profit > 0 &&
                    shopPayoutQuery.isSuccess &&
                    shopPayoutQuery.data.status === 'INACTIVE' &&
                    recommendationsDismiss.payouts.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Set up your payouts',
                        description: "We'll automatically deposit your monthly earnings into your account",
                        buttonLabel: 'Set up payouts',
                        buttonTo: routing.settings.billing.self,
                        imageUrl: setUpYourPayouts,
                        analytics: 'setupYourPayouts',
                        onDismiss: createOnDismiss(recommendationsDismiss.payouts.dismiss),
                    },
                },
            },
            {
                // 5th: Set up a custom domain (hides after custom domain is connected)
                enabled: currentShopQuery.isSuccess &&
                    currentShopQuery.data.primaryDomain.endsWith('fourthwall.com') &&
                    recommendationsDismiss.domain.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Set up a custom domain',
                        description: 'Give your site a custom domain (ex: creatorname.com)',
                        buttonLabel: 'Connect a new domain',
                        buttonTo: routing.settings.domain.self,
                        imageUrl: setUpACustomDomain,
                        analytics: 'setupACustomDomain',
                        onDismiss: createOnDismiss(recommendationsDismiss.domain.dismiss),
                    },
                },
            },
            {
                // 6th: Invite team members (hides after at least 1 team member is invited)
                enabled: usersQuery.isSuccess &&
                    usersQuery.data.users &&
                    usersQuery.data.users.length === 1 &&
                    recommendationsDismiss.invite.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Invite team members',
                        description: 'Add additional team members to help you manage your account',
                        buttonLabel: 'Invite team members',
                        buttonTo: routing.settings.team,
                        imageUrl: inviteTeamMembers,
                        analytics: 'inviteTeamMembers',
                        onDismiss: createOnDismiss(recommendationsDismiss.invite.dismiss),
                    },
                },
            },
            // Stage 2: Post activation stage
            {
                enabled: shopStage.stage2 &&
                    promotionsQuery.isSuccess &&
                    promotionsQuery.data.page.elementsTotal === 0 &&
                    recommendationsDismiss.promotions.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Set up promotions',
                        description: 'Create promo codes, giveaway links, or auto-applied offers',
                        buttonLabel: 'Set up promo codes',
                        buttonTo: routing.promotions.self,
                        imageUrl: setUpPromotions,
                        analytics: 'setUpPromotions',
                        onDismiss: createOnDismiss(recommendationsDismiss.promotions.dismiss),
                    },
                },
            },
            {
                enabled: shopStage.stage2 &&
                    socialLinksQuery.isSuccess &&
                    !!socialLinksQuery.data.socialNetworks.length &&
                    recommendationsDismiss.social.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Add social links',
                        description: 'Feature Instagram, Twitch, TikTok, and YouTube on your page',
                        buttonLabel: 'Add social links',
                        buttonTo: routing.themeEditor.general.socialLinks,
                        imageUrl: addSocialLinks,
                        analytics: 'addSocialLinks',
                        onDismiss: createOnDismiss(recommendationsDismiss.social.dismiss),
                    },
                },
            },
            {
                enabled: shopStage.stage2 && recommendationsDismiss.creatorApp.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: "Download Fourthwall's creator app",
                        description: 'Manage your account on the go and send thank you videos to supporters',
                        content: React.createElement(DownloadCreatorApp, { analytics: "downloadCreatorApp" }),
                        imageUrl: downloadFourthwallsCreatorApp,
                        onDismiss: createOnDismiss(recommendationsDismiss.creatorApp.dismiss),
                    },
                },
            },
            {
                enabled: shopStage.stage2 && recommendationsDismiss.helpCenter.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Check out the Help Center',
                        description: 'Access helpful tips and resources',
                        buttonLabel: 'Visit help center',
                        buttonHref: addUtmSourceParamToUrl(routing.external.helpCenter.self, 'onboarding'),
                        buttonTarget: '_blank',
                        imageUrl: checkOutTheHelpCenter,
                        analytics: 'visitHelpCenter',
                        onDismiss: createOnDismiss(recommendationsDismiss.helpCenter.dismiss),
                    },
                },
            },
            // Stage 3: Post profit $100
            // {
            //   // "LOGIC: disappears after first giveaway link is created"
            //   enabled: shopStage.stage3,
            //   suggestionProps: {
            //     title: 'Run a giveaway',
            //     description: 'Let supporters easily redeem a', // TODO
            //     buttonLabel: 'Create a giveaway link',
            //     buttonTo: routing.promotions.self,
            //     imageUrl: runAGiveaway,
            //   },
            // },
            {
                // "LOGIC: only visible to Twitch creators; disappears after Twitch App is enabled (Twitch creator = if in social there is Twitch set up assume this is Twitch creator)"
                enabled: shopStage.stage3 &&
                    socialLinksQuery.isSuccess &&
                    socialLinksQuery.data.socialNetworks.some(({ name }) => name === 'twitch') &&
                    creatorIntegrationsQuery.isSuccess &&
                    creatorIntegrationsQuery.data.twitch.status !== 'CONNECTED' &&
                    recommendationsDismiss.twitchGifting.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Set up Twitch gifting',
                        description: 'Allow viewers to gift products and memberships to chat while you’re livestreaming',
                        buttonLabel: 'Set up Twitch gifting',
                        buttonTo: routing.apps.twitch,
                        imageUrl: setUpTwitchGifting,
                        analytics: 'setUpTwitchGifting',
                        onDismiss: createOnDismiss(recommendationsDismiss.twitchGifting.dismiss),
                    },
                },
            },
            {
                // "LOGIC: disappears after first video sent - ok"
                enabled: shopStage.stage3 &&
                    kudosOrdersQuery.isSuccess &&
                    kudosOrdersQuery.data.page.elementsTotal === 0 &&
                    recommendationsDismiss.thankYou.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Send a personalized thank you video',
                        description: 'Easily record and share a video to show your appreciation to supporters',
                        buttonLabel: 'Send a thank you',
                        buttonHref: MOBILE_APP_IOS_LINK,
                        buttonTarget: '_blank',
                        imageUrl: sendAPersonalizedThankYouVideo,
                        analytics: 'sendThankYou',
                        onDismiss: createOnDismiss(recommendationsDismiss.thankYou.dismiss),
                    },
                },
            },
            {
                // "LOGIC: Disappears after 1st digital product"
                enabled: shopStage.stage4 &&
                    digitalOffersQuery.isSuccess &&
                    digitalOffersQuery.data.page.elementsTotal === 0 &&
                    recommendationsDismiss.digitalProduct.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Offer a digital product',
                        description: 'Boost traffic, build an email list, and diversify your shop with digital products',
                        buttonLabel: 'Offer a digital product',
                        imageUrl: offerADigitalProduct,
                        analytics: 'offerDigitalProduct',
                        onButtonClick: () => open(CreateProductModal),
                        onDismiss: createOnDismiss(recommendationsDismiss.digitalProduct.dismiss),
                    },
                },
            },
            {
                // LOGIC: disappears after Memberships are enabled
                enabled: shopStage.stage4 &&
                    !dashboardFeatureFlags.memberships.enabled &&
                    recommendationsDismiss.memberships.visible,
                suggestionProps: {
                    type: 'props',
                    props: {
                        title: 'Level up with Memberships',
                        description: 'Offer members-only perks, rewards, and more',
                        buttonLabel: 'Launch memberships',
                        buttonTo: routing.membershipsBeta.self,
                        imageUrl: levelUpWithMemberships,
                        analytics: 'launchMemberships',
                        onDismiss: createOnDismiss(recommendationsDismiss.memberships.dismiss),
                    },
                },
            },
            // {
            //   enabled: true,
            //   suggestionProps: {
            //     title: 'Create a new post',
            //     description: 'Add more content to engage with your members',
            //     imageUrl: createANewPost,
            //     analytics: 'createANewPost',
            //   },
            // },
        ];
    })();
    const enabledRecommendations = (() => {
        if (!recommendations)
            return;
        return recommendations
            .filter(({ enabled }) => enabled)
            .map(({ suggestionProps }) => suggestionProps);
    })();
    return { recommendations: enabledRecommendations };
};
