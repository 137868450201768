import { ApiBase } from './ApiBase';
class MetaPartnerApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getMetaConfiguration = () => {
            return this.get('/creator-integrations/meta-partner/configuration');
        };
        this.deleteMetaConfiguration = () => {
            return this.delete('/creator-integrations/meta-partner/configuration');
        };
        this.getMetaCommerces = () => {
            return this.get('/creator-integrations/meta-partner/configuration/commerces');
        };
        this.updateMetaCommerce = (pathParams) => {
            return this.put(`/creator-integrations/meta-partner/configuration/select-commerce/${pathParams.metaCMSId}`);
        };
        this.updateMetaStatus = (payload) => {
            return this.put('/creator-integrations/meta-partner/configuration/status', payload);
        };
    }
}
export const MetaPartnerApiClient = new MetaPartnerApiClientModel();
