import { Dashboard, PageLayout } from '@components/common';
import { NavTabs } from '@components/common/NavTabs';
import { BaseContent, ButtonClean, EmptyState, HeadContent, Loading, Pagination, } from '@fourthwall/components';
import { ThankYousBarContainer } from '@modules/Dashboard/ThankYous';
import { ThankYousData } from '@modules/Dashboard/ThankYous/components/ThankYousData';
import { routing } from '@utils/routing';
import React from 'react';
import { NoThankYousContent } from './components/NoThankYousContent';
import * as S from './styled';
const emptyStateMessages = {
    WAITING: {
        title: "You're all caught up! 🤗",
        subtitle: 'Thanks for showing your fans the love they deserve.',
    },
    SKIPPED: {
        title: "You don't have any skipped thank yous 😇",
    },
    SENT: {
        title: "You haven't sent any thank yous yet,",
    },
};
export const ThankYousView = ({ tabs, activeTab, data, isLoading, noThankYous, currentPage, totalPages, onTabClick, onCurrentPageChange, onRowClick, onBarModalSuccess, }) => {
    const renderData = (emptyState) => (React.createElement(Dashboard.Section.Item, null,
        React.createElement(ThankYousData, { data: data, isLoading: isLoading, activeTab: activeTab, emptyComponent: emptyState ? (React.createElement(EmptyState, { title: emptyStateMessages[activeTab].title, subtitle: emptyStateMessages[activeTab].subtitle })) : undefined, onRowClick: onRowClick })));
    const renderThankYousContent = () => (React.createElement(React.Fragment, null,
        React.createElement(Dashboard.Section.Item, null,
            React.createElement(NavTabs, { tabs: tabs, activeTab: activeTab, onTabClick: onTabClick })),
        renderData(true),
        !!totalPages && (React.createElement(Dashboard.Section.Item, null,
            React.createElement(Pagination, { currentPage: currentPage, pageCount: totalPages, onChange: onCurrentPageChange })))));
    const renderNoThankYousContent = () => (React.createElement(React.Fragment, null,
        renderData(false),
        React.createElement(Loading, { isLoading: isLoading, variant: "solid" }, !isLoading && React.createElement(NoThankYousContent, null))));
    const renderBottomContent = () => {
        if (noThankYous)
            return null;
        return (React.createElement(S.BottomContentContainer, null,
            React.createElement(BaseContent, null,
                "Thank your top supporters with a quick video, audio, or picture message! Thank yous take less than 15 seconds to send and recipients are 5x more likely to place another order.",
                ' ',
                React.createElement(ButtonClean, { label: "Learn more", href: routing.external.helpCenter.thankYous, target: "_blank" })),
            React.createElement(ThankYousBarContainer, { type: "MODAL", onModalSuccess: onBarModalSuccess })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Thank yous" }),
        React.createElement(PageLayout, { size: "large" },
            React.createElement(Dashboard.Section, { name: "Thank yous", bottomContent: renderBottomContent(), bottomMargin: "medium" }, noThankYous ? renderNoThankYousContent() : renderThankYousContent()))));
};
