import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  display: grid;
  gap: 16px;

  ${mediaQueries.tablet} {
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }
`;
export const Lead = styled.p `
  margin-bottom: 14px;
`;
