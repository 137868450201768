import { useState } from 'react';
import { useCurrentShopQuery } from '../../../../hooks/useCurrentShopQuery';
import { useDnsQuery } from '../../../../hooks/useDnsQuery';
import { DNS_REFETCH_INTERVAL } from '../../../constants';
import { useEntri } from '../../../hooks/useEntri';
export const useCheckDomain = (options) => {
    const { currentShopQuery } = useCurrentShopQuery();
    const { connectDomain } = useEntri();
    const [isLoading, setIsLoading] = useState(false);
    const [isQueryEnabled, setIsQueryEnabled] = useState(false);
    useDnsQuery([], {
        refetchInterval: DNS_REFETCH_INTERVAL,
        enabled: isQueryEnabled,
        onSuccess: (dns) => {
            if (!dns || !dns.dnsList || !currentShopQuery.data)
                return;
            const isEveryRecordValueLoaded = dns.dnsList.list.every((record) => record.value != null);
            if (!isEveryRecordValueLoaded)
                return;
            setIsQueryEnabled(false);
            connectDomain(dns.dnsList, options?.onConnect);
        },
    });
    return {
        isLoading,
        setIsLoading,
        setIsQueryEnabled,
    };
};
