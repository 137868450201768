import styled from 'styled-components';
import { ScrollContent } from '@components/common/ScrollContent';
export const ShippingGroups = styled.div `
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 4px;
`;
export const ScrollContainer = styled(ScrollContent) `
  padding-right: 20px;
  margin-right: -20px;
`;
