import { Colors, mediaQueries } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
`;
export const ImagesContainer = styled.div `
  position: relative;
  overflow: hidden;
`;
export const Loader = styled.div `
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.1s ease-in;
`;
export const Image = styled.img `
  object-fit: cover;
  object-position: top;
  opacity: 1;
  transition: opacity 0.1s ease-in;
`;
export const DesktopImageWrapper = styled.div `
  position: relative;
  width: 86%;
  aspect-ratio: 1.33;
  border: 1px solid rgba(128, 128, 128, 0.15);

  ${Loader}, ${Image} {
    width: 100%;
    height: 100%;
  }
`;
export const MobileImageWrapper = styled.div `
  position: absolute;
  right: 1px;
  bottom: 1px;
  width: 30%;
  aspect-ratio: 0.53;
  outline: 1px solid rgba(128, 128, 128, 0.15);
  background: ${Colors.GS100};
  box-shadow: -80px 4px 80px -40px rgba(0, 0, 0, 0.12);

  ${Loader}, ${Image} {
    width: 100%;
    height: 100%;
  }
`;
const visibleActionsStyles = css `
  opacity: 1;

  button {
    pointer-events: initial;
  }
`;
export const SelectContainer = styled.div `
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: rgba(255, 255, 255, 0.92);
  transition: opacity 0.2s ease-in;

  button {
    pointer-events: none;
  }

  ${({ display }) => display && visibleActionsStyles};

  ${mediaQueries.laptop} {
    &:hover {
      ${visibleActionsStyles};
    }
  }
`;
export const Footer = styled.div `
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
export const Details = styled.div `
  display: flex;
  justify-content: space-between;
`;
export const Tags = styled.div `
  display: flex;
  gap: 8px;

  ${mediaQueries.laptop} {
    min-height: 24px;
  }
`;
export const Name = styled.p `
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;
