import { useState } from 'react';
import { useDashboardStorage } from '../../../../../hooks/useDashboardStorage';
const KEYS = [
    'createProduct',
    'orderSamples',
    'integrations',
    'payouts',
    'domain',
    'invite',
    'promotions',
    'social',
    'creatorApp',
    'helpCenter',
    'twitchGifting',
    'twitchPromotion',
    'youTubeProductShelf',
    'thankYou',
    'digitalProduct',
    'memberships',
    'joinDiscord',
];
export const useRecommendationsDismiss = () => {
    const { dashboardStorage } = useDashboardStorage();
    const initialVisibility = KEYS.reduce((acc, key) => ({
        ...acc,
        [key]: !dashboardStorage.get(`recommendationsDismiss.${key}`),
    }), {});
    const [visibility, setVisibility] = useState(initialVisibility);
    const dismiss = (key) => {
        setVisibility((prev) => ({ ...prev, [key]: false }));
        dashboardStorage.set(`recommendationsDismiss.${key}`, true);
    };
    const recommendationsDismiss = KEYS.reduce((acc, key) => ({
        ...acc,
        [key]: { visible: visibility[key], dismiss: () => dismiss(key) },
    }), {});
    return { recommendationsDismiss };
};
