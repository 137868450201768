import isInteger from 'lodash-es/isInteger';
import { DIGITAL_COST_PERCENTAGE, SINGLE_UNIT_PRODUCT_ORDER_SHIPPING_FEES, SINGLE_UNIT_PRODUCT_ORDER_TAXES_FEES, SINGLE_UNIT_PRODUCT_PAYMENT_PROCESSING_FEE_PERCENT, SINGLE_UNIT_PRODUCT_PAYMENT_PROCESSING_FEE_VALUE, } from '@fourthwall/utils/lib/constants';
import { MAX_SOLD_QUANTITY_VALUE } from './consts';
export const getSuggestions = (offerType, sizePriceSuggestion) => {
    const suggestions = [];
    if (offerType === 'ON_DEMAND') {
        suggestions.push('On-demand products have a single manufacturing price, regardless of how many are ordered.');
    }
    if (offerType === 'MOQ') {
        suggestions.push('Profit for screen printed products is determined by the number of items sold. The more you sell, the more you make per item.');
    }
    if (sizePriceSuggestion) {
        suggestions.push(sizePriceSuggestion);
    }
    return suggestions;
};
export const getEstimatedPaymentProcessingFees = (price) => {
    const feeValue = (price + SINGLE_UNIT_PRODUCT_ORDER_TAXES_FEES + SINGLE_UNIT_PRODUCT_ORDER_SHIPPING_FEES) *
        (SINGLE_UNIT_PRODUCT_PAYMENT_PROCESSING_FEE_PERCENT / 100) +
        SINGLE_UNIT_PRODUCT_PAYMENT_PROCESSING_FEE_VALUE;
    return Number(feeValue.toFixed(2));
};
export const getUnitProfit = (price, cost, includeFees) => {
    const profit = price - (cost || 0) - (includeFees ? getEstimatedPaymentProcessingFees(price) : 0);
    return Number(profit.toFixed(2));
};
export const getTotalProfit = (unitProfit, quantity) => Number((unitProfit * quantity).toFixed(2));
export const getAutoAdjustedSellingPrice = (profit, cost) => profit + (cost || 0);
export const getSizeTabLabel = (sizes) => sizes.length > 1 ? `${sizes[0]} to ${sizes[sizes.length - 1]}` : sizes[0];
export const getMOQPricingData = (firstItemOnly, includeFees, priceValue, sizesPerCost, pricing) => {
    const data = firstItemOnly ? [sizesPerCost[0]] : sizesPerCost;
    return (data.map(({ sizes }) => {
        const label = sizes ? getSizeTabLabel(sizes) : '';
        const quantityPricing = pricing?.map((quantityPricingItem) => {
            const cost = quantityPricingItem.perSize.filter((perSizeItem) => perSizeItem.size === sizes[0])[0]?.cost.value;
            const price = getAutoAdjustedSellingPrice(getUnitProfit(priceValue, quantityPricingItem.perSize[0]?.cost.value || 0), cost);
            const unitProfit = getUnitProfit(price, cost, includeFees);
            const totalProfit = getTotalProfit(unitProfit, quantityPricingItem.numberOfUnits);
            const feesValue = getEstimatedPaymentProcessingFees(price);
            return {
                numberOfUnits: quantityPricingItem.numberOfUnits,
                price,
                cost,
                unitProfit,
                totalProfit,
                feesValue,
            };
        });
        return { label, quantityPricing };
    }) || []);
};
export const getOnDemandPricingData = (firstItemOnly, includeFees, priceValue, sizesPerCost) => {
    const data = firstItemOnly ? [sizesPerCost[0]] : sizesPerCost;
    return (data.map(({ cost, sizes }, index) => {
        const label = getSizeTabLabel(sizes);
        const price = index === 0
            ? priceValue
            : getAutoAdjustedSellingPrice(getUnitProfit(priceValue, sizesPerCost[0].cost), cost);
        const unitProfit = getUnitProfit(price, cost, includeFees);
        const feesValue = getEstimatedPaymentProcessingFees(price);
        return { cost, label, price, unitProfit, feesValue };
    }) || []);
};
export const getDigitalCost = (price) => price * (DIGITAL_COST_PERCENTAGE / 100);
export const isSoldValueValid = (value, minimumOrderQuantity) => value >= minimumOrderQuantity && value < MAX_SOLD_QUANTITY_VALUE && isInteger(Number(value));
export const getSoldValueError = (value, minimumOrderQuantity) => {
    if (value.length && !isInteger(Number(value))) {
        return 'The value is wrong.';
    }
    if (!!minimumOrderQuantity && parseInt(value) < minimumOrderQuantity) {
        return `Minimum ${minimumOrderQuantity} required.`;
    }
    if (parseInt(value) > MAX_SOLD_QUANTITY_VALUE) {
        return `The value is too large.`;
    }
};
