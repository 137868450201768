import { Offer } from '@fourthwall/services/lib/models/offer';
export const filterOffersWithVariants = (offers) => offers
    .map((offer) => ({
    ...offer,
    variants: offer.variants.filter((variant) => variant.status !== 'UNAVAILABLE'),
}))
    .filter((offer) => offer.variants.length > 0);
export const getSamplesOffersQueryParams = ({ size = 1_000, query, includeFulfillmentService = true, }) => ({
    size,
    status: Offer.OFFER_STATUSES_WITH_SAMPLES,
    fulfillmentService: includeFulfillmentService
        ? Offer.FULFILLING_SERVICES_WITH_SAMPLES
        : undefined,
    q: !!query?.length && query.length > 2 ? query : undefined,
});
