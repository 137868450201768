import React from 'react';
import { useParams, useLocation, Navigate } from 'react-router-dom';
const createTo = (to, params, search) => {
    const pathname = typeof to === 'string' ? to : to.pathname || '';
    const keys = Object.keys(params);
    return {
        pathname: keys.reduce((previous, key) => previous.replace(`:${key}`, params[key] || ''), pathname),
        search,
    };
};
export const Redirect = ({ to }) => {
    const params = useParams();
    const location = useLocation();
    return React.createElement(Navigate, { to: createTo(to, params, location.search), replace: true });
};
