import { BackButton, ButtonClean } from '@fourthwall/components';
import React from 'react';
import { Dashboard } from '../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../components/common/PageLayout';
import { routing } from '../../../../../utils/routing';
import { DomainForm } from './components/DomainForm';
import * as S from './NewDomainView.styled';
export const NewDomainView = ({ isLoading, onSubmit }) => (React.createElement(PageLayout, { size: "small" },
    React.createElement(Dashboard.Section, { name: "Connect a domain to your site", title: React.createElement(S.DescriptionContainer, null,
            "We\u2019re excited to help you set up your custom domain! To connect a custom domain, you need to own the domain you want to connect. If you haven\u2019t purchased a domain yet we recommend using",
            ' ',
            React.createElement(ButtonClean, { appearance: "primary", label: "Porkbun", href: "https://porkbun.com/", target: "_blank" }),
            ". If you have purchased a domain, enter it below to connect it to your site.",
            ' ',
            React.createElement(ButtonClean, { label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/13331283794587-How-do-I-use-a-custom-domain", target: "_blank" })), titleSpacing: "medium", topContent: React.createElement(BackButton, { label: "Domains", to: routing.settings.domain.self }) },
        React.createElement(DomainForm, { isLoading: isLoading, onSubmit: onSubmit }))));
