import { useState } from 'react';
import { useImageConfirmMutation } from '../../../hooks/useImageConfirmMutation';
import { useUploadImageMutation } from '../../../hooks/useUploadImageMutation';
import { useCreateVideoEmbedPostMutation } from './useCreateVideoEmbedPostMutation';
export const useCreateVideoEmbedPost = () => {
    const [isLoading, setLoading] = useState(false);
    const { createVideoEmbedPostMutation } = useCreateVideoEmbedPostMutation();
    const { uploadImageMutation } = useUploadImageMutation();
    const { imageConfirmMutation } = useImageConfirmMutation();
    const createVideoEmbedPost = async (thumbnail, payload, handleSuccess) => {
        setLoading(true);
        const { postId, imageDirectUploadData } = await createVideoEmbedPostMutation.mutateAsync(payload, {
            onSuccess: ({ imageDirectUploadData }) => {
                if (!imageDirectUploadData) {
                    setLoading(false);
                    handleSuccess();
                }
            },
            onError: () => setLoading(false),
        });
        if (imageDirectUploadData) {
            await uploadImageMutation.mutateAsync({ file: thumbnail, ...imageDirectUploadData }, {
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: create video embed post upload image error');
                },
            });
            await imageConfirmMutation.mutateAsync({ id: postId, signedId: imageDirectUploadData.signedId }, {
                onSuccess: () => {
                    setLoading(false);
                    handleSuccess();
                },
                onError: () => {
                    setLoading(false);
                    throw new Error('Memberships: create video embed post confirm image error');
                },
            });
        }
    };
    return {
        createVideoEmbedPost,
        isLoading,
    };
};
