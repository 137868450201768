import React from 'react';
import { Button, Icon, Input } from '@fourthwall/components';
import { TickIcon } from '@fourthwall/icons';
import { useCopyToClipboard } from '@components/hooks/useCopyToClipboard';
import * as S from './styled';
export const CopyLinkInput = ({ analytics, href }) => {
    const { isCopied, copyToClipboard } = useCopyToClipboard();
    return (React.createElement(S.Container, null,
        React.createElement(S.InputWrapper, null,
            React.createElement(Input, { readOnly: true, size: "medium", value: href, fullWidth: true })),
        React.createElement(S.ButtonWrapper, null,
            React.createElement(Button, { fullWidth: true, label: isCopied ? 'Copied' : 'Copy Link', leftIcon: isCopied ? React.createElement(Icon, { component: TickIcon, height: 16 }) : undefined, size: "medium", analytics: analytics, disabled: isCopied, onClick: () => copyToClipboard(href) }))));
};
