import { Button, ButtonGroup, ConfirmModal, Heading, Icon, Tag, Tooltip, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import { QuestionMarkIcon } from '@fourthwall/icons';
import React from 'react';
import { routing } from '@utils/routing';
import * as S from './ExistingDomainTile.styled';
import { tagPropsMap } from './consts';
export const ExistingDomainTile = ({ domainName, status, currentShopDomain, shopInternalDomain, isDomainRemovalRequested, isRetryConnectionInProgress, onDomainDelete, onRetryConnection, }) => {
    const media = useMediaQueryContext();
    const { open } = useModalContext();
    const openDomainRemoveModal = () => {
        open(ConfirmModal, {
            title: 'Are you sure you want to delete this domain?',
            text: (React.createElement(S.RemoveDomainTextContainer, null,
                "You'll still be able to access ",
                shopInternalDomain,
                ".fourthwall.com")),
            confirmLabel: 'Delete',
            confirmAppearance: 'destructive',
            onConfirm: onDomainDelete,
        });
    };
    const renderStatusTag = () => {
        const tagProps = tagPropsMap[status];
        if (status === 'REMOVAL_IN_PROGRESS') {
            return (React.createElement(S.TagWithTooltip, null,
                React.createElement(Tag, { ...tagProps }),
                React.createElement(Tooltip, { reference: React.createElement(Icon, { component: QuestionMarkIcon, height: 18.75 }), placement: "top", fluid: true }, "This process usually takes a couple of seconds")));
        }
        return React.createElement(Tag, { ...tagProps });
    };
    const renderDescription = () => {
        if (status === 'REMOVAL_IN_PROGRESS') {
            return `For testing, your site will also always be available at ${shopInternalDomain}.fourthwall.com.`;
        }
        if (status === 'SYNCING_YOUR_DOMAIN') {
            return `Your site is processing. It will be ready soon at ${currentShopDomain}.`;
        }
        if (status === 'PARTIALLY_CONNECTED' || status === 'CONNECTED') {
            return `Your site is currently accessible at ${currentShopDomain}.`;
        }
        return `This domain is not yet connected. Your site is currently accessible at ${shopInternalDomain}.fourthwall.com.`;
    };
    const renderButtons = () => {
        if (status === 'REMOVAL_IN_PROGRESS') {
            return null;
        }
        const buttonSize = media.tablet ? 'small' : 'medium';
        return (React.createElement(ButtonGroup, { space: "large", direction: media.tablet ? 'row' : 'column', fullWidth: true },
            onRetryConnection && (React.createElement(Button, { appearance: "primary", label: "Retry connection", size: buttonSize, fullWidth: true, loading: isRetryConnectionInProgress, onClick: onRetryConnection })),
            React.createElement(Button, { label: "View DNS records", size: buttonSize, fullWidth: true, disabled: isRetryConnectionInProgress, to: routing.settings.domain.configuration }),
            React.createElement(Button, { label: "Remove domain", size: buttonSize, loading: isDomainRemovalRequested, fullWidth: true, disabled: isRetryConnectionInProgress, onClick: openDomainRemoveModal })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(S.HeadingContainer, null,
            React.createElement(S.Heading, null,
                React.createElement(Heading, { type: "h7", weight: "semibold" }, domainName)),
            renderStatusTag()),
        React.createElement(S.Description, null, renderDescription()),
        renderButtons()));
};
