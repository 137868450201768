import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Content = styled.div `
  display: flex;
  align-items: center;
  flex-direction: column;

  min-width: 300px;
  padding: 8px 16px;

  line-height: 150%;
  text-align: center;
`;
export const AvatarContainer = styled.div `
  margin-bottom: 8px;
`;
export const UserName = styled.div `
  overflow-wrap: anywhere;

  font-size: 16px;
  color: ${Colors.GS1000};
`;
export const UserEmail = styled.div `
  overflow: hidden;
  text-decoration: none;

  width: 100%;

  text-overflow: ellipsis;

  font-size: 14px;
  color: ${Colors.GS600};
`;
