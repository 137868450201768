import { AddButton, Banner, Button, Icon, Loader, ModalBox } from '@fourthwall/components';
import { SecureBadgeIcon } from '@fourthwall/icons';
import React from 'react';
import { PaymentMethodsList } from '../../components/PaymentMethodsList';
import * as S from './styled';
export const PaymentMethodRequiredModalView = ({ title, subtitle, submitLabel, errorBanner, paymentMethodsProps, isSubmitting, isDisabled, isLoading, onAddPaymentMethod, onSubmit, onClose, }) => {
    const renderContent = () => {
        if (isLoading) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            subtitle && React.createElement(S.Subtitle, null, subtitle),
            errorBanner && (React.createElement(S.ErrorBannerContainer, null,
                React.createElement(Banner, { appearance: "danger" }, errorBanner))),
            paymentMethodsProps && (React.createElement(S.PaymentMethodsContainer, null,
                React.createElement(PaymentMethodsList, { paymentMethods: paymentMethodsProps }))),
            React.createElement(AddButton, { label: "Add new payment method", onClick: onAddPaymentMethod })));
    };
    return (React.createElement(ModalBox, { title: title, buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: onClose }),
            React.createElement(Button, { label: submitLabel, appearance: "primary", loading: isSubmitting, disabled: isDisabled, onClick: onSubmit }),
        ], onClose: onClose },
        isLoading && (React.createElement(S.LoaderWrapper, null,
            React.createElement(Loader, { size: "large" }))),
        renderContent(),
        React.createElement(S.Footer, null,
            React.createElement(Icon, { component: SecureBadgeIcon, height: 20 }))));
};
