import { ButtonClean, Icon } from '@fourthwall/components';
import { YoutubeIcon } from '@fourthwall/icons';
import React from 'react';
import { checklistDataLayer } from '../../../../../utils/gtm/checklistDataLayer';
import { routing } from '../../../../../utils/routing';
import { CreateProductModal } from '../../../Products';
import connectYoutubeProductShelfImage from './assets/connect-youtube-product-shelf.png';
import customizeThemeImage from './assets/customize-theme.png';
import { HomeProductsSliderContainer } from '../../modules/HomeProducts';
export const sortChecklistItems = (a, b) => {
    const ORDER = ([
        'ConnectYoutubeProductShelf',
        'CreateNewProduct',
        'CustomiseWebsiteLook',
    ]).reverse();
    return ORDER.indexOf(b.action.type) - ORDER.indexOf(a.action.type);
};
export const getShopChecklistItemsConfig = ({ openModal, skipItem, }) => ({
    ConnectYoutubeProductShelf: {
        title: 'Set up YouTube Product Shelf',
        description: (React.createElement(React.Fragment, null,
            "Feature products from your shop on your YouTube channels.",
            ' ',
            React.createElement(ButtonClean, { appearance: "secondary", label: "Learn more", href: routing.external.helpCenter.youtubeProductShelf, target: "_blank" }))),
        imageUrl: connectYoutubeProductShelfImage,
        primaryButton: {
            label: 'Connect YouTube',
            appearance: 'secondary',
            leftIcon: React.createElement(Icon, { component: YoutubeIcon, height: 20 }),
            iconColor: '#FF0000',
            to: routing.apps.youTubeProductShelf,
            onClick: () => {
                checklistDataLayer('checklistStep', 'Home', 'Launch', 'Set up YouTube Product Shelf');
            },
        },
        onSkipConfirm: async () => {
            await skipItem('ConnectYoutubeProductShelf');
            checklistDataLayer('skipChecklistStepConfirm', 'Home', 'Launch', 'Set up YouTube Product Shelf');
        },
    },
    CreateNewProduct: {
        title: 'Create your first product',
        description: 'Design a product or list something you own',
        primaryButton: {
            label: 'Create product',
            onClick: () => {
                openModal(CreateProductModal);
                checklistDataLayer('checklistStep', 'Home', 'Launch', 'Create your first product');
            },
        },
        completedButton: {
            label: 'Create another',
            onClick: () => {
                openModal(CreateProductModal);
            },
        },
        node: React.createElement(HomeProductsSliderContainer, null),
        onSkipConfirm: async () => {
            await skipItem('CreateNewProduct');
            checklistDataLayer('skipChecklistStepConfirm', 'Home', 'Launch', 'Create your first product');
        },
    },
    CustomiseWebsiteLook: {
        title: 'Customize the look of your site',
        description: 'Select your theme and make it yours. Change colors, add logo, set up your homepage sections, and much more.',
        imageUrl: customizeThemeImage,
        primaryButton: {
            label: 'Customize site',
            to: routing.themeEditor.general.self,
            onClick: () => {
                checklistDataLayer('checklistStep', 'Home', 'Launch', 'Customize the look of your site');
            },
        },
        completedButton: {
            label: 'Edit',
            to: routing.themeEditor.general.self,
        },
        onSkipConfirm: async () => {
            await skipItem('CustomiseWebsiteLook');
            checklistDataLayer('skipChecklistStepConfirm', 'Home', 'Launch', 'Customize the look of your site');
        },
    },
});
