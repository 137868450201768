import { Toasts } from '@fourthwall/components';
import { useAbandonedCheckoutRemindersSettings } from '@modules/Dashboard/Settings/modules/Checkout/hooks/useAbandonedCheckoutRemindersSettings';
import { useCurrentShop } from '../../../../hooks/useCurrentShop';
import { useCurrencySettings } from './useCurrencySettings';
import { useUpdatePaymentSettingsMutation } from './useUpdatePaymentSettingsMutation';
import { useUpdateShopCurrencySettingsMutation } from './useUpdateShopCurrencySettingsMutation';
export const useCheckout = () => {
    const { currentShop, invalidateCurrentShopQuery } = useCurrentShop();
    const currentAllowedPaymentProviders = currentShop?.settings.payment.allowedPaymentProviders || [];
    const { currencySettings } = useCurrencySettings();
    const { abandonedCheckoutRemindersSettings } = useAbandonedCheckoutRemindersSettings();
    const { updatePaymentSettingsMutation } = useUpdatePaymentSettingsMutation();
    const { updateShopCurrencySettingsMutation } = useUpdateShopCurrencySettingsMutation();
    const getAllowPaymentProviders = (values) => {
        return [
            currentAllowedPaymentProviders.includes('Klarna') ? 'Klarna' : undefined,
            currentAllowedPaymentProviders.includes('Afterpay') ? 'Afterpay' : undefined,
            values.payPal ? 'PayPal' : undefined,
            values.deferred ? 'StripeDeferred' : undefined,
        ].filter(Boolean);
    };
    const handleSubmit = async (values) => {
        if (!currentShop?.id)
            return;
        try {
            const allowedPaymentProviders = getAllowPaymentProviders(values);
            await Promise.all([
                await updatePaymentSettingsMutation.mutateAsync([
                    { shopId: currentShop?.id },
                    {
                        allowedPaymentProviders,
                    },
                ]),
                await updateShopCurrencySettingsMutation.mutateAsync([
                    { shopId: currentShop.id },
                    {
                        defaultCurrency: 'USD',
                        enabledCurrencies: values.enabledCurrencies,
                    },
                ]),
                await abandonedCheckoutRemindersSettings.update(values),
            ]);
            invalidateCurrentShopQuery();
            Toasts.notify('Settings saved', { type: 'info' });
        }
        catch (error) {
            Toasts.notify('Failed to update shop details. Please try again.');
        }
    };
    const getEnabledCurrencies = () => {
        if (!currentShop?.settings.enabledCurrencies) {
            return [];
        }
        return currentShop.settings.enabledCurrencies.filter((currency) => currencySettings.list.some((listItem) => listItem.currency === currency));
    };
    return {
        checkout: {
            initialValues: {
                payPal: currentAllowedPaymentProviders.includes('PayPal'),
                deferred: currentAllowedPaymentProviders.includes('StripeDeferred'),
                enabledCurrencies: getEnabledCurrencies(),
                ...abandonedCheckoutRemindersSettings.initialValues,
            },
            abandonedCheckout: abandonedCheckoutRemindersSettings.abandonedCheckout,
            currency: {
                list: currencySettings.list,
                enabled: currencySettings.enabled,
                yenEnabled: currencySettings.yenEnabled,
            },
            onSubmit: handleSubmit,
        },
    };
};
