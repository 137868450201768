import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
import { getAutomatedStyleProp } from '../../providers/ComponentsThemeProvider';
export const Container = styled.div `
  position: relative;
  display: flex;

  background: ${Colors.GS300};

  line-height: 150%;
  color: ${Colors.GS1000};

  border-radius: ${getAutomatedStyleProp('inputCornerRadius')};

  a,
  button {
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;
    text-underline-offset: 1px;
  }

  ul {
    list-style: disc;
    padding-inline-start: 1.5em;
  }

  ol {
    list-style: decimal;
    padding-inline-start: 1.5em;
  }

  ${({ appearance }) => appearance === 'danger' &&
    css `
      background: ${Colors.R100};
      color: ${Colors.R500};
    `};

  ${({ appearance }) => appearance === 'warning' &&
    css `
      background: ${Colors.Y100};
      color: ${Colors.Y500};
    `};

  ${({ appearance }) => appearance === 'inherit' &&
    css `
      background: ${getAutomatedStyleProp('paleBackground')};
      color: ${getAutomatedStyleProp('paleTextColor')};
    `};

  ${({ size }) => size === 'normal' &&
    css `
      padding: 12px 20px;
      font-size: 16px;
    `};

  ${({ size }) => size === 'small' &&
    css `
      padding: 10px 16px;
      font-size: 14px;
    `};

  ${({ size, withRightContent }) => size === 'normal' &&
    withRightContent &&
    css `
      padding-right: 52px;
    `};

  ${({ size, withRightContent }) => size === 'small' &&
    withRightContent &&
    css `
      padding-right: 48px;
    `};
`;
export const Main = styled.div `
  flex: 1;
  display: flex;

  ${({ justify }) => justify === 'center' &&
    css `
      justify-content: center;
    `};

  ${({ justify }) => justify === 'right' &&
    css `
      justify-content: flex-end;
    `};
`;
export const IconContainer = styled.div `
  position: relative;
  top: 2px;
  margin-right: 16px;
`;
export const Title = styled.div `
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS1000};
`;
export const Subtitle = styled.div `
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${Colors.GS600};
`;
export const ChildrenContainer = styled.div `
  position: relative;
  top: 1px;
`;
export const CloseButtonContainer = styled.button `
  position: absolute;
  cursor: pointer;
  right: 20px;

  ${({ size }) => size === 'normal' &&
    css `
      top: 16px;
    `};

  ${({ size }) => size === 'small' &&
    css `
      top: 14px;
    `};
`;
