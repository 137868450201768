export const transactionTypeTexts = {
    CREATOR_BUDGET: 'Samples order',
    CREATOR_CHARGE: 'Payment method charge',
    DONATION: 'Donation',
    DONATION_CANCEL: 'Donation cancel',
    GIFT_FROM_GIVEAWAY: 'Twitch gift redemption',
    GIFT_ON_CREATOR_COST: 'Giveaway links',
    GIVEAWAY_PURCHASE: 'Twitch gift purchase',
    INVENTORY_STORAGE: 'Inventory storage',
    INVENTORY_SHIPMENT: 'Inventory shipment',
    MANUAL_BONUS: 'Bonus',
    MANUAL_CHARGE: 'Charge',
    MANUAL_PAID_INVOICE: 'Paid invoice',
    MANUAL_PAYOUT: 'Payout',
    MANUAL_PRODUCTION_DISCOUNT: 'Production discount',
    MEMBERSHIP_LOCKED_MESSAGE: 'Memberships locked message',
    MEMBERSHIP_TIP: 'Memberships tip',
    ORDER: 'Order',
    ORDER_CANCEL: 'Order cancellation',
    ORDER_PARTIAL_CANCEL: 'Partial order cancellation',
    ORDER_REFUND: 'Order refund',
    REQUESTED_PAYOUT: 'Requested payout',
    SCHEDULED_PAYOUT: 'Scheduled payout',
    SUBSCRIPTION_CHARGE: 'Memberships charge',
    SUBSCRIPTION_REFUND: 'Memberships refund',
};
