export class LocalStorage {
    constructor() {
        this.setItem = (key, value) => {
            localStorage.setItem(key, value);
        };
        this.setObjectItem = (key, value) => {
            this.setItem(key, JSON.stringify(value));
        };
        this.getItem = (key) => {
            return localStorage.getItem(key);
        };
        this.getObjectItem = (key) => {
            try {
                return JSON.parse(this.getItem(key) || '');
            }
            catch (error) {
                return undefined;
            }
        };
    }
}
