import { Field, Formik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { Banner, ButtonClean, CurrencyInput, Fieldset, HeadContent, Input, InputUI, Pagination, RadioButton, Subsection, Switch, Table, Tag, } from '@fourthwall/components';
import { pluralize } from '@fourthwall/utils/lib/string';
import { DURATION_MAX, DURATION_MIN, SettingsValidationSchema, } from '@modules/Dashboard/Apps/modules/AppsTwitch/consts';
import { Dashboard, PageLayout, InnerField, SelectedProducts } from '@components/common';
import { DashboardBackButton } from '@components/utils';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { mapStatusToTagProps } from '@modules/Dashboard/Apps/utils';
import { ConfirmBarContainer } from '@modules/Dashboard/ConfirmBar';
import { routing } from '@utils/routing';
import { YouTubeVideoEntry } from '../../components/YouTubeVideoEntry';
import { ConnectBar } from '../../components/ConnectBar';
import * as S from './styled';
import { getError } from './utils';
export const AppsTwitchView = ({ formikRef, status, title, description, twitchConnect, streamelementsConnect, confirmBarProps, formProps, giftableProducts, giveaways, }) => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const renderStatusTag = () => {
        const props = mapStatusToTagProps(status);
        if (!props)
            return null;
        return (React.createElement("div", { style: { marginBottom: 20 } },
            React.createElement(Tag, { ...props, size: "large", display: "inline-flex" })));
    };
    const renderTwitchConnect = () => {
        if (!twitchConnect || !twitchConnect.connectBar)
            return null;
        return React.createElement(ConnectBar, { ...twitchConnect.connectBar });
    };
    const renderStreamElementsConnect = () => {
        if (!streamelementsConnect || !streamelementsConnect.connectBar)
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement(ConnectBar, { ...streamelementsConnect.connectBar }),
            streamelementsConnect.banner && React.createElement(Banner, { ...streamelementsConnect.banner })));
    };
    const renderGiftingToggle = ({ values }) => (React.createElement(Subsection, null,
        React.createElement(Fieldset, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Field, { component: Switch, name: "enabled", label: React.createElement(S.SwitchLabelWrapper, null, "Allow products to be gifted while I\u2019m live on Twitch"), disabled: status !== 'CONNECTED' || values.platform === 'NOT_SELECTED' })))));
    const renderChatbotSettings = ({ values, errors, dirty, submitCount }) => {
        if (!dashboardFeatureFlags.streamElementsGifting.enabled)
            return null;
        const error = errors.platform && submitCount > 0 && dirty ? (React.createElement(S.InputError, null, errors.platform)) : null;
        return (React.createElement(Subsection, { title: "Chatbot" },
            React.createElement(S.DescriptionWrapper, null,
                React.createElement("p", null, "Your giveaways will be announced and run by a chatbot in your channel. You can use the Fourthwall chatbot or the StreamElements chatbot.")),
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: "platform", label: "Use Fourthwall chatbot", checkedValue: "TWITCH" })),
                values.platform === 'TWITCH' && (React.createElement(S.ConnectBarWrapper, null,
                    renderTwitchConnect(),
                    error)),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: "platform", label: "Use StreamElements chatbot", checkedValue: "OPEN_API" })),
                values.platform === 'OPEN_API' && (React.createElement(S.ConnectBarWrapper, null,
                    renderStreamElementsConnect(),
                    error)))));
    };
    const renderEntryTimer = () => {
        if (status !== 'CONNECTED')
            return null;
        return (React.createElement(Subsection, { title: "Giveaway entry time limit" },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement("div", null,
                        React.createElement(S.NumberInputWrapper, null,
                            React.createElement(Field, { component: Input, name: "duration", label: "Entry time limit (sec)", type: "number", hideSpinner: true, displayError: false, min: DURATION_MIN, max: DURATION_MAX })),
                        React.createElement(InputUI.Suggestion, null,
                            "Set the amount of time viewers have to enter giveaways (",
                            DURATION_MIN,
                            "-",
                            DURATION_MAX,
                            " secs)."))))));
    };
    const renderInternationalShipping = ({ values }) => {
        if (status !== 'CONNECTED')
            return null;
        return (React.createElement(Subsection, { title: "Covering the cost of international shipping" },
            React.createElement(S.DescriptionWrapper, null,
                React.createElement("p", null, "The average cost of domestic shipping is included in the gift price, but international shipping may cost extra. How would you like to handle international shipping costs?")),
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: "shippingChargeType", label: "Ask gift winner to cover extra cost", checkedValue: "ALL_WINNER" })),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: "shippingChargeType", label: "Cover extra cost myself", checkedValue: "ALL_CREATOR" })),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: "shippingChargeType", label: "Cover up to a specific amount, with any remaining costs covered by the gift winner", checkedValue: "MAX_CREATOR" }))),
            values.shippingChargeType === 'MAX_CREATOR' && (React.createElement(InnerField, { fluid: true },
                React.createElement(S.NumberInputWrapper, null,
                    React.createElement(Field, { component: CurrencyInput, name: "shippingCharge", label: "Covered by me", type: "number", hideSpinner: true, min: DURATION_MIN, max: DURATION_MAX }))))));
    };
    const renderGiftableProducts = ({ values, submitCount, errors }) => {
        if (status !== 'CONNECTED')
            return null;
        const { productSelectionType, excludedProductsIds } = values;
        return (React.createElement(Subsection, { title: "Giftable products" },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: "productSelectionType", label: React.createElement("div", null,
                            "Make all products giftable while I\u2019m livestreaming",
                            productSelectionType === 'ALL_PRODUCTS' && !excludedProductsIds.length && (React.createElement(React.Fragment, null,
                                React.createElement("br", null),
                                React.createElement(ButtonClean, { appearance: "secondary", label: "Exclude selected products", onClick: () => giftableProducts.excludedProducts.onAddProduct() })))), checkedValue: "ALL_PRODUCTS" })),
                values.productSelectionType === 'ALL_PRODUCTS' &&
                    !!values.excludedProductsIds.length && (React.createElement(InnerField, { fluid: true },
                    React.createElement(SelectedProducts, { maxHeight: 500, header: React.createElement(React.Fragment, null,
                            values.excludedProductsIds.length,
                            ' ',
                            pluralize(values.excludedProductsIds.length, 'product'),
                            " excluded"), isLoading: giftableProducts.excludedProducts.isLoading, productsCount: values.excludedProductsIds.length, data: giftableProducts.excludedProducts.data, onAddProducts: giftableProducts.excludedProducts.onAddProduct, onRemoveProduct: giftableProducts.excludedProducts.onRemoveProduct }))),
                React.createElement(Fieldset.Row, null,
                    React.createElement(Field, { component: RadioButton, name: "productSelectionType", label: "Make selected products giftable while I\u2019m livestreaming", checkedValue: "SELECTED_PRODUCTS" }))),
            values.productSelectionType === 'SELECTED_PRODUCTS' && (React.createElement(InnerField, { fluid: true },
                React.createElement(SelectedProducts, { maxHeight: 500, isLoading: giftableProducts.includedProducts.isLoading, productsCount: values.includedProductsIds.length, data: giftableProducts.includedProducts.data, error: !submitCount ? undefined : getError(errors.includedProductsIds), onAddProducts: giftableProducts.includedProducts.onAddProduct, onRemoveProduct: giftableProducts.includedProducts.onRemoveProduct })))));
    };
    const renderForm = () => {
        if (!formProps)
            return null;
        return (React.createElement(Formik, { innerRef: formikRef, enableReinitialize: true, validationSchema: SettingsValidationSchema, ...formProps }, (formikProps) => (React.createElement(React.Fragment, null,
            React.createElement(ConfirmBarContainer, { isOpen: formikProps.dirty, onConfirm: formikProps.submitForm, isLoading: confirmBarProps.isLoading, onCancel: () => {
                    formikProps.resetForm();
                    confirmBarProps.onCancel();
                } }),
            React.createElement(Subsection, null,
                renderGiftingToggle(formikProps),
                status && (React.createElement("div", { style: { marginBlock: 20 } },
                    React.createElement(YouTubeVideoEntry, { title: "How Twitch Gifting Works", description: "See how to let your viewers gift merch during your Twitch streams and trigger live giveaways with just a few clicks.", size: status === 'NOT_CONNECTED' ? 'regular' : 'compact', videoId: "yuRO2b2hzuU" })))),
            renderChatbotSettings(formikProps),
            renderEntryTimer(),
            renderInternationalShipping(formikProps),
            renderGiftableProducts(formikProps)))));
    };
    const renderPurchasedGifts = () => {
        if (!giveaways)
            return null;
        return (React.createElement(Subsection, { title: "Purchased gifts", borderless: true },
            React.createElement(Dashboard.Section.Item, null,
                React.createElement(Table, { ...giveaways.table, emptyMessage: "There are no purchased gifts yet", rowComponent: Link, minWidth: 0 })),
            giveaways.pagination && (React.createElement(Dashboard.Section.Item, null,
                React.createElement(Pagination, { ...giveaways.pagination })))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: title }),
        React.createElement(PageLayout, { size: "tiny" },
            React.createElement(Dashboard.Section, { name: title, topContent: React.createElement(DashboardBackButton, { label: "Apps", to: routing.apps.self }) },
                React.createElement(Subsection, null,
                    React.createElement(S.HeaderContainer, null,
                        renderStatusTag(),
                        React.createElement(S.DescriptionWrapper, null,
                            React.createElement("p", null, description)),
                        !dashboardFeatureFlags.streamElementsGifting.enabled && (React.createElement("div", null, renderTwitchConnect()))),
                    renderForm()),
                renderPurchasedGifts()))));
};
