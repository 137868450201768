import { AuthProvider, Protected } from '@fourthwall/auth';
import { ComponentsThemeProvider, ModalProvider, SuspenseFallback } from '@fourthwall/components';
import Cookies from 'js-cookie';
import React from 'react';
import { SitesManagerGuardContainer } from '@fourthwall/module-sites-manager-guard';
import { onWebViewModalClose, onWebViewModalOpen } from '@fourthwall/utils';
import { DashboardFeatureFlagsProvider } from '@components/utils/DashboardFeatureFlags';
import { DashboardPermissionsProvider } from '@components/utils/DashboardPermissions';
import { FingerprintProvider } from '@modules/App/providers/FingerprintProvider';
import { useWebviewEventsBus } from '../../contexts/WebviewEventsBusContext';
import Dashboard from '../Dashboard';
import { EntriContextProvider } from '../Dashboard/Settings/providers/Entri';
import { CurrentShopProvider } from '../ThemeEditor/providers/CurrentShopProvider';
import { ForbiddenShopGuard } from './modules/forbiddenShopGuard';
import { IncorrectDomainGuard } from './modules/incorrectDomainGuard';
import { AdminModeProvider } from './providers/AdminModeProvider';
import { TagManagerProvider } from './providers/TagManagerProvider';
import { API_CLIENTS } from './apiClients';
export const AppView = () => {
    const webviewEventBus = useWebviewEventsBus();
    /**
     * Keycloak creator account is used in Core Dashboard, Memberships Supporter and Shop Renderer.
     * If the user goes to Memberships Supporter there is no way to detect that the user is logged in
     * or prefer to log in as a creator instead of a supporter in Memberships Supporter
     * This cookie is used to detect if the user visited Core Dashboard and redirect to Keycloak.
     */
    const setCookieForMembershipsCreatorAutoLogin = () => {
        return Cookies.set('core_dashboard_visited', '1', { expires: 365 });
    };
    const handleInitAuthSuccess = (keycloak) => {
        API_CLIENTS.forEach((client) => {
            client.addRequestInterceptor((config) => {
                keycloak.updateToken(5);
                return config;
            });
        });
    };
    const handleAuthSuccess = (keycloak) => {
        webviewEventBus.dispatchEvent('onAuthSuccess');
        setCookieForMembershipsCreatorAutoLogin();
        const { authenticated, token, tokenParsed } = keycloak;
        if (authenticated && token && tokenParsed) {
            API_CLIENTS.forEach((client) => {
                // NOTE: `setAuthorizationHeader` needs to be called before `setUserRequest` to set the token properly.
                client.setAuthorizationHeader(token);
            });
        }
    };
    return (React.createElement(AuthProvider, { loadingComponent: SuspenseFallback, onInitAuthSuccess: handleInitAuthSuccess, onAuthSuccess: handleAuthSuccess },
        React.createElement(Protected, null,
            React.createElement(ComponentsThemeProvider, { mode: "solid" },
                React.createElement(AdminModeProvider, null,
                    React.createElement(DashboardPermissionsProvider, null,
                        React.createElement(SitesManagerGuardContainer, null,
                            React.createElement(ForbiddenShopGuard, null,
                                React.createElement(TagManagerProvider, null,
                                    React.createElement(DashboardFeatureFlagsProvider, null,
                                        React.createElement(CurrentShopProvider, null,
                                            React.createElement(ModalProvider, { onModalOpen: onWebViewModalOpen, onModalClose: onWebViewModalClose },
                                                React.createElement(IncorrectDomainGuard, null,
                                                    React.createElement(EntriContextProvider, null,
                                                        React.createElement(FingerprintProvider, null,
                                                            React.createElement(Dashboard, null))))))))))))))));
};
