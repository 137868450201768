import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 24px;
  padding: 20px;
  width: 100%;

  ${mediaQueries.laptop} {
    padding: 24px 0;
    margin-top: -140px;
    margin-bottom: -140px;
  }
`;
export const Gif = styled.img `
  width: 100%;
  aspect-ratio: 4/3;
  margin-bottom: 12px;

  ${mediaQueries.laptop} {
    width: 400px;
    max-height: 300px;
    aspect-ratio: 4/3;
  }
`;
export const Message = styled.span `
  word-break: break-word;
`;
export const Content = styled.div `
  display: flex;
  gap: 16px;
  flex-direction: column;

  span {
    color: ${Colors.GS100};
  }

  ${mediaQueries.laptop} {
    span {
      color: ${Colors.GS1000};
    }
  }
`;
export const Footer = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
`;
