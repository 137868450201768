import { ShopUsersApiClient } from '@fourthwall/services/lib/ShopUsersApiClient';
import { useMutation } from '@tanstack/react-query';
export const useUserInviteMutation = () => {
    const userInviteMutation = useMutation({
        mutationFn: async (variables) => {
            const { data } = await ShopUsersApiClient.inviteUser(variables);
            return data;
        },
    });
    return { userInviteMutation };
};
