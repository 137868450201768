import { ApiBase } from './ApiBase';
class WebhooksApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getWebhookConfigurations = () => {
            return this.get('/webhook/configurations');
        };
        this.getWebhookTypes = () => {
            return this.get('/webhook/configurations/types');
        };
        this.createWebhookConfiguration = (payload) => {
            return this.post('/webhook/configurations', payload);
        };
        this.updateWebhookConfiguration = (params, payload) => {
            return this.put(`/webhook/configurations/${params.webhookConfigurationId}`, payload);
        };
        this.deleteWebhookConfiguration = (params) => {
            return this.delete(`/webhook/configurations/${params.webhookConfigurationId}`);
        };
        this.testWebhookConfiguration = (params, payload) => {
            return this.post(`/webhook/configurations/${params.webhookConfigurationId}/test`, payload);
        };
    }
}
export const WebhooksApiClient = new WebhooksApiClientModel();
