import styled from 'styled-components';
import { Colors, hideScrollbar, mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  padding: 16px;
  border: 1px solid ${Colors.GS400};
  border-radius: 2px;

  ${mediaQueries.tablet} {
    padding: 21px 28px 24px;
  }
`;
export const Header = styled.header `
  margin-bottom: 16px;

  display: flex;
  justify-content: space-between;
  gap: 12px;

  ${mediaQueries.tablet} {
    margin-bottom: 28px;
  }
`;
export const Heading = styled.div `
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  ${mediaQueries.tablet} {
    font-size: 20px;
    line-height: 140%;
  }
`;
export const Slider = styled.div `
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -3px; // to make outline visible

  display: flex;
  gap: 20px;

  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding-inline: 16px;

  ${hideScrollbar};

  ${mediaQueries.tablet} {
    padding-left: 28px;
    padding-right: 28px;
    margin-left: -28px;
    margin-right: -28px;

    gap: 40px;

    scroll-padding-inline: 28px;
  }
`;
export const Slide = styled.div `
  padding-bottom: 3px; // to make outline visible

  flex: 0 0 100%;
  display: flex;
  gap: 16px;

  scroll-snap-align: start end;

  ${mediaQueries.tablet} {
    flex: 0 0 calc((100% / 3) - (80px / 3));
  }
`;
export const Thumbnail = styled.img `
  display: block;
  width: 48px;
  height: 48px;
  object-fit: contain;
`;
export const Name = styled.div `
  margin-bottom: 4px;

  color: ${Colors.GS1000};
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`;
export const Description = styled.div `
  min-height: 2lh;
`;
export const Buttons = styled.div `
  margin-top: 16px;

  display: flex;
`;
export const Signet = styled.img `
  display: block;
  width: 16px;
  height: 16px;
  object-fit: contain;
`;
