import { ArrowShortDownIcon } from '@fourthwall/icons';
import React from 'react';
import { PriceString } from '../../../functional/PriceString';
import { Icon } from '../../Icon';
import { OrderSummaryTooltip } from '../OrderSummaryTooltip/OrderSummaryTooltip';
import * as S from './styled';
import { isPrice } from './utils';
export const SummaryRow = ({ label, value, suggestion, tooltipContent, testId, bold = false, highlighted = false, }) => {
    const renderValue = () => {
        if (isPrice(value)) {
            return (React.createElement("div", null,
                React.createElement(PriceString, { value: value.value, currency: value.currency })));
        }
        return React.createElement("div", null, value);
    };
    const renderContent = () => {
        if (tooltipContent) {
            return (React.createElement(React.Fragment, null,
                React.createElement(OrderSummaryTooltip, { content: tooltipContent },
                    React.createElement(S.LabelWithIcon, null,
                        label,
                        React.createElement(S.IconContainer, null,
                            React.createElement(Icon, { component: ArrowShortDownIcon, height: 12 })))),
                renderValue()));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(S.Label, null, label),
            renderValue()));
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.Main, { bold: bold, highlighted: highlighted, "data-testid": testId }, renderContent()),
        suggestion && React.createElement(S.Suggestion, null, suggestion)));
};
