import { useDashboardStatisticsQuery } from '@queries';
import { useOrdersQuery } from '../useOrdersQuery';
import { getOrders, getProfit, getShopStageObject } from './utils';
const staleTime = 60_000; // 1 minute
export const useShopStage = () => {
    const { dashboardStatisticsQuery } = useDashboardStatisticsQuery(undefined, { staleTime });
    const { ordersQuery } = useOrdersQuery({ types: ['COMMON'], limit: 1 }, { staleTime });
    const getShopStage = () => {
        const profit = getProfit(dashboardStatisticsQuery.data);
        const orders = getOrders(ordersQuery.data);
        return getShopStageObject(profit, orders);
    };
    return { shopStage: getShopStage() };
};
