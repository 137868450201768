import { ApiBase } from '../ApiBase';
class PaymentApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getUserPaymentMethods = () => {
            return this.get('/v2/payment/user/methods');
        };
        this.addUserPaymentMethod = (payload) => {
            return this.post('/v2/payment/user/methods', payload);
        };
        this.initializeUserPaymentMethodSetup = () => {
            return this.post('/v2/payment/user/methods/setup-initialization');
        };
        this.initializeUserPaymentMethodPayPalSetup = () => {
            return this.post('/v2/payment/user/methods/paypal/setup-initialization');
        };
        this.completeUserPaymentMethodSetup = (payload) => {
            return this.post('/v2/payment/user/methods/setup-completion', payload);
        };
        this.getShopPaymentMethods = () => {
            return this.get('/v2/payment/shop/methods');
        };
        this.addShopPaymentMethod = (payload) => {
            return this.post('/v2/payment/shop/methods', payload);
        };
        this.removeShopPaymentMethod = (shopPaymentMethodId) => {
            return this.delete(`/v2/payment/shop/methods/${shopPaymentMethodId}`);
        };
        this.setShopDefaultPaymentMethod = (shopPaymentMethodId) => {
            return this.put(`/v2/payment/shop/methods/default/${shopPaymentMethodId}`);
        };
        this.isPaymentMethodModalRequired = () => {
            return this.get(`/v2/payment/shop/methods/modal-required`);
        };
        this.getShopCharges = (params) => {
            return this.get('/v2/payment/shop/charges', params);
        };
        this.getBlockedTransactions = (params) => {
            return this.get('/v2/payment/blocked-transactions', params);
        };
        this.markBlockedTransactionsAsSeen = () => {
            return this.put('/v2/payment/blocked-transactions/mark-all-seen');
        };
    }
}
export const PaymentApiClient = new PaymentApiClientModel();
