export var FormFields;
(function (FormFields) {
    FormFields["annualEnabled"] = "annualEnabled";
    FormFields["freeAccounts"] = "freeAccounts";
    FormFields["anualDiscount"] = "anualDiscount";
    FormFields["contentVisibility"] = "contentVisibility";
    FormFields["membersCountEnabled"] = "membersCountEnabled";
    FormFields["adultContent"] = "adultContent";
    FormFields["audioDownload"] = "audioDownload";
})(FormFields || (FormFields = {}));
