import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
  width: 100%;
  height: 64px;
  background: ${Colors.GS200};
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaQueries.tablet} {
    padding: 6px 24px;
  }
`;
export const LeftContent = styled.div ``;
export const CenterContent = styled.div `
  ${mediaQueries.tablet} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const RightContent = styled.div ``;
