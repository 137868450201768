import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 96px;
  height: 54px;
  background: ${Colors.GS300};
  color: ${Colors.GS600};
  user-select: none;
  position: relative;
`;
export const Thumbnail = styled.img `
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Overlay = styled.div `
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;
export const ErrorOverlay = styled(Overlay) `
  background: ${Colors.R300};
  opacity: 0.8;
`;
export const ProcessingOverlay = styled(Overlay) `
  background: ${Colors.GS1000};
  opacity: 0.6;
`;
export const ErrorMessage = styled.div `
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${Colors.GS100};
  gap: 6px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.03em;
`;
export const ProcessingMessage = styled(ErrorMessage) `
  flex-direction: column;
  gap: 2px;
`;
export const PinnedBadge = styled.div `
  position: absolute;
  top: -7px;
  left: -7px;

  display: flex;

  align-items: center;
  justify-content: center;

  border-radius: 100%;

  height: 24px;
  width: 24px;

  background-color: ${Colors.GS1000};

  color: ${Colors.GS0};
`;
