import styled, { css } from 'styled-components';
export const ListContainer = styled.div `
  display: flex;
  list-style-type: none;
  flex-direction: row;

  ${({ $direction }) => $direction === 'vertical' &&
    css `
      flex-direction: column;
      margin-right: 24px;
    `}
`;
