import React from 'react';
import { OnboardingList } from '../OnboardingList';
import * as S from './styled';
export const Onboarding = ({ progress, onboardingListProps, skipLabel, onSkip, }) => {
    const renderSkipButton = () => {
        if (!onSkip)
            return null;
        return (React.createElement(S.SkipButton, { onClick: onSkip, "data-analytics": "skipChecklist" }, skipLabel));
    };
    const getProgressWidth = () => {
        // NOTE: we don't want to display empty bar by design
        return `${progress.percentage || 2}%`;
    };
    return (React.createElement("div", null,
        React.createElement("header", null,
            React.createElement(S.TitleContainer, null,
                React.createElement(S.Title, null, progress.title),
                renderSkipButton()),
            React.createElement(S.Progress, null,
                React.createElement(S.ProgressFill, { style: { width: getProgressWidth() } }))),
        React.createElement(OnboardingList, { ...onboardingListProps })));
};
