import { useMemo, useState } from 'react';
import { mapTableItems } from '../utils';
import { useGiveawayGiftsQuery } from './useGiveawayGiftsQuery';
export const useTableItems = (giveawayId) => {
    const { giveawayGiftsQuery } = useGiveawayGiftsQuery([{ giveawayId }]);
    const [selectedTableItemsIds, setSelectedTableItemsIds] = useState([]);
    const toggleIsTableItemSelected = (id) => {
        const isItemSelected = selectedTableItemsIds.includes(id);
        if (isItemSelected) {
            setSelectedTableItemsIds((currentSelectedTableItemsIds) => currentSelectedTableItemsIds.filter((giftId) => giftId !== id));
        }
        else {
            setSelectedTableItemsIds((currentSelectedTableItemsIds) => [
                ...currentSelectedTableItemsIds,
                id,
            ]);
        }
    };
    const tableItems = useMemo(() => {
        return mapTableItems(giveawayGiftsQuery.data?.gifts || [], selectedTableItemsIds, {
            onClick: toggleIsTableItemSelected,
        });
    }, [giveawayGiftsQuery.data?.gifts, selectedTableItemsIds]);
    return {
        tableItems,
        selectedTableItemsIds,
        stats: giveawayGiftsQuery.data?.stats,
        toggleIsTableItemSelected,
    };
};
