import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Tabs = styled.div `
  display: flex;
  gap: 16px;
  border-bottom: 1px solid ${Colors.GS400};
`;
export const Tab = styled.button `
  border-bottom: 2px solid transparent;
  color: ${Colors.GS600};
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;

  ${({ active }) => active &&
    css `
      color: ${Colors.GS1000};
      border-bottom: 2px solid ${Colors.GS1000};
    `};
`;
export const NewWindowLink = styled.div `
  display: inline-flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;
