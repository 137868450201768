import React from 'react';
import * as S from './styled';
import { TableBody } from './TableBody/TableBody';
import { TableHead } from './TableHead';
export const Table = ({ data, headers, sorting, actions, sortable = false, compact = false, rowComponent, rowTarget, isLoading = false, emptyComponent, emptyMessage, minWidth = 768, maxBodyHeight, renderFooter, getRowLink, getRowHref, getRowHighlighted = () => false, onSortingChange, onRowClick, onPositionChange, }) => {
    const handleHeadCellClick = (headerId) => {
        if (!onSortingChange)
            return;
        onSortingChange({
            headerId,
            direction: sorting && sorting.direction === 'desc' ? 'asc' : 'desc',
        });
    };
    return (React.createElement(S.Container, { role: "table", minWidth: minWidth, hasActions: !!actions, sortable: sortable },
        React.createElement(TableHead, { headers: headers, sorting: sorting, sortable: sortable, compact: compact, hasActions: !!actions, onHeadCellClick: handleHeadCellClick }),
        React.createElement(TableBody, { data: data, headers: headers, actions: actions, sortable: sortable, compact: compact, maxHeight: maxBodyHeight, rowComponent: rowComponent, rowTarget: rowTarget, isLoading: isLoading, emptyMessage: emptyMessage, emptyComponent: emptyComponent, renderFooter: renderFooter, getRowLink: getRowLink, getRowHref: getRowHref, getRowHighlighted: getRowHighlighted, onRowClick: onRowClick, onPositionChange: onPositionChange })));
};
