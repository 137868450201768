import React from 'react';
import { Heading } from '../Heading';
import * as S from './styled';
export const Subsection = ({ title, id, borderless = false, noMargin = false, rightContent, children, }) => (React.createElement(S.Container, { id: id },
    title && (React.createElement(S.Title, { borderless: borderless, noMargin: noMargin },
        React.createElement(S.HeadingContainer, null,
            React.createElement(S.Heading, null,
                React.createElement(Heading, { type: "h7", weight: "semibold" }, title)),
            rightContent && React.createElement(S.RightContent, null, rightContent)))),
    children));
