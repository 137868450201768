import { VIMEO_REGEX } from '../../../../../consts';
export const getVimeoVideoId = (link) => {
    if (!link.match(VIMEO_REGEX)) {
        return null;
    }
    return {
        id: link.match(VIMEO_REGEX)?.groups?.id,
        unlistedHash: link.match(VIMEO_REGEX)?.groups?.unlistedHash,
    };
};
