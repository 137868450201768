import { ApiBase } from './ApiBase';
class GiftsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getGift = (giftId) => {
            return this.get(`/v2/gifts/${giftId}`);
        };
        this.getGifts = (params) => {
            return this.get(`/v2/gifts/`, params);
        };
        this.getGiftPackages = () => {
            return this.get(`/v2/gifts/packages`);
        };
        this.createGiftPackage = (payload) => {
            return this.post(`/v2/gifts`, payload);
        };
        this.getGiftPackage = (giftPackageId) => {
            return this.get(`/v2/gifts/packages/${giftPackageId}`);
        };
        this.deleteGiftPackage = (giftPackageId) => {
            return this.delete(`/v2/gifts/packages/${giftPackageId}`);
        };
    }
}
export const GiftsApiClient = new GiftsApiClientModel();
