import { Fieldset, HeadContent, Input, Subsection } from '@fourthwall/components';
import React from 'react';
import { Field, Form, Formik } from 'formik';
import { validationSchema } from './consts';
import { DetailsList } from './components/DetailsList';
import { ConfirmBarContainer } from '../../../ConfirmBar';
export const GENERAL_VIEW_TITLE = 'General';
export const GeneralView = ({ initialValues, loginDetails, onSubmit }) => (React.createElement(React.Fragment, null,
    React.createElement(HeadContent, { title: `${GENERAL_VIEW_TITLE} \u2013 Account settings` }),
    React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, onSubmit: onSubmit, enableReinitialize: true }, ({ dirty, isSubmitting, handleSubmit, resetForm }) => (React.createElement(Form, null,
        React.createElement(Subsection, { title: "Profile" },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(Field, { component: Input, name: "fullName", label: "Full name" }))))),
        React.createElement(Subsection, { title: "Login details", noMargin: true },
            React.createElement(Fieldset, null,
                React.createElement(Fieldset.Row, null,
                    React.createElement(Fieldset.Item, null,
                        React.createElement(DetailsList, null,
                            React.createElement(DetailsList.Item, { title: "Email", subtitle: loginDetails.email, tagProps: {
                                    label: loginDetails.verified ? 'Verified' : 'Unverified',
                                    appearance: loginDetails.verified ? 'success' : 'caution',
                                } })))))),
        React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isSubmitting, onConfirm: handleSubmit, onCancel: resetForm }))))));
