import { AlertModal, ButtonClean, useMediaQueryContext, useModalContext, } from '@fourthwall/components';
import React from 'react';
import { useProductBySlugQuery } from '../../hooks/useProductBySlugQuery';
import { redirectToDesigner } from './utils/redirectToDesigner';
import { useCustomizationV4Query } from '../../hooks/useCustomizationV4Query';
export const DesignEditButton = ({ customizationId, offerId }) => {
    const { open } = useModalContext();
    const mq = useMediaQueryContext();
    const { customizationV4Query } = useCustomizationV4Query([customizationId]);
    const { productBySlugQuery } = useProductBySlugQuery([customizationV4Query.data?.product.slug], {
        enabled: !!customizationV4Query.data?.product.slug,
    });
    if (!customizationV4Query.data || !productBySlugQuery.data) {
        return null;
    }
    if (productBySlugQuery.data.customizationType === 'REQUESTABLE') {
        return null;
    }
    const handleEditClick = () => {
        if (productBySlugQuery.data.allowedForDesigner) {
            if (mq.tablet) {
                return redirectToDesigner({
                    customizationId: customizationV4Query.data.id,
                    productId: offerId,
                    designerVersion: customizationV4Query.data.designerVersion,
                });
            }
            return open(AlertModal, {
                title: 'We suggest using desktop to create your products',
                text: "We're working on product customizing on mobile. For now you can only edit design on a larger screen.",
            });
        }
        return open(AlertModal, {
            title: 'Product is currently unavailable',
            text: (React.createElement("p", null,
                "Product ",
                productBySlugQuery.data.name,
                " is not currently available and designs cannot be edited. We're working on bringing this product back as soon as we can. If you have any questions, please contact us at",
                ' ',
                React.createElement(ButtonClean, { label: "support@fourthwall.com", href: "mailto:support@fourthwall.com" }),
                ".")),
        });
    };
    return React.createElement(ButtonClean, { label: "Edit design", onClick: handleEditClick });
};
