import { useFormikContext } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { Subsection } from '@fourthwall/components';
import { PostTypeButtons } from '../../components/PostTypeButtons';
import { FormFields } from '../../types';
import { TYPE_BUTTONS } from './const';
export const PostTypeFields = ({ disabled }) => {
    const { values, setFieldValue } = useFormikContext();
    return (React.createElement(Subsection, { title: "Post type" },
        React.createElement(PostTypeButtons, { buttons: TYPE_BUTTONS, activeButton: values.postType, disabled: disabled, onClick: (type) => {
                setFieldValue(`${FormFields.postType}`, type);
            } })));
};
