import React from 'react';
import { InputUI } from '../../ui';
import { Radio } from '../../ui/Radio';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';
import * as S from './Option.styled';
export const Option = ({ name, label, tooltip, value, checked, suggestion, disabled, onChange, onBlur, }) => {
    const renderTooltip = () => {
        if (!tooltip)
            return null;
        return (React.createElement(S.TooltipContainer, null,
            React.createElement(InfoTooltip, { content: tooltip, dark: true })));
    };
    return (React.createElement(S.Container, null,
        React.createElement("div", null,
            React.createElement(Radio, { name: name, value: value, checked: checked, disabled: disabled, onChange: onChange, onBlur: onBlur })),
        React.createElement(S.LabelContainer, null,
            React.createElement(S.Label, { disabled: disabled },
                label,
                renderTooltip()),
            suggestion && React.createElement(InputUI.Suggestion, { withoutMargin: true }, suggestion))));
};
