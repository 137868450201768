import { Dashboard } from '@components/common/Dashboard';
import { PageLayout } from '@components/common/PageLayout';
import { Status } from '@components/composed/Status';
import { DashboardBackButton } from '@components/utils/DashboardBackButton';
import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { FeatureFlagDecorator } from '@components/utils/FeatureFlagDecorator';
import { Banner, Fieldset, HeadContent, LeavingGuard, Loading, SaveShortcutListener, Select, Subsection, useDidUpdate, usePrevious, } from '@fourthwall/components';
import { Offer } from '@fourthwall/services/lib/models/offer';
import { useFocusFirstErrorOnSubmit } from '@fourthwall/utils/lib/formik';
import { routing } from '@utils/routing';
import { addProtocol, addSourceParamToUrl } from '@utils/url';
import { Field, Form, useFormikContext } from 'formik';
import isEqual from 'lodash-es/isEqual';
import { reverse } from 'named-urls';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmBarContainer } from '../ConfirmBar';
import { ProductDetails, ProductToolbar } from './components';
import { ProductVariants } from './components/ProductVariants/ProductVariants';
import { LABEL_BY_FULFILLMENT_SERVICE, LABEL_BY_MANUFACTURING_SERVICE } from './consts';
import { DesignEditButton } from './modules/designEditButton/DesignEditButton';
import { ProductFiles } from './modules/ProductFiles';
import { Shipping } from './modules/shipping/Shipping';
import { ProductBanners } from './ProductBanners';
import { ProductBespokeProductId } from './ProductBespokeProductId/ProductBespokeProductId';
import { ProductOrdersContainer } from './ProductOrders';
import { ProductPricing } from './ProductPricing';
import { getFulfillmentType } from './store/product/utils';
import { getInitialValues } from './utils';
var FULFILLING_SERVICES = Offer.FULFILLING_SERVICES;
var MANUFACTURING_SERVICES = Offer.MANUFACTURING_SERVICES;
export const ProductView = ({ productId, productCustomizationId, bespokeProductId, productIsDeleting, productUpdateError, productUpdateIsSubmitting, variantTypes, offerImages, images, offerType, shipment, customsInformation, productBanners, rightContent, status, withMembersOnly, membersOnlyType, membersOnlyTierIds, membershipTiers, name, variantsAnalytics, variantsAnalyticsWhiplash, slug, isAvailable, productIsFetching, shippingFlatRatesAreFetching, productStatusIsUpdating, productStockIsSubmitting, isUploadingImages, shopBaseUri, description, variants, visualHints, digitalItems, maxDigitalFilesSize, fulfillmentService, manufacturingService, isSettingCostsRequired, pricingType, showPricingPerVariant, shippingPackageSize, togglePricingType, applyToAllVariants, onChangeUrl, onOrderSamples, onArchive, onStatusChange, onStockCreate, onStockUpdate, onStockDelete, }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const prevProps = usePrevious({
        productUpdateIsSubmitting,
        isAvailable,
    });
    const { hasRole } = useDashboardPermissions();
    const { values, submitForm, isSubmitting, setFieldValue, resetForm } = useFormikContext();
    useFocusFirstErrorOnSubmit();
    const isUploadingFiles = values.digitalItems.some((item) => item.isUploading);
    const fulfillmentType = getFulfillmentType(values.fulfillmentService);
    const isFormEditable = hasPermission('products.edit.details');
    const initialValues = getInitialValues({
        name,
        description,
        variants,
        visualHints,
        digitalItems,
        variantTypes,
        images,
        withMembersOnly,
        membersOnlyType,
        membersOnlyTierIds,
        isAvailable,
        fulfillmentService,
        manufacturingService,
        shipment,
        bespokeProductId,
        customsInformation,
        shippingPackageSize,
    });
    const productUrl = `${shopBaseUri}/products/${slug}`;
    const isFormDirty = !isEqual(initialValues, values);
    useDidUpdate(() => {
        if (prevProps?.productUpdateIsSubmitting && !productUpdateIsSubmitting && !productUpdateError) {
            resetForm({
                values: getInitialValues({
                    name,
                    description,
                    variants,
                    visualHints,
                    variantTypes,
                    withMembersOnly,
                    membersOnlyType,
                    membersOnlyTierIds,
                    images,
                    digitalItems,
                    isAvailable,
                    fulfillmentService,
                    manufacturingService,
                    shipment,
                    bespokeProductId,
                    customsInformation,
                    shippingPackageSize,
                }),
            });
        }
    }, [productUpdateIsSubmitting]);
    const renderOfferImages = () => {
        if (!offerImages)
            return null;
        return (React.createElement(Subsection, { title: fulfillmentType === 'DIGITAL' ? 'Photography' : 'Photography and design' },
            offerImages,
            productCustomizationId && isFormEditable && (React.createElement("div", { style: { marginTop: 16 } },
                React.createElement(DesignEditButton, { offerId: productId, customizationId: productCustomizationId })))));
    };
    const handleCostsClick = () => {
        if (productId) {
            navigate(reverse(routing.products.all.product.costs, { productId }));
        }
    };
    const handleStatusChange = (formValues) => {
        // NOTE: will be resolved when status cleanup will be merged
        onStatusChange(formValues.status);
    };
    const renderMissingCost = () => {
        if (hasRole('ROLE_COSTS_WRITE') && isSettingCostsRequired && productId) {
            return (React.createElement("div", { style: { marginBottom: 16 } },
                React.createElement(Banner, { appearance: "warning" },
                    "Product is missing costs.",
                    ' ',
                    React.createElement("button", { type: "button", onClick: handleCostsClick }, "Set it up"))));
        }
        return null;
    };
    const renderStatus = () => {
        if (!status)
            return null;
        return (React.createElement(Status, { headerContent: renderMissingCost(), status: status, isSoldOut: !isAvailable, disabled: !hasPermission('products.edit.status') || status === 'ARCHIVED', onSubmit: handleStatusChange }));
    };
    const renderBottomContent = () => {
        if (!images)
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement(ProductVariants, { productId: productId, variantTypes: variantTypes, data: values.variants, visualHints: values.visualHints, allImages: images, offerType: offerType, variantsAnalytics: variantsAnalytics, variantsAnalyticsWhiplash: variantsAnalyticsWhiplash, disabled: !isFormEditable, isCompareAtPriceEnabled: values.isCompareAtPriceEnabled, stockDisabled: !hasPermission('products.edit.stock'), showPricing: showPricingPerVariant, membershipTiers: membershipTiers, setFieldValue: setFieldValue, fulfillmentService: fulfillmentService, onStockCreate: onStockCreate, onStockUpdate: onStockUpdate, onStockDelete: onStockDelete }),
            React.createElement(ProductOrdersContainer, { offerId: productId })));
    };
    const renderLeftContent = () => {
        const shouldRenderFiles = () => {
            switch (fulfillmentType) {
                case 'ON_DEMAND':
                case 'SCREEN_PRINTED':
                case 'FULFILLED_BY_CREATOR':
                    return false;
                case 'DIGITAL':
                    return true;
            }
        };
        const renderPricing = () => {
            if (showPricingPerVariant) {
                return null;
            }
            return (React.createElement(ProductPricing, { pricingType: pricingType, togglePricingType: togglePricingType, applyToAllVariants: applyToAllVariants }));
        };
        const renderManufacturer = () => {
            const options = MANUFACTURING_SERVICES.filter((value) => value !== 'DIGITAL_ITEM').map((value) => ({ label: LABEL_BY_MANUFACTURING_SERVICE[value], value }));
            return (React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { component: Select, name: "manufacturingService", label: "Manufacturer", options: options, disabled: !isFormEditable }),
                    values.manufacturingService === 'FOURTHWALL_BESPOKE' && (React.createElement(ProductBespokeProductId, { bespokeProductId: values.bespokeProductId })))));
        };
        const renderFulfiller = () => {
            const options = FULFILLING_SERVICES.filter((value) => value !== 'DIGITAL_ITEM').map((value) => ({ label: LABEL_BY_FULFILLMENT_SERVICE[value], value }));
            return (React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { component: Select, name: "fulfillmentService", label: "Fulfiller", options: options, disabled: !isFormEditable }))));
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(ProductBanners, { ...productBanners }),
            React.createElement(ProductDetails, { productId: productId, isFormEditable: isFormEditable }),
            shouldRenderFiles() && (React.createElement(Subsection, { title: "Files to send to buyers" },
                React.createElement(ProductFiles, { maxSize: maxDigitalFilesSize, offerId: productId, files: values.digitalItems, isFormEditable: isFormEditable }))),
            React.createElement(Subsection, null, renderPricing()),
            React.createElement(FeatureFlagDecorator, { ...dashboardFeatureFlags.productAdvancedOptions, wrapper: Subsection },
                React.createElement(Subsection, { title: "Manufacturer and Fulfiller" },
                    React.createElement(Fieldset, null,
                        renderManufacturer(),
                        renderFulfiller()))),
            React.createElement(Shipping, { initialValues: initialValues }),
            renderOfferImages()));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Loading, { isLoading: isSubmitting ||
                productIsFetching ||
                shippingFlatRatesAreFetching ||
                productUpdateIsSubmitting ||
                productIsDeleting ||
                productStatusIsUpdating ||
                productStockIsSubmitting },
            React.createElement(HeadContent, { title: name }),
            React.createElement(Form, null,
                React.createElement(PageLayout, { leftContent: renderLeftContent(), rightContent: rightContent, bottomContent: renderBottomContent() },
                    React.createElement(Dashboard.ItemViewHeader, { name: name, link: {
                            url: addSourceParamToUrl(productUrl, 'dashboard'),
                            label: addProtocol(productUrl),
                        }, status: renderStatus(), toolbar: React.createElement(ProductToolbar, { onChangeUrl: onChangeUrl, onOrderSamples: onOrderSamples, onArchive: onArchive }), backButton: React.createElement(DashboardBackButton, { label: "Products", to: {
                                pathname: routing.products.all.self,
                                search: location.search,
                            } }) })))),
        React.createElement(SaveShortcutListener, { disabled: !isFormDirty, onSave: submitForm }),
        React.createElement(ConfirmBarContainer, { isOpen: isFormDirty || isUploadingImages || productUpdateIsSubmitting, isLoading: isUploadingFiles || isUploadingImages || productUpdateIsSubmitting || isSubmitting, onConfirm: submitForm, onCancel: () => resetForm({ values: initialValues }) }),
        React.createElement(LeavingGuard, { when: (isFormDirty && !!productId) || isUploadingImages })));
};
