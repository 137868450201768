import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const Wrapper = styled.div `
  ${mediaQueries.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 48px;
  }
`;
export const SectionHeader = styled.div `
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
`;
export const LoaderWrapper = styled.div `
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;

  padding: 24px 0 0 20px;

  ${mediaQueries.laptop} {
    padding: 24px 0 0 32px;
  }
`;
export const TitleContainer = styled.div `
  margin-bottom: 16px;
  padding-right: 20px;

  ${mediaQueries.laptop} {
    margin-bottom: 20px;
    padding-right: 32px;
  }
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  gap: 32px;
  padding-right: 20px;
  padding-bottom: 16px;

  ${mediaQueries.laptop} {
    padding-right: 32px;
    padding-bottom: 24px;
  }
`;
