export const mapStatusToTagProps = (status, showNotConnected = true) => {
    if (!status)
        return;
    if (!showNotConnected && status === 'NOT_CONNECTED')
        return;
    if (status === 'EXTERNAL')
        return;
    const tagProps = {
        COMING_SOON: { label: 'Coming Soon', appearance: 'caution' },
        CONFIGURED: { label: 'Configured', appearance: 'success' },
        CONNECTED: { label: 'Connected', appearance: 'success' },
        CONNECTING_IN_PROGRESS: { label: 'Pending', appearance: 'caution' },
        DISCONNECTING_IN_PROGRESS: { label: 'Disconnecting', appearance: 'caution' },
        IN_PROGRESS: { label: 'In Progress', appearance: 'caution' },
        NOT_CONFIGURED: { label: 'Requires Setup', appearance: 'caution' },
        NOT_CONNECTED: { label: 'Not Connected', appearance: 'caution' },
        SYNCING: { label: 'Pending', appearance: 'caution' },
    };
    return status === true ? tagProps.CONNECTED : tagProps[status];
};
