import { ApiBase } from '../ApiBase';
class ThemeEditorApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getActiveTheme = () => {
            return this.get('/themes/active');
        };
        this.updateCheckoutSettings = (payload) => {
            return this.put('/themes/checkout', payload);
        };
        this.uploadThemeImage = (shopId, tag, payload) => {
            return this.post(`/themes/${shopId}/images/${tag}/upload`, payload);
        };
        this.updateBranding = (payload) => {
            return this.put('/branding/v1', payload);
        };
    }
}
export const ThemeEditorApiClient = new ThemeEditorApiClientModel();
