import { Link } from 'react-router-dom';
import { EmptyState, List, Table, useMediaQueryContext } from '@fourthwall/components';
import React from 'react';
import { isShopPromotion } from '@modules/Dashboard/Promotions/utils';
import { reverse } from 'named-urls';
import { routing } from '@utils/routing';
import { TABLE_HEADERS } from './consts';
import { PromotionCodeListItem } from '../PromotionCodeListItem';
const getPromotionLink = (promotion) => {
    const promotionId = promotion.id;
    if (isShopPromotion(promotion)) {
        return {
            pathname: reverse(routing.promotions.promotionCodes.promotionCode.self, {
                promotionId,
            }),
            search: location.search,
        };
    }
    return {
        pathname: reverse(routing.promotions.promotionCodes.membershipsPromotion.self, {
            promotionId,
        }),
        search: location.search,
    };
};
export const PromotionCodesData = ({ data, isLoading, emptyStateProps }) => {
    const media = useMediaQueryContext();
    const emptyComponent = React.createElement(EmptyState, { ...emptyStateProps });
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, getItemLink: getPromotionLink, renderItem: (promotion) => React.createElement(PromotionCodeListItem, { promotion: promotion }), emptyComponent: emptyComponent }));
    }
    return (React.createElement(Table, { data: data, headers: TABLE_HEADERS, isLoading: isLoading, rowComponent: Link, emptyComponent: emptyComponent, getRowLink: getPromotionLink }));
};
