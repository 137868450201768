import React from 'react';
import { ArrowShortLeftIcon, CloseIcon, DotsVerticalIcon, DownloadIcon, PreviewOffIcon, PreviewOnIcon, RefreshIcon, } from '@fourthwall/icons';
import { Dropdown, Icon } from '@fourthwall/components';
import * as S from './styled';
import { IconButton } from '../IconButton';
export const TopBar = ({ thankYou, media, isSuccess, isUploading, showOrderDetails, onToggleShowOrderDetails, onSkip, onClose, onDownload, }) => {
    const renderLeftSide = () => {
        if (thankYou.state === 'SENT' || isSuccess || isUploading)
            return null;
        if (media.status === 'PREVIEW') {
            return React.createElement(IconButton, { onClick: media.onRecordDelete, component: ArrowShortLeftIcon });
        }
        return React.createElement(S.Nickname, null, thankYou.supporter.fullName);
    };
    const renderCloseButton = () => {
        if ((media.status === 'PREVIEW' && thankYou.state !== 'SENT' && !isSuccess) || isSuccess)
            return null;
        return React.createElement(IconButton, { component: CloseIcon, onClick: onClose });
    };
    const renderDropdownItemLabel = (icon, label) => (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { marginRight: 16 } },
            React.createElement(Icon, { component: icon, height: 16 })),
        ' ',
        label));
    const renderDropdown = () => {
        if ((media.status !== 'IDLE' && thankYou.state !== 'SENT') ||
            (thankYou.state === 'SENT' && !onDownload) ||
            isSuccess)
            return null;
        return (React.createElement(Dropdown, { content: thankYou.state === 'SENT' ? (onDownload && (React.createElement(Dropdown.Item, { label: renderDropdownItemLabel(DownloadIcon, 'Download'), onClick: onDownload }))) : (React.createElement(React.Fragment, null,
                React.createElement(Dropdown.Item, { label: renderDropdownItemLabel(showOrderDetails ? PreviewOffIcon : PreviewOnIcon, `${showOrderDetails ? 'Hide' : 'Show'} order details`), onClick: onToggleShowOrderDetails }),
                onSkip && (React.createElement(Dropdown.Item, { label: renderDropdownItemLabel(RefreshIcon, 'Skip this customer'), onClick: onSkip })))) },
            React.createElement(IconButton, { component: DotsVerticalIcon })));
    };
    return (React.createElement(S.Container, null,
        React.createElement("div", null, renderLeftSide()),
        React.createElement(S.Controls, null,
            renderDropdown(),
            renderCloseButton())));
};
