import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const DiscountInputContainer = styled.div `
  display: flex;
`;
export const DisountLabel = styled.div `
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 1.5;
`;
export const DiscountInfo = styled.div `
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-content: center;

  height: 56px;
  margin-left: 16px;
`;
export const DiscountInfoLabel = styled.div `
  font-size: 12px;
  line-height: 1.3;
  color: ${Colors.GS600};
`;
