import { useDashboardFeatureFlags } from '@components/utils/DashboardFeatureFlags';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useAuth } from '@fourthwall/auth';
import { ConfirmModal, Toasts, useModalContext } from '@fourthwall/components';
import { useUsersQuery } from '@queries/useUsersQuery';
import { RoleModal } from '../../components/RoleModal';
import { useCancelInvitationsMutation } from './hooks/useCancelInvitationsMutation';
import { useResendInvitationMutation } from './hooks/useResendInvitationMutation';
import { useTeamMembersRoles } from './hooks/useTeamMembersRoles';
import { useUserInviteMutation } from './hooks/useUserInviteMutation';
import { useUserRemoveMutation } from './hooks/useUserRemoveMutation';
import { useUserUpdateMutation } from './hooks/useUserUpdateMutation';
import { getTeamTableHeaders, sortTeamUsers } from './utils';
export const useTeamTab = ({ options }) => {
    const { logout } = useAuth();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const { open } = useModalContext();
    const { usersQuery, invalidateUsersQuery } = useUsersQuery([], options);
    const { userInviteMutation } = useUserInviteMutation();
    const { resendInvitationMutation } = useResendInvitationMutation();
    const { cancelInvitationsMutation } = useCancelInvitationsMutation();
    const { userRemoveMutation } = useUserRemoveMutation();
    const { userUpdateMutation } = useUserUpdateMutation();
    const { availableRoles, topRoleId, getRoleName, selectableRoles, canUserManageTopRoleTeamMembers, } = useTeamMembersRoles();
    const { user } = useAuth();
    const areManyTopRoleTeamMembers = ((topRoleId &&
        usersQuery.data?.users?.filter((user) => user.group === topRoleId && user.userId)?.length) ||
        0) > 1;
    const openRoleModal = ({ ...props }) => {
        open(RoleModal, {
            ...props,
            selectableRoles,
        });
    };
    const handleUserInvite = ({ email, role }) => {
        // TODO remove as InviteUserPayload when InviteUserPayload is ready to receive role: string
        const payload = { email, role };
        userInviteMutation.mutate(payload, {
            onSuccess: (response) => {
                Toasts.notify(`${response.invitedUserEmail} will receive an invitation email shortly.`, {
                    type: 'info',
                });
                invalidateUsersQuery();
                availableRoles.invalidate();
            },
        });
    };
    const handleInviteClick = () => openRoleModal({
        onSubmit: handleUserInvite,
    });
    const handleChangeRole = ({ email, role }) => {
        // TODO Remove unnecessary parameters when changing role endpoint is ready
        const user = usersQuery.data?.users.find((user) => user.email === email);
        if (user) {
            const payload = { ...user, group: role };
            userUpdateMutation.mutate([payload], {
                onSuccess: () => {
                    Toasts.notify(`The role of ${user.fullName || email} has been successfully changed to ${getRoleName(role)}.`, {
                        type: 'info',
                    });
                    invalidateUsersQuery();
                    availableRoles.invalidate();
                },
            });
        }
    };
    const handleChangeRoleClick = (role, email, fullName) => {
        openRoleModal({
            type: 'edit',
            onSubmit: handleChangeRole,
            initialValues: { email, role },
            name: fullName,
        });
    };
    const handleResendInvitation = (invitationId) => {
        resendInvitationMutation.mutate({ id: invitationId }, {
            onSuccess: () => {
                Toasts.notify('The invitation has been resent.', { type: 'info' });
                invalidateUsersQuery();
            },
        });
    };
    const handleInvitationCancel = (invitedUserEmail) => {
        cancelInvitationsMutation.mutate({ userEmail: invitedUserEmail }, {
            onSuccess: () => {
                Toasts.notify('The invitation has been canceled.', { type: 'info' });
                invalidateUsersQuery();
            },
        });
    };
    const handleUserRemove = (email, fullName) => {
        const isYou = user?.email === email;
        if (isYou) {
            open(ConfirmModal, {
                title: 'Are you sure you want to leave this team?',
                text: 'You will no longer have access to the admin tools for this site.',
                confirmLabel: 'Yes, leave',
                confirmAppearance: 'destructive',
                onConfirm: () => {
                    userRemoveMutation.mutate({ email }, {
                        onSuccess: () => {
                            Toasts.notify('You have left the team.', {
                                type: 'info',
                            });
                            logout();
                        },
                    });
                },
            });
        }
        open(ConfirmModal, {
            title: `Are you sure you want to remove ${fullName || 'this user'} as a team member?`,
            text: `${fullName || 'This user'} will no longer have access to the admin tools for this site.`,
            confirmLabel: 'Yes, remove team member',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                userRemoveMutation.mutate({ email }, {
                    onSuccess: () => {
                        Toasts.notify('The user has been removed.', {
                            type: 'info',
                        });
                        invalidateUsersQuery();
                    },
                });
            },
        });
    };
    const canUserResendInvitation = (teamMember) => {
        const hasPermissions = hasPermission('settings.team.invite');
        const isTeamMemberInvited = teamMember.invitationStatus === 'INVITED' && !!teamMember.invitationId;
        const canManageTeamMember = teamMember.group !== topRoleId || canUserManageTopRoleTeamMembers;
        return hasPermissions && isTeamMemberInvited && canManageTeamMember;
    };
    const canUserCancelInvitation = (teamMember) => {
        const hasPermissions = hasPermission('settings.team.invite');
        const hasRequiredProps = !!teamMember.email;
        const isTeamMemberInvited = teamMember.invitationStatus === 'INVITED' && !!teamMember.invitationId;
        const canManageTeamMember = teamMember.group !== topRoleId || canUserManageTopRoleTeamMembers;
        return hasPermissions && hasRequiredProps && isTeamMemberInvited && canManageTeamMember;
    };
    const canUserChangeRole = (teamMember) => {
        const isEnabled = dashboardFeatureFlags.roles.enabled;
        const hasPermissions = hasPermission('settings.team.changeRole');
        const hasRequiredProps = !!teamMember.email;
        const canManageTeamMember = teamMember.group !== topRoleId || canUserManageTopRoleTeamMembers;
        const isOnlyTopRoleTeamMember = teamMember.group === topRoleId && !!teamMember.userId && !areManyTopRoleTeamMembers;
        const isYou = teamMember.email === user?.email;
        return (isEnabled &&
            hasPermissions &&
            hasRequiredProps &&
            canManageTeamMember &&
            !isOnlyTopRoleTeamMember &&
            !isYou);
    };
    const canUserRemoveTeamMember = (teamMember) => {
        const hasPermissions = hasPermission('settings.team.remove');
        const hasRequiredProps = !!teamMember.email && !!teamMember.userId;
        const canManageTeamMember = teamMember.group !== topRoleId || canUserManageTopRoleTeamMembers;
        const isOnlyTopRoleTeamMember = teamMember.group === topRoleId && !!teamMember.userId && !areManyTopRoleTeamMembers;
        const isYou = teamMember.email === user?.email;
        return ((hasPermissions || isYou) &&
            hasRequiredProps &&
            canManageTeamMember &&
            !isOnlyTopRoleTeamMember);
    };
    const data = (() => {
        const partnersData = usersQuery.data?.agencies ?? [];
        const sortedTeamMembers = sortTeamUsers(user?.email, usersQuery.data?.users, availableRoles.roles);
        return [
            ...sortedTeamMembers.map((teamMember) => ({
                name: teamMember.fullName || '',
                isYou: teamMember.email === user?.email,
                email: teamMember.email,
                role: teamMember.group,
                roleName: getRoleName(teamMember.group),
                invitationId: teamMember.invitationId,
                invitationStatus: teamMember.invitationStatus,
                permissions: {
                    resendInvitation: canUserResendInvitation(teamMember),
                    cancelInvitation: canUserCancelInvitation(teamMember),
                    changeRole: canUserChangeRole(teamMember),
                    remove: canUserRemoveTeamMember(teamMember),
                },
            })),
            ...partnersData.map((partner) => ({
                isPartner: true,
                name: partner.name,
                logoUri: partner.logoUri,
                isYou: false,
                roleName: topRoleId ? getRoleName(topRoleId) : '',
            })),
        ];
    })();
    const isLoading = usersQuery.isLoading || availableRoles.loading;
    const emptyMessage = 'No users found';
    const dropdownProps = {
        onResendInvitation: handleResendInvitation,
        onInvitationCancel: handleInvitationCancel,
        onUserRemove: handleUserRemove,
        onChangeRole: handleChangeRoleClick,
    };
    return {
        teamTab: {
            listProps: {
                data,
                isLoading,
                emptyComponent: emptyMessage,
            },
            dropdownProps,
            tableProps: {
                headers: getTeamTableHeaders({
                    disabledHeaders: !dashboardFeatureFlags.roles.enabled ? ['role'] : undefined,
                    dropdownProps,
                }),
                data,
                isLoading: usersQuery.isLoading || availableRoles.loading,
                emptyMessage,
            },
            buttonProps: hasPermission('settings.team.invite') && availableRoles
                ? {
                    label: 'Invite new member',
                    onClick: handleInviteClick,
                }
                : undefined,
        },
    };
};
