import { HeadContent } from '@fourthwall/components';
import React from 'react';
import { useDashboardPermissions } from '../../../../../components/utils/DashboardPermissions';
import { OpenAPI } from './modules/OpenAPI';
import { Webhooks } from './modules/Webhooks';
export const ForDevelopersView = () => {
    const { hasPermission } = useDashboardPermissions();
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "For developers \u2013 Settings" }),
        hasPermission('settings.forDevelopers.openAPI') && React.createElement(OpenAPI, null),
        hasPermission('settings.forDevelopers.webhooks') && React.createElement(Webhooks, null)));
};
