import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: fit-content;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
`;
export const Link = styled.a `
  display: inline-block;
  width: 100%;
  text-decoration-line: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ appearance }) => (appearance === 'primary' ? Colors.GS1000 : Colors.GS600)};

  &:hover {
    color: ${({ appearance }) => (appearance === 'primary' ? Colors.B500 : Colors.GS1000)};
  }
`;
export const IconButton = styled.button `
  padding: 2px;
  cursor: pointer;
  color: ${({ appearance }) => (appearance === 'primary' ? Colors.GS1000 : Colors.GS600)};

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;
export const Reference = styled.div `
  position: relative;
`;
export const ConfirmIcon = styled.div `
  position: absolute;
  top: 7px;
  right: 0;
`;
