import styled, { css } from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const ButtonContainer = styled.div `
  margin-top: 16px;
  margin-bottom: 16px;

  ${({ small }) => small &&
    css `
      font-size: 14px;
      margin-top: 4px;
    `};

  ${mediaQueries.tablet} {
    margin-bottom: 0;
  }
`;
export const CompareAtPriceInputContainer = styled.div `
  margin-top: 12px;

  ${mediaQueries.tablet} {
    max-width: 160px;
  }
`;
export const CompareAtPriceCheckboxContainer = styled.div `
  margin-top: 12px;
  width: fit-content;
`;
