import { getTimezoneAbbreviation } from '@fourthwall/utils/lib/timezone';
export const getFormattedTime = (date) => {
    if (!date || !date.isValid())
        return '';
    return date.format('h:mm A');
};
// NOTE: A function that returns proposed date basing on the given string
// Example: "11 30 AM" => "23:30"
export const getTimeProposal = (str) => {
    const patterns = [
        // 1, 12, 123, 1234, 12345, 123456
        [/^(\d+)$/, '$1'],
        // :1, :2, :3, :4 ... :9
        [/^:(\d)$/, '$10'],
        // :1, :12, :123, :1234 ...
        [/^:(\d+)/, '$1'],
        // 6:06, 5:59, 5:8
        [/^(\d):([7-9])$/, '0$10$2'],
        [/^(\d):(\d\d)$/, '$1$2'],
        [/^(\d):(\d{1,})$/, '0$1$20'],
        // 10:8, 10:10, 10:34
        [/^(\d\d):([7-9])$/, '$10$2'],
        [/^(\d\d):(\d)$/, '$1$20'],
        [/^(\d\d):(\d*)$/, '$1$2'],
        // 123:4, 1234:456
        [/^(\d{3,}):(\d)$/, '$10$2'],
        [/^(\d{3,}):(\d{2,})/, '$1$2'],
        //
        [/^(\d):(\d):(\d)$/, '0$10$20$3'],
        [/^(\d{1,2}):(\d):(\d\d)/, '$10$2$3'],
    ];
    const { length } = patterns;
    const time = new Date();
    let am = 0;
    let pm = 0;
    let h = false;
    let m = false;
    let s = false;
    if (typeof str === 'undefined' || !str.toLowerCase) {
        return null;
    }
    str = str.toLowerCase();
    am = /a/.test(str);
    pm = am ? false : /p/.test(str);
    str = str.replace(/[^0-9:]/g, '').replace(/:+/g, ':');
    for (let k = 0; k < length; k += 1) {
        if (patterns[k][0].test(str)) {
            str = str.replace(patterns[k][0], patterns[k][1]);
            break;
        }
    }
    str = str.replace(/:/g, '');
    if (str.length === 1) {
        h = str;
    }
    else if (str.length === 2) {
        h = str;
    }
    else if (str.length === 3 || str.length === 5) {
        h = str.substr(0, 1);
        m = str.substr(1, 2);
        s = str.substr(3, 2);
    }
    else if (str.length === 4 || str.length > 5) {
        h = str.substr(0, 2);
        m = str.substr(2, 2);
        s = str.substr(4, 2);
    }
    if (str.length > 0 && str.length < 5) {
        if (str.length < 3) {
            m = 0;
        }
        s = 0;
    }
    if (h === false || m === false || s === false) {
        return null;
    }
    h = parseInt(`${h}`);
    m = parseInt(`${m}`);
    s = parseInt(`${s}`);
    if (am && h === 12) {
        h = 0;
    }
    else if (pm && h < 12) {
        h += 12;
    }
    if (h > 24) {
        if (str.length >= 6) {
            return getTimeProposal(str.substr(0, 5));
        }
        return getTimeProposal(`${str}0${am ? 'a' : ''}${pm ? 'p' : ''}`);
    }
    time.setHours(h, m, s);
    return time;
};
export const createLabel = (label) => {
    const timezoneAbbreviation = getTimezoneAbbreviation();
    if (typeof label === 'function') {
        return label({ timezoneAbbreviation });
    }
    return `${label}${timezoneAbbreviation ? ` (${timezoneAbbreviation})` : ''}`;
};
