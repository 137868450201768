export const dnsManagementUrls = {
    // uncommented are unverified links, they may link to main page instead of DNS management
    '123-Reg': 'https://www.123-reg.co.uk/domain-names',
    'Amazon Route 53': 'https://aws.amazon.com/route53',
    Arsys: 'https://www.arsys.es/dominios',
    'Aruba.it': 'https://www.aruba.it/domini',
    Bluehost: 'https://www.bluehost.com/domains',
    Cloudflare: 'https://dash.cloudflare.com/websites', // verified, direct not possible
    'Crazy Domains': 'https://www.crazydomains.com.au/domain-names',
    DigitalOcean: 'https://www.digitalocean.com/products/dns',
    DNSimple: 'https://dnsimple.com',
    'Domain.com': 'https://www.domain.com/domains',
    DreamHost: 'https://www.dreamhost.com/domains',
    'Google Cloud': 'https://cloud.google.com/dns',
    'Google Domains': 'https://domains.google.com/registrar/{domain}/dns', // verified, direct
    Gandi: 'https://www.gandi.net/en/domain',
    GoDaddy: 'https://dcc.godaddy.com/control/dnsmanagement?domainName={domain}', // verified, direct
    HostGator: 'https://www.hostgator.com/domains',
    Hostinger: 'https://www.hostinger.com/domains',
    Hover: 'https://hover.com/domains',
    IONOS: 'https://my.ionos.com/start-with-domain/dns',
    iwantmyname: 'https://iwantmyname.com/domains',
    Linode: 'https://www.linode.com/products/domains',
    'Name.com': 'https://www.name.com/account/domain',
    NameBright: 'https://www.namebright.com',
    Namecheap: 'https://ap.www.namecheap.com/Domains/DomainControlPanel/{domain}/advancedns', // verified, direct
    NameSilo: 'https://www.namesilo.com',
    Netlify: 'https://www.netlify.com/products/dns',
    'Network Solutions': 'https://www.networksolutions.com/domain-name-registration/index.jsp',
    'One.com': 'https://www.one.com/en/domains',
    OpenSrs: 'https://opensrs.com/products-and-services/domain-names',
    'OVH Global': 'https://www.ovh.com/world/domains',
    'Register.com': 'https://www.register.com/domain-names',
    Registro: 'https://www.registro.br',
    Shopify: 'https://www.shopify.com/domains',
    SiteGround: 'https://www.siteground.com/domains',
    Squarespace: 'https://www.squarespace.com/domains',
    Strato: 'https://www.strato.de/domains',
    'Web.com': 'https://www.web.com/domains',
    Wix: 'https://www.wix.com/domains',
    Wordpress: 'https://wordpress.com/domains',
    Xneelo: 'https://xneelo.co.za/domains',
};
