import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const RemoveIcon = styled.div `
  color: ${Colors.GS1000};
  opacity: 0.5;
`;
export const Button = styled.button `
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border-radius: 100%;
  margin-left: 8px;

  background: ${Colors.GS300};

  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`;
