import { Button, Input, ModalBox } from '@fourthwall/components';
import { useFormik } from '@fourthwall/utils/lib/formik';
import React from 'react';
import { validationSchema } from './consts';
import { convertStringToAlphanumericWithDash } from './utils';
const getCursorPositionOffset = (prevValue, newValue) => {
    const diff = newValue.length - prevValue.length;
    if (diff === 1 || diff === 2)
        return 1;
    return 0;
};
export const SlugChangeModal = ({ title, slug, shopBaseUri, prepend, onSubmit, close }) => {
    const formik = useFormik({
        initialValues: {
            slug,
        },
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values.slug);
            close();
        },
    });
    const setSlugifiedFieldValue = (field, e) => {
        const newValue = convertStringToAlphanumericWithDash(e.target.value);
        formik.setFieldValue(field, newValue);
        const cursorPositionOffset = getCursorPositionOffset(e.target.value, newValue);
        const newCursorPosition = (e.target.selectionStart || 0) + cursorPositionOffset;
        setTimeout(() => {
            e.target.selectionStart = newCursorPosition;
            e.target.selectionEnd = newCursorPosition;
        }, 0);
    };
    return (React.createElement("form", { onSubmit: formik.handleSubmit },
        React.createElement(ModalBox, { title: title, buttons: [
                React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close }),
                React.createElement(Button, { label: "Update", appearance: "primary", type: "submit", disabled: !formik.isValid || !formik.dirty }),
            ], onClose: close },
            React.createElement(Input, { prepend: React.createElement(React.Fragment, null,
                    `${shopBaseUri}/${prepend}/`,
                    "\u200E"), required: true, autoFocus: true, ...formik.getFieldProps('slug'), onChange: (e) => {
                    setSlugifiedFieldValue('slug', e);
                } }))));
};
