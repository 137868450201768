import { useAuth } from '@fourthwall/auth';
import { getPrice } from '@fourthwall/utils/lib/common';
import { pluralize } from '@fourthwall/utils/lib/string';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { useCreatorIntegrationsQuery } from '@modules/Dashboard/Apps';
import { useTiktokShopConfigurationQuery } from '@modules/Dashboard/Apps/modules/AppsTiktokShop';
import { routing } from '../../../utils/routing';
import { useCurrentShopQuery } from './useCurrentShopQuery';
import { useMarkBlockedTransactionsAsSeenMutation } from './useMarkBlockedTransactionsAsSeenMutation';
import { useSendVerifyEmailMutation } from './useSendVerifyEmailMutation';
export const useBanners = () => {
    const { keycloak } = useAuth();
    const { sendVerifyEmailMutation } = useSendVerifyEmailMutation();
    const { creatorIntegrationsQuery } = useCreatorIntegrationsQuery();
    const { currentShopQuery, invalidateCurrentShopQuery, setCurrentShopData } = useCurrentShopQuery(undefined, {
        staleTime: Infinity,
    });
    const { tiktokShopConfigurationQuery } = useTiktokShopConfigurationQuery([], {
        enabled: creatorIntegrationsQuery.data?.tiktokPartner?.status === 'CONNECTED',
    });
    const { markBlockedTransactionsAsSeenMutation } = useMarkBlockedTransactionsAsSeenMutation({
        onMutate: () => {
            setCurrentShopData((currentData) => {
                if (!currentData) {
                    return currentData;
                }
                return {
                    ...currentData,
                    blockedTransactionsStatus: { type: 'ALL_SEEN' },
                };
            });
            return undefined;
        },
    });
    const resendEmail = () => {
        sendVerifyEmailMutation.mutate();
    };
    const markBlockedTransactionsAsSeen = () => {
        markBlockedTransactionsAsSeenMutation.mutate([], {
            onSuccess: () => {
                invalidateCurrentShopQuery();
            },
        });
    };
    const banners = [
        {
            // @ts-ignore
            when: keycloak?.tokenParsed?.email_verified === false,
            bannerProps: {
                children: (React.createElement(React.Fragment, null,
                    "Please verify your email address to activate your account.",
                    ' ',
                    React.createElement("button", { onClick: resendEmail }, "Resend email"))),
                appearance: 'warning',
            },
        },
        {
            when: !!creatorIntegrationsQuery.data?.twitch?.missingScopes?.length,
            bannerProps: {
                children: (React.createElement(React.Fragment, null,
                    "Twitch requires a permissions update in order for Twitch apps to work.",
                    ' ',
                    React.createElement(Link, { to: routing.apps.twitch }, "Update permissions"))),
                appearance: 'warning',
            },
        },
        {
            when: tiktokShopConfigurationQuery.data?.type === 'CONFIGURED' &&
                !!tiktokShopConfigurationQuery.data.authorizationExpiresAt,
            bannerProps: {
                children: (React.createElement(React.Fragment, null,
                    "TikTok Shop connection expires on",
                    ' ',
                    dayjs(tiktokShopConfigurationQuery.data?.type === 'CONFIGURED'
                        ? tiktokShopConfigurationQuery.data.authorizationExpiresAt
                        : undefined).format('MMM D, YYYY'),
                    ". ",
                    React.createElement(Link, { to: routing.apps.tiktokShop }, "Update connection"))),
                appearance: 'warning',
            },
        },
        {
            when: currentShopQuery.data?.blockedTransactionsStatus?.type === 'PAYMENT_METHOD_LIMIT_EXCEEDED',
            bannerProps: {
                children: (() => {
                    if (!currentShopQuery.isSuccess)
                        return;
                    const { blockedTransactionsStatus } = currentShopQuery.data;
                    if (blockedTransactionsStatus.type !== 'PAYMENT_METHOD_LIMIT_EXCEEDED')
                        return;
                    const { numberOfBlockedTransactions: count, limit } = blockedTransactionsStatus;
                    return (React.createElement(React.Fragment, null,
                        "We blocked ",
                        count,
                        " ",
                        pluralize(count, 'transaction'),
                        " that would have taken you past your monthly charge limit",
                        limit ? ` (${getPrice(limit.value, { currency: limit.currency })})` : '',
                        ".\u00A0\u00A0",
                        React.createElement(Link, { to: routing.settings.billing.self }, "Change limit"),
                        " \u00A0\u00A0",
                        React.createElement(Link, { to: routing.blockedTransactions }, "View blocked transactions")));
                })(),
                appearance: 'danger',
                onClose: () => {
                    markBlockedTransactionsAsSeen();
                },
            },
        },
        {
            when: currentShopQuery.data?.blockedTransactionsStatus?.type === 'NO_PAYMENT_METHOD',
            bannerProps: {
                children: (() => {
                    if (!currentShopQuery.isSuccess)
                        return;
                    const { blockedTransactionsStatus } = currentShopQuery.data;
                    if (blockedTransactionsStatus.type !== 'NO_PAYMENT_METHOD')
                        return;
                    const { numberOfBlockedTransactions: count } = blockedTransactionsStatus;
                    return (React.createElement(React.Fragment, null,
                        "We blocked ",
                        count,
                        " ",
                        pluralize(count, 'transaction'),
                        " that could not be covered by your account balance.\u00A0\u00A0",
                        React.createElement(Link, { to: routing.settings.billing.self }, "Add payment method"),
                        " \u00A0\u00A0",
                        React.createElement(Link, { to: routing.blockedTransactions }, "View blocked transactions")));
                })(),
                appearance: 'danger',
                onClose: () => {
                    markBlockedTransactionsAsSeen();
                },
            },
        },
        {
            when: currentShopQuery.data?.blockedTransactionsStatus?.type === 'PAYMENT_METHOD_NOT_VALID',
            bannerProps: {
                children: (React.createElement(React.Fragment, null,
                    "We were unable to process your payment. Transactions that incur losses will be blocked. \u00A0\u00A0",
                    React.createElement(Link, { to: routing.settings.billing.self }, "Update payment method"),
                    " \u00A0\u00A0",
                    React.createElement(Link, { to: routing.blockedTransactions }, "View blocked transactions"))),
                appearance: 'danger',
                onClose: () => {
                    markBlockedTransactionsAsSeen();
                },
            },
        },
    ];
    return {
        bannersProps: banners
            .filter(({ when }) => when)
            .map(({ bannerProps }) => ({ ...bannerProps, justify: 'center' })),
    };
};
