import { lazyWithRetry } from '@fourthwall/utils/lib/lazyWithRetry';
import { ButtonClean, Heading } from '@fourthwall/components';
import React from 'react';
import * as S from './UploadedMediaDisplayer.styled';
const AudioPlayer = lazyWithRetry(() => import('./AudioPlayer'));
const VideoPlayer = lazyWithRetry(() => import('./VideoPlayer'));
export const UploadedMediaDisplayer = ({ vm: { fileType, file }, onRemove, }) => {
    if (!file || !fileType) {
        return null;
    }
    return (React.createElement(S.Section, null,
        React.createElement(S.MediaPlayer, null,
            fileType === 'video' && React.createElement(VideoPlayer, { file: file }),
            fileType === 'audio' && React.createElement(AudioPlayer, { file: file })),
        React.createElement(S.FileDescription, null,
            React.createElement(Heading, { asTag: "h4", type: "h7", weight: "semibold" }, "Attached recording"),
            React.createElement(S.ButtonWrapper, null,
                React.createElement(ButtonClean, { label: "Remove", onClick: onRemove })))));
};
