import { CreatorMessagesApiClient } from '@fourthwall/services/lib/CreatorMessagesApiClient';
import { useQuery } from '@tanstack/react-query';
export const useRecipientsQuery = (recipientFilter = { type: 'ALL' }) => {
    const recipientsQuery = useQuery({
        queryKey: ['useRecipientsQuery', recipientFilter.type],
        queryFn: async () => {
            const { data } = await CreatorMessagesApiClient.getPotentialRecipients(recipientFilter);
            return data;
        },
    });
    return {
        recipientsData: recipientsQuery.data,
        recipientsQuery,
    };
};
