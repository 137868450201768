import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  padding: 0 20px;
  margin-bottom: 16px;

  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.GS1000};

  ${mediaQueries.laptop} {
    padding: 0 24px;
  }
`;
