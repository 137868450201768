import { Loading } from '@fourthwall/components';
import React, { useCallback, useEffect, useRef } from 'react';
import { useThemeEditorContext } from '../../hooks/useThemeEditorContext';
import { InspectorFrame } from '../../modules/LivePreview/modules/InspectorFrame/InspectorFrame';
import { useThemeLivePreviewContext } from '../../modules/LivePreview/providers/ThemeLivePreviewProvider';
import * as S from './ThemePreview.styled';
export const ThemePreview = ({ iframeTimestamp, shopUrl, isLoading, previewMode = 'desktop', previewSectionKey, previewInspector, className, }) => {
    const { isPreviewLoading, setIsPreviewLoading } = useThemeEditorContext();
    const iframeRef = useRef(null);
    const { setInspectorVisible } = useThemeLivePreviewContext();
    useEffect(() => {
        setIsPreviewLoading(true);
    }, [iframeTimestamp, shopUrl]);
    const scrollToPreviewSection = useCallback((behavior = 'smooth') => {
        try {
            if (!iframeRef.current || !iframeRef.current.contentWindow)
                return;
            const elementId = `fw-section-${previewSectionKey}`;
            const element = iframeRef.current.contentWindow.document.getElementById(elementId);
            element && element.scrollIntoView({ behavior });
        }
        catch { }
    }, [previewSectionKey]);
    useEffect(() => {
        if (previewSectionKey) {
            scrollToPreviewSection();
        }
    }, [previewSectionKey, scrollToPreviewSection]);
    const handleLoad = () => {
        if (previewSectionKey) {
            scrollToPreviewSection('auto');
        }
        setIsPreviewLoading(false);
    };
    return (React.createElement(S.Container, { className: className },
        React.createElement(S.Wrapper, { previewMode: previewMode, onMouseEnter: () => setInspectorVisible(true), onMouseLeave: () => setInspectorVisible(false) },
            React.createElement(Loading, { isLoading: isLoading || isPreviewLoading, variant: "solid" },
                previewInspector && React.createElement(InspectorFrame, null),
                React.createElement("iframe", { key: iframeTimestamp, ref: iframeRef, src: shopUrl, title: "Shop preview", width: "100%", height: "100%", onLoad: handleLoad })))));
};
