import { useState } from 'react';
import { useImageConfirmMutation } from '../../../hooks/useImageConfirmMutation';
import { useUploadImageMutation } from '../../../hooks/useUploadImageMutation';
import { useUpdateImagePostMutation } from './useUpdateImagePostMutation';
export const useUpdateImagePost = () => {
    const { updateImagePostMutation } = useUpdateImagePostMutation();
    const { uploadImageMutation } = useUploadImageMutation();
    const { imageConfirmMutation } = useImageConfirmMutation();
    const [isLoading, setLoading] = useState(false);
    const updateImagePost = async (id, image, payload, handleSuccess) => {
        setLoading(true);
        const onSuccess = () => {
            handleSuccess();
            setLoading(false);
        };
        const { postId, imageDirectUploadData } = await updateImagePostMutation.mutateAsync({
            id: Number(id),
            ...payload,
        }, {
            onSuccess: ({ imageDirectUploadData }) => {
                // Update text fields only
                if (!imageDirectUploadData) {
                    onSuccess();
                }
            },
            onError: () => setLoading(false),
        });
        // Update image
        if (imageDirectUploadData) {
            await uploadImageMutation.mutateAsync({ file: image, ...imageDirectUploadData }, { onError: () => setLoading(false) });
            await imageConfirmMutation.mutate({ id: postId, signedId: imageDirectUploadData.signedId }, {
                onSuccess,
                onError: () => setLoading(false),
            });
        }
    };
    return {
        updateImagePost,
        isLoading,
    };
};
