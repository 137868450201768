import styled, { css, keyframes } from 'styled-components';
import { Colors } from '@fourthwall/components';
import { BUTTON_SIZE, STROKE_WIDTH } from './consts';
const redStyles = css `
  background: ${Colors.R300};

  &:active {
    background: ${Colors.R500};
  }
`;
const blueStyles = css `
  background: ${Colors.B500};

  &:active {
    background: ${Colors.B700};
  }
`;
export const StaticRing = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid ${Colors.GS100};
  border-radius: 100%;
  background: transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.4);
  }
`;
export const InnerCircle = styled.div `
  position: absolute;
  width: ${BUTTON_SIZE - 14}px;
  height: ${BUTTON_SIZE - 14}px;
  background: ${Colors.GS100};
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
`;
const activeRingStyles = css `
  ${StaticRing} {
    border-color: ${Colors.GS500};
  }
`;
const activeInnerCircleStyles = css `
  ${InnerCircle} {
    background: ${Colors.GS500};
  }
`;
const typeStylesMap = {
    video: css `
    ${redStyles};
  `,
    audio: css `
    ${redStyles};
  `,
    photo: css `
    background: transparent;

    &:active {
      ${activeRingStyles};
      ${activeInnerCircleStyles};
    }
  `,
    upload: css `
    ${blueStyles};

    img {
      margin-top: -2px;
    }
  `,
    send: css `
    ${blueStyles};
  `,
    stop: css `
    background: transparent;
  `,
};
export const Container = styled.button.attrs({ type: 'button' }) `
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;

  ${({ actionType }) => typeStylesMap[actionType]};
`;
export const ProgressRing = styled.svg `
  position: absolute;
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  top: calc(50% - ${BUTTON_SIZE / 2}px);
  top: calc(50% - ${BUTTON_SIZE / 2}px);
`;
const commonCircleStyles = css `
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  stroke-width: ${STROKE_WIDTH};
  fill: transparent;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;
const progressAnimation = (circumference) => keyframes `
    0% {
      stroke-dashoffset: ${circumference};
    }
    100% {
      stroke-dashoffset: 0;
    }
`;
export const Circle = styled.circle `
  ${commonCircleStyles};
  stroke: ${Colors.GS100};
  stroke-dasharray: ${({ circumference }) => `${circumference} ${circumference}`};
  animation: ${({ circumference }) => progressAnimation(circumference)} 20s linear infinite;
`;
export const BackgroundCircle = styled.circle `
  ${commonCircleStyles};
  stroke: rgb(255, 255, 255, 0.2);
`;
