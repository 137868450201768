import * as yup from 'yup';
import { includesHTMLTags, isLatin, isPOBox } from './validation';
yup.setLocale({
    mixed: {
        required: 'This field is required.',
    },
    string: {
        email: 'Email must be valid.',
        min: 'Must have more than ${min} characters.',
        max: 'Must have less than ${max} characters.',
    },
    number: {
        positive: 'Must be greater than 0.',
        min: 'Must not be lower than ${min}.',
        max: 'Must not be higher than ${max}.',
        integer: 'Must be an integer.',
    },
});
yup.addMethod(yup.string, 'fullName', function (message) {
    return this.test('fullName', message, function (value) {
        const isValid = !value || value.trim().includes(' ');
        return isValid || this.createError({ path: this.path, message });
    });
});
yup.addMethod(yup.string, 'latin', function (message) {
    return this.test('latin', message, function (value) {
        const isValid = !value || isLatin(value);
        return isValid || this.createError({ path: this.path, message });
    });
});
yup.addMethod(yup.string, 'notEmail', function (message) {
    return this.test('notEmail', message, function (value) {
        const isValid = !yup.string().email().isValidSync(value);
        return isValid || this.createError({ path: this.path, message });
    });
});
yup.addMethod(yup.string, 'notPOBox', function (message) {
    return this.test('notPOBox', message, function (value) {
        const isValid = !value || !isPOBox(value);
        return isValid || this.createError({ path: this.path, message });
    });
});
yup.addMethod(yup.string, 'noHTML', function (message = 'Must not include HTML tags.') {
    return this.test('noHTML', message, function (value) {
        const isValid = !value || !includesHTMLTags(value);
        return isValid || this.createError({ path: this.path, message });
    });
});
export default yup;
