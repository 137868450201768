import { Skeleton } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const MembershipsSplashPageSkeleton = () => {
    return (React.createElement(S.Container, null,
        React.createElement(Skeleton, null,
            React.createElement(Skeleton.Shape, { width: 272, height: 200 })),
        React.createElement("div", { style: { marginTop: 48 } },
            React.createElement(Skeleton, null,
                React.createElement(Skeleton.Shape, { width: 98, height: 19 }))),
        React.createElement(S.HeadingContainer, null,
            React.createElement(Skeleton, null,
                React.createElement(Skeleton.Shape, { width: 222, height: 38 }))),
        React.createElement(Skeleton, null,
            React.createElement(Skeleton.Shape, { width: 440, height: 72 })),
        React.createElement(S.ButtonsContainer, null,
            React.createElement(Skeleton, null,
                React.createElement(Skeleton.Shape, { width: 200, height: 48 })))));
};
