import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 20px 20px;

  ${mediaQueries.laptop} {
    padding: 12px 24px;
  }
`;
