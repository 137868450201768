export const FILTER_BILLING_CYCLE_LABELS = {
    Month: 'Monthly',
    Year: 'Annual',
};
export const FILTER_STATE_LABELS = {
    Active: 'Active',
    Expiring: 'Expiring',
    Former: 'Former',
    PastDue: 'Past due',
};
export const FILTER_TYPE_LABELS = {
    member: 'Member',
    free: 'Free account',
};
