import { OffersApiClient } from '@fourthwall/services/lib/OffersApiClient';
import { useMutation } from '@tanstack/react-query';
export const useAvailabilityMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({ offerId, params }) => {
            const { data } = await OffersApiClient.setAvailability(offerId, params);
            return data;
        },
    });
    return {
        availability: mutation.data,
        availabilityMutation: mutation,
    };
};
