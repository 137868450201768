import styled from 'styled-components';
import { mediaQueries } from '@fourthwall/components';
import { ThemePreview as ThemePreviewComponent } from '../../../../components/ThemePreview';
export const ThemePreview = styled(ThemePreviewComponent) `
  height: 100%;
  width: 100%;

  ${mediaQueries.laptop} {
    padding: 10px 24px 24px 24px;
    height: calc(100% - 64px);
  }
`;
