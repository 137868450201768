import { Fieldset, Input, PriceString } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React from 'react';
import { FormFields } from '../../types';
import * as S from './styled';
export const MonthDiscount = ({ annualDiscount }) => {
    const formik = useFormikContext();
    if (formik.values.price && parseFloat(formik.values.price) === 0)
        return null;
    if (!formik.values.discount)
        return null;
    const renderMonthDiscountInfo = () => {
        if (!formik.values.price ||
            !formik.values.monthDiscount ||
            parseInt(formik.values.monthDiscount) > 100 ||
            parseInt(formik.values.monthDiscount) < 0)
            return null;
        return (React.createElement(S.DiscountInfo, null,
            React.createElement(S.DiscountInfoLabel, null, "First month"),
            React.createElement(PriceString, { value: formik.values.price
                    ? parseFloat(formik.values.price) -
                        parseFloat(formik.values.price) * (parseInt(formik.values.monthDiscount) / 100)
                    : 0 })));
    };
    return (React.createElement(Fieldset.Row, { inner: true },
        React.createElement("div", null,
            annualDiscount && React.createElement(S.DisountLabel, null, "Monthly membership"),
            React.createElement(S.DiscountInputContainer, null,
                React.createElement(Field, { component: Input, name: FormFields.monthDiscount, label: "First month discount", type: "number", append: "%", hideSpinner: true }),
                renderMonthDiscountInfo()))));
};
