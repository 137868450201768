import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
export const IconContainer = styled.div `
  display: flex;

  align-items: center;
  justify-content: center;

  border-radius: 100%;

  height: 16px;
  width: 16px;

  background: ${Colors.B500};
  color: ${Colors.GS0};
`;
export const Container = styled.button `
  width: 100%;
  display: flex;

  gap: 12px;
  align-items: center;

  line-height: 1.5;
  color: ${Colors.B500};
  cursor: pointer;

  &:hover {
    color: ${Colors.B700};
  }

  &:hover ${IconContainer} {
    background: ${Colors.B700};
  }

  ${({ disabled }) => disabled &&
    css `
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;
