import styled, { css } from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const Container = styled.div `
  padding: 24px 20px 28px;
  background: ${Colors.GS0};
  border: 1px solid ${Colors.GS400};
  border-radius: 2px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  position: relative;

  ${({ size }) => size === 'large' &&
    css `
      padding: 20px;
    `};

  ${mediaQueries.tablet} {
    padding: 24px 40px 24px 28px;

    display: flex;
    flex-direction: row;
    gap: 40px;

    ${({ size }) => size === 'large' &&
    css `
        padding: 40px;
        gap: 12px;

        align-items: center;
        flex-direction: column-reverse;

        ${Image} {
          height: 202px;
        }

        ${Main} {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          text-align: center;
          gap: 16px;
        }

        ${TextualContent} {
          text-align: center;
          align-items: center;
        }
      `};
  }
`;
export const Main = styled.div `
  flex: 1;

  display: flex;
  gap: 20px;

  ${mediaQueries.tablet} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0;
  }
`;
export const Image = styled.img `
  height: 70px;

  ${mediaQueries.tablet} {
    height: 140px;
    object-fit: contain;
  }
`;
export const ImageContainer = styled.div `
  display: flex;
  justify-content: center;
`;
export const TextualContent = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  flex: 1;

  ${mediaQueries.tablet} {
    gap: 4px;
  }
}
`;
export const Title = styled.h3 `
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  ${mediaQueries.tablet} {
    font-size: 20px;
    line-height: 140%;
  }
`;
export const Badge = styled.span `
  ${mediaQueries.tablet} {
    display: inline-block;
    margin-left: 12px;

    position: relative;
    bottom: 3px;
  }
`;
export const Description = styled.p `
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;

  ${mediaQueries.tablet} {
    font-size: 16px;
    line-height: 150%;
  }
`;
export const Content = styled.div `
  display: flex;
  justify-content: center;

  ${mediaQueries.tablet} {
    align-self: stretch;
    display: block;
    width: auto;
  }
`;
export const ButtonContainer = styled.div `
  width: 100%;

  display: inline-block;

  ${mediaQueries.tablet} {
    width: auto;
  }
`;
export const DropdownButtonContainer = styled.div `
  position: absolute;
  top: -1px;
  right: 14px;

  ${mediaQueries.tablet} {
    top: 8px;
    right: 8px;
  }
`;
export const DropdownButton = styled.button `
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.06);
  }
`;
