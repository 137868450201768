import { getCurrentEnvironment } from '@fourthwall/env';
export const isDevelopment = () => getCurrentEnvironment() === 'development';
export const isStaging = () => getCurrentEnvironment() === 'staging';
export const isProduction = () => getCurrentEnvironment() === 'production';
export const isDeployed = () => isStaging() || isProduction();
export const devConsoleError = (message) => {
    if (isDeployed()) {
        return;
    }
    console.error(message);
};
export const errorLoggerInterceptor = (logger) => (error) => {
    var _a, _b, _c;
    if ((((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) && ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status) >= 500) || !((_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.status)) {
        logger(error);
    }
    return Promise.reject(error);
};
