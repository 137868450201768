import { AutoComplete, Icon } from '@fourthwall/components';
import { PlusIcon } from '@fourthwall/icons';
import kebabCase from 'lodash-es/kebabCase';
import React, { useState } from 'react';
import { Tag } from '../Tag';
import * as S from './styled';
const TAG_LENGTH_LIMIT = 30;
export const TagSelect = ({ selectedTags, tags, label = 'Add new tag', withAddButton = false, disabled, onAdd, onRemove, }) => {
    const [value, setValue] = useState('');
    const handleInputChange = (e) => {
        setValue(e.target.value);
    };
    const handleInputPaste = (e) => {
        const combinedValue = `${value}${e.clipboardData.getData('Text')}`;
        e.preventDefault();
        setValue(combinedValue.substring(0, TAG_LENGTH_LIMIT));
    };
    const handleAdd = (value) => {
        onAdd(value.trim());
        setValue('');
    };
    const getFilteredTags = () => {
        if (value) {
            const escaped = value.trim().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
            return tags.filter((tag) => tag.match(new RegExp(escaped, 'gim')));
        }
        return tags;
    };
    const getAddOption = () => {
        if (value.trim() && withAddButton) {
            const exactMatch = tags.find((tag) => tag.toLowerCase() === value.trim().toLowerCase());
            const selectedExactMatch = selectedTags.find((tag) => tag.toLowerCase() === value.trim().toLowerCase());
            if (exactMatch || selectedExactMatch)
                return null;
            return [
                {
                    id: value,
                    content: (React.createElement(S.AddOption, null,
                        React.createElement(Icon, { component: PlusIcon, height: 12 }),
                        "Add ",
                        value.trim())),
                },
            ];
        }
        return null;
    };
    const getOptions = () => {
        if (value.length > TAG_LENGTH_LIMIT) {
            return;
        }
        const tagsWithoutSelected = getFilteredTags().filter((tag) => selectedTags.indexOf(tag) === -1);
        const options = tagsWithoutSelected.map((tag) => {
            const getContent = () => {
                if (value.trim()) {
                    const index = tag.indexOf(value.trim());
                    if (index === -1)
                        return tag;
                    return [
                        tag.slice(0, index).replace(/ /g, '\u00A0'),
                        React.createElement("span", { style: { fontWeight: 700 } }, value.trim()),
                        tag.slice(index + value.trim().length).replace(/ /g, '\u00A0'),
                    ];
                }
                return tag;
            };
            return {
                id: tag,
                content: getContent(),
            };
        });
        return [...(getAddOption() || []), ...options].filter(Boolean);
    };
    const renderTags = () => {
        if (!selectedTags.length)
            return null;
        return (React.createElement(S.Tags, null, selectedTags.map((tag) => (React.createElement(Tag, { key: kebabCase(tag), label: tag, onRemove: onRemove })))));
    };
    const getError = () => {
        if (value.length > TAG_LENGTH_LIMIT) {
            return `Must have less than ${TAG_LENGTH_LIMIT} characters`;
        }
    };
    return (React.createElement("div", null,
        React.createElement(AutoComplete, { options: getOptions(), label: label, value: value, blurOnSelect: false, error: getError(), withArrow: true, disabled: disabled, onChange: handleInputChange, onSelect: (option) => handleAdd(option.id), onPaste: handleInputPaste }),
        renderTags()));
};
