import { Toasts } from '@fourthwall/components';
import { OrderApiClient } from '@fourthwall/services/lib/OrderApiClient';
import { useMutation } from '@tanstack/react-query';
export const useItemsReplacementMutation = (orderId) => {
    const itemsReplacementMutation = useMutation({
        mutationFn: async (payload) => {
            const { data } = await OrderApiClient.replaceOrderItems(orderId, payload);
            return data;
        },
        onError: (error) => {
            Toasts.notify(error.response?.data.title, { type: 'error' });
        },
    });
    return { itemsReplacementMutation };
};
