import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonClean, Icon, useModalContext } from '@fourthwall/components';
import { CloseIcon } from '@fourthwall/icons';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { isWebView } from '@fourthwall/utils';
import { routing } from '@utils/routing';
import { SeriesCell } from '../../../Series/components/SeriesCell';
import { useSeries } from '../../hooks/useSeries';
import { FormFields } from '../../types';
import { SelectSeries } from '../SelectSeries';
import { EmptyModal } from './EmptyModal';
import * as S from './styled';
export const SeriesFields = ({ disabled }) => {
    const modal = useModalContext();
    const { values, setFieldValue } = useFormikContext();
    const { series } = useSeries();
    const navigate = useNavigate();
    if (!series || isWebView()) {
        return null;
    }
    const handleAddClick = () => {
        if (!series.length) {
            return modal.open(EmptyModal, {
                onCreateClick: () => {
                    navigate(routing.memberships.series.create);
                },
            });
        }
        modal.open(SelectSeries, {
            selectedSeries: values.videoSeries,
            onConfirm: (selectedSeries) => {
                setFieldValue(FormFields.videoSeries, selectedSeries);
            },
        });
    };
    const handleRemoveClick = (id) => {
        setFieldValue(FormFields.videoSeries, values.videoSeries?.filter((singleSeries) => singleSeries.id !== id));
    };
    if (values.videoSeries?.length) {
        return (React.createElement(S.Container, null,
            React.createElement(S.Buttons, null,
                "Added in ",
                values.videoSeries.length,
                " series",
                React.createElement(ButtonClean, { label: "Add to video series", withUnderline: true, onClick: handleAddClick, disabled: disabled })),
            React.createElement(S.List, null, values.videoSeries.map((videoSeries) => {
                const singleSeries = series.find((s) => s.id === videoSeries.id);
                if (!singleSeries) {
                    return null;
                }
                return (React.createElement(S.SeriesListItem, null,
                    React.createElement(SeriesCell, { title: singleSeries.title, quantity: singleSeries.episodesCount, thumbnailUrl: singleSeries.coverImage?.url }),
                    React.createElement(S.RemoveButton, { type: "button", onClick: () => handleRemoveClick(singleSeries.id), disabled: disabled },
                        React.createElement(Icon, { component: CloseIcon, height: 16 }))));
            }))));
    }
    return (React.createElement(ButtonClean, { label: "Add to video series", withUnderline: true, onClick: handleAddClick, disabled: disabled }));
};
