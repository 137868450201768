import { Colors, mediaQueries } from '@fourthwall/components';
import { CloseIcon, TickIcon } from '@fourthwall/icons';
import styled, { css } from 'styled-components';
const sharedIconStyle = css `
  border-radius: 50%;
  padding: 7px;
  color: #fff;
`;
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 100%;

  ${mediaQueries.tablet} {
    flex-direction: row;
    justify-content: flex-end;
    height: auto;
    gap: 20px;
  }
`;
export const StatusTickIcon = styled(TickIcon) `
  background-color: ${Colors.G300};
  ${sharedIconStyle}
`;
export const StatusCloseIcon = styled(CloseIcon) `
  background-color: ${Colors.R300};
  ${sharedIconStyle}
`;
export const StatusWrapper = styled.div `
  min-width: 24px;
`;
export const LoaderWrapper = styled.div `
  height: 24px;
  display: flex;
  align-items: center;
`;
export const Info = styled.div `
  color: ${Colors.GS600};

  ${mediaQueries.tablet} {
    color: ${Colors.GS1000};
  }
`;
