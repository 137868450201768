import styled from 'styled-components';
import { Colors } from '../../colors';
import { HELPER_CLASS } from './consts';
const getWidth = ({ size }) => {
    if (size === 'extraSmall') {
        return 109;
    }
    if (size === 'small') {
        return 124;
    }
    if (size === 'medium') {
        return 202;
    }
    return null;
};
const getHeight = ({ size }) => {
    if (size === 'extraSmall') {
        return 145;
    }
    if (size === 'small') {
        return 165;
    }
    if (size === 'medium') {
        return 269;
    }
    return null;
};
const getGutter = ({ size }) => {
    if (size === 'extraSmall') {
        return 4;
    }
    if (size === 'small') {
        return 4;
    }
    if (size === 'medium') {
        return 8;
    }
    return null;
};
export const Image = styled.img `
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const Options = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;
export const OptionsIconContainer = styled.div `
  padding: 8px 10px 6px 8px;
`;
export const ImageContainer = styled.div `
  position: relative;
  width: ${getWidth}px;
  height: ${getHeight}px;
  background-color: ${Colors.GS200};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 2px solid transparent;
  }

  &:hover {
    &:after {
      border: 2px solid ${Colors.GS1000};
    }

    ${Image} {
      opacity: 0.3;
    }

    ${Options} {
      opacity: 1;
    }
  }
`;
export const LoaderContainer = styled.div `
  width: ${getWidth}px;
  height: ${getHeight}px;
  background-color: ${Colors.GS200};
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 24px;
  justify-content: center;
`;
export const Item = styled.div `
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'grab')};

  &.${HELPER_CLASS} {
    pointer-events: auto !important;
    cursor: grabbing;
  }

  &:hover {
    z-index: 2;
  }
`;
export const ItemWrapper = styled.div `
  position: relative;
`;
export const Placeholder = styled.div `
  position: absolute;
  background-color: ${Colors.GS200};
`;
export const AdditionalItem = styled.div ``;
export const Container = styled.div `
  display: flex;
  flex-wrap: wrap;
  margin: -${getGutter}px;

  ${Item} {
    padding: ${getGutter}px;
  }

  ${Placeholder} {
    top: ${getGutter}px;
    left: ${getGutter}px;
    right: ${getGutter}px;
    bottom: ${getGutter}px;
  }

  ${AdditionalItem} {
    width: ${getWidth}px;
    height: ${getHeight}px;
    margin: ${getGutter}px;
  }
`;
