import styled from 'styled-components';
import { Colors } from '../../colors';
import { InputUI } from '../../ui';
export const InputsContainer = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
`;
export const IconContainer = styled.div `
  color: ${Colors.GS600};
  flex: 0;
`;
export const InputContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const InputBoxContainer = styled.div `
  ${({ width }) => (width ? `width: ${width}px;` : '')}
`;
export const InputWrapper = styled.div `
  flex: 1;

  ${InputUI.Box} {
    border: 0 !important;
    background: transparent;
  }
`;
