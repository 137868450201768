import { useMemo } from 'react';
import { useSamplesInfiniteOffersQuery } from './useSamplesInfiniteOffersQuery';
import { filterOffersWithVariants } from './utils';
export const useSamplesInfiniteOffers = (query) => {
    const { offersInfiniteQuery } = useSamplesInfiniteOffersQuery(query);
    const totalOffers = offersInfiniteQuery.data?.pages.reduce((acc, currentOffer) => {
        return [...acc, ...currentOffer.offers];
    }, []);
    const offers = useMemo(() => filterOffersWithVariants(totalOffers || []), [totalOffers]);
    return {
        samplesOffers: {
            offers,
            isLoading: offersInfiniteQuery.isLoading,
            hasNextPage: !!offersInfiniteQuery.hasNextPage,
            fetchNextPage: offersInfiniteQuery.fetchNextPage,
        },
    };
};
