import { FeatureFlagsProvider } from '@fourthwall/components';
import { isProduction } from '@fourthwall/utils/lib/dev';
import React from 'react';
import { useAdminModeContext } from '../../../modules/App/providers/AdminModeProvider';
import { useCurrentShopQuery } from '../../../modules/Dashboard/hooks/useCurrentShopQuery';
// import { useUsersQuery } from '../../../queries/useUsersQuery'
import { useDashboardPermissions } from '../DashboardPermissions';
import { hasMembershipsMessagesEnabled } from './hasMembershipsMessagesEnabled';
import { hasMembershipsWlaEnabled } from './hasMembershipsWlaEnabled';
import { usePlatformFeatureFlags } from './usePlatformFeatureFlags';
export const DashboardFeatureFlagsProvider = ({ children }) => {
    const { hasRole } = useDashboardPermissions();
    const { currentShopQuery } = useCurrentShopQuery(undefined, { staleTime: Infinity });
    const { hasFeatureFlag } = usePlatformFeatureFlags();
    // const { usersQuery } = useUsersQuery(undefined, { staleTime: Infinity })
    // const shopMember =
    //   usersQuery.isSuccess && !!usersQuery.data.users.find(({ email }) => email === user.email)
    const adminMode = useAdminModeContext();
    const admin = {
        enabled: !!adminMode?.isActive,
        description: 'Admins 🔐',
        highlight: true,
    };
    const membershipsEnabled = !hasRole('ROLE_MEMBERSHIPS_DISABLED') &&
        currentShopQuery.isSuccess &&
        currentShopQuery.data.settings.memberships.enabled;
    const featureFlags = {
        account: true,
        accountSettingsSecurity: hasFeatureFlag('dashboard:account_settings:security') || !!adminMode?.isActive,
        analyticsGoogleAnalytics: hasFeatureFlag('dashboard:analytics:googleanalytics'),
        analyticsTrackingPixels: true,
        createNewSite: hasFeatureFlag('create_new_site'),
        homeOnboarding: currentShopQuery.isSuccess &&
            new Date(currentShopQuery.data.createdAt) > new Date('2022-09-08'),
        homeProducts: hasFeatureFlag('dashboard:home_products'),
        homeScheduler: true, // still disabled when !homeOnboarding
        hsCodes: admin,
        googleFontsEnabled: hasFeatureFlag('google_fonts_enabled'),
        integrationMeta: hasFeatureFlag('integration_meta'),
        memberships: membershipsEnabled,
        membershipsFreeAccounts: membershipsEnabled && hasFeatureFlag('memberships_free_accounts'),
        membershipsLockedPages: membershipsEnabled && hasFeatureFlag('memberships_locked_pages'),
        membershipsMessages: membershipsEnabled && hasMembershipsMessagesEnabled(currentShopQuery.data.id),
        membershipsMessagesPayPerView: membershipsEnabled,
        membershipsMultipleImages: true,
        membershipsOnboarding: membershipsEnabled,
        membershipsPinnedPosts: membershipsEnabled && hasFeatureFlag('memberships_pinned_posts'),
        membershipsPromoCodes: membershipsEnabled,
        membershipsPublicPost: membershipsEnabled && hasFeatureFlag('memberships_public_post'),
        membershipsPublicVideo: membershipsEnabled && hasFeatureFlag('memberships_public_video'),
        membershipsPwaSettings: currentShopQuery.isSuccess && !hasMembershipsWlaEnabled(currentShopQuery.data.id),
        membershipsSplash: currentShopQuery.isSuccess && !currentShopQuery.data.settings.memberships.enabled,
        membershipsTwitchDiscounts: membershipsEnabled,
        membershipsUpsellingSettings: membershipsEnabled,
        membershipsVideoSubtitles: membershipsEnabled && hasFeatureFlag('memberships_video_subtitles'),
        messages: !isProduction(),
        messagesSendTo: !isProduction(),
        multicurrencyCustomTheme: hasFeatureFlag('multicurrency_custom_theme'),
        multicurrencyCustomThemeYen: hasFeatureFlag('multicurrency_custom_theme_yen'),
        multicurrencySettings: true,
        newPageTemplates: hasFeatureFlag('new_page_templates'),
        partner: hasRole('ROLE_AGENCY') || admin,
        payoutSetup: hasRole('ROLE_PAYOUT_SETUP'),
        productAdvancedOptions: admin,
        roles: !hasFeatureFlag('disabled_user_roles'),
        storeDesignThemeVersion: admin,
        streamElementsGifting: hasFeatureFlag('stream_elements_gifting'),
        thankYousSending: true,
        themeEditorDynamicPreview: hasFeatureFlag('theme_editor:dynamic_preview'),
        themeEditorExperimentalStyles: hasFeatureFlag('theme_editor:experimental_styles'),
        themeEditorInspectorSections: hasFeatureFlag('theme_editor:inspector_sections'),
        themeEditorLogoUpload: hasFeatureFlag('theme_editor:logo_upload'),
        themeEditorNavigations: hasFeatureFlag('theme_editor:navigations'),
    };
    return React.createElement(FeatureFlagsProvider, { featureFlags: featureFlags }, children);
};
