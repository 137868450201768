import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const LinkContainer = styled.div `
  margin-top: 16px;
  background: ${Colors.GS200};
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
`;
export const SlugContainer = styled.span `
  font-weight: 600;
`;
export const InfoContainer = styled.div `
  margin-top: 8px;
  color: ${Colors.GS600};
  font-size: 14px;
  line-height: 21px;
`;
