import React from 'react';
import { usePagination } from '@fourthwall/components';
import { PER_PAGE } from './consts';
import { DonationsView } from './DonationsView';
import { useDonationsQuery } from './queries/useDonationsQuery';
export const DonationsContainer = () => {
    const [currentPage, setCurrentPage] = usePagination();
    const { donationsQuery } = useDonationsQuery([{ page: currentPage - 1, size: PER_PAGE }]);
    const donations = donationsQuery.data?.donations || [];
    const totalPages = donationsQuery.data?.page.totalPages || 0;
    const isFetching = donationsQuery.isInitialLoading;
    return (React.createElement(DonationsView, { donations: donations, currentPage: currentPage, totalPages: totalPages, isFetching: isFetching, onPageChange: setCurrentPage }));
};
