import { mapPaymentMethods } from '../utils';
import { useShopPaymentMethodsQuery } from './useShopPaymentMethodsQuery';
export const useShopPaymentMethods = (options) => {
    const { shopPaymentMethodsQuery, invalidateShopPaymentMethodsQuery } = useShopPaymentMethodsQuery([], options);
    const shopPaymentMethods = shopPaymentMethodsQuery.data
        ? mapPaymentMethods(shopPaymentMethodsQuery.data)
        : undefined;
    const defaultPaymentMethod = shopPaymentMethods?.find((shopPaymentMethod) => shopPaymentMethod.default);
    const hasActivePaymentMethod = defaultPaymentMethod && !defaultPaymentMethod.invalid;
    return {
        shopPaymentMethods,
        defaultPaymentMethod,
        hasActivePaymentMethod,
        isLoadingShopPaymentMethods: shopPaymentMethodsQuery.isLoading,
        isFetchingShopPaymentMethods: shopPaymentMethodsQuery.isFetching,
        invalidateShopPaymentMethods: invalidateShopPaymentMethodsQuery,
    };
};
