import { useRoles } from '@fourthwall/auth';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import isObject from 'lodash-es/isObject';
import { useDashboardPermissionsContext } from './useDashboardPermissionsContext';
export const useDashboardPermissions = () => {
    const { dashboardPermissions } = useDashboardPermissionsContext();
    const { hasRole, hasGroup } = useRoles();
    const hasSinglePermission = (permissionKey) => {
        const permission = get(dashboardPermissions, permissionKey);
        if (isObject(permission)) {
            return Object.keys(permission).some((key) => hasSinglePermission(`${permissionKey}.${key}`));
        }
        return permission;
    };
    const hasPermission = (permissionKey) => {
        if (isArray(permissionKey)) {
            return permissionKey.every((key) => hasSinglePermission(key));
        }
        return hasSinglePermission(permissionKey);
    };
    const hasAnyPermission = () => {
        return Object.keys(dashboardPermissions).some((key) => hasPermission(key));
    };
    return {
        dashboardPermissions,
        hasPermission,
        hasAnyPermission,
        hasRole,
        hasGroup,
    };
};
