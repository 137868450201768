import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const ImageContainer = styled.div `
  position: relative;
  border: 1px solid transparent;
  width: 60px;
  flex-shrink: 0;
  box-sizing: content-box;

  ${({ hasTooltip }) => hasTooltip &&
    css `
      &:hover {
        outline-offset: -1px;
        outline: 1px solid ${Colors.GS1000};
      }
    `}
`;
export const Image = styled.img `
  width: 100%;
  height: 100%;
  background-color: ${Colors.GS200};
  object-fit: contain;
`;
