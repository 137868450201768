import { CloseIcon } from '@fourthwall/icons';
import React, { useEffect } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { ButtonGroup } from '../../common/ButtonGroup';
import { Heading } from '../../common/Heading';
import { Icon } from '../../common/Icon';
import { useFocus } from '../../hooks/useFocus';
import { useMediaQueryContext } from '../../providers/MediaQueryProvider';
import { Skeleton } from '../Skeleton';
import * as S from './ModalBox.styled';
export const ModalBox = ({ title, size = 'medium', maxWidth, width, withoutPadding = false, withoutCloseButton = false, disableScrolling = false, blurOverlay = false, topContent, fullWidth, footer, buttons, buttonsDirection, loading = false, children, onClose, }) => {
    const media = useMediaQueryContext();
    const [closeButtonRef, setCloseButtonFocus] = useFocus();
    useEffect(() => {
        setCloseButtonFocus();
    }, []); // make sure it's an empty array
    if (loading) {
        return (React.createElement(RemoveScroll, null,
            React.createElement(S.Container, { blurOverlay: blurOverlay },
                React.createElement(S.Overlay, { "data-testid": "ModalBox.Overlay", onClick: onClose }),
                React.createElement(ModalBoxSkeleton, { size, maxWidth, width, title, buttons, footer, withoutPadding }))));
    }
    return (React.createElement(RemoveScroll, null,
        React.createElement(S.Container, { blurOverlay: blurOverlay },
            React.createElement(S.Overlay, { "data-testid": "ModalBox.Overlay", onClick: onClose }),
            React.createElement(S.Box, { size: size, maxWidth: maxWidth, width: width, withoutPadding: withoutPadding, disableScrolling: disableScrolling, role: "dialog", "aria-modal": "true", "data-testid": "ModalBox.Content", id: "modal-box" },
                onClose && !withoutCloseButton && (React.createElement(S.CloseButton, { "aria-label": "Close", type: "button", onClick: onClose, ref: closeButtonRef },
                    React.createElement(Icon, { component: CloseIcon, height: media.tablet ? 16 : 12 }))),
                topContent && (React.createElement(S.TopContentContainer, { withoutPadding: withoutPadding }, topContent)),
                title && (React.createElement(S.TitleContainer, null,
                    React.createElement(Heading, { type: media.tablet ? 'h6' : 'h7', weight: "semibold" }, title))),
                React.createElement(S.ChildrenContainer, { disableScrolling: disableScrolling }, children),
                (buttons || footer) && (React.createElement(S.Footer, null,
                    footer && React.createElement(S.FooterContent, null, footer),
                    buttons && (React.createElement(S.ButtonsContent, null,
                        React.createElement(ButtonGroup, { justify: "right", align: "center", space: media.tablet ? 'large' : 'medium', fullWidth: fullWidth || !media.tablet, direction: buttonsDirection || media.tablet ? 'row' : 'column-reverse' }, buttons.map((button, index) => React.cloneElement(button, { key: index, fullWidth: true })))))))))));
};
const ModalBoxSkeleton = ({ size = 'medium', maxWidth, width, title, buttons, footer, withoutPadding, }) => {
    return (React.createElement(S.Box, { size: size, maxWidth: maxWidth, withoutPadding: withoutPadding, width: width, role: "dialog", "aria-modal": "true", "data-testid": "ModalBox.Content", disableScrolling: true, id: "modal-box" },
        title && (React.createElement(S.TitleContainer, null,
            React.createElement(Skeleton, { gap: 12 },
                React.createElement(Skeleton.Shape, { width: "100%", height: "25px" }),
                React.createElement(Skeleton.Shape, { width: "100%", height: "25px" })))),
        React.createElement(S.ChildrenContainer, { disableScrolling: true },
            React.createElement(Skeleton, { gap: 16 },
                React.createElement(Skeleton.Shape, { width: "0", height: "20px" }),
                React.createElement(Skeleton.Shape, { width: "80%", height: "20px" }),
                React.createElement(Skeleton.Shape, { width: "70%", height: "20px" }),
                React.createElement(Skeleton.Shape, { width: "100%", height: "20px" }))),
        (buttons || footer) && (React.createElement(S.Footer, null,
            React.createElement(S.FooterContent, null)))));
};
