import { Icon, Tooltip, useMediaQueryContext } from '@fourthwall/components';
import { QuestionMarkIcon } from '@fourthwall/icons';
import React from 'react';
const getRecordInfoMessage = (alias) => {
    switch (alias) {
        case 'SHOP_IP_ADDRESS':
            return 'This points your store domain to Fourthwall';
        case 'SHOP_WWW_REDIRECT':
            return 'This allows the www address to work as well';
        case 'SENDGRID_MAIL_CNAME':
        case 'SENDGRID_DOMAIN_KEY1':
        case 'SENDGRID_DOMAIN_KEY2':
            return 'Allows for us to send transactional emails from domain';
        case 'ZENDESK_DOMAIN_KEY1':
        case 'ZENDESK_DOMAIN_KEY2':
            return 'Allows for us to send emails under support@ alias';
        case 'ZENDESK_MAIL1':
        case 'ZENDESK_MAIL2':
        case 'ZENDESK_MAIL3':
        case 'ZENDESK_MAIL4':
        case 'ZENDESK_VERIFICATION':
        case 'SPF_RULE':
            return 'Ensures that we can handle all customer support queries';
        case 'DMARC':
            return 'Ensures that no spam comes from your domain';
        case 'IMPROVEMX_MX1':
        case 'IMPROVEMX_MX2':
            return 'Allow us to receive emails under the support@ alias';
        default:
            return '';
    }
};
export const DnsRecordInfoCell = ({ record }) => {
    const media = useMediaQueryContext();
    const infoIcon = React.createElement(Icon, { component: QuestionMarkIcon, height: media.tablet ? 18.75 : 16 });
    return (React.createElement(Tooltip, { reference: infoIcon, placement: "top", fluid: true }, getRecordInfoMessage(record.alias)));
};
