import axios from 'axios';
import { useCurrentShopQuery } from './useCurrentShopQuery';
export const useCurrentShop = (settings) => {
    const { currentShopQuery, invalidateCurrentShopQuery } = useCurrentShopQuery(undefined, settings);
    return {
        currentShopQuery,
        invalidateCurrentShopQuery,
        currentShop: currentShopQuery.data,
        isLoading: currentShopQuery.isLoading,
        isUnauthorized: axios.isAxiosError(currentShopQuery.error) &&
            currentShopQuery.error?.response?.status === 401,
    };
};
