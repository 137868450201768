import React, { useMemo } from 'react';
import { CheckboxIndeterminate, TableSortableUI, TableUI } from '@fourthwall/components';
import unescape from 'lodash-es/unescape';
import { PhotoThumbnail } from '@components/common/PhotoThumbnail';
import { OfferStatuses } from '@modules/Dashboard/Products/modules/OfferStatuses';
import { ProductVariantsTooltipContent } from '@modules/Dashboard/Products/components/ProductVariantsTooltipContent';
import { StockCellContent } from '@components/common/StockCellContent';
import { getDefaultProductImage } from '@utils/products';
import { insertIf } from '@utils/insertIf';
import { Tooltip } from '@components/common/Tooltip';
import { TableCell } from '@components/common/TableCell';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import * as S from '../ProductsData.styled';
export const useProductsTableColumns = ({ onArchiveSelectedOffersClick, onToggleMultipleOfferStatus, }) => {
    const { hasPermission } = useDashboardPermissions();
    return useMemo(() => [
        {
            id: 'select-offers-active',
            header: ({ table }) => (React.createElement(TableUI.HeaderRoot, null,
                React.createElement(TableUI.HeaderSelectionWrapper, { visible: true },
                    React.createElement(CheckboxIndeterminate, { id: "select-all", checked: table.getIsAllRowsSelected(), indeterminate: table.getIsSomeRowsSelected(), onChange: table.getToggleAllRowsSelectedHandler() })),
                React.createElement(TableUI.HeaderActions, null,
                    React.createElement("div", { style: { width: '80px' } },
                        React.createElement(TableUI.HeaderActionsHeading, null,
                            table.getSelectedRowModel().flatRows.length,
                            " selected")),
                    table
                        .getSelectedRowModel()
                        .flatRows.every((row) => row.original.state.status !== 'ARCHIVED') && (React.createElement(TableUI.HeaderActionsItems, null,
                        React.createElement(TableUI.HeaderActionsItem, { onClick: () => onArchiveSelectedOffersClick(table.getSelectedRowModel().flatRows.map((row) => row.original.id)) }, "Archive"),
                        React.createElement(TableUI.HeaderActionsItem, { onClick: () => onToggleMultipleOfferStatus(table.getSelectedRowModel().flatRows.map((row) => row.original.id)) }, "Change status")))))),
            columns: [
                {
                    id: 'row-left-side-placeholder',
                    header: ({ table }) => (React.createElement(TableUI.HeaderSelectionWrapper, { visible: table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() },
                        React.createElement(CheckboxIndeterminate, { id: "select-all", checked: table.getIsAllRowsSelected(), indeterminate: table.getIsSomeRowsSelected(), onChange: table.getToggleAllRowsSelectedHandler() }))),
                    cell: ({ table, row }) => {
                        return (React.createElement(React.Fragment, null,
                            table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() ? null : (React.createElement(TableSortableUI.RowDragHandle, null)),
                            React.createElement(TableUI.RowSelection, { visible: table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() },
                                React.createElement(CheckboxIndeterminate, { checked: row.getIsSelected(), disabled: !row.getCanSelect(), indeterminate: row.getIsSomeSelected(), onChange: row.getToggleSelectedHandler() }))));
                    },
                    meta: {
                        width: '0px',
                        height: '100%',
                        position: 'relative',
                    },
                },
                {
                    id: 'photo',
                    header: () => React.createElement(TableUI.HeaderHeading, null, "Product"),
                    accessorFn: (offer) => offer,
                    cell: (info) => {
                        const offer = info.getValue();
                        const url = getDefaultProductImage(offer);
                        return (React.createElement(TableUI.CellItemPositioner, { position: "start", width: "60px", height: "80px" },
                            React.createElement(PhotoThumbnail, { url: url, size: "large" })));
                    },
                    meta: {
                        width: '60px',
                        textAlign: 'left',
                    },
                },
                {
                    id: 'name',
                    header: () => null,
                    accessorKey: 'name',
                    cell: (info) => {
                        const name = info.getValue();
                        return React.createElement(S.ProductName, null, unescape(name));
                    },
                    meta: {
                        textAlign: 'left',
                        width: '650px',
                    },
                },
                {
                    id: 'status',
                    header: () => React.createElement(TableUI.HeaderHeading, null, "Status"),
                    accessorFn: (offer) => offer,
                    cell: (info) => {
                        const offer = info.getValue();
                        return (React.createElement(S.ProductStatus, null,
                            React.createElement(OfferStatuses, { offer: offer })));
                    },
                    meta: {
                        textAlign: 'left',
                        width: '200px',
                    },
                },
                ...insertIf(hasPermission('contributions.profit'), {
                    id: 'sold',
                    header: () => React.createElement(TableUI.HeaderHeading, null, "Sold"),
                    accessorKey: 'sold',
                    // @ts-ignore
                    cell: (info) => React.createElement(S.ProductSold, null, info.getValue()),
                    meta: {
                        textAlign: 'left',
                        width: '150px',
                    },
                }),
                {
                    id: 'inventory',
                    header: React.createElement(TableUI.HeaderHeading, null, "In stock"),
                    accessorFn: (offer) => offer,
                    cell: (info) => {
                        const { variants, state, manufacturingService } = info.getValue();
                        const getAdditionalContent = () => {
                            if (state && !state?.available) {
                                return 'Marked as sold out';
                            }
                            if (manufacturingService !== 'MANUFACTURED_BY_CREATOR' || variants.length <= 1) {
                                return null;
                            }
                            return (React.createElement(Tooltip, { size: "small", content: React.createElement(ProductVariantsTooltipContent, { variants: variants }), placement: "bottom-end" },
                                variants.length,
                                " variants"));
                        };
                        return (React.createElement(S.ProductInventory, null,
                            React.createElement(TableCell, { mainContent: React.createElement(StockCellContent, { variants: variants }), additionalContent: getAdditionalContent() })));
                    },
                    meta: {
                        textAlign: 'left',
                        width: '180px',
                    },
                },
            ],
            meta: {
                textAlign: 'left',
            },
        },
    ], []);
};
