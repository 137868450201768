export const FormFields = {
    name: 'name',
    customerSupportEmail: 'customerSupportEmail',
    creatorName: 'creatorName',
    description: 'description',
    themeAutoUpdateHour: 'themeAutoUpdateHour',
    socialImage: 'socialImage',
    socialImageType: 'socialImageType',
    cookiePolicy: 'cookiePolicy',
};
