import React, { createContext, useMemo, useState } from 'react';
export const ThemeEditorContext = createContext(undefined);
export const ThemeEditorProvider = ({ children }) => {
    const [previewMode, setPreviewMode] = useState('desktop');
    const [previewTimestamp, setPreviewTimestamp] = useState(new Date().valueOf());
    const [isPreviewLoading, setIsPreviewLoading] = useState(true);
    const [currentPageId, setCurrentPageId] = useState('index');
    const [currentSectionKey, setCurrentSectionKey] = useState();
    const [currentSectionId, setCurrentSectionId] = useState();
    const resetPreviewTimestamp = () => {
        setPreviewTimestamp(new Date().valueOf());
        setIsPreviewLoading(false);
    };
    const handleSetCurrentPageId = (pageId) => {
        setCurrentPageId(pageId);
    };
    const value = useMemo(() => ({
        previewMode,
        previewTimestamp,
        isPreviewLoading,
        currentPageId,
        currentSectionKey,
        currentSectionId,
        setPreviewMode,
        setPreviewTimestamp,
        resetPreviewTimestamp,
        setIsPreviewLoading,
        setCurrentPageId: handleSetCurrentPageId,
        setCurrentSectionKey,
        setCurrentSectionId,
    }), [
        currentPageId,
        currentSectionId,
        currentSectionKey,
        isPreviewLoading,
        previewMode,
        previewTimestamp,
    ]);
    return React.createElement(ThemeEditorContext.Provider, { value: value }, children);
};
