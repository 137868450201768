import React, { useState } from 'react';
import { Button, ModalBox } from '@fourthwall/components';
import * as S from './ChangeProductionMethodModal.styled';
export const ChangeProductionMethodModal = ({ initialOption, options, onConfirm, onClose, }) => {
    const [selectedOption, setSelectedOption] = useState(initialOption);
    const handleConfirm = () => {
        if (selectedOption.id !== initialOption.id) {
            onConfirm?.(selectedOption);
        }
        onClose?.();
    };
    return (React.createElement(ModalBox, { size: "large", title: "Choose on demand print method", onClose: onClose, buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: onClose }),
            React.createElement(Button, { label: "Confirm", appearance: "primary", onClick: handleConfirm }),
        ] },
        React.createElement(S.Lead, null, "This action will result in creating a duplicate of your product with a selected production method."),
        React.createElement(S.Container, null, options.map((option) => (React.createElement(S.Column, { key: option.id, "$active": selectedOption.id === option.id, onClick: () => {
                setSelectedOption(option);
            } },
            React.createElement(S.Heading, null, option.productionMethod.label),
            React.createElement(S.Content, null, option.productionMethod.advantages && (React.createElement("ul", null, option.productionMethod.advantages.map((advantage) => (React.createElement("li", { key: advantage }, advantage))))))))))));
};
