import { usePagination, Pagination, Table } from '@fourthwall/components';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dashboard } from '../../../../../../../components/common/Dashboard';
import { TableCell } from '../../../../../../../components/common/TableCell';
import { CreatorMessagesEmptyState } from './components/Empty/CreatorMessagesEmptyState';
import { Message } from './components/Message/Message';
import { ThumbnailComponent } from './components/Thumbnail/Thumbnail';
import { getTableRowLink } from './utils/getTableRowLink';
export const TABLE_HEADERS = [
    {
        id: 'date',
        label: 'Date',
        render: ({ date }) => React.createElement(TableCell, { mainContent: date }),
        width: 1,
    },
    {
        id: 'thumbnail',
        label: '',
        render: ({ thumbnail }) => (React.createElement(TableCell, { mainContent: React.createElement(ThumbnailComponent, { type: thumbnail.type, thumbnail: thumbnail.thumbnail }) })),
        width: 1,
    },
    {
        id: 'recipient',
        label: 'Recipients',
        render: ({ recipient }) => React.createElement(TableCell, { mainContent: recipient }),
        width: 1.5,
    },
    {
        id: 'message',
        label: 'Message',
        render: ({ messageSubject, messageBody }) => (React.createElement(TableCell, { mainContent: React.createElement(Message, { subject: messageSubject, body: messageBody }) })),
        width: 8,
    },
];
export const CreatorMessagesListView = ({ vm: { creatorMessages, page, isFetching = false, emptyStateViewModel }, }) => {
    const [current, setCurrentPage] = usePagination();
    if (!creatorMessages) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Dashboard.Section.Item, null,
            React.createElement(Dashboard.Section.ScrollableContent, null,
                React.createElement(Table, { data: creatorMessages, headers: TABLE_HEADERS, rowComponent: Link, getRowLink: getTableRowLink, isLoading: isFetching, emptyComponent: React.createElement(CreatorMessagesEmptyState, { vm: emptyStateViewModel }) }))),
        page.showPagination && (React.createElement(Dashboard.Section.Item, null,
            React.createElement(Pagination, { pageCount: page.totalPages, currentPage: current, onChange: setCurrentPage })))));
};
