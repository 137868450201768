import styled from 'styled-components';
import { Colors, mediaQueries } from '@fourthwall/components';
export const HelperText = styled.div `
  margin-top: 4px;

  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${Colors.GS600};
`;
export const AdditionalElement = styled.div `
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
`;
export const OptionLabel = styled.span `
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.GS1000};

  ${mediaQueries.tablet} {
    display: flex;
    justify-content: space-between;
    width: 526px;
  }
`;
export const OptionLabelExtra = styled.span `
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GS600};
`;
