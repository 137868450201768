import styled from 'styled-components';
import { Colors } from '../../colors';
export const Container = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const ImageContainer = styled.div `
  flex-shrink: 0;
`;
export const Content = styled.div `
  overflow: hidden;
`;
export const TopContainer = styled.div `
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${Colors.GS1000};
`;
export const BottomContainer = styled.div `
  min-height: 21px; // in order to support empty string correctly

  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${Colors.GS600};

  a,
  button {
    text-decoration: underline;

    &:hover {
      color: ${Colors.B500};
    }
  }
`;
