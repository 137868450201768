export const getProfit = (data) => {
    if (!data)
        return;
    return data.status === 'DASHBOARD_STATISTICS' ? data.profit.amount.value : 0;
};
export const getOrders = (data) => {
    return data ? data.page.elementsTotal : undefined;
};
export const getShopStageObject = (profit, orders) => {
    if (profit == undefined || orders == undefined) {
        return { currentStage: undefined, stage1: false, stage2: false, stage3: false, stage4: false };
    }
    const stage1 = true;
    const stage2 = stage1 && orders >= 3;
    const stage3 = stage2 && profit >= 100;
    const stage4 = stage3 && profit >= 1_000;
    const currentStage = (() => {
        if (stage4)
            return '4';
        if (stage3)
            return '3';
        if (stage2)
            return '2';
        if (stage1)
            return '1';
        return undefined;
    })();
    return { currentStage, stage1, stage2, stage3, stage4 };
};
