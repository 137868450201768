import { Button, ButtonGroup, Colorway, Heading, Icon, ModalBox, useMediaQueryContext, } from '@fourthwall/components';
import { ArrowLongLeftIcon, CloseIcon, DesktopIcon, MobileIcon } from '@fourthwall/icons';
import React, { useEffect, useState } from 'react';
import { IconSwitch } from '../../../../components/IconSwitch';
import { ThemePreview } from '../../../../components/ThemePreview';
import { ThemeCard } from './components/ThemeCard';
import { Toolbar } from './components/Toolbar';
import * as S from './styled';
import { getSelectedPreset } from './utils';
export const ThemeModalView = ({ title, toolbar, header, buttonsProps, themeCardProps, preview, onPreviewClose, onModalClose, }) => {
    const media = useMediaQueryContext();
    const [previewMode, setPreviewMode] = useState('desktop');
    const iconSize = media.laptop ? 16 : 14;
    useEffect(() => {
        if (media.mobileM && !media.tablet && previewMode !== 'mobile') {
            setPreviewMode('mobile');
        }
    }, [media]);
    const renderPreview = () => {
        if (!preview)
            return null;
        const selectedPreset = getSelectedPreset(preview.theme);
        const { primaryButtonProps, secondaryButtonProps } = preview.toolbar;
        return (React.createElement(React.Fragment, null,
            React.createElement(Toolbar, { leftContent: React.createElement(S.PreviewHeaderLeftSide, null,
                    React.createElement("button", { title: "Back", type: "button", onClick: onPreviewClose },
                        React.createElement(Icon, { component: ArrowLongLeftIcon, height: iconSize })),
                    media.tablet && preview.theme.name), centerContent: React.createElement(S.PreviewToolsContainer, null,
                    media.tablet && (React.createElement(IconSwitch, { items: [
                            { label: 'Desktop', value: 'desktop', icon: DesktopIcon },
                            { label: 'Mobile', value: 'mobile', icon: MobileIcon },
                        ], value: previewMode, onChange: (previewMode) => setPreviewMode(previewMode) })),
                    React.createElement(Colorway, { size: media.tablet ? 'medium' : 'small', ...preview.toolbar.colorwayProps })), rightContent: React.createElement(ButtonGroup, { space: "medium" },
                    secondaryButtonProps && media.laptop && (React.createElement(Button, { size: "small", label: secondaryButtonProps.label, onClick: secondaryButtonProps.onClick })),
                    primaryButtonProps && (React.createElement(Button, { appearance: "primary", size: "small", label: !media.tablet && primaryButtonProps.mobileLabel
                            ? primaryButtonProps.mobileLabel
                            : primaryButtonProps.label, onClick: primaryButtonProps.onClick })),
                    onModalClose && (React.createElement(S.CloseButtonWrapper, null,
                        React.createElement("button", { title: "Close", type: "button", onClick: onModalClose },
                            React.createElement(Icon, { component: CloseIcon, height: iconSize }))))) }),
            React.createElement(S.PreviewContainer, null,
                React.createElement(ThemePreview, { previewMode: previewMode, shopUrl: selectedPreset?.previewUrl || '' }))));
    };
    const renderToolbar = () => {
        if (toolbar)
            return toolbar;
        return (React.createElement(Toolbar, { leftContent: title && (React.createElement(Heading, { type: "h7", weight: "semibold" }, title)), rightContent: React.createElement(React.Fragment, null,
                buttonsProps && (React.createElement(ButtonGroup, { space: "medium" }, buttonsProps.map((props) => (React.createElement(Button, { ...props }))))),
                onModalClose && (React.createElement(S.CloseButtonWrapper, null,
                    React.createElement("button", { title: "Close", type: "button", onClick: onModalClose },
                        React.createElement(Icon, { component: CloseIcon, height: iconSize }))))) }));
    };
    const renderThemesGrid = () => {
        if (preview)
            return null;
        return (React.createElement(React.Fragment, null,
            renderToolbar(),
            media.laptop && header,
            React.createElement(S.ScrollableContainer, null,
                !media.laptop && header,
                React.createElement(S.ThemesGrid, null, themeCardProps.map((themeCardProps, index) => (React.createElement(ThemeCard, { key: index, ...themeCardProps })))))));
    };
    return (React.createElement(ModalBox, { withoutPadding: true, disableScrolling: true, size: "fullScreen" },
        React.createElement(S.Container, null,
            renderThemesGrid(),
            renderPreview())));
};
