import { useThirdPartyLogisticsOptionsQuery } from '@modules/Dashboard/hooks/useThirdPartyLogisticsOptionsQuery';
import { ButtonClean, Icon, Loader, PriceString, Tooltip } from '@fourthwall/components';
import React from 'react';
import { QuestionMarkIcon } from '@fourthwall/icons';
import * as S from './styled';
export const AssistedShippingContent = () => {
    const { thirdPartyLogisticsOptionsQuery } = useThirdPartyLogisticsOptionsQuery();
    if (!thirdPartyLogisticsOptionsQuery.data) {
        return (React.createElement(S.LoaderWrapper, null,
            React.createElement(Loader, { size: "medium" })));
    }
    const { minimumStorageFee, shippingFirstItemFee, shippingNextItemFee } = thirdPartyLogisticsOptionsQuery.data;
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Heading, null, "I want Fourthwall to ship orders"),
        React.createElement(S.Content, null,
            React.createElement("p", null,
                "Send your products to us in bulk and we\u2019ll ship orders and handle customer support for you.",
                ' ',
                React.createElement(ButtonClean, { label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/13331284092187-Fourthwall-fulfillment-service-guide", target: "_blank" })),
            React.createElement("div", null,
                React.createElement("strong", null, "Pricing")),
            React.createElement(S.List, null,
                React.createElement("li", null,
                    React.createElement("strong", null, "When we ship an order:"),
                    React.createElement("br", null),
                    React.createElement(PriceString, { value: shippingFirstItemFee.value, currency: shippingFirstItemFee.currency }),
                    ' ',
                    "for the first item +",
                    ' ',
                    React.createElement(PriceString, { value: shippingNextItemFee.value, currency: shippingNextItemFee.currency }),
                    ' ',
                    "for each additional item in the shipment"),
                React.createElement("li", null,
                    React.createElement("strong", null, "Storage fees:"),
                    React.createElement("br", null),
                    React.createElement(S.WithTooltipSpan, null,
                        "From",
                        ' ',
                        React.createElement(PriceString, { value: minimumStorageFee.value, currency: minimumStorageFee.currency }),
                        "/month per item",
                        ' ',
                        React.createElement(Tooltip, { placement: "top", reference: React.createElement(Icon, { component: QuestionMarkIcon, height: 12, width: 12 }) },
                            React.createElement("div", null,
                                "Storage fees range from\u00A0",
                                React.createElement(PriceString, { value: minimumStorageFee.value, currency: minimumStorageFee.currency, minimumFractionDigits: 2 }),
                                "-$1.00/month per item based on the size of the item being stored. As an example, a t-shirt is $0.45/month to store."))))))));
};
