import { ApiBase } from './ApiBase';
class MetaPartnerProductsApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getMetaPartnerProducts = () => {
            return this.get('/creator-integrations/meta-partner/products');
        };
    }
}
export const MetaPartnerProductsApiClient = new MetaPartnerProductsApiClientModel();
