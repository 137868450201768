import { Icon } from '@fourthwall/components';
import React from 'react';
import * as S from './IconSwitch.styled';
export const IconSwitch = ({ items, value, onChange }) => {
    if (items.length === 0)
        return null;
    const renderItem = ({ label, value: itemValue, icon }) => {
        const isActive = itemValue === value;
        const handleClick = () => {
            if (isActive)
                return;
            onChange(itemValue);
        };
        return (React.createElement(S.ItemContainer, { isActive: isActive, key: itemValue, type: "button", onClick: handleClick },
            React.createElement(Icon, { component: icon, height: 16, alt: label })));
    };
    return React.createElement(S.Container, null, items.map(renderItem));
};
