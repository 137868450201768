import { useMemo } from 'react';
import { useSamplesOffersQuery } from './useSamplesOffersQuery';
import { filterOffersWithVariants } from './utils';
export const useSamplesOffers = (options) => {
    const { offersQuery } = useSamplesOffersQuery(options);
    const offers = useMemo(() => filterOffersWithVariants(offersQuery.data?.offers || []), [offersQuery.data]);
    return {
        samplesOffers: {
            offers,
            enabled: !!offers.length,
            isLoading: offersQuery.isLoading,
        },
    };
};
