import { Toasts } from '@fourthwall/components';
import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { errorMessageSelector } from '@fourthwall/utils/lib/selectors';
import { useMutation } from '@tanstack/react-query';
export const useUpdateAccountSettingsTiersMutation = () => {
    const updateAccountSettingsTiersMutation = useMutation({
        mutationFn: async (payload) => {
            const { data } = await MembershipsCreatorApiClient.updateAccountSettingsTiers(payload);
            return data;
        },
        onError: (error) => {
            const errorMessage = errorMessageSelector(error.response?.data);
            Toasts.notify(errorMessage, { type: 'error' });
        },
    });
    return { updateAccountSettingsTiersMutation };
};
