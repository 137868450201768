import { Fieldset, Input, Subsection } from '@fourthwall/components';
import { Field } from 'formik';
import React from 'react';
import { FormFields } from '../../types';
export const CreatorDetails = () => (React.createElement(Subsection, { title: "Creator details" },
    React.createElement(Fieldset, null,
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { name: FormFields.creatorName, component: Input, label: "Creator name", suggestion: "Supporters will see your creator name during checkout and on marketing emails.", required: true, fullWidth: true })),
        React.createElement(Fieldset.Row, null,
            React.createElement(Field, { name: FormFields.customerSupportEmail, component: Input, label: "Customer support email address", suggestion: "Email address where we\u2019ll forward emails we\u2019re unable to answer for you (e.g., a sponsorship offer or a question about a product you\u2019re shipping)", required: true, fullWidth: true })))));
