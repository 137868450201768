import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Variant = styled.div `
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${Colors.GS500};

  ${mediaQueries.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;
export const Label = styled.div `
  flex: 1;
  margin-bottom: 16px;

  ${mediaQueries.tablet} {
    margin-bottom: 0;
  }
`;
