import { ConfirmModal, useModalContext } from '@fourthwall/components';
import { reverse } from 'named-urls';
import { useMatch, useNavigate } from 'react-router-dom';
import { routing } from '../../../utils/routing';
import { useThemeLivePreviewContext } from '../modules/LivePreview/providers/ThemeLivePreviewProvider';
import { useActiveThemeContext } from '../providers/ActiveThemeProvider';
import { usePageLayoutContext } from '../providers/PageLayoutProvider';
import { useCurrentShopContext } from './useCurrentShopContext';
import { useRemoveSectionMutation } from './useRemoveSectionMutation';
import { useThemeEditorContext } from './useThemeEditorContext';
export const useRemoveSection = () => {
    const { currentPageId } = useThemeEditorContext();
    const { currentShop } = useCurrentShopContext();
    const { pageLayoutQuery } = usePageLayoutContext();
    const { activeTheme } = useActiveThemeContext();
    const { channel, setInspectorVisible } = useThemeLivePreviewContext();
    const { removeLayoutSectionMutation } = useRemoveSectionMutation();
    const matchesSectionPage = useMatch(routing.themeEditor.layout.page.sections);
    const navigate = useNavigate();
    const { open } = useModalContext();
    const removeSection = (sectionKey) => {
        const section = pageLayoutQuery.data?.sections.blocks
            .flatMap((block) => block.sections)
            .find((section) => section.section_key === sectionKey);
        setInspectorVisible(false);
        if (!section || !currentPageId || !sectionKey) {
            throw new Error('removeSection: Missing section or current page ID');
        }
        open(ConfirmModal, {
            title: 'Are you sure you want to delete this section?',
            confirmLabel: 'Delete',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                setInspectorVisible(false);
                channel.emit({
                    action: 'ThemePreview:RemoveSection',
                    payload: { id: sectionKey },
                });
                removeLayoutSectionMutation.mutate([
                    {
                        shop_id: currentShop.id,
                        theme_id: activeTheme.id,
                        section_id: section.id,
                    },
                ], {
                    onSuccess: () => {
                        pageLayoutQuery.refetch();
                        if (matchesSectionPage) {
                            navigate(reverse(routing.themeEditor.layout.page.self, { pageId: currentPageId }));
                        }
                    },
                });
            },
        });
    };
    return {
        removeSection,
    };
};
