import { ConfirmModal, useModalContext } from '@fourthwall/components';
import { useTagManager } from '@hooks';
import { useDeleteBeehiivConfigurationMutation } from './useDeleteBeehiivConfigurationMutation';
import { useUpdateBeehiivConfigurationMutation } from './useUpdateBeehiivConfigurationMutation';
import { useBeehiivConfigurationQuery } from './useBeehiivConfigurationQuery';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
export const useBeehiiv = () => {
    const { open } = useModalContext();
    const { beehiivConfigurationQuery, invalidateBeehiivConfigurationQuery } = useBeehiivConfigurationQuery();
    const { updateBeehiivConfigurationMutation } = useUpdateBeehiivConfigurationMutation();
    const { deleteBeehiivConfigurationMutation } = useDeleteBeehiivConfigurationMutation();
    const tagManager = useTagManager();
    const appConnectEvent = useAppConnectEvent('beehiiv');
    const handleSubmit = async (values) => {
        appConnectEvent.trigger();
        return await updateBeehiivConfigurationMutation.mutateAsync([values], {
            onSuccess: () => {
                invalidateBeehiivConfigurationQuery();
                tagManager.trigger('app_connected', { app_name: 'beehiiv' });
            },
        });
    };
    const handleDisconnect = () => {
        open(ConfirmModal, {
            title: 'Are you sure you want to disconnect your Beehiiv account?',
            confirmAppearance: 'destructive',
            onConfirm: () => {
                deleteBeehiivConfigurationMutation.mutate([], {
                    onSuccess: () => {
                        invalidateBeehiivConfigurationQuery();
                    },
                });
            },
        });
    };
    return {
        beehiiv: {
            connection: {
                initialValues: {
                    apiKey: '',
                    publicationId: '',
                },
                onSubmit: handleSubmit,
            },
            status: beehiivConfigurationQuery.data?.hasApiKey,
            isLoading: beehiivConfigurationQuery.isLoading,
            onDisconnect: handleDisconnect,
        },
    };
};
