import React, { useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { DragIcon } from '@fourthwall/icons';
import { Icon } from '../../Icon';
import { TableOptionsCell } from '../TableOptionsCell';
import * as S from './styled';
const DragHandle = SortableHandle(() => (React.createElement(S.DragHandle, { "data-testid": "TableRow.DragHandle" },
    React.createElement(Icon, { component: DragIcon, height: 16 }))));
export const TableRow = ({ component, row, actions, to, href, target, withDragHandle, highlighted, children, renderFooter, onClick, }) => {
    const [isHover, setIsHover] = useState(false);
    const [dropdownKey, setDropdownKey] = useState(0);
    return (React.createElement(S.Wrapper, null,
        React.createElement(S.Container, null,
            React.createElement(S.Row, { as: component || 'div', to: to, href: href, target: target, role: "row", hoverable: to || href || onClick, "$highlighted": highlighted, onMouseEnter: () => setIsHover(true), onMouseLeave: () => {
                    setIsHover(false);
                    setDropdownKey((key) => key + 1);
                }, "data-testid": "TableRow" },
                withDragHandle && React.createElement(DragHandle, null),
                React.createElement(S.ChildrenContainer, null,
                    React.createElement(S.Children, { onClick: onClick }, children),
                    renderFooter && React.createElement(S.Footer, null, renderFooter(row, { isHover }))),
                actions && (React.createElement(S.Options, null,
                    React.createElement(TableOptionsCell, { dropdownKey: dropdownKey, actions: actions, row: row })))))));
};
