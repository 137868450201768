import { useAnalyticsChartQuery, useMembershipsChartQuery, useMembershipsTopTiersTableQuery, useSaleByCountryFullTableQuery, useTopOfferFullTableQuery, } from '@fourthwall/module-analytics';
import { downloadCsvFile } from '@fourthwall/utils/lib/downloadCsvFile';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { routing } from '../../../../../../../utils/routing';
import { useCurrentShop } from '../../../../../hooks/useCurrentShop';
import { useCurrentShopQuery } from '../../../../../hooks/useCurrentShopQuery';
import { useMembershipAccountQuery } from '../../../../../hooks/useMembershipAccountQuery';
import { REPORT_TYPES, title } from '../../../../consts';
import { useReportType } from '../../../../hooks/useReportType';
import { createChart, createStatsTable, getCsvData } from './utils';
export const useAnalyticsReport = (params) => {
    const { currentShopQuery } = useCurrentShopQuery();
    const { reportType } = useReportType();
    const location = useLocation();
    const { endpoint, formatCsv } = REPORT_TYPES[reportType];
    const { membershipAccountQuery } = useMembershipAccountQuery();
    const { currentShop } = useCurrentShop({ staleTime: Infinity });
    const { analyticsChartQuery } = useAnalyticsChartQuery([
        {
            ...params,
            chartTypes: [
                'CONTRIBUTION',
                'CONTRIBUTION_AVG',
                'CONTRIBUTION_TOTAL', // Contributions (Money)
                'CONTRIBUTION_ORDERS', // Contributions (number)
                'CONTRIBUTION_DONATIONS', // Contributions (number)
                'CONTRIBUTION_GIVEAWAY', // Contributions (number)
                // 'CONTRIBUTION_GIFTS_FROM_GIVEAWAY', // Contributions (number)
                'CONTRIBUTION_GIFTS_ON_CREATOR_COST', // Contributions (number)
                'CONTRIBUTION_SUBSCRIPTIONS', // Contributions (number)
                'CONTRIBUTION_MEMBERSHIP_TIPS',
                'CONTRIBUTION_MEMBERSHIP_LOCKED_MESSAGES',
                'PROFIT',
                // 'PROFIT_ORDERS', // Contributions (Money)
                // 'PROFIT_DONATIONS', // Contributions (Money)
                // 'PROFIT_GIFTS_FROM_GIVEAWAY', // Contributions (Money)
                // 'PROFIT_GIFTS_ON_CREATOR_COST', // Contributions (Money)
                // 'PROFIT_SUBSCRIPTIONS', // Contributions (Money)
                'PROFIT_MEMBERSHIP_TIPS',
                'PROFIT_MEMBERSHIP_LOCKED_MESSAGES',
                'GROSS_SALES',
                'INCOME',
                'COST',
                'CANCELLATIONS',
                'REFUNDS',
                'DISCOUNTS',
                'AVERAGE_SALES', // Average order value
                'AVERAGE_PROFIT', // Average order value
            ],
            sid: [currentShop?.id],
        },
    ], {
        keepPreviousData: true,
        enabled: endpoint === 'chart',
    });
    const { membershipsChartQuery } = useMembershipsChartQuery([
        {
            ...params,
            chartTypes: [
                'MEMBERS_TOTAL',
                'FREE_ACCOUNTS_TOTAL',
                'MEMBERS_CHANGE',
                'FREE_ACCOUNTS_CHANGE',
            ],
            sid: [currentShop?.id],
        },
    ], {
        keepPreviousData: true,
        enabled: endpoint === 'membership-chart' && !!currentShopQuery.data?.settings.memberships?.enabled,
    });
    const { topOfferFullTableQuery } = useTopOfferFullTableQuery([{ ...params, sid: [currentShop?.id] }], {
        keepPreviousData: true,
        enabled: endpoint === 'top-offer',
    });
    const { saleByCountryFullTableQuery } = useSaleByCountryFullTableQuery([{ ...params, sid: [currentShop?.id] }], {
        keepPreviousData: true,
        enabled: endpoint === 'top-sale-by-country',
    });
    const { membershipsTopTiersTableQuery } = useMembershipsTopTiersTableQuery([{ ...params, sid: [currentShop?.id] }], {
        enabled: !!currentShopQuery.data?.settings.memberships?.enabled,
    });
    const backButtonTo = {
        pathname: routing.analytics.self,
        search: location.search,
    };
    const chart = useMemo(() => createChart(analyticsChartQuery, membershipsChartQuery, params, reportType), [analyticsChartQuery, membershipsChartQuery, params, reportType]);
    const statsTable = useMemo(() => createStatsTable(reportType, params, analyticsChartQuery, membershipsChartQuery, topOfferFullTableQuery, saleByCountryFullTableQuery, membershipsTopTiersTableQuery, membershipAccountQuery), [
        reportType,
        params,
        analyticsChartQuery,
        membershipsChartQuery,
        topOfferFullTableQuery,
        saleByCountryFullTableQuery,
        membershipsTopTiersTableQuery,
        membershipAccountQuery,
    ]);
    const getExportButton = () => {
        const formattedData = formatCsv ? formatCsv(statsTable.data) : statsTable.data;
        const data = getCsvData(formattedData);
        return {
            onConfirm: () => downloadCsvFile(data, title.toLocaleLowerCase()),
        };
    };
    return {
        title: REPORT_TYPES[reportType].title,
        backButtonLabel: title,
        backButtonTo,
        exportButton: getExportButton(),
        chart,
        statsTable,
    };
};
