export const getUpdateDirectMessagesPayload = (formValues) => {
    const { messagesEnabled, tipsEnabled, minTipEnabled, minTipAmount } = formValues;
    if (!messagesEnabled) {
        return {
            directMessages: null,
        };
    }
    if (!tipsEnabled) {
        return {
            directMessages: {
                tips: {
                    type: 'Disable',
                },
            },
        };
    }
    if (tipsEnabled && !minTipEnabled) {
        return {
            directMessages: {
                tips: {
                    type: 'Enable',
                },
            },
        };
    }
    if (tipsEnabled && minTipEnabled && minTipAmount) {
        return {
            directMessages: {
                tips: {
                    type: 'Enable',
                    minimumAmount: minTipAmount * 100,
                },
            },
        };
    }
    return {};
};
