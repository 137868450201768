import { BackButton, Button, Checkbox, Division, HeadContent, Heading, Loading, PriceString, ProductsList, Select, Summary, } from '@fourthwall/components';
import isUndefined from 'lodash-es/isUndefined';
import range from 'lodash-es/range';
import { reverse } from 'named-urls';
import React from 'react';
import { Dashboard } from '../../../../../../components/common/Dashboard';
import { PageLayout } from '../../../../../../components/common/PageLayout';
import { SideSummary } from '../../../../../../components/common/SideSummary';
import { routing } from '../../../../../../utils/routing';
import { getVariantsString } from '../../../../../../utils/variants';
import { getNonCancellableReason } from '../utils';
import * as S from './OrderCancelView.styled';
export const OrderCancelView = ({ orderId, friendlyId = '', cancelableOffers, nonCancellableOffers, promotion, donation, subtotal, shipping, taxes, discount, refundTotal, isCancelButtonDisabled, shouldRestock, isLoading, onOrderCancel, onOfferSelect, onRestockChange, onOfferSelectedQuantityChange, onSelectAll, }) => {
    const selectedOffers = cancelableOffers?.filter((offer) => offer.selected);
    const selectedOffersCount = selectedOffers?.reduce((prev, selectedOffer) => prev + selectedOffer.selectedQuantity, 0);
    const areAllOffersSelected = selectedOffers?.length === cancelableOffers?.length;
    const getSelectedQuantityOptions = (quantity) => {
        return range(1, quantity + 1).map((number) => ({
            label: number.toString(),
            value: number.toString(),
        }));
    };
    const renderPromotionDescription = () => {
        if (promotion?.type.type === 'FixedAmount') {
            return (React.createElement(React.Fragment, null,
                React.createElement(PriceString, { value: promotion.type.money.value }),
                " off"));
        }
        if (promotion?.type.type === 'Percentage') {
            return `${promotion.type.percentage}% off entire order`;
        }
        if (promotion?.type.type === 'FreeShipping') {
            return 'Free shipping';
        }
        return '';
    };
    const summaryContent = (React.createElement(React.Fragment, null,
        !isUndefined(subtotal) && selectedOffers && (React.createElement(Summary.Row, { label: `Subtotal (${selectedOffersCount} items)`, value: subtotal })),
        !isUndefined(shipping) && React.createElement(Summary.Row, { label: "Shipping", value: shipping }),
        !isUndefined(taxes) && taxes?.value > 0 && React.createElement(Summary.Row, { label: "Sales tax", value: taxes }),
        !isUndefined(discount) && discount?.value > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Summary.Row, { label: "Discount", value: {
                    value: discount.value * -1,
                    currency: discount.currency,
                } }),
            React.createElement(S.SummarySubsection, null,
                promotion && React.createElement("div", null, promotion.isAutoApplied ? promotion.title : promotion.code),
                promotion && React.createElement("div", null, renderPromotionDescription())))),
        !isUndefined(refundTotal) && (React.createElement(React.Fragment, null,
            React.createElement(S.SummaryDivider, null),
            React.createElement(Summary.Row, { label: "Refund amount", value: refundTotal, bold: true })))));
    const renderOffer = (orderOffer, editable) => {
        const { variant, offer, quantity, price } = orderOffer;
        const isSelectable = (o) => {
            return o.selected !== undefined;
        };
        return (React.createElement(Division, { size: "small", key: offer.id },
            React.createElement(S.ProductItemContainer, { "data-testid": `OrderCancelView.OfferSection-${offer.id}` },
                editable && isSelectable(orderOffer) && (React.createElement(S.ProductCheckoutContainer, null,
                    React.createElement(Checkbox, { value: orderOffer.selected, onValueChange: (value) => onOfferSelect(orderOffer, value) }))),
                React.createElement(S.ProductDetailsContainer, null,
                    React.createElement(ProductsList.Item, { name: offer.name, description: getVariantsString(variant), tag: !isSelectable(orderOffer)
                            ? getNonCancellableReason(orderOffer.nonCancellableReason)
                            : undefined, image: variant?.images[0]?.url })),
                React.createElement(S.ProductQuantityContainer, null,
                    editable && isSelectable(orderOffer) && quantity > 1 && (React.createElement(Select, { size: "small", label: "Quantity", options: getSelectedQuantityOptions(quantity), value: orderOffer.selectedQuantity.toString(), onValueChange: (value) => onOfferSelectedQuantityChange(orderOffer, parseInt(value)) })),
                    isSelectable(orderOffer) && (quantity <= 1 || !editable) && (React.createElement(S.ProductQuantityText, null, orderOffer.selectedQuantity)),
                    !isSelectable(orderOffer) && (React.createElement(S.ProductQuantityText, null, orderOffer.quantity))),
                React.createElement(S.ProductPriceContainer, null,
                    React.createElement(PriceString, { value: price.value })))));
    };
    const renderDonation = () => (React.createElement(Division, { size: "small" },
        React.createElement(S.ProductItemContainer, null,
            React.createElement(S.ProductDetailsContainer, null,
                React.createElement(ProductsList.Item, { name: "Donation", type: "donation" })),
            React.createElement(S.ProductQuantityContainer, null,
                React.createElement(S.ProductQuantityText, null, "1")),
            React.createElement(S.ProductPriceContainer, null,
                React.createElement(PriceString, { ...donation })))));
    const renderOffers = (offers, editable) => {
        return offers?.map((offer) => renderOffer(offer, editable));
    };
    const handleCancelOrderClick = () => {
        if (!selectedOffers?.length) {
            return;
        }
        const productsContent = (React.createElement(React.Fragment, null,
            React.createElement(Division, { size: "small" }),
            React.createElement(Division, { size: "small" },
                React.createElement(ProductsList, null, renderOffers(selectedOffers, false))),
            React.createElement(Division, { size: "small" },
                React.createElement(Summary, null, summaryContent))));
        onOrderCancel(productsContent);
    };
    const leftContent = (React.createElement(React.Fragment, null,
        cancelableOffers && cancelableOffers.length > 0 && (React.createElement(S.Section, null,
            React.createElement(S.SectionHeader, null,
                React.createElement(Checkbox, { value: areAllOffersSelected, onValueChange: (value) => onSelectAll(value), label: areAllOffersSelected ? 'Unselect all' : 'Select all' })),
            React.createElement(Division, { size: "small" },
                React.createElement(ProductsList, null, renderOffers(cancelableOffers, true))),
            React.createElement(Division, { size: "small" }))),
        (donation || (nonCancellableOffers && nonCancellableOffers.length > 0)) && (React.createElement(S.Section, null,
            React.createElement(S.SectionHeader, null,
                React.createElement(Heading, { type: "h7", weight: "semibold" }, "Items that cannot be canceled")),
            React.createElement(Division, { size: "small" },
                React.createElement(ProductsList, null,
                    nonCancellableOffers && nonCancellableOffers.length > 0 && (React.createElement(React.Fragment, null, renderOffers(nonCancellableOffers, false))),
                    donation && renderDonation())),
            React.createElement(Division, { size: "small" })))));
    const summary = (React.createElement(SideSummary, null,
        React.createElement(S.SummaryHeader, null, "Summary"),
        React.createElement(Summary, { small: true }, summaryContent),
        React.createElement(S.SummaryButton, null,
            React.createElement(Button, { label: "Cancel items", appearance: "primary", fullWidth: true, disabled: isCancelButtonDisabled, onClick: handleCancelOrderClick })),
        React.createElement(S.SummaryCheckbox, null,
            React.createElement(Checkbox, { value: shouldRestock, label: "Restock items", onValueChange: onRestockChange }))));
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: `Cancel items (#${friendlyId})` }),
        React.createElement(Loading, { isLoading: isLoading },
            React.createElement(PageLayout, { leftContent: leftContent, rightContent: summary },
                React.createElement(Dashboard.ItemViewHeader, { name: "Cancel items", backButton: React.createElement(BackButton, { label: `Order #${friendlyId}`, to: { pathname: reverse(routing.contributions.orders.order.self, { orderId }) } }) })))));
};
