import axios from 'axios';
export const retry = (failureCount, error) => {
    var _a;
    if (axios.isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
        return false;
    }
    // It runs the query three times before throwing an error.
    return failureCount < 2;
};
export const getDefaultErrorMessage = (error) => {
    var _a, _b;
    let message = 'Something went wrong.';
    if (axios.isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.title)) {
        message = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data.title;
    }
    else if (error instanceof Error && error.message) {
        message = error.message;
    }
    return message;
};
export const createOnError = (notify) => (error) => {
    notify(getDefaultErrorMessage(error));
};
export const createQueryCacheOnError = (notify) => (error, query) => {
    // Make it possible to override QueryCache error with single Query options.
    if ('onError' in query.options)
        return;
    return createOnError(notify)(error);
};
