export const checkDomain = (domain, config) => {
    return window.entri.checkDomain(domain, config);
};
export const showEntri = (config) => {
    window.entri.showEntri(config);
};
export const addEntriCloseEvent = (fn) => {
    // @ts-ignore
    window.addEventListener('onEntriClose', fn);
};
export const removeEntriCloseEvent = (fn) => {
    // @ts-ignore
    window.removeEventListener('onEntriClose', fn);
};
