import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Overlay = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: ${Colors.GS1000};
  opacity: 0.6;
  z-index: 2;
`;
export const ErrorOverlay = styled(Overlay) `
  background: ${Colors.R300};
  opacity: 0.8;
`;
export const Container = styled.div `
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  color: ${Colors.GS100};
  letter-spacing: 0.03em;
  z-index: 2;
`;
export const IconContainer = styled.div `
  padding-bottom: 12px;
`;
export const Title = styled.div `
  font-size: 16px;
  font-weight: 600;
  max-width: 322px;
`;
export const SubTitle = styled.div `
  padding-top: 4px;
  font-size: 14px;
  line-height: 21px;
  max-width: 250px;
  letter-spacing: 0.01em;
`;
