import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { EmptyState, List, Table, useMediaQueryContext } from '@fourthwall/components';
import { routing } from '@utils/routing';
import { reverse } from 'named-urls';
import React from 'react';
import { Link } from 'react-router-dom';
import { SeriesListItem } from './components/SeriesListItem';
import { tableHeaders } from './consts';
const getSeriesItemLink = ({ id }) => ({
    pathname: reverse(routing.memberships.series.singleSeries.self, { singleSeriesId: id }),
    search: location.search,
});
export const SeriesData = ({ data, isLoading }) => {
    const { hasPermission } = useDashboardPermissions();
    const media = useMediaQueryContext();
    const emptyComponent = (React.createElement(EmptyState, { variant: media.tablet ? 'primary' : 'secondary', title: "You haven\u2019t created any video series yet", label: hasPermission('memberships.video.create') ? 'Add new video series' : undefined, to: routing.memberships.series.create }));
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, emptyComponent: emptyComponent, renderItem: (series) => React.createElement(SeriesListItem, { series: series }), getItemLink: getSeriesItemLink }));
    }
    return (React.createElement(Table, { data: data, headers: tableHeaders, isLoading: isLoading, emptyComponent: emptyComponent, rowComponent: Link, getRowLink: (seriesItem) => getSeriesItemLink(seriesItem) }));
};
