import React from 'react';
import { BaseContent } from '@fourthwall/components';
import * as S from './styled';
const SVGPlayIcon = () => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "36", height: "36", viewBox: "0 0 36 36", fill: "none" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.7553 35.4545C27.4222 35.4545 35.2587 27.6398 35.2587 18C35.2587 8.36008 27.4222 0.54541 17.7553 0.54541C8.08848 0.54541 0.251953 8.36008 0.251953 18C0.251953 27.6398 8.08848 35.4545 17.7553 35.4545ZM23.7397 19.1373C24.4781 18.7692 24.4781 17.7156 23.7397 17.3475L13.8533 12.418C13.1885 12.0865 12.4071 12.5701 12.4071 13.313V23.1718C12.4071 23.9147 13.1885 24.3983 13.8533 24.0668L23.7397 19.1373Z", fill: "white" })));
export const VideoEntry = ({ title, description, thumbnail, size = 'regular', onClick, }) => (React.createElement(S.Container, { size: size, onClick: onClick },
    React.createElement(S.Thumbnail, null,
        React.createElement(S.ThumbnailImage, { src: thumbnail, alt: `${title} video thumbnail` }),
        React.createElement(SVGPlayIcon, null)),
    React.createElement("div", null,
        React.createElement("div", null,
            React.createElement(BaseContent, { size: "small" },
                React.createElement("strong", null, title))),
        React.createElement(S.Description, null,
            React.createElement(BaseContent, { size: "small", appearance: "secondary" }, description)))));
