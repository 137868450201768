import { Button, ButtonGroup, Fieldset, Input, ModalBox, useMediaQueryContext, } from '@fourthwall/components';
import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
const ValidationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
});
export const OrderEmailView = ({ initialValues, onSubmit, onClose }) => {
    const media = useMediaQueryContext();
    return (React.createElement(ModalBox, { title: "Edit email address", onClose: onClose },
        React.createElement(Formik, { initialValues: initialValues, validationSchema: ValidationSchema, onSubmit: onSubmit }, ({ isSubmitting }) => (React.createElement(Form, null,
            React.createElement(Fieldset.Row, null,
                React.createElement(Fieldset.Item, null,
                    React.createElement(Field, { name: "email", component: Input, label: "Email", fluid: true }))),
            React.createElement("div", { style: { marginTop: media.tablet ? 40 : 16 } },
                React.createElement(ButtonGroup, { space: "medium", justify: media.tablet ? 'right' : undefined, direction: media.tablet ? 'row' : 'column-reverse', fullWidth: !media.tablet },
                    React.createElement(Button, { label: "Cancel", disabled: isSubmitting, fullWidth: !media.tablet, onClick: onClose }),
                    React.createElement(Button, { type: "submit", label: "Update email address", appearance: "primary", fullWidth: !media.tablet, loading: isSubmitting }))))))));
};
