import styled from 'styled-components';
import { Colors } from '@fourthwall/components';
export const Button = styled.button.attrs({ type: 'button' }) `
  padding: 6px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.GS100};
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.6));
  -webkit-tap-highlight-color: transparent;
`;
