import { Checkbox, DatePicker, Fieldset } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React from 'react';
import { FormFields } from '../../types';
export const Limit = () => {
    const formik = useFormikContext();
    if (formik.values.price && parseFloat(formik.values.price) === 0)
        return null;
    if (!formik.values.discount)
        return null;
    const renderLimitInput = () => {
        if (!formik.values.timeLimit)
            return null;
        return (React.createElement(Fieldset.Row, { inner: true },
            React.createElement(DatePicker, { startDate: formik.values.startDate, endDate: formik.values.endDate, showTimeInputs: true, placement: "top", onDatesChange: ({ startDate, endDate }) => {
                    formik.setFieldValue(FormFields.startDate, startDate);
                    formik.setFieldValue(FormFields.endDate, endDate);
                } })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Fieldset.Row, { inner: true },
            React.createElement(Field, { component: Checkbox, name: FormFields.timeLimit, label: "Make this offer time-limited" })),
        renderLimitInput()));
};
