import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
`;
export const TooltipWrapper = styled.div `
  white-space: normal;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
