import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;
export const Tags = styled.div `
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;
export const Info = styled.div `
  font-size: 12px;
  color: ${Colors.GS600};
  line-height: 1.3;
  letter-spacing: 0.02em;
`;
