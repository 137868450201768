import { Toasts } from '@fourthwall/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../../../utils/routing';
import { useDnsQuery } from '../../../hooks/useDnsQuery';
import { useCheckDomain } from './hooks/useCheckDomain';
import { useCreateDomainMutation } from './hooks/useCreateDomainMutation';
import { NewDomainView } from './NewDomainView';
import { createMutationPayload } from './utils';
import { useEntri } from '../../hooks/useEntri';
export const NewDomain = () => {
    const navigate = useNavigate();
    const toastId = React.useRef(null);
    const { invalidateDnsQuery } = useDnsQuery();
    const { createDomainMutation } = useCreateDomainMutation();
    const { isLoading, setIsLoading, setIsQueryEnabled } = useCheckDomain({
        onConnect: () => {
            toastId.current && Toasts.dismiss(toastId.current);
        },
    });
    const { isEntriLoaded } = useEntri();
    const handleCreateError = (domain, error) => {
        switch (error?.response?.data?.code) {
            case 'DNS_FOR_SHOP_REGISTERED':
                navigate(routing.settings.domain.self);
                return 'DNS domain for shop is already registered';
            case 'DNS_DOMAIN_NAME_REGISTERED':
                return `${domain} is already connected to a different Fourthwall site. If you’d like to connect it to this account, you must first remove the current connection`;
            case 'SUBDOMAIN_OF_BASE_DOMAIN':
                return 'Dns domain is part of base domain';
            default:
                return error?.message;
        }
    };
    const handleSubmit = (values, onError) => {
        const payload = createMutationPayload(values);
        invalidateDnsQuery();
        setIsLoading(true);
        setIsQueryEnabled(true);
        createDomainMutation.mutate(payload, {
            onError: (error) => {
                setIsLoading(false);
                onError(handleCreateError(values.domain, error));
            },
            onSuccess: () => {
                if (isEntriLoaded) {
                    toastId.current = Toasts.notify("We're pulling information about the domain. This can take up to 30 seconds.", {
                        type: 'info',
                        autoClose: false,
                    });
                }
                else {
                    navigate(routing.settings.domain.configuration);
                }
            },
        });
    };
    return (React.createElement(NewDomainView, { onSubmit: handleSubmit, isLoading: createDomainMutation.isLoading || isLoading }));
};
