import { DocumentDownloadIcon, PlaceholderPhotoIcon } from '@fourthwall/icons';
import { getCurrencySymbol } from '@fourthwall/utils/lib/common';
import isUndefined from 'lodash-es/isUndefined';
import React from 'react';
import { PriceString } from '../../../functional/PriceString';
import { ButtonClean } from '../../ButtonClean';
import { Icon } from '../../Icon';
import { Tag } from '../../Tag';
import * as S from './styled';
const renderThumbnail = ({ type, name, image, price, }) => {
    if (type === 'donation' && price) {
        return (React.createElement(S.Donation, { "aria-label": name, "data-testid": "ProductListItem.Donation" }, getCurrencySymbol(price.currency)));
    }
    if (!image) {
        return (React.createElement(S.Placeholder, null,
            React.createElement(Icon, { component: PlaceholderPhotoIcon, height: 32 })));
    }
    return React.createElement("img", { src: image, alt: name });
};
export const ProductsListItem = ({ type = 'product', image, name, description, tag, downloads, quantity, price, size = 'medium', footer, onFileDownload, }) => {
    return (React.createElement(S.Container, { size: size },
        React.createElement(S.Row, null,
            React.createElement(S.ImageContainer, { size: size }, renderThumbnail({ type, image, name, price })),
            React.createElement(S.Description, null,
                React.createElement("div", null, name),
                description && React.createElement(S.Variant, null, description),
                tag && (React.createElement(S.TagContainer, null,
                    React.createElement(Tag, { label: tag }))),
                downloads && downloads.length > 0 && (React.createElement(S.Downloads, { "data-testid": "ProductsListItem.Downloads" }, downloads.map(({ name, fileSize, uri }) => {
                    const itemName = `${name} (${fileSize})`;
                    return (React.createElement(S.DownloadsItem, { key: uri },
                        React.createElement(S.DownloadsItemName, { title: itemName },
                            React.createElement(S.DownloadsItemNameIcon, null,
                                React.createElement(Icon, { component: DocumentDownloadIcon, height: 20 })),
                            React.createElement(S.DownloadsItemNameText, null, itemName)),
                        React.createElement(S.DownloadsItemDownload, null,
                            React.createElement(ButtonClean, { label: "Download", href: onFileDownload ? undefined : uri, title: `Download ${itemName}`, onClick: onFileDownload ? () => onFileDownload(uri) : undefined }))));
                })))),
            !isUndefined(quantity) && React.createElement(S.Quantity, null, quantity),
            !isUndefined(price) && (React.createElement(S.Price, null,
                React.createElement(PriceString, { value: price.value, currency: price.currency })))),
        footer || null));
};
