import { Button, ButtonClean, Tag } from '@fourthwall/components';
import React from 'react';
import * as S from './styled';
export const ConnectBar = ({ logoSrc, logoAlt, logoStyle = 'normal', description, tag, info, connectButtonLabel = 'Connect', disconnectButtonLabel = 'Disconnect', reconnectButtonLabel = 'Reconnect', settingsUri, settingsLabel = 'Settings', footer, disabled = false, isConnecting = false, onConnect, onDisconnect, onReconnect, }) => {
    const tagProps = (() => {
        if (tag)
            return tag;
        if (!onConnect && onDisconnect) {
            return { label: 'Connected', appearance: 'success' };
        }
    })();
    return (React.createElement(S.Container, null,
        React.createElement(S.Main, null,
            logoSrc && (React.createElement(S.LogoContainer, { logoStyle: logoStyle },
                React.createElement("img", { src: logoSrc, alt: logoAlt }))),
            React.createElement(S.Content, null,
                React.createElement(S.DescriptionAndTagContainer, null,
                    description && React.createElement(S.Description, null, description),
                    tagProps && (React.createElement(S.TagWrapper, null,
                        React.createElement(Tag, { ...tagProps, display: "inline-flex" })))),
                info && React.createElement(S.InfoContainer, null, info)),
            React.createElement(S.Buttons, null,
                settingsUri && React.createElement(ButtonClean, { label: settingsLabel, href: settingsUri, target: "_blank" }),
                onDisconnect && (React.createElement(ButtonClean, { label: disconnectButtonLabel, disabled: disabled, onClick: onDisconnect })),
                onConnect && (React.createElement(Button, { label: connectButtonLabel, appearance: "primary", size: "small", loading: isConnecting, disabled: disabled, onClick: onConnect })),
                onReconnect && (React.createElement(ButtonClean, { label: reconnectButtonLabel, disabled: disabled, onClick: onReconnect })))),
        footer && React.createElement(S.Footer, null, footer)));
};
