import { useDashboardFeatureFlags } from '../../../../../../../../components/utils/DashboardFeatureFlags';
import { useMembershipsOnboardingQuery } from './useMembershipsOnboardingQuery';
import { LINK_TO_HOMEPAGE_STEP_TYPE, PERKS_STEP_TYPE, POST_STEP_TYPE } from '../consts';
export const useMembershipsOnboardingData = () => {
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { membershipsOnboardingQuery } = useMembershipsOnboardingQuery(undefined, {
        enabled: dashboardFeatureFlags.membershipsOnboarding.enabled,
    });
    return {
        membershipsOnboardingQuery,
        onboardingSteps: membershipsOnboardingQuery.data?.steps,
        perksStep: membershipsOnboardingQuery.data?.steps.find((step) => step.type === PERKS_STEP_TYPE),
        postStep: membershipsOnboardingQuery.data?.steps.find((step) => step.type === POST_STEP_TYPE),
        linkToHomepageStep: membershipsOnboardingQuery.data?.steps.find((step) => step.type === LINK_TO_HOMEPAGE_STEP_TYPE),
        isOnboardingCompleted: membershipsOnboardingQuery.data?.completed,
        isOnboardingHidden: membershipsOnboardingQuery.data?.hidden,
    };
};
