import styled from 'styled-components';
export const InputContainer = styled.div `
  flex: 1;
`;
export const RolesContainer = styled.div `
  margin-top: 24px;
`;
export const RolesTitle = styled.p `
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 16px;
`;
