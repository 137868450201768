import { useParams } from 'react-router-dom';
import React from 'react';
import { ButtonClean, Fieldset, Subsection } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { routing } from '@utils/routing';
import { usePlatformFeatureFlags } from '@components/utils/DashboardFeatureFlags/usePlatformFeatureFlags';
import { getFulfillmentType } from '@modules/Dashboard/Product/store/product/utils';
import { useShopShippingFlatRatesQuery } from '@modules/Dashboard/hooks/useShopShippingFlatRatesQuery';
import { useCurrentShop } from '@modules/Dashboard/hooks/useCurrentShop';
import { useShippingChoiceModal } from '@modules/Dashboard/Product/modules/shipping/shared/SelfOrAssistedShippingChoiceModal';
import { ShipmentWeightField } from '@modules/Dashboard/Product/modules/shipping/shared/ShippingWeight/ShipmentWeightField';
import { ShippingStartDate } from '@modules/Dashboard/Product/modules/shipping/shared/ShippingStartDate/ShippingStartDate';
import { TaxFields } from '@modules/Dashboard/Product/modules/shipping/shared/TaxFields/TaxFields';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { FeatureFlagDecorator } from '@components/utils/FeatureFlagDecorator';
import { useDashboardFeatureFlags } from '@components';
import { useOfferQuery } from '../../../queries/useOfferQuery';
import * as S from './styled';
export const ProductShippingByCreator = ({ initialValues }) => {
    const { hasFeatureFlag } = usePlatformFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const adminMode = useAdminModeContext();
    const { dashboardFeatureFlags } = useDashboardFeatureFlags();
    const { openShippingModal } = useShippingChoiceModal();
    const { values } = useFormikContext();
    const { productId = '' } = useParams();
    const { offerQuery } = useOfferQuery([productId]);
    const { isShippingTypeEditable } = offerQuery?.data?.config || {};
    const canChangeShippingType = isShippingTypeEditable &&
        ((hasFeatureFlag('assisted_product_shipping') &&
            hasPermission('products.thirdPartyLogistics.edit')) ||
            Boolean(adminMode?.isActive));
    const fulfillmentType = getFulfillmentType(values.fulfillmentService);
    const { shopShippingFlatRatesQuery } = useShopShippingFlatRatesQuery();
    const areFlatRatesEnabled = Boolean(shopShippingFlatRatesQuery.data?.enabled);
    const { currentShop } = useCurrentShop();
    const isShopOriginAddressDefined = Boolean(currentShop?.originAddress);
    return (React.createElement(React.Fragment, null,
        React.createElement(Subsection, { title: "Shipping" },
            fulfillmentType === 'FULFILLED_BY_CREATOR' && (React.createElement(S.ShippingSubtitle, null,
                React.createElement("strong", null, "I\u2019m shipping orders myself"),
                canChangeShippingType && (React.createElement(ButtonClean, { label: "Change", onClick: () => openShippingModal() })))),
            (() => {
                switch (true) {
                    case fulfillmentType === 'FULFILLED_BY_CREATOR' && !isShopOriginAddressDefined:
                        return (React.createElement(S.CopyContainer, null,
                            React.createElement("p", null,
                                "In order to properly calculate shipping costs for self-fulfilled products you have to setup your shipping location.",
                                ' ',
                                React.createElement(ButtonClean, { label: "Settings", to: routing.settings.shipping, appearance: "secondary" }))));
                    case fulfillmentType === 'FULFILLED_BY_CREATOR' && !areFlatRatesEnabled:
                        return (React.createElement(S.CopyContainer, null,
                            React.createElement("p", null,
                                "Shipping costs will be estimated based on order weight during checkout.",
                                ' ',
                                React.createElement(ButtonClean, { label: "Settings", to: routing.settings.shipping, appearance: "secondary" }))));
                    case fulfillmentType === 'FULFILLED_BY_CREATOR' && areFlatRatesEnabled:
                        return (React.createElement(S.CopyContainer, null,
                            React.createElement("p", null,
                                "You charge a fixed price for shipping based on the number of items in the cart.",
                                ' ',
                                React.createElement(ButtonClean, { label: "Settings", to: routing.settings.shipping, appearance: "secondary" }))));
                    default:
                        return null;
                }
            })(),
            React.createElement(Fieldset, null,
                React.createElement(ShipmentWeightField, null),
                React.createElement(ShippingStartDate, { initialValues: initialValues }))),
        React.createElement(FeatureFlagDecorator, { ...dashboardFeatureFlags.hsCodes, wrapper: Subsection },
            React.createElement(TaxFields, null))));
};
