import { Avatar, ButtonClean, Skeleton } from '@fourthwall/components';
import { reverse } from 'named-urls';
import React from 'react';
import { routing } from '../../../../../../../utils/routing';
import { useTiersQuery } from '../../../Perks/hooks/useTiersQuery';
import { EventsSection } from '../../components/EventsSection/EventsSection';
import { useMembershipsOverview } from '../../hooks/useMembershipsOverview';
import { getJoinedDate } from '../../utils';
import * as S from './styled';
export const NewMembers = () => {
    const { newMembers } = useMembershipsOverview();
    const { tiersQuery } = useTiersQuery();
    if (!newMembers || !tiersQuery.data) {
        return (React.createElement(Skeleton, null,
            React.createElement(Skeleton.Shape, { width: "100%", height: 256 })));
    }
    return (React.createElement(EventsSection, { title: "Newest members", button: React.createElement(S.ButtonContainer, null,
            React.createElement(ButtonClean, { label: "See all", appearance: "secondary", to: newMembers.length ? routing.memberships.members.self : undefined, withUnderline: false, disabled: !newMembers.length })), emptyMessage: !newMembers.length ? "You don't have any members yet." : undefined },
        React.createElement(S.Container, null, newMembers.map(({ id, avatarUrl, joinedAt, nickname, tierName }) => {
            return (React.createElement(S.Member, { to: reverse(routing.memberships.members.member.self, { memberId: id }) },
                React.createElement(S.JoinedDate, null, getJoinedDate(joinedAt)),
                React.createElement(S.MemberDetails, null,
                    React.createElement(S.AvatarContainer, null,
                        React.createElement(Avatar, { name: nickname, imageUrl: avatarUrl, size: "small" })),
                    React.createElement(S.Nickname, null, nickname)),
                React.createElement(S.Tier, null, tierName)));
        }))));
};
