import { getTierIds } from '../../../../utils/getTierIds';
import { FREE_TIER_ID } from '../../consts';
export const getCommonPerkPayload = (formValues) => {
    const { title, description, selectedTiers } = formValues;
    const tiers = getTierIds(selectedTiers).filter((tier) => tier.id !== FREE_TIER_ID);
    return {
        title,
        description,
        tiers,
    };
};
