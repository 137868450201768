import styled, { css } from 'styled-components';
export const Wrapper = styled.span ``;
export const Content = styled.div `
  ${({ isHidden, hiddenColor }) => isHidden &&
    css `
      user-select: none;

      ${Wrapper} {
        color: ${hiddenColor};
        background: ${hiddenColor};
      }
    `};
`;
