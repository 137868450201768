import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  gap: 4px;
`;
export const ItemContainer = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  outline: none;

  color: ${Colors.GS1000};
  background-color: ${({ isActive }) => (isActive ? Colors.GS300 : 'none')};

  &:hover {
    background-color: ${Colors.GS300};
  }
`;
