import styled from 'styled-components';
export const Container = styled.div `
  > * + * {
    margin-top: 16px;
  }
`;
export const ShippingSubtitle = styled.div `
  & > * + * {
    margin-left: 8px;
  }
`;
