import { ApiBase } from './ApiBase';
class OpenAPIApiClientModel extends ApiBase {
    constructor() {
        super(...arguments);
        this.getConfiguration = () => {
            return this.get('/open-api/configuration');
        };
        this.createConfiguration = () => {
            return this.post('/open-api/configuration');
        };
        this.removeConfiguration = () => {
            return this.delete('/open-api/configuration');
        };
    }
}
export const OpenAPIApiClient = new OpenAPIApiClientModel();
