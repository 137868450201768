import React from 'react';
import { PriceString, ProfitString, Tag } from '@fourthwall/components';
import { TableItemDate } from '@components/utils/TableItemDate';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { getGiveawayBreakdownTagProps } from '../../utils/getGiveawayBreakdownTagProps';
import * as S from './styled';
import { getGiveawayBreakdownTypeLabel } from '../../utils/getGiveawayBreakdownTypeLabel';
export const GiveawayBreakdownListItem = ({ giveawayBreakdown, }) => {
    const { hasPermission } = useDashboardPermissions();
    return (React.createElement(S.Container, null,
        React.createElement(S.Details, null,
            React.createElement(S.Type, null, getGiveawayBreakdownTypeLabel(giveawayBreakdown.type)),
            React.createElement(S.Supporter, null, giveawayBreakdown.supporter.username || giveawayBreakdown.supporter.email),
            React.createElement(S.Date, null,
                React.createElement(TableItemDate, { date: giveawayBreakdown.type === 'GIFT_REDEEM'
                        ? giveawayBreakdown.redeemedAt
                        : giveawayBreakdown.createdAt })),
            React.createElement(Tag, { ...getGiveawayBreakdownTagProps(giveawayBreakdown.status) })),
        React.createElement(S.Calculations, null,
            React.createElement(PriceString, { ...giveawayBreakdown.total }),
            hasPermission('contributions.profit') && (React.createElement(S.Profit, null,
                React.createElement(ProfitString, { ...giveawayBreakdown.profit }))))));
};
