/* eslint-disable no-bitwise */
export default function isColorBright(hex, sensitivity = 200) {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    // @ts-expect-error This clever replace is hard to type properly so here we are
    const color = Number(`0x${hex.slice(1).replace(hex.length < 5 && /./g, '$&$&')}`);
    const r = color >> 16;
    const g = (color >> 8) & 255;
    const b = color & 255;
    // HSP equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > sensitivity) {
        return true;
    }
    return false;
}
