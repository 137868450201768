import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: grid;
  margin-top: 14px;
`;
export const UploadContainer = styled.div `
  height: 120px;
  width: 100%;
`;
export const PlayerContainer = styled.div `
  user-select: none;
  position: relative;
`;
export const OverlayContainer = styled.div `
  user-select: none;
  position: relative;
  padding-top: 56.25%;
`;
export const IFrameContainer = styled.div `
  position: relative;
  margin-top: 16px;
  width: 100%;
  height: 310px;
`;
export const Buttons = styled.div `
  display: flex;
  gap: 12px;
  flex-direction: column;

  ${mediaQueries.tablet} {
    flex-direction: row;
  }

  &:not(:empty) {
    margin-top: 16px;
  }
`;
