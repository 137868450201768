import React from 'react';
import { ArrowShortRightIcon } from '@fourthwall/icons';
import { Icon, PriceString, ProfitString } from '@fourthwall/components';
import isNil from 'lodash/isNil';
import { formatCurrency } from '@fourthwall/utils/lib/currency';
import * as S from './styled';
export const ExplicitSellingPriceListItem = ({ offerVariant }) => {
    const cost = offerVariant.cost?.value;
    const profit = !isNil(cost) ? formatCurrency(offerVariant.price.value - cost) : 0;
    return (React.createElement(S.Container, null,
        React.createElement(S.Content, null,
            React.createElement("div", null, offerVariant.size),
            React.createElement(S.Details, null,
                React.createElement(PriceString, { ...offerVariant.price }),
                offerVariant.compareAtPrice && (React.createElement(S.CompareAtPrice, null,
                    React.createElement(PriceString, { ...offerVariant.compareAtPrice }))),
                React.createElement(S.Separator, null),
                React.createElement("div", null,
                    "Profit: ",
                    !isNil(cost) ? React.createElement(ProfitString, { value: Number(profit) }) : React.createElement(React.Fragment, null, "\u2013")))),
        React.createElement(S.Arrow, null,
            React.createElement(Icon, { component: ArrowShortRightIcon, height: 12 }))));
};
