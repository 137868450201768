import React from 'react';
import { FwIcon } from '@fourthwall/icons';
import { isWebView } from '@fourthwall/utils';
import { Icon } from '../Icon';
import * as S from './styled';
export const SuspenseFallback = () => {
    if (isWebView())
        return React.createElement("div", null);
    return (React.createElement(S.Container, null,
        React.createElement(Icon, { component: FwIcon, height: 60 })));
};
