import { Button, ModalBox, Toasts } from '@fourthwall/components';
import { ProductColorVariantProvider } from '@fourthwall/module-product';
import React, { useEffect, useState } from 'react';
import { useOfferMigrate } from '../../../hooks/useOfferMigrateMutation/useOfferMigrateMutation';
import { useOfferMigrateProductOptionsQuery } from '../../../hooks/useOfferMigrateProductOptionsQuery/useOfferMigrateProductOptionsQuery';
import { AnotherProductThumbnail } from './modules/AnotherProductThumbnail/AnotherProductThumbnail';
import * as S from './ChangeToAnotherProductModal.styled';
export const ChangeToAnotherProductModal = ({ offerId, onConfirm, onClose, }) => {
    const [option, setOption] = useState();
    const { offerMigrateMutation } = useOfferMigrate();
    const { offerMigrateProductOptionsQuery } = useOfferMigrateProductOptionsQuery([{ offerId }]);
    const handleConfirm = async () => {
        if (!option) {
            throw new Error('Option is missing');
        }
        try {
            const { id } = await offerMigrateMutation.mutateAsync([
                { offerId },
                { productId: option.id, migrateToNewProduct: true },
            ]);
            if (!id) {
                throw new Error('Product id is missing');
            }
            Toasts.notify('Product has been successfully migrated', {
                type: 'success',
            });
            onConfirm?.(id);
            onClose?.();
        }
        catch (error) {
            if (error instanceof Error) {
                Toasts.notify(error.message, {
                    type: 'error',
                });
            }
        }
    };
    useEffect(() => {
        if (offerMigrateProductOptionsQuery.data) {
            if (offerMigrateProductOptionsQuery.data.migrationOptions.length === 0) {
                Toasts.notify("You can't duplicate this product as it's either unavailable or out of stock at this time.", {
                    type: 'error',
                });
            }
            else {
                setOption(offerMigrateProductOptionsQuery.data.migrationOptions[0]);
            }
        }
    }, [offerMigrateProductOptionsQuery.data]);
    if (!offerMigrateProductOptionsQuery.data ||
        offerMigrateProductOptionsQuery.data.migrationOptions.length === 0) {
        return null;
    }
    return (React.createElement(ModalBox, { size: "large", title: "This product can\u2019t be duplicated", onClose: onClose, buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: onClose }),
            React.createElement(Button, { label: "Confirm", appearance: "primary", onClick: handleConfirm }),
        ] },
        React.createElement(S.Lead, null, "Product is not available anymore. However you may copy its design into a new product."),
        React.createElement(S.Container, null, offerMigrateProductOptionsQuery.data.migrationOptions.map((product) => (React.createElement(ProductColorVariantProvider, { initialColorVariant: product.variants.matched[0], colorVariants: product.variants.matched },
            React.createElement(AnotherProductThumbnail, { product: product, checked: option?.id === product.id, onChange: () => {
                    setOption(product);
                } })))))));
};
