import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
const colorMap = {
    primary: Colors.GS1000,
    secondary: Colors.GS600,
};
const fontSizeMap = {
    xsmall: 12,
    small: 14,
    base: 16,
};
export const Container = styled.div `
  color: ${({ appearance }) => colorMap[appearance]};

  font-size: ${({ size }) => fontSizeMap[size]}px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;

  display: grid;
  gap: 4px;

  ul,
  ol {
    padding-left: 20px;

    display: grid;
    gap: 8px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  a,
  button {
    line-height: inherit;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.15em;

    &:hover {
      color: ${Colors.GS1000};
    }
  }

  strong {
    font-weight: 600;
  }

  dl {
    display: grid;
    gap: 12px;

    dt {
      font-weight: 600;
    }

    dd {
      margin-top: 4px;
    }
  }

  ${({ size }) => size === 'small' &&
    css `
      ul {
        padding-left: 16px;
      }
    `}
`;
