import { CreatorMessagesApiClient } from '@fourthwall/services/lib/CreatorMessagesApiClient';
import { useQuery } from '@tanstack/react-query';
export const useGetMessageQuery = (messageId) => {
    const useMessageQuery = useQuery({
        queryKey: ['useGetMessageQuery', messageId],
        queryFn: async () => {
            const { data } = await CreatorMessagesApiClient.getCreatorMessage(messageId);
            return data;
        },
    });
    return {
        useMessageQuery,
        messageData: useMessageQuery.data,
    };
};
