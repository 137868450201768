import { useEffect } from 'react';
import { useModalContext } from '@fourthwall/components';
import { useParams } from 'react-router-dom';
import { usePlatformFeatureFlags } from '@components/utils/DashboardFeatureFlags/usePlatformFeatureFlags';
import { useHash } from '@utils/useHash/useHash';
import { useOfferUpdateShippingTypeMutation } from '@modules/Dashboard/hooks/useOfferUpdateShippingTypeMutation';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useAdminModeContext } from '@modules/App/providers/AdminModeProvider';
import { useOfferQuery } from '../../../../queries/useOfferQuery';
import { SHIPPING_MODAL_HASH, SelfOrAssistedShippingChoiceModal, } from './SelfOrAssistedShippingChoiceModal';
const options = [
    { id: 'WEIGHT_BASED_SELF_SHIPPING' },
    { id: 'SHIPPING_3PL' },
];
export const useShippingChoiceModal = () => {
    const { hasHash, removeHash, addHash } = useHash();
    const { open, close } = useModalContext();
    const { productId = '' } = useParams();
    const { offerQuery } = useOfferQuery([productId]);
    const { hasFeatureFlag } = usePlatformFeatureFlags();
    const { hasPermission } = useDashboardPermissions();
    const adminMode = useAdminModeContext();
    const { updateOfferShippingTypeMutation } = useOfferUpdateShippingTypeMutation();
    const { setFieldValue } = useFormikContext();
    const openShippingModal = () => addHash(SHIPPING_MODAL_HASH);
    useEffect(() => {
        if (hasHash(SHIPPING_MODAL_HASH) &&
            ((hasFeatureFlag('assisted_product_shipping') &&
                hasPermission('products.thirdPartyLogistics.edit')) ||
                Boolean(adminMode?.isActive))) {
            open(SelfOrAssistedShippingChoiceModal, {
                initialOption: offerQuery.data?.config.shippingType === 'SHIPPING_3PL' ? options[1] : options[0],
                options,
                async onConfirm(option) {
                    await updateOfferShippingTypeMutation.mutateAsync([{ offerId: offerQuery.data?.id }, { shippingType: option.id }], {
                        onSuccess(data) {
                            offerQuery.refetch();
                            setFieldValue('fulfillmentService', data.fulfillingService);
                            removeHash();
                            close();
                        },
                    });
                },
                onClose() {
                    removeHash();
                    close();
                },
                onRequestClose() {
                    removeHash();
                    close();
                },
            });
        }
    }, [
        close,
        hasFeatureFlag,
        hasHash,
        offerQuery,
        offerQuery.data?.config.shippingType,
        offerQuery.data?.id,
        open,
        removeHash,
        setFieldValue,
        updateOfferShippingTypeMutation,
    ]);
    return {
        openShippingModal,
    };
};
