import { Colors, mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  max-width: 500px;
  margin: 0 24px;
`;
export const AudioPlayerTrack = styled.div `
  flex: 1;

  .rc-slider {
    position: relative;
    width: calc(100% - 16px);
    margin-left: 8px;
    height: 16px;
    padding: 6px 0;
  }

  .rc-slider-rail {
    cursor: pointer;
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: ${Colors.GS0};
    opacity: 0.2;
    border-radius: 100px;
  }

  .rc-slider-track {
    cursor: pointer;
    position: absolute;
    left: 0;
    height: 4px;
    background-color: ${Colors.GS0};
    border-radius: 100px;
  }

  .rc-slider-disabled {
    background-color: transparent;
  }

  .rc-slider-step {
    height: 4px;
  }

  .rc-slider-handle {
    cursor: grab;
    position: absolute;
    top: 0;
    width: 16px;
    height: 16px;
    background: ${Colors.GS0};
    margin-top: 0;
    border-radius: 50%;
    border: 0;
    outline: 0;
    opacity: 1;

    &-dragging {
      box-shadow: 0 0 0 2px ${Colors.GS0} !important;
    }
  }
`;
export const AudioPlayerControls = styled.div `
  display: flex;
  margin-top: 16px;
  gap: 8px;

  ${mediaQueries.laptop} {
    gap: 16px;
  }
`;
export const AudioPlayerControlsTimer = styled.div `
  flex: 1;
  display: flex;
  gap: 6px;
  font-size: 16px;
  line-height: 24px;
  align-items: center;

  ${mediaQueries.laptop} {
    gap: 12px;
  }
`;
export const Time = styled.div `
  font-variant-numeric: tabular-nums;
`;
export const AudioPlayerAudio = styled.div `
  display: flex;
  min-width: 100px;
  align-items: center;

  ${mediaQueries.laptop} {
    min-width: 120px;
  }
`;
export const AudioPlayerControl = styled.button `
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid ${Colors.GS700};
  padding-left: ${({ paddingLeft }) => paddingLeft ?? 0}px;

  &:hover:not(:disabled) {
    border-color: ${Colors.GS600};
  }
`;
export const AudioElement = styled.audio `
  display: none;
`;
