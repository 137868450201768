import React from 'react';
import { VirtualProductThumbnail } from '@components/common/VirtualProductThumbnail';
import { TableItemDate } from '@components/utils/TableItemDate';
import { StatusTag } from '@components/common/StatusTag';
import { PriceString, ProfitString } from '@fourthwall/components';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import * as S from './styled';
export const DonationListItem = ({ donation }) => {
    const { hasPermission } = useDashboardPermissions();
    return (React.createElement(S.Container, null,
        React.createElement(S.Photo, null,
            React.createElement(VirtualProductThumbnail, null)),
        React.createElement(S.Details, null,
            React.createElement(S.Supporter, null, donation.username || donation.email),
            React.createElement(S.Date, null,
                React.createElement(TableItemDate, { date: donation.createdAt })),
            React.createElement(StatusTag, { status: donation.status })),
        React.createElement(S.Calculations, null,
            React.createElement(PriceString, { value: donation.donation.value }),
            hasPermission('contributions.profit') && donation.profit && (React.createElement(S.Profit, null,
                React.createElement(ProfitString, { value: donation.profit.value }))))));
};
