import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
const border = css `1px solid ${Colors.GS300}`;
export const TableHeaderSelectionWrapper = styled.div `
  display: block;
  opacity: 0;
  position: absolute;
  z-index: 5;
  left: -44px;
  top: 50%;
  transform: translateY(-50%);

  width: 48px;
  padding: 8px;

  &:hover {
    opacity: 1;
  }

  ${({ visible }) => visible &&
    css `
      opacity: 1;
    `}
`;
export const TableHeadTr = styled.tr `
  position: relative;

  &:hover {
    ${TableHeaderSelectionWrapper} {
      opacity: 1;
    }
  }
`;
export const TableHead = styled.thead `
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.2px;

  color: ${Colors.GS600};
  user-select: none;
`;
const CommonCellStyles = css `
  font-weight: 400;

  ${({ $textAlign }) => css `
    text-align: ${$textAlign || 'center'};
  `}

  padding: ${({ verticalSpacing = '8px', horizontalSpacing = '0' }) => `${verticalSpacing} ${horizontalSpacing}`};

  ${({ $width }) => $width &&
    css `
      width: ${$width};
    `}

  ${({ $borderTop }) => $borderTop &&
    css `
      border-top: ${$borderTop} !important;
    `}

  ${({ $position }) => $position &&
    css `
      position: ${$position};
    `}

  ${({ $verticalAlign }) => $verticalAlign &&
    css `
      vertical-align: ${$verticalAlign};
    `}
`;
export const TableHeadTh = styled.th `
  ${CommonCellStyles};
`;
export const TableBodyTd = styled.td `
  ${CommonCellStyles};

  // TODO: this is temporary solution, we need to set this value dynamically to enable rows height customization
  // rel: https://popshop.atlassian.net/browse/PRD-2628
  height: ${({ $height }) => $height || '80px'};
`;
export const TableCellItemPositioner = styled.div `
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};

  ${({ position }) => {
    if (position === 'start') {
        return css `
        margin-left: 0;
        margin-right: auto;
      `;
    }
    if (position === 'end') {
        return css `
        margin-right: 0;
        margin-left: auto;
      `;
    }
    return css `
      margin: 0 auto;
    `;
}}
`;
export const TableLoaderWrapper = styled.div `
  display: flex;
  justify-content: center;
  margin: 80px auto;
`;
export const TableHeaderRoot = styled.div `
  position: relative;
`;
export const TableHeaderActions = styled.div `
  display: flex;
  flex-direction: row;

  height: 30px;

  & > * + * {
    margin-left: 12px;
  }
`;
export const TableHeaderActionsItems = styled.div `
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
export const TableHeaderActionsHeading = styled.div `
  display: flex;
  align-items: center;
  height: 30px;
`;
export const TableHeaderActionsItem = styled.button `
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  outline: none;

  color: ${Colors.GS1000};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;

  background: ${Colors.GS200};

  padding: 4px 10px;

  &:hover {
    background: ${Colors.GS400};
  }
`;
export const TableRowActionsContainer = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 100%;

  padding: 8px;
  margin: 20px 0;

  color: ${Colors.GS600};
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: ${Colors.GS1000};
    background: rgba(0, 0, 0, 0.06);
    height: 32px;
  }
`;
export const TableRowActionsWrapper = styled.div `
  display: block;
  opacity: 0;
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    opacity: 1;
  }
`;
export const TableRowDragHandle = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -77px;
  top: 50%;
  width: 32px;
  padding: 8px;
  height: 100%;

  transform: translateY(-50%);

  opacity: 0;
  color: ${Colors.GS600};

  cursor: move;
  cursor: grab;

  &:hover {
    color: ${Colors.GS1000};
    opacity: 1;
  }
`;
export const TableRowSelectionWrapper = styled.td `
  display: flex;
  justify-content: center;
  flex-direction: column;

  opacity: 0;

  position: absolute;
  left: -44px;
  top: 50%;
  transform: translateY(-50%);

  height: 100%;
  width: 44px;

  padding-left: 8px;

  z-index: 5;

  &:hover {
    opacity: 1;
  }

  ${({ visible }) => visible &&
    css `
      opacity: 1;
    `}
`;
export const TableBody = styled.tbody `
  position: relative;

  width: 1200px;

  ${({ isLoading }) => isLoading &&
    css `
      opacity: 0.5;
    `}
`;
export const TableBodyTr = styled.tr `
  position: relative;

  &:hover {
    ${TableRowActionsWrapper}, ${TableRowDragHandle}, ${TableRowSelectionWrapper} {
      opacity: 1;
    }

    z-index: 6;
  }

  ${({ isDragged }) => isDragged &&
    css `
      background-color: #ffffff;
      background-image: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0,
        #f9f8f7 25%,
        #f9f8f7 71%,
        rgba(255, 255, 255, 0) 100%
      );
    `}

  ${({ isSelected }) => isSelected &&
    css `
      background-image: linear-gradient(90deg, #fff 0%, #f2f5fe 44.27%, #f2f5fe 54.17%, #fff 100%);
    `}
`;
export const Root = styled.table `
  width: 100%;

  ${({ $minWidth }) => $minWidth &&
    css `
      min-width: ${$minWidth};
    `}

  border-collapse: collapse;

  & > ${TableHead} > tr > th {
    border-bottom: ${border};
  }

  & > tfoot > tr > th,
  & > ${TableBody} > tr > th,
  & > ${TableBody} > tr > td {
    border-top: ${border};
  }

  & > ${TableBody} > tr:first-of-type > td,
  & > ${TableBody} > tr:first-of-type > th {
    border-top: none;
  }

  & > ${TableBody} > tr {
    ${({ hoverable = true }) => hoverable &&
    css `
        cursor: pointer;

        &:hover {
          background-image: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0,
            #f9f8f7 25%,
            #f9f8f7 71%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      `};
  }
`;
