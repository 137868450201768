import { TableCell } from '@components/common/TableCell';
import { PriceString, ProfitString } from '@fourthwall/components';
import React from 'react';
import { VirtualProductThumbnail } from '@components/common/VirtualProductThumbnail';
import { TableItemDate } from '@components/utils/TableItemDate';
import { StatusTag } from '@components/common/StatusTag';
export const getTableHeaders = ({ hideProfitColumn, }) => [
    {
        id: 'date',
        label: 'Date',
        render: ({ createdAt }) => (React.createElement("div", null,
            React.createElement(TableItemDate, { date: createdAt }))),
    },
    {
        id: 'icon',
        label: ' ',
        render: () => {
            return (React.createElement("div", { style: { width: 60, height: 80 } },
                React.createElement(VirtualProductThumbnail, null)));
        },
    },
    {
        id: 'supporter',
        label: 'Supporter',
        render: ({ username, email }) => {
            return username || email;
        },
        width: 2,
    },
    {
        id: 'status',
        label: 'Status',
        render: ({ status }) => {
            return React.createElement(StatusTag, { status: status });
        },
        width: 2,
    },
    {
        id: 'total',
        label: 'Total',
        render: ({ donation }) => {
            return React.createElement(PriceString, { value: donation.value });
        },
    },
    {
        id: 'profit',
        hidden: hideProfitColumn,
        label: 'Profit',
        render: ({ profit }) => (React.createElement(TableCell, { mainContent: profit ? React.createElement(ProfitString, { value: profit.value }) : React.createElement(React.Fragment, null, "\u2013") })),
    },
];
