import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.min.css';
export const GlobalStyles = createGlobalStyle `
  :root {
    --toastify-toast-width: auto;
    --toastify-toast-background: transparent;
  }

  .Toastify__toast {
    padding: 0;
    box-shadow: none;
    background: none;
  }
`;
