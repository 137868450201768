import styled, { css } from 'styled-components';
import { Colors } from '../../../colors';
import { getAutomatedStyleProp } from '../../../providers/ComponentsThemeProvider';
export const Container = styled.div `
  & + & {
    margin-top: 8px;
  }
`;
export const Main = styled.div `
  display: flex;

  ${({ bold }) => bold &&
    css `
      font-weight: ${getAutomatedStyleProp('fontWeightBaseBold', '700')};
    `}

  ${({ highlighted }) => highlighted &&
    css `
      color: ${getAutomatedStyleProp('colorOnBackground', Colors.B500)};
    `}
`;
export const Label = styled.div `
  flex: 1;
`;
export const LabelWithIcon = styled.div `
  flex: 1;
  display: flex;
  align-items: center;
`;
export const IconContainer = styled.div `
  margin-left: 8px;
`;
export const Suggestion = styled.div `
  margin-top: 2px;

  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GS600};
`;
