import styled from 'styled-components';
import { DASHBOARD_STYLED_CONSTS } from '@components/common';
import { mediaQueries } from '@fourthwall/components';
export const Suggestions = styled.div `
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: -1px;
  }

  ${mediaQueries.tablet} {
    gap: 20px;

    & > * {
      margin-top: 0;
    }
  }
`;
export const SuggestionWrapper = styled.div `
  position: relative;
  left: -${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL}px;
  width: calc(100% + ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL * 2}px);

  ${mediaQueries.tablet} {
    position: static;
    width: auto;
  }
`;
