import { DateString, PriceString, ProfitString, SummaryModal } from '@fourthwall/components';
import React from 'react';
import { transactionTypeTexts } from '../../constants';
export const TransactionSummaryModal = ({ transaction, onClose }) => {
    const { value, balance, type, reason, transactionAt, principalName } = transaction;
    return (React.createElement(SummaryModal, { title: "Transaction summary", items: [
            { label: 'Amount', content: React.createElement(ProfitString, { value: value.value }) },
            { label: 'Balance after transaction', content: React.createElement(PriceString, { value: balance.value }) },
            { label: 'Type of transaction', content: transactionTypeTexts[type] },
            { label: 'Description', content: reason },
            {
                label: 'Issue time',
                content: React.createElement(DateString, { date: transactionAt, format: "h:mm A, MMM D, YYYY" }),
            },
            { label: 'Issuer', content: principalName },
        ], onClose: onClose }));
};
