import { useOffersQuery } from '@queries/useOffersQuery';
import { getSamplesOffersQueryParams } from './utils';
import { useAdminModeContext } from '../../../../../App/providers/AdminModeProvider';
export const useSamplesOffersQuery = (options) => {
    const adminMode = useAdminModeContext();
    const { offersQuery } = useOffersQuery([
        getSamplesOffersQueryParams({
            includeFulfillmentService: !adminMode?.isActive,
            ...options,
        }),
    ]);
    return {
        offersQuery,
    };
};
