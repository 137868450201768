import { DASHBOARD_STYLED_CONSTS } from '@components/common';
import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Recommendations = styled.div `
  display: flex;
  flex-direction: column;

  ${mediaQueries.tablet} {
    gap: 20px;
  }
`;
export const RecommendationBars = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;

  ${mediaQueries.tablet} {
    margin-bottom: 0;
  }
`;
export const SuggestionWrapper = styled.div `
  position: relative;
  left: -${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL}px;
  width: calc(100% + ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL * 2}px);

  & + & {
    margin-top: -1px;
  }

  ${mediaQueries.tablet} {
    position: static;
    width: auto;

    & + & {
      margin-top: 0;
    }
  }
`;
export const ActionBarsContainer = styled.div `
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Title = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;

  ${mediaQueries.tablet} {
    margin-top: 0;
  }
`;
export const CopyLinkWrapper = styled.div `
  display: block;

  flex: 1;
  overflow: hidden;
  min-width: 150px;
`;
