import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const Container = styled.div `
  display: grid;
  gap: 8px;
`;
export const Nickname = styled.div `
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Member = styled(Link) `
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 2fr 1fr;
  gap: 24px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    ${Nickname} {
      text-decoration: underline;
    }
  }
`;
export const JoinedDate = styled.div `
  width: 56px;
`;
export const MemberDetails = styled.div `
  display: flex;
  gap: 12px;
  align-items: center;
  margin-right: auto;
  overflow: hidden;
  width: 100%;
`;
export const AvatarContainer = styled.div `
  flex-shrink: 0;
`;
export const Tier = styled.div `
  min-width: 56px;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const ButtonContainer = styled.div `
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.02em;
`;
