import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 440px;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
export const HeadingText = styled.div `
  color: ${Colors.GS0};
`;
