import styled from 'styled-components';
export const Description = styled.div `
  margin-bottom: 16px;
  line-height: 150%;
`;
export const ShippingFeeContainer = styled.div `
  margin-bottom: 16px;
`;
export const ShippingFeeInput = styled.div `
  margin-top: 12px;
  margin-left: 36px;
  width: 160px;
`;
