import { useThirdPartyLogisticsOptionsQuery } from '@modules/Dashboard/hooks/useThirdPartyLogisticsOptionsQuery';
import React from 'react';
import { ButtonClean, PriceString } from '@fourthwall/components';
import * as S from './Shipping3plDescription.styled';
export const Shipping3plDescription = ({ shippingConfig, }) => {
    const { thirdPartyLogisticsOptionsQuery } = useThirdPartyLogisticsOptionsQuery();
    if (!thirdPartyLogisticsOptionsQuery.data) {
        return null;
    }
    const { shippingFirstItemFee, shippingNextItemFee } = thirdPartyLogisticsOptionsQuery.data;
    return (React.createElement(S.Text, null,
        "Send your products to us and we\u2019ll handle shipping for you. When we ship an order, we\u2019ll charge you",
        ' ',
        React.createElement(PriceString, { value: shippingFirstItemFee.value, currency: shippingFirstItemFee.currency }),
        ' ',
        "for the first item +",
        ' ',
        React.createElement(PriceString, { value: shippingNextItemFee.value, currency: shippingNextItemFee.currency }),
        " for each additional item in the shipment.",
        ' ',
        shippingConfig?.shippingPackageStorageFee?.value &&
            shippingConfig?.shippingPackageStorageFee?.value > 0 ? (React.createElement(React.Fragment, null,
            "We also charge a",
            ' ',
            React.createElement(PriceString, { value: shippingConfig?.shippingPackageStorageFee.value, currency: shippingConfig?.shippingPackageStorageFee.currency }),
            ' ',
            "per item per month storage fee.")) : null,
        ' ',
        React.createElement(ButtonClean, { appearance: "secondary", label: "Learn more", href: "https://help.fourthwall.com/hc/en-us/articles/13331284092187-Fourthwall-fulfillment-service-guide", target: "_blank" })));
};
