import cloneDeep from 'lodash-es/cloneDeep';
import set from 'lodash-es/set';
import { convertNumberToPrice } from './convertNumberToPrice';
export const setVariantsPriceValue = (variants, priceValue, size) => {
    return cloneDeep(variants).map((variant, index) => {
        if (size && variant.size !== size)
            return variant;
        const relativeVariant = size ? variant : variants[0];
        const newPriceValue = priceValue ? parseFloat(priceValue) : 0;
        const profitValue = newPriceValue - (relativeVariant.cost?.value || 0);
        const compareAtPriceValue = relativeVariant.compareAtPrice?.value || null;
        const newVariantPriceValue = (variant.cost?.value || 0) + profitValue;
        const newHeadVariantPriceValue = index === 0 ? newVariantPriceValue : undefined;
        set(variant, 'price', convertNumberToPrice(newVariantPriceValue));
        if (compareAtPriceValue !== null && !size && newHeadVariantPriceValue) {
            const difference = compareAtPriceValue - newHeadVariantPriceValue;
            const newVariantCompareAtPriceValue = Number(newVariantPriceValue) + Number(difference);
            set(variant, 'compareAtPrice', convertNumberToPrice(newVariantCompareAtPriceValue));
        }
        return variant;
    });
};
