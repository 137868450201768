import styled from 'styled-components';
import { DASHBOARD_STYLED_CONSTS } from '@components/common';
import { mediaQueries } from '@fourthwall/components';
export const StatWrapper = styled.div `
  scroll-snap-align: start end;

  &:last-child {
    padding-right: ${DASHBOARD_STYLED_CONSTS.CONTENT_PADDING_HORIZONTAL}px;

    ${mediaQueries.tablet} {
      padding-right: 0;
    }
  }
`;
