import React from 'react';
import { Loader } from '../../../common/Loader';
import { Loading } from '../../../common/Loading';
import { Tabs } from '../../../common/Tabs';
import { LoaderContainer, TabsItemStyle, TabsListStyle } from '../styled';
import { QuantityAccordion } from './components/QuantityAccordion';
import * as S from './styled';
export const MOQPricing = ({ accordionState, data, includeFeesValue, isLoading, soldValue, soldValueError, onAccordionChange, onSoldValueChange, }) => {
    const renderAccordion = (data, isFirstTab = true) => {
        if (!data?.quantityPricing) {
            return (React.createElement(LoaderContainer, null,
                React.createElement(Loader, { size: "large" })));
        }
        return (React.createElement(Loading, { isLoading: isLoading },
            React.createElement(QuantityAccordion, { accordionState: accordionState, autoAdjustedSellingPrice: !isFirstTab, includeFeesValue: includeFeesValue, data: data, soldValue: soldValue, soldValueError: soldValueError, onChange: onAccordionChange, onSoldValueChange: onSoldValueChange })));
    };
    const renderSizeTabs = () => (React.createElement(Tabs, null,
        React.createElement(S.TabsListContainer, null,
            React.createElement(Tabs.List, { style: TabsListStyle }, data?.map((tab) => (React.createElement(Tabs.Item, { key: tab.label, style: TabsItemStyle, name: tab.label, variant: "dimmed" }))))),
        data?.map((tab, index) => (React.createElement(Tabs.Panel, { key: `${tab.label}-${index}` }, renderAccordion(data[index], index === 0))))));
    const renderContent = () => {
        if (data.length > 1) {
            return renderSizeTabs();
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(S.Separator, null),
            renderAccordion(data[0])));
    };
    return React.createElement(S.Container, null, renderContent());
};
