import { useTiktokIntegrationQuery } from '@queries';
import { useCurrentShopQuery } from '@modules/Dashboard/hooks/useCurrentShopQuery';
import { useTagManager } from '@hooks';
import logoSrc from '../../../assets/signets/tiktok.svg';
import { useOAuthDialog } from '../../../hooks/useOAuthDialog';
import { useCreateTiktokIntegrationMutation } from './useCreateTiktokIntegrationMutation';
import { useRemoveTiktokIntegrationMutation } from './useRemoveTiktokIntegrationMutation';
import { useUpdateTiktokIntegrationMutation } from './useUpdateTiktokIntegrationMutation';
import { useAppConnectEvent } from '../../../hooks/useAppConnectEvent';
export const useTiktok = () => {
    const { currentShopQuery } = useCurrentShopQuery();
    const { createTiktokIntegrationMutation } = useCreateTiktokIntegrationMutation();
    const { removeTiktokIntegrationMutation } = useRemoveTiktokIntegrationMutation();
    const { updateTiktokIntegrationMutation } = useUpdateTiktokIntegrationMutation();
    const { tiktokIntegrationQuery, setTiktokIntegrationData } = useTiktokIntegrationQuery([currentShopQuery.data?.id], { enabled: !!currentShopQuery.data?.id, onError: undefined });
    const { openOAuthDialog } = useOAuthDialog({
        onComplete: () => {
            tiktokIntegrationQuery.refetch();
            tagManager.trigger('app_connected', { app_name: 'tiktok_feed' });
        },
    });
    const tagManager = useTagManager();
    const appConnectEvent = useAppConnectEvent('tiktok_feed');
    const getStatus = () => {
        if (tiktokIntegrationQuery.isError) {
            return 'NOT_CONNECTED';
        }
        if (tiktokIntegrationQuery.data?.consumerToken) {
            return 'CONNECTED';
        }
    };
    const status = getStatus();
    const getConnectBar = () => {
        const connectBarBase = {
            logoSrc,
            description: 'Connect to your TikTok account',
            disabled: true,
        };
        if (status === 'CONNECTED') {
            const onDisconnect = async () => {
                await removeTiktokIntegrationMutation.mutateAsync([currentShopQuery.data?.id]);
                await tiktokIntegrationQuery.refetch();
            };
            return {
                ...connectBarBase,
                disabled: false,
                onDisconnect,
            };
        }
        if (status === 'NOT_CONNECTED') {
            const onConnect = async () => {
                appConnectEvent.trigger();
                const integration = await createTiktokIntegrationMutation.mutateAsync([
                    currentShopQuery.data?.id,
                    { flow: 'postMessage' },
                ]);
                openOAuthDialog(integration.redirectTo);
            };
            return {
                ...connectBarBase,
                disabled: false,
                onConnect,
            };
        }
        return connectBarBase;
    };
    const handleSettingsSubmit = (values) => {
        updateTiktokIntegrationMutation.mutate([currentShopQuery.data?.id, { features: { kudos_sync: values.kudosSync } }], {
            onSuccess: (data) => {
                setTiktokIntegrationData(data);
            },
        });
    };
    return {
        tiktok: {
            connectBar: getConnectBar(),
            settings: {
                onSubmit: handleSettingsSubmit,
                isSubmitting: updateTiktokIntegrationMutation.isLoading,
                initialValues: {
                    kudosSync: !!tiktokIntegrationQuery.data?.consumerToken?.features.kudosSync,
                },
            },
            status,
        },
    };
};
