import { HeadContent, LeavingGuard, SaveShortcutListener } from '@fourthwall/components';
import { Formik } from 'formik';
import React from 'react';
import { ConfirmBarContainer } from '../../../../../ConfirmBar/ConfirmBarContainer';
import { useTiers } from '../../../Perks/hooks/useTiers';
import { getValidationSchema } from './consts';
import { useDeleteWelcomePostsMutation } from './hooks/useDeleteWelcomePostsMutation';
import { useDifferentWelcomePostsCreate } from './hooks/useDifferentWelcomePostsCreate';
import { useSingleWelcomePostsCreate } from './hooks/useSingleWelcomePostsCreate';
import { useWelcomePostsQuery } from './hooks/useWelcomePostsQuery';
import { CommonFields } from './modules/CommonFields';
import { DisabledFields } from './modules/DisabledFields';
import { ImageFields } from './modules/ImageFields';
import { PostFields } from './modules/PostFields';
import { VideoFields } from './modules/VideoFields';
import { FormFields } from './types';
import { getUsage, getWelcomePostFormData, getWelcomePostsFormData } from './utils/form';
export const WelcomePost = () => {
    const { tiers } = useTiers();
    const { welcomePostsQuery } = useWelcomePostsQuery();
    const { deleteWelcomePostsMutation } = useDeleteWelcomePostsMutation();
    const { createSingleWelcomePosts, isLoading: createSingleIsLoading, mediaUploadProgress: singleMediaUploadProgress, } = useSingleWelcomePostsCreate();
    const { createDifferentWelcomePosts, isLoading: createDifferentIsLoading, mediaUploadProgress: differentMediaUploadProgress, } = useDifferentWelcomePostsCreate();
    if (!tiers)
        return null;
    const isLoading = welcomePostsQuery.isLoading || createSingleIsLoading || createDifferentIsLoading;
    const handleSubmit = (values) => {
        if (values.usage === 'none') {
            return deleteWelcomePostsMutation.mutate(undefined, {
                onSuccess: () => {
                    welcomePostsQuery.refetch();
                },
            });
        }
        if (values.usage === 'same') {
            createSingleWelcomePosts(values, !!welcomePostsQuery.data, () => {
                welcomePostsQuery.refetch();
            });
        }
        if (values.usage === 'different') {
            createDifferentWelcomePosts(values, !!welcomePostsQuery.data, () => {
                welcomePostsQuery.refetch();
            });
        }
    };
    const initialValues = {
        usage: getUsage(welcomePostsQuery),
        welcomePost: getWelcomePostFormData(welcomePostsQuery),
        welcomePosts: getWelcomePostsFormData(welcomePostsQuery, tiers),
    };
    const renderContent = () => {
        if (tiers.length === 0) {
            return React.createElement(DisabledFields, null);
        }
        return (React.createElement(Formik, { initialValues: initialValues, validationSchema: getValidationSchema(tiers), enableReinitialize: true, onSubmit: handleSubmit }, ({ values, dirty, submitForm, resetForm }) => {
            const { welcomePost, welcomePosts } = values;
            return (React.createElement(React.Fragment, null,
                React.createElement(CommonFields, { disabled: isLoading }),
                values.usage === 'same' && (React.createElement(PostFields, { postTypeFieldName: `${FormFields.welcomePost}.postType`, titleFieldName: `${FormFields.welcomePost}.title`, descriptionFieldName: `${FormFields.welcomePost}.description`, postType: welcomePost.postType, disabled: isLoading },
                    welcomePost.postType === 'Image' && (React.createElement(ImageFields, { imageFieldName: `${FormFields.welcomePost}.image`, image: welcomePost.image || undefined, isUploading: isLoading && typeof welcomePost.image === 'object' })),
                    welcomePost.postType === 'Video' && (React.createElement(VideoFields, { type: welcomePost.videoType, typeFieldName: `${FormFields.welcomePost}.videoType`, video: welcomePost.video, videoFieldName: `${FormFields.welcomePost}.video`, thumbnail: welcomePost.videoThumbnail, thumbnailFieldName: `${FormFields.welcomePost}.videoThumbnail`, url: welcomePost.videoUrl, urlFieldName: `${FormFields.welcomePost}.videoUrl`, uploadProgress: singleMediaUploadProgress.single || 0, isUploading: isLoading &&
                            (typeof welcomePost.video === 'object' ||
                                typeof welcomePost.videoThumbnail === 'object'), isThumbnailCustom: welcomePost.imageType === 'Custom', status: welcomePost.status || 'None' })))),
                values.usage === 'different' &&
                    tiers.map((tier) => (React.createElement(PostFields, { postTypeFieldName: `${FormFields.welcomePosts}.${tier.id}.postType`, titleFieldName: `${FormFields.welcomePosts}.${tier.id}.title`, descriptionFieldName: `${FormFields.welcomePosts}.${tier.id}.description`, postType: welcomePosts?.[tier.id]?.postType, subsectionTitle: tier.title, disabled: isLoading },
                        welcomePosts?.[tier.id]?.postType === 'Image' && (React.createElement(ImageFields, { imageFieldName: `${FormFields.welcomePosts}.${tier.id}.image`, image: welcomePosts?.[tier.id]?.image, isUploading: isLoading && typeof welcomePosts?.[tier.id]?.image === 'object' })),
                        welcomePosts?.[tier.id]?.postType === 'Video' && (React.createElement(VideoFields, { type: welcomePosts?.[tier.id]?.videoType, typeFieldName: `${FormFields.welcomePosts}.${tier.id}.videoType`, video: welcomePosts?.[tier.id]?.video, videoFieldName: `${FormFields.welcomePosts}.${tier.id}.video`, thumbnail: welcomePosts?.[tier.id]?.videoThumbnail, thumbnailFieldName: `${FormFields.welcomePosts}.${tier.id}.videoThumbnail`, url: welcomePosts?.[tier.id]?.videoUrl, urlFieldName: `${FormFields.welcomePosts}.${tier.id}.videoUrl`, uploadProgress: differentMediaUploadProgress[tier.id] || 0, isUploading: isLoading &&
                                (typeof welcomePosts?.[tier.id]?.video === 'object' ||
                                    typeof welcomePosts?.[tier.id]?.videoThumbnail === 'object'), isThumbnailCustom: welcomePosts?.[tier.id]?.imageType === 'Custom', status: welcomePosts?.[tier.id].status || 'None' }))))),
                React.createElement(SaveShortcutListener, { disabled: !dirty, onSave: submitForm }),
                React.createElement(ConfirmBarContainer, { isOpen: dirty, isLoading: isLoading, onConfirm: submitForm, onCancel: resetForm }),
                React.createElement(LeavingGuard, { when: dirty || isLoading })));
        }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadContent, { title: "Welcome post" }),
        renderContent()));
};
