import { mediaQueries } from '@fourthwall/components';
import styled from 'styled-components';
export const Form = styled.form `
  display: grid;
  gap: 20px;
  grid-template-columns: 50% 50%;

  ${mediaQueries.tablet} {
    grid-template-columns: 200px minmax(min-content, 200px);
  }
`;
export const FiltersGroup = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;

  & + & {
    margin-top: 20px;
  }
`;
export const CheckboxGroup = styled.div `
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
