import React from 'react';
import trim from 'lodash-es/trim';
import { useTimelineQuery } from '../../queries/useTimelineQuery';
import { OrderView } from './OrderView';
import { useOrderIdParam } from './hooks';
import { useOrderQuery } from './queries';
export const OrderContainer = () => {
    const { orderId } = useOrderIdParam();
    const { orderQuery } = useOrderQuery([orderId]);
    const { timelineQuery } = useTimelineQuery([orderId]);
    return (React.createElement(OrderView, { orderId: orderId, order: orderQuery.data, date: orderQuery.data?.createdAt, email: orderQuery.data?.email, friendlyId: orderQuery.data?.friendlyId, shippingAddress: orderQuery.data?.shipping.address, shippingMethod: orderQuery.data?.shipping.method, grandTotalPrice: orderQuery.data?.totalPrice, message: orderQuery.data?.message, donation: orderQuery.data?.donation, discount: orderQuery.data?.discount, usedCreatorBudget: orderQuery.data?.creatorBudget?.usedBudget, status: orderQuery.data?.status, orderType: orderQuery.data?.type, fullName: trim(`${orderQuery.data?.shipping.address?.firstName || ''} ${orderQuery.data?.shipping.address?.lastName || ''}`), hasNoProfit: orderQuery.data && !orderQuery.data.profit, replacement: orderQuery.data?.replacement, isLoading: orderQuery.isLoading, isUnavailable: orderQuery.isError || (orderQuery.isSuccess && orderQuery.data.status === 'ARCHIVED'), timelineEvents: timelineQuery.data?.events, timelineFulfillments: timelineQuery.data?.fulfillments, timelineIsFetching: timelineQuery.isLoading }));
};
