import { MembershipsCreatorApiClient } from '@fourthwall/services/lib/MembershipsCreatorApiClient';
import { captureMessage } from '@fourthwall/sentry';
import { useMutation } from '@tanstack/react-query';
const MAX_ITERATIONS = 20;
export const useCheckMediaCreatedMutation = () => {
    const pollMediaData = async (iteration, postId) => {
        const mediaData = await MembershipsCreatorApiClient.confirmMediaUpload(postId);
        if (mediaData.data.status !== 'Created') {
            return mediaData.data;
        }
        if (iteration === MAX_ITERATIONS) {
            captureMessage("Couldn't confirm Mux media upload", {
                extra: { postId },
            });
            return mediaData.data;
        }
        await new Promise((resolve) => {
            setTimeout(resolve, 1000);
        });
        return await pollMediaData(iteration + 1, postId);
    };
    const checkMediaCreatedMutation = useMutation({
        mutationFn: async (postId) => {
            return await pollMediaData(0, postId);
        },
    });
    return {
        checkMediaCreatedMutation,
    };
};
