import { Button, Input, ModalBox, Division } from '@fourthwall/components';
import React, { useState } from 'react';
import * as S from './styled';
export const OrderEditConfirmModal = ({ content, footer, close, onConfirm }) => {
    const [reason, setReason] = useState('');
    const handleConfirm = () => {
        close();
        return onConfirm(reason || undefined);
    };
    return (React.createElement(ModalBox, { title: "Are you sure you want to edit this order?", buttons: [
            React.createElement(Button, { label: "Cancel", appearance: "secondary", onClick: close }),
            React.createElement(Button, { label: "Update", appearance: "primary", onClick: handleConfirm }),
        ], onClose: close },
        content,
        footer && React.createElement(Division, { size: "small" }, footer),
        React.createElement(S.InputContainer, null,
            React.createElement(Input, { name: "reason", value: reason, label: "Edit reason (optional)", suggestion: "Only you and other managers can see this reason", onValueChange: setReason }))));
};
