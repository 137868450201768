export const getBaseUrl = (suffix) => {
    if (!window.__fw_app__ || !window.__fw_app__.baseUrl) {
        throw new Error('Missing getBaseUrl (window.__fw_app__.baseUrl)');
    }
    if (suffix) {
        return `${window.__fw_app__.baseUrl}/${suffix}`;
    }
    return window.__fw_app__.baseUrl;
};
export const getHasuraApiUrl = (suffix) => {
    if (!window.__fw_app__ || !window.__fw_app__.hasuraApiUrl) {
        throw new Error('Missing hasuraApiUrl (window.__fw_app__.hasuraApiUrl)');
    }
    if (suffix) {
        return `${window.__fw_app__.hasuraApiUrl}/${suffix}`;
    }
    return window.__fw_app__.hasuraApiUrl;
};
export const getBaseApiUrl = (suffix) => {
    if (!window.__fw_app__ || !window.__fw_app__.baseApiUrl) {
        throw new Error('Missing baseApiUrl (window.__fw_app__.baseApiUrl)');
    }
    if (suffix) {
        return `${window.__fw_app__.baseApiUrl}/${suffix}`;
    }
    return window.__fw_app__.baseApiUrl;
};
export const getRenderingApiUrl = (suffix) => {
    if (!window.__fw_app__ || !window.__fw_app__.renderingApiUrl) {
        throw new Error('Missing renderingApiUrl) { (window.__fw_app__.renderingApiUrl) {)');
    }
    if (suffix) {
        return `${window.__fw_app__.renderingApiUrl}/${suffix}`;
    }
    return window.__fw_app__.renderingApiUrl;
};
export const getHeroUrl = (suffix) => {
    if (!window.__fw_app__ || !window.__fw_app__.heroUrl) {
        throw new Error('Missing heroUrl (window.__fw_app__.heroUrl)');
    }
    if (suffix) {
        return `${window.__fw_app__.heroUrl}/${suffix}`;
    }
    return window.__fw_app__.heroUrl;
};
export const getShopRendererApiUrl = (suffix) => {
    if (!window.__fw_app__ || !window.__fw_app__.shopRendererApiUrl) {
        throw new Error('Missing shopRendererApiUrl (window.__fw_app__.shopRendererApiUrl)');
    }
    if (suffix) {
        return `${window.__fw_app__.shopRendererApiUrl}/${suffix}`;
    }
    return window.__fw_app__.shopRendererApiUrl;
};
export const getChatbotUrl = (suffix) => {
    if (!window.__fw_app__ || !window.__fw_app__.chatbotUrl) {
        throw new Error('Missing chatbotUrl (window.__fw_app__.chatbotUrl)');
    }
    if (suffix) {
        return `${window.__fw_app__.chatbotUrl}/${suffix}`;
    }
    return window.__fw_app__.chatbotUrl;
};
export const getAuthUrl = (suffix) => {
    if (!window.__fw_app__ || !window.__fw_app__.authUrl) {
        throw new Error('Missing authUrl (window.__fw_app__.authUrl)');
    }
    if (suffix) {
        return `${window.__fw_app__.authUrl}/${suffix}`;
    }
    return window.__fw_app__.authUrl;
};
export const getCurrentEnvironment = () => {
    if (!window.__fw_app__ || !window.__fw_app__.env) {
        throw new Error('Missing env (window.__fw_app__.env)');
    }
    return window.__fw_app__.env;
};
export const getSentryDsn = () => {
    return window.__fw_app__.sentryDsn || '';
};
export const getCommitHash = () => {
    return window.__fw_app__.commitHash || 'development';
};
export const getMyShopUrl = () => {
    if (!window.__fw_app__ || !window.__fw_app__.myShopUrl) {
        throw new Error('Missing myShopUrl (window.__fw_app__.myShopUrl)');
    }
    return window.__fw_app__.myShopUrl;
};
export const getRegisterUrl = () => {
    if (!window.__fw_app__ || !window.__fw_app__.registerUrl) {
        throw new Error('Missing registerUrl (window.__fw_app__.registerUrl)');
    }
    return window.__fw_app__.registerUrl;
};
export const getProductCatalogUrl = () => {
    if (!window.__fw_app__ || !window.__fw_app__.productCatalogUrl) {
        throw new Error('Missing productCatalogUrl (window.__fw_app__.productCatalogUrl)');
    }
    return window.__fw_app__.productCatalogUrl;
};
