import { Fieldset } from '@fourthwall/components';
import * as S from '@modules/Dashboard/Product/modules/shipping/ProductShippingByCreator/styled';
import { WeightInput } from '@components/common/WeightInput';
import React from 'react';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { useParams } from 'react-router-dom';
import { getFulfillmentType } from '@modules/Dashboard/Product/store/product/utils';
import { useShopShippingFlatRatesQuery } from '@modules/Dashboard/hooks/useShopShippingFlatRatesQuery';
import some from 'lodash-es/some';
import cloneDeep from 'lodash-es/cloneDeep';
import set from 'lodash-es/set';
import { useOfferQuery } from '@modules/Dashboard/Product';
export const ShipmentWeightField = () => {
    const { hasPermission } = useDashboardPermissions();
    const { values, setFieldValue } = useFormikContext();
    const { variants } = values;
    const isFormEditable = hasPermission('products.edit.details');
    const disabled = !isFormEditable;
    const { productId = '' } = useParams();
    const { offerQuery } = useOfferQuery([productId]);
    const variantTypes = offerQuery?.data?.variantTypes || [];
    const hasDefinedWeight = offerQuery?.data?.productId !== null;
    const fulfillmentType = getFulfillmentType(values.fulfillmentService);
    const { shopShippingFlatRatesQuery } = useShopShippingFlatRatesQuery();
    const areFlatRatesEnabled = Boolean(shopShippingFlatRatesQuery.data?.enabled);
    const weightMayVary = some(variantTypes, { variesBy: { weight: true } });
    const handleWeightValueChange = async (value) => {
        await setFieldValue('variants', cloneDeep(variants).map((variant) => set(variant, 'weight.value', value)));
    };
    const handleUnitValueChange = async (value) => {
        await setFieldValue('variants', cloneDeep(variants).map((variant) => set(variant, 'weight.unit', value)));
    };
    if (fulfillmentType === 'DIGITAL') {
        return null;
    }
    return (!weightMayVary &&
        !hasDefinedWeight &&
        (!areFlatRatesEnabled || fulfillmentType !== 'FULFILLED_BY_CREATOR') && (React.createElement(Fieldset.Row, null,
        React.createElement(S.WeightInputContainer, null,
            React.createElement(WeightInput, { weightName: "variants[0].weight.value", unitName: "variants[0].weight.unit", disabled: disabled, onWeightValueChange: handleWeightValueChange, onUnitValueChange: handleUnitValueChange })))));
};
