import React from 'react';
import { Toasts } from '@fourthwall/components';
import { useAuth } from '@fourthwall/auth';
import { GeneralView } from './GeneralView';
import { useUserAccountSettingsMutation } from './hooks/useUserAccountSettingsMutation';
export const GeneralContainer = () => {
    const { user, updateToken } = useAuth();
    const { userAccountSettingsMutation } = useUserAccountSettingsMutation();
    const handleSubmit = async (values) => {
        try {
            await userAccountSettingsMutation.mutateAsync([values], {
                onSuccess: () => {
                    updateToken();
                    Toasts.notify('Settings saved.', { type: 'success' });
                },
            });
        }
        catch (e) {
            Toasts.notify('Failed to save settings.');
        }
    };
    return (React.createElement(GeneralView, { initialValues: {
            fullName: user?.fullName || '',
        }, loginDetails: {
            email: user?.email || '',
            verified: user?.verified || false,
        }, onSubmit: handleSubmit }));
};
