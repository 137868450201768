import { Toasts } from '@fourthwall/components';
import { loadStripeScript } from '@fourthwall/utils/lib/stripe';
import { useEffect, useState } from 'react';
import { STRIPE_PUBLIC_KEY } from '../consts';
import { useStripeElement } from './useStripeElement';
export const useStripe = () => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [instance, setInstance] = useState();
    const [elements, setElements] = useState();
    const [submitCount, setSubmitCount] = useState(0);
    const cardNumberElement = useStripeElement('cardNumber', elements, { placeholder: 'Card number' });
    const cardExpiryElement = useStripeElement('cardExpiry', elements);
    const cardCvcElement = useStripeElement('cardCvc', elements);
    const areAllFieldsComplete = cardNumberElement.isComplete && cardCvcElement.isComplete && cardExpiryElement.isComplete;
    const handleSubmit = async (clientSecret) => {
        if (!instance || !cardNumberElement.element) {
            return;
        }
        setSubmitCount((currentSubmitCount) => currentSubmitCount + 1);
        const confirmCardSetupResponse = await instance.confirmCardSetup(clientSecret, {
            payment_method: {
                card: cardNumberElement.element,
            },
        });
        if (confirmCardSetupResponse.error?.message && areAllFieldsComplete) {
            Toasts.notify(confirmCardSetupResponse.error?.message, { type: 'error' });
            return;
        }
        return confirmCardSetupResponse.setupIntent;
    };
    const initializeScript = async () => {
        await loadStripeScript();
        setIsScriptLoaded(true);
    };
    const cleanup = () => {
        cardNumberElement.unmountElement();
        cardExpiryElement.unmountElement();
        cardCvcElement.unmountElement();
    };
    const getElementError = (element) => {
        if (submitCount === 0) {
            return;
        }
        return element.error;
    };
    useEffect(() => {
        initializeScript();
        return () => {
            cleanup();
        };
    }, []);
    useEffect(() => {
        if (isScriptLoaded) {
            setInstance(Stripe(STRIPE_PUBLIC_KEY, { locale: 'en' }));
        }
    }, [isScriptLoaded]);
    useEffect(() => {
        if (instance) {
            setElements(instance.elements());
        }
    }, [instance]);
    const reset = () => {
        setSubmitCount(0);
    };
    return {
        fields: {
            cardNumber: {
                error: getElementError(cardNumberElement),
                isFocused: cardNumberElement.isFocused,
                isInitialized: cardNumberElement.isInitialized,
                isMounted: cardNumberElement.isMounted,
                mount: cardNumberElement.mountElement,
            },
            cardExpiry: {
                error: getElementError(cardExpiryElement),
                isFocused: cardExpiryElement.isFocused,
                isInitialized: cardExpiryElement.isInitialized,
                isMounted: cardExpiryElement.isMounted,
                mount: cardExpiryElement.mountElement,
            },
            cardCvc: {
                error: getElementError(cardCvcElement),
                isFocused: cardCvcElement.isFocused,
                isInitialized: cardCvcElement.isInitialized,
                isMounted: cardCvcElement.isMounted,
                mount: cardCvcElement.mountElement,
            },
        },
        isSubmitted: submitCount > 0,
        submit: handleSubmit,
        reset,
    };
};
