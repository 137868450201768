import styled, { css } from 'styled-components';
import { Colors } from '../../colors';
export const ActivityIndicatorWrapper = styled.div `
  position: absolute;
  top: 2px;
  right: 2px;
`;
const sizeStyleMap = {
    extraSmall: 24,
    small: 32,
    medium: 40,
};
export const Container = styled.div `
  width: ${({ size }) => sizeStyleMap[size]}px;
  height: ${({ size }) => sizeStyleMap[size]}px;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  color: ${({ color }) => color || Colors.GS1000};

  &:hover {
    background: ${Colors.GS300};

    ${ActivityIndicatorWrapper} {
      border-color: ${Colors.GS300};
    }
  }

  ${({ active }) => active &&
    css `
      background: ${Colors.GS300};

      ${ActivityIndicatorWrapper} {
        border-color: ${Colors.GS300};
      }
    `};

  ${({ disabled }) => disabled &&
    css `
      background: none !important;
      opacity: 0.4;

      cursor: default;
    `};
`;
