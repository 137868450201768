import styled from 'styled-components';
import { Colors } from '../../colors';
export const Backdrop = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: ${Colors.GS1000};
  opacity: 0.8;
`;
export const BottomInset = styled.div `
  position: fixed;
  left: 0;
  right: 0;
  height: 50px;
  top: 100%;
  background: ${Colors.GS0};
`;
