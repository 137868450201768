import { ButtonClean, CurrencyInput, Fieldset, PriceString } from '@fourthwall/components';
import { useFormikContext } from '@fourthwall/utils/lib/formik';
import { Field } from 'formik';
import React from 'react';
import { routing } from '@utils/routing';
import { isPaidTier } from '../../../../utils';
import { FormFields } from '../../types';
export const Price = ({ membersCount, annualDiscount, tier, onAnnualClick }) => {
    const formik = useFormikContext();
    const renderSuggestion = () => {
        if (formik.values.price && parseFloat(formik.values.price) === 0) {
            return 'All free accounts will be automatically assigned to this free tier.';
        }
        if (!annualDiscount) {
            return (React.createElement(ButtonClean, { withUnderline: true, appearance: "secondary", type: "button", label: "Offer annual memberships", to: formik.dirty ? undefined : routing.memberships.settings.general, onClick: onAnnualClick }));
        }
        if (!formik.values.price)
            return undefined;
        const yearPrice = formik.values.price
            ? parseFloat(formik.values.price) * 12 -
                parseFloat(formik.values.price) * 12 * (annualDiscount / 100)
            : 0;
        return (React.createElement(React.Fragment, null,
            React.createElement(PriceString, { value: yearPrice }),
            " price per year (",
            React.createElement(ButtonClean, { withUnderline: true, appearance: "secondary", type: "button", label: `${annualDiscount}% discount`, to: formik.dirty ? undefined : routing.memberships.settings.general, onClick: onAnnualClick }),
            ")"));
    };
    return (React.createElement(Fieldset.Row, null,
        React.createElement(Fieldset.Item, null,
            React.createElement(Field, { component: CurrencyInput, name: FormFields.price, label: "Price per month", suggestion: renderSuggestion(), disabled: (membersCount ? membersCount > 0 : false) || (tier ? !isPaidTier(tier) : false) }))));
};
