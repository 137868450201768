import { List, Table, useMediaQueryContext, useModalContext } from '@fourthwall/components';
import React from 'react';
import { ExplicitSellingPriceListItem } from '../ExplicitSellingPriceListItem';
import { SizeVariantSellingPriceModal } from '../SizeVariantSellingPriceModal';
export const ExplicitSellingPrice = ({ data, tableHeaders, isCompareAtPriceEnabled, applyToAllVariants, }) => {
    const media = useMediaQueryContext();
    const { open } = useModalContext();
    const handleSubmit = (size) => (values) => {
        applyToAllVariants({
            priceValue: values.price.value.toString(),
            compareAtPriceValue: values.compareAtPrice?.value
                ? values.compareAtPrice?.value.toString()
                : null,
            size,
        });
    };
    const openModal = (offerVariant) => open(SizeVariantSellingPriceModal, {
        offerVariant,
        isCompareAtPriceEnabled,
        onSubmit: handleSubmit(offerVariant.size),
    });
    if (!media.tablet) {
        return (React.createElement(List, { data: data, renderItem: (offerVariant) => (React.createElement(ExplicitSellingPriceListItem, { offerVariant: offerVariant })), onClick: openModal }));
    }
    return React.createElement(Table, { data: data, headers: tableHeaders, minWidth: 0 });
};
