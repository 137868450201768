import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  display: inline-flex;

  align-items: center;

  margin: 4px;
  padding: 4px 8px;

  border-radius: 1000px;

  background: ${Colors.GS300};
`;
export const RemoveButton = styled.button `
  display: flex;

  align-items: center;
  justify-content: center;

  margin-right: 8px;

  border-radius: 100%;

  width: 20px;
  height: 20px;

  color: ${Colors.GS100};
  background: ${Colors.GS600};

  &:hover {
    background: ${Colors.GS700};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
