import { getPrice } from '@fourthwall/utils';
export const getOrderType = ({ type, giveaway_id: giveawayId, }) => {
    if (type === 'GIFT')
        return giveawayId ? 'Twitch gift redemption' : 'Giveaway links';
    if (type === 'CREATOR_BUDGET')
        return 'Samples order';
    return 'Order';
};
export const formatMoney = (value, currency) => value !== null ? getPrice(value, { currency }) : '';
export const sortOrderRowsByDate = (orderRows) => orderRows.sort((a, b) => (a['DATE (UTC)'] > b['DATE (UTC)'] ? -1 : 1));
