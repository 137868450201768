import { ButtonClean, Toasts, useModalContext } from '@fourthwall/components';
import { reverse } from 'named-urls';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LABEL_BY_MANUFACTURING_SERVICE } from '@modules/Dashboard/Product/consts';
import { PRODUCTION_METHODS } from '../../../../../../consts/productionMethods';
import { routing } from '../../../../../../utils/routing';
import { ChangeProductionMethodModal } from '../../../../MigrateOffer/modules/ChangeProductionMethodModal/ChangeProductionMethodModal';
import { useOfferMigrate } from '../../../../hooks/useOfferMigrateMutation/useOfferMigrateMutation';
import { useOfferMigrateProductionMethodOptionsQuery } from '../../../../hooks/useOfferMigrateProductionMethodOptionsQuery/useOfferMigrateProductionMethodOptionsQuery';
import { useCustomizationV4Query } from '../../../hooks/useCustomizationV4Query';
export const CatalogProductionMethod = ({ product, offer }) => {
    const navigate = useNavigate();
    const { customizationV4Query } = useCustomizationV4Query([offer.customizationId], {
        enabled: !!offer.customizationId,
    });
    const { open, close } = useModalContext();
    const { offerMigrateMutation } = useOfferMigrate();
    const { offerMigrateProductionMethodOptionsQuery } = useOfferMigrateProductionMethodOptionsQuery([
        { offerId: offer.id },
    ]);
    const isRecommendProductionMethod = customizationV4Query.data?.settings.recommendProductionMethod &&
        (product.productionMethod === 'DTG' || product.productionMethod === 'DTFX');
    const currentOption = {
        id: product.productionMethod,
        productId: product.id,
        productionMethod: PRODUCTION_METHODS[product.productionMethod],
    };
    const options = [
        currentOption,
        ...(offerMigrateProductionMethodOptionsQuery.data?.migrationOptions.map((option) => {
            return {
                id: option.productionMethod,
                productId: option.id,
                productionMethod: PRODUCTION_METHODS[option.productionMethod],
            };
        }) ?? []),
    ];
    const handleChangeProductionMethod = async () => {
        open(ChangeProductionMethodModal, {
            initialOption: currentOption,
            options,
            async onConfirm(option) {
                const { id } = await offerMigrateMutation.mutateAsync([
                    { offerId: offer.id },
                    { productId: option.productId, migrateToNewProduct: true },
                ]);
                if (!id) {
                    throw new Error('Product id is missing');
                }
                Toasts.notify('Product has been successfully duplicated with a new production method', {
                    type: 'success',
                });
                navigate(reverse(routing.products.all.product.self, { productId: id }));
            },
            onClose() {
                close();
            },
        });
    };
    if (!product) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        product.productionMethod && (React.createElement("p", null, isRecommendProductionMethod ? (React.createElement(React.Fragment, null,
            "Printed by ",
            LABEL_BY_MANUFACTURING_SERVICE[product.manufacturer],
            " (",
            PRODUCTION_METHODS[product.productionMethod].label,
            ")")) : (React.createElement(React.Fragment, null,
            PRODUCTION_METHODS[product.productionMethod].label,
            " by",
            ' ',
            LABEL_BY_MANUFACTURING_SERVICE[product.manufacturer])))),
        offerMigrateProductionMethodOptionsQuery.data &&
            offerMigrateProductionMethodOptionsQuery.data.migrationOptions.length > 0 && (React.createElement(ButtonClean, { label: "Change method", onClick: handleChangeProductionMethod }))));
};
