import { OrderApiClient } from '@fourthwall/services/lib/OrderApiClient';
import { useQuery } from '@tanstack/react-query';
import { decodeHtmlEntities } from '@fourthwall/utils/lib/sanitize';
const fetch = async (params) => {
    const { data } = await OrderApiClient.getOrders(params);
    return data;
};
export const useOrdersQuery = (params, options) => {
    const ordersQuery = useQuery({
        queryKey: ['useOrdersQuery', params],
        queryFn: async () => fetch(params),
        ...options,
        select: (data) => {
            data.orders = data.orders.map((order) => ({
                ...order,
                offers: order.offers.map((offer) => ({
                    ...offer,
                    offer: { ...offer.offer, name: decodeHtmlEntities(offer.offer.name) },
                })),
            }));
            return data;
        },
    });
    return {
        orders: ordersQuery.data,
        ordersQuery,
    };
};
