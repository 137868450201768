import { useOffersInfiniteQuery } from '../../hooks/useOffersInfiniteQuery';
import { SEARCH_MIN_LENGTH } from '../consts';
const size = 20;
export const useProductsInfiniteQuery = (query, options, params) => {
    const { offersInfiniteQuery: { data, hasNextPage, isLoading, fetchNextPage }, } = useOffersInfiniteQuery({
        q: query && query?.length > SEARCH_MIN_LENGTH - 1 ? query : undefined,
        size,
        ...params,
    }, {
        keepPreviousData: true,
        ...options,
    });
    const products = data?.pages.reduce((acc, currentOffer) => {
        return [...acc, ...currentOffer.offers];
    }, []);
    return {
        products,
        isLoading,
        hasNextPage,
        fetchNextPage,
    };
};
