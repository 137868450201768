import { ShopUsersApiClient } from '@fourthwall/services/lib/ShopUsersApiClient';
import { useMutation } from '@tanstack/react-query';
export const useUserRemoveMutation = () => {
    const userRemoveMutation = useMutation({
        mutationFn: async (variables) => {
            const { data } = await ShopUsersApiClient.removeUser(variables);
            return data;
        },
    });
    return { userRemoveMutation };
};
