import React from 'react';
import { EmptyState, List, Table, useMediaQueryContext } from '@fourthwall/components';
import { Link } from 'react-router-dom';
import { useDashboardPermissions } from '@components/utils/DashboardPermissions';
import { useSearchQuery } from '@modules/Dashboard/hooks/useSearchQuery';
import { useFilterContributions } from '@modules/Dashboard/Contributions/hooks/useFilterContributions';
import { routing } from '@utils/routing';
import { getOrdersTableHeaders } from './utils/getOrdersTableHeaders/getOrdersTableHeaders';
import { getOrderLink } from './utils/getOrderLink';
import { OrderListItem } from './components/OrderListItem';
export const OrdersData = ({ data, isLoading }) => {
    const { hasPermission } = useDashboardPermissions();
    const media = useMediaQueryContext();
    const { searchQuery } = useSearchQuery();
    const { hasFilterParams } = useFilterContributions();
    const hasFilters = searchQuery || hasFilterParams;
    const getEmptyStateSubtitle = () => {
        if (hasFilters) {
            return 'Adjust your filters or try different search term.';
        }
        return (React.createElement(React.Fragment, null,
            "Start selling ",
            React.createElement(Link, { to: routing.products.all.self }, "products"),
            " or run",
            ' ',
            React.createElement(Link, { to: routing.promotions.self }, "promotions"),
            " to boost your sales!"));
    };
    const emptyComponent = (React.createElement(EmptyState, { variant: media.tablet ? 'primary' : 'secondary', title: hasFilters ? 'No orders found' : 'You haven’t received any orders yet', subtitle: getEmptyStateSubtitle() }));
    if (!media.tablet) {
        return (React.createElement(List, { data: data, isLoading: isLoading, emptyComponent: emptyComponent, renderItem: (order) => React.createElement(OrderListItem, { order: order }), getItemLink: getOrderLink }));
    }
    return (React.createElement(Table, { data: data, headers: getOrdersTableHeaders({ hideProfitColumn: !hasPermission('contributions.profit') }), emptyComponent: emptyComponent, rowComponent: Link, isLoading: isLoading, getRowLink: getOrderLink }));
};
