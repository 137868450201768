import { Colors } from '@fourthwall/components';
import styled, { css } from 'styled-components';
export const Container = styled.button `
  border: 1px solid ${Colors.GS700};
  color: ${Colors.GS550};
  border-radius: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  gap: 8px;

  &:hover {
    border-color: ${Colors.GS600};
  }

  ${({ isOpen }) => isOpen &&
    css `
      &,
      &:hover {
        color: ${Colors.GS500};
        border-color: ${Colors.GS500};
      }
    `}
`;
