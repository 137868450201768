import React from 'react';
import { ConfirmModal, useModalContext } from '@fourthwall/components';
export const DigitalProductUpdateModal = ({ onConfirm, ...props }) => {
    const { close } = useModalContext();
    // const [acceptance, setAcceptance] = useState(true)
    return (React.createElement(ConfirmModal, { title: "Your new files will be sent to previous buyers", 
        // title="Do you want to send new files to all previous buyers?"
        text: "The new files you uploaded will be sent to all buyers of this product. If you don\u2019t want previous buyers to receive the new files, create a new product.", 
        // text={
        //   <>
        //     You’ve updated downloadable files for this product. Do you want to send these files to
        //     your previous buyers?
        //     <div style={{ marginTop: 12 }}>
        //       <Checkbox
        //         value={acceptance}
        //         onValueChange={setAcceptance}
        //         label="Send new files to all previous buyers"
        //       />
        //     </div>
        //   </>
        // }
        confirmLabel: "Continue", 
        // confirmLabel="Save"
        close: close, onConfirm: () => onConfirm(true), ...props }));
};
