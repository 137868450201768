import { Colors } from '@fourthwall/components';
import styled from 'styled-components';
export const Container = styled.div `
  & + & {
    margin-top: 54px;
  }
`;
export const HeaderContainer = styled.div `
  margin-bottom: ${({ bottomMargin }) => {
    switch (bottomMargin) {
        case 'small':
            return '8px';
        case 'large':
            return '32px';
        default:
            return '24px';
    }
}};
`;
const titleSpacingMap = {
    small: '4px',
    medium: '8px',
    large: '12px',
};
export const TitleContainer = styled.div `
  margin-top: ${({ titleSpacing }) => titleSpacingMap[titleSpacing]};
`;
export const Title = styled.h2 `
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${Colors.GS1000};
`;
export const NameContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
export const HeadingContainer = styled.div `
  word-break: break-word;
`;
export const NameContent = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;
export const ToolbarContainer = styled.div `
  display: flex;
  margin-left: auto;

  & > * {
    margin-left: 16px;
  }
`;
export const TopContainer = styled.div `
  margin-bottom: 14px;
  align-items: center;
  display: flex;
`;
export const TopContent = styled.div `
  flex: 1;
`;
export const BottomContainer = styled.div `
  margin-top: 16px;

  &:empty {
    margin-top: 0;
  }
`;
